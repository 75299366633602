import { Link } from "react-router-dom";
import Tooltip from "src/Components/Tooltip";


const TitleAndViewAll = ({ title, viewPath, id }) => {
    // console.log(viewPath)
    return (
        <Tooltip
            id={id}
            title={title}
            place="bottom"
            variant="info"
        // content='Create new Project'
        >
            <Link className="font-[500] flex justify-center items-center h-5 w-5 text-lg rounded-md text-secondary" to={viewPath} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                </svg>
            </Link>
        </Tooltip>

    )
}

export default TitleAndViewAll;