import moment from 'moment'
import {
    ACTION_GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
    ACTION_GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
    ACTION_GET_EFFORT_HISTROY_METRICS,
    ACTION_GET_METRICS_FOR_HOME,
    ACTION_GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
    ACTION_GET_NOTIFICATION_FOR_HOMEPAGE,
    ACTION_GET_PRIMARYSKILL_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
    ACTION_GET_PROFITABILITY_FOR_DASHBOARD,
    ACTION_GET_REVENUE_FOR_DASHBOARD,
} from 'src/Redux/types'
import { AlertError } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'
const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
}

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: Number(localStorage.getItem('comuserid') || 0),
    }
}

export const GET_NOTIFICATION_FOR_HOMEPAGE = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_announcements`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No notification')
                dispatch({
                    type: ACTION_GET_NOTIFICATION_FOR_HOMEPAGE,
                    data: addnewkey || [],
                })
                return items
            }
        } catch (error) {
            // console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_REVENUE_FOR_DASHBOARD = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_FinanceDashboard`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No revenue')
                dispatch({
                    type: ACTION_GET_REVENUE_FOR_DASHBOARD,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_workforceallocationdashboard`
            delete paylaod['typeid']
            delete paylaod['year']
            delete paylaod['quarter']
            delete paylaod['month']
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                // console.log(items, "itemsforallocation");
                if (items === null) AlertError('No allocation')
                dispatch({
                    type: ACTION_GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PRIMARYSKILL_ALLOCATION_FOR_WORKFORCE_DASHBOARD = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_workforceprimaryskillallocationdashboard`
            delete paylaod['typeid']
            delete paylaod['year']
            delete paylaod['quarter']
            delete paylaod['month']
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                // console.log(items, "itemsdddddddddd");
                if (items === null) AlertError('No primaryskills')
                dispatch({
                    type: ACTION_GET_PRIMARYSKILL_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_workforcemonthwiseallocationdashboard`
            delete paylaod['typeid']
            delete paylaod['year']
            delete paylaod['quarter']
            delete paylaod['month']
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                // console.log(items, "itemsdddddddddd");
                if (items === null) AlertError('No allocation months')
                dispatch({
                    type: ACTION_GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_workforcebandwiseallocationdashboard`
            delete paylaod['typeid']
            delete paylaod['year']
            delete paylaod['quarter']
            delete paylaod['month']
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                // console.log(items, "itemsdddddddddd");
                if (items === null) AlertError('No bands')
                dispatch({
                    type: ACTION_GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROFITABILITY_FOR_DASHBOARD = (paylaod) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_FinanceDashboard`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                // console.log(items, "itemsdddddddddd");
                if (items === null) AlertError('No revenue')
                dispatch({
                    type: ACTION_GET_PROFITABILITY_FOR_DASHBOARD,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_METRICS_FOR_HOME = () => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: getTanAndComId()?.Tenantid,
                userid: getTanAndComId()?.comuserid,
            }
            const url = `${baseUrl}/get_employeemetrics`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                dispatch({
                    type: ACTION_GET_METRICS_FOR_HOME,
                    data: items[0] || null,
                })
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_EFFORT_HISTROY_METRICS = () => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: getTanAndComId()?.Tenantid,
                userid: getTanAndComId()?.comuserid,
                fromyearmonth: moment().subtract(11, 'months').format('YYYYMM'),
                toyearmnoth: moment().format('YYYYMM'),
            }
            const url = `${baseUrl}/get_efforttimeline`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data

                dispatch({
                    type: ACTION_GET_EFFORT_HISTROY_METRICS,
                    data: items?.length > 0 ? items[0]?.Data : [],
                })
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
