import React, { useEffect, useState } from 'react'
import Barchart from './byStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { GET_ALLOCATION_STATUS_FOR_EMPLOYEE } from 'src/Redux/actions/workforce/talent'
import {
    getBusinessUnitData,
    getStatusMasterIdData,
} from 'src/Services/constant/defaultPayload'
import {
    GET_BUSINESS_MASTER,
    GET_SERVICELINE_MASTER,
} from 'src/Redux/actions/masterdata/user'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import PieChartWithPaddingAngle from './byServiceLine'
import PieChartWithPaddingAngleBu from './byBusinessUnit'
import { GET_CUSTOMER_MASTERLIST } from 'src/Redux/actions/masterdata/customer'
import { getCustomerListPayload } from 'src/Services/constant/Customerpayload'
import PieChartWithPaddingAngleCustomer from './byCustomer'
import PieChartWithPaddingAngleAge from './byDemandAge'
import { PreIcon, NextIcon } from 'src/Assets/icons'

const Demands = () => {
    const dispatch = useDispatch()
    const [showMore, setShowMore] = useState(true)
    const { allocationstatuslist } = useSelector(state => state?.EmployeeDetails)
    const { serviceList, businessList } = useSelector(state => state?.UserManagementList)
    const { listOfCustomers } = useSelector(state => state?.CustomerManagementList)

    useEffect(() => {
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('W')))
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_BUSINESS_MASTER(getBusinessUnitData()))
        dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload()))
    }, [dispatch])

    return (
        <div className="w-full ">
            <div className="absolute flex items-center justify-start space-x-2 top-2 right-2 ">
                <button
                    disabled={showMore}
                    onClick={() => setShowMore(!showMore)}
                    className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                >
                    {PreIcon}
                </button>
                <button
                    disabled={!showMore}
                    onClick={() => setShowMore(!showMore)}
                    className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                >
                    {NextIcon}
                </button>
            </div>
            <div className="grid grid-cols-3 py-2">
                {showMore ? (
                    <>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Status/Type
                            </h3>
                            <Barchart allocationstatuslist={allocationstatuslist} />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Service Line
                            </h3>
                            <PieChartWithPaddingAngle serviceList={serviceList} />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Customer
                            </h3>
                            <PieChartWithPaddingAngleCustomer
                                listOfCustomers={listOfCustomers}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Business Unit
                            </h3>
                            <PieChartWithPaddingAngleBu businessList={businessList} />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Demand Age
                            </h3>
                            <PieChartWithPaddingAngleAge />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Demands
