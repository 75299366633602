import { useEffect, useState } from 'react'

export default function MultiSelectItem(props) {
    const {
        handleSelection,
        className = '',
        placeholder = 'Select',
        selectedOptions,
        options,
        labelKeyname,
        itemIdKey,
        limitShow = 1,
        disabled = false,
    } = props
    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState('')

    const getTitle = (selected) => {
        /*  let items = selected?.filter(tech => tech[itemIdKey] > 0)
    if (!items?.length) {
      return 'Please select'
    } else if (items?.length === 1) {
      return items[0][labelKeyname] || ''
    } else if (items?.length > 1) {
      return `${items[0][labelKeyname]}, ${items?.length - limitShow ? `(${items?.length - limitShow}+)` : ''}`
    } */
        if (
            selected?.length === 0 ||
            (selected?.length === 1 && selected[0][itemIdKey] === '0')
        ) {
            return placeholder
        } else if (selected?.length === 1) {
            return selected[0][labelKeyname] || ''
        } else if (selected?.length > 1) {
            return `${selected[0][labelKeyname]}, ${
                selected?.length - limitShow ? `+${selected?.length - limitShow}` : ''
            }`
        }
    }

    const isActive = (techs, active) => {
        let res = techs?.find((item) => item[itemIdKey] === active[itemIdKey])
        if (res) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (!open && query?.length > 0) {
            setOpen(true)
        }
        // eslint-disable-next-line
    }, [query])

    const filteredOptions =
        query === ''
            ? options
            : options?.filter((item) =>
                  item[labelKeyname]
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .startsWith(query.toLowerCase().replace(/\s+/g, '')),
              )

    const handleOpen = () => {
        setOpen(!open)
        // searchinput
    }

    return (
        <div className="w-full">
            <div className="relative w-full">
                <div
                    className={`relative flex justify-between w-full p-1.5 text-left rounded-lg ${className}  disabled:cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
                >
                    <input
                        disabled={disabled}
                        id="searchinput"
                        className="w-full outline-none bg-transparent placeholder:text-gray-900 placeholder:font-[300]"
                        placeholder={getTitle(selectedOptions)}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <button disabled={disabled} onClick={handleOpen}>
                        {open ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 text-secondary"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 rotate-180 "
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
                                />
                            </svg>
                        )}
                    </button>
                </div>

                {open && (
                    <div className="absolute z-40 w-full py-1 mt-1 overflow-auto text-base transition duration-100 ease-in bg-white rounded-md shadow-lg max-h-72 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <div>
                            {filteredOptions?.map((item) => {
                                return (
                                    <div
                                        key={item[itemIdKey]}
                                        className={`hover:bg-secondary/10 hover:text-secondary flex justify-start items-start relative select-none p-2 cursor-pointer space-x-2 ${
                                            isActive(selectedOptions, item)
                                                ? 'bg-secondary/10 text-secondary/90'
                                                : 'text-gray-900'
                                        }`}
                                        onClick={() => {
                                            document.getElementById('searchinput').value =
                                                ''
                                            handleSelection(item)
                                            setQuery('')
                                        }}
                                    >
                                        {/* <span className='w-5 h-5' >
                      <input type='checkbox' readOnly checked={isActive(selectedOptions, item)} />
                    </span> */}
                                        <span>{item[labelKeyname]}</span>
                                        {isActive(selectedOptions, item) && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-5 h-5"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4.5 12.75l6 6 9-13.5"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
