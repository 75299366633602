import { Fragment, memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog, Transition, Tab } from '@headlessui/react'
import { AlertSuccess } from 'src/Services';
import { SkillItem } from '../defaultSkill'
import SkillDetails from './details'
import IconButton from 'src/Components/Buttons/IconButton';
import { GET_SKILLAREA_MASTER, GET_SKILL_MASTER } from 'src/Redux/actions/workforce/eprofile';
import { SAVE_SKILL_MASTER } from 'src/Redux/actions/masterdata/user'
import { getMasterSkillAreaData, getMasterSkillData } from 'src/Services/constant/masterdatapayload';


const CreateSkill = () => {
	const [detailsData, setDetailsData] = useState(SkillItem())
	const dispatch = useDispatch()
	let [isOpen, setIsOpen] = useState(false)

	const loadFormApis = () => {
		dispatch(GET_SKILLAREA_MASTER(getMasterSkillAreaData()))
	};

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
		loadFormApis();

	}

	async function detailSave(payload) {
		let res = await dispatch(SAVE_SKILL_MASTER(payload))
		if (res?.success) {
			AlertSuccess("Skill saved successfully.");
			await dispatch(GET_SKILL_MASTER(getMasterSkillData()))
		}
	}
	return (
		<>
			<IconButton
				title='Create New Skill'
				onClick={openModal}
				className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
					<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
				</svg>
			</IconButton>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-20' onClose={openModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
					</Transition.Child>
					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-2xl bg-white px-6 text-left align-middle shadow-xl transition-all'>
									<div className='border-b py-2 border-primary flex justify-between items-center'>
										<h3 className='text-lg font-[500] leading-6 text-primary'>
											Create Skill
										</h3>
										<IconButton
											title="Close"
											onClick={closeModal}
											type='button'
											className='rounded-full border h-10 w-10 flex justify-center items-center'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-4 h-4'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
											<span className='sr-only'>Close icon</span>
										</IconButton>
									</div>
									<Tab.Group>
										<Tab.Panels>
											<Tab.Panel>
												<SkillDetails
													detailSave={detailSave}
													detailsData={detailsData}
													setDetailsData={setDetailsData}
													onModalClose={closeModal}
												/>
											</Tab.Panel>
										</Tab.Panels>
									</Tab.Group>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default memo(CreateSkill)
