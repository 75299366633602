import moment from 'moment'
import { useSelector } from 'react-redux'
import EmployeeNote from './employeeNote'

const EmployeePersonalInfo = () => {
    const EmployeeProfile = useSelector((state) => state.EmployeeProfile)
    const { employeeSummary, eprofilePermission } = EmployeeProfile

    return (
        <>
            <div className="h-full gap-4 p-2 text-sm bg-white rounded-md shadow-md">
                <div className="border-b w-full flex justify-between items-center border-primary py-1.5 ">
                    <p className="mb-1 text-sm font-medium">Employee Summary</p>
                    <EmployeeNote
                        eprofilePermission={eprofilePermission}
                        notes={employeeSummary[0]?.notes}
                    />
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Gender: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.gender}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Email: </p>
                    <p className="text-gray-500"> {employeeSummary[0]?.emailid}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Phone: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.mobileno}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Status: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.emp_statusname}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Worker Type : </p>
                    <p className="text-gray-500">{employeeSummary[0]?.workertype}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Time Type : </p>
                    <p className="text-gray-500">{employeeSummary[0]?.timetype}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Workforce group: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.workforcegroup}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Designation: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.designation}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Band: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.bandname}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Department: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.department}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Joining Date: </p>
                    <p className="text-gray-500">
                        {moment(employeeSummary[0]?.dateofjoining).format('DD-MM-YYYY') ||
                            ''}
                    </p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Manager: </p>
                    <p className="text-gray-500">
                        {employeeSummary[0]?.managername} (
                        {employeeSummary[0]?.manageremployeecode})
                    </p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Service Line: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.servicelinename}</p>
                </div>
                <div className="py-[3px] flex justify-start items-start space-x-1 ">
                    <p>Primary Skill Area: </p>
                    <p className="text-gray-500">{employeeSummary[0]?.skillarea}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Primary Skill: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.primaryskill}</p>
                </div>
            </div>
        </>
    )
}

export default EmployeePersonalInfo
