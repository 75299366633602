import { useDispatch, useSelector } from 'react-redux';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import { GET_EMPLOYEE_DETAILS } from 'src/Redux/actions/workforce/talent';
import {
  getDeafultTalentPayload,
} from 'src/Services/constant/defaultPayload';
import { useMemo, useState } from 'react';
import { PreIcon, NextIcon } from "src/Assets/icons";
import { useLocation } from 'react-router-dom';
import { GET_PROJECT_STAFFING_LIST } from 'src/Redux/actions/projects/staffing';

const cols1 = [
  'Name', // add name (Employee Id )
  'Designation',
  "Band",
  "Location",
  'Service Line',
  'Skill Area',
  'Allocation Status (%)',
  "Match Status",
];
const cols2 = [
  "Date Available",
  "Staff Type",
  'Workforce Group',
  "Reporting Manager",
]

const filters = getDeafultTalentPayload()
const limit = 10

const StaffTable = ({ selectedStaff, handleStaffAdd, setSelectedStaff, handleCancel, Mainpage, Mainlimit }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const UserManagementList = useSelector(state => state.UserManagementList)
  const { getStatusByTypeE, usertypelist } = UserManagementList
  const EmployeeDetail = useSelector((state) => state.EmployeeDetails);
  const { Employees } = EmployeeDetail;
  const [showMore, setShowMore] = useState(true)
  const [empstatus, setEmpStatus] = useState(`{50,52}`)
  const workforcegroup = 0
  const [usertypeid, setUsertypeid] = useState(0)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search.split('?')[1])
  const staffingProjectId = searchParams.get('projectid')

  useMemo(() => {
    if (usertypelist?.length > 0) {
      let id = usertypelist?.find(it => it?.usertype === "Employee")?.datusertypeid
      setUsertypeid(id)
    }
  }, [usertypelist])

  useMemo(() => {
    if (getStatusByTypeE?.length > 0) {
      let id = getStatusByTypeE?.filter(it => it?.statusname === 'Active' || it?.statusname === "Notice Period")
			if(id?.length > 1){
				let value = `{${id[0]?.statusid}, ${id[1]?.statusid} }`
				setEmpStatus(value)
			}
    }
  }, [getStatusByTypeE])

  const handleSearch = (search) => {
    console.log(search)
  };

  const handlePagination = async (page) => {
    dispatch(
      GET_EMPLOYEE_DETAILS({
        ...filters,
        limit,
        pageno: page?.selected + 1,
        sorttype: 2,
        status: 'Active'
      })
    );
    setPage(page?.selected + 1);
  };

  const callApiAgain = () => {
    dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId, null, Mainpage, Mainlimit))
    dispatch(
      GET_EMPLOYEE_DETAILS({
        ...filters,
        limit,
        pageno: page,
        sorttype: 2,
        status: 'Active',
        empstatus
      })
    );
  }

  const cancelButton = () => {
    callApiAgain()
    setSelectedStaff(null)
    handleCancel()
  }

  return (
    <div className='p-2 min-h-[60vh]'>
      <div className='flex items-center justify-between mt-2 mb-2'>
        <div className='flex items-center justify-start space-x-6'>
          <h1 className='pb-2'>
            Staff (
            {Employees?.length > 0 ? Employees[0]?.totalcount : Employees?.length})
          </h1>
          <div className='flex items-center justify-start space-x-6'>
            {empstatus && <div className=' w-96'>
              <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_EMPLOYEE_DETAILS}
                searchPayload={{
                  ...getDeafultTalentPayload(),
                  username: searchTerm,
                  limit,
                  status: 'Active',
                  empstatus,
                  workforcegroup,
                  usertypeid
                }}
                setSearchTerm={setSearchTerm}
                options={Employees}
                idkey='uId'
                optionlabelKey='firstname'
                optionLabelkey2='lastname'
                bracketLabel='employeecode'
                className='p-2'
                getSelectedOption={handleSearch}
                placeholder='Enter Employee Name'
                hideOptions={true}
              />
            </div>}
            <ReactPaginationStyle
              limit={limit}
              total={
                Employees?.length > 0 ? Employees[0]?.totalcount : Employees?.length
              }
              currentPage={page}
              handlePagination={handlePagination}
            />

          </div>
        </div>
        <div className='absolute flex items-center justify-start space-x-2 top-2 right-2 '>
          <button disabled={showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary'>
            {PreIcon}
          </button>
          <button disabled={!showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary'>
            {NextIcon}
          </button>

          <button onClick={cancelButton} type="button" className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span className="sr-only">Close icon</span>
          </button>
        </div>

      </div>
      <div className='w-full h-[56vh] overflow-y-scroll mt-2'>
        {
          Employees ? <table className='relative w-full mb-5 text-sm min-h-max'>
            <thead>
              <tr className='text-left capitalize bg-gray-50'>
                {
                  showMore ? cols1?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='p-2 font-[500] border text-start'
                      >
                        {col}
                      </th>
                    )
                  }) : <>
                    {
                      cols2?.map((col) => {
                        return (
                          <th
                            key={col}
                            title={col}
                            scope='col'
                            className='p-2 font-[500] border text-start'
                          >
                            {col}
                          </th>
                        )
                      })
                    }
                  </>
                }
                <th title='Select' scope="col" className="font-[500] bg-gray-50 right-2 p-1 py-2 border text-start">Select</th>
              </tr>
            </thead>
            <tbody className='text-gray-600'>
              {Employees?.map((item) => {
                let { firstname, lastname, skillarea,
                  servicelinename, cityname, allocationstatus,
                  workertype, workforcegroup, designation, uId, employeecode,
                  dateofjoining, managername, bandname, comuserid, matchstatus, allocation_percentage
                } = item;

                // console.log(item)
                return (
                  <tr
                    key={uId || employeecode}
                    className='text-left cursor-pointer '
                  >
                    {
                      showMore ? <>
                        <td className='border min-w-[200px] p-1'>
                          <div className='w-full'>
                            {firstname} {lastname}
                            {employeecode ? `(${employeecode})` : ''}
                          </div>
                        </td>
                        <td className='p-1 border'>
                          {designation || 'N/A'}
                        </td>
                        <td className='p-1 border'>
                          {bandname || 'N/A'}
                        </td>
                        <td className='p-1 border'>{cityname || 'N/A'}</td>
                        <td className='p-1 border'>
                          {servicelinename || 'NA'}
                        </td>
                        <td className='p-1 border'>
                          {skillarea || 'NA'}
                        </td>
                        <td className='p-1 border'>{allocationstatus || 'N/A'} • {allocation_percentage || 0}%</td>
                        <td className='p-1 border'>{matchstatus || 'N/A'}</td>
                      </> : <>
                        <td className='p-1 border'>{dateofjoining || 'N/A'}</td>
                        <td className='px-3 border'>{workertype || 'N/A'}</td>
                        <td className='p-1 border'>{workforcegroup || 'N/A'}</td>
                        <td className='p-1 border'>{managername || 'N/A'}</td>
                      </>
                    }
                    <td className="p-1 bg-white border">
                      <div className='flex justify-center w-10'>
                        <input
                          checked={comuserid === selectedStaff?.userid}
                          onChange={() => handleStaffAdd(item, selectedStaff?.uId)}
                          type='checkbox' />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> : <div className='w-full flex justify-center items-center h-[46vh]'>
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default StaffTable;
