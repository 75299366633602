import { useSelector } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'

const SelectUser = ({
    setUser,
    user,
    apiCallForSearch,
    userSelectorKey,
    labelKey1 = 'firstname',
    labelKey2 = 'lastname',
    idKey = 'uId',
    placeholder = 'Select User',
    searchPayloadFunction,
}) => {
    const UserManagementList = useSelector((state) => state.UserManagementList)
    const userList = UserManagementList[userSelectorKey]
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        if (user?.[labelKey1]) {
            setSearchTerm(
                `${user?.[labelKey1]} ${user?.[labelKey2] || ''} ${
                    user?.employeecode ? `(${user?.employeecode || 0})` : ''
                }`,
            )
        } else {
            setSearchTerm('')
        }
    }, [user, labelKey1, labelKey2])

    return (
        <>
            <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={apiCallForSearch}
                searchPayload={{
                    ...searchPayloadFunction(),
                    username: searchTerm.trim(),
                }}
                setSearchTerm={setSearchTerm}
                options={userList}
                idkey={idKey}
                optionlabelKey={labelKey1}
                optionLabelkey2={labelKey2}
                bracketLabel="username"
                getSelectedOption={setUser}
                className="p-2"
                placeholder={placeholder}
            />
        </>
    )
}

export default memo(SelectUser)
