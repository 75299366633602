const Cards = ({ item, setTalentId, setIsOpen, setSelectedProfile }) => {
    const formatExperience = (months) => {
        if (months === 0 || !months) return ''
        const years = months / 12
        const roundedYears = Math.round(years * 10) / 10
        return roundedYears === 1 ? `${roundedYears} year` : `${roundedYears} years`
    }
    const experience = formatExperience(item?.totalexperienceinmonths)

    const handleCardClick = (com) => {
        setTalentId(com?.comuserid)
        setIsOpen(true)
        setSelectedProfile(com)
    }

    return (
        <>
            <div
                className="w-full px-2 pb-1 bg-white rounded-lg shadow"
                onClick={() => handleCardClick(item)}
                style={{ margin: '1px', boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1)' }}
            >
                <div className="py-2">
                    <div className="flex justify-between space-x-2">
                        <button className="inline-flex items-center px-2 py-1 text-xs font-normal text-green-600 bg-green-200 rounded">
                            {item?.allocationstatus}
                        </button>
                        {item?.matchstatus && (
                            <button className="inline-flex items-center px-2 py-1 text-xs font-normal capitalize rounded text-primary bg-primary/20">
                                {item?.matchstatus}
                            </button>
                        )}
                    </div>

                    <div className="flex items-center mt-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-4 mr-1 text-red-500"
                        >
                            <path
                                fillRule="evenodd"
                                d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <p className="text-xs font-normal text-gray-500">{item?.cityname}</p>
                    </div>
                </div>
                <div className="px-1">
                    <p className="text-sm font-normal text-primary">
                        {item?.firstname} {item?.lastname} {item?.employeecode ? `(${item?.employeecode})` : ''}
                        {item?.bandname ? ` - ${item?.bandname}` : ''}
                    </p>
                    <p className="text-sm text-gray-500 capitalize">
                        {item?.designation || <span className="invisible h-18">Placeholder</span>}{' '}
                        {experience ? ` - ${experience}` : ''}
                    </p>
                    <div className="grid grid-cols-1 gap-y-0.5 py-1.5 text-sm font-normal text-gray-500">
                        <p>
                            <span className="text-gray-700">Emp Type:</span> {item?.workertype}
                        </p>
                        <p>
                            <span className="text-gray-700">Workforce Group:</span> {item?.workforcegroup}
                        </p>
                        <p>
                            <span className="text-gray-700">Department:</span> {item?.department}
                        </p>
                        <p>
                            <span className="text-gray-700">Primary Skill:</span> {item?.primaryskill}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards
