import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../defaultDetails';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';

const LocDetails = props => {
	const [city, setCity] = useState({})
	const { cityList } = useSelector(state => state.UserManagementList)
	const { detailSave, detailsData, setDetailsData } = props
	const [checkvalidation, setCheckvalidation] = useState(false);
	const [searchTerm, setSearchTerm] = useState('')

	useMemo(() => {
		if (city?.cityname) {
			setSearchTerm(city?.cityname)
		}
	}, [city])

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCheckvalidation(true);

		let item = {
			...detailsData,
			comcityid: city?.comcityid
		};

		let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
		if (isValidated?.isvalid) {
			let payload = {
				locationjson: [item],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='locationname' className='block font-[500] mb-2'>
						Location Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						type='text'
						title='locationname'
						id='locationname'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='locationname'
						required=''
						onChange={e => handleChangeInput('locationname', e.target.value)}
						maxLength={50}
					/>
					{!detailsData?.locationname && checkvalidation && (
						<small className='text-red-400'>Location Name is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='locationcode' className='block font-[500] mb-2'>
						Location Code
					</label>
					<input
						disabled
						type='text'
						title='locationcode'
						id='locationcode'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='locationcode'
						required=''
						onChange={e => handleChangeInput('locationcode', e.target.value)}
					/>
				</div>
				<div>
					<label htmlFor='comcityid' className='block font-[500] mb-2'>
						City
						<span className="text-red-300"> *</span>
					</label>
					<CustomAutoSearch
						searchTerm={searchTerm}
						API_CALL_FOR_SEARCH={GET_CITY_MASTER}
						searchPayload={{ ...getMasterCityPayload(), searchname: searchTerm, }}
						setSearchTerm={setSearchTerm}
						options={cityList}
						idkey='cityname'
						optionlabelKey='cityname'
						getSelectedOption={setCity}
						placeholder='Enter City Name'
						className='bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
					/>
					{!city?.comcityid && checkvalidation && (
						<small className='text-red-400'>City is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				<div className='gap-2 flex justify-end items-center'>
					<PrimaryButton
						className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
						onClick={handleSubmit}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default memo(LocDetails)