import { Dialog, Transition } from '@headlessui/react'
import { Fragment, memo, useState } from 'react'
import { Tab } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import EditDetails from './details'
import {
	GET_PROJECT_REVENUE,
	INSERT_PROJECT_REVENUE,
} from 'src/Redux/actions/projects/financials'
import IconButton from 'src/Components/Buttons/IconButton'
import { getDefaultRevenuePayload } from '../defaultPayload'

const EditProjectRevenue = ({ hooks }) => {
	const {
		isOpen = true,
		setIsOpen,
		setProject,
		setRevenue,
		revenue,
		project,
		ProjectRevenue,
		setRevId,
		addEditPermission
		// getDefaultRevenueData
	} = hooks
	const dispatch = useDispatch()
	const [canEdit, setCanEdit] = useState(null)
	const [canDelete, setCanDelete] = useState(null)

	function closeModal() {
		setIsOpen(false)
		setCanDelete(null)
	}

	function openModal() {
		setIsOpen(true)
		setCanDelete(null)
	}

	async function detailSave(payload) {
		let res = await dispatch(INSERT_PROJECT_REVENUE(payload))
		dispatch(GET_PROJECT_REVENUE(getDefaultRevenuePayload(project?.projectid)))
		if (res !== null) {
			setIsOpen(false)
			// getDefaultRevenueData()
		}
	}

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-20' onClose={openModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
					</Transition.Child>
					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex items-center justify-center min-h-full p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-[1160px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ml-12'>
									<div className='border-b py-2 border-primary flex justify-between items-center'>
										<h3 className='mb-4 text-lg font-[500] leading-6 text-primary'>
											Edit Revenue
										</h3>
										<div className='flex items-center justify-end space-x-2'>
											{/*  For Furtue use */}
											{/* {!canEdit && !canDelete && (
												<IconButton
													className='w-8 h-8 text-white bg-primary'
													onClick={() => setCanEdit(!canEdit)}
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='w-4 h-4'
													>
														{' '}
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
														/>{' '}
													</svg>
												</IconButton>
											)} */}
											{addEditPermission ? <>
												{!canEdit && !canDelete && (
													<IconButton
														onClick={() => setCanDelete(!canDelete)}
														type='button'
														className='w-8 h-8 text-white bg-primary'
													>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															fill='none'
															viewBox='0 0 24 24'
															strokeWidth={1.5}
															stroke='currentColor'
															className='w-5 h-5'
														>
															{' '}
															<path
																strokeLinecap='round'
																strokeLinejoin='round'
																d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
															/>{' '}
														</svg>
													</IconButton>
												)}
											</> : ''}
											<IconButton
												onClick={closeModal}
												className='w-8 h-8 text-white bg-primary'
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													className='w-4 h-4'
												>
													{' '}
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M6 18L18 6M6 6l12 12'
													/>{' '}
												</svg>
												<span className='sr-only'>Close icon</span>
											</IconButton>
										</div>
									</div>
									<Tab.Group>
										<Tab.Panels>
											<Tab.Panel>
												<EditDetails
													hooks={{
														detailSave,
														closeModal,
														setProject,
														canDelete,
														setCanDelete,
														project,
														canEdit,
														setCanEdit,
														setRevenue,
														revenue,
														ProjectRevenue,
														setRevId,
													}}
												/>
											</Tab.Panel>
										</Tab.Panels>
									</Tab.Group>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default memo(EditProjectRevenue)
