
import { memo } from 'react';
import {
    getTotalPeopleCostProjectPlanning
} from 'src/Services'


/*
Logic for gross margin 1 
 revenue minus Estimated People cost and divided by revenue * 100 
*/

export const getGross1 = (revenue, peopleCost) => {
    const result = ((revenue - peopleCost) / revenue) * 100;
    return (result || 0).toFixed(2)
}

const Calculation = ({ hooksValue }) => {
    const { roles, project } = hooksValue
    // getTotalPeopleCostProjectPlanning(roles, 'estPeopleCost')
    return (
        <div className="py-3 px-3 flex justify-between items-center text-sm">
            <div className='flex justify-start items-center'>
                <div className='mx-1'>
                    <p className='mr-2 font-[500] mb-1'>Planned Revenue</p>
                    <div className="px-2 py-1 text-green-600 rounded-md border">{project?.totalestimateamt || 0} {project?.currency}</div>
                </div>
                <div className='mx-1'>
                    <p className='mr-2 font-[500] mb-1'>Direct Cost (Plan)</p>
                    <div className="px-2 py-1 text-green-600 rounded-md border">{getTotalPeopleCostProjectPlanning(roles, 'estPeopleCost')} {project?.currency}</div>
                </div>

                <div className='mx-1'>
                    <p className='mr-2 font-[500] mb-1'>GM 1 (Plan)</p>
                    <div className="px-2 py-1 rounded-md border text-green-600">
                        {getGross1(project?.totalestimateamt || 0, getTotalPeopleCostProjectPlanning(roles, 'estPeopleCost'))} %
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Calculation);