import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'src/Components/Buttons/IconButton';
import { getUserTypeIdData, getStatusMasterIdData } from 'src/Services/constant/defaultPayload';
import { getDemandMatchPayload } from 'src/Services/constant/masterdatapayload';
import Details from './details'
import { GET_DEMAND_MATCH, SAVE_DEMAND_MATCH_CONFIG } from 'src/Redux/actions/configuration';
import { GET_ALLOCATION_STATUS_FOR_EMPLOYEE } from 'src/Redux/actions/workforce/talent';
import { GET_USER_TYPE_LIST } from 'src/Redux/actions/masterdata/user';
import { AlertSuccess } from 'src/Services';
import { demandmatchItem } from './default';


const Matchmaking = () => {
   const [canEdit, setCanEdit] = useState(null);
   const [detailsData, setDetailsData] = useState(demandmatchItem());
   const [demandmatchconfigurationid, setDemandmatchconfigurationid] = useState(0);

   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()));
      dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')));
   }, [dispatch,]);


   async function detailSave(payload, demandmatchconfigurationid) {
      let res = await dispatch(SAVE_DEMAND_MATCH_CONFIG(payload, demandmatchconfigurationid));
      setDemandmatchconfigurationid(res?.demandmatchconfigurationid);
      if (res?.success) {
         AlertSuccess('Demand Match updated successfully.');
         await dispatch(GET_DEMAND_MATCH(getDemandMatchPayload()));
      }
   }


   return (
      <div className='h-[65vh]'>
         <div className='flex justify-end items-center'>
            <div className='flex justify-start items-center space-x-5 mb-2'>
               {!canEdit && (
                  <IconButton
			            id='matching-profile-edit-btn'
                     title='Edit'
                     onClick={() => setCanEdit(true)}
                     type='button'
                     className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                  >
                     <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-4 h-4'
                     >
                        <path
                           strokeLinecap='round'
                           strokeLinejoin='round'
                           d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                        />
                     </svg>
                     <span className='sr-only'>Edit icon</span>
                  </IconButton>
               )}
            </div>
         </div>
         <Details
            detailSave={detailSave}
            detailsData={detailsData}
            setDetailsData={setDetailsData}
            canEdit={!canEdit}
            setCanEdit={setCanEdit}
            demandmatchconfigurationid={demandmatchconfigurationid}
         />
      </div>
   )
};

export default memo(Matchmaking);