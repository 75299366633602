import { useDispatch, useSelector } from 'react-redux'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import { useMemo, useState } from 'react'
import { GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE, DOWNLOAD_REPORT_BY_ENDPOINT } from 'src/Redux/actions/reports'
import { PreIcon, NextIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'

const cols1 = [
    'Employee Name',
    'Project Name',
    'Service Line',
    'Role Name',
    'Billing Type',
    'Week Effort ',
]
const cols2 = [
    'Employee Name',
    'Approver Name ',
    'Approver Remarks ',
    'Week Start',
    'Week End',
    'Non-Compliance Type'
]
const rows = [10, 25, 50]

const NonComplianceTimesheetReport = ({
    filters,
    reportMasterList,
    reportId,
    projectIds,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const { timesheetreportForNonCompliance } = useSelector(
        (state) => state?.WorkforceReport,
    )
    const [showMore, setShowMore] = useState(true)
    const handlePagination = (page) => {
        dispatch(
            GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE({
                ...filters,
                limit,
                pageno: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }
    const handleRows = (row) => {
        setLimit(row)
        let data = filters
        if (filters['projectid'] === '{}') {
            data['projectid'] = `{${projectIds?.join(',')}}`
        }
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE({
                ...data,
                limit: row,
                pageno: row > 10 ? 1 : page,
            }),
        )
    }
    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    const totalRecords = useMemo(() => {
        return timesheetreportForNonCompliance?.length > 0
            ? timesheetreportForNonCompliance[0]?.totalcount
            : timesheetreportForNonCompliance?.length
    }, [timesheetreportForNonCompliance])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={!showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>
                <div className="flex items-center justify-between w-full space-x-5">
                    <p className="text-primary font-[500]">
                        Reports Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        limit={totalRecords}
                        endpoint="download_timesheetnoncompliancereport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        fileName={fileName}
                        filters={filters}
                    />
                </div>
            </div>
            {timesheetreportForNonCompliance === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(88%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {showMore
                                    ? cols1.map((col) => {
                                        return (
                                            <th
                                                key={col}
                                                title={col}
                                                scope="col"
                                                className=" min-w-[100px] p-2 font-[500] border text-start"
                                            >
                                                {' '}
                                                {col}
                                            </th>
                                        )
                                    })
                                    : cols2.map((col) => {
                                        return (
                                            <th
                                                key={col}
                                                title={col}
                                                scope="col"
                                                className="p-2 font-[500] border text-start"
                                            >
                                                {' '}
                                                {col}
                                            </th>
                                        )
                                    })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {timesheetreportForNonCompliance?.map((item, i) => {
                                let {
                                    billingtype,
                                    servicelinename,
                                    approvername,
                                    effort,
                                    employeeid,
                                    employeename,
                                    weekenddate,
                                    rolename,
                                    weekstartdate,
                                    approverremarks,
                                    noncompliancetype,
                                    projectcode,
                                    projectname,
                                    uId,
                                } = item
                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {showMore ? (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}{' '}
                                                    {employeeid ? `(${employeeid})` : ''}
                                                </td>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {projectname}{' '}
                                                    {projectcode
                                                        ? `(${projectcode})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {servicelinename}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {rolename}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {billingtype}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {effort} Hrs
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}{' '}
                                                    {employeeid ? `(${employeeid})` : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {approvername}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    <span>{approverremarks}</span>
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {weekstartdate}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {weekenddate}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {noncompliancetype}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default NonComplianceTimesheetReport
