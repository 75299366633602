import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo,useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';



const CountryDetails = (props) => {
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const { regionList } = useSelector((state) => state.UserManagementList);
  const [checkvalidation, setCheckvalidation] = useState(false);


  const handleSubmit = () => {
    setCheckvalidation(true);

    let item = {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      countryname: detailsData?.countryname,
      comcountryid: detailsData?.comcountryid,
      comregionid: detailsData?.comregionid,
      countrycode: detailsData?.countrycode,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
    };

    let isValidated = CheckObjectValidation(detailsData, [
      'notes',
      'comcountryid',
      'modifiedtimestamp',
      'modifiedcomuserid',
    ]);

    if (isValidated?.isvalid) {
      let payload = {
        countryjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='countryname' className='block font-[500] mb-2'>
            Country Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='countryname'
            id='countryname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='countryname'
            required=''
            onChange={(e) => handleChangeInput('countryname', e.target.value)}
            value={detailsData?.countryname || ''}
            maxLength={50}
          />
          	{!detailsData?.countryname && checkvalidation && (
						<small className='text-red-400'>Country Name is required!</small>
					)}
        </div>
        <div>
          <label htmlFor='countrycode' className='block font-[500] mb-2'>
            Country Code
          </label>
          <input
            disabled
            type='text'
            title='countrycode'
            id='countrycode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='countrycode'
            required=''
            onChange={(e) => handleChangeInput('countrycode', e.target.value)}
            value={detailsData?.countrycode || ''}
          />
        </div>
        <div>
          <label htmlFor='comregionid' className='block font-[500] mb-2'>
            Region
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={regionList || []}
            value={detailsData?.comregionid || ''}
            noDataLabel='No region'
            name='comregionid'
            labelkey='regionname'
            className='border rounded bg-gray-100 py-1.5'
            onChange={handleChangeInput}
            id='comregionid'
          />
          	{!detailsData?.comregionid && checkvalidation && (
						<small className='text-red-400'>Region Name is required!</small>
					)}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                // disabled={!canEdit}
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CountryDetails);
