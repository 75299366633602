import { memo, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

const CustomAutoSearch = ({ placeholder, getSelectedOption, options, searchPayload,
	API_CALL_FOR_SEARCH,
	searchTerm, setSearchTerm,
	optionlabelKey, idkey,
	optionLabelkey2,
	bracketLabel,
	className,
	disabled = false,
	hideOptions,
	bgcolor='bg-gray-50',
	defaultApiCall = true
}) => {
	const [showOption, setShowOption] = useState(false);
	const dispatch = useDispatch();
	const dropdownRef = useRef(null); // Create a ref for the dropdown container

	const handleInputChange = event => {
		const query = event.target.value;
		setSearchTerm(query);
	}

	useEffect(() => {
		const debounceTimer = setTimeout(() => {
			if(defaultApiCall){
				dispatch(API_CALL_FOR_SEARCH(searchPayload));
			}
		}, 1200);

		return () => {
			clearTimeout(debounceTimer);
		};
		// eslint-disable-next-line
	}, [searchTerm, API_CALL_FOR_SEARCH, dispatch]);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowOption(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const handleInputClick = () => {
        setShowOption(!showOption);
		dispatch(API_CALL_FOR_SEARCH(searchPayload));
    };

	useEffect(()=>{
        const debounceTimer = setTimeout(() => {
            if(searchTerm?.trim() === ''){
				if(getSelectedOption){
					getSelectedOption({})
				}
            }
		}, 200)

		return () => {
			clearTimeout(debounceTimer)
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchTerm])


	return (
		<div className="relative">
			<div role="button"  onClick={handleInputClick} className="relative flex items-center justify-between w-full bg-white rounded-lg disabled:bg-white">
				<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
					<svg
						className="w-6 h-6"
						fill="text-gray-500"
						xmlns="http://www.w3.org/2000/svg"
						height="48"
						viewBox="0 96 960 960"
						width="48"
					>
						<path d="M782 892 528 638q-29.605 27.077-69.051 41.038Q419.503 693 381 693q-91.812 0-155.406-63.337Q162 566.325 162 474.663 162 383 225.337 319.5 288.675 256 380.11 256t155.662 63.385Q600 382.769 600 474.288 600 515 585 554.5T544 622l254 253-16 17ZM381 671q83.083 0 140.042-56.5Q578 558 578 474.5t-56.958-140Q464.083 278 381 278t-140.042 56.5Q184 391 184 474.5t56.958 140Q297.917 671 381 671Z" />
					</svg>
				</div>
				<input
					autoComplete="off"
					value={searchTerm}
					type="search"
					disabled={disabled}
					className={` ${disabled ? 'bg-white' :bgcolor} outlined  disabled:bg-white border placeholder:text-gray-600  border-gray-300 text-textSecondary text-sm rounded outline-none focus:ring-0 block w-full pl-10 ${className}`}
					placeholder={placeholder}
					onChange={handleInputChange}
				/>
			</div>
			{ !hideOptions && <>
				{showOption && (
					<div className="max-h-[200px] overflow-y-scroll absolute z-10  w-full shadow-md rounded bg-white py-2 mt-3" ref={dropdownRef}>
						{options?.map((item)=>{
							return (
								<div
									key={item[idkey]}
									title={`${item[optionlabelKey]} ${item[optionLabelkey2]} ${bracketLabel !== undefined ? `(${item[bracketLabel]})` : ''}`}
									role="button"
									className="w-full px-2 pb-2 text-sm truncate hover:text-secondary"
									onClick={() => {
										setShowOption(false);
										getSelectedOption(item);
										setSearchTerm(`${item[optionlabelKey]} ${item[optionLabelkey2] || ''}`);
									}}
								>
									{item[optionlabelKey]} {item[optionLabelkey2]} {bracketLabel !== undefined ? `(${item[bracketLabel]})` : ''}
								</div>
							)
						})}
					</div>
				)}
			</>}
		</div>
	)
}

export default memo(CustomAutoSearch)
