import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import SelectUser from 'src/Components/SelectUser'
import { GET_CURRENCY_MASTER_LIST } from 'src/Redux/actions/projects'
import { GET_EMPLOYEE_DETAILS } from 'src/Redux/actions/workforce/talent'
import { AlertSuccess } from 'src/Services'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import {
    getDeafultTalentPayload,
    getDefaultCurrencyPayload,
} from 'src/Services/constant/defaultPayload'

const EditDetails = ({ hooks }) => {
    const { currencyList } = useSelector((state) => state?.Projects)
    const { ProjectOverhead } = useSelector((state) => state?.ProjectFinancial)
    const dispatch = useDispatch()
    const {
        detailSave,
        canEdit,
        closeModal,
        expenses,
        setExpenses,
        setCanEdit,
        canDelete,
        setCanDelete,
        project,
    } = hooks
    const [user, setUser] = useState({})
    const [checkValidation, setCheckValidation] = useState(false)

    useEffect(() => {
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_EMPLOYEE_DETAILS(getDeafultTalentPayload()))
    }, [dispatch])

    useMemo(() => {
        setUser({ taggedusername: expenses?.taggedusername })
        // eslint-disable-next-line
    }, [])

    const handleDates = (e) => {
        let { name, value } = e.target
        setExpenses((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setCheckValidation(true)

        // Extracting monthid and yearid from transactiondate
        const transactionDate = new Date(
            expenses?.transactiondate?.replace(/\/|\./g, '-'),
        )
        const monthid = transactionDate?.getMonth() + 1 // Adjust for JavaScript's 0-based months
        const yearid = transactionDate?.getFullYear()

        let validationdata = {}
        let requiredInDetail = [
            'projectid',
            'overheadid',
            'monthid',
            'amount',
            'datcomcurrencyid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'comtenantid',
            'companyid',
            'description',
            'yearid',
            'transactionid',
            'externalsystemcode',
            'transactiondate',
        ]

        for (let value of requiredInDetail) {
            validationdata[value] = expenses[value]
        }

        validationdata['userid'] = user?.comuserid
        validationdata['projectid'] = expenses?.projectid
        validationdata['yearid'] = yearid
        validationdata['monthid'] = monthid

        let optionalkey = [
            'projexpenseid',
            'taggeduser',
            'externalsystemcode',
            'notes',
            'companyid',
            'userid',
        ]

        let isValidated = CheckObjectValidation(validationdata, optionalkey, true)
        if (!isValidated?.isvalid) {
            return
        } else {
            let item = {
                comtenantid: Number(localStorage.getItem('Tenantid') || 0),
                projexpenseid: expenses?.projexpenseid,
                projectid: expenses?.projectid,
                companyid: expenses?.companyid,
                overheadid: expenses?.overheadid,
                monthid: monthid,
                yearid: yearid,
                amount: Number(expenses?.amount),
                datcomcurrencyid: expenses?.datcomcurrencyid,
                taggeduser: user?.comuserid,
                modifiedcomuserid: expenses?.modifiedcomuserid,
                createdcomuserid: expenses?.createdcomuserid,
                notes: expenses?.notes,
                email: expenses?.email,
                description: expenses?.description,
                transactiondate: expenses?.transactiondate.split('T')[0],
                externalsystemcode: expenses?.externalsystemcode,
                transactionid: expenses?.transactionid,
                isactiveflag: expenses?.isactiveflag,
            }

            let apiData = { ...item, amount: Number(expenses?.amount) }
            setCheckValidation(false)
            let payload = {
                projectexpensejson: [apiData],
            }

            detailSave(payload)
            setCanEdit(!canEdit)
        }
    }

    const handleDelete = async () => {
        let item = {
            comtenantid: Number(localStorage.getItem('Tenantid') || 0),
            projexpenseid: expenses?.projexpenseid,
            projectid: expenses?.projectid,
            companyid: expenses?.companyid,
            overheadid: expenses?.overheadid,
            monthid: expenses?.monthid,
            yearid: expenses?.yearid,
            amount: expenses?.amount,
            datcomcurrencyid: expenses?.datcomcurrencyid,
            taggeduser: expenses?.comuserid,
            modifiedcomuserid: expenses?.modifiedcomuserid,
            createdcomuserid: expenses?.createdcomuserid,
            notes: expenses?.notes,
            email: expenses?.email,
            description: expenses?.description,
            transactiondate: expenses?.transactiondate.split('T')[0],
            externalsystemcode: expenses?.externalsystemcode,
            transactionid: expenses?.transactionid,
            isactiveflag: false,
        }

        let payload = {
            projectexpensejson: [item],
        }
        detailSave(payload)
        AlertSuccess('Record deleted successfully')
        closeModal()
    }

    const handleChangeInput = (name, value) => {
        setExpenses({ ...expenses, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label htmlFor="Overhead" className="block font-[500] mb-2">
                        Overhead
                        <span className="text-red-300">*</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={!canEdit}
                        options={ProjectOverhead || []}
                        noDataLabel="No Overhead Type"
                        value={expenses?.overheadid || null}
                        name="overheadid"
                        labelkey="overhead"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleChangeInput}
                        id="overheadmasterid"
                    />
                    {!expenses?.overheadid && checkValidation && (
                        <small className="text-red-400">Overhead is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="datcomcurrencyid" className="block font-[500] mb-2">
                        Currency
                        <span className="text-red-300">*</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={!canEdit}
                        options={currencyList || []}
                        noDataLabel="No Currency Type"
                        value={expenses?.datcomcurrencyid || null}
                        name="datcomcurrencyid"
                        labelkey="currency"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleChangeInput}
                        id="datcomcurrencyid"
                    />
                    {!expenses?.datcomcurrencyid && checkValidation && (
                        <small className="text-red-400">Currency is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="amount" className="block font-[500] mb-2">
                        Amount
                        <span className="text-red-300">*</span>
                    </label>
                    <input
                        disabled={!canEdit}
                        type="number"
                        title="amount"
                        id="amount"
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px]"
                        placeholder="Amount"
                        required=""
                        onChange={(e) => handleChangeInput('amount', e.target.value)}
                        value={expenses?.amount || ''}
                    />
                    {!expenses?.amount && checkValidation && (
                        <small className="text-red-400">amount is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="description" className="block font-[500] mb-2">
                        Description
                        <span className="text-red-300">*</span>
                    </label>
                    <input
                        disabled={!canEdit}
                        type="text"
                        title="description"
                        id="description"
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px]"
                        placeholder="Description"
                        required=""
                        value={expenses?.description || ''}
                        onChange={(e) => handleChangeInput('description', e.target.value)}
                    />
                    {!expenses?.description && checkValidation && (
                        <small className="text-red-400">description is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="User" className="block capitalize mb-2  font-[500]">
                        Linked User
                        <span className="text-red-300">*</span>
                    </label>
                    <SelectUser
                        setUser={setUser}
                        className="py-2 border rounded bg-gray-50"
                        user={user}
                        apiCallForSearch={GET_EMPLOYEE_DETAILS}
                        userSelectorKey="Employees"
                        searchPayloadFunction={getDeafultTalentPayload}
                        placeholder="Select User"
                        disabled={!canEdit}
                    />
                </div>
                <div>
                    <label htmlFor="transactionid" className="block font-[500] mb-2">
                        Transaction ID
                        <span className="text-red-300">*</span>
                    </label>
                    <input
                        disabled={!canEdit}
                        type="text"
                        title="transactionid"
                        id="transactionid"
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px]"
                        placeholder="transactionid"
                        required=""
                        value={expenses?.transactionid || ''}
                        onChange={(e) =>
                            handleChangeInput('transactionid', e.target.value)
                        }
                    />
                    {!expenses?.transactionid && checkValidation && (
                        <small className="text-red-400">transactionid is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="externalsystemcode" className="block font-[500] mb-2">
                        Cost Center Code
                    </label>
                    <input
                        disabled={!canEdit}
                        type="text"
                        title="externalsystemcode"
                        id="externalsystemcode"
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px] "
                        placeholder="externalsystemcode"
                        required=""
                        value={expenses?.externalsystemcode || ''}
                        onChange={(e) =>
                            handleChangeInput('externalsystemcode', e.target.value)
                        }
                    />
                </div>
                <div>
                    <label htmlFor="transactiondate" className="block font-[500] mb-2">
                        Transaction Date
                        <span className="text-red-300">*</span>
                    </label>
                    <FlatPickrDateInput
                        name="transactiondate"
                        value={expenses?.transactiondate?.split('T')[0] || ''}
                        id="transactiondate"
                        min={project?.startdate}
                        max={project?.enddate}
                        disabled={!canEdit}
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]"
                        onChangeDate={handleDates}
                    />
                    {!expenses?.transactiondate && checkValidation && (
                        <small className="text-red-400">
                            transactiondate is required!
                        </small>
                    )}
                </div>
                {canEdit ? (
                    <div className="fixed bottom-0 mt-2 right-6">
                        <button
                            onClick={() => setCanEdit(null)}
                            className="text-primary bg-gray-100 w-[80px] rounded-md py-2"
                        >
                            Cancel
                        </button>
                        <PrimaryButton
                            onClick={handleSubmit}
                            className="py-2 mx-2 rounded-md bg-primary"
                        >
                            Save
                        </PrimaryButton>
                    </div>
                ) : (
                    <div className="h-12" />
                )}
                {canDelete ? (
                    <div className='fixed bottom-0 mt-2 right-6'>
                        <div className='flex items-center justify-end gap-2'>
                            <span className=""> Do you really want to delete this record? </span>
                            <PrimaryButton className='px-6 border bg-white  border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
                                onClick={handleDelete}
                            >
                                Yes
                            </PrimaryButton>
                            <PrimaryButton className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                                onClick={() => setCanDelete(null)}
                            >
                                No
                            </PrimaryButton>
                        </div>
                    </div>
                ) : (
                    <div className="h-12" />
                )}
            </div>
        </div>
    )
}

export default memo(EditDetails)
