import { memo } from 'react'
import { useDispatch } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import MultiSelectItem from 'src/Components/MultiSelect'
import { GET_DEMAND_MATCH_DETAILS } from 'src/Redux/actions/workforce/demands'
import { getDeafultProfilePayload } from 'src/Services/constant/defaultPayload'

const ProfileFilter = ({ hooks }) => {
    const dispatch = useDispatch()
    const {
        filters,
        setFilters,
        demandData,
        allocationStatus,
        setAllocationStatuslist,
        workforceGroup,
        setWorkforceGroup,
        workerType,
        setWorkerType,
        primarySkill,
        setPrimarySkill,
        extractStatusIds,
        getBandIDs,
        page,
        limit,
        allocationstatuslist,
        workertypelist,
        workforcelist,
        bandList,
        userSkillList,
        setMatchStatuslist,
        setSearchTerm,
        minExp,
        setMinExp
    } = hooks

    const handleSelectChange = (name, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }))
    }

    const handleStatusSelect = (status) => {
        const isNew = allocationStatus?.some((p) => p?.statusid === status?.statusid)
        if (isNew) {
            const updatedAllocationStatus = allocationStatus?.filter((p) => p?.statusid !== status?.statusid)
            setAllocationStatuslist(updatedAllocationStatus)
        } else {
            setAllocationStatuslist((prevList) => [
                ...prevList,
                { statusid: status?.statusid, statusname: status?.statusname },
            ])
        }
    }

    const handleSkillSelect = (skill) => {
        if (skill.skillmasterid === '') {
            setPrimarySkill([{ skillmasterid: '', skill: 'All' }])
        } else {
            let updatedSkills
            if (primarySkill.some((p) => p.skillmasterid === skill.skillmasterid)) {
                updatedSkills = primarySkill.filter((p) => p.skillmasterid !== skill.skillmasterid)
            } else {
                updatedSkills = [...primarySkill.filter((p) => p.skillmasterid !== ''), skill]
            }
            setPrimarySkill(updatedSkills.length > 0 ? updatedSkills : [{ skillmasterid: '', skill: 'All' }])
        }
    }

    const handleWorkerTypeSelect = (type) => {
        if (type.workertypeid === '') {
            setWorkerType([{ workertypeid: '', workertype: 'All' }])
        } else {
            let updatedTypes
            if (workerType.some((p) => p.workertypeid === type.workertypeid)) {
                updatedTypes = workerType.filter((p) => p.workertypeid !== type.workertypeid)
            } else {
                updatedTypes = [...workerType.filter((p) => p.workertypeid !== ''), type]
            }
            setWorkerType(updatedTypes.length > 0 ? updatedTypes : [{ workertypeid: '', workertype: 'All' }])
        }
    }

    const handleWorkforceGroupSelect = (group) => {
        if (group.wfgid === '') {
            setWorkforceGroup([{ wfgid: '', workforcegroup: 'All' }])
        } else {
            let updatedGroups
            if (workforceGroup.some((p) => p.wfgid === group.wfgid)) {
                updatedGroups = workforceGroup.filter((p) => p.wfgid !== group.wfgid)
            } else {
                updatedGroups = [...workforceGroup.filter((p) => p.wfgid !== ''), group]
            }
            setWorkforceGroup(updatedGroups.length > 0 ? updatedGroups : [{ wfgid: '', workforcegroup: 'All' }])
        }
    }

    const applyFilters = () => {
        dispatch(GET_DEMAND_MATCH_DETAILS({ ...filters, limit: limit, pageno: page, mintotalexperience: minExp }))
    }

    const clearFilters = () => {
        const { defaultMinBandID, defaultMaxBandID } = getBandIDs()
        const statusIds = extractStatusIds()
        const filteredStatusObjects = allocationstatuslist?.filter((status) => statusIds.includes(status.statusid))

        setAllocationStatuslist(filteredStatusObjects)
        setWorkerType([{ workertypeid: '', workertype: 'All' }])
        setWorkforceGroup([{ wfgid: '', workforcegroup: 'All' }])
        setPrimarySkill([{ skillmasterid: '', skill: 'All' }])
        setMatchStatuslist([{ statusid: '', statusname: 'All' }])
        setSearchTerm('')
        setMinExp(0)
        const payload = {
            ...getDeafultProfilePayload(),
            workertypeid: '{}',
            allocationstatusid: `{${statusIds.join(', ')}}` || '{}',
            workforcegroupid: '{}',
            skillmasterid: '{}',
            minbandid: defaultMinBandID,
            maxbandid: defaultMaxBandID,
            pageno: page,
            limit: limit,
            demandid: demandData[0]?.demandid,
            matchstatus: '{}',
            username: '',
            mintotalexperience: 0,
        }
        setFilters(payload)
        dispatch(GET_DEMAND_MATCH_DETAILS(payload))
    }

    return (
        <div className="w-full text-sm">
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-1">
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Emp Type</h1>
                    <div className="border rounded bg-gray-50 p-0.5">
                        <MultiSelectItem
                            itemIdKey="workertypeid"
                            labelKeyname="workertype"
                            limitShow={1}
                            selectedOptions={
                                workerType.length > 0 ? workerType : [{ workertypeid: '', workertype: 'All' }]
                            }
                            options={[{ workertypeid: '', workertype: 'All' }, ...workertypelist]}
                            handleSelection={handleWorkerTypeSelect}
                            placeholder={workerType.length === 0 ? 'All' : ''}
                        />
                    </div>
                </div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Primary Skill</h1>
                    <div className="border rounded bg-gray-50 p-0.5">
                        <MultiSelectItem
                            itemIdKey="skillmasterid"
                            labelKeyname="skill"
                            limitShow={1}
                            selectedOptions={
                                primarySkill.length > 0 ? primarySkill : [{ skillmasterid: '', skill: 'All' }]
                            }
                            options={[{ skillmasterid: '', skill: 'All' }, ...userSkillList]}
                            handleSelection={handleSkillSelect}
                            placeholder={primarySkill.length === 0 ? 'All' : ''}
                        />
                    </div>
                </div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Workforce Group</h1>
                    <div className="border rounded bg-gray-50 p-0.5">
                        <MultiSelectItem
                            itemIdKey="wfgid"
                            labelKeyname="workforcegroup"
                            limitShow={1}
                            selectedOptions={
                                workforceGroup.length > 0 ? workforceGroup : [{ wfgid: '', workforcegroup: 'All' }]
                            }
                            options={[{ wfgid: '', workforcegroup: 'All' }, ...workforcelist]}
                            handleSelection={handleWorkforceGroupSelect}
                            placeholder={workforceGroup.length === 0 ? 'All' : ''}
                        />
                    </div>
                </div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Allocation Status</h1>
                    <div className="border rounded bg-gray-50 p-0.5">
                        <MultiSelectItem
                            itemIdKey="statusid"
                            labelKeyname="statusname"
                            limitShow={1}
                            selectedOptions={allocationStatus}
                            options={allocationstatuslist}
                            handleSelection={handleStatusSelect}
                        />
                    </div>
                </div>
                <div className="col-span-1">
                    <h1 className="mb-2 font-[500]">Min Band</h1>
                    <CustomhtmlSelect
                        options={bandList || []}
                        value={filters?.minbandid || getBandIDs()?.defaultMinBandID}
                        noDataLabel="No band"
                        name="minbandid"
                        labelkey="bandname"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleSelectChange}
                        id="bandmasterid"
                    />
                </div>
                <div className="col-span-1">
                    <h1 className="mb-2 font-[500]">Max Band</h1>
                    <CustomhtmlSelect
                        options={bandList || []}
                        value={filters.maxbandid || getBandIDs()?.defaultMaxBandID}
                        noDataLabel="No band"
                        name="maxbandid"
                        labelkey="bandname"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleSelectChange}
                        id="bandmasterid"
                    />
                </div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-1">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ProfileFilter)
