import { postMethodApiCall } from 'src/Services/api'
import { AlertError } from 'src/Services'
import { ACTION_GET_TIME_CATEGORY_MASTER, ACTION_GET_DEMAND_MATCH, ACTION_GET_MENU_MASTER, ACTION_GET_NOTIFICATION_MASTER, ACTION_GET_ROLES_MASTER, ACTION_GET_RULES_MASTER, ACTION_GET_TEMPLATE_MASTER, ACTION_GET_ROLE_BY_ID } from 'src/Redux/types'
const baseUrl = process.env.REACT_APP_BASE_URL
// const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getHeadersWithoutAuth = () => {
	return {
	'Content-Type': 'application/json',
	'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
}
}

export const SAVE_CATEGORY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_timecategorymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const categoryId = item?.timecategorymasterid;
				return { success: true, categoryId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_TIME_CATEGORY_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/gettimecategorymaster`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Time Type found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_TIME_CATEGORY_MASTER,
				data: addnewkey || []
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_TEMPLATE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_timetemplatemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const templateId = item?.timetemplatemasterid;
				return { success: true, templateId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_RULES_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_timetypetemplatemapping`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const rulesId = item?.timetypetemplatemappingid;
				return { success: true, rulesId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const GET_TEMPLATE_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/gettimetemplatemaster`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Template found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_TEMPLATE_MASTER,
				data: addnewkey || []
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const GET_RULES_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/gettimetypetemplatemapping`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Rules found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_RULES_MASTER,
				data: addnewkey || []
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}


export const SAVE_DEMAND_MATCH_CONFIG = (data) => {
	return async () => {
		try {

			const url = `${baseUrl}/insert_demandmatchconfiguration`;
			let res = await postMethodApiCall(url, getHeadersWithoutAuth(), data);
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const demandmatchconfigurationid = item?.demandmatchconfigurationid;
					// AlertSuccess('Company Weekdays saved successfully!');
		    	return { success: true, demandmatchconfigurationid };
				
				}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '');
			return { success: false };
		}
	};
};
// const getDemandMatchPayload = (Page = 1, Limit = 10) => {
// 	return {
// 		Tenantid: Number(localStorage.getItem('Tenantid') || 0),
// 		demandmatchconfigurationid: 0,
// 		Limit,
// 		Page
// 	};
// };
export const GET_DEMAND_MATCH = payload => async dispatch => {
	try {
		const url = `${baseUrl}/get_demandmatchconfiguration`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			{
				Tenantid: Number(localStorage.getItem('Tenantid') || 0),
				demandmatchconfigurationid: payload?.demandmatchconfigurationid,
				Limit:10,
		      Page:1
			},		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Demand Match found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_DEMAND_MATCH,
				data: addnewkey || []
			})

			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const GET_NOTIFICATION_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/getnotificationmaster`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Notification found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_NOTIFICATION_MASTER,
				data: addnewkey || []
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_NOTIFICATION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_timetypetemplatemapping`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const notificationId = item?.notificationid;
				return { success: true, notificationId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_ROLES_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/get_rolemaster`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Role found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_ROLES_MASTER,
				data: addnewkey || []
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_ROLE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/create_rolemenupermissions`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const roleId = item?.roleid;
				return { success: true, roleId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_MENU_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/getmodulemenupermissionlist`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Role found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_MENU_MASTER,
				data: addnewkey || []
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const GET_ROLE_BY_ID = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_rolemenupermissions`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				{
					...data,
					Tenantid: Number(localStorage.getItem('Tenantid') || 0),
				}
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (items === null) {
					AlertError('No Role Found!')
					return { success: false, rolemenus: [] }
				}
				const rolemenus = items[0].rolemenus;
				const sp = [];
				rolemenus[0].rolemenupermissions.forEach(permission => {
					sp.push(permission.rolemenupermissionsid);
				});
				dispatch({ type: ACTION_GET_ROLE_BY_ID, data: items })
				return { success: true, rolemenus };
			}
		} catch (error) {
			const errorMessage = error?.response?.data.replace(/\\/g, '')
			AlertError(errorMessage)
			return { success: false, rolemenus: [] }
		}
	}
}