import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
	GET_LEVEL_MASTER,
	GET_SKILLS_EMPLOYEE,
	GET_TECHNOLOGY_MASTER,
	GET_INSUDTRY_MASTER,
	INSERT_SKILL,
	GET_SKILLAREA_MASTER,
	GET_SKILL_MASTER,
} from 'src/Redux/actions/workforce/eprofile'
import Table from './table'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import {
	getDeafultSkillAreaData,
	getDeafultProficiencyData,
	getDeafultIndustryData,
	getDeafultTechnologyData,
	getDeafultSkillData,
} from 'src/Services/constant/defaultPayload'
import { AlertError } from 'src/Services'

const SkillPortfolio = ({ canEdit, setCanEdit }) => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const { userSkillsDetail } = EmployeeProfile
	const [formData, setFormData] = useState([])
	const [notValidated, setNotValidated] = useState(null)

	useEffect(() => {
		dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
		dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()))
		dispatch(GET_TECHNOLOGY_MASTER(getDeafultTechnologyData()))
		dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()))
		dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData()))
		dispatch(GET_SKILLS_EMPLOYEE())
	}, [dispatch])

	useEffect(() => {
		setFormData(userSkillsDetail)
	}, [userSkillsDetail])

	const HandleSubmit = async () => {
		for (let item of formData) {
			let { skillType } = item
			if (!item?.skillareamasterid) {
				AlertError(`Skill Area is required ! `)
				return
			} else if (!item?.skillmasterid) {
				AlertError(`Skill is required !`)
				return
			} else if (!item?.proficiencyid) {
				AlertError(`Proficiency is required for ${item?.skillname || 'All Skill'}! `)
				return
			} else if (!item?.lastusedyear) {
				AlertError(`Last used is required !`)
				return
			} else if (!item?.expertise?.trim()) {
				AlertError(`Expertise is required for ${item?.skillname || 'All Skill'}! `)
				return
			} else if (skillType === 'Technical') {
				if (!item?.technologymasterid) {
					AlertError(`Technology is required for ${item['skillname']}! `)
					return
				}
			} else if (skillType === "Domain") {
				if (!item?.domainid) {
					AlertError(`Domain is required for ${item['skillname']}! `)
					return
				}
			}
		}
		setNotValidated(null)
		let res = await dispatch(INSERT_SKILL(formData))
		if (res !== null) {
			await dispatch(GET_SKILLS_EMPLOYEE())
			setCanEdit(false)
		}
	}

	return (
		<div className='h-screen my-2 text-sm'>
			<div className='overflow-scroll min-h-max'>
				<Table
					notValidated={notValidated}
					canEdit={canEdit}
					formData={formData}
					setFormData={setFormData}
				/>
			</div>
			{canEdit ? (
				<div className='fixed mb-4 mr-4 bottom-2 right-6'>
					<div className='flex items-center justify-end gap-2'>
						<button
							onClick={() => {
								setCanEdit(false)
								dispatch(GET_SKILLS_EMPLOYEE())
							}}
							className='px-2 py-1 border rounded border-primary text-primary h-9'
						>
							Cancel
						</button>
						<PrimaryButton
							className='py-2 text-sm text-white rounded-md bg-primary'
							onClick={HandleSubmit}
						>
							Save
						</PrimaryButton>
					</div>
				</div>
			) : (
				''
			)}
		</div>
	)
}

export default SkillPortfolio
