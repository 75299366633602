
import { useState, useRef, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { GET_PROJECTS_EPROFILE, SAVE_NOTES_OF_PROJECT_STAFFING } from 'src/Redux/actions/workforce/eprofile';

function SingleSelectField({ props }) {
    const dispatch = useDispatch()
    const { notes, projectstaffid, userId, tenid, eprofilePermission } = props
    const [summary, setSummary] = useState('')
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSummary(notes)
    }, [notes])

    const handleCancel = () => {
        setOpen(false)
        setSummary(notes)
    }

    const saveNoteByStaffing = async () => {
        let payload = {
            Tenantid: tenid,
            projectstaffid,
            notes: summary
        }
        let res = await dispatch(SAVE_NOTES_OF_PROJECT_STAFFING(payload))
        if (res?.success) {
            setOpen(false)
            dispatch(GET_PROJECTS_EPROFILE({ comuserid: +userId, Tenantid: +tenid }))
        }

    }

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative ">
                <div role='button' onClick={() => setOpen(!open)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                    </svg>
                </div>
            </div>
            {open && <div className="p-2 transition right-0 z-[1000] ease-in duration-100 absolute mt-1 w-96 min-w-[90px] rounded-sm bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className='border rounded-md bg-gray-50'>
                    <textarea rows={6}
                        placeholder='Describe your role in this project not exceeding 500 characters'
                        maxLength={500}
                        className=' placeholder:text-xs placeholder:italic w-full bg-gray-50 outline-none rounded-md p-1 '
                        value={summary || ''} name='notes' onChange={(e) => setSummary(e.target.value)} />
                </div>
                {eprofilePermission.includes("Add/Edit Profile") && <div className='flex items-center justify-end gap-2'>
                    <button
                        onClick={handleCancel}
                        className='p-0.5 px-2 border rounded border-primary text-primary'
                    >
                        Cancel
                    </button>
                    <PrimaryButton
                        onClick={saveNoteByStaffing}
                        // disabled={cvSaving || !pdfUrl}
                        className='py-1 text-sm text-white rounded-md disabled:bg-primary/20 bg-primary'
                    >
                        Save
                    </PrimaryButton>
                </div>}
            </div>}
        </div>
    )
}


const NotePopup = (props) => {

    return (
        <div className=' w-full'>
            <SingleSelectField props={props} />
        </div>
    )
}

export default memo(NotePopup);
