import { useDispatch } from 'react-redux'
import { GET_USER_LEAVE_DATA } from 'src/Redux/actions/projects/financials'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import SelectInput from 'src/Components/SelectInput'
import { basicShortList } from 'src/Services/constant/staticValues'
import EditLeaves from './editLeaves'
import UploadLeave from './uploadLeave'
import DownloadAsExcel from './downloadLeave'

const rows = [10, 25, 50]
const cols = [
    'Employee Name',
    'Employee Code',
    'Leave Start Date',
    'Leave End Date',
    'Leave Type',
    'Leave Status',
    'No. Of Days',
]
const Leavetable = ({ hooks }) => {
    const {
        getUserLeave,
        loading,
        filters,
        isOpen,
        setIsOpen,
        userLeave,
        setUserLeave,
        setLeaveId,
        sortType,
        setSortType,
        page,
        setPage,
        setLimit,
        limit,
        getDefaultLeaveData,
        addEditPermission,
    } = hooks
    const dispatch = useDispatch()
    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_USER_LEAVE_DATA({
                ...filters,
                sorttype: item?.id,
                limit: limit,
                page: page,
            }),
        )
    }
    const handlePagination = async (page) => {
        dispatch(
            GET_USER_LEAVE_DATA({
                ...filters,
                limit: limit,
                page: page?.selected + 1,
                sorttype: sortType?.id,
            }),
        )

        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_USER_LEAVE_DATA({
                ...filters,
                limit: row,
                page: row > 10 ? 1 : page,
                sorttype: sortType?.id,
            }),
        )
    }

    const handleComClick = (com) => {
        setUserLeave(com)
        setLeaveId(com?.userpayid)
        setIsOpen(true)
    }

    return (
        <div className="p-2 h-[66vh]">
            <div className="flex items-center justify-between py-4">
                <div className="flex items-center justify-start space-x-4">
                    <p className="text-primary font-[500] inline-flex justify-start items-center ">
                        {' '}
                        Leaves
                        <span>
                            (
                            {getUserLeave?.length > 0
                                ? getUserLeave[0]?.totalcount
                                : getUserLeave?.length}
                            )
                        </span>
                    </p>
                    <ReactPaginationStyle
                        total={
                            getUserLeave?.length > 0
                                ? getUserLeave[0]?.totalcount
                                : getUserLeave?.length
                        }
                        currentPage={page}
                        handlePagination={handlePagination}
                        limit={limit}
                    />
                    <div className="w-32 ">
                        <FilterButtonTab
                            setSelectedtab={handleRows}
                            selectedTab={limit}
                            tabs={rows}
                        />
                    </div>
                    <div>
                        <div className="w-32 ">
                            <SelectInput
                                options={basicShortList || []}
                                keyname="label"
                                selected={sortType}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                </div>
                {addEditPermission ? (
                    <div className="flex justify-end w-full space-x-2">
                        <UploadLeave
                            getDefaultLeaveData={getDefaultLeaveData}
                            filters={filters}
                        />
                        <DownloadAsExcel fileName="getUserLeave" apiData={[]} />
                        {/* DONT REMOVE THIS */}
                        {/* <AddLeaves
						getDefaultLeaveData={getDefaultLeaveData}
						userLeave={userLeave}
						setUserLeave={setUserLeave}
					/> */}
                    </div>
                ) : (
                    ''
                )}
            </div>
            {loading ? (
                <div className="flex items-center justify-center w-full h-[56vh]">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[56vh] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {cols?.map((col) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="font-[500] px-2 py-2 border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {getUserLeave?.map((item, i) => {
                                const {
                                    username,
                                    leavestartdate,
                                    leaveenddate,
                                    leavetypename,
                                    uId,
                                    employeecode,
                                    leavestatus,
                                    noofdays
                                } = item
                                return (
                                    <tr
                                        key={uId}
                                        onClick={() => handleComClick(item)}
                                        className="text-left cursor-pointer"
                                    >
                                        <td className="px-2 py-1 border">{username}</td>
                                        <td className="px-2 py-1 border">
                                            {employeecode}
                                        </td>
                                        <td className="px-2 py-1 border">
                                            <div className="w-36">{leavestartdate}</div>
                                        </td>
                                        <td className="px-2 py-1 border">
                                            {leaveenddate}
                                        </td>
                                        <td className="px-2 py-1 border">
                                            {leavetypename}
                                        </td>
                                        <td className="px-2 py-2 border">
                                            {leavestatus}
                                        </td>
                                        <td className="px-2 py-2 border">
                                            {noofdays}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div>
                        <EditLeaves
                            hooks={{
                                addEditPermission,
                                getUserLeave,
                                setUserLeave,
                                userLeave,
                                setLeaveId,
                                isOpen,
                                setIsOpen,
                                getDefaultLeaveData,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Leavetable
