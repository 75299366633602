import moment from "moment";
import AddStaff from "./addStaff";
import CustomerRole from "./customerRole";
import { defaultItem } from './defaultdata'
import IconButton from "src/Components/Buttons/IconButton";
import SelectStatus from "./selectStatus";
import { AlertError, getStaffingIdByNameOfStatus, isfuturedate } from "src/Services";
import { memo, useState } from "react";
import AddDemand from "./addDemand";
import RaiseSr from "./raiseSr";
import AddStaffModal from "./addStaff/modal";
import EditStaffBtn from "./addStaff/editStaff";
import EditStaff from "./edit-staff";
import ScrollToBottom from 'react-scroll-to-bottom';
import { useSelector } from "react-redux";
import CancelDemand from "./cancelDemand";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const cols = ["Project Role *", "Level", "Country", "Billable", "Start date", "End date", "Allocation", "Staff Name", "Staff Id", "Staff Type", "Staffing Status", "Demand ID", "Demand Status"]

const StaffingTable = ({ hooksValue }) => {
    const [selectedProject, setSelectedProject] = useState(null)
    const [selectedStaff, setSelectedStaff] = useState(null)
    const [editStaff, setEditSatff] = useState(null)
    const [demandStaff, setDemandStaff] = useState(null)
    const listOfProjectStaffing = useSelector(state => state?.ProjectStaffing?.listOfProjectStaffing)
    const { staffingData, setstaffingData, getListOfProjectRole, validation, canEdit, statusListByType,
        thisPagePermissions, project, saveStaffing, loading, setWarning, limit, page } = hooksValue

    // Staffing Status get id by status name
    let staffingA = getStaffingIdByNameOfStatus(statusListByType, "Staffing Approval")
    let ReleaseA = getStaffingIdByNameOfStatus(statusListByType, "Release Approval")
    let extensionA = getStaffingIdByNameOfStatus(statusListByType, "Extension Approval")
    let pendingFullFill = getStaffingIdByNameOfStatus(statusListByType, "Pending Fulfilment")
    let statusRejected = getStaffingIdByNameOfStatus(statusListByType, "Rejected")
    let statusStaffed = getStaffingIdByNameOfStatus(statusListByType, "Staffed")

    const handleInputChange = (event, id) => {
        const { name, value } = event.target
        const newroles = staffingData.map((item) => {
            if (item?.uId === id) {
                if (item?.employeecode) {
                    let pendDate = moment(project?.enddate).format('YYYY/MM/DD')
                    if (pendDate < value && name === 'releasedate') {
                        // if release date is bigger than end date then pass  extention approval status id ( 29 )
                        item['StaffingStatusId'] = extensionA //  29
                    } else if (pendDate > value && name === 'releasedate') {
                        // if release date is less than end date then pass  extention approval status id ( 28 )
                        item['StaffingStatusId'] = ReleaseA // 28
                    } else {
                        // Staffing Approval if there is a staff and user trying to edit info
                        item['StaffingStatusId'] = staffingA // 24
                    }
                }
                if (name === 'releasedate') {
                    item['notes'] = '';
                }
                item[name] = value;
            }
            return item;
        });
        setstaffingData(newroles);
    };

    const selectCustomer = (e, uId) => {
        let { value, customer } = e.target

        let already = staffingData?.filter(it => it?.projroleid === value && it?.statffingstatus !== "DeStaffed")
        if (already?.length < customer?.peopleno) {
            let afterChange = staffingData?.map((item) => {
                if (item?.uId === uId) {
                    let enddate = moment(customer?.enddate).format('YYYY-MM-DD')
                    item['projectstaffid'] = null
                    item['levelname'] = customer?.levelname
                    item['projroleid'] = customer?.projectroleid
                    item['countryid'] = customer?.countryid
                    item['countryname'] = customer?.countryname
                    item['billable'] = customer?.billable || false
                    item['startdate'] = moment(customer?.startdate).format('YYYY-MM-DD')
                    item['enddate'] = enddate
                    item['allocation'] = customer?.allocation //(customer?.allocation / 100).toFixed(2)
                    if (item?.projectstaffid === null && enddate < moment().format('YYYY-MM-DD')) {
                        setWarning(true)
                    } else {
                        setWarning(false)
                    }
                }
                return item
            })
            setstaffingData(afterChange)
        } else {
            AlertError(`This role has a staffing limit of ${customer?.peopleno} resources. `)
        }
    }

    const handleAddRow = () => {
        let staffingId = statusListByType?.find(i => i?.statuscode === "SPENDFUL")?.statusid
        setstaffingData([...staffingData, defaultItem(staffingId)]);
    };

    const handleDeleteRow = (id) => {
        if (staffingData?.length > 1) {
            let rm = staffingData?.filter((item) => item?.uId !== id)
            let rmwraning = rm?.filter((item) => (item?.projectstaffid === null && item?.enddate < moment().format('YYYY-MM-DD')))
            if (rmwraning?.length === 0) {
                setWarning(false)
            }
            setstaffingData(rm)
        }
    };

    const handleRaiseSr = (item) => {
        setSelectedProject(project)
        setDemandStaff(item)
    }

    const hideEditButton = (status) => {
        // don't show edit button if pass below condition
        if (status === staffingA || status === ReleaseA || status === extensionA) {
            return true
        }
        return false
    }

    const isCurrentDateOrFutureDate = (it) => {
        if (isfuturedate(it?.enddate) || moment(it?.enddate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            return true
        }
        return false
    }

    return (
        <ScrollToBottom className="rounded-lg h-[50vh] text-sm">
            <table className='relative w-full text-sm'>
                <thead>
                    <tr className="text-left capitalize bg-gray-50">
                        {cols?.map((col, i) => {
                            return (
                                <th key={col} title={col} scope="col" className="font-[500] px-2 py-2 border text-start">
                                    {col.replace('*', '')}
                                    {i === 0 ? <span className='text-red-300'> *</span> : ''}
                                </th>
                            )
                        })}
                        {(project?.statusname === 'Execution' && project?.projecttype !== 'Bench') && <th scope="col" className="font-[500] px-2 py-2 border text-start">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {staffingData?.length > 0 &&
                        staffingData?.map((item, i) => {
                            let { StaffingStatusId, staffingstatusid, uId, demandcode, demandstatus, projroleid, countryname, billable, startdate,
                                enddate, allocation, employeecode, workertype, username, levelname } = item
                            let staffStatusID = StaffingStatusId || staffingstatusid

                            return (
                                <tr key={uId || staffStatusID || i} className="text-sm text-left">
                                    <td className={`border w-[140px]`}>
                                        <CustomerRole
                                            hooksValue={{ uId, getListOfProjectRole, selectCustomer }}
                                            value={projroleid}
                                            canEdit={canEdit} />
                                    </td>
                                    <td className='px-2 border'>
                                        {levelname}
                                    </td>
                                    <td className='px-2 border'>
                                        <div className="w-[80px]">
                                            {countryname}
                                        </div>
                                    </td>
                                    <td className='px-2 border'>
                                        {billable ? 'Yes' : 'No'}
                                    </td>
                                    <td className={` border px-2 ${validation?.rowId === i ? 'border-red-400' : ''}`}>
                                        <div className="w-[86px]">{startdate ? moment(startdate).format('DD-MM-YYYY') : ''}</div>
                                    </td>
                                    <td className={` border px-2 ${validation?.rowId === i ? 'border-red-400' : ''}`}>
                                        <div className="w-[86px]">{enddate ? moment(enddate).format('DD-MM-YYYY') : ''}</div>
                                    </td>
                                    <td className='px-2 border'>
                                        {Number(allocation || 0).toFixed(0)} %
                                    </td>
                                    <td className='px-2 border'>
                                        <div className="w-[100px]">
                                            {username}
                                        </div>
                                    </td>
                                    <td className='px-2 border'>
                                        {employeecode}
                                    </td>
                                    <td className='px-2 border'>
                                        {workertype}
                                    </td>
                                    <td className='px-2 border'>
                                        {(StaffingStatusId || staffingstatusid) ? <SelectStatus
                                            hooksValue={{ uId, statusListByType, handleInputChange }}
                                            value={StaffingStatusId || staffingstatusid}
                                            canEdit={false} /> : ''}
                                    </td>
                                    <td className='px-2 border' >{demandcode}</td>
                                    <td className='px-2 border' >{demandstatus}</td>
                                    {project?.statusname === 'Execution' && project?.projecttype !== 'Bench' && <>
                                        {canEdit ? <td className="px-1 py-0 border">
                                            <div className='flex items-center justify-end'>
                                                {
                                                    item?.localItem && <>
                                                        {staffingData?.length !== 1 && <IconButton title="Delete Role" onClick={() =>
                                                            handleDeleteRow(uId)
                                                        }
                                                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                            <MinusIcon className="w-3" />
                                                        </IconButton>}
                                                    </>
                                                }

                                                {staffingData?.length - 1 === i && <IconButton title="Add Role" onClick={handleAddRow} className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                    <PlusIcon className="w-3" />
                                                </IconButton>}
                                            </div>
                                        </td> : <td className="px-2 border">
                                            <>
                                                {projroleid !== 0 && <div className="flex items-center justify-start gap-1">
                                                    <>
                                                        {((staffStatusID === pendingFullFill || staffStatusID === statusRejected)) ? <>
                                                            {(item?.demandid === null || item?.demandstatuscode === 'WCANCELD') && <>
                                                                {thisPagePermissions.includes("Add/Edit Staffing") ? <AddStaff
                                                                    setSelectedStaff={setSelectedStaff}
                                                                    item={item}
                                                                /> : ''}
                                                            </>}
                                                            {(item?.demandstatuscode !== 'WCANCELD' && thisPagePermissions.includes("Add/Edit Demand")) ? <RaiseSr handleRaiseSr={handleRaiseSr} item={item} /> : ''}

                                                            {((item?.demandstatuscode !== 'WCANCELD' && item?.demandid) && thisPagePermissions.includes("Add/Edit Demand")) ? <CancelDemand limit={limit} page={page} project={project} demandData={item} /> : ''}
                                                        </> : <>
                                                            {hideEditButton(staffingstatusid) ? '' :
                                                                <>
                                                                    {(staffStatusID === statusStaffed && isCurrentDateOrFutureDate(item)) && <>
                                                                        {thisPagePermissions.includes("Add/Edit Staffing") ? <EditStaffBtn setEditSatff={setEditSatff} item={item} /> : ''}
                                                                    </>}
                                                                </>
                                                            }
                                                        </>}
                                                    </>
                                                </div>}
                                            </>
                                        </td>}
                                    </>}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {listOfProjectStaffing === null && <div className='flex items-center justify-center w-full h-1/2'>
                <div
                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div>}
            {demandStaff !== null && <AddDemand
                hooks={{
                    setstaffingData,
                    saveStaffing,
                    selectedProject,
                    staffingData,
                    demandStaff,
                    setDemandStaff
                }}
            />}
            <AddStaffModal
                hooks={{
                    page, limit,
                    setstaffingData,
                    setSelectedStaff,
                    selectedStaff,
                    handleInputChange,
                    getListOfProjectRole,
                    selectCustomer, saveStaffing,
                    loading, project,
                    staffingData,
                    statusListByType
                }} />
            <EditStaff
                hooksValue={{
                    page, limit,
                    setstaffingData,
                    setEditSatff,
                    editStaff,
                    handleInputChange,
                    getListOfProjectRole,
                    selectCustomer, saveStaffing,
                    loading, project,
                    staffingData,
                }} />
        </ScrollToBottom>
    )
}

export default memo(StaffingTable);