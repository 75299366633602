export const CategoryItem = () => {
  return {
    timecategorymasterid: null,
    timecategory: "",
    companyid: 0,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
    isdefault: false,
  }
};

export const TemplateItem = () => {
  return {
    timetemplatemasterid: null,
    templatename: "",
    templatetype: "",
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    companyid: 0,
    isdefault: false,
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    allocationrestriction: null,
    validationunit:-1,
    notes: ""
  }
};

export const RulesItem = () => {
  return {
    timetypetemplatemappingid: null,
    timecategorymasterid: 0,
    timetemplatemasterid: 0,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    companyid: 0,
    comcountryid: 0,
    workertypeid: 0,
    validationunit:1,
    weekendentryallowed: false,
    holidayallowed: false,
    isactiveflag: true,
    mindailylimit: 0,
    maxdailylimit: 0,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
    allocationrestriction: false,
    timeentryformat: "",
    timetypeuniqueness: "",
    selfattestation: false,
    holidaycalendarintegration: "",
    weekdayentryallowed: false
  }
}






