const getDefaultDemandPayload = () => {
    return {
        userid: JSON.parse(localStorage.getItem('comuserid')),
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        cityid: 0,
        billabletype: '',
        assignedto: 0,
        skillid: 0,
        technologyid: 0,
        priority: '',
        requesttype: '',
        companyid: 0,
        role: '',
        requesteddate: '',
        startdate: '',
        Limit: 10,
        Page: 1,
        Sorttype: 3,
        Searchname: '',
        opportunityid: 0,
        projectid: 0,
        departmentid: 0,
        statusid: 0,
        workertypeid: 0,
        workforcegroupid: 0,
        minbandid: 0,
        maxbandid: 0,
        mintotalexperience: 0,
    }
}

const getDemandMasterPayload = (Demandid) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        COMUserId: 0,
        Demandid,
        Limit: 25,
        Page: 1,
        Sorttype: 3,
        Searchname: '',
    }
}

export { getDefaultDemandPayload, getDemandMasterPayload }
