import { Dialog, Tab, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import IconButton from 'src/Components/Buttons/IconButton'
import { SAVE_DEMAND_LIST_MASTER } from 'src/Redux/actions/workforce/demands'
import { createDemandDetailsInfo } from './demanddetails/demandDetailsItem'
import Details from './demanddetails/details'
import RoleDetails from './roleDetails/roleDetails'
import { basePayload, RoleItemSkeleton } from './roleDetails/roleDetailsItem'
import { insertDemandSkillExpertise } from './skill&Experties/defaultItems'
import SkillExperties from './skill&Experties/skill&Experties'
const tabslist = ['Demand Details', 'Role Details', 'Skills & Expertise']

const CreateDemand = ({ getDefaultDemandData }) => {
	const [payload, setPayload] = useState(createDemandDetailsInfo())
	const [roleData, setRoleData] = useState(
		RoleItemSkeleton()?.roleresponsibilities,
	)
	const [band, setBand] = useState(basePayload())
	const dispatch = useDispatch()
	let [isOpen, setIsOpen] = useState(false)
	const [formData, setFormData] = useState([insertDemandSkillExpertise()])
	const [businessUnit, setBusinessUnit] = useState({})
	const [serviceLine, setServiceLine] = useState({})
	const [workmode, setWorkmode] = useState({})
	const [worktype, setWorktype] = useState({})
	const [location, setLocation] = useState({})
	const [opportunity, setOpportunity] = useState({})
	const [department, setDepartment] = useState({})
	const [project, setProject] = useState({})
	const [demandid, setDemandid] = useState(0)
	const [activeTabs, setActiveTabs] = useState('Demand Details')
	const [tabCanAccess, setTabCanAccess] = useState(['Demand Details'])

	function closeModal() {
		setIsOpen(false)
		setActiveTabs('Demand Details')
	}

	function openModal() {
		setIsOpen(true)
	}

	async function detailSave(payload) {
		let res = await dispatch(SAVE_DEMAND_LIST_MASTER(payload))
		if (res !== null) {
			setDemandid(res?.demandid)
			setActiveTabs('Role Details')
			setTabCanAccess(['Demand Details', 'Role Details', 'Skills & Expertise'])
			getDefaultDemandData()
		}
	}

	return (
		<>
			<IconButton
				title='Create Demand'
				onClick={openModal}
				className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth={1.5}
					stroke='currentColor'
					className='w-5 h-5'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M12 4.5v15m7.5-7.5h-15'
					/>
				</svg>
			</IconButton>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-20' onClose={openModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex items-center justify-center min-h-full p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Tab.Group>
										<div className='flex items-center justify-between w-full border-b border-secondary '>
											<h3 className=' text-lg font-[500] leading-6 text-primary'>
												Create Demand
											</h3>

											<Tab.List className='flex justify-start p-1 space-x-2 rounded-lg'>
												{tabslist?.map((item, i) => {
													return (
														<Tab
															key={i}
															disabled={!tabCanAccess.includes(item)}
															onClick={() => setActiveTabs(item)}
															className={`py-1 rounded-md text-lg outline-white ${item === activeTabs
																? 'bg-secondary/10 text-secondary font-[500]'
																: 'text-gray-500'
																}`}
														>
															<span className='px-2'> {item}</span>
														</Tab>
													)
												})}
											</Tab.List>

											<button
												onClick={closeModal}
												type='button'
												className='text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													strokeWidth={1.5}
													stroke='currentColor'
													className='w-4 h-4'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														d='M6 18L18 6M6 6l12 12'
													/>
												</svg>
												<span className='sr-only'>Close icon</span>
											</button>

										</div>
										<div className='w-full h-[70vh]'>
											{activeTabs === 'Demand Details' && (
												<>
													<Details
														hooks={{
															payload,
															detailSave,
															opportunity,
															setOpportunity,
															department,
															setDepartment,
															setPayload,
															businessUnit,
															setBusinessUnit,
															serviceLine,
															setServiceLine,
															workmode,
															setWorkmode,
															location,
															setLocation,
															worktype,
															setWorktype,
															project,
															setProject,
														}}
													/>
												</>
											)}
											{activeTabs === 'Role Details' && (
												<>
													<RoleDetails
														hooks={{
															band,
															setBand,
															roleData,
															setRoleData,
															getDefaultDemandData,
															demandid,
															setDemandid,
															setActiveTabs,
														}}
													/>
												</>
											)}
											{activeTabs === 'Skills & Expertise' && (
												<>
													<SkillExperties
														hooks={{
															getDefaultDemandData,
															formData,
															setFormData,
															demandid,
															setDemandid,
														}}
													/>
												</>
											)}
										</div>
									</Tab.Group>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default CreateDemand
