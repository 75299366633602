import ReportsFilter from 'src/Filter/reports/workforce/releasePlan'
import {memo, useEffect, useState} from 'react'
import ReportList from './table'
import {
	getCityPayload,
	getDeafultIndustryData,
	getDeafultSkillData,
	getDefaultStatusPayload,
	getDesignationMasterIdData,
	getReleasePlanReportsPayload,
	getStatusMasterIdData,
	getTenantCompanyIdData,
	getTenantWorkForceyIdData,
	getTenantWorkerTypeyIdData,
} from 'src/Services/constant/defaultPayload'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {
	GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
	GET_CITY_MASTER,
	GET_COMPANY_MASTER,
	GET_DESIGNATION_MASTER,
	GET_WORKERTYPE_MASTER,
	GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import {
	GET_INSUDTRY_MASTER,
	GET_SKILL_MASTER,
	GET_TECHNOLOGY_MASTER,
} from 'src/Redux/actions/workforce/eprofile'
import {
	GET_RELEASE_PLAN_REPORT,
	GET_REPORT_MASTER_LIST,
} from 'src/Redux/actions/reports'
import {GET_SERVICELINE_MASTER} from 'src/Redux/actions/masterdata/user'
import {getMasterServiceLinePayload, getMasterTechPayload} from 'src/Services/constant/masterdatapayload'
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'

const ReleasePlanDetails = ({setReportId, reportId}) => {
	const [filters, setFilters] = useState(getReleasePlanReportsPayload())
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const dispatch = useDispatch()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const datmenuId = searchParams.get('datmenuid')
	const { reportMasterList} = useSelector( state => state?.WorkforceReport, )
	useEffect(() => {
		dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
	}, [datmenuId, dispatch])

	useEffect(() => {
		dispatch(GET_CITY_MASTER(getCityPayload()))
		dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
		dispatch(GET_RELEASE_PLAN_REPORT(getReleasePlanReportsPayload()))
		dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()))
		dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
		dispatch(GET_DESIGNATION_MASTER(getDesignationMasterIdData()))
		dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))
		dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()))
		dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
		dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
		dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E')))
        dispatch(GET_TIMESHEET_PROJECTS_M())
 		dispatch(GET_TECHNOLOGY_MASTER(getMasterTechPayload()))
	}, [dispatch])

	return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <ReportsFilter
                    reportId={reportId}
                    setReportId={setReportId}
                    setFilters={setFilters}
                    filters={filters}
                    page={page}
                    limit={limit}
                />
            </div>
            <ReportList
                filters={filters}
                reportMasterList={reportMasterList}
                reportId={reportId}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(ReleasePlanDetails)
