import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import StaffingReportsFilter from 'src/Filter/reports/workforce/staffingDetailReport'
import { GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import {
    GET_LIST_OF_STATUS,
    GET_LIST_TYPE_OF_PROJECT,
    GET_PROJECT_LIST_BILLING_TYPE,
} from 'src/Redux/actions/projects'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import {
    GET_REPORT_MASTER_LIST,
    GET_STAFFING_DETAILS_REPORT,
} from 'src/Redux/actions/reports'
import { GET_ALLOCATION_STATUS_FOR_EMPLOYEE } from 'src/Redux/actions/workforce/talent'
import {
    getDefaultBillingIdPayload,
    getDefaultProjectTypePayload,
    getDefaultStatusPayload,
    getStaffingReportsPayload,
    getStatusMasterIdData,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import StaffingReportList from './table'

const StaffingDetails = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getStaffingReportsPayload())
    const dispatch = useDispatch()
    const [staffing, setStaffing] = useState([])
	const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    const [project, setProject] = useState([])
    let { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    const projectIds = projectsOfM?.map((project) => project.projectid)
    const { reportMasterList } = useSelector((state) => state?.WorkforceReport)

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    useEffect(() => {
        dispatch(GET_STAFFING_DETAILS_REPORT(getStaffingReportsPayload()))
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('P')))
        dispatch(GET_LIST_TYPE_OF_PROJECT(getDefaultProjectTypePayload(0)))
        dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
        dispatch(GET_TIMESHEET_PROJECTS_M())
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('S')))
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <StaffingReportsFilter
                    setReportId={setReportId}
                    reportId={reportId}
                    setFilters={setFilters}
                    filters={filters}
                    setProject={setProject}
                    project={project}
                    setStaffing={setStaffing}
                    staffing={staffing}
                    page={page}
                    limit={limit}
                />
            </div>
            <StaffingReportList
                filters={filters}
                projectIds={projectIds}
                staffing={staffing}
                reportId={reportId}
                setStaffing={setStaffing}
                reportMasterList={reportMasterList}
                projectsOfM={projectsOfM}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(StaffingDetails)
