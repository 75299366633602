import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import SelectAccountManager from './selectAccountManager';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../default';

const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    accountSelect,
    setAccountSelect,
  } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let item = {
      ...detailsData,
      customergroupid: detailsData?.customergroupid,
      accountmanagerid: parseFloat(accountSelect?.comuserid),
    };
    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        customerjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, accountmanagerid: select.comuserid });
    setCheckvalidation(false);

  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='customergroupname' className='block font-[500] mb-2'>
            Customer Group Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='customergroupname'
            id='customergroupname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Customer Group Name'
            required=''
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('customergroupname', e.target.value)
              }
            }}
            maxLength={50}
          />
          {!detailsData?.customergroupname && checkvalidation && (
            <small className='text-red-400'>Customer Group Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='accountmanagername' className='block font-[500] mb-2 mt-0.4'> Account Manager Name
            <span className="text-red-300"> *</span>
          </label>
          <div className='relative'>
            <SelectAccountManager
              accountSelect={accountSelect}
              handleSelectManager={handleSelectManager}
              setAccountSelect={setAccountSelect}
            />
            {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>Account Manager Name is required!</small>
            )}
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            type=''
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
