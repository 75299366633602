
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import { store } from './Redux/storeConfig/store'
import './index.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import authConfig from './authConfig';

const msalInstance = new PublicClientApplication(authConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <Routes />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);