export const TaskItem = (taskgroupid, tasktypeid) => {
	return {
		taskid: null,
		taskgroupid,
		taskdesc: '',
		tasktypeid,
		comtenantid: JSON.parse(localStorage.getItem('Tenantid')) ,
		isactiveflag: true,
		createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
		notes: null,
	}
}

export const TaskGroupItem = () => {
	return {
		taskgroupid:null,
		taskgroupdesc:"",
		comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
		isactiveflag: true,
		createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
		notes: null,
	}
}

export const detailNotRequired = [
'taskid',
'notes'
 ];
