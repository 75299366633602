import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { GET_CERTIFICATIONS } from 'src/Redux/actions/workforce/eprofile'
import { useLocation } from 'react-router-dom'
import ScrollToBottom from 'react-scroll-to-bottom'

const LearningHistory = ({ talentId }) => {
    const dispatch = useDispatch()
    const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
    const { certifications } = EmployeeProfile
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const tenid = searchParams.get('tenid')

    useEffect(() => {
        dispatch(GET_CERTIFICATIONS({ comuserid: +talentId, Tenantid: +tenid }))
    }, [dispatch, tenid, talentId])

    const isValidCertification = (certification) => {
        return certification.certificationname && certification.certificationdate
    }

    return (
        <div className=" h-[44vh] my-2 text-sm">
            <ScrollToBottom className="h-[48vh]">
                <table className="w-full text-left text-textSecondary">
                    <thead className="capitalize bg-gray-50">
                        <tr className="bg-gray-100 border-b">
                            <th className="border font-[500] px-1 py-1">
                                Course/ Certification
                            </th>
                            <th className="border font-[500] px-1 py-1">Certified By</th>
                            <th className="border font-[500] px-1 py-1">
                                Completed Date
                            </th>
                            <th className="border font-[500] px-1 py-1">Valid Till</th>
                        </tr>
                    </thead>
                    <tbody>
                        {certifications?.map(
                            (item, i) =>
                                isValidCertification(item) && (
                                    <tr key={i} className="bg-white border-b">
                                        <td className="px-1 py-1 border">
                                            {item.certificationname}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            {item.certificationagency}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            {item.certificationdate}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            {item.certificationexpirydate}
                                        </td>
                                    </tr>
                                ),
                        )
                        }

                    </tbody>
                </table>
                {certifications?.length > 0 ? '' : <span className=' text-center '> No certifications available.</span>}
            </ScrollToBottom>
        </div>
    )
}

export default LearningHistory
