const getDefaultTechPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        techgroup: 0,
        technologymasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterTechPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        techgroup: 0,
        technologymasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getBusinessUnitGroupData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        businessunitgroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getBusinessUnitGroupMasterData = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        businessunitgroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getTaskTypePayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
    }
}

const getTaskGroupPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        taskgroupid: 0,
    }
}

const getGenderPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        datcomgenderid: 0,
    }
}

const getDefaultStatusPayload = (
    page = 1,
    limit = 10,
    sorttype = 1,
    statustype = 'T',
) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        page,
        limit,
        sorttype,
        statustype,
    }
}

const getDeafultTechGroupData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        techgroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getTechGroupMasterData = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        techgroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultProductData = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        productmasterid: 0,
    }
}

const getDefaultProductMasterData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        productmasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDeafultSkillAreaData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillareamasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
        servicelineid: 0,
    }
}

const getMasterSkillAreaData = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillareamasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
        servicelineid: 0,
    }
}

const getDeafultSkillData = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillmasterid: 0,
        skillareamasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}
const getMasterSkillData = (page = 1, limit, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillmasterid: 0,
        skillareamasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultCostRates = (Page = 1, Limit = 10, Sorttype = 3, Searchname) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Companyid: 0,
        Limit,
        Page,
        Sorttype,
        Searchname: '',
        Status: 'Active',
        // Skillareamasterid: 0,
        // Countryid: 0,
        // Bandid: 0,
        // Techgroupid: 0
    }
}
const getDeafultRoleData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    }
}

const getTenantCompanyIdData = (page = 1, limit, sorttype, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        sorttype,
        page,
        limit,
        status,
    }
}

const getUserTypeIdData = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        datusertypeid: 0,
    }
}

const getDesignationMasterIdData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        desingationMasterId: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDesignationDefaultIdData = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        desingationMasterId: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getBandMasterIdData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        bandmasterid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDepartmentMasterIdData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        departmentid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'Active',
    }
}

const getAnnouncementMasterIdData = (limit = 10, page) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        announcementid: 0,
        limit,
        page: 1,
    }
}

const getDefaultServiceLinePayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        servicelineid: 0,
        servicelinegroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
        skillareamasterid: 0,
    }
}

const getMasterServiceLinePayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        servicelineid: 0,
        servicelinegroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
        skillareamasterid: 0,
    }
}

const getRoleMasterIdData = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        Roleid: 0,
    }
}

const getTechGroupMasterIdData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        techgroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultCompanyPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterCompanyPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultCompanyHolidayPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        companyholidaysid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultCustomerHolidayPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customerid: 0,
        customerholidaysid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultStatePayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid')),
        comcountryid: 0,
        comstateid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterStatePayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid')),
        comcountryid: 0,
        comstateid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultLocationPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comlocationid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterLocationPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comlocationid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultRegionPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comregionid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterRegionPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comregionid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultCountryPayload = (page, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        comcountryid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterCountryPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        comcountryid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultCityPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comstateid: 0,
        comcityid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterCityPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comstateid: 0,
        comcityid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getUserAccessPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        Roleid: 0,
        companyid: 0,
        datusertypeid: 0,
        userstatus: '',
    }
}

const getBusinessUnitData = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid')) || 0,
        businessunitid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}


const getDefaultServiceLineGroupMasterPayload = (page = 1, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        servicelinegroupid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getDefaultBusinessUnitPayload = (
    page,
    limit = 10,
    sorttype = 3,
    status = 'ACTIVE',
) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        businessunitid: 0,
        page,
        limit,
        sorttype,
        status,
    }
}

const getTaskMaster = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    }
}

const getTaskMasterPayload = (limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        taskgroupid: 0,
        taskid: 0,
        limit,
        page: 1,
        sorttype:3,
        searchname: '',
        status: 'ACTIVE',
        tasktypeid: 0,
        createdcomuserid: 0,
    }
}

const getTaskDefaultPayload = (limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        taskgroupid: 0,
        taskid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
        tasktypeid: 0,
        createdcomuserid: 0,
    }
}

const getDefaultTimeTypePayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        timetypeid: 0,
    }
}

const getDefaultNationalityPayload = (status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        nationalityid: 0,
        status,
    }
}

const getDefaultOverheadsData = (limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        overheadmasterid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
        costtype: '',
    }
}

const getDefaultTimeCategoryPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit,
        page,
        sorttype,
        status: 'ACTIVE',
    }
}

const getMasterCategoryPayload = (page = 1, limit = 10, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit,
        page,
        sorttype,
        status: 'ACTIVE',
    }
}

const getTemplateCategoryPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        isdefault: '',
        limit,
        page,
        sorttype,
        status: 'ACTIVE',
        typefilter: '',
    }
}

const getTemplateMasterCategoryPayload = (page = 1, limit = 1000, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        isdefault: '',
        limit,
        page,
        sorttype,
        status: 'ACTIVE',
    }
}

const getDemandMatchPayload = (Page = 1, Limit = 10) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        demandmatchconfigurationid: 0,
        Limit,
        Page,
    }
}

const getRulesPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        timecategorymasterid: 0,
        timetemplatemasterid: 0,
        companyid: 0,
        countryid: 0,
        workertypeid: 0,
        limit,
        page,
        sorttype,
        status: 'ACTIVE',
    }
}

const getNotificationPayload = (page = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        notificationid: 0,
        limit,
        page,
        sorttype,
        status: 'ACTIVE',
    }
}

const getRolesMasterPayload = (page = 1, limit = 10, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Roleid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMenuMasterPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        DatmodulealiasId: 0,
        DATModuleId: 0,
        Datmenuid: 0,
    }
}

const getRoleByIdPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Roleid: 0,
    }
}

export {
    getRoleByIdPayload,
    getMenuMasterPayload,
    getRolesMasterPayload,
    getNotificationPayload,
    getAnnouncementMasterIdData,
    getDemandMatchPayload,
    getMasterSkillData,
    getTemplateMasterCategoryPayload,
    getRulesPayload,
    getTemplateCategoryPayload,
    getMasterCategoryPayload,
    getDefaultTimeCategoryPayload,
    getBusinessUnitGroupMasterData,
    getMasterCompanyPayload,
    getMasterServiceLinePayload,
    getDefaultCustomerHolidayPayload,
    getDefaultCompanyHolidayPayload,
    getDesignationDefaultIdData,
    getTaskDefaultPayload,
    getDefaultServiceLineGroupMasterPayload,
    getMasterCountryPayload,
    getMasterRegionPayload,
    getTechGroupMasterData,
    getMasterSkillAreaData,
    getMasterTechPayload,
    getMasterCityPayload,
    getMasterLocationPayload,
    getMasterStatePayload,
    getDefaultProductMasterData,
    getDefaultProductData,
    getDefaultOverheadsData,
    getDefaultCostRates,
    getTaskMasterPayload,
    getTaskGroupPayload,
    getTaskTypePayload,
    getDefaultNationalityPayload,
    getDefaultTimeTypePayload,
    getGenderPayload,
    getTaskMaster,
    getUserAccessPayload,
    getDefaultCityPayload,
    getDefaultCountryPayload,
    getDefaultLocationPayload,
    getTechGroupMasterIdData,
    getRoleMasterIdData,
    getDepartmentMasterIdData,
    getBandMasterIdData,
    getDesignationMasterIdData,
    getUserTypeIdData,
    getTenantCompanyIdData,
    getDeafultSkillData,
    getDeafultSkillAreaData,
    getDefaultServiceLinePayload,
    getDeafultTechGroupData,
    getDefaultStatePayload,
    getDefaultRegionPayload,
    getBusinessUnitGroupData,
    getBusinessUnitData,
    getDefaultTechPayload,
    getDefaultStatusPayload,
    getDefaultCompanyPayload,
    getDefaultBusinessUnitPayload,
    getDeafultRoleData,
}
