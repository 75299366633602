import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import drilldown from 'highcharts/modules/drilldown'
import {  useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GET_REVENUE_FOR_DASHBOARD } from 'src/Redux/actions/analytics'

drilldown(Highcharts)

const getBarColor = (index) => {
    const colors = ['#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658']
    return colors[index % colors.length]
}

const BarChartDrillDown = ({ revenueDashboard, filters }) => {
    const dispatch = useDispatch()
    const [chartOptions, setChartOptions] = useState({})
    const [drilldownData, setDrilldownData] = useState([])
    const [reportLevel, setReportLevel] = useState('Region')
    const [selectedIds, setSelectedIds] = useState({ regionId: null, accountId: null, customerId: null })
    const [navigationPath, setNavigationPath] = useState([{ name: 'Revenue', level: 'Region' }])

    const fetchData =
        async (payload) => {
            try {
                const res = await dispatch(GET_REVENUE_FOR_DASHBOARD(payload))
                if (res && res.data) {
                    setDrilldownData(res.data)
                    setChartOptions(generateChartOptions(res.data, payload.Reportlevel))
                }
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

    useEffect(() => {
        if (revenueDashboard) {
            setChartOptions(generateChartOptions(revenueDashboard, reportLevel))
        }
    },
        // eslint-disable-next-line
        [revenueDashboard])

    const handleDrilldown =
        async (event) => {
            const { point } = event

            if (reportLevel === 'Project') {
                return
            }

            let newLevel
            const newSelectedIds = { ...selectedIds }

            if (reportLevel === 'Region') {
                newLevel = 'Account'
                newSelectedIds.regionId = point.drilldown
            } else if (reportLevel === 'Account') {
                newLevel = 'Customer'
                newSelectedIds.accountId = point.drilldown
            } else if (reportLevel === 'Customer') {
                newLevel = 'Project'
                newSelectedIds.customerId = point.drilldown
            }

            const newPayload = buildPayload(newLevel, newSelectedIds)

            setReportLevel(newLevel)
            setNavigationPath((prevPath) => [...prevPath, { name: point.name, level: newLevel }])
            setSelectedIds(newSelectedIds)

            await fetchData(newPayload)
        }

    const handleDrillup = async () => {
        if (navigationPath.length <= 1) {
            return
        }

        const newPath = navigationPath.slice(0, -1)
        const newLevel = newPath[newPath.length - 1]?.level || 'Region'
        const newSelectedIds = { ...selectedIds }

        if (newLevel === 'Region') {
            newSelectedIds.regionId = null
            newSelectedIds.accountId = null
            newSelectedIds.customerId = null
        } else if (newLevel === 'Account') {
            newSelectedIds.accountId = null
            newSelectedIds.customerId = null
        } else if (newLevel === 'Customer') {
            newSelectedIds.customerId = null
        }

        const newPayload = buildPayload(newLevel, newSelectedIds)

        setNavigationPath(newPath)
        setReportLevel(newLevel)
        setSelectedIds(newSelectedIds)

        await fetchData(newPayload)
    }

    const handleBreadcrumbClick =
        async (index) => {
            if (index === navigationPath.length - 1) return

            const newPath = navigationPath.slice(0, index + 1)
            const newLevel = newPath[newPath.length - 1]?.level || 'Region'
            const newSelectedIds = { ...selectedIds }

            if (newLevel === 'Region') {
                newSelectedIds.regionId = null
                newSelectedIds.accountId = null
                newSelectedIds.customerId = null
            } else if (newLevel === 'Account') {
                newSelectedIds.accountId = null
                newSelectedIds.customerId = null
            } else if (newLevel === 'Customer') {
                newSelectedIds.customerId = null
            }

            const newPayload = buildPayload(newLevel, newSelectedIds)

            setNavigationPath(newPath)
            setReportLevel(newLevel)
            setSelectedIds(newSelectedIds)

            await fetchData(newPayload)
        }

    const buildPayload =
        (level, ids) => ({
            ...filters,
            Tenantid: 3,
            Projectid: 0,
            customerid: level === 'Project' ? ids.customerId : 0,
            accountid: level === 'Customer' || level === 'Project' ? ids.accountId : 0,
            regionid: level === 'Account' || level === 'Customer' || level === 'Project' ? ids.regionId : 0,
            Reporttype: 'Revenue',
            Reportlevel: level,
        })

    const generateChartOptions =
        (data, currentReportLevel) => {
            let chartData = []
            let seriesName = currentReportLevel

            if (currentReportLevel === 'Region' && data.regionwise_Revenue && data.regionwise_Revenue.length > 0) {
                chartData = data.regionwise_Revenue.map((item) => ({
                    name: item.regionname,
                    y: item.Revenue,
                    drilldown: item.regionid,
                }))
                seriesName = 'Region'
            } else if (
                currentReportLevel === 'Account' &&
                data.Accountwise_Revenue &&
                data.Accountwise_Revenue.length > 0
            ) {
                chartData = data.Accountwise_Revenue.map((item) => ({
                    name: item.Accountname,
                    y: item.Revenue,
                    drilldown: item.Accountid,
                }))
                seriesName = 'Account'
            } else if (
                currentReportLevel === 'Customer' &&
                data.Customerwise_Revenue &&
                data.Customerwise_Revenue.length > 0
            ) {
                chartData = data.Customerwise_Revenue.map((item) => ({
                    name: item.Customername,
                    y: item.Revenue,
                    drilldown: item.Customerid,
                }))
                seriesName = 'Customer'
            } else if (
                currentReportLevel === 'Project' &&
                data.Projectwise_Revenue &&
                data.Projectwise_Revenue.length > 0
            ) {
                chartData = data.Projectwise_Revenue.map((item) => ({
                    name: item.Projectname,
                    y: item.Revenue,
                    drilldown: null,
                }))
                seriesName = 'Project'
            }

            return {
                chart: {
                    type: 'column',
                    backgroundColor: 'transparent',
                    style: {
                        fontSize: '14px',
                    },
                    events: {
                        drilldown: handleDrilldown,
                        drillup: handleDrillup,
                    },
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: 'Earned Revenue (USD)',
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                    },
                    labels: {
                        formatter: function () {
                            return Highcharts.numberFormat(this.value, 0, '.', ',')
                        },
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                    },
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return Highcharts.numberFormat(this.y, 0, '.', ',')
                            },
                            style: {
                                color: '#6b7280',
                                fontSize: '14px',
                                fontWeight: '500',
                            },
                        },
                    },
                },
                tooltip: {
                    headerFormat:
                        '<span style="font-size:14px;font-weight:500;color:#6b7280">{point.series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.0f}</b><br/>',
                },
                series: [
                    {
                        name: seriesName,
                        colorByPoint: true,
                        data: chartData.map((item, index) => ({
                            ...item,
                            color: getBarColor(index),
                        })),
                    },
                ],
                drilldown: {
                    activeAxisLabelStyle: {
                        textDecoration: 'none',
                        color: '#6b7280',
                        fontWeight: '500',
                    },
                    activeDataLabelStyle: {
                        textDecoration: 'none',
                        color: '#6b7280',
                        fontWeight: '500',
                    },
                    series: drilldownData.map((series) => ({
                        ...series,
                        data: series.data.map((item, index) => ({
                            ...item,
                            y: item.y,
                            color: getBarColor(index),
                        })),
                    })),
                },
            }
        }

    return (
        <div className="relative flex flex-col items-center justify-center w-full h-[55vh]">
            <h2 style={{ color: '#6b7280', fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>
                {/* Revenue Dashboard */}
            </h2>
            <div
                className="subtitle"
                style={{
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                    cursor: 'pointer',
                    marginBottom: '10px',
                }}
            >
                {navigationPath.map((item, index) => (
                    <span key={index} className="highcharts-breadcrumb" onClick={() => handleBreadcrumbClick(index)}>
                        {item.name}
                        {index < navigationPath.length - 1 ? ' / ' : ''}
                    </span>
                ))}
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            // ref={chartRef}
            />
        </div>
    )
}

export default BarChartDrillDown
