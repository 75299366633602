export const TaskItem = () => {
	return {
		taskid: null,
		taskgroupid: 0,
		taskdesc: '',
		tasktypeid: 0,
		comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
		isactiveflag: true,
		createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
		notes: null,
	}
}

export const TaskGroupItem = () => {
	return {
		taskgroupid: null,
		taskgroupdesc: "",
		comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
		isactiveflag: true,
		createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
		notes: null,
	}
}

export const detailNotRequired = [
	'taskid',
	'notes',
	'taskcode',
	'taskgroupid',
	'tasktypeid'

];
