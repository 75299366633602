import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import SelectInput from 'src/Components/SelectInput'
import { GET_BUSINESS_MASTER, GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import { GET_CURRENCY_MASTER_LIST, GET_OPPORTUNITY_LIST, GET_PROJECTS } from 'src/Redux/actions/projects'
import { GET_WORK_MODE_LIST } from 'src/Redux/actions/workforce/demands'
import { GET_SKILLAREA_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
    GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    GET_CITY_MASTER,
    GET_COMPANY_MASTER,
    GET_DEPARTMENT_MASTER,
    GET_WORKERTYPE_MASTER,
    GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import {
    getBusinessUnitData,
    getDeafultSkillAreaData,
    getDefaultCityPayload,
    getDefaultCompanyPayload,
    getDefaultCurrencyPayload,
    getDefaultOpportunityPayload,
    getDefaultProjectPayload,
    getDefaultServiceLinePayload,
    getDepartmentMasterIdData,
    getStatusMasterIdData,
    getTenantWorkForceyIdData,
    getWorkModePayload,
    getWorkTypePayload,
} from 'src/Services/constant/defaultPayload'
import SelectCompany from './company'
import SelectDepartment from './department'
import SelectLocation from './location'
import SelectOpportunity from './opportunity'

const cycleList = [
    { id: 3, cycle: 'Daily' },
    { id: 4, cycle: 'Hourly' },
    { id: 1, cycle: 'Monthly' },
    { id: 2, cycle: 'Weekly' },
]
const requestTypeslist = [
    { requesttype: 'Internal', id: 'I' },
    { requesttype: 'Opportunity', id: 'O' },
]
const prioritiesList = [
    { priority: 'Low', id: 'L' },
    { priority: 'Medium', id: 'M' },
    { priority: 'High', id: 'H' },
]

const Details = ({ hooks }) => {
    const dispatch = useDispatch()
    const {
        detailSave,
        opportunity,
        setOpportunity,
        department,
        setDepartment,
        payload,
        setPayload,
        location,
        setLocation,
    } = hooks
    const { currencyList } = useSelector((state) => state?.Projects)
    const { skillList } = useSelector((state) => state?.EmployeeProfile)
    const { companylist, allocationstatuslist } = useSelector((state) => state?.EmployeeDetails)
    const { businessList, serviceList } = useSelector((state) => state?.UserManagementList)
    const { workertypelist, departmentlist, workforcelist } = useSelector((state) => state?.EmployeeDetails)
    const { workmodelist } = useSelector((state) => state?.DemandDetailsList)
    const { listOfOpportunity } = useSelector((state) => state?.Projects)
    const [checkValidation, setCheckValidation] = useState(false)
    const [skillArea, setSkillArea] = useState(0)
    const currentDate = new Date()
    const futureDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000)
    const minDate = futureDate.toISOString().split('T')[0]
    const [selectedStatus, setSelectedStatus] = useState(null)

    useEffect(() => {
        if (allocationstatuslist) {
            const status = allocationstatuslist?.find((item) => item.statuscode === 'WOPEN')
            if (status) {
                setSelectedStatus(status.statusid)
            }
        }
    }, [allocationstatuslist])

    useEffect(() => {
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_COMPANY_MASTER(getDefaultCompanyPayload()))
        dispatch(GET_BUSINESS_MASTER(getBusinessUnitData()))
        dispatch(GET_CITY_MASTER(getDefaultCityPayload()))
        dispatch(GET_WORKERTYPE_MASTER(getWorkTypePayload()))
        dispatch(GET_WORK_MODE_LIST(getWorkModePayload()))
        dispatch(GET_OPPORTUNITY_LIST(getDefaultOpportunityPayload()))
        dispatch(GET_PROJECTS(getDefaultProjectPayload()))
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('W')))
        dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        if (skillArea?.skillareamasterid) {
            dispatch(GET_SERVICELINE_MASTER(getDefaultServiceLinePayload(skillArea?.skillareamasterid)))
        }
    }, [dispatch, skillArea?.skillareamasterid])

    const handleDates = (e) => {
        let { name, value } = e.target
        setPayload((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }))
    }

    const handleSubmit = async () => {
        setCheckValidation(true)
        let validationdata = {}
        let requiredInDetail = [
            'workertypeid',
            'companyid',
            'requesttype',
            'businessunitid',
            'servicelineid',
            'startdate',
            'datcomcurrencyid',
            'workmodeid',
            'priority',
            'demandstatusid',
            'is_billable',
            'comtenantid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'peopleno',
            'departmentid',
            'isonsite',
            'locationid',
            'workforcegroupid',
        ]
        for (let value of requiredInDetail) {
            validationdata[value] = payload[value]
        }
        validationdata['cityid'] = location.comcityid
        validationdata['opportunityid'] = opportunity?.opportunityid
        validationdata['departmentid'] = department?.departmentid
        let optionalkey = []
        if (payload?.requesttype === 'I') {
            optionalkey = [
                'demandid',
                'allocation',
                'billingtype',
                'opportunityid',
                'billingunit',
                'workmodeid',
                'billrate',
                'isonsite',
                'peopleno',
                'businessunitid',
                'opportunityid',
                'servicelineid',
                'demandcode',
                'billrate',
                'datcomcurrencyid',
                'billingunit',
                'locationid',
                'assignedto',
                'assigneddate',
                'notes',
            ]
        } else {
            optionalkey = [
                'demandid',
                'demandcode',
                'departmentid',
                'locationid',
                'assignedto',
                'assigneddate',
                'notes',
                'datcomcurrencyid',
            ]
        }

        let validation = CheckObjectValidation(validationdata, optionalkey, false)
        if (!validation?.isvalid) {
            return
        } else {
            let insertKeysare = [
                'demandid',
                'demandcode',
                'workertypeid',
                'companyid',
                'requesttype',
                'startdate',
                'opportunityid',
                'departmentid',
                'priority',
                'locationid',
                'assignedto',
                'assigneddate',
                'demandstatusid',
                'cityid',
                'is_billable',
                'workmodeid',
                'comtenantid',
                'isactiveflag',
                'createdcomuserid',
                'modifiedcomuserid',
                'notes',
                'workforcegroupid',
            ]
            setCheckValidation(false)
            let apiPayload = {
                ...payload,
                opportunityid: opportunity?.opportunityid,
                departmentid: department?.departmentid,
                cityid: location.comcityid,
                allocation: (Number(payload?.allocation || 0) / 100).toFixed(2),
                peopleno: Number(payload?.peopleno),
                billrate: Number(payload?.billrate),
            }
            let finalPayload = {
                demandmasterjson: [apiPayload],
            }
            detailSave(finalPayload, insertKeysare)
            setPayload({ ...apiPayload, allocation: Number(apiPayload?.allocation) * 100 })
        }
    }
    const handleSelectTab = (name, value) => {
        setPayload({ ...payload, [name]: value })
    }

    const handleChangeInput = (name, value) => {
        setPayload({ ...payload, [name]: value })
    }

    const selectLocationFlag = (option) => {
        setPayload({ ...payload, isonsite: option === 'Onsite' ? true : false })
    }
    const setBillingType = (option) => {
        setPayload({ ...payload, is_billable: option === 'Billable' ? true : false })
    }

    return (
        <div className="w-full mt-4 text-sm">
            <div className="w-full h-full mt-4 text-sm">
                <div className="grid grid-cols-4 gap-5">
                    <div>
                        <label htmlFor="demandcode" className="block font-[500] mb-2">
                            Demand Code <span className="text-red-300">*</span>
                        </label>
                        <input
                            disabled
                            type="text"
                            title="demandcode"
                            id="demandcode"
                            className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            placeholder="Demand Code"
                            required=""
                            value={payload?.demandcode || ''}
                        />
                    </div>
                    <div>
                        <label htmlFor="companyid" className="block font-[500] mb-2">
                            Select Company <span className="text-red-300">*</span>
                        </label>
                        <SelectCompany
                            listOfCompany={companylist}
                            value={payload?.companyid || ''}
                            handleChangeValue={handleChangeInput}
                        />
                        {!payload?.companyid && checkValidation && (
                            <small className="text-red-400">Company is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="requestType" className="block font-[500] mb-2">
                            Request Type <span className="text-red-300">*</span>
                        </label>
                        <FilterButtonTabObject
                            className="py-2 border"
                            tabs={requestTypeslist || []}
                            selected={payload?.requesttype}
                            labelkey="requesttype"
                            name="requesttype"
                            valuekey="id"
                            onClick={handleSelectTab}
                        />
                        {!payload?.requesttype && checkValidation && (
                            <small className="text-red-400">Request type is required!</small>
                        )}
                    </div>
                    {payload?.requesttype === 'I' && (
                        <div>
                            <label htmlFor="Department" className="block font-[500] mb-2">
                                Select Department <span className="text-red-300">*</span>
                            </label>
                            <SelectDepartment
                                department={department}
                                setDepartment={setDepartment}
                                departmentlist={departmentlist}
                            />
                            {(!department || !department.departmentid) && checkValidation && (
                                <small className="text-red-400">opportunityid is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.requesttype === 'O' && (
                        <div>
                            <label htmlFor="Opportunity" className="block font-[500] mb-2">
                                Select Opportunity <span className="text-red-300">*</span>
                            </label>
                            <SelectOpportunity
                                opportunity={opportunity}
                                setOpportunity={setOpportunity}
                                listOfOpportunity={listOfOpportunity}
                            />
                            {(!opportunity || !opportunity.opportunityid) && checkValidation && (
                                <small className="text-red-400">opportunityid is required!</small>
                            )}
                        </div>
                    )}
                    <div>
                        <label htmlFor="workertype" className="block font-[500] mb-2">
                            Employee Type <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={workertypelist || []}
                            value={payload?.workertypeid || ''}
                            noDataLabel="No workertype"
                            name="workertypeid"
                            labelkey="workertype"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="workertypeid"
                        />
                        {!payload?.workertypeid && checkValidation && (
                            <small className="text-red-400">Employee is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="workforcegroup" className="block font-[500] mb-2">
                            Workforce group <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={workforcelist || []}
                            value={payload?.workforcegroupid || ''}
                            noDataLabel="No workertype"
                            name="workforcegroupid"
                            labelkey="workforcegroup"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="wfgid"
                        />
                        {!payload?.workforcegroupid && checkValidation && (
                            <small className="text-red-400">workforcegroup is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="noofpeople" className="block font-[500] mb-2">
                            Number Of People <span className="text-red-300">*</span>
                        </label>
                        <div className="relative">
                            <input
                                type="number"
                                name="peopleno"
                                className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                                placeholder="peopleno"
                                required=""
                                value={payload?.peopleno || ''}
                                onChange={(e) => handleChangeInput('peopleno', e.target.value)}
                            />
                            {!payload?.peopleno && checkValidation && (
                                <small className="text-red-400">noofpeople is required!</small>
                            )}
                        </div>
                    </div>
                    {payload?.requesttype === 'O' && (
                        <div>
                            <label htmlFor="BusinessUnit" className="block font-[500] mb-2">
                                Business Unit <span className="text-red-300">*</span>
                            </label>
                            <CustomhtmlSelect
                                options={businessList || []}
                                value={payload?.businessunitid || ''}
                                noDataLabel="No business unit"
                                name="businessunitid"
                                labelkey="businessunitname"
                                className="p-2 border rounded-lg bg-gray-50"
                                onChange={handleChangeInput}
                                id="businessunitid"
                            />
                            {!payload?.businessunitid && checkValidation && (
                                <small className="text-red-400">Business Unit is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.requesttype === 'O' && (
                        <div>
                            <label htmlFor="skillarea" className="block font-[500] mb-2">
                                Skill Area
                                <span className="text-red-300"> *</span>
                            </label>
                            <SelectInput
                                className="block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50"
                                options={skillList}
                                keyname="skillarea"
                                selected={skillArea}
                                setSelected={setSkillArea}
                            />
                            {(!skillArea || !skillArea.skillareamasterid) && checkValidation && (
                                <small className="text-red-400">Skill Area is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.requesttype === 'O' && (
                        <div>
                            <label htmlFor="ServiceLine" className="block font-[500] mb-2">
                                Service Line <span className="text-red-300">*</span>
                            </label>
                            <CustomhtmlSelect
                                options={serviceList || []}
                                value={payload?.servicelineid || ''}
                                noDataLabel="No service"
                                name="servicelineid"
                                labelkey="servicelinename"
                                className="py-2 border rounded bg-gray-50"
                                onChange={handleChangeInput}
                                id="servicelineid"
                            />
                            {!payload?.servicelineid && checkValidation && (
                                <small className="text-red-400">Service line is required!</small>
                            )}
                        </div>
                    )}
                    <div>
                        <h1 className="mb-2 font-[500]">
                            Status <span className="text-red-300">*</span>
                        </h1>
                        <CustomhtmlSelect
                            options={allocationstatuslist || []}
                            value={selectedStatus || 31}
                            noDataLabel="No Allocation Status"
                            name="demandstatusid"
                            labelkey="statusname"
                            className="p-2 border rounded bg-gray-50"
                            onChange={handleChangeInput}
                            id="statusid"
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label htmlFor="startdate" className="block font-[500] mb-2">
                            Start Date <span className="text-red-300">*</span>
                        </label>
                        <FlatPickrDateInput
                            name="startdate"
                            id="startdate"
                            min={minDate}
                            value={payload?.startdate || ''}
                            className="w-full p-2 uppercase border rounded-md bg-gray-50 focus:outline-none hover:outline-none"
                            onChangeDate={handleDates}
                        />
                        {!payload?.startdate && checkValidation && (
                            <small className="text-red-400">Start date is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="billingType" className="block font-[500] mb-2">
                            Billing Type <span className="text-red-300">*</span>
                        </label>
                        <FilterButtonTab
                            className="w-full p-2 border"
                            tabs={['Billable', 'Non-billable']}
                            setSelectedtab={setBillingType}
                            selectedTab={
                                payload?.is_billable && payload?.requesttype === 'O'
                                    ? 'Billable'
                                    : 'Non-billable' || null
                            }
                        />
                    </div>
                    {payload?.is_billable && payload?.requesttype !== 'I' && (
                        <div>
                            <label htmlFor="billrate" className="block font-[500] mb-2">
                                Billing Rate <span className="text-red-300">*</span>
                            </label>
                            <div className="relative ">
                                <input
                                    disabled={!payload?.is_billable}
                                    required=""
                                    placeholder="billrate"
                                    type="number"
                                    className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                                    value={payload?.billrate || ''}
                                    onChange={(e) => handleChangeInput('billrate', e.target.value)}
                                />
                            </div>
                            {!payload?.billrate && checkValidation && (
                                <small className="text-red-400">Bill Rate is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.is_billable && payload?.requesttype !== 'I' && (
                        <div>
                            <label htmlFor="billingunit" className="block capitalize mb-2  font-[500]">
                                Billing Unit <span className="text-red-300">*</span>
                            </label>
                            <CustomhtmlSelect
                                options={cycleList || []}
                                noDataLabel="No cycle"
                                value={payload?.billingunit || ''}
                                name="billingunit"
                                labelkey="cycle"
                                className="py-2 border rounded-lg bg-gray-50"
                                onChange={handleChangeInput}
                                id="id"
                            />
                            {!payload?.billingunit && checkValidation && (
                                <small className="text-red-400">Billing Unit is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.is_billable && payload?.requesttype !== 'I' && (
                        <div>
                            <label htmlFor="billingCurrency" className="block capitalize mb-2  font-[500]">
                                Billing Currency <span className="text-red-300">*</span>
                            </label>
                            <CustomhtmlSelect
                                options={currencyList || []}
                                noDataLabel="No Currency Type"
                                value={payload?.datcomcurrencyid || null}
                                name="datcomcurrencyid"
                                labelkey="currency"
                                className="py-2 border rounded-lg bg-gray-50"
                                onChange={handleChangeInput}
                                id="datcomcurrencyid"
                            />
                            {!payload?.datcomcurrencyid && checkValidation && (
                                <small className="text-red-400">Billing Currency is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.requesttype !== 'I' && payload?.is_billable && (
                        <div>
                            <label htmlFor="allocation" className="block font-[500] mb-2">
                                Allocation (%)<span className="text-red-300">*</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="number"
                                    name="allocation"
                                    className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                                    placeholder="Allocation"
                                    required
                                    value={payload?.allocation || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        // Remove leading zeros
                                        if (/^0[0-9]+/.test(value)) {
                                            value = value.replace(/^0+/, '')
                                        }
                                        // Ensure the value is within 0-100
                                        if (/^[0-9]*$/.test(value) && Number(value) >= 0 && Number(value) <= 100) {
                                            handleChangeInput('allocation', value)
                                        } else if (value === '') {
                                            handleChangeInput('allocation', 0)
                                        }
                                    }}
                                />

                                {!payload?.allocation && checkValidation && (
                                    <small className="text-red-400">allocation is required!</small>
                                )}
                            </div>
                        </div>
                    )}
                    <div>
                        <label htmlFor="Priority" className="block font-[500] mb-2">
                            Priority <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={prioritiesList || []}
                            value={payload?.priority || ''}
                            noDataLabel="No priority"
                            name="priority"
                            labelkey="priority"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="id"
                        />
                        {!payload?.priority && checkValidation && (
                            <small className="text-red-400">priority is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="location" className="block font-[500] mb-2">
                            Location <span className="text-red-300">*</span>
                        </label>
                        <SelectLocation location={location} setLocation={setLocation} />
                        {(!location || !location.comcityid) && checkValidation && (
                            <small className="text-red-400">Location is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="workmode" className="block font-[500] mb-2">
                            Work Mode <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={workmodelist || []}
                            value={payload?.workmodeid || ''}
                            noDataLabel="No workmode"
                            name="workmodeid"
                            labelkey="workmode"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="workmodeid"
                        />
                        {!payload?.workmodeid && checkValidation && (
                            <small className="text-red-400">workmodeid is required!</small>
                        )}
                    </div>
                    {payload?.requesttype !== 'I' && payload?.is_billable && (
                        <div>
                            <label htmlFor="locationflag" className="block font-[500] mb-2">
                                Location Flag <span className="text-red-300">*</span>
                            </label>
                            <FilterButtonTab
                                className="w-full p-2 border"
                                tabs={['Onsite', 'Offshore']}
                                setSelectedtab={selectLocationFlag}
                                selectedTab={payload?.isonsite ? 'Onsite' : 'Offshore' || null}
                            />
                            {!payload?.isonsite && checkValidation && (
                                <small className="text-red-400">Location is required!</small>
                            )}
                        </div>
                    )}
                </div>
                <div className="fixed bottom-2 right-5">
                    <PrimaryButton className="px-8 py-2 mb-4 text-white rounded-lg bg-primary" onClick={handleSubmit}>
                        <span>Save</span>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default Details
