import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ConfigInfo = ({ data, entrytype }) => {
    let item = data?.timesheetconfiguration?.length ? data?.timesheetconfiguration[0] : data?.timesheetconfiguration

    const timetypetemplateMap = useMemo(() => {
        return item?.timetypetemplatemapping?.find(r => r?.timetypeid === data?.timetypeid)
        // eslint-disable-next-line 
    }, [data, item, data?.timetypeid])


    return (
        <>
            <span data-tooltip-id={"timesheet-data-config-template" + data?.uId}>
                <InformationCircleIcon className="cursor-pointer rounded-full text-primary w-4 h-4" />
            </span>

            <Tooltip
                opacity={1}
                place='right-end'
                id={"timesheet-data-config-template" + data?.uId}
                className="!bg-white !text-gray-800 !border !border-gray-200 !shadow-sm !rounded-md !p-0 relative"
                render={() => (
                    <div className="w-64 px-3 py-2 m-0 rounded-md relative">
                        <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-4 border-r-4 border-b-4 border-transparent border-b-white"></div>
                        <h3 className="mb-1 font-[400]">Template Rules</h3>
                        <ul className=" m-0 bg-white space-y-1 text-gray-500 font-[400]">
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Template :</span>{item?.templatename || 'None'}</li>
                            {entrytype === 'Project' && <li className="py-0 my-0 text-xs"><span className="pr-1.5">Allocation Restriction :</span>{item?.allocationrestriction ? 'Yes' : 'No'}</li>}
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Time Validation :</span>{item?.validationunitname || 'None'}</li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Min Total :</span>{item?.minhours} Hrs</li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Max Total :</span>{item?.maxhours} Hrs</li>
                        </ul>
                        <h3 className="mb-1 mt-2 font-[400]">Timetype Rules</h3>
                        <ul className=" m-0 bg-white space-y-1 text-gray-500 font-[400]">
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Daily Range:</span>{timetypetemplateMap?.mindailylimit} - {timetypetemplateMap?.maxdailylimit} Hrs </li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Timetype Uniqueness :</span>{timetypetemplateMap?.timetype || 'None'}</li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Holiday Calendar :</span>{timetypetemplateMap?.holidaycalendarintegration} </li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Holiday Entry :</span>{timetypetemplateMap?.holidayallowed ? 'Yes' : 'No'}  </li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Weekday Entry :</span>{timetypetemplateMap?.weekdayentryallowed ? 'Yes' : 'No'}  </li>
                            <li className="py-0 my-0 text-xs"><span className="pr-1.5">Weekend Entry :</span>{timetypetemplateMap?.weekendentryallowed ? 'Yes' : 'No'}</li>
                        </ul>
                    </div>
                )}
                border={'1px solid #e5e7eb'}
                style={{ zIndex: 2000, backgroundColor: 'white', padding: '0', margin: '0', color: 'black', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
            />
        </>
    );
};

export default ConfigInfo;
