import RadialPieChart from './metrics'
import BarchartStaffing from './byAllocation'
import BillingRole from './BillingRole'
import AllocationStatus from './AllocationStatus'

const metrics = [
    { title: 'Avg Role Duration (Days)', value: 45, color: '#83a6ed' },
    { title: 'Early releases by Vol (%)', value: 12, color: '#8dd1e1' },
    { title: 'Extensions by Vol (%)', value: 25, color: '#82ca9d' },
    { title: 'Avg Allocation (%)', value: 65, color: '#a4de6c' },
    { title: 'Avg Monthly Utilization (%)', value: 75, color: '#d0ed57' },
    { title: 'Avg Project FTE', value: 4.5, color: '#ffc658' },
]

const AnalyticStaffing = () => {
    return (
        <div className="relative w-full">
            <div className="grid grid-cols-3 gap-4 py-2 ml-8">
                <div className="relative flex items-center justify-center w-full h-80">
                    <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                        By Allocation %
                    </h3>
                    <BarchartStaffing />
                </div>
                <div className="relative w-full h-80">
                    <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                        By Billing Role
                    </h3>
                    <BillingRole />
                </div>
                <div className="relative w-full h-80 ">
                    <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                        By Allocation Status
                    </h3>
                    <AllocationStatus />
                </div>
            </div>
            <div className="grid grid-cols-6 gap-1 ">
                {metrics.map((metric, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-center h-12 col-span-1"
                    >
                        <RadialPieChart
                            title={metric.title}
                            value={metric.value}
                            color={metric.color}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AnalyticStaffing
