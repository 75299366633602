import React, { useMemo } from 'react'
import { Tab } from '@headlessui/react'
import Revenue from './revenue'
import Expenses from './expenses'
import Payroll from './payroll'
import Leaves from './leaves'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const tabsList = ['Expenses', 'Revenue', 'Payroll', 'Leaves']
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProjectFinancialsLayout = ({ modulename, menu }) => {
    const navigate = useNavigate('')

    const addEditPermission = useMemo(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(
            (item) => item?.permissionname,
        )
        if (permissionNames?.length > 0 && !permissionNames.includes('View Financials')) {
            navigate('/app/access-denied')
        }
        if (
            permissionNames?.length > 0 &&
            permissionNames.includes('Add/Edit Financials')
        ) {
            return true
        }
        // eslint-disable-next-line
    }, [modulename, menu])

    return (
        <>
            <div className=" mt-4 bg-white h-[calc(100%-90px)] rounded-md shadow">
                <Tab.Group>
                    <div className="flex items-center justify-between w-full border-b border-secondary ">
                        <Tab.List className="w-[300px] flex rounded-lg p-1">
                            {tabsList.map((item, i) => (
                                <Tab
                                    key={i}
                                    className={({ selected }) =>
                                        classNames(
                                            'text-sm text-left w-full rounded-lg py-2 font-[500] outline-white',
                                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                            selected
                                                ? 'bg-secondary/10 text-secondary font-[500] p-1.5 rounded-md'
                                                : 'text-gray-500',
                                        )
                                    }
                                >
                                    <span className="px-4">{item}</span>
                                </Tab>
                            ))}
                        </Tab.List>
                    </div>
                    <Tab.Panels>
                        <Tab.Panel>
                            <Expenses addEditPermission={addEditPermission} />
                        </Tab.Panel>
                        <Tab.Panel>
                            <Revenue addEditPermission={addEditPermission} />
                        </Tab.Panel>
                        <Tab.Panel>
                            <Payroll addEditPermission={addEditPermission} />
                        </Tab.Panel>
                        <Tab.Panel>
                            <Leaves addEditPermission={addEditPermission} />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>

    )
}

export default ProjectFinancialsLayout
