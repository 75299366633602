
import { useState, useRef, useEffect, memo, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

function SingleSelectField({ options, handleSelected, disabled, value, labelKey, valueKey, tasknotes}) {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('')
    const dropdownRef = useRef(null);

    const getByTitle = useMemo(() => {
        try {
            let item = options?.find(el => el[valueKey] === value);
            return `${item[labelKey]} ${item?.tasknotes ? ` - ${tasknotes}` : ''}`
        } catch (error) {
            return '';
        }
    // eslint-disable-next-line
    }, [valueKey, options, value])

    const handleOpen = () => {
        setOpen(true)
        setQuery('')
    }

    useMemo(() => {
        setQuery(getByTitle)
        // eslint-disable-next-line
    }, [valueKey, options, value])

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getIsActive = (task)=>{
        return task[valueKey] === value && (task?.tasknotes ? tasknotes === task?.tasknotes : true )
    }

    const filteredList =
        query === ''
            ? options
            : options?.filter(el =>
                el[labelKey]
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, '')),
            )

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative ">
                <button disabled={disabled} onClick={handleOpen} className='w-full border border-primary/40 py-1 rounded flex justify-start items-center px-1 bg-white'>
                    <input value={query || getByTitle}
                        disabled={disabled}
                        placeholder='Select Task'
                        onChange={(e) => setQuery(e.target.value || ' ')}
                        className='w-full text-xs focus:outline-none placeholder:text-primary/40 ' />
                </button>
            </div>
            {open && <div className="transition z-[1000] max-h-44 ease-in duration-100 absolute mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {filteredList?.map((item, itemIdx) => {
                    return (
                        <div
                            key={item?.uId || itemIdx}
                            className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-2 cursor-pointer ${getIsActive(item) ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                            onClick={() => {
                                handleSelected(item)
                                setOpen(!open)
                                setQuery(item[labelKey])
                            }}
                        >
                            <span>
                                {item[labelKey]} {item?.tasknotes ? ` - ${item?.tasknotes}` : ''}
                            </span>
                            {getIsActive(item) &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                            }
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}


const TaskgroupById = ({ hooks: { it, rowId }, setOpen }) => {
    const ProjectTimesheet = useSelector(state => state?.ProjectTimesheet)
    const employeeTimesheetData = useSelector(state => state?.ProjectTimesheet?.employeeTimesheetData)
    let { getTasksByTaskType } = ProjectTimesheet
    const dispatch = useDispatch()

    const getSelected = (valueis) => {
        let items = employeeTimesheetData?.map(((row) => {
            if (row?.uId === rowId) {
                row['newChange'] = true
                row['projtaskid'] = valueis?.taskid
                row['projecttaskid'] = valueis?.projecttaskid || null
                row['taskname'] = valueis?.taskname
                row['taskdesc'] = valueis?.taskname || valueis?.taskdesc
                row['billtoclient'] = valueis?.billtoclient
                row['clientapproval'] = valueis?.clientapproval
                row['allocation'] = valueis?.allocation
                row['referencenumber'] = valueis?.tasknotes
                
                row['timetypeid'] = null
                row['selectedTimetype'] = null
                row['timetypeUniqueness'] = null

                setOpen(false)
            }
            return row
        }))
        dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
    }
    return (
        <SingleSelectField
            tasknotes={it?.referencenumber}
            valueKey='taskid'
            labelKey={it?.entrytype === 'Generic' ? 'taskdesc' : 'taskname'}
            options={it?.entrytype === 'Generic' ? getTasksByTaskType : it?.selectedAllocation?.taskdetails || []}
            value={it?.projtaskid || null}
            handleSelected={getSelected}
            disabled={it?.curstatus === 'Approved'}
        />
    )
}

export default memo(TaskgroupById);
