
function getUserId() { return JSON.parse(localStorage.getItem('comuserid')) }
function gettenantId() { return JSON.parse(localStorage.getItem('Tenantid')) }


export const defaultItemOfMileStone = (datcomcurrencyid = null) => {
    return (
        {
            projectmilestoneid: null,
            comtenantid: gettenantId(),
            projectid: 0,
            milestonename: "",
            milestonedesc: "",
            projecttaskid: null,
            plannedcompletiondate: "",
            actualcompletiondate: null,
            billingmilestone: false,
            isactiveflag: true,
            createdcomuserid: getUserId(),
            notes: "",
            milestoneamount:'',
            datcomcurrencyid,
            uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
        }
    )
}

export const insertDataKeys = ['milestoneamount', 'datcomcurrencyid','notes', 'confirmed', 'createdcomuserid', 'isactiveflag', 'billingmilestone', 'projectmilestoneid', 'comtenantid', 'projectid', 'milestonename', 'milestonedesc', 'projecttaskid', 'plannedcompletiondate', 'actualcompletiondate']