import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import { GET_PROFITABILITY_FOR_DASHBOARD, GET_REVENUE_FOR_DASHBOARD } from 'src/Redux/actions/analytics'
import { GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import {
    GET_BUSINESS_UNIT_MASTER_LIST,
    GET_CURRENCY_MASTER_LIST,
    GET_LIST_COMPANY,
    GET_LIST_TYPE_OF_PROJECT,
    GET_PROJECT_LIST_BILLING_TYPE,
} from 'src/Redux/actions/projects'
import {
    getDefaultBillingIdPayload,
    getDefaultBusinessUnitPayload,
    getDefaultCompanyPayload,
    getDefaultCurrencyPayload,
    getDefaultProjectTypePayload,
    getRevenuePayload,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'

const FinanceAnalyticsFilter = ({ filters, setFilters }) => {
    const dispatch = useDispatch()
    const { serviceList } = useSelector(state => state?.UserManagementList)
    const { typesListProject, listOfCompany, listOfBillingProjects, businessUnitList, currencyList } = useSelector(
        (state) => state?.Projects,
    )

    useEffect(() => {
        dispatch(GET_LIST_COMPANY(getDefaultCompanyPayload()))
        dispatch(GET_BUSINESS_UNIT_MASTER_LIST(getDefaultBusinessUnitPayload()))
        dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
        dispatch(GET_LIST_TYPE_OF_PROJECT(getDefaultProjectTypePayload(0)))
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
    }, [dispatch])

    useEffect(() => {
        if (currencyList && currencyList.length > 0) {
            const usdCurrency = currencyList.find(currency => currency.currency === 'USD')
            if (usdCurrency && !filters.defaultcomcurrencyid) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    defaultcomcurrencyid: usdCurrency.datcomcurrencyid
                }))
            }
        }
    }, [currencyList, filters.defaultcomcurrencyid, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleSelectTab = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const applyFilters = () => {
        dispatch(GET_REVENUE_FOR_DASHBOARD({ ...filters }))
        dispatch(GET_PROFITABILITY_FOR_DASHBOARD({ ...filters, Reporttype: 'Profitablity' }))
    }

    const clearFilters = () => {
        let payload = {
            ...getRevenuePayload(),
            billtypeid: 0,
            year: new Date().getFullYear(),
            quarter: 'Q1',
            // Reportlevel: "Region"
        }
        setFilters(payload)

        dispatch(GET_REVENUE_FOR_DASHBOARD(payload))
        dispatch(GET_PROFITABILITY_FOR_DASHBOARD({ ...payload, quarter: 'Q1', Reporttype: 'Profitablity' }))
    }

    return (
        <div className="text-sm grid grid-cols-2 gap-4 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-1">
            <div>
                <h1 className="mb-2 font-[500]">Company</h1>
                <CustomhtmlSelect
                    options={listOfCompany || []}
                    value={filters?.companyid}
                    noDataLabel="No Company"
                    name="companyid"
                    labelkey="companyname"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="companyid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Project Type</h1>
                <CustomhtmlSelect
                    options={typesListProject || []}
                    noDataLabel="No Type"
                    value={filters?.projecttypeid}
                    name="projecttypeid"
                    labelkey="projecttype"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="projecttypeid"
                />
            </div>
            <div>
                <h1 className='mb-2 font-[500]'>Service Line</h1>
                <CustomhtmlSelect
                    options={serviceList || []}
                    value={filters?.servicelineid}
                    noDataLabel='No Serviceline'
                    name='servicelineid'
                    labelkey='servicelinename'
                    className='py-2 border rounded bg-gray-50'
                    onChange={handleSelectChange}
                    id='servicelineid'
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Business Unit</h1>
                <CustomhtmlSelect
                    options={businessUnitList || []}
                    value={filters?.businessunitid}
                    noDataLabel="No Business Unit"
                    name="businessunitid"
                    labelkey="businessunitname"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="businessunitid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Billing Type</h1>
                <FilterButtonTabObject
                    className="py-2 border bg-gray-50"
                    tabs={listOfBillingProjects || []}
                    selected={filters?.billtypeid}
                    labelkey="billingtype"
                    name="billtypeid"
                    valuekey="datprojectbillingtypeid"
                    onClick={handleSelectTab}
                />
            </div>
            <div>
                <h1 className='mb-2 font-[500]'>Action</h1>
                <div className='grid grid-cols-2 gap-2'>
                    <button
                        onClick={clearFilters}
                        className='px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5'>
                        Clear
                    </button>
                    <button
                        onClick={applyFilters}
                        className='px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5'>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FinanceAnalyticsFilter
