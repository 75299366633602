import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { AlertSuccess } from 'src/Services'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { getDeafultTalentPayload } from 'src/Services/constant/defaultPayload'
import { GET_LEAVETYPE_MASTER } from 'src/Redux/actions/projects/financials'
import { GET_EMPLOYEE_DETAILS } from 'src/Redux/actions/workforce/talent'
import FlatpickerDateInput from 'src/Components/FlatpickerDateInput'
import { getLeaveTypePayload } from '../defaultPayload'
import SelectUser from 'src/Components/SelectUser'

const EditDetails = ({ hooks }) => {
    const dispatch = useDispatch()
    const {
        detailSave,
        closeModal,
        canDelete,
        setCanDelete,
        canEdit,
        setCanEdit,
        setUserLeave,
        userLeave,
    } = hooks

    const [user, setUser] = useState({})
    const [isLop, setIsLop] = useState(false)
    const { leaveTypeList } = useSelector((state) => state?.ProjectFinancial)
    const [checkValidation, setCheckValidation] = useState(false)
    const [dateError, setDateError] = useState('')

    const handleChangeInput = (name, value) => {
        setCheckValidation(false)
        if (name === 'islop') {
            setIsLop(value)
        }
        setUserLeave({ ...userLeave, [name]: value })
    }

    const handleStartDate = (name, value) => {
        setUserLeave((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }))
        // Set minimum end date to the day after the selected start date
        const startDate = new Date(value)
        startDate.setDate(startDate.getDate() + 1)
    }

    useEffect(() => {
        dispatch(GET_LEAVETYPE_MASTER(getLeaveTypePayload()))
        dispatch(GET_EMPLOYEE_DETAILS(getDeafultTalentPayload()))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (userLeave) {
            setUser({
                comuserid: userLeave.comuserid,
                firstname: userLeave.username || '',
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (userLeave) {
            setIsLop(userLeave.islop)
        }
    }, [userLeave])

    const handleSubmit = (e) => {
        e.preventDefault()
        setCheckValidation(true)
        const startDate = new Date(userLeave.leavestartdate)
        const endDate = new Date(userLeave.leaveenddate)
        const timeDiff = endDate - startDate // Difference in milliseconds
        const dayDiff = timeDiff / (1000 * 3600 * 24)

        if (endDate <= startDate) {
            setDateError('End date hould be greater than start date!')
            setCheckValidation({ isvalid: false })
            return
        } else {
            setDateError('')
        }

        let validationdata = {}
        let requiredInDetail = [
            'comuserid',
            'Leavetypeid',
            'leavestartdate',
            'leaveenddate',
            'Noofdays',
            'leavereason',
            'leavestatus',
            'comtenantid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'notes',
            'islop',
        ]

        for (let value of requiredInDetail) {
            validationdata[value] = userLeave[value]
        }
        validationdata['Noofdays'] = dayDiff + 1
        validationdata['comuserid'] = user?.comuserid
        let optionalkey = [
            'userleavedataid',
            'notes',
            'leavereason',
            'leavestatus',
            'Leavetypeid',
            'modifiedcomuserid',
        ]
        let isValidated = CheckObjectValidation(validationdata, optionalkey, true)
        if (!isValidated?.isvalid) {
            return
        } else {
            let item = {
                userleavedataid: userLeave?.userleavedataid,
                comuserid: user?.comuserid,
                Leavetypeid: userLeave?.leavetypeid,
                leavestartdate: userLeave.leavestartdate,
                leaveenddate: userLeave.leaveenddate,
                Noofdays: dayDiff + 1,
                leavereason: '',
                leavestatus: null,
                comtenantid: Number(localStorage.getItem('Tenantid') || 0),
                isactiveflag: true,
                islop: userLeave?.islop,
                createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
                modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
                notes: userLeave?.notes,
            }

            let insertKeysare = [
                'comuserid',
                'Leavetypeid',
                'leavestartdate',
                'leaveenddate',
                'Noofdays',
                'comtenantid',
                'isactiveflag',
                'createdcomuserid',
                'modifiedcomuserid',
                'notes',
                'islop',
            ]
            setCheckValidation(false)
            let finalPayload = {
                userleavedatajson: [item],
            }
            detailSave(finalPayload, insertKeysare)
        }
    }

    const handleDelete = async () => {
        let item = {
            userleavedataid: userLeave?.userleavedataid,
            comuserid: user?.comuserid,
            Leavetypeid: userLeave?.leavetypeid,
            leavestartdate: userLeave.leavestartdate,
            leaveenddate: userLeave.leaveenddate,
            Noofdays: 1,
            leavereason: '',
            leavestatus: null,
            comtenantid: Number(localStorage.getItem('Tenantid') || 0),
            isactiveflag: false,
            islop: userLeave.islop,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: userLeave?.notes,
            fromsession: "",
            tosession: ""
        }

        let payload = {
            userleavedatajson: [item],
        }
        detailSave(payload)
        AlertSuccess('Record deleted successfully')
        closeModal()
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label
                        htmlFor="LeaveType"
                        className="block capitalize mb-2  font-[500]"
                    >
                        Select User
                        <span className="text-red-300">*</span>
                    </label>
                    <SelectUser
                        setUser={setUser}
                        className="py-2 border rounded bg-gray-50"
                        user={user}
                        apiCallForSearch={GET_EMPLOYEE_DETAILS}
                        userSelectorKey="Employees"
                        searchPayloadFunction={getDeafultTalentPayload}
                        placeholder="Select User"
                        disabled={!canEdit}
                    />
                    {!user?.comuserid && checkValidation && (
                        <small className="text-red-400">user is required!</small>
                    )}
                </div>
                <div>
                    <label
                        htmlFor="LeaveType"
                        className="block capitalize mb-2  font-[500]"
                    >
                        Leave Type
                        <span className="text-red-300">*</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={!canEdit}
                        options={leaveTypeList || []}
                        noDataLabel="No leaveTypeList"
                        value={userLeave?.leavetypeid || null}
                        name="leavetypeid"
                        labelkey="leavetypename"
                        className="py-2 border rounded-lg bg-gray-50"
                        onChange={handleChangeInput}
                        id="leavetypeid"
                    />
                </div>
                <div>
                    <label htmlFor="leavestartdate" className="block font-[500] mb-2">
                        Leave Start Date
                        <span className="text-red-300">*</span>
                    </label>
                    <FlatpickerDateInput
                        name="leavestartdate"
                        value={userLeave?.leavestartdate || ''}
                        id="leavestartdate"
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]"
                        disabled={!canEdit}
                        onChangeDate={(e) =>
                            handleStartDate('leavestartdate', e.target.value)
                        }
                    />
                    {!userLeave?.leavestartdate && checkValidation && (
                        <small className="text-red-400">
                            leavestartdate is required!
                        </small>
                    )}
                </div>
                <div>
                    <label htmlFor="leaveenddate" className="block font-[500] mb-2">
                        Leave End Date
                        <span className="text-red-300">*</span>
                    </label>
                    <FlatpickerDateInput
                        name="leaveenddate"
                        value={userLeave?.leaveenddate || ''}
                        id="leaveenddate"
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]"
                        disabled={!canEdit}
                        onChangeDate={(e) =>
                            handleStartDate('leaveenddate', e.target.value)
                        }
                        min={userLeave?.startdate}
                    // disabled={!payload?.startdate}
                    />
                    {!userLeave?.leaveenddate && checkValidation && (
                        <small className="text-red-400">leaveenddate is required!</small>
                    )}
                    {dateError && <small className="text-red-400">{dateError}</small>}
                </div>
                <div className="flex items-center mt-4">
                    <label
                        htmlFor="islop"
                        className="block mr-4 font-medium text-gray-700"
                    >
                        Loss of Pay
                    </label>
                    <input
                        type="checkbox"
                        id="islop"
                        name="islop"
                        checked={isLop || false}
                        readOnly={!canEdit}
                        onClick={!canEdit ? (e) => e.preventDefault() : undefined}
                        onChange={
                            canEdit
                                ? (e) => handleChangeInput('islop', e.target.checked)
                                : undefined
                        }
                        className="mt-1"
                    />
                </div>
            </div>
            {canEdit ? (
                <div className="fixed bottom-0 mt-2 right-6">
                    <button
                        onClick={() => setCanEdit(null)}
                        className="text-primary bg-gray-100 w-[80px] rounded-md py-2"
                    >
                        Cancel
                    </button>
                    <PrimaryButton
                        onClick={handleSubmit}
                        className="py-2 mx-2 rounded-md bg-primary"
                    >
                        Save
                    </PrimaryButton>
                </div>
            ) : (
                <div className="h-12" />
            )}
            {canDelete ? (
                <div className='fixed bottom-0 mt-2 right-6'>
                    <div className='flex items-center justify-end gap-2'>
                        <span className=""> Do you really want to delete this record? </span>
                        <PrimaryButton className='px-6 border bg-white  border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
                            onClick={handleDelete}
                        >
                            Yes
                        </PrimaryButton>
                        <PrimaryButton className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                            onClick={() => setCanDelete(null)}
                        >
                            No
                        </PrimaryButton>
                    </div>
                </div>
            ) : (
                <div className="h-12" />
            )
            }
        </div >
    )
}

export default memo(EditDetails)
