import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import SelectMonth from 'src/Components/SelectMonth'
import { GET_UTILIZATION_REPORT_FOR_EMPLOYEE } from 'src/Redux/actions/reports'
import {
    getEmployeeUtilizationReportsPayload,
} from 'src/Services/constant/defaultPayload'
import SelectLocation from './selectLocation'
import GetUserFromAdmin from 'src/Components/GetUserFromAdmin'
import { GET_LIST_OF_STATUS_BY_E } from 'src/Redux/actions/masterdata/user'

const categorylist = [
    { category: 'G1 (0)', id: 'G1' },
    { category: 'G2 (0 - 50)', id: 'G2' },
    { category: 'G3 (50 - 100)', id: 'G3' },
    { category: 'G4 (100)', id: 'G4' },
]

const EmployeeUtilizationReportsFilter = ({
    filters,
    setFilters,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
    user,
    setUser,
    APICALL = GET_UTILIZATION_REPORT_FOR_EMPLOYEE,
}) => {
    const dispatch = useDispatch()
    const { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const { designationlist, bandList, companylist } = useSelector(
        (state) => state.EmployeeDetails,
    )
    const { userSkillList } = useSelector((state) => state.EmployeeProfile)
    const { serviceList } = useSelector((state) => state.UserManagementList)
    const [location, setLocation] = useState({})

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            comuserid: user?.comuserid || 0,
            locationid: location?.comcityid || 0,
        }))
    }, [location?.comcityid, setFilters, user?.comuserid])

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        let m = month?.length === 1 ? `0${month}` : month
        const parsedMonth = parseInt(m, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthId = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearId = isNaN(parsedYear) ? '' : parsedYear
        if (name === 'startyearmonth') {
            setFilters({
                ...filters,
                [name]: Number(`${parsedYear}${m}`),
                startMonthid: validMonthId,
                startYearid: validYearId,
            })
        } else {
            setFilters({
                ...filters,
                [name]: Number(`${parsedYear}${m}`),
                endMonthid: validMonthId,
                endYearid: validYearId,
            })
        }
    }

    const clearFilters = () => {
        let payload = {
            ...getEmployeeUtilizationReportsPayload(),
            comuserid: 0,
            locationid: 0,
            utilizationgroup: '',
        }
        setUser({})
        setLocation({})
        dispatch(APICALL(payload))
        setFilters(payload)
    }

    const applyFilters = () => {
        let payload = {
            ...filters,
            locationid: location?.comcityid || 0,
            comuserid: user?.comuserid || 0,
        }
        dispatch(APICALL(payload))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    useEffect(()=>{
        dispatch(GET_LIST_OF_STATUS_BY_E())
    // eslint-disable-next-line
    },[])

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <div>
                    <label htmlFor="User" className="block capitalize mb-2  font-[500]">
                        Select User
                    </label>
                    <GetUserFromAdmin selected={user} setSelected={setUser} />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Service Line</h1>
                    <CustomhtmlSelect
                        options={serviceList || []}
                        value={filters?.servicelineid}
                        noDataLabel="No Serviceline"
                        name="servicelineid"
                        labelkey="servicelinename"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="servicelineid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Company</h1>
                    <CustomhtmlSelect
                        options={companylist || []}
                        value={filters?.companyid}
                        noDataLabel="No Company"
                        name="companyid"
                        labelkey="companyname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="companyid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]"> Location</h1>
                    <SelectLocation location={location} setLocation={setLocation} />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Designation</h1>
                    <CustomhtmlSelect
                        options={designationlist || []}
                        value={filters?.designationid}
                        noDataLabel="No designation found"
                        name="designationid"
                        labelkey="designation"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="designationmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Band</h1>
                    <CustomhtmlSelect
                        options={bandList || []}
                        value={filters?.bandid}
                        noDataLabel="No Type"
                        name="bandid"
                        labelkey="bandname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="bandmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Primary Skill</h1>
                    <CustomhtmlSelect
                        options={userSkillList || []}
                        value={filters?.primaryskillid}
                        noDataLabel="No Skill"
                        name="primaryskillid"
                        labelkey="skill"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="skillmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Start Month</h1>
                    <SelectMonth
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        name="startyearmonth"
                        onChange={handleMonthChange}
                        month={
                            filters?.startMonthid?.length === 1
                                ? `0${filters?.startMonthid}`
                                : filters?.startMonthid
                        }
                        year={filters?.startYearid}
                    // min={}
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select End Month</h1>
                    <SelectMonth
                        // disabled={!filters?.startYearid}
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        name="endyearmonth"
                        onChange={handleMonthChange}
                        month={
                            filters?.endMonthid?.length === 1
                                ? `0${filters?.endMonthid}`
                                : filters?.endMonthid
                        }
                        year={filters?.endYearid}
                        min={filters?.startyearmonth}
                    // disabled={filters?.startyearmonth}
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Utilization Group</h1>
                    <CustomhtmlSelect
                        options={categorylist || []}
                        noDataLabel="No Category"
                        value={filters?.utilizationgroup}
                        name="utilizationgroup"
                        labelkey="category"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="id"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeUtilizationReportsFilter
