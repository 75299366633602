import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from 'src/Components/Buttons/IconButton'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { SAVE_DEMAND_ROLE_MASTER } from 'src/Redux/actions/workforce/demands'
import { GET_BAND_MASTER } from 'src/Redux/actions/workforce/talent'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'
import { getBandMasterIdData } from 'src/Services/constant/defaultPayload'

const RoleDetails = ({ hooks }) => {
    const { band, setBand, roleData, getDefaultDemandData, setRoleData, demandid } = hooks
    const dispatch = useDispatch()
    const { bandList } = useSelector((state) => state?.EmployeeDetails)
    const [checkValidation, setCheckValidation] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)

    useEffect(() => {
        dispatch(GET_BAND_MASTER(getBandMasterIdData()))
    }, [dispatch])

    const handleAdd = () => {
        if (roleData?.length < 10) {
            setRoleData([
                ...roleData,
                {
                    uId:
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36),
                    responsibilities: '',
                },
            ])
        }
    }

    const handleDelete = (uId) => {
        if (roleData?.length > 1) {
            setRoleData(roleData.filter((item) => item.uId !== uId))
        }
    }

    const handleChange = (e) => {
        let { name, value, id } = e.target
        const newroleData = roleData.map((item) => {
            if (item.uId === id) {
                item[name] = value
            }
            return item
        })
        setRoleData([...newroleData])
    }

    const handleChangeInput = (name, value) => {
        setBand({ ...band, [name]: value })
    }

    const handleSubmit = async () => {
        const hasEmptyResponsibility = roleData.some(
            (item) => !item.responsibilities || item.responsibilities.trim() === '',
        )
        if (roleData.length === 0 || hasEmptyResponsibility) {
            setCheckValidation({
                isvalid: false,
            })
            return
        }
        setCheckValidation(true)
        band['roleresponsibilities'] = roleData
        band['demandid'] = demandid
        // band['createdcomuserid'] = Number(localStorage.getItem('comuserid') || 0)
        let data = [band]
        const minBandId = band?.min_bandid
        const maxBandId = band?.max_bandid

        const isMinBandLessThanMaxBand = () => {
            const minBand = bandList?.find((band) => band.bandmasterid === minBandId)
            const maxBand = bandList?.find((band) => band.bandmasterid === maxBandId)
            setErrorMsg('Max band should be greater than Min band!')
            if (!(minBand?.slno < maxBand?.slno)) {
            } else {
                setErrorMsg('')
            }
            return minBand?.slno < maxBand?.slno
        }

        if (!isMinBandLessThanMaxBand()) {
            setCheckValidation({
                isvalid: false,
            })
            return
        }

        let isValidated = CheckListObjectValidation(
            data,
            ['notes', 'demandroledetailid', 'demandid', 'bandmasterid', 'comtenantid',],
            false,
        )
        if (!isValidated?.isvalid) {
            return
        } else {
            setCheckValidation(false)
            let res = await dispatch(SAVE_DEMAND_ROLE_MASTER(data, demandid))
            if (res?.success) {
                setErrorMsg('')
            }
        }
        getDefaultDemandData()
    }

    return (
        <div className="mt-4 w-full h-[70vh] text-sm " onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-8 mt-2">
                <div>
                    <label htmlFor="jobtitle" className="block font-[500] mb-2">
                        Job Title <span className="text-red-300">*</span>
                    </label>
                    <input
                        type="text"
                        title="jobtitle"
                        id="jobtitle"
                        className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                        placeholder="Enter Job Title"
                        required=""
                        value={band?.jobtitle || ''}
                        onChange={(e) => handleChangeInput('jobtitle', e.target.value)}
                    />
                    {!band?.jobtitle && checkValidation && (
                        <small className="text-red-400">Job is required!</small>
                    )}
                </div>
                <div className="grid grid-cols-3 gap-8 px-2">
                    <div>
                        <label htmlFor="band" className="block font-[500] mb-2">
                            Min Band <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={bandList || []}
                            value={band?.min_bandid || ''}
                            noDataLabel="No band"
                            name="min_bandid"
                            labelkey="bandname"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="bandmasterid"
                        />
                        {!band?.min_bandid && checkValidation && (
                            <small className="text-red-400">band is required!</small>
                        )}
                    </div>

                    <div>
                        <label htmlFor="band" className="block font-[500] mb-2">
                            Max Band <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={bandList || []}
                            value={band?.max_bandid || ''}
                            noDataLabel="No band"
                            name="max_bandid"
                            labelkey="bandname"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="bandmasterid"
                        />
                        {!band?.max_bandid && checkValidation && (
                            <small className="text-red-400">band is required!</small>
                        )}
                        {errorMsg && <small className="text-red-400">{errorMsg}</small>}
                    </div>
                    <div>
                        <label
                            htmlFor="experienceinyear"
                            className="block font-[500] mb-2"
                        >
                            Min Exp (Years) <span className="text-red-300">*</span>
                        </label>
                        <input
                            placeholder="Min"
                            type="text"
                            pattern="[0-9\s]*"
                            className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            value={band?.experienceinyear || ''}
                            onChange={(e) => {
                                if (/^[0-9\s]*$/.test(e.target?.value)) {
                                    handleChangeInput('experienceinyear', e.target?.value)
                                }
                            }}
                        />
                        {!band?.experienceinyear && checkValidation && (
                            <small className="text-red-400">
                                Min Experience is required!
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="rolesummary" className="block font-[500] mb-2">
                    Role Summary <span className="text-red-300">*</span>
                </label>
                <textarea
                    placeholder="Role Summary..."
                    rows={3}
                    type="text"
                    className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                    value={band?.rolesummary || ''}
                    onChange={(e) => handleChangeInput('rolesummary', e.target.value)}
                />
                {!band?.rolesummary && checkValidation && (
                    <small className="text-red-400">Role Summary is required!</small>
                )}
            </div>
            <div className="flex items-center justify-between mt-8">
                <label htmlFor="responsibilities" className="block font-[500] mb-2">
                    Role Responsibilities <span className="text-red-300">*</span>
                </label>
            </div>
            <div className=" overflow-scroll h-[260px] ">
                {roleData?.map((item, i) => {
                    let { uId, responsibilities } = item
                    return (
                        <div
                            key={uId}
                            className="flex items-center justify-start my-2 space-x-2"
                        >
                            <input
                                placeholder="responsibility"
                                onChange={handleChange}
                                name="responsibilities"
                                value={responsibilities || ''}
                                id={uId}
                                className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            />
                            {!responsibilities && checkValidation && (
                                <small className="text-red-400">
                                    Please enter at least one responsibility!
                                </small>
                            )}
                            <div className="flex justify-center space-x-2">
                                {roleData?.length > 1 && (
                                    <IconButton
                                        onClick={() => handleDelete(item?.uId)}
                                        className="flex items-center justify-center text-white rounded-full bg-primary w-7 h-7"
                                        title="Delete responsibility"
                                    >
                                        <MinusIcon className="w-3" />
                                    </IconButton>
                                )}
                                {roleData?.length - 1 === i && (
                                    <IconButton
                                        disabled={roleData?.length >= 10}
                                        onClick={handleAdd}
                                        className="flex items-center justify-center text-white rounded-full bg-primary w-7 h-7"
                                        title="Add responsibility"
                                    >
                                        <PlusIcon className="w-3" />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="fixed bottom-2 right-5">
                <PrimaryButton
                    className="px-8 py-2 mb-4 text-white rounded-lg bg-primary"
                    onClick={handleSubmit}
                >
                    <span>Save</span>
                </PrimaryButton>
            </div>
        </div>
    )
}

export default memo(RoleDetails)
