import { useState } from "react"
import ForgotPassword from "./forgotPassword"
import msIcon from 'src/Assets/icons/MS icon.png'

const LoginForm = ({ hooks, handleLogin }) => {
    const [isLogin, setIsLogin] = useState(true)
    const { username, setUsername, password, setPassword } = hooks

    const handleSubmit = (e) => {
        e.preventDefault()
        if (username?.trim() === '') {
            setUsername(null)
            return
        } else if (password?.trim() === '') {
            setPassword(null)
            return
        } else {
            handleLogin({ username, password:'', loginmethod: process.env?.REACT_APP_LOGIN_TYPE })
        }
    }


    return (
        <div className="min-h-[300px] flex items-center justify-between">
            <div className="w-[330px] relative flex h-[100px] flex-col items-center justify-between ">
                <h1 className="text-primary font-[500] ">Welcome to ProSrv360</h1>
                <img
                    width={200}
                    height={200}
                    src='/assets/login-icon.png'
                    alt="login"
                />
            </div>
            <div className="w-[430px] p-6 space-y-2 sm:p-8">
                
                {
                    process.env.REACT_APP_LOGIN_TYPE === 'prosrvusernamepwd' ?
                        <>

                            {isLogin ? <form
                                onSubmit={handleSubmit}
                                className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="username" className="block mb-2 text-sm font-[500] text-textprimary">Enter username</label>
                                    <input onChange={(e) => { setUsername(e.target.value) }}
                                        placeholder='username'
                                        type="text" name="username" id="username"
                                        className='shadow-md backdrop-blur-[5px] bg-white/70 border border-gray-300 sm:text-sm rounded-md focus:ring-0 focus:border-0 block w-full p-2' />
                                    {username === null && <small className="text-red-500">Username is required !</small>}
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-[500] text-textprimary">Enter your password</label>
                                    <input onChange={(e) => { setPassword(e.target.value) }}
                                        type={"password"}
                                        name="password" id="password"
                                        autoComplete="true"
                                        placeholder="••••••••"
                                        className='shadow-md backdrop-blur-[5px] bg-white/70 border border-gray-300 sm:text-sm rounded-md focus:ring-0 focus:border-0 block w-full p-2' />
                                    {password === null && <small className="text-red-500">Password is required !</small>}
                                </div>
                                <div className="w-full flex justify-between ">
                                    <button
                                        type="submit"
                                        className="text-white w-32 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-[500] rounded-lg text-sm px-5 py-2 text-center">
                                        Login
                                    </button>
                                    <button className="text-sm underline" onClick={() => setIsLogin(false)}>
                                        Forgot Password?
                                    </button>
                                </div>
                            </form> : <ForgotPassword setIsLogin={setIsLogin} />}
                        </> : <div className="h-full flex justify-center">
                            <div role="button" className="pr-3 h-12 shadow-sm hover:shadow-md rounded-md w-full border flex justify-between items-center " onClick={handleLogin}>
                                <img alt="ms icon" className="h-12" src={msIcon} />
                                <span  className="text-gray-900 font-semibold">Sign in with Microsoft</span>
                                <span />
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}
export default LoginForm;