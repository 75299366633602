import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState } from 'react'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'

const SkillAreaDetails = props => {
	const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props
	const [checkvalidation, setCheckvalidation] = useState(false);

	const handleSubmit = () => {
		setCheckvalidation(true);

		let item = {
			skillareamasterid: detailsData?.skillareamasterid,
			skillarea: detailsData?.skillarea,
			comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
			isactiveflag: detailsData?.isactiveflag,
			createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
			notes: detailsData?.notes,
		}

		let isValidated = CheckObjectValidation(detailsData, [
			'notes',
			'skillmasterid',
			'skill',
			'modifiedtimestamp',
			'modifiedcomuserid',
		])
		if (isValidated?.isvalid) {
			let payload = {
				skillareajson: [item],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='skillarea' className='block font-[500] mb-2'>
						Skill Area
						<span className="text-red-300"> *</span>
					</label>
					<input
						disabled={canEdit}
						type='text'
						title='skillarea'
						id='skillarea'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='Enter Skill Area'
						required=''
						onChange={(e) => {
							if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
								handleChangeInput('skillarea', e.target.value)
							}
						}}
						value={detailsData?.skillarea}
						maxLength={50}
					/>
					{!detailsData?.skillarea && checkvalidation && (
						<small className='text-red-400'>Skill Area is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				{!canEdit && (
					<div className='fixed bottom-0 right-6 mt-2'>
						<div className='gap-2 flex justify-end items-center'>
							<button
								onClick={() => setCanEdit(false)}
								type=''
								className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
							>
								Cancel
							</button>
							<PrimaryButton
								disabled={canEdit}
								className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
								onClick={handleSubmit}
							>
								Save
							</PrimaryButton>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(SkillAreaDetails)
