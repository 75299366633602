import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { detailNotRequired } from '../default'

const Details = (props) => {
    const planningState = useSelector((state) => state?.ProjectPlanning)
    const { taskGroups } = planningState
    const ProjectTimesheet = useSelector((state) => state?.ProjectTimesheet)
    let { taskType } = ProjectTimesheet
    const { detailSave, detailsData, setDetailsData } = props
    const [checkvalidation, setCheckvalidation] = useState(false)

    const taskTypeOption = useMemo(()=>{
        return taskType?.filter((task) => task.tasktype !== 'Miscellaneous' &&  task?.tasktype !== 'All')
    },[taskType])

    const taskGroupseOption = useMemo(()=>{
        return taskGroups?.filter((g) => g.taskgroupid !== 0)
    },[taskGroups])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setCheckvalidation(true)

        let isValidated = CheckObjectValidation(detailsData, detailNotRequired)
        if (isValidated?.isvalid) {
            let payload = {
                taskjson: [detailsData],
            }
            detailSave(payload)
        }
    }

    const handleChangeInput = (name, value) => {
        setCheckvalidation(false)
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label htmlFor="taskcode" className="block font-[500] mb-2">
                        Task Code
                    </label>
                    <input
                        disabled
                        maxLength={50}
                        type="text"
                        title="taskcode"
                        id="taskcode"
                        className="block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50"
                        placeholder="Task Code"
                        required=""
                        onChange={(e) => {
                            if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                                handleChangeInput('taskcode', e.target.value)
                            }
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="taskdesc" className="block font-[500] mb-2">
                        Task Name
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        maxLength={50}
                        type="text"
                        title="taskdesc"
                        id="taskid"
                        className="block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50"
                        placeholder="Task Name"
                        required=""
                        // value={detailsData?.customername || ''}
                        onChange={(e) => {
                            if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                                handleChangeInput('taskdesc', e.target.value)
                            }
                        }}
                    />
                    {!detailsData?.taskdesc && checkvalidation && (
                        <small className="text-red-400">Task Name is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="tasktype" className="block font-[500] mb-2">
                        Task Type
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={taskTypeOption || []}
                        value={detailsData?.tasktypeid || ''}
                        noDataLabel="No Task Type"
                        name="tasktypeid"
                        labelkey="tasktype"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleChangeInput}
                        id="tasktypeid"
                    />
                    {!detailsData?.tasktypeid && checkvalidation && (
                        <small className="text-red-400">Task Type is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="taskgroupdesc" className="block font-[500] mb-2">
                        Task Group
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={taskGroupseOption || []}
                        value={detailsData?.taskgroupid || ''}
                        noDataLabel="No Group"
                        name="taskgroupid"
                        labelkey="taskgroupdesc"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleChangeInput}
                        id="taskgroupid"
                    />
                    {!detailsData?.taskgroupid && checkvalidation && (
                        <small className="text-red-400">Task Group is required!</small>
                    )}
                </div>
            </div>
            <div className="fixed bottom-0 mt-2 right-6">
                <div className="flex items-center justify-end gap-2">
                    <PrimaryButton
                        type=""
                        className="px-8 py-2 mb-4 text-white rounded-lg bg-primary"
                        onClick={handleSubmit}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default memo(Details)
