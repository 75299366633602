import SingleSelectField from "src/Components/SingleSelectForTable";

const Currency = ({ options, disabled, value, rowId, handleChange }) => {

   const getSelected = (value) => {
      let e = { target: { name: 'datcomcurrencyid', value: value['datcomcurrencyid'] } }
      handleChange(e, rowId)
   }

   return (
      <div>
         <SingleSelectField
            valueKey='datcomcurrencyid'
            labelKey='currency'
            options={options}
            value={value || 0}
            handleSelected={getSelected}
            disabled={disabled} />
      </div>
   )
}

export default Currency;