import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { GET_PDP_AGING_REPORT } from 'src/Redux/actions/reports'
import { getPdpAgingReportsPayload } from 'src/Services/constant/defaultPayload'

const ReportFilter = ({
    filters,
    setFilters,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
}) => {
    const dispatch = useDispatch()
    const { bandList, companylist } = useSelector((state) => state.EmployeeDetails)
    const { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const { serviceList, usertypelist } = useSelector((state) => state.UserManagementList)

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        let payload = {
            ...getPdpAgingReportsPayload(),
        }
        dispatch(GET_PDP_AGING_REPORT(payload))
        setFilters(payload)
    }

    const applyFilters = () => {
        dispatch(GET_PDP_AGING_REPORT(filters))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }
    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <>
                    <div>
                        <h1 className="mb-2 font-[500]">Company</h1>
                        <CustomhtmlSelect
                            options={companylist || []}
                            value={filters?.companyid}
                            noDataLabel="No Company"
                            name="companyid"
                            labelkey="companyname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="companyid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Emp Type</h1>
                        <CustomhtmlSelect
                            options={usertypelist || []}
                            value={filters?.usertype}
                            noDataLabel="No Type"
                            name="usertype"
                            labelkey="usertype"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="datusertypeid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Service Line</h1>
                        <CustomhtmlSelect
                            options={serviceList || []}
                            value={filters?.servicelineid}
                            noDataLabel="No Serviceline"
                            name="servicelineid"
                            labelkey="servicelinename"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="servicelineid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Band</h1>
                        <CustomhtmlSelect
                            options={bandList || []}
                            value={filters?.bandid}
                            noDataLabel="No band"
                            name="bandid"
                            labelkey="bandname"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleSelectChange}
                            id="bandmasterid"
                        />
                    </div>
                </>
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportFilter
