import { useEffect, useState } from "react";

const TimeInput = ({ onChange, value, name }) => {
    const [hour, setHour] = useState('')
    const [min, setMin] = useState('')

    useEffect(() => {
        if ((min === '' || hour === '') && value?.length > 0) {
            let [hour, min] = value.split(":");
            setMin(min)
            setHour(hour)
        }
        // eslint-disable-next-line 
    }, [value])

    useEffect(() => {
        if (min !== '' || hour !== '') {
            onChange(name, `${hour}:${min}`)
        }
        // eslint-disable-next-line 
    }, [min, hour])

    return <div className="outline-none flex justify-start items-center space-x-2  bg-gray-50 border border-gray-300 text-textSecondary rounded focus:ring-primary focus:border-primary w-full p-[8px]">
        <input type='text'
            maxLength={2}
            value={hour}
            onChange={(e) => {
                if (/^[0-9]*$/.test(e.target?.value) && Number(e.target?.value) <= 24) {
                    setHour(e.target?.value)
                }
            }}
            className='outline-none w-1/2 text-sm bg-transparent'
            placeholder='HH' />
        <span>:</span>
        <input
            value={min}
            maxLength={2}
            onChange={(e) => {
                if (/^[0-9]*$/.test(e.target?.value) && Number(e.target?.value) <= 59) {
                    setMin(e.target?.value)
                }
            }}
            className='outline-none w-1/2 bg-transparent' type='text' placeholder='MM' />
    </div>
}

export default TimeInput;