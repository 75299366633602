import PrimaryButton from "src/Components/Buttons/PrimaryButton";
import CustomerInfoDynamicForm from "./customerInfoDynamicForm"
import SelectDm from "./dm";
import SelectPm from "./pm";
import SelectPmo from "./pmo";

const Contacts = ({ hooks }) => {
    let {
        errorFound,
        addProject,
        pm, setPm,
        dm, setDm,
        setPmo, pmo,
        // payload, setPayload,
        serviceLine,
        businessUnit,
        selectedContacts, setSelectedContacts,
    } = hooks
    return (
        <div className="mt-4 w-full text-sm">
            <div className=" h-[60vh] w-full pb-10 overflow-y-scroll">
                <div className="grid grid-cols-3 gap-8">
                    <div>
                        <SelectPm pm={pm} setPm={setPm} />
                        {errorFound?.keyname === 'projectmanagerid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <SelectDm dm={dm} setDm={setDm} />
                        {errorFound?.keyname === 'deliverymanagerid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <SelectPmo pmo={pmo} setPmo={setPmo} />
                    <div>
                        <label htmlFor="Buniessunithead" className="block mb-2 text-sm font-[500]">Business Unit Head</label>
                        <div className='border rounded bg-gray-50 p-1.5 text-sm font-[400]'>
                            {businessUnit?.buhead?.trim() || 'N/A'}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="serviceLine" className="block mb-2 text-sm font-[500]">Service Line Head</label>
                        <div className='border rounded bg-gray-50 p-1.5 text-sm font-[400]'>
                            {serviceLine?.servicelinehead?.trim() || 'N/A'}
                        </div>
                    </div>
                </div>
                <CustomerInfoDynamicForm hooks={{ selectedContacts, setSelectedContacts }} />
            </div>
            <div className='flex justify-end'>
                <PrimaryButton onClick={addProject} className='text-white rounded py-2'>
                    Save
                </PrimaryButton>
            </div>
        </div>
    )
}

export default Contacts;