import Actionheader from "./ActionHeader"
import BaseInfo from "./baseInfo"
import Tabs from "./tabs"
import { memo, useEffect, useMemo, useState } from "react"
import { GET_TIMESHEET_PROJECTS_M } from "src/Redux/actions/projects/timesheet"
import { useDispatch, useSelector } from "react-redux"
import { GET_LEVEL_MASTER } from "src/Redux/actions/workforce/eprofile"
import { getDeafultProficiencyData, getDefaultCurrencyPayload, getBudgetofProjectPayload, getTenantCompanyWeedayData, getExchangeRatePayload } from "src/Services/constant/defaultPayload"
import { defaultItem } from "./roles/defaultData"
import { GET_CUSTOMER_ROLE_BY_ID, GET_LIST_OF_PROJECT_ROLE, GET_BUDGET_OF_PROJECT } from "src/Redux/actions/projects/planning"
import { defaultItemOfMileStone } from './milestone/defautVar'
import { GET_CURRENCY_MASTER_LIST } from "src/Redux/actions/projects"
import { budgetRow } from './budget/defaultItems'
import { useLocation, useNavigate } from "react-router-dom"
import { GET_COMPANY_WEEKDAYS, GET_COST_RATES, GET_EXCHANGE_RATE_MASTER, GET_LIST_OF_STATUS_BY_E } from "src/Redux/actions/masterdata/user"
import { calculatEstdEffortHrs, getPermissionsOfCurrentUrl, getQuarterDates, getWorkingDaysStartAndEndDate } from "src/Services"
import { GET_OVERHEAD_MASTER } from "src/Redux/actions/projects/financials"
import { getExpenseOverheadPayload } from "../../Financials/expenses/deafultPayload"
import { getDefaultCostRates } from "src/Services/constant/masterdatapayload"
import { ToastContainer } from "react-toastify"


const Plannings = ({ modulename, menu }) => {
    const [roles, setRoles] = useState([])
    const [thisPagePermissions, setThisPagePermissions] = useState(null)
    const { projectsOfM } = useSelector(state => state?.ProjectTimesheet)
    const { getListOfProjectRole, getMilestoneByproject, getBudgetByproject } = useSelector(state => state?.ProjectPlanning)
    const { ProjectOverhead } = useSelector(state => state?.ProjectFinancial)
    const { companyWeekdaysList } = useSelector((state) => state.UserManagementList);
    const dispatch = useDispatch()
    const navigator = useNavigate()
    const [project, setProject] = useState({})
    const [budget, setBudget] = useState([])
    const [milestone, setMilestone] = useState([])
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const staffingProjectId = searchParams.get('projectid')
    const activetab = searchParams.get('activetab')
    const [errorInPeopleCost, setErrorInPeopleCost] = useState(false)
    const [activeTab, setActiveTab] = useState(activetab || 'Phases')

    const fetchAllProjectList = async () => {
        let res = await dispatch(GET_TIMESHEET_PROJECTS_M())
        if (res?.length > 0) {
            if (staffingProjectId) {
                let p = res?.find(p => p?.projectid === +staffingProjectId)
                setProject(p)
                // navigator(`/app/projects/planning?projectid=${staffingProjectId}&AppliedFilters=null&activetab=${activeTab}`)
            } else {
                navigator(`/app/projects/planning?projectid=${res[0]?.projectid}&AppliedFilters=null&activetab=${activeTab}`)
                setProject(res[0])
            }
        }
    }

    useEffect(() => {
        fetchAllProjectList()
        dispatch(GET_OVERHEAD_MASTER(getExpenseOverheadPayload()))
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData('C')))
        // dispatch(GET_TIMESHEET_PROJECTS_M())
        dispatch(GET_LIST_OF_STATUS_BY_E())
        // eslint-disable-next-line
    }, [dispatch])


    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(item => item?.permissionname);
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true; // Track whether the component is mounted

        if (getListOfProjectRole?.length > 0) {
            const debounceTimer = setTimeout(() => {
                findTotalEstCostOfPeople()
            }, 1500)
            return () => {
                clearTimeout(debounceTimer)
            }
        } else {
            if (isMounted) {
                setRoles([defaultItem()])
            }
        }

        return () => {
            isMounted = false; // Clean up the flag when the component unmounts
        };
        // eslint-disable-next-line
    }, [getListOfProjectRole])

    useMemo(() => {
        if (getMilestoneByproject?.length > 0) {
            setMilestone(getMilestoneByproject)
        } else {
            setMilestone([defaultItemOfMileStone(project?.defaultcomcurrencyid)])
        }
        // eslint-disable-next-line
    }, [getMilestoneByproject])

    useEffect(() => {
        if (project) {
            dispatch(GET_LIST_OF_PROJECT_ROLE(project?.projectid, 0))
            dispatch(GET_CUSTOMER_ROLE_BY_ID(project?.customerid))
            dispatch(GET_BUDGET_OF_PROJECT(getBudgetofProjectPayload(10, 1, project?.projectid)), null)
            dispatch(GET_COMPANY_WEEKDAYS(getTenantCompanyWeedayData(project?.companyid)));
            // setProject(p)
        }
        // eslint-disable-next-line
    }, [project])

    useMemo(() => {
        if (getBudgetByproject?.length > 0) {
            setBudget(getBudgetByproject)
        } else {
            setBudget([{ ...budgetRow(), datcomcurrencyid: project?.defaultcomcurrencyid }])
        }
    }, [getBudgetByproject, project])

    const findTotalEstCostOfPeople = () => {
        let costratesList = null
        let costRatePaylaod = null
        let items = getListOfProjectRole?.map((it) => {
            let roleInfo = { ...it }
            let po = roleInfo?.peopleno
            let al = roleInfo?.allocation
            let hr = 0
            let wday = getWorkingDaysStartAndEndDate(roleInfo?.startdate, roleInfo?.enddate)
            if (companyWeekdaysList?.length > 0) {
                hr = companyWeekdaysList[0]?.noofhoursperday
            }
            roleInfo['plannedeffort'] = calculatEstdEffortHrs(po, al, hr, wday)

            async function startAsyncCalculation() {
                if (project?.companyid) {
                    let noofhoursperday = companyWeekdaysList[0]?.noofhoursperday
                    let currencyto = project?.defaultcomcurrencyid
                    let conversionValue = 0 // cost rate multiply by no of hours per day ( converting into hours  )

                    let payload = {
                        ...getDefaultCostRates(),
                        Companyid: project?.companyid,
                        Startdate: getQuarterDates()?.startDate,
                        Enddate: getQuarterDates()?.endDate,
                        Bandid: roleInfo?.max_bandid,
                        Skillareamasterid: roleInfo?.skillareamasterid,
                        techgroupid: roleInfo?.techgroupid,
                        countryid: roleInfo?.countryid

                    }

                    let costrate = 0
                    let unitname = null
                    let conversionrate = 0
                    let noDataErr = false

                    if (JSON.stringify(costRatePaylaod) !== JSON.stringify(payload)) {
                        costratesList = await dispatch(GET_COST_RATES(payload, noDataErr))
                        costRatePaylaod = payload
                    }

                    if (costratesList) {
                        costrate = costratesList[0]?.cost
                        unitname = costratesList[0]?.unitname

                        let currencyfrom = costratesList[0]?.comcurrencyid
                        // get exchange rate if project and role costrate currency is diff ...
                        if (currencyto !== currencyfrom) {
                            let data = {
                                ...getExchangeRatePayload(),
                                currencyfrom,
                                currencyto,
                                Startdate: getQuarterDates()?.startDate,
                                Enddate: getQuarterDates()?.endDate,
                            }
                            let exchangeratesList = await dispatch(GET_EXCHANGE_RATE_MASTER(data, noDataErr));

                            if (exchangeratesList?.data?.length > 0) {
                                conversionrate = exchangeratesList?.data[0]?.conversionrate || 0
                            }
                        }
                    }

                    /*  Convert cost rate into hours  */
                    if (unitname === 'Monthly') {
                        conversionValue = (costrate / (22 * noofhoursperday))
                    } else if (unitname === 'Weekly') {
                        conversionValue = (costrate / (5 * noofhoursperday))
                    } else if (unitname === 'Daily') {
                        conversionValue = costrate / noofhoursperday
                    }

                    let x = conversionValue
                    // if we have conversion rate then multiply conversionValue
                    if (conversionrate !== 0) {
                        x = (conversionValue * conversionrate).toFixed(2)
                    }
                    roleInfo['estPeopleCost'] = (x * roleInfo?.plannedeffort).toFixed(0)
                    if (costrate <= 0 || conversionrate <= 0) {
                        setErrorInPeopleCost(true)
                        roleInfo['estPeopleCost'] = 0
                    }
                }
            }
            startAsyncCalculation()
            return roleInfo

        })
        setRoles(items)
    }

    const selectProject = (p) => {
        navigator(`/app/projects/planning?projectid=${p?.projectid}&AppliedFilters=null&activetab=${activeTab}`)
        setProject(p)
    }

    return (
        <>
            <div className="w-full h-full">
                <Actionheader
                    hooks={{
                        projectsOfM,
                        project, selectProject,
                    }}
                />
                {project && <BaseInfo project={project} />}
                {project?.projectid && <>
                    <div className="relative mt-4 h-[calc(100%-200px)] bg-white border rounded-md shadow-md">
                        <Tabs
                            hooks={{
                                thisPagePermissions,
                                errorInPeopleCost, setErrorInPeopleCost,
                                roles, setRoles,
                                budget, setBudget,
                                setActiveTab,
                                milestone, setMilestone,
                                project,
                                activeTab,
                                ProjectOverhead,
                            }}
                        />
                    </div>
                </>}
            </div>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default memo(Plannings)