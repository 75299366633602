import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { BusinessGroupItem } from './default';
import CreateBusinessGroup from './createBusinessGroup';
import EditBusinessGroup from './editBusinessGroup';
import { GET_BUSINESSGROUP_MASTER } from 'src/Redux/actions/masterdata/user';
import { getBusinessUnitGroupData } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';


const cols = ['Business Unit Group', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const BusinessGroupMasterList = () => {
  const [businessGroup, setBusinessGroup] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [bugId, setBugId] = useState(null);
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(BusinessGroupItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });

  const dispatch = useDispatch();
  const { businessGroupList, loading: businessGroupLoading,PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);
 
  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(businessGroupLoading); // Hide the loader after a delay
    }, 100);
  }, [businessGroupLoading]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_BUSINESSGROUP_MASTER({ ...getBusinessUnitGroupData(), sorttype: item.id, status: status?.label, limit: limit }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  useMemo(() => {
    if (businessGroup?.businessunitgroupname) {
      setSearchTerm(businessGroup?.businessunitgroupname);
    }
  }, [businessGroup]);

  const handleBuClick = (bug) => {
    setDetailsData(bug);
    setBugId(bug?.businessunitgroupid);
    setIsOpen(true);
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_BUSINESSGROUP_MASTER({ ...getBusinessUnitGroupData(), status: item.label }));
    if (res === null) {
      setDetailsData(res?.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(GET_BUSINESSGROUP_MASTER({ ...getBusinessUnitGroupData(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    await dispatch(GET_BUSINESSGROUP_MASTER({ ...getBusinessUnitGroupData(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }));
  };

  return (
    <div className='w-full h-screen p-1 py-2 mt-4 '>
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center justify-start space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Business Unit Groups ({businessGroupList?.length > 0 ? businessGroupList[0]?.totalcount : businessGroupList?.length}){' '}
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_BUSINESSGROUP_MASTER}
              searchPayload={{ ...getBusinessUnitGroupData(), searchname: searchTerm }}
              setSearchTerm={setSearchTerm}
              options={businessGroupList}
              idkey='businessunitgroupname'
              optionlabelKey='businessunitgroupname'
              getSelectedOption={setBusinessGroup}
              placeholder='Enter Business Unit Group Name'
              className='p-2'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={businessGroupList?.length > 0 ? businessGroupList[0]?.totalcount : businessGroupList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {(PermissionsOfMasterdata.includes('Add/Edit Business Unit') )? <CreateBusinessGroup/>:""}
      </div>
      <div className='overflow-scroll h-[50vh] '>
        {loading || businessGroupList === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          businessGroupList && (
            <table className='relative w-full text-sm'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {businessGroupList?.map((item, i) => {
                  let {
                    businessunitgroupname,
                    businessunitgroupid,
                    isactiveflag,
                    createduser,
                    createdtimestamp,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleBuClick(item)}
                      key={businessunitgroupid}
                      className='cursor-pointer text-left'>
                      <td className='border px-2 py-1.5'><div>{businessunitgroupname}</div></td>
                      <td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='border px-2'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                      <td className='border px-2'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditBusinessGroup
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        businessunitgroupid={bugId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfMasterdata}
      />
    </div>
  );
};

export default memo(BusinessGroupMasterList);
