export const insertDemandSkillExpertise = () => {
	return {
		createdtimestamp: "",
		demandskillid: null,
		demandid: 0,
		skillmasterid: 0,
		technologymasterid: null,
		domainid: null,
		importance: 0,
		expertise: '',
		comtenantid: Number(localStorage.getItem('Tenantid') || 0),
		isactiveflag: true,
		createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
		modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
		uId:
			Math.random().toString(36).substr(2, 9) +
			new Date().getTime().toString(36),
	}
}
