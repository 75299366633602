import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';

const Details = (props) => {
  const { technologylist } = useSelector((state) => state.EmployeeDetails);
  const {
    detailSave,
    detailsData,
    setDetailsData,
    canEdit,
    setCanEdit,
    productmasterid,
  } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let item = {
      productmasterid: productmasterid,
      productname: detailsData?.productname,
      technology: detailsData?.technologyid,
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
    };

    let isValidated = CheckObjectValidation(detailsData, [
      'notes',
      'techgroupid',
      'modifiedtimestamp',
      'modifiedcomuserid',
    ]);
    if (isValidated?.isvalid) {
      let payload = {
        productjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='productname' className='block font-[500] mb-2'>
            Product Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='productname'
            id='productname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Enter Product Name'
            required=''
            onChange={(e) => handleChangeInput('productname', e.target.value)}
            value={detailsData?.productname || ''}
            maxLength={50}
          />
          {!detailsData?.productname && checkvalidation && (
            <small className='text-red-400'>Product Name is required!</small>
          )}{' '}
        </div>
        <div>
          <label htmlFor='technology' className='block font-[500] mb-2'>
            Technology
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={technologylist || []}
            value={detailsData?.technologyid}
            noDataLabel='No tech'
            name='technologyid'
            labelkey='technology'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            onChange={handleChangeInput}
            id='technologymasterid'
          />
          {!detailsData?.technologyid && checkvalidation && (
            <small className='text-red-400'>Technology is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <PrimaryButton
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white text-black  border-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Details);
