import { useEffect, useState } from "react";
import TimesheetEmployee from "./employee";
import TimesheetManager from "./manager";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { ACTION_GET_TIMESHEET_USERLIST } from "src/Redux/types";


const TimesheetApp = () => {
    const [timesheetView, setTimesheetView] = useState('Employee')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: ACTION_GET_TIMESHEET_USERLIST, data: [] })
        // eslint-disable-next-line 
    }, [])

    return (
        <>
            {timesheetView === 'Employee' ? <TimesheetEmployee
                setTimesheetView={setTimesheetView}
                timesheetView={timesheetView}
            /> : <TimesheetManager setTimesheetView={setTimesheetView}
                timesheetView={timesheetView} />}
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default TimesheetApp;