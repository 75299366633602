import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ReportsFilter from 'src/Filter/reports/workforce/capacityWorkforce'
import { GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects'
import { GET_CAPACITY_REPORT, GET_REPORT_MASTER_LIST } from 'src/Redux/actions/reports'
import {
    GET_INSUDTRY_MASTER,
    GET_SKILL_MASTER,
} from 'src/Redux/actions/workforce/eprofile'
import {
    GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    GET_CITY_MASTER,
    GET_COMPANY_MASTER,
    GET_DESIGNATION_MASTER,
    GET_WORKERTYPE_MASTER,
    GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import {
    getCityPayload,
    getDeafultIndustryData,
    getDeafultSkillData,
    getDefaultStatusPayload,
    getDesignationMasterIdData,
    getStatusMasterIdData,
    getTenantCompanyIdData,
    getTenantWorkForceyIdData,
    getTenantWorkerTypeyIdData,
    getWorkForceReportsPayload,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import ReportList from './table'

const ReportsDetails = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getWorkForceReportsPayload())
    const dispatch = useDispatch()
    const [capacity, setCapacity] = useState([])
    const [user, setUser] = useState({})
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    const { capacityreport, reportMasterList } = useSelector(
        (state) => state?.WorkforceReport,
    )

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    useEffect(() => {
        dispatch(GET_CITY_MASTER(getCityPayload()))
        dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
        dispatch(GET_CAPACITY_REPORT(getWorkForceReportsPayload()))
        dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_DESIGNATION_MASTER(getDesignationMasterIdData()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))
        dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E')))
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <ReportsFilter
                    reportId={reportId}
                    setReportId={setReportId}
                    setFilters={setFilters}
                    filters={filters}
                    setCapacity={setCapacity}
                    capacity={capacity}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    setLimit={setLimit}
                    user={user}
                    setUser={setUser}
                />
            </div>
            <ReportList
                capacityreport={capacityreport}
                filters={filters}
                reportId={reportId}
                capacity={capacity}
                setCapacity={setCapacity}
                reportMasterList={reportMasterList}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(ReportsDetails)
