import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FinanceReportFilter from 'src/Filter/reports/finance'
import {
    GET_CUSTOMER_GROUP_MASTER,
    GET_DM_LIST,
    GET_SERVICELINE_MASTER,
} from 'src/Redux/actions/masterdata/user'
import {
    GET_CUSTOMER_LIST,
    GET_LIST_OF_STATUS,
    GET_PROJECT_LIST_BILLING_TYPE,
} from 'src/Redux/actions/projects'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import { GET_FINANCE_REPORT, GET_REPORT_MASTER_LIST } from 'src/Redux/actions/reports'
import { getCustomerRoleListPayload } from 'src/Services/constant/Customerpayload'
import {
    getDefaultBillingIdPayload,
    getDefaultCustomerPayload,
    getDefaultStatusPayload,
    getFinanceReportPayload,
    getUserAccessPayload,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import ProjectFinancesReport from './table'

const FinanceReports = ({ reportId, setReportId }) => {
    const [filters, setFilters] = useState(getFinanceReportPayload())
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    let { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    let { reportMasterList } = useSelector((state) => state?.WorkforceReport)
    const [project, setProject] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const projectIds = projectsOfM?.map((project) => project.projectid)

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    useEffect(() => {
        dispatch(GET_TIMESHEET_PROJECTS_M())
        dispatch(GET_CUSTOMER_LIST(getDefaultCustomerPayload()))
        dispatch(GET_CUSTOMER_GROUP_MASTER(getCustomerRoleListPayload()))
        dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('P')))
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_FINANCE_REPORT(getFinanceReportPayload()))
        dispatch(GET_DM_LIST(getUserAccessPayload()))
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <FinanceReportFilter
                    projectsOfM={projectsOfM}
                    filters={filters}
                    setFilters={setFilters}
                    project={project}
                    setProject={setProject}
                    reportId={reportId}
                    setReportId={setReportId}
                    page={page}
                    limit={limit}
                />
            </div>
            <ProjectFinancesReport
                projectsOfM={projectsOfM}
                projectIds={projectIds}
                filters={filters}
                project={project}
                setProject={setProject}
                reportMasterList={reportMasterList}
                reportId={reportId}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(FinanceReports)
