import { postMethodApiCall } from 'src/Services/api'
import { AlertError, AlertSuccess } from 'src/Services'
import { ACTION_GET_DELIVERY_ROLE_MASTER_BY_ID } from 'src/Redux/types';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER;

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: Number(localStorage.getItem('comuserid') || 0),
    }
}
const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
    }
}

export const GET_DELIVERY_ROLE_MASTER_BY_ID = () => {
    return async (dispatch) => {
        try {
            let payload = {
                deliveryroleid: null,
                Tenantid: getTanAndComId()?.Tenantid,
                searchname: '',
                status: 'ACTIVE',
                sorttype: 1
            }
            const url = `${baseUrlMaster}/get_deliveryrolemaster`;
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
            if ((res?.status) === 200) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_DELIVERY_ROLE_MASTER_BY_ID,
                    data: addnewkey || [],
                })
                return addnewkey
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '');
        }
    };
};

export const ADD_RATE_OF_CUSTOMER = (payload) => {
    return async () => {
        try {
            const url = `${baseUrl}/insert_customerrates`;
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const customerid = item?.customerid;
                if (item?.Message === 'Success') {
                    AlertSuccess('Rate saved successfully!');
                    return { success: true, customerid };
                } else {
                    AlertError(item?.Message || 'Something went wrong. Please try later.')
                }
                return { success: true, customerid };
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '');
        }
    };
};

export const UPDATE_RATE_OF_CUSTOMER = (data, customerid) => {
    return async () => {
        try {
            let afterModify = data?.map((item) => {
                let info = { ...item }
                delete info['uId']
                delete info['RateUnit']
                // delete info['croleid']
                // delete info['levelid']
                delete info['isonsite']
                info['RateUnit'] = Number(info?.rateunit)
                delete info['rateunit']
                info['bill_hr'] = Number(info['bill_hr'])
                info['customerid'] = customerid
                return info
            })

            let payload = { customerjson: afterModify }
            const url = `${baseUrl}/insert_customerrates`;
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const customerid = item?.customerid;
                const custroleid = item?.custroleid;
                if (item?.Message === 'Success') {
                    return { success: true, customerid, custroleid };
                } else {
                    AlertError(item?.Message || 'Something went wrong. Please try later.')
                }
                return { success: true, customerid, custroleid };
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '');
        }
    };
};
