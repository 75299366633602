import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { GET_DEMAND_SUMMARY_REPORT } from 'src/Redux/actions/reports'
import { getDemandSummaryReportsPayload } from 'src/Services/constant/defaultPayload'
import SelectLocation from './selectLocation'
const requestTypeslist = [
    { requesttype: 'Internal', id: 'I' },
    { requesttype: 'Opportunity', id: 'O' },
    { requesttype: 'Project', id: 'P' },
]
const prioritiesList = [
    { priority: 'Low', id: 'L' },
    { priority: 'Medium', id: 'M' },
    { priority: 'High', id: 'H' },
]

const ReportFilter = ({
    filters,
    setFilters,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
}) => {
    const dispatch = useDispatch()
    const { companylist, allocationstatuslist } = useSelector(
        (state) => state.EmployeeDetails,
    )
    const { skillList } = useSelector((state) => state.EmployeeProfile)
    const { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const { serviceList } = useSelector((state) => state.UserManagementList)
    const [location, setLocation] = useState({})
    const [reset, setReset] = useState(false)

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            locationid: location?.comcityid || 0,
        }))
    }, [location, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        let payload = {
            ...getDemandSummaryReportsPayload(),
            locationid: 0,
        }
        setLocation({})
        dispatch(GET_DEMAND_SUMMARY_REPORT(payload))
        setFilters(payload)
        setReset(true)
    }

    const applyFilters = () => {
        dispatch(GET_DEMAND_SUMMARY_REPORT(filters))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <>
                    <div>
                        <h1 className="mb-2 font-[500]">Company</h1>
                        <CustomhtmlSelect
                            options={companylist || []}
                            value={filters?.companyid}
                            noDataLabel="No Company"
                            name="companyid"
                            labelkey="companyname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="companyid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Request Type</h1>
                        <CustomhtmlSelect
                            options={requestTypeslist || []}
                            value={filters?.requesttype}
                            noDataLabel="No request type"
                            name="requesttype"
                            labelkey="requesttype"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="id"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Skill Area</h1>
                        <CustomhtmlSelect
                            options={skillList || []}
                            value={filters?.skillareaid}
                            noDataLabel="No Skill"
                            name="skillareaid"
                            labelkey="skillarea"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="skillareamasterid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Demand Status</h1>
                        <CustomhtmlSelect
                            options={allocationstatuslist || []}
                            value={filters?.demandstatusid}
                            noDataLabel="No Demand Status found"
                            name="demandstatusid"
                            labelkey="statusname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="statusid"
                        />
                    </div>

                    <div>
                        <h1 className="mb-2 font-[500]">Service Line</h1>
                        <CustomhtmlSelect
                            options={serviceList || []}
                            value={filters?.servicelineid}
                            noDataLabel="No Serviceline"
                            name="servicelineid"
                            labelkey="servicelinename"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="servicelineid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Priority</h1>
                        <CustomhtmlSelect
                            options={prioritiesList || []}
                            value={filters?.priority}
                            noDataLabel="No Priorities"
                            name="priority"
                            labelkey="priority"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="id"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]"> Location</h1>
                        <SelectLocation
                            location={location}
                            setLocation={setLocation}
                            reset={reset}
                        />
                    </div>
                </>
                <div />
                <div />
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                                setReset(false)
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportFilter
