import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useEffect,useState } from 'react'
import SelectDepartmentHead from './selectDepartmentHead'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = props => {
	const { canEdit, setDetailsData, detailsData, detailSave, setCanEdit, setAccountSelect, accountSelect } = props
	const [checkvalidation, setCheckvalidation] = useState(false);

	useEffect(() => {
		setDetailsData({
			...detailsData,
		})
		setAccountSelect({
			comuserid: detailsData?.departmentheadid,
			firstname: detailsData?.departmentheadname
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setAccountSelect])

	const handleSubmit = () => {
		setCheckvalidation(true);

		let item = {
			comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
			departmentid: detailsData?.departmentid,
			costcenter_id: detailsData?.costcenter_id,
			isactiveflag: detailsData?.isactiveflag,
			createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
			notes: detailsData?.notes,
			departmentheadid: accountSelect?.comuserid,
			department: detailsData?.department
		}
		let isValidated = CheckObjectValidation(detailsData, ['modifiedcomuserid','notes','costcenter_id'])
			if (isValidated?.isvalid) {


		let payload = {
			departmentjson: [item],
		}
		detailSave(payload)
	}
	};
	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	const handleSelectManager = (select) => {
		setAccountSelect(select);
		setDetailsData({ ...detailsData, departmentheadid: select.comuserid });
	};

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='departmentcode' className='block font-[500] mb-2'>
						Department Code
					</label>
					<input
						disabled
						maxLength={50}
						type='text'
						title='departmentcode'
						id='departmentcode'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
						placeholder='Deaprtment Code'
						required=''
						value={detailsData?.departmentcode || ''}
						onChange={(e) => {
							if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
								handleChangeInput('departmentcode', e.target.value);
							}
						}} />
				</div>
				<div>
					<label htmlFor='department' className='block font-[500] mb-2'>
						Department
						<span className="text-red-300"> *</span>
					</label>
					<input
						disabled={canEdit}
						type='text'
						title='department'
						id='department'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='Enter a department'
						required=''
						value={detailsData?.department || ''}
						onChange={(e) => {
							if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
								handleChangeInput('department', e.target.value)
							}
						}}
						maxLength={50}
					/>
					 {!detailsData?.department && checkvalidation && (
            <small className='text-red-400'>Department is required!</small>
          )}
				</div>
				<div>
					<label
						htmlFor='departmentheadid'
						className='block font-[500] mb-2'
					>
						Department Head
						<span className="text-red-300"> *</span>
					</label>
					<div className='relative'>
						<SelectDepartmentHead
							canEdit={canEdit}
							accountSelect={accountSelect}
							setAccountSelect={setAccountSelect}
							handleSelectManager={handleSelectManager}
						/>
						    {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>Department Head is required!</small>
            )}
					</div>
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				{!canEdit && (
					<div className='fixed bottom-0 right-6 mt-2'>
						<div className='gap-2 flex justify-end items-center'>
							<button
								onClick={() => setCanEdit(false)}
								type=''
								className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
							>
								Cancel
							</button>
							<PrimaryButton
								disabled={canEdit}
								className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
								onClick={handleSubmit}
							>
								Save
							</PrimaryButton>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(Details)
