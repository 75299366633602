
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const TooltipCom = ({ children, title, id }) => {
    return (
        <div>
            <span data-tooltip-id={id} data-tooltip-content={title}>
                {children}
            </span>
            <Tooltip 
            border={`1px solid ${process.env.REACT_APP_color_secondary}`}
            style={{
                zIndex:1000,
                color: process.env.REACT_APP_color_secondary,
                background:'#fff',
                // border: `1px solid ${process.env.REACT_APP_color_secondary}`
            }} id={id} className='shadow' />
        </div>
    )
}
export default TooltipCom;