import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import { detailItem } from './defaultRowAndData';
import CreateSubscription from './index';
import EditSubscription from '../editSubscription/editSubscription';
import { GET_SUBSCRIPTIONS_MASTER } from 'src/Redux/actions/subscriptions/index';
import { getSubscriptionsPayload } from 'src/Services/constant/defaultPayload';
import { basicShortList } from 'src/Services/constant/staticValues'

const cols = ['Subscription Name', 'Description', 'Modules', 'Price/ User/ Month', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const SubscriptionManagementList = () => {
  const [subscription, setSubscription] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [comsubscriptionId, setComsubscriptionId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(detailItem());
  const [shortType, setShortType] = useState({ label: 'Latest First', id: 3 });
  const dispatch = useDispatch();
  const { subscriptionList, loading: subscriptionLoading } = useSelector((state) => state.SubscriptionList);

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(subscriptionLoading); // Hide the loader after a delay
    }, 100);
  }, [subscriptionLoading]);

  useEffect(() => {
    setLimit(10)
  }, []);

  const handleSorting = async (item) => {
    setShortType(item);
    let res = await dispatch(GET_SUBSCRIPTIONS_MASTER({ ...getSubscriptionsPayload(), sorttype: item.id, status: status?.label, limit: limit }));
    if (res !== null) {
      setSubscription(res);
    }
  };

  useEffect(() => {
    if (subscription?.packagename) {
      setSearchTerm(`${subscription?.packagename}`);
    }
  }, [subscription]);

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_SUBSCRIPTIONS_MASTER({ ...getSubscriptionsPayload(), status: item.label, }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(GET_SUBSCRIPTIONS_MASTER({ ...getSubscriptionsPayload(), limit: limit, page: page?.selected + 1, sorttype: shortType?.id, status: status?.label }));
    if (res?.success) {
      setSubscription(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    setPage(1); // Reset the page to the first page when the page limit is changed
    let res = await dispatch(GET_SUBSCRIPTIONS_MASTER({ ...getSubscriptionsPayload(), limit: row, page: 1, sorttype: shortType?.id, status: status?.label, }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  const handleCustomerClick = (subscription) => {
    setDetailsData(subscription);
    setComsubscriptionId(subscription?.datsubscriptionpackageid);
    setIsOpen(true);
  };

  return (
    <div className='w-full h-screen py-3 mt-2'>
      <div className='flex items-center justify-between mb-2 mt-4'>
        <div className='flex items-center justify-start space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Subscription Details ( {subscriptionList?.length > 0 ? subscriptionList[0]?.totalcount : subscriptionList?.length} )
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_SUBSCRIPTIONS_MASTER}
              searchPayload={{ ...getSubscriptionsPayload(), searchname: searchTerm, status: status?.label }}
              setSearchTerm={setSearchTerm}
              options={subscriptionList}
              idkey='packagename'
              optionlabelKey='packagename'
              getSelectedOption={setSubscription}
              placeholder='Enter Subscription Name'
              className='p-2'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={subscriptionList?.length > 0 ? subscriptionList[0]?.totalcount : subscriptionList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={shortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        <CreateSubscription />
      </div>
      <div className="h-[56vh] overflow-scroll">
        {loading || subscriptionList === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          subscriptionList && (
            <table className='relative w-full text-sm'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] px-2 py-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {subscriptionList?.map((item, i) => {
                  let {
                    packagename,
                    datsubscriptionpackagemodule,
                    notes,
                    priceperuser,
                    activeflag,
                    createdtimestamp,
                    createduser,
                    uId,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleCustomerClick(item)}
                      key={`${uId}-${i}`}
                      className='text-left cursor-pointer'>
                      <td className='border px-2 py-1.5'><div>{packagename}</div></td>
                      <td className='border px-2 py-1.5'><div>{notes}</div></td>
                      <td className='border px-2 py-1.5'><div><div>{datsubscriptionpackagemodule.length > 1 ? `${datsubscriptionpackagemodule[0].modulename} / ${datsubscriptionpackagemodule[1].modulename} + ${datsubscriptionpackagemodule.length - 2} more` : datsubscriptionpackagemodule[0].modulename} </div></div></td>
                      <td className='border px-2 py-1.5'><div>{priceperuser + ' INR'}</div></td>
                      <td className='border px-2'><div>{activeflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='border px-2'><div>{createdtimestamp}</div></td>
                      <td className='border px-2'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditSubscription
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        datsubscriptionpackageid={comsubscriptionId}
        page={page}
        limit={limit}
        shortType={shortType}
      />
    </div>
  );
};

export default SubscriptionManagementList;