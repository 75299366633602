import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { Combobox, Transition } from '@headlessui/react'

const SearchCustomerGroup = ({ setSelected, selected, Options }) => {
    const [query, setQuery] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    useMemo(() => {
        if (selected?.customergroupname) {
            setIsOpen(false)
        }
        // eslint-disable-next-line
    }, [selected])

    const filteredItems =
        query === ''
            ? Options
            : Options?.filter((customer) =>
                  customer?.customergroupname
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, '')),
              )

    const handleInputClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Combobox value={selected} onChange={setSelected}>
                <div className="relativemt-1">
                    <div
                        className="relative flex items-center justify-start w-full p-0.5 overflow-hidden text-left border rounded cursor-default bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                        onClick={handleInputClick}
                    >
                        <Combobox.Input
                            placeholder="Enter Customer Name"
                            className="placeholder:font-[400] placeholder:text-gray-600 w-full bg-transparent outline-none border-none py-1.5 px-2 space-x-2 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={(customer) => customer?.customergroupname}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <svg
                            className="w-6 h-6"
                            fill="text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            height="48"
                            viewBox="0 96 960 960"
                            width="48"
                        >
                            <path d="M782 892 528 638q-29.605 27.077-69.051 41.038Q419.503 693 381 693q-91.812 0-155.406-63.337Q162 566.325 162 474.663 162 383 225.337 319.5 288.675 256 380.11 256t155.662 63.385Q600 382.769 600 474.288 600 515 585 554.5T544 622l254 253-16 17ZM381 671q83.083 0 140.042-56.5Q578 558 578 474.5t-56.958-140Q464.083 278 381 278t-140.042 56.5Q184 391 184 474.5t56.958 140Q297.917 671 381 671Z" />
                        </svg>
                    </div>
                    <Transition
                        as={Fragment}
                        show={isOpen}
                        enter="transition ease-in duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-out duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options
                            style={{ zIndex: 1 }}
                            className="absolute w-[240px] py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            ref={dropdownRef} // Assign ref to the dropdown
                        >
                            {filteredItems?.length === 0 ? (
                                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredItems?.map((customer) => (
                                    <Combobox.Option
                                        key={customer?.customergroupid}
                                        className={({ active }) =>
                                            `relative cursor-pointer select-none p-2 ${
                                                active
                                                    ? 'bg-secondary/20 text-secondary'
                                                    : 'text-gray-900'
                                            }`
                                        }
                                        value={customer}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block text-xs ${
                                                        selected
                                                            ? 'font-medium'
                                                            : 'font-normal'
                                                    }`}
                                                >
                                                    {customer?.customergroupname}
                                                </span>
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </>
    )
}

export default SearchCustomerGroup
