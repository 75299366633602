import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import MultiSelectItem from 'src/Components/MultiSelect'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import ProfileFilter from 'src/Filter/workforce/profiles'
import { GET_STAFF_STATUS_TYPE_S } from 'src/Redux/actions/projects/staffing'
import { GET_DEMAND_MATCH_DETAILS, GET_STAFF_STATUS_TYPE_M } from 'src/Redux/actions/workforce/demands'
import { GET_SKILL_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
    GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    GET_BAND_MASTER,
    GET_CITY_MASTER,
    GET_STAFF_STATUS_TYPE_W,
    GET_WORKERTYPE_MASTER,
    GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import { formatIdForPayload } from 'src/Services/constant/customvalidation'
import {
    getBandMasterData,
    getCityPayload,
    getDeafultProfilePayload,
    getDeafultSkillData,
    getStatusMasterIdData,
    getTenantCustomerId,
    getTenantWorkForceyIdData,
    getTenantWorkerTypeyIdData,
} from 'src/Services/constant/defaultPayload'
import { basicShortList } from 'src/Services/constant/staticValues'
import Cards from './cards'
import TalentPopUpLayout from './talentpopup'

const rows = [10, 25, 50]

const ProfilesLayout = ({ demandData, extractStatusIds, thisPagePermissions }) => {
    const [filters, setFilters] = useState(getDeafultProfilePayload())
    const [minExp, setMinExp] = useState(demandData[0]?.demandroledetails?.[0]?.experienceinyear || 0)
    const dispatch = useDispatch()
    const { userSkillList } = useSelector((state) => state?.EmployeeProfile)
    const DemandDetailsList = useSelector((state) => state?.DemandDetailsList)
    const { demandMatchProfile, getStaffStatusTypeM, getStaffStatusTypeS, demandMatch } = DemandDetailsList
    const { allocationstatuslist, workertypelist, workforcelist, bandList } = useSelector(
        (state) => state?.EmployeeDetails,
    )
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [talentId, setTalentId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [allocationStatus, setAllocationStatuslist] = useState(null)
    const [matchStatus, setMatchStatuslist] = useState(null)
    const [workforceGroup, setWorkforceGroup] = useState([])
    const [workerType, setWorkerType] = useState([])
    const [primarySkill, setPrimarySkill] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        dispatch(GET_CITY_MASTER(getCityPayload()))
        dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_BAND_MASTER(getBandMasterData()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
        dispatch(GET_STAFF_STATUS_TYPE_W())
        dispatch(GET_STAFF_STATUS_TYPE_M())
        dispatch(GET_STAFF_STATUS_TYPE_S())
    }, [dispatch])

    useEffect(() => {
        if (allocationStatus === null && allocationstatuslist?.length > 0) {
            const statusIds = extractStatusIds()
            const filteredStatusObjects = allocationstatuslist?.filter((status) => statusIds.includes(status.statusid))
            setAllocationStatuslist(filteredStatusObjects)
        }
     // eslint-disable-next-line 
    }, [allocationstatuslist, extractStatusIds])


    const getBandIDs = useCallback(() => {
        if (!bandList || bandList?.length === 0) {
            return { defaultMinBandID: undefined, defaultMaxBandID: undefined }
        }

        // Determine the band with the lowest slno
        const defaultMinBand = bandList?.reduce((min, band) => (band.slno < min.slno ? band : min), bandList[0])
        // Determine the band with the highest slno
        const defaultMaxBand = bandList?.reduce((max, band) => (band.slno > max.slno ? band : max), bandList[0])

        return {
            defaultMinBandID: defaultMinBand ? defaultMinBand.bandmasterid : undefined,
            defaultMaxBandID: defaultMaxBand ? defaultMaxBand.bandmasterid : undefined,
        }
    }, [bandList])

    const { defaultMinBandID, defaultMaxBandID } = getBandIDs()

    useEffect(
        () => {
            const { defaultMinBandID, defaultMaxBandID } = getBandIDs()
            const statusIds = extractStatusIds()
            const filteredStatusObjects = allocationstatuslist?.filter((status) => statusIds.includes(status.statusid))
            setAllocationStatuslist(filteredStatusObjects)
            const statusIdString = `{${statusIds?.join(',')}}` || '{}'

            if (demandData && demandData?.length > 0 && demandData[0]?.demandskills) {
                const filteredSkills = demandData[0]?.demandskills?.filter((skill) => skill.importance === 1)
                const skillIds = filteredSkills?.map((skill) => skill.skillmasterid)
                const skillIdString = `{${skillIds?.join(', ')}}` || '{}'

                const demandRoleDetails = demandData[0]?.demandroledetails?.[0]
                const minBandid = demandRoleDetails?.min_bandid || defaultMinBandID
                const maxBandid = demandRoleDetails?.max_bandid || defaultMaxBandID

                const payload = {
                    ...getDeafultProfilePayload(),
                    workertypeid: formatIdForPayload(demandData[0]?.workertypeid) || '{}',
                    allocationstatusid: statusIdString,
                    workforcegroupid: formatIdForPayload(demandData[0]?.workforcegroupid) || '{}',
                    skillmasterid: skillIdString,
                    minbandid: minBandid,
                    maxbandid: maxBandid,
                    mintotalexperience: Number(minExp),
                    pageno: page,
                    limit,
                    sorttype: sortType?.id,
                    username: searchTerm,
                    demandid: demandData[0]?.demandid,
                    matchstatus: '{}',
                }
                setFilters(payload)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, /*  demandData, */ getBandIDs],
    )

    useEffect(
        () => {
            const statusIds = extractStatusIds()
            const filteredStatusObjects = allocationstatuslist?.filter((status) => statusIds?.includes(status.statusid))
            setAllocationStatuslist(filteredStatusObjects)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [demandData, demandMatch, allocationstatuslist],
    )

    useEffect(
        () => {
            if (demandData?.length > 0) {
                const initialWorkerType = workertypelist?.filter(
                    (item) => item.workertypeid === demandData[0]?.workertypeid,
                ) || [{ workertypeid: '', workertype: 'All' }]
                setWorkerType(initialWorkerType)

                const initialWorkforceGroup = workforcelist?.filter(
                    (item) => item.wfgid === demandData[0]?.workforcegroupid,
                ) || [{ wfgid: '', workforcegroup: 'All' }]
                setWorkforceGroup(initialWorkforceGroup)
                setMatchStatuslist([{ statusid: '', statusname: 'All' }])
            } else {
                setWorkerType([{ workertypeid: '', workertype: 'All' }])
                setWorkforceGroup([{ wfgid: '', workforcegroup: 'All' }])
                setMatchStatuslist([{ statusid: '', statusname: 'All' }])
            }

            if (demandData[0]?.demandskills?.length === 0) {
                setPrimarySkill([{ skillmasterid: '', skill: 'All' }])
            } else {
                const initialSkills = demandData[0]?.demandskills
                    ?.filter((skill) => skill.importance === 1 && skill.skillmasterid != null)
                    ?.map((skill) => ({
                        skillmasterid: skill.skillmasterid,
                        skill: skill.skill,
                    }))
                setPrimarySkill(initialSkills?.length > 0 ? initialSkills : [{ skillmasterid: '', skill: 'All' }])
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            // demandData,
            workertypelist,
            workforcelist,
            setWorkerType,
            setWorkforceGroup,
            setPrimarySkill,
            setMatchStatuslist,
            getStaffStatusTypeM,
        ],
    )

    useEffect(
        () => {
            const allocationStatusIds = allocationStatus?.map((status) => status.statusid)
            const workforceGroupIds = workforceGroup
                ?.filter((group) => group.wfgid != null)
                ?.map((group) => group.wfgid)
            const workerTypeIds = workerType?.map((type) => type.workertypeid)
            const matchIds = matchStatus?.map((match) => match.statusid)
            // console.log(matchIds)
            const primarySkillIds = primarySkill
                ?.filter((skill) => skill.skillmasterid != null)
                ?.map((skill) => skill.skillmasterid)

            setFilters((prevFilters) => ({
                ...prevFilters,
                allocationstatusid: allocationStatusIds ? `{${allocationStatusIds?.join(',')}} ` : '{}',
                workforcegroupid: workforceGroupIds ? `{${workforceGroupIds?.join(',')}}` : '{}',
                workertypeid: workerTypeIds ? `{${workerTypeIds?.join(',')}}` : '{}',
                skillmasterid: primarySkillIds ? `{${primarySkillIds?.join(',')}}` : '{}',
                matchstatus: matchIds ? `{${matchIds?.join(',')}}` : '{}',
                mintotalexperience: Number(minExp),
            }))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allocationStatus, workforceGroup, workerType, primarySkill, matchStatus /* setFilters */],
    )

    const handleSearchTermChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm?.trim())
        setPage(1) // Reset to the first page whenever search term changes
    }

    const handleMinExpChange = (e) => {
        const { value } = e.target
        const sanitizedValue = value.replace(/^0+/, '').replace(/\D/g, '')
        setMinExp(sanitizedValue)
        setFilters((prevFilters) => ({
            ...prevFilters,
            mintotalexperience: sanitizedValue === '' ? '' : Number(sanitizedValue),
        }))
    }

    const apiPayload = useMemo(
        () => ({
            Tenantid: getTenantCustomerId()?.Tenantid,
            username: searchTerm || '',
            status: 'Active',
            COMUserId: 0,
            allocationstatusid: filters?.allocationstatusid || '{}',
            companyid: 0,
            workertypeid: filters?.workertypeid || '{}',
            workforcegroupid: filters?.workforcegroupid || '{}',
            designationmasterid: 0,
            departmentid: 0,
            mintotalexperience: filters?.mintotalexperience || 0,
            maxtotalexperience: 0,
            skillmasterid: filters?.skillmasterid || '{}',
            technologymasterid: 0,
            city_id: '{}',
            limit,
            pageno: page,
            sorttype: sortType?.id || 3,
            secondaryskillid: 0,
            minbandid: filters?.minbandid || defaultMinBandID,
            maxbandid: filters?.maxbandid || defaultMaxBandID,
            demandid: demandData[0]?.demandid,
            matchstatus: filters?.matchstatus || '{}',
        }),
        [filters, searchTerm, limit, page, sortType, demandData, defaultMinBandID, defaultMaxBandID],
    )

    const CustomSearch = useMemo(
        () => {
            const searchPayload = {
                Tenantid: getTenantCustomerId()?.Tenantid,
                username: searchTerm || '',
                status: 'Active',
                COMUserId: 0,
                allocationstatusid: filters?.allocationstatusid || '{}',
                companyid: 0,
                workertypeid: filters?.workertypeid || '{}',
                workforcegroupid: filters?.workforcegroupid || '{}',
                designationmasterid: 0,
                departmentid: 0,
                mintotalexperience: filters?.mintotalexperience || 0,
                maxtotalexperience: 0,
                skillmasterid: filters?.skillmasterid || '{}',
                technologymasterid: 0,
                city_id: '{}',
                limit,
                pageno: page,
                sorttype: sortType?.id || null,
                secondaryskillid: 0,
                minbandid: filters?.minbandid || defaultMinBandID,
                maxbandid: filters?.maxbandid || defaultMaxBandID,
                demandid: demandData[0]?.demandid,
                matchstatus: filters?.matchstatus || '{}',
            }

            if (allocationStatus?.length > 0 && bandList?.length > 0 && filters?.allocationstatusid !== '{}') {
                return (
                    <CustomAutoSearch
                        hideOptions={true}
                        searchTerm={searchTerm}
                        API_CALL_FOR_SEARCH={GET_DEMAND_MATCH_DETAILS}
                        searchPayload={searchPayload}
                        setSearchTerm={handleSearchTermChange}
                        options={demandMatchProfile || []}
                        idkey="uId"
                        optionlabelKey="firstname"
                        optionLabelkey2="lastname"
                        bracketLabel="employeecode"
                        className="p-2 w-[342px]"
                        getSelectedOption={() => console.log()}
                        placeholder="Enter Employee Name"
                    />
                )
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [apiPayload, searchTerm, page, limit, demandMatchProfile, allocationStatus, bandList],
    )

    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_DEMAND_MATCH_DETAILS({
                ...apiPayload,
                limit: limit,
                pageno: page,
                sorttype: item?.id,
            }),
        )
    }

    const handleRows = (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_DEMAND_MATCH_DETAILS({
                ...apiPayload,
                limit: row,
                pageno: row > 10 ? 1 : page,
                sorttype: sortType?.id,
            }),
        )
    }

    const handlePagination = (page) => {
        dispatch(
            GET_DEMAND_MATCH_DETAILS({
                ...apiPayload,
                limit,
                pageno: page?.selected + 1,
                sorttype: sortType?.id,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleStatusSelect = (status) => {
        if (status.statusid === '') {
            setMatchStatuslist([{ statusid: '', statusname: 'All' }])
        } else {
            let updatedTypes
            if (matchStatus?.some((p) => p.statusid === status.statusid)) {
                updatedTypes = matchStatus.filter((p) => p.statusid !== status.statusid)
            } else {
                updatedTypes = [...matchStatus.filter((p) => p.statusid !== ''), status]
            }
            setMatchStatuslist(updatedTypes.length > 0 ? updatedTypes : [{ statusid: '', statusname: 'All' }])
        }
    }

    const employeeCount = useMemo(() => {
        return demandMatchProfile?.length > 0 ? demandMatchProfile[0]?.totalcount || 0 : demandMatchProfile?.length || 0
    }, [demandMatchProfile])

    return (
        <>
            <div className="sticky top-0 bg-white rounded-md z-8">
                <ProfileFilter
                    hooks={{
                        filters,
                        setFilters,
                        demandData,
                        allocationStatus,
                        setAllocationStatuslist,
                        workforceGroup,
                        setWorkforceGroup,
                        workerType,
                        setWorkerType,
                        primarySkill,
                        setPrimarySkill,
                        extractStatusIds,
                        getBandIDs,
                        page,
                        limit,
                        setMatchStatuslist,
                        allocationstatuslist,
                        workertypelist,
                        workforcelist,
                        bandList,
                        userSkillList,
                        setSearchTerm,
                        minExp,
                        setMinExp,
                    }}
                />
                <div className="grid items-center grid-cols-1 gap-4 mt-2 mb-2 lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-1">
                    <div className="col-span-2">
                        <span className="mb-2 text-sm font-medium whitespace-nowrap">Match Status</span>
                        <div className="p-0.5 border rounded bg-gray-50">
                            <MultiSelectItem
                                itemIdKey="statusid"
                                labelKeyname="statusname"
                                limitShow={1}
                                selectedOptions={matchStatus}
                                options={getStaffStatusTypeM}
                                handleSelection={handleStatusSelect}
                            />
                        </div>
                    </div>
                    <div className="col-span-4 mt-5">{CustomSearch}</div>
                    <div className="col-span-1">
                        <span className="mb-2 text-sm font-medium whitespace-nowrap"> Min Exp (Years) </span>
                        <input
                            placeholder="Min"
                            type="number"
                            pattern="[0-9\s]*"
                            className="block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary"
                            value={minExp}
                            onChange={handleMinExpChange}
                        />
                    </div>
                    <div className="flex items-center justify-end col-span-5 mt-5 space-x-2">
                        <div className="w-18">
                            <ReactPaginationStyle
                                total={employeeCount}
                                limit={limit}
                                currentPage={page}
                                handlePagination={handlePagination}
                            />
                        </div>
                        <div className="w-22">
                            <FilterButtonTab selectedTab={limit} tabs={rows} setSelectedtab={handleRows} />
                        </div>
                        <div className="w-22">
                            <SelectInput
                                options={basicShortList || []}
                                keyname="label"
                                selected={sortType}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[56vh] overflow-y-scroll">
                {demandMatchProfile === null ? (
                    <div className="flex items-center justify-center w-full h-1/2">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                Loading...
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4 pb-2 mt-2 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
                        {demandMatchProfile?.map((item) => {
                            return (
                                <Cards
                                    item={item}
                                    setTalentId={setTalentId}
                                    setIsOpen={setIsOpen}
                                    setSelectedProfile={setSelectedProfile}
                                    key={item?.uId}
                                />
                            )
                        })}
                        {isOpen && (
                            <TalentPopUpLayout
                                apiPayload={apiPayload}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                talentId={talentId}
                                thisPagePermissions={thisPagePermissions}
                                demandMatchProfile={demandMatchProfile}
                                getStaffStatusTypeM={getStaffStatusTypeM}
                                demandData={demandData}
                                selectedProfile={selectedProfile}
                                setSelectedProfile={setSelectedProfile}
                                filters={filters}
                                getStaffStatusTypeS={getStaffStatusTypeS}
                                page={page}
                                limit={limit}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default ProfilesLayout
