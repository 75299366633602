
import { memo, useState } from 'react';
import RejectOrApproveButton from './rejectOrApproveButton';
import TaskDetails from './taskDetails';
import ApproverRemarkNotePopup from '../comment/approverRemark'
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'recharts';
import { PencilIcon } from '@heroicons/react/24/outline'
import IconButton from 'src/Components/Buttons/IconButton';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { INSERT_TIMESHEET_EFFORT_TASK } from 'src/Redux/actions/projects/timesheet';
import moment from 'moment';

const Table = (props) => {
    const { updatehooks, updatehooks: { weeks, page, limit, getData, selectUser } } = props
    const datalist = useSelector(state => state?.ProjectTimesheet?.getManagerTimesheetRecord)
    const [editItem, setEditItem] = useState(false)
    const loggedinuser = JSON.parse(localStorage.getItem('comuserid'))
    const [active, setActive] = useState(null)
    const [open, setOpen] = useState(false);
    const [apiCall, setApiCall] = useState(false)
    const dispatch = useDispatch()

    const handleOpen = (data) => {
        if (active?.uId === data?.uId) {
            setActive(null)
        } else {
            let list = { ...data }
            let dataAfterEffortLimit = list?.timesheetdata?.map(item => {
                if (item?.timesheetconfiguration?.length > 0) {
                    let timeObject = item?.timesheetconfiguration[0]?.timetypetemplatemapping.find(time => time?.timetypeid === item?.timetypeid)
                    item['timetypeUniqueness'] = timeObject?.timetype
                    item['minEffort'] = timeObject?.mindailylimit
                    item['maxEffort'] = timeObject?.maxdailylimit
                }

                return item
            })
            setActive({ ...data, timesheetdata: dataAfterEffortLimit })
        }
    }

    const openAnotherTab = (it) => {
        if (active === null) {
            setEditItem(false)
        }
        setActive(it)
    }

    const listToDisplay = (id) => {
        if (id) {
            return datalist?.filter(i => (i?.comuserid === selectUser?.comuserid))
        } else {
            return datalist?.filter(i => (i?.comuserid !== loggedinuser))
        }
    }

    const EditTimesheet = async (detail) => {
        setApiCall(true)
        let data = [...detail]
        let rows = data?.map((row) => {
            row['statusname'] = 'Approved'
            row['isactiveflag'] = true
            return row
        })

        let payload = {
            timesheetjson: [
                {
                    comuserid: active?.comuserid,
                    createdcomuserid: +localStorage.getItem("comuserid"),
                    notes: "",
                    startdate: moment(weeks?.monday).format('YYYY-MM-DD'),
                    enddate: moment(weeks?.sunday).format('YYYY-MM-DD'),
                    isactiveflag: true,
                    comtenantid: +localStorage.getItem("Tenantid")
                }
            ],
            detailjson: rows,
        }
        let res = await dispatch(INSERT_TIMESHEET_EFFORT_TASK(payload))

        if (res) {
            getData(page, limit)
        }
        setApiCall(false)
    }

    return (
        <div className='w-full h-[80%] overflow-scroll'>
            {
                listToDisplay(selectUser?.comuserid)?.map((item) => {
                    return (
                        <div key={item?.uId} className="mb-2 w-ful px-2 bg-[#f5f5f5] border hover:shadow rounded-md mx-auto transition-[max-height] duration-500 overflow-hidden">
                            <div className="w-full py-1 z-50 flex items-center justify-between outline-none cursor-pointer marker:text-transparent ">
                                <div onClick={() => handleOpen(item)} role='button' className="w-full flex justify-start items-center space-x-1">
                                    <span>
                                        {active?.uId === item?.uId ? <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#5f6368"><path d="M480-357.85 253.85-584l35.79-35.79L480-429.43l190.36-190.36L706.15-584 480-357.85Z" /></svg> : <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#5f6368"><path d="M530.57-480.41 339.8-671.44l35.79-35.79 226.56 226.82-226.56 226.56-35.79-35.79 190.77-190.77Z" /></svg>}
                                    </span>
                                    <span className=' text-gray-600 font-medium py-0.5 px-1' >{item?.timesheetdata[0]?.entrytype}</span>
                                    <span className=' text-gray-700 font-medium'>|</span>
                                    {item?.timesheetdata[0]?.entrytype === 'Project' ? <span className='px-1'>{item?.projectname} </span> : ''}
                                    {item?.timesheetdata[0]?.entrytype === 'Project' ? <span className=' text-gray-700 font-medium'>|</span> : ''}
                                    <span className='px-1'>{item?.staffname} ({item?.employeecode}) </span>
                                    <span className=' text-gray-700 font-medium'>|</span>
                                    {
                                        item?.timesheetdata[0]?.entrytype === 'Project' ? <>
                                            <span className='px-1'>{item?.timesheetdata[0]?.Allocationstartdate} - {item?.timesheetdata[0]?.Allocationenddate} </span>
                                            <span className=' text-gray-700 font-medium'>|</span>
                                            <span className='px-1'>
                                                {item?.timesheetdata[0]?.allocationperc * 100}%
                                            </span>
                                            <span className=' text-gray-700 font-medium'>|</span>
                                        </> : ''
                                    }

                                    <span className={` text-sm px-2  ${item?.curstatus === 'Submitted' ? 'bg-yellow-100 text-yellow-600 ' : 'bg-green-100 text-green-600 '}`}>
                                        {item?.curstatus}
                                    </span>
                                    <div>
                                        <Tooltip title='Manager Remark'>
                                            {item?.timesheetdata?.length > 0 && <ApproverRemarkNotePopup value={item?.timesheetdata[0]?.approverremarks} />}
                                        </Tooltip>
                                    </div>
                                </div>
                                {item?.curstatus !== 'Submitted' ? ''
                                    : <RejectOrApproveButton
                                        setOpen={setOpen}
                                        open={open}
                                        updatehooks={updatehooks}
                                        item={item}
                                        setActive={openAnotherTab}
                                    />}

                                {item?.curstatus === 'Approved' &&
                                    (editItem?.uId === item?.uId ? '' : <IconButton onClick={() => {
                                        setEditItem(item)
                                        setActive(item)
                                    }} title="Edit Efforts" className='text-primary'>
                                        <PencilIcon className='text-primary w-5 h-5' />
                                    </IconButton>)
                                }

                            </div>
                            {active?.uId === item?.uId && <>
                                <hr className="my-2 scale-x-150" />
                                <div className=" relative -m-4 -mt-2 px-6 bg-gray-50">
                                    <TaskDetails active={active}
                                        editItem={editItem}
                                        setActive={openAnotherTab}
                                        weeks={weeks} />
                                    {(editItem?.uId === item?.uId && item?.curstatus === 'Approved') ? <div className="flex justify-end items-center space-x-2 mb-3">
                                        <button onClick={() => {
                                            setEditItem(false)
                                            getData(page, limit)
                                        }} className='border-primary border text-primary w-16 h-9 rounded-md'>
                                            Cancel
                                        </button>
                                        <PrimaryButton onClick={() => EditTimesheet(active?.timesheetdata)} className='bg-primary py-2 mt-0 text-white rounded-md'>
                                            {apiCall ? 'Processing...' : 'Save'}
                                        </PrimaryButton>
                                    </div> : ''}
                                </div>
                            </>}
                        </div>
                    )
                })
            }


        </div>
    )
}

export default memo(Table);