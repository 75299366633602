import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'

const BarchartDashboard = ({ serviceList, workertypelist }) => {
    const data = serviceList?.slice(0, 6)?.map((service) => {
        let dataObject = {
            name: service.servicelinename,
        }

        workertypelist?.forEach((workertype) => {
            dataObject[workertype.workertype] = Math.floor(Math.random() * 5000)
        })

        return dataObject
    })
    const barColors = ['#8884d8', '#82ca9d', '#57779b', '#F08080', '#F4A261']

    return (
        <div className="sm:h-[50dvh] md:h-[50dvh]">
            <ResponsiveContainer  debounce={300} width="100%" height="100%">
                <BarChart
                    width={400}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        angle={-15}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {workertypelist?.map((workertypes, index) => (
                        <Bar
                            key={workertypes?.workertype}
                            dataKey={workertypes?.workertype}
                            stackId="a"
                            fill={barColors[index % barColors.length]}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default BarchartDashboard
