
export const demandmatchItem = () => {
    return {
        demandmatchconfigurationid: null,
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        parametername: "",
        conditions: "",
        isactiveflag: true,
        notes: "",
        emptype_usage: "",
        emptype_weightage: 0,
        workforcegroup_usage: "",
        workforcegroup_weightage: 0,
        serviceline_usage: "",
        serviceline_weightage: 0,
        allocation_usage: "Less Than Equal to",
        allocation_weightage: 0,
        workmode_usage: "",
        workmode_weightage: 0,
        location_usage: "",
        location_weightage: 0,
        minband_usage: "Less Than Equal to",
        minband_weightage: 0,
        maxband_usage: "Greater Than Equal to",
        maxband_weightage: 0,
        minexp_usage: "Less Than Equal to",
        minexp_weightage: 0,
        maxexp_usage: "Greater Than Equal to",
        maxexp_weightage: 0,
        skill_usage: "",
        skill_weightage: 0,
        technology_usage: "",
        technology_weightage: 0,
        domain_usage: "",
        domain_weightage: 0,
        includestatus: [
            {
                includestatusid: 0
            },
            {
                includestatusid: 0
            }
        ],
        includenoticeperiod: false,
        includeusertype: [
            {
                datusertypeid: 0
            },
            {
                datusertypeid: 0
            }
        ],
        createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
        modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    };

};

