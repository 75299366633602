import { ACTION_GET_PROJECT_DETAIL_TIMELINE } from "src/Redux/types"

const initialState = {
    projectList: [],
    customerList: [],
    typesListProject: [],
    listOfCompany: [],
    listOfBillingProjects: [],
    statusListByType: [],
    servicelineList: [],
    businessUnitList: [],
    currencyList: [],
    listOfOpportunity: [],
    listOfTechnology: [],
    productList: [],
    userMasterList: [],
    getProjectsByUserId: null,
    projectBudgetEstimation: null,
    projectBytimeline: null
}

const Projects = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROJECTS_BY_ID':
            return { ...state, projectDetail: action.data }
        case 'GET_BUDGET_ESTIMATION':
            return { ...state, projectBudgetEstimation: action.data }
        case 'GET_PROJECTS':
            return { ...state, projectList: action.data?.Message ? [] : action.data }
        case 'GET_CUSTOMER_LIST':
            return { ...state, customerList: action.data?.Message ? [] : action.data }
        case 'GET_LIST_TYPE_OF_PROJECT':
            return { ...state, typesListProject: action.data?.Message ? [] : action.data }
        case 'GET_LIST_COMPANY':
            return { ...state, listOfCompany: action.data?.Message ? [] : action.data }
        case 'GET_PROJECT_LIST_BILLING_TYPE':
            return { ...state, listOfBillingProjects: action.data?.Message ? [] : action.data }
        case 'GET_LIST_OF_STATUS':
            return { ...state, statusListByType: action.data?.Message ? [] : action.data }
        case 'GET_SERVICE_LINE_MASTER_LIST':
            return { ...state, servicelineList: action.data?.Message ? [] : action.data }
        case 'GET_BUSINESS_UNIT_MASTER_LIST':
            return { ...state, businessUnitList: action.data?.Message ? [] : action.data }
        case 'GET_CURRENCY_MASTER_LIST':
            return { ...state, currencyList: action.data?.Message ? [] : action.data }
        case 'GET_OPPORTUNITY_LIST':
            return { ...state, listOfOpportunity: action.data?.Message ? [] : action.data }
        case 'GET_TECHNOLOGY_LIST':
            return { ...state, listOfTechnology: action.data?.Message ? [] : action.data }
        case 'GET_PRODUCT_LIST':
            return { ...state, productList: action.data?.Message ? [] : action.data }
        case 'GET_USER_MASTER_LIST':
            return { ...state, userMasterList: action.data?.Message ? [] : action.data }
        case 'GET_PROJECTS_BY_USER':
            return { ...state, getProjectsByUserId: action.data?.Message ? [] : action.data }
        case 'GET_PROJECT_DOCUMENT_LIST':
            return { ...state, getProjectDocument: action?.data }
        case 'GET_PROJECT_DOCUMENT_TYPE_LIST':
            return { ...state, getProjectDocumentType: action?.data || [] }
        case ACTION_GET_PROJECT_DETAIL_TIMELINE:
            return { ...state, projectBytimeline: action?.data || [] }
        default:
            return state
    }
}

export default Projects
