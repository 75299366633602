import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TooltipCom from "src/Components/Tooltip";
import ApproverNotePopup from './approverRemark'

const cols = [
    'Employee',
    'Task',
    'Ref No',
    'Time Type',
    'Date',
    'Effort (Hrs)',
    'Notes'
]
const BillingTable = () => {
    const getTimesheetBilling = useSelector(state => state?.ProjectFinancial?.getTimesheetBilling)
    const [data, setData] = useState([])

    console.log(getTimesheetBilling)
    const handleNoteChange = ()=>{

    }

    return (
        <>
            <table className='relative w-full text-sm'>
                <thead>
                    <tr className='text-left capitalize bg-gray-50'>
                        {cols?.map((col) => {
                            return (
                                <th
                                    key={col}
                                    title={col}
                                    scope='col'
                                    className='font-[500] p-2 border text-start'
                                >
                                    {col}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr
                        className='text-left cursor-pointer'>
                        <td className='border px-2 py-1.5'>

                        </td>
                        <td className='border px-2 py-1.5'>

                        </td>
                        <td className='border px-2 py-1.5'>

                        </td>
                        <td className='border px-2 py-1.5'>

                        </td>
                        <td className='border px-2 py-1.5'>

                        </td>
                        <td className='border px-2 py-1.5'>

                        </td>
                        <td className='border px-2 py-1.5'>
                            <div className=" flex justify-start items-center space-x-2">
                                <TooltipCom title='Employee comments'>
                                    <ApproverNotePopup onChange={handleNoteChange} value='' />
                                </TooltipCom>
                                <TooltipCom title='Manager Remark'>
                                    <ApproverNotePopup onChange={handleNoteChange} value='' />
                                </TooltipCom>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </>
    )
}

export default BillingTable;