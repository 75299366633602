import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent'
import { getCityPayload } from 'src/Services/constant/defaultPayload'

const SelectLocation = ({ location, setLocation }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const { citylist } = useSelector(state => state?.EmployeeDetails)

	useEffect(() => {
		if (location?.cityname) {
			setSearchTerm(location?.cityname || '')
		}
	}, [location])

	return (
		<CustomAutoSearch
			searchTerm={searchTerm}
			API_CALL_FOR_SEARCH={GET_CITY_MASTER}
			searchPayload={{
				...getCityPayload(),
				searchname: searchTerm,
			}}
			className='p-2'
			setSearchTerm={setSearchTerm}
			options={citylist}
			idkey='uId'
			optionlabelKey='cityname'
			getSelectedOption={setLocation}
			placeholder='Search Location'
		/>
	)
}

export default memo(SelectLocation)
