import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../defaultSkill';
import { statuslist } from 'src/Services/constant/staticValues';


const SkillDetails = (props) => {
  const { skillList } = useSelector((state) => state.EmployeeProfile);
  const { detailSave, detailsData, setDetailsData } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        skilljson: [detailsData],
      };
      detailSave(payload);
    };
  }

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='skillcode' className='block font-[500] mb-2'>
            Skill Code
          </label>
          <input
            disabled
            maxLength={50}
            type='text'
            title='skillcode'
            id='skillcode'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Skill Code'
            required=''
            // value={detailsData?.customername || ''}
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('skillcode', e.target.value);
              }
            }} />
        </div>
        <div>
          <label htmlFor='skill' className='block font-[500] mb-2'>
            Skill
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='skill'
            id='skill'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
            placeholder='skill'
            required=''
            onChange={(e) => handleChangeInput('skill', e.target.value)}
            maxLength={50}
          // value={customercode || ''}
          />
          {!detailsData?.skill && checkvalidation && (
            <small className='text-red-400'>Skill is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='skillarea' className='block font-[500] mb-2'>
            Skill Area
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={skillList || []}
            value={detailsData?.skillarea}
            noDataLabel='No skillarea'
            name='skillarea'
            labelkey='skillarea'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 ' onChange={handleChangeInput}
            id='skillareamasterid'
          />
          {!detailsData?.skillarea && checkvalidation && (
            <small className='text-red-400'>Skill Area is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='skilltype' className='block font-[500] mb-2'>
            Skill Type
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={statuslist || []}
            value={detailsData?.skilltype}
            noDataLabel='No skilltype found'
            name='skilltype'
            labelkey='name'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 ' onChange={handleChangeInput}
            id='skilltype'
          />
          {!detailsData?.skilltype && checkvalidation && (
            <small className='text-red-400'>Skill Type is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 mt-2 right-6'>
        <div className='flex items-center justify-end gap-2'>
          <PrimaryButton
            className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(SkillDetails);
