import { Fragment, memo, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import IconButton from 'src/Components/Buttons/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { GET_TIMESHEET_USERLIST } from 'src/Redux/actions/projects/timesheet';
import SelectInput from 'src/Components/SelectInput';
import { uniqueUsers } from '../../../../timesheetUi/manager/filters';
import moment from 'moment';
import SelectMonth from 'src/Components/SelectMonth';
import BillingTable from './table';
import { GET_TIMESHEET_BILLING } from 'src/Redux/actions/projects/financials';

const FinancialBilling = ({ projectDetail, m, y }) => {
    let [isOpen, setIsOpen] = useState(false);
    const [selectUser, setSelectUser] = useState({})
    const comuserid = localStorage.getItem("comuserid")
    const timesheetUserUnderManager = useSelector(state => state?.ProjectTimesheet?.timesheetUserUnderManager)
    const dispatch = useDispatch()
    const [month, setMonth] = useState(m)
    const [year, setYear] = useState(y)

    const startDate = moment([year, month - 1]).startOf('month').format('YYYY-MM-DD');
    const endDate = moment([year, month - 1]).endOf('month').format('YYYY-MM-DD');

    const getData = async (id) => {
        let payload = {
            "loginuserid": comuserid,
            "comuserid": id || selectUser?.comuserid || 0,
            "Tenantid": localStorage.getItem("Tenantid"),
            "projectid": projectDetail?.projectid || 0,
            "startdate": startDate,
            "enddate": endDate,
            "timesheetstatus": 'Approved',
        }
        dispatch(GET_TIMESHEET_BILLING(payload))
    }

    useEffect(() => {
        dispatch(GET_TIMESHEET_USERLIST({ projectid: projectDetail?.projectid })).then((res) => {
            if (res?.length > 0) {
                setSelectUser(res[0])
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        getData()
        setIsOpen(true)
    }
    const handleMonthChange = async (e) => {
        let { value } = e.target
        const [year, month] = value.split('-')
        const parsedMonth = parseInt(month, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        await setYear(validYearid)
        await setMonth(validMonthid)
        await getData()
    }

    const handleChangeStaff = (userInfo) => {
        setSelectUser(userInfo)
        let id = userInfo?.comuserid || 0
        if (userInfo?.comuserid === null) {
            setSelectUser({})
        } else {
            setSelectUser(userInfo)
        }
        getData(id)
    }

    return (
        <>
            <IconButton onClick={openModal} className='p-0 w-9 h-9 bg-primary text-white' title="Billing">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M441-120v-86q-53-12-91.5-46T293-348l74-30q15 48 44.5 73t77.5 25q41 0 69.5-18.5T587-356q0-35-22-55.5T463-458q-86-27-118-64.5T313-614q0-65 42-101t86-41v-84h80v84q50 8 82.5 36.5T651-650l-74 32q-12-32-34-48t-60-16q-44 0-67 19.5T393-614q0 33 30 52t104 40q69 20 104.5 63.5T667-358q0 71-42 108t-104 46v84h-80Z" /></svg>
            </IconButton>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-20' onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
                    </Transition.Child>
                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className=' w-[calc(90%)] md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                                    <div className='border-b py-2 border-primary flex justify-between items-center'>
                                        <h3 className='text-lg font-[500] leading-6 text-primary'>
                                            Billing - {projectDetail?.projectname}
                                        </h3>
                                        <IconButton
                                            id='create-notification-btn-close'
                                            title='Create'
                                            onClick={closeModal}
                                            type='button'
                                            className='text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-4 h-4'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M6 18L18 6M6 6l12 12'
                                                />
                                            </svg>
                                        </IconButton>
                                    </div>
                                    <div className='grid grid-cols-4 gap-2 my-2'>
                                        <div className='w-full'>
                                            <h6 className='text-sm'>Select Month</h6>
                                            <SelectMonth
                                                className='text-sm shadow-sm flex items-center justify-start w-full p-1.5 space-x-1 border border-0.5 rounded-md'
                                                onChange={handleMonthChange}
                                                name='Monthid'
                                                year={Number(year)}
                                                month={Number(month)}
                                                min={projectDetail?.startDate?.substring(0, 7)} // 2024-02 
                                                max={projectDetail?.endDate?.substring(0, 7)} // 2024-04 
                                            />
                                        </div>
                                        <div className='w-full'>
                                            <h6 className='text-sm'>Select User</h6>
                                            <SelectInput
                                                className="shadow py-1.5 bg-gray-50 w-full"
                                                options={uniqueUsers(timesheetUserUnderManager, comuserid)}
                                                keyname='firstname'
                                                extrakey='lastname'
                                                mapkey='effortid'
                                                selected={selectUser || uniqueUsers(timesheetUserUnderManager, comuserid)[0]}
                                                setSelected={handleChangeStaff}
                                            />
                                        </div>

                                    </div>
                                    <BillingTable />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default memo(FinancialBilling)
