import { useEffect, useMemo, useState } from "react";
import Row from "./row";
import Calculation from './calculation'
import DayHeaders from "./dayHeaders";
import { useDispatch, useSelector } from "react-redux";
import { GET_TIMESHEET_ORG_TASKS_BY_PRO_ID, INSERT_TIMESHEET_EFFORT_TASK, SUBMIT_TIMESHEET_OF_EMPLOYEE } from "src/Redux/actions/projects/timesheet";
import moment from "moment";
import { AlertError, isfuturedate } from "src/Services";
import ScrollToBottom from "react-scroll-to-bottom";

const cols = ['Entry Type', 'Select Task', 'Time Type'];
const cols2 = ['Notes', 'Actions']

const getActiveData = (data) => {
    return data?.filter(row => row?.isactiveflag === true || row?.isactiveflag === undefined) || []
}

const converttimeToDecimal = (timeString) => {
    if (timeString?.length > 0) {
        const decimalTime = timeString?.replace(":", '.')
        return decimalTime
    }
    return timeString

}
const Table = ({ weeks, fetchTimesheetData }) => {
    const dispatch = useDispatch()
    const getTaskTimesheetConfig = useSelector(state => state?.ProjectTimesheet?.getTaskTimesheetConfig)
    const employeeTimesheetData = useSelector(state => state?.ProjectTimesheet?.employeeTimesheetData)
    const [projects, setProjects] = useState([])
    const [leaves, setLeaves] = useState([])
    const ProjectTimesheet = useSelector(state => state?.ProjectTimesheet)
    const [weekHrsByProjstaffId, setWeekHrsByProjstaffId] = useState({})
    const [dailyHrsTimeProjStaffId, setDailyHrsTimeProjStaffId] = useState({})
    const [dailyHrsOfGenric, setDailyHrsOfGenric] = useState({})
    const [weeklyHrsOfGenric, setWeeklyHrsOfGenric] = useState({})
    let { taskType } = ProjectTimesheet
    const [apiCall, setApiCall] = useState(false)

    useEffect(() => {
        if (taskType?.length > 0) {
            let tasktypeId = taskType?.find(
                el => el?.tasktype === 'Organization'
            )
            dispatch(GET_TIMESHEET_ORG_TASKS_BY_PRO_ID(tasktypeId?.tasktypeid))
        }
        // eslint-disable-next-line 
    }, [taskType])

    const showSaveBtn = useMemo(() => {
        if (employeeTimesheetData?.length > 0) {
            let newEffort = employeeTimesheetData?.filter(row => row?.newChange === true)
            if (newEffort?.length > 0) {
                return true
            }
            return false
        }

    }, [employeeTimesheetData])

    const showSubmitBtn = useMemo(() => {
        if (employeeTimesheetData?.length > 0) {
            const onlyActiveRow = employeeTimesheetData?.filter(row => row?.isactiveflag === true || row?.isactiveflag === undefined)
            let draftRec = onlyActiveRow?.filter(row => row?.curstatus === 'Draft' && row?.effortid !== null)
            if (draftRec?.length > 0) {
                return true
            }
            return false
        }

    }, [employeeTimesheetData])

    useEffect(() => {
        if (getTaskTimesheetConfig?.timesheetdata) {
            setLeaves(getTaskTimesheetConfig?.leavedetails_var || [])
            let rowsWithValue = []

            let items = getTaskTimesheetConfig?.timesheetdata?.filter(row => row?.projtaskid !== null)
            if (items?.length > 0) {
                rowsWithValue = rowsWithValue.concat(items)
            } else if (items?.length === 0) {
                let uId = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                let projectItem = getTaskTimesheetConfig?.timesheetdata?.find(it => it?.entrytype === 'Project')
                if (projectItem) {
                    rowsWithValue.push({ ...projectItem, uId })
                }
            }
            let genericItem = getTaskTimesheetConfig?.timesheetdata?.find(it => it?.entrytype === 'Generic' && (it?.projtaskid === null && it?.projtaskid === null))
            if (genericItem) {
                let uId = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                rowsWithValue.push({ ...genericItem, uId, timesheetconfiguration: genericItem?.timesheetconfiguration?.length ? genericItem?.timesheetconfiguration[0] : genericItem?.timesheetconfiguration })
            }
            let addedTimetypeUniqueness = rowsWithValue?.map((item) => {
                if (!item?.effortid) {
                    item['projstaffid'] = null
                    item['projectid'] = null
                    item['projroleid'] = null
                    item['rolename'] = ''
                    item['projectname'] = ''
                }
                if (item?.timesheetconfiguration?.length > 0) {
                    let timeObject = item?.timesheetconfiguration[0]?.timetypetemplatemapping.find(time => time?.timetypeid === item?.timetypeid)
                    item['timetypeUniqueness'] = timeObject?.timetype
                    if (timeObject) {
                        item['minEffort'] = timeObject?.mindailylimit
                        item['maxEffort'] = timeObject?.maxdailylimit
                    }
                    item['newChange'] = false
                }
                return item
            })
            dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: addedTimetypeUniqueness })
            let p = []
            let pro_duplicate = []
            for (let pro of getTaskTimesheetConfig?.timesheetdata) {
                if (pro?.entrytype === "Project") {
                    if (!pro_duplicate.includes(pro?.projectid)) {
                        pro_duplicate.push(pro?.projectid)
                        p.push({
                            projectid: pro?.projectid,
                            projectname: pro?.projectname,
                            projstaffid: pro?.projstaffid,
                            Companyholidays: pro?.Companyholidays,
                            customerholidays: pro?.customerholidays,
                            timesheetconfiguration: pro?.timesheetconfiguration?.length > 0 ? pro?.timesheetconfiguration[0] : pro?.timesheetconfiguration
                        })
                    }

                }
            }
            setProjects(p)
        }

        // eslint-disable-next-line 
    }, [getTaskTimesheetConfig?.timesheetdata])

    const dates = useMemo(() => {
        let items = []
        const startDate = moment(weeks?.monday); // replace with your own start date
        for (let i = 0; i < 7; i++) {
            const nextDate = startDate.clone().add(i, 'days');
            const formattedDate = nextDate.format('YYYY-MM-DD');
            items.push(formattedDate);
        }
        return items
    }, [weeks])

    const generateEffotCombination = (TimesheetData = employeeTimesheetData) => {
        let objData = {}
        let data = [...TimesheetData]?.filter(r => r?.entrytype === 'Project' && r?.isactiveflag !== false && (r?.curstatus !== "Submitted" && r?.curstatus !== "Approved"))

        let projstaffidList = data?.map(i => {
            return `${i?.projstaffid}-${i?.timetypeid}`
        })
        let projstaffIds = data?.map(i => {
            return i?.projstaffid
        })

        let allIds = projstaffidList.concat(projstaffIds)

        let ids = [...new Set(allIds)]
        // week calculation
        for (let id of ids) {
            if (id) {
                let listdata = []
                for (let row of data) {
                    if (`${row?.projstaffid}-${row?.timetypeid}` === id) {
                        listdata.push(row)
                    } else if (row?.projstaffid === id) {
                        listdata.push(row)
                    }
                }

                if (listdata?.length > 0) {
                    const totalEffort = listdata
                        ?.filter(r => `${r?.projstaffid}-${r?.timetypeid}` === id || r?.projstaffid === id)
                        ?.reduce((total, entry) => {
                            const entryEffort = entry.timesheetdetails.reduce(
                                (sum, timesheet) => sum + timesheet.efforthours,
                                0
                            );
                            return total + entryEffort;
                        }, 0);
                    objData[id] = totalEffort
                }
            }
        }
        // Initialize total hours to zero for projects
        let dailyhrsTotalCal = {}

        // Loop through each ID
        for (let id of ids) {
            if (id) {
                let listdata = []
                let datesEffort = []
                for (let targetDate of dates) {
                    let totalEffortHours = 0;
                    // Filter the rows that match the current ID
                    listdata = data.filter(row => {
                        return `${row?.projstaffid}-${row?.timetypeid}` === id || row?.projstaffid === id
                    });
                    // Accumulate effort hours for matching rows
                    totalEffortHours += listdata
                        ?.reduce((sum, mainObject) => {
                            // Sum effort hours for timesheet details that match the target date
                            let effortSum = mainObject.timesheetdetails?.reduce((detailSum, detail) => {
                                return detail.effortdate === targetDate ? detailSum + detail.efforthours : detailSum;
                            }, 0);

                            return sum + effortSum;
                        }, 0);
                    datesEffort.push({ [targetDate]: totalEffortHours, date: targetDate })
                }
                dailyhrsTotalCal[id] = datesEffort
            }
        }
        setDailyHrsTimeProjStaffId(dailyhrsTotalCal)
        setWeekHrsByProjstaffId(objData)
    }

    const generateEffotCombinationForGenric = (TimesheetData = employeeTimesheetData) => {
        let data = TimesheetData?.filter(r => r?.entrytype === 'Generic' && r?.isactiveflag !== false && (r?.curstatus !== "Submitted" && r?.curstatus !== "Approved"))

        let timetemplatemasterIds = data?.map(i => {
            let timesheetconfiguration = i?.timesheetconfiguration?.length ? i?.timesheetconfiguration[0] : i?.timesheetconfiguration
            return `${timesheetconfiguration?.timetemplatemasterid}-${i?.timetypeid}`
        })

        let genricTimeConfig = data?.timesheetconfiguration?.length
            ? data?.timesheetconfiguration[0]
            : data[0]?.timesheetconfiguration[0];

        let allIds = timetemplatemasterIds

        let ids = [...new Set(allIds), genricTimeConfig?.timetemplatemasterid]

        let dailyhrsTotalCal = {}

        for (let id of ids) {
            if (id) {
                let datesEffort = []
                let listdata = []
                for (let targetDate of dates) {
                    let totalEffortHours = 0;
                    listdata = data.filter(row => {
                        return `${genricTimeConfig?.timetemplatemasterid}-${row?.timetypeid}` === id || genricTimeConfig?.timetemplatemasterid === id
                    });

                    // Accumulate effort hours for matching rows
                    totalEffortHours += listdata?.reduce((sum, mainObject) => {
                        // Sum effort hours for timesheet details that match the target date
                        let effortSum = mainObject.timesheetdetails?.reduce((detailSum, detail) => {
                            return detail.effortdate === targetDate ? detailSum + detail.efforthours : detailSum;
                        }, 0);

                        return sum + effortSum;
                    }, 0);
                    datesEffort.push({ [targetDate]: totalEffortHours, date: targetDate })
                }
                dailyhrsTotalCal[id] = datesEffort
            }
        }

        // week total 
        let objData = {}
        for (let id of ids) {
            if (id) {
                let listdata = []
                for (let row of data) {
                    let timesheetconfiguration = row?.timesheetconfiguration?.length ? row?.timesheetconfiguration[0] : row?.timesheetconfiguration
                    if (`${timesheetconfiguration?.timetemplatemasterid}-${row?.timetypeid}` === id) {
                        listdata.push(row)
                    } else if (timesheetconfiguration?.timetemplatemasterid === id) {
                        listdata.push(row)
                    }
                }

                if (listdata?.length > 0) {
                    const totalEffort = listdata
                        ?.filter(r => `${genricTimeConfig?.timetemplatemasterid}-${r?.timetypeid}` === id || genricTimeConfig?.timetemplatemasterid === id)
                        ?.reduce((total, entry) => {
                            const entryEffort = entry.timesheetdetails?.reduce(
                                (sum, timesheet) => sum + timesheet.efforthours,
                                0
                            );
                            return total + entryEffort;
                        }, 0);
                    objData[id] = totalEffort
                }
            }
        }
        setDailyHrsOfGenric(dailyhrsTotalCal || {})
        setWeeklyHrsOfGenric(objData || {})
    }

    useEffect(() => {
        generateEffotCombination()
        generateEffotCombinationForGenric()
        // eslint-disable-next-line
    }, [employeeTimesheetData])

    const allocationDateValidation = (effortdate, rowdata) => {
        if (rowdata?.Allocationenddate && rowdata.Allocationstartdate) {
            if (rowdata?.entrytype === 'Project') {
                let start = moment(new Date(rowdata?.Allocationstartdate)).format('YYYY-MM-DD')
                let end = moment(new Date(rowdata?.Allocationenddate)).format('YYYY-MM-DD')
                let effortDateis = moment(new Date(effortdate)).format('YYYY-MM-DD')

                if (effortDateis >= start && effortDateis <= end) {
                    return false
                } else {
                    return true
                }
            }
        }
    }

    const handleInsertTimesheet = async () => {
        setApiCall(true)
        const debounceTimer = setTimeout(async () => {
            let duplicates = [];
            let activedataonly = await employeeTimesheetData?.filter(row => row?.isactiveflag !== false)
            let genericItemIs = employeeTimesheetData.find(r => r?.entrytype === 'Generic' && r?.isactiveflag !== false)

            let genricTimeConfig = genericItemIs?.timesheetconfiguration?.length
                ? genericItemIs?.timesheetconfiguration[0]
                : genericItemIs?.timesheetconfiguration;

            for (let row of activedataonly) {
                if (!row?.timetypeid) {
                    if (row?.entrytype === 'Generic' && row?.projtaskid) {
                        setApiCall(false)
                        AlertError(`Generic: Please select timetype for ${row?.taskdesc}`)
                        return
                    } else if (row?.entrytype === 'Project' && row?.projstaffid) {
                        setApiCall(false)
                        AlertError(`Project : Please select timetype for ${row?.projectname}-${row?.taskdesc}`)
                        return
                    }

                }
                let timesheetconfiguration = row?.timesheetconfiguration?.length ? row?.timesheetconfiguration[0] : row?.timesheetconfiguration
                let selectedTimetype = timesheetconfiguration?.timetypetemplatemapping?.find(value => value?.timetypeid === row?.timetypeid)
                if (row?.effortnotes?.trim()?.length > 0 && row?.effortnotes?.trim()?.length > 200) {
                    AlertError(`${row?.entrytype} : Maximum 200 character for ${row?.taskdesc}`)
                    setApiCall(false)
                    return
                }
                if (row?.curstatus !== "Submitted" && row?.curstatus !== "Approved") {
                    let holidays = []

                    if (timesheetconfiguration?.validationunitname !== 'None') {
                        let Companyholidays = []
                        let customerholidays = []

                        if (selectedTimetype?.holidaycalendarintegration === 'Both' && !selectedTimetype?.holidayallowed) {
                            Companyholidays = row?.Companyholidays?.map(h => h?.holiday) || []
                            customerholidays = row?.customerholidays?.map(h => h?.holiday) || []
                        } else if (selectedTimetype?.holidaycalendarintegration === 'Customer' && !selectedTimetype?.holidayallowed) {
                            customerholidays = row?.customerholidays?.map(h => h?.holiday) || []
                        } else if (selectedTimetype?.holidaycalendarintegration === 'Company' && !selectedTimetype?.holidayallowed) {
                            Companyholidays = row?.Companyholidays?.map(h => h?.holiday) || []
                        }
                        holidays = [...Companyholidays, ...customerholidays ]
                        let weekDays = []
                        let weekEndDays = []
                        let leaveDates = leaves.filter(l => l?.Noofdays === 1)?.map(l => l?.leavedate)
                        
                        if(selectedTimetype?.weekdayentryallowed){
                            weekDays = row?.timesheetdetails?.filter(r => (moment(r.effortdate).format('ddd') !== 'Sun' && moment(r.effortdate).format('ddd') !== 'Sat') && selectedTimetype?.weekdayentryallowed)
                        }if(selectedTimetype?.weekendentryallowed){
                            weekEndDays = row?.timesheetdetails?.filter(r => (moment(r.effortdate).format('ddd') === 'Sun' || moment(r.effortdate).format('ddd') === 'Sat') && selectedTimetype?.weekendentryallowed)
                        }
                        const combinedArray = weekDays?.concat(weekEndDays);
                        let datesTillCurrentDate = combinedArray?.filter(r => !isfuturedate(r?.effortdate) && !holidays?.includes(r?.effortdate))
                        datesTillCurrentDate = datesTillCurrentDate?.filter(r => !leaveDates?.includes(r?.effortdate))
                        datesTillCurrentDate = datesTillCurrentDate?.filter(r => !allocationDateValidation(r?.effortdate, row))

                        for (let effort of datesTillCurrentDate) {
                            let allocation = 0
                            let maxhours = row?.entrytype === 'Project' ? timesheetconfiguration?.maxhours : genricTimeConfig?.maxhours
                            let minhours = row?.entrytype === 'Project' ? (timesheetconfiguration?.minhours || 0) : genricTimeConfig?.minhours || 0

                            let maxdailylimit = selectedTimetype?.maxdailylimit
                            let mindailylimit = selectedTimetype?.mindailylimit || 0

                            if (row?.entrytype === 'Project') {
                                let cellLeave = leaves.find(l => l?.leavedate === effort?.effortdate)
                                if (cellLeave?.Noofdays < 1) {
                                    maxhours = maxhours / 2;
                                    minhours = minhours / 2;
                                    maxdailylimit = maxdailylimit / 2
                                    mindailylimit = mindailylimit / 2
                                }
                            }

                            if (row?.allocation) {
                                allocation = row?.allocation
                            } else if (row?.selectedAllocation?.allocation) {
                                allocation = row?.selectedAllocation?.allocation || 0
                            } else if (row?.allocationperc) {
                                allocation = row?.allocationperc * 100
                            }

                            // projstaff id -> check for total and validate against min and max total hours (based on daily or weekly)
                            let allocMaxHrs = (maxhours * allocation) / 100
                            let allocMinHrs = !minhours ? 0 : (minhours * allocation) / 100

                            // prostaffid + timetypeid -> check for allowed range and validate against that. 
                            let rangeAllocMaxHrs = (maxdailylimit * allocation) / 100
                            let rangeAllocMinHrs = !mindailylimit ? 0 : (mindailylimit * allocation) / 100

                            let dailyHrsByProjStaffIdTimetype = dailyHrsTimeProjStaffId[`${row?.projstaffid}-${row?.timetypeid}`]?.find(r => r?.date === effort?.effortdate)
                            let dailyHrsByProjStaffId = dailyHrsTimeProjStaffId[row?.projstaffid]?.find(r => r?.date === effort?.effortdate)

                            let dailyHaveValues_P = Object.keys(dailyHrsTimeProjStaffId)?.length > 0
                            let dailyHaveValues_G = Object.keys(dailyHrsOfGenric)?.length > 0

                            let currentCellTotal = parseFloat(document.getElementById(`cell-effort-${effort?.effortdate}`).textContent)

                            if (!isNaN(currentCellTotal) && currentCellTotal > 24) {
                                AlertError('Daily Effort should not be more than 24 hours')
                                setApiCall(false)
                                return
                            }

                            if (timesheetconfiguration?.allocationrestriction) {
                                if (dailyHaveValues_P && row?.entrytype === 'Project') {
                                    let firstConValue = dailyHrsByProjStaffIdTimetype[effort?.effortdate]
                                    let secConValue = dailyHrsByProjStaffId[effort?.effortdate]
                                    if ((firstConValue > rangeAllocMaxHrs || firstConValue < rangeAllocMinHrs) && (timesheetconfiguration?.templatetype === 'P')) {
                                        AlertError(`Project : ${row?.projectname}, Daily Effort for ${selectedTimetype?.timetypename} should be between ${rangeAllocMinHrs}-${rangeAllocMaxHrs} Hours`)
                                        setApiCall(false)
                                        return
                                    } else if ((secConValue > allocMaxHrs || secConValue < allocMinHrs) && timesheetconfiguration?.validationunitname !== 'Weekly') {
                                        AlertError(`Project : ${row?.projectname}, Total Daily Effort should be between  ${allocMinHrs} - ${allocMaxHrs} hours`)
                                        setApiCall(false)
                                        return
                                    }
                                }
                            } else {
                                if (dailyHaveValues_P && row?.entrytype === 'Project') {
                                    let firstConValue = dailyHrsByProjStaffIdTimetype[effort?.effortdate]
                                    let secConValue = dailyHrsByProjStaffId[effort?.effortdate]
                                    if ((firstConValue > maxdailylimit || firstConValue < mindailylimit) && (timesheetconfiguration?.templatetype === 'P')) {
                                        AlertError(`Project : ${row?.projectname}, Daily Effort for ${selectedTimetype?.timetypename} should be between  ${mindailylimit} - ${maxdailylimit} hours`)
                                        setApiCall(false)
                                        return
                                    } else if ((secConValue > maxhours || secConValue < minhours) && (timesheetconfiguration?.templatetype === 'P' && timesheetconfiguration?.validationunitname !== 'Weekly')) {
                                        AlertError(`Project : ${row?.projectname}, Daily Effort for ${selectedTimetype?.timetypename} should be between ${minhours} - ${maxhours} Hours`)
                                        setApiCall(false)
                                        return
                                    }
                                } else if (dailyHaveValues_G && row?.entrytype === 'Generic') {
                                    let firstConValue = dailyHrsOfGenric[`${genricTimeConfig?.timetemplatemasterid}-${row?.timetypeid}`]?.find(r => r?.date === effort?.effortdate)[effort?.effortdate]
                                    let secConValue = dailyHrsOfGenric[genricTimeConfig?.timetemplatemasterid]?.find(r => r?.date === effort?.effortdate)[effort?.effortdate]
                                    if ((firstConValue > maxdailylimit || firstConValue < mindailylimit)) {
                                        AlertError(`Generic : Daily Effort for ${selectedTimetype?.timetypename} should be between ${mindailylimit} - ${maxdailylimit} hours`)
                                        setApiCall(false)
                                        return
                                    } else if ((secConValue > genricTimeConfig?.maxhours || secConValue < genricTimeConfig?.minhours) && genricTimeConfig?.validationunitname !== 'Weekly') {
                                        AlertError(`Generic : Total Daily Effort should be between ${genricTimeConfig?.minhours} - ${genricTimeConfig?.maxhours} hours`)
                                        setApiCall(false)
                                        return
                                    }
                                }
                            }
                        }
                    }

                    if (duplicates?.includes(`${row?.projecttaskid}-${row?.projstaffid}-${row?.projtaskid}-${row?.timetypeid}`)) {
                        AlertError("Selected time type and task already exists")
                        setApiCall(false)
                        return
                    } else {
                        duplicates.push(`${row?.projecttaskid}-${row?.projstaffid}-${row?.projtaskid}-${row?.timetypeid}`)
                    }
                }

            }
            let detailjson = employeeTimesheetData?.filter(row => row?.curstatus !== 'Submitted')?.map((it => {
                let row = {}
                if (it["isactiveflag"] === undefined) {
                    row["isactiveflag"] = true
                } else {
                    row["isactiveflag"] = it["isactiveflag"]
                }
                row["statusname"] = it['curstatus'] || 'Draft'
                row["entrytype"] = it["entrytype"]
                row["projstaffid"] = it["projstaffid"]
                row["projectid"] = it["projectid"]
                row["prevstatusname"] = it["prevstatus"]
                row["projecttaskid"] = it["projecttaskid"]
                row["timetypeid"] = it["timetypeid"]
                row["projtaskid"] = it["projtaskid"]
                row["taskdesc"] = it["taskdesc"] || ''
                row["staffremarks"] = it["effortnotes"] || ''
                row["referencenumber"] = it["referencenumber"] || ''
                row['effortid'] = it?.effortid
                if (it?.effortid && it?.isactiveflag === false) {
                    row['timesheetdetails'] = []
                } else {
                    row['timesheetdetails'] = it?.timesheetdetails?.map(row => {
                        let e = { ...row }
                        e['endtime'] = converttimeToDecimal(row?.endtime)
                        e['starttime'] = converttimeToDecimal(row?.starttime)
                        return e

                    })
                }

                return row
            }))
            detailjson = detailjson.filter(row => row?.projtaskid)
            let aciveOnly = []
            for (let d of detailjson) {
                if (d?.effortid === null && d?.isactiveflag !== false) {
                    aciveOnly.push(d)
                } else if (d?.effortid !== null && d?.isactiveflag === false) {
                    aciveOnly.push(d)
                } else if (d?.effortid !== null) {
                    aciveOnly.push(d)
                }
            }
            if (aciveOnly?.length === 0) {
                setApiCall(false)
                return
            }

            let payload = {
                timesheetjson: [
                    {
                        comuserid: +localStorage.getItem("comuserid"),
                        createdcomuserid: +localStorage.getItem("comuserid"),
                        notes: "",
                        startdate: moment(weeks?.monday).format('YYYY-MM-DD'),
                        enddate: moment(weeks?.sunday).format('YYYY-MM-DD'),
                        isactiveflag: true,
                        comtenantid: +localStorage.getItem("Tenantid")
                    }
                ],
                detailjson: aciveOnly,
            }
            let res = await dispatch(INSERT_TIMESHEET_EFFORT_TASK(payload))
            if (res) {
                fetchTimesheetData()
            }
            setApiCall(false)
        }, 800)
        return () => {
            clearTimeout(debounceTimer)
        }
    }

    const handleSubmit = () => {
        setApiCall(true)
        const debounceTimer = setTimeout(async () => {

            let activedataonly = await employeeTimesheetData?.filter(row => row?.isactiveflag !== false && (row?.curstatus !== "Approved" || row?.curstatus !== "Submitted"))

            let genericItemIs = activedataonly.find(r => r?.entrytype === 'Generic' && r?.isactiveflag !== false)

            let genricTimeConfig = genericItemIs?.timesheetconfiguration?.length
                ? genericItemIs?.timesheetconfiguration[0]
                : genericItemIs?.timesheetconfiguration;


            for (let row of activedataonly) {
                let timesheetconfiguration = row?.timesheetconfiguration?.length ? row?.timesheetconfiguration[0] : row?.timesheetconfiguration

                let allocation = 0

                let totalweekHrs = weekHrsByProjstaffId[row?.projstaffid]
                let totalWeekGenricHrs = weeklyHrsOfGenric[genricTimeConfig?.timetemplatemasterid] || 0

                let selectedTimetype = timesheetconfiguration?.timetypetemplatemapping?.find(value => value?.timetypeid === row?.timetypeid)

                let maxhours = row?.entrytype === 'Project' ? timesheetconfiguration?.maxhours : genricTimeConfig?.maxhours
                let minhours = row?.entrytype === 'Project' ? (timesheetconfiguration?.minhours || 0) : genricTimeConfig?.minhours || 0

                if (row?.allocation) {
                    allocation = row?.allocation
                } else if (row?.selectedAllocation?.allocation) {
                    allocation = row?.selectedAllocation?.allocation || 0
                } else if (row?.allocationperc) {
                    allocation = row?.allocationperc * 100
                }

                // projstaff id -> check for total and validate against min and max total hours (based on daily or weekly)
                let allocMaxHrs = (maxhours * allocation) / 100
                let allocMinHrs = !minhours ? 0 : (minhours * allocation) / 100

                if (timesheetconfiguration?.validationunitname === "Weekly") {
                    if (timesheetconfiguration?.allocationrestriction) {
                        if ((totalweekHrs > allocMaxHrs || totalweekHrs < allocMinHrs) && timesheetconfiguration?.templatetype === 'P') {
                            AlertError(`Project : Weekly Effort for ${row?.projectname} project, should be between  ${allocMinHrs} - ${allocMaxHrs} hours`)
                            setApiCall(false)
                            return
                        }
                    } else {
                        if ((totalweekHrs > maxhours || totalweekHrs < minhours) && timesheetconfiguration?.templatetype === 'P') {
                            AlertError(`Project : Weekly Effort for ${row?.projectname} project, should be between  ${minhours} - ${maxhours} hours`)
                            setApiCall(false)
                            return
                        } if ((totalWeekGenricHrs > maxhours || totalWeekGenricHrs < minhours) && genricTimeConfig?.templatetype === 'G') {
                            AlertError(`Generic : Weekly Effort for ${selectedTimetype?.timetypename} should be between ${minhours} - ${maxhours} hours`)
                            setApiCall(false)
                            return
                        }
                    }
                }
            }

            let timesheetjson = activedataonly?.filter(i => i?.curstatus !== 'Approved')?.map(it => {
                let row = {}
                if (it?.effortid) {
                    row['effortid'] = it?.effortid
                    row['statusname'] = 'Submitted'
                    row['comtenantid'] = Number(localStorage.getItem('Tenantid') || 0)
                }

                return row
            })

            let payload = { timesheetjson }
            let res = await dispatch(SUBMIT_TIMESHEET_OF_EMPLOYEE(payload))
            if (res) {
                fetchTimesheetData()
            }
            setApiCall(false)

        }, 1000)
        return () => {
            clearTimeout(debounceTimer)
        }
    }

    return (
        <div className='h-[calc(100%-100px)]'>
            <ScrollToBottom className="rounded-lg h-full text-sm">
                <table className='relative w-full text-sm'>
                    <thead>
                        <tr className='text-left capitalize bg-gray-50'>
                            {cols?.map((col, i) => {
                                return (
                                    <th
                                        key={i}
                                        title={col}
                                        scope='col'
                                        className='font-[500] text-xs px-2 py-2 border text-start'
                                    >
                                        {col}
                                    </th>
                                );
                            })}
                            <DayHeaders startdate={weeks?.monday} />
                            {cols2?.map((col, i) => {
                                return (
                                    <th
                                        key={i}
                                        title={col}
                                        scope='col'
                                        className='font-[500] text-xs px-2 py-2 border text-start'
                                    >
                                        {col}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getActiveData(employeeTimesheetData)?.map((it) => {
                                return (
                                    <Row key={it?.uId}
                                        rowId={it?.uId}
                                        hooks={{ employeeTimesheetData, it, projects, leaves, weeks, generateEffotCombination, generateEffotCombinationForGenric }} />
                                )
                            })
                        }
                    </tbody>
                    <tbody>
                        <Calculation weeks={weeks}
                            data={getActiveData(employeeTimesheetData)} />
                    </tbody>
                </table>
            </ScrollToBottom>
            <div className="flex justify-end items-center space-x-2">
                {(!showSaveBtn && showSubmitBtn) ? <button
                    disabled={apiCall}
                    onClick={handleSubmit}
                    className="text-white bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm px-5 mt-2 mb-2 focus:outline-none py-2 disabled:bg-primary/20 rounded-md my-0"
                >{apiCall ? 'Processing...' : 'Submit'}</button> : <>
                    {showSaveBtn ? <button
                        disabled={apiCall}
                        onClick={handleInsertTimesheet}
                        className="text-white bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm px-5 mt-2 mb-2 focus:outline-none py-2 disabled:bg-primary/20 rounded-md my-0"
                    >{apiCall ? 'Processing...' : 'Save'}</button> : ''}
                </>}
            </div>
        </div>
    )
}

export default Table;