import { AlertError } from 'src/Services'

const allowedFileTypes = ['pdf']

const UploadResume = props => {

	const {
		resume: { uId, filepath, filename},
		resume,
		setResume,
		setPdfUrl,
		pdfUrl
	} = props

	const handleFileChange = e => {
		const fileInput = e.target

		const { files } = e.target
		if (files?.length) {
			let doc = files[0]
			setPdfUrl(URL.createObjectURL(doc));
			if (!allowedFileTypes.includes(doc?.type.split('/')[1])) {
				fileInput.value = null
				AlertError(
					`Invalid file type. Allowed formats are : ${allowedFileTypes}`,
				)
				return
			}
			const maxSizeInBytes = 25 * 1024 * 1024 // 25 MB

			// Check if the file size exceeds the limit
			if (doc.size > maxSizeInBytes) {
				AlertError('Please choose a file smaller than 5 MB.')
				// Optionally, you can clear the file input
				fileInput.value = null
				return
			}

			const reader = new FileReader()
			reader.onloadend = () => {
				// The result property contains the base64 string
				const base64 = reader.result.split(',')[1]
				let item = { ...resume }
				// item['filepath'] = ''
				item['file'] = base64
				item['filename'] = doc?.name
				item['fileformat'] = doc?.type

				setResume(item)
			}

			// Read the file as a data URL
			reader.readAsDataURL(doc)
		}
	}

	return (
		<div className='flex items-center justify-start'>
			<span className='mx-2 text-gray-600'>{filepath ? filename : 'Upload Resume'}</span>
			<label>
				{pdfUrl ? <span className='flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary/20'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-4 h-4'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18'
						/>
					</svg>
				</span> :  <span className='flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-4 h-4'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18'
						/>
					</svg>
				</span>}
				<input
					disabled={pdfUrl}
					accept='.pdf, .docx'
					onChange={handleFileChange}
					className='hidden'
					id={uId}
					type='file'
				/>
			</label>
		</div>
	)
}

export default UploadResume
