import IconButton from 'src/Components/Buttons/IconButton';

const RaiseSr = ({ handleRaiseSr, item }) => {

    return (
        <IconButton title={item?.demandid !== null ? 'Edit Demand' : 'Add Demand'} onClick={() => handleRaiseSr(item)} className='h-7 w-7 bg-primary text-white'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
        </IconButton>
    )
}

export default RaiseSr;