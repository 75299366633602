
function getUserId() { return JSON.parse(localStorage.getItem('comuserid')) }
function gettenantId() { return JSON.parse(localStorage.getItem('Tenantid')) }


export const defaultItemOfTask = () => {
    return (
        {
            taskid: null,
            comtenantid: gettenantId(),
            taskgroupid: 0,
            taskdesc: "",
            tasktypeid: 0,
            isactiveflag: true,
            createdcomuserid: getUserId(),
            notes: "",
            uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
        }
    )
}

export const insertDataKeys = ['taskgroupid', 'taskdesc','notes', 'createdcomuserid', 'isactiveflag', 'tasktypeid', 'comtenantid', 'taskid']