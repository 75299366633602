import { useEffect, useState } from 'react'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import { GET_DEPARTMENT_MASTER } from 'src/Redux/actions/workforce/talent'
import { getDepartmentMasterIdData } from 'src/Services/constant/defaultPayload'

const SelectDepartment = ({ department, setDepartment, departmentlist }) => {
	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		if (department?.department) {
			setSearchTerm(department?.department || '')
		}
	}, [department])

	return (
		<>
			<CustomAutoSearch
				searchTerm={searchTerm}
				API_CALL_FOR_SEARCH={GET_DEPARTMENT_MASTER}
				searchPayload={{
					...getDepartmentMasterIdData(),
					searchname: searchTerm,
				}}
				className='p-2'
				setSearchTerm={setSearchTerm}
				options={departmentlist}
				idkey='departmentid'
				optionlabelKey='department'
				getSelectedOption={setDepartment}
				placeholder='Search Department'
			/>
		</>
	)
}

export default SelectDepartment
