import CustomhtmlSelect from "src/Components/CustomhtmlSelect";

const SelectStatus = ({ statusListByType, handleChangeValue, value }) => {

    return (
        <>
            <label htmlFor="projectstatus" className="block font-[500] mb-2">Select Status <span className="text-red-300">*</span></label>
            <CustomhtmlSelect
                options={statusListByType || []}
                value={value}
                noDataLabel='No Status'
                name='projectstatus'
                labelkey='statusname'
                						className='border rounded bg-gray-50 py-2'

                onChange={handleChangeValue}
                id='statusid'
            />
        </>
    )
}

export default SelectStatus;