import { useMemo, useState } from "react";
import SelectInput from "src/Components/SelectInput";


const SelectCurrency = ({currencyList, disabled, value, handleChange, uId}) => {
    const [currency, setCurrency] = useState({})

    useMemo(()=>{
        let item = currencyList?.find(a=> a?.datcomcurrencyid === value)
        setCurrency(item)
        // eslint-disable-next-line 
    },[value])

    const changeCurrency = (valueis)=>{
        let e = { target: { name: 'datcomcurrencyid', value: valueis['datcomcurrencyid'], id: uId } }
        handleChange(e)
        // setCurrency(valueis)
    }

    return (
        <SelectInput
            className='outline-gray-50 mt-0 text-gray-600'
            options={currencyList}
            keyname='currencycode'
            mapkey='datcomcurrencyid'
            disabled={disabled}
            selected={currency}
            setSelected={changeCurrency} />
    )
}

export default SelectCurrency;