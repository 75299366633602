import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DOWNLOAD_CUSTOMER_DOCUMENT } from 'src/Redux/actions/masterdata/customer'
import PreviewPdf from './previewPdf'

const DownloadResume = props => {
	const dispatch = useDispatch()
	const [binaryData, setBinaryData] = useState('')
	const {
		item: {  fileformat, filepath },item,empCode
	} = props

	async function getBase64() {
		let res = await dispatch(DOWNLOAD_CUSTOMER_DOCUMENT(filepath))
		setBinaryData(res?.res?.data)
	}

	useEffect(() => {
		if (filepath) {
			getBase64()
		}
		// eslint-disable-next-line
	}, [filepath])

	return (
		<PreviewPdf title={`${item?.createduser} (${empCode})`} binaryData={binaryData} fileformat={fileformat} />
	)
}

export default DownloadResume
