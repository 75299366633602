import moment from 'moment';
import { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { CHANGE_STATUS_OF_TIMESHEET, GET_MANAGER_TIMESHEET_RECORDS } from 'src/Redux/actions/projects/timesheet';
import { AlertError } from 'src/Services';

function RejectOrApproveButton({ setActive, item, updatehooks, open, setOpen }) {
    let { selectStatus, selectUser, project, weeks, limit, page, } = updatehooks
    const [note, setNote] = useState('')
    const [action, setAction] = useState('')
    const dispatch = useDispatch()

    const openModal = (flag) => {
        setAction(flag)
        setOpen(true);
        if (flag === 'Approve') {
            setActive(item)
            setNote('Approved')
        } else {
            setActive(item)
            setNote('')
        }
    }

    const handleApproveReject = async () => {
        let payload = null
        if(note?.trim()?.length === 0){
            AlertError('Please Enter a comment')
            return
        }

        if (action === 'Approve') {
            payload = {
                statusname: "Approved",
                effortid: item?.timesheetdata[0]?.effortid,
                approverremarks: note,
                approved: true,
            }
        } else if (action === 'Reject') {
            payload = {
                statusname: item?.timesheetdata[0]?.prevstatus || "Draft",
                effortid: item?.timesheetdata[0]?.effortid,
                approverremarks: note,
                approved: false,
            }
        }
        let res = await dispatch(CHANGE_STATUS_OF_TIMESHEET(payload))
        if (res) {
            let payload = {
                "loginuserid": localStorage.getItem('comuserid'),
                "comuserid": selectUser?.comuserid || 0,
                "Tenantid": localStorage.getItem("Tenantid"),
                "projectid": project?.projectid || 0,
                "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
                "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
                "timesheetstatus": selectStatus?.value,
                "limit": limit,
                "page": page
            }
            dispatch(GET_MANAGER_TIMESHEET_RECORDS(payload))
            setOpen(false)
        }
    }

    return (
        <div className="relative">
            <div className="group relative ">
                <div className='flex justify-end items-center space-x-2'>
                    <button onClick={() => openModal('Reject')} className='focus:outline-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ce381e"><path d="m291-208-83-83 189-189-189-189 83-83 189 189 189-189 83 83-189 189 189 189-83 83-189-189-189 189Z" /></svg>
                    </button>
                    <button onClick={() => openModal('Approve')} className='focus:outline-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#228B22"><path d="M382-208 122-468l90-90 170 170 366-366 90 90-456 456Z" /></svg>
                    </button>
                </div>
            </div>
            {open && <div className="p-2 transition right-0 z-[1000] ease-in duration-100 absolute mt-3 w-72 min-w-[90px] rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className='border rounded-md bg-gray-50'>
                    <textarea id="note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        name="note" rows="3"
                        placeholder='Enter comments'
                        className=" bg-gray-50  placeholder:text-xs placeholder:placeholder:italic outline-none  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2" />
                </div>
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={() => setOpen(false)}
                        type="button"
                        className="inline-flex justify-center rounded-md border bg-white border-transparent bg-primary px-4 py-1 text-sm font-[500] text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleApproveReject}
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-1 text-sm font-[500] text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                        {action}
                    </button>
                </div>
            </div>}
        </div>
    )
}



export default memo(RejectOrApproveButton)