import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import SelectInput from 'src/Components/SelectInput';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { detailNotRequired } from './defaultRowAndData';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';

const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    account,
    setAccount,
    canEdit,
    setCanEdit,
  } = props;
  const [city, setCity] = useState({})
  const [searchCity, setSearchCity] = useState('')
  const { companylist, citylist } = useSelector((state) => state.EmployeeDetails);
  const { industrylist } = useSelector((state) => state.EmployeeProfile);
  const { businessUnitList, currencyList } = useSelector((state) => state?.Projects);
  const [checkvalidation, setcheckvalidation] = useState(false);
  const { customerGroupList } = useSelector(
    (state) => state.UserManagementList
  );
  const { sourcefilteredlist } = useSelector(
    (state) => state.CustomerManagementList
  );

  useEffect(() => {
    if (city?.cityname) {
      setSearchCity(`${city?.cityname}`);
    }
  }, [city]);

  useEffect(() => {
    if (detailsData?.cityname) {
      setCity({ cityname: detailsData?.cityname, comcityid: detailsData?.comcityid })
    }
  }, [detailsData])

  useEffect(() => {
    setDetailsData({
      ...detailsData,
      Source: detailsData?.source,
    });
    // setAccountSelect(accountSelect)
    let findAccount = customerGroupList?.find(
      (it) => it?.customergroupid === detailsData?.customergroupid
    );
    setAccount(findAccount);
    // eslint-disable-next-line
  }, [setAccount, customerGroupList]);

  const handleSubmit = () => {
    setcheckvalidation(true);

    let item = {
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      customerid: detailsData?.customerid,
      companyid: detailsData?.companyid,
      customername: detailsData?.customername,
      industryid: detailsData?.industryid,
      businessunitid: detailsData?.businessunitid,
      defaultcomcurrencyid: detailsData?.defaultcomcurrencyid,
      address3: detailsData?.address3,
      address_street: detailsData?.address_street,
      comcityid: city?.comcityid,
      comcountryid: detailsData?.comcountryid,
      phoneno: detailsData?.phoneno,
      email: detailsData?.email,
      Source: detailsData?.Source,
      payment_days: detailsData?.payment_days || 30,
      customergroupid: account?.customergroupid,
      accountmanagerid: parseFloat(account?.accountmanagerid),
      isactiveflag: detailsData?.isactiveflag,
      shortname: detailsData?.shortname,
      fax: detailsData?.fax,
    };

    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        customerjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setcheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div>
      <div className='w-full h-[65vh] text-sm mb-10'>
        <div className='grid grid-cols-4 gap-5 mt-4'>
          <div>
            <label htmlFor='customercode' className='block font-[500] mb-2'>
              Customer Code
            </label>
            <input
              disabled
              type='text'
              title='customercode'
              id='customercode'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='customercode'
              // required=''
              value={detailsData?.customercode || ''}
              onChange={(e) =>
                handleChangeInput('customercode', e.target.value)
              }
            />
          </div>
          <div>
            <label htmlFor='companyname' className='block font-[500] mb-2'>
              Company
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={companylist || []}
              value={detailsData?.companyid || ''}
              noDataLabel='No Projects'
              name='companyid'
              labelkey='companyname'
              className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
              onChange={handleChangeInput}
              id='companyid'
            />
            {!detailsData?.companyid && checkvalidation && (
              <small className='text-red-400'>Field is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='customername' className='block font-[500] mb-2'>
              Customer Name
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='text'
              onChange={(e) => {
                if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                  handleChangeInput('customername', e.target.value);
                }
              }}
              title='customeranme'
              id='customername'
              className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
              placeholder='Customer Name'
              required=''
              value={detailsData?.customername || ''}
              maxLength={50}
            />
            {!detailsData?.customername && checkvalidation && (
              <small className='text-red-400'>name is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='source' className='block font-[500] mb-2'>
              Select Source
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={sourcefilteredlist || []}
              value={detailsData?.Source}
              noDataLabel='No Source'
              name='Source'
              labelkey='sourcename'
              className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
              onChange={handleChangeInput}
              id='sourcename'
            />
            {!detailsData?.Source && checkvalidation && (
              <small className='text-red-400'>Source is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='account' className='block font-[500] mb-2'>
              Select Account
              <span className='text-red-300'> *</span>
            </label>
            <SelectInput
              disabled={canEdit}
              className={`outline-gray-50 bg-${canEdit ? 'white' : 'gray-50'
                } text-gray-600 border rounded-lg block w-full py-2`}
              options={customerGroupList}
              keyname='customergroupname'
              selected={account}
              setSelected={setAccount}
            />
            {!account?.customergroupname && checkvalidation && (
              <small className='text-red-400'>Account is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='Industry' className='block font-[500] mb-2'>
              Industry
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={industrylist || []}
              value={detailsData?.industryid || ''}
              noDataLabel='No Industry'
              name='industryid'
              labelkey='industryname'
              className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
              onChange={handleChangeInput}
              id='industryid'
            />
            {!detailsData?.industryid && checkvalidation && (
              <small className='text-red-400'>Industry is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='BusinessUnit' className='block font-[500] mb-2'>
              Business Unit
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={businessUnitList || []}
              value={detailsData?.businessunitid || ''}
              noDataLabel='No Business Unit'
              name='businessunitid'
              labelkey='businessunitname'
              className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
              onChange={handleChangeInput}
              id='businessunitid'
            />
            {!detailsData?.businessunitid && checkvalidation && (
              <small className='text-red-400'>Business Unit is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='DefaultCurrency' className='block font-[500] mb-2'>
              Default Currency
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={true}
              options={currencyList || []}
              value={detailsData?.defaultcomcurrencyid || ''}
              noDataLabel='No Currency'
              name='defaultcomcurrencyid'
              labelkey='currency'
              className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
              onChange={handleChangeInput}
              id='datcomcurrencyid'
            />
            {!detailsData?.defaultcomcurrencyid && checkvalidation && (
              <small className='text-red-400'>Currency is required!</small>
            )}
          </div>
          <div>
            <label
              htmlFor='AccountManager'
              className='block mb-2 text-sm font-[500]'
            >
              Account Manager
            </label>
            <input
              disabled={canEdit}
              value={account?.accountmanagername || ''}
              type='text'
              title='accountmanagername'
              id='accountmanagerid'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='Account Manager'
              required=''
            />
          </div>
          <div>
            <label htmlFor='addressline1' className='block font-[500] mb-2'>
              Address Line 1<span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='text'
              title='address3'
              id='address3'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='Address Line 1'
              required=''
              value={detailsData?.address3 || ''}
              onChange={(e) => handleChangeInput('address3', e.target.value)}
              maxLength={50}
            />
            {!detailsData?.address3 && checkvalidation && (
              <small className='text-red-400'>Address is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='address_street' className='block font-[500] mb-2'>
              Address Line 2<span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='text'
              title='address_street'
              id='address_street'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='Address Street'
              required=''
              value={detailsData?.address_street || ''}
              onChange={(e) =>
                handleChangeInput('address_street', e.target.value)
              }
              maxLength={50}
            />
            {!detailsData?.address_street && checkvalidation && (
              <small className='text-red-400'>
                Address Street is required!
              </small>
            )}
          </div>
          <div>
            <label htmlFor='cityname' className='block font-[500] mb-2'>
              City
              <span className='text-red-300'> *</span>
            </label>
            <CustomAutoSearch
              searchTerm={searchCity}
              API_CALL_FOR_SEARCH={GET_CITY_MASTER}
              searchPayload={{ ...getMasterCityPayload(), searchname: searchCity }}
              setSearchTerm={setSearchCity}
              options={citylist}
              idkey='comcityid'
              disabled={canEdit}
              optionlabelKey='cityname'
              getSelectedOption={setCity}
              className='p-2'
              placeholder='Enter City Name'
            />
            {!city?.comcityid && checkvalidation && (
              <small className='text-red-400'>City is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='phone' className='block font-[500] mb-2'>
              Phone
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='tel'
              title='phoneno'
              id='phoneno'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='Phone No'
              required=''
              value={detailsData?.phoneno || ''}
              onChange={(e) => handleChangeInput('phoneno', e.target.value)}
            />
            {!detailsData?.phoneno && checkvalidation && (
              <small className='text-red-400'>Phone is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='email' className='block font-[500] mb-2'>
              Email
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='email'
              title='email'
              id='email'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='Email..'
              required=''
              value={detailsData?.email || ''}
              onChange={(e) => handleChangeInput('email', e.target.value)}
            />
            {!detailsData?.email && checkvalidation && (
              <small className='text-red-400'>Email is required!</small>
            )}
          </div>
          <div>
            <label htmlFor='fax' className='block font-[500] mb-2'>
              Fax
            </label>
            <input
              disabled={canEdit}
              type='text'
              title='fax'
              id='fax'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-lg block w-full p-1.5'
              placeholder='Fax'
              required=''
              value={detailsData?.fax || ''}
              onChange={(e) => handleChangeInput('fax', e.target.value)}
            />
          </div>
        </div>
        <div className='fixed bottom-0 mt-2 right-6'>
          {!canEdit && (
            <div className='fixed bottom-0 mt-2 right-6'>
              <div className='gap-1.5 flex justify-end items-center'>
                <button
                  onClick={() => setCanEdit(false)}
                  type=''
                  className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                >
                  Cancel
                </button>
                <PrimaryButton
                  disabled={canEdit}
                  className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                  onClick={handleSubmit}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Details);