
import { memo, useState } from 'react';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';

const Details = (props) => {
    const { detailSave, detailsData, setDetailsData } = props;
    const [checkvalidation, setCheckvalidation] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault()

        setCheckvalidation(true);
        let isValidated = CheckObjectValidation(detailsData, [
            'modifiedcomuserid',
            'notes',
            'industryid'
        ]);
        if (isValidated?.isvalid) {
            let payload = {
                industryjson: [detailsData],
            };
            detailSave(payload);
        }
    };

    const handleChangeInput = (name, value) => {
        setCheckvalidation(false);
        setDetailsData({ ...detailsData, [name]: value });
    };

    return (
        <div className='w-full h-[65vh] text-sm mb-10'>
            <div className='grid grid-cols-4 gap-1 mt-4'>
                <div>
                    <label htmlFor='reason' className='block font-[500] mb-2'>
                        Industry
                        <span className='text-red-300'> *</span>
                    </label>
                    <input
                        type='text'
                        title='industryname'
                        id='industryname'
                        className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
                        placeholder='Industry Name'
                        maxLength={50}
                        onChange={(e) => {
                            if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                                handleChangeInput('industryname', e.target.value);
                            }
                        }}
                    />
                    {!detailsData?.industryname && checkvalidation && (
                        <small className='text-red-400'>Industry Name is required!</small>
                    )}
                </div>
                <div className='fixed bottom-0 mt-2 right-6'>
                    <div className='flex items-center justify-end gap-2'>
                        <PrimaryButton
                            className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
                            onClick={handleSubmit}
                        >
                            Save
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default memo(Details);
