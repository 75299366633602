const initialState = {
  tenants: [],
  tenantList: [],
  subscriptionList: [],
  subscriptions: [],
  tenantSubscription: [],
  tenantSubscriptionById: []
}

const SubscriptionList = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_TENANTS_MASTER':
      return { ...state, tenants: action.data?.Message ? [] : action.data, };
    case "GET_TENANTS_MASTER":
      return { ...state, tenantList: action.data }
    case "GET_SUBSCRIPTIONS_MASTER":
      return { ...state, subscriptionList: action.data }
    case "SAVE_SUBSCRIPTION_MASTER":
      return { ...state, subscriptions: action.data }
    case "SAVE_SUBSCRIPTION_DETAILS":
      return { ...state, tenantSubscription: action.data }
    case "GET_TENANT_SUBSCRIPTION_PRICING_BY_ID":
      return { ...state, tenantSubscriptionById: action.data }
    default:
      return state;
  }
};

export default SubscriptionList;



