import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'src/Components/Buttons/IconButton';
import { AlertError, AlertSuccess } from 'src/Services';
import Customer from './customer';
import { weekdaysItem } from './default';
import { GET_CUSTOMER_MASTERLIST } from 'src/Redux/actions/masterdata/customer';
import { GET_WEEKDAYS_OF_A_CUSTOMER, SAVE_CUSTOMER_WEEKDAYS } from 'src/Redux/actions/masterdata/user';
import { getCustomerListPayload } from 'src/Services/constant/Customerpayload';
import { getDefaultCustomerWeekdayPayload } from 'src/Services/constant/defaultPayload';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';


const CustomerWeekdaysMasterlist = () => {
  const dispatch = useDispatch();
  const [weekdaysData, setWeekdaysData] = useState([]);
  const customerweekdaysid = null
  const { customerWeekdaysList } = useSelector((state) => state.UserManagementList);
  const { listOfCustomers } = useSelector((state) => state.CustomerManagementList);

  useEffect(() => {
    dispatch(GET_WEEKDAYS_OF_A_CUSTOMER(getDefaultCustomerWeekdayPayload()));
    let payload = getCustomerListPayload()
    delete payload['limit']
    delete payload['pageno']
    payload['sorttype'] = 1;
    dispatch(GET_CUSTOMER_MASTERLIST(payload));
  }, [dispatch]);

  useEffect(() => {
    let rmUnwanted = customerWeekdaysList?.map((it) => {
      delete it['createdtimestamp'];
      delete it['modifiedtimestamp'];
      return it;
    });
    if (rmUnwanted?.length > 0) {
      const sortedData = [...rmUnwanted].sort((a, b) => a.customerweekdaysid - b.customerweekdaysid);
      setWeekdaysData(sortedData);
    } else {
      const defaultRow = weekdaysItem();
      setWeekdaysData([defaultRow]);
    }
  }, [customerWeekdaysList]);


  const handleAdd = () => {
    const maxUId = weekdaysData.reduce((acc, curr) => Math.max(acc, curr.uId), 0);
    const newRow = weekdaysItem(maxUId + 1);
    setWeekdaysData((prevData) => [...prevData, newRow]);
  };

  const SelectHandleChange = (e, uId) => {
    const { name, value } = e.target;
    let isCustomerAlreadySelected = false;

    const updatedData = weekdaysData.map((item) => {
      if (item.uId !== uId && item.customerid === value) {
        isCustomerAlreadySelected = true;
      }
      if (item.uId === uId) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    if (isCustomerAlreadySelected) {
      AlertError('Customer already selected!');
      return;
    }
    setWeekdaysData(updatedData);
  };

  const handleDelete = async (uId) => {
    const updatedData = weekdaysData.map((item) => {
      if (item?.uId === uId) {
        return {
          ...item,
          isactiveflag: false
        };
      }
      return item;
    });

    const filteredData = updatedData.filter((item) => {
      return (
        item?.customerweekdaysid !== null &&
        item?.customerweekdaysid !== 0);
    });
    setWeekdaysData(filteredData);
    dispatch(SAVE_CUSTOMER_WEEKDAYS(filteredData, customerweekdaysid))
      .then((res) => {
        if (res?.success) {
          AlertSuccess('Customer Weekdays saved successfully!');
        }
        // After successful dispatch, fetch updated data
        dispatch(GET_WEEKDAYS_OF_A_CUSTOMER(getDefaultCustomerWeekdayPayload()));
      })
      .catch((error) => {
        // Handle error if needed
        console.error('Error saving customer weekdays:', error);
      });
  };


  const handleHolidaySelect = async (e, uId, name) => {
    const { checked } = e.target;
    let afterChange = weekdaysData?.map((item) => {
      if (item.uId === uId) {
        return {
          ...item,
          [name]: !checked,
        };
      }
      return item;
    });
    setWeekdaysData(afterChange);
    const debounceTimer = setTimeout(async () => {
      let res = await dispatch(SAVE_CUSTOMER_WEEKDAYS(afterChange, customerweekdaysid));
      if (res?.success) {
        AlertSuccess('Customer Weekdays saved successfully!');
      }
      dispatch(GET_WEEKDAYS_OF_A_CUSTOMER(getDefaultCustomerWeekdayPayload()));
    }, 100)
    return () => {
      clearTimeout(debounceTimer)
    }
  };

  return (
    <div className='mt-4'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Weekdays
          </h1>
        </div>
      </div>
      <div className='overflow-scroll h-[50vh] pb-40'>
        <table className='w-full relative text-left'>
          <thead className='capitalize bg-gray-50 h-[1vh]'>
            <tr className='p-2 text-left capitalize bg-gray-50'>
              <th className='border font-[500] p-1 '>
                Customer  <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Monday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Tuesday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Wednesday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Thursday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Friday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Saturday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Sunday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] py-2 px-2'>Action</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 mr-4'>
            {weekdaysData?.map((item, i) => {
              const {
                uId,
                monholiday,
                tueholiday,
                wedholiday,
                thuholiday,
                friholiday,
                satholiday,
                sunholiday,
              } = item;
              return (
                <tr key={uId} className='text-left'>
                  <td className='border px-2 py-1'>
                    <Customer
                      value={item?.customerid}
                      rowId={item?.uId}
                      id={uId}
                      handleChange={SelectHandleChange}
                      options={listOfCustomers}
                      labelKey='customername'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.customerid}
                      checked={!monholiday}
                      value={monholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'monholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      disabled={!item?.customerid}
                      type='checkbox'
                      checked={!tueholiday}
                      value={tueholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'tueholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.customerid}
                      checked={!wedholiday}
                      value={wedholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'wedholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.customerid}
                      checked={!thuholiday}
                      value={thuholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'thuholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.customerid}
                      checked={!friholiday}
                      value={friholiday} onChange={(e) => handleHolidaySelect(e, uId, 'friholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.customerid}
                      checked={!satholiday}
                      value={satholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'satholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.customerid}
                      checked={!sunholiday}
                      value={sunholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'sunholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  {(
                    <td className='border'>
                      <div className='flex items-center justify-start w-full px-2'>
                        {weekdaysData?.length > 2 && (
                          <IconButton
                            title='Delete'
                            onClick={() => handleDelete(item?.uId)}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <MinusIcon className="w-3" />
                          </IconButton>
                        )}
                        {weekdaysData?.length - 1 === i && (

                          <IconButton
                            title='Add'
                            disabled={
                              weekdaysData?.length >= 10
                            }
                            onClick={() => handleAdd()}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>)}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default memo(CustomerWeekdaysMasterlist);