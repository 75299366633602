import React from 'react';

const Metrics = ({ title, value }) => {

  return (
    <div className='w-[100%] text-center flex flex-col items-center'>
      <h3 className='text-xs font-[500] capitalize text-gray-700'>{title}</h3>
      <h3 className='text-lg font-[500] capitalize text-secondary mt-2'>{value}</h3>
    </div>
  );
};

export default Metrics;
