import React from 'react';

const ResumePreview = ({ pdfUrl }) => {
  const srcUrl = `${pdfUrl}#view=FitH&zoom=100`;

  return (
    <>
      {pdfUrl && <iframe
        title="PDF Viewer"
        src={srcUrl}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          backgroundColor: 'white', // Set the background color for the container
        }} />}
    </>
  );
};

export default ResumePreview;
