import { memo, useState } from 'react';
import { useSelector, } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';


const Details = (props) => {
  const { companylist } = useSelector((state) => state.EmployeeDetails);
  const { detailSave, detailsData, setDetailsData } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let isValidated = CheckObjectValidation(detailsData, ['timecategorymasterid', 'notes', 'isdefault']);
    if (isValidated?.isvalid) {
      let payload = {
        timecategorymasterjson: [detailsData],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={companylist || []}
            value={detailsData?.companyid || ''}
            noDataLabel='No Company'
            name='companyid'
            labelkey='companyname'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='companyid'
          />
          {!detailsData?.companyid && checkvalidation && (
            <small className='text-red-400'>Company is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='timecategory' className='block font-[500] mb-2'>
            Time Type
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='timecategory'
            id='timecategorymasterid'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder=' Enter Time Type'
            maxLength={50}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('timecategory', e.target.value);
              }
            }}
          />
          {!detailsData?.timecategory && checkvalidation && (
            <small className='text-red-400'>Time Type is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='notes' className='block font-[500] mb-2'>
            Description
          </label>
          <input
            type='text'
            title='notes'
            id='notes'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder=' Enter Description'
            maxLength={50}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('notes', e.target.value);
              }
            }}
          />
        </div>
        <div className='flex items-center mt-2'>
          <label htmlFor='isdefault' className='py-4 block font-[500] mr-4 mt-2'>
            Is Default
          </label>
          <input
            type='checkbox'
            id="isdefault"
            name="isdefault"
            checked={detailsData?.isdefault}
            onChange={(e) => handleChangeInput('isdefault', e?.target.checked)}
            className="mt-2 mr-120"
          />
        </div>
        <div className='fixed bottom-0 right-6 mt-2'>
          <div className='gap-2 flex justify-end items-center'>
            <PrimaryButton
              className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
