
import { useDispatch, useSelector } from "react-redux"
import { memo, useEffect, useMemo, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { GET_USER_MASTER_LIST } from "src/Redux/actions/projects";
import { getDefaultUserPayload } from "src/Services/constant/defaultPayload";

const Owner = ({ setOwner, ownerId, disabled }) => {
    const dispatch = useDispatch()
    const UserManagementList = useSelector(state => state.UserManagementList)
    const { getStatusByTypeE } = UserManagementList
    const projectsReducer = useSelector(state => state.Projects)
    const { userMasterList } = projectsReducer
    const [searchTerm, setSearchTerm] = useState('');
    const [empstatus, setEmpStatus] = useState(`{50,52}`)

    useMemo(() => {
        if (getStatusByTypeE?.length > 0) {
            let id = getStatusByTypeE?.filter(it => it?.statusname === 'Active' || it?.statusname === "Notice Period")
			if(id?.length > 1){
				let value = `{${id[0]?.statusid}, ${id[1]?.statusid} }`
				setEmpStatus(value)
			}
        }
    }, [getStatusByTypeE])

    useEffect(() => {
        let payload = {
            ...getDefaultUserPayload(ownerId),
            username: '',
        }
        dispatch(GET_USER_MASTER_LIST(payload));
         // eslint-disable-next-line 
    }, [ownerId])

    useMemo(() => {
        if (userMasterList?.length === 1) {
            let user = userMasterList[0]
            let usernameis = `${user?.firstname} ${user?.lastname || ''} ${user?.employeecode ? `(${user?.employeecode || 0})` : ''}`
            setSearchTerm((usernameis === undefined ? '' : usernameis).trim())
        }
    }, [userMasterList])

    return (
        <>
            <label htmlFor="Owner" className="block mb-2 text-sm font-[500]">Phase Owner  <span className="text-red-300">*</span></label>
           {empstatus &&  <CustomAutoSearch
                disabled={disabled}
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_USER_MASTER_LIST}
                searchPayload={{
                    ...getDefaultUserPayload(),
                    username: searchTerm,
                    empstatus
                }}
                setSearchTerm={setSearchTerm}
                options={userMasterList}
                // mapping key  (idkey)
                idkey='uId'
                optionLabelkey2='lastname'
                optionlabelKey='firstname'
                bracketLabel='employeecode'
                getSelectedOption={setOwner}
                className='py-2 px-0'
                placeholder='Select Owner' />}
        </>
    )
}

export default memo(Owner)