import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useEffect, useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { useSelector } from 'react-redux';
import MultiSelectItem from 'src/Components/MultiSelect';


const SubscriptionDetails = (props) => {
  const { detailSave, canEdit, setCanEdit, datsubscriptionpackageid, detailsData, setDetailsData, selectedModules, setSelectedModules } = props;
  const [checkvalidation, setcheckvalidation] = useState(false);
  const { menuList } = useSelector((state) => state.ConfigurationList);


  useEffect(() => {
    setSelectedModules(
      detailsData?.datsubscriptionpackagemodule?.map((module) => ({
        datmoduleid: module.datmoduleid,
        modulename: module.modulename,
      })) || []
    );
    // eslint-disable-next-line 
  }, [setSelectedModules]);

  const transformedOptions = menuList.reduce((acc, module) => {
    module.moduledetails.forEach((detail) => {
      const { datmoduleid, modulename } = detail;
      if (modulename !== 'Subscriptions') {
        // Check if datmoduleid already exists in the accumulator
        const existingIndex = acc.findIndex((item) => item.datmoduleid === datmoduleid);
        if (existingIndex === -1) {
          // If not found, add the module
          acc.push({
            datmoduleid,
            modulename: modulename,
          });
        } else {
          // If found, update the modulename if needed
          if (acc[existingIndex].modulename !== modulename) {
            acc[existingIndex].modulename = modulename;
          }
        }
      }
    });
    return acc;
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setcheckvalidation(true);
    let items = {
      datsubscriptionpackageid: datsubscriptionpackageid,
      packagename: detailsData?.packagename,
      activeflag: true,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
      noofusers: detailsData?.noofusers,
      priceperuser: detailsData?.priceperuser,
      currencyid: detailsData?.currencyid,
      datsubscriptionpackagemodule: selectedModules?.map((role) => ({
        datmoduleid: role.datmoduleid,
        modulename: role.modulename
      })),
    };
    let isValidated = CheckObjectValidation(items, ['noofusers', 'datsubscriptionpackageid', 'currencyid','notes']);
    if (isValidated?.isvalid && selectedModules.length > 0) { // Check if selectedModules is not empty
      let payload = {
        datsubscriptionpackagejson: [items],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setcheckvalidation(false);
    if (name === 'priceperuser') {
      // Remove any non-numeric characters from the input value
      const numericValue = value.replace(/[^0-9.]/g, '');
      setDetailsData({ ...detailsData, [name]: numericValue });
    } else {
      setDetailsData({ ...detailsData, [name]: value });
    }
  };

  const handleModuleSelect = (item) => {
    const isNew = selectedModules.some((p) => p.datmoduleid === item.datmoduleid);
    if (isNew) {
      const updatedselectedModules = selectedModules.filter(
        (p) => p.datmoduleid !== item.datmoduleid
      );
      setSelectedModules(updatedselectedModules);
    } else {
      setSelectedModules((prevList) => [
        ...prevList,
        {
          datmoduleid: item.datmoduleid,
          modulename: item.modulename, // Use 'label' from transformedOptions
        },
      ]);
    }
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10 overflow-y-auto'>
      <div className='grid grid-cols-4 gap-2 mt-4'>
        <div className='col-span-1 mr-3'>
          <label htmlFor='packagename' className='block font-[500] mb-2'>
            Subscription Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='packagename'
            id='packagename'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Subscription Name'
            onChange={(e) => handleChangeInput('packagename', e.target.value)}
            value={detailsData?.packagename || ''}
          />
          {!detailsData?.packagename && checkvalidation && (
            <small className='text-red-400'>Subscription Name is required!</small>
          )}
        </div>
        <div className='col-span-3'>
          <div className='grid grid-cols-3 gap-1'>
            <label htmlFor='notes' className='block font-[500] mb-1 col-span-3'>
              Description
            </label>
            <input
              disabled={canEdit}
              type='text'
              title='notes'
              id='notes'
              className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2 col-span-3'
              placeholder='Name Description'
              onChange={(e) => handleChangeInput('notes', e.target.value)}
              value={detailsData?.notes || ''}
            />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-5 mt-4'>
        <div>
          <label htmlFor='datmoduleid' className='block capitalize font-[500] mb-1'>
            Modules
            <span className="text-red-300"> *</span>
          </label>
          <div className='border p-0.5 bg-gray-50 rounded mt-2'>
            <MultiSelectItem
              disabled={canEdit}
              itemIdKey='datmoduleid'
              labelKeyname='modulename' // Assuming 'menuname3' is the name to be displayed
              limitShow={1}
              selectedOptions={selectedModules}
              options={transformedOptions}
              handleSelection={handleModuleSelect}
            />
          </div>
          {!selectedModules && checkvalidation && (
            <small className='text-red-400'>Modules is required</small>
          )}
        </div>
        <div>
          <label htmlFor="priceperuser" className="block font-[500] mb-2">
            Price/User/Month <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type="text"
            title="priceperuser"
            id="priceperuser"
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder="Price Per User"
            required=""
            onChange={(e) => handleChangeInput("priceperuser", e.target.value)}
            maxLength={50}
            value={detailsData?.priceperuser + " INR" || ''}
          />
          {!detailsData?.priceperuser && checkvalidation && (
            <small className="text-red-400">Price Per User is required!</small>
          )}
        </div>
        <div>
        </div>
        <div className='fixed bottom-0 right-6 mt-2'>
          {!canEdit && (
            <div className='fixed bottom-0 right-6 mt-2'>
              <div className='gap-2 flex justify-end items-center'>
                <button
                  onClick={() => setCanEdit(false)}
                  type=''
                  className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                >
                  Cancel
                </button>
                <PrimaryButton
                  disabled={canEdit}
                  className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                  onClick={handleSubmit}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SubscriptionDetails);