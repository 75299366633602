import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../defaultDetails';
import { GET_STATE_MASTER } from 'src/Redux/actions/masterdata/user';
import { getMasterStatePayload } from 'src/Services/constant/masterdatapayload';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';

const CityDetails = props => {
	const [state, setState] = useState({})
	const { stateList } = useSelector(state => state.UserManagementList)
	const { detailSave, detailsData, setDetailsData } = props
	const [checkvalidation, setCheckvalidation] = useState(false);
	const [searchTerm, setSearchTerm] = useState('')


	useMemo(() => {
		if (state?.statename) {
			setSearchTerm(state?.statename)
		}
	}, [state])

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCheckvalidation(true);

		let item = {
			...detailsData,
			comstateid: state?.comstateid
		};

		let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
		if (isValidated?.isvalid) {
			let payload = {
				cityjson: [item],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='cityname' className='block font-[500] mb-2'>
						City Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						maxLength={50}
						type='text'
						title='cityname'
						id='cityname'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='cityname'
						required=''
						onChange={e => handleChangeInput('cityname', e.target.value)}
					/>
					{!detailsData?.cityname && checkvalidation && (
						<small className='text-red-400'>City Name is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='citycode' className='block font-[500] mb-2'>
						City Code
					</label>
					<input
						disabled
						type='text'
						title='citycode'
						id='citycode'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='citycode'
						required=''
						onChange={e => handleChangeInput('citycode', e.target.value)}
					/>
				</div>
				<div>
					<label htmlFor='comstateid' className='block font-[500] mb-2'>
						State
						<span className="text-red-300"> *</span>
					</label>
					<CustomAutoSearch
						searchTerm={searchTerm}
						API_CALL_FOR_SEARCH={GET_STATE_MASTER}
						searchPayload={{ ...getMasterStatePayload(), searchname: searchTerm, }}
						setSearchTerm={setSearchTerm}
						options={stateList}
						idkey='statename'
						optionlabelKey='statename'
						getSelectedOption={setState}
						placeholder='Enter State Name'
						className='bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
					/>
					{!state?.comstateid && checkvalidation && (
						<small className='text-red-400'>State is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				<div className='gap-2 flex justify-end items-center'>
					<PrimaryButton
						className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
						onClick={handleSubmit}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default memo(CityDetails)
