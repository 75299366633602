import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
// import CreateRole from './createRoles';
import EditRole from './editRoles';
import { GET_ROLES_MASTER } from 'src/Redux/actions/configuration';
import { getRolesMasterPayload } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';
import { RolesItem } from './createRoles/default';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';

const cols = ['Role Name', 'code', 'Menus', 'Permissions', 'Users', 'System Role', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];


const RolesMasterList = () => {
  const dispatch = useDispatch();
  const [role, setRole] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [roleId, setRolesId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState({ ...RolesItem(), datmenuid: null })
  const [sortType, setSortType] = useState({ label: 'Name (A-Z)', id: 1 });
  const { roleList, loading: roleLoading, PermissionsOfConfiguration } = useSelector((state) => state.ConfigurationList);


  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(roleLoading); // Hide the loader after a delay
    }, 100);
  }, [roleLoading]);


  useMemo(() => {
    if (role?.rolename) {
      setSearchTerm(role?.rolename);
    }
  }, [role]);

  const handleRolesClick = (item) => {
    let modifyData = item
    if (item?.rolemenus?.length > 0) {
      modifyData['datmenuid'] = item?.rolemenus[0]?.datmenuid
    }
    setDetailsData(modifyData);
    setRolesId(item?.roleid);
    setIsOpen(true);
  };

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_ROLES_MASTER({ ...getRolesMasterPayload(), sorttype: item.id, status: status?.label, limit: limit }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_ROLES_MASTER({ ...getRolesMasterPayload(), status: item.label }));
    if (res === null) {
      setDetailsData(res.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(
      GET_ROLES_MASTER({ ...getRolesMasterPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res !== null) {
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(GET_ROLES_MASTER({ ...getRolesMasterPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  return (
    <div className='w-full h-screen p-1 py-1 mt-4'>
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center justify-start space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Roles ({roleList?.length > 0 ? roleList[0]?.totalcount : roleList?.length} )
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_ROLES_MASTER}
              searchPayload={{ ...getRolesMasterPayload(), searchname: searchTerm, sorttype: sortType?.id}}
              setSearchTerm={setSearchTerm}
              options={roleList}
              idkey='rolename'
              optionlabelKey='rolename'
              getSelectedOption={setRole}
              placeholder='Enter Role Name'
              className='p-2'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={roleList?.length > 0 ? roleList[0]?.totalcount : roleList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='py-2 border rounded bg-gray-50'
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='py-2 border rounded bg-gray-50'
            />
          </div>
        </div>
        {/* {PermissionsOfConfiguration?.includes('Add/Edit Config') ? <CreateRole /> : ""} */}
      </div>
      <div className='overflow-scroll h-[50vh] '>
        {loading || roleList === null || roleList.length === 0 ? (<div className='flex items-center justify-center w-full h-1/2'>
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>) : (
          roleList && (
            <table className='relative w-full text-sm'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {roleList?.map((item, i) => {
                  const {
                    rolename,
                    code,
                    menucount,
                    permissioncount,
                    usercount,
                    issystemrole,
                    activeflag,
                    createddate,
                    createduser,
                    roleid,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleRolesClick(item)}
                      key={roleid}
                      className='text-left cursor-pointer'>
                      <td className='border px-2 py-1.5'><div>{rolename}</div></td>
                      <td className='border px-2 py-1.5'><div>{code}</div></td>
                      <td className='border px-2 py-1.5'><div>{menucount}</div></td>
                      <td className='border px-2 py-1.5'><div>{permissioncount}</div></td>
                      <td className='border px-2 py-1.5'><div>{usercount}</div></td>
                      <td className='px-1 border'><div>{issystemrole === true ? 'True' : 'False'}</div></td>
                      <td className='px-1 border'><div>{activeflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='px-2 border'> <div>{createddate}</div></td>
                      <td className='px-2 border'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditRole
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        roleid={roleId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfConfiguration}
      />
    </div>
  );
};

export default memo(RolesMasterList);
