import { useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from 'src/Components/Buttons/IconButton';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import Currency from 'src/Pages/app/Projects/planning/budget/currency';


const cols = [
  'Min Users',
  'Max Users',
  'Price/Unit/Month',
  'Currency',
  'Action',
];

const SubscriptionPricing = ({ pricingData, setPricingData, handleSubmit, tenantid }) => {
  const [mockObject] = useState({
    uId: Date.now(), // Unique ID for each new row
    minusers: '',
    maxusers: '',
    unitprice: '',
    datcomcurrencyid: 0,
    comtenantid: 0,
  });

  const [rowKeys, setRowKeys] = useState([mockObject.uId]);
  const [checkvalidation, setCheckValidation] = useState(null);
  const { currencyList } = useSelector((state) => state?.Projects);

  const isValidatedField = (checkvalidation, index, keyname) => {
    return (
      checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
    );
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPricingData = pricingData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    setPricingData(updatedPricingData);
  };

  const SelectHandleChange = (e, uId) => {
    let { name, value } = e.target;
    let afterChange = pricingData?.map((item) => {
      if (item?.uId === uId) {
        item[name] = value;
      }
      return item;
    });
    setPricingData(afterChange);
  };


  const handleAdd = () => {
    const newId = Date.now();
    setRowKeys([...rowKeys, newId]);
    // Set default currency value to 0 or any other appropriate default value
    setPricingData([...pricingData, {
      ...mockObject, isactiveflag: true, createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
      modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0), notes: ""
    }]);
    setCheckValidation(false)
  };

  const handleDelete = (uId) => {
    const updatedPricingData = pricingData.filter((item) => item.uId !== uId);
    setPricingData(updatedPricingData.length > 0 ? updatedPricingData : [mockObject]);
    setRowKeys(rowKeys.filter(key => key !== uId));
    setCheckValidation(false)
  };

  return (
    <div className='mt-2 w-full h-[70vh]'>
      <div className='flex space-between items-center mt-2 text-sm align-items'>
        <table className='w-full mt-10 text-sm'>
          <thead>
            <tr className='text-left capitalize bg-gray-50'>
              {cols?.map((col) => (
                <th key={col} title={col} className='p-2 border font-[500] text-start'>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 mr-4'>
            {pricingData?.map((item, i) => {
              return <tr key={item?.uId} className='text-left'>
                <td
                  className={`${isValidatedField(checkvalidation, i, 'minusers')
                    ? 'border-2 border-red-400'
                    : 'border'
                    }`}
                >
                  <input
                    id={`minusers_${item.uId}`}
                    type='number'
                    value={item.minusers}
                    onChange={(e) => handleChange(e, i)}
                    name='minusers'
                    placeholder='Min Users'
                    className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                  />
                </td>
                <td
                  className={`${isValidatedField(checkvalidation, i, 'maxusers')
                    ? 'border-2 border-red-400'
                    : 'border'
                    }`}
                >
                  <input
                    id={`maxusers_${item.uId}`}
                    type='num'
                    value={item.maxusers}
                    onChange={(e) => handleChange(e, i)}
                    name='maxusers'
                    placeholder='Max Users'
                    className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                  />
                </td>
                <td
                  className={`${isValidatedField(checkvalidation, i, 'unitprice')
                    ? 'border-2 border-red-400'
                    : 'border'
                    }`}
                >
                  <input
                    id={`price_${item.uId}`}
                    type='text'
                    value={item.unitprice}
                    onChange={(e) => handleChange(e, i)}
                    name='unitprice'
                    placeholder='Price/Unit/Month'
                    className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                  />
                </td>
                <td
                  className={`${isValidatedField(checkvalidation, i, 'datcomcurrencyid')
                    ? 'border-2 border-red-400'
                    : 'border'
                    } px-1 w-1/3`}
                >
                  <Currency
                    key={`currency_${item.uId}`}
                    value={item.datcomcurrencyid}
                    id={`currency_${item.uId}`}
                    handleChange={(e) => SelectHandleChange(e, item.uId)}
                    options={currencyList}
                    className='text-gray-600 w-full border-none rounded-md border focus:outline-none hover:outline-none'
                  />
                </td>
                <td className='border px-4 py-1'>
                  <div className='flex justify-start items-center'>
                    {pricingData?.length > 1 && (
                      <IconButton
                        key={`delete_${item.uId}`}
                        title='Delete'
                        onClick={() => handleDelete(item.uId)}
                        className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='currentColor'
                          className='w-6 h-6'
                        >
                          <path
                            fillRule='evenodd'
                            d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </IconButton>
                    )}
                    <span className="hidden addIconbutton-add-btn">
                      <IconButton
                        key={`add_${item.uId}`}
                        title='Add'
                        onClick={handleAdd}
                        className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='currentColor'
                          className='w-6 h-6'
                        >
                          <path
                            fillRule='evenodd'
                            d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </IconButton>
                    </span>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-1.5 flex justify-end items-center'>
          <PrimaryButton
            type=''
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>

  );
};

export default SubscriptionPricing;