import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { TaskItem } from './default';
import CreateTask from './createTask/index';
import EditTask from './editTask';
import { GET_TASK_MASTER } from 'src/Redux/actions/masterdata/user';
import { getTaskMasterPayload } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';
import { GET_TASK_GROUPS } from 'src/Redux/actions/projects/planning';
import { GET_TIMESHEET_TASK_TYPE } from 'src/Redux/actions/projects/timesheet';
import { getTaskTypePayload } from 'src/Services/constant/defaultPayload';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';

const cols = ['Task Type', 'Task Group', 'Task Description', 'Code', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'ACTIVE' },
  { label: 'Inactive' },
  { label: 'All' },
];

const TaskMasterList = () => {
  const [task, setTask] = useState({});
  const [taskId, setTaskId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [detailsData, setDetailsData] = useState(TaskItem());
  const [taskFilter, setTaskFilter] = useState(getTaskMasterPayload())

  const dispatch = useDispatch();
  const { taskList, loading: taskLoading, PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);

  const planningState = useSelector((state) => state?.ProjectPlanning)
  const { taskGroups } = planningState
  const ProjectTimesheet = useSelector((state) => state?.ProjectTimesheet)
  let { taskType } = ProjectTimesheet
  const filteredTaskType = taskType?.filter((task) => task.tasktype !== 'Miscellaneous')
  
  useEffect(() => {
    dispatch(GET_TASK_GROUPS())
    dispatch(GET_TIMESHEET_TASK_TYPE(getTaskTypePayload()))
  // eslint-disable-next-line 
  }, [])

  useMemo(() => {
    if (task?.taskdesc) {
      setSearchTerm(task?.taskdesc);
    }
  }, [task]);

  const handlePagination = (page) => {
    setTaskFilter({ ...taskFilter, page: page?.selected + 1 })
  };

  const handleBuClick = (bu) => {
    setDetailsData(bu);
    setTaskId(bu?.taskid);
    setIsOpen(true);
  };

  const handleChangeInput = (name, value) => {
    setTaskFilter({ ...taskFilter, [name]: value })
  }

  const getDataByFilter = async ()=>{
    let res = await dispatch(GET_TASK_MASTER(taskFilter));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  }

  useEffect(() => {
    getDataByFilter()
  // eslint-disable-next-line
  }, [taskFilter, searchTerm])

  return (
    <div className='w-full h-screen p-2 py-3 mt-4'>
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center justify-start space-x-5'>
          <span className='pb-2 font-[500] text-center inline-flex items-center  '>
            Tasks({taskList?.length > 0 ? taskList[0]?.totalcount : taskList?.length}){' '}
          </span>
          <div className='w-72 '>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_TASK_MASTER}
              searchPayload={{ ...taskFilter, searchname: searchTerm }}
              setSearchTerm={setSearchTerm}
              options={taskList}
              idkey='taskdesc'
              optionlabelKey='taskdesc'
              getSelectedOption={setTask}
              placeholder='Enter Task Description'
              className='p-2'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={taskList?.length > 0 ? taskList[0]?.totalcount : taskList?.length}
              currentPage={taskFilter?.page}
              handlePagination={handlePagination}
              limit={taskFilter?.limit}
            />
            <FilterButtonTab
              setSelectedtab={(r) => handleChangeInput('limit', r)}
              selectedTab={taskFilter?.limit}
              tabs={rows}
            />
          </div>

          <div className='w-32 '>
            <CustomhtmlSelect
              options={statusList || []}
              value={taskFilter?.status || ''}
              noDataLabel="No Status"
              name="status"
              labelkey="label"
              className="p-2 border rounded-lg bg-gray-50"
              onChange={handleChangeInput}
              id="label"
            />
          </div>

          <div className='w-1/4'>
            <CustomhtmlSelect
              options={filteredTaskType || []}
              value={taskFilter?.tasktypeid}
              noDataLabel="No Task Type"
              name="tasktypeid"
              labelkey="tasktype"
              className="p-2 border rounded-lg bg-gray-50"
              onChange={handleChangeInput}
              id="tasktypeid"
            />
          </div>

          <div className='w-1/4'>
            <CustomhtmlSelect
              options={taskGroups || []}
              value={taskFilter?.taskgroupid}
              noDataLabel="No Group"
              name="taskgroupid"
              labelkey="taskgroupdesc"
              className="p-2  w-full border rounded-lg bg-gray-50"
              onChange={handleChangeInput}
              id="taskgroupid"
            />
          </div>

          <div>
            <CustomhtmlSelect
              options={basicShortList || []}
              value={taskFilter?.sorttype}
              noDataLabel="No Sorttype"
              name="sorttype"
              labelkey="label"
              className="p-2 border rounded-lg bg-gray-50"
              onChange={handleChangeInput}
              id="id"
            />

          </div>
        </div>
        {(PermissionsOfMasterdata.includes('Add/Edit Task')) ? <CreateTask /> : ""}
      </div>
      <div className="h-[46vh] overflow-scroll">
        {taskLoading ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          taskList && (
            <table className='relative w-full text-sm'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {taskList?.map((item, i) => {
                  let {
                    tasktype,
                    taskgroupdesc,
                    taskdesc,
                    taskcode,
                    isactiveflag,
                    createduser,
                    createdtimestamp,
                    taskid,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleBuClick(item)}
                      key={taskid}
                      className='text-left cursor-pointer'>
                      <td className='border px-2 py-1.5'><div>{tasktype}</div></td>
                      <td className='border px-2 py-1.5'><div>{taskgroupdesc}</div></td>
                      <td className='border px-2 py-1.5'><div>{taskdesc}</div></td>
                      <td className='border px-2 py-1.5'><div>{taskcode}</div></td>
                      <td className='px-2 border'> <div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='px-2 border'><div> {moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                      <td className='px-2 border'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditTask
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        page={taskFilter?.page}
        limit={taskFilter?.limit}
        sorttype={taskFilter?.sorttype}
        taskid={taskId}
        permissions={PermissionsOfMasterdata}
      />
    </div >
  );
};

export default memo(TaskMasterList);
