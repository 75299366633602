
import { useState, useRef, useEffect } from 'react';

export default function SingleSelectField({ options, handleSelected, disabled, value, labelKey, valueKey, hover = false , displayLabel = 'Select'}) {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    const getByTitle = () => {
        try {
            return options?.find(el => el[valueKey] === value)[labelKey];
        } catch (error) {
            return disabled ? '' : displayLabel;
        }
    }

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative w-full">

            <div className="group relative flex flex-col items-end ">
                <button disabled={disabled} onClick={() => setOpen(!open)} className="w-full disabled:cursor-default relative flex justify-between truncate rounded-lg bg-transparent p-1 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span title={!hover ? getByTitle() : ''} className="block  truncate "> {getByTitle()}</span>
                    {!disabled && <div>
                        {open ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-secondary">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>}
                    </div>}
                </button>
                {hover && <div className="z-[2000] mt-1 min-h-[1.8rem]  py-1 px-4 right-0 shadow-xl min-w-max absolute top-5 scale-0 rounded bg-white border p-2 text-xs text-secondary group-hover:scale-100">
                    <svg className="absolute text-gray-50 h-3 left-[50%] mr-3 rotate-180 -top-2.5" x="0px" y="0px" viewBox="0 0 255 255" space="preserve"><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
                    <span>{getByTitle()}</span>
                </div>}
            </div>


            {open && <div className="w-full transition z-[1000] min-h-44 max-h-44 ease-in duration-100 absolute mt-1 min-w-[90px] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div>
                    {options?.map((item, itemIdx) => {
                        return (
                            <div
                                key={item?.uId || itemIdx}
                                className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-2 cursor-pointer ${item[valueKey] === value ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                                onClick={() => {
                                    handleSelected(item)
                                    setOpen(!open)
                                }}
                            >
                                <span>
                                    {item[labelKey]}
                                </span>
                                {item[valueKey] === value &&
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}
