import axios from "axios"
import { AlertError } from ".";
import { getTitleOfKeyname } from "./constant/customvalidation";

axios.interceptors.response.use(response => {
    // console.log(response)
    return response

}, error => {
    // console.log(error)
    if (error?.code === "ERR_NETWORK") {
        AlertError(error?.name, error?.message)
    } else {
        let status = error?.response?.status
        if (status === 400) {
            AlertError(`Error Status ${error?.response?.status}`, error?.response?.data?.error?.message)
        }
    }

    return Promise.reject(error);
}
);

export const postMethodApiCall = async (apiPath, header, bodyData = {}) => {
    try {
        const res = await axios.post(apiPath, bodyData, { headers: { ...header } })
        if (res?.status === 200 || res?.status === 201) {
            return res
        } else {
            AlertError(res?.data?.Message || 'Something went wrong. Please try later.')
            return
        }
    } catch (error) {
        let errMsg = error.response?.data?.data?.Message || error.response?.data?.data
        if (error.response?.status === 401) {
            localStorage.removeItem('user')
            localStorage.removeItem('comuserid')
            localStorage.removeItem('menuinfo')
            localStorage.removeItem('token')
            window.location.href = '/app/login'
            AlertError(getTitleOfKeyname(errMsg) || 'Something went wrong. Please try later.')
        } else {
            AlertError(getTitleOfKeyname(errMsg) || 'Something went wrong. Please try later.')
        }
        return error
    }
}

export const putMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.put(apiPath, bodyData, { headers: { ...header } })
        return res
    } catch (error) {
        // let errMsg = error.response?.data?.data?.Message
        return { status: error.response?.status }
    }
}

export const getMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.get(apiPath, { headers: { ...header } })
        return res
    } catch (error) {
        if (error.response?.status === 401) {
            localStorage.clear()
            window.location.href = '/app/login'
        }
        return { status: error.response?.status }
    }
}

export const patchMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.patch(apiPath, bodyData, { headers: { ...header } })
        return res
    } catch (error) {
        // let errMsg = error.response?.data?.data?.Message
        return { status: error.response?.status }
    }
}

export const deleteMethodApiCall = async (apiPath, method, header, bodyData = {}) => {
    try {
        const res = await axios.delete(apiPath, { headers: header, data: bodyData })
        return res
    } catch (error) {
        // let errMsg = error.response?.data?.data?.Message
        return { status: error.response?.status }
    }
}
