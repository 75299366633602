import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts'

const data = [
    {
        name: '01/24',
        Billed: 4000,
        Unbilled: 2400,
        amt: 2400,
    },
    {
        name: '02/24',
        Billed: 3000,
        Unbilled: 1398,
        amt: 2210,
    },
    {
        name: '03/24',
        Billed: 2000,
        Unbilled: 9800,
        amt: 2290,
    },
    {
        name: '04/24',
        Billed: 2780,
        Unbilled: 3908,
        amt: 2000,
    },
    {
        name: '05/24',
        Billed: 1890,
        Unbilled: 4800,
        amt: 2181,
    },
    {
        name: '06/24',
        Billed: 2390,
        Unbilled: 3800,
        amt: 2500,
    },
]

const BarChartEffortTimeline = () => {
    return (
        <div className="h-[32vh] w-full flex justify-center items-center ">
            <ResponsiveContainer  debounce={300} width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid opacity={1} vertical={false} strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize={10} />
                    <ReferenceLine y={0} stroke="gray" />
                    <YAxis axisLine={false} fontSize={10} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Billed" fill="#8884d8" />
                    <Bar dataKey="Unbilled" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default BarChartEffortTimeline
