
export const AnnouncementItem = () => {
   return {
      announcementid: null,
      announcementtext: "",
      announcementlink: "",
      sequence: 0,
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      expirydate: "",
      isactiveflag: true,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: ""

   }
}

export const detailNotRequired = [
   'announcementid',
   'announcementlink',
   'notes'
];
