import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import MultiSelectMonth from 'src/Components/MultiSelectMonth'
import SelectProject from 'src/Filter/workforce/selectProject'
import { GET_EXIT_PIPELINE_REPORT } from 'src/Redux/actions/reports'
import { getExitPipelineReportsPayload } from 'src/Services/constant/defaultPayload'
import SelectLocation from './selectLocation'

const ReportFilter = ({ cols = 'grid-cols-6', filters, setFilters, project, setProject, reportId, setReportId }) => {
    const dispatch = useDispatch()
    const { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    const { projectList } = useSelector((state) => state?.Projects)
    const { serviceList, businessList } = useSelector((state) => state.UserManagementList)
    const [location, setLocation] = useState({})
    const [reset, setReset] = useState(false)
    const { workforcelist, companylist, departmentlist } = useSelector((state) => state.EmployeeDetails)
    let { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const [selectedMonths, setSelectedMonths] = useState([moment().format('YYYY-MM')])

    useEffect(() => {
        if (projectsOfM?.length > 0) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                projectid: project?.projectid,
            }))
        }
    }, [project, setFilters, projectsOfM?.length])

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            locationid: location?.comcityid || 0,
        }))
    }, [location, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        setLocation({})
        setReset(true)
        setSelectedMonths([moment().format('YYYY-MM')])
        let payload = {
            ...getExitPipelineReportsPayload(),
            projectid: 0,
        }
        dispatch(GET_EXIT_PIPELINE_REPORT(payload))
        setFilters(payload)
    }

    const applyFilters = () => {
        dispatch(GET_EXIT_PIPELINE_REPORT(filters))
    }

    useEffect(() => {
        const formattedMonths = selectedMonths.map((month) => month.replace('-', ''))
        setFilters((prevFilters) => ({
            ...prevFilters,
            yearmonthid: `{${formattedMonths.join(',')}}`,
        }))
    }, [selectedMonths, setFilters])

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-3`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <div>
                    {projectsOfM !== null && (
                        <SelectProject
                            project={project}
                            handleSelectProject={setProject}
                            projectList={projectList}
                            reset={reset}
                        />
                    )}
                </div>

                <div>
                    <h1 className="mb-2 font-[500]">Service Line</h1>
                    <CustomhtmlSelect
                        options={serviceList || []}
                        value={filters?.servicelineid || ''}
                        noDataLabel="No service"
                        name="servicelineid"
                        labelkey="servicelinename"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="servicelineid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Business Unit</h1>
                    <CustomhtmlSelect
                        options={businessList || []}
                        value={filters?.businessunitid || ''}
                        noDataLabel="No business unit"
                        name="businessunitid"
                        labelkey="businessunitname"
                        className="border rounded-lg bg-gray-50 p-[8px]"
                        onChange={handleSelectChange}
                        id="businessunitid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Company</h1>
                    <CustomhtmlSelect
                        options={companylist || []}
                        value={filters?.companyid}
                        noDataLabel="No Company"
                        name="companyid"
                        labelkey="companyname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="companyid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Workforce group</h1>
                    <CustomhtmlSelect
                        options={workforcelist || []}
                        value={filters?.workforcegroupid}
                        noDataLabel="No Workforce found"
                        name="workforcegroupid"
                        labelkey="workforcegroup"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="wfgid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Department</h1>
                    <CustomhtmlSelect
                        options={departmentlist || []}
                        value={filters?.departmentid}
                        noDataLabel="No Department"
                        name="departmentid"
                        labelkey="department"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="departmentid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]"> Location</h1>
                    <SelectLocation location={location} setLocation={setLocation} />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Month/year</h1>
                    <MultiSelectMonth
                        selectedMonths={selectedMonths}
                        setSelectedMonths={setSelectedMonths}
                        name="yearmonthid"
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        multiselect={true}
                        onChange={() => {}}
                    />
                </div>
                <div />
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ReportFilter)
