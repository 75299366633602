import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useMemo, useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { useSelector } from 'react-redux';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';


const Details = (props) => {
  const [city, setCity] = useState({});
  const { cityList } = useSelector((state) => state.UserManagementList);
  const { detailSave, detailsData, setDetailsData } = props;
  const [checkvalidation, setcheckvalidation] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useMemo(() => {
    if (city?.cityname) {
      setSearchTerm(city?.cityname);
    }
  }, [city]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setcheckvalidation(true);

    let item = {
      ...detailsData,
      companyname: detailsData?.tenantname,
      tenantaddress: `${detailsData?.addressLine1 || ''} ${detailsData?.addressLine2 || ''}`,
      comcityid: city?.comcityid,
    };

    let isValidated = CheckObjectValidation(item, [
      'comcurrencyid',
      'password',
      'token',
      // 'tenantcode',
      'mobileno',
      'employeecode',
      'emailid',
      'contactdetails',
      'fax',
      'notes',
      'CIN',
      'comtenantid',
      'themecode',
      'createdcomuserid',
      'loginmethod',
      'logopath',
      'heroimagepath',
      'GSTnumber',
    ]);
    let contactDetailsValidated = detailsData?.tenantcontactdetails.every(contact =>
      contact.contactpersonname && contact.designation && contact.phoneno && contact.email
    );

    if (isValidated?.isvalid && contactDetailsValidated) {
      delete item['password']
      delete item['url']

      let payload = {
        tenantjson: [item],
        password: detailsData?.password || '',
        url: detailsData?.url || ''
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setcheckvalidation(false);
    let updatedDetailsData;
    if (
      name === 'contactpersonname' ||
      name === 'designation' ||
      name === 'phoneno' ||
      name === 'email'
    ) {
      updatedDetailsData = {
        ...detailsData,
        tenantcontactdetails: [
          {
            ...detailsData?.tenantcontactdetails[0],
            [name]: value,
          },
        ],
      };
    } else {
      updatedDetailsData = {
        ...detailsData,
        [name]: value,
      };
    }
    setDetailsData(updatedDetailsData);
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-4 mt-4'>
        <div>
          <label htmlFor='tenantname' className='block font-[500] mb-2'>
            Tenant Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('tenantname', e.target.value);
              }
            }}
            title='tenantname'
            id='tenantname'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Tenant Name'
            required=''
            maxLength={50}
            value={detailsData?.tenantname || ''}
          />
          {!detailsData?.tenantname && checkvalidation && (
            <small className='text-red-400'>Tenant Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={!detailsData?.tenantname}
            type='text'
            title='companyname'
            id='companyname'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Company Name'
            required=''
            maxLength={50}
            value={detailsData?.tenantname || ''}
            onChange={(e) =>
              handleChangeInput('companyname', detailsData?.tenantname)
            }
          />
          {!detailsData?.tenantname && checkvalidation && (
            <small className='text-red-400'>Tenant Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='tenantcode' className='block font-[500] mb-2'>
            Sub Domain
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='tenantcode'
            id='tenantcode'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Sub Domain'
            value={detailsData?.tenantcode || ''}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^\w]/g, '');
              handleChangeInput('tenantcode', inputValue);
            }}
          />
          {!detailsData?.tenantcode && checkvalidation && (
            <small className='text-red-400'>Sub Domain/ Tenant Code is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='tenantcode' className='block font-[500] mb-2'>
            Website
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='url'
            id='url'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='https://example.com'
            value={detailsData?.url || ''}
            onChange={(e) => {
              const inputValue = e.target.value
              handleChangeInput('url', inputValue);
            }}
          />
          {!detailsData?.url && checkvalidation && (
            <small className='text-red-400'>Website is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='tenantcode' className='block font-[500] mb-2'>
            Password
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='password'
            title='password'
            id='password'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='*******'
            value={detailsData?.password || ''}
            onChange={(e) => {
              const inputValue = e.target.value
              handleChangeInput('password', inputValue);
            }}
          />
          {!detailsData?.password && checkvalidation && (
            <small className='text-red-400'>password is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='addressLine1' className='block font-[500] mb-2'>
            Address Line 1 <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='addressLine1'
            id='addressLine1'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Address Line 1'
            required=''
            onChange={(e) => handleChangeInput('addressLine1', e.target.value)}
            maxLength={50}
          />
          {!detailsData?.addressLine1 && checkvalidation && (
            <small className='text-red-400'>Address Line 1 is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='addressLine2' className='block font-[500] mb-2'>
            Address Line 2<span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='addressLine2'
            id='addressLine2'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Address Line 2'
            onChange={(e) => handleChangeInput('addressLine2', e.target.value)}
            maxLength={50}
          />
          {!detailsData?.addressLine2 && checkvalidation && (
            <small className='text-red-400'>Address Line 2 is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='comcityid' className='block font-[500] mb-2'>
            City
            <span className='text-red-300'> *</span>
          </label>
          <CustomAutoSearch
            searchTerm={searchTerm}
            API_CALL_FOR_SEARCH={GET_CITY_MASTER}
            searchPayload={{
              ...getMasterCityPayload(),
              searchname: searchTerm,
            }}
            setSearchTerm={setSearchTerm}
            options={cityList}
            idkey='cityname'
            optionlabelKey='cityname'
            getSelectedOption={setCity}
            placeholder='Enter City Name'
            className=' bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
          />
          {!city?.comcityid && checkvalidation && (
            <small className='text-red-400'>City is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='pincode' className='block font-[500] mb-2'>
            Pincode
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='pincode'
            id='pincode'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Pincode'
            required=''
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target?.value)) {
                handleChangeInput('pincode', e.target.value);
              }
            }}
          />
          {!detailsData?.pincode && checkvalidation && (
            <small className='text-red-400'>Pincode is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='emailid' className='block font-[500] mb-2'>
            Email
          </label>
          <input
            type='email'
            title='emailid'
            id='emailid'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Email'
            required=''
            onChange={(e) => handleChangeInput('emailid', e.target.value)}
          />
          {!detailsData?.emailid && checkvalidation && (
            <small className='text-red-400'></small>
          )}
        </div>
        <div>
          <label htmlFor='GSTnumber' className='block font-[500] mb-2'>
            GST
          </label>
          <input
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('GSTnumber', e.target.value);
              }
            }}
            title='GSTnumber'
            id='GSTnumber'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='GST No.'
            required=''
            value={detailsData?.GSTnumber || ''}
            maxLength={50}
          />
        </div>
        <div>
          <label htmlFor='CIN' className='block font-[500] mb-2'>
            CIN
          </label>
          <input
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('CIN', e.target.value);
              }
            }}
            title='CIN'
            id='CIN'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='CIN'
            required=''
            value={detailsData?.CIN || ''}
            maxLength={50}
          />
        </div>
      </div>
      <div className='grid grid-cols-4 gap-4 mt-8'>
        <div>
          <label htmlFor='contactpersonname' className='block font-[500] mb-2'>
            Contact Person
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            id='contactpersonname'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Name'
            required=''
            onChange={(e) =>
              handleChangeInput('contactpersonname', e.target.value)
            }
            maxLength={50}
          />
          {(!detailsData?.tenantcontactdetails || !detailsData.tenantcontactdetails.some(contact => contact.contactpersonname)) && checkvalidation && (
            <small className='text-red-400'>Contact Person Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='designation' className='block font-[500] mb-2'>
            Designation
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            id='designation'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Designation'
            required=''
            onChange={(e) => handleChangeInput('designation', e.target.value)}
            maxLength={50}
          />
          {(!detailsData?.tenantcontactdetails || !detailsData.tenantcontactdetails.some(designation => designation.designation)) && checkvalidation && (
            <small className='text-red-400'>Designation is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='phoneno' className='block font-[500] mb-2'>
            Phone
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='tel'
            id='phoneno'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Phone'
            required=''
            onChange={(e) => handleChangeInput('phoneno', e.target.value)}
          />
          {(!detailsData?.tenantcontactdetails || !detailsData.tenantcontactdetails.some(phoneno => phoneno.phoneno)) && checkvalidation && (
            <small className='text-red-400'>Phone No. is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='email' className='block font-[500] mb-2'>
            Email
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='email'
            id='email'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Email'
            required=''
            onChange={(e) => handleChangeInput('email', e.target.value)}
          />
          {(!detailsData?.tenantcontactdetails || !detailsData.tenantcontactdetails.some(email => email.email)) && checkvalidation && (
            <small className='text-red-400'>Email is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-1.5 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);