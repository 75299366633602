import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomerRole from './customerRole'
import moment from 'moment'
import { AlertError } from 'src/Services'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import { useDispatch } from 'react-redux'
import { GET_PROJECT_STAFFING_LIST } from 'src/Redux/actions/projects/staffing'
import { useLocation } from 'react-router-dom'

const EditStaff = ({ hooksValue }) => {
    const [commentError, setCommentError] = useState(false)
    const { editStaff, setEditSatff, handleInputChange, getListOfProjectRole, selectCustomer, saveStaffing, loading, project, page, limit } = hooksValue
    const uId = editStaff?.uId
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const staffingProjectId = searchParams.get('projectid')

    const dispatch = useDispatch()

    const disableNext14Days = () => {
        // Get the current date
        let currentDate = new Date();

        // Calculate the date 0 days from now
        currentDate.setDate(currentDate.getDate() + 0);

        // Format the date as YYYY-MM-DD for the 'max' attribute
        let maxDate = currentDate.toISOString().split('T')[0];
        return maxDate
    }

    const canDisableDate = () => {
        // Get the current date
        const currentDate = new Date();
        // Parse the editStaff start date as a Date object
        const editStartDate = new Date(editStaff?.startdate);

        // Compare the dates
        return editStartDate < currentDate;
    };

    const editReleaseDate = () => {
        // Get the current date
        const currentDate = moment().format('YYYY-MM-DD');
        // Parse the editStaff start date as a Date object
        const editStartDate = moment(new Date(editStaff?.startdate)).format('YYYY-MM-DD');
        // Compare the dates
        return editStartDate > currentDate;
    }

    // console.log(editStaff?.startdate)
    const handleDates = (e, id) => {
        let { name, value } = e.target
        let dateis = value
        setCommentError(false)

        if (name === 'startdate' && dateis >= editStaff?.plannedenddate) {
            AlertError(`End date must be greater than start date ${editStaff?.plannedstartdate} - ${editStaff?.plannedenddate}`)
            return
        } else if (name === 'startdate' && dateis < editStaff?.plannedstartdate) {
            AlertError(`Start date must be between the Planned start date and end date ${editStaff?.plannedstartdate} - ${editStaff?.plannedenddate}`)
            return
        } else if (name === 'enddate' && dateis > editStaff?.plannedenddate) {
            AlertError(`End date must be between the Planned start date and end date ${editStaff?.plannedstartdate} - ${editStaff?.plannedenddate}`)
            return
        } else if (name === 'enddate' && dateis < editStaff?.plannedstartdate) {
            AlertError(`End date must be between the Planned start date and end date ${editStaff?.plannedstartdate} - ${editStaff?.plannedenddate}`)
            return
        }

        handleInputChange(e, id)
    }

    const checkValidationOfComment = () => {
        if (editStaff?.releasedate && editStaff?.notes?.trim() === '') {
            setCommentError(true)
            // AlertError('Note is required')
        } else {
            saveStaffing()
        }
    }

    const commentlabel = () => {
        // console.log(editStaff?.releasedate)
        let releaseData = editStaff?.releasedate ? moment(new Date(editStaff?.releasedate)).format('YYYY-MM-DD') : null
        let enddateis = moment(editStaff?.enddate).format('YYYY-MM-DD')
        // console.log(releaseData)
        if (releaseData > enddateis) {
            return 'Reason for extension'
        } else if (releaseData < enddateis) {
            return 'Reason for early release'
        } else if (releaseData === enddateis) {
            return 'Staffing Comments'
        } else {
            return 'Staffing Comments'
        }
    }

    const handleClose = () => {
        setEditSatff(null)
        dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId || project?.projectid, null, page, limit))
    }

    return (
        <>
            <Transition appear show={editStaff !== null} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => (console.log(''))}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[990px] relative py-4 transform overflow-hidden  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className='border-b py-2 border-primary flex justify-between items-center'>
                                        <h3 className="mb-4 text-lg font-[500] leading-6 text-primary">
                                            Edit Staff
                                        </h3>
                                        <button onClick={handleClose} type="button" className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                            <span className="sr-only">Close icon</span>
                                        </button>
                                    </div>
                                    <div className='mt-2 text-sm grid gap-5 grid-cols-4'>
                                        <div>
                                            <p className='pb-2 font-[500]'>Role</p>
                                            <div className='border rounded-md p-1'>
                                                <CustomerRole
                                                    hooksValue={{ uId, getListOfProjectRole, selectCustomer }}
                                                    value={editStaff?.projroleid}
                                                    canEdit={false} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Level</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.levelname || 'N/A'}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <p className='pb-2 font-[500]'>Staff Name</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.username}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Staff ID</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.employeecode}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Staff Type</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.workertype || 'N/A'}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Billable</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.billable ? 'Yes' : 'No'}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Designation</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.designation || 'N/A'}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Band</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.bandname || 'N/A'}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <p className='pb-2 font-[500]'>Plan Start Date</p>
                                            <div className='border rounded-md p-2 h-9 '>
                                                <p className='text-gray-600'>{editStaff?.plannedstartdate}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Plan End Date</p>
                                            <div className='border rounded-md p-2 h-9 '>
                                                <p className='text-gray-600'>{editStaff?.plannedenddate}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Plan Allocation</p>
                                            <div className='border rounded-md p-2'>
                                                <p className='text-gray-600'>{editStaff?.plannedallocation} %</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Start Date</p>
                                            <FlatPickrDateInput
                                                disabled={canDisableDate()}
                                                min={new Date().toISOString().split("T")[0]}
                                                name='startdate'
                                                max={project?.enddate}
                                                value={editStaff?.startdate || ''}
                                                className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none"
                                                onChangeDate={(e) => handleDates(e, editStaff?.uId)}
                                            />
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>End Date</p>
                                            <FlatPickrDateInput
                                                disabled={canDisableDate()}
                                                name='enddate'
                                                min={project?.startdate}
                                                max={project?.enddate}
                                                value={editStaff?.enddate || ''}
                                                className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none"
                                                onChangeDate={(e) => handleDates(e, editStaff?.uId)}
                                            />
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Allocation (%)</p>
                                            <div>
                                                <input name='allocation'
                                                    maxLength={3}
                                                    disabled={canDisableDate()}
                                                    onChange={(e) => {
                                                        if (/^[0-9]*$/.test(e.target?.value) && Number(e.target.value) <= 100) {
                                                            handleInputChange(e, editStaff?.uId)
                                                        }
                                                    }}
                                                    className="border p-2 w-full rounded-md bg-gray-50 focus:outline-none hover:outline-none" value={editStaff?.allocation} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='pb-2 font-[500]'>Release Date</p>
                                            <FlatPickrDateInput
                                                disabled={editReleaseDate()}
                                                name='releasedate'
                                                min={disableNext14Days()}
                                                max={project?.enddate}
                                                value={editStaff?.releasedate || ''}
                                                className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none"
                                                onChangeDate={(e) => handleDates(e, editStaff?.uId)}
                                            />
                                        </div>
                                    </div>
                                    <div className='gap-4 mt-4 flex justify-between items-center'>
                                        <div className="w-full">
                                            <p className='pb-2 text-sm font-[500]'>{commentlabel()}</p>
                                            <textarea onChange={(e) => handleInputChange(e, editStaff?.uId)}
                                                value={editStaff?.notes}
                                                id="notes" name="notes" rows="1" className="outline-gray-200 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                                placeholder="Comments " />
                                            {commentError ? <small className='text-red-400'>
                                                Comment is required !
                                            </small> : ''}
                                        </div>
                                    </div>
                                    <div className=" float-right ">
                                        <PrimaryButton
                                            onClick={checkValidationOfComment}
                                            disabled={editStaff?.releasedate === moment(editStaff?.enddate).format('YYYY/MM/DD')} className='ml-1 bg-primary disabled:bg-primary/70 text-white py-[10px] rounded-md'>
                                            {loading ? 'Processing...' : 'Save'}
                                        </PrimaryButton>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default EditStaff;

