import { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { AlertError, AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { RoleItem, contactItem, customerDocumentRow } from './defaultRowAndData';
import Details from './details';
import RoleDetails from './roleDetails';
import ContactInfo from './contactInfo';
import {
  GET_ALL_CONTACTS_OF_A_CUSTOMER,
  GET_SOURCE_MASTER_FILTER,
  UPDATE_CUSTOMER_AS_DRAFT,
  GET_CUSTOMER_MASTERLIST,
  SAVE_CUSTOMER_CONTACTS,
  GET_CUSTOMER_DOCUMENT_LIST,
  SAVE_CUSTOMER_DOCUMENT,
  GET_CUSTOMER_DOCUMENT_TYPE_LIST,
} from 'src/Redux/actions/masterdata/customer';
import { GET_COUNTRY_MASTER, GET_CUSTOMER_GROUP_MASTER, GET_TECHNOLOGY_GROUP } from 'src/Redux/actions/masterdata/user';
import { GET_BAND_MASTER, GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import {
  GET_BUSINESS_UNIT_MASTER_LIST,
  GET_CURRENCY_MASTER_LIST,
} from 'src/Redux/actions/projects';
import {
  UPDATE_RATE_OF_CUSTOMER,
} from 'src/Redux/actions/masterdata/customer/role';
import {
  GET_LEVEL_MASTER,
  GET_INSUDTRY_MASTER,
  GET_SKILLAREA_MASTER,
} from 'src/Redux/actions/workforce/eprofile';
import { getMasterCountryPayload } from 'src/Services/constant/masterdatapayload'
import {
  getCustomerRoleListMasterPayload,
  getCustomerListPayload,
  getCustomerDocument,
} from 'src/Services/constant/Customerpayload';
import {
  getDeafultIndustryData,
  getDefaultBusinessUnitPayload,
  getDeafultProficiencyData,
  getDefaultCurrencyPayload,
  getTechGroupMasterIdData,
  getDeafultSkillAreaData,
  getBandMasterIdData,
  getMasterCompanyIdData,
} from 'src/Services/constant/defaultPayload';
import Documents from './documents';

const tabslist = ['Details', 'Contacts', 'Roles', 'Documents'];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditCustomer = (props) => {
  const {
    isOpen = true,
    setIsOpen,
    customerid,
    setDetailsData,
    detailsData,
    limit,
    page,
    sortType,
    permissions
  } = props;
  const [roleData, setRoleData] = useState([RoleItem()]);
  const [contactsData, setContactsData] = useState([contactItem()]);
  const [documents, setDocuments] = useState([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [account, setAccount] = useState({});
  const [canEdit, setCanEdit] = useState(null);
  const [activeTab, setActiveTab] = useState('Details')
  const dispatch = useDispatch();
  const canEditCustomer = permissions.includes('Add/Edit Customer');

  useEffect(() => {
    setCanEdit(false);
    if (isOpen) {
      dispatch(GET_CUSTOMER_DOCUMENT_LIST(getCustomerDocument(customerid)))
      dispatch(GET_CUSTOMER_DOCUMENT_TYPE_LIST())
      dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
      dispatch(GET_CUSTOMER_GROUP_MASTER(getCustomerRoleListMasterPayload()));
      dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()));
      dispatch(GET_BUSINESS_UNIT_MASTER_LIST(getDefaultBusinessUnitPayload()));
      dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()));
      dispatch(GET_BAND_MASTER(getBandMasterIdData()))
      dispatch(GET_SOURCE_MASTER_FILTER());
      dispatch(GET_COUNTRY_MASTER(getMasterCountryPayload()))
      dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData('C')));
      dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()));
      dispatch(GET_TECHNOLOGY_GROUP(getTechGroupMasterIdData()));
    }
    // eslint-disable-next-line
  }, [dispatch, isOpen]);

  function closeModal() {
    setIsOpen(false);
    setCanEdit(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function detailSave(payload) {
    let res = await dispatch(UPDATE_CUSTOMER_AS_DRAFT(payload));
    if (res?.success) {
      AlertSuccess('Customer updated successfully.');
      dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload()));
    }
  }

  const contactSave = async () => {
    let res = await dispatch(SAVE_CUSTOMER_CONTACTS(contactsData, customerid));
    if (res?.success) {
      AlertSuccess('Customer Contact updated successfully.');
      dispatch(GET_ALL_CONTACTS_OF_A_CUSTOMER(customerid));
    }
  };

  const documentSave = async () => {
    let afterModify = []
    for (let it of documents) {
      let document = { ...it }
      delete document['comtenantid']
      delete document['createduser']
      document['customerid'] = customerid
      document['Tenantid'] = it?.comtenantid || it?.Tenantid
      document['documenttype'] = it?.documenttypeid || it['documenttype']
      if (!document['documenttype']) {
        AlertError('Document type is required !')
        return
      }
      afterModify.push(document)
    }

    let res = await dispatch(SAVE_CUSTOMER_DOCUMENT(afterModify));
    if (res?.success) {
      setDocuments([])
      AlertSuccess('Customer document saved successfully.');
      dispatch(GET_CUSTOMER_DOCUMENT_LIST(getCustomerDocument(customerid)))
    }
  };

  const roleSave = async () => {
    let res = await dispatch(UPDATE_RATE_OF_CUSTOMER(roleData, customerid));
    if (res?.success) {
      AlertSuccess('Customer Role updated successfully.');
      setCanEdit(false);
    }
  };

  const handleDelete = async () => {
    let item = {
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      customerid: detailsData?.customerid,
      companyid: detailsData?.companyid,
      customername: detailsData?.customername,
      industryid: detailsData?.industryid,
      businessunitid: detailsData?.businessunitid,
      defaultcomcurrencyid: detailsData?.defaultcomcurrencyid,
      address3: detailsData?.address3,
      address_street: detailsData?.address_street,
      comcityid: detailsData?.comcityid,
      comcountryid: detailsData?.comcountryid,
      phoneno: detailsData?.phoneno,
      email: detailsData?.email,
      Source: detailsData?.Source,
      payment_days: detailsData?.payment_days || 30,
      customergroupid: account?.customergroupid,
      accountmanagerid: parseFloat(account?.accountmanagerid),
      isactiveflag: !detailsData.isactiveflag,
      shortname: detailsData?.shortname,
    };
    const payload = {
      customerjson: [item],
    };

    let res = await dispatch(UPDATE_CUSTOMER_AS_DRAFT(payload));
    if (res?.success) {
      if (detailsData.isactiveflag) {
        AlertSuccess('Customer deactivated successfully.');
      } else {
        AlertSuccess('Customer reactivated successfully.');
      }
      await dispatch(
        GET_CUSTOMER_MASTERLIST({
          ...getCustomerListPayload(),
          limit,
          pageno: page?.selected + 1,
          sorttype: sortType?.id,
        })
      );
    } else {
      AlertError('Failed to update Customer.');
    }
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    closeModal();
  };

  function handleDeleting() {
    if (detailsData?.isactiveflag) {
      setCanEdit(true);
    }
    setShowDeleteConfirmation(true);
  }

  function handleCancelDeleting() {
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    //  closeModal();
  }

  function handleCancel() {
    closeModal();
    setShowDeleteConfirmation(false);
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <div className='border-b py-2 border-primary flex justify-between items-center'>
                    <h3 className='text-lg font-[500] leading-6 text-primary'>
                      Edit Customer
                    </h3>
                    <div className='flex items-center justify-end space-x-2'>
                      {canEditCustomer && !canEdit && detailsData.isactiveflag && (
                        <IconButton
                          title='Edit'
                          onClick={() => setCanEdit(true)}
                          type='button'
                          className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-4 h-4'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                            />
                          </svg>
                          <span className='sr-only'>Edit icon</span>
                        </IconButton>
                      )}
                      { canEditCustomer && !canEdit && (
                        <>
                          {activeTab === 'Details' && <IconButton
                            title={detailsData?.isactiveflag ? 'Delete' : 'Reactivate'}
                            onClick={handleDeleting}
                            type='button'
                            className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.8}
                              stroke='currentColor'
                              className='w-4 h-4'
                            >
                              {detailsData?.isactiveflag ? (
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                />
                              ) : (
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth={3}
                                  d='M5 13l4 4L19 7'
                                />
                              )}
                            </svg>
                            <span className='sr-only'>Delete icon</span>
                          </IconButton>}
                        </>
                      )}
                      <IconButton
                        title='Close'
                        onClick={handleCancel}
                        type='button'
                        className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-4 h-4'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                        <span className='sr-only'>Close icon</span>
                      </IconButton>
                    </div>
                  </div>
                  <Tab.Group>
                    <div className='flex justify-center w-full border-b-2 border-primary '>
                      <Tab.List className='w-[550px] justify-start flex space-x-2 rounded-lg p-1'>
                        {tabslist?.map((item, i) => {
                          return (
                            <Tab
                              key={i}
                              onClick={() => setActiveTab(item)}
                              // disabled={!activeTabs, setActive. = useState('includes(item)}
                              className={({ selected }) =>
                                classNames(
                                  'w-full rounded-lg py-2 text-lg outline-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                  selected
                                    ? 'text-primary font-[500]'
                                    : 'text-gray-500'
                                )
                              }
                            >
                              <span className='px-2'> {item}</span>
                            </Tab>
                          );
                        })}
                      </Tab.List>
                    </div>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Details
                          canEdit={!canEdit}
                          setCanEdit={setCanEdit}
                          customerid={customerid}
                          detailSave={detailSave}
                          account={account}
                          setAccount={setAccount}
                          detailsData={detailsData}
                          setDetailsData={setDetailsData}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ContactInfo
                          canEdit={!canEdit}
                          setCanEdit={setCanEdit}
                          customerid={customerid}
                          handleSubmit={contactSave}
                          mockObject={contactItem()}
                          contactsData={contactsData}
                          setContactsData={setContactsData}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <RoleDetails
                          canEdit={!canEdit}
                          setCanEdit={setCanEdit}
                          customerid={customerid}
                          formData={roleData}
                          detailsData={detailsData}
                          setFormData={setRoleData}
                          handleSubmit={roleSave}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <Documents
                          canEdit={!canEdit}
                          setCanEdit={setCanEdit}
                          customerid={customerid}
                          handleSubmit={documentSave}
                          mockObject={customerDocumentRow()}
                          documents={documents}
                          setDocuments={setDocuments}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                  {showDeleteConfirmation && (
                    <div className='absolute bottom-0 left-0 w-full p-6 text-center bg-white border-t border-gray-300'>
                      <p className='mb-4'>
                        {detailsData?.isactiveflag
                          ? 'Are you sure you want to delete this Customer?'
                          : 'Are you sure you want to reactivate this Customer?'}
                      </p>
                      <button
                        onClick={handleDelete}
                        className='px-8 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5 mr-4'
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleCancelDeleting}
                        className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                      >
                        No
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default memo(EditCustomer);