import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PROFITABILITY_FOR_DASHBOARD, GET_REVENUE_FOR_DASHBOARD } from 'src/Redux/actions/analytics'
import { ProfitabilityItem, RevenueItem } from 'src/Services/constant/defaultPayload'
import BarChartDrillDown from './RevenueDashboard'

const Revenue = ({ filters, thisPagePermissions }) => {
    const dispatch = useDispatch()
    const { revenueDashboard
        // profitabilityDashboard
     } = useSelector((state) => state?.Analytics)

    useEffect(() => {
        dispatch(GET_REVENUE_FOR_DASHBOARD(RevenueItem()))
        dispatch(GET_PROFITABILITY_FOR_DASHBOARD(ProfitabilityItem()))
    }, [dispatch])

    return (
        <div>
            <div className="grid w-full h-full grid-cols-1 gap-4 p-4 md:grid-cols-1">
                <div className="flex flex-col items-center justify-center w-full h-full p-4">
                    {thisPagePermissions?.includes('View Dashboards') ? (
                        <BarChartDrillDown revenueDashboard={revenueDashboard} filters={filters} />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    )
}

export default Revenue
