import { postMethodApiCall } from "src/Services/api"
import { AlertError, AlertSuccess } from "src/Services";
import {
    ACTION_GET_PROJECT_STAFFING_LIST,
    ACTION_GET_PROJECT_STAFFING_LIST_SEARCH,
    ACTION_GET_STAFF_STATUS_TYPE_S,
    // ACTION_SYNC_STAFFING_DATA,
    // ACTION_INSERT_PROJECT_STAFFING
} from "src/Redux/types";

const baseUrl = process.env.REACT_APP_BASE_URL

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem("comuserid"))
    }
}

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
}

export const GET_PROJECT_STAFFING_LIST = (projectid, staffingstatusid, page, limit, searchname) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId()?.Tenantid,
                projectid,
                projectstaffid: 0,
                staffingstatusid,
                Status: 'Active',
                sorttype: 3,
                searchname
            }
            if (page) {
                payload['pageno'] = page
            } if (limit) {
                payload['limit'] = limit
            }
            const url = `${baseUrl}/get_projectstaff`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data?.Data
                let fte = null
                if (res?.data?.data?.FTE?.length) {
                    fte = res?.data?.data?.FTE[0]
                }
                let addnewkey = items?.map(item => {
                    item['allocation'] = item?.allocation * 100
                    item['plannedallocation'] = item?.plannedallocation * 100
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No staffing found for this project !')
                dispatch({ type: ACTION_GET_PROJECT_STAFFING_LIST, data: addnewkey, fte })
                return addnewkey
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, "")
        }
    }
}
export const GET_PROJECT_STAFFING_LIST_SEARCH = (data) => {
    return async dispatch => {
        try {
            let payload = {
                // sorttype:3,
                Tenantid: getTanAndComId()?.Tenantid,
                projectstaffid: 0,
                Status: 'Active',
                ...data
            }
            // console.log(payload)
            const url = `${baseUrl}/get_projectstaff`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data?.Data
                let fte = null
                if (res?.data?.data?.FTE?.length) {
                    fte = res?.data?.data?.FTE[0]
                }
                let addnewkey = items?.map(item => {
                    item['allocation'] = item?.allocation * 100
                    item['plannedallocation'] = item?.plannedallocation * 100
                    item['uId'] = Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No staffing found for this project !')
                dispatch({ type: ACTION_GET_PROJECT_STAFFING_LIST_SEARCH, data: addnewkey, fte })
                dispatch({ type: ACTION_GET_PROJECT_STAFFING_LIST, data: addnewkey, fte })
                return addnewkey
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, "")
        }
    }
}

export const INSERT_PROJECT_STAFFING = (data) => {
    return async dispatch => {
        try {
            let payload = { staffjson: data }
            const url = `${baseUrl}/insert_projectstaff`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
                return true
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_STAFF_STATUS_TYPE_S = (data) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid || 0,
                statustype: 'S',
                searchname: data?.searchname || ''
            }
            const url = `${baseUrl}/getstatusmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Status type found !')
                dispatch({ type: ACTION_GET_STAFF_STATUS_TYPE_S, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const SYNC_STAFFING_DATA = () => {
    return async dispatch => {
        try {
            let payload = {}
            const url = `${baseUrl}/SyncStaffingData`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Data Synced successfully !')
                return true
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}
