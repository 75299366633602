import IconButton from 'src/Components/Buttons/IconButton'
import { activityItem } from './defaultDataSkeleton'
import { useState } from 'react'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SubActivity = ({ hooks }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6;
    const dispatch = useDispatch()
    const { editTab, activeId, selectActivity, project, setActiveId } = hooks
    const planningState = useSelector((state => state?.ProjectPlanning))
    const { activitiesByProjectId } = planningState

    // Calculate the index for slicing the array
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the array to get only the items for the current page
    const currentItems = activitiesByProjectId?.slice(startIndex, endIndex);

    // Disable "Previous" if on the first page
    const isPrevDisabled = currentPage === 0;
    // Disable "Next" if on the last page
    const isNextDisabled = endIndex >= activitiesByProjectId?.length;

    const handleAdd = () => {
        let item = {
            ...activityItem(),
            activityid: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
            activityname: `Phase ${activitiesByProjectId?.length + 1}`,
            activityownerid: project?.projectmanagerid

        }
        dispatch({ type: 'GET_ACTIVITY_BY_PROJECT', data: [...activitiesByProjectId, item] })
        setActiveId(item?.activityid)
        selectActivity(item)
    }

    const handleDelete = activityid => {
        let items = activitiesByProjectId?.filter(item => item?.activityid !== activityid)
        dispatch({ type: 'GET_ACTIVITY_BY_PROJECT', data: items })
        if(items?.length > 0){
            selectActivity(items[0])
        }
    }

    return (
        <div className="w-full py-1 flex justify-between items-center border-secondary border-b text-sm">
            <div className="w-full flex justify-start px-1 space-x-2 mb-1">
                {currentItems?.map((item) => {
                    return (
                        <div
                            role='button'
                            key={item?.activityid}
                            onClick={() => selectActivity(item, true)}
                            className={classNames(
                                ' max-w-48 truncate text-sm text-left px-1 rounded-md p-1 bg-transparent outline-white',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                item?.activityid === activeId
                                    ? 'bg-secondary/10 text-secondary  font-[400]'
                                    : 'text-gray-500'
                            )}
                        >
                            <span data-tooltip-id={item?.activityname}>
                                {item?.activityname}
                            </span>
                            <Tooltip
                                opacity={1}
                                id={item?.activityname}
                                className="!bg-[#f9fafb] !text-gray-800 !border !border-gray-200 !shadow-sm !rounded-md !p-0 relative" // Added relative positioning
                                render={() => (
                                    <span className='rounded-md p-2 text-secondary '>
                                        {item?.activityname}
                                    </span>
                                )}
                                border={'1px solid #e5e7eb'}
                                style={{
                                    zIndex: 2000,
                                    backgroundColor: '#f9fafb', padding: '0', margin: '0', color: 'black',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                                }}
                            />
                        </div>
                    )
                })}
            </div>
            <div className='flex justify-end items-center space-x-2'>
                <button
                    disabled={isPrevDisabled}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                    className={classNames(
                        'disabled:text-gray-400 disabled:bg-gray-50 disabled:border-0 z-10 flex justify-center items-center font-[500] w-8 rounded-md text-secondary',
                        { 'opacity-50': isPrevDisabled } // Optional: add opacity to show disabled state
                    )}
                >
                    {PreIcon}
                </button>

                <button
                    disabled={isNextDisabled}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    className={classNames(
                        'disabled:bg-gray-50 disabled:text-gray-500 disabled:border-0 z-10 flex justify-center items-center font-[500] w-8 rounded-md text-secondary',
                        { 'opacity-50': isNextDisabled } // Optional: add opacity to show disabled state
                    )}
                >
                    {NextIcon}
                </button>
                {editTab !== null && <>
                    {isNaN(activeId) && <>
                        {isNaN(activeId) && <IconButton
                            title='Remove Phase'
                            onClick={() =>
                                handleDelete(activeId)
                            }
                            className="bg-primary text-white">
                            <MinusIcon className="w-3" />
                        </IconButton>}
                    </>}
                    <IconButton
                        title='Add New Phase'
                        onClick={handleAdd}
                        className="bg-primary text-white"
                    >
                        <PlusIcon className="w-3" />
                    </IconButton>
                </>}
            </div>
        </div>
    )
}

export default SubActivity
