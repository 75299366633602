import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const HolidayDetails = (props) => {
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const {companylist} = useSelector((state) => state?.EmployeeDetails)
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let items = {
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      companyholidaysid: detailsData?.companyholidaysid,
      holiday: detailsData?.holiday,
      reason: detailsData?.reason,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: detailsData?.createdcomuserid,
      modifiedcomuserid: detailsData?.modifiedcomuserid,
      notes: detailsData?.notes,
      companyid: detailsData?.companyid,
    };

    let isValidated = CheckObjectValidation(detailsData, [
      'notes',
      'modifiedcomuserid',
      'createdtimestamp',
    ]);

    if (isValidated?.isvalid) {
      let payload = {
        companyholidaysjson: [items],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={companylist || []}
            value={detailsData?.companyid || ''}
            noDataLabel='No Company'
            name='companyid'
            labelkey='companyname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            onChange={handleChangeInput}
            id='companyid'
          />
          {!detailsData?.companyid && checkvalidation && (
            <small className='text-red-400'>Company is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='holiday' className='block font-[500] mb-2'>
            Holiday Date
            <span className='text-red-300'> *</span>
          </label>
          <FlatPickrDateInput
            disabled={canEdit}
            name='holiday'
            value={detailsData?.holiday || ''}
            id='holiday'
            className='outline-none border bg-gray-50 border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]'
            onChangeDate={e => handleChangeInput('holiday', e.target.value)}
          />

          {!detailsData?.holiday && checkvalidation && (
            <small className='text-red-400'>Holiday Date is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='reason' className='block font-[500] mb-2'>
            Holiday Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='reason'
            id='reason'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Holiday Name'
            maxLength={50}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('reason', e.target.value);
              }
            }}
            value={detailsData?.reason || ''}
          />
          {!detailsData?.reason && checkvalidation && (
            <small className='text-red-400'>Holiday Name is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(HolidayDetails);
