import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CurrencyFrom from './currencyfrom';
import CurrencyTo from './currencyto';
import { detailItem } from '../../default';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';
import IconButton from 'src/Components/Buttons/IconButton';
import ScrollToBottom from 'react-scroll-to-bottom';
import { GET_EXCHANGE_RATE_MASTER } from 'src/Redux/actions/masterdata/user';
import { getExchangeRatePayload } from 'src/Services/constant/defaultPayload';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';


const isValidatedField = (checkvalidation, index, keyname) => {
  return (
    checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
  );
};

const getActiveRecordOnly = (data) => {
  if (data?.length > 0) {
    return data?.filter((it) => it?.isactiveflag === true);
  }
  return []
};

const ExchangeRateDetails = ({
  addOrEdit,
  canEdit,
  handleSubmit,
  formData,
  setFormData,
  exchangerateid,
  setCanEdit,
  startEndDate
}) => {
  const dispatch = useDispatch();
  const { currencyList } = useSelector((state) => state?.Projects);
  const { exchangeratesList } = useSelector((state) => state.UserManagementList);
  const [checkvalidation, setCheckvalidation] = useState(null);

  const handleAdd = () => {
    const newId = Math.random().toString(36).substr(2, 9); // Generate a unique id
    const newRow = { ...detailItem(), ...startEndDate, uId: newId }; // Add the unique id to the new row
    setFormData([...formData, newRow]);
  };

  const handleDelete = (selected) => {
    if (formData?.length > 1) {
      if (selected?.createdtimestamp === undefined) {
        let rm = formData?.filter(item => item?.uId !== selected?.uId)
        // console.log("form", formData)
        setFormData(rm)
      } else {
        let items = formData?.map((item) => {
          if (item?.uId === selected?.uId) {
            item['isactiveflag'] = false
          }
          return item
        })
        setFormData(items)
      }

    }
  };

  useEffect(() => {
    if (exchangerateid) {
      dispatch(GET_EXCHANGE_RATE_MASTER(getExchangeRatePayload(exchangerateid)));
    }
  }, [dispatch, exchangerateid]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (exchangeratesList?.length > 0 && addOrEdit === 'Edit') {
          const filteredExchangerateData = exchangeratesList?.filter(
            (item) => item.exchangerateid === exchangerateid
          );
          // console.log("fetch", filteredExchangerateData)
          let rmUnwanted = filteredExchangerateData?.map((it) => {
            const {
              currencyfromname,
              currencytoname,
              conversionrate,
              totalcount,
              uId,
              modifiedtimestamp,
              createdtimestamp,
              ...updatedItem
            } = it;
            updatedItem.modifiedcomuserid = Number(
              localStorage.getItem('comuserid') || 0
            );
            updatedItem.conversionrate = parseFloat(conversionrate); // Convert to number
            return updatedItem;
          });
          if (isMounted) {
            setFormData(rmUnwanted);
          }
        } else {
          if (isMounted) {
            setFormData([{ ...detailItem(), ...startEndDate }]);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeratesList]);

  const SelectHandleChange = (e, uId) => {
    let { name, value } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        item[name] = value;
      }
      return item;
    });
    setFormData(afterChange);
  };

  const saveExchangeRateInfo = () => {
    let isValidated = CheckListObjectValidation(formData, [
      'exchangerateid',
      'notes',
      'validfrom',
      'validto'
    ]);
    if (isValidated?.isvalid) {
      const formDataArray = formData.map((item) => {
        return {
          ...item,
          validfrom: startEndDate?.startdate || '', // Assign startdate to validfrom
          validto: startEndDate?.enddate || '',
          // conversionrate:parseInt(formData?.conversionrate) // Assign enddate to validto
        };
      });
      // console.log("form1", formDataArray)
      handleSubmit(exchangerateid, formDataArray);
    } else {
      setCheckvalidation(isValidated);
    }
  };

  return (
    <div className='my-2 text-sm'>
      <div className='w-full h-screen'>
        <ScrollToBottom className="h-4/5">
          <table className="w-full text-sm relative overflow-scroll">
            <thead className='capitalize bg-gray-50'>
              <tr className='text-left capitalize bg-gray-50'>
                <th className='border font-[500] p-1'>Currency From<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Currency To<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Conversion Rate<span className="text-red-300">*</span></th>
                {!canEdit && (
                  <th className='border font-[500] py-2 px-2'>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {getActiveRecordOnly(formData)?.map((item, i) => {
                return (
                  <tr key={item?.uId + item?.exchangerateid} className='text-left px-0.5'>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'currencyfrom')
                        ? 'border-2 border-red-400'
                        : 'border'
                        } px-1 w-1/3`}
                    >
                      <CurrencyFrom
                        disabled={canEdit}
                        value={item?.currencyfrom}
                        rowId={item?.uId}
                        id={item?.uId}
                        handleChange={SelectHandleChange}
                        options={currencyList}
                        className='text-gray-600 border-none rounded-md border focus:outline-none hover:outline-none'
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'currencyto')
                        ? 'border-2 border-red-400'
                        : 'border'
                        } px-1 w-1/3`}
                    >
                      <CurrencyTo
                        disabled={canEdit}
                        value={item?.currencyto}
                        rowId={item?.uId}
                        id={item?.uId}
                        handleChange={SelectHandleChange}
                        options={currencyList}
                        className='text-gray-600 w-full border-none rounded-md border focus:outline-none hover:outline-none'
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'conversionrate')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1 w-32`}
                    >
                      <input
                        disabled={canEdit}
                        className='text-gray-600 border-none rounded-md border focus:outline-none hover:outline-none'
                        name='conversionrate'
                        type='number'
                        id={item?.uId}
                        value={item?.conversionrate || ''}
                        onChange={(e) => SelectHandleChange(e, item?.uId)} />
                    </td>
                    {!canEdit && (
                      <td className='border'>
                        <div className='flex items-center justify-start w-full px-2'>
                          {getActiveRecordOnly(formData)?.length !== 1 && (
                            <IconButton
                              title='Delete'
                              onClick={() => handleDelete(item)}
                              className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                            >
                              <MinusIcon className="w-3" />
                            </IconButton>
                          )}
                          <IconButton
                            title='Add'
                            disabled={
                              getActiveRecordOnly(formData)?.length >= 10
                            }
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScrollToBottom>
        <div className='fixed bottom-0 right-6 mt-2'>
          {!canEdit && (
            <div className='fixed bottom-0 right-6 mt-2'>
              <div className='gap-1.5 flex justify-end items-center'>
                {addOrEdit === 'Edit' && <button
                  // disabled={!canEdit}
                  onClick={() => setCanEdit(false)}
                  type=''
                  className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                >
                  Cancel
                </button>}
                <PrimaryButton
                  disabled={canEdit}
                  className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                  onClick={saveExchangeRateInfo}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExchangeRateDetails;