import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import drilldown from 'highcharts/modules/drilldown'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GET_PROFITABILITY_FOR_DASHBOARD } from 'src/Redux/actions/analytics'

drilldown(Highcharts)

const getBarColors = () => ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933', '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF']

const BarChartDrilldownGM2 = ({ profitabilityDashboard, filters }) => {
    const dispatch = useDispatch()
    const [chartOptions, setChartOptions] = useState({})
    const [drilldownData, setDrilldownData] = useState([])
    const [reportLevel, setReportLevel] = useState('Region')
    const [selectedIds, setSelectedIds] = useState({ regionId: null, accountId: null, customerId: null })
    const [navigationPath, setNavigationPath] = useState([{ name: 'Gross Margin 2', level: 'Region' }])

    const fetchData = async (payload) => {
        try {
            const res = await dispatch(GET_PROFITABILITY_FOR_DASHBOARD(payload))
            if (res && res.data) {
                setDrilldownData(res.data)
                setChartOptions(generateChartOptions(res.data, payload.Reportlevel))
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        if (profitabilityDashboard) {
            setChartOptions(generateChartOptions(profitabilityDashboard, reportLevel))
        }
    },
        //eslint-disable-next-line
        [profitabilityDashboard, reportLevel])

    const handleDrilldown = async (event) => {
        const { point } = event

        if (reportLevel === 'Project') {
            return
        }

        let newLevel
        const newSelectedIds = { ...selectedIds }

        if (reportLevel === 'Region') {
            newLevel = 'Account'
            newSelectedIds.regionId = point.drilldown
        } else if (reportLevel === 'Account') {
            newLevel = 'Customer'
            newSelectedIds.accountId = point.drilldown
        } else if (reportLevel === 'Customer') {
            newLevel = 'Project'
            newSelectedIds.customerId = point.drilldown
        }

        const newPayload = buildPayload(newLevel, newSelectedIds)

        setReportLevel(newLevel)
        setNavigationPath((prevPath) => [...prevPath, { name: point.name, level: newLevel }])
        setSelectedIds(newSelectedIds)

        await fetchData(newPayload)
    }

    const handleDrillup = async () => {
        if (navigationPath.length <= 1) {
            return
        }

        const newPath = navigationPath.slice(0, -1)
        const newLevel = newPath[newPath.length - 1]?.level || 'Region'
        const newSelectedIds = { ...selectedIds }

        if (newLevel === 'Region') {
            newSelectedIds.regionId = null
            newSelectedIds.accountId = null
            newSelectedIds.customerId = null
        } else if (newLevel === 'Account') {
            newSelectedIds.accountId = null
            newSelectedIds.customerId = null
        } else if (newLevel === 'Customer') {
            newSelectedIds.customerId = null
        }

        const newPayload = buildPayload(newLevel, newSelectedIds)

        setNavigationPath(newPath)
        setReportLevel(newLevel)
        setSelectedIds(newSelectedIds)

        await fetchData(newPayload)
    }

    const handleBreadcrumbClick = async (index) => {
        if (index === navigationPath.length - 1) return

        const newPath = navigationPath.slice(0, index + 1)
        const newLevel = newPath[newPath.length - 1]?.level || 'Region'
        const newSelectedIds = { ...selectedIds }

        if (newLevel === 'Region') {
            newSelectedIds.regionId = null
            newSelectedIds.accountId = null
            newSelectedIds.customerId = null
        } else if (newLevel === 'Account') {
            newSelectedIds.accountId = null
            newSelectedIds.customerId = null
        } else if (newLevel === 'Customer') {
            newSelectedIds.customerId = null
        }

        const newPayload = buildPayload(newLevel, newSelectedIds)

        setNavigationPath(newPath)
        setReportLevel(newLevel)
        setSelectedIds(newSelectedIds)

        await fetchData(newPayload)
    }

    const buildPayload = (level, ids) => ({
        ...filters,
        Tenantid: 3,
        Projectid: 0,
        customerid: level === 'Project' ? ids.customerId : 0,
        accountid: level === 'Customer' || level === 'Project' ? ids.accountId : 0,
        regionid: level === 'Account' || level === 'Customer' || level === 'Project' ? ids.regionId : 0,
        Reporttype: 'Profitablity',
        Reportlevel: level,
    })

    const generateChartOptions = (data, currentReportLevel) => {
        let chartData = []
        let seriesName = currentReportLevel

        if (currentReportLevel === 'Region' && data.regionwise_profitability && data.regionwise_profitability.length > 0) {
            chartData = data.regionwise_profitability.map((item) => ({
                name: item.regionname,
                y: item.profitability,
                drilldown: item.regionid,
            }))
            seriesName = 'Region'
        } else if (currentReportLevel === 'Account' && data.Accountwise_profitability && data.Accountwise_profitability.length > 0) {
            chartData = data.Accountwise_profitability.map((item) => ({
                name: item.Accountname,
                y: item.profitability,
                drilldown: item.Accountid,
            }))
            seriesName = 'Account'
        } else if (currentReportLevel === 'Customer' && data.Customerwise_profitability && data.Customerwise_profitability.length > 0) {
            chartData = data.Customerwise_profitability.map((item) => ({
                name: item.Customername,
                y: item.profitability,
                drilldown: item.Customerid,
            }))
            seriesName = 'Customer'
        } else if (currentReportLevel === 'Project' && data.Projectwise_profitability && data.Projectwise_profitability.length > 0) {
            chartData = data.Projectwise_profitability.map((item) => ({
                name: item.Projectname,
                y: item.profitability,
                drilldown: null,
            }))
            seriesName = 'Project'
        }

        return {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                style: {
                    fontSize: '14px',
                },
                events: {
                    drilldown: handleDrilldown,
                    drillup: handleDrillup,
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: '#6b7280',
                        fontSize: '12px',
                    },
                },
            },
            yAxis: {
                title: {
                    text: 'Gross Margin 2(%)',
                    style: {
                        color: '#6b7280',
                    },
                },
                labels: {
                    style: {
                        color: '#6b7280',
                    },
                },
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                headerFormat: '<span style="font-size:14px;font-weight:500;color:#6b7280">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}%</b><br/>',
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
            plotOptions: {
                column: {
                    colorByPoint: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%',
                        style: {
                            color: '#6b7280',
                            fontSize: '12px',
                            fontWeight: '500',
                        },
                    },
                },
            },
            series: [
                {
                    name: seriesName,
                    data: chartData.map((item, index) => ({
                        ...item,
                        color: getBarColors()[index % getBarColors().length],
                    })),
                },
            ],
            drilldown: {
                activeAxisLabelStyle: {
                    textDecoration: 'none',
                    color: '#6b7280',
                    fontWeight: '500',
                    fontSize: '14px',
                },
                activeDataLabelStyle: {
                    textDecoration: 'none',
                    color: '#6b7280',
                    fontWeight: '500',
                    fontSize: '14px',
                },
                series: drilldownData.map((series) => ({
                    ...series,
                    type: 'column',
                    data: series.data.map((item, index) => ({
                        ...item,
                        color: getBarColors()[index % getBarColors().length],
                    })),
                })),
            },
        }
    }

    return (
        <div className="relative flex flex-col items-center justify-center w-full h-[55vh]">
            <h2 style={{ color: '#6b7280', fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}>
                {/* Profitability Dashboard */}
            </h2>
            <div
                className="subtitle"
                style={{
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                    cursor: 'pointer',
                    marginBottom: '10px',
                }}
            >
                {navigationPath.map((item, index) => (
                    <span key={index} className="highcharts-breadcrumb" onClick={() => handleBreadcrumbClick(index)}>
                        {item.name}
                        {index < navigationPath.length - 1 ? ' / ' : ''}
                    </span>
                ))}
            </div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default BarChartDrilldownGM2