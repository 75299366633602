import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import SelectServiceLineHead from './selectServiceLineHead';
import MultiSelectItem from 'src/Components/MultiSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    setAccountSelect,
    accountSelect,
    canEdit,
    setCanEdit,
    selectedSkillAreas,
    setSelectedSkillAreas
  } = props;
  const { serviceGroup } = useSelector((state) => state.UserManagementList);
  const { skillList } = useSelector((state) => state.EmployeeProfile);
  const [checkvalidation, setCheckvalidation] = useState(false);

  useEffect(() => {
    setAccountSelect({
      comuserid: detailsData?.servicelineheadid,
      firstname: detailsData?.servicelinehead,
    });

    setSelectedSkillAreas(
      detailsData?.servicelineskillareamapping?.map((skill) => ({
        skillareamasterid: skill.skillareamasterid,
        skillarea: skill.skillarea,
      })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData,setAccountSelect]);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let items = {
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      servicelineid: detailsData?.servicelineid,
      servicelinegroupid: detailsData?.servicelinegroupid,
      servicelinename: detailsData?.servicelinename,
      servicelineheadid: accountSelect?.comuserid,
      servicelinecontactpersonid: detailsData?.servicelinecontactpersonid,
      isactiveflag: detailsData?.isactiveflag,
      notes: detailsData?.notes,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      servicelineskillareamapping: selectedSkillAreas.map((skill) => ({
        skillareamasterid: skill.skillareamasterid,
      })),
    };
    // console.log("acc",accountSelect?.comuserid)
    let isValidated = CheckObjectValidation(detailsData, ['servicelinecontactpersonid', 'modifiedcomuserid', 'notes']);
    if (isValidated?.isvalid) {
      let payload = {
        servicelinejson: [items],
      };
      detailSave(payload);
    };
  }
  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    if (name === 'servicelineheadid') {
      setAccountSelect({ ...accountSelect, comuserid: value });
    }
    setDetailsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, servicelineheadid: select.comuserid });
  };

  const handleSkillAreaSelect = (skill) => {
    let copySkillAreas = [...selectedSkillAreas];
    let isNew = copySkillAreas?.find((p) => p?.skillareamasterid === skill?.skillareamasterid);

    if (isNew) {
      let filteredSkillAreas = copySkillAreas?.filter((p) => p?.skillareamasterid !== skill?.skillareamasterid);
      setSelectedSkillAreas(filteredSkillAreas);
    } else {
      copySkillAreas?.push({
        skillareamasterid: skill?.skillareamasterid,
        skillarea: skill?.skillarea,
      });
      setSelectedSkillAreas(copySkillAreas);
    }
  };
  console.log(serviceGroup);

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='slcode' className='block font-[500] mb-2'>
            Service Line Code
          </label>
          <input
            type='text'
            onChange={(e) => handleChangeInput('slcode', e.target.value)}
            title='slcode'
            id='slcode'
            className='block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50'
            placeholder='Service Line Code'
            required=''
            maxLength={50}
            disabled
            value={detailsData?.slcode || ''}
          />
        </div>
        <div>
          <label htmlFor='servicelinename' className='block font-[500] mb-2'>
            Service Line Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='servicelinename'
            id='servicelinename'
            className='block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50'
            placeholder='Service Line Name'
            required=''
            value={detailsData?.servicelinename || ''}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('servicelinename', e.target.value);
              }
            }}
            maxLength={50}
          />
          {!detailsData?.servicelinename && checkvalidation && (
            <small className='text-red-400'>Service Line Name is required!</small>
          )}
        </div>
        <div>
          <label
            htmlFor='servicelinegroupname'
            className='block font-[500] mb-2'
          >
            Service Line Group
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={serviceGroup || []}
            value={detailsData?.servicelinegroupid || ''}
            noDataLabel='No Group'
            name='servicelinegroupid'
            labelkey='servicelinegroupname'
            className='py-2 border rounded-lg bg-gray-50'
            onChange={handleChangeInput}
            id='servicelinegroupid'
          />
          {!detailsData?.servicelinegroupid && checkvalidation && (
            <small className='text-red-400'>Service Line Group Name is required!</small>
          )}
        </div>
        <div>
          <label
            htmlFor='servicelineheadname'
            className='block font-[500] mb-2'
          >
            Service Line Head
            <span className='text-red-300'> *</span>
          </label>
          <div className='relative'>
            <SelectServiceLineHead
              accountSelect={accountSelect}
              setAccountSelect={setAccountSelect}
              handleSelectManager={handleSelectManager}
              canEdit={canEdit}
            />
            {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>Service Line Head is required!</small>
            )}
          </div>
        </div>
        <div>
          <label htmlFor='skillareamasterid' className='block capitalize mb-2  font-[500]'>
            Skill Area
            <span className='text-red-300'> *</span>
          </label>
          <div className='p-1 border rounded bg-gray-50'>
            <MultiSelectItem
              disabled={canEdit}
              itemIdKey='skillareamasterid'
              labelKeyname='skillarea'
              limitShow={1}
              selectedOptions={selectedSkillAreas}
              options={skillList}
              handleSelection={handleSkillAreaSelect}
            />
            {!selectedSkillAreas && checkvalidation && (
              <small className='text-red-400'>Skill Area is required</small>
            )}
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 mt-2 right-6'>
        {!canEdit && (
          <div className='fixed bottom-0 mt-2 right-6'>
            <div className='flex items-center justify-end gap-2'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Details);