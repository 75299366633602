import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { CategoryItem } from './default';
import CreateCategory from './createCategory/index';
import EditCategory from './editCategory';
import { GET_TIME_CATEGORY_MASTER } from 'src/Redux/actions/configuration';
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getDefaultTimeCategoryPayload } from 'src/Services/constant/masterdatapayload';
import { getMasterCompanyIdData } from 'src/Services/constant/defaultPayload';
import { basicShortList } from 'src/Services/constant/staticValues'
// CODE FOR FUTURE USE import CustomAutoSearch from 'src/Components/CustomAutoSearch';


const cols = ['Company', 'Time Type', 'Is Default', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const CategoryMasterList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [company, setCompany] = useState({})
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(CategoryItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
  // CODE FOR FUTURE USE const [category, setCategory] = useState({});
  // CODE FOR FUTURE USE  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();
  const { categoryList, PermissionsOfConfiguration } = useSelector((state) => state.ConfigurationList);
  const { companylist } = useSelector((state) => state.EmployeeDetails);

  useEffect(() => {
    dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
    // eslint-disable-next-line
  }, [])

  async function fetchData() {
    setLoading(true); // set loading state to true when data is being fetched
    let res = await dispatch(GET_TIME_CATEGORY_MASTER(getDefaultTimeCategoryPayload(1, 10, 3, 'Active')));
    if (res !== null) {
      setDetailsData(res.data);
    }
    setLoading(false); // set loading state to false when data has been fetched
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, [dispatch]);

  const handleCategoryClick = (bu) => {
    setDetailsData(bu);
    setCategoryId(bu?.timecategorymasterid);
    setIsOpen(true);
  };

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_TIME_CATEGORY_MASTER({
      ...getDefaultTimeCategoryPayload(), sorttype: item.id, status: status?.label, limit: limit, companyid: company?.companyid,
    }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_TIME_CATEGORY_MASTER({ ...getDefaultTimeCategoryPayload(), status: item.label, companyid: company?.companyid }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handleCompany = async (item) => {
    setCompany(item);
    let res = await dispatch(GET_TIME_CATEGORY_MASTER({
      ...getDefaultTimeCategoryPayload(), limit: limit, page: page,
      sorttype: sortType?.id,
      status: status?.label,
      companyid: item?.companyid,
    }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(
      GET_TIME_CATEGORY_MASTER({
        ...getDefaultTimeCategoryPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, companyid: company?.companyid,
      }));
    if (res !== null) {
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(GET_TIME_CATEGORY_MASTER({
      ...getDefaultTimeCategoryPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, companyid: company?.companyid,
    }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };


  return (
    <div className='mt-4 p-1 py-1 w-full h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Time Types ({categoryList?.length > 0 ? categoryList[0]?.totalcount : categoryList?.length}){' '}
          </h1>
          {/* 
          //CODE FUTURE USE 
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_TIME_CATEGORY_MASTER}
              searchPayload={{ ...getDefaultTimeCategoryPayload(), searchname: searchTerm, }}
              setSearchTerm={setSearchTerm}
              options={categoryList}
              idkey='timecategory'
              optionlabelKey='timecategory'
              getSelectedOption={setCategory}
              placeholder='Enter Time Category Name'
              className='p-2'
            />
          </div> */}
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              total={categoryList?.length > 0 ? categoryList[0]?.totalcount : categoryList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-40 '>
            <SelectInput
              options={companylist || []}
              keyname='companyname'
              selected={company}
              setSelected={handleCompany}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {PermissionsOfConfiguration?.includes('Add/Edit Config') ? <CreateCategory /> : ""}
      </div>
      {loading ? (<div className='flex items-center justify-center w-full h-1/2'>
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>) : (
        <div className='h-[calc(60%)] overflow-scroll'>
          <table className='text-sm w-full relative'>
            <thead>
              <tr className='text-left capitalize bg-gray-50'>
                {cols?.map((col) => {
                  return (
                    <th
                      key={col}
                      title={col}
                      scope='col'
                      className='font-[500] p-2 border text-start'
                    >
                      {col}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {categoryList?.map((item, i) => {
                let {
                  companyname,
                  timecategory,
                  isactiveflag,
                  createduser,
                  createdtimestamp,
                  timecategorymasterid,
                  isdefault
                } = item;
                // console.log(item)
                return (
                  <tr
                    onClick={() => handleCategoryClick(item)}
                    key={timecategorymasterid}
                    className='cursor-pointer text-left'>
                    <td className='border px-2 py-1.5'><div>{companyname}</div></td>
                    <td className='border px-2 py-1.5'> <div>{timecategory}</div></td>
                    <td className='border px-2'><div>{isdefault ? 'Yes' : 'No'}</div></td>
                    <td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                    <td className='border px-2'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                    <td className='border px-2'><div>{createduser}</div></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>)}
      <EditCategory
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        timecategorymasterid={categoryId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfConfiguration}
      />
    </div>
  );
};

export default memo(CategoryMasterList);
