import { useState } from "react";
import { useDispatch } from "react-redux";
import { SEND_FORGOT_PASSWORD_LINK } from "src/Redux/actions/auth";
import { AlertSuccess } from "src/Services";


const ForgotPassword = ({ setIsLogin }) => {
    const [username, setUsername] = useState("");
    const dispatch = useDispatch()

    const handleSendPasswordLink = async (e) => {
        e.preventDefault()
        let payload = {
            Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            username,
            url: `${window.location?.origin}/`
        }
        let res = await dispatch(SEND_FORGOT_PASSWORD_LINK(payload))
        if(res?.success){
            AlertSuccess('Forgot password link sent successfully to your Email.')
        }
    }

    return (
        <form
            onSubmit={handleSendPasswordLink}
            className="space-y-4 flex flex-col justify-between items-center w-full  h-52 md:space-y-6">
            <div className="w-full">
                <label htmlFor="username" className="block mb-2 text-sm font-[500] text-textprimary">Enter username</label>
                <input onChange={(e) => { setUsername(e.target.value) }}
                    placeholder='Account username'
                    type="text" name="username" id="username"
                    className='shadow-md backdrop-blur-[5px] bg-white/70 border border-gray-300 sm:text-sm rounded-md focus:ring-0 focus:border-0 block w-full p-2' />
                {username === null && <small className="text-red-500">Username is required !</small>}
            </div>
            <div className="w-full flex justify-between ">
                <button
                    disabled={username.trim()?.length === 0}
                    type="submit"
                    className="text-white disabled:bg-primary/20 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-[500] rounded-lg text-sm px-5 py-2 text-center">
                    Send Link
                </button>
                <button className="text-sm underline" onClick={() => setIsLogin(true)}>
                    Click to Login
                </button>
            </div>
        </form>
    )
}

export default ForgotPassword