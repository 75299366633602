import moment from 'moment'
import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { GET_PROJECT_EXPENSES } from 'src/Redux/actions/projects/financials'
import EditDetails from './editProjectExpenses'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import UploadExpenses from './uploadExpense'
import DownloadAsExcel from './downloadExpense'
import { basicShortList } from 'src/Services/constant/staticValues'

const rows = [10, 25, 50]

const cols = [
    'Overhead',
    'Description',
    'Transaction Date',
    'Transaction Id',
    'Amount',
    'Currency',
    'Linked user',
    'Cost Center Code',
]

const Expensetable = ({
    ProjectExpenses,
    filters,
    setExpenses,
    expenses,
    setExpenseId,
    expenseId,
    setIsOpen,
    isOpen,
    project,
    setProject,
    sortType,
    setSortType,
    page,
    setPage,
    limit,
    setLimit,
    getDefaultExpensesData,
    addEditPermission,
    projectsOfM,
}) => {
    const dispatch = useDispatch()
    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_PROJECT_EXPENSES({
                ...filters,
                Limit: limit,
                Page: page,
                Projectid: project?.projectid,
                Sorttype: item?.id,
            }),
        )
    }

    const handlePagination = (page) => {
        dispatch(
            GET_PROJECT_EXPENSES({
                ...filters,
                Projectid: project?.projectid,
                Limit: limit,
                Page: page?.selected + 1,
                Sorttype: sortType?.id,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        setLimit(row)
        dispatch(
            GET_PROJECT_EXPENSES({
                ...filters,
                Projectid: project?.projectid,
                Limit: row,
                Page: page,
                Sorttype: sortType?.id,
            }),
        )
    }

    const handleComClick = (com) => {
        setExpenses(com)
        setExpenseId(com?.projexpenseid)
        setIsOpen(true)
    }
    return (
        <div className="h-full p-2 mt-">
            <div className="flex items-center justify-between py-4">
                <div className="flex items-center justify-start space-x-4">
                    <p className="text-primary font-[500] inline-flex justify-start items-center ">
                        Expenses{' '}
                        <span>
                            (
                            {ProjectExpenses?.length > 0
                                ? ProjectExpenses[0]?.totalcount
                                : ProjectExpenses?.length}
                            )
                        </span>
                    </p>
                    <ReactPaginationStyle
                        total={
                            ProjectExpenses?.length > 0
                                ? ProjectExpenses[0]?.totalcount
                                : ProjectExpenses?.length
                        }
                        currentPage={page}
                        handlePagination={handlePagination}
                        limit={limit}
                    />
                    <div className="w-32 ">
                        <FilterButtonTab
                            setSelectedtab={handleRows}
                            selectedTab={limit}
                            tabs={rows}
                        />
                    </div>
                    <div className="w-32 ">
                        <SelectInput
                            options={basicShortList || []}
                            keyname="label"
                            selected={sortType}
                            setSelected={handleSorting}
                        />
                    </div>
                </div>
                {addEditPermission ? (
                    <div className="flex justify-end w-full space-x-2">
                        <UploadExpenses
                            getDefaultExpensesData={getDefaultExpensesData}
                            projectsOfM={projectsOfM}
                        />
                        <DownloadAsExcel fileName="Expense" />
                        {/* DONT REMOVE THIS */}
                        {/* <AddExpenses hooks={{project, setProject, getDefaultExpensesData}} /> */}
                    </div>
                ) : (
                    ''
                )}
            </div>
            {ProjectExpenses === null ? (
                <div className="flex items-center justify-center w-full h-[56vh]">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[56vh] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {cols?.map((col) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="font-[500] px-2 py-2 border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {ProjectExpenses?.map((item, i) => {
                                const {
                                    uId,
                                    overhead,
                                    amount,
                                    currency,
                                    employeecode,
                                    externalsystemcode,
                                    taggedusername,
                                    transactiondate,
                                    transactionid,
                                    description,
                                } = item
                                return (
                                    <tr
                                        className="text-left cursor-pointer"
                                        key={uId}
                                        onClick={() => handleComClick(item)}
                                    >
                                        <td className="px-2 py-1 border">{overhead}</td>
                                        <td className="px-2 py-1 border">
                                            {description}
                                        </td>
                                        <td className="px-2 py-1 border">
                                            <div className="w-36">
                                                {moment(transactiondate).format(
                                                    'DD-MM-YYYY',
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-2 py-2 border">
                                            {transactionid}
                                        </td>
                                        <td className="px-2 py-1 border">{amount}</td>
                                        <td className="px-2 py-1 border">{currency}</td>
                                        <td className="px-2 py-1 border">
                                            {taggedusername && employeecode ? (
                                                <p>
                                                    {taggedusername} ({employeecode})
                                                </p>
                                            ) : null}
                                        </td>
                                        <td className="px-2 py-1 border">
                                            {externalsystemcode}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            <div>
                <EditDetails
                    hooks={{
                        addEditPermission,
                        ProjectExpenses,
                        filters,
                        project,
                        setExpenses,
                        expenses,
                        expenseId,
                        setExpenseId,
                        isOpen,
                        setIsOpen,
                        getDefaultExpensesData,
                    }}
                />
            </div>
        </div>
    )
}

export default memo(Expensetable)
