import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { GET_EMPLOYEE_SUMMARY } from 'src/Redux/actions/workforce/eprofile'
import { useEffect } from 'react'

const EmployeePersonalInfo = ({ talentId }) => {
    const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
    const { employeeSummary, allocationData } = EmployeeProfile
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const tenid = searchParams.get('tenid')

    useEffect(() => {
        dispatch(GET_EMPLOYEE_SUMMARY({ COMUserId: +talentId, Tenantid: +tenid }))
        // eslint-disable-next-line
    }, [dispatch])

    return (
        <div className="grid grid-cols-3 gap-4 p-4 text-sm bg-white">
            <div className="space-y-2">
                <p className="font-[500]">
                    Full Name:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.firstname} {employeeSummary[0]?.lastname}
                    </span>
                </p>
                <p className="font-[500]">
                    Employee Code:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.employeecode}
                    </span>
                </p>
                <p className="font-[500]">
                    Company :{' '}
                    <span className="font-normal">{employeeSummary[0]?.companyname}</span>
                </p>
                <p className="font-[500]">
                    City :{' '}
                    <span className="font-normal">{employeeSummary[0]?.cityname}</span>
                </p>
                <p className="font-[500]">
                    Location :{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.locationname}
                    </span>
                </p>
                <p className="font-[500]">
                    Allocation Status:{' '}
                    <span className="font-normal">{allocationData?.Status}</span>
                </p>
                <p className="font-[500]">
                    Total Allocation:{' '}
                    <span className="font-normal">
                        {((allocationData?.totalallocation || 0) * 100).toFixed(0)} %
                    </span>
                </p>
                <p className="font-[500]">
                    Gender:{' '}
                    <span className="font-normal">{employeeSummary[0]?.gender}</span>
                </p>
            </div>
            <div className="space-y-2">
                <p className="font-[500]">
                    Email:{' '}
                    <span className="font-normal">{employeeSummary[0]?.emailid}</span>
                </p>
                <p className="font-[500]">
                    Phone:{' '}
                    <span className="font-normal">{employeeSummary[0]?.mobileno}</span>
                </p>
                <p className="font-[500]">
                    Status:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.emp_statusname}
                    </span>
                </p>
                <p className="font-[500]">
                    Worker Type:{' '}
                    <span className="font-normal">{employeeSummary[0]?.workertype}</span>
                </p>
                <p className="font-[500]">
                    Time Type:{' '}
                    <span className="font-normal">{employeeSummary[0]?.timetype}</span>
                </p>
                <p className="font-[500]">
                    Workforce Group:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.workforcegroup}
                    </span>
                </p>
                <p className="font-[500]">
                    Designation:{' '}
                    <span className="font-normal">{employeeSummary[0]?.designation}</span>
                </p>
            </div>
            <div className="space-y-2">
                <p className="font-[500]">
                    Band:{' '}
                    <span className="font-normal">{employeeSummary[0]?.bandname}</span>
                </p>
                <p className="font-[500]">
                    Department:{' '}
                    <span className="font-normal">{employeeSummary[0]?.department}</span>
                </p>
                <p className="font-[500]">
                    Joining Date:{' '}
                    <span className="font-normal">
                        {moment(employeeSummary[0]?.dateofjoining).format('DD-MM-YYYY')}
                    </span>
                </p>
                <p className="font-[500]">
                    Manager:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.managername} (
                        {employeeSummary[0]?.manageremployeecode})
                    </span>
                </p>
                <p className="font-[500]">
                    Service Line:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.servicelinename}
                    </span>
                </p>
                <p className="font-[500]">
                    Primary Skill Area:{' '}
                    <span className="font-normal">{employeeSummary[0]?.skillarea}</span>
                </p>
                <p className="font-[500]">
                    Primary Skill:{' '}
                    <span className="font-normal">
                        {employeeSummary[0]?.primaryskill}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default EmployeePersonalInfo
