// Resume.js
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import UploadResume from './uploadResume'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import {
	DELETE_USER_DOCUMENT,
	GET_EMPLOYEE_DOCUMENT_LIST,
} from 'src/Redux/actions/workforce/eprofile'
import IconButton from 'src/Components/Buttons/IconButton'
import { getuserDocumentPayload } from 'src/Services/constant/defaultPayload'
import ResumePreview from './previewPdf'
import { useNavigate } from 'react-router-dom'
import Resumes from '../../employeeDetails/resumes'

const Resume = props => {
	const dispatch = useDispatch()
	const { resume, setResume, documentSave, cvSaving, canEdit, setCanEdit, mockObject, pdfUrl, setPdfUrl } = props
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const { empDocumentList } = EmployeeProfile
	const navigate = useNavigate()

	useEffect(() => {
		let userid = localStorage.getItem('comuserid')
		let t = localStorage.getItem('Tenantid')
		navigate(`/app/workforce/eprofile?userid=${userid}&tenid=${t}`)
		// eslint-disable-next-line 
	}, [])

	useEffect(() => {
		if (empDocumentList?.length > 0) {
			setResume(empDocumentList[0])
		} else {
			setResume(mockObject)
		}
		// eslint-disable-next-line
	}, [empDocumentList])

	const removeDoc = async () => {
		let id = resume?.userdocumentattachmentsid
		if (canEdit && id) {
			let res = await dispatch(DELETE_USER_DOCUMENT(id))
			if (res !== null) {
				setPdfUrl(null)
				dispatch(GET_EMPLOYEE_DOCUMENT_LIST(getuserDocumentPayload()))
				// setCanEdit(false)
			} else {
				setResume(mockObject)
			}
		}
	}

	const handleCancel = () => {
		setPdfUrl('')
		dispatch(GET_EMPLOYEE_DOCUMENT_LIST(getuserDocumentPayload()))
		setCanEdit(false)
	}

	return (
		<div className='h-[70vh] pb-10 w-full overflow-hidden'>
			{canEdit ? <div className='flex items-center justify-end py-2'>
				<UploadResume
					resume={resume}
					setResume={setResume}
					documents={resume}
					setCanEdit={setCanEdit}
					canEdit={canEdit}
					pdfUrl={pdfUrl}
					setPdfUrl={setPdfUrl}
					empDocumentList={empDocumentList}
				/>
				{resume?.userdocumentattachmentsid && <IconButton
					title='Remove Document'
					onClick={removeDoc}
					className='w-6 h-6 p-1 text-white disabled:bg-primary/10 bg-primary'
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
					</svg>
				</IconButton>}
			</div> : <div className='h-10' />}
			{pdfUrl ? <ResumePreview pdfUrl={pdfUrl} /> : <Resumes />}

			{canEdit ? (
				<div className='fixed mb-4 mr-4 bottom-2 right-6'>
					<div className='flex items-center justify-end gap-2'>
						<button
							onClick={handleCancel}
							className='px-2 py-1 border rounded border-primary text-primary h-9'
						>
							Cancel
						</button>
						<PrimaryButton
							disabled={cvSaving || !pdfUrl}
							className='py-2 text-sm text-white rounded-md disabled:bg-primary/20 bg-primary'
							onClick={documentSave}
						>
							{cvSaving ? 'Loading...' : 'Save'}
						</PrimaryButton>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

export default Resume
