import { memo, useMemo, useState } from 'react'
import { useDispatch, } from 'react-redux'
import moment from 'moment'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import { getTaskMasterProjectPayload } from 'src/Services/constant/defaultPayload'
import SelectInput from 'src/Components/SelectInput'
import { GET_TASK_MASTER } from 'src/Redux/actions/masterdata/user'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import EditTask from './editTask'
import CreateTask from './addTask'
import { basicShortList } from 'src/Services/constant/staticValues'

const cols = [
	'Task Name',
	'Task Type',
	'Task Group',
	'Task Code',
	'Created By',
	'Created At',
]
const rows = [10, 25, 50]

const TaskTable = ({ hooksValues }) => {
	const { task, setTask, taskList, taskG, taskId, setTaskId, isOpen, setIsOpen, project, thisPagePermissions } = hooksValues
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
	const status = { label: 'Active' }
	const [searchTerm, setSearchTerm] = useState('')
	const [dm, setDm] = useState({})
	const canEditinput = project?.statusname === 'Closed' || project?.statusname === 'Completed'

	useMemo(() => {
		if (dm?.taskdesc) {
			setSearchTerm(dm?.taskdesc)
		}
	}, [dm])

	const handleSorting = async item => {
		setSortType(item)
		let res = await dispatch(
			GET_TASK_MASTER({
				...getTaskMasterProjectPayload(taskG, 1, 0),
				sorttype: item?.id,
				// searchname: searchTerm,
				status: status?.label,
				limit: limit,
			}),
		)
		if (res !== null) {
			setTask(res?.data)
		}
	}

	const handlePagination = async page => {
		let res = await dispatch(
			GET_TASK_MASTER({
				...getTaskMasterProjectPayload(taskG, 1, 0),
				limit,
				page: page?.selected + 1,
				sorttype: sortType?.id,
				status: status?.label,
			}),
		)
		if (res !== null) {
			setTask(res?.data)
		}
		setPage(page?.selected + 1)
	}
	const handleRows = async row => {
		setLimit(row)
		let res = await dispatch(
			GET_TASK_MASTER({
				...getTaskMasterProjectPayload(taskG, 1, 0),
				limit: row,
				page: page?.selected + 1,
				sorttype: sortType?.id,
				status: status?.label,
			}),
		)
		if (res !== null) {
			setTask(res?.data)
		}
	}

	const handleBuClick = bu => {
		setTask(bu)
		setTaskId(bu?.taskid)
		setIsOpen(true)
	}

	const CanEditTask = (item) => {
		if (canEditinput) {
			return
		} else {
			handleBuClick(item)
		}
	}

	return (
		<div className='m-2'>
			<div className='flex items-center justify-between mb-2'>
				<div className='flex items-center justify-start space-x-5'>
					<h1 className='pb-2 font-[500]'>
						Tasks (
						{taskList?.length > 0
							? taskList[0]?.totalcount
							: taskList?.length}
						){' '}
					</h1>
					<div className='w-72'>
						<CustomAutoSearch
							searchTerm={searchTerm}
							API_CALL_FOR_SEARCH={GET_TASK_MASTER}
							searchPayload={{
								...getTaskMasterProjectPayload(63, 1, 0),
								searchname: searchTerm,
							}}
							setSearchTerm={setSearchTerm}
							options={taskList}
							idkey='taskdesc'
							optionlabelKey='taskdesc'
							getSelectedOption={setDm}
							placeholder='Enter Task Description'
							className='p-2'
						/>
					</div>
					<div className='flex items-center justify-start space-x-6'>
						<ReactPaginationStyle
							total={
								taskList?.length > 0
									? taskList[0]?.totalcount
									: taskList?.length
							}
							currentPage={page}
							handlePagination={handlePagination}
							limit={limit}
						/>
						<FilterButtonTab
							setSelectedtab={handleRows}
							selectedTab={limit}
							tabs={rows}
						/>
					</div>
					<div>
						<div className='w-32 '>
							<SelectInput
								options={basicShortList || []}
								keyname='label'
								selected={sortType}
								setSelected={handleSorting}
								className='border rounded bg-gray-50 py-2'
							/>
						</div>
					</div>
				</div>
				{!canEditinput && (thisPagePermissions.includes("Add/Edit Task") ? <CreateTask /> : "")}
			</div>
			<div className='w-full'>
				<table className='w-full relative overflow-y-scroll'>
					<thead>
						<tr className='text-left capitalize bg-gray-50'>
							{cols?.map(col => {
								return (
									<th
										key={col}
										title={col}
										scope='col'
										className='p-2 font-[500] border text-start'
									>
										{col}
									</th>
								)
							})}
						</tr>
					</thead>
					<tbody className='text-gray-600'>
						{taskList?.map((item, i) => {
							const {
								uId,
								taskdesc,
								taskcode,
								taskgroupdesc,
								tasktype,
								createdtimestamp,
								createduser,
								taskid,
							} = item
							return (
								<tr
									role='button'
									onClick={() => CanEditTask(item)}
									key={taskid || uId}
									className='text-left cursor-pointer'
								>
									<td className=' px-2  py-1 border'>
										{taskdesc}
									</td>
									<td className='px-2 py-1 border'>
										{tasktype}
									</td>
									<td className='px-2 py-1 border'>
										{taskgroupdesc}
									</td>
									<td className='px-2 py-1 border'>
										{taskcode}
									</td>
									<td className='px-2 py-1 border'>
										{createduser}
									</td>
									<td className='px-2 py-1 border'>
										{createdtimestamp
											? moment(createdtimestamp).format(
												'DD-MM-YYYY kk:mm:ss',
											)
											: ''}
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
			<EditTask
				detailsData={task}
				setDetailsData={setTask}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				page={page}
				limit={limit}
				sortType={sortType}
				taskid={taskId}
			/>
		</div>
	)
}

export default memo(TaskTable)
