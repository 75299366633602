import { memo } from "react";


const BaseInfo = ({ project }) => {
    return (
        <div className="mt-3 p-2 w-full rounded-lg border shadow-md bg-white">
            <table className="w-full text-sm">
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Company:</p>
                                <p className='text-gray-600'>{project?.companyname || 'N/A'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Customer:</p>
                                <p className='text-gray-600'>{project?.customername || 'N/A'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Business Unit:</p>
                                <p className='text-gray-600'>{project?.businessunitname || 'N/A'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Service Line:</p>
                                <p className='text-gray-600'>{project?.servicelinename || 'N/A'}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Project Type:</p>
                                <p className='text-gray-600'>{project?.projecttype || 'N/A'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Billing Type:</p>
                                <p className='text-gray-600'>{project?.billingtype || 'N/A'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Status:</p>
                                <p className='text-gray-600'>{project?.statusname || 'N/A'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start items-start w-full">
                                <p className="font-[500] pr-1">Duration: </p>
                                <p className='text-gray-600'>{project?.startdate} - {project?.enddate}</p>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default memo(BaseInfo);