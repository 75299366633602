import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import MultiSelectItem from 'src/Components/MultiSelect'
import SelectMonth from 'src/Components/SelectMonth'
import {
    GET_BUSINESS_MASTER,
    GET_LIST_OF_STATUS_BY_E,
    GET_SERVICELINE_MASTER,
} from 'src/Redux/actions/masterdata/user'
import {
    GET_LIST_OF_STATUS,
    GET_PROJECT_LIST_BILLING_TYPE,
} from 'src/Redux/actions/projects'
import { GET_TIMESHEET_TASK_TYPE } from 'src/Redux/actions/projects/timesheet'
import { GET_TIMESHEET_REPORT_FOR_EXCEPTION } from 'src/Redux/actions/reports'
import {
    getBusinessUnitData,
    getDefaultBillingIdPayload,
    getDefaultServiceLinePayload,
    getDefaultStatusPayload,
    getTaskTypePayload,
    getTimesheetExceptionReportPayload,
} from 'src/Services/constant/defaultPayload'
import WeekSelect from './weekSelect'

const categorylist = [
    { category: 'Excess', id: 'E' },
    { category: 'Deficit', id: 'D' },
]
const getCurrentWeekDates = () => {
    const now = new Date()
    const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1))
    const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7))
    return {
        monday: moment(monday).format('YYYY-MM-DD'),
        sunday: moment(sunday).format('YYYY-MM-DD'),
    }
}

const TimesheetExceptionReportsFilter = ({
    cols = 'grid-cols-6',
    filters,
    setFilters,
    project,
    setProject,
    setLoader,
    setReportId,
    reportId,
    projectIds,

}) => {
    const dispatch = useDispatch()
    const { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    const projectsReducer = useSelector((state) => state.Projects)
    const { statusListByType, listOfBillingProjects } = projectsReducer
    let { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const { businessList, serviceList } = useSelector((state) => state?.UserManagementList)
    const [weeks, setWeeks] = useState({
        monday: getCurrentWeekDates()?.monday,
        sunday: getCurrentWeekDates()?.sunday,
    })
    const [resetWeekSelect, setResetWeekSelect] = useState(false)
    const handleResetComplete = () => setResetWeekSelect(false)

    useEffect(() => {
        dispatch(GET_LIST_OF_STATUS_BY_E())
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload()))
        dispatch(GET_SERVICELINE_MASTER(getDefaultServiceLinePayload()))
        dispatch(GET_BUSINESS_MASTER(getBusinessUnitData()))
        dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
        dispatch(GET_TIMESHEET_TASK_TYPE(getTaskTypePayload()))
        // eslint-disable-next-line
    }, [dispatch])

    const dataBySelectedDate = async (range) => {
        const weekStart = moment(range.monday).format('YYYY-MM-DD')
        const weekEnd = moment(range.sunday).format('YYYY-MM-DD')
        const payload = {
            ...filters,
            weekstartdate: weekStart,
            weekenddate: weekEnd,
        }
        setFilters(payload)
        setWeeks(range)
    }

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }
    const handleSelectTab = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        let m = month?.length === 1 ? `0${month}` : month
        const parsedMonth = parseInt(m, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        setFilters({
            ...filters,
            [name]: Number(`${parsedYear}${m}`),
            Monthid: validMonthid,
            Yearid: validYearid,
        })
    }

    const clearFilters = () => {
        setProject([])
        setResetWeekSelect(true)
        let payload = {
            ...getTimesheetExceptionReportPayload(),
            comuserid: 0,
            projectid: `{${projectIds?.join(',')}}`,
            billingtypeid: 0,
        }
        dispatch(GET_TIMESHEET_REPORT_FOR_EXCEPTION(payload))
        setFilters(payload)
        setLoader(false)
    }

    const handleProjectSelect = (item) => {
        const isNew = project?.some((p) => p?.projectid === item?.projectid)
        if (isNew) {
            const updatedAllocationStatus = project?.filter(
                (p) => p?.projectid !== item?.projectid,
            )
            setProject(updatedAllocationStatus)
        } else {
            setProject((prevList) => [
                ...prevList,
                { projectid: item?.projectid, projectname: item?.projectname },
            ])
        }
    }

    useEffect(() => {
        if (projectsOfM?.length > 0) {
            const projectIdsString = `{${project?.map((p) => p?.projectid).join(',')}}`
            setFilters((prevFilters) => ({
                ...prevFilters,
                projectid: projectIdsString,
                comuserid: 0,
            }))
        }
    }, [project, setFilters, projectsOfM?.length])

    const applyFilters = () => {
        let data = { ...filters }
        setLoader(true)
        dispatch(GET_TIMESHEET_REPORT_FOR_EXCEPTION(data))
        setLoader(false)
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    return (
        <div className={`grid ${cols} gap-3`}>
            <div>
                <h1 className="mb-2 font-[500]">Select Report</h1>
                {reportMasterList?.length > 0 && (
                    <CustomhtmlSelect
                        options={reportMasterList || []}
                        noDataLabel="No Report"
                        value={reportId}
                        name="reportid"
                        labelkey="reportname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={selectReport}
                        id="reportid"
                    />
                )}
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Projects</h1>
                <div className=" bg-gray-50">
                    {projectsOfM !== null && (
                        <MultiSelectItem
                            className="py-2 border rounded bg-gray-50"
                            itemIdKey="projectid"
                            labelKeyname="projectname"
                            limitShow={1}
                            selectedOptions={project}
                            options={projectsOfM}
                            handleSelection={handleProjectSelect}
                        />
                    )}
                </div>
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Month/year</h1>
                <div className=" bg-gray-50">
                    <SelectMonth
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        onChange={handleMonthChange}
                        name="yearmonth"
                        year={filters?.Yearid}
                        month={
                            filters?.Monthid?.length === 1
                                ? `0${filters?.Monthid}`
                                : filters?.Monthid
                        }
                    />
                </div>
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Status</h1>
                <CustomhtmlSelect
                    disabled={projectsOfM?.length === 0}
                    options={statusListByType || []}
                    value={filters?.approvalstatus}
                    noDataLabel="Select"
                    name="approvalstatus"
                    labelkey="statusname"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="statusname"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Service Line</h1>
                <CustomhtmlSelect
                    options={serviceList || []}
                    value={filters?.servicelineid || ''}
                    noDataLabel="No service"
                    name="servicelineid"
                    labelkey="servicelinename"
                    className="p-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="servicelineid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Billing Type</h1>
                <FilterButtonTabObject
                    className="py-2 border bg-gray-50"
                    tabs={listOfBillingProjects || []}
                    selected={filters?.billingtypeid}
                    labelkey="billingtype"
                    name="billingtypeid"
                    valuekey="datprojectbillingtypeid"
                    onClick={handleSelectTab}
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Business Unit</h1>
                <CustomhtmlSelect
                    options={businessList || []}
                    value={filters?.businessunitid || ''}
                    noDataLabel="No business unit"
                    name="businessunitid"
                    labelkey="businessunitname"
                    className="p-2 border rounded-lg bg-gray-50"
                    onChange={handleSelectChange}
                    id="businessunitid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Exception</h1>
                <CustomhtmlSelect
                    options={categorylist || []}
                    noDataLabel="No Category"
                    value={filters?.exception}
                    name="exception"
                    labelkey="category"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="id"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Week Start Date</h1>
                <WeekSelect
                    setWeeks={setWeeks}
                    dataBySelectedDate={dataBySelectedDate}
                    state={weeks}
                    reset={resetWeekSelect}
                    onResetComplete={handleResetComplete}
                />
            </div>
            <div />
            <div />
            <div>
                <h1 className="mb-2 font-[500]">Action</h1>
                <div className="grid grid-cols-2 gap-2">
                    <button
                        onClick={() => {
                            clearFilters()
                        }}
                        className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                    >
                        Clear
                    </button>
                    <button
                        disabled={projectsOfM?.length === 0}
                        onClick={applyFilters}
                        className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

export default memo(TimesheetExceptionReportsFilter)
