import SingleSelectField from "src/Components/SingleSelectForTable";

const Technology = ({ options, disabled, value, rowId, handleChange }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'techgroupid', value: value['techgroupid'] } }
        handleChange(e, rowId)
    }

    return (
        <div>
            <SingleSelectField
                valueKey='techgroupid'
                labelKey='techarea'
                options={options}
                value={value || 0}
                handleSelected={getSelected}
                disabled={disabled} />
        </div>
    )
}

export default Technology;