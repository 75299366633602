import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { SkillAreaItem } from './defaultSkill';
import EditSkillArea from './editSkillArea';
import CreateSkillArea from './createSkillArea';
import { GET_SKILLAREA_MASTER } from 'src/Redux/actions/workforce/eprofile';
import { getDeafultSkillAreaData } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = ['Skill Area', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const SkillAreaMasterList = () => {
  const [skillarea, setSkillArea] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [skillAreaId, setSkillAreaId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(SkillAreaItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });

  const dispatch = useDispatch();
  const { skillList, loading: skillLoading } = useSelector((state) => state.EmployeeProfile);
  const { PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(skillLoading); // Hide the loader after a delay
    }, 100);
  }, [skillLoading]);

  useMemo(() => {
    if (skillarea?.skillarea) {
      setSearchTerm(skillarea?.skillarea);
    }
  }, [skillarea]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_SKILLAREA_MASTER({ ...getDeafultSkillAreaData(), sorttype: item.id, status: status?.label, limit: limit }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_SKILLAREA_MASTER({ ...getDeafultSkillAreaData(), status: item.label }));
    if (res === null) {
      setDetailsData(res?.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(
      GET_SKILLAREA_MASTER({ ...getDeafultSkillAreaData(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(GET_SKILLAREA_MASTER({ ...getDeafultSkillAreaData(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  const handleSkillAreaClick = (skillarea) => {
    setDetailsData(skillarea);
    setSkillAreaId(skillarea?.skillareamasterid);
    setIsOpen(true);
  };

  return (
    <div className='mt-4 p-2 py-3 w-full h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Skill Areas ({skillList?.length > 0 ? skillList[0]?.totalcount : skillList?.length})
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_SKILLAREA_MASTER}
              searchPayload={{ ...getDeafultSkillAreaData(), searchname: searchTerm, }}
              setSearchTerm={setSearchTerm}
              options={skillList}
              idkey='skillarea'
              optionlabelKey='skillarea'
              getSelectedOption={setSkillArea}
              placeholder='Enter Skill Area Name'
              className='p-2'
            />
          </div>
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              total={skillList?.length > 0 ? skillList[0]?.totalcount : skillList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {(PermissionsOfMasterdata.includes("Add/Edit Skills")) ? <CreateSkillArea /> : ""}
      </div>
      <div className="h-[46vh] overflow-scroll">
        {loading || skillList === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          skillList && (
            <table className='text-sm w-full relative'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {skillList?.map((item, i) => {
                  let {
                    skillarea,
                    isactiveflag,
                    createduser,
                    createdtimestamp,
                    skillareamasterid,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleSkillAreaClick(item)}
                      key={skillareamasterid}
                      className='cursor-pointer text-left'>
                      <td className='border px-2 py-1.5'><div>{skillarea}</div></td>
                      <td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='border px-2'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                      <td className='border px-2'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditSkillArea
        setIsOpen={setIsOpen}
        setDetailsData={setDetailsData}
        detailsData={detailsData}
        isOpen={isOpen}
        skillareamasterid={skillAreaId}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfMasterdata}
      />
    </div>
  );
};

export default SkillAreaMasterList;
