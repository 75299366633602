

export const RoleItem = (comcurrencyid = null) => {
  return {
    uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    croleid: null,
    levelid: null,
    skillareamasterid: null,
    techgroupid: null,
    locationid: null,
    bill_hr: null,
    comcurrencyid,
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: '',
    deliveryroleid: null,
    countryid: 0,
    customerid: 0,
    RateUnit: 0
  }
}

export const detailItem = () => {
  return {
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    customername: "",
    customergroupid: 0,
    shortname: "",
    companyid: 0,
    address_street: "",
    address_pobox: "",
    address3: "",
    pincode: "",
    phoneno: "",
    comcityid: 0,
    comstateid: 0,
    comcountryid: null,
    comregionid: null,
    industryid: 0,
    mobile1: "",
    Source: "",
    customeridinsourcesystem: "",
    accountmanagerid: 0,
    defaultcomcurrencyid: 0,
    email: "",
    payment_days: 30,
    gst_type: null,
    gst_number: "",
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: "",
    businessunitid: 0
  }
}

export const contactItem = () => {
  return {
    uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    contactpersonname: "",
    designation: "",
    phoneno: "",
    email: "",
    contacttype: "",
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: "",
    isprimary: false
  }
}

export const customerDocumentRow = () => {
  return {
    uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    customerdocumentattachmentsid: null,
    Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    documenttype: 0,
    customerid: null,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    deletedcomuserid: null,
    versionno: "",
    notes: "",
    filename: "",
    filepath: "",
    isdeletedflag: false,
    file: ""
  }
}

export const detailNotRequired = [
  'customercode', 'accountmanagerid',
  'companyname', 'gst_type', 'gst_number',
  'customername',
  'customergroupname',
  'industryname',
  'businessunitname',
  'currency',
  'accountmanagername',
  'addressLine1',
  'cityname',
  'shortname',
  "address_pobox",
  "pincode", "comregionid", "mobile1",
  "customeridinsourcesystem",
  'notes',
  'fax',
  'contactpersonid',
  'contactpersonname',
  'isprimary',
  'companyid',
  'isonsite',
  'comcountryid'
];