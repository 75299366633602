import { ACTION_GET_ALL_CONTACTS_OF_A_CUSTOMER_GROUP, ACTION_GET_CUSTOMER_GROUP_DOCUMENT_LIST } from "src/Redux/types";

const initialState = {
    userlist: [],
    customerlist: [],
    regionfilteredlist: [],
    companyfilteredlist: [],
    industryfilteredlist: [],
    accountfilteredlist: [],
    countryfilteredlist: [],
    customers: [],
    displaycustomerfilteredlist: [],
    statuslist: [],
    savedRoles: [],
    sourcefilteredlist: [],
    customerById: [],
    customerContacts: [],
    saveCustomerGroupContacts:[],
    CustomerContacts: [],
    CustomerGroupContacts: [],
    customerId: null,
    contacts: [],
    primaryContact: [],
    listOfCustomers: [],
    customerDocumentList: [],
    customerGroupDocumentList:[]
};

const CustomerManagementList = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USER_LIST':
            return { ...state, userlist: action.data?.Message ? [] : action.data, };
        case 'GET_CUSTOMER_MASTERLIST':
            return { ...state, listOfCustomers: action.data?.Message ? [] : action.data };
        case 'GET_REGION_MASTER_FILTER':
            return { ...state, regionfilteredlist: action.data?.Message ? [] : action.data, };
        case 'GET_ACCOUNT_MASTER_FILTER':
            return { ...state, accountfilteredlist: action.data?.Message ? [] : action.data, };
        case 'GET_COMPANY_MASTER_FILTER':
            return { ...state, companyfilteredlist: action.data?.Message ? [] : action.data, };
        case 'GET_INDUSTRY_MASTER_FILTER':
            return { ...state, industryfilteredlist: action.data?.Message ? [] : action.data, };
        case 'GET_COUNTRY_MASTER_FILTER':
            return { ...state, countryfilteredlist: action.data?.Message ? [] : action.data, };
        case 'GET_SOURCE_MASTER_FILTER':
            return { ...state, sourcefilteredlist: action.data?.Message ? [] : action.data, };
        case 'SAVE_CUSTOMER_AS_DRAFT':
            return { ...state, customers: action.data?.Message ? [] : action.data, };
        case 'GET_CUSTOMER_BY_ID':
            return { ...state, customerById: action.data, };
        case 'GET_ALL_CONTACTS_OF_A_CUSTOMER':
            return { ...state, CustomerContacts: action.data };
        case ACTION_GET_ALL_CONTACTS_OF_A_CUSTOMER_GROUP:
            return { ...state, CustomerGroupContacts: action.data };
        case 'SAVE_CUSTOMER_CONTACTS':
            return { ...state, customerContacts: action.data?.Message ? [] : action.data, };
            case 'SAVE_CUSTOMER_GROUP_CONTACTS':
        return { ...state, saveCustomerGroupContacts: action.data?.Message ? [] : action.data, };
        case 'GET_CUSTOMER_DOCUMENT_LIST':
            return { ...state, customerDocumentList: action.data }
        case ACTION_GET_CUSTOMER_GROUP_DOCUMENT_LIST:
            return { ...state, customerGroupDocumentList: action.data }
        case 'GET_CUSTOMER_DOCUMENT_TYPE_LIST':
            return { ...state, customerDocumentTypeList: action.data }
        default:
            return state;
    }

};

export default CustomerManagementList;
