import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import MultiSelectItem from 'src/Components/MultiSelect'
import { GET_RELEASE_PLAN_REPORT } from 'src/Redux/actions/reports'
import { getReleasePlanReportsPayload } from 'src/Services/constant/defaultPayload'

const ReportFilter = ({
    filters,
    setFilters,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
}) => {
    const dispatch = useDispatch()
    const {
        workforcelist,
        allocationstatuslist,
        companylist,
        technologylist,
        workertypelist,
    } = useSelector((state) => state?.EmployeeDetails)
    const { userSkillList } = useSelector((state) => state?.EmployeeProfile)
    const { reportMasterList } = useSelector((state) => state?.WorkforceReport)
    const { serviceList } = useSelector((state) => state?.UserManagementList)

    const [allocationStatus, setAllocationStatuslist] = useState([])
    const handleStatusSelect = (status) => {
        const isNew = allocationStatus?.some((p) => p?.statusid === status?.statusid)
        if (isNew) {
            const updatedAllocationStatus = allocationStatus?.filter(
                (p) => p?.statusid !== status?.statusid,
            )
            setAllocationStatuslist(updatedAllocationStatus)
        } else {
            setAllocationStatuslist((prevList) => [
                ...prevList,
                { statusid: status?.statusid, statusname: status?.statusname },
            ])
        }
    }

    useEffect(() => {
        if (allocationStatus) {
            const statusIdsString = `{${allocationStatus
                .map((status) => status.statusid)
                .join(',')}}`
            setFilters((prevFilters) => ({
                ...prevFilters,
                allocationstatusid: statusIdsString,
            }))
        }
    }, [allocationStatus, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        let payload = {
            ...getReleasePlanReportsPayload(),
            allocationstatusid: '{}',
            specifieddate: '',
        }
        dispatch(GET_RELEASE_PLAN_REPORT(payload))
        setAllocationStatuslist([])
        setFilters(payload)
    }

    const applyFilters = () => {
        let data = {
            ...filters,
            projectid: '{}',
        }
        dispatch(GET_RELEASE_PLAN_REPORT(data))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }
    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        // disabled={!reportId}
                        />
                    )}
                </div>
                <>
                    <div>
                        <h1 className="mb-2 font-[500]">Company</h1>
                        <CustomhtmlSelect
                            options={companylist || []}
                            value={filters?.companyid}
                            noDataLabel="No Company"
                            name="companyid"
                            labelkey="companyname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="companyid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Worker Type</h1>
                        <CustomhtmlSelect
                            options={workertypelist || []}
                            value={filters?.workertypeid}
                            noDataLabel="No Type"
                            name="workertypeid"
                            labelkey="workertype"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="workertypeid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Workforce group</h1>
                        <CustomhtmlSelect
                            options={workforcelist || []}
                            value={filters?.workforcegroupid}
                            noDataLabel="No Workforce found"
                            name="workforcegroupid"
                            labelkey="workforcegroup"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="wfgid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Allocation Status </h1>
                        <div className="border rounded bg-gray-50 p-0.5">
                            <MultiSelectItem
                                itemIdKey="statusid"
                                labelKeyname="statusname"
                                limitShow={1}
                                selectedOptions={allocationStatus}
                                options={allocationstatuslist}
                                handleSelection={handleStatusSelect}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Primary Skill</h1>
                        <CustomhtmlSelect
                            options={userSkillList || []}
                            value={filters?.skillid}
                            noDataLabel="No Skill"
                            name="skillid"
                            labelkey="skill"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="skillmasterid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Technology</h1>
                        <CustomhtmlSelect
                            options={technologylist || []}
                            value={filters?.technologyid}
                            noDataLabel="No tech"
                            name="technologyid"
                            labelkey="technology"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="technologymasterid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Service Line</h1>
                        <CustomhtmlSelect
                            options={serviceList || []}
                            value={filters?.servicelineid}
                            noDataLabel="No Serviceline"
                            name="servicelineid"
                            labelkey="servicelinename"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="servicelineid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]"> Release Plan Date</h1>
                        <FlatPickrDateInput
                            name="specifieddate"
                            value={filters?.specifieddate || ''}
                            onChangeDate={(e) =>
                                handleSelectChange('specifieddate', e.target.value)
                            }
                        />
                    </div>
                </>
                <div />
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportFilter
