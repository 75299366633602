import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import SingleSelectField from 'src/Components/SingleSelectForTable'
import { GET_CUSTOMER_MASTERLIST } from 'src/Redux/actions/masterdata/customer'
import {
	GET_COUNTRY_MASTER,
	GET_CUSTOMER_GROUP_MASTER,
	GET_REGION_MASTER,
	GET_SERVICELINE_MASTER,
} from 'src/Redux/actions/masterdata/user'
import {
	GET_BUSINESS_UNIT_MASTER_LIST,
	GET_LIST_TYPE_OF_PROJECT,
	GET_PROJECT_LIST_BILLING_TYPE,
} from 'src/Redux/actions/projects'
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent'
import { getCustomerListPayload, getCustomerRoleListPayload } from 'src/Services/constant/Customerpayload'
import {
	getDefaultBillingIdPayload,
	getDefaultBusinessUnitPayload,
	getDefaultCompanyPayload,
	getDefaultProjectTypePayload,
	getMasterCountryPayload,
} from 'src/Services/constant/defaultPayload'
import { getMasterRegionPayload, getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import SearchCustomer from '../../projects/searchCustomer'
import SearchCustomerGroup from '../../reports/finance/selectCustomerGroup'

const quarters = [
	{ label: 'Q1', value: '-04-01,-06-30' },
	{ label: 'Q2', value: '-07-01,-09-30' },
	{ label: 'Q3', value: '-10-01,-12-31' },
	{ label: 'Q4', value: '-01-01,-03-31' },
]
const yearsData = Array.from({ length: 21 }, (_, index) => ({
	value: 2020 + index,
	label: 2020 + index,
}))

/* const getStartAndEndDate = () => {
	let startdate = `${new Date().getFullYear()}${quarters[0]?.value?.split(',')[0]}`
	let enddate = `${new Date().getFullYear()}${quarters[0]?.value?.split(',')[1]}`
	return { startdate, enddate }
} */

const AnalyticsProjectFilter = ({filter, setFilter}) => {
	const [account, setAccount] = useState({})
	const [customer, setCustomer] = useState({})
	const dispatch = useDispatch()
	const { customerList, listOfBillingProjects } = useSelector((state) => state?.Projects)
	const { regionList, customerGroupList } = useSelector((state) => state.UserManagementList)

	useEffect(() => {
		dispatch(GET_REGION_MASTER(getMasterRegionPayload()))
		dispatch(GET_COUNTRY_MASTER(getMasterCountryPayload()))
		dispatch(GET_COMPANY_MASTER(getDefaultCompanyPayload()))
		dispatch(GET_BUSINESS_UNIT_MASTER_LIST(getDefaultBusinessUnitPayload()))
		dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
		dispatch(GET_CUSTOMER_GROUP_MASTER(getCustomerRoleListPayload()))
		dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload()))
		dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
		dispatch(GET_LIST_TYPE_OF_PROJECT(getDefaultProjectTypePayload(0)))
		// dispatch(GET_LIST_OF_STATUS((getDefaultStatusPayload('P'))))
	}, [dispatch])

	const handleChangeInput = (name, value) => {
		setFilter({ ...filter, [name]: value })
	}

	const handleSelectTab = (name, value) => {
		setFilter({ ...filter, [name]: value })
	}
	// region, account, customer, billing type, year, quarter
	return (
		<div className="w-full text-sm">
			<div className="grid grid-cols-2 gap-4 lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-1">
				<div className="col-span-2">
					<h1 htmlFor="comregionid" className="block font-[500] mb-2">
						Region
					</h1>
					<CustomhtmlSelect
						options={regionList || []}
						value={filter?.comregionid || ''}
						noDataLabel="No region"
						name="comregionid"
						labelkey="regionname"
						className="py-2 border rounded bg-gray-50"
						onChange={handleChangeInput}
						id="comregionid"
					/>
				</div>
				<div className="col-span-2">
					<h1 className="mb-2 font-[500]">Select Account</h1>
					<SearchCustomerGroup
						selected={account}
						setSelected={setAccount}
						Options={customerGroupList || []}
					/>
				</div>
				<div className="col-span-2">
					<div>
						<h1 className="mb-2 font-[500]">Select Customer</h1>
						<SearchCustomer selected={customer} setSelected={setCustomer} Options={customerList || []} />
					</div>
				</div>
				<div className="col-span-2">
					<h1 className="mb-2 font-[500]">Billing Type</h1>
					<FilterButtonTabObject
						className="py-2 border"
						tabs={listOfBillingProjects || []}
						selected={filter?.billingtypeid}
						labelkey="billingtype"
						name="billingtypeid"
						valuekey="datprojectbillingtypeid"
						onClick={handleSelectTab}
					/>
				</div>
				<div className="col-span-1">
					<h1 className="mb-2 font-[500]">Select Year</h1>
					<div className="px-2 py-1 border rounded w-26 bg-gray-50">
						<SingleSelectField
							valueKey="value"
							labelKey="label"
							options={yearsData}
							value={filter?.label}
							handleSelected={handleChangeInput}
						/>
					</div>
				</div>
				<div className="col-span-1">
					<h1 className="mb-2 font-[500]">Select Quarter</h1>
					<div className="px-2 py-1 border rounded w-26 bg-gray-50">
						<SingleSelectField
							valueKey="value"
							labelKey="label"
							options={quarters}
							value={filter?.value}
							handleSelected={handleChangeInput}
						/>
					</div>
				</div>
				<div className="col-span-2">
					<h1 className="mb-2 font-[500]">Action</h1>
					<div className="grid grid-cols-2 gap-1">
						<button className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5">
							Clear
						</button>
						<button className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5">
							Apply
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AnalyticsProjectFilter
