
function getUserId() { return JSON.parse(localStorage.getItem('comuserid')) }
function gettenantId() { return JSON.parse(localStorage.getItem('Tenantid')) }

export const budgetRow = (projectid) => {
    return {
        projectbudgetid: null,
        projectid,
        description: "",
        overheadid: 0,
        plannedeffort: 0,
        plannedcost: 0,
        datcomcurrencyid: 0,
        isactiveflag: true,
        createdcomuserid: getUserId(),
        modifiedcomuserid: getUserId(),
        notes: "",
        units: '',
        rate: '',
        comtenantid: gettenantId(),
        isbillable: false,
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    }
}