import { memo, useEffect, useState } from 'react'
import ViewlistFilter from 'src/Filter/projects/viewlistFilter'
import Cards from './card'
import CreateProject from '../createProject'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PROJECTS_BY_USER, GET_CUSTOMER_LIST, GET_LIST_TYPE_OF_PROJECT, GET_LIST_COMPANY, GET_PROJECT_LIST_BILLING_TYPE, GET_LIST_OF_STATUS, } from 'src/Redux/actions/projects'
import { getDefaultProjectPayload, getDefaultCustomerPayload, getDefaultProjectTypePayload, getDefaultCompanyPayload, getDefaultBillingIdPayload, getDefaultStatusPayload, } from 'src/Services/constant/defaultPayload'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import SelectInput from 'src/Components/SelectInput'
import { GET_LIST_OF_STATUS_BY_E } from 'src/Redux/actions/masterdata/user'
import { useLocation } from 'react-router-dom'
import { basicShortList } from 'src/Services/constant/staticValues'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import { ToastContainer } from 'react-toastify'

const rows = [10, 25, 50]
const ProjectListing = ({ modulename, menu }) => {
    const dispatch = useDispatch()
    const projectsReducer = useSelector(state => state.Projects)
    const { getProjectsByUserId } = projectsReducer
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filters, setFilters] = useState(getDefaultProjectPayload())
    const [projects, setProjects] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const AppliedFilters = searchParams.get('AppliedFilters')
    const [shortType, setShortType] = useState({ label: 'Latest First', id: 3 })

    // get project default data with default filters
    const getProjectDefaultdata = async () => {
        const debounceTimer = setTimeout(async () => {
            let res = await dispatch(GET_PROJECTS_BY_USER({ ...filters, limit: limit, pageno: page, sorttype: shortType?.id }));
            if (res !== null) {
                setProjects(res)
            }
        }, 600)
        return () => {
            clearTimeout(debounceTimer)
        }
    }
    // api call for filter options
    useEffect(() => {
        dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
        dispatch(GET_LIST_OF_STATUS((getDefaultStatusPayload('P'))))
        dispatch(GET_CUSTOMER_LIST(getDefaultCustomerPayload()));
        dispatch(GET_LIST_TYPE_OF_PROJECT(getDefaultProjectTypePayload(0)))
        dispatch(GET_LIST_COMPANY(getDefaultCompanyPayload()))
        dispatch(GET_LIST_OF_STATUS_BY_E())
        // eslint-disable-next-line
    }, [dispatch]);

    const handleSorting = async (item) => {
        setShortType(item)
        let res = await dispatch(GET_PROJECTS_BY_USER({
            ...filters,
            limit: limit, pageno: page, sorttype: item?.id,
            projectname_code: searchTerm
        }));
        if (res !== null) {
            setProjects(res)
        }

    }

    const handleSearch = (search) => {
        setProjects([search])
    }

    const handlePagination = async (page) => {
        let data = filters || getDefaultProjectPayload()
        if (searchTerm?.trim()) {
            data['projectname_code'] = searchTerm
        }
        let res = await dispatch(GET_PROJECTS_BY_USER({ ...data, limit, pageno: page?.selected + 1, sorttype: shortType?.id }));
        if (res !== null) {
            setProjects(res)
        }
        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        let data = filters || getDefaultProjectPayload()
        if (searchTerm?.trim()) {
            data['projectname_code'] = searchTerm
        }
        let res = await dispatch(GET_PROJECTS_BY_USER({ ...data, limit: row, pageno: page, sorttype: shortType?.id }));
        if (res !== null) {
            setProjects(res)
        }
    }

    useEffect(() => {
        if (AppliedFilters) {
            setFilters(JSON.parse(AppliedFilters))
        }
        // eslint-disable-next-line
    }, [AppliedFilters])

    const payloadWithAppliedFilter = () => {
        let applied = JSON.parse(AppliedFilters)
        if (applied) {
            return applied
        } else {
            return getDefaultProjectPayload()
        }
    }

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(item => item?.permissionname);
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [menu])

    return (
        <>
            <div className="w-full h-screen p-2 overflow-y-scroll">
                <div className="p-2 py-3 bg-white rounded-md shadow">
                    <ViewlistFilter
                        limit={limit}
                        setProjects={setProjects}
                        projects={projects}
                        projectsReducer={projectsReducer}
                        filters={filters}
                        setFilters={setFilters}
                    />
                </div>
                <div className="flex items-center justify-between py-4">
                    <div className='flex items-center justify-start space-x-6'>
                        <div className="text-primary font-[500]">Projects <span className='min-w-[100px]'> ({getProjectsByUserId?.length > 0 ? getProjectsByUserId[0]?.totalcount || 0 : getProjectsByUserId?.length || 0}) </span></div>
                        <div className=" w-96">
                            <CustomAutoSearch
                                hideOptions={true}
                                searchTerm={searchTerm}
                                API_CALL_FOR_SEARCH={GET_PROJECTS_BY_USER}
                                searchPayload={{
                                    ...payloadWithAppliedFilter(),
                                    projectname_code: searchTerm
                                }}
                                setSearchTerm={setSearchTerm}
                                options={getProjectsByUserId}
                                idkey='projectid'
                                optionlabelKey='projectname'
                                className='p-2 bg-white'
                                getSelectedOption={handleSearch}
                                placeholder='Enter Project Name' />
                        </div>
                        <ReactPaginationStyle
                            limit={limit}
                            total={getProjectsByUserId?.length > 0 ? getProjectsByUserId[0]?.totalcount : getProjectsByUserId?.length}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className='w-44 '>
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows} />
                        </div>
                        <div className='w-32 '>
                            <SelectInput
                                options={basicShortList || []}
                                keyname='label'
                                selected={shortType}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                    {thisPagePermissions.includes('Add Project') ? <CreateProject getProjectDefaultdata={getProjectDefaultdata} /> : ''}
                </div>
                {getProjectsByUserId === null ? <div className='flex items-center justify-center w-full h-1/2'>
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
                    : <div
                        className="grid grid-cols-1 gap-4 mt-4  pb-14 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                        {
                            getProjectsByUserId?.map((item) => {
                                return (
                                    <Cards id={item?.projectid} key={item?.projectid + item?.uId} item={item} />
                                )
                            })
                        }
                    </div>}
            </div>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default memo(ProjectListing)