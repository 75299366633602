import { Tab } from '@headlessui/react'
import Revenue from './revenue'
import Profitability from './profitability'
import { useState } from 'react'
import { PreIcon, NextIcon } from 'src/Assets/icons'
import FinanceAnalyticSubFilter from 'src/Filter/analytics/analyticsFinance/subFilter'

const tabs = ['Revenue', 'Profitability', 'Expenses', 'Budgeting', 'Cash Flows']

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Tabs = ({ thisPagePermissions,
    filters, setFilters, setSubFilter, subFilter }) => {
    const [showMore, setShowMore] = useState(true)

    return (
        <div className="relative w-full h-full">
            <Tab.Group>
                <Tab.List className="w-full flex justify-between p-1 space-x-1 border-b border-secondary">
                    <div className='flex justify-start items-center space-x-2'>
                        <Tab.List className="flex justify-start p-1">
                            {tabs?.map((item, i) => {
                                return (
                                    <Tab
                                        key={i}
                                        onClick={() => setShowMore(false)}
                                        className={({ selected }) =>
                                            classNames(
                                                'py-2 text-sm font-[500] outline-white',
                                                'ring-white ring-opacity-60 ring-offset-0 ring-offset-white focus:outline-none focus:ring-0',
                                                selected
                                                    ? 'bg-secondary/10 text-secondary font-[500] p-1 rounded-md'
                                                    : 'text-gray-500',
                                            )
                                        }
                                    >
                                        <span className="px-4"> {item}</span>
                                    </Tab>
                                )
                            })}
                        </Tab.List>
                        <div className="flex items-center justify-start space-x-2 top-2 right-2 ">
                            <button
                                disabled={showMore}
                                onClick={() => setShowMore(!showMore)}
                                className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                            >
                                {PreIcon}
                            </button>
                            <button
                                disabled={!showMore}
                                onClick={() => setShowMore(!showMore)}
                                className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                            >
                                {NextIcon}
                            </button>
                        </div>
                    </div>
                    <FinanceAnalyticSubFilter
                        filters={subFilter}
                        setFilters={setSubFilter}
                    />
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <Revenue filters={filters} setFilters={setFilters} thisPagePermissions={thisPagePermissions} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Profitability filters={filters} setFilters={setFilters} thisPagePermissions={thisPagePermissions} />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default Tabs
