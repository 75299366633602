import { memo } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'

const Details = props => {
    const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props

    const handleSubmit = async e => {
        e.preventDefault()
        let items = {
            comtenantid: Number(localStorage.getItem('Tenantid') || 0),
            industryname: detailsData?.industryname,
            industryid: detailsData?.industryid,
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            isactiveflag: detailsData?.isactiveflag,
            notes: detailsData?.notes
        }
        let payload = {
            industryjson: [items],
        }
        detailSave(payload)
    }

    const handleChangeInput = (name, value) => {
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className='w-full h-[65vh] text-sm mb-10'>
            <div className='grid grid-cols-4 gap-10 mt-4'>
                <div>
                    <label htmlFor='rolename' className='block font-[500] mb-2'>
                        Industry
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        disabled={canEdit}
                        type='text'
                        title='industryname'
                        id='industryname'
                        className='block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50'
                        placeholder='Role Name'
                        required=''
                        value={detailsData?.industryname || ''}
                        onChange={(e) => {
                            if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                                handleChangeInput('industryname', e.target.value)
                            }
                        }}
                        maxLength={50}
                    />
                </div>
            </div>
            <div className='fixed bottom-0 mt-2 right-6'>
                {!canEdit && (
                    <div className='fixed bottom-0 mt-2 right-6'>
                        <div className='flex items-center justify-end gap-2'>
                            <button
                                onClick={() => setCanEdit(false)}
                                type=''
                                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                            >
                                Cancel
                            </button>
                            <PrimaryButton
                                disabled={canEdit}
                                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                                onClick={handleSubmit}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default memo(Details)
