import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useMemo, useState} from 'react'
import TaskTable from './table'
import {GET_TASK_MASTER} from 'src/Redux/actions/masterdata/user'
import {GET_TASK_GROUPS} from 'src/Redux/actions/projects/planning'
import {GET_TIMESHEET_TASK_TYPE} from 'src/Redux/actions/projects/timesheet'
import {
	getTaskMasterProjectPayload,
	getTaskTypePayload,
} from 'src/Services/constant/defaultPayload'
import {defaultItemOfTask} from './defaultVal'

const TaskLayout = ({hooksValues, disabled}) => {
	const dispatch = useDispatch()
	const [task, setTask] = useState(defaultItemOfTask())
	const [isOpen, setIsOpen] = useState(false)
	const [taskId, setTaskId] = useState(null)
	const [taskG, setTaskG] = useState(0)
	const planningState = useSelector(state => state?.ProjectPlanning)
	const {taskGroups} = planningState
	const {taskList} = useSelector(state => state.UserManagementList)

	useMemo(() => {
		setTaskG(taskGroups?.filter(item => item['defaulttask'] === true))
		if (taskGroups?.length > 0) {
			const projectTaskGroupId = taskGroups?.find(
				item => item['defaulttask'] === true,
			)?.['taskgroupid']
			setTaskG(projectTaskGroupId)
		}
	}, [taskGroups])

	useEffect(() => {
		dispatch(GET_TASK_GROUPS(0))
		dispatch(GET_TIMESHEET_TASK_TYPE(getTaskTypePayload()))
		dispatch(GET_TASK_MASTER(getTaskMasterProjectPayload(taskG, 1, 0)))
	}, [dispatch, taskG])

	useMemo(() => {
		if (taskList?.length > 0) {
			setTask(taskList)
		}
	}, [taskList])

	return (
		<div className='w-full text-sm bg-white rounded-lg'>
			<div className='h-[64vh] overflow-hidden  px-1'>
				<TaskTable
					hooksValues={{
						...hooksValues,
						disabled,
						task,
						setTask,
						taskList,
						taskG,
						taskId,
						setTaskId,
						isOpen,
						setIsOpen,
					}}
				/>
			</div>
		</div>
	)
}

export default TaskLayout
