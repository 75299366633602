import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'

const SelectInput = ({
	options,
	keyname,
	selected = {},
	setSelected,
	className = 'border py-2',
	extrakey = '',
	mapkey,
	staticLabel,
	disabled = false,
	defaultSeletectLebel = 'Select',
	hover = false
}) => {
	return (
		<Listbox
			disabled={disabled}
			className="w-full"
			value={selected}
			onChange={e => setSelected(e)}
		>
			<div className="relative w-full">
				<Listbox.Button
					className={`${className} group relative  w-full flex justify-start items-center cursor-pointer rounded-md bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
				>
					<span className="block truncate capitalize font-[400] text-gray-900 ">
						{options?.length === 0 || options === null ? (
							'No Records'
						) : (
							<>
								{Object.keys(selected)?.length
									? selected[keyname] || 'Select'
									: defaultSeletectLebel}
							</>
						)}
					</span>
					{hover && <div className="z-[2000] mt-1 min-h-[1.8rem]  py-1 px-4 right-0 shadow-xl min-w-max absolute top-5 scale-0 rounded bg-white border p-2 text-xs text-secondary group-hover:scale-100">
						<svg className="absolute text-white h-3 left-[50%] mr-3 rotate-180 -top-2.5" x="0px" y="0px" viewBox="0 0 255 255" space="preserve"><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
						<span>{
							Object.keys(selected)?.length
								? selected[keyname] || 'Select'
								: defaultSeletectLebel
						}</span>
					</div>}
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-5 h-5 text-gray-500"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19.5 8.25l-7.5 7.5-7.5-7.5"
							/>
						</svg>
					</span>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Listbox.Options className="z-10 absolute mt-1 max-h-[180px] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{options
							?.sort((a, b) =>
								a[keyname]?.localeCompare(b[keyname]),
							)
							?.map((option, optionIdx) => (
								<Listbox.Option
									key={
										mapkey === undefined
											? optionIdx
											: option[mapkey]
									}
									className={({ active }) =>
										`relative cursor-pointer select-none ${active
											? 'text-secondary'
											: 'text-secondary'
										}`
									}
									title={`${option[keyname]} ${option[extrakey] || ''}`}
									value={option}
								>
									{({ selected }) => (
										<>
											<span

												className={`py-2 pl-3 pr-4 block truncate ${selected
													? 'font-[500] text-secondary hover:text-secondary'
													: 'font-normal text-gray-600 hover:text-secondary'
													}`}
											>
												<>
													{option[keyname]}{' '}
													{option[extrakey] && staticLabel}
													{option[extrakey]}
												</>
											</span>
										</>
									)}
								</Listbox.Option>
							))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	)
}

export default SelectInput
