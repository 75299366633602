import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import SelectBusinessUnitHead from './selectBusinessUnitHead';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    setAccountSelect,
    accountSelect,
    canEdit,
    setCanEdit,
  } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);


  const { businessGroupList } = useSelector(
    (state) => state.UserManagementList
  );

  useEffect(() => {
    setDetailsData({
      ...detailsData,
    });
    setAccountSelect({
      comuserid: detailsData?.businessunitheadid,
      firstname: detailsData?.buhead,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAccountSelect]);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let items = {
      businessunitid: detailsData?.businessunitid,
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      businessunitgroupid: detailsData?.businessunitgroupid,
      businessunitheadid: accountSelect?.comuserid,
      businessunitname: detailsData?.businessunitname,
      businessunitcontactpersonid: detailsData?.businessunitcontactpersonid,
      isactiveflag: detailsData?.isactiveflag,
      notes: '',
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    };
    let isValidated = CheckObjectValidation(detailsData, ['businessunitcontactpersonid','modifiedcomuserid','notes']);
    if (isValidated?.isvalid) {

      let payload = {
        businessunitjson: [items],
      };
      detailSave(payload);
    };
  }
  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, businessunitheadid: select.comuserid });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='code' className='block font-[500] mb-2'>
            Business Unit Code
          </label>
          <input
            type='text'
            onChange={(e) => handleChangeInput('code', e.target.value)}
            title='code'
            id='code'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Business Unit Code'
            required=''
            maxLength={50}
            disabled
            value={detailsData?.code || ''}
          />
        </div>
        <div>
          <label htmlFor='businessunitname' className='block font-[500] mb-2'>
            Business Unit Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='businessunitname'
            id='businessunitname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Business Unit Name'
            required=''
            value={detailsData?.businessunitname || ''}
            onChange={(e) =>
              handleChangeInput('businessunitname', e.target.value)
            }
            maxLength={50}
          />
          {!detailsData?.businessunitname && checkvalidation && (
            <small className='text-red-400'>Business Unit Name is required!</small>
          )}
        </div>
        <div>
          <label
            htmlFor='businessunitgroupname'
            className='block font-[500] mb-2'
          >
            Business Unit Group
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={businessGroupList || []}
            value={detailsData?.businessunitgroupid || ''}
            noDataLabel='No Group'
            name='businessunitgroupid'
            labelkey='businessunitgroupname'
            className='border rounded-lg bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='businessunitgroupid'
          />
          {!detailsData?.businessunitgroupid && checkvalidation && (
            <small className='text-red-400'>Business Unit Group is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='businessunitheadid' className='block font-[500] mb-2'>
            Business Unit Head
            <span className="text-red-300"> *</span>
          </label>
          <div className='relative'>
            <SelectBusinessUnitHead
              accountSelect={accountSelect}
              setAccountSelect={setAccountSelect}
              handleSelectManager={handleSelectManager}
              canEdit={canEdit}
            />
            {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>Business Unit Head is required!</small>
            )}
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Details);
