import { memo } from "react";
import SingleSelectField from "src/Components/SingleSelectForTable";

const TaskGroup = ({ hooks }) => {
    const { value, disabled, handleGroupSelect, uId, taskGroups } = hooks

    const getSelected = (valueis) => {
        let e = { target: { name: 'taskgroupid', value: valueis['taskgroupid'], id: uId } }
        handleGroupSelect(e, uId)
    }

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='taskgroupid'
                labelKey='taskgroupdesc'
                options={taskGroups}
                value={value}
                handleSelected={getSelected}
                disabled={!disabled}
            />
        </div>
    )
}

export default memo(TaskGroup);
