import { useEffect, useState } from "react";
import Table from "./table";
import Toolbar from "./toolbar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GET_TASK_TIMESHEET_CONFIG, GET_TIMESHEET_TASK_TYPE, INSERT_TIMESHEET_EFFORT_TASK } from "src/Redux/actions/projects/timesheet";
import { getTaskTypePayload } from "src/Services/constant/defaultPayload";
import { getDifferenceFromCurrentDay } from "src/Services";

const getCurrentWeekDates = () => {
    const now = new Date()
    const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1))
    const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7))
    return {
        monday: moment(monday).format('YYYY-MM-DD'),
        sunday: moment(sunday).format('YYYY-MM-DD'),
    }
}


const TimesheetEmployee = ({ timesheetView, setTimesheetView }) => {
    const dispatch = useDispatch()
    const getTaskTimesheetConfig = useSelector(state => state?.ProjectTimesheet?.getTaskTimesheetConfig)
    const [weeks, setWeeks] = useState({
        monday: getCurrentWeekDates()?.monday,
        sunday: getCurrentWeekDates()?.sunday,
    })

    const fetchTimesheetData = async () => {
        let payload = {
            Tenantid: localStorage.getItem('Tenantid'),
            comuserid: localStorage.getItem('comuserid'),
            startdate: moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
            enddate: moment(new Date(weeks?.sunday)).format('YYYY-MM-DD')
        }
        await dispatch(GET_TASK_TIMESHEET_CONFIG(payload))
    }

    useEffect(() => {
        fetchTimesheetData()
        // eslint-disable-next-line 
    }, [weeks])

    useEffect(() => {
        dispatch(GET_TIMESHEET_TASK_TYPE(getTaskTypePayload()))
        // eslint-disable-next-line 
    }, [])


    // only show reopen button if status approved , month change and diffrence of day is <= 30
    const canDispalyReopen = (daterange, cellDate) => {
        let dayDifference = getDifferenceFromCurrentDay(new Date(daterange?.monday))
        let getMonth = Number(moment(cellDate).format('M'))
        let current = Number(moment(new Date()).format('M'))
        let apporedData  = getTaskTimesheetConfig?.timesheetdata?.find(it => it?.curstatus === 'Approved')
        if (apporedData !== undefined && dayDifference <= 30) {
            if (getMonth !== current) return true
            return false
        }
    }

    const handleReopen = async () => {
        let data = [...getTaskTimesheetConfig?.timesheetdata]
        let updatedEffort = data?.filter(it => it?.curstatus === 'Approved')?.map(row => {
            let item = { ...row }
            item['prevstatus'] = row?.curstatus
            item['statusname'] = 'Reopened'
            item?.timesheetdetails?.map(ef=>{
                ef['endtime'] = parseFloat(ef['endtime'])
                ef['starttime'] = parseFloat(ef['starttime'])
                return ef
            })
            return item

        })

        let payload = {
            timesheetjson: [
                {
                    comuserid: +localStorage.getItem("comuserid"),
                    createdcomuserid: +localStorage.getItem("comuserid"),
                    notes: "",
                    startdate: moment(weeks?.monday).format('YYYY-MM-DD'),
                    enddate: moment(weeks?.sunday).format('YYYY-MM-DD'),
                    isactiveflag: true,
                    comtenantid: +localStorage.getItem("Tenantid")
                }
            ],
            detailjson: updatedEffort,
        }
        let res = await dispatch(INSERT_TIMESHEET_EFFORT_TASK(payload))

        if (res) {
            fetchTimesheetData()
        }
    }

    return (
        <div className="bg-white shadow rounded-lg h-[calc(100%-100px)] text-sm  mt-2 p-2">
            <Toolbar weeks={weeks}
                handleReopen={handleReopen}
                canDispalyReopen={canDispalyReopen}
                setTimesheetView={setTimesheetView}
                timesheetView={timesheetView}
                setWeeks={setWeeks} />
            {getTaskTimesheetConfig === null ? <div className='flex items-center justify-center w-full h-1/2'>
                <div
                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            </div> : <Table weeks={weeks} fetchTimesheetData={fetchTimesheetData} />}
        </div>
    )
}

export default TimesheetEmployee;