import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_REPORT_MASTER_LIST, GET_TIMESHEET_TRACKING_REPORT } from 'src/Redux/actions/reports'
import { getDefaultStatusPayload, getStatusMasterIdData, getTenantCompanyIdData, getTenantWorkerTypeyIdData, getTenantWorkForceyIdData, getTimesheetTrackingReportPayload } from 'src/Services/constant/defaultPayload'
import TimesheetTrackingReportsDetails from './table'
import TrackingReportFilter from 'src/Filter/reports/workforce/timesheetTracking'
import { useLocation } from 'react-router-dom'
import { GET_ALLOCATION_STATUS_FOR_EMPLOYEE, GET_COMPANY_MASTER, GET_WORKERTYPE_MASTER, GET_WORKFORCEGROUP_MASTER } from 'src/Redux/actions/workforce/talent'
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects'

const TimesheetTrackingReports = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getTimesheetTrackingReportPayload())
    const dispatch = useDispatch()
    let { reportMasterList, timesheetTrackingReport } = useSelector((state) => state?.WorkforceReport)
    const [page, setPage] = useState(1)
    const [user, setUser] = useState({})
    const [limit, setLimit] = useState(10)
    const locationwindow = useLocation()
    const searchParams = new URLSearchParams(locationwindow.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    useEffect(() => {
        dispatch(GET_TIMESHEET_TRACKING_REPORT(getTimesheetTrackingReportPayload()))
        dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
        dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E')))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))


        // eslint-disable-next-line
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 text-sm bg-white rounded-md shadow">
                <TrackingReportFilter
                    filters={filters}
                    setFilters={setFilters}
                    reportId={reportId}
                    setReportId={setReportId}
                    page={page}
                    limit={limit}
                    user={user}
                    setUser={setUser}
                />
            </div>
            <TimesheetTrackingReportsDetails
                timesheetTrackingReports={timesheetTrackingReport}
                filters={filters}
                reportId={reportId}
                reportMasterList={reportMasterList}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(TimesheetTrackingReports)
