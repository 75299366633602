import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { GET_COUNTRY_MASTER } from 'src/Redux/actions/masterdata/user'
import { GET_COMPANY_MASTER, GET_WORKERTYPE_MASTER } from 'src/Redux/actions/workforce/talent'
import {
	getDefaultCompanyPayload,
	getMasterCountryPayload,
	getWorkTypePayload,
	WorkforceAllocationItem,
} from 'src/Services/constant/defaultPayload'
import FilterButtonTab from 'src/Components/FilterButtonTab'

const AnalyticsWorkforceFilter = ({ filters, setFilters }) => {
	const dispatch = useDispatch()
	const { countryList } = useSelector((state) => state?.UserManagementList)
	const { companylist, workertypelist } = useSelector((state) => state?.EmployeeDetails)
	const [tempFilters, setTempFilters] = useState(filters)

	useEffect(() => {
		dispatch(GET_COUNTRY_MASTER(getMasterCountryPayload()))
		dispatch(GET_COMPANY_MASTER(getDefaultCompanyPayload()))
		dispatch(GET_WORKERTYPE_MASTER(getWorkTypePayload()))
	}, [dispatch])

	useEffect(() => {
		setTempFilters(filters)
	}, [filters])

	const handleChangeInput = (name, value) => {
		setTempFilters((prev) => ({ ...prev, [name]: value }))
	}

	const handleStatusChange = (option) => {
		setTempFilters((prev) => ({ ...prev, status: option }))
	}

	const handleBillingChange = (option) => {
		setTempFilters((prev) => ({ ...prev, billable: option }))
	}

	const applyFilters = () => {
		const updatedFilters = {
			...tempFilters,
			startdate: tempFilters.startdate,
			enddate: tempFilters.enddate,
		}

		setFilters(updatedFilters)
	}

	const clearFilters = () => {
		// Preserve typeid, startdate, and enddate from current filters
		const { typeid, startdate, enddate } = tempFilters

		// Get default filters but override with preserved values
		const defaultFilters = {
			...WorkforceAllocationItem(),
			typeid,
			startdate,
			enddate,
		}

		// Update local state
		setTempFilters(defaultFilters)

		// Update parent state
		setFilters(defaultFilters)
	}

	return (
		<div className="grid grid-cols-2 gap-4 text-sm lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-1">
			<div>
				<h1 className="block font-[500] mb-2">Company</h1>
				<CustomhtmlSelect
					options={companylist || []}
					value={tempFilters.companyid || ''}
					noDataLabel="No Company"
					name="companyid"
					labelkey="companyname"
					className="py-2 border rounded bg-gray-50"
					onChange={handleChangeInput}
					id="companyid"
				/>
			</div>

			<div>
				<h1 className="block font-[500] mb-2">Country</h1>
				<CustomhtmlSelect
					options={countryList || []}
					value={tempFilters.countryid || ''}
					noDataLabel="No Country"
					name="countryid"
					labelkey="countryname"
					className="py-2 border rounded bg-gray-50"
					onChange={handleChangeInput}
					id="comcountryid"
				/>
			</div>

			<div>
				<h1 className="mb-2 font-[500]">Emp Type</h1>
				<CustomhtmlSelect
					options={workertypelist || []}
					value={tempFilters.emptypeid || ''}
					noDataLabel="No Type"
					name="emptypeid"
					labelkey="workertype"
					className="py-2 border rounded bg-gray-50"
					onChange={handleChangeInput}
					id="workertypeid"
				/>
			</div>

			<div>
				<h1 className="block font-[500] mb-2">Billable</h1>
				<FilterButtonTab
					buttonWidth="w-full"
					className="p-2 border"
					tabs={['Yes', 'No']}
					setSelectedtab={handleBillingChange}
					selectedTab={tempFilters.billable}
				/>
			</div>

			<div>
				<h1 className="block font-[500] mb-2">Status</h1>
				<FilterButtonTab
					buttonWidth="w-full"
					className="p-2 border"
					tabs={['Active', 'All']}
					setSelectedtab={handleStatusChange}
					selectedTab={tempFilters.status}
				/>
			</div>

			<div>
				<h1 className="mb-2 font-[500]">Action</h1>
				<div className="grid grid-cols-2 gap-2">
					<button
						onClick={clearFilters}
						className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
					>
						Clear
					</button>
					<button
						onClick={applyFilters}
						className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
					>
						Apply
					</button>
				</div>
			</div>
		</div>
	)
}

export default AnalyticsWorkforceFilter
