import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import MultiSelectItem from 'src/Components/MultiSelect'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import DemandsFilter from 'src/Filter/workforce/findDemands'
import { GET_STAFF_STATUS_TYPE_S } from 'src/Redux/actions/projects/staffing'
import { GET_DEMAND_LIST_FOR_TALENT, GET_STAFF_STATUS_TYPE_M } from 'src/Redux/actions/workforce/demands'
import { GET_SKILL_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
    GET_BAND_MASTER,
    GET_CITY_MASTER,
    GET_EMPLOYEE_DETAILS,
    GET_STAFF_STATUS_TYPE_W,
    GET_WORKERTYPE_MASTER,
    GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import {
    getBandMasterData,
    getDeafultSkillData,
    getDeafultTalentPayload,
    getDefaultCityPayload,
    getTenantWorkForceyIdData,
    getTenantWorkerTypeyIdData,
} from 'src/Services/constant/defaultPayload'
import { basicShortList } from 'src/Services/constant/staticValues'
import Cards from './card'
import { getDefaultDemandTalentPayload } from './defaultPayload'
import DemandsPopUpLayout from './demandPopUp'
const rows = [10, 25, 50]
const excludedStatusNames = ['Cancelled']

const FindOpportunity = ({ Employees, thisPagePermissions }) => {
    const dispatch = useDispatch()
    const { demandListTalent, getStaffStatusTypeM, getStaffStatusTypeS } = useSelector(
        (state) => state?.DemandDetailsList,
    )
    const { bandList, stauslistdemands } = useSelector((state) => state?.EmployeeDetails)
    const [primarySkill, setPrimarySkill] = useState([])
    const [workerType, setWorkerType] = useState([])
    const [workforceGroup, setWorkforceGroup] = useState([])
    const [minExp, setMinExp] = useState([])
    const [band, setBand] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [filters, setFilters] = useState(getDefaultDemandTalentPayload())
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const [demandId, setDemandId] = useState(null)
    const [selectedDemand, setSelectedDemand] = useState(null)
    const [matchStatus, setMatchStatus] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_BAND_MASTER(getBandMasterData()))
        dispatch(GET_EMPLOYEE_DETAILS(getDeafultTalentPayload()))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
        dispatch(GET_CITY_MASTER(getDefaultCityPayload()))
        dispatch(GET_STAFF_STATUS_TYPE_W())
        dispatch(GET_STAFF_STATUS_TYPE_M())
        dispatch(GET_STAFF_STATUS_TYPE_S())
    }, [dispatch])

    const getBandIDs = useCallback(() => {
        if (!bandList || bandList?.length === 0) {
            return { defaultMinBandID: undefined, defaultMaxBandID: undefined }
        }
        // Determine the band with the lowest slno
        const defaultMinBand = bandList?.reduce((min, band) => (band.slno < min.slno ? band : min), bandList[0])
        // Determine the band with the highest slno
        const defaultMaxBand = bandList?.reduce((max, band) => (band.slno > max.slno ? band : max), bandList[0])
        return {
            defaultMinBandID: defaultMinBand ? defaultMinBand.bandmasterid : 0,
            defaultMaxBandID: defaultMaxBand ? defaultMaxBand.bandmasterid : 0,
        }
    }, [bandList])

    const { defaultMinBandID, defaultMaxBandID } = getBandIDs()
    // console.log(stauslistdemands);
    const remainingStatusIds = useMemo(() => {
        const filteredStatuses = stauslistdemands?.filter((status) => !excludedStatusNames.includes(status.statusname))
        const ids = filteredStatuses?.map((status) => status.statusid)
        return `{${ids.join(',')}}`
        //eslint-disable-next-line
    }, [stauslistdemands])

    useEffect(
        () => {
            if (band?.length > 0 && remainingStatusIds !== '{}') {
                const initialBand = band[0]
                const bandmasterid = initialBand?.band
                const initialMinBandId = bandmasterid > 10 ? bandmasterid - 1 : 0
                const initialMaxBandId = bandmasterid >= 10 ? bandmasterid + 1 : 0
                const experienceYears = Math.floor((minExp[0]?.totalexperienceinmonths || 0) / 12)
                const payload = {
                    ...getDefaultDemandTalentPayload(),
                    skillid: primarySkill[0]?.primaryskillid || 0,
                    workertypeid: workerType[0]?.worker_type || 0,
                    workforcegroupid: workforceGroup[0]?.workforcegroupid || 0,
                    mintotalexperience: experienceYears || 0,
                    minbandid: initialMinBandId || defaultMinBandID,
                    maxbandid: initialMaxBandId || defaultMaxBandID,
                    statusid: remainingStatusIds,
                    userid: Employees[0]?.comuserid,
                }
                setFilters(payload)
                dispatch(GET_DEMAND_LIST_FOR_TALENT(payload))
            }
        },
        // eslint-disable-next-line
        [dispatch, getBandIDs, remainingStatusIds],
    )

    const handleSearchTermChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm?.trim())
        setPage(1)
    }

    const handleStatusSelect = (status) => {
        let updatedStatus
        if (status.statusid === '') {
            setMatchStatus([{ statusid: '', statusname: 'All' }])
        } else {
            if (matchStatus?.some((s) => s.statusid === status.statusid)) {
                updatedStatus = matchStatus?.filter((s) => s.statusid !== status.statusid)
            } else {
                updatedStatus = [...matchStatus?.filter((s) => s.statusid !== ''), status]
            }
            setMatchStatus(updatedStatus?.length > 0 ? updatedStatus : [{ statusid: '', statusname: 'All' }])
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            matchstatus: `{${updatedStatus?.map((s) => s.statusid).join(',')}}`,
        }))
    }

    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_DEMAND_LIST_FOR_TALENT({
                ...filters,
                Limit: limit,
                Page: page,
                Sorttype: item?.id,
            }),
        )
    }

    const handleRows = (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_DEMAND_LIST_FOR_TALENT({
                ...filters,
                Limit: row,
                Page: row > 10 ? 1 : page,
                Sorttype: sortType?.id,
            }),
        )
    }

    const handlePagination = (page) => {
        dispatch(
            GET_DEMAND_LIST_FOR_TALENT({
                ...filters,
                Limit: limit,
                Page: page?.selected + 1,
                Sorttype: sortType?.id,
            }),
        )
        setPage(page?.selected + 1)
    }

    const demandListCount = useMemo(() => {
        return demandListTalent?.length > 0 ? demandListTalent[0]?.totalcount || 0 : demandListTalent?.length || 0
    }, [demandListTalent])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setShowSearch(true)
        }, 1000)
        return () => {
            clearTimeout(debounceTimer)
        }
    }, [])

    return (
        <>
            <div className="sticky top-0 h-full bg-white rounded-md z-8 ">
                <DemandsFilter
                    hooks={{
                        filters,
                        setFilters,
                        Employees,
                        primarySkill,
                        setPrimarySkill,
                        workerType,
                        setWorkerType,
                        workforceGroup,
                        setWorkforceGroup,
                        minExp,
                        setMinExp,
                        setBand,
                        band,
                        getBandIDs,
                        remainingStatusIds,
                        page,
                        limit,
                        searchTerm,
                        setMatchStatus,
                    }}
                />
                <div className="grid items-center grid-cols-1 gap-4 mt-2 mb-2 lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-1">
                    <div className="col-span-2">
                        <span className="mb-2 text-sm font-medium whitespace-nowrap">Match Status</span>
                        <div className="p-0.5 border rounded bg-gray-50">
                            <MultiSelectItem
                                itemIdKey="statusid"
                                labelKeyname="statusname"
                                limitShow={1}
                                selectedOptions={matchStatus}
                                options={getStaffStatusTypeM}
                                handleSelection={handleStatusSelect}
                            />
                        </div>
                    </div>
                    <div className="col-span-4 mt-5">
                        {showSearch ? (
                            <CustomAutoSearch
                                hideOptions={true}
                                searchTerm={searchTerm}
                                API_CALL_FOR_SEARCH={GET_DEMAND_LIST_FOR_TALENT}
                                searchPayload={{ ...filters, demandcode: searchTerm }}
                                setSearchTerm={handleSearchTermChange}
                                options={demandListTalent || []}
                                idkey="uId"
                                bracketLabel="demandcode"
                                className="p-2 w-[342px]"
                                placeholder="Enter Demand Code"
                            />
                        ) : (
                            <div className="w-full h-8 bg-gray-200 rounded-md" />
                        )}
                    </div>
                    <div className="flex items-center justify-end col-span-6 mt-5 space-x-4">
                        <div className="w-22">
                            <ReactPaginationStyle
                                total={demandListCount}
                                limit={limit}
                                currentPage={page}
                                handlePagination={handlePagination}
                            />
                        </div>
                        <div className="w-26">
                            <FilterButtonTab selectedTab={limit} tabs={rows} setSelectedtab={handleRows} />
                        </div>
                        <div className="w-32">
                            <SelectInput
                                options={basicShortList || []}
                                keyname="label"
                                selected={sortType}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-scroll h-[58vh] ">
                {demandListTalent === null ? (
                    <div className="flex items-center justify-center w-full h-1/2">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                Loading...
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4 pb-2 mt-2 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 ">
                        {demandListTalent?.map((item, i) => {
                            return (
                                <Cards
                                    setIsOpen={setIsOpen}
                                    setDemandId={setDemandId}
                                    key={i}
                                    item={item}
                                    setSelectedDemand={setSelectedDemand}
                                    selectedDemand={selectedDemand}
                                />
                            )
                        })}
                        {isOpen && (
                            <DemandsPopUpLayout
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                demandId={demandId}
                                thisPagePermissions={thisPagePermissions}
                                getStaffStatusTypeM={getStaffStatusTypeM}
                                selectedDemand={selectedDemand}
                                setSelectedDemand={setSelectedDemand}
                                filters={filters}
                                Employees={Employees}
                                getStaffStatusTypeS={getStaffStatusTypeS}
                                page={page}
                                limit={limit}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default FindOpportunity
