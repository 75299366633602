import React, { useRef, useEffect } from 'react'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'

const WeekSelector = ({
    dataBySelectedDate,
    state,
    setWeeks,
    reset,
    onResetComplete,
}) => {
    const inputRef = useRef(null)

    useEffect(() => {
        const instance = flatpickr(inputRef.current, {
            // defaultDate: state.monday, // Set the default date
            dateFormat: 'd/m/Y', // Set the date format
            disable: [
                function (date) {
                    // Disable all days except Monday
                    return date.getDay() !== 1
                },
            ],
            onChange: (selectedDates) => {
                if (selectedDates.length === 0) {
                    // If no date is selected (input cleared), reset the field
                    inputRef.current.value = ''
                    setWeeks(null) // Reset the weeks state if necessary
                } else {
                    let now = selectedDates[0]
                    const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1))
                    const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7))
                    let range = { monday, sunday }
                    dataBySelectedDate(range)
                    setWeeks(range)
                }
            },
        })

        return () => {
            instance.destroy() // Destroy the Flatpickr instance on component unmount
        }
        // eslint-disable-next-line
    }, [dataBySelectedDate])

    useEffect(() => {
        if (reset) {
            inputRef.current.value = '' // Clear the input field
            onResetComplete()
        }
    }, [onResetComplete, reset])

    return (
        <div className="relative">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 pointer-events-none right-3 top-1/2"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
            </svg>
            <input
                type="text"
                ref={inputRef}
                placeholder="Select a week start date"
                className="w-full py-2 pl-2 pr-8 text-gray-600 border border-b rounded bg-inherit focus:outline-none bg-gray-50"
            />
        </div>
    )
}

export default WeekSelector
