import { GET_PROJECT_DETAIL_TIMELINE } from 'src/Redux/actions/projects'
import TimelineChart from './timeline'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const SummaryTimeline = () => {
    const dispatch = useDispatch()
    let [data, setData] = useState([])
    const projectBytimeline = useSelector(state => state?.Projects?.projectBytimeline)
    const params = new URLSearchParams(window.location.search)
	const projectid = params.get('projectid')

    useEffect(() => {
        let payload = {
            Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            projectid: projectid
        }
        if (projectid) {
            dispatch(GET_PROJECT_DETAIL_TIMELINE(payload))
        }
        // eslint-disable-next-line
    }, [projectid])

    useEffect(() => {
        if (projectBytimeline) {
            let plan = []
            let actual = []
            for (let timeline of projectBytimeline) {
                let a = { task:timeline?.taskname, effort: timeline?.actualhours, x: `${timeline?.phasenmae}: ${timeline?.taskname}`, y: [new Date(timeline?.actualstartdate || timeline?.plannedstartdate).getTime(), new Date(timeline?.actualenddate || timeline?.plannedstartdate).getTime()] }
                let p = { task:timeline?.taskname, effort: timeline?.plannedhours, x: `${timeline?.phasenmae}: ${timeline?.taskname}`, y: [new Date(timeline?.plannedstartdate).getTime(), new Date(timeline?.plannedenddate).getTime()] }
                plan.push(p)
                actual.push(a)
            }
            setData([{
                name: 'Plan',
                data: plan
            }
            , {
                name: 'Actual',
                data: actual
            }
        ])
        }

    }, [projectBytimeline])

    return <>
        <br />
        {data?.length > 0 && <TimelineChart seriesData={data} />}
    </>

}

export default SummaryTimeline