import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState } from 'react'
import FlatpickerDateInput from 'src/Components/FlatpickerDateInput';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from './default';


const Details = props => {
   const { canEdit, setDetailsData, detailsData, detailSave, setCanEdit } = props
   const [checkvalidation, setCheckvalidation] = useState(false);

   const getTodayDate = () => {
      const today = new Date()
      const year = today.getFullYear()
      let month = today.getMonth() + 1
      let day = today.getDate() + 1
      // Ensure month and day have two digits
      month = month < 10 ? `0${month}` : month
      day = day < 10 ? `0${day}` : day
      return `${year}-${month}-${day}`
   }
   const handleSubmit = () => {
      setCheckvalidation(true);

      let item = {
         comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
         announcementid: detailsData?.announcementid,
         announcementtext: detailsData?.announcementtext,
         announcementlink: detailsData?.announcementlink,
         isactiveflag: detailsData?.isactiveflag,
         createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
         modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
         notes: detailsData?.notes,
         sequence: parseInt(detailsData?.sequence),
         expirydate: detailsData?.expirydate,
      }
      let isValidated = CheckObjectValidation(item, detailNotRequired)
      if (isValidated?.isvalid) {

         let payload = {
            announcementsjson: [item],
         }
         detailSave(payload)
      }
   }

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);
      setDetailsData({ ...detailsData, [name]: value })
   }


   return (
      <div className='w-full h-[65vh] text-sm mb-10'>
         <div className='mt-3'>
            <label htmlFor='announcementtext' className='block font-[500] mb-2'>
               Announcement Text
               <span className="text-red-300"> *</span>
            </label>
            <input
               disabled={canEdit}
               type='text'
               title='announcementtext'
               id='announcementtext'
               className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
               placeholder='Announcement Text'
               required=''
               onChange={(e) => {
                  if (/^[A-Za-z0-9._-\s]*$/.test(e.target?.value)) {
                     handleChangeInput('announcementtext', e.target.value)
                  }
               }}
               value={detailsData?.announcementtext || ''}
               maxLength={100}
            />
            {!detailsData?.announcementtext && checkvalidation && (
               <small className='text-red-400'>Announcement Text is required!</small>
            )}
         </div>
         <div className='grid grid-cols-4 gap-10 mt-4'>
            <div>
               <label htmlFor='announcementlink' className='block font-[500] mb-2'>
                  Announcement Link
               </label>
               <input
                  disabled={canEdit}
                  type='text'
                  title='announcementlink'
                  id='announcementlink'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  placeholder='Announcement Link'
                  required=''
                  onChange={(e) => {
                     if (/^[A-Za-z0-9\s:/._-]*$/.test(e.target?.value)) {
                        handleChangeInput('announcementlink', e.target.value)
                     }
                  }}
                  value={detailsData?.announcementlink || ''}
                  maxLength={50}
               />
            </div>
            <div>
               <label htmlFor='sequence' className='block font-[500] mb-2'>
                  Sequence
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  disabled={canEdit}
                  value={detailsData?.sequence || ''}
                  type='number'
                  title='sequence'
                  id='sequence'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
                  placeholder='Enter Sequence'
                  required=''
                  onChange={(e) => {
                     handleChangeInput('sequence', e.target.value)
                  }}
               />
               {!detailsData?.sequence && checkvalidation && (
                  <small className='text-red-400'> Sequence is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='expirydate' className='block font-[500] mb-2'>
                  Expiry Date
                  <span className="text-red-300"> *</span>
               </label>
               <FlatpickerDateInput
                  disabled={(canEdit || detailsData?.expirydate > 'min')}
                  value={detailsData?.expirydate || ''}
                  min={getTodayDate()}
                  name='expirydate'
                  id='expirydate'
                  className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-2'
                  onChangeDate={e => handleChangeInput('expirydate', e.target.value)}
               />
               {!detailsData?.expirydate && checkvalidation && (
                  <small className='text-red-400'>Expiry Date is required!</small>
               )}
            </div>
         </div>
         <div className='fixed bottom-0 right-6 mt-2'>
            {!canEdit && (
               <div className='fixed bottom-0 right-6 mt-2'>
                  <div className='gap-2 flex justify-end items-center'>
                     <button
                        onClick={() => setCanEdit(false)}
                        type=''
                        className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                     >
                        Cancel
                     </button>
                     <PrimaryButton
                        disabled={canEdit}
                        className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                        onClick={handleSubmit}
                     >
                        Save
                     </PrimaryButton>
                  </div>
               </div>
            )}
         </div>
      </div>
   )
}

export default memo(Details)
