  import React from 'react';
  import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';

  const RadialPieChart = ({ title, value, color }) => {
    const data = [
      { name: title, value: value, fillColor: color },
      { name: 'Remaining', value: 100 - value, fillColor: 'transparent' },
    ];

    return (
      <div className='flex flex-col items-center justify-center h-full'>
        <h3 className='text-xs font-semibold'>{title}</h3>
        <ResponsiveContainer width='50%' height={160}>
          <PieChart>
            <Pie
              data={data}
              cx='50%'
              cy='50%'
              startAngle={90}
              endAngle={-270}
              innerRadius={40}
              outerRadius={45}
              dataKey='value'
              blendStroke
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fillColor} />
              ))}
              <Label
                value={value}
                position='center'
                fill='black'
                fontSize='20'
                fontWeight='bold'
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  export default RadialPieChart;
