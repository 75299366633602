import moment from 'moment'

export const getDefaultLeavePayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0,
        userleavedataid: 0,
        leavetypeid: 0,
        yearmonth: Number(moment().format('YYYYMM')),
        limit: 10,
        page: 1,
        sorttype: 3,
        Searchname: '',
        status: '',
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
    }
}

export const getLeaveTypePayload = (limit = 25, page = 1, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        leavetypeid: 0,
        limit,
        page,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}
