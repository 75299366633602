export const getDefaultRevenuePayload = (
    Projectid = 1,
    Limit = 10,
    Page = 1,
    Sorttype = 3,
) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Projectid,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        Limit,
        Page,
        Sorttype,
        Searchname: '',
        Profitcentercode: '',
    }
}
