import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState, useEffect } from 'react'
import SelectAccountManager from './selectAccountManager';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = props => {
	const { detailSave, detailsData, setDetailsData, canEdit, accountSelect, setAccountSelect, setCanEdit } = props
	const [checkvalidation, setCheckvalidation] = useState(false);

	useEffect(() => {
		setDetailsData({
			...detailsData,
		})
		setAccountSelect({
			comuserid: detailsData?.accountmanagerid,
			firstname: detailsData?.accountmanagername
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setAccountSelect])

	const handleSubmit = async e => {
		e.preventDefault()
		setCheckvalidation(true)
		let items = {
			comtenantid: Number(localStorage.getItem('Tenantid') || 0),
			customergroupname: detailsData?.customergroupname,
			accountmanagerid: accountSelect?.comuserid,
			contactpersonid: detailsData?.contactpersonid,
			createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
			modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
			isactiveflag: detailsData?.isactiveflag,
			notes: detailsData?.notes,
			customergroupid: detailsData?.customergroupid
		}

		let isValidated = CheckObjectValidation(detailsData, [
			'notes',
			'contactpersonid',
			'modifiedcomuserid',
			'createdcomuserid',
			'customerid',
			"accountmanagerid",
			"modifiedtimestamp"
		])
		if (isValidated?.isvalid) {
			let payload = {
				customerjson: [items],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false)
		setDetailsData({ ...detailsData, [name]: value })
	}

	const handleSelectManager = (select) => {
		setAccountSelect(select);
		setDetailsData({ ...detailsData, accountmanagerid: select.comuserid });
		setCheckvalidation(false);
	};

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='customergroupname' className='block font-[500] mb-2'>
						Customer Group Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						disabled={canEdit}
						type='text'
						title='customergroupname'
						id='customergroupname'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
						placeholder='Customer Group Name'
						required=''
						value={detailsData?.customergroupname || ''}
						onChange={e => handleChangeInput('customergroupname', e.target.value)}
						maxLength={50}
					/>
					{!detailsData?.customergroupid && checkvalidation && (
						<small className='text-red-400'>Customer Group Name is required!</small>
					)}
				</div>
				<div>
					<label
						htmlFor='accountmanagername'
						className='block font-[500] mb-2'
					>
						Account Manager Name
						<span className="text-red-300"> *</span>
					</label>
					<div className="relative">
						<SelectAccountManager
							accountSelect={accountSelect}
							handleSelectManager={handleSelectManager}
							setAccountSelect={setAccountSelect}
							canEdit={canEdit} />
						{!accountSelect?.comuserid && checkvalidation && (
							<small className='text-red-400'>Account Manager Name is required!</small>
						)}
					</div>
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				{!canEdit && (
					<div className='fixed bottom-0 right-6 mt-2'>
						<div className='gap-2 flex justify-end items-center'>
							<button
								onClick={() => setCanEdit(false)}
								type=''
								className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
							>
								Cancel
							</button>
							<PrimaryButton
								disabled={canEdit}
								className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
								onClick={handleSubmit}
							>
								Save
							</PrimaryButton>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(Details)