import { memo, useMemo } from 'react'
import TimeSelect from './timeSelect'
import { useSelector } from 'react-redux'

const EffortInput = (props) => {
    let { handleOnchange, item, disabled, holidayActive, row,
        hooks, rowId, isOnLeave, selectedTimetype, effortIndex } = props
    let timeformat = selectedTimetype?.timeentryformat
    const getTaskTimesheetConfig = useSelector(state => state?.ProjectTimesheet?.getTaskTimesheetConfig)
    let currentCellLeave = getTaskTimesheetConfig?.leavedetails_var?.find(l => l?.leavedate === item?.effortdate)
    let leaveActive = isOnLeave ? (currentCellLeave?.Noofdays < 1 ? false : isOnLeave) : isOnLeave

    const weekendentryallowed = useMemo(() => {
        if ((effortIndex === 6 || effortIndex === 5)) {
            return selectedTimetype?.weekendentryallowed
        }
        // eslint-disable-next-line
    }, [selectedTimetype, effortIndex, row?.timetypeid])

    const weekdayentryallowed = useMemo(() => {
        if (effortIndex <= 4) {
            return selectedTimetype?.weekdayentryallowed
        }
        // eslint-disable-next-line
    }, [selectedTimetype, effortIndex, row?.timetypeid])

    const renderWeekDayCalender = useMemo(() => {
        if ((holidayActive || leaveActive) && selectedTimetype?.holidayallowed) {
            return <TimeSelect
                effortdate={item?.effortdate}
                hooks={{ ...hooks, rowId, row, item }} />
        } else if (!holidayActive && !leaveActive) {
            return <TimeSelect
                effortdate={item?.effortdate}
                hooks={{ ...hooks, rowId, row, item }} />
        } else if (!leaveActive && !holidayActive) {
            return <TimeSelect
                effortdate={item?.effortdate}
                hooks={{ ...hooks, rowId, row, item }} />
        }
        // eslint-disable-next-line
    }, [selectedTimetype, holidayActive, hooks])

    return (
        <span className="flex justify-start items-center text-primary/40 space-x-2">
            {holidayActive && <span className="h-4 w-[3px] rounded-md  bg-blue-500" />}
            {isOnLeave && <span className="h-4 w-[3px] rounded-md  bg-gray-500" />}
            {timeformat !== 'Duration' ? <>
                {weekendentryallowed && <input
                    disabled={leaveActive || disabled}
                    defaultValue={item?.efforthours}
                    name={item?.effortdate}
                    id={item?.effortdate}
                    autoComplete="false"
                    onChange={handleOnchange}
                    className={`border border-primary/40 disabled:border-0 outline-none w-9 rounded  text-gray-700 pl-0.5 `} />}
                {weekdayentryallowed && <input
                    disabled={leaveActive || disabled}
                    defaultValue={item?.efforthours}
                    name={item?.effortdate}
                    id={item?.effortdate}
                    autoComplete="false"
                    onChange={handleOnchange}
                    className={`border border-primary/40 disabled:border-0 outline-none w-9 rounded  text-gray-700 pl-0.5 `} />}
            </> : <>
                {/* SHOW  INPUT IF WEEK_DAY OR WEEK_END IS TRUE AND ALSO CHECK CONDITION IF HOLIDAY AND LEAVE IS FALE */}
                {weekdayentryallowed ? <span className='w-full'>
                    {renderWeekDayCalender}
                </span> : ''}

                {weekendentryallowed ? <span className='w-full'>
                    {renderWeekDayCalender}
                </span> : ''}
            </>}
        </span>
    )
}

export default memo(EffortInput);