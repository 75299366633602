import { postMethodApiCall } from "src/Services/api"
import {
    AlertError, AlertSuccess,
    //  AlertSuccess
} from "src/Services";
import axios from "axios";
import {
    ACTION_GET_PROJECTS,
    ACTION_GET_PROJECTS_BY_USER,
    ACTION_GET_PROJECTS_BY_ID,
    ACTION_GET_CUSTOMER_LIST,
    ACTION_GET_LIST_TYPE_OF_PROJECT,
    ACTION_GET_LIST_COMPANY,
    ACTION_GET_PROJECT_LIST_BILLING_TYPE,
    ACTION_GET_LIST_OF_STATUS,
    ACTION_GET_SERVICE_LINE_MASTER_LIST,
    ACTION_GET_BUSINESS_UNIT_MASTER_LIST,
    ACTION_GET_CURRENCY_MASTER_LIST,
    ACTION_GET_OPPORTUNITY_LIST,
    ACTION_GET_TECHNOLOGY_LIST,
    ACTION_GET_PRODUCT_LIST,
    ACTION_GET_USER_MASTER_LIST,
    ACTION_GET_PROJECT_DOCUMENT_LIST,
    ACTION_GET_PROJECT_DOCUMENT_TYPE_LIST,
    ACTION_GET_BUDGET_ESTIMATION,
    ACTION_GET_PROJECT_DETAIL_TIMELINE
    // ACTION_CREATE_NEW_PROJECT,
    // ACTION_SAVE_PROJECT_DOCUMENT,
    // ACTION_DELETE_PROJECT_DOCUMENT,
    // ACTION_DOWNLOAD_PROJECT_DOCUMENT,
} from "src/Redux/types";

const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem("comuserid"))
    }
}

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
}

export const GET_PROJECTS = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/getlist_projectmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Projects found!')
                dispatch({ type: ACTION_GET_PROJECTS, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_PROJECT_DETAIL_TIMELINE = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/get_projecttimeline`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Project timeline found!')
                dispatch({ type: ACTION_GET_PROJECT_DETAIL_TIMELINE, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}


export const GET_PROJECTS_BY_USER = (data) => {
    // console.log(data)
    return async dispatch => {
        try {
            data['userid'] = getTanAndComId().comuserid
            const url = `${baseUrl}/getlist_projectmaster_forauser`
            dispatch({ type: ACTION_GET_PROJECTS_BY_USER, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No Projects found!')
                dispatch({ type: ACTION_GET_PROJECTS_BY_USER, data: addnewkey || [] })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_PROJECTS_BY_ID = (projectid, technologymasterid = 0) => {
    let payload = {
        Tenantid: getTanAndComId()?.Tenantid,
        projectid,
        // limit: 25,
        // page: 1,
        sorttype: 1,
        searchname: '',
        technologymasterid
    }
    return async dispatch => {
        try {
            const url = `${baseUrl}/get_projectmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Project found!')
                // AlertSuccess('Added Successfully !')
                dispatch({ type: ACTION_GET_PROJECTS_BY_ID, data: items[0] || null })
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, "")
        }
    }
}

export const CREATE_NEW_PROJECT = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/insert_projectmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
                return { success: true, data: res?.data?.data }
            }
            return false
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_CUSTOMER_LIST = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/get_customermasterList`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Customer found !')
                dispatch({ type: ACTION_GET_CUSTOMER_LIST, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_LIST_TYPE_OF_PROJECT = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrlMaster}/get_projecttypemaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No project type found !')
                dispatch({ type: ACTION_GET_LIST_TYPE_OF_PROJECT, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_LIST_COMPANY = (data) => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || '',
                status: 'ACTIVE'
            }
            const url = `${baseUrlMaster}/get_companymaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Company found !')
                dispatch({ type: ACTION_GET_LIST_COMPANY, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_PROJECT_LIST_BILLING_TYPE = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrlMaster}/get_datprojectbilltype`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Billing type found !')
                dispatch({ type: ACTION_GET_PROJECT_LIST_BILLING_TYPE, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}


export const GET_LIST_OF_STATUS = (data) => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || ''
            }
            const url = `${baseUrl}/getstatusmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Status type found !')
                dispatch({ type: ACTION_GET_LIST_OF_STATUS, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_SERVICE_LINE_MASTER_LIST = (data) => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || ''
            }
            const url = `${baseUrlMaster}/get_slmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Service Line found !')
                dispatch({ type: ACTION_GET_SERVICE_LINE_MASTER_LIST, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}


export const GET_BUSINESS_UNIT_MASTER_LIST = (data) => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || ''
            }
            const url = `${baseUrlMaster}/get_bumaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Buiness unit found !')
                dispatch({ type: ACTION_GET_BUSINESS_UNIT_MASTER_LIST, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_CURRENCY_MASTER_LIST = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrlMaster}/get_datcurrency`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Currency found !')
                dispatch({ type: ACTION_GET_CURRENCY_MASTER_LIST, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_OPPORTUNITY_LIST = (data) => {
    return async dispatch => {
        try {

            const url = `${baseUrl}/get_opportunity`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No Opportunity found !')
                dispatch({ type: ACTION_GET_OPPORTUNITY_LIST, data: addnewkey || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}


export const GET_TECHNOLOGY_LIST = (data) => {
    return async dispatch => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || ''
            }
            const url = `${baseUrlMaster}/get_technologymaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Technology found !')
                dispatch({ type: ACTION_GET_TECHNOLOGY_LIST, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_PRODUCT_LIST = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrlMaster}/get_productmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Product found !')
                dispatch({ type: ACTION_GET_PRODUCT_LIST, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_USER_MASTER_LIST = (data) => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/getuserlist`
            let payload = { ...data }
            if (typeof data?.empstatus === "number") {
                payload['empstatus'] = `{${data?.empstatus}}`
            }
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_USER_MASTER_LIST,
                    data: addnewkey || [],
                })
                return addnewkey
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const SAVE_PROJECT_DOCUMENT = (data) => {
    return async () => {
        try {
            let newdataFrame = []
            for (let item of data) {
                let contact = { ...item }
                delete contact['uId']
                newdataFrame.push(contact)
            }

            const url = `${baseUrl}/insert_projectdocuments`
            let res = await postMethodApiCall(url, getHeadersWithoutAuth(), newdataFrame)
            let item = res?.data?.data
            if (res?.status === 200 || res?.status === 201) {
                const customerid = item?.customerid;
                return { success: true, customerid };
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '');
            return { success: false };
        }
    };
}

export const GET_PROJECT_DOCUMENT_LIST = (projectid) => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
                projectid,
                projectdocumentattachmentsid: 0
            }
            const url = `${baseUrl}/get_projectdocuments`;
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);

            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_PROJECT_DOCUMENT_LIST,
                    data: addnewkey || []
                },
                );
            }
        } catch (error) {
            console.log(error)
            AlertError(error.response.data).replace(/\\/g, '');
        }
    };
};

export const DELETE_PROJECT_DOCUMENT = (id) => {
    return async () => {
        try {
            let payload = {
                projectdocumentattachmentsid: id,
                deletedcomuserid: Number(localStorage.getItem('comuserid') || 0),
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/delete_projectdocuments`
            let res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            let item = res?.data?.data
            if (res?.status === 200 || res?.status === 201) {
                const customerid = item?.customerid;
                AlertSuccess('Document Deleted successfully!');
                return { success: true, customerid };
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '');
            return { success: false };
        }
    };
}

export const DOWNLOAD_PROJECT_DOCUMENT = (file_path) => {
    return async () => {
        try {
            let payload = {
                file_path,
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/Download_s3documentsDirect`
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                },
                responseType: 'arraybuffer',
                data: payload
            };

            let res = await axios.request(config)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, res };
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '');
            return { success: false };
        }
    };
}

export const GET_PROJECT_DOCUMENT_TYPE_LIST = () => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
                documenttypeid: 0,
                Type: "P"
            }
            const url = `${baseUrlMaster}/get_documenttypemaster`;
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);

            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_PROJECT_DOCUMENT_TYPE_LIST,
                    data: addnewkey || []
                },
                );
            }
        } catch (error) {
            AlertError(error.response.data).replace(/\\/g, '');
        }
    };
};


export const GET_BUDGET_ESTIMATION = (projectid) => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
                projectid,
            }
            const url = `${baseUrl}/getEstimatedetailsforaproject`;
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);

            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data?.length > 0 ? res?.data?.data[0] : {}

                dispatch({
                    type: ACTION_GET_BUDGET_ESTIMATION,
                    data: items || []
                },
                );
            }
        } catch (error) {
            AlertError(error.response.data).replace(/\\/g, '');
        }
    };
};
