import { Tab } from '@headlessui/react'
import AnalyticProjects from './projects'
import AnalyticPlanning from './planning'
import AnalyticStaffing from './staffing'
import AnalyticEffort from './effort'
import NoPermissionComponent from 'src/Components/NoPermission'

const tabs = ['Projects', 'Planning', 'Staffing', 'Effort']

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Tabs = ({ thisPagePermissions }) => {
    return (
        <div className="relative w-full">
            <Tab.Group>
                <Tab.List className="flex justify-start w-full p-1 space-x-1 border-b border-secondary">
                    {tabs?.map((item, i) => {
                        return (
                            <Tab
                                key={i}
                                className={({ selected }) =>
                                    classNames(
                                        'py-2 text-sm font-[500] outline-white',
                                        'ring-white ring-opacity-60 ring-offset-0 ring-offset-white focus:outline-none focus:ring-0',
                                        selected
                                            ? 'bg-secondary/10 text-secondary font-[500] p-1.5 rounded-md'
                                            : 'text-gray-500',
                                    )
                                }
                            >
                                <span className="px-4"> {item}</span>
                            </Tab>
                        )
                    })}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? (
                            <AnalyticProjects />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? (
                            <AnalyticPlanning />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? (
                            <AnalyticStaffing />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? (
                            <AnalyticEffort />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default Tabs
