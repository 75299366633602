import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from './default';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';


const Details = (props) => {
  const [city, setCity] = useState({})
  const { cityList } = useSelector((state) => state.UserManagementList);
  const { detailSave, detailsData, setDetailsData } = props;

  const [checkvalidation, setCheckvalidation] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')

  const getTodayDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()
    // Ensure month and day have two digits
    month = month < 10 ? `0${month}` : month
    day = day < 10 ? `0${day}` : day
    return `${year}-${month}-${day}`
  }


  useMemo(() => {
    if (city?.cityname) {
      setSearchTerm(city?.cityname)
    }
  }, [city])

  const handleSubmit = () => {
    setCheckvalidation(true);

    let item = {
      ...detailsData,
      comcityid: city?.comcityid
    };

    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        companyjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='companycode' className='block font-[500] mb-2'>
            Company Code
          </label>
          <input
            maxLength={50}
            disabled
            type='text'
            title='companycode'
            id='companycode'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Company Code'
            required=''
            onChange={(e) => handleChangeInput('companycode', e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='companyname'
            id='companyid'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Company Name'
            maxLength={50}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('companyname', e.target.value);
              }
            }}
          />
          {!detailsData?.companyname && checkvalidation && (
            <small className='text-red-400'>Company Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='incorporationdate' className='block font-[500] mb-2'>
            Incorporation Date
            <span className="text-red-300"> *</span>
          </label>
          <FlatPickrDateInput
            disabled={detailsData?.incorporationdate > 'max'}
            value={detailsData?.incorporationdate || ''}
            max={getTodayDate()}
            name='incorporationdate'
            id='incorporationdate'
            className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
            onChangeDate={e => handleChangeInput('incorporationdate', e.target.value)}
          />
          {!detailsData?.incorporationdate && checkvalidation && (
            <small className='text-red-400'>Incorporation Date is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='address3' className='block font-[500] mb-2'>
            Address Line 1
            <span className="text-red-300"> *</span>
          </label>
          <input
            maxLength={50}
            type='text'
            title='address3'
            id='address3'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Address Line 1'
            required=''
            onChange={(e) => handleChangeInput('address3', e.target.value)}
          />
          {!detailsData?.address3 && checkvalidation && (
            <small className='text-red-400'>Address Line is required!</small>
          )}
        </div>

        <div>
          <label htmlFor='address_street' className='block font-[500] mb-2'>
            Address Line 2
            <span className="text-red-300"> *</span>
          </label>
          <input
            maxLength={50}
            type='text'
            title='address_street'
            id='address_street'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Address Street'
            required=''
            onChange={(e) => handleChangeInput('address_street', e.target.value)}
          />
          {!detailsData?.address_street && checkvalidation && (
            <small className='text-red-400'>Address Line is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='comcityid' className='block font-[500] mb-2'>
            City
            <span className="text-red-300"> *</span>
          </label>
          <CustomAutoSearch
            searchTerm={searchTerm}
            API_CALL_FOR_SEARCH={GET_CITY_MASTER}
            searchPayload={{ ...getMasterCityPayload(), searchname: searchTerm, }}
            setSearchTerm={setSearchTerm}
            options={cityList}
            idkey='cityname'
            optionlabelKey='cityname'
            getSelectedOption={setCity}
            placeholder='Enter City Name'
            className='block w-full p-2 text-gray-600 border border-gray-200 rounded-lg bg-gray-50 focus:ring-primary-600 focus:border-primary-600'
          />
          {!city?.comcityid && checkvalidation && (
            <small className='text-red-400'>City is required!</small>
          )}
        </div>

        <div>
          <label htmlFor='pincode' className='block font-[500] mb-2'>
            Pincode
          </label>
          <input
            type='text'
            title='pincode'
            id='pincode'
            className='block w-full p-2 text-gray-600 border border-gray-200 rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
            placeholder='Pincode'
            required=''
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target?.value)) {
                handleChangeInput('pincode', e.target.value);
              }
            }}
          />
          {!detailsData?.pincode}
        </div>
        <div>
          <label htmlFor='phoneno' className='block font-[500] mb-2'>
            Phone
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='tel'
            title='phoneno'
            id='phoneno'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Phone No'
            required=''
            onChange={(e) => handleChangeInput('phoneno', e.target.value)}
          />
          {!detailsData?.phoneno && checkvalidation && (
            <small className='text-red-400'>Phone is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='fax' className='block font-[500] mb-2'>
            Fax
          </label>
          <input
            type='text'
            title='fax'
            id='fax'
            className='block w-full p-2 text-gray-600 border border-gray-200 rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
            placeholder='Fax'
            required=''
            onChange={(e) => handleChangeInput('fax', e.target.value)}
          />
        </div>
        <div className='fixed bottom-0 mt-2 right-6'>
          <div className='flex items-center justify-end gap-2'>
            <PrimaryButton
              className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
