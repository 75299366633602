import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo,useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const EditDetails = (props) => {
  const { canEdit, setDetailsData, detailsData, detailSave, setCanEdit } = props
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let item = {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      bandname: detailsData?.bandname,
      bandmasterid: detailsData?.bandmasterid,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
      slno: detailsData?.slno
    }
    let isValidated = CheckObjectValidation(detailsData, ['modifiedcomuserid','notes'])
    if (isValidated?.isvalid) {

      let payload = {
        bandjson: [item]
      }
      detailSave(payload)
    };
  }
  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value })
  }

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>

        <div>
          <label htmlFor='bandname' className='block font-[500] mb-2'>
            Band
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='bandname'
            id='bandname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Bandname'
            required=''
            onChange={(e) => handleChangeInput('bandname', e.target.value)}
            value={detailsData?.bandname || ''}
          />
          {!detailsData?.bandname && checkvalidation && (
            <small className='text-red-400'>Bandname is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='slno' className='block font-[500] mb-2'>
            Serial No
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='number'
            title='slno'
            id='slno'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Serial Number'
            required=''
            onChange={(e) => {
              handleChangeInput('slno', e.target.value)
            }}
            value={detailsData?.slno || ''}
          />
          {!detailsData?.slno && checkvalidation && (
            <small className='text-red-400'>Serial Number is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                // disabled={!canEdit}
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(EditDetails);
