import { Fragment, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { detailItem, subscriptionItem } from './defaultRowAndData';
import Details from './details';
import SubscriptionDetails from './subscription/index'
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { GET_SUBSCRIPTIONS_MASTER, GET_TENANTS_MASTER } from 'src/Redux/actions/subscriptions/index';
import { SAVE_TENANTS_MASTER, SAVE_SUBSCRIPTION_DETAILS } from 'src/Redux/actions/subscriptions';
import { GET_CURRENCY_MASTER_LIST } from 'src/Redux/actions/projects'
import { getDefaultCurrencyPayload, getTenantsPayload, getSubscriptionsPayload } from 'src/Services/constant/defaultPayload';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';


const tabslist = ['Tenant Details', 'Subscription Details'];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateTenant = () => {
  const [detailsData, setDetailsData] = useState(detailItem());
  const [subscriptionData, setSubscriptionData] = useState(subscriptionItem());
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [activeTabs, setActiveTabs] = useState(['Tenant Details']);
  const [tenantid, setComtenantId] = useState(null);

  const loadFormApis = () => {
    dispatch(GET_CITY_MASTER(getMasterCityPayload()));
    dispatch(GET_SUBSCRIPTIONS_MASTER(getSubscriptionsPayload()));
    dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()));
  };

  function closeModal() {
    setIsOpen(false);
    setDetailsData(detailItem());
    setSubscriptionData(subscriptionItem());
    setActiveTabs(['Tenant Details']);
  }

  function openModal() {
    setIsOpen(true);
    loadFormApis();
  }

  async function detailSave(payload) {
    try {
      let res = await dispatch(SAVE_TENANTS_MASTER(payload));
      if (res?.success) {
        setComtenantId(res?.tenantid);
        setActiveTabs([...activeTabs, 'Subscription Details']);
        AlertSuccess('Tenant saved successfully.');
        dispatch(GET_TENANTS_MASTER(getTenantsPayload()));
      }
    } catch (error) {
      console.error('Error saving tenant details:', error);
    }
  }

  const subscriptionSave = async (payload) => {
    try {
      let res = await dispatch(SAVE_SUBSCRIPTION_DETAILS(payload, tenantid));
      if (res?.success) {
        AlertSuccess('Subscription Details saved successfully!');
        setComtenantId(res?.tenantid);
        dispatch(GET_TENANTS_MASTER(getTenantsPayload()));

      }
    } catch (error) {
      console.error('Error saving subscription details:', error);
    }
  };


  return (
    <>
      <IconButton
        title='Create New Tenant'
        onClick={openModal}
        className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-5 h-5'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 4.5v15m7.5-7.5h-15'
          />
        </svg>
      </IconButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <Tab.Group>
                    <div className='flex justify-between items-center my-2 w-full border-b border-secondary '>
                      <h3 className='text-lg font-[500] leading-6 text-primary'>
                        Create Tenant
                      </h3>
                      <Tab.List className=' justify-start flex space-x-2 rounded-lg p-1'>
                        {tabslist?.map((item, i) => {
                          return (
                            <Tab
                              key={i}
                              disabled={!activeTabs.includes(item)}
                              className={({ selected }) =>
                                classNames(
                                  'rounded-md py-1 px-2 outline-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                  selected
                                    ? 'text-secondary bg-secondary/10 font-[500]'
                                    : 'text-gray-500'
                                )
                              }
                            >
                              <span className='px-2'> {item}</span>
                            </Tab>
                          );
                        })}
                      </Tab.List>
                      <IconButton
                        title='Close'
                        onClick={closeModal}
                        type='button'
                        className='text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-4 h-4'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                        <span className='sr-only'>Close icon</span>
                      </IconButton>
                    </div>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Details
                          tenantid={tenantid}
                          setDetailsData={setDetailsData}
                          detailsData={detailsData}
                          detailSave={detailSave}
                          onModalClose={closeModal}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <SubscriptionDetails
                          tenantid={tenantid}
                          subscriptionSave={subscriptionSave}
                          mockObject={subscriptionItem()}
                          subscriptionData={subscriptionData}
                          setSubscriptionData={setSubscriptionData}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(CreateTenant);