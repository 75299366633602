import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	GET_COUNTRY_MASTER,
	GET_TECHNOLOGY_GROUP,
} from 'src/Redux/actions/masterdata/user'
import { GET_SKILLAREA_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
	getDeafultSkillAreaData,
	getMasterCountryPayload,
	getTechGroupMasterIdData,
} from 'src/Services/constant/defaultPayload'
import PieChartCountry from './byCountry'
import PieChartTech from './byTechGroup'
import PieChartExp from './byExperience'
import RadialPieChart from './metrics'
import PieChartSkill from './bySkillArea'
// import RadialPieChart from './area'

const metrics = [
	{ title: 'Avg Allocation (%)', value: 60, color: '#8884d8', innerLabel:'' },
	{ title: 'Avg Role Duration (Days)', value: 30, color: '#83a6ed', innerLabel:'' },
	{ title: 'Avg Tasks/Role', value: 3.7, color: '#8dd1e1', innerLabel:'' },
	{ title: 'Avg Staff/Role', value: 2.7, color: '#82ca9d', innerLabel:'' },
	{ title: 'Effort Hours (Billed)', value: 90, color: '#a4de6c', innerLabel:'' },
	{ title: 'Effort Hours (Unbilled)', value: 90, color: '#d0ed57', innerLabel:'' },
	{ title: 'Project Staff (Billed)', value: 65, color: '#ffc658', innerLabel:'' },
	{ title: 'Project Staff (Unbilled)', value: 35, color: '#ffc0cb', innerLabel:'' }
];

const AnalyticPlanning = () => {
	const dispatch = useDispatch()
	const { countryList, technologyGroup } = useSelector(state => state?.UserManagementList)

	useEffect(() => {
		dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()))
		dispatch(GET_COUNTRY_MASTER(getMasterCountryPayload()))
		dispatch(GET_TECHNOLOGY_GROUP(getTechGroupMasterIdData()))
	}, [dispatch])

	return (
		<div className='relative w-full'>
			<div className='grid grid-cols-4 gap-4 py-2 ml-8'>
				<div className='relative w-full h-80'>
					<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
						Staff by Skill Area
					</h3>
					<PieChartSkill  />
				</div>
				<div className='relative w-full h-80'>
					<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
						Staff by Country
					</h3>
					<PieChartCountry countryList={countryList} />
				</div>
				<div className='relative w-full h-80'>
					<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
						Staff by Tech Group
					</h3>
					<PieChartTech technologyGroup={technologyGroup} />
				</div>
				<div className='relative w-full h-80 '>
					<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
						Staff by Experience
					</h3>
					<PieChartExp />
				</div>
			</div>
			<div className='grid grid-cols-8 gap-1 '>
				{metrics.map((metric, index) => (
					<div key={index} className='flex items-center justify-center h-12 col-span-1'>
						<RadialPieChart
							title={metric.title}
							value={metric.value}
							color={metric.color}
							innerLabel={metric?.innerLabel}
						/>
					</div>
				))}
			</div>

		</div>
	)
}

export default AnalyticPlanning
