import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { GET_EMPLOYEE_DOCUMENT_LIST } from 'src/Redux/actions/workforce/eprofile'
import DownloadResume from './downloadResume'

const Resumes = () => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector(state => state?.EmployeeProfile)
	const { empDocumentList, allocationData } = EmployeeProfile
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const userId = searchParams.get('userid')
	const tenid = searchParams.get('tenid')

	useEffect(() => {
		dispatch(GET_EMPLOYEE_DOCUMENT_LIST({ comuserid: +userId, Tenantid: +tenid }))
	}, [dispatch, tenid, userId])

	return (
		<div className='text-sm'>
			{empDocumentList?.length > 0 && <DownloadResume empCode={allocationData?.employeecode} item={empDocumentList[0]} />}
		</div>
	)
}

export default Resumes
