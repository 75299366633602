import { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { GET_BUSINESS_MASTER, GET_LOCATION_MASTER, GET_SERVICELINE_MASTER } from "src/Redux/actions/masterdata/user"
import { GET_EXIT_PIPELINE_REPORT, GET_REPORT_MASTER_LIST } from "src/Redux/actions/reports"
import { GET_SKILLAREA_MASTER } from "src/Redux/actions/workforce/eprofile"
import { GET_COMPANY_MASTER, GET_DEPARTMENT_MASTER, GET_WORKFORCEGROUP_MASTER } from "src/Redux/actions/workforce/talent"
import { getBusinessUnitData, getDefaultLocationPayload, getDepartmentMasterIdData, getExitPipelineReportsPayload, getTenantCompanyIdData, getTenantWorkForceyIdData } from "src/Services/constant/defaultPayload"
import { getMasterServiceLinePayload, getMasterSkillAreaData } from "src/Services/constant/masterdatapayload"
import ReportsFilter from 'src/Filter/reports/workforce/exitPipeline'
import ReportList from "./table"
import { GET_TIMESHEET_PROJECTS_M } from "src/Redux/actions/projects/timesheet"

const ExitPipelineReports = ({setReportId, reportId}) => {
	const [filters, setFilters] = useState(getExitPipelineReportsPayload())
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const datmenuId = searchParams.get('datmenuid')
    const [project, setProject] = useState([])
	let {projectsOfM} = useSelector(state => state?.ProjectTimesheet)
	const {reportMasterList} = useSelector(
		state => state?.WorkforceReport,
	)
    const { exitPipelineReport } = useSelector((state) => state?.WorkforceReport)
	useEffect(() => {
		dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
	}, [datmenuId, dispatch])

	useEffect(() => {
 		dispatch(GET_SKILLAREA_MASTER(getMasterSkillAreaData()))
		dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
		dispatch(GET_EXIT_PIPELINE_REPORT(getExitPipelineReportsPayload()))
		dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
 		dispatch(GET_LOCATION_MASTER(getDefaultLocationPayload()))
		dispatch(GET_TIMESHEET_PROJECTS_M())
        dispatch(GET_BUSINESS_MASTER(getBusinessUnitData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()))
	}, [dispatch])

	return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <ReportsFilter
                    reportId={reportId}
                    setReportId={setReportId}
                    setFilters={setFilters}
                    filters={filters}
                    project={project}
                    setProject={setProject}
                    page={page}
                    limit={limit}
                />
            </div>
            <ReportList
                filters={filters}
                reportMasterList={reportMasterList}
                reportId={reportId}
                projectsOfM={projectsOfM}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                exitPipelineReport={exitPipelineReport}
            />
        </>
    )
}

export default memo(ExitPipelineReports)