import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { costtypeList } from 'src/Services/constant/staticValues';

const OverheadDetails = (props) => {
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;

  const handleSubmit = () => {
    let item = {
      overheadmasterid: detailsData?.overheadmasterid,
      overhead: detailsData?.overhead,
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: '',
      costtype: detailsData?.costtype,
    };
    let isValidated = CheckObjectValidation(detailsData, [
      'modifiedtimestamp',
      'modifiedcomuserid',
      'createdcomuserid',
      'notes',
    ]);
    if (isValidated?.isvalid) {
      let payload = {
        overheadjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='overhead' className='block font-[500] mb-2'>
            Overhead
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='overhead'
            id='overhead'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Overhead'
            required=''
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('overhead', e.target.value)
              }
            }}
            value={detailsData?.overhead || ''}
            maxLength={50}
          />
        </div>
        <div>
          <label htmlFor='costtype' className='block font-[500] mb-2'>
            Cost Type
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={costtypeList || []}
            value={detailsData?.costtype}
            noDataLabel='No costtype found'
            name='costtype'
            labelkey='name'
            className={`outline-gray-50 ${canEdit ? 'bg-white-50' : 'bg-gray-50'
              } text-gray-600 border rounded-lg block w-full p-2`}
            onChange={handleChangeInput}
            id='costtype'
          />
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                // disabled={!canEdit}
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(OverheadDetails);
