import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import MultiSelectItem from 'src/Components/MultiSelect'
import MultiSelectMonth from 'src/Components/MultiSelectMonth'
import SearchCustomer from 'src/Filter/projects/searchCustomer'
import { GET_DM_LIST, GET_USER_ACCESS_LIST } from 'src/Redux/actions/masterdata/user'
import { GET_FINANCE_REPORT } from 'src/Redux/actions/reports'
import {
    getFinanceReportPayload,
    getUserAccessPayload,
} from 'src/Services/constant/defaultPayload'
import SearchCustomerGroup from './selectCustomerGroup'
import Selectuser from './selectuser'

const categorylist = [
    { category: 'All', id: '' },
    { category: 'Internal', id: 'I' },
    { category: 'Customer', id: 'C' },
]

const FinanceReportFilter = ({
    cols = 'grid-cols-6',
    filters,
    setFilters,
    project,
    setProject,
}) => {
    const dispatch = useDispatch()
    const { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    const [customer, setCustomer] = useState({})
    const [account, setAccount] = useState({})
    const { customerGroupList, serviceList } = useSelector(
        (state) => state.UserManagementList,
    )
    const projectsReducer = useSelector((state) => state.Projects)
    const { listOfBillingProjects, customerList, statusListByType } = projectsReducer
    let { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const [pm, setPm] = useState({})
    const [dm, setDm] = useState({})
    const [selectedMonths, setSelectedMonths] = useState([moment().format('YYYY-MM')])

    useEffect(() => {
        if (projectsOfM?.length > 0) {
            const projectIdsString = `{${project.map((p) => p.projectid).join(',')}}`
            setFilters((prevFilters) => ({
                ...prevFilters,
                projectid: projectIdsString || '{}',
            }))
        }
    }, [project, setFilters, projectsOfM?.length])

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            accountid: account?.customergroupid || 0,
            customerid: customer?.customerid || 0,
        }))
    }, [account?.customergroupid, customer?.customerid, setFilters])

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            projectmanagerid: pm?.comuserid || 0,
            deliverymanagerid: dm?.comuserid || 0,
        }))
    }, [dm?.comuserid, pm?.comuserid, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        setProject([])
        setDm(null)
        setPm(null)
        setCustomer({})
        setAccount({})
        setSelectedMonths([moment().format('YYYY-MM')])
        let payload = {
            ...getFinanceReportPayload(),
            projectmanagerid: 0,
            deliverymanagerid: 0,
            accountid: 0,
            customerid: 0,
            projectid: '{}',
        }
        dispatch(GET_FINANCE_REPORT(payload))
        setFilters(payload)
    }

    const applyFilters = () => {
        dispatch(
            GET_FINANCE_REPORT({
                ...filters,
            }),
        )
    }

    const handleProjectSelect = (item) => {
        const isNew = project?.some((p) => p?.projectid === item?.projectid)
        if (isNew) {
            const updatedAllocationStatus = project?.filter(
                (p) => p?.projectid !== item?.projectid,
            )
            setProject(updatedAllocationStatus)
        } else {
            setProject((prevList) => [
                ...prevList,
                { projectid: item?.projectid, projectname: item?.projectname },
            ])
        }
    }

    useEffect(() => {
        const formattedMonths = selectedMonths?.map((month) => month.replace('-', ''))
        setFilters((prevFilters) => ({
            ...prevFilters,
            yearmonthid: `{${formattedMonths.join(',')}}`,
        }))
    }, [selectedMonths, setFilters])

    return (
        <div className={`grid ${cols} gap-3 text-sm`}>
            <div>
                <h1 className="mb-2 font-[500]">Select Report</h1>
                {reportMasterList?.length > 0 && (
                    <CustomhtmlSelect
                        options={reportMasterList || []}
                        defaultValue={reportMasterList[0]}
                        disabled={projectsOfM?.length === 0}
                        value={filters?.reportid || 9}
                        noDataLabel="No Report"
                        name="reportid"
                        labelkey="reportname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="reportid"
                    />
                )}
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Projects</h1>
                {projectsOfM !== null && (
                    <MultiSelectItem
                        className="py-2 border rounded bg-gray-50"
                        itemIdKey="projectid"
                        labelKeyname="projectname"
                        limitShow={1}
                        selectedOptions={project}
                        options={projectsOfM}
                        handleSelection={handleProjectSelect}
                    />
                )}
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Customer</h1>
                <SearchCustomer
                    selected={customer}
                    setSelected={setCustomer}
                    Options={customerList || []}
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Service Line</h1>
                <CustomhtmlSelect
                    options={serviceList || []}
                    value={filters?.servicelineid || ''}
                    noDataLabel="No service"
                    name="servicelineid"
                    labelkey="servicelinename"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="servicelineid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Bill Type</h1>
                <CustomhtmlSelect
                    options={listOfBillingProjects || []}
                    noDataLabel="No Type"
                    value={filters?.billtypeid}
                    name="billtypeid"
                    labelkey="billingtype"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="datprojectbillingtypeid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Category</h1>
                <CustomhtmlSelect
                    options={categorylist || []}
                    noDataLabel="No Category"
                    value={filters?.projectcategory}
                    name="projectcategory"
                    labelkey="category"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="id"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Account</h1>
                <SearchCustomerGroup
                    selected={account}
                    setSelected={setAccount}
                    Options={customerGroupList || []}
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Project Manager</h1>
                <Selectuser
                    setUser={setPm}
                    className="py-2 border rounded bg-gray-50"
                    user={pm}
                    apiCallForSearch={GET_USER_ACCESS_LIST}
                    userSelectorKey="userlistforadmin"
                    searchPayloadFunction={getUserAccessPayload}
                    placeholder="Select Project Manager"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Delivery Manager</h1>
                <Selectuser
                    setUser={setDm}
                    user={dm}
                    apiCallForSearch={GET_DM_LIST}
                    className="py-2 border rounded bg-gray-50"
                    userSelectorKey="dmList"
                    searchPayloadFunction={getUserAccessPayload}
                    placeholder="Select Delivery Manager"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Project Status</h1>
                <CustomhtmlSelect
                    options={statusListByType || []}
                    value={filters?.projectstatusid}
                    noDataLabel="No Projects"
                    name="projectstatusid"
                    labelkey="statusname"
                    className="py-2 border rounded bg-gray-50"
                    onChange={handleSelectChange}
                    id="statusid"
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Select Month/year</h1>
                <MultiSelectMonth
                    selectedMonths={selectedMonths}
                    setSelectedMonths={setSelectedMonths}
                    name="yearmonthid"
                    className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                    multiselect={true}
                    onChange={() => {
                        console.log()
                    }}
                />
            </div>
            <div>
                <h1 className="mb-2 font-[500]">Action</h1>
                <div className="grid grid-cols-2 gap-2">
                    <button
                        onClick={clearFilters}
                        className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                    >
                        Clear
                    </button>
                    <button
                        disabled={projectsOfM?.length === 0}
                        onClick={applyFilters}
                        className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

export default memo(FinanceReportFilter)
