import { useSelector } from "react-redux"
import { memo, useMemo, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { getUserAccessPayload } from "src/Services/constant/defaultPayload";
import { GET_PMO_LIST } from "src/Redux/actions/masterdata/user";

const SelectPmo = ({ setPmo, pmo }) => {
    const UserManagementList = useSelector(state => state.UserManagementList)
    const { pmoList, getStatusByTypeE, rolelist } = UserManagementList
    const Roleid = rolelist?.find(it => it?.code === 'PMO')?.roleid
    const [searchTerm, setSearchTerm] = useState('');
    const [empstatus, setEmpStatus] = useState(0)

    useMemo(() => {
        if (getStatusByTypeE?.length > 0) {
            let id = getStatusByTypeE?.find(it => it?.statusname === 'Active')?.statusid
            setEmpStatus(id)
        }
    }, [getStatusByTypeE])

    useMemo(() => {
        if (pmo?.firstname) {
            setSearchTerm(`${pmo?.firstname || ''} ${pmo?.lastname || ''} ${pmo?.employeecode ? `(${pmo?.employeecode || 0})` : ''}`)
        }
    }, [pmo])

    return (
        <div>
            <label htmlFor="ProjectManager" className="block mb-2 text-sm font-[500]">PMO</label>
            {empstatus && <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_PMO_LIST}
                searchPayload={{
                    ...getUserAccessPayload(),
                    Roleid:`{${Roleid}}`,
                    username: searchTerm,
                    empstatus
                }}
                setSearchTerm={setSearchTerm}
                options={pmoList}
                // mapping key  (idkey)
                idkey='uId'
                optionLabelkey2='lastname'
                optionlabelKey='firstname'
                bracketLabel='username'
                getSelectedOption={setPmo}
                className='p-2'
                placeholder='Select PMO SPOC' />}
        </div>
    )
}

export default memo(SelectPmo)