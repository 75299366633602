import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { getDefaultDemandTalentPayload } from 'src/Pages/app/Workforce/employeeDetails/findOpportunity/defaultPayload'
import { GET_DEMAND_LIST_FOR_TALENT } from 'src/Redux/actions/workforce/demands'

const DemandsFilter = ({ hooks }) => {
    const dispatch = useDispatch()
    const { bandList, workertypelist, workforcelist } = useSelector((state) => state?.EmployeeDetails)
    const { userSkillList } = useSelector((state) => state?.EmployeeProfile)

    const {
        filters,
        setFilters,
        Employees,
        primarySkill,
        setPrimarySkill,
        workerType,
        setWorkerType,
        workforceGroup,
        setWorkforceGroup,
        minExp,
        setMinExp,
        setBand,
        band,
        getBandIDs,
        remainingStatusIds,
        page,
        limit,
        searchTerm,
        setMatchStatus,
    } = hooks

    const initialPrimarySkills = () => {
        const primarySkills = Employees?.map((employee) => ({
            primaryskillid: employee.primaryskillid,
            primaryskill: employee.primaryskill,
        }))

        return primarySkills
    }

    const initialWorkerType = () => {
        const workerTypes = Employees?.map((employee) => ({
            worker_type: employee.worker_type,
            workertype: employee.workertype,
        }))

        return workerTypes
    }

    const initialExp = () => {
        const exps = Employees?.map((employee) => ({
            totalexperienceinmonths: employee?.totalexperienceinmonths,
        }))

        return exps
    }

    const initialBand = () => {
        const minband = Employees?.map((employee) => ({
            band: employee?.band,
        }))

        return minband
    }

    const initialWorkForce = () => {
        const WorkForce = Employees?.map((employee) => ({
            workforcegroupid: employee.workforcegroupid,
            workforcegroup: employee.workforcegroup,
        }))

        return WorkForce
    }

    useEffect(
        () => {
            if (Employees?.length > 0) {
                const initialSkills = Employees?.map((employee) => ({
                    skillmasterid: employee.primaryskillid,
                    skill: employee.primaryskill,
                })).filter((skill) => skill.skillmasterid != null)
                setPrimarySkill(initialSkills.length > 0 ? initialSkills : [{ skillmasterid: '', skill: 'All' }])
                const band = {
                    bandmasterid: Employees[0].band,
                    bandname: Employees[0].bandname,
                }
                setBand(band)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [Employees],
    )

    useEffect(
        () => {
            setPrimarySkill(initialPrimarySkills)
            setWorkerType(initialWorkerType)
            setWorkforceGroup(initialWorkForce)
            setMinExp(initialExp)
            setBand(initialBand)
            setMatchStatus([{ statusid: '', statusname: 'All' }])
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleExperience = (e) => {
        let { name, value } = e.target
        const parsedValue = parseInt(value, 10);

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: isNaN(parsedValue) ? '' : parsedValue.toString(),
        }));
    }

    useEffect(() => {
        if (minExp?.length > 0) {
            const experienceInYears = Math.round(minExp[0].totalexperienceinmonths / 12)

            setFilters((prevFilters) => ({
                ...prevFilters,
                skillid: primarySkill[0]?.primaryskillid,
                workertypeid: workerType[0]?.worker_type,
                workforcegroupid: workforceGroup[0]?.workforcegroupid,
                mintotalexperience: experienceInYears,
                minbandid: band[0]?.band,
            }))
        }
    }, [workforceGroup, workerType, primarySkill, setFilters, minExp, band])

    const handleSelectChange = (name, value) => {
        if (name === 'minbandid') {
            const selectedBand = bandList?.find((band) => band.bandmasterid.toString() === value)
            if (selectedBand) {
                value = String(selectedBand.slno - 1) // Adjust the minimum band filter
            }
        } else if (name === 'maxbandid') {
            const selectedBand = bandList?.find((band) => band.bandmasterid.toString() === value)
            if (selectedBand) {
                value = String(selectedBand.slno + 1) // Adjust the maximum band filter
            }
        }
        setFilters({ ...filters, [name]: value })
    }

    const applyFilters = () => {
        dispatch(
            GET_DEMAND_LIST_FOR_TALENT({
                ...filters,
                Limit: limit,
                Page: page,
                userid: Employees?.[0]?.comuserid,
                demandcode: searchTerm || '',
            }),
        )
    }

    const clearFilters = () => {
        setPrimarySkill([])
        setWorkerType([])
        setWorkforceGroup([])
        setMinExp([])
        setBand([])
        setMatchStatus([{ statusid: '', statusname: 'All' }])
        const payload = {
            ...getDefaultDemandTalentPayload(),
            skillid: 0,
            workertypeid: 0,
            workforcegroupid: 0,
            mintotalexperience: 0,
            minbandid: 0,
            maxbandid: 0,
            statusid: remainingStatusIds,
            Limit: limit,
            Page: page,
            userid: Employees?.[0]?.comuserid || 0,
        }
        setFilters(payload)
        dispatch(GET_DEMAND_LIST_FOR_TALENT(payload))
    }

    return (
        <div className="w-full text-sm">
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-1">
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Emp Type</h1>
                    <CustomhtmlSelect
                        options={[{ workertypeid: 0, workertype: 'All' }, ...workertypelist]}
                        value={filters?.workertypeid || 0}
                        noDataLabel="No Projects"
                        name="workertypeid"
                        labelkey="workertype"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="workertypeid"
                    />
                </div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Primary Skill</h1>
                    <CustomhtmlSelect
                        options={[{ skillmasterid: 0, skill: 'All' }, ...userSkillList]}
                        value={filters?.skillid || 0}
                        noDataLabel="No skill"
                        name="skillid"
                        labelkey="skill"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="skillmasterid"
                    />
                </div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Workforce Group</h1>
                    <CustomhtmlSelect
                        options={[{ wfgid: 0, workforcegroup: 'All' }, ...workforcelist]}
                        value={filters?.workforcegroupid || 0}
                        noDataLabel="No Workforce found"
                        name="workforcegroupid"
                        labelkey="workforcegroup"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="wfgid"
                    />
                </div>
                <div className="col-span-1 ">
                    <h1 className="mb-2 font-[500]">Min Band</h1>
                    <CustomhtmlSelect
                        options={bandList.map((band) => ({
                            ...band,
                            label: band.bandname,
                            value: band.bandmasterid.toString(),
                        }))}
                        value={filters?.minbandid || getBandIDs()?.defaultMinBandID}
                        noDataLabel="No band"
                        name="minbandid"
                        labelkey="bandname"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleSelectChange}
                        id="bandmasterid"
                    />
                </div>
                <div className="col-span-1">
                    <h1 className="mb-2 font-[500]">Max Band</h1>
                    <CustomhtmlSelect
                        options={bandList.map((band) => ({
                            ...band,
                            label: band.bandname,
                            value: band.bandmasterid.toString(),
                        }))}
                        value={filters.maxbandid || getBandIDs()?.defaultMaxBandID}
                        noDataLabel="No band"
                        name="maxbandid"
                        labelkey="bandname"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleSelectChange}
                        id="bandmasterid"
                    />
                </div>
                <div className="w-[80px]">
                    <h1 className="mb-2 font-[500]">Min Exp (Y)</h1>
                    <input
                        value={filters?.mintotalexperience || 0}
                        onChange={handleExperience}
                        name="mintotalexperience"
                        placeholder="Min "
                        type="number"
                        className="block w-full p-2 border border-gray-300 rounded-lg outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary"
                    />
                </div>
                <div></div>
                <div className="col-span-2">
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-1">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(DemandsFilter)
