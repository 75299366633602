import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'

const data = [
    { month: 'January', Internal: 40, External: 60, Contract: 20 },
    { month: 'February', Internal: 45, External: 70, Contract: 25 },
    { month: 'March', Internal: 50, External: 80, Contract: 30 },
    { month: 'April', Internal: 55, External: 85, Contract: 35 },
    { month: 'May', Internal: 60, External: 90, Contract: 40 },
    { month: 'June', Internal: 65, External: 95, Contract: 45 },
]

const LineChartAnalytic = () => {
    return (
        <div className="sm:h-[50dvh] md:h-[50dvh]">
            <ResponsiveContainer  debounce={300} width="100%" height="100%">
                <LineChart
                    width={400}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="month"
                        angle={-15}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        strokeWidth={2}
                        type="monotone"
                        dataKey="Internal"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                    <Line
                        strokeWidth={2}
                        type="monotone"
                        dataKey="External"
                        stroke="#82ca9d"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default LineChartAnalytic
