import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import MultiSelectItem from 'src/Components/MultiSelect'
import SelectMonth from 'src/Components/SelectMonth'
import { GET_PROJECT_WISE_UTILIZATION_REPORT } from 'src/Redux/actions/reports'
import { getProjectUtilizationReportsPayload } from 'src/Services/constant/defaultPayload'

const categorylist = [
    { category: 'G1 (0)', id: 'G1' },
    { category: 'G2 (0 - 50)', id: 'G2' },
    { category: 'G3 (50 - 100)', id: 'G3' },
    { category: 'G4 (100)', id: 'G4' },
]

const ProjectWiseReportsFilter = ({
    filters,
    setFilters,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
    project,
    setProject,
    page,
    limit,
}) => {
    const dispatch = useDispatch()
    const { reportMasterList } = useSelector((state) => state.WorkforceReport)
    let projectsOfM = useSelector((state) => state?.ProjectTimesheet?.projectsOfM)
    const { workforcelist, workertypelist } = useSelector(
        (state) => state.EmployeeDetails,
    )
    const { userSkillList } = useSelector((state) => state.EmployeeProfile)
    const { serviceList } = useSelector((state) => state.UserManagementList)
    const { businessUnitList } = useSelector((state) => state.Projects)

    useEffect(() => {
        if (projectsOfM?.length > 0) {
            const projectIdsString = `{${project.map((p) => p.projectid).join(',')}}`
            setFilters((prevFilters) => ({
                ...prevFilters,
                projectid: projectIdsString,
            }))
        }
    }, [project, setFilters, projectsOfM?.length])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        let m = month?.length === 1 ? `0${month}` : month
        const parsedMonth = parseInt(m, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthId = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearId = isNaN(parsedYear) ? '' : parsedYear

        if (name === 'startyearmonth') {
            setFilters({
                ...filters,
                [name]: Number(`${parsedYear}${m}`),
                startMonthid: validMonthId,
                startYearid: validYearId,
            })
        } else {
            setFilters({
                ...filters,
                [name]: Number(`${parsedYear}${m}`),
                endMonthid: validMonthId,
                endYearid: validYearId,
            })
        }
    }

    const clearFilters = () => {
        let payload = {
            ...getProjectUtilizationReportsPayload(),
            comuserid: 0,
            locationid: 0,
            utilizationgroup: '',
        }
        setProject([])
        dispatch(GET_PROJECT_WISE_UTILIZATION_REPORT(payload))
        setFilters(payload)
    }

    const applyFilters = () => {
        let payload = {
            ...filters,
            locationid: 0,
            comuserid: 0,
        }
        dispatch(GET_PROJECT_WISE_UTILIZATION_REPORT(payload))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    const handleProjectSelect = (item) => {
        const isNew = project?.some((p) => p?.projectid === item?.projectid)
        if (isNew) {
            const updatedAllocationStatus = project?.filter(
                (p) => p?.projectid !== item?.projectid,
            )
            setProject(updatedAllocationStatus)
        } else {
            setProject((prevList) => [
                ...prevList,
                { projectid: item?.projectid, projectname: item?.projectname },
            ])
        }
    }

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Project</h1>
                    {projectsOfM !== null && (
                        <MultiSelectItem
                            className="py-2 border rounded bg-gray-50"
                            itemIdKey="projectid"
                            labelKeyname="projectname"
                            limitShow={1}
                            selectedOptions={project}
                            options={projectsOfM}
                            handleSelection={handleProjectSelect}
                        />
                    )}
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Service Line</h1>
                    <CustomhtmlSelect
                        options={serviceList || []}
                        value={filters?.servicelineid}
                        noDataLabel="No Serviceline"
                        name="servicelineid"
                        labelkey="servicelinename"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="servicelineid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Workforce group</h1>
                    <CustomhtmlSelect
                        options={workforcelist || []}
                        value={filters?.workforcegroupid}
                        noDataLabel="No Workforce found"
                        name="workforcegroupid"
                        labelkey="workforcegroup"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="wfgid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Worker type</h1>
                    <CustomhtmlSelect
                        options={workertypelist || []}
                        value={filters?.workertypeid || ''}
                        noDataLabel="No workertype"
                        name="workertypeid"
                        labelkey="workertype"
                        className="p-2 border rounded-lg bg-gray-50"
                        onChange={handleSelectChange}
                        id="workertypeid"
                    />
                </div>

                <div>
                    <h1 className="mb-2 font-[500]">Primary Skill</h1>
                    <CustomhtmlSelect
                        options={userSkillList || []}
                        value={filters?.primaryskillid}
                        noDataLabel="No Skill"
                        name="primaryskillid"
                        labelkey="skill"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="skillmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Start Month</h1>
                    <SelectMonth
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        name="startyearmonth"
                        onChange={handleMonthChange}
                        month={
                            filters?.startMonthid?.length === 1
                                ? `0${filters?.startMonthid}`
                                : filters?.startMonthid
                        }
                        year={filters?.startYearid}
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select End Month</h1>
                    <SelectMonth
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        name="endyearmonth"
                        onChange={handleMonthChange}
                        month={
                            filters?.endMonthid?.length === 1
                                ? `0${filters?.endMonthid}`
                                : filters?.endMonthid
                        }
                        year={filters?.endYearid}
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Utilization Group</h1>
                    <CustomhtmlSelect
                        options={categorylist || []}
                        noDataLabel="No Category"
                        value={filters?.utilizationgroup}
                        name="utilizationgroup"
                        labelkey="category"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="id"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]"> Business Unit </h1>
                    <CustomhtmlSelect
                        options={businessUnitList || []}
                        value={filters?.businessunitid || ''}
                        noDataLabel="No Business Unit"
                        name="businessunitid"
                        labelkey="businessunitname"
                        className="block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50"
                        onChange={handleSelectChange}
                        id="businessunitid"
                    />
                </div>
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectWiseReportsFilter
