function getUserId() { return JSON.parse(localStorage.getItem('comuserid')) }
function gettenantId() { return JSON.parse(localStorage.getItem('Tenantid')) }


export const defaultItem = () => {
    return {
        billable:false,
        projectroleid: null,
        projectid: 0,
        customerid: 0,
        croleid: 0,
        rolename: "",
        crateid: 0,
        levelid: 0,
        skillareamasterid: 0,
        techgroupid: 0,
        workmodeid: 0,
        cityid: 0,
        citytext: "",
        billratehr: 0,
        cost_hr: 0,
        datcomcurrencyid: 0,
        peopleno: 0,
        allocation: 0,
        startdate: "",
        enddate: "",
        staffcostid: null,
        comtenantid: gettenantId(),
        isactiveflag: true,
        createdcomuserid: getUserId(),
        notes: "",
        plannedeffort:null,
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
        projectroletasks: [
            // {
            //     projtaskid: null,
            //     isactiveflag: true,
            //     notes: "",
            //     createdcomuserid: getUserId()
            // },
        ]
    }
}

export const insertDataKeys = ['billable', 'plannedeffort', 'projectroleid', 'projectid', 'customerid', 'croleid', 'rolename', 'crateid', 'levelid', 'skillareamasterid', 'techgroupid', 'workmodeid', 'cityid', 'citytext', 'billratehr', 'cost_hr', 'datcomcurrencyid', 'peopleno', 'allocation', 'startdate', 'enddate', 'staffcostid', 'comtenantid', 'isactiveflag', 'createdcomuserid', 'notes', 'projectroletasks']