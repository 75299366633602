// ** Redux Imports
import { combineReducers } from 'redux'
import auth from './auth'
import ProjectTimesheet from './projects/timesheet'
import EmployeeProfile from './workforce/eprofile'
import EmployeeDetails from './workforce/talent'
import DemandDetailsList from './workforce/demands'
import Projects from './projects'
import CustomerManagementList from './masterdata/customer'
import ConfigurationList from './configuration'
import WorkforceReport from './reports'
import UserManagementList from './masterdata/user'
import masterDataCustomer from './masterdata/customer/role'
import ProjectPlanning from './projects/planning'
import ProjectStaffing from './projects/staffing'
import ProjectFinancial from './projects/financials'
import SubscriptionList from './subscriptions'
import Analytics from './analytics'

const rootReducer = combineReducers({
    SubscriptionList,
    ProjectStaffing,
    auth,
    ProjectTimesheet,
    Projects,
    CustomerManagementList,
    UserManagementList,
    ConfigurationList,
    EmployeeProfile,
    EmployeeDetails,
    WorkforceReport,
    masterDataCustomer,
    ProjectPlanning,
    DemandDetailsList,
    ProjectFinancial,
    Analytics,
})

export default rootReducer
