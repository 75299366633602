import React, { memo } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const LineChartDashboard = ({ data }) => {
    return (
        <div className='sm:h-[36dvh] md:h-[36dvh]'>
            <ResponsiveContainer debounce={300} width='100%' height='100%'  >
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='monthname' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        strokeWidth={2}
                        type='monotone'
                        dataKey='Utilization'
                        stroke='#8884d8'
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default memo(LineChartDashboard);
