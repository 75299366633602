import { useMemo, useState } from "react";
import { getDeafultSkillData } from 'src/Services/constant/defaultPayload'
import { GET_SKILL_MASTER, } from 'src/Redux/actions/workforce/eprofile'
import { useDispatch } from "react-redux";
import SingleSelectField from "src/Components/SingleSelectForTable";

const SkillComponent = ({ rowid, value, handleChange, skillareamasterid, item }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useMemo(() => {
        setLoading(true)
        let payload = {
            ...getDeafultSkillData(),
            skillareamasterid: Number(skillareamasterid || 0)
        }
        dispatch(GET_SKILL_MASTER(payload)).then((res) => {
            if(res?.success){
                setLoading(false)
                setData(res?.data)
            }
        }).catch((err) => {
            setLoading(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skillareamasterid])

    const getSelected = (value) => {
        let e = { target: { name: 'skillmasterid', value: value['skillmasterid'], objectValue: value } }
        handleChange(e, rowid)
    }

    return (
        <div >
            {loading ? <span className="text-xs"> Loading...</span> : <>
                <SingleSelectField
                    valueKey='skillmasterid'
                    labelKey='skill'
                    options={data}
                    value={value || 0}
                    handleSelected={getSelected}
                />
            </>}
        </div>
    )
}

export default SkillComponent;
