import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useDispatch } from 'react-redux'
import { GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD } from 'src/Redux/actions/analytics'

const BandWiseAllocationChart = ({ reportLevel, selectedIds, filters }) => {
    const dispatch = useDispatch()
    const [chartOptions, setChartOptions] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true) // Start loading
            const payload = buildPayload(reportLevel, selectedIds)
            const res = await dispatch(GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD(payload))

            const responseData = res || []
            if (responseData.length > 0) {
                const totalAllocation = responseData.reduce((sum, item) => sum + item.AllocationPerc, 0)

                const formattedData = responseData.map((item) => ({
                    name: item.BandName,
                    y: (item.AllocationPerc / totalAllocation) * 100,
                }))

                setChartOptions(generateChartOptions(formattedData))
            } else {
                setChartOptions(generateChartOptions([]))
            }
            setLoading(false) // Stop loading after data is fetched
        }

        fetchData()
    }, [dispatch, selectedIds, filters])

    const buildPayload = (level, ids) => ({
        ...filters,
        reportlevel: level,
        servicelinegroupid: ids.servicelinegroupid || 0,
        servicelineid: ids.servicelineid || 0,
        businessunitid: ids.businessunitid || 0,
        customergroupid: ids.customergroupid || 0,
        customerid: ids.customerid || 0,
    })

    const generateChartOptions = (data) => ({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: 180,
        },
        title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 55,
            style: {
                color: '#6b7280',
                fontSize: '14px',
                fontWeight: '600',
            },
        },
        tooltip: {
            pointFormat: '{point.name}: <b>{point.y:.1f}%</b>',
            style: {
                color: '#6b7280',
                fontSize: '14px',
            },
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -30,
                    style: {
                        fontWeight: '500',
                        color: '#6b7280',
                        fontSize: '14px',
                    },
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '150%',
                innerSize: '50%',
            },
        },
        series: [
            {
                type: 'pie',
                name: 'Percentage',
                innerSize: '50%',
                data: data,
            },
        ],
    })

    return (
        <div className="relative w-full h-full">
            {loading ? (
                <div className="flex items-center justify-center w-full h-full">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>

            ) : (
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
        </div>
    )
}

export default BandWiseAllocationChart
