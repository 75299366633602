import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreIcon, NextIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import { DOWNLOAD_REPORT_BY_ENDPOINT, GET_DEMAND_SUMMARY_REPORT } from 'src/Redux/actions/reports'

const cols1 = [
	'Demand Code',
	'Company Name',
	'Request Type',
	'Employee Type',
	'No. of People',
	'Business Unit',
	'Skill Area',
	'Start Date',
	'Billing Rate',
	'Billing Unit',
	'Billing Currency',
]
const cols2 = [
	'Demand Code',
	'Allocation (%)',
	'Priority',
	'Work Mode',
	'Location Flag',
	'Location',
	'Min Band',
	'Max Band',
	'Min Experience (Yr)',
	'Demand Status',
	'Selected Staff',
]

const rows = [10, 25, 50]
const ReportList = ({ filters, reportMasterList, reportId }) => {
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const { demandSummaryReport } = useSelector(state => state?.WorkforceReport)
	const [showMore, setShowMore] = useState(true)
	const handlePagination = page => {
		dispatch(
			GET_DEMAND_SUMMARY_REPORT({
				...filters,
				limit,
				pageno: page?.selected + 1,
			}),
		)
		setPage(page?.selected + 1)
	}

	const handleRows = row => {
		setLimit(row)
		if (row > 10) {
			setPage(1)
		}
		dispatch(
			GET_DEMAND_SUMMARY_REPORT({
				...filters,
				limit: row,
				pageno: row > 10 ? 1 : page,
			}),
		)
	}

	const report = reportMasterList?.find(report => report?.reportid === reportId)
	const fileName = report ? `${report.reportname}` : ''


	const totalRecords = useMemo(() => {
		return demandSummaryReport?.length > 0
		? demandSummaryReport[0]?.totalcount
		: demandSummaryReport?.length
	}, [demandSummaryReport])

	return (
		<div className='relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]'>
			<div className='flex items-center justify-between mb-2'>
				<div className='absolute flex items-center justify-start space-x-2 top-3 right-20 '>
					<button disabled={showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary'>
						{PreIcon}
					</button>
					<button disabled={!showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary'>
						{NextIcon}
					</button>
				</div>
				<div className='flex items-center justify-between w-full space-x-5'>
					<p className='text-primary font-[500]'>Report Details ({totalRecords})</p>
					<div className='flex items-center justify-start space-x-6'>
						<ReactPaginationStyle
							total={totalRecords}
							limit={limit}
							currentPage={page}
							handlePagination={handlePagination}
						/>
						<div className='w-32'>
							<FilterButtonTab
								setSelectedtab={handleRows}
								selectedTab={limit}
								tabs={rows}
							/>
						</div>
					</div>
					<DownloadAsCsv
						fileName={fileName}
						limit={totalRecords}
						endpoint='Download_demandsummary'
						APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
						filters={filters} />
				</div>
			</div>
			{demandSummaryReport === null ? (
				<div className='flex items-center justify-center w-full h-1/2'>
					<div
						className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
						role='status'
					>
						<span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
							Loading...
						</span>
					</div>
				</div>
			) : (
				<div className='h-[calc(90%)] overflow-scroll'>
					<table className='relative w-full text-sm'>
						<thead className='sticky top-0'>
							<tr className='text-left capitalize bg-gray-50'>
								{showMore
									? cols1.map(col => {
										return (
											<th
												key={col}
												title={col}
												scope='col'
												className='p-2 font-[500] border text-start'
											>
												{' '}
												{col}
											</th>
										)
									})
									: cols2.map(col => {
										return (
											<th
												key={col}
												title={col}
												scope='col'
												className='p-2 font-[500] border text-start'
											>
												{' '}
												{col}
											</th>
										)
									})}
							</tr>
						</thead>
						<tbody className='text-gray-600'>
							{demandSummaryReport?.map((item) => {
								let {
									demandcode,
									companyname,
									servicelinename,
									requesttype,
									peopleno,
									startdate,
									skillarea,
									billrate,
									currency,
									billingunitname,
									businessunitname,
									allocation,
									priority,
									workmode,
									locationflag,
									uId,
									locationname,
									min_band,
									max_band,
									experienceinyear,
									statusname,
									selectedstaff,
								} = item
								return (
									<tr key={uId} className='text-left cursor-pointer'>
										{showMore ? (
											<>
												<td className='px-1 py-1 capitalize border '>
													{demandcode ? `(${demandcode})` : ''}
												</td>
												<td className='px-1 py-1 border '>{companyname}</td>
												<td className='px-1 py-1 border '>
													{requesttype === 'I' ? 'Internal' : 'Project'}
												</td>
												<td className='px-1 py-1 border '>{servicelinename}</td>
												<td className='px-1 py-1 border '>{peopleno}</td>
												<td className='px-1 py-1 border '>
													{businessunitname}
												</td>
												<td className='px-1 py-1 border '>{skillarea}</td>
												<td className='px-1 py-1 border '>{startdate}</td>
												<td className='px-1 py-1 border '>{billrate}</td>
												<td className='px-1 py-1 border '>{billingunitname}</td>
												<td className='px-1 py-1 border '>{currency}</td>
											</>
										) : (
											<>
												<td className='px-1 py-1 capitalize border '>
													{demandcode ? `(${demandcode})` : ''}
												</td>
												<td className='px-1 py-1 border '>
													{allocation * 100}
												</td>
												<td className='px-1 py-1 border '>{priority}</td>
												<td className='px-1 py-1 border '>{workmode}</td>
												<td className='px-1 py-1 border '>{locationflag}</td>
												<td className='px-1 py-1 border '>{locationname}</td>
												<td className='px-1 py-1 border '>{min_band}</td>
												<td className='px-1 py-1 border '>{max_band}</td>
												<td className='px-1 py-1 border '>
													{experienceinyear}
												</td>
												<td className='px-1 py-1 border '>{statusname}</td>
												<td className='px-1 py-1 border '>{selectedstaff}</td>
											</>
										)}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</div>
	)
}

export default ReportList