export const createDemandDetailsInfo = () => {
	return {
		demandid: null,
		demandcode: '',
		workertypeid: 0,
		companyid: 0,
		requesttype: 'O',
		businessunitid: 0,
		servicelineid: 0,
		startdate: '2023-07-01',
		opportunityid: null,
		projectid: 0,
		departmentid: 0,
		billrate: null,
		datcomcurrencyid: null,
		billingunit: null,
		allocation: 0,
		workmodeid: 0,
		priority: '',
		locationid: null,
		assignedto: null,
		assigneddate: null,
		demandstatusid: 31,
		cityid: null,
		countryid:null,
		is_billable: true,
		comtenantid: Number(localStorage.getItem('Tenantid') || 0),
		isactiveflag: true,
		createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
		modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
		notes: '',
		peopleno: 0,
		isonsite: true,
	}
}

export const detailNotRequired = [ 'demandid','opportunityid', 'projectid', 'departmentid','demandcode','locationid', 'assignedto', 'assigneddate', 'is_billable', 'billrate', 'datcomcurrencyid', 'billingunit', 'notes' ];

