import Tooltip from "src/Components/Tooltip";
import EmployeeNote from "../comment/EmployeeNotePopup";
// import ManagerNotePopup from "../comment/ManagerNotePopup";
import EffortInput from "./effortInput";
import SelectTask from "./selectTask";
import SelectTimeType from "./selectTimeType";
import CustomhtmlSelect from "src/Components/CustomhtmlSelect";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "src/Components/Buttons/IconButton";
import { useMemo } from "react";
import moment from "moment";
import ApproverRemarkNotePopup from "../comment/approverRemark";
import { AlertError } from "src/Services";
import ConfigInfo from "../configInfo";
import { PlusIcon } from '@heroicons/react/24/outline'
import { MinusIcon } from '@heroicons/react/24/outline'

const getColorCode = status => {
    switch (status) {
        case 'Pending':
            return 'bg-gray-500 '
        case null:
            return 'bg-gray-500 '
        case 'Draft':
            return 'bg-blue-500 '
        case 'Reopened':
            return 'bg-[#008080] '
        case 'Approved':
            return 'bg-green-500 '
        case 'Submitted':
            return 'bg-yellow-500 '
        default:
            break
    }
}

const getActiveData = (data) => {
    return data?.filter(row => row?.isactiveflag !== false) || []
}

const genRateUnitID = () => {
    return Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
}

const Row = ({ hooks, rowId, hooks: { it, employeeTimesheetData, leaves, projects, generateEffotCombination, generateEffotCombinationForGenric } }) => {
    let { getTaskTimesheetConfig } = useSelector(state => state?.ProjectTimesheet)
    const dispatch = useDispatch()

    const handleOnchange = (e) => {
        let { name, value } = e.target
        let items = employeeTimesheetData?.map(row => {
            if (row?.uId === rowId) {
                row[name] = value
                row['prevstatus'] = row?.curstatus
                row['curstatus'] = 'Draft'
                row['newChange'] = true
                // row['prevstatus'] = row?.prevstatus
                if (name === 'entrytype') {
                    row["projectId"] = ""
                    row["taskdesc"] = ""
                    row["taskid"] = ""
                }
            }
            return row
        })
        dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
    }

    const selectEntryType = (name, value) => {
        if (value === 'Generic') {
            let approvedGen = employeeTimesheetData?.find(row => row?.curstatus === 'Approved' && row?.entrytype === 'Generic')
            if (approvedGen) {
                AlertError("Can not add new Genric timesheet because it is already approved")
                return
            }
        }
        // if(value === 'Generic')
        let items = employeeTimesheetData?.map(row => {
            if (row?.uId === rowId) {
                row[name] = value
                row['prevstatus'] = row?.curstatus
                row['curstatus'] = 'Draft'
                row['newChange'] = true
                row["projectId"] = ""
                row["taskdesc"] = ""
                row["taskid"] = ""
                row['timetypeid'] = null
                row['billtoclient'] = null
                row['clientapproval'] = null
                row['projtaskid'] = null

                row?.timesheetdetails?.map(eff => {
                    eff['efforthours'] = 0
                    eff['endtime'] = 0
                    eff['starttime'] = 0
                    return eff
                })
            }
            return row
        })
        dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
    }

    const effortHandleChange = (e) => {
        let { name, value } = e.target
        // Convert the input value to a decimal number
        value = parseFloat(value) || 0;

        let updateEffort = employeeTimesheetData?.map(row => {
            if (row?.uId === rowId) {
                row['prevstatus'] = row?.curstatus
                row['curstatus'] = 'Draft'
                row['newChange'] = true
                let totalweekEffort = 0
                row?.timesheetdetails?.map((effort) => {
                    totalweekEffort = totalweekEffort + effort['efforthours']
                    if (effort?.effortdate === name) {
                        effort['efforthours'] = value
                        totalweekEffort = totalweekEffort + value
                    }
                    return effort
                })
            }
            return row
        })
        dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: updateEffort })
    }

    const timeTypelist = useMemo(() => {
        if (it?.entrytype === 'Generic') {
            let timeType = getTaskTimesheetConfig?.timesheetdata?.find(row => row?.entrytype === 'Generic')
            if (timeType?.timesheetconfiguration?.length > 0) {
                return timeType?.timesheetconfiguration[0]?.timetypetemplatemapping
            }
            return []
        } else {
            let timeType = getTaskTimesheetConfig?.timesheetdata?.find(row => row?.projectid === it?.projectid)
            if (timeType?.timesheetconfiguration?.length > 0) {
                return timeType?.timesheetconfiguration[0]?.timetypetemplatemapping
            }
            return []
        }
        // eslint-disable-next-line
    }, [it?.entrytype, it?.projectid])

    const selectedTimetype = useMemo(() => {
        return timeTypelist?.find(value => value?.timetypeid === it?.timetypeid)
        // eslint-disable-next-line 
    }, [timeTypelist, it?.timetypeid])

    const holidaycalendar = (effortdate, time_type) => {
        let listOfLeaves = []
        // this row will contain holiday and leave list 
        let rowis = {}
        if (it?.entrytype === 'Generic') {
            rowis = it
        } else {
            rowis = projects?.find(p => p?.projectid === it?.projectid)
        }
        let selectedTimetype = timeTypelist?.find(value => value?.timetypeid === time_type)
        if (selectedTimetype?.holidaycalendarintegration === 'Both') {
            // Company holidays 
            if (rowis?.Companyholidays) {
                for (let l of rowis?.Companyholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }
            // Customer holidays 
            if (rowis?.customerholidays) {
                for (let l of rowis?.customerholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }

        } else if (selectedTimetype?.holidaycalendarintegration === 'Customer') {
            // Customer holidays 
            if (rowis?.customerholidays) {
                for (let l of rowis?.customerholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }
        } else if (selectedTimetype?.holidaycalendarintegration === 'Company') {
            // Company holidays 
            if (rowis?.Companyholidays) {
                for (let l of rowis?.Companyholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }
        }
        return listOfLeaves?.includes(effortdate)
    }

    const leaveFound = (celldate, entrytype) => {
        if (entrytype === 'Generic') return false
        let list = leaves?.map(l => {
            return l?.leavedate
        })
        return list?.includes(celldate)
    }

    const handleRemove = () => {
        let items = employeeTimesheetData?.map(row => {
            if (row?.uId === rowId) {
                row['isactiveflag'] = false
                row['prevstatus'] = row?.curstatus
                row['curstatus'] = 'Draft'
                row['newChange'] = true
            }
            return row
        })
        if (items?.length > 0) {
            dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
            generateEffotCombination()
            generateEffotCombinationForGenric()
        }
    }

    const handleAdd = () => {
        let flag = 'Generic'
        let Item = getTaskTimesheetConfig?.timesheetdata?.find(it => it?.entrytype === 'Generic')
        if (!Item) {
            Item = getTaskTimesheetConfig?.timesheetdata?.find(it => it?.entrytype === 'Project')
            flag = "Project"
        }

        let timesheetdetails = Item?.timesheetdetails?.map(item => {
            let effort = { ...item }
            effort['efforthours'] = 0
            return effort
        })
        if (Item) {
            let preData = [...employeeTimesheetData]
            preData?.push({
                ...Item,
                isactiveflag: undefined,
                timesheetdetails, uId: genRateUnitID(),
                taskdesc: '',
                entrytype: flag,
                projtaskid: '',
                effortid: null,
                timetypeid: null,
                timetypename: '',
                curstatus: 'Draft',
                prevstatus: null,
                newChange: true
            })
            dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: preData })
        }
    }

    const allocationDateValidation = (effortdate, rowdata) => {
        if (rowdata?.Allocationenddate && rowdata.Allocationstartdate) {
            if (rowdata?.entrytype === 'Project') {
                let start = moment(new Date(rowdata?.Allocationstartdate)).format('YYYY-MM-DD')
                let end = moment(new Date(rowdata?.Allocationenddate)).format('YYYY-MM-DD')
                let effortDateis = moment(new Date(effortdate)).format('YYYY-MM-DD')

                if (effortDateis >= start && effortDateis <= end) {
                    return false
                } else {
                    return true
                }
            }
        }

    }

    const getAllocation = () => {
        if (it?.allocationperc) {
            return (it?.allocationperc * 100) + '%'
        } else if (it?.allocation) {
            return (it?.allocation) + '%'
        } else if (JSON.stringify(it?.selectedAllocation?.allocation)) {
            return it?.selectedAllocation?.allocation + '%'
        } else return ''
    }

    const RuleInfo = useMemo(() => {
        return <ConfigInfo entrytype={it?.entrytype} data={it} />
        // eslint-disable-next-line 
    }, [it?.timetypeid])

    return (
        <tr className='text-left border'>
            <td className='border py-1.5'>
                <CustomhtmlSelect
                    options={[{ name: 'Generic', id: 'Generic' }, { name: 'Project', id: 'Project' }]}
                    noDataLabel='No Entry type available'
                    value={it?.entrytype}
                    name='entrytype'
                    labelkey='name'
                    disabled={it?.curstatus === 'Submitted' || it?.curstatus === 'Approved'}
                    className='text-xs font-normal'
                    defaultWidthClass="pl-1 pr-0"
                    onChange={selectEntryType}
                    id='id'
                />
            </td>
            <td className='border px-2 py-1.5'>
                <div className="flex justify-start items-center space-x-1">
                    {it?.billtoclient && <span className="h-4 w-[3px] rounded-md bg-orange-500"></span>}
                    {it?.clientapproval && <span className="h-4 w-[3px] rounded-md  bg-green-500"></span>}
                    <SelectTask hooks={{ ...hooks, rowId }} />
                    {it?.entrytype === 'Project' && <span className="text-gray-500 text-xs">{getAllocation()}</span>}
                    <span className={`h-2 w-2 ${getColorCode(it?.curstatus)} rounded-full`} />
                </div>
            </td>
            {/* <td className='border px-2 py-1.5 w-24 '>{it?.referencenumber}</td> */}
            <td className='border px-2 py-1.5 w-16'>
                <div className="w-full flex justify-start items-center space-x-1">
                    <SelectTimeType hooks={{ ...hooks, rowId }} />
                    {it?.timetypeid && RuleInfo}
                </div>
            </td>
            {
                it?.timesheetdetails?.map((effort, effortIndex) => {
                    return (
                        <td key={effortIndex + effort?.effortdate} className='border pl-1.5 py-1.5'>
                            <EffortInput
                                selectedTimetype={selectedTimetype}
                                hooks={hooks}
                                rowId={rowId}
                                row={it}
                                isOnLeave={leaveFound(effort?.effortdate, it?.entrytype)}
                                holidayActive={holidaycalendar(effort?.effortdate, it?.timetypeid)}
                                disabled={(
                                    // disable if current status is approved 
                                    it?.curstatus === 'Approved' ||
                                    // check allication validation 
                                    allocationDateValidation(effort?.effortdate, it) ||
                                    // disable if future date 
                                    (effort?.effortdate > moment().format('YYYY-MM-DD')) ||
                                    // disable if current status is Submitted 
                                    it?.curstatus === 'Submitted' ||
                                    // disable if time type not selected
                                    it?.timetypeid === null ||
                                    (holidaycalendar(effort?.effortdate, it?.timetypeid) && !selectedTimetype?.holidayallowed)
                                )}
                                handleOnchange={effortHandleChange}
                                effortIndex={effortIndex}
                                item={effort} />
                        </td>
                    )
                })
            }
            <td className='p-1'>
                <div className=" flex justify-start items-center space-x-2">
                    <Tooltip title='Employee comments'>
                        <EmployeeNote
                            disabled={it?.curstatus === 'Submitted' || it?.curstatus === 'Approved'}
                            id={rowId}
                            value={it?.effortnotes || ''}
                            handleOnchange={handleOnchange}
                            name='effortnotes'
                        />
                    </Tooltip>
                    <Tooltip title='Manager Remark'>
                        {it?.approverremarks && <ApproverRemarkNotePopup value={it?.approverremarks} />}
                    </Tooltip>
                </div>
            </td>
            <td className='border py-1.5' >
                <div className='flex justify-start items-center w-full'>
                    <IconButton
                        disabled={it?.curstatus === 'Submitted' || it?.curstatus === 'Approved' || getActiveData(employeeTimesheetData)?.length === 1}
                        title='Delete'
                        onClick={handleRemove}
                        className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                    >
                        <MinusIcon className="w-3" />
                    </IconButton>
                    <span className="hidden addIconbutton-add-btn">
                        <IconButton
                            title='Add'
                            // disabled={it?.curstatus === 'Submitted'}
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                        >
                            <PlusIcon className="w-3" />
                        </IconButton>
                    </span>
                </div>
            </td>
        </tr>

    )
}

export default Row;