
import CustomhtmlSelect from "src/Components/CustomhtmlSelect";

const ProjectType = ({ typesListProject, value, handleChangeValue }) => {
    return (
        <>
            <label htmlFor="projectsubtype" className="block font-[500] mb-2">Project Type <span className="text-red-300">*</span></label>
            <CustomhtmlSelect
                options={typesListProject || []}
                noDataLabel='No Type'
                value={value}
                name='projecttypeid'
                labelkey='projecttype'
                						className='border rounded bg-gray-50 py-2'

                onChange={handleChangeValue}
                id='projecttypeid'
            />
        </>
    )
}

export default ProjectType;