import { Tab } from '@headlessui/react'
import Demands from './demands'
import Talent from './talent'
import AnalyticFullFillment from './fulfilment'
import NoPermissionComponent from 'src/Components/NoPermission'
import Allocation from './allocation'
import Utilization from './utilization'
import AnalyticsWorkforceSubFilter from 'src/Filter/analytics/analyticsWorkforce/subFilter'
import { PreIcon, NextIcon } from 'src/Assets/icons'
import { useState } from 'react'

const tabs = ['Talent', 'Allocation', 'Utilization', 'Demands', 'Fulfilment']

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Tabs = ({ thisPagePermissions, filters, setFilters }) => {
    const [showMore, setShowMore] = useState(true)

    return (
        <div className="relative w-full h-full">
            <Tab.Group>
                <div className="flex items-center justify-between space-x-3 border-b border-secondary">
                    <div className="flex items-center justify-start space-x-2">
                        <Tab.List className="flex justify-start p-1">
                            {tabs?.map((item, i) => {
                                return (
                                    <Tab
                                        key={i}
                                        onClick={() => setShowMore(false)}
                                        className={({ selected }) =>
                                            classNames(
                                                'py-2 text-sm font-[500] outline-white',
                                                'ring-white ring-opacity-60 ring-offset-0 ring-offset-white focus:outline-none focus:ring-0',
                                                selected
                                                    ? 'bg-secondary/10 text-secondary font-[500] p-1 rounded-md'
                                                    : 'text-gray-500',
                                            )
                                        }
                                    >
                                        <span className="px-4"> {item}</span>
                                    </Tab>
                                )
                            })}
                        </Tab.List>
                        <div className="flex items-center justify-start space-x-2 top-2 right-2 ">
                            <button
                                disabled={showMore}
                                onClick={() => setShowMore(!showMore)}
                                className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                            >
                                {PreIcon}
                            </button>
                            <button
                                disabled={!showMore}
                                onClick={() => setShowMore(!showMore)}
                                className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                            >
                                {NextIcon}
                            </button>
                        </div>
                    </div>
                    <AnalyticsWorkforceSubFilter filter={filters} setFilter={setFilters} />
                </div>
                <Tab.Panels>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? <Talent /> : <NoPermissionComponent />}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? (
                            <Allocation
                                filters={filters}
                            />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? <Utilization /> : <NoPermissionComponent />}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? <Demands /> : <NoPermissionComponent />}
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Dashboards') ? (
                            <AnalyticFullFillment />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default Tabs
