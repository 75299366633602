export const DesignationItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    designationmasterid: null,
    designation: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}
export const detailNotRequired = [
  'designationmasterid',
  'notes'
];
