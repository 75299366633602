import moment from 'moment'

const getDefaultProjectPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customerid: 0,
        projectid: 0,
        projectname_code: '',
        companyid: 0,
        projecttypeid: 0,
        locationid: 0,
        billingtypeid: 0,
        projectstaus: 0,
        category: '',
        limit: 10,
        pageno: 1,
        sorttype: 3,
    }
}

const getTaskTypePayload = (tasktypeid) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 3,
        tasktypeid,
    }
}

const getTimesheetReportPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0,
        projectid: '{}',
        yearmonth: Number(moment(new Date()).format('YYYYMM')),
        billable: '',
        approvalstatus: '',
        approveddatefrom: '',
        approveddateto: '',
        limit: 10,
        pageno: 1,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        billingtypeid: 0,
        servicelineid: 0,
        businessunitid: 0,
        weekstartdate: '',
        weekenddate: '',
        tasktypeid: 0,
    }
}

const getTimesheetTrackingReportPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0,
        allocationstatusid: '{}',
        yearmonth: Number(moment(new Date()).format('YYYYMM')),
        limit: 10,
        page: 1,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        workertypeId: 0,
        workforcegroupid: 0,
        empstatusid: 0,
        weekstartdate: '',
        weekenddate: '',
        timesheetstatus: '{}',
        companyid: 0,
    }
}
const getTimesheetExceptionReportPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0,
        projectid: '{}',
        yearmonth: Number(moment(new Date()).format('YYYYMM')),
        approvalstatus: '',
        limit: 10,
        pageno: 1,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        billingtypeid: 0,
        servicelineid: 0,
        businessunitid: 0,
        weekstartdate: ``,
        weekenddate: ``,
        exception: '',
    }
}
const getTimesheetNonComplianeReportPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0,
        projectid: '{}',
        yearmonth: Number(moment(new Date()).format('YYYYMM')),
        limit: 10,
        pageno: 1,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        billingtypeid: 0,
        servicelineid: 0,
        businessunitid: 0,
        weekstartdate: ``,
        weekenddate: ``,
        noncompliancetype: ``,
    }
}

const getDemandSummaryReportsPayload = (reportid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        servicelineid: 0,
        requesttype: '',
        priority: '',
        companyid: 0,
        skillareaid: 0,
        demandstatusid: 0,
        locationid: 0,
        limit: 10,
        page: 1,
        reportid,
    }
}

const getExitPipelineReportsPayload = (reportid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        projectid: 0,
        servicelineid: 0,
        businessunitid: 0,
        companyid: 0,
        yearmonthid: `{${moment(new Date()).format('YYYYMM')}}`,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        workforcegroupid: 0,
        departmentid: 0,
        locationid: 0,
        limit: 10,
        page: 1,
        reportid,
    }
}

const getFinanceReportPayload = (limit, page) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        projecttypeid: 0,
        accountid: 0,
        projectid: '{}',
        projectcategory: '',
        customerid: 0,
        billtypeid: 0,
        limit,
        page,
        sorttype: 1,
        servicelineid: 0,
        projectmanagerid: 0,
        deliverymanagerid: 0,
        yearmonthid: `{${moment().format('YYYYMM')}}`,
        projectstatusid: 0,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
    }
}

const getuserDocumentPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comuserid: Number(localStorage.getItem('comuserid') || 0),
    }
}

const getBudgetofProjectPayload = (Limit = 10, Page = 1, projectid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Projectbudgetid: 0,
        projectid,
        // Limit,
        // Page,
        Sorttype: 1,
        Searchname: '',
    }
}

const getTenantEducationData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        dateducationmasterid: 0,
    }
}

const getReportMasterPayload = () => {
    return {
        reportid: 0,
        reportname: '',
        reporttype: '',
        datmenuid: JSON.parse(localStorage.getItem('datmenuid')),
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        notes: '',
    }
}
const getDeafultTalentPayload = () => {
    return {
        empstatus: '{}',
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        username: '',
        status: 'Active',
        COMUserId: 0,
        allocationstatusid: 0,
        companyid: 0,
        usertypeid: 0,
        workertypeid: 0,
        workforcegroup: 0,
        designationmasterid: 0,
        departmentid: 0,
        mintotalexperience: 0,
        maxtotalexperience: 0,
        skillmasterid: 0,
        technologymasterid: 0,
        locationid: 0,
        limit: 10,
        pageno: 1,
        sorttype: 3,
        secondaryskillid: 0,
        loginuserid: Number(localStorage.getItem('comuserid') || 0),
    }
}

const getDeafultProfilePayload = (COMUserId = 0) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        username: '',
        status: 'Active',
        COMUserId,
        allocationstatusid: '{}',
        companyid: 0,
        workertypeid: '{}',
        workforcegroupid: '{}',
        designationmasterid: 0,
        departmentid: 0,
        mintotalexperience: 0,
        maxtotalexperience: 0,
        skillmasterid: '{}',
        technologymasterid: 0,
        city_id: '{}',
        limit: 10,
        pageno: 1,
        sorttype: 3,
        secondaryskillid: 0,
        minbandid: 0,
        maxbandid: 0,
        demandid: 0,
        matchstatus: '{}',
    }
}

const getWorkForceReportsPayload = (reportid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        loginuserid: Number(localStorage.getItem('comuserid') || 0),
        Userid: 0,
        Primaryskillid: 0,
        Technologymasterid: 0,
        Domainid: 0,
        Companyid: 0,
        Workforcegroup: 0,
        Allocationstatusid: '{}',
        Status: '',
        Cityid: 0,
        Emptypeid: 0,
        Benchageindays: 0,
        Limit: 10,
        Page: 1,
        Secondaryskillid: 0,
        servicelineid: 0,
        Empstatus: 0,
        reportid,
    }
}

const getReleasePlanReportsPayload = (reportid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0,
        servicelineid: 0,
        companyid: 0,
        workertypeid: 0,
        domainid: 0,
        skillid: 0,
        technologyid: 0,
        locationid: 0,
        workforcegroupid: 0,
        allocationstatusid: '{}',
        projectid: '{}',
        specifieddate: '',
        releaseplan: 0,
        limit: 10,
        page: 1,
        reportid,
    }
}

const getPdpAgingReportsPayload = (reportid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        servicelineid: 0,
        companyid: 0,
        usertype: 0,
        bandid: 0,
        limit: 10,
        page: 1,
        reportid,
    }
}

const getStaffingReportsPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        projectid: '{}',
        servicelineid: 0,
        yearmonth: 0,
        startdate: moment(new Date()).subtract(11, 'months').format('YYYY/MM/DD'),
        enddate: moment(new Date()).format('YYYY/MM/DD'),
        userid: 0,
        staffingstatusid: 0,
        projecttypeid: 0,
        billingtypeid: 0,
        projectstatusid: 0,
        limit: 10,
        page: 1,
    }
}

const getEmployeeUtilizationReportsPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0, //Number(localStorage.getItem('comuserid') || 0),
        servicelineid: 0,
        companyid: 0,
        locationid: 0,
        designationid: 0,
        bandid: 0,
        primaryskillid: 0,
        skillareamasterid: 0,
        startyearmonth: Number(moment().subtract(11, 'months').format('YYYYMM')),
        endyearmonth: Number(moment(new Date()).format('YYYYMM')),
        startMonthid: new Date().getMonth() + 1,
        startYearid: new Date().getFullYear() - 1,
        endMonthid: new Date().getMonth() + 1,
        endYearid: new Date().getFullYear(),
        limit: 10,
        page: 1,
        sorttype: 1,
        searchname: '',
        utilizationgroup: '',
    }
}

const getProjectUtilizationReportsPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: 0, //Number(localStorage.getItem('comuserid') || 0),
        servicelineid: 0,
        companyid: 0,
        locationid: 0,
        designationid: 0,
        bandid: 0,
        primaryskillid: 0,
        skillareamasterid: 0,
        startyearmonth: moment().subtract(11, 'months').format('YYYYMM'),
        endyearmonth: moment(new Date()).format('YYYYMM'),
        startMonthid: new Date().getMonth() + 1,
        startYearid: new Date().getFullYear() - 1,
        endMonthid: new Date().getMonth() + 1,
        endYearid: new Date().getFullYear(),
        limit: 10,
        page: 1,
        sorttype: 1,
        searchname: '',
        utilizationgroup: '',
        projectservicelineid: 0,
        workertypeid: 0,
        workforcegroupid: 0,
        projectid: '{}',
        businessunitid: 0,
    }
}

const getUserEducationPayload = () => {
    return {
        comusereducationid: null,
        comuserid: Number(localStorage.getItem('comuserid') || 0),
        dateducationmasterid: 0,
        datcoursemasterid: 0,
        datspecialisationid: 0,
        university_institute: '',
        cousetype: 'f',
        gradeorclass: 'A',
        percentageofmarks: '',
        periodofeducation: '',
        yearofpassing: '',
        remarks: 'passed',
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        isactiveflag: false,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: '',
    }
}

const getPreviousExperiencePayload = () => {
    return {
        comuserprevexperienceid: null,
        comuserid: Number(localStorage.getItem('comuserid') || 0),
        companyname: '',
        designation: '',
        joiningdate: 0,
        releivingdate: 0,
        ctc: 10000,
        jobprofile: 'IT',
        fulltimeorparttimeflag: 'F',
        remarks: '',
        createdtimestamp: '2023-06-21T12:20:37.54248',
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: '',
        username: '',
    }
}

const getWorkerTypePayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        technologymasterid: 0,
        techgroup: 0,
    }
}

const getCityPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comstateid: 0,
        comcityid: 0,
        limit: 25,
        page: 1,
        sorttype: 1,
        searchname: '',
        status: 'ACTIVE',
    }
}
const getDefaultStatePayload = (page = 0, limit = 25, sortype, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid')),
        comcountryid: 0,
        comstateid: 0,
        limit,
        page,
        sorttype: 3,
        searchname: '',
        status,
    }
}

const getDefaultCustomerPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customername: '',
        cityId: 0,
        stateId: 0,
        countryid: 0,
        companyid: 0,
        accountid: 0,
        industryid: 0,
        regionid: 0,
        sorttype: 1,
        statusname: 'ALL',
    }
}
const getDefaultRegionPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comregionid: 0,
    }
}

const getDefaultProjectTypePayload = (projecttypeid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        projecttypeid,
        sorttype: 1,
    }
}

const getDefaultCompanyPayload = (searchname = '', status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        searchname,
        status,
        sorttype: 1,
    }
}

const getDefaultOverheadPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        overheadmasterid: 0,
        // "limit":  25,
        // "page" : 1,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
        costtype: '',
    }
}

const getDefaultBillingIdPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datprojectbillingtypeid: 0,
        sorttype: 1,
    }
}

const getDefaultStatusPayload = (statustype = 'T') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        statustype: statustype,
        sorttype: 1,
    }
}

const getDefaultDeliveryPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        deliveryroleid: null,
    }
}

const getTaskMaster = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    }
}

const getDefaultServiceLinePayload = (skillareamasterid = 0, searchname = '', sorttype = 1, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        servicelineid: 0,
        servicelinegroupid: 0,
        searchname,
        status,
        sorttype,
        skillareamasterid,
    }
}

const getDefaultBusinessUnitPayload = (status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        businessunitid: 0,
        status,
    }
}
const getBusinessUnitGroupData = (status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        businessunitgroupid: 0,
        status,
    }
}

const getDefaultCurrencyPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datcomcurrencyid: 0,
    }
}

const getDefaultOpportunityPayload = (opportunityid = 0, sorttype = 3, limit = 10) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        opportunityid,
        searchname: '',
        limit,
        page: 1,
        sorttype,
        Status: 'ACTIVE',
    }
}

const getDefaultCustomerWeekdayPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customerid: 0,
        customerweekdaysid: 0,
    }
}

const getGenderPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        datcomgenderid: 0,
    }
}
const getContactsPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        customerid: 0,
    }
}

const getDefaultTechPayload = (page, limit, sorttype = 3, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        techgroup: 0,
        technologymasterid: 0,
        page,
        limit,
        sorttype,
        searchname: '',
        status,
    }
}

const getDefaultProductPayload = (technologymasterid = 0) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        productmasterid: 0,
        technologymasterid,
    }
}

const getDefaultUserPayload = (COMUserId = 0, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        username: '',
        status: 'Active',
        COMUserId,
        allocationstatusid: 0,
        companyid: 0,
        usertypeid: 0,
        workforcegroup: 0,
        designationmasterid: 0,
        departmentid: 0,
        mintotalexperience: 0,
        maxtotalexperience: 0,
        skillmasterid: 0,
        technologymasterid: 0,
        locationid: 0,
        limit: 20,
        pageno: 1,
        sorttype,
    }
}

const getMasterUserPayload = (COMUserId = 0, sorttype = 1, limit) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        username: '',
        status: 'Active',
        COMUserId,
        allocationstatusid: 0,
        companyid: 0,
        usertypeid: 0,
        workforcegroup: 0,
        designationmasterid: 0,
        departmentid: 0,
        mintotalexperience: 0,
        maxtotalexperience: 0,
        skillmasterid: 0,
        technologymasterid: 0,
        locationid: 0,
        limit,
        pageno: 1,
        sorttype,
    }
}

const getDefaultCoursePayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datcoursemasterid: 0,
        dateducationmasterid: 0,
    }
}

const getDefaultCourseListPayload = (limit = 10, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datcoursemasterid: 0,
        dateducationmasterid: 0,
        searchname: '',
        status: 'ACTIVE',
        limit,
        page: 1,
        sorttype,
    }
}

const getDefaultEducationListPayload = (limit = 10, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datcoursemasterid: 0,
        dateducationmasterid: 0,
        searchname: '',
        status: 'ACTIVE',
        limit,
        page: 1,
        sorttype,
    }
}

const getDefaultSpecListPayload = (limit = 10, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datspecialisationid: 0,
        datcoursemasterid: 0,
        searchname: '',
        status: 'ACTIVE',
        limit,
        page: 1,
        sorttype,
    }
}

const getDefaultSpecialisationPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        datspecialisationid: 0,
        datcoursemasterid: 0,
    }
}

const getTenCustomerGroupId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customergroupid: 0,
        searchname: '',
        sorttype: 3,
        status: 'ACTIVE',
    }
}
const getTenantCustomerId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customerid: 0,
    }
}

const getCountryId = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        comcountryid: 0,
        limit: 25,
        page: 1,
        sorttype: 1,
        searchname: '',
        status: 'ALL',
    }
}
const getSourceId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        sourcemasterid: 0,
    }
}

const getDeafultSkillAreaData = (searchname = '', sorttype = 1, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillareamasterid: 0,
        status,
        searchname,
        sorttype,
        servicelineid: 0,
    }
}

const getDeafultSkillData = (page = 1, limit, sorttype = 1, searchname = '', status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillmasterid: 0,
        skillareamasterid: 0,
        limit,
        page,
        sorttype,
        searchname,
        status,
    }
}
const getMasterSkillData = (page = 1, limit, sorttype = 3, searchname = '', status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        skillmasterid: 0,
        skillareamasterid: 0,
        limit,
        page,
        sorttype,
        searchname,
        status,
    }
}
const getDeafultIndustryData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        industryid: 0,
    }
}
const getDeafultProficiencyData = (type = 'S') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        levelmasterid: 0,
        leveltype: type,
    }
}
const getDeafultTechnologyData = (searchname = '', sorttype = 1, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        techgroup: 0,
        technologymasterid: 0,
        searchname,
        status,
        sorttype,
    }
}
const getDeafultTechGroupData = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        techgroupid: 0,
    }
}
const getStatusMasterIdData = (statustype = 'D') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        statustype,
    }
}

const getTenantCompanyIdData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit: 10,
        page: 1,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getTenantCompanyMasterData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit: 10,
        page: 1,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
    }
}
const getTenantCompanyWeedayData = (companyid = 0) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid,
        companyweekdaysid: 0,
    }
}
const getCompanyHolidayIdData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        companyholidaysid: 0,
        limit: 10,
        page: 1,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getCustomerHolidayIdData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customerid: 0,
        customerholidaysid: 0,
        limit: 10,
        page: 1,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getMasterCompanyIdData = (page, limit, sorttype = 1, searchname, status) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getTenantWorkForceyIdData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        wfgid: 0,
    }
}

const getBusinessUnitData = (searchname = '', status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        businessunitid: 0,
        searchname,
        status,
    }
}

const getUserTypeIdData = (limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        datusertypeid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getTenantWorkerTypeyIdData = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        workertypeid: 0,
    }
}

const getWorkerTypeyMasterData = (limit = 10, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        workertypeid: 0,
        searchname: '',
        status: 'ACTIVE',
        limit,
        page: 1,
        sorttype,
    }
}

const getDesignationMasterIdData = (page, limit, sorttype = 1, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        desingationMasterId: 0,
        limit,
        page,
        sorttype,
        status,
        searchname: '',
    }
}

const getBandMasterIdData = (searchname = '', status = 'Active') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        bandmasterid: 0,
        searchname,
        status,
        sorttype: 1,
        /* limit,
      page,
    sorttype */
    }
}
const getBandMasterData = (searchname = '', limit, status = 'Active', sorttype = 4) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        bandmasterid: 0,
        searchname,
        status,
        limit,
        page: 1,
        sorttype,
    }
}

const getDepartmentMasterIdData = (searchname = '', sorttype = 1, status = 'ACTIVE') => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        departmentid: 0,
        searchname,
        status,
        sorttype,
    }
}
const getDepartmentDefaultIdData = (page, limit, sorttype = 3, searchname, status) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        departmentid: 0,
        limit,
        page: 1,
        sorttype,
        status: 'Active',
    }
}

const getRoleMasterIdData = (limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 3,
        Roleid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getTechGroupMasterIdData = (page, limit, sorttype = 1, status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 3,
        techgroupid: 0,
        page,
        limit,
        sorttype,
        status,
    }
}

const getDefaultLocationPayload = (searchname = '', status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comlocationid: 0,
        searchname,
        status,
    }
}

const getDefaultCountryPayload = (page, limit, sorttype = 3, status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        comcountryid: 0,
        limit: 25,
        page: 1,
        sorttype: 1,
        searchname: '',
        status,
    }
}
const getMasterCountryPayload = (page, limit, sorttype = 1, searchname = '') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 0,
        comcountryid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}
const getDefaultCityPayload = (searchname = '', status = 'ACTIVE') => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        comstateid: 0,
        comcityid: 0,
        searchname,
        status,
    }
}

const getWorkTypePayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        workertypeid: 0,
    }
}
const getWorkModePayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        workmodeid: 0,
    }
}

const getDemandListPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        cityid: 0,
        billabletype: '',
        assignedto: 0,
        skillid: 0,
        technologyid: 0,
        priority: '',
        requesttype: '',
        companyid: 0,
        role: '',
        requesteddate: '',
        startdate: '',
        Limit: 25,
        Page: 1,
        Sorttype: 3,
        Searchname: '',
    }
}

const getUserAccessPayload = (page, sorttype = 3, limit = 10) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid')),
        Roleid: `{}`,
        companyid: 0,
        datusertypeid: 0,
        userstatus: 'Active',
        limit,
        page: 1,
        sorttype,
        username: '',
        empstatus: 0,
    }
}
const getUserAccessMasterPayload = (page, sorttype = 3, limit = 10) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid')),
        Roleid: `{}`,
        companyid: 0,
        datusertypeid: 0,
        userstatus: 'Active',
        limit,
        page: 1,
        sorttype,
        username: '',
        empstatus: 0,
    }
}
const getDefaultTimeTypePayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        timetypeid: 0,
    }
}
const getDefaultNationalityPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        nationalityid: 0,
    }
}
const getTaskGroupPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 3,
        taskgroupid: 0,
        limit: 25,
        page: 1,
        sorttype: 3,
        searchname: '',
    }
}
const getTaskGroupDefaultPayload = (page, limit = 10, sorttype = 3, searchname, status) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')) || 3,
        taskgroupid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}
const getExchangeRatePayload = (limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        currencyfrom: 0,
        currencyto: 0,
        exchangerateid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        Status: 'ACTIVE',
        Startdate: '',
        Enddate: '',
    }
}

const getTaskMasterProjectPayload = (taskgroupid = 0, tasktypeid = 1, taskid = 0) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        taskgroupid,
        taskid,
        tasktypeid,
        limit: 10,
        page: 1,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    }
}

const getTenantsPayload = (Sorttype = 3, Limit = 10) => {
    return {
        Tenantid: 0,
        Limit,
        Page: 1,
        Sorttype,
        Searchname: '',
        Status: 'ACTIVE',
    }
}

const getRevenuePayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Projectid: 0,
        customerid: 0,
        billtypeid: 0,
        businessunitid: 0,
        year: new Date().getFullYear(),
        quarter: 'Q1',
        projectcategory: '',
        accountid: 0,
        servicelineid: 0,
        projectmanagerid: 0,
        deliverymanagerid: 0,
        projectstatusid: 0,
        regionid: 0,
        method: 'A',
        Reporttype: 'Revenue',
        Reportlevel: 'Region',
        companyid: 0,
        projecttypeid: 0,
    }
}

const notificationRowDefault = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        announcementid: 0,
        limit: 25,
        page: 1,
    }
}

const getSubscriptionsPayload = (sorttype = 3, limit = 10) => {
    return {
        datsubscriptionpackageid: 0,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'Active',
    }
}

const ProfitabilityItem = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Projectid: 0,
        customerid: 0,
        billtypeid: 0,
        businessunitid: 0,
        year: new Date().getFullYear(),
        quarter: 'Q1',
        projectcategory: '',
        accountid: 0,
        servicelineid: 0,
        projectmanagerid: 0,
        deliverymanagerid: 0,
        projectstatusid: 0,
        regionid: 0,
        method: 'A',
        Reporttype: 'Profitablity',
        Reportlevel: 'Region',
        companyid: 0,
        projecttypeid: 0,
    }
}

const RevenueItem = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Projectid: 0,
        customerid: 0,
        billtypeid: 0,
        businessunitid: 0,
        year: new Date().getFullYear(),
        quarter: 'Q1',
        projectcategory: '',
        accountid: 0,
        servicelineid: 0,
        projectmanagerid: 0,
        deliverymanagerid: 0,
        projectstatusid: 0,
        regionid: 0,
        method: 'A',
        Reporttype: 'Revenue',
        Reportlevel: 'Region',
        companyid: 0,
        projecttypeid: 0,
        // currencyid: 0,
    }
}

const WorkforceAllocationItem = () => {
    const today = moment()
    const fiscalYear = today.month() < 3 ? today.year() - 1 : today.year()
    const startDate = moment(`${fiscalYear}-04-01`).format('YYYY-MM-DD')
    const endDate = today.format('YYYY-MM-DD')

    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
        countryid: 0,
        emptypeid: 0,
        billable: 'Yes',
        status: 'Active',
        reportlevel: 'SERVICELINEGROUP',
        startdate: startDate, // Changed to financial year start (April 1st)
        enddate: endDate,
        servicelinegroupid: 0,
        servicelineid: 0,
        businessunitid: 0,
        customergroupid: 0,
        customerid: 0,
    }
}

export {
    ProfitabilityItem,
    RevenueItem,
    getBandMasterData,
    WorkforceAllocationItem,
    getBandMasterIdData,
    getBudgetofProjectPayload,
    getBusinessUnitData,
    getBusinessUnitGroupData,
    getCityPayload,
    getCompanyHolidayIdData,
    getContactsPayload,
    getCountryId,
    getCustomerHolidayIdData,
    getDeafultIndustryData,
    getDeafultProficiencyData,
    getDeafultProfilePayload,
    getDeafultSkillAreaData,
    getDeafultSkillData,
    getDeafultTalentPayload,
    getDeafultTechGroupData,
    getDeafultTechnologyData,
    getDefaultBillingIdPayload,
    getDefaultBusinessUnitPayload,
    getDefaultCityPayload,
    getDefaultCompanyPayload,
    getDefaultCountryPayload,
    getDefaultCourseListPayload,
    getDefaultCoursePayload,
    getDefaultCurrencyPayload,
    getDefaultCustomerPayload,
    getDefaultCustomerWeekdayPayload,
    getDefaultDeliveryPayload,
    getDefaultEducationListPayload,
    getDefaultLocationPayload,
    getDefaultNationalityPayload,
    getDefaultOpportunityPayload,
    getDefaultOverheadPayload,
    getDefaultProductPayload,
    getDefaultProjectPayload,
    getDefaultProjectTypePayload,
    getDefaultRegionPayload,
    getDefaultServiceLinePayload,
    getDefaultSpecListPayload,
    getDefaultSpecialisationPayload,
    getDefaultStatePayload,
    getDefaultStatusPayload,
    getDefaultTechPayload,
    getDefaultTimeTypePayload,
    getDefaultUserPayload,
    getDemandListPayload,
    getDemandSummaryReportsPayload,
    getDepartmentDefaultIdData,
    getDepartmentMasterIdData,
    getDesignationMasterIdData,
    getEmployeeUtilizationReportsPayload,
    getExchangeRatePayload,
    getExitPipelineReportsPayload,
    getFinanceReportPayload,
    getGenderPayload,
    getMasterCompanyIdData,
    getMasterCountryPayload,
    getMasterSkillData,
    getMasterUserPayload,
    getPdpAgingReportsPayload,
    getPreviousExperiencePayload,
    getProjectUtilizationReportsPayload,
    getReleasePlanReportsPayload,
    getReportMasterPayload,
    getRevenuePayload,
    getRoleMasterIdData,
    getSourceId,
    getStaffingReportsPayload,
    getStatusMasterIdData,
    getSubscriptionsPayload,
    getTaskGroupDefaultPayload,
    getTaskGroupPayload,
    getTaskMaster,
    getTaskMasterProjectPayload,
    getTaskTypePayload,
    getTechGroupMasterIdData,
    getTenCustomerGroupId,
    getTenantCompanyIdData,
    getTenantCompanyMasterData,
    getTenantCompanyWeedayData,
    getTenantCustomerId,
    getTenantEducationData,
    getTenantWorkForceyIdData,
    getTenantWorkerTypeyIdData,
    getTenantsPayload,
    getTimesheetExceptionReportPayload,
    getTimesheetNonComplianeReportPayload,
    getTimesheetReportPayload,
    getUserAccessMasterPayload,
    getUserAccessPayload,
    getUserEducationPayload,
    getUserTypeIdData,
    getWorkForceReportsPayload,
    getWorkModePayload,
    getWorkTypePayload,
    getWorkerTypePayload,
    getWorkerTypeyMasterData,
    getuserDocumentPayload,
    notificationRowDefault,
    getTimesheetTrackingReportPayload,
}
