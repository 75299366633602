import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { GET_DEMAND_MATCH } from 'src/Redux/actions/configuration'
import { GET_USER_TYPE_LIST } from 'src/Redux/actions/masterdata/user'
import { GET_DEMAND_MASTER } from 'src/Redux/actions/workforce/demands'
import { GET_ALLOCATION_STATUS } from 'src/Redux/actions/workforce/eprofile'
import { GET_ALLOCATION_STATUS_FOR_EMPLOYEE, GET_WORKFORCEGROUP_MASTER } from 'src/Redux/actions/workforce/talent'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import {
    getStatusMasterIdData,
    getTenantWorkForceyIdData,
    getUserTypeIdData,
} from 'src/Services/constant/defaultPayload'
import { getDemandMatchPayload } from 'src/Services/constant/masterdatapayload'
import Actionheader from './ActionHeader'
import ActiveTab from './activeTab'
import BaseDemandInfo from './baseDemandInfo'

const WorkforceDemandDetails = ({ modulename, menu }) => {
    const [filterTab, setFilterTab] = useState('')
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const dispatch = useDispatch()
    const location = useLocation()
    const DemandDetailsList = useSelector((state) => state?.DemandDetailsList)
    const { demandData, demandMatch, demandMatchProfile } = DemandDetailsList
    const { allocationstatuslist } = useSelector((state) => state?.EmployeeDetails)
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const demandid = searchParams.get('demandid')

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [menu])

    useEffect(() => {
        dispatch(GET_DEMAND_MASTER(+demandid))
        dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_ALLOCATION_STATUS())
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))
        dispatch(GET_DEMAND_MATCH(getDemandMatchPayload()))
    }, [demandid, dispatch])

    const extractStatusIds = () => {
        const entry = demandMatch?.find((item) => item?.includestatus && item?.includestatus?.length > 0)
        if (entry) {
            try {
                return entry.includestatus.map((status) => status.includestatusid)
            } catch (error) {
                console.error('Error extracting status IDs:', error)
            }
        }
        if (allocationstatuslist?.length > 0) {
            return allocationstatuslist?.map((status) => status.statusid)
        }
        return []
    }

    return (
        <>
            <div className="w-full h-[calc(100%-80px)]">
                <Actionheader demandData={demandData} filterTab={filterTab} setFilterTab={setFilterTab} />
                <div className="flex justify-start h-full gap-4">
                    <BaseDemandInfo demandData={demandData} demandMatchProfile={demandMatchProfile} />
                    <ActiveTab
                        demandData={demandData}
                        setFilterTab={setFilterTab}
                        filterTab={filterTab}
                        extractStatusIds={extractStatusIds}
                        thisPagePermissions={thisPagePermissions}
                        demandMatch={demandMatch}
                    />
                </div>
            </div>
            <ToastContainer position="bottom-center" newestOnTop limit={1} />
        </>
    )
}

export default memo(WorkforceDemandDetails)
