import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import SelectMonth from 'src/Components/SelectMonth'
import SelectProjects from 'src/Components/SelectProjects'
import { getDefaultExpensePayload } from 'src/Pages/app/Financials/expenses/deafultPayload'
import { GET_PROJECT_EXPENSES } from 'src/Redux/actions/projects/financials'

const FinancialsFilterExpense = ({
    project,
    ProjectOverhead,
    filters,
    setFilters,
    setExpenses,
    setProject,
}) => {
    const dispatch = useDispatch()
    const { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)

    const handleInputChange = (e) => {
        let { name, value } = e.target
        setFilters({ ...filters, [name]: value })
    }

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        const parsedMonth = parseInt(month, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear

        setFilters({
            ...filters,
            [name]: value,
            Monthid: validMonthid,
            Yearid: validYearid,
        })
    }

    const clearFilters = () => {
        if (projectsOfM?.length > 0) {
            setFilters(getDefaultExpensePayload(0))
            setProject({ projectid: 0, projectname: 'All' })
            dispatch(
                GET_PROJECT_EXPENSES(getDefaultExpensePayload(0)),
            )
        }
    }

    const applyFilters = async () => {
        let res = await dispatch(
            GET_PROJECT_EXPENSES({ ...filters, Projectid: project?.projectid }),
        )
        setExpenses(res)
    }

    return (
        <div className="grid grid-cols-1 gap-4 p-2 text-sm bg-white rounded-md lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-1">
            <div>
                <p className="font-[500] pb-1">Select Project</p>
                <SelectProjects
                    hideLabel={true}
                    Options={projectsOfM}
                    setSelected={setProject}
                    selected={project}
                />
            </div>
            <div>
                <p className="font-[500]">Overhead</p>
                <CustomhtmlSelect
                    options={ProjectOverhead || []}
                    value={filters?.Overheadid || ''}
                    noDataLabel="No Overhead"
                    name="Overheadid"
                    labelkey="overhead"
                    className="w-full p-1.5 mt-1 text-gray-600 border rounded-md"
                    onChange={handleSelectChange}
                    id="overheadmasterid"
                />
            </div>
            <div>
                <p className="font-[500]">Cost Center Code</p>
                <input
                    value={filters?.Costcentercode || ''}
                    onChange={handleInputChange}
                    name="Costcentercode"
                    placeholder="Cost Center Code"
                    type="text"
                    className="w-full p-1.5 mt-1 text-gray-600 border rounded-md"
                />
            </div>
            <div>
                <p className="font-[500]">Month/Year</p>
                <SelectMonth
                    onChange={handleMonthChange}
                    name="Monthid"
                    className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                    year={filters?.Yearid}
                    month={
                        filters?.Monthid?.length === 1
                            ? `0${filters?.Monthid}`
                            : filters?.Monthid
                    }
                />
            </div>
            <div></div>
            <div className="flex justify-end mt-0">
                <div className="w-40">
                    <h1 className="mb-1 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialsFilterExpense
