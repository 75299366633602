import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';
import SubscriptionPricing from './subscriptionpricing';
import { GET_TENANT_SUBSCRIPTION_PRICING_BY_ID } from 'src/Redux/actions/subscriptions';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { AlertError } from 'src/Services';
import { cycleList } from 'src/Services/constant/staticValues';
import { subscriptionItem } from '../defaultRowAndData';

const SubscriptionDetails = (props) => {
   const { setCanEdit, canEdit, comtenantid, setPricingData, setSubscriptionData, pricingData, subscriptionData, subscriptionSave } = props;
   const dispatch = useDispatch();
   const { subscriptionList, tenantSubscriptionById } = useSelector((state) => state.SubscriptionList);
   const [checkvalidation, setCheckvalidation] = useState(false);

   useEffect(() => {
      dispatch(GET_TENANT_SUBSCRIPTION_PRICING_BY_ID({
         Tenantid: comtenantid
      }));
   }, [comtenantid, dispatch]);

   useEffect(() => {
      if (Array.isArray(tenantSubscriptionById) && tenantSubscriptionById.length > 0) {
         let rmUnwanted = tenantSubscriptionById.map((it) => {
            delete it['createdtimestamp'];
            delete it['modifiedtimestamp'];
            return it;
         });

         setSubscriptionData(rmUnwanted[0]);

         const updatedPricingData = tenantSubscriptionById.map((item) => ({
            uId: item?.uId,
            minusers: item?.minusers,
            maxusers: item?.maxusers,
            unitprice: item?.unitprice,
            datcomcurrencyid: item?.datcomcurrencyid,
            comtenantid: item?.comtenantid,
         }));
         setPricingData(updatedPricingData);
      } else {
         setSubscriptionData([])
         setPricingData([]); // Ensure pricingData is empty when tenantSubscriptionById is empty
      }
   }, [tenantSubscriptionById, setSubscriptionData, setPricingData]);

   const handleUpdatedPricingData = (updatedPricingData) => {
      setPricingData(updatedPricingData);       // Update the payload state with the updated pricing data
   };

   // console.log(tenantSubscriptionById)
   const handleSubmit = async (e) => {
      e.preventDefault();
      setCheckvalidation(true);
      if (subscriptionData.subscriptionfromdate && subscriptionData.subscriptiontodate) {
         const startDate = new Date(subscriptionData.subscriptionfromdate);
         const endDate = new Date(subscriptionData.subscriptiontodate);

         if (startDate >= endDate) {
            AlertError('End Date must be greater than Start Date');
            return;
         }
      }
      // Check if pricing data is empty
      if (pricingData.length === 0) {
         AlertError('Pricing data is empty');
         return;
      }
      // Check if any mandatory fields are missing
      const isAnyFieldEmpty = pricingData.some(item => (
         item?.minusers === '' ||
         item?.maxusers === '' ||
         item?.unitprice === '' ||
         item?.datcomcurrencyid === 0
      ));
      if (isAnyFieldEmpty) {
         AlertError('Mandatory fields are empty');
         return;
      }
      // Check if any pricing data fields are not positive numbers
      const isAnyFieldNotPositive = pricingData.some(item => (
         parseFloat(item?.minusers) <= 0 ||
         parseFloat(item?.maxusers) <= 0 ||
         parseFloat(item?.unitprice) <= 0
      ));
      if (isAnyFieldNotPositive) {
         AlertError('Please enter positive values for Min Users/ Max Users/ Unit Price');
         return;
      }

      // Check if Max Users is less than Min Users
      const isMaxUsersLessThanMinUsers = pricingData?.some((item, index, array) => {
         if (Number(item?.maxusers) < Number(item?.minusers)) {
            return true
         } else if (Number(item?.maxusers) < Number(item?.minusers)) {
            return true
         } else if (index > 0 && (Number(array[index - 1]?.maxusers) < Number(item?.minusers))) {
            return false
         } else if (index > 0 && (Number(array[index - 1]?.maxusers) > Number(item?.minusers))) {
            return true
         } else {
            return false
         }
      });

      if (isMaxUsersLessThanMinUsers) {
         AlertError('Maximum Users cannot be less than Minimum Users');
         return;
      }
      const item = {
         tenantsubscriptionid: subscriptionData?.tenantsubscriptionid,
         comtenantid: parseInt(comtenantid),
         datsubscriptionpackageid: subscriptionData?.datsubscriptionpackageid,
         subscriptionfromdate: subscriptionData?.subscriptionfromdate,
         subscriptiontodate: subscriptionData?.subscriptiontodate,
         graceperiodindays: 0,
         istrialaccount: true,
         activeflag: true,
         isadvancesubscriptionrenew: true,
         createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
         billingcycle: subscriptionData?.billingcycle || 2,
         tenantsubscriptionpricing: pricingData.map((pricing) => ({
            ...pricing,
            comtenantid: parseInt(comtenantid),
            maxusers: pricing.maxusers, // Use pricingData value instead of subscriptionData
            minusers: pricing.minusers, // Use pricingData value instead of subscriptionData
            unitprice: pricing.unitprice, // Use pricingData value instead of subscriptionData
            datcomcurrencyid: pricing.datcomcurrencyid, // Use pricingData value instead of subscriptionData
            isactiveflag: true,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: ''
         })),
      };

      let isValidated = CheckObjectValidation(subscriptionData, [
         'graceperiodindays',
         'notes',
         'minusers',
         'istrialaccount',
         'isadvancesubscriptionrenew',
         'billingcycle',
         'tenantsubscriptionid',
         'comtenantid',
         'maxusers',
         'unitprice',
         'datcomcurrencyid'
      ]);

      if (isValidated?.isvalid) {
         let payload = {
            tenantsubscriptionjson: [item],
         };
         subscriptionSave(payload);
      }
   };

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);
      if (name === 'datsubscriptionpackageid') {
         setSubscriptionData({...subscriptionItem(), datsubscriptionpackageid: subscriptionData?.datsubscriptionpackageid, [name]: value});
         setPricingData(subscriptionItem().tenantsubscriptionpricing);
      } else {
         setSubscriptionData({ ...subscriptionData, [name]: value });
      }
   };

   return (
      <div className="w-full text-sm my-4">
         <div className="grid grid-cols-4 gap-10 mt-4">
            <div>
               <label htmlFor="datsubscriptionpackageid" className="block font-[500] mb-2">
                  Subscription <span className="text-red-300">*</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={subscriptionList || []}
                  value={subscriptionData?.datsubscriptionpackageid || ''}
                  noDataLabel='No Subscription Package'
                  name='datsubscriptionpackageid'
                  labelkey='packagename'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='datsubscriptionpackageid'
               />
               {!subscriptionData?.datsubscriptionpackageid && checkvalidation && (
                  <small className="text-red-400">Subscription Package is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='subscriptionfromdate' className='block font-[500] mb-2'>
                  Start Date
                  <span className="text-red-300">*</span>
               </label>
               <FlatPickrDateInput
                  disabled={canEdit}
                  value={subscriptionData?.subscriptionfromdate || ''}
                  name='subscriptionfromdate'
                  id='subscriptionfromdate'
                  className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                  onChangeDate={e => handleChangeInput('subscriptionfromdate', e.target.value)}
               />
               {!subscriptionData?.subscriptionfromdate && checkvalidation && (
                  <small className='text-red-400'>Start Date is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='subscriptiontodate' className='block font-[500] mb-2'>
                  End Date
                  <span className="text-red-300">*</span>
               </label>
               <FlatPickrDateInput
                  disabled={canEdit}
                  value={subscriptionData?.subscriptiontodate || ''}
                  name='subscriptiontodate'
                  id='subscriptiontodate'
                  className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                  onChangeDate={e => handleChangeInput('subscriptiontodate', e.target.value)}
               />
               {!subscriptionData?.subscriptiontodate && checkvalidation && (
                  <small className='text-red-400'>End Date is required!</small>
               )}
            </div>
            <div>
               <label htmlFor="billingcycle" className="block font-[500] mb-2">
                  Billing Cycle <span className="text-red-300">*</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={cycleList || []}
                  value={subscriptionData?.billingcycle || ''}
                  noDataLabel='No Billing Cycle'
                  name='billingcycle'
                  labelkey='cycle'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='billingcycle'
               />
               {!subscriptionData?.billingcycle && checkvalidation && (
                  <small className="text-red-400">Billing Cycle is required!</small>
               )}
            </div>
         </div>
         <SubscriptionPricing
            canEdit={canEdit}
            comtenantid={comtenantid}
            setSubscriptionData={setSubscriptionData}
            pricingData={pricingData}
            setPricingData={setPricingData}
            handleSubmit={handleSubmit}
            setCanEdit={setCanEdit}
            subscriptionData={subscriptionData}
            tenantSubscriptionById={tenantSubscriptionById}
            handleUpdatedPricingData={handleUpdatedPricingData} // Pass the function to handle updated pricing data
         />
      </div>
   );
};

export default SubscriptionDetails;