const initialState = {
    Employees: null,
    departmentlist: [],
    designationlist: [],
    technologylist: [],
    citylist: [],
    workertypelist: [],
    workforcelist: [],
    companylist: [],
    allocationstatuslist: [],
    displayemployeefilteredlist: [],
    bandList: [],
    tanentPermission: [],
    stauslistdemands: [],
}

const EmployeeDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_EMPLOYEE_DETAILS':
            return {
                ...state,
                Employees: action.data,
            }
        case 'GET_FILTERED_EMPLOYEE_DETAILS':
            return {
                ...state,
                displayemployeefilteredlist: action.data,
            }
        case 'GET_DEPARTMENT_MASTER':
            return {
                ...state,
                departmentlist: action.data,
            }
        case 'GET_DESIGNATION_MASTER':
            return {
                ...state,
                designationlist: action.data,
            }
        case 'GET_BAND_MASTER':
            return {
                ...state,
                bandList: action.data,
            }
        case 'TANENT_PERMISSIONS':
            return {
                ...state,
                tanentPermission: action.data,
            }

        case 'GET_TECHNOLOGY_MASTER':
            return {
                ...state,
                technologylist: action.data,
            }

        case 'GET_CITY_MASTER':
            return {
                ...state,
                citylist: action.data,
            }
        case 'GET_WORKERTYPE_MASTER':
            return {
                ...state,
                workertypelist: action.data,
            }
        case 'GET_WORKFORCEGROUP_MASTER':
            return {
                ...state,
                workforcelist: action.data,
            }
        case 'GET_COMPANY_MASTER':
            return {
                ...state,
                companylist: action.data || [],
            }
        case 'GET_ALLOCATION_STATUS_FOR_EMPLOYEE':
            return {
                ...state,
                allocationstatuslist: action.data,
            }
        case 'GET_STAFF_STATUS_TYPE_W':
            return {
                ...state,
                stauslistdemands: action.data,
            }

        default:
            return state
    }
}

export default EmployeeDetails
