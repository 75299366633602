import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FinancialsFilterExpense from 'src/Filter/projects/financialsFilterExpense'
import {
    GET_OVERHEAD_MASTER,
    GET_PROJECT_EXPENSES,
    GET_PROJECT_EXPENSE_TEMPLATE,
} from 'src/Redux/actions/projects/financials'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import {
    getDefaultExpensePayload,
    getExpenseOverheadPayload,
    getTenantPayload,
} from './deafultPayload'
import Expensetable from './table'

const Expenses = ({ addEditPermission }) => {
    const dispatch = useDispatch()
    const { ProjectOverhead, ProjectExpenses } = useSelector(state => state?.ProjectFinancial)
    const [filters, setFilters] = useState(getDefaultExpensePayload())
    const [expenses, setExpenses] = useState([])
    const [expenseId, setExpenseId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [project, setProject] = useState({ projectid: 0, projectname: 'All' })
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    let { projectsOfM } = useSelector(state => state?.ProjectTimesheet)

    const getDefaultExpensesData = async () => {
        let res = await dispatch(
            GET_PROJECT_EXPENSES({
                ...filters,
                Projectid: 0,
                Limit: limit,
                Page: page,
                Sorttype: sortType?.id,
            }),
        )
        if (res !== null) {
            setExpenses(res)
        }
    }

    useEffect(() => {
        dispatch(GET_TIMESHEET_PROJECTS_M())
        dispatch(GET_OVERHEAD_MASTER(getExpenseOverheadPayload()))
        dispatch(GET_PROJECT_EXPENSE_TEMPLATE(getTenantPayload()))
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(
        () => {
            if (projectsOfM?.length > 0) {
                dispatch(
                    GET_PROJECT_EXPENSES(
                        getDefaultExpensePayload(0),
                    ),
                    null,
                )
            }
        }, // eslint-disable-next-line
        [projectsOfM],
    )

    return (
        <>
            <FinancialsFilterExpense
                cols="grid-cols-4"
                project={project}
                setExpenses={setExpenses}
                setProject={setProject}
                ProjectOverhead={ProjectOverhead}
                ProjectExpenses={ProjectExpenses}
                filters={filters}
                setFilters={setFilters}
                limit={limit}
                page={page}
            />
            <Expensetable
                addEditPermission={addEditPermission}
                sortType={sortType}
                setSortType={setSortType}
                page={page}
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                expenses={expenses}
                expenseId={expenseId}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                project={project}
                ProjectExpenses={ProjectExpenses}
                setExpenseId={setExpenseId}
                setExpenses={setExpenses}
                setProject={setProject}
                filters={filters}
                getDefaultExpensesData={getDefaultExpensesData}
                projectsOfM={projectsOfM}
            />
        </>
    )
}

export default memo(Expenses)
