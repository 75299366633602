const locations = [
    'Bangalore',
    'London',
    'Dubai',
    'New Jersey',
    'London',
    'Mumbai',
    'Delhi',
]
export const insertKeysForProjectStaffing = [
    'plannedallocation',
    'demandid',
    'releasedate',
    'projectstaffid',
    'projroleid',
    'userid',
    'plannedstartdate',
    'plannedenddate',
    'allocation',
    'startdate',
    'enddate',
    'projectid',
    'comtenantid',
    'isactiveflag',
    'createdcomuserid',
    'notes',
]
const projectStatusByDate = [
    { label: 'All Allocations' },
    { label: 'Upcoming' },
    { label: 'Previous' },
    { label: 'Active' },
]
const sortTypeStaffing = [
    { label: 'By End Date', id: 3 },
    { label: 'Latest First', id: 4 },
    { label: 'By Allocation', id: 5 },
]
const basicShortList = [
    { label: 'Name (A-Z)', id: 1 },
    { label: 'Name (Z-A)', id: 2 },
    { label: 'Latest First', id: 3 },
    { label: 'Oldest First', id: 4 },
]
const requestedDates = ['< 1 week', '1-3 weeks', '3-6 weeks', '> 6 weeks']
const assignedTolist = ['Naresh ', 'Praveen', 'Abhay', 'Vicky']
const primarySkills = [
    'Account Management',
    'CXO',
    'Delivery Management',
    'Finance',
    'Admin',
    'Human Resource Management',
    'Culture & Employee Engagement',
    'Talent Acquisition',
    'Product Innovation',
    'Marketing',
    'Operations',
    'Project Management',
    'Sales',
    'Salesforce - Functional',
    'Salesforce - Technical',
    'SAP - Functional',
    'SAP - Technical',
    'Testing',
    'Domain Expertise',
]
const technologies = [
    'SAP ABAP',
    'SAP BASIS',
    'SAP CX',
    'SAP FICO',
    'SAP SF',
    'SAP SD',
    'SAP FIORI',
    'SAP CAR',
    'SAP MM',
    'SAP PI/PO/CPI',
    'SAP BI/BW/HANA',
    'SAP ByD',
    'SAP PS',
    'SAP PP',
    'SAP LMS',
    'SAP PM',
    'SAP ANALYTICS',
    'SAP QUALTRICS',
    'SALESFORCE',
    'AWS CLOUD',
    'GOOGLE CLOUD',
    'AZURE CLOUD',
    'REACT JS',
    'NODE JS',
    'SQL',
]
const Entitylist = ['Motive Mind IND', 'Trackier', 'Google', 'Mircosoft']
const requestTypelist = ['Opportunity', 'Project', 'Internal']
const roles = [
    'Software Engineer',
    'Architect',
    'CEO',
    'Head Operations',
    'Project Manager',
]
const priorities = ['High', 'Low', 'Medium']
const workmodelist = ['Remote', 'Office', 'Hybrid']
const employeetype = ['Permanent', 'Contract']
const BusinessUnitList = ['Manufacturing', 'Banking', 'Retail', 'Automotive']
const department_list = [
    'Consulting',
    'Culture',
    'Delivery',
    'Finance & Admin',
    'HR',
    'Talent Acquisition',
    'Innovation',
    'IT',
    'Marketing',
    'Operations',
    'Others',
    'Pre-Sales',
    'Product',
    'Sales',
    'Salesforce',
    'SAP ERP',
    'SAP ByD',
    'SAP Cx',
    'SAP SF',
    'Strategy',
    'Testing',
]
const bandList = ['C0', 'C1', 'C2', 'C3', 'C4', 'C5']
const skillarealist = ['Consulting', 'Delivery', 'Technology', 'Domain Expertise']
const skillList = [
    'Functional Consulting',
    'Technical Consulting',
    'Project Management',
    'Infrastructure Management',
    'Database Management',
    'Manufacturing & Supply Chain',
    'Investment Banking',
    'Construction & Real-Estate',
    'Risk Management & Auditing',
    'Demand Forecasting',
]
const ExpertiseList = [
    'Process Blueprinting',
    'Process Optimization',
    'Delivery Planning',
    'Incident Management',
    'Planning & Estimation',
    'Resource Management',
    'Access Management',
    'Infrastructure Design',
    'Data-Modeling',
    'ETLs',
    'Material Sourcing & Vendor Management',
    'Mutual funds & Portfolio Management',
    'Customer lifecycle management',
    'Accounting Standards & Best Practices',
    'Trend analysis & Product line planning',
]
const domainList = ['Manufacturing', 'Construction', 'BFSI', 'Consumer Goods']
const accountlist = ['Permanent', 'Contractual']
const currencylist = ['USD', 'Rs', 'Euro']
const countrylist = ['India', 'USA', 'Brazil', 'SriLanka']
const sources = ['Google', 'Twitter', 'Facebook', 'Others', 'Microsoft']
const companylist = ['Motive Mind IND', 'Trackier', 'Google', 'Mircosoft']
const industrylist = ['IT', 'Manufacturing']
const levels = ['Senior', 'Junior']
const primarySkillList = ['Development', 'Software']
const techlist = ['React', 'Js', 'Java']
const mockemployees = [
    {
        id: 1,
        company: 'ABC Inc.',
        gender: 'Male',
        email: 'john.doe@abcinc.com',
        name: 'John Doe (12345)',
        department: 'Consulting',
        phone: '555-1234',
        status: 'Active',
        employeeType: 'Full-time',
        workforceGroup: 'Deployable',
        designation: 'Sales Manager',
        Primaryskill: 'Operations',
        technology: 'SAP CX',
        allocationStatus: 'Deployed',
        location: 'Delhi',
    },
    {
        id: 2,
        company: 'ABC Inc.',
        gender: 'Female',
        email: 'jane.doe@abcinc.com',
        name: 'Jane Doe (23456)',
        department: 'Culture',
        phone: '555-5678',
        status: 'Active',
        employeeType: 'Part-time',
        workforceGroup: 'Non-Deployable',
        designation: 'Accountant',
        Primaryskill: 'SAP - Technical',
        technology: 'SAP BASIS',
        allocationStatus: 'Available',
        location: 'Delhi',
    },
    {
        id: 3,
        company: 'XYZ Corp.',
        gender: 'Male',
        email: 'bob.smith@xyzcorp.com',
        name: 'Bob Smith (34567)',
        department: 'Marketing',
        phone: '555-9012',
        status: 'Inactive',
        employeeType: 'Full-time',
        workforceGroup: 'Deployable',
        designation: 'Marketing Director',
        Primaryskill: 'Admin',
        technology: 'SAP SD',
        allocationStatus: 'Partial Deployed',
        location: 'Delhi',
    },
    {
        id: 4,
        company: 'XYZ Corp.',
        gender: 'Female',
        email: 'mary.jones@xyzcorp.com',
        name: 'Mary Jones (45678)',
        department: 'Operations',
        phone: '555-3456',
        status: 'Active',
        employeeType: 'Contractor',
        workforceGroup: 'Deployable',
        designation: 'Software Engineer',
        Primaryskill: 'Account Management',
        technology: 'SAP CAR',
        allocationStatus: 'Deployed',
        location: 'Delhi',
    },
]
const educationRowDefault = () => {
    return {
        comusereducationid: null,
        comuserid: Number(localStorage.getItem('comuserid') || 0),
        dateducationmasterid: 0,
        datcoursemasterid: 0,
        datspecialisationid: 0,
        university_institute: '',
        cousetype: '',
        gradeorclass: '',
        percentageofmarks: '',
        periodofeducation: '',
        yearofpassing: '',
        remarks: '',
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: '',
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    }
}

let skillRowDefault = () => {
    return {
        userskilltechnologyjson: [
            {
                comuserskilltechnologyid: null,
                comuserid: Number(localStorage.getItem('comuserid') || 0),
                skillmasterid: null,
                comtenantid: Number(localStorage.getItem('Tenantid') || 0),
                isactiveflag: true,
                createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
                notes: '',
                expertise: '',
                remarks: '',
                techversion: '',
                technologymasterid: 0,
                lastusedyear: '',
                experienceinmonths: '',
                proficiencyid: 0,
                domainid: 0,
                isprimary: false,
                uId:
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36),
            },
        ],
    }
}
const experienceRowDefault = () => {
    return {
        comuserprevexperienceid: null,
        comuserid: Number(localStorage.getItem('comuserid') || 0),
        companyname: '',
        designation: '',
        joiningdate: '',
        releivingdate: '',
        ctc: 0,
        jobprofile: '',
        fulltimeorparttimeflag: '',
        remarks: '',
        createdtimestamp: '',
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: '',
        username: '',
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    }
}
const certificationRowDefault = () => {
    return {
        comusercertificationid: null,
        certificatefilepath: '',
        comuserid: Number(localStorage.getItem('comuserid') || 0),
        certificationname: '',
        certificationagency: '',
        modulename: '',
        certificationdate:
            /* new Date().toLocaleDateString('fr-CA', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}) */ '',
        certificationexpirydate:
            /* new Date().toLocaleDateString('fr-CA', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}) */ '',
        remarks: '',
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: '',
        username: '',
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    }
}

const reportMasterRowDefault = () => {
    return {
        reportid: 0,
        reportname: '',
        reporttype: '',
        datmenuid: JSON.parse(localStorage.getItem('datmenuid')),
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        notes: '',
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    }
}

const ExactFlexible = [{ name: 'Exact' }, { name: 'Flexible' }]

const LessThan = [{ name: 'Less Than Equal to' }]

const GreaterThan = [{ name: 'Greater Than Equal to' }]
const yesNoWithboolean = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
]
const includenoticeperiod = [{ name: 'Yes' }, { name: 'No' }]

const validationTime = [
    { name: 'None', validationunit: -1 },
    { name: 'Daily', validationunit: 1 },
    { name: 'Weekly', validationunit: 2 },
    // { name: 'Monthly', validationunit: 3 },
]
const timeEntryFormat = [
    { name: 'Effort (XX Hours)', timeentryformat: 'H' },
    { name: 'Duration (HH:MM-HH:MM)', timeentryformat: 'D' },
]

const timeTypeUniquenes = (type) => {
    if (type === 'P') {
        return [
            { name: 'None', timetypeuniqueness: 'N' },
            { name: 'Project', timetypeuniqueness: 'P' },
        ]
    } else if (type === 'G') {
        return [
            { name: 'None', timetypeuniqueness: 'N' },
            { name: 'Date', timetypeuniqueness: 'D' },
        ]
    }
}
const holidayCalendarAttestaion = [
    { name: 'Company', holidaycalendarintegration: 'CO' },
    { name: 'Customer ', holidaycalendarintegration: 'CU' },
    { name: 'Both', holidaycalendarintegration: 'B' },
    { name: 'None', holidaycalendarintegration: 'N' },
]
const costtypeList = [
    { name: 'Payroll', costtype: 'D' },
    { name: 'Expenses', costtype: 'I' },
]
const statuslist = [
    { name: 'Domain', skilltype: 'Domain' },
    { name: 'Generic', skilltype: 'Generic' },
    { name: 'Technical', skilltype: 'Technical' },
]
const eventListforProject = [{ name: 'Create Project' }]
const eventListforStaffing = [
    { name: 'User Staffed' },
    { name: 'User Released' },
    { name: 'User Extended' },
    { name: 'Staffing Rejected' },
    { name: 'Staffing Approval' },
    { name: 'Release Approval' },
    { name: 'Extension Approval' },
]
const eventListforTimesheet = [
    { name: 'Timesheet Submitted' },
    { name: 'Timesheet Approved' },
    { name: 'Timesheet Rejected' },
    { name: 'Submission Reminder' },
    { name: 'Approval Reminder' },
]
const eventListforDemand = [
    { name: 'Demand Created' },
    { name: 'Demand Edited ' },
    { name: 'Demand Fulfilled' },
]
const eventListforEprofile = [{ name: 'Update Reminder' }]
const eventListforUsermanagement = [
    { name: 'user created' },
    { name: 'user decactivated' },
    { name: 'forgot password' },
]
const modesList = [
    { name: 'Event Based' },
    { name: 'Scheduled' },
]
const ChannelList = [
    { name: 'Email' },
    { name: 'SMS' },
    { name: 'App' },
    { name: 'Whatsapp' },
]

const FrequencyList = [
    { name: 'Daily' },
    { name: 'Weekly' },
    { name: 'Every 3 Hours' },
    { name: 'Every 6 Hours' },
]


const messageToList = [{ name: 'Employee' }, { name: 'Program Manager' }]
const ccList1 = [{ name: 'Program Manager' }]
const moduleList = [
    { name: 'Project' },
    { name: 'Staffing' },
    { name: 'Timesheet' },
    { name: 'Demands' },
    { name: 'Eprofile' },
    { name: 'User Management' },
]
const paramsListforStaffing = [
    { paramsid: 1, name: 'projcode' },
    { paramsid: 2, name: 'projname' },
    { paramsid: 3, name: 'startdate' },
    { paramsid: 4, name: 'enddate' },
    { paramsid: 5, name: 'alloc' },
    { paramsid: 6, name: 'pmname' },
]

const menuList = [
    { menuid: 1, name: 'Projects' },
    { menuid: 2, name: 'Planning' },
    { menuid: 3, name: 'Staffing' },
    { menuid: 4, name: 'Timesheet' },
    { menuid: 5, name: 'Demands' },
    { menuid: 6, name: 'Taleet' },
    { menuid: 7, name: 'E-Profile' },
    { menuid: 8, name: 'Finance' },
    { menuid: 9, name: 'Finance Reports' },
    { menuid: 10, name: 'Project Reports' },
    { menuid: 11, name: 'Workforce Reports' },
    { menuid: 12, name: 'Finance Dashboards' },
    { menuid: 13, name: 'Project Dashboards' },
    { menuid: 14, name: 'Workforce Dashboards' },
    { menuid: 15, name: 'Configuration' },
    { menuid: 16, name: 'Masterdata' },
    { menuid: 17, name: 'Use Access' },
]
const permissionslist = [
    { permissionid: 1, name: 'Add User' },
    { permissionid: 2, name: 'Add Customer' },
    { permissionid: 3, name: 'Add Project' },
    { permissionid: 4, name: 'Add Demand' },
]
const cycleList = [
    { billingcycle: 1, cycle: 'Weekly' },
    { billingcycle: 2, cycle: 'Monthly' },
    { billingcycle: 3, cycle: 'Quarterly' },
    { billingcycle: 4, cycle: 'BiAnnual' },
    { billingcycle: 5, cycle: 'Annual' },
    { billingcycle: 6, cycle: 'Milestone Based' },
]
export {
    cycleList,
    permissionslist,
    menuList,
    ccList1,
    paramsListforStaffing,
    eventListforDemand,
    eventListforEprofile,
    eventListforStaffing,
    eventListforProject,
    eventListforTimesheet,
    eventListforUsermanagement,
    modesList,
    messageToList,
    moduleList,
    includenoticeperiod,
    LessThan,
    GreaterThan,
    statuslist,
    costtypeList,
    holidayCalendarAttestaion,
    timeTypeUniquenes,
    timeEntryFormat,
    validationTime,
    locations,
    // same start date
    requestedDates,
    assignedTolist,
    primarySkills,
    technologies,
    roles,
    requestTypelist,
    Entitylist,
    priorities,
    employeetype,
    workmodelist,
    BusinessUnitList,
    department_list,
    bandList,
    skillarealist,
    skillList,
    ExpertiseList,
    domainList,
    accountlist,
    currencylist,
    countrylist,
    sources,
    companylist,
    industrylist,
    levels,
    primarySkillList,
    techlist,
    mockemployees,
    projectStatusByDate,
    sortTypeStaffing,
    basicShortList,
    ExactFlexible,
    yesNoWithboolean,
    educationRowDefault,
    skillRowDefault,
    experienceRowDefault,
    certificationRowDefault,
    reportMasterRowDefault,
    ChannelList,
    FrequencyList
}
