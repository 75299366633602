import { ACTION_GET_PMO_LIST } from "src/Redux/types"

const initialState = {
   usertypelist: [],
   rolelist: [],
   userlistforadmin: [],
   businessList: [],
   businessGroupList: [],
   serviceList: [],
   serviceGroup: [],
   technologyGroup: [],
   stateList: [],
   locationList: [],
   regionList: [],
   countryList: [],
   cityList: [],
   users: [],
   deliveryRoleList: [],
   userSkillAreas: [],
   customerGroupList: [],
   taskList: [],
   tasks: [],
   genderList: [],
   timeType: [],
   userProfile: [],
   taskGroups: [],
   taskGroupList: [],
   skillArea: [],
   overheadsList: [],
   costratesList: null,
   exchangeratesList: [],
   opportunity: [],
   productMasterList: [],
   dmList: [],
   getStatusByTypeE: [],
   companyHolidaysList: [],
   companyHoliday: [],
   customerHolidaysList: [],
   customerHoliday: [],
   customerWeekdays: [],
   companyWeekdaysList: [],
   companyWeekdays: [],
   customerWeekdaysList: [],
   announcementList: [],
   announcements: [],
   PermissionsOfMasterdata: [],
   PermissionsOfUserAccess: [],
   pmoList: [],
   workerTypeList: [],
   courseList: [],
   educationList: [],
   specList: [],
   industryList: [],

}

const UserManagementList = (state = initialState, action) => {
   switch (action.type) {
      case "GET_LIST_OF_STATUS_BY_E":
         return { ...state, getStatusByTypeE: action.data }
      case "GET_USER_TYPE_LIST":
         return { ...state, usertypelist: action.data }
      case "GET_ROLE_MASTER":
         return { ...state, rolelist: action.data }
      case "GET_DM_LIST":
         return { ...state, dmList: action.data }
      case ACTION_GET_PMO_LIST:
         return { ...state, pmoList: action.data }
      case "GET_USER_ACCESS_LIST":
         return { ...state, userlistforadmin: action.data }
      case "GET_BUSINESS_MASTER":
         return { ...state, businessList: action.data }
      case "GET_BUSINESSGROUP_MASTER":
         return { ...state, businessGroupList: action.data }
      case "GET_SERVICELINE_MASTER":
         return { ...state, serviceList: action.data }
      case "GET_SERVICEGROUP_MASTER":
         return { ...state, serviceGroup: action.data }
      case "GET_TECHNOLOGY_GROUP":
         return { ...state, technologyGroup: action.data }
      case "GET_STATE_MASTER":
         return { ...state, stateList: action.data }
      case "GET_LOCATION_MASTER":
         return { ...state, locationList: action.data }
      case "GET_REGION_MASTER":
         return { ...state, regionList: action.data }
      case "GET_COUNTRY_MASTER":
         return { ...state, countryList: action.data }
      case "GET_CITY_MASTER":
         return { ...state, cityList: action.data }
      case "GET_DELIVERY_ROLE_MASTER":
         return { ...state, deliveryRoleList: action.data }
      case "GET_CUSTOMER_GROUP_MASTER":
         return { ...state, customerGroupList: action.data }
      case "GET_TASK_MASTER":
         return { ...state, taskList: action.data }
      case 'SAVE_TASK_AS_DRAFT ':
         return { ...state, tasks: action.data?.Message ? [] : action.data, };
      case 'SAVE_USER_AS_DRAFT':
         return { ...state, users: action.data?.Message ? [] : action.data, };
      case "GET_GENDER_MASTER":
         return { ...state, genderList: action.data }
      case "GET_TIME_TYPE":
         return { ...state, timeType: action.data }
      case 'SAVE_USER_PROFILE_AS_DRAFT':
         return { ...state, userProfile: action.data?.Message ? [] : action.data, };
      case 'SAVE_TASK_GROUP_AS_DRAFT':
         return { ...state, taskGroups: action.data?.Message ? [] : action.data, };
      case "GET_NATIONALITY_MASTER":
         return { ...state, nationality: action.data }
      case "GET_TASK_GROUPS_MASTER":
         return { ...state, taskGroupList: action.data }
      case 'SAVE_SKILL_AREA_MASTER':
         return { ...state, skillArea: action.data?.Message ? [] : action.data, };
      case "GET_OVERHEADS":
         return { ...state, overheadsList: action.data }
      case "GET_COST_RATES":
         return { ...state, costratesList: action.data }
      case 'SAVE_OVERHEADS_MASTER':
         return { ...state, overheads: action.data?.Message ? [] : action.data, };
      case 'SAVE_COST_RATES':
         return { ...state, costrates: action.data?.Message ? [] : action.data, };
      case 'GET_EXCHANGE_RATE_MASTER':
         return { ...state, exchangeratesList: action.data };
      case 'SAVE_EXCHANGE_RATE':
         return { ...state, exchangerate: action.data?.Message ? [] : action.data, };
      case 'SAVE_OPPORTUNITY_MASTER':
         return { ...state, opportuniy: action.data?.Message ? [] : action.data, };
      case 'GET_PRODUCT_MASTERLIST':
         return { ...state, productMasterList: action.data };
      case 'GET_COMPANY_HOLIDAYS_MASTERLIST':
         return { ...state, companyHolidaysList: action.data };
      case 'SAVE_COMPANY_HOLIDAY_MASTER':
         return { ...state, companyHoliday: action.data?.Message ? [] : action.data, };
      case 'GET_CUSTOMER_HOLIDAYS_MASTERLIST':
         return { ...state, customerHolidaysList: action.data };
      case 'SAVE_CUSTOMER_HOLIDAY_MASTER':
         return { ...state, customerHoliday: action.data?.Message ? [] : action.data, };
      case 'SAVE_CUSTOMER_WEEKDAYS':
         return { ...state, customerWeekdays: action.data?.Message ? [] : action.data, };
      case 'GET_COMPANY_WEEKDAYS':
         return { ...state, companyWeekdaysList: action.data };
      case 'SAVE_COMPANY_WEEKDAYS':
         return { ...state, companyWeekdays: action.data?.Message ? [] : action.data, };
      case 'GET_WEEKDAYS_OF_A_CUSTOMER':
         return { ...state, customerWeekdaysList: action.data };
      case 'GET_ANNOUNCEMENT_MASTER':
         return { ...state, announcementList: action.data };
      case 'GET_WORKERTYPE_LIST_MASTER':
         return { ...state, workerTypeList: action.data };
      case 'GET_COURSE_LIST_MASTER':
         return { ...state, courseList: action.data };
      case 'GET_EDUCATION_LIST_MASTER':
         return { ...state, educationList: action.data };
      case 'GET_SPEC_LIST_MASTER':
         return { ...state, specList: action.data };
      case 'GET_INDUSTRY_LIST_MASTER':
         return { ...state, industryList: action.data };
      case 'SAVE_ANNOUNCEMENT_MASTER':
         return { ...state, announcements: action.data };
      case 'SAVE_PERMISSIONS_OF_MASTERDATA':
         return { ...state, PermissionsOfMasterdata: action.data };
      case 'SAVE_PERMISSIONS_OF_USER_ACCESS':
         return { ...state, PermissionsOfUserAccess: action.data };
      default:
         return state

   }
}

export default UserManagementList






