
import { useState, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function SingleSelectField({ options, handleSelected, value, labelKey, disabled, valueKey, rowId }) {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('')
    const [getByTitle, setGetByTitle] = useState('')
    const dropdownRef = useRef(null);
    const employeeTimesheetData = useSelector(state => state?.ProjectTimesheet?.employeeTimesheetData)
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            const debounceTimer = setTimeout(() => {
                let it = options?.find(el => el[valueKey] === value);
                if (it) {
                    let items = employeeTimesheetData?.map((item) => {
                        if (item?.uId === rowId) {
                            item['newChange'] = true
                            item['selectedAllocation'] = it
                            item['projstaffid'] = it?.projectstaffid
                            item['rolename'] = it?.rolename
                        }
                        return item
                    })
                    dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
                    setGetByTitle(`${it?.rolename} | ${it?.allocation} % | ${it?.startdate} - ${it?.enddate}` || '')
                    setQuery(`${it?.rolename} | ${it?.allocation} % | ${it?.startdate} - ${it?.enddate}` || '')
                }else{
                    setGetByTitle('')  
                    setQuery('')
                }
            }, 500)
            return () => {
                clearTimeout(debounceTimer)
            }
        } catch (error) {
            setGetByTitle('')
            setQuery('')
        }
        // eslint-disable-next-line
    }, [valueKey, options, value])

    const handleOpen = () => {
        setOpen(true)
        setQuery('')
    }

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredList =
        query === ''
            ? options
            : options.filter(el =>
                el?.rolename
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query?.toLowerCase().replace(/\s+/g, '')),
            )
    
    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative ">
                <button disabled={disabled} onClick={handleOpen} className='w-full border border-primary/40 py-1 rounded flex justify-start items-center px-1 bg-white'>
                    <input value={query || getByTitle}
                        disabled={disabled}
                        placeholder={getByTitle || 'Select Allocation'}
                        onChange={(e) => setQuery(e.target.value || ' ')}
                        className='w-full text-xs focus:outline-none placeholder:text-primary/40 ' />
                </button>
            </div>
            {open && <div className="transition z-[1000] max-h-44 ease-in duration-100 absolute mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {filteredList?.map((item, itemIdx) => {
                    return (
                        <div
                            key={item?.uId || itemIdx}
                            className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-2 cursor-pointer ${item[valueKey] === value ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                            onClick={() => {
                                handleSelected(item)
                                setOpen(!open)
                                setQuery(`${item?.rolename} | ${item?.allocation} % | ${item?.startdate} - ${item?.enddate}`)
                            }}
                        >
                            <span>
                                {`${item?.rolename} | ${item?.allocation} % | ${item?.startdate} - ${item?.enddate}`}
                            </span>
                            {item[valueKey] === value &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                            }
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}


const AlloocationById = ({ hooks: { it, rowId, allocations } }) => {
    const employeeTimesheetData = useSelector(state => state?.ProjectTimesheet?.employeeTimesheetData)
    const dispatch = useDispatch()

    const getSelected = (valueis) => {
        let items = employeeTimesheetData.map((item) => {
            if (item?.uId === rowId) {
                item['selectedAllocation'] = valueis
                item['projstaffid'] = valueis?.projectstaffid
                item['rolename'] = valueis?.rolename
                item['Allocationenddate'] = valueis?.enddate
                item['Allocationstartdate'] = valueis?.startdate
                item['allocation'] = valueis?.allocation
            }
            return item
        })
        dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
    }

    return (
        <SingleSelectField
            rowId={rowId}
            valueKey='projectstaffid'
            labelKey='rolename'
            options={allocations}
            value={it?.projstaffid || null}
            disabled={it?.curstatus === 'Approved'}
            handleSelected={getSelected}
        />
    )
}

export default memo(AlloocationById);
