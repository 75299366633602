import moment from 'moment'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_STAFFING_DETAILS_REPORT,
} from 'src/Redux/actions/reports'

const cols = [
    'Employee Name',
    'Company',
    'Worker Type',
    'Workforce Group',
    'Designation',
    'Band',
    'Department',
    'Primary Skill',
    'Service Line',
]
const cols1 = [
    'Employee Name',
    'Project Name',
    'Customer Name',
    'Project Manager',
    'Project Start',
    'Project End',
    'Project Type',
    'Project Status',
    'Project Category',
]
const cols2 = [
    'Employee Name',
    'Billing Type',
    'Customer Role',
    'Bill Rate',
    'Currency',
    'Billing Unit',
    'Start Date',
    'End Date',
    'Allocation %',
    'Staffing Status',
]

const rows = [10, 25, 50]

const StaffingReportList = ({
    filters,
    reportMasterList,
    reportId,
    projectIds,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const [currentColsIndex, setCurrentColsIndex] = useState(0)
    const allCols = [cols, cols1, cols2]

    const handleRightSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex + 1) % allCols.length)
    }

    const handleLeftSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex - 1) % allCols.length)
    }

    const currentCols = allCols[currentColsIndex]
    const { staffingDetailsReport } = useSelector((state) => state?.WorkforceReport)
    const handlePagination = (page) => {
        dispatch(
            GET_STAFFING_DETAILS_REPORT({
                ...filters,
                limit: limit,
                page: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        let data = filters
        if (filters['projectid'] === '{}') {
            data['projectid'] = `{${projectIds?.join(',')}}`
        }
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_STAFFING_DETAILS_REPORT({
                ...data,
                limit: row,
                page: row > 10 ? 1 : page,
            }),
        )
    }

    const totalRecords = useMemo(() => {
        return staffingDetailsReport?.length > 0
            ? staffingDetailsReport[0]?.totalcount
            : staffingDetailsReport?.length
    }, [staffingDetailsReport])

    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={currentColsIndex === 0}
                        onClick={handleLeftSlide}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={currentColsIndex === 2}
                        onClick={handleRightSlide}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>
                <div className="flex items-center justify-between w-full space-x-6">
                    <p className="text-primary font-[500]">
                        Report Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32 ">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        fileName={fileName}
                        limit={totalRecords}
                        endpoint="Download_projectstaffingdetailreport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        filters={filters}
                    />
                </div>
            </div>
            {staffingDetailsReport === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(90%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {currentCols.map((col) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="p-2 font-[500] border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {staffingDetailsReport?.map((item) => {
                                let {
                                    businessunitname,
                                    employeecode,
                                    employeename,
                                    workertype,
                                    workforcegroup,
                                    designation,
                                    bandname,
                                    department,
                                    primaryskill,
                                    servicelinename,
                                    projectname,
                                    customername,
                                    projectstartdate,
                                    projectenddate,
                                    projecttype,
                                    projectstausname,
                                    billingtype,
                                    rolename,
                                    billratehr,
                                    allocation,
                                    allocationstartdate,
                                    allocationenddate,
                                    staffstatusname,
                                    uId,
                                    projectcode,
                                    currency,
                                    billingunit,
                                    projectmanagername,
                                    projectcategory,
                                    projectmanagercode,
                                } = item
                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {currentColsIndex === 0 && (
                                            <>
                                                <td className="p-1 px-2 border w-[240px]">
                                                    {' '}
                                                    {employeename}{' '}
                                                    {employeecode
                                                        ? `(${employeecode})`
                                                        : ''}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {businessunitname}
                                                </td>
                                                <td className="w-[100px] p-1 px-2 border">
                                                    {workertype}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {workforcegroup}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {designation}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {bandname}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {department}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {primaryskill}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {servicelinename}
                                                </td>
                                            </>
                                        )}
                                        {currentColsIndex === 1 && (
                                            <>
                                                <td className="p-1 px-2 border w-[240px]">
                                                    {' '}
                                                    {employeename}{' '}
                                                    {employeecode
                                                        ? `(${employeecode})`
                                                        : ''}{' '}
                                                </td>
                                                <td className="p-1 px-2 border w-[210px]">
                                                    {projectname} ({projectcode})
                                                </td>
                                                <td className="p-1 px-2 border  w-[190px]">
                                                    {customername}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {projectmanagername}{' '}
                                                    {projectmanagercode
                                                        ? `(${projectmanagercode})`
                                                        : ''}{' '}
                                                </td>
                                                <td className="p-1 px-2 border w-[110px]">
                                                    {projectstartdate}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {projectenddate}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {projecttype}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {projectstausname}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {projectcategory === 'I'
                                                        ? 'Internal'
                                                        : 'Customer'}
                                                </td>
                                            </>
                                        )}
                                        {currentColsIndex === 2 && (
                                            <>
                                                <td className="p-1 px-2 border w-[240px]">
                                                    {' '}
                                                    {employeename}{' '}
                                                    {employeecode
                                                        ? `(${employeecode})`
                                                        : ''}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {billingtype}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {rolename}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {billratehr}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {currency}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {billingunit}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {moment(allocationstartdate).format(
                                                        'DD-MM-YYYY',
                                                    ) || ''}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {moment(allocationenddate).format(
                                                        'DD-MM-YYYY',
                                                    ) || ''}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {allocation !== null
                                                        ? `${(allocation * 100).toFixed(
                                                            0,
                                                        )}%`
                                                        : 'N/A'}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {staffstatusname}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default StaffingReportList
