import SelectInput from "src/Components/SelectInput";

const ServiceLine = ({ servicelineList, serviceLine, setServiceLine }) => {
    return (
        <>
            <SelectInput
                className="bg-gray-50 border p-[8px]"
                options={servicelineList || []}
                keyname='servicelinename'
                selected={serviceLine}
                setSelected={setServiceLine}
            />
        </>
    )
}

export default ServiceLine;