import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState } from 'react'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = props => {
	const { detailSave, detailsData, setDetailsData } = props
	const [checkvalidation, setCheckvalidation] = useState(false);

	const handleSubmit = async e => {
		setCheckvalidation(true);

		let item = {
			...detailsData,
		};
		let isValidated = CheckObjectValidation(item, ['taskgroupid', 'notes']);
		if (isValidated?.isvalid) {
			let payload = {
				taskgroupjson: [detailsData],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='taskgroupdesc' className='block font-[500] mb-2'>
						Task Group Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						maxLength={50}
						type='text'
						title='taskgroupdesc'
						id='taskgroupdesc'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-lg block w-80 p-2'
						placeholder='Task Group Name'
						required=''
						onChange={(e) => {
							if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
								handleChangeInput('taskgroupdesc', e.target.value);
							}
						}}
					/>
					{!detailsData?.taskgroupdesc && checkvalidation && (
						<small className='text-red-400'>Task Group Name is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				<div className='gap-2 flex justify-end items-center'>
					<PrimaryButton
						className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
						onClick={handleSubmit}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default memo(Details)
