import {useEffect, useState} from 'react'
import {getDeafultSkillData} from 'src/Services/constant/defaultPayload'
import {GET_SKILL_MASTER} from 'src/Redux/actions/workforce/eprofile'
import {useDispatch} from 'react-redux'
import SingleSelectField from 'src/Components/SingleSelectForTable'

const SkillComponent = ({
	className,
	canEdit,
	value,
	rowId,
	handleChange,
	skillareamasterid,
	isprimary,
}) => {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	let isMounted = true

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			isMounted = false
		}
	}, [])

	useEffect(() => {
		setLoading(true)
		let payload = {
			...getDeafultSkillData(),
			skillareamasterid: Number(skillareamasterid || 0),
		}
		dispatch(GET_SKILL_MASTER(payload))
			.then(res => {
				if (isMounted) {
					setLoading(false)
					setData(res?.data)
				}
			})
			.catch(err => {
				if (isMounted) {
					setLoading(false)
				}
			})
		// eslint-disable-next-line
	}, [dispatch, skillareamasterid])

	const getSelected = value => {
		let e = {
			target: {
				name: 'skillmasterid',
				value: value['skillmasterid'],
				objectValue: value,
			},
		}
		handleChange(e, rowId)
	}

	return (
		<div>
			{loading ? (
				<span className='text-xs'> Loading...</span>
			) : (
				<>
					<SingleSelectField
						valueKey='skillmasterid'
						labelKey='skill'
						options={data}
						value={value || 0}
						handleSelected={getSelected}
						disabled={isprimary || !canEdit}
						/>
				</>
			)}
		</div>
	)
}

export default SkillComponent
