


const BaseProjectInfo = ({ projectDetail }) => {

    return (
        <div className="w-3/12 h-[calc(100%-400px)] ">
            <div className="bg-white h-auto  mb-2 text-sm shadow-md rounded-lg p-2.5">
                <div className="border-b border-primary mb-1.5 ">
                    <p className="pb-1 text-sm font-[500]">Project Details</p>
                </div>
                <p className="mb-1">Company : <span className="font-normal text-gray-500">{projectDetail?.companyname || 'N/A'}</span></p>
                <p className="mb-1">Customer : <span className="font-normal text-gray-500">{projectDetail?.customername || 'N/A'} </span></p>
                <p className="mb-1">Account Group : <span className="font-normal text-gray-500">{projectDetail?.accountname || 'N/A'}</span></p>
                <p className="mb-1">Service Line : <span className="font-normal text-gray-500">{projectDetail?.servicelinename || 'N/A'} </span></p>
                <p className="mb-1">Business Unit : <span className="font-normal text-gray-500">{projectDetail?.businessunitname || 'N/A'} </span></p>
                <p className="mb-1">Project Type : <span className="font-normal text-gray-500">{projectDetail?.projecttype || 'N/A'}</span></p>
                <p className="mb-1">Billing Type : <span className="font-normal text-gray-500">{projectDetail?.billingtype}</span></p>
            </div>
            <div className="bg-white  h-auto mb-2 text-sm shadow-md rounded-lg p-2.5">
                <div className="border-b border-primary mb-1.5 ">
                    <p className="pb-1 text-sm font-[500]">Opportunity Details</p>
                </div>
                <p className="mb-1">Opportunity : <span className="font-normal text-gray-500">{projectDetail?.opportunityname || 'N/A'}</span></p>
                <p className="mb-1"> Status : <span className="font-normal text-gray-500">{projectDetail?.opportunitystatus || 'N/A'} </span></p>
                <p className="mb-1"> Value : <span className="font-normal text-gray-500"> {projectDetail?.currency} {projectDetail?.totalestimateamt || 'N/A'} </span></p>
                <p className="mb-1">Sales Manager : <span className="font-normal text-gray-500">{projectDetail?.salesmanager || 'N/A'} </span></p>
            </div>
            <div className="bg-white h-full text-sm shadow-md rounded-lg p-2.5 min-h-[25dvh]">
                <div className="border-b border-primary mb-1.5 ">
                    <p className="pb-1 text-sm font-[500]">Contact Details</p>
                </div>
                <p className="mb-1">Project Manager : <span className="font-normal text-gray-500">{projectDetail?.projectmanager || 'N/A'}</span></p>
                <p className="mb-1">Delivery Manager : <span className="font-normal text-gray-500">{projectDetail?.deliverymanager || 'N/A'} </span></p>
                <p className="mb-1">Business Unit Head : <span className="font-normal text-gray-500">{projectDetail?.businessunithead || 'N/A'}</span></p>
                <p className="mb-1">Service Line Head: <span className="font-normal text-gray-500">{projectDetail?.servicelinehead || 'N/A'}</span></p>
                <p>PMO SPOC : <span className="font-normal text-gray-500">{projectDetail?.pmocontactperson || 'N/A'} </span></p>
            </div>
        </div>
    )
}

export default BaseProjectInfo;