import { Tab } from '@headlessui/react'
import { useMemo, useState } from 'react'
import EducationBackground from './educationBackground'
import Skills from './skills'
import EmploymentHistory from './employmentHistory/index'
import Certifications from './Certifications'
import ProjectHistory from './projectHistory'
import EmployeePersonalInfo from './employeePersonalInfo'
import { useDispatch, useSelector } from 'react-redux'
import {
	GET_EMPLOYEE_SUMMARY,
	GET_ALLOCATION_STATUS,
	GET_EMPLOYEE_DOCUMENT_TYPE_LIST,
	SAVE_USER_DOCUMENT,
	GET_EMPLOYEE_DOCUMENT_LIST,
	GET_CERTIFICATIONS,
	GET_SKILLS_EMPLOYEE,
	GET_PROJECTS_EPROFILE,
	GET_EDUCATIONBACKGROUND_LIST,
	GET_PREVIOUS_EXPERIENCE,
} from 'src/Redux/actions/workforce/eprofile'
import { useEffect } from 'react'
import IconButton from 'src/Components/Buttons/IconButton'
import {
	insertUserCertificatePayload,
	insertUserDocumentPayload,
} from './defaultRowData'
import Resume from './resume'
import { AlertError, AlertSuccess, getPermissionsOfCurrentUrl } from 'src/Services'
import { getuserDocumentPayload } from 'src/Services/constant/defaultPayload'
import ResumeBuilder from '../employeeDetails/resumeBuilder'
import { ToastContainer } from 'react-toastify'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const tabs = [
	'Projects',
	'Skills',
	'Experience',
	'Certifications',
	'Education ',
	'Resume',
]

const EprofileLayout = ({ modulename, menu }) => {
	const [canEdit, setCanEdit] = useState(false)
	const [activeTab, setActiveTab] = useState('Projects')
	const dispatch = useDispatch()
	const [cvSaving, setCVSaving] = useState(false)
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const {
		employeeSummary,
		allocationData,
		empDocumentTypeList,
		certifications,
		userSkillsDetail,
		projectsEprofile,
		academicQualificationList,
		previousExperience,
		eprofilePermission
	} = EmployeeProfile
	const [resume, setResume] = useState(insertUserDocumentPayload())
	const [pdfUrl, setPdfUrl] = useState('')
	const [showPdf, setShowPdf] = useState(false)

	useEffect(() => {
		const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(item => item?.permissionname);
		dispatch({ type: 'EPROFILE_PERMISSIONS', data: permissionNames })
		// eslint-disable-next-line
	}, [menu])

	useEffect(() => {
		dispatch(GET_EMPLOYEE_SUMMARY())
		dispatch(GET_EMPLOYEE_DOCUMENT_TYPE_LIST())
		dispatch(GET_ALLOCATION_STATUS())
		dispatch(GET_CERTIFICATIONS())
		dispatch(GET_SKILLS_EMPLOYEE())
		dispatch(GET_PROJECTS_EPROFILE())
		dispatch(GET_EDUCATIONBACKGROUND_LIST())
		dispatch(GET_PREVIOUS_EXPERIENCE())
		// eslint-disable-next-line
	}, [dispatch])

	const convertMonthsToYearsAndMonths = totalMonths => {
		const years = Math.floor(totalMonths / 12)
		const months = totalMonths % 12
		return { years, months }
	}

	const experience = useMemo(() => {
		if (employeeSummary?.length > 0) {
			const totalExperienceInMonths = employeeSummary[0]?.totalexperienceinmonths || 0
			const currentcompanyexperience = employeeSummary[0]?.currentcompanyexperience || 0
			return convertMonthsToYearsAndMonths(totalExperienceInMonths + currentcompanyexperience)
		}

	}, [employeeSummary])

	const resumeDocumentType = empDocumentTypeList?.find(
		item => item.documenttype === 'Resume',
	)

	const certDocumentType = empDocumentTypeList?.find(
		item => item.documenttype === 'Education Certificate',
	)

	const trainingDocumentType = empDocumentTypeList?.find(
		item => item.documenttype === 'Training Certificate',
	)

	const certDocumentTypeId = certDocumentType
		? certDocumentType.documenttypeid
		: null
	const resumeDocumentTypeId = resumeDocumentType
		? resumeDocumentType.documenttypeid
		: null
	const trainingDocumentTypeId = trainingDocumentType
		? trainingDocumentType.documenttypeid
		: null

	let mockObject = insertUserDocumentPayload(resumeDocumentTypeId)
	let certObject = insertUserCertificatePayload(certDocumentTypeId)
	let trainingObject = insertUserCertificatePayload(trainingDocumentTypeId)

	const documentSave = async () => {
		setCVSaving(true)
		let afterModify = []
		let document = { ...resume }
		delete document['comtenantid']
		delete document['createduser']
		document['filepath'] = null
		document['comuserid'] = resume?.comuserid
		document['Tenantid'] = resume?.comtenantid || resume?.Tenantid
		document['documenttypeid'] = resumeDocumentTypeId
		document['documenttype'] = resumeDocumentTypeId
		document['userdocumentattachmentsid'] =
			resume?.userdocumentattachmentsid || null
		if (!document['documenttype']) {
			AlertError('Document type is required !')
			return
		}
		afterModify.push(document)
		let res = await dispatch(SAVE_USER_DOCUMENT(afterModify))
		if (res?.success) {
			setCanEdit(false)
			setPdfUrl(null)
			AlertSuccess('User Document Saved successfully.')
			dispatch(GET_EMPLOYEE_DOCUMENT_LIST(getuserDocumentPayload()))
		}
		setCVSaving(false)
	}
	//WAITING FOR BACKEND CHANGES FOR CERTIFICATE UPLOAD ___DO___NOT____TOUCH_____ //
	/* 	const certificateSave = async () => {
			let afterModify = []
			for (let it of certificate) {
				// console.log(it)
				let document = {...it}
				delete document['comtenantid']
				delete document['createduser']
				delete document['uId']
				document['comuserid'] = it?.comuserid
				document['Tenantid'] = it?.comtenantid || it?.Tenantid
				document['documenttypeid'] = certDocumentTypeId
				document['documenttype'] = certDocumentTypeId
				// document['versionno'] = it?.versionno
				if (!document['documenttype']) {
					AlertError('Document type is required !')
					return
				}
				afterModify.push(document)
			}

			console.log(afterModify)
			let res = await dispatch(SAVE_USER_DOCUMENT(afterModify))
			if (res?.success) {
				setCanEdit(false)
				AlertSuccess('User Document Saved successfully.')
				dispatch(GET_EMPLOYEE_DOCUMENT_LIST(getuserDocumentPayload()))
			}
		} */

	/* 	const trainingCertificateSave = async () => {
			let afterModify = []
			for (let it of training) {
				// console.log(it)
				let document = {...it}
				delete document['comtenantid']
				delete document['createduser']
				delete document['uId']
				document['comuserid'] = it?.comuserid
				document['Tenantid'] = it?.comtenantid || it?.Tenantid
				document['documenttypeid'] = trainingDocumentTypeId
				document['documenttype'] = trainingDocumentTypeId
				// document['versionno'] = it?.versionno
				if (!document['documenttype']) {
					AlertError('Document type is required !')
					return
				}
				afterModify.push(document)
			}
			console.log(afterModify)
			let res = await dispatch(SAVE_USER_DOCUMENT(afterModify))
			if (res?.success) {
				setCanEdit(false)
				AlertSuccess('User Document Saved successfully.')
				dispatch(GET_EMPLOYEE_DOCUMENT_LIST(getuserDocumentPayload()))
			}
		} */
	const generatePDF = () => {
		setShowPdf(true)
	}

	const onClose = () => {
		setShowPdf(false)
	}
	return (
		<>
			<div className='h-[calc(100%-75px)]  w-full '>
				<div className='flex justify-start'>
					<div className='flex items-center justify-start text-sm '>
						<h1 className='text-gray-500 '>
							{employeeSummary[0]?.firstname} {employeeSummary[0]?.lastname}{' '}
							{employeeSummary[0]?.employeecode
								? `(${employeeSummary[0]?.employeecode})`
								: ''}
							<span className='px-2 text-black '>|</span> Total Experience :{' '}
							{experience?.years} Years {experience?.months} Months
							<span className='px-2 text-black '>|</span>{' '}
						</h1>
						<h1 className='text-gray-500 '>
							{' '}
							{employeeSummary[0]?.companyname}{' '}
							<span className='px-2 text-black '>|</span>{' '}
						</h1>
						<div className='flex items-center justify-start ml-1'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 24 24'
								fill='currentColor'
								className='w-3 h-3 text-red-500'
							>
								{' '}
								<path
									fillRule='evenodd'
									d='M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z'
									clipRule='evenodd'
								/>{' '}
							</svg>
							<p className='ml-1 text-gray-500'>
								{employeeSummary[0]?.cityname}{' '}
							</p>
						</div>
						<div className='flex items-center justify-start ml-1'>
							<span className='px-2 text-black '> | </span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-4 h-4 text-gray-600'
							>
								{' '}
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21'
								/>{' '}
							</svg>
							<p className='ml-1 text-gray-500'>
								{employeeSummary[0]?.locationname}
							</p>
						</div>
						<div className='flex items-center justify-start ml-1'>
							<span className='px-2 text-black '> | </span>
							<p className='text-green-600'>
								<b>{allocationData?.Status}</b>
							</p>
						</div>
						<div className='flex items-center justify-start pl-2 space-x-2'>
							<span className='text-black '> | </span>
							<p className='ml-1 text-gray-500'>Total Allocation</p>
							<p className='text-green-600'>
								<b>{((allocationData?.totalallocation || 0) * 100).toFixed(0)} %</b>
							</p>
						</div>
						<div className='flex items-center justify-start ml-1'>
							<span className='px-2 text-black '> | </span>
							<IconButton
								title='Build Resume'
								className='flex items-center justify-center w-6 h-6 p-0 text-white bg-primary'
								onClick={generatePDF}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth='1.5'
									stroke='currentColor'
									className='w-4 h-4'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12'
									/>
								</svg>
							</IconButton>
							{showPdf && (
								<div className=' flex p-5 justify-center fixed top-0 left-0 w-full h-[100vh] bg-white z-[1000] '>
									<ResumeBuilder
										hooks={{
											employeeSummary,
											allocationData,
											certifications,
											userSkillsDetail,
											projectsEprofile,
											academicQualificationList,
											previousExperience,
											onClose,
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='h-full flex items-start justify-start mt-4 space-x-2'>
					<div className='w-3/12  h-[calc(100%-40px)]'>
						<EmployeePersonalInfo />
					</div>
					<div className='w-9/12 p-2 h-[calc(100%-40px)] relative rounded-md shadow-md bg-white'>
						<Tab.Group>
							<div className='h-[2.4rem] w-full flex justify-between items-center border-secondary border-b '>
								<Tab.List className='grid grid-cols-6 gap-2'>
									{tabs?.map((item, i) => {
										return (
											<Tab
												key={i}
												onClick={() => {
													setActiveTab(item)
													setCanEdit(false)
												}}
												className={({ selected }) =>
													classNames(
														'text-sm text-left w-full font-[500] outline-white',
														'ring-white ring-opacity-60 ring-offset-0 ring-offset-white focus:outline-none focus:ring-0',
														selected
															? 'bg-secondary/10 text-secondary font-[500] p-1.5 rounded-md'
															: 'text-gray-500',
													)
												}
											>
												<span className='px-4'> {item}</span>
											</Tab>
										)
									})}
								</Tab.List>
								{eprofilePermission?.includes("Add/Edit Profile") && <>
									{!canEdit && activeTab !== 'Projects' && (
										<IconButton
											role='button'
											title='Edit E-Profile'
											onClick={() => {
												setCanEdit(true)
											}}
											className='flex items-center justify-center rounded-full bg-primary w-7 h-7 '
										>
											{' '}
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-4 h-4 text-white'
											>
												{' '}
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
												/>{' '}
											</svg>{' '}
										</IconButton>
									)}
								</>}
							</div>
							<Tab.Panels>
								<Tab.Panel>
									<ProjectHistory />
								</Tab.Panel>
								<Tab.Panel>
									<Skills setCanEdit={setCanEdit} canEdit={canEdit} />
								</Tab.Panel>
								<Tab.Panel>
									<EmploymentHistory canEdit={canEdit} setCanEdit={setCanEdit} />
								</Tab.Panel>
								<Tab.Panel>
									<Certifications
										canEdit={canEdit}
										setCanEdit={setCanEdit}
										mockObject={certObject}
									/>
								</Tab.Panel>
								<Tab.Panel>
									<EducationBackground
										setCanEdit={setCanEdit}
										canEdit={canEdit}
										mockObject={trainingObject}
									/>
								</Tab.Panel>
								<Tab.Panel>
									<Resume
										pdfUrl={pdfUrl}
										setPdfUrl={setPdfUrl}
										setCanEdit={setCanEdit}
										canEdit={canEdit}
										resume={resume}
										setResume={setResume}
										mockObject={mockObject}
										cvSaving={cvSaving}
										resumeDocumentTypeId={resumeDocumentTypeId}
										documentSave={documentSave}
									/>
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</div>
				</div>
			</div>
			<ToastContainer
				position="bottom-center"
				newestOnTop limit={1} />
		</>
	)
}

export default EprofileLayout
