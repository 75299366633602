import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IconButton from 'src/Components/Buttons/IconButton'
import { GET_PROJECT_STAFFING_LIST } from 'src/Redux/actions/projects/staffing'
import { SAVE_DEMAND_STATUS_MASTER } from 'src/Redux/actions/workforce/demands'

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem('comuserid')),
    }
}

const CancelDemand = ({ demandData, project, limit, page }) => {
    const dispatch = useDispatch()
    let [isOpen, setIsOpen] = useState(false)
    const [feedbackMessage, setFeedbackMessage] = useState('')
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const staffingProjectId = searchParams.get('projectid')

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const handleCancel = async () => {
        const { Tenantid } = getTanAndComId()
        const payload = {
            Tenantid,
            Demandid: demandData?.demandid,
            demandstatus: 'Cancelled',
        }

        let res = await dispatch(SAVE_DEMAND_STATUS_MASTER(payload))
        setFeedbackMessage('')
        if (res !== null) {
            dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId || project?.projectid, null, page, limit))
        } else {
            setFeedbackMessage('Failed to cancel demand.')
        }
        closeModal()
    }

    // console.log(demandData)
    return (
        <Fragment>
            <IconButton
                title="Cancel Demand"
                type="button"
                onClick={openModal}
                className="text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-1 focus:outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </IconButton>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <div className="mt-2">
                                        <p className="text-sm text-primary ml-9">
                                            Are you sure you want to cancel this demand?
                                        </p>
                                        {feedbackMessage && <p className="mt-2 text-red-400">{feedbackMessage}</p>}
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-black bg-white-300 px-4 py-2 text-sm font-[500]text-whitefocus:outline-none focus-visible:ring-2 "
                                            onClick={closeModal}
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-[500] text-white focus:outline-none focus-visible:ring-2 "
                                            onClick={handleCancel}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}
export default CancelDemand
