import SingleSelectField from "src/Components/SingleSelectForTable";
import { useSelector } from 'react-redux'

const Tech = ({ canEdit, value, rowId, handleChange }) => {
  const EmployeeProfile = useSelector(state => state.EmployeeProfile)
  const { technologyList } = EmployeeProfile
    const getSelected = (value) => {
        let e = { target: { name: 'technologymasterid', value: value['technologymasterid'] } }
        handleChange(e, rowId)
    }

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='technologymasterid'
                labelKey='technology'
                options={technologyList}
                value={value}
                handleSelected={getSelected}
                disabled={!canEdit}
            />
        </div>
    )
}

export default Tech;