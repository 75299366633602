import { useSelector } from "react-redux"
import FilterButtonTab from "src/Components/FilterButtonTab"
import CustomhtmlSelect from "src/Components/CustomhtmlSelect";
import PrimaryButton from "src/Components/Buttons/PrimaryButton";
import SelectOpportunity from "./selectOpportunity";
import MultiSelectItem from "src/Components/MultiSelect";

const cycleList = [
    { cycle: "Weekly" },
    { cycle: "Monthly" },
    { cycle: "Quarterly" },
    { cycle: "BiAnnual" },
    { cycle: "Annual" },
    { cycle: "Milestone Based" },
]

const Comercials = ({ hooks }) => {
    const {
        payload, setPayload,
        opportunity, setOpportunity,
        selectedProducts, setSelectedProducts,
        nextToCommercials,
        errorFound, setErrorFound,
    } = hooks
    const projectsReducer = useSelector(state => state.Projects)
    const { technologyGroup } = useSelector((state) => state.UserManagementList);
    const { currencyList, listOfOpportunity, productList } = projectsReducer

    const handleChangeValue = (name, value) => {
        if (errorFound) {
            setErrorFound(null)
        }
        setPayload({ ...payload, [name]: value })
    };
    const selectLicensingRevenue = (option) => {
        setPayload({ ...payload, licensingrevenue: option === 'Yes' ? true : false })
    }

    const handleProductSelect = (product) => {
        let copyProducts = selectedProducts
        let isNew = copyProducts?.find(p => p?.productmasterid === product?.productmasterid)
        if (isNew) {
            let rm = copyProducts?.filter(p => p?.productmasterid !== product?.productmasterid)
            setSelectedProducts(rm)
        } else {
            copyProducts.push({
                comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
                productmasterid: product?.productmasterid,
                productname: product?.productname,
                isactiveflag: true
            })
            setSelectedProducts([...copyProducts])
        }
    }

    return (
        <div className="mt-4 w-full text-sm">
            <div className="h-[64vh] w-full pb-10 overflow-y-scroll">
                <div className="grid grid-cols-4 gap-8">
                    <div>
                        <label htmlFor="billingCurrency" className="block capitalize mb-2  font-[500]">Billing Currency <span className="text-red-300">*</span></label>
                        <CustomhtmlSelect
                            disabled={true}
                            options={currencyList || []}
                            noDataLabel='No Currency Type'
                            value={payload?.defaultcomcurrencyid}
                            name='defaultcomcurrencyid'
                            labelkey='currency'
                            className='border rounded bg-gray-50 py-2'
                            onChange={handleChangeValue}
                            id='datcomcurrencyid'
                        />
                    </div>
                    <div>
                        <label htmlFor="billingCycle" className="block capitalize mb-2  font-[500]">Billing Cycle <span className="text-red-300">*</span></label>
                        <CustomhtmlSelect
                            options={cycleList || []}
                            noDataLabel='No cycle'
                            value={payload?.billingcycle}
                            name='billingcycle'
                            labelkey='cycle'
                            className='border rounded bg-gray-50 py-2'
                            onChange={handleChangeValue}
                            id='cycle'
                        />
                        {errorFound?.keyname === 'billingcycle' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <label htmlFor="totalestimateamt" className="block capitalize mb-2  font-[500]">Planned Revenue  <span className="text-red-300">*</span></label>
                        <input value={payload?.totalestimateamt || ''}
                            type="number"
                            title="totalestimateamt"
                            id="totalestimateamt"
                            onChange={(e) => handleChangeValue('totalestimateamt', e.target.value)}
                            className="bg-gray-50 border border-gray-300 rounded focus:ring-primary outline-none focus:border-primary block w-full p-2"
                            placeholder="" required="" />
                    </div>
                    <div>
                        <label htmlFor="opportunity" className="block capitalize mb-2  font-[500]">Opportunity name</label>
                        <SelectOpportunity
                            options={listOfOpportunity || []}
                            selected={opportunity}
                            setSelected={setOpportunity} />

                        {errorFound?.keyname === 'opportunityid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <label htmlFor="value" className="block capitalize mb-2  font-[500]">Opportunity value</label>
                        <input disabled value={opportunity?.totalopportunityvalue || ''} type="text" title="OpportunityValue" id="OpportunityValue" className="bg-gray-50 border border-gray-300 rounded focus:ring-primary outline-none focus:border-primary block w-full p-2"
                            placeholder="Value" required="" />
                    </div>
                    <div>
                        <label htmlFor="code" className="block capitalize mb-2  font-[500]">Opportunity Code</label>
                        <input disabled value={opportunity?.opportunitycode || ''} type="text" title="code" id="code" className="bg-gray-50 border border-gray-300 rounded focus:ring-primary outline-none focus:border-primary block w-full p-2"
                            placeholder="Opportunity Code" required="" />
                    </div>
                    <div>
                        <label htmlFor="status" className="block capitalize mb-2  font-[500]">Opportunity Status</label>
                        <input disabled value={opportunity?.opportunitystatus || ''} type="text" title="status" id="status" className="bg-gray-50 border border-gray-300 rounded focus:ring-primary outline-none focus:border-primary block w-full p-2"
                            placeholder="Opportunity Status" required="" />
                    </div>
                    <div>
                        <label htmlFor="probabilty" className="block capitalize mb-2  font-[500]">Win Probabilty</label>
                        <input disabled value={`${opportunity?.winprobabilitypercentage || 0}%` || ''} type="text" title="winProbabilty" id="winProbabilty" className="bg-gray-50 border border-gray-300 rounded focus:ring-primary outline-none focus:border-primary block w-full p-2"
                            placeholder="70%" required="" />
                    </div>
                    <div>
                        <label htmlFor="salesManager" className="block mb-2 text-sm font-[500]">Sales Manager</label>
                        <input disabled type="text" value={opportunity?.Salesmanagername || ''} title="salesManager" id="salesManager" className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-2"
                            placeholder="Sales Manager" required="" />
                    </div>
                    <div>
                        <label htmlFor="licensingRevenue" className="block font-[500] mb-2">Licensing Revenue</label>
                        <FilterButtonTab
                            className="p-2 border w-full"
                            tabs={['Yes', 'No']}
                            setSelectedtab={selectLicensingRevenue}
                            selectedTab={payload?.licensingrevenue ? 'Yes' : 'No' || null}
                        />
                    </div>
                    <div>
                        <label htmlFor="technology" className="block capitalize mb-2  font-[500]">Technology Group <span className="text-red-300">*</span></label>
                        <CustomhtmlSelect
                            options={technologyGroup || []}
                            noDataLabel='No Technology'
                            value={payload?.techgroupid}
                            name='techgroupid'
                            labelkey='techarea'
                            className='border rounded bg-gray-50 py-2'
                            onChange={handleChangeValue}
                            id='techgroupid'
                        />
                    </div>
                    <div>
                        <label htmlFor="Product" className="block capitalize mb-2  font-[500]">Product</label>
                        <div className="border p-1 bg-gray-50 rounded">
                            <MultiSelectItem
                                itemIdKey='productmasterid'
                                labelKeyname='productname'
                                limitShow={1}
                                selectedOptions={selectedProducts}
                                options={productList}
                                handleSelection={handleProductSelect}
                            />
                        </div>
                    </div>

                </div>
                {/* <div className="grid grid-cols-4 gap-8 my-7">
                    <div>
                        <label htmlFor="formFileSm" className="form-label inline-block mb-2  font-[500]">Upload SOW</label>
                        <input
                            className="
                         file:mr-2 file:py-2 file:px-4
            file:rounded file:border-0
            file:text-sm file:font-[500]
            hover:file:cursor-pointer file:bg-secondary/10
            file:text-secondary" id="formFileSm" type="file" />
                    </div>
                    <div>
                        <label htmlFor="uploadshow" className="form-label inline-block mb-2  font-[500]">Upload MSA</label>
                        <input
                            className="
                               file:mr-2 file:py-2 file:px-4
            file:rounded file:border-0
            file:text-sm file:font-[500]
            hover:file:cursor-pointer file:bg-secondary/10
            file:text-secondary"  id="uploadshow" type="file" />
                    </div>
                    <div>
                        <label htmlFor="formFileSm" className="form-label inline-block mb-2  font-[500]">Other Docs</label>
                        <input
                            className="
                               file:mr-2 file:py-2 file:px-4
            file:rounded file:border-0
            file:text-sm file:font-[500]
            hover:file:cursor-pointer file:bg-secondary/10
            file:text-secondary"  id="formFileSm" type="file" />
                    </div>
                </div> */}
            </div>
            <div className='flex justify-end'>
                <PrimaryButton onClick={nextToCommercials} className='text-white rounded py-2'>
                    <span>Next</span>
                </PrimaryButton>
            </div>
        </div>
    )
}

export default Comercials;