import { useState, useRef, useEffect } from 'react';

export default function SelectOption({ options, onChange, disabled, value, name, id }) {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    const getByTitle = () => {
        try {
            return options?.find(el => el?.value === value)?.label
        } catch (error) {
            return disabled ? '' : 'select';
        }
    }

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div  ref={dropdownRef} className="relative w-full">
            <button disabled={disabled} onClick={() => setOpen(!open)} className="disabled:cursor-default relative flex justify-between w-full rounded-lg bg-white p-1 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span title={getByTitle()} className="block truncate"> {getByTitle()}</span>
                {!disabled && <div>
                    {open ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-secondary">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>}
                </div>}
            </button>

            {open && <div style={{ zIndex: 2000 }} className="transition ease-in duration-100 absolute mt-1 w-auto min-w-[90px]  max-h-72  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div>
                    {options?.map((item, itemIdx) => {
                        return (
                            <div
                                key={item?.value + itemIdx + item?.label}
                                className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-1.5 cursor-pointer ${item?.value === value ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                                onClick={() => {
                                    let e = { target: { name: name, value: item?.value, id } }
                                    onChange(e, id)
                                    setOpen(!open)
                                }}
                            >
                                <span>
                                    {item?.label}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}
