import { memo, useEffect, useMemo, useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { GET_COURSE_LIST_MASTER } from 'src/Redux/actions/masterdata/user'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { getDefaultCourseListPayload } from 'src/Services/constant/defaultPayload'

const Details = (props) => {
    const { canEdit, setDetailsData, detailsData, detailSave, setCanEdit, educationData, courseList } = props
    const [course, setCourse] = useState({})
    const [checkvalidation, setCheckvalidation] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    useMemo(() => {
        if (course?.coursemaster) {
            setSearchTerm(`${course?.coursemaster}`)
        }
    }, [course])

    useEffect(() => {
        if (detailsData?.coursename) {
            setCourse({ coursemaster: detailsData?.coursename, datcoursemasterid: detailsData?.datcoursemasterid })
        }
    }, [detailsData])

    const handleSubmit = () => {
        setCheckvalidation(true)

        let item = {
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            specialisation: detailsData?.specialisation,
            datspecialisationid: detailsData?.datspecialisationid,
            datcoursemasterid: course?.datcoursemasterid,
            dateducationmasterid: detailsData?.dateducationmasterid,
            activeflag: detailsData.activeflag,
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            notes: detailsData?.notes,
        }
        let isValidated = CheckObjectValidation(detailsData, ['modifiedcomuserid', 'modifiedtimestamp', 'notes'])
        if (isValidated?.isvalid) {
            let payload = {
                datspecialisationjson: [item],
            }
            detailSave(payload)
        }
    }

    const handleChangeInput = (name, value) => {
        setCheckvalidation(false)
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label htmlFor="specialisation" className="block font-[500] mb-2">
                        Specialization
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        type="text"
                        title="specialisation"
                        id="specialisation"
                        className="block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 "
                        placeholder="Enter specialisation Name"
                        required=""
                        onChange={(e) => {
                            handleChangeInput('specialisation', e.target.value)
                        }}
                        value={detailsData?.specialisation || ''}
                        disabled={canEdit}
                    />
                    {!detailsData?.specialisation && checkvalidation && (
                        <small className="text-red-400"> specialisation is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="dateducationmasterid" className="block font-[500] mb-2">
                        Education
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={educationData || []}
                        value={detailsData?.dateducationmasterid || ''}
                        noDataLabel="No education master"
                        name="dateducationmasterid"
                        labelkey="educationmaster"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleChangeInput}
                        id="dateducationmasterid"
                        disabled={canEdit}
                    />
                    {!detailsData?.dateducationmasterid && checkvalidation && (
                        <small className="text-red-400">Region is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="datcoursemasterid" className="block font-[500] mb-2">
                        Course
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomAutoSearch
                        searchTerm={searchTerm}
                        API_CALL_FOR_SEARCH={GET_COURSE_LIST_MASTER}
                        searchPayload={{ ...getDefaultCourseListPayload(), searchname: searchTerm }}
                        setSearchTerm={setSearchTerm}
                        options={courseList}
                        idkey="datcoursemasterid"
                        optionlabelKey="coursemaster"
                        getSelectedOption={setCourse}
                        placeholder="Enter Course Name"
                        className="block w-full p-2 text-gray-600 border border-gray-200 rounded-lg bg-gray-50 focus:ring-primary-600 focus:border-primary-600"
                    />
                    {!course?.datcoursemasterid && checkvalidation && (
                        <small className="text-red-400">Course is required!</small>
                    )}
                </div>
            </div>
            <div className="fixed bottom-0 mt-2 right-6">
                {!canEdit && (
                    <div className="fixed bottom-0 mt-2 right-6">
                        <div className="flex items-center justify-end gap-2">
                            <button
                                onClick={() => setCanEdit(false)}
                                type=""
                                className="px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2"
                            >
                                Cancel
                            </button>
                            <PrimaryButton
                                disabled={canEdit}
                                className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                onClick={handleSubmit}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default memo(Details)
