import { Dialog, Transition } from '@headlessui/react';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertSuccess } from 'src/Services';
import Details from './details';
import { TaskItem } from '../default';
import {
  GET_TASK_MASTER,
  SAVE_TASK_AS_DRAFT,
} from 'src/Redux/actions/masterdata/user';
import { GET_TASK_GROUPS } from 'src/Redux/actions/projects/planning';
import { GET_TIMESHEET_TASK_TYPE } from 'src/Redux/actions/projects/timesheet';
import { getTaskMasterProjectPayload, getTaskTypePayload } from 'src/Services/constant/defaultPayload';
import IconButton from 'src/Components/Buttons/IconButton';

const CreateTask = () => {
  const [detailsData, setDetailsData] = useState(TaskItem());
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  const [taskP, setTaskP] = useState(0)
  const [taskG, setTaskG] = useState(0)
  const planningState = useSelector((state) => state?.ProjectPlanning);
  const { taskGroups } = planningState;
  const ProjectTimesheet = useSelector((state) => state?.ProjectTimesheet);
  let { taskType } = ProjectTimesheet;

  useEffect(()=>{
    if(taskGroups?.length > 0 && taskType?.length > 0){
      let taskgroupid = taskGroups?.find(item => item?.taskgroupdesc === "Custom Tasks")?.taskgroupid
      let tasktypeid = taskType?.find(item => item?.tasktype === 'Project')?.tasktypeid
      setDetailsData(TaskItem(taskgroupid, tasktypeid))
    }
  },[taskType, taskGroups])

  useMemo(() => {
    setTaskP(taskType?.filter(item => item['tasktype'] === 'Project'))
    if (taskType?.length > 0) {
      const projectTaskTypeId = taskType?.find(
        item => item['tasktype'] === 'Project',
      )?.['tasktypeid']
      // Storing task type ID in state
      // Replace 'taskTypeIdState' with your state variable
      setTaskP(projectTaskTypeId)
    }
  }, [taskType])

  useMemo(() => {
    setTaskG(taskGroups?.filter(item => item['defaulttask'] === true))
    if (taskGroups?.length > 0) {
      const projectTaskGroupId = taskGroups?.find(
        item => item['defaulttask'] === true,
      )?.['taskgroupid']
      // Storing task type ID in state
      // Replace 'taskTypeIdState' with your state variable
      setTaskG(projectTaskGroupId)
    }
  }, [taskGroups])


  useEffect(() => {
    dispatch(GET_TASK_GROUPS(taskG));
    dispatch(GET_TIMESHEET_TASK_TYPE(getTaskTypePayload(taskP)));
  }, [dispatch, taskG, taskP]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  async function detailSave(payload) {
    let res = await dispatch(SAVE_TASK_AS_DRAFT(payload));
    if (res?.success) {
      AlertSuccess('Tasks saved successfully!');
      dispatch(GET_TASK_MASTER(getTaskMasterProjectPayload(63, 1, 0)));
    }
  }

  return (
    <>
      <IconButton
        title='Add New Task'
        onClick={openModal}
        className='flex items-center justify-center w-8 h-8 px-1 py-2 text-white rounded-full bg-primary'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          className='w-6 h-6'
        >
          {' '}
          <path
            fillRule='evenodd'
            d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
            clipRule='evenodd'
          />{' '}
        </svg>
      </IconButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <div className='border-b py-2 border-primary flex justify-between items-center'>
                    <h3 className='text-lg font-[500] leading-6 text-primary'>
                      Create Task 
                    </h3>
                    <button
                      onClick={closeModal}
                      type='button'
                      className='flex items-center justify-center w-10 h-10 border rounded-full'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </svg>
                      <span className='sr-only'>Close icon</span>
                    </button>
                  </div>
                  <Details
                    setDetailsData={setDetailsData}
                    detailsData={detailsData}
                    detailSave={detailSave}
                    onModalClose={closeModal}
                    taskP={taskP}
                    taskG={taskG}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(CreateTask);
