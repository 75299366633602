import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import MultiSelectItem from 'src/Components/MultiSelect'
import { GET_TIMESHEET_TRACKING_REPORT } from 'src/Redux/actions/reports'
import { getTimesheetTrackingReportPayload, getUserAccessPayload } from 'src/Services/constant/defaultPayload'
import WeekSelect from '../timsheet/weekSelect'
import SelectMonth from 'src/Components/SelectMonth'
import moment from 'moment'
import { GET_USER_ACCESS_LIST } from 'src/Redux/actions/masterdata/user'
import Selectuser from '../finance/selectuser'

const getCurrentWeekDates = () => {
    const now = new Date()
    const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1))
    const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7))
    return {
        monday: moment(monday).format('YYYY-MM-DD'),
        sunday: moment(sunday).format('YYYY-MM-DD'),
    }
}

const timesheetStatusList = [
    { statusname: 'Pending Entry', code: 'PE' },
    { statusname: 'Pending Submission', code: 'PS' },
    { statusname: 'Pending Approval', code: 'PA' },
    { statusname: 'Approved', code: 'AP' },
]

const TrackingReportFilter = ({ filters, setFilters, cols = 'grid-cols-6', setReportId, reportId, user, setUser }) => {
    const dispatch = useDispatch()
    const { workertypelist, workforcelist, allocationstatuslist, companylist } = useSelector(
        (state) => state.EmployeeDetails,
    )
    const { statusListByType } = useSelector((state) => state.Projects)
    const { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const [allocationStatus, setAllocationStatuslist] = useState([])
    const [timesheetStatus, setSelectedTimesheetStatuses] = useState([])
    const [weeks, setWeeks] = useState(getCurrentWeekDates())
    const [resetWeekSelect, setResetWeekSelect] = useState(false)

    const handleResetComplete = () => setResetWeekSelect(false)

    const handleStatusSelect = (status) => {
        const isNew = allocationStatus.some((p) => p.statusid === status.statusid)
        if (isNew) {
            setAllocationStatuslist(allocationStatus.filter((p) => p.statusid !== status.statusid))
        } else {
            setAllocationStatuslist([...allocationStatus, status])
        }
    }

    const handleTimesheetStatusSelect = (status) => {
        const isSelected = timesheetStatus.some((s) => s.statusname === status.statusname)
        if (isSelected) {
            setSelectedTimesheetStatuses(timesheetStatus.filter((s) => s.statusname !== status.statusname))
        } else {
            setSelectedTimesheetStatuses([...timesheetStatus, status])
        }
    }

    useEffect(() => {
        const statusIdsString = `{${allocationStatus.map((status) => status.statusid).join(',')}}`
        const timesheetStatusArray = timesheetStatus?.length > 0 ? timesheetStatus?.map((status) => status.code) : '{}'
        setFilters((prevFilters) => ({
            ...prevFilters,
            allocationstatusid: statusIdsString,
            timesheetstatus: timesheetStatusArray,
            comuserid: user?.comuserid || 0,
        }))
    }, [allocationStatus, timesheetStatus, user, setFilters])

    const dataBySelectedDate = async (range) => {
        const weekStart = moment(range.monday).format('YYYY-MM-DD')
        const weekEnd = moment(range.sunday).format('YYYY-MM-DD')
        setFilters({
            ...filters,
            weekstartdate: weekStart,
            weekenddate: weekEnd,
        })
        setWeeks(range)
    }

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        let m = month?.length === 1 ? `0${month}` : month
        const parsedMonth = parseInt(m, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        setFilters({
            ...filters,
            [name]: Number(`${parsedYear}${m}`),
            Monthid: validMonthid,
            Yearid: validYearid,
        })
    }

    const clearFilters = () => {
        setAllocationStatuslist([])
        setSelectedTimesheetStatuses([])
        const payload = {
            ...getTimesheetTrackingReportPayload(),
            allocationstatusid: '{}',
            timesheetstatus: '{}',
        }
        dispatch(GET_TIMESHEET_TRACKING_REPORT(payload))
        setFilters(payload)
        setUser({})
    }

    const applyFilters = () => {
        const data = {
            ...filters,
            comuserid: user?.comuserid || 0,
        }
        dispatch(GET_TIMESHEET_TRACKING_REPORT(data))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <>
                    <div>
                        <label htmlFor="User" className="block capitalize mb-2  font-[500]">
                            Select User
                        </label>
                        <Selectuser
                            setUser={setUser}
                            user={user}
                            apiCallForSearch={GET_USER_ACCESS_LIST}
                            className="py-2 border rounded bg-gray-50"
                            userSelectorKey="userlistforadmin"
                            searchPayloadFunction={getUserAccessPayload}
                            placeholder="Select User"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Company</h1>
                        <CustomhtmlSelect
                            options={companylist || []}
                            value={filters?.Companyid}
                            noDataLabel="No Company"
                            name="Companyid"
                            labelkey="companyname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="companyid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Worker Type</h1>
                        <CustomhtmlSelect
                            options={workertypelist || []}
                            value={filters?.workertypeId}
                            noDataLabel="No Type"
                            name="workertypeId"
                            labelkey="workertype"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="workertypeid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Workforce group</h1>
                        <CustomhtmlSelect
                            options={workforcelist || []}
                            value={filters?.workforcegroupid}
                            noDataLabel="No Workforce found"
                            name="workforcegroupid"
                            labelkey="workforcegroup"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="wfgid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Emp Status</h1>
                        <CustomhtmlSelect
                            options={statusListByType || []}
                            value={filters?.empstatusid}
                            noDataLabel="No Employee Status found"
                            name="empstatusid"
                            labelkey="statusname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="statusid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Allocation Status</h1>
                        <div className="border rounded bg-gray-50 p-0.5">
                            <MultiSelectItem
                                itemIdKey="statusid"
                                labelKeyname="statusname"
                                limitShow={1}
                                selectedOptions={allocationStatus}
                                options={allocationstatuslist}
                                handleSelection={handleStatusSelect}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Timesheet Status</h1>
                        <div className="border rounded bg-gray-50 p-0.5">
                            <MultiSelectItem
                                itemIdKey="statusname"
                                labelKeyname="statusname"
                                limitShow={1}
                                selectedOptions={timesheetStatus}
                                options={timesheetStatusList}
                                handleSelection={handleTimesheetStatusSelect}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Select Month/year</h1>
                        <div className=" bg-gray-50">
                            <SelectMonth
                                className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                                onChange={handleMonthChange}
                                name="yearmonth"
                                year={filters?.Yearid}
                                month={filters?.Monthid?.length === 1 ? `0${filters?.Monthid}` : filters?.Monthid}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Select Week Start Date</h1>
                        <WeekSelect
                            setWeeks={setWeeks}
                            dataBySelectedDate={dataBySelectedDate}
                            state={weeks}
                            reset={resetWeekSelect}
                            onResetComplete={handleResetComplete}
                        />
                    </div>
                </>
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackingReportFilter
