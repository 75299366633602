import { Link } from "react-router-dom";

const maricsList = [
	{ count: 1, title: 'Skills Added', name: 'totalnoofskills', viewLink: '/app/workforce/eprofile' },
	{ count: 4, title: 'Projects Assigned', name: 'totalprojectsworked', viewLink: '/app/workforce/eprofile' },
	{ count: 1, title: 'Timesheets Pending Submission', name: 'noofweektimesheetpendingsubmission', viewLink: '/app/projects/timesheet' },
	{ count: 1, title: 'Timesheets Pending Approval', name: 'noofweektimesheetpendingapproval', viewLink: '/app/projects/timesheet' },
	{ count: 4, title: 'Profile Completeness', name: 'profilecompleteness' },
	{ count: 7, title: 'Total Allocation', name: 'currentallocation' },
	{ count: 7, title: 'Average Utilization', name: 'avgutilization' },
	{ count: 3, title: 'Timesheet Compliance', name: 'timesheetcomplaince' },
]

const PendingCard = ({ count, title, link }) => {
	const isProgressBarShown = !['Skills Added', 'Projects Assigned', 'Timesheets Pending Submission', 'Timesheets Pending Approval'].includes(title);
	const showTitleBelow = ['Total Allocation', 'Timesheet Compliance', 'Profile Completeness', 'Average Utilization'].includes(title);
	const widthStyle = { width: `${count || 0}%` };

	return (
		<div className="p-3 bg-white rounded-md shadow-lg min-h-[70px] ">
			{isProgressBarShown && (
				<div className='flex items-center justify-start w-full space-x-1'>
					<div className="w-full h-3 overflow-hidden bg-gray-200 rounded-full">
						<div
							className="flex items-center justify-center h-3 text-xs font-semibold text-white rounded-md bg-secondary/70"
							style={widthStyle}
						>
							{!showTitleBelow && `${count || 0}%`}
						</div>
					</div>
					<span className="text-sm font-semibold text-primary">{count || 0}%</span>
				</div>
			)}
			<div className="flex items-center w-full text-left">
				{!showTitleBelow && (
					<span className="mr-2 text-3xl font-bold text-primary">{count || 0}</span>
				)}
				<div className="w-full flex justify-between items-center">
					<span className="text-sm font-semibold inline-flex justify-start text-gray-600">{title}</span>
					{!isProgressBarShown && <Link to={link} className="text-secondary ">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
							<path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
						</svg>
					</Link>}
				</div>

			</div>
		</div>
	);
};


const AppMatrics = ({ homePagemetrics }) => {
	return (
		<div className="w-full gap-4 flex flex-col">
			<div className='grid grid-cols-2 gap-4'>
				{maricsList?.map(item => {
					return (
						<PendingCard
							link={item?.viewLink}
							key={item?.title}
							count={homePagemetrics ? homePagemetrics[item?.name]?.toFixed() : 0}
							title={item?.title}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default AppMatrics