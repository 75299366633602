
import SingleSelectField from "src/Components/SingleSelectForTable";

const Technology = ({ options, value, rowid, handleChange }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'technologymasterid', value: value['technologymasterid'] } }
        handleChange(e, rowid)
    }
    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='technologymasterid'
                labelKey='technology'
                options={options}
                value={value || 0}
                handleSelected={getSelected}
            />
        </div>
    )
}

export default Technology;
