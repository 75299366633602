import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useEffect } from 'react';
import SelectSalesManager from './selectSalesManager';
import SelectCustomer from './selectcustomer';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { useSelector } from 'react-redux';

const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    canEdit,
    setCanEdit,
    accountSelect,
    setAccountSelect,
    opportunityid,
    customer,
    setCustomer
  } = props;
  const projectsReducer = useSelector((state) => state.Projects);
  const { customerList, currencyList, statusListByType } = projectsReducer
  const { sourcefilteredlist } = useSelector((state) => state.CustomerManagementList);

  useEffect(() => {
    setDetailsData({
      ...detailsData,
      sourceofopportunity: detailsData?.sourceofopportunity,

    });
    setAccountSelect({
      comuserid: detailsData?.salesmanagerid,
      firstname: detailsData?.Salesmanagername,
    });
    setCustomer({
      customerid: detailsData?.customerid,
      customername: detailsData?.customername,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAccountSelect, setCustomer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let items = {
      opportunityid: opportunityid,
      opportunityname: detailsData?.opportunityname,
      opportunitycode: detailsData?.opportunitycode,
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      totalopportunityvalue: detailsData?.totalopportunityvalue,
      opportunitystatus: detailsData?.opportunitystatus,
      winprobabilitypercentage: detailsData?.winprobabilitypercentage,
      salesmanagerid: accountSelect?.comuserid,
      sourceofopportunity: detailsData?.sourceofopportunity,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
      notes: detailsData?.notes,
      customerid: detailsData?.customerid,
      currencyid:detailsData?.currencyid
    };
    let payload = {
      opportunityjson: [items],
    };
    detailSave(payload);
  };

  const handleChangeInput = (name, value) => {
    setDetailsData({ ...detailsData, [name]: value });
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, salesmanagerid: select.comuserid });
  };

  const handleSelectCustomer = (select) => {
    setCustomer(select);
    setDetailsData({ ...detailsData, customerid: select.customerid });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='opportunitycode' className='block font-[500] mb-2'>
            Code
          </label>
          <input
            disabled
            type='text'
            title='opportunitycode'
            id='opportunitycode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Opportunity Code'
            required=''
            value={detailsData?.opportunitycode || ''}
            onChange={(e) =>
              handleChangeInput('opportunitycode', e.target.value)
            }
          />
        </div>
        <div>
          <label
            htmlFor='customerid'
            className='block font-[500] mb-2 mt-0.4'
          >
            {' '}
            Select Customer
            <span className="text-red-300"> *</span>
          </label>
          <div className='relative'>
            <SelectCustomer
              customer={customer}
              handleSelectCustomer={handleSelectCustomer}
              customerList={customerList}
              canEdit={canEdit}
            />
          </div>
        </div>
        <div>
          <label htmlFor='opportunityname' className='block font-[500] mb-2'>
            Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='opportunityname'
            id='opportunityname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Opportunity Name'
            required=''
            value={detailsData?.opportunityname || ''}
            onChange={(e) =>
              handleChangeInput('opportunityname', e.target.value)
            }
            maxLength={50}
          />
        </div>
        <div>
          <label
            htmlFor='totalopportunityvalue'
            className='block font-[500] mb-2'
          >
            Total Opportunity Value
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='number'
            title='totalopportunityvalue'
            id='totalopportunityvalue'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Opportunity Value'
            required=''
            value={detailsData?.totalopportunityvalue || ''}
            onChange={(e) =>
              handleChangeInput('totalopportunityvalue', e.target.value)
            }
          />
        </div>
        <div>
          <label htmlFor='DefaultCurrency' className='block font-[500] mb-2'>
            Select Currency
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={currencyList || []}
            value={detailsData?.currencyid || ''}
            noDataLabel='No Industry'
            name='currencyid'
            labelkey='currency'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            onChange={handleChangeInput}
            id='datcomcurrencyid'
          />
        </div>
        <div>
          <label
            htmlFor='winprobabilitypercentage'
            className='block font-[500] mb-2'
          >
            Win Probability (%)
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='number'
            title='winprobabilitypercentage'
            id='winprobabilitypercentage'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Win probability Percentage'
            required=''
            value={detailsData?.winprobabilitypercentage || ''}
            onChange={(e) =>
              handleChangeInput('winprobabilitypercentage', e.target.value)
            }
          />
        </div>
        <div>
          <label htmlFor='opportunitystatus' className='block font-[500] mb-2'>
            Opportunity Status
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={statusListByType || []}
            value={detailsData?.opportunitystatus}
            noDataLabel='No status found'
            name='opportunitystatus'
            labelkey='statusname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            onChange={handleChangeInput}
            id='statusname'
          />
        </div>
        <div>
          <label
            htmlFor='salesmanagerid'
            className='block font-[500] mb-2 mt-0.4'
          >
            {' '}
            Sales Manager Name
            <span className="text-red-300"> *</span>
          </label>
          <div className='relative'>
            <SelectSalesManager
              accountSelect={accountSelect}
              handleSelectManager={handleSelectManager}
              setAccountSelect={setAccountSelect}
              canEdit={canEdit}
            />
          </div>
        </div>
        <div>
          <label htmlFor='source' className='block font-[500] mb-2'>
            Select Source
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={sourcefilteredlist || []}
            value={detailsData?.sourceofopportunity}
            noDataLabel='No Source'
            name='sourceofopportunity'
            labelkey='sourcename'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            onChange={handleChangeInput}
            id='sourcename'
          />
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(Details);
