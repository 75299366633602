import {
    ACTION_GET_DEMAND_LIST_FOR_TALENT,
    ACTION_GET_DEMAND_LIST_MASTER,
    ACTION_GET_DEMAND_MASTER,
    ACTION_GET_DEMAND_MATCH,
    ACTION_GET_DEMAND_MATCH_DETAILS,
    ACTION_GET_DEMAND_ROLE_MASTER,
    ACTION_GET_DEMAND_SKILL_MASTER,
    ACTION_GET_INSUDTRY_MASTER,
    ACTION_GET_PROJECT_STAFFING_LIST,
    ACTION_GET_SKILLAREA_MASTER,
    ACTION_GET_SKILL_LIST_MASTER,
    ACTION_GET_STAFF_STATUS_TYPE_M,
    ACTION_GET_TECHNOLOGY_MASTER,
    ACTION_GET_WORK_MODE_LIST,
} from 'src/Redux/types'
import { AlertError, AlertSuccess } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'

const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
}

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem('comuserid')),
    }
}

export const GET_WORK_MODE_LIST = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/get_workmodemaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            let items = res?.data?.data
            if (items === null) AlertError('No work mode found')
            let addnewkey = items?.map((item) => {
                item['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                return item
            })
            dispatch({ type: ACTION_GET_WORK_MODE_LIST, data: addnewkey || [] })
            return { success: true, data: addnewkey }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_LIST_MASTER = (data) => {
    return async (dispatch) => {
        try {
            let payload = { ...data, billabletype: data?.billabletype.toString() }
            const url = `${baseUrl}/get_demandlistforauser`
            dispatch({ type: ACTION_GET_DEMAND_LIST_MASTER, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No Demands found!')
                dispatch({ type: ACTION_GET_DEMAND_LIST_MASTER, data: addnewkey || [] })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_MATCH = (demandid) => {
    let payload = {
        Tenantid: getTanAndComId()?.Tenantid,
        userid: 0,
        demandid,
        matchstatusid: 0,
    }
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_demandmatch`
            dispatch({ type: ACTION_GET_DEMAND_MATCH, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            let items = res?.data?.data
            if (items === null) AlertError('No Demand match found!')
            let addnewkey = items?.map((item) => {
                item['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                return item
            })
            dispatch({ type: ACTION_GET_DEMAND_MATCH, data: addnewkey || [] })
            return { success: true, data: addnewkey }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_MASTER = (Demandid) => {
    let payload = {
        Tenantid: getTanAndComId()?.Tenantid,
        COMUserId: 0,
        Demandid,
        Limit: 25,
        Page: 1,
        Sorttype: 1,
        Searchname: '',
    }
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_demandmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            let items = res?.data?.data
            if (items === null) AlertError('No demands found')
            let addnewkey = items?.map((item) => {
                item['allocation'] = (item?.allocation * 100).toFixed(0)
                item['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                return item
            })
            dispatch({ type: ACTION_GET_DEMAND_MASTER, data: addnewkey || [] })
            return { success: true, data: addnewkey }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const SAVE_DEMAND_MATCH = (data) => {
    return async () => {
        try {
            let payload = { demandmatchjson: [data] }
            const url = `${baseUrl}/insert_demandmatch`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Demand Match saved successfully!')
                return true
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}
export const SAVE_DEMAND_LIST_MASTER = (data) => {
    return async () => {
        try {
            const url = `${baseUrl}/insert_demandmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const demandid = item?.demandid
                if (item?.Message === 'Success') {
                    AlertSuccess('Demand Details saved successfully!')
                } else {
                    AlertError(item?.Message || 'Something went wrong !')
                }
                return { success: true, demandid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const SAVE_DEMAND_STATUS_MASTER = (data) => {
    return async () => {
        try {
            const url = `${baseUrl}/UpdateDemandStatus`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                // const demandid = item?.demandid
                if (item?.Message === 'Success') {
                    AlertSuccess('Demand status saved successfully!')
                } else {
                    AlertError(item?.Message || 'Something went wrong !')
                }
                return { success: true, /* demandid */ }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const SAVE_DEMAND_SKILL_MASTER = (data, requiredKeys, id) => {
    return async () => {
        try {
            let items = []
            for (let item of data) {
                let newObj = {}
                for (let keys in item) {
                    if (requiredKeys.includes(keys)) {
                        newObj[keys] = item[keys]
                    }
                }
                items.push(newObj)
            }

            items?.map((item) => {
                item['demandid'] = id
                item['importance'] = Number(item['importance'])
                return item
            })

            let payload = { demandskilljson: items }
            const url = `${baseUrl}/insert_demandskills`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            let item = res?.data?.data
            if (res?.status === 200 || res?.status === 201) {
                // const demandskillid = item?.demandskillid;
                if (item?.Message === 'Success') {
                    AlertSuccess('Skill & Expertise saved successfully!')
                    return { success: true }
                }
                return { success: true }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const GET_DEMAND_MATCH_DETAILS = (data) => {
    return async (dispatch) => {
        try {
            let payload = { ...data, mintotalexperience: Number(data.mintotalexperience) }
            const url = `${baseUrl}/get_demandmatchprofiledetails`
            dispatch({ type: ACTION_GET_DEMAND_MATCH_DETAILS, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No matches found !')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_DEMAND_MATCH_DETAILS,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_LIST_FOR_TALENT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_demandlistfortalentpage`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No demands found !')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_DEMAND_LIST_FOR_TALENT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const SAVE_DEMAND_ROLE_MASTER = (data, id) => {
    return async () => {
        try {
            let items = data?.map((item) => {
                item['demandid'] = id
                item['experienceinyear'] = Number(item['experienceinyear'])
                if (!item['createdcomuserid']) {
                    item['createdcomuserid'] = Number(localStorage.getItem('comuserid') || 0)
                }
                return item
            })
            let payload = { demandroledetailjson: items }
            const url = `${baseUrl}/insert_demandroledetail`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            let item = res?.data?.data
            if (res?.status === 200 || res?.status === 201) {
                const demandid = item?.demandid
                if (item?.Message === 'Success') {
                    AlertSuccess('Role saved successfully!')
                    return { success: true, demandid }
                } else {
                    AlertError(item?.Message || 'Something went wrong !')
                }
                return { success: true, demandid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}


export const GET_SKILL_LIST_MASTER = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || '',
            }
            const url = `${baseUrlMaster}/get_skillmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Skill found !')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_SKILL_LIST_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_TECHNOLOGY_MASTER = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || '',
            }
            const url = `${baseUrlMaster}/get_technologymaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No tECH found !')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_TECHNOLOGY_MASTER, data: items || [] })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_INSUDTRY_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_industrymaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No  Industry/Domain found !')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_INSUDTRY_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_SKILLAREA_MASTER = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || '',
            }
            const url = `${baseUrlMaster}/get_skillareamaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_SKILLAREA_MASTER, data: addnewkey || [] })
                return { data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_SKILL_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/get_demandskills`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_DEMAND_SKILL_MASTER, data: addnewkey || [] })
                return { data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_ROLE_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_demandrole`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_DEMAND_ROLE_MASTER, data: addnewkey || [] })
                return { data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_STAFF_STATUS_TYPE_M = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid || 0,
                statustype: 'M',
                searchname: data?.searchname || '',
                sorttype: 1,
            }
            const url = `${baseUrl}/getstatusmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Status type found !')
                dispatch({ type: ACTION_GET_STAFF_STATUS_TYPE_M, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_STAFF_STATUS_TYPE_S = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid || 0,
                statustype: 'S',
                searchname: data?.searchname || '',
                sorttype: 1,
            }
            const url = `${baseUrl}/getstatusmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Status type found !')
                dispatch({ type: ACTION_GET_STAFF_STATUS_TYPE_M, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROJECT_STAFFING_LIST = (
    projectid,
    projectstaffid,
    staffingstatusid,
    page,
    limit,
) => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: getTanAndComId()?.Tenantid,
                projectid,
                projectstaffid,
                staffingstatusid,
                Status: 'Active',
            }
            if (page) {
                payload['pageno'] = page
            }
            if (limit) {
                payload['limit'] = limit
            }
            const url = `${baseUrl}/get_projectstaff`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data?.Data
                let fte = null
                if (res?.data?.data?.FTE?.length) {
                    fte = res?.data?.data?.FTE[0]
                }
                let addnewkey = items?.map((item) => {
                    item['allocation'] = item?.allocation * 100
                    item['plannedallocation'] = item?.plannedallocation * 100
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No staffing found for this project !')
                dispatch({ type: ACTION_GET_PROJECT_STAFFING_LIST, data: addnewkey, fte })
                return addnewkey
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
