import PlanningPhases from './phase'
import BudgetLayout from './budget'
import Milestone from './milestone'
import PlanningRoles from './roles'
import TaskLayout from './tasks'
import IconButton from 'src/Components/Buttons/IconButton'
import { useMemo, useState } from 'react'
import NoPermissionComponent from 'src/Components/NoPermission'

const tabslist = ['Phases', 'Roles', 'Milestones', 'Budget', 'Tasks']

const Tabs = ({ hooks }) => {
	const { setActiveTab, project, activeTab, thisPagePermissions } = hooks
	const [editTab, setEditTab] = useState(null)

	useMemo(() => {
		if (project !== null) {
			setEditTab(null)
		}
	}, [project])

	const buttonPermission = (tab) => {
		switch (tab) {
			case 'Phases':
				return thisPagePermissions?.includes("Add/Edit Phase")
			case 'Roles':
				return thisPagePermissions?.includes("Add/Edit Roles")
			case 'Milestones':
				return thisPagePermissions?.includes("Add/Edit Milestones")
			case 'Budget':
				return thisPagePermissions?.includes("Add/Edit Budget")
			default:
				break;
		}
	}
		
	
	return (
		<div className='w-full h-full relative'>
			<div className='flex items-center justify-between w-full border-b border-secondary '>
				<div className='w-[300px] flex rounded-lg p-1'>
					{tabslist?.map((item, i) => {
						return (
							<button
								key={i}
								onClick={() => {
									setActiveTab(item)
									setEditTab(null)
								}}
								className={`text-sm text-left w-full rounded-lg py-2 font-[500] outline-white 
								ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0'
								${activeTab === item ? 'bg-secondary/10 text-secondary font-[500]' : 'text-gray-500'}`}
							>
								<span className='px-4'> {item}</span>
							</button>
						)
					})}
				</div>
				{project?.statusname === 'Closed' ||
					project?.statusname === 'Completed' ? (
					''
				) : (buttonPermission(activeTab) &&
					<>
						{editTab === null && (
							<IconButton
								title={`Edit ${activeTab}`}
								className='w-8 h-8 disabled:bg-primary/40 text-white bg-primary'
								onClick={() => setEditTab(activeTab)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
									className='w-4 h-4'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
									/>
								</svg>
							</IconButton>
						)}
					</>
				)}
			</div>
			<>
				{activeTab === 'Phases' && (thisPagePermissions?.includes("View Phase") ?
					<PlanningPhases
						editTab={editTab}
						setEditTab={setEditTab}
						disabled={editTab === 'Phases'}
						project={project}
					/> : <NoPermissionComponent />
				)}

				{activeTab === 'Roles' && (thisPagePermissions?.includes("View Roles") ? <PlanningRoles
					editTab={editTab}
					setEditTab={setEditTab}
					disabled={editTab === 'Roles'}
					hooksValue={hooks}
				/> : <NoPermissionComponent />)}

				{activeTab === 'Milestones' && (thisPagePermissions?.includes("View Milestones") ? <Milestone
					editTab={editTab}
					setEditTab={setEditTab}
					disabled={editTab === 'Milestones'}
					hooksValues={hooks}
				/> : <NoPermissionComponent />)}

				{activeTab === 'Budget' && (thisPagePermissions?.includes("View Budget") ? <BudgetLayout
					disabled={editTab === 'Budget'}
					setEditTab={setEditTab}
					hooksValues={hooks}
				/> : <NoPermissionComponent />)}

				{activeTab === 'Tasks' && (thisPagePermissions?.includes("View Task") ? <TaskLayout hooksValues={hooks} /> : <NoPermissionComponent />)}

			</>
		</div>
	)
}

export default Tabs
