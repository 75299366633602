import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { GET_STATE_MASTER } from 'src/Redux/actions/masterdata/user';
import { getMasterStatePayload } from 'src/Services/constant/masterdatapayload';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';

const CityDetails = (props) => {
  const [state, setState] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const { stateList } = useSelector((state) => state.UserManagementList);
  const [checkvalidation, setCheckvalidation] = useState(false);

  useMemo(() => {
    if (state?.statename) {
      setSearchTerm(state?.statename)
    }
  }, [state])

  useEffect(() => {
    if (detailsData?.statename && detailsData?.comstateid) {
      setState({ statename: detailsData?.statename, comstateid: detailsData?.comstateid });
    }
  }, [detailsData?.statename, detailsData?.comstateid]);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let item = {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      cityname: detailsData?.cityname,
      citycode: detailsData?.citycode,
      comcityid: detailsData?.comcityid,
      comstateid: state?.comstateid,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
    };

    let isValidated = CheckObjectValidation(detailsData, [
      'notes',
      'comcityid',
      'modifiedtimestamp',
      'modifiedcomuserid',
    ]);

    if (isValidated?.isvalid) {
      let payload = {
        cityjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='cityname' className='block font-[500] mb-2'>
            City Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='cityname'
            id='cityname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='cityname'
            required=''
            onChange={(e) => handleChangeInput('cityname', e.target.value)}
            disabled={canEdit}
            value={detailsData?.cityname || ''}
            maxLength={50}
          />
          {!detailsData?.cityname && checkvalidation && (
            <small className='text-red-400'>City Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='citycode' className='block font-[500] mb-2'>
            City Code
          </label>
          <input
            disabled
            type='text'
            title='citycode'
            id='citycode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='citycode'
            required=''
            onChange={(e) => handleChangeInput('citycode', e.target.value)}
            value={detailsData?.citycode || ''}
          />
        </div>
        <div>
          <label htmlFor='comstateid' className='block font-[500] mb-2'>
            State
            <span className="text-red-300"> *</span>
          </label>
          <CustomAutoSearch
            disabled={canEdit}
            searchTerm={searchTerm}
            API_CALL_FOR_SEARCH={GET_STATE_MASTER}
            searchPayload={{ ...getMasterStatePayload(), searchname: searchTerm, }}
            setSearchTerm={setSearchTerm}
            options={stateList}
            idkey='statename'
            optionlabelKey='statename'
            getSelectedOption={setState}
            placeholder='Enter State Name'
            className='bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
          />
          {!state?.comstateid && checkvalidation && (
            <small className='text-red-400'>State is required!</small>
          )}
        </div>

      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CityDetails);
