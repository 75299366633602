import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import SelectCustomer from './selectcustomer'
import SelectSalesManager from './selectSalesManager';
import { detailNotRequired } from '../default';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { useSelector } from 'react-redux';


const Details = (props) => {
  const projectsReducer = useSelector((state) => state.Projects);
  const { customerList, currencyList } = projectsReducer
  const { sourcefilteredlist } = useSelector((state) => state.CustomerManagementList);
  const { statusListByType } = projectsReducer;
  const {
    detailSave,
    detailsData,
    setDetailsData,
    accountSelect,
    setAccountSelect,
    customer,
    setCustomer
  } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let item = {
      ...detailsData,
      salesmanagerid: accountSelect?.comuserid,
      customerid: customer?.customerid,
    };

    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        opportunityjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, salesmanagerid: select.comuserid });
    setCheckvalidation(false);
  };
  const handleSelectCustomer = (select) => {
    setCustomer(select);
    setDetailsData({ ...detailsData, customerid: select.customerid });
    setCheckvalidation(false);
  };


  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='opportunitycode' className='block font-[500] mb-2'>
            Code
          </label>
          <input
            disabled
            type='text'
            title='opportunitycode'
            id='opportunitycode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Opportunity Code'
            required=''
            // value={detailsData?.customername || ''}
            onChange={(e) =>
              handleChangeInput('opportunitycode', e.target.value)
            }
          />
        </div>
        <div className="relative">
          <SelectCustomer
            customer={customer}
            handleSelectCustomer={handleSelectCustomer}
            customerList={customerList} />
              {!customer?.customerid && checkvalidation && (
            <small className='text-red-400'>Customer is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='opportunityname' className='block font-[500] mb-2'>
            Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='opportunityname'
            id='opportunityname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Opportunity Name'
            required=''
            // value={detailsData?.opportunityname || ''}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('opportunityname', e.target.value);
              }
            }}
            maxLength={50}
          />
          {!detailsData?.opportunityname && checkvalidation && (
            <small className='text-red-400'>Name is required!</small>
          )}
        </div>
        <div>
          <label
            htmlFor='totalopportunityvalue'
            className='block font-[500] mb-2'
          >
            Value
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='number'
            title='totalopportunityvalue'
            id='totalopportunityvalue'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Opportunity Value'
            required=''
            // value={detailsData?.customername || ''}
            onChange={(e) =>
              handleChangeInput('totalopportunityvalue', e.target.value)
            }
          />
          {!detailsData?.totalopportunityvalue && checkvalidation && (
            <small className='text-red-400'>Value is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='DefaultCurrency' className='block font-[500] mb-2'>
            Select Currency
          </label>
          <CustomhtmlSelect
            options={currencyList || []}
            value={detailsData?.currencyid || ''}
            noDataLabel='No Industry'
            name='currencyid'
            labelkey='currency'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            onChange={handleChangeInput}
            id='datcomcurrencyid'
          />
        </div>
        <div>
          <label
            htmlFor='winprobabilitypercentage'
            className='block font-[500] mb-2'
          >
            Win Probability (%)
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='number'
            title='winprobabilitypercentage'
            id='winprobabilitypercentage'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Win probability Percentage'
            required=''
            // value={detailsData?.customername || ''}
            onChange={(e) =>
              handleChangeInput('winprobabilitypercentage', e.target.value)
            }
          />
          {!detailsData?.winprobabilitypercentage && checkvalidation && (
            <small className='text-red-400'>Win Probability (%) is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='opportunitystatus' className='block font-[500] mb-2'>
            Opportunity Status
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={statusListByType || []}
            value={detailsData?.opportunitystatus}
            noDataLabel='No Opportunity status found found'
            name='opportunitystatus'
            labelkey='statusname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            onChange={handleChangeInput}
            id='statusname'
          />
          {!detailsData?.opportunitystatus && checkvalidation && (
            <small className='text-red-400'>Opportunity Status is required!</small>
          )}
        </div>
        <div>
          <label
            htmlFor='salesmanagerid'
            className='block font-[500] mb-2 mt-0.4'
          >
            {' '}
            Sales Manager Name
            <span className="text-red-300"> *</span>
          </label>
          <div className='relative'>
            <SelectSalesManager
              accountSelect={accountSelect}
              handleSelectManager={handleSelectManager}
              setAccountSelect={setAccountSelect}
            />
            {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>Sales Manager Name is required!</small>
            )}
          </div>
        </div>
        <div>
          <label htmlFor='source' className='block font-[500] mb-2'>
            Select Source
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={sourcefilteredlist || []}
            value={detailsData?.sourceofopportunity || ''}
            noDataLabel='No Country'
            name='sourceofopportunity'
            labelkey='sourcename'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            onChange={handleChangeInput}
            id='sourcename'
          />
          {!detailsData?.sourceofopportunity && checkvalidation && (
            <small className='text-red-400'>Source is required!</small>
          )}
        </div>
        
      </div>

      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            type=''
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
