import { useDispatch, useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { GET_USER_ACCESS_LIST } from 'src/Redux/actions/masterdata/user';
import { getUserAccessPayload } from 'src/Services/constant/defaultPayload';


const UserManagmentFilter = ({
  cols = 'grid-cols-6',
  filters,
  setFilters,
  setUsers,
}) => {
  const dispatch = useDispatch();
  const { usertypelist, rolelist } = useSelector(
    (state) => state.UserManagementList
  );
  const projectsReducer = useSelector((state) => state.Projects);
  const { statusListByType } = projectsReducer;
  const { companylist } = useSelector((state) => state.EmployeeDetails);


  const handleSelectChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const clearFilters = () => {
    setFilters(getUserAccessPayload());
    dispatch(GET_USER_ACCESS_LIST(getUserAccessPayload())).then((res) => {
      if (res?.success) {
        setUsers(res?.data);
      }
    });
  };

  const applyFilters = () => {
    const appliedFilters = { ...filters };
    if (filters.Roleid === "" || filters.Roleid === "{}") {
      appliedFilters.Roleid = "{}";
    } else {
      appliedFilters.Roleid = `{${filters.Roleid}}`;
    }

    dispatch(GET_USER_ACCESS_LIST(appliedFilters)).then((res) => {
      if (res?.success) {
        setUsers(res?.data);
      }
    });
  };

  return (
    <div className='w-full text-sm'>
      <div className={`grid ${cols}  gap-4`}>
        <div>
          <h1 className='mb-2 font-[500]'>Company</h1>
          <CustomhtmlSelect
            options={companylist || []}
            value={filters?.companyid}
            noDataLabel='No Company'
            name='companyid'
            labelkey='companyname'
            className='py-2 border rounded bg-gray-50'
            onChange={handleSelectChange}
            id='companyid'
          />
        </div>
        <div>
          <h1 className='mb-2 font-[500]'>User Type</h1>
          <CustomhtmlSelect
            options={usertypelist || []}
            value={filters?.datusertypeid}
            noDataLabel='No Users'
            name='datusertypeid'
            labelkey='usertype'
            className='py-2 border rounded bg-gray-50'
            onChange={handleSelectChange}
            id='datusertypeid'
          />
        </div>
        <div>
          <h1 className='mb-2 font-[500]'>Role</h1>
          <CustomhtmlSelect
            options={rolelist || []}
            value={filters?.Roleid}
            noDataLabel='No Roles'
            name='Roleid'
            labelkey='rolename'
            className='py-2 border rounded bg-gray-50'
            onChange={handleSelectChange}
            id='roleid'
          />
        </div>
        <div>
          <h1 className='mb-2 font-[500]'>Employee Status</h1>
          <CustomhtmlSelect
            options={statusListByType || []}
            value={filters?.empstatus}
            noDataLabel='No Employee Status found'
            name='empstatus'
            labelkey='statusname'
            className='py-2 border rounded bg-gray-50'
            onChange={handleSelectChange}
            id='statusid'
          />
        </div>
        <div />
        <div>
          <h1 className='mb-2 font-[500]'>Action</h1>
          <div className='grid grid-cols-2 gap-2'>
            <button
              onClick={clearFilters}
              className='px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5'
            >
              Clear
            </button>
            <button
              onClick={applyFilters}
              className='px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagmentFilter;
