import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PrimaryButton from "src/Components/Buttons/PrimaryButton"
import CustomhtmlSelect from "src/Components/CustomhtmlSelect"
import { GET_LEVEL_MASTER, GET_SKILL_MASTER, INSERT_SKILL } from "src/Redux/actions/workforce/eprofile"
import { AlertError } from "src/Services"
import { getDeafultProficiencyData, getDeafultSkillData } from "src/Services/constant/defaultPayload"

const UserPrimarySkill = ({ profileData, UserId }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state?.EmployeeProfile)
    const { userSkillList, proficiencylist, userSkillsDetail } = state
    const [skillmasterid, setSkillmaster] = useState(null)
    const [proficency, setproficency] = useState(null)

    useEffect(() => {
        dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData()))
    }, [dispatch])

    useEffect(() => {
        dispatch(GET_SKILL_MASTER({ ...getDeafultSkillData(), skillareamasterid: profileData?.skillareamasterid }))
        // eslint-disable-next-line 
    }, [profileData])

    const getSkillMaster = (keyname, value) => {
        setSkillmaster(value)
    }

    const getProficency = (keyname, value) => {
        setproficency(value)
    }

    const saveSkills = async () => {
        if (!proficency) {
            AlertError(`Proficiency is required! `)
            return
        } else if (!skillmasterid) {
            AlertError(`Skill is required! `)
            return
        }
        let otherskill = userSkillsDetail?.filter((it => it?.isprimary === false)) || []
        let payload = [
            ...otherskill, {
                comtenantid: +localStorage.getItem('Tenantid'),
                comuserid: UserId,
                comuserskilltechnologyid: null,
                createdcomuserid: UserId,
                createduser: UserId,
                domainid: null,
                experienceinmonths: null,
                expertise: null,
                isactiveflag: true,
                isprimary: true,
                lastusedyear: null,
                notes: null,
                remarks: null,
                skillcode: null,
                skilltype: null,
                technologymasterid: null,
                techversion: null,
                proficiencyid: proficency,
                skillmasterid
            }]
        await dispatch(INSERT_SKILL(payload))
    }

    return (
        <div className='w-full h-[65vh] text-sm mb-10'>
            <div className='grid grid-cols-4 gap-5 mt-4'>
                <div>
                    <label htmlFor='skillprimary' className='block font-[500] mb-2'>
                        Primary Skill
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={userSkillList || []}
                        value={skillmasterid}
                        noDataLabel='No Skill Area'
                        name='skillmasterid'
                        labelkey='skill'
                        className='border rounded bg-gray-50 py-2'
                        onChange={getSkillMaster}
                        id='skillmasterid'
                    />
                </div>
                <div>
                    <label htmlFor='Proficiency' className='block font-[500] mb-2'>
                        Proficiency
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={proficiencylist || []}
                        value={proficency}
                        noDataLabel='No Proficiency'
                        name='levelmasterid'
                        labelkey='levelname'
                        className='border rounded bg-gray-50 py-2'
                        onChange={getProficency}
                        id='levelmasterid'
                    />
                </div>
            </div>
            <div className='fixed bottom-0 right-6 mt-2'>
                <div className='gap-2 flex justify-end items-center'>
                    {/* CODE FOR FUTURE USE <PrimaryButton
                        onClick={handleCancel}
                        className='px-6 border bg-white border-primary text-black rounded mt-0 h-9 py-2 mb-2'
                    >
                        Cancel
                    </PrimaryButton> */}
                    <PrimaryButton
                        className='bg-primary text-white rounded py-2 px-8 mb-4 h-9'
                        onClick={saveSkills}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default UserPrimarySkill