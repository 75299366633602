import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import EmployeeUtilizationReportsFilter from 'src/Filter/reports/workforce/employeeUtilization'
import { GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import {
    GET_REPORT_MASTER_LIST,
    GET_UTILIZATION_REPORT_FOR_EMPLOYEE,
} from 'src/Redux/actions/reports'
import { GET_SKILL_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
    GET_BAND_MASTER,
    GET_CITY_MASTER,
    GET_COMPANY_MASTER,
    GET_DESIGNATION_MASTER,
} from 'src/Redux/actions/workforce/talent'
import {
    getBandMasterIdData,
    getCityPayload,
    getDeafultSkillData,
    getDesignationMasterIdData,
    getEmployeeUtilizationReportsPayload,
    getTenantCompanyIdData,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import EmployeeUtilizationReportsList from './table'

const UltilizationDetailsEmployee = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getEmployeeUtilizationReportsPayload())
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const dispatch = useDispatch()
    const locationwindow = useLocation()
    const searchParams = new URLSearchParams(locationwindow.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    const { utilizationreportforEmployee } = useSelector(
        (state) => state?.WorkforceReport,
    )
    const [user, setUser] = useState({})

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    useEffect(() => {
        dispatch(GET_UTILIZATION_REPORT_FOR_EMPLOYEE(getEmployeeUtilizationReportsPayload()))
        dispatch(GET_BAND_MASTER(getBandMasterIdData()))
        dispatch(GET_CITY_MASTER(getCityPayload()))
        dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_DESIGNATION_MASTER(getDesignationMasterIdData()))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <EmployeeUtilizationReportsFilter
                    setReportId={setReportId}
                    reportId={reportId}
                    setFilters={setFilters}
                    filters={filters}
                    user={user}
                    setUser={setUser}
                    page={page}
                    limit={limit}
                />
            </div>
            <EmployeeUtilizationReportsList
                utilizationreportforEmployee={utilizationreportforEmployee}
                filters={filters}
                user={user}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(UltilizationDetailsEmployee)
