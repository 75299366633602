import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {
	GET_PREVIOUS_EXPERIENCE,
	INSERT_EXPERIENCE,
} from 'src/Redux/actions/workforce/eprofile'
import {CheckListObjectValidation} from 'src/Services/constant/customvalidation'
import Table from './table'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import {experienceRowDefault} from 'src/Services/constant/staticValues'

const Experiences = ({canEdit, setCanEdit}) => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const {previousExperience} = EmployeeProfile
	const [formData, setFormData] = useState([])
	const [notValidated, setNotValidated] = useState(null)

	useEffect(() => {
		dispatch(GET_PREVIOUS_EXPERIENCE())
	}, [dispatch])

	useEffect(() => {
		setFormData(previousExperience)
	}, [previousExperience])

	const HandleSubmit = async () => {
		let NotReq = ['username', 'createdtimestamp', 'ctc', 'comuserprevexperienceid', 'notes','jobprofile' ,'fulltimeorparttimeflag', 'remarks']
		let isvalidaed = CheckListObjectValidation(formData, NotReq, false)
		let afterFilter = formData.map(item => {
				for (let keyname in experienceRowDefault()) {
					if (item[keyname] === undefined) {
						item[keyname] = experienceRowDefault()[keyname]
					}
				}
				return item
			})
		if (isvalidaed?.isvalid) {
			let res = await dispatch(INSERT_EXPERIENCE(afterFilter))
			if(res !== null) {

			}
			setCanEdit(false)
			await dispatch(GET_PREVIOUS_EXPERIENCE())
		} else {
			setNotValidated(isvalidaed)
		}
	}

	return (
		<div className="h-[44vh] text-sm my-2">
			<div className="overflow-hidden min-h-max">
				<Table
					notValidated={notValidated}
					canEdit={canEdit}
					formData={formData}
					setFormData={setFormData}
				/>
			</div>
			{canEdit ? (
				<div className="fixed mb-4 mr-4 bottom-2 right-6">
					<div className="flex items-center justify-end gap-2">
						<button
							onClick={() => {
								setCanEdit(false)
								dispatch(GET_PREVIOUS_EXPERIENCE())
							}}
							className="px-2 py-1 border rounded border-primary text-primary h-9"
						>
							Cancel
						</button>
						<PrimaryButton
							className="py-2 text-sm text-white rounded-md bg-primary"
							onClick={HandleSubmit}
						>
							Save
						</PrimaryButton>
					</div>
				</div>
			) : (
				''
			)}
		</div>
	)
}

export default Experiences
