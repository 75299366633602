import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import SingleSelectField from 'src/Components/SingleSelectForTable'
import {
    GET_CURRENCY_MASTER_LIST,
} from 'src/Redux/actions/projects'
import {
    getDefaultCurrencyPayload,
} from 'src/Services/constant/defaultPayload'

const quarters = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' },
]

const currentYear = new Date().getFullYear()
const financialYearsData = Array.from({ length: 21 }, (_, index) => {
    const year = currentYear - 5 + index
    return {
        value: year,
        label: `${year}-${(year + 1).toString().slice(-2)}`
    }
})

const monthsData = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
];

const periodTypes = [
    { label: 'YTD', value: 'YTD' },  // Year-to-Date
    { label: 'QTD', value: 'QTD' },  // Quarter-to-Date
    { label: 'MTD', value: 'MTD' },  // Month-to-Date
];


const FinanceAnalyticSubFilter = ({ filters, setFilters }) => {
    const dispatch = useDispatch()
    const { currencyList } = useSelector(
        (state) => state?.Projects,
    )
    const [availableQuarters, setAvailableQuarters] = useState(quarters)
    const [availableMonths, setAvailableMonths] = useState([])

    useEffect(() => {
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
    }, [dispatch])

    useEffect(() => {
        if (currencyList && currencyList.length > 0) {
            const usdCurrency = currencyList.find(currency => currency.currency === 'USD')
            if (usdCurrency && !filters.defaultcomcurrencyid) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    defaultcomcurrencyid: usdCurrency.datcomcurrencyid
                }))
            }
        }
    }, [currencyList, filters.defaultcomcurrencyid, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleSelectTab = (name, value) => {
        setFilters({ ...filters, [name]: value, year: null, quarter: null, month: null  })
    }

    const handleYearChange = (value) => {
        setFilters({ ...filters, year: value.value, quarter: null, month: null, typeid:0 })
        setAvailableQuarters(quarters)
        setAvailableMonths([])
    }

    const handleQuarterChange = (value) => {
        setFilters({ ...filters, quarter: value.value, month: null , typeid: 0})
        const quarterMonths = monthsData.slice((value.value.slice(-1) - 1) * 3, (value.value.slice(-1) - 1) * 3 + 3)
        setAvailableMonths(quarterMonths)
    }

    const handleMonthChange = (value) => {
        setFilters({ ...filters, month: value.value })
    }

    return (
        <div className="text-sm flex justify-start items-center space-x-2">
            <CustomhtmlSelect
                options={currencyList}
                value={filters?.defaultcomcurrencyid}
                noDataLabel='No Currency'
                name='defaultcomcurrencyid'
                labelkey='currency'
                className='py-1.5 border rounded bg-gray-50'
                onChange={handleSelectChange}
                id='datcomcurrencyid'
            />
            <div className="py-0.5 border rounded bg-gray-50">
                <SingleSelectField
                    displayLabel='Year'
                    valueKey="value"
                    labelKey="label"
                    options={financialYearsData}
                    value={filters?.year}
                    handleSelected={handleYearChange}
                />
            </div>
            <div className="py-0.5 border rounded bg-gray-50">
                <SingleSelectField
                    valueKey="value"
                    labelKey="label"
                    displayLabel='Quarter'
                    options={filters?.year  ? availableQuarters : []}
                    value={filters?.quarter}
                    handleSelected={handleQuarterChange}
                />
            </div>
            <div className="py-0.5 border rounded bg-gray-50">
                <SingleSelectField
                    displayLabel='Month'
                    valueKey="value"
                    labelKey="label"
                    options={filters?.quarter ? availableMonths : []}
                    value={filters?.month}
                    handleSelected={handleMonthChange}
                    isDisabled={!filters?.quarter}
                />
            </div>
            <FilterButtonTabObject
                className="py-1.5 border bg-gray-50"
                tabs={periodTypes || []}
                selected={filters?.typeid}
                labelkey="label"
                name="typeid"
                valuekey="value"
                onClick={handleSelectTab}
            />
        </div>
    )
}

export default FinanceAnalyticSubFilter
