export const BandItem = () => {
   return {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      "bandmasterid": null,
      "bandname": "",
      "isactiveflag": true,
      "createdcomuserid": JSON.parse(localStorage.getItem('comuserid')),
      "notes": "",
      "slno": 0
   }
}

export const detailNotRequired = [
   "bandmasterid",
   'notes',
   'slno'
];
