import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import drilldown from 'highcharts/modules/drilldown'
import { useState, useEffect } from 'react'

// Initialize the drilldown module
drilldown(Highcharts)

const data = [
    { name: 'Business Unit 1', y: 30, drilldown: 'Business Unit 1' },
    { name: 'Business Unit 2', y: 60, drilldown: 'Business Unit 2' },
    { name: 'Business Unit 3', y: 85, drilldown: 'Business Unit 3' },
]

const drilldownSeries = [
    {
        id: 'Region1',
        name: 'Accounts',
        data: [
            { name: 'Account 1', y: 20, drilldown: 'Account1' },
            { name: 'Account 2', y: 40, drilldown: 'Account2' },
        ],
    },
    // ... (other drilldown series remain the same)
]

const getBarColor = (index) => {
    const colors = ['#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658']
    return colors[index % colors.length]
}

const BarChartDrillDown = () => {
    const [chartOptions, setChartOptions] = useState({})
    const [navigationPath, setNavigationPath] = useState([{ name: 'Utilization', level: 'Business Unit' }])

    useEffect(() => {
        setChartOptions(generateChartOptions(data, 'Business Unit'))
    // eslint-disable-next-line
    }, [])

    const handleDrilldown = (event) => {
        const { point } = event
        const newLevel = point.drilldown
        setNavigationPath((prevPath) => [...prevPath, { name: point.name, level: newLevel }])
        // Here you would typically fetch new data based on the drilldown
        // For this example, we'll just use the static drilldownSeries data
        const newData = drilldownSeries.find(series => series.id === newLevel)
        if (newData) {
            setChartOptions(generateChartOptions(newData.data, newLevel))
        }
    }

    const handleDrillup = () => {
        if (navigationPath.length <= 1) return
        const newPath = navigationPath.slice(0, -1)
        setNavigationPath(newPath)
        const newLevel = newPath[newPath.length - 1].level
        setChartOptions(generateChartOptions(data, newLevel))
    }

    const generateChartOptions = (chartData, currentLevel) => ({
        chart: {
            type: 'column',
            backgroundColor: 'transparent',
            style: {
                fontSize: '14px',
            },
            events: {
                drilldown: handleDrilldown,
                drillup: handleDrillup,
            },
        },
        title: {
            text: '',
        },
        xAxis: {
            type: 'category',
            labels: {
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
        },
        yAxis: {
            title: {
                text: 'Utilization (%)',
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
            labels: {
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}%',
                    style: {
                        color: '#6b7280',
                        fontSize: '14px',
                        fontWeight: '500',
                    },
                },
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:14px;font-weight:500;color:#6b7280">{point.series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}%</b><br/>',
        },
        series: [
            {
                name: currentLevel,
                colorByPoint: true,
                data: chartData.map((item, index) => ({
                    ...item,
                    color: getBarColor(index),
                })),
            },
        ],
        drilldown: {
            activeAxisLabelStyle: {
                textDecoration: 'none',
                color: '#6b7280',
                fontWeight: '500',
            },
            activeDataLabelStyle: {
                textDecoration: 'none',
                color: '#6b7280',
                fontWeight: '500',
            },
            series: drilldownSeries,
        },
    })

    return (
        <div className="relative flex flex-col items-center justify-center w-full h-[55vh]">
            <div
                className="subtitle"
                style={{
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                    cursor: 'pointer',
                    marginBottom: '10px',
                }}
            >
                {navigationPath.map((item, index) => (
                    <span key={index} className="highcharts-breadcrumb">
                        {item.name}
                        {index < navigationPath.length - 1 ? ' / ' : ''}
                    </span>
                ))}
            </div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default BarChartDrillDown