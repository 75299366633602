import { useMemo } from 'react'
import TimeSelect from './timeSelect'

const EffortInput = (props) => {
    let { handleeffort, item, disabled, holidayActive, row, isOnLeave, selectedTimetype, effortIndex } = props
    let timeformat = selectedTimetype?.timeentryformat
    let currentCellLeave = row?.leavedetails_var?.find(l => l?.leavedate === item?.effortdate)
    let leaveActive = isOnLeave ? (currentCellLeave?.Noofdays < 1 ? false : isOnLeave) : isOnLeave

    const weekendentryallowed = useMemo(() => {
        if ((effortIndex === 6 || effortIndex === 5)) {
            return selectedTimetype?.weekendentryallowed
        }
        // eslint-disable-next-line
    }, [selectedTimetype, effortIndex, row?.timetypeid])

    const weekdayentryallowed = useMemo(() => {
        if (effortIndex <= 4) {
            return selectedTimetype?.weekdayentryallowed
        }
        // eslint-disable-next-line
    }, [selectedTimetype, effortIndex, row?.timetypeid])

    const handleUpdate = (e) => {
        handleeffort(item?.effortdate, e.target?.value, row?.projtaskid, effortIndex)
    }


    const renderWeekDayCalender = useMemo(() => {
        if ((holidayActive || leaveActive) && selectedTimetype?.holidayallowed) {
            return <TimeSelect
                effortdate={item?.effortdate}
                {...props} />
        } else if (!holidayActive && !leaveActive) {
            return <TimeSelect
                effortdate={item?.effortdate}
                {...props} />
        } else if (!leaveActive && !holidayActive) {
            return <TimeSelect
                effortdate={item?.effortdate}
                {...props} />
        }
        // eslint-disable-next-line
    }, [selectedTimetype, holidayActive])

    return (
        <span className="flex justify-start items-center text-primary/40 space-x-2">
            {holidayActive && <span className="h-4 w-[3px] rounded-md  bg-blue-500" />}
            {isOnLeave && <span className="h-4 w-[3px] rounded-md  bg-gray-500" />}
            {timeformat !== 'Duration' ? <>
                {weekendentryallowed && <input
                    disabled={leaveActive || disabled}
                    value={item?.efforthours}
                    name={item?.effortdate}
                    id={item?.effortdate}
                    autoComplete="false"
                    onChange={handleUpdate}
                    className={`border border-primary/40 disabled:border-0 outline-none w-9 rounded  text-gray-700 pl-0.5 bg-transparent `} />}
                {weekdayentryallowed && <input
                    disabled={leaveActive || disabled}
                    value={item?.efforthours}
                    name={item?.effortdate}
                    id={item?.effortdate}
                    autoComplete="false"
                    onChange={handleUpdate}
                    className={`border border-primary/40 disabled:border-0 outline-none w-9 rounded  text-gray-700 pl-0.5 bg-transparent `} />}
            </> : <>
                {/* SHOW  INPUT IF WEEK_DAY OR WEEK_END IS TRUE AND ALSO CHECK CONDITION IF HOLIDAY AND LEAVE IS FALE */}
                {weekdayentryallowed ? <span className='w-full'>
                    {renderWeekDayCalender}
                </span> : ''}

                {weekendentryallowed ? <span className='w-full'>
                    {renderWeekDayCalender}
                </span> : ''}
            </>}
        </span>
    )
}

export default EffortInput;