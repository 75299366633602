import BarChartDrillDown from "./barchart"

const Utilization = () => {
    return (
        <div>
            <div className="grid w-full h-full grid-cols-1 gap-4 p-4 md:grid-cols-1">
                <div className="flex flex-col items-center justify-center w-full h-full p-4"><BarChartDrillDown /></div>
            </div>
        </div>
    )
}

export default Utilization
