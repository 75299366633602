import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { RulesItem } from './default';
import CreateRules from './createRules';
import EditRules from './editRules';
import { GET_COMPANY_MASTER, GET_WORKERTYPE_MASTER } from 'src/Redux/actions/workforce/talent';
import { GET_TIME_CATEGORY_MASTER, GET_TEMPLATE_MASTER, GET_RULES_MASTER, } from 'src/Redux/actions/configuration';
import { GET_COUNTRY_MASTER } from 'src/Redux/actions/masterdata/user';
import { basicShortList } from 'src/Services/constant/staticValues';
import { getMasterCountryPayload, getTenantWorkerTypeyIdData, getMasterCompanyIdData, } from 'src/Services/constant/defaultPayload';
import { getMasterCategoryPayload, getTemplateCategoryPayload, getRulesPayload, } from 'src/Services/constant/masterdatapayload';

const cols = ['Company', 'Time Template', 'Template Type', 'Time  Type', 'Country', 'Worker Type',
  // 'Validate Time', 'Daily Hours (Min)', 'Daily Hours (Max)', 'Time Entry Format', 'Time Type Uniqueness', 'Holiday Calendar Integration', 'Weekend Entry', 'Weekday Entry', 'Holiday Entry', 'Restrict By Allocation', 'Self Attestation',
  'Status', 'Created At', 'Created By',];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const RulesMasterList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [company, setCompany] = useState({});
  const [country, setCountry] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [template, setTemplate] = useState({});
  const [rulesId, setRulesId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [workertypefilter, setWorkertypeFilter] = useState({});
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(RulesItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
  const [filteredTemplateList, setFilteredTemplateList] = useState([{templatename: 'All', timetemplatemasterid: 0 }]);

  const dispatch = useDispatch();
  const { rulesList, PermissionsOfConfiguration } = useSelector((state) => state.ConfigurationList);
  const { companylist } = useSelector((state) => state.EmployeeDetails);
  const { countryList } = useSelector((state) => state.UserManagementList);
  const { workertypelist } = useSelector((state) => state.EmployeeDetails);

  useEffect(() => {
    dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
    dispatch(GET_COUNTRY_MASTER(getMasterCountryPayload()));
    dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()));
    dispatch(GET_TIME_CATEGORY_MASTER(getMasterCategoryPayload()));
    dispatch(GET_TEMPLATE_MASTER(getTemplateCategoryPayload(1,100)));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (company?.companyid) {
      let payload = {
        ...getTemplateCategoryPayload(1,100),
        companyid: company.companyid,
      };
      dispatch(GET_TEMPLATE_MASTER(payload))
        .then(res => {
          const filteredData = res?.data;
          if(filteredData?.length > 0){
            setFilteredTemplateList([ {templatename: 'All', timetemplatemasterid: 0 } ,...filteredData]);
          }
        });
    } else {
      setFilteredTemplateList([{templatename: 'All', timetemplatemasterid: 0 }]);
    }
  }, [company, dispatch]);

  async function fetchData() {
    setLoading(true); // set loading state to true when data is being fetched
    let res = await dispatch(
      GET_RULES_MASTER(getRulesPayload(1, 10, 3, 'Active'))
    );
    if (res !== null) {
      setDetailsData(res?.data);
    }
    setLoading(false); // set loading state to false when data has been fetched
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  const handleBuClick = (bu) => {
    setDetailsData(bu);
    setRulesId(bu?.timetypetemplatemappingid);
    setIsOpen(true);
  };


  const handleSorting = async (item) => {
    setSortType(item);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), sorttype: item.id, status: status?.label, limit: limit, companyid: company?.companyid, countryid: country?.comcountryid,
        workertypeid: workertypefilter?.workertypeid,
        timecategorymasterid: 0,
        timetemplatemasterid: template?.timetemplatemasterid,
        // Use the selected country ID from the event
      })
    );
  };

  const handleStatus = async (item) => {
    setStatus(item);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), status: item.label, companyid: company?.companyid, countryid: country?.comcountryid, workertypeid: workertypefilter?.workertypeid, timecategorymasterid: 0, timetemplatemasterid: template?.timetemplatemasterid, // Use the selected country ID from the event
      })
    );

  };
  const handlePagination = async (page) => {
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, companyid: company?.companyid, countryid: country?.comcountryid, workertypeid: workertypefilter?.workertypeid, timecategorymasterid: 0, timetemplatemasterid: template?.timetemplatemasterid, // Use the selected country ID from the event
      })
    );

    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), limit: row, page,
        sorttype: sortType?.id, status: status?.label,
        companyid: company?.companyid,
        countryid: country?.comcountryid,
        workertypeid: workertypefilter?.workertypeid,
        timecategorymasterid: 0,
        timetemplatemasterid: template?.timetemplatemasterid, // Use the selected country ID from the event
      })
    );

  };
  const handleCompany = async (item) => {
    setCompany(item);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), sorttype: sortType?.id || 0,
        status: status.label, companyid: item?.companyid,
        countryid: country?.comcountryid || 0,
        workertypeid: workertypefilter?.workertypeid || 0,
        timecategorymasterid: 0,
        timetemplatemasterid: template?.timetemplatemasterid || 0,
      })
    );
  };
  const handleCountry = async (item) => {
    setCountry(item);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), sorttype: sortType?.id || 0,
        status: status.label, companyid: company?.companyid,
        countryid: item?.comcountryid || 0,
        workertypeid: workertypefilter?.workertypeid || 0,
        timecategorymasterid: 0,
        timetemplatemasterid: template?.timetemplatemasterid || 0,
      })
    );
  };

  const handleWorkertype = async (item) => {
    setWorkertypeFilter(item);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(),
        sorttype: sortType?.id || 0,
        status: status?.label,
        companyid: company?.companyid || 0,
        countryid: country?.comcountryid || 0,
        workertypeid: item?.workertypeid || 0,
        timecategorymasterid: 0,
        timetemplatemasterid: template?.timetemplatemasterid || 0,
      })
    );
  };

  const handleTimeTemplate = async (item) => {
    setTemplate(item);
    dispatch(
      GET_RULES_MASTER({
        ...getRulesPayload(), sorttype: sortType?.id || 0,
        status: status.label,
        companyid: company?.companyid || 0,
        countryid: country?.comcountryid || 0,
        workertypeid: workertypefilter?.workertypeid || 0,
        timecategorymasterid: 0,
        timetemplatemasterid: item?.timetemplatemasterid || 0,
      })
    );
  };

  return (
    <div className='mt-4 p-1 py-1 w-full h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-2'>
          <h1 className='pb-2 font-[500]'>
            Rules (
            {rulesList?.length > 0
              ? rulesList[0]?.totalcount
              : rulesList?.length}
            ){' '}
          </h1>
          <div className='flex justify-start items-center space-x-4'>
            <ReactPaginationStyle
              total={
                rulesList?.length > 0
                  ? rulesList[0]?.totalcount
                  : rulesList?.length
              }
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-30 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-30 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-40 '>
            <SelectInput
              options={[{ companyname: 'All', companyid: 0 }, ...companylist]}
              keyname='companyname'
              selected={company}
              setSelected={handleCompany}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-22 '>
            <SelectInput
              options={[{ countryname: 'All', comcountryid: 0 }, ...countryList] }
              keyname='countryname'
              selected={country}
              setSelected={handleCountry}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-40 '>
            <SelectInput
              options={[{ workertype: 'All', workertypeid: 0 }, ...workertypelist]}
              keyname='workertype'
              selected={workertypefilter}
              setSelected={handleWorkertype}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-44 '>
            <SelectInput
              options={filteredTemplateList}
              keyname='templatename'
              selected={template}
              setSelected={handleTimeTemplate}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {PermissionsOfConfiguration?.includes('Add/Edit Config') ? (
          <CreateRules />
        ) : (
          ''
        )}
      </div>
      {loading ? (
        <div className='flex items-center justify-center w-full h-1/2'>
          <div
            className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]'
            role='status'
          >
            <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
              Loading...
            </span>
          </div>
        </div>
      ) : (
        <div className='h-[56vh] overflow-scroll'>
          <table className='text-sm w-full relative'>
            <thead className='sticky -top-1'>
              <tr className='text-left capitalize bg-gray-50'>
                {cols?.map((col) => {
                  return (
                    <th
                      key={col}
                      title={col}
                      scope='col'
                      className='font-[500] px-1 py-3 border text-start'
                    >
                      {col}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {rulesList?.map((item, i) => {
                let {
                  companyname,
                  templatename,
                  timecategory,
                  countryname,
                  workertype,

                  templatetype,
                  isactiveflag,
                  createduser,
                  createdtimestamp,
                  timetypetemplatemappingid,
                } = item;
                return (
                  <tr
                    onClick={() => handleBuClick(item)}
                    key={timetypetemplatemappingid}
                    className='cursor-pointer text-left'
                  >
                    <td className='border p-1'>{companyname}</td>
                    <td className='border p-1'>{templatename} </td>
                    <td className='border p-1'> {templatetype}</td>
                    <td className='border p-1'>{timecategory} </td>
                    <td className='border p-1'> {countryname}</td>
                    <td className='border p-1'> {workertype}</td>
                    <td className='border p-1'>
                      {isactiveflag === true ? 'Active' : 'Inactive'}
                    </td>
                    <td className='border p-1'>
                      {moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}
                    </td>
                    <td className='border p-1'>{createduser}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <EditRules
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        timetypetemplatemappingid={rulesId}
        isOpen={isOpen}
        setPage={setPage}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfConfiguration}
      />
    </div>
  );
};

export default memo(RulesMasterList);
