import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import BusinessUnitMasterList from './Business'
import BusinessGroupMasterList from './Business/businessGroup'
import CompanyMasterList from './Company'
import CompanyHolidayMasterList from './Company/holidays'
import CompanyWeekdaysMasterList from './Company/weekdays'
import StateMasterList from './Geography'
import CityMasterList from './Geography/city'
import CountryMasterList from './Geography/country'
import LocationMasterList from './Geography/location'
import RegionMasterList from './Geography/region'
import DepartmentMasterList from './Organization'
import AnnouncementMasterList from './Organization/announcement'
import BandMasterList from './Organization/band'
import DesignationMasterList from './Organization/designation'
import ServiceLineList from './Service'
import ServiceLineGroupList from './Service/serviceline'
import SkillMasterList from './Skill'
import SkillAreaMasterList from './Skill/skillArea'
import TechnologyMasterList from './Technology'
import TechnologyGroupMasterList from './Technology/techGroup'
import CustomerManagementList from './list'
import CustomerRoleManagmentList from './list/CustomerRole'
//  CODE FOR FUTURE USE import DeliveryRoleManagmentList from './list/DeliveryRoles'
import NoPermissionComponent from 'src/Components/NoPermission'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import CourseMasterList from './Education/course'
import QualificationsMasterList from './Education/qualification'
import SpecialisationsMasterList from './Education/specialisation'
import ExchangeRatesMasterList from './ExchangeRates/index'
import CostRatesMasterlist from './Finance'
import OverheadsMasterList from './Finance/overhead'
import WorkerTypesMasterList from './Organization/workerType'
import TaskMasterList from './Task'
import TaskGroupMasterList from './Task/taskGroup'
import ProductMasterList from './Technology/product'
import CustomerHolidayMasterList from './list/customerholidays'
import CustomerWeekdaysMasterlist from './list/customerweekdays'
import IndustryList from './list/industry'
import OpportunityManagmentList from './list/opportunity'

const typeList = [
    'Companies',
    'Business Units',
    'Service Lines',
    'Customers',
    // CODE FOR FUTURE USE 'Delivery Roles',
    'Geographies',
    'Organization',
    'Education',
    'Technologies',
    'Skills',
    'Tasks',
    'Finance',
]

const tabslistforCC = ['Details', 'Holidays', 'Weekdays']
const tabsList = ['Service Line Groups', 'Service Lines']
const tabslistforB = ['Business Unit Groups', 'Business Units']
const tabslistforT = ['Technology Groups', 'Technologies', 'Products']
const tabslistforS = ['Skill Areas', 'Skills']
const tabslistforG = ['Regions', 'Countries', 'States', 'Cities', 'Locations']
const tabslistforO = ['Departments', 'Designation', 'Band', 'Worker Types', 'Announcements']
const tabslistforE = ['Courses', 'Specializations', 'Qualifications']
const tabslistforC = ['Customer Groups', 'Customers', 'Opportunities', 'Industries', 'Holidays', 'Weekdays']
const tabslistforTT = ['Task Groups', 'Tasks']
const tabslistforF = ['Overheads', 'Cost Rates', 'Exchange Rates']

const MasterData = ({ modulename, menu }) => {
    const dispatch = useDispatch()
    const [typeis, setTypeis] = useState(typeList[0])
    const [tabs, setTabs] = useState(tabsList[0])
    const [tabsB, setTabsB] = useState(tabslistforB[0])
    const [tabsT, setTabsT] = useState(tabslistforT[0])
    const [tabsS, setTabsS] = useState(tabslistforS[0])
    const [tabsG, setTabsG] = useState(tabslistforG[0])
    const [tabsO, setTabsO] = useState(tabslistforO[0])
    const [tabsC, setTabsC] = useState(tabslistforC[0])
    const [tabsTT, setTabsTT] = useState(tabslistforTT[0])
    const [customers, setCustomers] = useState([])
    const [tabsF, setTabsF] = useState(tabslistforF[0])
    const [tabsCC, setTabsCC] = useState(tabslistforCC[0])
    const [tabsE, setTabsE] = useState(tabslistforE[0])
    const { PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList)

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        dispatch({ type: 'SAVE_PERMISSIONS_OF_MASTERDATA', data: permissionNames })
        // eslint-disable-next-line
    }, [dispatch, menu])

    return (
        <div>
            <div className="flex items-start px-1 py-2 ">
                <FilterButtonTab
                    className="px-5 py-2 shadow"
                    tabs={typeList}
                    setSelectedtab={setTypeis}
                    selectedTab={typeis}
                />
            </div>
            <div className="px-1 text-sm ">
                {typeis === 'Business Units' &&
                    (PermissionsOfMasterdata?.includes('View Business Unit') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforB}
                                    setSelectedtab={setTabsB}
                                    selectedTab={tabsB}
                                />
                            </div>
                            <div>
                                {tabsB === 'Business Unit Groups' ? (
                                    <>
                                        <BusinessGroupMasterList modulename={modulename} menu={menu} />
                                    </>
                                ) : (
                                    <>
                                        <BusinessUnitMasterList modulename={modulename} menu={menu} />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Customers' &&
                    (PermissionsOfMasterdata?.includes('View Customer') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforC}
                                    setSelectedtab={setTabsC}
                                    selectedTab={tabsC}
                                // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsC === 'Customer Groups' && (
                                    <>
                                        <CustomerRoleManagmentList />
                                    </>
                                )}
                                {tabsC === 'Customers' && (
                                    <>
                                        <CustomerManagementList users={customers} setUsers={setCustomers} />
                                    </>
                                )}
                                {tabsC === 'Opportunities' && (
                                    <>
                                        <OpportunityManagmentList />
                                    </>
                                )}
                                {tabsC === 'Industries' && (
                                    <>
                                        <IndustryList />
                                    </>
                                )}
                                {tabsC === 'Holidays' && (
                                    <>
                                        <CustomerHolidayMasterList />
                                    </>
                                )}
                                {tabsC === 'Weekdays' && (
                                    <>
                                        <CustomerWeekdaysMasterlist />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Companies' &&
                    (PermissionsOfMasterdata?.includes('View Company') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforCC}
                                    setSelectedtab={setTabsCC}
                                    selectedTab={tabsCC}
                                // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsCC === 'Details' && (
                                    <>
                                        <CompanyMasterList />
                                    </>
                                )}
                                {tabsCC === 'Holidays' && (
                                    <>
                                        <CompanyHolidayMasterList />
                                    </>
                                )}
                                {tabsCC === 'Weekdays' && (
                                    <>
                                        <CompanyWeekdaysMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {/* CODE FOR FUTURE USE
					{typeis === 'Delivery Roles' && (
						<>
						<DeliveryRoleManagmentList />
						</>
					)} */}
                {typeis === 'Organization' &&
                    (PermissionsOfMasterdata?.includes('View Organization') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforO}
                                    setSelectedtab={setTabsO}
                                    selectedTab={tabsO} // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsO === 'Designation' && (
                                    <>
                                        <DesignationMasterList />
                                    </>
                                )}
                                {tabsO === 'Departments' && (
                                    <>
                                        <DepartmentMasterList />
                                    </>
                                )}
                                {tabsO === 'Band' && (
                                    <>
                                        <BandMasterList />
                                    </>
                                )}
                                {tabsO === 'Worker Types' && (
                                    <>
                                        <WorkerTypesMasterList />
                                    </>
                                )}
                                {tabsO === 'Announcements' && (
                                    <>
                                        <AnnouncementMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Geographies' &&
                    (PermissionsOfMasterdata?.includes('View Geographies') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforG}
                                    setSelectedtab={setTabsG}
                                    selectedTab={tabsG}
                                    buttonWidth="auto"
                                />
                            </div>
                            <div>
                                {tabsG === 'States' && (
                                    <>
                                        <StateMasterList />
                                    </>
                                )}
                                {tabsG === 'Locations' && (
                                    <>
                                        <LocationMasterList />
                                    </>
                                )}
                                {tabsG === 'Regions' && (
                                    <>
                                        <RegionMasterList />
                                    </>
                                )}
                                {tabsG === 'Cities' && (
                                    <>
                                        <CityMasterList />
                                    </>
                                )}
                                {tabsG === 'Countries' && (
                                    <>
                                        <CountryMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Technologies' &&
                    (PermissionsOfMasterdata?.includes('View Technology') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforT}
                                    setSelectedtab={setTabsT}
                                    selectedTab={tabsT}
                                // buttonWidth='w-auto'
                                />
                            </div>
                            <div>
                                {tabsT === 'Technology Groups' && (
                                    <>
                                        <TechnologyGroupMasterList />
                                    </>
                                )}
                                {tabsT === 'Technologies' && (
                                    <>
                                        <TechnologyMasterList />
                                    </>
                                )}
                                {tabsT === 'Products' && (
                                    <>
                                        <ProductMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Skills' &&
                    (PermissionsOfMasterdata?.includes('View Skills') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforS}
                                    setSelectedtab={setTabsS}
                                    selectedTab={tabsS}
                                // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsS === 'Skill Areas' ? (
                                    <>
                                        <SkillAreaMasterList />
                                    </>
                                ) : (
                                    <>
                                        <SkillMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Education' &&
                    (PermissionsOfMasterdata?.includes('View Education') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforE}
                                    setSelectedtab={setTabsE}
                                    selectedTab={tabsE}
                                // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsE === 'Courses' && (
                                    <>
                                        <CourseMasterList />
                                    </>
                                )}
                                {tabsE === 'Specializations' && (
                                    <>
                                        <SpecialisationsMasterList />
                                    </>
                                )}
                                {tabsE === 'Qualifications' && (
                                    <>
                                        <QualificationsMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Service Lines' &&
                    (PermissionsOfMasterdata?.includes('View Service Line') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabsList}
                                    setSelectedtab={setTabs}
                                    selectedTab={tabs}
                                />
                            </div>
                            <div>
                                {tabs === 'Service Lines' ? (
                                    <>
                                        <ServiceLineList />
                                    </>
                                ) : (
                                    <>
                                        <ServiceLineGroupList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Tasks' &&
                    (PermissionsOfMasterdata?.includes('View Task') ? (
                        <>
                            <div className="flex justify-start">
                                {/* <div className="w-[400px] text-sm"> */}
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforTT}
                                    setSelectedtab={setTabsTT}
                                    selectedTab={tabsTT}
                                // buttonWidth='w-56'
                                />
                                {/* </div> */}
                            </div>
                            <div>
                                {tabsTT === 'Task Groups' ? (
                                    <>
                                        <TaskGroupMasterList />
                                    </>
                                ) : (
                                    <>
                                        <TaskMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Finance' &&
                    (PermissionsOfMasterdata?.includes('View Finance') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforF}
                                    setSelectedtab={setTabsF}
                                    selectedTab={tabsF}
                                // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsF === 'Overheads' && (
                                    <>
                                        <OverheadsMasterList />
                                    </>
                                )}
                                {tabsF === 'Cost Rates' && (
                                    <>
                                        <CostRatesMasterlist />
                                    </>
                                )}
                                {tabsF === 'Exchange Rates' && (
                                    <>
                                        <ExchangeRatesMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
            </div>
        </div >
    )
}

export default MasterData
