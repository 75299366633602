import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'src/Components/Buttons/IconButton';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { GET_ALL_CONTACTS_OF_A_CUSTOMER } from 'src/Redux/actions/masterdata/customer';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';

const cols = [
  'Customer SPOC',
  'Contact Type',
  'Designation',
  'Email',
  'Phone No.',
  'Is Primary',
];

const isValidatedField = (checkvalidation, index, keyname) => {
  return ((checkvalidation?.keyname === keyname) && (checkvalidation?.rowNo === index + 1))
}

const ContactInfo = (props) => {
  const dispatch = useDispatch()
  const { setContactsData, contactsData, customerid, mockObject, handleSubmit, canEdit, setCanEdit } = props
  const [checkvalidation, setcheckvalidation] = useState(null)
  const { CustomerContacts } = useSelector(state => state?.CustomerManagementList)

  useEffect(() => {
    dispatch(GET_ALL_CONTACTS_OF_A_CUSTOMER(customerid))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerid])

  useEffect(() => {
    if (CustomerContacts?.length) {
      let rmUnwanted = CustomerContacts?.map((it => {
        delete it['customercontactpersonid']
        delete it['createdtimestamp']
        delete it['modifiedtimestamp']
        return it
      }))
      setContactsData(rmUnwanted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomerContacts])

  const handleChange = (e) => {
    let { id, name, value } = e.target
    setcheckvalidation(null)
    let afterAdd = contactsData?.map((item) => {
      if (item?.uId === id) {
        item[name] = value
      }
      return item
    })
    setContactsData(afterAdd)
  }

  const handlePrimarySelect = (e) => {
    let { id, name, checked } = e.target
    let afterChange = contactsData?.map((item) => {
      if (item?.uId === id) {
        item[name] = checked
      } else {
        item[name] = false
      }
      return item
    })
    setContactsData(afterChange)
  }

  const handleAdd = () => {
    setContactsData([...contactsData, mockObject])
  }

  const handleDelete = uId => {
    if (contactsData?.length > 1) {
      setContactsData(contactsData.filter(item => item?.uId !== uId))
    }
  }

  const saveContactInfo = () => {
    let isValidated = CheckListObjectValidation(contactsData, ['notes', 'modifiedcomuserid'])
    if (isValidated?.isvalid) {
      handleSubmit()
    } else {
      setcheckvalidation(isValidated)
    }
  }

  return (
    <div className='my-2 text-sm'>
      <div className='w-full h-[65vh] text-sm mb-10'>
        <div className='h-[60vh] overflow-scroll'>
          <table className='w-full relative text-left'>
            <thead className='capitalize bg-gray-50 h-[1vh]'>
              <tr className='text-left capitalize bg-gray-50'>
                {cols?.map((col) => {
                  return (
                    <th
                      key={col}
                      title={col}
                      className='p-1 border font-[500]'>
                      {col}
                    </th>

                  );
                })}
                {!canEdit && <th
                  className='p-1 border font-[500]'>
                  Action
                </th>}
              </tr>
            </thead>
            <tbody className='text-gray-600 mr-4'>
              {contactsData?.map((item, i) => {
                const {
                  uId,
                  contactpersonname,
                  designation,
                  phoneno,
                  email,
                  contacttype,
                  isprimary,
                } = item;
                return (
                  <tr key={uId} className='text-left'>
                    <td className={`${isValidatedField(checkvalidation, i, 'contactpersonname') ? 'border-2 border-red-400' : 'border'} `}>
                      <input
                        id={uId}
                        disabled={canEdit}
                        onChange={(e) => {
                          if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                            handleChange(e)
                          }
                        }}
                        type='text'
                        value={contactpersonname}
                        name='contactpersonname'
                        placeholder='Contact Person'
                        // onChange={(e) => handleChange(e.target.value, 'contactpersonname', item.id)}
                        className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                      />
                    </td>
                    <td className={`${isValidatedField(checkvalidation, i, 'contacttype') ? 'border-2 border-red-400' : 'border'} `}>
                      <input
                        id={uId}
                        disabled={canEdit}
                        onChange={(e) => {
                          if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                            handleChange(e)
                          }
                        }}
                        type='text'
                        value={contacttype}
                        name='contacttype'
                        placeholder=' Contact Type'
                        className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                      />
                    </td>
                    <td className={`${isValidatedField(checkvalidation, i, 'designation') ? 'border-2 border-red-400' : 'border'} `}>
                      <input
                        id={uId}
                        disabled={canEdit}
                        onChange={handleChange}
                        type='text'
                        value={designation}
                        name='designation'
                        placeholder='Designation'
                        className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                      />
                    </td>
                    <td className={`${isValidatedField(checkvalidation, i, 'email') ? 'border-2 border-red-400' : 'border'} `} >
                      <input
                        id={uId}
                        disabled={canEdit}
                        onChange={handleChange}
                        type='email'
                        value={email}
                        name='email'
                        placeholder='Email'
                        className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                      />
                    </td>
                    <td className={`${isValidatedField(checkvalidation, i, 'phoneno') ? 'border-2 border-red-400' : 'border'} `} >
                      <input
                        id={uId}
                        disabled={canEdit}
                        onChange={handleChange}
                        type='text'
                        value={phoneno}
                        name='phoneno'
                        placeholder='Phone No'
                        className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                      />
                    </td>
                    <td className='border px-4 py-1'>
                      <input
                        id={uId}
                        onChange={handlePrimarySelect}
                        type='checkbox'
                        checked={isprimary}
                        name='isprimary'
                        className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                      />
                    </td>
                    {!canEdit && <td className={`${isValidatedField(checkvalidation, i, 'email') ? 'border-2 border-red-400' : 'border'} `}>
                      <div className='flex justify-start items-cpx-2 w-full'>
                        <IconButton
                          title="Delete"
                          onClick={() => handleDelete(item?.uId)}
                          className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                        >
                          <MinusIcon className="w-3" />
                        </IconButton>
                        <span className="hidden addIconbutton-add-btn">
                          <IconButton
                            title="Add"
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>
                        </span>
                      </div>
                    </td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='fixed bottom-12 left-8'>
          <div className="grid grid-cols-4 gap-8 my-7">
            {/*      CODE FOR FUTURE USE
             <div>
              <label htmlFor="formFileSm" className="form-label inline-block mb-2  font-[500]">Upload SOW</label>
              <input
                className="
                     file:mr-2 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-[500]
        hover:file:cursor-pointer file:bg-secondary/10
        file:text-secondary" id="formFileSm" type="file" />
            </div>
            <div>
              <label htmlFor="uploadshow" className="form-label inline-block mb-2  font-[500]">Upload MSA</label>
              <input
                className="
                           file:mr-2 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-[500]
        hover:file:cursor-pointer file:bg-secondary/10
        file:text-secondary"  id="uploadshow" type="file" />
            </div>
            <div>
              <label htmlFor="formFileSm" className="form-label inline-block mb-2  font-[500]">Other Docs</label>
              <input
                className="
                           file:mr-2 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-[500]
        hover:file:cursor-pointer file:bg-secondary/10
        file:text-secondary"  id="formFileSm" type="file" />
            </div> */}
          </div>
          <div className='fixed bottom-0 right-6 mt-2'>
            {!canEdit && (
              <div className='fixed bottom-0 right-6 mt-2'>
                <div className='gap-2 flex justify-end items-center'>
                  <button
                    // disabled={!canEdit}
                    onClick={() => setCanEdit(false)}
                    type=''
                    className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    disabled={canEdit}
                    className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                    onClick={saveContactInfo}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ContactInfo);