import { memo, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { AlertError } from 'src/Services';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { validationTime, yesNoWithboolean } from 'src/Services/constant/staticValues';

const templateType = [
   { name: 'Project', templatetype: "P" },
   { name: 'Generic', templatetype: 'G' },
];

const Details = (props) => {
   const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit, timetemplatemasterid } = props;
   const { companylist } = useSelector((state) => state.EmployeeDetails);
   const { templateList } = useSelector((state) => state.ConfigurationList);
   const [isDefault, setIsDefault] = useState(detailsData?.isdefault || false);
   const [filteredTemplates, setFilteredTemplates] = useState([]);
   const [checkvalidation, setCheckvalidation] = useState(false);
   const [load, setLoad] = useState(false)

   useEffect(() => {
      if (templateList?.length > 0) {
         if (detailsData.companyid && detailsData.templatetype) {
            const filteredData = templateList?.filter(template => (
               template?.companyid === detailsData.companyid &&
               template?.templatetype === detailsData.templatetype &&
               template?.isdefault === true
            ));
            setFilteredTemplates(filteredData);
            if (detailsData.isdefault && filteredData?.length > 0) {
               const updatedFilteredData = filteredData?.map(template => ({
                  ...template,
                  isdefault: false
               }));
               setFilteredTemplates(updatedFilteredData);
            }
         }
      }
   }, [detailsData, templateList, isDefault]);

   const handleSubmit = async () => {
      setLoad(true)
      setCheckvalidation(true);
      let item = { ...detailsData };
      item['minhours'] = detailsData['minhours'] || null
      item['maxhours'] = detailsData['maxhours'] || null

      let notReqField = ['notes', 'timetemplatemasterid', 'validationunitname']

      if (item?.templatetype === 'G') {
         notReqField.push('allocationrestriction')
      } if (item?.validationunit === -1) {
         notReqField.push('maxhours')
         notReqField.push('minhours')
      } if (detailsData?.allocationrestriction !== true) {
         notReqField.push('validationunit')
      }

      // console.log(item?.minhours , item?.maxhours)
      if (parseFloat(item?.minhours) > parseFloat(item?.maxhours)) {
         setLoad(false)
         AlertError('Can not add min hours more than max hours')
         return
      }

      let isValidated = await CheckObjectValidation(item, notReqField);
      if (isValidated?.isvalid) {
         let updatedFilteredTemplates = filteredTemplates.map(template => {
            if (item.isdefault) {
               return {
                  ...template,
                  isdefault: template?.timetemplatemasterid === item.timetemplatemasterid,
               };
            } else if (!template?.isdefault && template?.timetemplatemasterid === item.timetemplatemasterid) {
               return {
                  ...template,
                  isdefault: true,
               };
            } else {
               return template;
            }
         });

         // Check if the updatedFilteredTemplates contains the current item
         let currentItemIndex = updatedFilteredTemplates.findIndex(template => template?.timetemplatemasterid === item.timetemplatemasterid);
         if (currentItemIndex !== -1) {
            updatedFilteredTemplates[currentItemIndex] = item; // Replace the current item with the updated item
         } else {
            console.log("Current item not found in filtered templates:", item);
         }

         item.isdefault = isDefault;
         let payload = {
            timetemplatemasterjson: [item, ...updatedFilteredTemplates],
         };
         await detailSave(payload);
         setLoad(false)
      }
      setLoad(false)
   };

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);
      if (name === 'isdefault') {
         setIsDefault(value);
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            isdefault: value,
         }));

         if (value && filteredTemplates.length > 0) {
            setFilteredTemplates((prevFilteredTemplates) => {
               const updatedTemplates = prevFilteredTemplates.map((template) => ({
                  ...template,
                  isdefault: template?.timetemplatemasterid === timetemplatemasterid,
               }));
               return updatedTemplates;
            });
         } else if (!value && filteredTemplates.length > 0) {
            setFilteredTemplates((prevFilteredTemplates) => (
               prevFilteredTemplates.map((template) => ({
                  ...template,
                  isdefault: false,
               }))
            ));
         }
      } else if (name === 'allocationrestriction') {
         setDetailsData(prevDetailsData => ({
            ...prevDetailsData,
            [name]: value,
            validationunit: value ? 1 : -1,
            maxhours: 0,
            minhours: 0,
         }));
      } else if (name === 'validationunit') {
         setDetailsData(prevDetailsData => ({
            ...prevDetailsData,
            [name]: value,
            maxhours: 0,
            minhours: 0,
         }));
      } else {
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: value,
         }));
      }
   };

   const validationTimeOption = useMemo(() => {
      if (detailsData?.allocationrestriction) {
         return validationTime.filter(r => r?.name !== 'None')
      } return validationTime
   }, [detailsData?.allocationrestriction])

   return (
      <div className='w-full h-[65vh] text-sm mb-10'>
         <div className='grid grid-cols-4 gap-10 mt-4'>
            <div>
               <label htmlFor='companyname' className='block font-[500] mb-2'>
                  Company
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled
                  options={companylist || []}
                  value={detailsData?.companyid || ''}
                  noDataLabel='No Company'
                  name='companyid'
                  labelkey='companyname'
                  className='border rounded bg-gray-100 py-2'
                  onChange={handleChangeInput}
                  id='companyid'
               />
            </div>
            <div>
               <label htmlFor='templatename' className='block font-[500] mb-2'>
                  Time Template
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  disabled={canEdit}
                  type='text'
                  title='templatename'
                  id='timetemplatemasterid'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  placeholder=' Enter Time Template'
                  maxLength={50}
                  onChange={(e) => handleChangeInput('templatename', e.target.value)}
                  value={detailsData?.templatename || ''}
               />
            </div>
            <div>
               <label htmlFor='templatetype' className='block font-[500] mb-2'>
                  Template Type
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled
                  options={templateType || []}
                  value={detailsData?.templatetype}
                  noDataLabel='No templatetype found'
                  name='templatetype'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ' onChange={handleChangeInput}
                  id='templatetype'
               />
               {!detailsData?.templatetype && checkvalidation && (
                  <small className='text-red-400'>Template Type is required!</small>
               )}
            </div>
            <div className='flex items-center mt-2'>
               <label htmlFor='isdefault' className='py-4 block font-[500] mr-4 mt-2'>
                  Is Default
               </label>
               <input
                  type='checkbox'
                  id="isdefault"
                  name="isdefault"
                  checked={isDefault}
                  disabled={(!canEdit ? canEdit : !isDefault)} // Disabled if canEdit is true or isDefault is false
                  onChange={() => {
                     setIsDefault(true);
                     setDetailsData(prevDetailsData => ({
                        ...prevDetailsData,
                        isdefault: true
                     }));
                  }}
                  className="mr-120 mt-2"
               />
            </div>
            <div>
               <label htmlFor='allocationrestriction' className='mb-2 block font-[500]'>
                  Restrict By Allocation
                  {detailsData?.templatetype === 'P' && <span className="text-red-300"> *</span>}
               </label>
               <CustomhtmlSelect
                  disabled={canEdit || detailsData?.templatetype === 'G'}
                  options={yesNoWithboolean} value={detailsData?.allocationrestriction}
                  name='allocationrestriction'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />
               {!detailsData?.templatetype && checkvalidation && (
                  <small className='text-red-400'>Restrict By Allocation is required!</small>
               )}

            </div>
            <div>
               <label htmlFor='validationunit' className='block font-[500] mb-2'>
                  Validate Time
                  {detailsData?.allocationrestriction && <span className="text-red-300"> *</span>}
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={validationTimeOption}
                  value={detailsData?.validationunit}
                  noDataLabel='No Validation Time'
                  name='validationunit'
                  labelkey='name'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='validationunit'
               />
               {!detailsData?.validationunit && checkvalidation && (
                  <small className='text-red-400'>Time Type is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='validationunit' className='block font-[500] mb-2'>
                  Min Hours
                  {detailsData?.validationunit !== -1 && <span className="text-red-300"> *</span>}
               </label>
               <input
                  disabled={canEdit}
                  name='minhours'
                  value={detailsData?.minhours}
                  onChange={(e) => {
                     if (/^[0-9]*\.?[0-9]*$/.test(e.target?.value)) {
                        handleChangeInput('minhours', e.target.value);
                     }
                  }}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
               />
               {(detailsData?.validationunit !== -1 && !detailsData?.minhours) && checkvalidation && (
                  <small className='text-red-400'>Min hours is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='validationunit' className='block font-[500] mb-2'>
                  Max Hours
                  {detailsData?.validationunit !== -1 && <span className="text-red-300"> *</span>}
               </label>
               <input
                  disabled={canEdit}
                  name='maxhours'
                  value={detailsData?.maxhours}
                  onChange={(e) => {
                     if (/^[0-9]*\.?[0-9]*$/.test(e.target?.value)) {
                        handleChangeInput('maxhours', e.target.value)
                     }
                  }}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
               />
               {(detailsData?.validationunit !== -1 && !detailsData?.maxhours) && checkvalidation && (
                  <small className='text-red-400'>Max hours is required!</small>
               )}
            </div>
         </div>

         <div className='fixed bottom-0 right-6 mt-2'>
            {!canEdit && (
               <div className='fixed bottom-0 right-6 mt-2'>
                  <div className='gap-2 flex justify-end items-center'>
                     <button
                        onClick={() => setCanEdit(false)}
                        className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                     >
                        Cancel
                     </button>
                     <PrimaryButton
                        disabled={load || canEdit}
                        className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
                        onClick={handleSubmit}
                     >
                        {load ? 'Processing...' : 'Save'}
                     </PrimaryButton>
                  </div>
               </div>
            )}
         </div>
      </div >
   );
};

export default memo(Details);
