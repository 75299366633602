import { useDispatch } from "react-redux";
import CustomhtmlSelect from "src/Components/CustomhtmlSelect";
import FilterButtonTabObject from "src/Components/FilterButtonTabObject"
import { GET_PROJECTS_BY_USER } from "src/Redux/actions/projects";
import { getDefaultProjectPayload } from "src/Services/constant/defaultPayload";
import SearchCustomer from "./searchCustomer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const categorylist = [
    { category: 'All', id: '' },
    { category: 'Internal', id: 'I' },
    { category: 'Customer', id: 'C' },
]

const ViewlistFilter = ({ projectsReducer, filters, setFilters, setProjects, limit }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search);
    // Get a specific parameter value
    const datmenuid = params.get('datmenuid');
    const [customer, setCustomer] = useState({})
    const { customerList, typesListProject, listOfCompany, servicelineList, businessUnitList,
        listOfBillingProjects, statusListByType } = projectsReducer

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    };

    const handleSelectTab = (name, value) => {
        setFilters({ ...filters, [name]: value })
    };
    const applyFilters = async () => {
        let search = `${location?.pathname}?datmenuid=${datmenuid}&AppliedFilters=${JSON.stringify(filters)}`
        navigate(search)
        let res = await dispatch(GET_PROJECTS_BY_USER({ ...filters, limit }));
        setProjects(res)
    }

    const clearFilter = () => {
        let search = `${location?.pathname}?datmenuid=${datmenuid}`
        navigate(search)
        setCustomer({})
        dispatch(GET_PROJECTS_BY_USER({ ...getDefaultProjectPayload(), limit }))
        setFilters({ ...getDefaultProjectPayload(), limit })
    }

    useEffect(() => {
        setFilters({ ...filters, customerid: customer?.customerid || 0, limit})
        // eslint-disable-next-line
    }, [customer, setFilters])

    return (
        <div className="w-full text-sm">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-1">
                <div>
                    <h1 className="mb-2 font-[500]">Select Status</h1>
                    <CustomhtmlSelect
                        options={statusListByType || []}
                        value={filters?.projectstaus}
                        noDataLabel='No Projects'
                        name='projectstaus'
                        labelkey='statusname'
                        className='border rounded bg-gray-50 py-2'
                        onChange={handleSelectChange}
                        id='statusid'
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Customer</h1>
                    <SearchCustomer
                        selected={customer}
                        setSelected={setCustomer}
                        Options={customerList || []} />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Company</h1>
                    <CustomhtmlSelect
                        options={listOfCompany || []}
                        value={filters?.companyid}
                        noDataLabel='No Company'
                        name='companyid'
                        labelkey='companyname'
                        className='border rounded bg-gray-50 py-2'
                        onChange={handleSelectChange}
                        id='companyid'
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Type</h1>
                    <CustomhtmlSelect
                        options={typesListProject || []}
                        noDataLabel='No Type'
                        value={filters?.projecttypeid}
                        name='projecttypeid'
                        labelkey='projecttype'
                        className='border rounded bg-gray-50 py-2'
                        onChange={handleSelectChange}
                        id='projecttypeid'
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Category</h1>
                    <CustomhtmlSelect
                        options={categorylist || []}
                        noDataLabel='No Category'
                        value={filters?.category}
                        name='category'
                        labelkey='category'
                        className='border rounded bg-gray-50 py-2'
                        onChange={handleSelectChange}
                        id='id'
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Billing Type</h1>
                    <FilterButtonTabObject
                        className='border py-1.5'
                        tabs={listOfBillingProjects || []}
                        selected={filters?.billingtypeid}
                        labelkey='billingtype'
                        name='billingtypeid'
                        valuekey='datprojectbillingtypeid'
                        onClick={handleSelectTab}
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Business Unit </h1>
                    <CustomhtmlSelect
                        options={businessUnitList || []}
                        value={filters?.businessunitid}
                        noDataLabel='No Busniess Unit'
                        name='businessunitid'
                        labelkey='businessunitname'
                        className='border rounded bg-gray-50 py-2'
                        onChange={handleSelectChange}
                        id='businessunitid'
                    />
                </div>

                <div>
                    <h1 className="mb-2 font-[500]">Service Line</h1>
                    <CustomhtmlSelect
                        options={servicelineList || []}
                        value={filters?.servicelineid}
                        noDataLabel='No Service Line'
                        name='servicelineid'
                        labelkey='servicelinename'
                        className='border rounded bg-gray-50 py-2'
                        onChange={handleSelectChange}
                        id='servicelineid'
                    />
                </div>
                <div />
                <div />
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button onClick={clearFilter} className='px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5'>
                            Clear
                        </button>
                        <button onClick={applyFilters} className='px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5'>
                            Apply
                        </button>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ViewlistFilter;