import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { detailItem } from './createUser/defaultRowAndData';
import CreateUser from './createUser/createUser';
import EditUser from './editUser/editUser';
import { GET_USER_ACCESS_LIST } from 'src/Redux/actions/masterdata/user';
import { getUserAccessPayload } from 'src/Services/constant/defaultPayload';
import { basicShortList } from 'src/Services/constant/staticValues';


const cols = ['Company', 'User Type', 'User Name', 'Email', 'Role', 'Employee Status', 'Exit Date', 'User Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const UserManagmentList = ({ filters, setUsers, users }) => {
  const [user, setUser] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [UserId, setUserId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [empstatus, setEmpStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [empstatusName, setEmpStatusName] = useState('');
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(detailItem());
  const [shortType, setShortType] = useState({ label: 'Latest First', id: 3 });
  const dispatch = useDispatch();
  const { userlistforadmin, loading: userLoading ,PermissionsOfUserAccess} = useSelector((state) => state.UserManagementList);
  
  useEffect(() => {
    setLoading(userLoading);
  }, [userLoading]);

  useEffect(() => {
    if (users?.length <= 1) {
      setUsers(userlistforadmin);
    }
  }, [setUsers, userlistforadmin, users?.length,]);

  useEffect(() => {
    setLimit(10)
  }, []);


  const handleSorting = async (item) => {
    setShortType(item);
    let res = await dispatch(GET_USER_ACCESS_LIST({ ...filters, limit: limit,page:page, sorttype: item?.id, userstatus: status?.label, }));
    if (res !== null) {
      setUsers(res);
    }
  };

  useEffect(() => {
    if (user?.firstname) {
      setSearchTerm(`${user?.firstname} ${user?.lastname}`);
    }
  }, [user]);

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_USER_ACCESS_LIST({ ...filters, userstatus: item.label }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(GET_USER_ACCESS_LIST({...filters, limit: limit, page: page?.selected + 1, sorttype: shortType?.id, userstatus: status?.label }));
    if (res?.success) {
      setUsers(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    setPage(1); // Reset the page to the first page when the page limit is changed
    let res = await dispatch(GET_USER_ACCESS_LIST({ ...filters, limit: row, page: 1, sorttype: shortType?.id, userstatus: status?.label, }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  const handleCustomerClick = (user) => {
    let modifyData = user
    if (user?.rolenames?.length > 0) {
      modifyData['Roleid'] = user?.rolenames[0]?.roleid
    }
    setDetailsData(modifyData);
    setUserId(user?.comuserid);
    setIsOpen(true);
    setEmpStatus(user?.empstatusid);
    setEmpStatusName(user?.emp_status);

  };

  return (
    <div className='w-full h-screen py-3 mt-4'>
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center justify-start space-x-5'>
          <h1 className='pb-2 font-[500]'>
            User Details ( {userlistforadmin?.length > 0 ? userlistforadmin[0]?.totalcount : userlistforadmin?.length} )
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_USER_ACCESS_LIST}
              searchPayload={{ ...getUserAccessPayload(), username: searchTerm, userstatus: status?.label }}
              setSearchTerm={setSearchTerm}
              options={userlistforadmin}
              idkey='comuserid'
              optionlabelKey='firstname'
              optionLabelkey2='lastname'
              bracketLabel='empid'
              getSelectedOption={setUser}
              placeholder='Enter User Name'
              className='p-2'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={userlistforadmin?.length > 0 ? userlistforadmin[0]?.totalcount : userlistforadmin?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={shortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {(PermissionsOfUserAccess.includes('Add/Edit Users')) ? <CreateUser /> : ""}
      </div>
      <div className="h-[56vh] overflow-scroll">
        {loading ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          userlistforadmin && (
            <table className='relative w-full text-sm'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] px-2 py-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {userlistforadmin?.map((item, i) => {
                  let {
                    firstname,
                    lastname,
                    username,
                    empid,
                    rolenames,
                    emp_statusname,
                    exitdate,
                    usertype,
                    companyname,
                    createdby,
                    createdtimestamp,
                    activeflag,
                    uId,
                  } = item;
                  // {console.log(item)}
                  return (
                    <tr
                      onClick={() => handleCustomerClick(item)}
                      key={`${uId}-${i}`}
                      className='text-left cursor-pointer'>
                      <td className='border px-2 py-1.5'><div>{companyname}</div></td>
                      <td className='border px-2 py-1.5'><div>{usertype}</div></td>
                      <td className='border px-2 py-1.5'><div>{firstname} {lastname} ({empid})</div></td>
                      <td className='border px-2 py-1.5'><div>{username}</div></td>
                      {/* <td className='border px-2 py-1.5'><div>{empid}</div></td> */}
                      <td className='border px-2 py-1.5'><div><div>{rolenames.length > 1 ? `${rolenames[0].rolename} / ${rolenames[1].rolename} + ${rolenames.length - 2} more` : rolenames[0].rolename} </div></div></td>
                      <td className='border px-2 py-1.5'><div>{emp_statusname}</div></td>
                      <td className='border px-2 py-1.5'><div>{(emp_statusname === "Notice Period" || emp_statusname === "Relieved") ? (moment(exitdate).format('DD-MM-YYYY')) : '-'}</div></td>
                      <td className='px-2 border'><div>{activeflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='px-2 border'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                      <td className='px-2 border'><div>{createdby}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditUser
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        UserId={UserId}
        // page={page}
        // limit={limit}
        // sortType={shortType}
        filters={filters}
        empstatus={empstatus} // Pass the empstatus as a prop
        setEmpStatus={setEmpStatus}
        empstatusName={empstatusName}
        permissions={PermissionsOfUserAccess}

      />
    </div>
  );
};

export default UserManagmentList;