import { useSelector } from "react-redux"
import { memo, useEffect, useMemo, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { getUserAccessPayload } from "src/Services/constant/defaultPayload";
import { GET_DM_LIST } from "src/Redux/actions/masterdata/user";

const SelectDm = ({ setDm, dm }) => {
    const UserManagementList = useSelector(state => state.UserManagementList)
    const { rolelist, dmList, getStatusByTypeE } = UserManagementList
    const [searchTerm, setSearchTerm] = useState('');
    const Roleid = rolelist?.find(it => it?.code === 'DM')?.roleid
    const [empstatus, setEmpStatus] = useState(0)

    useMemo(() => {
        if(getStatusByTypeE?.length > 0){
            let id = getStatusByTypeE?.find(it=> it?.statusname === 'Active')?.statusid
            setEmpStatus(id)
        }
    }, [getStatusByTypeE])

    useEffect(() => {
        if (dm?.firstname) {
            setSearchTerm(`${dm?.firstname} ${dm?.lastname || ''} ${dm?.employeecode ? `(${dm?.employeecode || 0})` : ''}`)
        }
    }, [dm])

    return (
        <>
            <label htmlFor="ProjectManager" className="block mb-2 text-sm font-[500]">Delivery Manager <span className="text-red-300">*</span></label>
            {empstatus && <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_DM_LIST}
                searchPayload={{
                    ...getUserAccessPayload(),
                    username: searchTerm,
                      Roleid:`{${Roleid}}`,
                    empstatus
                }}
                setSearchTerm={setSearchTerm}
                options={dmList}
                // mapping key  (idkey)
                idkey='uId'
                defaultApiCall={searchTerm === '' ? false : true}
                optionLabelkey2='lastname'
                optionlabelKey='firstname'
                bracketLabel='username'
                getSelectedOption={setDm}
                className='p-2'
                placeholder='Select Delivery Manager' />}
        </>
    )
}

export default memo(SelectDm)