
import { memo } from "react";
import SingleSelectField from "src/Components/SingleSelectForTable";

const SelectCurrency = ({ canEdit, value, rowId, handleChange, options }) => {

    const getSelected = (v) => {
        let e = { target: { name: 'datcomcurrencyid', value: v['datcomcurrencyid'], id:rowId } }
        handleChange(e)
    }
    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='datcomcurrencyid'
                labelKey='currencycode'
                options={options}
                value={value}
                handleSelected={getSelected}
                disabled={canEdit}
            />
        </div>
    )
}

export default memo(SelectCurrency);
