
function getUserId() { return JSON.parse(localStorage.getItem('comuserid')) }
function gettenantId() { return JSON.parse(localStorage.getItem('Tenantid')) }

export const defaultItem = (StaffingStatusId = 29) => {
    return (
        {
            plannedenddate: null,
            plannedstartdate: null,
            plannedallocation: null,
            releasedate: null,
            StaffingStatusId,
            projectstaffid: null,
            demandid: null,
            projroleid: 0,
            userid: null,
            allocation: 0,
            startdate: "",
            enddate: "",
            projectid: 0,
            comtenantid: gettenantId(),
            isactiveflag: true,
            createdcomuserid: getUserId(),
            notes: "",
            localItem: true,
            uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
        }
    )
}
