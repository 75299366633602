import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { detailNotRequired } from '../defaulTech';

const Details = (props) => {
  const { technologylist } = useSelector((state) => state.EmployeeDetails);
  const { detailSave, detailsData, setDetailsData } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let item = {
      ...detailsData,
      // productmasterid:detailsData?.productmasterid
    };

    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        productjson: [detailsData],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='productname' className='block font-[500] mb-2'>
            Product Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='productname'
            id='productname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Product Name'
            required=''
            onChange={(e) => handleChangeInput('productname', e.target.value)}
            maxLength={50}
          />
          {!detailsData?.productname && checkvalidation && (
            <small className='text-red-400'>Product Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='technology' className='block font-[500] mb-2'>
            Technology
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={technologylist}
            value={detailsData?.technology}
            noDataLabel='No tech'
            name='technology'
            labelkey='technology'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            onChange={handleChangeInput}
            id='technologymasterid'
          />
          {!detailsData?.technology && checkvalidation && (
            <small className='text-red-400'>Technology is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            type=''
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
