import { memo, useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import CreateNotification from '../Notifications/createNotification';
import EditNotification from '../Notifications/editNotification';
import { GET_NOTIFICATION_MASTER } from 'src/Redux/actions/configuration';
import { getNotificationPayload } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = ['Module', 'Event', 'Mode', 'Frequency', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];
const mockData = [
  {
    notificationid: 1,
    module: 'Project',
    event: 'Create Project',
    mode: 'Email',
    frequency: '1',
    isactiveflag: true,
    createduser: 'Nisha Rajesh',
    createdtimestamp: '2024-01-01T12:00:00',
  },
  {
    notificationid: 2,
    module: 'Staffing',
    event: 'User Staffed',
    mode: 'SMS',
    frequency: '2',
    isactiveflag: false,
    createduser: 'Nisha Rajesh',
    createdtimestamp: '2024-01-02T12:00:00',
  },
  {
    notificationid: 3,
    module: 'Timesheet',
    event: 'Timesheet submitted',
    mode: 'Whatsapp',
    frequency: '3',
    isactiveflag: true,
    createduser: 'Nisha Rajesh',
    createdtimestamp: '2022-01-03T12:00:00',
  },
];
const NotificationMasterList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [notificationId, setNotificationId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(mockData);
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchData = async () => {
      // Simulate API call or any asynchronous data fetching operation
      // Replace this setTimeout with your actual data fetching logic
      setTimeout(() => {
        setDetailsData(mockData);
      }, 1000);
    };

    fetchData();
  }, []);


  const handleTemplateClick = (item) => {
    setNotificationId(item?.notificationid);
    setIsOpen(true);
  };

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_NOTIFICATION_MASTER({ ...getNotificationPayload(), sorttype: item.id, status: status?.label, limit: limit }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_NOTIFICATION_MASTER({ ...getNotificationPayload(), status: item.label }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(
      GET_NOTIFICATION_MASTER({ ...getNotificationPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res !== null) {
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(GET_NOTIFICATION_MASTER({ ...getNotificationPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  return (
    <div className='mt-4 p-1 py-1 w-full h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Notifications ({mockData.length}){' '}
          </h1>
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        <CreateNotification />
      </div>
      <div className='h-[46vh] overflow-scroll'>
        <table className='text-sm w-full relative'>
          <thead className='sticky top-0'>
            <tr className='text-left capitalize bg-gray-50'>
              {cols?.map((col) => (
                <th
                  key={col}
                  title={col}
                  scope='col'
                  className='font-[500] p-2 border text-start'
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {detailsData?.map((item, i) => (
              <tr
                onClick={() => handleTemplateClick(item)}
                key={item.notificationid}
                className='cursor-pointer text-left'
              >
                <td className='border px-2 py-1.5'><div>{item.module}</div></td>
                <td className='border px-2 py-1.5'><div>{item.event}</div></td>
                <td className='border px-2'><div>{item.mode}</div></td>
                <td className='border px-2'><div>{item.frequency}</div></td>
                <td className='border px-2'><div>{item.isactiveflag ? 'Active' : 'Inactive'}</div></td>
                <td className='border px-2'><div>{moment(item.createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                <td className='border px-2'><div>{item.createduser}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <EditNotification
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        notificationid={notificationId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
      />
    </div>
  );

};

export default memo(NotificationMasterList);
