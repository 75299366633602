import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useDispatch } from 'react-redux'
import { GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD } from 'src/Redux/actions/analytics'

const DynamicAllocationSplineChart = ({ reportLevel, selectedIds, filters }) => {
    const dispatch = useDispatch()
    const [chartOptions, setChartOptions] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(
        () => {
            const fetchBandWiseData = async () => {
                setLoading(true) // Start loading
                const payload = buildPayload(reportLevel, selectedIds)
                const res = await dispatch(GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD(payload))
                const responseData = res || []

                if (responseData.length > 0) {
                    const formattedData = responseData.map((item) => ({
                        name: item.Month,
                        y: item.AllocationPerc,
                    }))
                    setChartOptions(generateChartOptions(formattedData))
                } else {
                    setChartOptions(generateChartOptions([]))
                }
                setLoading(false) // Stop loading after data is fetched
            }
            fetchBandWiseData()
        },
        // eslint-disable-next-line
        [dispatch, selectedIds, filters],
    )

    const buildPayload = (level, ids) => ({
        ...filters,
        reportlevel: level,
        servicelinegroupid: ids.servicelinegroupid || 0,
        servicelineid: ids.servicelineid || 0,
        businessunitid: ids.businessunitid || 0,
        customergroupid: ids.customergroupid || 0,
        customerid: ids.customerid || 0,
    })

    const generateChartOptions = (data) => {
        const dateRange = data.map((item) => item.name) // Month names for xAxis

        return {
            chart: {
                type: 'spline',
                backgroundColor: 'transparent',
                style: {
                    fontSize: '14px',
                },
            },
            title: {
                text: '',
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                },
            },
            xAxis: {
                categories: dateRange,
                crosshair: true,
                accessibility: {
                    description: 'Date Range',
                },
                labels: {
                    style: {
                        color: '#6b7280',
                        fontSize: '14px',
                    },
                },
            },
            yAxis: {
                title: {
                    text: 'Allocation(%)',
                    style: {
                        color: '#6b7280',
                        fontSize: '14px',
                        fontWeight: '500',
                    },
                },
                labels: {
                    format: '{value}%',
                    style: {
                        color: '#6b7280',
                        fontSize: '14px',
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
                useHTML: true,
                formatter: function () {
                    return `${this.x}<br>${this.series.name}: ${this.y}%`
                },
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        lineColor: '#666666',
                        lineWidth: 1,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    events: {
                        mouseOver: function () {
                            this.update({
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.y}%',
                                    style: {
                                        color: '#6b7280',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    },
                                },
                            })
                        },
                        mouseOut: function () {
                            this.update({
                                dataLabels: {
                                    enabled: false,
                                },
                            })
                        },
                    },
                },
            },
            series: [
                {
                    name: 'Allocation',
                    data: data,
                },
            ],
            legend: {
                enabled: false,
            },
        }
    }

    return (
        <div className="relative w-full h-full">
            {loading ? (
                <div className="flex items-center justify-center w-full h-full">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>

            ) : (
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
        </div>
    )
}

export default DynamicAllocationSplineChart
