import WeekSelect from "./weekSelect"
// import { useState } from "react"
import Indecator from "./indecator"
import FilterButtonTab from "src/Components/FilterButtonTab"
import IconButton from "src/Components/Buttons/IconButton"

const Toolbar = ({ weeks, setWeeks, timesheetView, setTimesheetView, canDispalyReopen, handleReopen }) => {

    return (
        <div className="flex justify-between items-center text-sm mb-2">
            <div className="flex justify-start items-center">
                <h1 className="font-[500] inline-flex text-black">Effort Details</h1>
                <WeekSelect weeks={weeks} setWeeks={setWeeks} />
            </div>
            <Indecator />
            <div className="flex justify-end items-center  space-x-3">
                {canDispalyReopen(weeks, weeks?.monday) ?
                    <IconButton title="Reopen" onClick={handleReopen} className='bg-primary w-8 h-8 text-white disabled:bg-primary/20 rounded-md my-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="currentColor"><path d="M240-160h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Zm0 80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h280v-80q0-83 58.5-141.5T720-920q83 0 141.5 58.5T920-720h-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80h120q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Z" /></svg>
                    </IconButton> : ''}
                <FilterButtonTab
                    // disabled={true}
                    className="py-1.5 px-2 border"
                    tabs={['Employee', 'Manager']}
                    setSelectedtab={setTimesheetView}
                    selectedTab={timesheetView}
                />
            </div>
        </div>
    )
}

export default Toolbar;