import { Dialog, Tab, Transition } from '@headlessui/react'
import { Fragment, memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ScrollToBottom from 'react-scroll-to-bottom'
import IconButton from 'src/Components/Buttons/IconButton'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { INSERT_PROJECT_STAFFING } from 'src/Redux/actions/projects/staffing'
import {
    GET_DEMAND_LIST_FOR_TALENT,
    GET_PROJECT_STAFFING_LIST,
    SAVE_DEMAND_MATCH,
} from 'src/Redux/actions/workforce/demands'
import { GET_EMPLOYEE_SUMMARY } from 'src/Redux/actions/workforce/eprofile'
import {
    insertDemandMatchItem,
    staffItemSkeleton,
} from '../../DemamdDetails/editDemandDetails/demanddetails/demandDetailsItem'
import BaseInfo from './baseDemandInfo'
import RoleDetails from './roleDetails'
import SkillExpertise from './skillExpertise'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const tabs = ['Summary', 'Role Details', 'Skills & Expertise']
const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem('comuserid')),
    }
}

const DemandsPopUpLayout = ({
    isOpen = true,
    setIsOpen,
    demandId,
    thisPagePermissions,
    selectedDemand,
    Employees,
    getStaffStatusTypeM,
    getStaffStatusTypeS,
    filters,
    limit,
    page,
}) => {
    const [demand, setDemands] = useState(selectedDemand)
    const dispatch = useDispatch()

    useEffect(() => {
        setDemands(selectedDemand)
    }, [selectedDemand])

    const closeModal = async () => {
        setIsOpen(false)
        await dispatch(GET_DEMAND_LIST_FOR_TALENT({ ...filters, Limit: limit, Page: page }))
        await dispatch(
            GET_EMPLOYEE_SUMMARY({
                COMUserId: +Employees?.[0]?.comuserid,
                Tenantid: +getTanAndComId()?.Tenantid,
            }),
        )
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const matchStatusCode = demand?.matchdetails?.[0]?.matchstatuscode || ''

    const matchedStaffinf = getStaffStatusTypeS?.find((status) => status?.statuscode === 'SSTAFED')

    const staffingId = matchedStaffinf ? matchedStaffinf.statusid : ''

    const handleStatusChange = async (statusCode) => {
        const statusId = getStaffStatusTypeM?.find((status) => status.statuscode === statusCode)?.statusid
        if (statusId && Employees.length > 0) {
            const payload = insertDemandMatchItem(
                demand?.demandid,
                Employees?.[0]?.comuserid,
                statusId,
                demand?.matchdetails?.[0]?.demandmatchid,
            )
            try {
                await dispatch(SAVE_DEMAND_MATCH(payload))
            } catch (error) {
                console.error('Error updating demand match status:', error)
            } finally {
                closeModal()
            }
        }
    }

    const handleSelectProfile = async () => {
        const statusCode = 'MSELECTD'
        const statusId = getStaffStatusTypeM?.find((status) => status.statuscode === statusCode)?.statusid

        if (statusId && Employees.length > 0) {
            const payload = insertDemandMatchItem(
                demand?.demandid,
                demand?.matchdetails?.[0]?.userid,
                statusId,
                demand?.matchdetails?.[0]?.demandmatchid,
            )
            try {
                const saveDemandMatchResult = await dispatch(SAVE_DEMAND_MATCH(payload))
                if (saveDemandMatchResult && demand?.requesttype === 'P') {
                    const projectRes = await dispatch(
                        GET_PROJECT_STAFFING_LIST(demand?.projectid, demand?.projectstaffid, null, 1, 10),
                    )
                    const staffPayload = staffItemSkeleton(
                        projectRes[0]?.demandid,
                        projectRes[0]?.projectstaffid,
                        projectRes[0]?.userid,
                        projectRes[0]?.projectid,
                        projectRes[0]?.startdate,
                        staffingId,
                        projectRes[0]?.projroleid,
                        (projectRes[0]?.allocation / 100).toFixed(2),
                        projectRes[0]?.enddate,
                        projectRes[0]?.plannedenddate,
                        projectRes[0]?.plannedstartdate,
                        (projectRes[0]?.plannedallocation / 100).toFixed(2),
                        projectRes[0]?.releasedate,
                    )
                    await dispatch(INSERT_PROJECT_STAFFING(staffPayload))
                }
            } catch (error) {
                console.error('Error updating demand match status:', error)
            } finally {
                closeModal()
            }
        }
    }

    return (
        <div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-2xl bg-white px-6 text-left align-middle shadow-xl transition-all">
                                    <Tab.Group>
                                        <div className="flex items-center justify-between py-2 border-b border-secondary">
                                            <h3 className="text-lg font-[500] leading-6 text-primary">
                                                Demand Details
                                            </h3>
                                            <Tab.List className="flex justify-start p-1 space-x-2 rounded-lg">
                                                {tabs.map((item, index) => (
                                                    <Tab
                                                        key={index}
                                                        className={({ selected }) =>
                                                            classNames(
                                                                'rounded-md p-1 px-2 outline-white',
                                                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                                                selected
                                                                    ? 'text-secondary bg-secondary/10 font-[500]'
                                                                    : 'text-gray-500',
                                                            )
                                                        }
                                                    >
                                                        {item}
                                                    </Tab>
                                                ))}
                                            </Tab.List>
                                            <IconButton
                                                title="Close"
                                                onClick={closeModal}
                                                type="button"
                                                className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                                <span className="sr-only">Close icon</span>
                                            </IconButton>
                                        </div>

                                        <Tab.Panels className="mt-2">
                                            <ScrollToBottom className="h-[calc(100vh-150px)] overflow-auto p-2">
                                                <Tab.Panel>
                                                    <BaseInfo demandId={demandId} />
                                                </Tab.Panel>
                                                <Tab.Panel>
                                                    <RoleDetails demandId={demandId} />
                                                </Tab.Panel>
                                                <Tab.Panel>
                                                    <SkillExpertise demandId={demandId} />
                                                </Tab.Panel>
                                            </ScrollToBottom>
                                        </Tab.Panels>
                                    </Tab.Group>

                                    {matchStatusCode !== 'MREJECTD' && (
                                        <div className="fixed bottom-0 mt-2 right-6">
                                            <div className="gap-1.5 flex justify-end items-center">
                                                {matchStatusCode === 'MPROPOSD' && (
                                                    <>
                                                        {thisPagePermissions?.includes('Shortlist Profile') && (
                                                            <>
                                                                <PrimaryButton
                                                                    onClick={() => handleStatusChange('MREJECTD')}
                                                                    className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                                                >
                                                                    Reject
                                                                </PrimaryButton>
                                                                <PrimaryButton
                                                                    onClick={() => handleStatusChange('MBLKED')}
                                                                    className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                                                >
                                                                    Block
                                                                </PrimaryButton>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {matchStatusCode === 'MBLKED' && (
                                                    <>
                                                        {thisPagePermissions?.includes('Shortlist Profile') && (
                                                            <>
                                                                <PrimaryButton
                                                                    onClick={() => handleStatusChange('MREJECTD')}
                                                                    className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                                                >
                                                                    Reject
                                                                </PrimaryButton>
                                                                <PrimaryButton
                                                                    onClick={handleSelectProfile}
                                                                    className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                                                >
                                                                    Select
                                                                </PrimaryButton>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {!['MPROPOSD', 'MBLKED', 'MREJECTD', 'MSELECTD'].includes(
                                                    matchStatusCode,
                                                ) && (
                                                    <>
                                                        {thisPagePermissions?.includes('Shortlist Profile') && (
                                                            <>
                                                                <PrimaryButton
                                                                    onClick={() => handleStatusChange('MPROPOSD')}
                                                                    className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                                                >
                                                                    Propose
                                                                </PrimaryButton>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default memo(DemandsPopUpLayout)
