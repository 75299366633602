import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_TIMESHEET_REPORT_FOR_EMPLOYEE,
} from 'src/Redux/actions/reports'

const cols1 = [
    'Employee Name',
    'Project Name',
    'Phase Name',
    'Role Name',
    'Task',
    'Entry Type',
    'Reference No.',
    'Effort',
    'Date',
]
const cols2 = [
    'Employee Name',
    'Status',
    'Approver',
    'Approver Remarks',
    'Week Start',
    'Week End',
    'Billable',
    'Client approval',
]

const cols3 = [
    'Employee Name',
    'Allocation',
    'Time Template',
    'Time Type',
    'Employee Code',
    'Project Code',
    'Customer Name',
]

const rows = [10, 25, 50]

const StaffTimesheetReport = ({
    filters,
    reportMasterList,
    reportId,
    projectIds,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const { timesheetreportForEmployee } = useSelector((state) => state?.WorkforceReport)
    const [showMore, setShowMore] = useState(0)  // Changed to handle 3 states

    const handlePagination = (page) => {
        dispatch(
            GET_TIMESHEET_REPORT_FOR_EMPLOYEE({
                ...filters,
                limit,
                pageno: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        setLimit(row)
        let data = filters
        if (filters['projectid'] === '{}') {
            data['projectid'] = `{${projectIds?.join(',')}}`
        }
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_TIMESHEET_REPORT_FOR_EMPLOYEE({
                ...data,
                limit: row,
                pageno: row > 10 ? 1 : page,
            }),
        )
    }

    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    const totalRecords = useMemo(() => {
        return timesheetreportForEmployee?.length > 0
            ? timesheetreportForEmployee[0]?.totalcount
            : timesheetreportForEmployee?.length
    }, [timesheetreportForEmployee])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={showMore === 0}
                        onClick={() => setShowMore((prev) => (prev - 1 + 3) % 3)}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={showMore === 2}
                        onClick={() => setShowMore((prev) => (prev + 1) % 3)}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>
                <div className="flex items-center justify-between w-full space-x-5">
                    <p className="text-primary font-[500]">
                        Reports Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        limit={totalRecords}
                        endpoint="Download_timesheetreportforemployeewise"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        fileName={fileName}
                        filters={filters}
                    />
                </div>
            </div>
            {timesheetreportForEmployee === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(88%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {showMore === 0 &&
                                    cols1.map((col) => (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="min-w-[100px] p-2 font-[500] border text-start"
                                        >
                                            {col}
                                        </th>
                                    ))}
                                {showMore === 1 &&
                                    cols2.map((col) => (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="min-w-[100px] p-2 font-[500] border text-start"
                                        >
                                            {col}
                                        </th>
                                    ))}
                                {showMore === 2 &&
                                    cols3.map((col) => (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="min-w-[100px] p-2 font-[500] border text-start"
                                        >
                                            {col}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {timesheetreportForEmployee?.map((item, i) => {
                                let {
                                    employeename,
                                    employeeid,
                                    projectname,
                                    projectcode,
                                    projectphase,
                                    rolename,
                                    taskname,
                                    tasktype,
                                    referencenumber,
                                    effort,
                                    effortdate,
                                    approvalstatus,
                                    approvername,
                                    approveremployeeid,
                                    approverremarks,
                                    startdate,
                                    enddate,
                                    billable,
                                    clientapproval,
                                    uId,
                                    customername,
                                    timecategory,
                                } = item
                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {showMore === 0 && (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}{' '}
                                                    {employeeid ? `(${employeeid})` : ''}
                                                </td>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {projectname}{' '}
                                                    {projectcode
                                                        ? `(${projectcode})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {projectphase}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {rolename.split('|')[0]} {/* Role */}
                                                </td>

                                                <td className="px-1 py-1 border ">
                                                    {taskname}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {tasktype}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {referencenumber}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {effort} Hrs
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {effortdate}
                                                </td>
                                            </>
                                        )}
                                        {showMore === 1 && (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}{' '}
                                                    {employeeid ? `(${employeeid})` : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    <span>{approvalstatus}</span>
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {approvername}{' '}
                                                    {approveremployeeid
                                                        ? `(${approveremployeeid})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {approverremarks}
                                                </td>

                                                <td className="px-1 py-1 border ">
                                                    {startdate}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {enddate}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {billable ? 'Yes' : 'No'}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {clientapproval ? 'Yes' : 'No'}
                                                </td>
                                            </>
                                        )}
                                        {showMore === 2 && (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}
                                                </td>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {rolename} {/* Role */}
                                                </td>

                                                <td className="px-1 py-1 border ">
                                                    {tasktype} {/* Time Template (assuming tasktype fits) */}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {timecategory} {/* Time Type */}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {employeeid} {/* Employee Code */}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {projectcode} {/* Project Code */}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {customername} {/* Customer Name */}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default StaffTimesheetReport
