import React, { useState } from 'react'
import IconButton from 'src/Components/Buttons/IconButton'
import { GET_USER_PAYROLL_TEMPLATE } from 'src/Redux/actions/projects/financials'
import { useDispatch } from 'react-redux'

const DownloadAsExcel = ({ fileName }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    async function downloadExcelFile() {
        setLoading(true)
        try {
            let res = await dispatch(GET_USER_PAYROLL_TEMPLATE())
            if (res?.success) {
                // Convert binary data to Blob
                const blob = new Blob([res?.res?.data], { type: fileType })
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `${fileName}${fileExtension}`
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                URL.revokeObjectURL(url)
            }
        } catch (error) {
            console.error('Error downloading Excel file:', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <IconButton
            title="Download Template"
            onClick={downloadExcelFile}
            className=" text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none"
        >
            {loading ? (
                <svg
                    aria-hidden="true"
                    className="inline w-5 h-5 text-white animate-spin dark:text-white fill-primary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
            ) : (
                <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="#fff"
                        d="M15.5336935,1.36078656 L14.3091934,0 L4.66164861,0 C3.96587259,0 3.69745022,0.516240645 3.69745022,0.918940882 L3.69745022,4.54990495 L5.05022147,4.54990495 L5.05022147,1.65231191 C5.05022147,1.49764309 5.17972642,1.36831506 5.33012577,1.36831506 L12.2326992,1.36831506 C12.3848666,1.36831506 12.4607275,1.39536077 12.4607275,1.51951183 L12.4607275,6.33974935 L17.374336,6.33974935 C17.5674293,6.33974935 17.64219,6.43910559 17.64219,6.58649791 L17.64219,18.3551379 C17.64219,18.6018372 17.5427649,18.6391348 17.3923656,18.6391348 L5.33012577,18.6391348 C5.17841421,18.6391348 5.05022147,18.5071054 5.05022147,18.3551379 L5.05022147,17.2797529 L3.70585195,17.2797529 L3.70585195,18.9746512 C3.68830357,19.5740762 4.00829472,20 4.66164861,20 L18.0607964,20 C18.7607484,20 19,19.492895 19,19.031053 L19,6.44396558 L19,5.18667048 L18.6504957,4.80720067 L15.5336935,1.36078656 Z M13.8361266,1.51951183 L14.2226173,1.95352728 L16.8187437,4.80720067 L16.9617674,4.98003873 L14.3091934,4.98003873 C14.1088763,4.98003873 13.9821388,4.94696877 13.9289809,4.88082886 C13.8758231,4.81468894 13.8448716,4.71017086 13.8361266,4.56727461 L13.8361266,1.51951183 Z M12.745155,10.6673887 L17.3228723,10.6673887 L17.3228723,12.0008027 L12.745155,12.0008027 L12.745155,10.6673887 Z M12.745155,8.00053511 L17.3228723,8.00053511 L17.3228723,9.33394906 L12.745155,9.33394906 L12.745155,8.00053511 Z M12.745155,13.3342423 L17.3228723,13.3342423 L17.3228723,14.6676563 L12.745155,14.6676563 L12.745155,13.3342423 Z M1,5.6257308 L1,16.2931195 L11.4647417,16.2931195 L11.4647417,5.6257308 L1,5.6257308 Z M6.23301435,11.8301319 L5.59210113,12.8075142 L6.23301435,12.8075142 L6.23301435,13.9996373 L3.01552801,13.9996373 L5.35148739,10.4913668 L3.28236069,7.33382814 L5.01013637,7.33382814 L6.2343518,9.16996719 L7.45725501,7.33382814 L9.18369325,7.33382814 L7.11194213,10.4900547 L9.44921372,13.9996373 L7.6560547,13.9996373 L6.23301435,11.8301319 Z"
                    />
                </svg>
            )}
        </IconButton>
    )
}

export default DownloadAsExcel
