
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const BarchartDashboard = ({ effortdetails, fontSize=14 }) => {
  return (
    <div className=' sm:h-[36dvh] md:h-[36dvh]'>
      <ResponsiveContainer debounce={300} width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={effortdetails || []}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis fontSize={fontSize} dataKey="MonthName" />
          <YAxis fontSize={fontSize} />
          <Tooltip />
          <Legend fontSize={fontSize} />
          <Bar dataKey="Project" stackId="a" fill="#8884d8" />
          <Bar dataKey="Organization" stackId="a" fill="#82ca9d" />
          <Bar dataKey="Miscellaneous" stackId="a" fill="#57779b" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarchartDashboard;