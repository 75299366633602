


const FilterButtonTab = ({ tabs, setSelectedtab, selectedTab, buttonWidth = 'auto', className = 'py-1.5 border px-2', disabled=false  }) => {

    return (
        <div className="w-auto rounded-md flex justify-end text-sm sm:text-[10px] lg:text-xs md:text-[10px]">
            {
                tabs.map((item, i) => {
                    return (
                        <button disabled={disabled} key={item} onClick={() => {
                            setSelectedtab(item)
                        }}
                            className={`${i === tabs?.length - 1 ? 'rounded-r-md' : ''} ${i === 0 ? 'rounded-l-md' : ''} ${selectedTab === item ? 'bg-secondary/10 text-secondary font-semibold' : 'text-gray-600 bg-white'} text-sm  ${buttonWidth} ${className}`}>
                            {item}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default FilterButtonTab;