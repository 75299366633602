import ScrollToBottom from 'react-scroll-to-bottom';
import Qualification from './Qualification'
import Course from './course'
import Specialisation from './specialisation'
import IconButton from 'src/Components/Buttons/IconButton';
// import UploadDocument from './uploadTraining'

const cols = [
	'Qualification',
	'Course',
	'Specialization',
	'Institution',
	'Completed Year',
	'Marks',
	'Grade',
]

const Table = (props) => {
	const { setFormData, formData, educationData, canEdit, /* document, setDocument, mockObject, trainingCertificateSave */ } = props

	const handleAdd = () => {
		setFormData([
			...formData,
			{
				comusereducationid: null,
				comuserid: Number(localStorage.getItem('comuserid') || 0),
				dateducationmasterid: 0,
				datcoursemasterid: 0,
				datspecialisationid: 0,
				university_institute: '',
				cousetype: '',
				gradeorclass: '',
				percentageofmarks: '',
				periodofeducation: '',
				yearofpassing: '',
				remarks: '',
				comtenantid: Number(localStorage.getItem('Tenantid') || 0),
				isactiveflag: true,
				createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
				notes: '',
				uId:
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36),
			},
		])
	}

	const handleDelete = uId => {
		if (formData?.length > 1) {
			setFormData(formData.filter(item => item.uId !== uId))
		}
	}

	const handleChange = (e, uId) => {
		let { name, value } = e.target
		let afterChange = formData?.map(item => {
			if (item?.uId === uId) {
				if (name === 'dateducationmasterid') {
					item['datcoursemasterid'] = null
					item['datspecialisationid'] = 0
					item['university_institute'] = ''
					item['yearofpassing'] = null
					item['percentageofmarks'] = null
				} else if (name === 'datcoursemasterid') {
					item['datspecialisationid'] = 0
					item['university_institute'] = ''
					item['yearofpassing'] = null
					item['percentageofmarks'] = null
				}
				if (name === 'university_institute' || name === 'gradeorclass') {
					item[name] = String(value)
				} else {
					item[name] = Number(value)
				}
			}
			return item
		})
		setFormData(afterChange)
	}

	const handleMarks = (e, uId) => {
		let { name, value } = e.target
		let marks = value.split('.')
		let afterChange = formData?.map(item => {
			if (item?.uId === uId) {
				if (marks?.length > 1) {
					item[name] = Number(`${marks[0]}.${marks[1][0]}`)
				} else {
					item[name] = Number(value)
				}
			}
			return item
		})
		setFormData(afterChange)
	}

	const handleYear = (e, uId) => {
		let { name, value } = e.target
		let year = value
		if (year.length <= 4) {
			let afterChange = formData?.map(item => {
				if (item?.uId === uId) {
					item[name] = year
				}
				return item
			})
			setFormData(afterChange)
		}
	}

	return (
		<ScrollToBottom className="bg-white w-full overflow-y-scroll h-[40vh] text-sm">
			<table className='relative w-full text-sm text-gray-600'>
				<thead>
					<tr className='text-left capitalize bg-gray-50'>
						{cols?.map(item => {
							const label = item.includes('*') ? item.replace('*', '') : item
							const isRequired = ['Qualification', 'Course', 'Specialization', 'Institution', 'Completed Year'].includes(label)
							return (
								<th
									key={item}
									className='bg-gray-100 border-b  font-[500] py-2 px-2'
								>
									{label}
									{isRequired && <span className='text-red-300'> *</span>}
								</th>
							)
						})}
						{canEdit && (
							<>
								<th className='bg-gray-100 border-b font-[500] py-2 px-2'>
									Action
								</th>
							</>
						)}
					</tr>
				</thead>
				<tbody>
					{formData?.map((item, i) => {
						let {
							percentageofmarks,
							university_institute,
							yearofpassing,
							datcoursemasterid,
							dateducationmasterid,
							datspecialisationid,
							gradeorclass,
							uId,
							// comusereducationid
						} = item

						return (
							<tr key={`${uId}-${i}`} className='bg-white border-b'>
								<td className='p-1 border'>
									<div className=' min-w-[80px] '>
										<Qualification
											// className='w-52'
											rowId={uId}
											value={dateducationmasterid}
											educationData={educationData}
											handleChange={handleChange}
											canEdit={canEdit}
										/>
									</div>
								</td>
								<td className='px-1 py-1 border'>
									<div className='w-48 '>
										<Course
											item={item}
											qualification={dateducationmasterid}
											rowId={uId}
											value={datcoursemasterid}
											handleChange={handleChange}
											canEdit={canEdit}
										/>
									</div>
								</td>
								<td className='px-1 py-1 border'>
									<div className='w-40 '>
										<Specialisation
											course={datcoursemasterid}
											rowId={uId}
											value={datspecialisationid}
											handleChange={handleChange}
											canEdit={canEdit}
										/>
									</div>
								</td>
								<td className='px-1 py-1 border'>
									<input
										title={university_institute || ''}
										disabled={!canEdit}
										onChange={e => handleChange(e, item?.uId)}
										type='text'
										value={university_institute || ''}
										name='university_institute'
										placeholder='Institute'
										className={` placeholder:text-gray-400 placeholder:font-[200] px-0.5 w-[120px] ${canEdit ? 'border-0 rounded-md' : 'border-0'
											} py-0.5 w-full disabled:text-gray-800 disabled:bg-transparent focus:outline-none `}
										maxLength={50}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<input
										disabled={!canEdit}
										onChange={e => handleYear(e, item?.uId)}
										type='number'
										value={Number(yearofpassing) || ''}
										name='yearofpassing'
										placeholder='Enter a year'
										className={` placeholder:text-gray-400 placeholder:font-[200] px-0.5 w-[120px] ${canEdit ? 'border-0 rounded-md' : 'border-0'
											} py-0.5 w-full disabled:text-gray-800 disabled:bg-transparent focus:outline-none `}
									/>
								</td>
								<td className='p-1 border'>
									<input
										disabled={!canEdit}
										onChange={e => {
											if (e.target.value > 100) {
												e.target.value = 0
											} else {
												handleMarks(e, item?.uId)
											}
										}}
										placeholder='Marks'
										className={`w-12 px-1 disabled:text-gray-800 focus:outline-none `}
										type='number'
										value={percentageofmarks || ''}
										name='percentageofmarks'
									/>
								</td>
								<td className='px-1 py-1 border'>
									<input
										disabled={!canEdit}
										onChange={e => handleChange(e, item?.uId)}
										type='text'
										value={gradeorclass || ''}
										name='gradeorclass'
										className={`w-12  px-1 disabled:text-gray-800 focus:outline-none `}
										maxLength={50}
									/>
								</td>
								{/* {(canEdit && comusereducationid) ?
								<td className='px-1 py-1 border'>
									<div className='flex items-center justify-start space-x-2'>
										 <UploadDocument
										// item={item}
										setDocuments={setDocument}
										documents={document}
										comusereducationid={comusereducationid}
										// uId={uId}
								/>
										<IconButton
											title='Remove Document'
											// onClick={removeDoc}
											className='w-6 h-6 p-1 text-white disabled:bg-primary/10 bg-primary'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-6 h-6'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
												/>
											</svg>
										</IconButton>
									</div>
								</td> : <td className='px-1 py-1 border'> </td>
								} */}
								{canEdit && (
									<td className='p-1 border'>
										<div className='flex justify-end space-x-1'>
											{formData?.length > 1 && (
												<IconButton
													onClick={() => handleDelete(item?.uId)}
													title='Delete Education'
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-4 h-4'
													>
														{' '}
														<path
															fillRule='evenodd'
															d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
															clipRule='evenodd'
														/>{' '}
													</svg>
												</IconButton>
											)}
											<span className="hidden addIconbutton-add-btn">
												<IconButton
													disabled={formData?.length >= 10}
													onClick={handleAdd}
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
													title="Add Education"
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-4 h-4'
													>
														{' '}
														<path
															fillRule='evenodd'
															d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
															clipRule='evenodd'
														/>{' '}
													</svg>
												</IconButton>
											</span>
										</div>
									</td>
								)}
							</tr>
						)
					})}
				</tbody>
			</table>
		</ScrollToBottom>
	)
}

export default Table
