import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { ProductItem } from './defaulTech';
import CreateProduct from './createProducts';
import EditProduct from './editProducts';
import { GET_PRODUCT_MASTERLIST } from 'src/Redux/actions/masterdata/user';
import { getDefaultProductMasterData } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = ['Product', 'Technology', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const ProductMasterList = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [product, setProduct] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [productId, setProductId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(ProductItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
  const { productMasterList, loading: productLoading, PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(productLoading); // Hide the loader after a delay
    }, 100);
  }, [productLoading]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_PRODUCT_MASTERLIST({ ...getDefaultProductMasterData(), sorttype: item?.id, status: status?.label, limit: limit }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  useMemo(() => {
    if (product?.productname) {
      setSearchTerm(product?.productname);
    }
  }, [product]);

  const handlePagination = async (page) => {
    let res = await dispatch(GET_PRODUCT_MASTERLIST({ ...getDefaultProductMasterData(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_PRODUCT_MASTERLIST({ ...getDefaultProductMasterData(), status: item.label }));
    if (res === null) {
      setDetailsData(res?.data);
    }
  };

  const handleProduct = (product) => {
    setDetailsData(product);
    setProductId(product?.productmasterid);
    setIsOpen(true);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(
      GET_PRODUCT_MASTERLIST({ ...getDefaultProductMasterData(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  return (
    <div className='mt-4 p-2 py-3 w-full h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Product List (
            {productMasterList?.length > 0 ? productMasterList[0]?.totalcount : productMasterList?.length}){''}
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_PRODUCT_MASTERLIST}
              searchPayload={{ ...getDefaultProductMasterData(), searchname: searchTerm, }}
              setSearchTerm={setSearchTerm}
              options={productMasterList}
              idkey='productname'
              optionlabelKey='productname'
              getSelectedOption={setProduct}
              placeholder='Enter Product Name'
              className='p-2'
            />
          </div>
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              total={productMasterList?.length > 0 ? productMasterList[0]?.totalcount : productMasterList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {(PermissionsOfMasterdata.includes('Add/Edit Technology')) ? <CreateProduct /> : ""}
      </div>
      <div className='h-[46vh] overflow-scroll'>
        {loading || productMasterList === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          productMasterList && (
            <table className='text-sm w-full relative'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {productMasterList?.map((item, i) => {
                  const {
                    productname,
                    technology,
                    isactiveflag,
                    createdtimestamp,
                    createduser,
                    productmasterid,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleProduct(item)}
                      key={productmasterid}
                      className='cursor-pointer text-left'>
                      <td className='border px-2 py-1.5'><div>{productname}</div></td>
                      <td className='border px-2 py-1.5'><div>{technology}</div></td>
                      <td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='border px-2'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                      <td className='border px-2'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditProduct
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        productmasterid={productId}
        setDetailsData={setDetailsData}
        detailsData={detailsData}
        permissions={PermissionsOfMasterdata}
      />
    </div>
  );
};

export default ProductMasterList;
