
import DataTable from './table'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { insertDataKeys } from './defaultData'
import { useDispatch, useSelector } from 'react-redux';
import { GET_LIST_OF_PROJECT_ROLE, SAVE_PROJECT_ROLE } from 'src/Redux/actions/projects/planning';
import moment from 'moment';
import {  useState } from 'react';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';
import { calculatEstdEffortHrs, getWorkingDaysStartAndEndDate } from 'src/Services';
import RoleCalculation from '../rolecalculation';

const Roles = ({ hooksValue, disabled, setEditTab }) => {
    const { roles, project, errorInPeopleCost } = hooksValue
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { companyWeekdaysList } = useSelector((state) => state.UserManagementList);

    const saveRoles = async () => {
        setLoading(true)
        let validationData = roles?.filter((item) => item?.isactiveflag === true)
        let dataCopy = [...roles]
        let notRequ = ['modifiedtimestamp',  'croleid','skillid', 'isonsite', 'estPeopleCost', 'skill', 'technology', 'max_band', 'min_band', 'max_bandid', 'min_bandid', 'modifiedcomuserid', 'projectroleid', 'billratehr', 'billrate', 'projectroletasks',
            'projectid', 'workmode', 'workmodeid', 'staffcostid', 'resourceid', 'controllingarea', 'cityname',
            'cityid', 'billable', 'cost_hr', 'datcomcurrencyid', 'allocationcostelement', 'citytext', 'notes', 'rolename', 'plannedeffort', "allocation"]

        let valdiation = CheckListObjectValidation(validationData, notRequ)
        if (valdiation?.isvalid) {
            let rmdata = []
            for (let d of dataCopy) {
                let item = {...d}
                let po = item?.peopleno
                let al = item?.allocation
                let hr = 0
                let wday = getWorkingDaysStartAndEndDate(item?.startdate, item?.enddate)
                if (companyWeekdaysList?.length > 0) {
                    hr = companyWeekdaysList[0]?.noofhoursperday
                }
                item['plannedeffort'] = calculatEstdEffortHrs(po, al, hr, wday)
                item['startdate'] = moment(new Date(item['startdate'])).format('YYYY-MM-DD') || ''
                item['enddate'] = moment(new Date(item[['enddate']])).format('YYYY-MM-DD') || ''
                delete item['skill']
                delete item['technology']
                delete item['cityname']
                delete item['estPeopleCost']
                item['projectid'] = project?.projectid
                item['workmodeid'] = null
                item['allocation'] = item?.allocation / 100
                if (!item['projectroletasks']) {
                    item['projectroletasks'] = []
                }
                let tasks = []
                for (let _task of item['projectroletasks']) {
                    tasks.push({
                        projtaskid: _task?.projtaskid,
                        isactiveflag: true,
                        notes: _task?.notes,
                        // notes: "",
                        createdcomuserid: Number(localStorage.getItem('comuserid') || 0)
                    })
                }
                item['projectroletasks'] = tasks

                for (let rolekey in item) {
                    if (!insertDataKeys.includes(rolekey)) {
                        delete item[rolekey]
                    }
                }
               
                rmdata.push(item)
            }
            let res = await dispatch(SAVE_PROJECT_ROLE(rmdata))
            if (res) {
                setEditTab(null)
                dispatch(GET_LIST_OF_PROJECT_ROLE(project?.projectid, 0))
            }
        }

        setLoading(false)
    }

    const handleCancel = () => {
        setEditTab(null)
        dispatch(GET_LIST_OF_PROJECT_ROLE(project?.projectid, 0))
    }
    
    return (
        <div className='w-full'>
            <DataTable disabled={disabled} hooksValue={hooksValue} />
            <div className='w-full absolute bottom-0 left-0 px-3 flex justify-between items-center'>
                <div className='flex justify-start items-start'>
                <RoleCalculation 
                hooksValue={hooksValue}
                />
                {errorInPeopleCost && <span className='text-xs mt-3  text-red-400'>Cost estimation failed for some roles. Org cost and exchange rate data not found for the given parameters.</span>}
                </div>
                {disabled ? <div className='flex justify-start items-center space-x-2'>
                    <button onClick={handleCancel} className='text-primary border border-primary w-[80px] rounded-md py-1.5'>
                        Cancel
                    </button>
                    <PrimaryButton disabled={loading} onClick={saveRoles} className='bg-primary py-2 mx-2 rounded-md text-white'>
                        {loading ? 'Processing...' : 'Save'}
                    </PrimaryButton>
                </div> :''}

            </div>
        </div>
    )
}

export default Roles;