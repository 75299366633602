import { Dialog, Transition } from '@headlessui/react';
import { Fragment, memo, useState } from 'react';
import Details from './details';
import Profile from './profile';
import {
  GET_DEPARTMENT_MASTER,
  GET_WORKFORCEGROUP_MASTER,
  GET_BAND_MASTER,
  GET_COMPANY_MASTER,
  GET_DESIGNATION_MASTER,
  GET_WORKERTYPE_MASTER,
  GET_CITY_MASTER,
} from 'src/Redux/actions/workforce/talent';
import {
  GET_USER_TYPE_LIST,
  GET_USER_ACCESS_LIST,
  SAVE_USER_AS_DRAFT,
  GET_ROLE_MASTER,
  GET_LOCATION_MASTER,
  GET_GENDER_MASTER,
  GET_TIME_TYPE,
  SAVE_USER_PROFILE_AS_DRAFT,
  GET_NATIONALITY_MASTER,
  GET_SERVICELINE_MASTER
} from 'src/Redux/actions/masterdata/user';
import { GET_SKILLAREA_MASTER } from 'src/Redux/actions/workforce/eprofile';
// import { GET_EMPLOYEE_SUMMARY } from 'src/Redux/actions/workforce/eprofile';
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects';
import { AlertSuccess } from 'src/Services';
import { useDispatch } from 'react-redux';
import {
  getDefaultStatusPayload,
  getDefaultNationalityPayload,
  getMasterCompanyIdData,
  getUserAccessPayload,
  getUserTypeIdData,
  getRoleMasterIdData,
  getTenantWorkForceyIdData,
  getDepartmentMasterIdData,
  getDesignationMasterIdData,
  getBandMasterData,
  getDefaultLocationPayload,
  getTenantWorkerTypeyIdData,
  getGenderPayload,
  getDefaultTimeTypePayload,
  getCityPayload,
  getUserAccessMasterPayload,
} from 'src/Services/constant/defaultPayload';
import { getMasterServiceLinePayload, getMasterSkillAreaData } from 'src/Services/constant/masterdatapayload';
import { detailItem, profileItem } from './defaultRowAndData';
import IconButton from 'src/Components/Buttons/IconButton';
import UserPrimarySkill from './skills';

const tabslist = ['Details', 'Profile', 'Skills'];

const CreateUser = () => {
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  const [tabCanAccess, setTabCanAccess] = useState(['Details']);
  const [activeTab, setActiveTab] = useState('Details');
  const [detailsData, setDetailsData] = useState(detailItem());
  const [profileData, setProfileData] = useState(profileItem());
  const [selectedRoles, setSelectedRoles] = useState([]);
  let [UserId, setUserId] = useState(0);
  const [rm, setRm] = useState(null);

  const loadFormApis = () => {
    dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
    dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()));
    dispatch(GET_ROLE_MASTER(getRoleMasterIdData()));
    dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()));
    dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()));
    dispatch(GET_DESIGNATION_MASTER(getDesignationMasterIdData()));
    dispatch(GET_BAND_MASTER(getBandMasterData()));
    dispatch(GET_LOCATION_MASTER(getDefaultLocationPayload()));
    dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()));
    dispatch(GET_GENDER_MASTER(getGenderPayload()));
    dispatch(GET_TIME_TYPE(getDefaultTimeTypePayload()));
    dispatch(GET_CITY_MASTER(getCityPayload()));
    dispatch(GET_NATIONALITY_MASTER(getDefaultNationalityPayload()));
    dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E')));
    dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()));
    dispatch(GET_SKILLAREA_MASTER(getMasterSkillAreaData()));
  };

  function closeModal() {
    setIsOpen(false);
    setTabCanAccess(['Details']);
    setActiveTab('Details')
    setDetailsData(detailItem());
    setProfileData(profileItem());
    setSelectedRoles([]);
    dispatch(GET_USER_ACCESS_LIST(getUserAccessMasterPayload()));
  }

  function openModal() {
    setIsOpen(true);
    loadFormApis();
  }

  async function detailSave(payload) {
    let res = await dispatch(SAVE_USER_AS_DRAFT(payload));
    if (res?.success) {
      setUserId(res?.UserId);
      setTabCanAccess([...tabCanAccess, 'Profile']);
      setActiveTab('Profile')
      AlertSuccess('User Details saved successfully!');
      dispatch(GET_USER_ACCESS_LIST(getUserAccessPayload()));
    }
  }

  const profileSave = async (payload) => {
    let res = await dispatch(SAVE_USER_PROFILE_AS_DRAFT(payload, UserId));
    if (res?.success) {
      setTabCanAccess(['Details', 'Profile', 'Skills']);
      AlertSuccess('User Profile Details saved successfully!');
      // setUserId(res?.UserId);
      // dispatch(GET_USER_ACCESS_LIST(getUserAccessMasterPayload()));
    }
  };

  return (
    <>
      <IconButton
        title='Create New User'
        onClick={openModal}
        className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </IconButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform rounded-2xl bg-white px-6  text-left align-middle shadow-xl transition-all '>
                  <div className='border-b border-secondary py-1  flex justify-between items-center'>
                    <h3 className='text-lg font-[500] leading-6 text-primary'>
                      Add User
                    </h3>

                    <div className="flex w-auto p-1 space-x-1 rounded-lg">
                      {tabslist?.map((item, i) => {
                        return (
                          <button
                            key={i}
                            disabled={!tabCanAccess.includes(item)}
                            onClick={() => setActiveTab(item)}
                            className={`w-full py-1 px-2 text-lg outline-white ${item === activeTab
                              ? 'bg-secondary/10 text-secondary rounded-md font-[500]'
                              : 'text-gray-500'}`}
                          >
                            <span className='px-4'> {item}</span>
                          </button>
                        )
                      })}
                    </div>

                    <IconButton
                      title='Close'
                      onClick={closeModal}
                      type='button'
                      className='rounded-full border h-10 w-10 flex justify-center items-center'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </svg>
                      <span className='sr-only'>Close icon</span>
                    </IconButton>
                  </div>

                  {activeTab === 'Details' && <Details
                    UserId={UserId}
                    detailSave={detailSave}
                    detailsData={detailsData}
                    setProfileData={setProfileData}
                    setDetailsData={setDetailsData}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                  />}
                  {activeTab === 'Profile' && <Profile
                    rm={rm}
                    setRm={setRm}
                    detailsData={detailsData}
                    UserId={UserId}
                    profileSave={profileSave}
                    profileData={profileData}
                    setProfileData={setProfileData}
                  />}
                  {activeTab === 'Skills' && <UserPrimarySkill UserId={UserId} profileData={profileData} />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(CreateUser);