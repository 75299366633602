import {
	ACTION_GET_ANNOUNCEMENT_MASTER,
	ACTION_GET_BUSINESSGROUP_MASTER,
	ACTION_GET_BUSINESS_MASTER,
	ACTION_GET_COMPANY_HOLIDAYS_MASTERLIST,
	ACTION_GET_COMPANY_WEEKDAYS,
	ACTION_GET_COST_RATES,
	ACTION_GET_COUNTRY_MASTER,
	ACTION_GET_COURSE_LIST_MASTER,
	ACTION_GET_CUSTOMER_GROUP_MASTER,
	ACTION_GET_CUSTOMER_HOLIDAYS_MASTERLIST,
	ACTION_GET_DELIVERY_ROLE_MASTER,
	ACTION_GET_DM_LIST,
	ACTION_GET_EDUCATION_LIST_MASTER,
	ACTION_GET_EXCHANGE_RATE_MASTER,
	ACTION_GET_GENDER_MASTER,
	ACTION_GET_INDUSTRY_LIST_MASTER,
	ACTION_GET_LIST_OF_STATUS_BY_E,
	ACTION_GET_LOCATION_MASTER,
	ACTION_GET_NATIONALITY_MASTER,
	ACTION_GET_OVERHEADS,
	ACTION_GET_PMO_LIST,
	ACTION_GET_PRODUCT_MASTERLIST,
	ACTION_GET_REGION_MASTER,
	ACTION_GET_ROLE_MASTER,
	ACTION_GET_SERVICEGROUP_MASTER,
	ACTION_GET_SERVICELINE_MASTER,
	ACTION_GET_SPEC_LIST_MASTER,
	ACTION_GET_STATE_MASTER,
	ACTION_GET_TASK_GROUPS_MASTER,
	ACTION_GET_TASK_MASTER,
	ACTION_GET_TECHNOLOGY_GROUP,
	ACTION_GET_TIME_TYPE,
	ACTION_GET_USER_ACCESS_LIST,
	ACTION_GET_USER_TYPE_LIST,
	ACTION_GET_WEEKDAYS_OF_A_CUSTOMER,
	ACTION_GET_WORKERTYPE_LIST_MASTER
} from 'src/Redux/types'
import { AlertError, AlertSuccess } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'


const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getHeadersWithoutAuth = () => {
	return {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
	}
}

export const GET_USER_TYPE_LIST = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_datusertype`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No user type found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_USER_TYPE_LIST, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const SAVE_DESIGNATION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_designationmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const designationId = item?.designationmasterid;
				return { success: true, designationId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_BAND_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_bandmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const bandId = item?.bandmasterid;
				return { success: true, bandId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_COURSE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_datcourse`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				/* const bandId = item?.bandmasterid; */
				return { success: true, item };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_SPECIALISATION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_datspecialisation`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				/* const bandId = item?.bandmasterid; */
				return { success: true, item };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_EDUCATION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_dateducation`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				/* const bandId = item?.bandmasterid; */
				return { success: true, item };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_WORKER_TYPE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_workertypemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				return { success: true, item};
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_DEPARTMENT_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_departmentmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const departmentId = item?.announcementid;
				return { success: true, departmentId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_STATUS_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_datstatusmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				AlertSuccess('Status saved successfully!')
			} else {
				AlertError('Something went wrong. Please try later.')
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const SAVE_TECHNOLOGY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_technologymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				// AlertSuccess('Tech saved successfully!')
				let item = res?.data?.data;
				const technologyId = item?.technologymasterid;
				return { success: true, technologyId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_TECHGROUP_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_techgroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const techgroupId = item?.techgroupid;
				return { success: true, techgroupId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_SKILL_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_skillmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				// AlertSuccess('Task Details saved successfully!');
				let item = res?.data?.data;
				const skillId = item?.skillmasterid;
				return { success: true, skillId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_SKILL_AREA_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_skillareamaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const skillAreaId = item?.skillareamasterid;
				return { success: true, skillAreaId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_REGION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_regionmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				return { success: true };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_COUNTRY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_countrymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const countryId = item?.comcountryid;
				return { success: true, countryId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_STATE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_statemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const stateId = item?.comstateid;
				return { success: true, stateId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_CITY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_citymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const cityId = item?.comcityid;
				return { success: true, cityId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_LOCATION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_locationmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const locId = item?.comlocationid;
				return { success: true, locId };
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
			return { success: false };

		}
	}
}

export const SAVE_COMPANY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_companymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const comId = item?.companyid;
				return { success: true, comId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_COMPANY_HOLIDAY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_companyholidays`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const comHolidayId = item?.companyholidaysid;
				return { success: true, comHolidayId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_CUSTOMER_HOLIDAY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_customerholidays`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const custHolidayId = item?.customerholidaysid;
				return { success: true, custHolidayId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_INDUSTRY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_industrymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const industryId = item?.industryid;
				return { success: true, industryId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_BUSINESS_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_businessunit`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const buId = item?.businessunitid;
				// if (item?.Message === 'Success') {
				// 	// AlertSuccess('Task Details saved successfully!');
				// } else {
				// 	AlertError(item?.Message || 'Something went wrong. Please try later.');
				// }
				return { success: true, buId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_SERVICE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_slmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const serviceId = item?.servicelineid;
				if (item?.Message === 'Success') {
					// AlertSuccess('Task Details saved successfully!');
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, serviceId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_CUSTOMER_ROLE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_customergroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const customerRoleId = item?.customergroupid;
				if (item?.Message === 'Success') {
					// AlertSuccess('Task Details saved successfully!');
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, customerRoleId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_SERVICE_GROUP_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_slgroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const serviceGId = item?.servicelinegroupid;
				if (item?.Message === 'Success') {
					// AlertSuccess('Task Details saved successfully!');
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, serviceGId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_BUSINESS_GROUP_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_bugroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const bugId = item?.businessunitgroupid;
				if (item?.Message === 'Success') {
					// AlertSuccess('Task Details saved successfully!');
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, bugId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_DELIVERY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_deliveryrolemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const deliveryRoleId = item?.deliveryroleid;
				if (item?.Message === 'Success') {
					// AlertSuccess('Task Details saved successfully!');
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, deliveryRoleId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const GET_ROLE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_rolemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No roles found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_ROLE_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}
export const GET_CUSTOMER_GROUP_MASTER = data =>
	async dispatch => {
		try {
			const url = `${baseUrl}/get_customergroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			let items = res?.data?.data;
			if (items === null) AlertError('No customer groups found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_CUSTOMER_GROUP_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}

	}

export const GET_WORKERTYPE_LIST_MASTER = (data) => async (dispatch) => {
	try {
		const url = `${baseUrlMaster}/get_workertypemasterlist`
		const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Workertype found for Employee')
			let addnewkey = items?.map((item) => {
				item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_WORKERTYPE_LIST_MASTER,
				data: items,
			})
			return { success: true, data: addnewkey }
		}
	} catch (error) {
		AlertError(error.response?.data).replace(/\\/g, '')
	}
}
export const GET_COURSE_LIST_MASTER = (data) => async (dispatch) => {
	try {
		const url = `${baseUrlMaster}/get_datcourselist`
		const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No courselist found for Employee')
			let addnewkey = items?.map((item) => {
				item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_COURSE_LIST_MASTER,
				data: items,
			})
			return { success: true, data: addnewkey }
		}
	} catch (error) {
		AlertError(error.response?.data).replace(/\\/g, '')
	}
}
export const GET_EDUCATION_LIST_MASTER = (data) => async (dispatch) => {
	try {
		const url = `${baseUrlMaster}/get_dateducationlist`
		const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No education found for Employee')
			let addnewkey = items?.map((item) => {
				item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_EDUCATION_LIST_MASTER,
				data: items,
			})
			return { success: true, data: addnewkey }
		}
	} catch (error) {
		AlertError(error.response?.data).replace(/\\/g, '')
	}
}

export const GET_SPEC_LIST_MASTER = (data) => async (dispatch) => {
	try {
		const url = `${baseUrlMaster}/get_datspecialisationlist`
		const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No specialisationlist found for Employee')
			let addnewkey = items?.map((item) => {
				item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_SPEC_LIST_MASTER,
				data: items,
			})
			return { success: true, data: addnewkey }
		}
	} catch (error) {
		AlertError(error.response?.data).replace(/\\/g, '')
	}
}

export const GET_INDUSTRY_LIST_MASTER = (data) => async (dispatch) => {
	try {
		const url = `${baseUrl}/get_industrymasterlist`
		const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No industry list found for Employee')
			let addnewkey = items?.map((item) => {
				item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_INDUSTRY_LIST_MASTER,
				data: items,
			})
			return { success: true, data: addnewkey }
		}
	} catch (error) {
		AlertError(error.response?.data).replace(/\\/g, '')
	}
}

export const GET_DELIVERY_ROLE_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_deliveryrolemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No delivery roles found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_DELIVERY_ROLE_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_USER_ACCESS_LIST = payload => {
	return async dispatch => {
		try {
			delete payload['roleid']
			const url = `${baseUrl}/get_userlistforAdmin`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (!items) AlertError('No Users found for Admin')
				let addnewkey = items?.map(item => {
					item['uId'] =
						Math.random().toString(36).substr(2, 9) +
						new Date().getTime().toString(36)
					return item
				})
				dispatch({
					type: ACTION_GET_USER_ACCESS_LIST,
					data: addnewkey || [],
				})
				return { success: true, data: addnewkey || [] }
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_DM_LIST = payload => {
	return async dispatch => {
		try {
			delete payload['roleid']
			const url = `${baseUrl}/get_userlistforAdmin`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (!items) AlertError('No Users found for Admin')
				let addnewkey = items?.map(item => {
					item['uId'] =
						Math.random().toString(36).substr(2, 9) +
						new Date().getTime().toString(36)
					return item
				})
				dispatch({
					type: ACTION_GET_DM_LIST,
					data: addnewkey || [],
				})
				return { success: true, data: addnewkey || [] }
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}
export const GET_PMO_LIST = payload => {
	return async dispatch => {
		try {
			delete payload['roleid']
			const url = `${baseUrl}/get_userlistforAdmin`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (!items) AlertError('No Users found for Admin')
				let addnewkey = items?.map(item => {
					item['uId'] =
						Math.random().toString(36).substr(2, 9) +
						new Date().getTime().toString(36)
					return item
				})
				dispatch({
					type: ACTION_GET_PMO_LIST,
					data: addnewkey || [],
				})
				return { success: true, data: addnewkey || [] }
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}
export const GET_BUSINESS_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || ''
			}
			const url = `${baseUrlMaster}/get_bumaster`
			delete payload['businessunitname']
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No business unit found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_BUSINESS_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}
export const GET_BUSINESSGROUP_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || '',
			}
			const url = `${baseUrlMaster}/get_bugroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No business group found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_BUSINESSGROUP_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_SERVICELINE_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || ''
			}
			const url = `${baseUrlMaster}/get_slmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No service line found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_SERVICELINE_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_SERVICEGROUP_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || '',
			}
			const url = `${baseUrlMaster}/get_slgroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Service Line Group found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_SERVICEGROUP_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}


export const GET_TECHNOLOGY_GROUP = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_techgroupmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Technology Group found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_TECHNOLOGY_GROUP, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_STATE_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || ''
			}
			const url = `${baseUrlMaster}/get_statemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No State found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_STATE_MASTER, data: addnewkey || [] })

			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_LOCATION_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || ''
			}
			const url = `${baseUrlMaster}/get_locationmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Location found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_LOCATION_MASTER, data: addnewkey || [] })

			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_REGION_MASTER = (data) => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || '',
			}
			const url = `${baseUrlMaster}/get_regionmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Region found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_REGION_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_COUNTRY_MASTER = data => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || ''
			}
			const url = `${baseUrlMaster}/get_countrymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Country found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_COUNTRY_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TASK_MASTER = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_taskmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_TASK_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TASK_GROUPS_MASTER = (payload) => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_taskgroupmaster`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (items === null) AlertError('No Task Group found !')
				let addnewkey = items?.map(item => {
					item['uId'] =
						Math.random().toString(36).substr(2, 9) +
						new Date().getTime().toString(36)
					return item
				})
				dispatch({ type: ACTION_GET_TASK_GROUPS_MASTER, data: addnewkey || [] })
				return { success: true, data: addnewkey || [] }
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, "")
		}
	}
}

export const GET_GENDER_MASTER = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_datgender`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Gender found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_GENDER_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TIME_TYPE = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_timetypemaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Time Type found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_TIME_TYPE, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).reny7place(/\\/g, '')
		}
	}
}

export const GET_NATIONALITY_MASTER = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_nationalitymaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Nationality found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_NATIONALITY_MASTER, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const SAVE_TASK_GROUP_AS_DRAFT = (payload) => {
	return async () => {
		try {
			const url = `${baseUrlMaster}/insert_taskgroupmaster`;
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				JSON.stringify(payload)
			);
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const TaskGroupId = item?.TaskGroupId;
				if (item?.Message === 'Success') {
					// AlertSuccess('Task Details saved successfully!');
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, TaskGroupId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_TASK_AS_DRAFT = (payload) => {
	return async () => {
		try {
			const url = `${baseUrlMaster}/insert_taskmaster`;
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				JSON.stringify(payload)
			);
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const TaskId = item?.taskid;
				return { success: true, TaskId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const SAVE_USER_AS_DRAFT = (payload) => {
	return async () => {
		try {
			const url = `${baseUrl}/insert_usersummary`;
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				JSON.stringify(payload)
			);
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const UserId = item?.UserId;
				return { success: true, UserId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_USER_PROFILE_AS_DRAFT = (payload) => {
	return async () => {
		try {
			const url = `${baseUrl}/update_userprofiledetails`;
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				JSON.stringify(payload)
			);
			if (res?.status === 200 || res?.status === 201) {
				// AlertSuccess('User Profile Details saved successfully!');
				let item = res?.data?.data;
				const UserId = item?.comuserid;
				return { success: true, UserId };
			}
			AlertError('Something went wrong. Please try later.');
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const GET_COST_RATES = (payload, noDataErr = true) => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_orgcostrate`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null && noDataErr) AlertError('No Cost Rates found')
			let addnewkey = items?.map((item) => {
				item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_COST_RATES,
				data: addnewkey || []
			}
			);
			return addnewkey
		}
		catch (error) {
			AlertError(error?.response?.data).reny7place(/\\/g, '')
		}
	}
}

export const SAVE_COST_RATES = (payload, orgcostrateid) => {
	return async dispatch => {

		try {
			const url = `${baseUrl}/insert_orgcostrate`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data
				const orgcostrateid = item?.orgcostrateid;
				if (item?.Message === 'Success') {
					return { success: true, orgcostrateid };
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.')
				}
				return { success: true, orgcostrateid };
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '');
		}
	};
};
export const GET_OVERHEADS = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_overheadmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			let items = res?.data?.data
			if (items === null) AlertError('No Overheads found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_OVERHEADS, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).reny7place(/\\/g, '')
		}
	}
}
export const SAVE_OVERHEADS_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_overheadmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const overheadsId = item?.overheadmasterid;
				if (item?.Message !== 'Success') {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, overheadsId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_EXCHANGE_RATE_MASTER = (payload, noDataErr = true) => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_datexchangerate`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (items === null && noDataErr) AlertError('No Exchange Rate found !')
				let addnewkey = items?.map((item) => {
					item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
					return item
				})
				dispatch({
					type: ACTION_GET_EXCHANGE_RATE_MASTER,
					data: addnewkey || []
				},
				);
				return { success: true, data: addnewkey || [] }
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const SAVE_EXCHANGE_RATE = (payload, exchangerateid) => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_datexchangerate`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const exchangerateid = item?.exchangerateid;
				if (item?.Message !== 'Success') {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, exchangerateid };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_PRODUCT_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/insert_productmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const productId = item?.productmasterid;
				return { success: true, productId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}
export const GET_PRODUCT_MASTERLIST = (payload) => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_productmasterlist`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			let items = res?.data?.data
			if (items === null) AlertError('No Product found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_PRODUCT_MASTERLIST, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}
export const SAVE_OPPORTUNITY_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_opportunity`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const opportunityId = item?.opportunityid;
				return { success: true, opportunityId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_LIST_OF_STATUS_BY_E = () => {
	return async dispatch => {
		try {
			let payload = {
				Tenantid: Number(localStorage.getItem('Tenantid') || 0),
				statustype: 'E',
			}
			const url = `${baseUrl}/getstatusmaster`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (items === null) AlertError('No Status type found !')
				dispatch({ type: ACTION_GET_LIST_OF_STATUS_BY_E, data: items || [] })
				return items
			}
		} catch (error) {
			console.log(error.response)
			AlertError(error.response.data).replace(/\\/g, "")
		}
	}
}
export const GET_COMPANY_HOLIDAYS_MASTERLIST = (data) => {
	return async dispatch => {
		try {
			let payload = {
				...data,
				searchname: data?.searchname || ''
			}
			const url = `${baseUrlMaster}/get_companyholidays`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			let items = res?.data?.data
			if (items === null) AlertError('No Holiday found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_COMPANY_HOLIDAYS_MASTERLIST, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_CUSTOMER_HOLIDAYS_MASTERLIST = (payload) => {
	return async dispatch => {
		try {
			const url = `${baseUrlMaster}/get_customerholidays`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			let items = res?.data?.data
			if (items === null) AlertError('No Holiday found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({ type: ACTION_GET_CUSTOMER_HOLIDAYS_MASTERLIST, data: addnewkey || [] })
			return { success: true, data: addnewkey || [] }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_COMPANY_WEEKDAYS = payload => async dispatch => {
	try {
		const url = `${baseUrlMaster}/get_companyweekdays`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No WeekDays for Company')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_COMPANY_WEEKDAYS,
				data: addnewkey || [],
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_COMPANY_WEEKDAYS = (data, id) => {
	return async () => {
		try {
			let newdataFrame = []
			for (let item of data) {
				let weekday = {
					companyid: item.companyid, // Make sure to include the companyid in the payload
					companyweekdaysid: id,
				};
				weekday['companyweekdaysid'] = id
				for (let key in item) {
					if (key !== 'uId') {
						weekday[key] = item[key]
					}
				}
				newdataFrame.push(weekday)
			}
			let payload = {
				companyweekdaysjson: newdataFrame
			}
			const url = `${baseUrlMaster}/insert_companyweekdays`
			let res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			let item = res?.data?.data
			if (res?.status === 200 || res?.status === 201) {
				const companyweekdaysid = item?.companyweekdaysid;
				if (item?.Message === 'Success') {
					return { success: true, companyweekdaysid };
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.')
				}
				return { success: true, companyweekdaysid };
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '');
			return { success: false };
		}
	};
}

export const GET_WEEKDAYS_OF_A_CUSTOMER = payload => async dispatch => {
	try {
		const url = `${baseUrlMaster}/get_customerweekdays`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No WeekDays for Customer')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_WEEKDAYS_OF_A_CUSTOMER,
				data: addnewkey || [],
			})
			return { success: true, data: addnewkey }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_CUSTOMER_WEEKDAYS = (data, id) => {
	return async () => {
		try {
			let newdataFrame = []
			for (let item of data) {
				let weekday = {
					customerid: item.customerid, // Make sure to include the customerid in the payload
					customerweekdaysid: id,
				};
				weekday['customerweekdaysid'] = id
				for (let key in item) {
					if (key !== 'uId') {
						weekday[key] = item[key]
					}
				}
				newdataFrame.push(weekday)
			}
			let payload = {
				customerweekdaysjson: newdataFrame
			}
			const url = `${baseUrlMaster}/insert_customerweekdays`
			let res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			let item = res?.data?.data
			if (res?.status === 200 || res?.status === 201) {
				const customerweekdaysid = item?.customerweekdaysid;
				if (item?.Message === 'Success') {
					return { success: true, customerweekdaysid };
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.')
				}
				return { success: true, customerweekdaysid };
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '');
			return { success: false };
		}
	};
}
export const GET_ANNOUNCEMENT_MASTER = data => async dispatch => {
	try {
		const url = `${baseUrl}/get_announcements`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			data,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Announcement found')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_ANNOUNCEMENT_MASTER,
				data: addnewkey || [],
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_ANNOUNCEMENT_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_announcements`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const announcementId = item?.announcementid;
				return { success: true, announcementId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}