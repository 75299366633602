import { useNavigate } from "react-router-dom"

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <section className="flex items-center h-full">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                    <h2 className="mb-8 font-extrabold text-primary text-9xl">
                        <span className="sr-only">Error</span>404
                    </h2>
                    <p className="text-2xl font-[500] md:text-3xl">Sorry, we couldn't find this page.</p>
                    <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our app.</p>
                </div>
                <button onClick={() => navigate(-1)} className="px-8 py-3 font-[500] rounded-full bg-primary text-white">Go back</button>
            </div>
        </section>
    )
}

export default NotFound