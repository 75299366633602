import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Currency from './currency'; // Import Currency component
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import IconButton from 'src/Components/Buttons/IconButton';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

const cols = [
   'Min Users',
   'Max Users',
   'Price/Unit/Month',
   'Currency',
   'Action',
];

const SubscriptionPricing = ({ handleUpdatedPricingData, pricingData, setPricingData, setCanEdit, handleSubmit, tenantSubscriptionById, canEdit, comtenantid }) => {
   const [checkvalidation, setCheckValidation] = useState(false);
   const { currencyList } = useSelector((state) => state?.Projects);
   const isValidatedField = (checkvalidation, index, keyname) => {
      return (
         checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
      );
   };

   useEffect(() => {
      if (Array.isArray(tenantSubscriptionById) && tenantSubscriptionById.length > 0) {
         const mappedData = tenantSubscriptionById.map((item) => ({
            uId: item?.uId || Date.now(),
            minusers: item.minusers,
            maxusers: item.maxusers,
            unitprice: item.unitprice,
            datcomcurrencyid: item.datcomcurrencyid,
            comtenantid: item.comtenantid,
            isactiveflag: true,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: ''
         }));
         setPricingData(mappedData);
      } else {
         // If tenantSubscriptionById is empty or not an array, initialize with one empty row
         setPricingData([{
            uId: Date.now(),
            minusers: '',
            maxusers: '',
            unitprice: '',
            datcomcurrencyid: 0,
            comtenantid: comtenantid,
            isactiveflag: true,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: ''
         }]);
      }
      // eslint-disable-next-line 
   }, [tenantSubscriptionById]);

   // Log pricingData for debugging
   useEffect(() => {
      // Check if pricingData is empty, then initialize with an empty row
      if (pricingData.length === 0) {
         setPricingData([{
            uId: Date.now(),
            minusers: '',
            maxusers: '',
            unitprice: '',
            datcomcurrencyid: 0,
            comtenantid: comtenantid,
            isactiveflag: true,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: ''
         }]);
      }
      // eslint-disable-next-line 
   }, [pricingData]); // Run whenever pricingData changes

   // Log pricingData for debugging
   // useEffect(() => {
   //    console.log("Pricing Data:", pricingData);
   // }, [pricingData]);// Empty dependency array to run once on mount


   const handleChange = (e, index) => {
      const { name, value } = e.target;
      const updatedPricingData = pricingData.map((item, i) => {
         if (i === index) {
            return {
               ...item,
               [name]: value,
            };
         }
         return item;
      });
      setPricingData(updatedPricingData);
      handleUpdatedPricingData(updatedPricingData); // Call handleUpdatedPricingData with updated data
   };

   const SelectHandleChange = (e, uId) => {
      const { name, value } = e.target;
      const updatedPricingData = pricingData.map((item) => {
         if (item?.uId === uId) {
            return {
               ...item,
               [name]: value,
            };
         }
         return item;
      });
      setPricingData(updatedPricingData);
      handleUpdatedPricingData(updatedPricingData); // Call handleUpdatedPricingData with updated data
   };

   const handleAdd = () => {
      const newId = Date.now();
      const emptyMockObject = {
         uId: newId,
         minusers: '',
         maxusers: '',
         unitprice: '',
         datcomcurrencyid: 0,
         comtenantid: comtenantid,
         isactiveflag: true,
         createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
         modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
         notes: ''
      };
      setPricingData(prevData => [...prevData, emptyMockObject]);
      setCheckValidation(false)
   };

   const handleDelete = (uId) => {
      const updatedPricingData = pricingData.filter((item) => item?.uId !== uId);
      setPricingData(updatedPricingData);
      setCheckValidation(false)
   };


   return (
      <div className='mt-2 w-full h-[70vh]'>
         <div className='flex space-between items-center mt-2 text-sm align-items'>
            <table className='w-full mt-10 text-sm'>
               <thead>
                  <tr className='text-left capitalize bg-gray-50'>
                     {cols?.map((col, i) => {
                        return <th key={i} className='p-2 border font-[500] text-start'>
                           {col}
                        </th>
                     })}
                  </tr>
               </thead>
               <tbody className='text-gray-600 mr-4'>
                  {pricingData?.map((item, i) => {
                     return <tr key={item?.uId} className='text-left'>
                        <td
                           className={`${isValidatedField(checkvalidation, i, 'minusers')
                              ? 'border-2 border-red-400'
                              : 'border'
                              }`}
                        >
                           <input
                              disabled={canEdit}
                              id={`minusers_${item?.uId}`}
                              type='number'
                              value={item.minusers}
                              onChange={(e) => handleChange(e, i)}
                              name='minusers'
                              placeholder='Min Users'
                              className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                           />
                        </td>
                        <td
                           className={`${isValidatedField(checkvalidation, i, 'maxusers')
                              ? 'border-2 border-red-400'
                              : 'border'
                              }`}
                        >
                           <input
                              disabled={canEdit}
                              id={`maxusers_${item?.uId}`}
                              type='number'
                              value={item.maxusers}
                              onChange={(e) => handleChange(e, i)}
                              name='maxusers'
                              placeholder='Max Users'
                              className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                           />
                        </td>
                        <td
                           className={`${isValidatedField(checkvalidation, i, 'unitprice')
                              ? 'border-2 border-red-400'
                              : 'border'
                              }`}
                        >
                           <input
                              disabled={canEdit}
                              id={`price_${item?.uId}`}
                              type='number'
                              value={item.unitprice}
                              onChange={(e) => handleChange(e, i)}
                              name='unitprice'
                              placeholder='Price/Unit/Month'
                              className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                           />
                        </td>
                        <td
                           className={`${isValidatedField(checkvalidation, i, 'datcomcurrencyid')
                              ? 'border-2 border-red-400'
                              : 'border'
                              } px-1 w-1/3`}
                        >
                           <Currency
                              disabled={canEdit}
                              rowId={item?.uId}

                              key={`currency_${item?.uId}`}
                              value={item?.datcomcurrencyid}
                              id={`currency_${item?.uId}`}
                              handleChange={SelectHandleChange}
                              options={currencyList}
                              className='text-gray-600 w-full border-none rounded-md border focus:outline-none hover:outline-none'
                           />
                        </td>
                        <td className='border px-4 py-1'>
                           <div className='flex justify-start items-center'>
                              <IconButton
                                 disabled={canEdit}
                                 type="button"
                                 key={`delete_${item?.uId}`}
                                 title='Delete'
                                 onClick={() => handleDelete(item?.uId)}
                                 className='bg-primary w-6 h-6 p-1 text-white disabled:bg-primary/20'
                              >
                                 <MinusIcon className="w-3" />
                              </IconButton>
                              <span className="hidden addIconbutton-add-btn">
                                 <IconButton
                                    disabled={canEdit}
                                    key={`add_${item?.uId}`}
                                    title='Add'
                                    onClick={handleAdd}
                                    className='bg-primary w-6 h-6 p-1 text-white disabled:bg-primary/20'
                                 >
                                    <PlusIcon className="w-3" />
                                 </IconButton>
                              </span>
                           </div>
                        </td>
                     </tr>
                  })}
               </tbody>
            </table>
         </div>
         {!canEdit && (
            <div className='fixed bottom-2 right-6 mt-2'>
               <div className='gap-2 flex justify-end items-center'>
                  <PrimaryButton
                     onClick={() => setCanEdit(false)}
                     className='px-6 border bg-white border-primary text-black rounded mt-0 h-9 p-2'
                  >
                     Cancel
                  </PrimaryButton>
                  <PrimaryButton
                     disabled={canEdit}
                     className='bg-primary text-white rounded p-2 px-8 h-9'
                     onClick={handleSubmit}
                  >
                     Save
                  </PrimaryButton>
               </div>
            </div>
         )}
      </div>
   );
};

export default SubscriptionPricing;