import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToBottom from 'react-scroll-to-bottom'
import { GET_DEMAND_MASTER } from 'src/Redux/actions/workforce/demands'

const RoleDetails = ({ demandId }) => {
    const dispatch = useDispatch()
    const DemandDetailsList = useSelector((state) => state.DemandDetailsList)
    const { demandData } = DemandDetailsList

    useEffect(() => {
        dispatch(GET_DEMAND_MASTER(+demandId))
    }, [dispatch, demandId])

    return (
        <div className="p-3 text-sm">
            <ScrollToBottom className="h-[48vh]">
                {demandData[0]?.demandroledetails && (
                    <div className="flex flex-wrap items-center justify-start my-2 space-x-4 text-sm font-normal">
                        <p>
                            <span className="font-semibold"> Role Name: </span>
                            {demandData[0]?.demandroledetails[0]?.jobtitle}
                        </p>
                        <p>
                            <span className="font-semibold"> Min Band: </span>
                            {demandData[0]?.demandroledetails[0]?.min_band}
                        </p>
                        <p>
                            <span className="font-semibold"> Max Band: </span>
                            {demandData[0]?.demandroledetails[0]?.max_band}
                        </p>
                        <p>
                            <span className="font-semibold"> Min Exp: </span>
                            {demandData[0]?.demandroledetails[0]?.experienceinyear} Years
                        </p>
                    </div>
                )}
                {demandData[0]?.demandroledetails && (
                    <div>
                        <h1 className="mt-4 font-semibold">Role Summary: </h1>
                        <p className="font-normal">
                            {demandData[0]?.demandroledetails[0]?.rolesummary}
                        </p>
                        <h1 className="mt-4 font-semibold">Role Responsibilities: </h1>
                        <div className="font-normal">
                            {demandData[0]?.demandroledetails[0]?.roleresponsibilities?.map(
                                (responsibility, index) => (
                                    <ul key={index}>
                                        {index + 1}
                                        {'.'} {responsibility?.responsibilities}
                                    </ul>
                                ),
                            )}
                        </div>
                    </div>
                )}
            </ScrollToBottom>
        </div>
    )
}

export default RoleDetails
