import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { ExactFlexible, LessThan, GreaterThan, includenoticeperiod } from 'src/Services/constant/staticValues';
import { AlertError } from 'src/Services';
import MultiSelectItem from 'src/Components/MultiSelect';
import { GET_DEMAND_MATCH } from 'src/Redux/actions/configuration';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';

const Details = (props) => {
  const { usertypelist } = useSelector((state) => state.UserManagementList);
  const { allocationstatuslist } = useSelector((state) => state.EmployeeDetails);
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit, demandmatchconfigurationid } = props;
  const [allocationStatus, setAllocationStatuslist] = useState([]);
  const [checkvalidation, setcheckvalidation] = useState(false);
  const [usertype, setUserType] = useState([]);
  const [includeNoticePeriodText, setIncludeNoticePeriodText] = useState(false);
  const dispatch = useDispatch();
  const { matchList } = useSelector((state) => state.ConfigurationList);

  useEffect(() => {
    dispatch(GET_DEMAND_MATCH({ demandmatchconfigurationid }));
  }, [demandmatchconfigurationid, dispatch]);

  useEffect(() => {
    if (matchList && matchList.length > 0) {
      const initialMatch = matchList[0]; // Get the first item from matchList
      setDetailsData(initialMatch); // Update detailsData with the initial match data
      setAllocationStatuslist(
        initialMatch.includestatus.map((status) => ({
          statusid: status.includestatusid,
          statusname: status.statusname,
        }))
      );
      setIncludeNoticePeriodText(initialMatch.includenoticeperiod ? 'Yes' : 'No');
      setUserType(initialMatch.includeusertype || []); // Update usertype with the initial match's includeusertype
    }
  }, [matchList, setDetailsData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setcheckvalidation(true);
    const totalWeightage = Object.keys(detailsData).reduce((sum, key) => {
      if (key.endsWith('_weightage')) {
        return (sum + (isNaN(Number(detailsData[key])) ? 0 : Number(detailsData[key])));
      }
      return sum;
    }, 0);
    if (totalWeightage !== 100) {
      AlertError('The total weightage must be equal to 100.');
      return; // Exit the function if the total weightage is not equal to 100
    }
    let item = {
      ...detailsData,
      includestatus: allocationStatus.map((status) => ({
        includestatusid: status.statusid,
      })),
      includeusertype: usertype.map((user) => ({
        datusertypeid: user.datusertypeid,
      })),
      includenoticeperiod: detailsData.includenoticeperiod ? true : false,
    };
    let isValidated = CheckObjectValidation(item, ['parametername', 'conditions', 'notes', 'emptype_weightage', 'workforcegroup_weightage', 'serviceline_weightage', 'allocation_weightage', 'workmode_weightage', 'location_weightage', 'minband_weightage', 'maxband_weightage', 'minexp_weightage', 'maxexp_weightage', 'skill_weightage', 'technology_weightage', 'domain_weightage', 'demandmatchconfigurationid', 'includenoticeperiod']);
    if (isValidated?.isvalid) {
      let payload = {
        demandmatchconfigurationjson: [item],
      };
      detailSave(payload);
      setCanEdit(false)
    };
  }

  const handleChangeInput = (name, value) => {
    setcheckvalidation(false);
    if (name === 'includenoticeperiod') {
      const includeNoticePeriodValue = value === 'Yes' ? true : false;
      setDetailsData({ ...detailsData, [name]: includeNoticePeriodValue });
      setIncludeNoticePeriodText(value);
    } else {
      setDetailsData({ ...detailsData, [name]: value });
    }
  };
  const handleStatusSelect = (status) => {
    const isNew = allocationStatus?.some((p) => p?.statusid === status?.statusid);
    if (isNew) {
      const updatedAllocationStatus = allocationStatus?.filter((p) => p?.statusid !== status?.statusid);
      setAllocationStatuslist(updatedAllocationStatus);
    } else {
      setAllocationStatuslist((prevList) => [
        ...prevList,
        { statusid: status?.statusid, statusname: status?.statusname },
      ]);
    }
  };
  const handleUsertypeSelect = (user) => {
    const isNew = usertype?.some((p) => p?.datusertypeid === user?.datusertypeid);
    if (isNew) {
      const updatedusertype = usertype?.filter((p) => p?.datusertypeid !== user?.datusertypeid);
      setUserType(updatedusertype);
    } else {
      setUserType((prevList) => [
        ...prevList,
        { datusertypeid: user?.datusertypeid, usertype: user?.usertype },
      ]);
    }
  };
  return (
    <div className='w-full text-sm px-2'>
      <div className='grid grid-cols-4 gap-4 my-4'>
        <div className='w-3/4'>
          <label htmlFor='usertype' className='block capitalize font-[500] mb-1'>
            Include User Type
            <span className='text-red-300'> *</span>
          </label>
          <div
            className={`border rounded ${!canEdit ? 'bg-gray-50' : ''} w-full`}>
            <MultiSelectItem
              disabled={canEdit}
              itemIdKey='datusertypeid'
              labelKeyname='usertype'
              limitShow={1}
              selectedOptions={usertype}
              options={usertypelist}
              handleSelection={handleUsertypeSelect}
            />
          </div>
          {!usertype && checkvalidation && (
            <small className='text-red-400'>User Type is required!</small>
          )}
        </div>
        <div className='w-3/4'>
          <label
            htmlFor='statusid' className='block capitalize font-[500] mb-1'>
            Include Status
            <span className='text-red-300'> *</span>

          </label>
          <div className={`border rounded ${!canEdit ? 'bg-gray-50' : ''} w-full`}>
            <MultiSelectItem
              disabled={canEdit}
              itemIdKey='statusid'
              labelKeyname='statusname'
              limitShow={1}
              selectedOptions={allocationStatus}
              options={allocationstatuslist}
              handleSelection={handleStatusSelect}
            />
          </div>
          {!allocationStatus && checkvalidation && (
            <small className='text-red-400'>Status is required!</small>
          )}
        </div>
        <div className='w-3/4'>
          <label htmlFor='includenoticeperiod' className='block font-[500] mb-1'>
            Include Notice Period
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={includenoticeperiod || []}
            value={includeNoticePeriodText}
            noDataLabel='No Notice Period'
            name='includenoticeperiod'
            labelkey='name'
            className='border rounded bg-gray-50 py-1.5 w-100'
            onChange={handleChangeInput}
            id='name'
          />

        </div>
      </div>
      <div className='grid grid-cols-4 gap-2 mt-4'>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='emptype_usage' className='block font-[500] mb-1'>
              Emp Type
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              options={ExactFlexible || []}
              value={detailsData?.emptype_usage || ''}
              disabled={canEdit}
              noDataLabel='No Employee Type'
              name='emptype_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              id='name'
              onChange={handleChangeInput}
            />
            {!detailsData?.emptype_usage && checkvalidation && (
              <small className='text-red-400'>Employee Type is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='emptype_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='emptype_weightage'
              id='emptype_weightage'
              value={detailsData?.emptype_weightage}
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              onChange={(e) =>
                handleChangeInput('emptype_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='workforcegroup_usage' className='block font-[500] mb-1'>
              Workforce Group
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.workforcegroup_usage || ''}
              noDataLabel='No Workforce Group'
              name='workforcegroup_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.workforcegroup_usage && checkvalidation && (
              <small className='text-red-400'>Work Force Group is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label
              htmlFor='workforcegroup_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='workforcegroup_weightage'
              id='workforcegroup_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.workforcegroup_weightage}
              onChange={(e) =>
                handleChangeInput('workforcegroup_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label
              htmlFor='serviceline_usage' className='block font-[500] mb-1'>
              Service Line/ Dept
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.serviceline_usage || ''}
              noDataLabel='No Service Line Type'
              name='serviceline_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.serviceline_usage && checkvalidation && (
              <small className='text-red-400'>Service Line is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label
              htmlFor='serviceline_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='serviceline_weightage'
              id='serviceline_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.serviceline_weightage}
              onChange={(e) =>
                handleChangeInput('serviceline_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='allocation_usage' className='block font-[500] mb-1'>
              Allocation
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={LessThan || []}
              value={detailsData?.allocation_usage || ''}
              noDataLabel='No Allocation'
              name='allocation_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.allocation_usage && checkvalidation && (
              <small className='text-red-400'>Allocation is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label
              htmlFor='allocation_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='allocation_weightage'
              id='allocation_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.allocation_weightage}
              onChange={(e) =>
                handleChangeInput('allocation_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='workmode_usage' className='block font-[500] mb-1'>
              Work Mode
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.workmode_usage || ''}
              noDataLabel='No Workmode Type'
              name='workmode_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.workmode_usage && checkvalidation && (
              <small className='text-red-400'>Work Mode is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label
              htmlFor='workmode_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='workmode_weightage'
              id='workmode_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.workmode_weightage}
              onChange={(e) =>
                handleChangeInput('workmode_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='location_usage' className='block font-[500] mb-1'>
              Location
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.location_usage || ''}
              noDataLabel='No Location Type'
              name='location_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.location_usage && checkvalidation && (
              <small className='text-red-400'>Location is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='location_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='location_weightage'
              id='location_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.location_weightage}
              onChange={(e) =>
                handleChangeInput('location_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center gap-2 mb-2'>
          <div className='w-3/4'>
            <label htmlFor='skill_usage' className='block font-[500] mb-1'>
              Skill
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.skill_usage || ''}
              noDataLabel='No Skill Type'
              name='skill_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.skill_usage && checkvalidation && (
              <small className='text-red-400'>Skill is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='skill_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='skill_weightage'
              id='skill_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.skill_weightage}
              onChange={(e) =>
                handleChangeInput('skill_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center gap-2 mb-2'>
          <div className='w-3/4'>
            <label htmlFor='technology_usage' className='block font-[500] mb-1'>
              Technology
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.technology_usage || ''}
              noDataLabel='No Technology '
              name='technology_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.technology_usage && checkvalidation && (
              <small className='text-red-400'>Technology is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='technology_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='technology_weightage'
              id='technology_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.technology_weightage}
              onChange={(e) =>
                handleChangeInput('technology_weightage', e.target.value)
              }
            />
          </div>
        </div>

        <div className='flex items-center gap-2 mb-2'>
          <div className='w-3/4'>
            <label htmlFor='domain_usage' className='block font-[500] mb-1'>
              Domain
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={ExactFlexible || []}
              value={detailsData?.domain_usage || ''}
              noDataLabel='No Domain Type'
              name='domain_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.domain_usage && checkvalidation && (
              <small className='text-red-400'>Domain is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='domain_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='domain_weightage'
              id='domain_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.domain_weightage}
              onChange={(e) =>
                handleChangeInput('domain_weightage', e.target.value)
              }
            />
          </div>
          <div />
        </div>
        <div className='flex items-center gap-2 mb-2'>
          <div className='w-3/4'>
            <label htmlFor='minexp_usage' className='block font-[500] mb-1'>
              Min Exp
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={LessThan || []}
              value={detailsData?.minexp_usage || ''}
              noDataLabel='No Min Exp'
              name='minexp_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.minexp_usage && checkvalidation && (
              <small className='text-red-400'>Min Exp is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='minexp_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='minexp_weightage'
              id='minexp_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.minexp_weightage}
              onChange={(e) =>
                handleChangeInput('minexp_weightage', e.target.value)
              }
            />
          </div>
        </div>{' '}
        <div className='flex items-center gap-2 mb-2'>
          <div className='w-3/4'>
            <label htmlFor='maxexp_usage' className='block font-[500] mb-1'>
              Max Exp
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={GreaterThan || []}
              value={detailsData?.maxexp_usage || ''}
              noDataLabel='No Max Exp'
              name='maxexp_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.maxexp_usage && checkvalidation && (
              <small className='text-red-400'>Max Exp is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='maxexp_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='maxexp_weightage'
              id='maxexp_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.maxexp_weightage}
              onChange={(e) =>
                handleChangeInput('maxexp_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='minband_usage' className='block font-[500] mb-1'>
              Min Band
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={LessThan || []}
              value={detailsData?.minband_usage || ''}
              noDataLabel='No Min Band'
              name='minband_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.minband_usage && checkvalidation && (
              <small className='text-red-400'>Min Band is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='minband_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='minband_weightage'
              id='minband_weightage'
              min={0} // Minimum value set to mindailylimit or 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              value={detailsData?.minband_weightage}
              onChange={(e) =>
                handleChangeInput('minband_weightage', e.target.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center justify-start space-x-2 mb-4 w-full'>
          <div className=' w-3/4'>
            <label htmlFor='maxband_usage' className='block font-[500] mb-1'>
              Max Band
              <span className='text-red-300'> *</span>
            </label>
            <CustomhtmlSelect
              disabled={canEdit}
              options={GreaterThan || []}
              value={detailsData?.maxband_usage || ''}
              noDataLabel='No Max Band'
              name='maxband_usage'
              labelkey='name'
              className='border rounded bg-gray-50 py-2 w-full'
              onChange={handleChangeInput}
              id='name'
            />
            {!detailsData?.maxband_usage && checkvalidation && (
              <small className='text-red-400'>Max Band Usage is required!</small>
            )}
          </div>
          <div className='w-[80px]'>
            <label htmlFor='maxband_weightage' className='block font-[500] mb-1'>
              Weight
              <span className='text-red-300'> *</span>
            </label>
            <input
              disabled={canEdit}
              type='number'
              title='maxband_weightage'
              id='maxband_weightage'
              min={0} // Minimum value set to 0
              max={100}
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block p-2 w-3/4'
              placeholder='No.'
              required=''
              value={detailsData?.maxband_weightage}
              onChange={(e) =>
                handleChangeInput('maxband_weightage', e.target.value)
              }
            />
          </div>
        </div>

        <div />
        <div />
        <div className='flex justify-end items-center space-x-3 pr-4'>
          {!canEdit && (
            <>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='border bg-white text-primary border-primary rounded py-1 h-9 w-1/4'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1 h-9 w-1/4'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(Details);