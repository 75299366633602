import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PrimaryButton from "src/Components/Buttons/PrimaryButton"
import CustomhtmlSelect from "src/Components/CustomhtmlSelect"
import { GET_EMPLOYEE_SUMMARY, GET_LEVEL_MASTER, GET_SKILL_MASTER, INSERT_SKILL } from "src/Redux/actions/workforce/eprofile"
import { AlertError } from "src/Services"
import { getDeafultProficiencyData, getDeafultSkillData } from "src/Services/constant/defaultPayload"

const UserPrimarySkill = ({ canEdit, setCanEdit, UserId }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state?.EmployeeProfile)
    const { userSkillList, proficiencylist, userSkillsDetail, employeeSummary } = state
    const [skillmasterid, setSkillmaster] = useState(null)
    const [proficency, setproficency] = useState(null)
    const [primarySkill, setPrimarySkill] = useState(null)

    useEffect(() => {
        dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData()))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(GET_EMPLOYEE_SUMMARY({ COMUserId: UserId }))
        // eslint-disable-next-line
    }, [UserId])

    useEffect(() => {
        if (employeeSummary?.length > 0) {
            dispatch(GET_SKILL_MASTER({ ...getDeafultSkillData(), skillareamasterid: employeeSummary[0]?.skillareamasterid }))
        }
        // eslint-disable-next-line
    }, [employeeSummary])

    useEffect(() => {
        if (userSkillsDetail) {
            let it = userSkillsDetail?.find(it => it?.isprimary === true)
            setSkillmaster(it?.skillmasterid)
            setproficency(it?.proficiencyid)
            setPrimarySkill(it)
        }
        // eslint-disable-next-line 
    }, [userSkillsDetail])

    const getSkillMaster = (keyname, value) => {
        setSkillmaster(value)
    }

    const getProficency = (keyname, value) => {
        setproficency(value)
    }

    const handleCancel = () => {
        setCanEdit(false)
    }

    const saveSkills = async () => {
        if (!proficency) {
            AlertError(`Proficiency is required! `)
            return
        } else if (!skillmasterid) {
            AlertError(`Skill is required! `)
            return
        }
        let otherskill = userSkillsDetail?.filter((it => it?.isprimary === false)) || []
        let payload = [
            ...otherskill, {
                comtenantid: +localStorage.getItem('Tenantid'),
                comuserid: UserId || null,
                comuserskilltechnologyid: primarySkill?.comuserskilltechnologyid || null,
                createdcomuserid: UserId || null,
                createduser: UserId || null,
                domainid: primarySkill?.domainid || null,
                experienceinmonths: primarySkill?.experienceinmonths || null,
                expertise: primarySkill?.expertise || null,
                isactiveflag: true,
                isprimary: true,
                lastusedyear: primarySkill?.lastusedyear || null,
                notes: primarySkill?.notes || null,
                remarks: primarySkill?.remarks || null,
                skillcode: primarySkill?.skillcode || null,
                skilltype: primarySkill?.skilltype || null,
                technologymasterid: primarySkill?.technologymasterid || null,
                techversion: primarySkill?.techversion || null,
                proficiencyid: proficency,
                skillmasterid
            }]
        let res = await dispatch(INSERT_SKILL(payload))
        if (res !== null) {
            // await dispatch(GET_SKILLS_EMPLOYEE())
            setCanEdit(false)
        }
    }

    return (
        <div className='w-full h-[65vh] text-sm mb-10'>
            <div className='grid grid-cols-4 gap-5 mt-4'>
                <div>
                    <label htmlFor='skillprimary' className='block font-[500] mb-2'>
                        Primary Skill
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={canEdit}
                        options={userSkillList || []}
                        value={skillmasterid}
                        noDataLabel='No Skill Area'
                        name='skillmasterid'
                        labelkey='skill'
                        className='border rounded bg-gray-50 py-2'
                        onChange={getSkillMaster}
                        id='skillmasterid'
                    />
                </div>
                <div>
                    <label htmlFor='Proficiency' className='block font-[500] mb-2'>
                        Proficiency
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={canEdit}
                        options={proficiencylist || []}
                        value={proficency}
                        noDataLabel='No Proficiency'
                        name='levelmasterid'
                        labelkey='levelname'
                        className='border rounded bg-gray-50 py-2'
                        onChange={getProficency}
                        id='levelmasterid'
                    />
                </div>
            </div>
            <div className='fixed bottom-0 right-6 mt-4'>
                {!canEdit && (
                    <div className='fixed bottom-0 right-6 mt-2'>
                        <div className='gap-2 flex justify-end items-center'>
                            <PrimaryButton
                                onClick={handleCancel}
                                className='px-6 border bg-white border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
                            >
                                Cancel
                            </PrimaryButton>
                            <PrimaryButton
                                disabled={canEdit}
                                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                                onClick={saveSkills}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserPrimarySkill