import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { statuslist } from 'src/Services/constant/staticValues';

const SkillDetails = (props) => {
  const { skillList } = useSelector((state) => state.EmployeeProfile);
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit, skillmasterid } = props;

  const handleSubmit = () => {
    let item = {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      skillmasterid: skillmasterid,
      skill: detailsData?.skill,
      skillarea: detailsData?.skillareamasterid,
      skilltype: detailsData?.skilltype,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: '',
    };
    let isValidated = CheckObjectValidation(detailsData, [
      'notes',
      'modifiedtimestamp',
      'modifiedcomuserid',
      'createdcomuserid',
    ]);
    if (isValidated?.isvalid) {
      let payload = {
        skilljson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='skillcode' className='block font-[500] mb-2'>
            Skill Code
          </label>
          <input
            disabled
            maxLength={50}
            type='text'
            title='skillcode'
            id='skillcode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Skill Code'
            required=''
            value={detailsData?.skillcode || ''}
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('skillcode', e.target.value);
              }
            }}
          />
        </div>
        <div>
          <label htmlFor='skill' className='block font-[500] mb-2'>
            Skill
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='skill'
            id='skill'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='skill'
            required=''
            onChange={(e) => handleChangeInput('skill', e.target.value)}
            value={detailsData?.skill || ''}
            maxLength={50}
          />
        </div>
        <div>
          <label htmlFor='skillarea' className='block font-[500] mb-2'>
            Skill Area
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={skillList || []}
            value={detailsData?.skillareamasterid}
            noDataLabel='No skillarea'
            name='skillareamasterid'
            labelkey='skillarea'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            onChange={handleChangeInput}
            id='skillareamasterid'
          />
        </div>
        <div>
          <label htmlFor='skilltype' className='block font-[500] mb-2'>
            Skill Type
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={statuslist || []}
            value={detailsData?.skilltype}
            noDataLabel='No skilltype found'
            name='skilltype'
            labelkey='skilltype'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            onChange={handleChangeInput}
            id='skilltype'
          />
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SkillDetails);
