import { Link } from "react-router-dom";
import { NextIcon } from "src/Assets/icons";
import SelectProjects from "src/Components/SelectProjects";

const Actionheader = ({ hooks }) => {
    const { selectProject, project, projectsOfM } = hooks

    return (
        <div className="flex justify-start items-center space-x-4">
            <div className=" bg-white rounded-lg">
                <SelectProjects Options={projectsOfM}
                    setSelected={selectProject} selected={project} />
            </div>
            <Link to={`/app/projects/staffing?projectid=${project?.projectid}`} className=" cursor-pointer flex justify-start items-center space-x-1">
                <p className="text-primary hover:font-[500] text-sm">Staffing</p>
                <span className="text-secondary h-5 w-5">{NextIcon}</span>
            </Link>
            <Link to='/app/projects/timesheet?viewMode=Manager' className=" cursor-pointer flex justify-start items-center space-x-1">
                <p className="text-primary hover:font-[500] text-sm">Effort</p>
                <span className="text-secondary h-5 w-5">{NextIcon}</span>
            </Link>
        </div>
    )
}

export default Actionheader;