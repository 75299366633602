import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'

const BarchartDashboard = ({ allocationstatuslist }) => {
    const data = allocationstatuslist?.map((status) => ({
        name: status.statusname,
        Internal: Math.floor(Math.random() * 5000),
        Project: Math.floor(Math.random() * 10000),
        Opportunity: Math.floor(Math.random() * 3000),
    }))

    return (
        <div className="sm:h-[50dvh] md:h-[50dvh]">
            <ResponsiveContainer  debounce={300} width="100%" height="100%">
                <BarChart
                    width={400}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        angle={-15}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Project" stackId="a" fill="#8884d8" />
                    <Bar dataKey="Internal" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="Opportunity" stackId="a" fill="#57779b" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default BarchartDashboard
