import { memo, useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { detailNotRequired } from './defaultPayload'

const Details = (props) => {
    const { detailSave, detailsData, setDetailsData } = props
    const [checkvalidation, setCheckvalidation] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setCheckvalidation(true)

        let isValidated = CheckObjectValidation(detailsData, detailNotRequired)
        if (isValidated?.isvalid) {
            let payload = {
                workertypejson: [detailsData],
            }
            detailSave(payload)
        }
    }

    const handleChangeInput = (name, value) => {
        setCheckvalidation(false)
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label htmlFor="workertype" className="block font-[500] mb-2">
                        Worker Type
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        type="text"
                        title="workertype"
                        id="workertype"
                        className="block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 "
                        placeholder="Enter workertype Name"
                        required=""
                        onChange={(e) => {
                            handleChangeInput('workertype', e.target.value)
                        }}
                    />
                    {!detailsData?.workertype && checkvalidation && (
                        <small className="text-red-400"> workertype is required!</small>
                    )}
                </div>
            </div>
            <div className="fixed bottom-0 mt-2 right-6">
                <div className="flex items-center justify-end gap-2">
                    <PrimaryButton className="px-8 py-2 mb-4 text-white rounded-lg bg-primary" onClick={handleSubmit}>
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default memo(Details)
