const getTenRegionId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comregionid: 0,
    }
}
const getTenCompanyId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        companyid: 0,
    }
}

const getRoleByCustomerId = (customerid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customerid,
        custroleid: 0,
        isactive: true,
    }
}

const getTenIndustryId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        industryid: 0,
    }
}

const getCustomerListPayload = (pageno = 1, limit = 10, sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customername: '',
        cityId: 0,
        stateId: 0,
        countryid: 0,
        companyid: 0,
        accountid: 0,
        industryid: 0,
        regionid: 0,
        limit,
        pageno,
        sorttype,
        statusname: 'ACTIVE',
    }
}
const getCustomerMasterListPayload = (pageno, limit, sorttype = 1) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        customername: '',
        cityId: 0,
        stateId: 0,
        countryid: 0,
        companyid: 0,
        accountid: 0,
        industryid: 0,
        regionid: 0,
        limit,
        pageno: 1,
        sorttype,
        statusname: 'ACTIVE',
    }
}

const getCompanyListPayload = () => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        companyid: 0,
    }
}

const getCustomerRoleListPayload = (page, limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        customergroupid: 0,
        page,
        limit,
        sorttype,
        status: 'ACTIVE',
        searchname: '',
    }
}

const getCustomerDocument = (customerid = 0) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        customerid,
    }
}

const getCustomerRoleListMasterPayload = (page, limit, sorttype = 1) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        customergroupid: 0,
        page,
        limit,
        sorttype,
        status: 'ACTIVE',
    }
}

const getDeliveryRoleListPayload = (limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        deliveryroleid: null,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

const getIndustryListPayload = (limit = 10, sorttype = 3) => {
    return {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        industryid: null,
        limit,
        page: 1,
        sorttype,
        searchname: '',
        status: 'ACTIVE',
    }
}

export {
    getCustomerDocument,
    getIndustryListPayload,
    getCustomerMasterListPayload,
    getCustomerRoleListMasterPayload,
    getTenRegionId,
    getTenCompanyId,
    getTenIndustryId,
    getCustomerListPayload,
    getRoleByCustomerId,
    getDeliveryRoleListPayload,
    getCompanyListPayload,
    getCustomerRoleListPayload,
}
