import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import {
    GET_BUSINESS_MASTER,
    GET_LIST_OF_STATUS_BY_E,
    GET_SERVICELINE_MASTER,
    GET_USER_TYPE_LIST,
} from 'src/Redux/actions/masterdata/user'
import { GET_CURRENCY_MASTER_LIST, GET_OPPORTUNITY_LIST, GET_PROJECTS } from 'src/Redux/actions/projects'
import { GET_WORK_MODE_LIST } from 'src/Redux/actions/workforce/demands'
import {
    GET_CITY_MASTER,
    GET_COMPANY_MASTER,
    GET_DEPARTMENT_MASTER,
    GET_WORKERTYPE_MASTER,
    GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import {
    getBusinessUnitData,
    getDefaultCityPayload,
    getDefaultCompanyPayload,
    getDefaultCurrencyPayload,
    getDefaultOpportunityPayload,
    getDefaultProjectPayload,
    getDefaultServiceLinePayload,
    getDepartmentMasterIdData,
    getTenantWorkForceyIdData,
    getUserTypeIdData,
    getWorkModePayload,
    getWorkTypePayload,
} from 'src/Services/constant/defaultPayload'
import SelectCompany from './company'
import SelectDepartment from './department'
import SelectLocation from './location'
import SelectOpportunity from './opportunity'
import SelectProject from './project'
const cycleList = [
    { id: 3, cycle: 'Daily' },
    { id: 4, cycle: 'Hourly' },
    { id: 1, cycle: 'Monthly' },
    { id: 2, cycle: 'Weekly' },
]
const requestTypeslist = [
    { requesttype: 'Internal', id: 'I' },
    { requesttype: 'Opportunity', id: 'O' },
    { requesttype: 'Project', id: 'P' },
]
const prioritiesList = [
    { priority: 'Low', id: 'L' },
    { priority: 'Medium', id: 'M' },
    { priority: 'High', id: 'H' },
]

const Details = ({ hooks }) => {
    const dispatch = useDispatch()
    const { currencyList } = useSelector((state) => state?.Projects)
    const { companylist } = useSelector((state) => state?.EmployeeDetails)
    const { demandData, detailSave } = hooks
    const { businessList, serviceList } = useSelector((state) => state?.UserManagementList)
    const { workertypelist, departmentlist, citylist, workforcelist } = useSelector((state) => state?.EmployeeDetails)
    const { workmodelist } = useSelector((state) => state?.DemandDetailsList)
    const { listOfOpportunity, projectList } = useSelector((state) => state?.Projects)
    const [checkValidation, setCheckValidation] = useState(false)
    const [payload, setPayload] = useState(demandData[0])
    const [opportunity, setOpportunity] = useState({})
    const [department, setDepartment] = useState({})
    const [project, setProject] = useState({})
    const [location, setLocation] = useState({})
    const [accountSelect, setAccountSelect] = useState({})

    useEffect(() => {
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_COMPANY_MASTER(getDefaultCompanyPayload()))
        dispatch(GET_BUSINESS_MASTER(getBusinessUnitData()))
        dispatch(GET_CITY_MASTER(getDefaultCityPayload()))
        dispatch(GET_SERVICELINE_MASTER(getDefaultServiceLinePayload()))
        dispatch(GET_WORKERTYPE_MASTER(getWorkTypePayload()))
        dispatch(GET_WORK_MODE_LIST(getWorkModePayload()))
        dispatch(GET_OPPORTUNITY_LIST(getDefaultOpportunityPayload()))
        dispatch(GET_PROJECTS(getDefaultProjectPayload()))
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()))
        dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()))
        dispatch(GET_LIST_OF_STATUS_BY_E())
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
    }, [dispatch])

    const getTodayDate = () => {
        const today = new Date()
        const year = today.getFullYear()
        let month = today.getMonth() + 1
        let day = today.getDate()
        // Ensure month and day have two digits
        month = month < 10 ? `0${month}` : month
        day = day < 10 ? `0${day}` : day
        return `${year}-${month}-${day}`
    }

    const handleDates = (e) => {
        let { name, value } = e.target
        setPayload((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }))
    }

    useMemo(
        () => {
            setLocation({
                comcityid: payload?.cityid,
                cityname: payload?.cityname,
            })
            setAccountSelect({
                comuserid: payload?.userid,
                firstname: payload?.demandusername?.trim() || '',
            })
            if (demandData[0]?.requesttype === 'O') {
                setOpportunity({
                    opportunityname: payload?.opportunityname,
                })
            }
            if (demandData[0]?.requesttype === 'I') {
                setDepartment({
                    department: payload?.department,
                })
            }
            if (demandData[0]?.requesttype === 'P') {
                setProject({
                    projectname: payload?.projectname,
                })
            }
        }, // eslint-disable-next-line
        [payload],
    )

    const handleSubmit = async (e) => {
        e.preventDefault()
        setCheckValidation(true)
        let validationdata = {}
        let requiredInDetail = [
            'cityid',
            'workertypeid',
            'workforcegroupid',
            'companyid',
            'requesttype',
            'startdate',
            'workmodeid',
            'priority',
            'is_billable',
            'comtenantid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'peopleno',
        ]
        if (payload?.requesttype === 'P') {
            requiredInDetail.push('projectid')
        } else if (payload?.requesttype === 'I') {
            requiredInDetail.push('departmentid')
        } else if (payload?.requesttype === 'O') {
            requiredInDetail.push('opportunityid')
        }

        for (let value of requiredInDetail) {
            validationdata[value] = payload[value]
        }
        validationdata['cityid'] = location?.comcityid
        validationdata['opportunityid'] = opportunity?.opportunityid
        validationdata['departmentid'] = department?.departmentid
        validationdata['projectid'] = project?.projectid
        validationdata['userid'] = accountSelect?.comuserid
        let optionalkey = [
            'demandid',
            'demandcode',
            'cityid',
            'demandmatchid',
            'demandstatusid',
            'departmentid',
            'opportunityid',
            'projectid',
            'billrate',
            'datcomcurrencyid',
            'billingunit',
            'locationid',
            'assignedto',
            'assigneddate',
            'demandstatusid',
            'notes',
        ]

        let validation = CheckObjectValidation(validationdata, optionalkey, false)
        if (validation?.isvalid) {
            return
        } else {
            let apiData = {
                ...payload,
                allocation: (Number(payload?.allocation || 0) / 100).toFixed(1),
                peopleno: Number(payload?.peopleno) || 1,
                billrate: Number(payload?.billrate),
                projectid: project?.projectid || payload?.projectid,
                opportunityid: opportunity?.opportunityid || payload?.opportunityid,
                departmentid: department?.departmentid || payload?.departmentid,
                cityid: location.comcityid,
                userid: accountSelect?.comuserid || payload?.userid,
                demandmatchid: payload?.demandmatchid,
            }
            setCheckValidation(false)
            let finalPayload = {
                demandmasterjson: [apiData],
            }
            detailSave(finalPayload)
        }
    }

    const handleSelectTab = (name, value) => {
        setPayload({ ...payload, [name]: value })
    }

    const handleChangeInput = (name, value) => {
        setPayload({ ...payload, [name]: value })
    }

    const selectLocationFlag = (option) => {
        setPayload({ ...payload, isonsite: option === 'Onsite' ? true : false })
    }

    const setBillingType = (option) => {
        setPayload({ ...payload, is_billable: option === 'Billable' ? true : false })
    }

    return (
        <div className="mt-4 w-full h-[70vh]  text-sm">
            <div className="mt-4 w-full h-[70vh]  text-sm">
                <div className="grid grid-cols-4 gap-8">
                    <div>
                        <label htmlFor="demandcode" className="block font-[500] mb-2">
                            Demand Code <span className="text-red-300">*</span>
                        </label>
                        <input
                            disabled
                            type="text"
                            title="demandcode"
                            id="demandcode"
                            className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            placeholder="Demand Code"
                            required=""
                            value={payload?.demandcode || ''}
                        />
                    </div>
                    {
                        <div>
                            <SelectCompany
                                diabled={true}
                                listOfCompany={companylist}
                                value={payload?.companyid}
                                handleChangeValue={handleChangeInput}
                            />
                            {!payload?.companyid && checkValidation && (
                                <small className="text-red-400">field is required!</small>
                            )}
                        </div>
                    }
                    <div>
                        <label htmlFor="requestType" className="block font-[500] mb-2">
                            {' '}
                            Request Type <span className="text-red-300">*</span>
                        </label>
                        <FilterButtonTabObject
                            disabled={true}
                            className="py-2 border"
                            tabs={requestTypeslist || []}
                            selected={payload?.requesttype}
                            labelkey="requesttype"
                            name="requesttype"
                            valuekey="id"
                            onClick={handleSelectTab}
                        />
                        {!payload?.requesttype && checkValidation && (
                            <small className="text-red-400">field is required!</small>
                        )}
                    </div>
                    {payload?.requesttype === 'I' && (
                        <div>
                            <SelectDepartment
                                disabled={true}
                                department={department}
                                handleSelectDepartment={setDepartment}
                                departmentlist={departmentlist}
                            />
                        </div>
                    )}
                    {payload?.requesttype === 'P' && (
                        <div>
                            <SelectProject
                                disabled={true}
                                project={project}
                                handleSelectProject={setProject}
                                projectList={projectList}
                            />
                        </div>
                    )}
                    {payload?.requesttype === 'O' && (
                        <div>
                            <SelectOpportunity
                                disabled={true}
                                opportunity={opportunity}
                                handleSelectOpportunity={setOpportunity}
                                listOfOpportunity={listOfOpportunity}
                            />
                        </div>
                    )}
                    <div>
                        <label htmlFor="workertype" className="block font-[500] mb-2">
                            {' '}
                            Employee Type <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={workertypelist || []}
                            value={payload?.workertypeid || ''}
                            noDataLabel="No workertype"
                            name="workertypeid"
                            labelkey="workertype"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="workertypeid"
                        />
                        {!payload?.workertypeid && checkValidation && (
                            <small className="text-red-400">field is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="workforcegroup" className="block font-[500] mb-2">
                            Workforce group <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={workforcelist || []}
                            value={payload?.workforcegroupid || ''}
                            noDataLabel="No workertype"
                            name="workforcegroupid"
                            labelkey="workforcegroup"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="wfgid"
                        />
                        {!payload?.workforcegroupid && checkValidation && (
                            <small className="text-red-400">workforcegroup is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="noofpeople" className="block font-[500] mb-2">
                            {' '}
                            Number Of People <span className="text-red-300">*</span>
                        </label>
                        <div className="relative">
                            <input
                                type="number"
                                name="peopleno"
                                className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                                placeholder="peopleno"
                                required=""
                                value={payload?.peopleno || ''}
                                onChange={(e) => handleChangeInput('peopleno', e.target.value)}
                                disabled={payload?.requesttype === 'P'}
                            />
                            {!payload?.peopleno && checkValidation && (
                                <small className="text-red-400">field is required!</small>
                            )}
                        </div>
                    </div>
                    {payload?.requesttype === 'O' && (
                        <div>
                            <label htmlFor="BusinessUnit" className="block font-[500] mb-2">
                                Business Unit <span className="text-red-300">*</span>
                            </label>
                            <CustomhtmlSelect
                                disabled={true}
                                options={businessList || []}
                                value={payload?.businessunitid || ''}
                                noDataLabel="No business unit"
                                name="businessunitid"
                                labelkey="businessunitname"
                                className="p-2 border rounded-lg bg-gray-50"
                                onChange={handleChangeInput}
                                id="businessunitid"
                            />
                            {!payload?.businessunitid && !payload?.requesttype === 'I' && checkValidation && (
                                <small className="text-red-400">field is required!</small>
                            )}
                        </div>
                    )}
                    {payload?.requesttype === 'O' && (
                        <div>
                            <label htmlFor="ServiceLine" className="block font-[500] mb-2">
                                Service Line <span className="text-red-300">*</span>
                            </label>
                            <CustomhtmlSelect
                                disabled={true}
                                options={serviceList || []}
                                value={payload?.servicelineid || ''}
                                noDataLabel="No service"
                                name="servicelineid"
                                labelkey="servicelinename"
                                className="py-2 border rounded bg-gray-50"
                                onChange={handleChangeInput}
                                id="servicelineid"
                            />
                            {!payload?.servicelineid && !payload?.requesttype === 'I' && checkValidation && (
                                <small className="text-red-400">field is required!</small>
                            )}
                        </div>
                    )}
                    <div>
                        <h1 className="mb-2 font-[500]">
                            Status <span className="text-red-300">*</span>
                        </h1>
                        <div>
                            <div className="border rounded bg-gray-50 p-2  text-textSecondary font-[500]">
                                {payload?.demandstatusname || 'N/A'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="startdate" className="block font-[500] mb-2">
                            Start Date <span className="text-red-300">*</span>
                        </label>
                        <FlatPickrDateInput
                            name="startdate"
                            id="startdate"
                            min={getTodayDate()}
                            value={payload?.startdate || ''}
                            className="w-full p-2 uppercase border rounded-md bg-gray-50 focus:outline-none hover:outline-none"
                            onChangeDate={handleDates}
                        />
                    </div>
                    <div>
                        <label htmlFor="billingType" className="block font-[500] mb-2">
                            {' '}
                            Billing Type <span className="text-red-300">*</span>
                        </label>
                        <FilterButtonTab
                            className="p-2 border w-full"
                            tabs={['Billable', 'Non-billable']}
                            setSelectedtab={setBillingType}
                            selectedTab={payload?.is_billable && payload?.requesttype === 'O' && payload?.requesttype === 'P' ? 'Billable' : 'Non-billable' || null}
                        />
                    </div>
                    <>
                        {payload?.is_billable && payload?.requesttype !== 'I' && (
                            <div>
                                <label htmlFor="billrate" className="block font-[500] mb-2">
                                    {' '}
                                    Billing Rate <span className="text-red-300">*</span>
                                </label>
                                <div className="flex items-center justify-between w-full p-2 mt-1 bg-white border rounded-lg ">
                                    <input
                                        disabled={!payload?.is_billable}
                                        required=""
                                        placeholder="billrate"
                                        type="number"
                                        className="w-full capitalize focus:outline-none hover:outline-none"
                                        value={payload?.billrate || ''}
                                        onChange={(e) => handleChangeInput('billrate', e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                        {payload?.is_billable && payload?.requesttype !== 'I' && (
                            <div>
                                <label htmlFor="billingunit" className="block capitalize mb-2  font-[500]">
                                    {' '}
                                    Billing Unit <span className="text-red-300">*</span>
                                </label>
                                <CustomhtmlSelect
                                    disabled={!payload?.is_billable}
                                    options={cycleList || []}
                                    noDataLabel="No cycle"
                                    value={payload?.billingunit || ''}
                                    name="billingunit"
                                    labelkey="cycle"
                                    className="py-2 border rounded-lg bg-gray-50"
                                    onChange={handleChangeInput}
                                    id="id"
                                />
                            </div>
                        )}
                        {payload?.is_billable && payload?.requesttype !== 'I' && (
                            <div>
                                <label htmlFor="billingCurrency" className="block capitalize mb-2  font-[500]">
                                    {' '}
                                    Billing Currency <span className="text-red-300">*</span>
                                </label>
                                <CustomhtmlSelect
                                    disabled={!payload?.is_billable}
                                    options={currencyList || []}
                                    noDataLabel="No Currency Type"
                                    value={payload?.datcomcurrencyid || ''}
                                    name="datcomcurrencyid"
                                    labelkey="currency"
                                    className="py-2 border rounded-lg bg-gray-50"
                                    onChange={handleChangeInput}
                                    id="datcomcurrencyid"
                                />
                            </div>
                        )}
                    </>
                    {payload?.requesttype !== 'I' && payload?.is_billable && (
                        <div>
                            <label htmlFor="allocation" className="block font-[500] mb-2">
                                {' '}
                                Allocation (%) <span className="text-red-300">*</span>
                            </label>
                            <div className="relative">
                                <input
                                    onChange={(e) => {
                                        if (
                                            /^[0-9]*$/.test(e.target?.value) &&
                                            Number(e.target.value) >= 0 &&
                                            Number(e.target.value) <= 100
                                        ) {
                                            handleChangeInput('allocation', e.target.value)
                                        }
                                    }}
                                    name="Allocation"
                                    className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                                    placeholder="Allocation"
                                    required=""
                                    value={payload?.allocation || ''}
                                />
                                {!payload?.allocation && checkValidation && (
                                    <small className="text-red-400">field is required!</small>
                                )}
                            </div>
                        </div>
                    )}
                    <div>
                        <label htmlFor="Priority" className="block font-[500] mb-2">
                            {' '}
                            Priority <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={prioritiesList || []}
                            value={payload?.priority || ''}
                            noDataLabel="No priority"
                            name="priority"
                            labelkey="priority"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="id"
                        />
                        {!payload?.priority && checkValidation && (
                            <small className="text-red-400">field is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="location" className="block font-[500] mb-2">
                            {' '}
                            Work Mode <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            options={workmodelist || []}
                            value={payload?.workmodeid || ''}
                            noDataLabel="No workmode"
                            name="workmodeid"
                            labelkey="workmode"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="workmodeid"
                        />
                        {!payload?.workmodeid && checkValidation && (
                            <small className="text-red-400">field is required!</small>
                        )}
                    </div>
                    {payload?.requesttype !== 'I' && payload?.is_billable && (
                        <div>
                            <label htmlFor="locationflag" className="block font-[500] mb-2">
                                {' '}
                                Location Flag <span className="text-red-300">*</span>
                            </label>
                            <FilterButtonTab
                                className="p-2 border w-full"
                                tabs={['Onsite', 'Offshore']}
                                setSelectedtab={selectLocationFlag}
                                selectedTab={payload?.isonsite ? 'Onsite' : 'Offshore' || null}
                            />
                        </div>
                    )}
                    <div>
                        <label htmlFor="location" className="block font-[500] mb-2">
                            Location <span className="text-red-300">*</span>
                        </label>
                        <SelectLocation location={location} setLocation={setLocation} cityList={citylist} />
                    </div>
                </div>
                <div className="fixed bottom-0 mt-2 right-6">
                    <div className="fixed bottom-2 right-5">
                        <PrimaryButton
                            className="px-8 py-2 mb-4 text-white rounded-lg bg-primary"
                            onClick={handleSubmit}
                        >
                            <span>Save</span>
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details
