import { useState, useEffect } from 'react'
import moment from 'moment'
import SingleSelectField from 'src/Components/SingleSelectForTable'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'

const quarters = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' },
]

const currentYear = moment().year()
const financialYearsData = Array.from({ length: 21 }, (_, index) => {
    const year = currentYear - 5 + index
    return {
        value: year,
        label: `${year}-${(year + 1).toString().slice(-2)}`,
    }
})

const monthsData = moment.months().map((month, index) => ({
    label: month,
    value: index + 1,
}))

const periodTypes = [
    { label: 'YTD', value: 'YTD' },
    { label: 'QTD', value: 'QTD' },
    { label: 'MTD', value: 'MTD' },
]

const AnalyticsWorkforceSubFilter = ({ filter, setFilter }) => {
    const [localSelections, setLocalSelections] = useState({
        year: filter?.year || null,
        quarter: filter?.quarter || null,
        month: filter?.month || null,
    })
    const [availableQuarters, setAvailableQuarters] = useState(quarters)
    const [availableMonths, setAvailableMonths] = useState([])

    useEffect(
        () => {
            if (!filter.typeid) {
                const today = moment()
                const currentFinancialYear = today.month() < 3 ? today.year() - 1 : today.year()
                const startDate = moment(`${currentFinancialYear}-04-01`).format('YYYY-MM-DD')
                const endDate = today.format('YYYY-MM-DD')
                const updatedFilter = {
                    ...filter,
                    typeid: 'YTD',
                    startdate: startDate,
                    enddate: endDate,
                }

                Object.keys(updatedFilter).forEach((key) => updatedFilter[key] === null && delete updatedFilter[key])
                setFilter(updatedFilter)
            }
        },
        // eslint-disable-next-line
        [],
    )

    useEffect(
        () => {
            if (localSelections.year && localSelections.quarter && localSelections.month) {
                const month = String(localSelections.month).padStart(2, '0')
                const startDate = moment(`${localSelections.year}-${month}-01`).format('YYYY-MM-DD')
                const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD')

                const updatedFilter = {
                    ...filter,
                    year: localSelections.year,
                    quarter: localSelections.quarter,
                    month: localSelections.month,
                    startdate: startDate,
                    enddate: endDate,
                    typeid: null,
                }

                Object.keys(updatedFilter).forEach((key) => updatedFilter[key] === null && delete updatedFilter[key])

                setFilter(updatedFilter)
            }
        },
        // eslint-disable-next-line
        [localSelections],
    )

    const handleYearChange = (value) => {
        setLocalSelections({
            year: value?.value || null,
            quarter: null,
            month: null,
        })
        setAvailableQuarters(quarters)
        setAvailableMonths([])
    }

    const handleQuarterChange = (value) => {
        const quarterValue = value?.value || null
        setLocalSelections((prev) => ({
            ...prev,
            quarter: quarterValue,
            month: null,
        }))

        if (quarterValue) {
            const quarterIndex = parseInt(quarterValue.slice(-1), 10) - 1
            const quarterMonths = monthsData.slice(quarterIndex * 3, quarterIndex * 3 + 3)
            setAvailableMonths(quarterMonths)
        } else {
            setAvailableMonths([])
        }
    }

    const handleMonthChange = (value) => {
        setLocalSelections((prev) => ({
            ...prev,
            month: value?.value || null,
        }))
    }

    const handleSelectPeriodTab = (value) => {
        const today = moment()
        const currentFinancialYear = today.month() < 3 ? today.year() - 1 : today.year()
        let startDate,
            endDate = today.format('YYYY-MM-DD')

        switch (value) {
            case 'YTD':
                startDate = moment(`${currentFinancialYear}-04-01`).format('YYYY-MM-DD')
                break
            case 'QTD':
                const quarterStartMonth = Math.floor(today.month() / 3) * 3
                startDate = moment().month(quarterStartMonth).startOf('month').format('YYYY-MM-DD')
                break
            case 'MTD':
                startDate = today.startOf('month').format('YYYY-MM-DD')
                break
            default:
                break
        }

        const updatedFilter = {
            ...filter,
            startdate: startDate,
            enddate: endDate,
            typeid: value,
        }

        setLocalSelections({
            year: null,
            quarter: null,
            month: null,
        })

        ;['year', 'quarter', 'month'].forEach((key) => delete updatedFilter[key])
        Object.keys(updatedFilter).forEach((key) => updatedFilter[key] === null && delete updatedFilter[key])

        setFilter(updatedFilter)
    }

    return (
        <div className="flex items-center justify-start space-x-2">
            <div className="border p-0.5 rounded bg-gray-50">
                <SingleSelectField
                    displayLabel="Year"
                    valueKey="value"
                    labelKey="label"
                    options={financialYearsData}
                    value={localSelections.year}
                    handleSelected={handleYearChange}
                />
            </div>
            <div className="border rounded p-0.5 bg-gray-50">
                <SingleSelectField
                    valueKey="value"
                    displayLabel="Quarter"
                    labelKey="label"
                    options={localSelections.year ? availableQuarters : []}
                    value={localSelections.quarter}
                    handleSelected={handleQuarterChange}
                />
            </div>
            <div className="border p-0.5 rounded bg-gray-50">
                <SingleSelectField
                    displayLabel="Month"
                    valueKey="value"
                    labelKey="label"
                    options={localSelections.quarter ? availableMonths : []}
                    value={localSelections.month}
                    handleSelected={handleMonthChange}
                />
            </div>
            <div className="col-span-3">
                <FilterButtonTabObject
                    className="text-sm p-1.5 border"
                    tabs={periodTypes}
                    selected={filter?.typeid}
                    labelkey="label"
                    name="typeid"
                    valuekey="value"
                    onClick={(name, value) => handleSelectPeriodTab(value)}
                />
            </div>
        </div>
    )
}

export default AnalyticsWorkforceSubFilter
