
const HomeNotification = ({notificationList}) => {
	return (
		<>
			<div className='flex items-center'>
				<h1 className='font-[500] p-3'>Notifications</h1>
			</div>
			<div className='h-full gap-1 overflow-hidden'>
				<marquee className='h-[calc(100%/1)] px-5' behavior="scroll" direction="up" scrollamount="3">
					{
					notificationList?.map(notification => (
						<div key={notification?.announcementid} className='py-2 text-sm space-x-2'>
							<span>{notification?.announcementtext}</span> 
							{notification?.announcementlink&& <a
								href={notification?.announcementlink}
								target='_blank'
								rel='noopener noreferrer'
								className='text-secondary/80 hover:text-secondary'
							>
								Click here
							</a>}
						</div>
					))}

				</marquee>
			</div>
		</>
	)
}

export default HomeNotification
