import { Dialog, Transition } from '@headlessui/react'
import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IconButton from 'src/Components/Buttons/IconButton'
import {
    GET_DEMAND_MASTER,
    SAVE_DEMAND_LIST_MASTER,
} from 'src/Redux/actions/workforce/demands'
import Details from './demanddetails/details'
import RoleDetails from './roleDetails/roleDetails'
import { RoleItemSkeleton } from './roleDetails/roleDetailsItem'
import { insertDemandSkillExpertise } from './skill&Experties/defaultItems'
import SkillExperties from './skill&Experties/skill&Experties'

const tabslist = ['Demand Details', 'Role Details', 'Skills & Expertise']

const UpdateDemand = ({ demandData }) => {
    const dispatch = useDispatch()
    const DemandDetailsList = useSelector((state) => state?.DemandDetailsList)
    const { getStaffStatusTypeM, getStaffStatusTypeS } = DemandDetailsList
    const [roleData, setRoleData] = useState([RoleItemSkeleton()])
    const [band, setBand] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [activeTabs, setActiveTabs] = useState('Demand Details')
    const [tabCanAccess, setTabCanAccess] = useState(['Demand Details'])
    const [skillData, setSkillData] = useState([])
    const [canEdit, setCanEdit] = useState(null)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const editDemandId = searchParams.get('demandid')

    useEffect(() => {
        setCanEdit(false)
    }, [dispatch])

    function closeModal() {
        setIsOpen(false)
        setActiveTabs('Demand Details')
    }

    function openModal() {
        setIsOpen(true)
    }

    async function detailSave(payload) {
        let res = await dispatch(SAVE_DEMAND_LIST_MASTER(payload))
        if (res !== null) {
            setActiveTabs('Role Details')
        }
        dispatch(GET_DEMAND_MASTER(+editDemandId))
    }

    useMemo(() => {
        if (demandData[0]?.demandskills?.length > 0 && demandData?.length > 0) {
            let items = []
            for (let it of demandData[0]?.demandskills) {
                it['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                items.push(it)
            }
            setSkillData(items)
        } else {
            setSkillData([insertDemandSkillExpertise()])
        }
    }, [demandData])

    return (
        <>
            <IconButton
                title="Edit Demand"
                onClick={openModal}
                className="border rounded-full bg-primary text-white focus:ring-0 focus:ring-primary font-[500] text-sm focus:outline-none p-2"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                    />
                </svg>
            </IconButton>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" w-[calc(90%)] 
  md:w-[calc(80%)] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex items-center justify-between w-full border-b border-secondary ">
                                        <h3 className="text-lg font-[500] leading-6 text-primary">
                                            Edit Demand
                                        </h3>
                                        <div className="flex justify-start p-1 space-x-2 rounded-lg ">
                                            {tabslist?.map((item, i) => {
                                                return (
                                                    <button
                                                        key={i}
                                                        onClick={() =>
                                                            setActiveTabs(item)
                                                        }
                                                        className={` rounded-md px-2 py-2  outline-white ${item === activeTabs ?
                                                            'bg-secondary/10 text-secondary font-[500]' : 'text-gray-500'
                                                            }`}
                                                    >
                                                        {item}
                                                    </button>
                                                )
                                            })}
                                        </div>

                                        <button
                                            onClick={closeModal}
                                            type="button"
                                            className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                            <span className="sr-only">
                                                Close icon
                                            </span>
                                        </button>

                                    </div>
                                    {activeTabs === 'Demand Details' && (
                                        <>
                                            <Details
                                                hooks={{
                                                    tabCanAccess,
                                                    setTabCanAccess,
                                                    demandData,
                                                    detailSave,
                                                    getStaffStatusTypeM,
                                                    getStaffStatusTypeS,
                                                }}
                                            />
                                        </>
                                    )}
                                    {activeTabs === 'Role Details' && (
                                        <>
                                            <RoleDetails
                                                hooks={{
                                                    canEdit,
                                                    tabCanAccess,
                                                    setTabCanAccess,
                                                    demandData,
                                                    band,
                                                    setBand,
                                                    roleData,
                                                    setRoleData,
                                                }}
                                            />
                                        </>
                                    )}
                                    {activeTabs === 'Skills & Expertise' && (
                                        <>
                                            <SkillExperties
                                                hooks={{
                                                    canEdit,
                                                    tabCanAccess,
                                                    setTabCanAccess,
                                                    skillData,
                                                    setSkillData,
                                                }}
                                            />
                                        </>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default memo(UpdateDemand)
