import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../defaultDetails';

const CountryDetails = props => {
	const { regionList } = useSelector(state => state.UserManagementList)
	const { detailSave, detailsData, setDetailsData } = props
	const [checkvalidation, setCheckvalidation] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCheckvalidation(true);

		let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
		if (isValidated?.isvalid) {
			let payload = {
				countryjson: [detailsData],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='countryname' className='block font-[500] mb-2'>
						Country Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						type='text'
						onChange={(e) => {
							if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
								handleChangeInput('countryname', e.target.value)
							}
						}}
						title='countryname'
						id='countryname'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
						placeholder='countryname'
						required=''
						maxLength={50}
					/>
					{!detailsData?.countryname && checkvalidation && (
						<small className='text-red-400'>Country Name is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='countrycode' className='block font-[500] mb-2'>
						Country Code
					</label>
					<input
						disabled
						type='text'
						title='countrycode'
						id='countrycode'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
						placeholder='countrycode'
						required=''
						onChange={e => handleChangeInput('countrycode', e.target.value)}
					/>
				</div>
				<div>
					<label htmlFor='comregionid' className='block font-[500] mb-2'>
						Region
						<span className="text-red-300"> *</span>
					</label>
					<CustomhtmlSelect
						options={regionList || []}
						value={detailsData?.comregionid || ''}
						noDataLabel='No region'
						name='comregionid'
						labelkey='regionname'
						className='border rounded bg-gray-50 py-2'

						onChange={handleChangeInput}
						id='comregionid'
					/>
					{!detailsData?.comregionid && checkvalidation && (
						<small className='text-red-400'>Region is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 mt-2 right-6'>
				<div className='flex items-center justify-end gap-2'>
					<PrimaryButton
						className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
						onClick={handleSubmit}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default memo(CountryDetails)
