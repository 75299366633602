import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SingleSelectField from 'src/Components/SingleSelectForTable';
import moment from 'moment';
import { detailItem } from './default';
import AddeditExchangeRates from './AddeditExchangeRates';
import { GET_EXCHANGE_RATE_MASTER } from 'src/Redux/actions/masterdata/user';
import { getExchangeRatePayload } from 'src/Services/constant/defaultPayload';
import IconButton from 'src/Components/Buttons/IconButton';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = [
  'Currency From',
  'Currency To',
  'Conversion Rate',
  'Valid From',
  'Valid To',
  'Status',
  'Created At',
  'Created By',
];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const quarters = [
  { label: 'Q1', value: '-04-01,-06-30' },
  { label: 'Q2', value: '-07-01,-09-30' },
  { label: 'Q3', value: '-10-01,-12-31' },
  { label: 'Q4', value: '-01-01,-03-31' },
];

const yearsData = Array.from({ length: 21 }, (_, index) => ({
  value: 2020 + index,
  label: 2020 + index,
}));

const getStartAndEndDate = () => {
  let validfrom = `${new Date().getFullYear()}${quarters[0]?.value?.split(',')[0]}`
  let validto = `${new Date().getFullYear()}${quarters[0]?.value?.split(',')[1]}`
  return { validfrom, validto }
}

const ExchangeRatesMasterList = () => {
  const [currency, setCurrency] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [addOrEdit, setAddOrEdit] = useState('Edit');
  const [selectQ, setSelectQ] = useState(quarters[0]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ label: 'Active' });
  const [ExchangeRateId, setExchangeRateId] = useState(null);
  const [detailsData, setDetailsData] = useState(detailItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
  const [year, setYear] = useState({
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  });
  const [startEndDate, setStartEndDate] = useState(getStartAndEndDate())

  const dispatch = useDispatch();
  const { exchangeratesList, loading: exchangeRateLoading } = useSelector(
    (state) => state.UserManagementList
  );

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(exchangeRateLoading); // Hide the loader after a delay
    }, 100);
  }, [exchangeRateLoading]);

  useEffect(() => {
    if (currency?.currencyfromname) {
      setSearchTerm(`${currency?.currencyfromname}`);
    }
  }, [currency]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(
      GET_EXCHANGE_RATE_MASTER({
        ...getExchangeRatePayload(),
        sorttype: item.id,
        Status: status?.label,
        limit: limit,
        Startdate: startEndDate?.validfrom,
        Enddate: startEndDate?.validto,
      })
    );
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(
      GET_EXCHANGE_RATE_MASTER({
        ...getExchangeRatePayload(),
        Status: item.label,
        limit: limit,
        page: page,
        Startdate: `${year?.value}${selectQ?.value?.split(',')[0]}`,
        Enddate: `${year?.value}${selectQ?.value?.split(',')[1]}`,
      })
    );
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(
      GET_EXCHANGE_RATE_MASTER({
        ...getExchangeRatePayload(),
        limit,
        page: page?.selected + 1,
        sorttype: sortType?.id,
        Status: status?.label,
        Startdate: startEndDate?.validfrom,
        Enddate: startEndDate?.validto,
      })
    );
    if (res?.success) {
      setDetailsData(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(
      GET_EXCHANGE_RATE_MASTER({
        ...getExchangeRatePayload(),
        limit: row,
        page: page?.selected + 1,
        sorttype: sortType?.id,
        Status: status?.label,
        Startdate: startEndDate?.validfrom,
        Enddate: startEndDate?.validto,
      })
    );
    if (res?.success) {
      setDetailsData(res?.data);
    }
  };

  const handleExchangeRateClick = (com) => {
    setDetailsData(com);
    setExchangeRateId(com?.exchangerateid);
    setIsOpen(true);
    setAddOrEdit('Edit');
  };

  const selectYear = (year) => {
    let value = selectQ?.label === 'Q4' ? Number(year?.value) + 1 : year?.value;

    setYear(year);
    setStartEndDate({
      validfrom: `${value}${selectQ?.value?.split(',')[0]}`,
      validto: `${value}${selectQ?.value?.split(',')[1]}`,
    });
    let payload = {
      ...getExchangeRatePayload(),
      limit: limit,
      page: page,
      sorttype: sortType?.id,
      Status: status?.label,
      Startdate: `${value}${selectQ?.value?.split(',')[0]}`,
      Enddate: `${value}${selectQ?.value?.split(',')[1]}`,
    };
    dispatch(GET_EXCHANGE_RATE_MASTER(payload));
  };

  const selectQuarters = (q) => {
    setSelectQ(q);
    let yearNum = q?.label === 'Q4' ? Number(year?.value) + 1 : year?.value;
    setStartEndDate({
      validfrom: `${yearNum}${q?.value?.split(',')[0]}`,
      validto: `${yearNum}${q?.value?.split(',')[1]}`,
    });
    let payload = {
      ...getExchangeRatePayload(),
      limit: limit,
      page: page,
      sorttype: sortType?.id,
      Status: status?.label,
      Startdate: `${yearNum}${q?.value?.split(',')[0]}`,
      Enddate: `${yearNum}${q?.value?.split(',')[1]}`,
    };
    // console.log(payload)
    dispatch(GET_EXCHANGE_RATE_MASTER(payload));
  };

  const YearCompanyQ = () => {
    return (
      <>
        <div>
          <div className='bg-gray-50 w-20 border rounded-md px-2 py-0.5'>
            <SingleSelectField
              valueKey='value'
              labelKey='label'
              options={yearsData}
              value={year?.value}
              handleSelected={selectYear}
            />
          </div>
        </div>
        <div>
          <div className='bg-gray-50 w-20 border rounded-md px-2 py-0.5'>
            <SingleSelectField
              valueKey='value'
              labelKey='label'
              options={quarters}
              value={selectQ?.value}
              handleSelected={selectQuarters}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='mt-4 h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Exchange Rates (
            {exchangeratesList?.length > 0
              ? exchangeratesList[0]?.totalcount
              : exchangeratesList?.length}
            )
          </h1>
          <div className=' w-64 '>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_EXCHANGE_RATE_MASTER}
              searchPayload={{
                ...getExchangeRatePayload(),
                searchname: searchTerm,
                Startdate: startEndDate?.validfrom,
                Enddate: startEndDate?.validto,
                limit: limit,
                page: 1,
              }}
              setSearchTerm={setSearchTerm}
              options={exchangeratesList}
              idkey='currencyfromname'
              optionlabelKey='currencyfromname'
              getSelectedOption={setCurrency}
              placeholder='Enter Currency Name'
              className='p-2'
            />
          </div>
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              total={
                exchangeratesList?.length > 0
                  ? exchangeratesList[0]?.totalcount
                  : exchangeratesList?.length
              }
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-24 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-28 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <YearCompanyQ />
        </div>
        <IconButton
          title='Create New Exchange Rate'
          onClick={() => {
            setIsOpen(true);
            setAddOrEdit('Add');
          }}
          className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-5 h-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 4.5v15m7.5-7.5h-15'
            />
          </svg>
        </IconButton>
      </div>
      <div className='h-[46vh] overflow-scroll'>
        {loading || exchangeratesList === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div
              className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]'
              role='status'
            >
              <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                Loading...
              </span>
            </div>
          </div>
        ) : (
          exchangeratesList && (
            <table className='text-sm w-full relative'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {exchangeratesList?.map((item, i) => {
                  let {
                    exchangerateid,
                    currencycodefrom,
                    currencycodeto,
                    conversionrate,
                    validfrom,
                    validto,
                    isactiveflag,
                    createduser,
                    createdtimestamp,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleExchangeRateClick(item)}
                      key={exchangerateid}
                      className='cursor-pointer text-left'
                    >
                      <td className='border px-2 py-1.5'>
                        <div>{currencycodefrom}</div>
                      </td>
                      <td className='border px-2 py-1.5'>
                        <div>{currencycodeto}</div>
                      </td>
                      <td className='border px-2 py-1.5'>
                        <div>{conversionrate}</div>
                      </td>
                      <td className='border px-2'>
                        <div> {moment(validfrom).format('DD-MM-YYYY')}</div>
                      </td>
                      <td className='border px-2 py-1.5'>
                        <div> {moment(validto).format('DD-MM-YYYY')}</div>
                      </td>
                      <td className='border px-2'>
                        <div>
                          {isactiveflag === true ? 'Active' : 'Inactive'}
                        </div>
                      </td>
                      <td className='border px-2'>
                        <div>
                          {moment(createdtimestamp).format(
                            'DD-MM-YYYY kk:mm:ss'
                          )}
                        </div>
                      </td>
                      <td className='border px-2'>
                        <div>{createduser}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        )}
      </div>

      {isOpen && (
        <AddeditExchangeRates
          YearCompanyQ={YearCompanyQ}
          startEndDate={startEndDate}
          detailsData={detailsData}
          addOrEdit={addOrEdit}
          setDetailsData={setDetailsData}
          exchangerateid={ExchangeRateId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          page={page}
          limit={limit}
          status={status}
          sortType={sortType}
        // permissions={PermissionsOfMasterdata}
        />
      )}
    </div>
  );
};

export default memo(ExchangeRatesMasterList);
