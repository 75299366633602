import SubscriptionManagementList from './createSubscription/list';

const SubscriptionManagment = () => {
  return (
    <div className='p-3 text-sm'>
      <SubscriptionManagementList />
    </div>
  );
};

export default SubscriptionManagment;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   