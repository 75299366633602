import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from './defaultRowAndData';
import MultiSelectItem from 'src/Components/MultiSelect';
import { AlertError } from 'src/Services';
import FlatpickerDateInput from 'src/Components/FlatpickerDateInput';

const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    canEdit,
    setCanEdit,
    UserId,
    empstatus,
    selectedRoles,
    setSelectedRoles,
  } = props;
  const [checkvalidation, setcheckvalidation] = useState(false);
  const { companylist } = useSelector((state) => state.EmployeeDetails);
  const { usertypelist, rolelist } = useSelector((state) => state.UserManagementList);
  const statusListByType = useSelector((state) => state.Projects?.statusListByType);

  useEffect(() => {
    setDetailsData({
      ...detailsData,
      emailid: detailsData?.username,
      employeecode: detailsData?.empid,
      userid: UserId,
      emp_statusid: empstatus,
    });
    setSelectedRoles(
      detailsData?.rolenames?.map((role) => ({
        roleid: role.roleid,
        rolename: role.rolename,
      })) || []
    );
    // eslint-disable-next-line
  }, [UserId]);

  const getEmpStatusId = (code) => {
    return statusListByType?.find(status => status.statuscode === code)?.statusid
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setcheckvalidation(false);
    let item = {
      userid: detailsData?.userid,
      username: detailsData?.username,
      emailid: detailsData?.emailid,
      personalemailid: '',
      mobileno: detailsData?.mobileno,
      phoneno: '',
      datusertypeid: detailsData?.datusertypeid,
      firstname: detailsData?.firstname,
      lastname: detailsData?.lastname,
      payrollcompanyid: detailsData?.payrollcompanyid,
      employeecode: detailsData?.employeecode,
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      createdcomuserid: Number(localStorage.getItem('comuserid')),
      notes: 'Emp insert',
      activeflag: detailsData?.activeflag,
      Roledetails: selectedRoles?.map((role) => ({
        Roleid: role?.roleid,
      })),
      emp_status: detailsData?.emp_statusid,
      skillareamasterid: detailsData?.skillareamasterid,
      exitdate: detailsData?.exitdate,
    };
    let req = [...detailNotRequired]
    // console.log(item, detailsData?.emp_statusid, getEmpStatusId("ENOTCEPRD"))
    if (!(detailsData?.emp_statusid === getEmpStatusId("ENOTCEPRD"))) {
      req.push('exitdate')
    }

    let isValidated = CheckObjectValidation(item, req);
    if (isValidated?.isvalid) {
      let payload = {
        userjson: [item],
        password: '123456',
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setcheckvalidation(false);
    if (name === 'emp_statusid') {
      setDetailsData(preData => ({
        ...preData,
        [name]: value,
        exitdate: null
      }));
    } else {
      setDetailsData(preData => ({
        ...preData,
        [name]: value
      }));
    }
  };

  const handleRoleSelect = async (item) => {
    const isNew = selectedRoles?.some(p => p?.roleid === item?.roleid)
    if (isNew) {
      const updatedAllocationStatus = selectedRoles?.filter(
        p => p?.roleid !== item?.roleid,
      )
      setSelectedRoles(updatedAllocationStatus)
    } else {
      if (selectedRoles?.length === 3) {
        AlertError('Maximum of 3 roles allowed per user');
        return
      }
      setSelectedRoles(prevList => [...prevList, {
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        roleid: item?.roleid,
        activeflag: true,
        rolename: item?.rolename,
      }])
    }

  }

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-5 mt-4'>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={companylist || []}
            value={detailsData?.payrollcompanyid || ''}
            noDataLabel='No Company'
            name='payrollcompanyid'
            labelkey='companyname'
            className='p-2 bg-gray-100 border rounded'
            onChange={handleChangeInput}
            id='companyid'
          />
          {!detailsData?.payrollcompanyid && checkvalidation && (
            <small className='text-red-400'>Company is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='usertype' className='block font-[500] mb-2'>
            User Type
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={usertypelist || []}
            value={detailsData?.datusertypeid || ''}
            noDataLabel='No Users'
            name='datusertypeid'
            labelkey='usertype'
            className='p-2 border rounded bg-gray-50'
            onChange={handleChangeInput}
            id='datusertypeid'
          />
          {!detailsData?.datusertypeid && checkvalidation && (
            <small className='text-red-400'>User Type is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='Role' className='block capitalize mb-2 font-[500]'>
            Role
            <span className="text-red-300"> *</span>
          </label>
          <div className={`border rounded ${!canEdit ? 'bg-gray-50' : ''} p-0.5 w-100`}>            <MultiSelectItem
            itemIdKey='roleid'
            disabled={canEdit}
            labelKeyname='rolename'
            limitShow={1}
            selectedOptions={selectedRoles}
            options={rolelist}
            handleSelection={handleRoleSelect}
          />
          </div>
          {selectedRoles && checkvalidation && (
            <small className='text-red-400'>Role is required!</small>
          )}
        </div>
        <div>
          <h1 className='mb-2 font-[500]'>
            Status<span className='text-red-300'> *</span>
          </h1>
          <CustomhtmlSelect
            disabled={canEdit}
            options={statusListByType || []}
            value={detailsData?.emp_statusid}
            noDataLabel='No Status'
            name='emp_statusid'
            labelkey='statusname'
            className='p-2 bg-gray-100 border rounded'
            onChange={handleChangeInput}
            id='statusid'
          />
          {(!detailsData?.emp_status) && checkvalidation && (
            <small className='text-red-400'>
              Employee Status is required!{' '}
            </small>
          )}
        </div>
        {(detailsData?.emp_statusid === getEmpStatusId("ENOTCEPRD") || detailsData?.emp_statusid === getEmpStatusId("ERELEIVD")) && <div>
          <label htmlFor='exitdate' className='block font-[500] mb-2'>
            Exit Date
            {
              detailsData?.emp_statusid === getEmpStatusId("ENOTCEPRD") ? (
                <span className='text-red-300'> *</span>
              ) : null}
          </label>
          <FlatpickerDateInput
            disabled={!(detailsData?.emp_statusid === getEmpStatusId("ENOTCEPRD"))}
            // min={detailsData?.dateofjoining}
            name='exitdate'
            id='exitdate'
            value={detailsData?.exitdate}
            className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
            onChangeDate={e => handleChangeInput('exitdate', e.target.value)}
          />
          {((!detailsData?.emp_statusid && checkvalidation) && detailsData?.emp_statusid === getEmpStatusId("ENOTCEPRD")) ? (
            <small className='text-red-400'>Exit date is required!</small>
          ) : ''}
        </div>}
        <div>
          <label htmlFor='firstname' className='block font-[500] mb-2'>
            First Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('firstname', e.target.value);
              }
            }}
            title='firstname'
            id='firstname'
            className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
            placeholder='First Name'
            required=''
            value={detailsData?.firstname || ''}
            maxLength={50}
          />
          {!detailsData?.firstname && checkvalidation && (
            <small className='text-red-400'>FirstName is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='lastname' className='block font-[500] mb-2'>
            Last Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='lastname'
            id='lastname'
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('lastname', e.target.value);
              }
            }}
            className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
            placeholder='Last Name'
            required=''
            value={detailsData?.lastname || ''}
            maxLength={50}
          />
          {!detailsData?.lastname && checkvalidation && (
            <small className='text-red-400'>LastName is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='username' className='block font-[500] mb-2'>
            Email
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='email'
            title='email'
            id='emailid'
            className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
            placeholder='Email'
            required=''
            value={detailsData?.emailid || ''}
            onChange={(e) => handleChangeInput('emailid', e.target.value)}
          />
          {!detailsData?.emailid && checkvalidation && (
            <small className='text-red-400'>Email is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='username' className='block font-[500] mb-2'>
            Username
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='username'
            title='username'
            id='username'
            className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
            placeholder='Username'
            required=''
            value={detailsData?.username || ''}
            onChange={(e) => handleChangeInput('username', e.target.value)}
          />
          {!detailsData?.username && checkvalidation && (
            <small className='text-red-400'>Username is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='mobileno' className='block font-[500] mb-2'>
            Mobile No
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='mobileno'
            maxLength={15}
            id='mobileno'
            className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
            placeholder='Phone No'
            required=''
            value={detailsData?.mobileno || ''}
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target?.value)) {
                handleChangeInput('mobileno', e.target.value);
              }
            }}
          />
          {!detailsData?.mobileno && checkvalidation && (
            <small className='text-red-400'>Phone no is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='usercode' className='block font-[500] mb-2'>
            User Code
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='employeecode'
            id='employeecode'
            className='block w-full p-2 text-gray-600 border rounded outline-gray-50 bg-gray-50'
            placeholder='User Code'
            required=''
            value={detailsData?.employeecode || ''}
            onChange={(e) =>
              handleChangeInput('employeecode', e.target.value)
            }
          />
        </div>
      </div>
      <div className='fixed bottom-0 mt-2 right-6'>
        {!canEdit && (
          <div className='fixed bottom-0 mt-2 right-6'>
            <div className='flex items-center justify-end gap-2'>
              <PrimaryButton
                onClick={() => setCanEdit(false)}
                className='px-6 border bg-white  border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Details);