import React, { useEffect } from 'react'
import Barchart from './byServiceLine'
import { useDispatch, useSelector } from 'react-redux'
import {
    GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    GET_CITY_MASTER,
    GET_WORKERTYPE_MASTER,
} from 'src/Redux/actions/workforce/talent'
import {
    getDeafultSkillData,
    getDefaultCityPayload,
    getStatusMasterIdData,
    getWorkTypePayload,
} from 'src/Services/constant/defaultPayload'
import { GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import { GET_SKILL_MASTER } from 'src/Redux/actions/workforce/eprofile'
import PieChartPrimarySkill from './byPrimarySkill'
import PieChartLocation from './byLocation'
import PieChartAllocation from './byAllocation'
import PieChartWithPaddingAngleExp from './byExperience'

const Talent = ({showMore}) => {
    const dispatch = useDispatch()
    const { allocationstatuslist, workertypelist } = useSelector(state => state?.EmployeeDetails)
    const { serviceList, cityList } = useSelector(state => state?.UserManagementList)
    const { userSkillList } = useSelector(state => state?.EmployeeProfile)

    useEffect(() => {
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_WORKERTYPE_MASTER(getWorkTypePayload()))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
        dispatch(GET_CITY_MASTER(getDefaultCityPayload()))
    }, [dispatch])

    return (
        <div className="w-full ">
          
            <div className="grid grid-cols-3 py-2">
                {showMore ? (
                    <>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Service Line/Emp Type
                            </h3>
                            <Barchart
                                serviceList={serviceList}
                                workertypelist={workertypelist}
                            />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Primary Skill
                            </h3>
                            <PieChartPrimarySkill userSkillList={userSkillList} />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Location
                            </h3>
                            <PieChartLocation locationList={cityList} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="relative w-full h-80 ">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Allocation Status
                            </h3>
                            <PieChartAllocation
                                allocationstatuslist={allocationstatuslist}
                            />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Experience
                            </h3>
                            <PieChartWithPaddingAngleExp />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Talent
