import { Dialog, Transition } from '@headlessui/react'
import { Fragment, memo } from 'react'
import SelectedStaffRow from './selectedStaffRow'
import StaffTable from './staffTable'
import { getStaffingIdByNameOfStatus } from 'src/Services'
// import { GET_PROJECT_STAFFING_LIST } from 'src/Redux/actions/projects/staffing'

const AddStaffModal = ({ hooks }) => {
    const {
        selectedStaff,
        setSelectedStaff,
        staffingData,
        setstaffingData,
        statusListByType,
        page, limit
    } = hooks
    let staffingA = getStaffingIdByNameOfStatus(statusListByType, "Staffing Approval")

    const handleStaffAdd = (row, cellId) => {
        const newroles = staffingData?.map((item) => {
            if (item?.uId === cellId) {
                item['StaffingStatusId'] = staffingA
                item['workertype'] = row?.workertype
                item['employeecode'] = row?.employeecode
                item['userid'] = row?.comuserid
                item['username'] = `${row?.firstname} ${row?.lastname}`
                // console.log(row)
            }
            return item;
        });
        setstaffingData([...newroles]);
    }

    const handleCancel = () => {
        const newroles = staffingData?.map((item) => {
            if (item?.uId === selectedStaff?.uId) {
                delete item['StaffingStatusId']
                item['workertype'] = null
                item['employeecode'] = null
                item['userid'] = null
                item['username'] = null
            }
            return item;
        });
        setstaffingData([...newroles]);
    }

    return (
        <>
            <Transition appear show={selectedStaff !== null} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={handleCancel}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-0 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[1200px] min-h-[600px] px-8 py-4 transform rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                                    {selectedStaff && <StaffTable
                                        Mainpage={page} Mainlimit={limit}
                                        handleCancel={handleCancel}
                                        handleStaffAdd={handleStaffAdd}
                                        setSelectedStaff={setSelectedStaff}
                                        selectedStaff={selectedStaff} />}
                                    {selectedStaff && <SelectedStaffRow
                                        hooksValue={hooks}
                                    />}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default memo(AddStaffModal);