import { Tab } from '@headlessui/react'
import { useState } from 'react'
import NoPermissionComponent from 'src/Components/NoPermission'
import CancelDemand from './cancelDemand'
import UpdateDemand from './editDemandDetails'
import HireDemand from './hireforDemand'
import ProfilesLayout from './profiles'
import RoleDetails from './roleDetails'
import SkillExpertise from './skillExpertise'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Tabs = ({ demandData, extractStatusIds, thisPagePermissions, demandMatch }) => {
    const [activeTab, setActiveTab] = useState('Role Details')
    return (
        <div className="w-9/12 rounded-lg shadow-md h-[84vh] bg-white px-4">
            <Tab.Group
                defaultIndex={0}
                onChange={(index) => {
                    const tabs = ['Role Details', 'Skills & Expertise', 'Profiles']
                    setActiveTab(tabs[index])
                }}
            >
                <div className="py-0.5 border-b text-sm border-secondary flex justify-between items-center">
                    <Tab.List className="w-[650px] flex space-x-1 rounded-lg">
                        {['Role Details', 'Skills & Expertise', 'Profiles'].map((item, i) => (
                            <Tab
                                key={i}
                                className={({ selected }) =>
                                    classNames(
                                        'rounded-lg border-0 text-sm outline-none',
                                        'ring-0 ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0',
                                        selected
                                            ? 'bg-secondary/10 text-secondary font-[500] p-1.5 rounded-md'
                                            : 'text-gray-500',
                                    )
                                }
                            >
                                <span className="px-4">{item}</span>
                            </Tab>
                        ))}
                    </Tab.List>
                    {thisPagePermissions?.includes('Edit Demand') ? (
                        <div className="flex justify-end w-full">
                            {activeTab !== 'Profiles' &&
                                demandData[0]?.demandstatusname !== 'Fulfilled' &&
                                demandData[0]?.demandstatusname !== 'Cancelled' ? (
                                <>
                                    <UpdateDemand demandData={demandData} />
                                    <CancelDemand demandData={demandData} />
                                    <HireDemand demandData={demandData} />
                                </>
                            ) : (
                                <div className="h-8" />
                            )}
                        </div>
                    ) : (
                        <div className="h-8" />
                    )}
                </div>
                <Tab.Panels className="my-2">
                    <Tab.Panel>
                        <RoleDetails demandData={demandData} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <SkillExpertise demandData={demandData} />
                    </Tab.Panel>
                    <Tab.Panel>
                        {thisPagePermissions?.includes('View Employee') ? (
                            <ProfilesLayout
                                thisPagePermissions={thisPagePermissions}
                                demandMatch={demandMatch}
                                demandData={demandData}
                                extractStatusIds={extractStatusIds}
                            />
                        ) : (
                            <NoPermissionComponent />
                        )}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default Tabs
