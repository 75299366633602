import { Fragment, memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { AlertError, AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { GET_SUBSCRIPTIONS_MASTER, SAVE_SUBSCRIPTION_DETAILS, SAVE_TENANTS_MASTER, GET_TENANTS_MASTER } from 'src/Redux/actions/subscriptions';
import { GET_CURRENCY_MASTER_LIST } from 'src/Redux/actions/projects';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getDefaultCurrencyPayload, getSubscriptionsPayload, getTenantsPayload } from 'src/Services/constant/defaultPayload';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import { subscriptionItem } from '../createTenant/defaultRowAndData';
import SubscriptionDetails from './subscription';
import Details from './details';

const tabslist = ['Tenant Details', 'Subscription Details'];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditTenant = (props) => {
  const {
    isOpen = true,
    setIsOpen,
    comtenantid,
    setDetailsData,
    detailsData,
    page,
    limit,
    shortType,
  } = props;

  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(subscriptionItem());
  const [pricingData, setPricingData] = useState(subscriptionItem().tenantsubscriptionpricing);


  async function fetchData() {
    try {
      await Promise.all([
        dispatch(GET_CITY_MASTER(getMasterCityPayload())),
        dispatch(GET_SUBSCRIPTIONS_MASTER(getSubscriptionsPayload())),
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload())),
      ])
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function closeModal() {
    setIsOpen(false);
    setCanEdit(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function detailSave(payload) {
    let res = await dispatch(SAVE_TENANTS_MASTER(payload));
    if (res?.success) {
      AlertSuccess('Tenant updated successfully.');
      await dispatch(GET_TENANTS_MASTER(getTenantsPayload()));
    }
  }

  const subscriptionSave = async (payload) => {
    try {
      let res = await dispatch(SAVE_SUBSCRIPTION_DETAILS(payload, comtenantid));
      if (res?.success) {
        AlertSuccess('Subscription Details saved successfully!');
        await dispatch(GET_TENANTS_MASTER(getTenantsPayload()));

      }
    } catch (error) {
      console.error('Error saving subscription details:', error);
    }
  };

  const handleDelete = async () => {
    let item = {
      comtenantid: detailsData?.comtenantid,
      tenantname: detailsData?.tenantname,
      tenantcode: detailsData?.tenantcode,
      themecode: detailsData?.themecode,
      isactiveflag: !detailsData.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
      loginmethod: detailsData?.loginmethod,
      logopath: detailsData?.logopath,
      heroimagepath: detailsData?.heroimagepath,
      tenantaddress: detailsData?.tenantaddress,
      contactdetails: detailsData?.contactdetails,
      GSTnumber: detailsData?.GSTnumber,
      CIN: detailsData?.CIN,
      comcityid: detailsData?.comcityid,
      emailid: detailsData?.emailid,
      pincode: detailsData?.pincode,
      companyname: detailsData?.tenantname,
      password: detailsData?.password,
      token: detailsData?.token,
      mobileno: detailsData?.mobileno,
      employeecode: detailsData?.employeecode,
      comcurrencyid: 1,
    
      tenantcontactdetails: detailsData?.tenantcontactdetails.map((contact) => ({
        ...contact,
        isrenewalreminderneedtosend: true,
        isinvoiceneedtosend: true,
        isactiveflag: true,
        notes: ''
      }))
    }
    const payload = {
      tenantjson: [item],
      password:detailsData?.password ||'',
      url:detailsData?.url ||'',
    };

    let res = await dispatch(SAVE_TENANTS_MASTER(payload));
    if (res?.success) {
      if (detailsData.isactiveflag) {
        AlertSuccess('Tenant deactivated successfully.');
      } else {
        AlertSuccess('Tenant reactivated successfully.');
      }
      await dispatch(
        GET_TENANTS_MASTER({
          ...getTenantsPayload(),
          limit,
          Page: page?.selected + 1,
          Sorttype: shortType?.id,
        })
      );
    } else {
      AlertError('Failed to update Tenant.');
    }
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    closeModal();
  };


  function handleDeleting() {
    if (detailsData?.isactiveflag) {
      setCanEdit(true);
    }
    setShowDeleteConfirmation(true);
  }

  function handleCancelDeleting() {
    setShowDeleteConfirmation(false);
    setCanEdit(false);
  }

  function handleCancel() {
    closeModal();
    setShowDeleteConfirmation(false);
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <Tab.Group>
                    <div className='w-full my-2 flex justify-between items-center border-primary border-b '>
                      <h3 className='text-lg font-[500] leading-6 text-primary'>
                        Edit Tenant
                      </h3>
                      <Tab.List className='my-2 justify-center items-center flex space-x-2 rounded-lg p-1'>
                        {tabslist?.map((item, i) => {
                          return (
                            <Tab
                              key={i}
                              // disabled={!activeTabs.includes(item)}
                              className={({ selected }) =>
                                classNames(
                                  'rounded-md py-1 px-2 outline-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                  selected
                                    ? 'text-secondary bg-secondary/10 font-[500]'
                                    : 'text-gray-500'
                                )
                              }
                            >
                              <span className='px-2'> {item}</span>
                            </Tab>
                          );
                        })}
                      </Tab.List>
                      <div className='flex justify-end items-center space-x-2'>
                        {!canEdit && detailsData.isactiveflag && (
                          <IconButton
                            title='Edit'
                            onClick={() => setCanEdit(true)}
                            type='button'
                            className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-4 h-4'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                              />
                            </svg>
                            <span className='sr-only'>Edit icon</span>
                          </IconButton>
                        )}
                        {!canEdit && (
                          <>
                            <IconButton
                              title={detailsData?.isactiveflag ? 'Delete' : 'Reactivate'}
                              onClick={handleDeleting}
                              type='button'
                              className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.8}
                                stroke='currentColor'
                                className='w-4 h-4'
                              >
                                {detailsData.isactiveflag ? (
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                  />
                                ) : (
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth={3}
                                    d='M5 13l4 4L19 7' />
                                )}
                              </svg>
                              <span className='sr-only'>
                                {detailsData.isactiveflag ? 'Unlock' : 'Delete'}{' '}
                                icon
                              </span>
                            </IconButton>
                          </>
                        )}
                        <IconButton
                          title='Close'
                          onClick={handleCancel}
                          type='button'
                          className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-4 h-4'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M6 18L18 6M6 6l12 12'
                            />
                          </svg>
                          <span className='sr-only'>Close icon</span>
                        </IconButton>
                      </div>
                    </div>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Details
                          canEdit={!canEdit}
                          comtenantid={comtenantid}
                          setDetailsData={setDetailsData}
                          detailsData={detailsData}
                          detailSave={detailSave}
                          onModalClose={closeModal}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <SubscriptionDetails
                          comtenantid={comtenantid}
                          canEdit={!canEdit}
                          subscriptionSave={subscriptionSave}
                          mockObject={subscriptionItem()}
                          subscriptionData={subscriptionData}
                          setSubscriptionData={setSubscriptionData}
                          setCanEdit={setCanEdit}
                          pricingData={pricingData}
                          setPricingData={setPricingData}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                  {showDeleteConfirmation && (
                    <div className='absolute bottom-0 left-0 w-full bg-white p-6 border-t border-gray-300 text-center'>
                      <p className='mb-4'>
                        {detailsData.isactiveflag
                          ? 'Are you sure you want to delete this Tenant?'
                          : 'Are you sure you want to reactivate this Tenant?'}
                      </p>
                      <button
                        onClick={handleDelete}
                        className='px-8 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5 mr-4'
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleCancelDeleting}
                        className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                      >
                        No
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default memo(EditTenant);