import ScrollToBottom from 'react-scroll-to-bottom'
import IconButton from 'src/Components/Buttons/IconButton'
import NotePopup from './notePopup'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'

const cols = ['Position', 'Company', 'Start Date', 'Last Date', 'Role Summary']

const Table = ({ canEdit, formData, setFormData }) => {
	const handleAdd = () => {
		setFormData([
			...formData,
			{
				comuserprevexperienceid: null,
				comuserid: Number(localStorage.getItem('comuserid') || 0),
				companyname: '',
				designation: '',
				joiningdate: '',
				releivingdate: '',
				ctc: null,
				jobprofile: '',
				fulltimeorparttimeflag: 'F',
				remarks: '',
				createdtimestamp: '',
				comtenantid: Number(localStorage.getItem('Tenantid') || 0),
				isactiveflag: true,
				createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
				notes: '',
				username: '',
				uId:
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36),
			},
		])
	}

	const handleDelete = uId => {
		if (formData?.length > 1) {
			setFormData(formData.filter(item => item.uId !== uId))
		}
	}

	const handleChange = (e, uId) => {
		let afterChange = formData?.map(item => {
			if (item.uId === uId) {
				item[e.target.name] = e.target.value
			}
			return item
		})
		setFormData(afterChange)
	}

	return (
		<ScrollToBottom className="bg-white w-full overflow-y-scroll h-[40vh] text-sm">
			<table className='w-full text-left text-gray-600'>
				<thead className='capitalize bg-gray-50'>
					<tr className='bg-gray-100 border-b'>
						{cols?.map(item => {
							const label = item.includes('*') ? item.replace('*', '') : item
							const isRequired = [
								'Position',
								'Company',
								'Start Date',
								'Last Date',
							].includes(label)
							return (
								<th
									key={item}
									className='bg-gray-100 border-b  font-[500] py-2 px-2'
								>
									{label}
									{isRequired && <span className='text-red-300'> *</span>}
								</th>
							)
						})}
						{canEdit && <th className='border  font-[500] py-2 px-2'>Action</th>}
					</tr>
				</thead>
				<tbody>
					{formData?.map((item, i) => {
						let { designation, companyname, joiningdate, releivingdate, uId, notes } = item
						return (
							<tr key={`${uId}-${i}`} className='text-gray-600 bg-white border-b'>
								<td className='px-1 py-1 border'>
									<input
										disabled={!canEdit}
										onChange={e => handleChange(e, item?.uId)}
										type='text'
										value={designation || ''}
										name='designation'
										placeholder='designation'
										className={`py-0.5 w-full disabled:text-gray-600  disabled:bg-transparent focus:outline-none `}
										maxLength={50}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<input
										disabled={!canEdit}
										onChange={e => handleChange(e, item?.uId)}
										type='text'
										value={companyname || ''}
										name='companyname'
										placeholder='companyname'
										className={`py-0.5 w-full disabled:text-gray-600  disabled:bg-transparent focus:outline-none `}
										maxLength={50}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<FlatPickrDateInput
										disabled={!canEdit}
										name='joiningdate'
										value={joiningdate || ''}
										id='startdate'
										className=''
										onChangeDate={e => handleChange(e, item?.uId)}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<FlatPickrDateInput
										disabled={!canEdit}
										name='releivingdate'
										value={releivingdate || ''}
										id='releivingdate'
										className=''
										onChangeDate={e => handleChange(e, item?.uId)}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<NotePopup disabled={!canEdit} handleChange={handleChange} notes={notes} uId={uId} />
								</td>
								{canEdit && (
									<td className='px-1 py-1 border'>
										<div className='flex justify-end'>
											{formData?.length > 1 && (
												<IconButton
													title='Delete Experience'
													onClick={() => handleDelete(item?.uId)}
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'
													>
														<path
															fillRule='evenodd'
															d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
															clipRule='evenodd'
														/>
													</svg>
												</IconButton>
											)}
											<span className="hidden addIconbutton-add-btn">
												<IconButton
													title='Add Experience'
													disabled={formData?.length >= 10}
													onClick={handleAdd}
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'
													>
														<path
															fillRule='evenodd'
															d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
															clipRule='evenodd'
														/>
													</svg>
												</IconButton>
											</span>
										</div>
									</td>
								)}
							</tr>
						)
					})}
				</tbody>
			</table>
		</ScrollToBottom>
	)
}

export default Table
