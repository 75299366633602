import moment from "moment"
import CustomerRole from "../customerRole"
import { AlertError } from "src/Services"
import FlatPickrDateInput from "src/Components/FlatpickerDateInput"

const SelectedStaffRow = ({ hooksValue }) => {
    const { setSelectedStaff, selectedStaff, handleInputChange, getListOfProjectRole, selectCustomer, saveStaffing, loading } = hooksValue
    const { uId } = selectedStaff

    const handleDates = (e, id) => {
        let { name, value } = e.target
        let dateis = value
        let startdate = moment(new Date(selectedStaff.startdate)).format('YYYY/MM/DD')
        let enddate = moment(new Date(selectedStaff.enddate)).format('YYYY/MM/DD')

        if (name === 'startdate' && dateis > enddate) {
            AlertError('Start date must be smaller than End Date')
            return
        } if (name === 'enddate' && startdate > dateis) {
            AlertError('End date must be greater than Start Date')
            return
        }

        handleInputChange(e, id)
    }

    return (
        <div className="w-full  text-sm mt-8">
            <div className='flex justify-between items-center space-x-2'>
                <div className="flex justify-start items-center space-x-4">
                    <div className=" min-w-[240px] ">
                        <p className='pb-2 font-[500]'>Role</p>
                        <div className="w-full border p-1 rounded-md bg-white focus:outline-none hover:outline-none">
                            <CustomerRole
                                hooksValue={{ uId, getListOfProjectRole, selectCustomer }}
                                value={selectedStaff?.projroleid}
                                canEdit={false} />
                        </div>
                    </div>
                    <div className=" max-w-[80px] ">
                        <p className='pb-2 font-[500]'>Level</p>
                        <div className="w-full border p-2 rounded-md bg-white focus:outline-none hover:outline-none">
                            <p className='text-gray-600'>{selectedStaff?.levelname}</p>
                        </div>
                    </div>
                    <div className=" max-w-[80px] ">
                        <p className='pb-2 font-[500]'>Billable</p>
                        <div className="w-full border p-2 rounded-md bg-white focus:outline-none hover:outline-none">
                            <p className='text-gray-600'>{selectedStaff?.billable ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                    <div className=" max-w-[80px] ">
                        <p className='pb-2 font-[500]'>Min. Band</p>
                        <input
                            name='min_band'
                            disabled
                            value={selectedStaff?.min_band || ''} className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none" />
                    </div>
                    <div className=" max-w-[80px] ">
                        <p className='pb-2 font-[500]'>Max. Band</p>
                        <input
                            name='max_band'
                            disabled
                            value={selectedStaff?.max_band || ''} className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none" />
                    </div>
                    <div>
                        <p className='pb-2 font-[500]'>Start Date</p>
                        <FlatPickrDateInput
                            name='startdate'
                            id='staffingstartdate'
                            max={selectedStaff?.plannedenddate}
                            min={selectedStaff?.plannedstartdate}
                            value={selectedStaff?.startdate || ''}
                            className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none"
                            onChangeDate={(e) => handleDates(e, selectedStaff?.uId)}
                        />
                    </div>
                    <div>
                        <p className='pb-2 font-[500]'>End Date</p>
                        <FlatPickrDateInput
                            name='enddate'
                            id='staffingenddate'
                            max={selectedStaff?.plannedenddate}
                            min={selectedStaff?.plannedstartdate}
                            value={selectedStaff?.enddate || ''}
                            className="w-full border p-2 rounded-md bg-gray-50  focus:outline-none uppercase hover:outline-none"
                            onChangeDate={(e) => handleDates(e, selectedStaff?.uId)}
                        />
                    </div>
                    <div className=" max-w-[100px] ">
                        <p className='pb-2 font-[500]'>Allocation (%)</p>
                        <input name='allocation'
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target?.value) && (Number(e.target.value) >= 0 && Number(e.target.value) <= 100)) {
                                    handleInputChange(e, selectedStaff?.uId)
                                }
                            }}
                            className="w-full border p-2 rounded-md bg-gray-50 focus:outline-none hover:outline-none" 
                            value={selectedStaff?.allocation} />
                    </div>

                </div>
                <div className='flex justify-end items-center space-x-2 mt-5'>
                    <button disabled={selectedStaff?.userid === null} onClick={() => {
                        saveStaffing()
                        setSelectedStaff(null)
                    }} className='bg-primary px-3 w-[100px] disabled:bg-primary/70 text-white rounded-md py-2'>
                        {loading ? 'Processing...' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default SelectedStaffRow;