import React, { useEffect, useRef } from "react";
import ProfileButton from "src/Components/ProfileButton";
import SidebarMenu from "src/Components/SidebarMenu";
import ProtectedRoute from "./protectedRoutes";
import HorizontalNavbar from "src/Components/SidebarMenu/horizontalNavbar";
import AppLogo from "src/Components/SidebarMenu/appLogo";


const IframeObserver = () => {
    const observerRef = useRef(null);

    useEffect(() => {
        // Callback function to execute when mutations are observed
        const callback = () => {
            try {
                const overlay = document.getElementById("webpack-dev-server-client-overlay");
                if (overlay) {
                    overlay.remove(); // Remove overlay if it exists
                    console.log('Webpack overlay removed.');
                }
            } catch (error) {
                console.error('Error removing the overlay:', error);
            }
        };

        // Create an instance of MutationObserver and pass in the callback
        observerRef.current = new MutationObserver(callback);

        // Start observing the document's body for child additions
        observerRef.current.observe(document.body, { childList: true, subtree: true });

        // Cleanup on unmount
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    return null; // No UI rendered by this component
};


const SidebarAndProtected = () => {
    const ref = useRef(null);
    return (
        <main className="h-[100vh] flex w-full overflow-hidden bg-lightGray">
            <SidebarMenu />
            <div className="w-full h-full">
                <div style={{ zIndex: 10 }} className='relative z-20 flex items-center justify-between w-full pl-4 mb-2 bg-primary '>
                    <AppLogo />
                    <HorizontalNavbar />
                    <div className="flex items-center justify-start space-x-4">
                        <h1 className="text-sm font-medium text-white capitalize ">
                            Hi, {JSON.parse(localStorage.getItem("user"))?.firstname} {JSON.parse(localStorage.getItem("user"))?.lastname}
                        </h1>
                        <ProfileButton />
                    </div>
                </div>
                <div className="h-full flex-grow flex flex-col overflow-hidden text-[#4d4d4d] max-w-[calc(100%-75px)] pl-2 ml-16 w-full ">
                    <ProtectedRoute ref={ref} />
                    <IframeObserver />
                </div>
            </div>
        </main>
    );
};

export default SidebarAndProtected;


// import React from "react";
// import ProfileButton from "src/Components/ProfileButton";
// import SidebarMenu from "src/Components/SidebarMenu";
// import ProtectedRoute from "./protectedRoutes";
// import HorizontalNavbar from "src/Components/SidebarMenu/horizontalNavbar";
// import AppLogo from "src/Components/SidebarMenu/appLogo";

// const SidebarAndProtected = () => {

//     return (
//         <main className="h-[100vh] flex w-full overflow-hidden bg-lightGray">
//             <SidebarMenu />
//             <div className="w-full h-full">
//                 <div style={{zIndex:10}}  className='relative z-20 flex items-center justify-between w-full pl-4 mb-2 bg-primary '>
//                     <AppLogo />
//                     <HorizontalNavbar />
//                     <div className="flex items-center justify-start space-x-4">
//                         <h1 className="text-sm font-medium text-white capitalize "> Hi, {JSON.parse(localStorage.getItem("user"))?.firstname} {JSON.parse(localStorage.getItem("user"))?.lastname}</h1>
//                         <ProfileButton />
//                     </div>
//                 </div>
//                 <div className="h-full flex-grow flex flex-col overflow-hidden text-[#4d4d4d] max-w-[calc(100%-75px)] pl-2 ml-16 w-full ">
//                     <ProtectedRoute />
//                 </div>
//             </div>
//         </main>
//     )

// }


// export default SidebarAndProtected;
