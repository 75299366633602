import React from 'react';
import { Tab } from '@headlessui/react';
import TenantManagment from './tenants';
import SubscriptionManagement from './subscriptions'
import { ToastContainer } from 'react-toastify';

const tabsList = ["Tenants", "Subscriptions"];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TenantLayout = () => {
  return (
    <>
      <div className='h-full mt-4 bg-white rounded-md shadow'>
        <Tab.Group defaultIndex={0}>
          <div className='flex items-center justify-between w-full border-b-2 border-primary '>
            <Tab.List className="py-0.5 text-md border-primary flex justify-between items-center">
              {tabsList.map((item, i) => (
                <Tab
                  key={i}
                  className={({ selected }) =>
                    classNames(
                      'text-sm text-left w-full rounded-lg py-2 font-[500] outline-white',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                      selected ? 'text-primary font-[500]' : 'text-gray-500',
                    )
                  }
                >
                  <span className="px-4">{item}</span>
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <TenantManagment />
            </Tab.Panel>
            <Tab.Panel>
              <SubscriptionManagement />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <ToastContainer
        position="bottom-center"
        newestOnTop limit={1} />
    </>
  );
};

export default TenantLayout;