import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FinancialsFilterLeave from 'src/Filter/projects/financialsFilterLeaves'
import {
    GET_LEAVETYPE_MASTER,
    GET_USER_LEAVE_DATA,
} from 'src/Redux/actions/projects/financials'
import { getDefaultLeavePayload, getLeaveTypePayload } from './defaultPayload'
import Leavetable from './table'
import { GET_LIST_OF_STATUS_BY_E } from 'src/Redux/actions/masterdata/user'

const Leaves = ({ addEditPermission }) => {
    const dispatch = useDispatch()
    const {
        getUserLeave,
        leaveTypeList,
        loading: serviceLoading,
    } = useSelector((state) => state.ProjectFinancial)
    const [userLeave, setUserLeave] = useState([])
    const [leaveId, setLeaveId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [filters, setFilters] = useState(getDefaultLeavePayload())
    const [loading, setLoading] = useState(true)
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [user, setUser] = useState({})

    const getDefaultLeaveData = async () => {
        let res = await dispatch(
            GET_USER_LEAVE_DATA({
                ...filters,
                limit: limit,
                page: page,
                sorttype: sortType?.id,
            }),
        )
        if (res !== null) {
            setUserLeave(res)
        }
    }
    useEffect(
        () => {
            dispatch(GET_LIST_OF_STATUS_BY_E())
            dispatch(GET_USER_LEAVE_DATA(getDefaultLeavePayload(10, 1, 3)))
            setLoading(serviceLoading)
            dispatch(GET_LEAVETYPE_MASTER(getLeaveTypePayload()))
        }, // eslint-disable-next-line
        [dispatch],
    )

    return (
        <>
            <FinancialsFilterLeave
                setUserLeave={setUserLeave}
                filters={filters}
                setFilters={setFilters}
                user={user}
                setUser={setUser}
                leaveTypeList={leaveTypeList}
                page={page}
                limit={limit}
                sortType={sortType}
            />
            <Leavetable
                hooks={{
                    addEditPermission,
                    getUserLeave,
                    loading,
                    filters,
                    setFilters,
                    isOpen,
                    setIsOpen,
                    userLeave,
                    setUserLeave,
                    leaveId,
                    setLeaveId,
                    sortType,
                    setSortType,
                    page,
                    setPage,
                    setLimit,
                    limit,
                    getDefaultLeaveData,
                }}
            />
        </>
    )
}

export default Leaves
