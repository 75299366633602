import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PROFITABILITY_FOR_DASHBOARD } from 'src/Redux/actions/analytics'
import { ProfitabilityItem } from 'src/Services/constant/defaultPayload'
import BarChartDrilldownGM2 from './ProfitabilityDashboard2'
import BarChartDrilldownGM1 from './ProfitabilityDashboard1'

const Profitability = ({ filters, thisPagePermissions }) => {
    const dispatch = useDispatch()
    const { profitabilityDashboard } = useSelector((state) => state?.Analytics)

    useEffect(() => {
        dispatch(GET_PROFITABILITY_FOR_DASHBOARD(ProfitabilityItem()))
    }, [dispatch])

    return (
        <div>
            <div className="grid w-full h-full grid-cols-1 gap-4 p-4 md:grid-cols-2">
                <div className="flex flex-col items-center justify-center w-full h-full p-4">
                    {thisPagePermissions?.includes('View Dashboards') ? (
                        <BarChartDrilldownGM1 profitabilityDashboard={profitabilityDashboard} filters={filters} />
                    ) : (
                        ''
                    )}
                </div>
                <div className="flex items-center justify-center w-full h-full p-4">
                    {thisPagePermissions?.includes('View Dashboards') ? (
                        <BarChartDrilldownGM2 profitabilityDashboard={profitabilityDashboard} filters={filters} />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    )
}

export default Profitability
