import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../default';

const Details = (props) => {
  const { detailSave, detailsData, setDetailsData } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        servicelinejson: [detailsData],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label
            htmlFor='servicelinegroupname'
            className='block font-[500] mb-2'
          >
            Service Line Group Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            maxLength={50}
            type='text'
            title='servicelinegroupname'
            id='servicelinegroupname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Service Line Group Name'
            required=''
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('servicelinegroupname', e.target.value);
              }
            }}
          />
          {!detailsData?.servicelinegroupname && checkvalidation && (
            <small className='text-red-400'>
              Service Line Group Name is required!
            </small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
