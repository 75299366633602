export const DepartmentItem = () => {
   return {
      departmentid: null,
      department: "",
      costcenter_id: null,
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      isactiveflag: true,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: "",
      departmentheadid: 0

   }
}

export const detailNotRequired = [
   'departmentid',
   'costcenter_id',
   'notes'
];
