const initialState = {
    academicQualificationList: [],
    skillList: [],
    educationData: [],
    employeeSummary: [],
    departmentList: [],
    allocationStatus: [],
    previousExperience: [],
    projectsEprofile: [],
    certifications: [],
    courses: [],
    specialisations: null,
    skillareaList: [],
    courseMap: {},
    userSkillList: [],
    proficiencylist: [],
    technologyList: [],
    industrylist: [],
    userSkillsDetail: [],
    empDocumentList: [],
    empDocumentTypeList: [],
    eprofilePermission: [],
}
const EmployeeProfile = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_EDUCATIONBACKGROUND_LIST':
            return {
                ...state,
                academicQualificationList: action.data,
            }
        case 'GET_COURSE_MASTER':
            return {
                ...state,
                courses: action.data,
            }
        case 'GET_COURSE_MAP':
            return {
                ...state,
                courseMap: action.data,
            }
        case 'GET_EMPLOYEE_SUMMARY':
            return {
                ...state,
                employeeSummary: action.data,
            }
        case 'GET_SKILLAREA_MASTER':
            return {
                ...state,
                skillList: action.data,
            }
        case 'EPROFILE_PERMISSIONS':
            return { ...state, eprofilePermission: action.data }
        case 'GET_SPECIALISATION_MASTER':
            return { ...state, specialisations: action.data }
        case 'GET_DEPARTMENTS':
            return { ...state, departmentList: action.data }
        case 'GET_EDUCATION_MASTER':
            return { ...state, educationData: action.data }
        case 'GET_ALLOCATION_STATUS':
            return { ...state, allocationData: action.data }
        case 'GET_PROJECTS_EPROFILE':
            return { ...state, projectsEprofile: action.data }
        case 'GET_CERTIFICATIONS':
            return { ...state, certifications: action.data }
        case 'GET_PREVIOUS_EXPERIENCE':
            return { ...state, previousExperience: action.data }
        case 'SAVE_USEREDUCATION':
            return { ...state, academicQualificationList: action.data }
        case 'GET_SKILL_MASTER':
            return { ...state, userSkillList: action?.data }
        case 'GET_LEVEL_MASTER':
            return { ...state, proficiencylist: action?.data }
        case 'GET_TECHNOLOGY_MASTER':
            return { ...state, technologyList: action?.data }
        case 'GET_INSUDTRY_MASTER':
            return { ...state, industrylist: action?.data }
        case 'GET_SKILLS_EMPLOYEE':
            return { ...state, userSkillsDetail: action?.data }
        case 'GET_EMPLOYEE_DOCUMENT_LIST':
            return { ...state, empDocumentList: action?.data }
        case 'GET_EMPLOYEE_DOCUMENT_TYPE_LIST':
            return { ...state, empDocumentTypeList: action?.data }
        default:
            return state
    }
}

export default EmployeeProfile
