import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { CityItem } from './defaultDetails';
import CreateCity from './createCity/index'
import EditCity from './editCity';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getDefaultCityPayload } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = ['City', 'Code', 'State', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50]

const statusList = [
	{ label: 'Active' },
	{ label: 'Inactive' },
	{ label: 'All' },
]

const CityMasterList = () => {
	const [city, setCity] = useState({})
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [cityId, setCityId] = useState(null)
	const [loading, setLoading] = useState(true)
	const [isOpen, setIsOpen] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [status, setStatus] = useState({ label: 'Active' })
	const [detailsData, setDetailsData] = useState(CityItem())
	const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })

	const dispatch = useDispatch();
	const { cityList, loading: cityLoading, PermissionsOfMasterdata } = useSelector(state => state.UserManagementList)

	useEffect(() => {
		setLoading(true); // Show the loader
		setTimeout(() => {
			setLoading(cityLoading); // Hide the loader after a delay
		}, 100);
	}, [cityLoading]);

	useMemo(() => {
		if (city?.cityname) {
			setSearchTerm(city?.cityname)
		}
	}, [city])

	const handleSorting = async (item) => {
		setSortType(item);
		let res = await dispatch(GET_CITY_MASTER({ ...getDefaultCityPayload(), sorttype: item.id, status: status?.label, limit: limit }));
		if (res !== null) {
			setDetailsData(res.data);
		}
	};

	const handleStatus = async (item) => {
		setStatus(item);
		let res = await dispatch(GET_CITY_MASTER({ ...getDefaultCityPayload(), status: item.label, }));
		if (res === null) {
			setDetailsData(res?.data);
		}
	};

	const handleCityidClick = city => {
		setDetailsData(city)
		setCityId(city?.comcityid)
		setIsOpen(true)
	}

	const handlePagination = async page => {
		let res = await dispatch(GET_CITY_MASTER({ ...getDefaultCityPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }),)
		if (res?.success) {
			setDetailsData(res?.data)
		}
		setPage(page?.selected + 1)
	}

	const handleRows = async row => {
		setLimit(row)
		let res = await dispatch(GET_CITY_MASTER({ ...getDefaultCityPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }),)
		if (res?.success) {
			setDetailsData(res?.data)
		}
	}

	return (
		<div className='mt-4 p-2 py-3 w-full h-screen'>
			<div className='mb-2 flex justify-between items-center'>
				<div className='flex justify-start items-center space-x-5'>
					<h1 className='pb-2 font-[500]'>
						Cities ({cityList?.length > 0 ? cityList[0]?.totalcount : cityList?.length}) </h1>
					<div className='w-72'>
						<CustomAutoSearch
							searchTerm={searchTerm}
							API_CALL_FOR_SEARCH={GET_CITY_MASTER}
							searchPayload={{ ...getDefaultCityPayload(), searchname: searchTerm, }}
							setSearchTerm={setSearchTerm}
							options={cityList}
							idkey='cityname'
							optionlabelKey='cityname'
							getSelectedOption={setCity}
							placeholder='Enter City Name'
							className='p-2'
						/>
					</div>
					<div className='flex justify-start items-center space-x-6'>
						<ReactPaginationStyle
							total={cityList?.length > 0 ? cityList[0]?.totalcount : cityList?.length}
							currentPage={page}
							handlePagination={handlePagination}
							limit={limit}
						/>
						<FilterButtonTab
							setSelectedtab={handleRows}
							selectedTab={limit}
							tabs={rows}
						/>
						<div className=' w-32 '>
							<SelectInput
								options={statusList || []}
								keyname='label'
								selected={status}
								setSelected={handleStatus}
								className='border rounded bg-gray-50 py-2'
							/>
						</div>
						<div className=' w-32 '>
							<SelectInput
								options={basicShortList || []}
								keyname='label'
								selected={sortType}
								setSelected={handleSorting}
								className='border rounded bg-gray-50 py-2'
							/>
						</div>
					</div>
				</div>
				{(PermissionsOfMasterdata.includes("Add/Edit Geographies")) ? <CreateCity /> : ""}
			</div>
			<div className="h-[46vh] overflow-scroll">
				{loading || cityList === null ? (
					<div className='w-full flex justify-center items-center h-[56vh]'>
						<div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
							<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
								Loading...
							</span>
						</div>
					</div>
				) : (
					cityList && (
						<table className='text-sm w-full relative'>
							<thead className='sticky top-0'>
								<tr className='text-left capitalize bg-gray-50'>
									{cols?.map((col) => {
										return (
											<th
												key={col}
												title={col}
												scope='col'
												className='font-[500] p-2 border text-start'
											>
												{col}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody>
								{cityList?.map((item, i) => {
									let { cityname, citycode, statename, isactiveflag, createduser, createdtimestamp, comcityid } = item
									return (
										<tr
											onClick={() => handleCityidClick(item)}
											key={comcityid}
											className='cursor-pointer text-left'>
											<td className='border px-2 py-1.5'><div>{cityname}</div></td>
											<td className='border px-2 py-1.5'><div>{citycode}</div></td>
											<td className='border px-2 py-1.5'><div>{statename}</div></td>
											<td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
											<td className='border px-2'><div>{moment(createdtimestamp).format("DD-MM-YYYY kk:mm:ss")}</div></td>
											<td className='border px-2'><div>{createduser}</div></td>
										</tr>
									);
								})}
							</tbody>
						</table>))}
			</div>
			<EditCity
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				comcityid={cityId}
				setDetailsData={setDetailsData}
				detailsData={detailsData}
				page={page}
				limit={limit}
				sortType={sortType}
				permissions={PermissionsOfMasterdata}
			/>
		</div>
	);
};


export default memo(CityMasterList);