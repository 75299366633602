/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import Selectmanager from './selectmanager';
import { AlertError } from 'src/Services';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired, profileItem } from './defaultRowAndData';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { GET_EMPLOYEE_SUMMARY, GET_SKILLAREA_MASTER } from 'src/Redux/actions/workforce/eprofile';
import { getMasterCityPayload, getMasterSkillAreaData } from 'src/Services/constant/masterdatapayload';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';
import moment from 'moment';


const currentDate = new Date();
currentDate.setFullYear(currentDate.getFullYear() - 18); // Subtract 18 years
const minDate = currentDate.toISOString().split('T')[0]; // Format the minimum date as YYYY-MM-DD

const Profile = (props) => {
   const { profileSave, UserId, detailsData, rm, setRm, canEdit, setCanEdit, empstatus, setEmpStatus } = props;
   const [profileData, setProfileData] = useState(profileItem());
   const [checkvalidation, setCheckvalidation] = useState(false);
   const [filteredLocationList, setFilteredLocationList] = useState([]);
   const [filteredSkillAreaList, setFilteredSkillAreaList] = useState([]);
   const [selectedServiceLineId, setSelectedServiceLineId] = useState('');
   const [city, setCity] = useState({})
   const [searchTerm, setSearchTerm] = useState('')

   const dispatch = useDispatch();
   const EmployeeProfile = useSelector((state) => state.EmployeeProfile);
   const { genderList, locationList, timeType, cityList, nationality, serviceList } = useSelector(state => state.UserManagementList);
   const { workertypelist, workforcelist, designationlist, departmentlist, bandList } = useSelector(state => state.EmployeeDetails);
   const { employeeSummary } = EmployeeProfile;

   useEffect(() => {
      if (city?.cityname) {
         setSearchTerm(city?.cityname)
         const selectedLocationId = locationList?.find(location => location.comcityid === city?.comcityid)
         const Items = locationList?.filter((location) => location.comcityid === city?.comcityid);
         setFilteredLocationList(Items);
         if (selectedLocationId) {
            setProfileData(prevProfileData => ({
               ...prevProfileData,
               location_id: selectedLocationId.comlocationid || null,
            }));
         } else {
            setFilteredLocationList([]);
         }
      }
   }, [city])

   useEffect(() => {
      dispatch(GET_EMPLOYEE_SUMMARY({
         COMUserId: UserId,
      }));
      // eslint-disable-next-line
   }, [UserId]);

   const selectCity = (value) => {
      setCity(value)
      setProfileData((prevProfileData) => ({
         ...prevProfileData,
         city_id: value?.comcityid,
      }));
   }

   useEffect(() => {
      if (profileData?.city_id && locationList) {
         setCity({ cityname: profileData?.cityname, comcityid: profileData?.city_id })
      }
   }, [locationList, profileData?.city_id]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      setCheckvalidation(true);

      if (rm?.comuserid === UserId) {
         AlertError('Reporting Manager cannot be the same as the Employee.');
         return;
      }

      let item = {
         comuserid: UserId || profileData?.comuserid,
         bloodgroup: '',
         dateofjoining: profileData?.dateofjoining,
         photo: null,
         datcomgenderid: profileData?.datcomgenderid,
         dob: profileData?.dob,
         workforcegroupid: profileData?.workforcegroupid,
         anniversarydate: null,
         comtenantid: Number(localStorage.getItem('Tenantid')),
         departmentid: profileData?.departmentid,
         servicelineid: profileData?.servicelineid,
         designationmasterid: profileData?.designationmasterid,
         nationalityid: profileData?.nationalityid,
         band: profileData?.band,
         time_type: profileData?.time_type,
         worker_type: profileData?.worker_type,
         emp_status: empstatus,
         location_id: profileData?.location_id,
         manager_id: rm?.comuserid,
         ModifiedCOMUserID: Number(localStorage.getItem('comuserid')),
         city_id: city?.comcityid,
         totalexperienceinmonths: parseInt(profileData?.totalexperienceinmonths),
         // exitdate: profileData?.exitdate || ,
         skillareamasterid: profileData?.skillareamasterid
      };
      let req = [...detailNotRequired, 'servicelineid', 'skillareamasterid']
      let isValidated = CheckObjectValidation(item, req);
      if (isValidated?.isvalid) {
         let payload = {
            userjson: [item],
         };
         delete payload.userjson[0]?.years;
         delete payload.userjson[0]?.months;
         profileSave(payload);
      }
   };

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);


      if (name === 'years' || name === 'months') {
         let totalMonths = profileData.totalexperienceinmonths || 0;

         if (name === 'years') {
            const yearsNum = parseInt(value, 10) || 0;
            const monthsNum = totalMonths % 12;
            totalMonths = yearsNum * 12 + monthsNum;
         } else if (name === 'months') {
            const yearsNum = Math.floor(totalMonths / 12);
            const monthsNum = parseInt(value, 10) || 0;
            totalMonths = yearsNum * 12 + monthsNum;
         }

         setProfileData({
            ...profileData,
            totalexperienceinmonths: totalMonths,
         });
      }
      else if (name === 'emp_status') {
         if (value === 'Relieved' || value === 'Notice Period') {
            setProfileData({
               ...profileData,
               [name]: value,
               exitdate: profileData?.exitdate,
            });
         }
         else {
            setProfileData({
               ...profileData,
               [name]: value,
               exitdate: profileData?.exitdate,
            });
         }
         setEmpStatus(value);
      } else if (name === 'workforcegroupid') {
         setProfileData((prevProfileData) => ({
            ...prevProfileData,
            [name]: value
         }));
      }
      if (name === 'servicelineid') {
         setSelectedServiceLineId(value);
         // Clear skill area when changing service line
         setProfileData(prevProfileData => ({
            ...prevProfileData,
            [name]: value,
         }));
      } else if (name === 'skillareamasterid') {
         // Manually selected skill area, update profileData
         setProfileData(prevProfileData => ({
            ...prevProfileData,
            [name]: value,
         }));
      } else {
         // Handle other input changes
         setProfileData(prevProfileData => ({
            ...prevProfileData,
            [name]: value,
         }));
      }
   };

   const handleRm = (select) => {
      setRm(select);
      setProfileData({ ...profileData, manager_id: select.comuserid });
   };

   useEffect(() => {
      if (employeeSummary?.length > 0) {
         let rmUnwanted = employeeSummary?.map((it) => {
            delete it['createdtimestamp'];
            delete it['modifiedtimestamp'];
            if (!it?.city_id) {
               setCity({})
               setSearchTerm('')
            }
            return it;
         });
         setRm({
            comuserid: rmUnwanted[0]?.manager_id,
            firstname: rmUnwanted[0]?.managername?.trim() || '',
         });
         setProfileData(rmUnwanted[0]);
      }

      // eslint-disable-next-line
   }, [employeeSummary]);

   useEffect(() => {
      const fetchData = async () => {
         try {
            if (profileData?.servicelineid) {
               let payload = {
                  ...getMasterSkillAreaData(),
                  servicelineid: profileData.servicelineid,
               };
               const res = await dispatch(GET_SKILLAREA_MASTER(payload));
               const filteredData = res?.data;
               setFilteredSkillAreaList(filteredData);
            }
         } catch (error) {
            console.error("Error fetching skill area data:", error);
         }
      };
      fetchData();
   }, [profileData?.servicelineid, selectedServiceLineId, dispatch]);

   return (
      <div className='w-full h-[65vh] text-sm mb-10 overflow-y-auto'>
         {profileData?.comuserid ? <div className='grid grid-cols-4 gap-5 mt-4'>
            <div>
               <label htmlFor='gender' className='block font-[500] mb-2'>
                  Gender
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={genderList || []}
                  value={profileData?.datcomgenderid || ''}
                  noDataLabel='No Gender'
                  name='datcomgenderid'
                  labelkey='gender'
                  className='p-2 bg-gray-100 border rounded'
                  onChange={handleChangeInput}
                  id='datcomgenderid'
               />
               {!profileData?.datcomgenderid && checkvalidation && (
                  <small className='text-red-400'>Gender is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='workforcegroup' className='block font-[500] mb-2'>
                  Work Force Group
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={workforcelist || []}
                  value={profileData?.workforcegroupid}
                  noDataLabel='No Workforce found'
                  name='workforcegroupid'
                  labelkey='workforcegroup'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='wfgid'
               />
               {!profileData?.workforcegroupid && checkvalidation && (
                  <small className='text-red-400'>
                     Work Force Group is required!
                  </small>
               )}
            </div>
            <div>
               <label htmlFor='dob' className='block font-[500] mb-2'>
                  Date of Birth
                  <span className='text-red-300'> *</span>
               </label>
               <FlatPickrDateInput
                  disabled={canEdit}
                  max={minDate}
                  name='dob'
                  value={profileData?.dob || ''}
                  id='holiday'
                  className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                  onChangeDate={e => handleChangeInput('dob', e.target.value)}
               />
               {!profileData?.dob && checkvalidation && (
                  <small className='text-red-400'>
                     Date of Birth is required!{' '}
                  </small>
               )}
            </div>
            <div>
               <label htmlFor='dateofjoining' className='block font-[500] mb-2'>
                  Date of Joining
                  <span className='text-red-300'> *</span>
               </label>
               <FlatPickrDateInput
                  disabled={canEdit || !profileData?.dob}
                  min={profileData?.incorporationdate || ''}
                  max={moment(new Date()).format('YYYY/MM/DD')}
                  name='dateofjoining'
                  value={profileData?.dateofjoining || ''}
                  id='dateofjoining'
                  className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                  onChangeDate={e => handleChangeInput('dateofjoining', e.target.value)}
               />
               {!profileData?.dateofjoining && checkvalidation && (
                  <small className='text-red-400'>
                     Date of Joining is required!
                  </small>
               )}
            </div>
            <div>
               <label htmlFor='designation' className='block font-[500] mb-2'>
                  Designation
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={designationlist || []}
                  value={profileData?.designationmasterid}
                  noDataLabel='No Designation'
                  name='designationmasterid'
                  labelkey='designation'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='designationmasterid'
               />
               {!profileData?.designationmasterid && checkvalidation && (
                  <small className='text-red-400'>Designation is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='department' className='block font-[500] mb-2'>
                  Department
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={departmentlist || []}
                  value={profileData?.departmentid}
                  noDataLabel='No Department'
                  name='departmentid'
                  labelkey='department'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='departmentid'
               />
               {!profileData?.departmentid && checkvalidation && (
                  <small className='text-red-400'>Department is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='serviceline' className='block font-[500] mb-2'>
                  Service Line <span className='text-red-300'> *</span>
               </label>{' '}
               <CustomhtmlSelect
                  options={serviceList || []}
                  value={profileData?.servicelineid}
                  noDataLabel='No Serviceline'
                  name='servicelineid'
                  labelkey='servicelinename'
                  className='p-2 border rounded bg-gray-50'
                  disabled={canEdit}
                  onChange={handleChangeInput}
                  id='servicelineid'
               />
               {(!profileData?.servicelineid && checkvalidation) && <small className='text-red-400'>Service Line is required!</small>}
            </div>
            <div>
               <label htmlFor='skillareamasterid' className='block font-[500] mb-2'>
                  Skill Area <span className='text-red-300'> *</span>
               </label>{' '}
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={filteredSkillAreaList}
                  value={profileData?.skillareamasterid}
                  noDataLabel='No Skill Area'
                  name='skillareamasterid'
                  labelkey='skillarea'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='skillareamasterid'
               />
               {(!profileData?.skillareamasterid && checkvalidation) && <small className='text-red-400'>Skill Area is required!</small>}
            </div>
            <div>
               <label htmlFor='bandname' className='block font-[500] mb-2'>
                  Band
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={bandList || []}
                  value={profileData?.band}
                  noDataLabel='No Band'
                  name='band'
                  labelkey='bandname'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='bandmasterid'
               />
               {!profileData?.band && checkvalidation && (
                  <small className='text-red-400'>Band is required!</small>
               )}
            </div>
            <div>
               <Selectmanager
                  UserId={UserId}
                  rm={rm}
                  setRm={handleRm}
                  companyid={detailsData?.payrollcompanyid}
                  searchStr={rm?.managername}
                  canEdit={canEdit}
               />
               {!profileData?.manager_id && checkvalidation && (
                  <small className='text-red-400'>Reporting Manager is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='nationality' className='block font-[500] mb-2'>
                  Nationality
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={nationality || []}
                  value={profileData?.nationalityid}
                  noDataLabel='No Location'
                  name='nationalityid'
                  labelkey='nationality'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='nationalityid'
               />
               {!profileData?.nationalityid && checkvalidation && (
                  <small className='text-red-400'>Nationality is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='city_id' className='block font-[500] mb-2'>
                  City
                  <span className='text-red-300'> *</span>
               </label>
               <CustomAutoSearch
                  disabled={canEdit}
                  // searchApiCall={searchApiCall}
                  searchTerm={searchTerm}
                  API_CALL_FOR_SEARCH={GET_CITY_MASTER}
                  searchPayload={{ ...getMasterCityPayload(), searchname: searchTerm, }}
                  setSearchTerm={setSearchTerm}
                  options={cityList}
                  idkey='comcityid'
                  optionlabelKey='cityname'
                  getSelectedOption={selectCity}
                  className='block w-full p-2 text-gray-600 border border-gray-200 rounded-lg bg-gray-50 focus:ring-primary-600 focus:border-primary-600'
               />
               {!profileData?.city_id && checkvalidation && (
                  <small className='text-red-400'>City is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='locationname' className='block font-[500] mb-2'>
                  Location
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={filteredLocationList}
                  value={profileData?.location_id}
                  noDataLabel='No Location'
                  name='location_id'
                  labelkey='locationname'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='comlocationid'
               />
               {!profileData?.location_id && checkvalidation && (
                  <small className='text-red-400'>Location is required!</small>
               )}
            </div>
            {/* <div>
               <h1 className='mb-2 font-[500]'>
                  Status<span className='text-red-300'> *</span>
               </h1>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={statusListByType || []}
                  value={empstatus}
                  noDataLabel='No Status'
                  name='emp_status'
                  labelkey='statusname'
                  className='p-2 bg-gray-100 border rounded'
                  onChange={handleChangeInput}
                  id='statusid'
               />
               {(!profileData?.emp_status || !empstatus) && checkvalidation && (
                  <small className='text-red-400'>
                     Employee Status is required!{' '}
                  </small>
               )}
            </div> */}
            <div>
               <label htmlFor='workertype' className='block font-[500] mb-2'>
                  Worker Type
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={workertypelist || []}
                  value={profileData?.worker_type}
                  noDataLabel='No Worker Type'
                  name='worker_type'
                  labelkey='workertype'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='workertypeid'
               />
               {!profileData?.worker_type && checkvalidation && (
                  <small className='text-red-400'>Worker type is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='timetype' className='block font-[500] mb-2'>
                  Time Type
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={timeType || []}
                  value={profileData?.time_type}
                  noDataLabel='No Time Type'
                  name='time_type'
                  labelkey='timetype'
                  className='p-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='timetypeid'
               />
               {!profileData?.time_type && checkvalidation && (
                  <small className='text-red-400'>Time type is required!</small>
               )}
            </div>
            <div>
               <label
                  htmlFor='totalexperienceinmonths'
                  className='block font-[500] mb-2'
               >
                  Previous Experience (Months)
               </label>
               <div className='flex items-center justify-start space-x-2 '>
                  <input
                     disabled={canEdit}
                     value={
                        profileData?.totalexperienceinmonths !== undefined
                           ? Math.floor(profileData.totalexperienceinmonths / 12)
                           : ''
                     }
                     onChange={(e) => handleChangeInput('years', e.target.value)}
                     name='years'
                     type='number'
                     id='years'
                     className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                     placeholder='Years'
                  />
                  <input
                     disabled={canEdit}
                     onChange={(e) => handleChangeInput('months', e.target.value)}
                     value={
                        profileData?.totalexperienceinmonths !== undefined
                           ? profileData.totalexperienceinmonths % 12
                           : ''
                     }
                     name='months'
                     type='number'
                     id='months'
                     min='0'
                     max='11'
                     className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                     placeholder='Months'
                  />
                  <input
                     disabled={canEdit}
                     value={profileData?.totalexperienceinmonths || ''}
                     name='totalexperienceinmonths'
                     type='number'
                     id='totalexperienceinmonths'
                     className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
                     placeholder='Total Months'
                     readOnly
                  />
               </div>

            </div>

         </div> : ''}
         <div className='fixed bottom-0 mt-4 right-6'>
            {!canEdit && (
               <div className='fixed bottom-0 mt-2 right-6'>
                  <div className='flex items-center justify-end gap-2'>
                     <PrimaryButton
                        onClick={() => setCanEdit(false)}
                        type=''
                        className='p-2 px-8 mb-4 text-black bg-white border rounded border-primary h-9'
                     >
                        Cancel
                     </PrimaryButton>
                     <PrimaryButton
                        disabled={canEdit}
                        className='p-2 px-8 mb-4 text-white rounded bg-primary h-9'
                        onClick={handleSubmit}
                     >
                        Save
                     </PrimaryButton>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default memo(Profile);