export const RolesItem = () => {
  return {
    roleid: null,
    rolename: '',
    code: '',
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    activeflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: '',
    issystemrole: false,
    rolemenus: [
      {
        datmenuid: 0,
        // isselected: false,
        isfullrights: true,
      },
      {
        datmenuid: 0,
        // isselected: false,
        isfullrights: true,
      },
      {
        datmenuid: 0,
        // isselected: false,
        isfullrights: true,
      },
      {
        datmenuid: 0,
        // isselected: false,
        isfullrights: false,
        rolemenupermissions: [
          {
            datmenuid: 0,
            datmenupermissionid: 0,
          },
          {
            datmenuid: 0,
            datmenupermissionid: 0,
          },
        ],
      },
    ],
  };
};

// {

//   "rolemenupermissionsjson": [
// {
// "roleid": 10,
// "rolename": "New Development  Manager",
// "code": "TM91",
// "activeflag": true,
// "comtenantid": 3,
// "createdcomuserid": 1005,
// "notes": "",
// "rolemenus":[
//   {

//   "datmenuid": 7,
//   "isselected":false,
//   "isfullrights":true
//   },
//   {

//   "datmenuid":8,
//   "isselected":true,
//   "isfullrights":false,
//   "rolemenupermissions":
//     [
//       {
//         "datmenuid": 8,
//         "datmenupermissionid": 32
//       },
//       {
//         "datmenuid": 8,
//         "datmenupermissionid": 33
//       }
//     ]
//   },
//   {

//   "datmenuid":9,
//   "isselected":true,
//   "isfullrights":true
//   },
//   {

//   "datmenuid":10,
//   "isselected":false,
//   "isfullrights":true
//   },
//   {

//   "datmenuid":11,
//   "isselected":true,
//   "isfullrights":true
//   },
//   {

//   "datmenuid":12,
//   "isselected":true,
//   "isfullrights":true
//   }
// ]
// }
// ]

// }
