import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextIcon, PreIcon } from "src/Assets/icons"
import { GET_CUSTOMER_MASTERLIST } from 'src/Redux/actions/masterdata/customer'
import {
	GET_SERVICELINE_MASTER,
} from 'src/Redux/actions/masterdata/user'
import { GET_LIST_TYPE_OF_PROJECT, GET_PROJECT_LIST_BILLING_TYPE } from 'src/Redux/actions/projects'
import { getCustomerListPayload } from 'src/Services/constant/Customerpayload'
import {
	getDefaultBillingIdPayload,
	getDefaultProjectTypePayload,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import PieChartServiceLine from './byServiceLine'
import PieChartProjectType from './byType'
// import PieChartCustomer from './byAccount'
import PieChartBilling from './byBilling'
import PieChartDuration from './byDuration'
import PieChartProjectStatus from './byType'
import PieChartCustomer from './pieChartHigh'

const AnalyticProjects = () => {
	const dispatch = useDispatch()
	const [showMore, setShowMore] = useState(true)
	const { serviceList } = useSelector(state => state?.UserManagementList)
	const { listOfCustomers } = useSelector(state => state?.CustomerManagementList)
	const { typesListProject, listOfBillingProjects } = useSelector(state => state?.Projects)

	useEffect(() => {
		dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
		dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload()))
		dispatch(GET_LIST_TYPE_OF_PROJECT(getDefaultProjectTypePayload(0)))
		dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
	}, [dispatch])


	return (
		<div className='w-full '>
			<div className='absolute flex items-center justify-start space-x-2 top-2 right-2 '>
				<button disabled={showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  w-8 rounded-md  text-secondary'>
					{PreIcon}
				</button>
				<button disabled={!showMore} onClick={() => setShowMore(!showMore)} className='disabled:bg-secondary/60 disabled:border-0 z-10  flex justify-center items-center font-[500  w-8 rounded-md text-secondary'>
					{NextIcon}
				</button>
			</div>
			<div className='flex justify-center'>
				<div className='grid w-full h-full grid-cols-3 py-2'>
					{showMore ?
						<>
							<div className='relative w-full h-80'>
								<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
									By Type
								</h3>
								<PieChartProjectType typesListProject={typesListProject} />
							</div>
							<div className='relative w-full h-80'>
								<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
									By Status
								</h3>
								<PieChartProjectStatus />
							</div>
							<div className='relative w-full h-80'>
								<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
									By Service Line
								</h3>
								<PieChartServiceLine serviceList={serviceList} />
							</div>

						</> : <>
							<div className='relative w-full h-80'>
								{/* <h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
									By Account
								</h3> */}
								<PieChartCustomer listOfCustomers={listOfCustomers} />
							</div>
							<div className='relative w-full h-80'>
								<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
									By Billing Type
								</h3>
								<PieChartBilling listOfBillingProjects={listOfBillingProjects} />
							</div>
							<div className='relative w-full h-80'>
								<h3 className='absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2'>
									By Duration
								</h3>
								<PieChartDuration />
							</div>
						</>
					}

				</div>
			</div>
		</div>
	)
}

export default AnalyticProjects
