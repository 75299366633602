import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import { useDispatch } from 'react-redux'
import { GET_PROJECT_REVENUE } from 'src/Redux/actions/projects/financials'
import EditDetails from './editRevenue'
import UploadRevenue from './uploadRevenue'
import DownloadAsExcel from './downloadRevenue'
import { basicShortList } from 'src/Services/constant/staticValues'

const rows = [10, 25, 50]
const cols = [
    'Mon & Year',
    'Currency',
    'actual revenue',
    'Profit Center Code',
    'Invoice number',
    'Transaction date',
    'Transaction Id',
]

const Revenuetable = ({ hooks }) => {
    const {
        ProjectRevenue,
        filters,
        setIsOpen,
        isOpen,
        project,
        revenue,
        setRevenue,
        setRevId,
        // setProject,
        projectsOfM,
        loading,
        addEditPermission,
        sortType,
        setSortType,
        page,
        setPage,
        setLimit,
        limit,
        getDefaultRevenueData,
    } = hooks
    const dispatch = useDispatch()

    const handleSorting = async (item) => {
        setSortType(item)
        let res = await dispatch(
            GET_PROJECT_REVENUE({
                ...filters,
                Limit: limit,
                Page: page,
                Projectid: project?.projectid,
                Sorttype: item?.id,
            }),
        )
        if (res !== null) {
        }
    }

    const handlePagination = async (page) => {
        let res = await dispatch(
            GET_PROJECT_REVENUE({
                ...filters,
                Projectid: project?.projectid,
                Limit: limit,
                Page: page?.selected + 1,
                Sorttype: sortType?.id,
            }),
        )
        if (res !== null) {
        }
        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        let res = await dispatch(
            GET_PROJECT_REVENUE({
                ...filters,
                Projectid: project?.projectid,
                Limit: row,
                Page: row > 10 ? 1 : page,
                Sorttype: sortType?.id,
            }),
        )
        if (res !== null) {
        }
    }

    const handleComClick = (com) => {
        setRevenue(com)
        setRevId(com?.projrevid)
        setIsOpen(true)
    }

    return (
        <div className="p-2 h-[70vh]">
            <div className="flex items-center justify-between py-4">
                <div className="flex items-center justify-start space-x-4">
                    <p className="text-primary font-[500] inline-flex justify-start items-center ">
                        Revenue
                        <span>
                            (
                            {ProjectRevenue?.length > 0
                                ? ProjectRevenue[0]?.totalcount
                                : ProjectRevenue?.length}
                            )
                        </span>
                    </p>
                    <ReactPaginationStyle
                        total={
                            ProjectRevenue?.length > 0
                                ? ProjectRevenue[0]?.totalcount
                                : ProjectRevenue?.length
                        }
                        currentPage={page}
                        handlePagination={handlePagination}
                        limit={limit}
                    />
                    <div className="w-32 ">
                        <FilterButtonTab
                            setSelectedtab={handleRows}
                            selectedTab={limit}
                            tabs={rows}
                        />
                    </div>
                    <div>
                        <div className="w-32 ">
                            <SelectInput
                                options={basicShortList || []}
                                keyname="label"
                                selected={sortType}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                </div>
                {addEditPermission ? (
                    <div className="flex justify-end w-full space-x-2">
                        <UploadRevenue hooks={{ getDefaultRevenueData, projectsOfM }} />
                        <DownloadAsExcel fileName="Revenue" apiData={[]} />
                        {/* DONT REMOVE THIS */}
                        {/* <AddRevenue hooks={{ project, setProject, getDefaultRevenueData }} /> */}
                    </div>
                ) : (
                    ''
                )}
            </div>
            {loading ? (
                <div className="flex items-center justify-center w-full h-[56vh]">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[56vh] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {cols?.map((col) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="font-[500] px-2 py-2 border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {ProjectRevenue?.map((item, i) => {
                                const {
                                    monthname,
                                    yearid,
                                    actualrevenue,
                                    currency,
                                    profitcentercode,
                                    transactiondate,
                                    invoiceno,
                                    uId,
                                    transactionid,
                                } = item
                                return (
                                    <tr
                                        key={uId}
                                        onClick={() => handleComClick(item)}
                                        className="text-left cursor-pointer"
                                    >
                                        <td className="px-2 py-1 border">
                                            <div className="w-44">
                                                {monthname.substring(0, 3)} {yearid}
                                            </div>
                                        </td>
                                        <td className="px-2 py-1 border">{currency}</td>
                                        <td className="px-2 py-1 border">
                                            {actualrevenue}
                                        </td>
                                        <td className="px-2 py-1 border">
                                            {profitcentercode}
                                        </td>

                                        <td className="px-2 py-2 border">{invoiceno}</td>
                                        <td className="px-2 py-2 border">
                                            {transactiondate}
                                        </td>
                                        <td className="px-2 py-2 border">
                                            {transactionid}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div>
                        <EditDetails
                            hooks={{
                                addEditPermission,
                                ProjectRevenue,
                                project,
                                setRevenue,
                                revenue,
                                setRevId,
                                isOpen,
                                setIsOpen,
                                getDefaultRevenueData,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Revenuetable
