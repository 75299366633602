import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Item } from './createWorkerType/defaultPayload';
import moment from 'moment'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import { GET_EDUCATION_LIST_MASTER } from 'src/Redux/actions/masterdata/user'
import { getDefaultEducationListPayload } from 'src/Services/constant/defaultPayload'
import { basicShortList } from 'src/Services/constant/staticValues'
import CreateQualification from './createQualification'
import { QualificationItem } from './defaultPayload'
import EditEducation from './editQualification'

const cols = ['Qualification', 'Status', 'Created At', 'Created By']

const rows = [10, 25, 50]

const statusList = [{ label: 'Active' }, { label: 'Inactive' }, { label: 'All' }]

const QualificationsMasterList = () => {
    const [education, setEducation] = useState({})
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [educationId, setEducationId] = useState(null)
    const [status, setStatus] = useState({ label: 'Active' })
    const [detailsData, setDetailsData] = useState(QualificationItem())
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const dispatch = useDispatch()
    const { educationList, loading: departmentLoading, PermissionsOfMasterdata } = useSelector((state) => state?.UserManagementList)

    useEffect(() => {
        setLoading(true) // Show the loader
        setTimeout(() => {
            setLoading(departmentLoading) // Hide the loader after a delay
        }, 100)
    }, [departmentLoading])

    useMemo(() => {
        if (education?.educationmaster) {
            setSearchTerm(education?.educationmaster);
        }
    }, [education]);

    const handleSorting = async (item) => {
        setSortType(item)
        let res = await dispatch(
            GET_EDUCATION_LIST_MASTER({
                ...getDefaultEducationListPayload(),
                sorttype: item.id,
                status: status?.label,
                limit: limit,
            }),
        )
        if (res !== null) {
            setDetailsData(res.data)
        }
    }

    const handleStatus = async (item) => {
        setStatus(item)
        let res = await dispatch(GET_EDUCATION_LIST_MASTER({ ...getDefaultEducationListPayload(), status: item.label }))
        if (res === null) {
            setDetailsData(res?.data)
        }
    }

    const handlePagination = async (page) => {
        let res = await dispatch(
            GET_EDUCATION_LIST_MASTER({
                ...getDefaultEducationListPayload(),
                limit,
                page: page?.selected + 1,
                sorttype: sortType?.id,
                status: status?.label,
            }),
        )
        if (res?.success) {
            setDetailsData(res?.data)
        }
        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        let res = await dispatch(
            GET_EDUCATION_LIST_MASTER({
                ...getDefaultEducationListPayload(),
                limit: row,
                page: page?.selected + 1,
                sorttype: sortType?.id,
                status: status?.label,
            }),
        )
        if (res?.success) {
            setDetailsData(res?.data)
        }
    }

    const handleEducationClick = (education) => {
        setDetailsData(education)
        setEducationId(education?.dateducationmasterid)
        setIsOpen(true)
    }

    return (
        <div className="w-full h-screen p-2 py-3 mt-4">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center justify-start space-x-5">
                    <h1 className="pb-2 font-[500]">
                        Qualifications  ({educationList?.length > 0 ? educationList[0]?.totalcount : educationList?.length})
                    </h1>
                    <div className="w-72">
                        <CustomAutoSearch
                            searchTerm={searchTerm}
                            API_CALL_FOR_SEARCH={GET_EDUCATION_LIST_MASTER}
                            searchPayload={{ ...getDefaultEducationListPayload(), searchname: searchTerm }}
                            setSearchTerm={setSearchTerm}
                            options={educationList}
                            idkey="educationmaster"
                            optionlabelKey="educationmaster"
                            getSelectedOption={setEducation}
                            placeholder="Enter Education Name"
                            className="p-2"
                        />
                    </div>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={educationList?.length > 0 ? educationList[0]?.totalcount : educationList?.length}
                            currentPage={page}
                            handlePagination={handlePagination}
                            limit={limit}
                        />
                        <FilterButtonTab setSelectedtab={handleRows} selectedTab={limit} tabs={rows} />
                    </div>
                    <div className="w-32 ">
                        <SelectInput
                            options={statusList || []}
                            keyname="label"
                            selected={status}
                            setSelected={handleStatus}
                            className="py-2 border rounded bg-gray-50"
                        />
                    </div>
                    <div className="w-32 ">
                        <SelectInput
                            options={basicShortList || []}
                            keyname="label"
                            selected={sortType}
                            setSelected={handleSorting}
                            className="py-2 border rounded bg-gray-50"
                        />
                    </div>
                </div>
                {(PermissionsOfMasterdata.includes("Add/Edit Education")) ? <CreateQualification /> : ""}
            </div>
            <div className="h-[46vh] overflow-scroll">
                {loading || educationList === null ? (
                    <div className="w-full flex justify-center items-center h-[56vh]">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                Loading...
                            </span>
                        </div>
                    </div>
                ) : (
                    educationList && (
                        <table className="relative w-full text-sm">
                            <thead>
                                <tr className="text-left capitalize bg-gray-50">
                                    {cols?.map((col) => {
                                        return (
                                            <th
                                                key={col}
                                                title={col}
                                                scope="col"
                                                className="font-[500] p-2 border text-start"
                                            >
                                                {col}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {educationList?.map((item, i) => {
                                    const {
                                        isactiveflag,
                                        educationmaster,
                                        createdtimestamp,
                                        createduser,
                                        dateducationmasterid,
                                    } = item
                                    return (
                                        <tr
                                            onClick={() => handleEducationClick(item)}
                                            key={dateducationmasterid}
                                            className="text-left cursor-pointer"
                                        >
                                            <td className="border px-2 py-1.5">
                                                <div>{educationmaster}</div>
                                            </td>
                                            <td className="px-2 border">
                                                <div>{isactiveflag === true ? 'Active' : 'Inactive'}</div>
                                            </td>
                                            <td className="px-2 border">
                                                <div> {moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div>
                                            </td>
                                            <td className="px-2 border">
                                                <div>{createduser}</div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                )}
            </div>
            <EditEducation
                detailsData={detailsData}
                setDetailsData={setDetailsData}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                educationid={educationId}
                page={page}
                limit={limit}
                sortType={sortType}
                permissions={PermissionsOfMasterdata}
            />
        </div>
    )
}

export default QualificationsMasterList
