import { useEffect } from 'react'

const RoleDetails = ({ demandData }) => {
    useEffect(() => {
        if (demandData[0]?.demandroledetails?.length > 0) {
        }
    }, [demandData])

    return (
        <div className="p-3 text-sm">
            {demandData[0]?.demandroledetails && (
                <div className="my-2">
                    <p>
                        Role Name :{' '}
                        <span className="font-normal text-gray-500">
                            {demandData[0]?.demandroledetails[0]?.jobtitle}
                        </span>
                    </p>
                    <p>
                        Min Band :{' '}
                        <span className="font-normal text-gray-500">
                            {demandData[0]?.demandroledetails[0]?.min_band}
                        </span>
                    </p>
                    <p>
                        Max Band :{' '}
                        <span className="font-normal text-gray-500">
                            {demandData[0]?.demandroledetails[0]?.max_band}
                        </span>
                    </p>
                    <p>
                        Min Experience :{' '}
                        <span className="font-normal text-gray-500">
                            {demandData[0]?.demandroledetails[0]?.experienceinyear} Years
                        </span>
                    </p>
                </div>
            )}
            {demandData[0]?.demandroledetails && (
                <div>
                    <h1 className="mt-4">Role Summary</h1>
                    <p className="text-gray-500">
                        {demandData[0]?.demandroledetails[0]?.rolesummary}
                    </p>
                    <h1 className="mt-4">Role Responsibilities</h1>
                    <div className="text-gray-500">
                        {demandData[0]?.demandroledetails[0]?.roleresponsibilities?.map(
                            (responsibility, index) => (
                                <ul key={index}>
                                    {index + 1}
                                    {'.'} {responsibility?.responsibilities}
                                </ul>
                            ),
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default RoleDetails
