

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useMemo, useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import StaffingTable from './table'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PROJECT_STAFFING_LIST
    // ,SYNC_STAFFING_DATA
} from 'src/Redux/actions/projects/staffing'
import { HANDE_STAFFING_APPROVAL_TALENT } from 'src/Redux/actions/workforce/talent'
import { VerifyUsers } from 'src/Assets/icons'
import IconButton from 'src/Components/Buttons/IconButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { findUniqueById } from 'src/Services'

const addAllLabel = (data, id, label) => {
    let defaultname = ''
    if (id === 'projectid') {
        defaultname = "All Projects"
    } else if (id === 'userid') {
        defaultname = 'All Employees'
    } else {
        defaultname = 'All Statuses'
    }
    if (data?.length) {
        return findUniqueById([{ [id]: 0, [label]: defaultname }, ...data], id, label)
    }
}

const AddApprovals = () => {
    const listOfProjectStaffing = useSelector(state => state?.ProjectStaffing?.listOfProjectStaffing)
    const listOfStaffWithType = useSelector((state) => state.ProjectStaffing?.listOfStaffWithType);
    const dispatch = useDispatch()
    let [isOpen, setIsOpen] = useState(false)
    let [staffingData, setstaffingData] = useState([])
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line
    // const [syncLoading, setSyncLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    const [filters, setFilters] = useState({})

    const getStatusIdOfStaff = (data)=>{
        let SSTFAPRVAL = data?.find(i=> i?.statuscode === "SSTFAPRVAL")?.statusid
        let SRELAPRVAL = data?.find(i=> i?.statuscode === "SRELAPRVAL")?.statusid
        let SEXTAPRVAL = data?.find(i=> i?.statuscode === "SEXTAPRVAL")?.statusid
        
        return [SSTFAPRVAL, SRELAPRVAL, SEXTAPRVAL]
    }

    function openModal() {
        dispatch(GET_PROJECT_STAFFING_LIST(0, getStatusIdOfStaff(listOfStaffWithType), 1, 100))
        setIsOpen(true)
    }

    useMemo(() => {
        setstaffingData(listOfProjectStaffing || [])
    }, [listOfProjectStaffing])

    const handleCancel = () => {
        let updateStatus = staffingData?.map((item) => {
            delete item['StaffingStatusId']
            return item
        })
        setstaffingData([...updateStatus])
        setEdit(false)
        setIsOpen(false)
    }

    const handleApprovals = async () => {
        setLoading(true)
        await dispatch(HANDE_STAFFING_APPROVAL_TALENT(staffingData))
        await dispatch(GET_PROJECT_STAFFING_LIST(0, getStatusIdOfStaff(listOfStaffWithType), 1, 100))
        setLoading(false)
    }
    // Future use ---------------------- 
    // eslint-disable-next-line
    // const handleSyncStaffingData = async () => {
    //     setSyncLoading(true)
    //     await dispatch(SYNC_STAFFING_DATA())
    //     setSyncLoading(false)
    // }

    const handleFilter = (name, value) => {
        setFilters(prevPayload => ({
            ...prevPayload,
            [name]: value
        }));
    }

    return (
        <>
            <div className='flex justify-start items-center'>
                {/* <IconButton
                    title='Sync Staffing Data'
                    disabled={syncLoading}
                    onClick={handleSyncStaffingData}
                    className='text-white w-9 disabled:bg-primary/80 h-9 rounded-full bg-primary  focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
                    {syncLoading ? <div className='w-7 h-7 flex justify-center items-center'>
                        <div
                            className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                Loading...
                            </span>
                        </div>
                    </div> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>}
                </IconButton> */}
                <IconButton
                    title='Staffing Approval'
                    onClick={openModal}
                    className='text-white w-9 h-9 rounded-full bg-primary  focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
                    {VerifyUsers}
                </IconButton>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={handleCancel}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="
  w-[calc(90%)] 
  md:w-[calc(80%)] 
  md:w-[calc(80%)] 
  h-[90vh] 
  py-4 
  transform 
  rounded-2xl 
  bg-white 
  text-left 
  align-middle 
  shadow-xl 
  transition-all
">
                                    <div className='relative px-1 w-full'>
                                        <div className='w-full mb-2 flex justify-between items-center px-2'>
                                            <div className='flex justify-start items-center space-x-4'>
                                                <h3 className=' text-lg font-[500] leading-6 text-primary'>Staffing Approvals</h3>
                                                <div className='w-44'>
                                                    <CustomhtmlSelect
                                                        options={addAllLabel(staffingData, 'projectid', 'projectname') || []}
                                                        displayLabel='All Projects'
                                                        value={filters?.projectid || null}
                                                        noDataLabel='No Project'
                                                        name='projectid'
                                                        labelkey='projectname'
                                                        className='border rounded bg-gray-50 py-1.5 text-xs'
                                                        onChange={handleFilter}
                                                        id='projectid'
                                                    />
                                                </div>
                                                <div className='w-44'>
                                                    <CustomhtmlSelect
                                                        options={addAllLabel(staffingData, 'staffingstatusid', 'statffingstatus') || []}
                                                        displayLabel='All Statuses'
                                                        value={filters?.staffingstatusid || null}
                                                        noDataLabel='No Status'
                                                        name='staffingstatusid'
                                                        labelkey='statffingstatus'
                                                        className='border rounded bg-gray-50 py-1.5 text-xs'
                                                        onChange={handleFilter}
                                                        id='staffingstatusid'
                                                    />
                                                </div>
                                                <div className='w-44'>
                                                    <CustomhtmlSelect
                                                        options={addAllLabel(staffingData, 'userid', 'username') || []}
                                                        displayLabel='All Employees'
                                                        value={filters?.userid || null}
                                                        noDataLabel='No User'
                                                        name='userid'
                                                        labelkey='username'
                                                        className='border rounded bg-gray-50 py-1.5 text-xs'
                                                        onChange={handleFilter}
                                                        id='userid'
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                onClick={handleCancel}
                                                type='button'
                                                className='rounded-full border h-10 w-10 flex justify-center items-center'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    strokeWidth={1.5}
                                                    stroke='currentColor'
                                                    className='w-4 h-4'
                                                >
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M6 18L18 6M6 6l12 12'
                                                    />
                                                </svg>
                                                <span className='sr-only'>Close icon</span>
                                            </button>
                                        </div>
                                        <div className='px-3 w-full overflow-y-scroll min-h-[60vh] h-[70vh]'>
                                            <StaffingTable hooksValue={{
                                                setstaffingData,
                                                staffingData,
                                                setEdit,
                                                edit,
                                                filters
                                            }} />
                                        </div>
                                        {edit && <div className='flex justify-end items-center space-x-2'>
                                            <PrimaryButton disabled={loading} onClick={handleApprovals} className='bg-primary py-2 mx-2 rounded-md text-white'>
                                                {loading ? 'Processing...' : 'Save'}
                                            </PrimaryButton>
                                        </div>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default AddApprovals;

