
import { memo, useState } from 'react';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = (props) => {
    const { detailSave, detailsData, setDetailsData, educationData } = props
    const [checkvalidation, setCheckvalidation] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCheckvalidation(true);
        let isValidated = CheckObjectValidation(detailsData, ['notes', 'datcoursemasterid']);
        if (isValidated?.isvalid) {
            let payload = {
                datcoursejson: [detailsData]
            }
            detailSave(payload)
        };
    }

    const handleChangeInput = (name, value) => {
        setCheckvalidation(false);
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className='w-full h-[65vh] text-sm mb-10'>
            <div className='grid grid-cols-4 gap-10 mt-4'>
                <div>
                    <label htmlFor='coursemaster' className='block font-[500] mb-2'>
                        Course
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        type='text'
                        title='coursemaster'
                        id='coursemaster'
                        className='block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
                        placeholder='Enter coursemaster Name'
                        required=''
                        onChange={(e) => {
                            handleChangeInput('coursemaster', e.target.value)
                        }}
                    />
                    {!detailsData?.coursemaster && checkvalidation && (
                        <small className='text-red-400'> coursemaster is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor='dateducationmasterid' className='block font-[500] mb-2'>
                        Education
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={educationData || []}
                        value={detailsData?.dateducationmasterid || ''}
                        noDataLabel='No education master'
                        name='dateducationmasterid'
                        labelkey='educationmaster'
                        className='py-2 border rounded bg-gray-50'
                        onChange={handleChangeInput}
                        id='dateducationmasterid'
                    />
                    {!detailsData?.dateducationmasterid && checkvalidation && (
                        <small className='text-red-400'>Education is required!</small>
                    )}
                </div>

            </div>
            <div className='fixed bottom-0 mt-2 right-6'>
                <div className='flex items-center justify-end gap-2'>
                    <PrimaryButton
                        className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
                        onClick={handleSubmit}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default memo(Details);
