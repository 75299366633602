import { Dialog, Tab, Transition } from '@headlessui/react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    GET_BUSINESS_MASTER,
    GET_SERVICELINE_MASTER,
} from 'src/Redux/actions/masterdata/user'
import {
    GET_CURRENCY_MASTER_LIST,
    GET_OPPORTUNITY_LIST,
    GET_PROJECTS,
} from 'src/Redux/actions/projects'
import {
    GET_DEMAND_MASTER,
    GET_WORK_MODE_LIST,
    SAVE_DEMAND_LIST_MASTER,
} from 'src/Redux/actions/workforce/demands'
import {
    GET_BAND_MASTER,
    GET_COMPANY_MASTER,
    GET_DEPARTMENT_MASTER,
    GET_WORKERTYPE_MASTER,
} from 'src/Redux/actions/workforce/talent'
import {
    getBandMasterIdData,
    getBusinessUnitData,
    getDefaultCompanyPayload,
    getDefaultCurrencyPayload,
    getDefaultOpportunityPayload,
    getDefaultProjectPayload,
    getDefaultServiceLinePayload,
    getDepartmentMasterIdData,
    getWorkModePayload,
    getWorkTypePayload,
} from 'src/Services/constant/defaultPayload'
import { createDemandDetailsInfo } from './demanddetails/demandDetailsItem'
import Details from './demanddetails/details'
import RoleDetails from './roleDetails/roleDetails'
import { RoleItemSkeleton } from './roleDetails/roleDetailsItem'
import { insertDemandSkillExpertise } from './skill&Experties/defaultItems'
import SkillExperties from './skill&Experties/skill&Experties'
import IconButton from 'src/Components/Buttons/IconButton'
// import DemamdDetails from 'src/Layout/app/workforce/DemamdDetails'

const tabslist = ['Demand Details', 'Role Details', 'Skills & Expertise']
let rolebaseInfo = {
    demandid: 0,
    jobtitle: '',
    experienceinyear: 0,
    rolesummary: '',
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
}

const AddDemandAndEdit = ({ hooks }) => {
    const {
        selectedProject,
        demandStaff,
        setDemandStaff,
        saveStaffing,
        staffingData,
        setstaffingData,
    } = hooks
    const [payload, setPayload] = useState(null)
    const [roleData, setRoleData] = useState(RoleItemSkeleton()?.roleresponsibilities)
    const [rolebaseDetail, setRoleBaseDetail] = useState(rolebaseInfo)
    const dispatch = useDispatch()
    const { currencyList } = useSelector((state) => state?.Projects)
    const [skillData, setSkillData] = useState([insertDemandSkillExpertise()])
    const [businessUnit, setBusinessUnit] = useState({})
    const [serviceLine, setServiceLine] = useState({})
    const [workmode, setWorkmode] = useState({})
    const [worktype, setWorktype] = useState({})
    const [location, setLocation] = useState({})
    const [opportunity, setOpportunity] = useState({})
    const [project, setProject] = useState({})
    const [department, setDepartment] = useState({})
    const [activeTabs, setActiveTabs] = useState('Demand Details')
    const [tabCanAccess, setTabCanAccess] = useState(['Demand Details'])
    const currentDate = new Date()
    const futureDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000) // Adding 14 days in milliseconds to the current date
    const next14DaysDisable = futureDate.toISOString().split('T')[0]

    useMemo(() => {
        setProject(selectedProject)
        setPayload({
            ...createDemandDetailsInfo(),
            demandstatusid: 31,
            companyname: selectedProject?.companyname,
            demandstatus: demandStaff?.demandstatus || 'Open',
            workertypeid: demandStaff?.workertypeid,
            billable: demandStaff?.billable,
            projectname: selectedProject?.projectname,
            cityname: demandStaff?.cityname,
            workmodeid: demandStaff?.workmodeid || null,
            billingunit: demandStaff?.rateunit,
            priority: demandStaff?.priority,
            businessunitid: selectedProject?.businessunitid,
            startdate:
                next14DaysDisable >= demandStaff?.plannedenddate ? '' : next14DaysDisable,
            servicelineid: selectedProject?.servicelineid,
            companyid: selectedProject?.companyid,
            projectid: demandStaff?.projectid,
            peopleno: 1,
            requesttype: 'P',
            // comstateid: demandStaff?.comstateid,
            demandcode: demandStaff?.demandcode,
            demandid: demandStaff?.demandid,
            countryname: demandStaff?.countryname,
            countryid: demandStaff?.countryid,
            cityid: demandStaff?.cityid,
            allocation: demandStaff?.allocation,
            billrate: demandStaff?.billratehr,
            datcomcurrencyid: currencyList?.find(
                (it) => it?.currency === demandStaff?.currency,
            )?.datcomcurrencyid,
        })

        setRoleBaseDetail({
            ...rolebaseDetail,
            jobtitle: demandStaff?.jobtitle || demandStaff?.rolename,
            max_bandid: demandStaff?.max_bandid,
            min_bandid: demandStaff?.min_bandid,
            max_band: demandStaff?.max_band,
            min_band: demandStaff?.min_band,
        })
        // eslint-disable-next-line
    }, [demandStaff])

    useEffect(() => {
        if (demandStaff !== null) {
            dispatch(
                GET_SERVICELINE_MASTER(
                    getDefaultServiceLinePayload(demandStaff?.skillareamasterid || 0),
                ),
            )
        }
        // eslint-disable-next-line
    }, [demandStaff])

    const getDemandDataById = async () => {
        if (demandStaff?.demandid) {
            setTabCanAccess(['Demand Details', 'Role Details', 'Skills & Expertise'])
            let res = await dispatch(GET_DEMAND_MASTER(demandStaff?.demandid))
            // console.log(res?.data);
            if (res?.data?.length > 0) {
                let resData = res?.data[0]
                if (resData?.demandroledetails?.length > 0) {
                    let roles = resData?.demandroledetails[0]?.roleresponsibilities
                    setRoleBaseDetail(resData?.demandroledetails[0] || rolebaseDetail)
                    if (roles?.length > 0) {
                        let Ritems = roles?.map((role) => {
                            role['uId'] =
                                Math.random().toString(36).substr(2, 9) +
                                new Date().getTime().toString(36)
                            return role
                        })
                        setRoleData(Ritems)
                    } else {
                        setRoleData(RoleItemSkeleton()?.roleresponsibilities)
                    }
                }

                let skills = resData?.demandskills?.map((skill) => {
                    skill['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return skill
                })
                setPayload({ ...payload, comstateid: resData?.comstateid })
                setSkillData(skills || [insertDemandSkillExpertise()])
                setPayload(resData)
            }
        }
    }

    useEffect(() => {
        getDemandDataById()
        // eslint-disable-next-line
    }, [demandStaff])

    useEffect(() => {
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_COMPANY_MASTER(getDefaultCompanyPayload()))
        dispatch(GET_BUSINESS_MASTER(getBusinessUnitData()))
        dispatch(GET_WORKERTYPE_MASTER(getWorkTypePayload()))
        dispatch(GET_WORK_MODE_LIST(getWorkModePayload()))
        dispatch(GET_OPPORTUNITY_LIST(getDefaultOpportunityPayload()))
        dispatch(GET_PROJECTS(getDefaultProjectPayload()))
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()))
        dispatch(GET_BAND_MASTER(getBandMasterIdData()))
    }, [dispatch])

    function closeModal() {
        setRoleBaseDetail({})
        setSkillData([insertDemandSkillExpertise()])
        setDemandStaff(null)
        setActiveTabs('Demand Details')
    }

    async function detailSave(payload) {
        let res = await dispatch(SAVE_DEMAND_LIST_MASTER(payload))
        if (res !== null) {
            // console.log(res)
            let addDemandId = staffingData.map((item) => {
                if (item?.uId === demandStaff?.uId) {
                    item['demandid'] = res?.demandid
                }
                return item
            })
            await setstaffingData(addDemandId)
            await setTabCanAccess([
                'Demand Details',
                'Role Details',
                'Skills & Expertise',
            ])
            await saveStaffing()
        }
    }

    return (
        <>
            <Transition appear show={demandStaff !== null} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-20"
                    onClose={() => console.log('')}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" w-[calc(90%)] 
  md:w-[calc(80%)] transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                                    <Tab.Group>
                                        <div className="w-full mb-1 flex justify-between border-b-2 border-primary ">
                                            <h3 className="text-lg font-[500] leading-6 text-primary">
                                                {demandStaff?.demandid ? 'Edit' : 'Create'}{' '}
                                                Demand
                                            </h3>
                                            <Tab.List className="justify-start mb-1 flex space-x-2 rounded-lg p-0.5">
                                                {tabslist?.map((item, i) => {
                                                    return (
                                                        <Tab
                                                            key={i}
                                                            disabled={
                                                                !tabCanAccess.includes(
                                                                    item,
                                                                )
                                                            }
                                                            onClick={() =>
                                                                setActiveTabs(item)
                                                            }
                                                            className={` py-1.5 px-2 rounded-md outline-white ${item === activeTabs
                                                                    ? 'text-secondary bg-secondary/10  font-[500]'
                                                                    : 'text-gray-500'
                                                                }`}
                                                        >
                                                            <span className="px-2">
                                                                {' '}
                                                                {item}
                                                            </span>
                                                        </Tab>
                                                    )
                                                })}
                                            </Tab.List>
                                            <IconButton
                                                onClick={closeModal}
                                                title="Close Modal"
                                                className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-4 h-4"
                                                >
                                                    {' '}
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />{' '}
                                                </svg>
                                                <span className="sr-only">Close icon</span>
                                            </IconButton>
                                        </div>
                                        {activeTabs === 'Demand Details' && (
                                            <>
                                                <Details
                                                    hooks={{
                                                        payload,
                                                        detailSave,
                                                        opportunity,
                                                        demandStaff,
                                                        setTabCanAccess,
                                                        selectedProject,
                                                        setOpportunity,
                                                        project,
                                                        setProject,
                                                        department,
                                                        setDepartment,
                                                        setPayload,
                                                        businessUnit,
                                                        setBusinessUnit,
                                                        serviceLine,
                                                        setServiceLine,
                                                        workmode,
                                                        setWorkmode,
                                                        location,
                                                        setLocation,
                                                        worktype,
                                                        setWorktype,
                                                    }}
                                                />
                                            </>
                                        )}
                                        {activeTabs === 'Role Details' && (
                                            <>
                                                <RoleDetails
                                                    hooks={{
                                                        rolebaseDetail,
                                                        setRoleBaseDetail,
                                                        roleData,
                                                        demandStaff,
                                                        setRoleData,
                                                        setActiveTabs,
                                                    }}
                                                />
                                            </>
                                        )}
                                        {activeTabs === 'Skills & Expertise' && (
                                            <>
                                                <SkillExperties
                                                    hooks={{
                                                        skillData,
                                                        setSkillData,
                                                        demandStaff,
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Tab.Group>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default AddDemandAndEdit
