import moment from 'moment'
import {
    ACTION_GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    ACTION_GET_BAND_MASTER,
    ACTION_GET_CITY_MASTER,
    ACTION_GET_COMPANY_MASTER,
    ACTION_GET_DEPARTMENT_MASTER,
    ACTION_GET_DESIGNATION_MASTER,
    ACTION_GET_EMPLOYEE_DETAILS,
    ACTION_GET_STAFF_STATUS_TYPE_W,
    ACTION_GET_WORKERTYPE_MASTER,
    ACTION_GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/types'
import { AlertError, AlertSuccess } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'

const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
}

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem('comuserid')),
    }
}

export const GET_EMPLOYEE_DETAILS = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/getuserlist`
            dispatch({ type: ACTION_GET_EMPLOYEE_DETAILS, data: null })
            let payload = { ...data }
            if (typeof data?.empstatus === 'number') {
                payload['empstatus'] = `{${data?.empstatus}}`
            }
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let addnewkey = res?.data?.data?.map((item) => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_EMPLOYEE_DETAILS,
                    data: addnewkey || [],
                })
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEPARTMENT_MASTER = (data) => async (dispatch) => {
    try {
        let payload = {
            ...data,
            searchname: data?.searchname || '',
        }
        const url = `${baseUrlMaster}/getdepartment`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No Department found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_DEPARTMENT_MASTER,
                data: addnewkey,
            })

            return { success: true, data: addnewkey }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_DESIGNATION_MASTER = (data) => async (dispatch) => {
    try {
        let payload = {
            ...data,
            searchname: data?.searchname || '',
        }
        const url = `${baseUrlMaster}/get_designationmaster`
        const res = await postMethodApiCall(
            url,

            getHeadersWithoutAuth(),
            payload,
        )
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No Role found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_DESIGNATION_MASTER,
                data: addnewkey || [],
            })
            return { success: true, data: addnewkey || [] }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_BAND_MASTER = (data) => async (dispatch) => {
    try {
        const url = `${baseUrlMaster}/get_bandmaster`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No Band found')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_BAND_MASTER,
                data: addnewkey || [],
            })
            return { success: true, data: addnewkey || [] }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_CITY_MASTER = (data) => async (dispatch) => {
    try {
        let payload = {
            ...data,
            searchname: data?.searchname || '',
        }
        const url = `${baseUrlMaster}/get_citymaster`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No city found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_CITY_MASTER,
                data: addnewkey || [],
            })
            return { success: true, data: addnewkey || [] }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_WORKERTYPE_MASTER = (data) => async (dispatch) => {
    try {
        const url = `${baseUrlMaster}/get_workertypemaster`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No Workertype found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_WORKERTYPE_MASTER,
                data: items,
            })
            return { success: true, data: addnewkey }
        }
    } catch (error) {
        AlertError(error.response?.data).replace(/\\/g, '')
    }
}

export const GET_WORKFORCEGROUP_MASTER = (data) => async (dispatch) => {
    try {
        const url = `${baseUrlMaster}/get_workforcegroupmaster`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No WorkForce found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_WORKFORCEGROUP_MASTER,
                data: addnewkey || [],
            })
            return { success: true, data: addnewkey }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_COMPANY_MASTER = (payload) => async (dispatch) => {
    try {
        const url = `${baseUrlMaster}/get_companymaster`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
        let items = res?.data?.data
        if (items === null) AlertError('No Company found for Employee')
        let addnewkey = items?.map((item) => {
            item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
            return item
        })
        dispatch({
            type: ACTION_GET_COMPANY_MASTER,
            data: addnewkey || [],
        })
        return { success: true, data: addnewkey || [] }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_ALLOCATION_STATUS_FOR_EMPLOYEE = (data) => async (dispatch) => {
    try {
        let payload = {
            ...data,
            searchname: data?.searchname || '',
        }

        const url = `${baseUrl}/getstatusmaster`
        const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (items === null) AlertError('No Status found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
                data: addnewkey || [],
            })
            return { success: true, data: addnewkey }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_STAFF_STATUS_TYPE_W = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid || 0,
                statustype: 'W',
                searchname: data?.searchname || '',
                sorttype: 1,
            }
            const url = `${baseUrl}/getstatusmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Status type found !')
                dispatch({ type: ACTION_GET_STAFF_STATUS_TYPE_W, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, '')
        }
    }
}

export const HANDE_STAFFING_APPROVAL_TALENT = (items) => {
    return async (dispatch) => {
        try {
            let itemsCopy = []
            for (let item of items) {
                item['allocation'] = (Number(item?.allocation) / 100).toFixed(2)
                item['plannedallocation'] = (Number(item?.plannedallocation) / 100).toFixed(2)
                let statusId = item?.StaffingStatusId || item?.staffingstatusid
                if (item['actionType'] === 'rejected') {
                    item['startdate'] = item?.plannedstartdate
                    item['enddate'] = item?.plannedenddate
                    item['allocation'] = Number(item?.plannedallocation)
                }

                delete item['actionType']
                item['createdcomuserid'] = JSON.parse(localStorage.getItem('comuserid'))
                item['startdate'] = moment(item?.startdate).format('YYYY-MM-DD')
                item['enddate'] = moment(item?.enddate).format('YYYY-MM-DD')
                item['demandid'] = null
                item['StaffingStatusId'] = statusId
                delete item['staffingstatusid']
                delete item['statffingstatus']
                itemsCopy.push(item)
            }

            // console.log(itemsCopy)
            let payload = { staffjson: itemsCopy }
            const url = `${baseUrl}/insert_projectstaff`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
