import { Dialog, Tab, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'src/Components/Buttons/IconButton';
import {
    GET_INDUSTRY_LIST_MASTER,
    SAVE_INDUSTRY_MASTER
} from 'src/Redux/actions/masterdata/user';
import { AlertError, AlertSuccess } from 'src/Services';
import { getIndustryListPayload } from 'src/Services/constant/Customerpayload';
import Details from './details';

const EditIndustry = (props) => {
    const {
        isOpen = true,
        setIsOpen,
        industryId,
        setDetailsData,
        detailsData,
        page,
        limit,
        sortType,
        permissions
    } = props;

    const dispatch = useDispatch();
    const [canEdit, setCanEdit] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const canEditOpportunity = permissions.includes('Add/Edit Customer');

    async function closeModal() {
        setIsOpen(false);
        setCanEdit(false);
    }

    async function openModal() {
        setIsOpen(true);
    }

    async function detailSave(payload) {
        let res = await dispatch(SAVE_INDUSTRY_MASTER(payload, industryId));
        if (res?.success) {
            AlertSuccess('industry updated successfully.');
            await dispatch(GET_INDUSTRY_LIST_MASTER(getIndustryListPayload()))
        }
    }

    const handleDelete = async () => {
        let item = {
            industryid: detailsData?.industryid,
            industryname: detailsData?.industryname,
            comtenantid: Number(localStorage.getItem('Tenantid') || 0),
            isactiveflag: !detailsData.isactiveflag,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: detailsData?.notes,
        };
        const payload = {
            industryjson: [item],
        };

        let res = await dispatch(SAVE_INDUSTRY_MASTER(payload));
        if (res?.success) {
            if (detailsData.isactiveflag) {
                AlertSuccess('industry deactivated successfully.');
            } else {
                AlertSuccess('industry reactivated successfully.');
            }
            await dispatch(
                GET_INDUSTRY_LIST_MASTER({
                    ...getIndustryListPayload(),
                    limit,
                    page: page?.selected + 1,
                    sorttype: sortType?.id,
                })
            );
        } else {
            AlertError('Failed to update industry.');
        }
        setShowDeleteConfirmation(false);
        setCanEdit(false);
        closeModal();
    };

    function handleDeleting() {
        if (detailsData?.isactiveflag) {
            setCanEdit(true);
        }
        setShowDeleteConfirmation(true);
    }

    function handleCancelDeleting() {
        setShowDeleteConfirmation(false);
        setCanEdit(false);
        //  closeModal();
    }

    function handleCancel() {
        closeModal();
        setShowDeleteConfirmation(false);
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-20' onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
                    </Transition.Child>
                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex items-center justify-center min-h-full p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                                    <div className='flex items-center justify-between py-2 border-b border-primary'>
                                        <h3 className='text-lg font-[500] leading-6 text-primary'>
                                            Edit Industry
                                        </h3>
                                        <div className='flex items-center justify-end space-x-2'>
                                            {canEditOpportunity && !canEdit && detailsData.isactiveflag && (
                                                <IconButton
                                                    title='Edit'
                                                    onClick={() => setCanEdit(true)}
                                                    type='button'
                                                    className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth={1.5}
                                                        stroke='currentColor'
                                                        className='w-4 h-4'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                                                        />
                                                    </svg>
                                                    <span className='sr-only'>Edit icon</span>
                                                </IconButton>
                                            )}

                                            {canEditOpportunity && !canEdit && (
                                                <>
                                                    <IconButton
                                                        title={detailsData?.isactiveflag ? 'Delete' : 'Reactivate'}
                                                        onClick={handleDeleting}
                                                        type='button'
                                                        className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                                                    >
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                            strokeWidth={1.8}
                                                            stroke='currentColor'
                                                            className='w-4 h-4'
                                                        >
                                                            {detailsData?.isactiveflag ? (
                                                                <path
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                                                />
                                                            ) : (
                                                                <path
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    strokeWidth={3}
                                                                    d='M5 13l4 4L19 7'
                                                                />
                                                            )}
                                                        </svg>
                                                        <span className='sr-only'>
                                                            {detailsData?.isactiveflag ? 'Unlock' : 'Delete'}{' '}
                                                            icon
                                                        </span>
                                                    </IconButton>
                                                </>
                                            )}
                                            <IconButton
                                                title='Close'
                                                onClick={handleCancel}
                                                type='button'
                                                className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    strokeWidth={1.5}
                                                    stroke='currentColor'
                                                    className='w-4 h-4'
                                                >
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M6 18L18 6M6 6l12 12'
                                                    />
                                                </svg>
                                                <span className='sr-only'>Close icon</span>
                                            </IconButton>
                                        </div>
                                    </div>
                                    <Tab.Group>
                                        <Tab.Panels>
                                            <Tab.Panel>
                                                <Details
                                                    detailSave={detailSave}
                                                    detailsData={detailsData}
                                                    industryId={industryId}
                                                    setDetailsData={setDetailsData}
                                                    canEdit={!canEdit}
                                                    setCanEdit={setCanEdit}

                                                />
                                            </Tab.Panel>
                                        </Tab.Panels>
                                    </Tab.Group>
                                    {showDeleteConfirmation && (
                                        <div className='absolute bottom-0 left-0 w-full p-6 text-center bg-white border-t border-gray-300'>
                                            <p className='mb-4'>
                                                {detailsData?.isactiveflag
                                                    ? 'Are you sure you want to delete this Opportunity?'
                                                    : 'Are you sure you want to reactivate this Opportunity?'}
                                            </p>
                                            <button
                                                onClick={handleDelete}
                                                className='px-8 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5 mr-4'
                                            >
                                                Yes
                                            </button>
                                            <button
                                                onClick={handleCancelDeleting}
                                                className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                                            >
                                                No
                                            </button>
                                        </div>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default EditIndustry;
