import moment from 'moment'
import { useEffect, useState } from 'react'
import NotePopup from './NotePopup'; // Adjust the import based on your file structure

const matchSummaryTitles = {
    Proposed: 'MPROPOSD',
    Blocked: 'MBLKED',
    Selected: 'MSELECTD',
}

const MatchSummaryCard = ({ count, title }) => {
    return (
        <div className="flex flex-col items-center justify-center p-2 text-center bg-white border border-gray-300 rounded-md shadow-md">
            <h1 className="text-2xl font-bold text-primary">{count}</h1>
            <p className="text-gray-500">{title}</p>
        </div>
    )
}

const BaseInfo = ({ demandData, demandMatchProfile }) => {
    const [currentDemandData, setCurrentDemandData] = useState(null)
    const [currentMatchProfile, setCurrentMatchProfile] = useState(null)

    useEffect(() => {
        if (demandData && demandData.length > 0) {
            setCurrentDemandData(demandData[0])
        } else {
            setCurrentDemandData(null)
        }

        if (demandMatchProfile && demandMatchProfile.length > 0) {
            setCurrentMatchProfile(demandMatchProfile[0])
        } else {
            setCurrentMatchProfile(null)
        }
    }, [demandData, demandMatchProfile])

    const getRequestTypeLabel = (requesttype) => {
        switch (requesttype) {
            case 'O':
                return 'Opportunity'
            case 'I':
                return 'Internal'
            case 'P':
                return 'Project'
            default:
                return 'Unknown'
        }
    }

    const getPriorityTypeLabel = (priority) => {
        switch (priority) {
            case 'H':
                return 'High'
            case 'L':
                return 'Low'
            case 'M':
                return 'Medium'
            default:
                return 'Unknown'
        }
    }

    const getCycleLabel = (id) => {
        switch (id) {
            case 1:
                return 'Monthly'
            case 2:
                return 'Weekly'
            case 3:
                return 'Daily'
            case 4:
                return 'Hourly'
            default:
                return ''
        }
    }

    const billingunit = getCycleLabel(currentDemandData?.billingunit)
    const priorityTypeLabel = getPriorityTypeLabel(currentDemandData?.priority)
    const requestTypeLabel = getRequestTypeLabel(currentDemandData?.requesttype)
    let requestName =
        requestTypeLabel === 'Opportunity'
            ? currentDemandData?.opportunityname
            : requestTypeLabel === 'Project'
                ? currentDemandData?.projectname
                : requestTypeLabel === 'Internal'
                    ? currentDemandData?.department
                    : ''

    const statusCountMap = (currentDemandData?.statuscount || []).reduce((map, item) => {
        map[item.matchstatuscode] = item.countno
        return map
    }, {})

    const matchSummary = Object.keys(matchSummaryTitles).map((title) => ({
        count: statusCountMap[matchSummaryTitles[title]] || 0,
        title,
    }))

    const matchDetails = currentDemandData?.matchdetails || []
    const primaryUsername = matchDetails[0]?.demandusername || ''
    const additionalUsernames = matchDetails?.slice(1).map((detail) => detail.demandusername)

    return (
        <div className="w-3/12 h-full text-sm">
            <div className="bg-white border shadow-md rounded-lg p-2.5">
                <div className="border-b border-primary py-0.5">
                    <p className="pb-1 text-sm font-[500]">Demand Details</p>
                </div>
                <p className="my-1">
                    Request Type : <span className="font-normal text-gray-500">{requestTypeLabel} </span>
                </p>
                <p className="my-1">
                    {requestTypeLabel === 'Internal' ? 'Department' : requestTypeLabel} :
                    <span className="font-normal text-gray-500"> {requestName}</span>
                </p>
                <p className="my-1">
                    Employee Type: <span className="font-normal text-gray-500">{currentDemandData?.workertype}</span>
                </p>
                <p className="my-1">
                    Service Line :{' '}
                    <span className="font-normal text-gray-500">{currentDemandData?.servicelinename} </span>
                </p>
                <p className="my-1">
                    Business Unit :{' '}
                    <span className="font-normal text-gray-500">{currentDemandData?.businessunitname} </span>
                </p>
                <p className="my-1">
                    Start Date : <span className="font-normal text-gray-500">{currentDemandData?.startdate}</span>
                </p>
                <p className="my-1">
                    Billable :{' '}
                    <span className="font-normal text-gray-500">
                        {currentDemandData?.is_billable === true ? 'Yes' : 'No'}{' '}
                    </span>
                </p>
                <p className="my-1">
                    Billing Unit : <span className="font-normal text-gray-500">{billingunit} </span>
                </p>
                <p className="my-1">
                    Workforce Group:{' '}
                    <span className="font-normal text-gray-500">{currentDemandData?.workforcegroup} </span>
                </p>
                <p className="my-1">
                    Billing Rate & Currency :{' '}
                    <span className="font-normal text-gray-500">
                        {currentDemandData?.billrate} {currentDemandData?.currency}
                    </span>
                </p>
                <p className="my-1">
                    Priority: <span className="font-normal text-gray-500">{priorityTypeLabel}</span>
                </p>
                <p className="my-1">
                    Allocation :{' '}
                    <span className="font-normal text-gray-500">
                        {Number(currentDemandData?.allocation) || currentDemandData?.allocation}%
                    </span>
                </p>
                <p className="my-1">
                    Work Mode : <span className="font-normal text-gray-500">{currentDemandData?.workmode}</span>
                </p>
                <p className="my-1">
                    Requested By: <span className="font-normal text-gray-500">{currentDemandData?.requestorname}</span>
                </p>
                <p className="my-1">
                    Requested Date :{' '}
                    <span className="font-normal text-gray-500">
                        {moment(currentDemandData?.createdtimestamp).format('DD-MM-YYYY ')}
                    </span>
                </p>
                <p className="my-1">
                    # People :{' '}
                    <span className="font-normal text-gray-500">
                        {currentDemandData?.peopleno}
                    </span>
                </p>
                <p className="my-1">
                    Selected Staff : <span className="font-normal text-gray-500">{primaryUsername}</span>
                    {additionalUsernames?.length > 0 && (
                        <>
                            <span className="font-normal text-gray-500">{', '}</span>
                            <NotePopup
                                props={{
                                    usernames: additionalUsernames,
                                }}
                            />
                        </>
                    )}
                </p>
            </div>
            <div className="p-1 my-2 text-sm bg-white border rounded-lg shadow-md">
                <div className="border-b border-primary mb-1.5">
                    <p className="pb-1 text-sm font-[500]">Demand Matches</p>
                </div>
                <div className="grid grid-cols-4 py-1.5 gap-1 bg-white">
                    <MatchSummaryCard key="Matches" count={currentMatchProfile?.totalcount || 0} title="Matches" />
                    {matchSummary.map((item) => (
                        <MatchSummaryCard key={item?.title} count={item?.count} title={item?.title} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BaseInfo
