import { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { AlertError } from 'src/Services';
import { GET_TIME_CATEGORY_MASTER, GET_TEMPLATE_MASTER } from 'src/Redux/actions/configuration';
import { getMasterCategoryPayload, getTemplateCategoryPayload } from 'src/Services/constant/masterdatapayload';
import { yesNoWithboolean, timeTypeUniquenes, timeEntryFormat, 
   // validationTime
    holidayCalendarAttestaion } from 'src/Services/constant/staticValues'


const Details = (props) => {
   const dispatch = useDispatch();
   const { companylist, workertypelist } = useSelector((state) => state.EmployeeDetails);
   const { countryList } = useSelector(state => state.UserManagementList)
   const [checkvalidation, setCheckvalidation] = useState(false);
   const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit, timetypetemplatemappingid } = props;
   const [filteredCategoryList, setFilteredCategoryList] = useState([]);
   const [filteredTemplateList, setFilteredTemplateList] = useState([]);
   const [selectedCompanyId, setSelectedCompanyId] = useState('');
   const [selectedTemplateType, setSelectedTemplateType] = useState('');

   useEffect(() => {
      const fetchData = async () => {
         try {
            if (detailsData?.companyid) {
               let payload = {
                  ...getTemplateCategoryPayload(),
                  companyid: detailsData?.companyid,
               };

               const res = await dispatch(GET_TEMPLATE_MASTER(payload));
               const filteredData = res?.data;
               setFilteredTemplateList(filteredData);

               let payloadTwo = {
                  ...getMasterCategoryPayload(),
                  companyid: detailsData.companyid,
               };
               const resTwo = await dispatch(GET_TIME_CATEGORY_MASTER(payloadTwo));
               const filteredDataTwo = resTwo?.data;
               setFilteredCategoryList(filteredDataTwo);
            }
         } catch (error) {
            AlertError("Error fetching data:", error);
         }
      };
      fetchData();
   }, [detailsData?.companyid, selectedCompanyId, dispatch]);


   useEffect(() => {
      if (detailsData?.timetemplatemasterid && filteredTemplateList?.length > 0) {
         const selectedTemplate = filteredTemplateList?.find(item => item.timetemplatemasterid === detailsData?.timetemplatemasterid);
         if (selectedTemplate) {
            setSelectedTemplateType(selectedTemplate.templatetype);
         }
      }
   }, [detailsData, filteredTemplateList]);

   const handleSubmit = () => {
      setCheckvalidation(true);
      if (!detailsData?.timetemplatemasterid || !detailsData?.timecategorymasterid) {
         AlertError('Template name and category are required!');
         return;
      }

      let items = {
         timetypetemplatemappingid: timetypetemplatemappingid,
         timecategorymasterid: detailsData?.timecategorymasterid,
         timetemplatemasterid: detailsData?.timetemplatemasterid,
         comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
         companyid: detailsData?.companyid,
         comcountryid: detailsData?.comcountryid,
         workertypeid: detailsData?.workertypeid,
         validationunit: detailsData?.validationunit,
         weekendentryallowed: detailsData?.weekendentryallowed,
         holidayallowed: detailsData?.holidayallowed,
         isactiveflag: true,
         mindailylimit: detailsData?.mindailylimit,
         maxdailylimit: detailsData?.maxdailylimit,
         createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
         modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
         notes: "",
         allocationrestriction: detailsData?.allocationrestriction,
         timeentryformat: detailsData?.timeentryformat,
         timetypeuniqueness: detailsData?.timetypeuniqueness,
         selfattestation: detailsData?.selfattestation,
         holidaycalendarintegration: detailsData?.holidaycalendarintegration,
         weekdayentryallowed: detailsData?.weekdayentryallowed
      };

      // console.log(detailsData);
      let isValidated = CheckObjectValidation(detailsData, ['weekdayentryallowed', 'timetypeuniquenessname', 'notes', 'modifiedtimestamp', 'modifiedcomuserid', 'mindailylimit']);
      if (isValidated?.isvalid && items.mindailylimit <= items.maxdailylimit) {
         if (items.maxdailylimit === 0 && items.mindailylimit === 0) {
            let payload = {
               timetypetemplatemappingjson: [items],
            };
            detailSave(payload);
         }
         else if (items.mindailylimit <= items.maxdailylimit) {
            let payload = {
               timetypetemplatemappingjson: [items],
            };
            detailSave(payload);
         }
         else {
            AlertError('Minimum daily limit should be less than or equal to maximum daily limit');
         }
      }
      else {
         AlertError('Minimum daily limit should be less than or equal to maximum daily limit');
      }
   }

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);
      if (name === 'companyid') {
         setSelectedCompanyId(value);
         const selectedTemplate = filteredTemplateList?.find((item) => item.timetemplatemasterid === value);
         const templateType = selectedTemplate ? selectedTemplate.templatetype : '';
         setSelectedTemplateType(templateType);

         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: value,
            timetemplatemasterid: null, // Reset the template name
            timecategorymasterid: null,
         }));
      }

      else if (name === 'mindailylimit' || name === 'maxdailylimit') {
         let parsedValue = parseFloat(value);
         if (value === '' || value === null) {
            parsedValue = null;
         } else if (isNaN(parsedValue) || parsedValue < 0) {
            value = '';
         } else if (name === 'maxdailylimit' && parsedValue > 24) {
            value = 24;
         } else if (name === 'mindailylimit' && parsedValue > 8) {
            parsedValue = 8;
         }
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: parsedValue,
         }));
      }
      setDetailsData((prevDetailsData) => ({
         ...prevDetailsData,
         [name]: value,
      }));
   };

   return (
      <div className='w-full h-[65vh] text-sm mb-10'>
         <div className='border-b pb-4 grid grid-cols-4 gap-10 mt-8'>
            <div>
               <label htmlFor='companyname' className='block font-[500] mb-2'>
                  Company
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={companylist || []}
                  value={detailsData?.companyid || ''}
                  noDataLabel='No Company'
                  name='companyid'
                  labelkey='companyname'
                  className='border rounded bg-gray-100 py-2'
                  onChange={handleChangeInput}
                  id='companyid'
               />
               {!detailsData?.companyid && checkvalidation && (
                  <small className='text-red-400'>Company is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='timetemplatemasterid' className='block font-[500] mb-2'>
                  Time Template Name
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={!(detailsData.companyid) ? true : canEdit}
                  options={filteredTemplateList || []}
                  value={detailsData?.timetemplatemasterid || ''}
                  noDataLabel='No Template'
                  name='timetemplatemasterid'
                  labelkey='templatename'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='timetemplatemasterid'
               />
               {!detailsData?.timetemplatemasterid && checkvalidation && (
                  <small className='text-red-400'>Time Template is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='templatetype' className='block font-[500] mb-2'>
                  Template Type
               </label>
               <input
                  type="text"
                  value={selectedTemplateType === 'P' ? 'Project' : selectedTemplateType === 'G' ? 'Generic' : ''} className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
                  disabled
               />
            </div>
         </div>
         <div className='grid grid-cols-4 gap-6 mt-4'>
            <div>
               <label htmlFor='comcountryid' className='block font-[500] mb-2'>
                  Country
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={countryList || []}
                  value={detailsData?.comcountryid || ''}
                  noDataLabel='No country'
                  name='comcountryid'
                  labelkey='countryname'
                  className='border rounded bg-gray-100 py-2'
                  onChange={handleChangeInput}
                  id='comcountryid'
               />
            </div>
            {!detailsData?.comcountryid && checkvalidation && (
               <small className='text-red-400'>Country is required!</small>
            )}
            <div>
               <label htmlFor='workertypeid' className='block font-[500] mb-2'>
                  Worker Type
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={workertypelist || []}
                  value={detailsData?.workertypeid}
                  noDataLabel='No Worker Type'
                  name='workertypeid'
                  labelkey='workertype'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='workertypeid'
               />
            </div>
            <div>
               <label htmlFor='timecategory' className='block font-[500] mb-2'>
                  Time Type
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={!(detailsData.companyid) ? true : canEdit}
                  options={filteredCategoryList || []}
                  value={detailsData?.timecategorymasterid || ''}
                  noDataLabel='No Category'
                  name='timecategorymasterid'
                  labelkey='timecategory'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='timecategorymasterid'
               />
               {!detailsData?.timecategorymasterid && checkvalidation && (
                  <small className='text-red-400'>Time Type is required!</small>
               )}
            </div>

            {/* */}
            <div >
               <label htmlFor='timeentryformat' className='block font-[500] mb-2'>
                  Time Entry Format
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={timeEntryFormat}
                  value={detailsData?.timeentryformat}
                  noDataLabel='No Time Entry Format'
                  name='timeentryformat'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='timeentryformat'
               />
               {!detailsData?.timeentryformat && checkvalidation && (
                  <small className='text-red-400'>Time Entry Format is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='timetypeuniqueness' className='block font-[500] mb-2'>
                  Time Type Uniqueness
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={timeTypeUniquenes(selectedTemplateType)}
                  value={detailsData?.timetypeuniqueness}
                  name='timetypeuniqueness'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='timetypeuniqueness'
               />
               {!detailsData?.timetypeuniqueness && checkvalidation && (
                  <small className='text-red-400'>Time Type Uniqueness is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='mindailylimit' className='block font-[500] mb-2'>
                  Min. Daily Hours
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  disabled={canEdit}
                  type='number'
                  title='mindailylimit'
                  id='mindailylimit'
                  min={0} // Minimum value set to 0
                  max={detailsData?.maxdailylimit || 24}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
                  placeholder='Minimum Daily Hours'
                  value={detailsData?.mindailylimit !== null && detailsData?.mindailylimit !== undefined ? detailsData?.mindailylimit : ''}
                  onChange={(e) => handleChangeInput('mindailylimit', e.target.value)}
               />
            </div>
            <div >
               <label htmlFor='maxdailylimit' className='block font-[500] mb-2'>
                  Max. Daily Hours
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  disabled={canEdit}
                  type='number'
                  title='maxdailylimit'
                  id='maxdailylimit'
                  min={detailsData?.mindailylimit || 0} // Minimum value set to mindailylimit or 0
                  max={24}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
                  placeholder='Maximum Daily Hours'
                  required=''
                  value={detailsData?.maxdailylimit || ''}
                  onChange={(e) => handleChangeInput('maxdailylimit', e.target.value)}
               />
               {!detailsData?.maxdailylimit && checkvalidation && (
                  <small className='text-red-400'>Maximum Daily Hours is required!</small>
               )}
            </div>


            <div >
               <label htmlFor='olidaycalendarintegration' className='block font-[500] mb-2'>
                  Holiday Calendar
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={holidayCalendarAttestaion || []}
                  value={detailsData?.holidaycalendarintegration}
                  noDataLabel='No costtype found'
                  name='holidaycalendarintegration'
                  labelkey='name'
                  className={`outline-gray-50 ${canEdit ? 'bg-white-50' : 'bg-gray-50'
                     } text-gray-600 border rounded-lg block w-full py-2`}
                  onChange={handleChangeInput}
                  id='holidaycalendarintegration'
               />
               {!detailsData?.holidaycalendarintegration && checkvalidation && (
                  <small className='text-red-400'> Holiday Calendar is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='weekdayentryallowed' className='block font-[500] mb-2 '>
                  Weekday Entry
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={yesNoWithboolean} value={detailsData?.weekdayentryallowed}
                  name='weekdayentryallowed'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />
            </div>
            <div>
               <label htmlFor='weekendentryallowed' className='block font-[500]  mb-2'>
                  Weekend Entry
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={yesNoWithboolean} value={detailsData?.weekendentryallowed}
                  name='weekendentryallowed'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />

            </div>
            <div>
               <label htmlFor='holidayallowed' className='block font-[500]  mb-2'>
                  Holiday Entry
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={yesNoWithboolean} value={detailsData?.holidayallowed}
                  name='holidayallowed'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />
            </div>
           
            <div>
               <label htmlFor='selfattestation' className=' block font-[500] mb-2'>
                  Self Attestation Required
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={yesNoWithboolean} value={detailsData?.selfattestation}
                  name='selfattestation'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />
            </div>

         </div>

         <div className='fixed bottom-0 mt-2 right-6'>
            {!canEdit && (
               <div className='fixed bottom-0 mt-2 right-6'>
                  <div className='flex items-center justify-end gap-2'>
                     <button
                        onClick={() => setCanEdit(false)}
                        type=''
                        className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                     >
                        Cancel
                     </button>
                     <PrimaryButton
                        disabled={canEdit}
                        className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                        onClick={handleSubmit}
                     >
                        Save
                     </PrimaryButton>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default memo(Details);
