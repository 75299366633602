import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../default';
const Details = (props) => {
  const planningState = useSelector((state) => state?.ProjectPlanning);
  const { taskGroups } = planningState;
  const ProjectTimesheet = useSelector((state) => state?.ProjectTimesheet);
  let { taskType } = ProjectTimesheet;
  const { detailSave, detailsData, setDetailsData, 
    // taskP, taskG
  } = props;
  const [checkvalidation, setcheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setcheckvalidation(true);
    let item = {
      ...detailsData,
    };
    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        taskjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
      <div>
          <label htmlFor='taskcode' className='block font-[500] mb-2'>
            Task Code
          </label>
          <input
          disabled
          	maxLength={50}
            type='text'
            title='taskcode'
            id='taskcode'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Task Code'
            required=''
            // value={detailsData?.customername || ''}
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('taskcode', e.target.value);
              }
            }}          />
        </div>
       <div>
          <label htmlFor='taskdesc' className='block font-[500] mb-2'>
            Task Name  <span className="text-red-300">*</span>
          </label>
          <input
            maxLength={50}
            type='text'
            title='taskdesc'
            id='taskid'
            className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
            placeholder='Task Name'
            required=''
            // value={detailsData?.customername || ''}
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('taskdesc', e.target.value);
              }
            }}
          />
          {!detailsData?.taskdesc && checkvalidation && (
            <small className='text-red-400'>Field is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='tasktype' className='block font-[500] mb-2'>
            Task Type
          </label>
          <CustomhtmlSelect
            options={taskType || []}
            value={detailsData?.tasktypeid || ''}
            noDataLabel='No Task Type'
            name='tasktypeid'
            labelkey='tasktype'
            className='p-2 border rounded-lg bg-gray-50'
            onChange={handleChangeInput}
            id='tasktypeid'
            disabled={true}
          />
          {!detailsData?.tasktypeid && checkvalidation && (
            <small className='text-red-400'>Field is required!</small>
          )}
        </div>

        <div>
          <label htmlFor='taskgroupdesc' className='block font-[500] mb-2'>
            Task Group
          </label>
          <CustomhtmlSelect
            options={taskGroups || []}
            value={detailsData?.taskgroupid || ''}
            noDataLabel='No Group'
            name='taskgroupid'
            labelkey='taskgroupdesc'
            className='p-2 border rounded-lg bg-gray-50'
            onChange={handleChangeInput}
            id='taskgroupid'
            disabled={true}
          />
          {!detailsData?.taskgroupid && checkvalidation && (
            <small className='text-red-400'>Field is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 mt-2 right-6'>
        <div className='flex items-center justify-end gap-2'>
          <PrimaryButton
            className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);