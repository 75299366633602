import SingleSelectField from "src/Components/SingleSelectForTable";

const Qualification = ({ educationData, canEdit, value, rowId, handleChange  }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'dateducationmasterid', value: value['dateducationmasterid'] } }
        handleChange(e, rowId)
    }

    return (
        <div>
            <SingleSelectField
                valueKey='dateducationmasterid'
                labelKey='educationmaster'
                options={educationData}
                value={value || 0}
                handleSelected={getSelected}
                disabled={!canEdit} />
        </div>
    )
}

export default Qualification;
