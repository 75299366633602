import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import SelectInput from "src/Components/SelectInput";
import SelectProjects from 'src/Components/SelectProjects';
import { GET_MANAGER_TIMESHEET_RECORDS, GET_TIMESHEET_USERLIST } from "src/Redux/actions/projects/timesheet";

const statusList = [{ value: 'Approved', label: "Approved" },
{ value: 'Submitted', label: "Submitted" },
{ value: '', label: "All" }]

export const uniqueUsers = (data, comuserid) => {
    const users = data.filter((user, index, arr) => {
        return arr.findIndex(u => u.comuserid === user.comuserid) === index;
    });
    let u = users?.filter(item => item?.comuserid !== +comuserid)
    u.push({
        firstname: 'All',
        lastname: '',
        username: '',
        uId: '32902902nelwndjwe',
        effortid:'29029029402',
        comuserid: null
    })
    return u
}


const ManagerFilters = ({ updatehooks }) => {
    const dispatch = useDispatch()
    let { weeks, page, limit, setLimit, setPage, project, setProject, selectStatus, setSelectStatus, selectUser, setSelectUser } = updatehooks
    const ProjectTimesheet = useSelector(state => state?.ProjectTimesheet)
    const comuserid = localStorage.getItem("comuserid")
    let { projectsOfM, timesheetUserUnderManager } = ProjectTimesheet

    // console.log(uniqueUsers(timesheetUserUnderManager, comuserid))
    const defaultPayload = {
        "loginuserid": comuserid,
        "comuserid": 0,
        "Tenantid": localStorage.getItem("Tenantid"),
        "projectid": project?.projectid || 0,
        "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
        "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
        "timesheetstatus": selectStatus?.value,
        "limit": 10,
        "page": 1
    }

    const selectProject = (p) => {
        setProject(p)
        dispatch(GET_TIMESHEET_USERLIST({ projectid: p?.projectid }))
        let payload = {
            "loginuserid": comuserid,
            "comuserid": selectUser?.comuserid || 0,
            "Tenantid": localStorage.getItem("Tenantid"),
            "projectid": p?.projectid || 0,
            "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
            "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
            "timesheetstatus": selectStatus?.value,
            "limit": limit,
            "page": page
        }
        dispatch(GET_MANAGER_TIMESHEET_RECORDS(payload))
    }

    useEffect(() => {
        if (timesheetUserUnderManager?.length > 0) {
            setSelectUser({})
        }
        // eslint-disable-next-line
    }, [timesheetUserUnderManager])

    const handleChangeStatus = (item) => {
        setSelectStatus(item)
        setPage(1)
        let payload = {
            "loginuserid": comuserid,
            "comuserid": selectUser?.comuserid,
            "Tenantid": localStorage.getItem("Tenantid"),
            "projectid": project?.projectid || 0,
            "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
            "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
            "timesheetstatus": item?.value,
            "limit": limit,
            "page": 1
        }
        dispatch(GET_MANAGER_TIMESHEET_RECORDS(payload))
    }

    const handleChangeStaff = (userInfo) => {
        setSelectUser(userInfo)
        let id = userInfo?.comuserid || 0
        if (userInfo?.comuserid === null) {
            setSelectUser({})
        }else{
            setSelectUser(userInfo)
        }

        let payload = {
            "loginuserid": comuserid,
            "comuserid": id,
            "Tenantid": localStorage.getItem("Tenantid"),
            "projectid": project?.projectid || 0,
            "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
            "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
            "timesheetstatus": selectStatus?.value,
            "limit": limit,
            "page": page
        }
        dispatch(GET_MANAGER_TIMESHEET_RECORDS(payload))
    }

    useEffect(() => {
        dispatch(GET_MANAGER_TIMESHEET_RECORDS(defaultPayload))
        // eslint-disable-next-line
    }, [weeks])

    const clearFilter = () => {
        setProject({})
        setSelectStatus({ value: '', label: "All" })
        setSelectUser({})
        setLimit(10)
        setPage(1)
        let clearData = {
            "loginuserid": comuserid,
            "comuserid": 0,
            "Tenantid": localStorage.getItem("Tenantid"),
            "projectid": 0,
            "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
            "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
            "timesheetstatus": '',
            "limit": 10,
            "page": 1
        }
        dispatch({type:'GET_TIMESHEET_USERLIST',data:[]})
        dispatch(GET_MANAGER_TIMESHEET_RECORDS(clearData))
    }
    return (
        <>
            <div className='ml-4 flex justify-start items-center space-x-4'>
                <div className='w-[100px]'>
                    <SelectInput
                        className="shadow py-1.5 bg-gray-50"
                        options={statusList}
                        keyname='label'
                        selected={selectStatus}
                        setSelected={handleChangeStatus}
                    />
                </div>
                <div className='min-w-[300px] ml-2 bg-gray-50 rounded-lg'>
                    <SelectProjects hideLabel={true}
                        Options={projectsOfM}
                        className=' flex justify-start items-center '
                        setSelected={selectProject}
                        selected={project} />
                </div>

                <div className='min-w-[200px] '>
                    <SelectInput
                        className="shadow py-1.5 bg-gray-50 w-full"
                        options={uniqueUsers(timesheetUserUnderManager, comuserid)}
                        keyname='firstname'
                        extrakey='lastname'
                        mapkey='effortid'
                        selected={selectUser || uniqueUsers(timesheetUserUnderManager, comuserid)[0]}
                        setSelected={handleChangeStaff}
                    />
                </div>
            </div>
            <button onClick={clearFilter} className='focus:outline-0 absolute right-12 '>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#EA3323"><path d="m609.52-472.52-75.65-75.65L663.3-713.22H368.83l-106-106H760q37.44 0 53.52 32.46 16.09 32.46-6.82 62.06L609.52-472.52ZM812.48-31.69 579.22-264.96V-200q0 24.35-17.44 41.78-17.43 17.44-41.78 17.44h-80q-24.35 0-41.78-17.44-17.44-17.43-17.44-41.78v-263.39L31.69-812.48l62.22-62.65L875.13-93.91l-62.65 62.22ZM533.87-548.17Z" /></svg>
            </button>
        </>
    )
}

export default ManagerFilters;