import { Fragment, useEffect, useState, useRef, useMemo } from 'react'
import { Combobox, Transition } from '@headlessui/react'

const SelectProjects = ({
	setSelected,
	selected,
	Options,
	defaultSet = true,
	hideLabel = false,
	className = 'relative flex items-center justify-start w-full pr-2 overflow-hidden text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-0 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'
}) => {
	const [query, setQuery] = useState('')
	const dropdownRef = useRef(null)
	const [isOpen, setIsOpen] = useState(false)

	// console.log(Options)
	useMemo(() => {
		if (selected?.projectname) {
			setIsOpen(false)
		}
		// eslint-disable-next-line
	}, [selected])

	useEffect(() => {
		const handleOutsideClick = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false)
			}
		}

		document.addEventListener('mousedown', handleOutsideClick)

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [])

	const filteredProjects =
		query === ''
			? Options
			: Options?.filter(project =>
				project?.projectname
					.toLowerCase()
					.replace(/\s+/g, '')
					.startsWith(query.toLowerCase().replace(/\s+/g, '')),
			)

	const handleInputClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<div style={{
				width: !hideLabel ? 450 : 'auto'
			}} className="flex items-center justify-start pl-2 bg-white border rounded-lg">
				{!hideLabel && <span className="text-sm font-[500] w-[140px]">Select Project: </span>}
				<Combobox className='w-full' value={selected} onChange={setSelected}>
					<div className='relative w-full'>
						<div className={className} onClick={handleInputClick}>
							<Combobox.Input
								autoComplete="off"
								placeholder='Enter Project Name'
								className='w-full bg-transparent outline-none border-none py-1.5 px-2 space-x-2 text-sm leading-5 text-gray-900 focus:ring-0'
								displayValue={project => (isOpen ? '' : project?.projectname)}
								onChange={event => setQuery(event.target.value)}
							/>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none"
								viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
								<path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
							</svg>

						</div>
						<Transition
							as={Fragment}
							show={isOpen}
							enter='transition ease-in duration-100'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='transition ease-out duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
							afterLeave={() => setQuery('')}
						>
							<Combobox.Options
								style={{ zIndex: 1 }}
								className='absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
								ref={dropdownRef}
							>
								{filteredProjects?.length === 0 && query !== '' ? (
									<div className='relative px-4 py-2 text-gray-700 cursor-default select-none'>
										Nothing found.
									</div>
								) : (
									filteredProjects?.map(project => (
										<Combobox.Option
											key={project?.projectid}
											className={({ active }) =>
												`relative cursor-pointer select-none p-2  ${active
													? 'bg-secondary/20 text-secondary'
													: 'text-gray-900'
												}`
											}
											value={project}
											onSelect={() => {
												setSelected(project)
											}}
										>
											{({ selected, active }) => (
												<>
													<span
														className={`block text-xs ${selected ? 'font-medium' : 'font-normal'
															}`}
													>
														{project?.projectname}
													</span>
												</>
											)}
										</Combobox.Option>
									))
								)}
							</Combobox.Options>
						</Transition>
					</div>
				</Combobox>
			</div>
		</>
	)
}

export default SelectProjects
