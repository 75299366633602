import { useState, useRef, useEffect, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertError } from 'src/Services';

function SingleSelectField({ options, handleSelected, value, labelKey, valueKey, disabled, hover = false }) {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('')
    const dropdownRef = useRef(null);

    const getByTitle = useMemo(() => {
        try {
            return options?.find(el => el[valueKey] === value)[labelKey];
        } catch (error) {
            return '';
        }
     // eslint-disable-next-line 
    }, [valueKey, options])

    useMemo(() => {
        setQuery(getByTitle)
        // eslint-disable-next-line
    }, [valueKey, options])

    const handleOpen = () => {
        setOpen(true)
        setQuery('')
    }

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredList =
        query === ''
            ? options
            : options.filter(el =>
                el?.projectname
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, '')),
            )

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative ">
                <button onClick={handleOpen} disabled={disabled} className='w-full border border-primary/40 py-1 rounded flex justify-start items-center px-1 bg-white'>
                    <input value={query || getByTitle}
                        disabled={disabled}
                        placeholder='Select Project'
                        onChange={(e) => setQuery(e.target.value || ' ')}
                        className='w-full text-xs focus:outline-none ' />
                </button>
            </div>
            {open && <div className="transition z-[1000] max-h-44 ease-in duration-100 absolute mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {filteredList?.map((item, itemIdx) => {
                    return (
                        <div
                            key={item?.uId || itemIdx}
                            className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-2 cursor-pointer ${item[valueKey] === value ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                            onClick={() => {
                                handleSelected(item)
                                setOpen(!open)
                                setQuery(item[labelKey])
                            }}
                        >
                            <span>
                                {item[labelKey]}
                            </span>
                            {item[valueKey] === value &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                            }
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}


const ProjectSearch = ({ hooks: {  it, projects, rowId } }) => {
    const dispatch = useDispatch()
    const employeeTimesheetData = useSelector(state => state?.ProjectTimesheet?.employeeTimesheetData)

    const getSelected = (valueis) => {
        let findAllreadySubmit  = employeeTimesheetData?.find(p=> p?.projectid === valueis.projectid && (p?.curstatus === 'Submitted' || p?.curstatus === 'Approved'))
        if(findAllreadySubmit){
            AlertError(`Timesheet has already been ${findAllreadySubmit?.curstatus} for this project for this week.`)
            return 
        }
        let items = employeeTimesheetData?.map(item => {
            if (item?.uId === rowId) {
                item['Allocationenddate'] = null
                item['Allocationstartdate'] = null
                item['allocation'] = null
                item['newChange'] = true
                item['projstaffid'] = null
                item['projectid'] = valueis?.projectid
                item['projectname'] = valueis?.projectname
                item['projtaskid'] = null
                item['projecttaskid'] = null
                item['selectedAllocation'] = null
                item['rolename'] = null
                item['projroleid'] = null
                item['timetypeid'] = null
                item['selectedTimetype'] = null
                item['timetypeUniqueness'] = null
                item['customerholidays'] = valueis?.customerholidays
                item['Companyholidays'] = valueis?.Companyholidays
                item['timesheetconfiguration'] = valueis?.timesheetconfiguration?.length ? valueis?.timesheetconfiguration[0] : valueis?.timesheetconfiguration
            }
            return item
        })
        dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
    }

    return (
        <SingleSelectField
            valueKey='projstaffid'
            labelKey='projectname'
            options={projects}
            value={it?.projstaffid || it?.projectid}
            handleSelected={getSelected}
            disabled={it?.curstatus === 'Approved'}
        />
    )
}

export default memo(ProjectSearch);
