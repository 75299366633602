import { memo } from "react";
import SingleSelectField from "src/Components/SingleSelectForTable";

const CustomerRole = ({ hooksValue, value, canEdit }) => {
    const { uId, getListOfProjectRole, selectCustomer } = hooksValue

    const getSelected = (v) => {
        let e = { target: { name: 'projroleid', value: v['projectroleid'] , customer : v} } 
        selectCustomer(e, uId)
    }

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='projectroleid'
                labelKey='rolename'
                options={getListOfProjectRole}
                value={value}
                handleSelected={getSelected}
                disabled={!canEdit}
        />
        </div>
    )
}

export default memo(CustomerRole);
