
import { useSelector } from "react-redux"
import { memo, useMemo, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { getUserAccessPayload } from "src/Services/constant/defaultPayload";
import { GET_USER_ACCESS_LIST } from "src/Redux/actions/masterdata/user";

const SelectSalesManager = ({  setAccountSelect, accountSelect, canEdit }) => {
  const UserManagementList = useSelector(state => state.UserManagementList)
  const { userlistforadmin, rolelist } = UserManagementList
  const Roleid = rolelist?.find(it => it?.code === 'SM')?.roleid
  const [searchTerm, setSearchTerm] = useState('');

  useMemo(() => {
    if (accountSelect?.firstname) {
      setSearchTerm(`${accountSelect?.firstname || ''} ${accountSelect?.lastname || ''} ${accountSelect?.employeecode ? `(${accountSelect?.employeecode || 0})` : ''}`)
    }
  }, [accountSelect])

  return (
    <>
      {Roleid && <CustomAutoSearch
        disabled={canEdit}
        searchTerm={searchTerm}
        API_CALL_FOR_SEARCH={GET_USER_ACCESS_LIST}
        searchPayload={{
          ...getUserAccessPayload(),
            Roleid:`{${Roleid}}`,
          username: searchTerm,
        }}
        setSearchTerm={setSearchTerm}
        options={userlistforadmin}
        // mapping key  (idkey)
        idkey='uId'
        optionLabelkey2='lastname'
        optionlabelKey='firstname'
        bracketLabel='username'
        getSelectedOption={setAccountSelect}
        className='p-2'
        placeholder='Select Sales manager' />}
    </>
  )
}

export default memo(SelectSalesManager)