import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ScrollToBottom from 'react-scroll-to-bottom'
import IconButton from 'src/Components/Buttons/IconButton'
import SelectMonth from 'src/Components/SelectMonth'
import { skillRowDefault } from 'src/Services/constant/staticValues'
import Domin from './selectDomain'
import Tech from './selectTech'
import Skillpopup from './skillpopup'

const cols = ['Skill', 'Expertise', 'Technology', 'Domain', 'Experience', 'Last Used', 'Proficiency', 'Primary']

const Table = ({ setFormData, formData, canEdit, }) => {
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const { skillList, proficiencylist } = EmployeeProfile

	const handleInputChange = (e, uId) => {
		const input = e.target.value
		if (/^[0-9]*$/.test(input) || input === '') {
			const updatedFormData = formData?.map(item => {
				if (item?.uId === uId) {
					let level = filterProficiencyByExperience(input)
					if (!item?.isprimary) {
						item['proficiencyid'] = level?.levelmasterid
					}
					item['experienceinmonths'] = input
				}
				return item
			})
			setFormData(updatedFormData)
		}
	}

	const filterProficiencyByExperience = experience => {
		const inputExperienceYears = experience / 12
		return proficiencylist?.find(level => {
			return (
				inputExperienceYears >= level.min_exp &&
				inputExperienceYears < level.max_exp
			)
		})
	}

	const handleAdd = () => {
		setFormData([
			...formData,
			{
				comuserskilltechnologyid: null,
				comuserid: Number(localStorage.getItem('comuserid') || 0),
				skillmasterid: null,
				technologymasterid: null,
				techversion: null,
				comtenantid: Number(localStorage.getItem('Tenantid') || 0),
				isactiveflag: true,
				createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
				notes: '',
				remarks: '',
				expertise: '',
				proficiencyid: null,
				domainid: null,
				lastusedyear: '',
				experienceinmonths: null,
				isprimary: false,
				uId:
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36),
			},
		])
	}

	const handleDelete = uId => {
		if (formData?.length > 1) {
			setFormData(formData.filter(item => item.uId !== uId))
		}
	}

	const handleChange = (e, uId) => {
		let { name, value, objectValue } = e.target
		let afterChange = formData?.map(item => {
			if (item?.uId === uId) {
				if (name === 'skillareamasterid') {
					// console.log('.')
				} else if (name === 'skillmasterid') {
					item['skillname'] = objectValue?.skill
					item['skillType'] = objectValue?.skilltype
				}
				if (name === 'expertise') {
					item[name] = String(value)
				} else {
					item[name] = Number(value)
				}
			}
			return item
		})
		setFormData(afterChange)
	}

	const handleMonthChange = (e, uId) => {
		const { name, value } = e.target
		const [year, month] = value.split('-') // Assuming the value format is 'YYYY-MM'
		const firstDayOfMonth = new Date(year, month - 1, 1) // Months are 0-indexed (0 - January, 1 - February, ...)
		const formattedDate = `${firstDayOfMonth.getFullYear()}-${(
			firstDayOfMonth.getMonth() + 1
		)
			.toString()
			.padStart(2, '0')}-01`
		let afterChange = formData?.map(item => {
			if (item?.uId === uId) {
				item[name] = String(formattedDate)
			}
			return item
		})
		setFormData(afterChange)
	}

	useEffect(() => {
		if (canEdit && formData === undefined) {
			setFormData([skillRowDefault()?.userskilltechnologyjson[0]])
		}
		// eslint-disable-next-line
	}, [canEdit])

	return (
		<ScrollToBottom className="h-screen">
			<div className='w-full'>
				<table className="relative w-full overflow-scroll text-sm text-gray-600">
					<thead>
						<tr className='text-left capitalize bg-gray-50'>
							{cols?.map(item => {
								const label = item.includes('*') ? item.replace('*', '') : item
								const isRequired = ['Skill', 'Expertise', 'Proficiency', 'Last Used', 'experience'].includes(
									label,
								)
								return (
									<th
										key={item}
										className='bg-gray-100 border font-[500] p-1'
									>
										{label}
										{isRequired && <span className='text-red-300'> *</span>}
									</th>
								)
							})}
							{canEdit && (
								<th className='bg-gray-100 border font-[500] p-1'>
									Actions
								</th>
							)}
						</tr>
					</thead>
					<tbody className='text-gray-600'>
						{formData?.map((item, i) => {
							let {
								skillmasterid,
								userskillid,
								skillareamasterid,
								technologymasterid,
								proficiencyid,
								expertise,
								domainid,
								experienceinmonths,
								lastusedyear,
								uId,
								isprimary,
								skillname,
								skill
							} = item

							return (
								<tr key={`${uId}-${i}`}>
									<td className='p-1 border'>
										<div>
											<Skillpopup
												isprimary={isprimary}
												uId={uId}
												skillareamasterid={skillareamasterid}
												skillList={skillList}
												handleChange={handleChange}
												canEdit={canEdit}
												skillmasterid={skillmasterid}
												userskillid={userskillid}
												skillname={skillname || skill}
											/>
										</div>
									</td>
									<td className='p-1 border'>
										<input
											disabled={!canEdit}
											onChange={e => handleChange(e, item?.uId)}
											type='text'
											value={expertise || ''}
											name='expertise'
											placeholder='Expertise'
											className={` placeholder:text-gray-400 placeholder:font-[200] px-0.5 w-[100px] ${canEdit ? 'border-0 rounded-md' : 'border-0'} py-0.5 w-full disabled:text-gray-800 disabled:bg-transparent focus:outline-none `}
											maxLength={50}
										/>
									</td>
									<td className='p-1 border'>
										<div>
											<Tech
												rowId={uId}
												value={technologymasterid}
												handleChange={handleChange}
												canEdit={canEdit}
											/>
										</div>
									</td>
									<td className='p-1 border'>
										<div>
											<Domin
												rowId={uId}
												value={domainid}
												handleChange={handleChange}
												canEdit={canEdit}
											/>
										</div>
									</td>
									<td className='p-1 border'>
										<div className='flex items-center justify-start space-x-1'>
											<input
												disabled={!canEdit}
												onChange={e => handleInputChange(e, item?.uId)}
												type='text'
												pattern='[0-9\s]*'
												value={experienceinmonths || ''}
												name='experienceinmonths'
												// placeholder=''
												className={` border  placeholder:text-gray-400 placeholder:font-[200] px-0.5 ${canEdit ? 'rounded-md' : 'border-0'} py-0.5 w-8 disabled:text-gray-800 disabled:bg-transparent focus:outline-none `}
												maxLength={3}
											/>
											<span>Months</span>
										</div>
									</td>
									<td className='p-1 border'>
										<SelectMonth onChange={e => handleMonthChange(e, uId)}
											disabled={!canEdit}
											className='flex items-center justify-start space-x-2 '
											name='lastusedyear'
											month={+(lastusedyear ? lastusedyear.slice(5, 7) : '')}
											year={+(lastusedyear ? lastusedyear.slice(0, 4) : '')}
										/>
									</td>
									<td className='p-1 border'>
										<div>
											<select
												disabled={true}
												value={proficiencyid || item?.proficiencyid}
												className={` placeholder:text-gray-500 placeholder:font-[200] px-0.5 w-[120px] ${canEdit ? 'border-0 rounded-md' : 'border-0'
													} py-0.5 w-full disabled:text-black/90 disabled:bg-transparent focus:outline-none `}
											>
												<option>Select Proficiency</option>
												{proficiencylist?.map(option => (
													<option
														key={option.levelmasterid}
														value={option.levelmasterid}
													>
														{option.levelname}
													</option>
												))}
											</select>
										</div>
									</td>
									<td className='p-1 border'>
										<input
											type='checkbox'
											checked={isprimary || false}
											readOnly
											name={`isprimary`}
											className='block w-full px-2 text-gray-600 outline-gray-100 focus:outline-none '
										/>
									</td>
									{canEdit && (
										<td className='p-1 border'>
											<div className='flex justify-end space-x-1'>
												{!isprimary && <IconButton
													title='Remove Skill'
													onClick={() => handleDelete(item?.uId)}
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'
													>
														<path
															fillRule='evenodd'
															d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
															clipRule='evenodd'
														/>
													</svg>
												</IconButton>}
												<span className="hidden addIconbutton-add-btn">
													<IconButton
														title='Add Skill'
														disabled={formData?.length >= 10}
														onClick={handleAdd}
														className='w-6 h-6 p-1 text-white disabled:bg-primary/20 bg-primary'
													>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															viewBox='0 0 24 24'
															fill='currentColor'
															className='w-6 h-6'
														>
															<path
																fillRule='evenodd'
																d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
																clipRule='evenodd'
															/>
														</svg>
													</IconButton>
												</span>
											</div>
										</td>
									)}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</ScrollToBottom>
	)
}

export default Table
