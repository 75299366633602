import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import NoPermissionComponent from 'src/Components/NoPermission'
import { GET_REPORT_MASTER_LIST } from 'src/Redux/actions/reports'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import TimesheetReports from './timesheet'
import ExceptionReports from './timesheetException'
import NonComplianceReports from './timesheetNonCompliance'

const ReportLayout = ({ modulename, menu }) => {
    const [reportId, setReportId] = useState(null)
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    let { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const location = useLocation()
    const dispatch = useDispatch()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [menu])

    const hasViewPermission = thisPagePermissions?.includes('View Reports')

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    const getIdByReportName = (name) => {
        return reportMasterList?.find((row) => row?.reportname === name)?.reportid
    }

    const renderReportComponent = useMemo(() => {
        if (!hasViewPermission) return <NoPermissionComponent />
        if (reportId === null) {
            let id = reportMasterList?.find((row) => row?.reportname === 'Timesheet Details')?.reportid
            setReportId(id)
        }
        switch (reportId) {
            case getIdByReportName('Timesheet Details'):
                return <TimesheetReports reportId={reportId} setReportId={setReportId} />
            case getIdByReportName('Timesheet Exception'):
                return <ExceptionReports reportId={reportId} setReportId={setReportId} />
            case getIdByReportName('Timesheet Non-Compliance'):
                return <NonComplianceReports reportId={reportId} setReportId={setReportId} />
            default:
                return <NoPermissionComponent />
        }
        // eslint-disable-next-line
    }, [reportId, reportMasterList])

    return (
        <>
            {reportId === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                renderReportComponent
            )}
            <ToastContainer position="bottom-center" newestOnTop limit={1} />
        </>
    )
}

export default ReportLayout
