import moment from 'moment';
import { useState, useRef, useEffect, memo } from 'react';

function SingleSelectField(props) {
    const [open, setOpen] = useState(false);
    const [startTimeHours, setStartTimeHours] = useState(0);
    const [startTimeMinutes, setStartTimeMinutes] = useState(0);
    const [startTimePeriod, setStartTimePeriod] = useState('AM');
    const [endTimeHours, setEndTimeHours] = useState(0);
    const [endTimeMinutes, setEndTimeMinutes] = useState(0);
    const [endTimePeriod, setEndTimePeriod] = useState('PM');
    const dropdownRef = useRef(null);
    let {effortdate, active, setActive, item, effortid, disabled} = props

    // console.log(props)
    const calculateDuration = () => {
        const startTimestamp = startTimeHours * 60 + startTimeMinutes + (startTimePeriod === 'PM' ? 12 * 60 : 0);
        const endTimestamp = endTimeHours * 60 + endTimeMinutes + (endTimePeriod === 'PM' ? 12 * 60 : 0);
        let durationInMinutes = endTimestamp - startTimestamp;
        if (durationInMinutes < 0) {
            durationInMinutes += 24 * 60; // Add 24 hours if end time is before start time
        }

        const durationHours = (durationInMinutes / 60).toFixed(1);
        return durationHours
    };

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (item?.endtime) {
            let [h, m, amPm] = moment(item?.starttime, 'HH:mm').format('h:mm:A').split(':')
            setStartTimeHours(Number(h))
            setStartTimeMinutes(Number(m))
            setStartTimePeriod(amPm)
        } if (item?.starttime) {
            let [h, m, amPm] = moment(item?.endtime, 'HH:mm').format('h:mm:A').split(':')
            setEndTimeHours(Number(h))
            setEndTimeMinutes(Number(m))
            setEndTimePeriod(amPm)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (startTimeHours !== 0 && endTimeHours !== 0) {
                // console.log(startTimeHours)
                let data = { ...active }
                let updatedEffort = data?.timesheetdata?.map(row => {
                    // console.log(row?.effortid , item?.effortid)
                    if (row?.effortid === effortid) {
                        row?.timesheetdetails?.map(effortrow => {
                            if (effortrow?.effortdate === effortdate) {
                                effortrow['efforthours'] = Number(calculateDuration())
                                effortrow['starttime'] = moment(`${startTimeHours}:${startTimeMinutes} ${startTimePeriod}`, 'h:mm A').format('HH:mm')
                                effortrow['endtime'] = moment(`${endTimeHours}:${endTimeMinutes} ${endTimePeriod}`, 'h:mm A').format('HH:mm')

                            }
                            return effortrow
                        })
                    }
                    return row
                })
                // console.log(updatedEffort)
                setActive({ ...active, timesheetdata: updatedEffort })
            }
        }, 800)

        return () => {
            clearTimeout(debounceTimer)
        }
        // eslint-disable-next-line
    }, [
        startTimeHours, startTimeMinutes, startTimePeriod,
        endTimeHours, endTimeMinutes, endTimePeriod,
    ])

    const handleOpen = () => {
        if(disabled) return
        setOpen(true)
    }
    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative w-full">
                <div role='button' className='w-full' onClick={handleOpen}>
                    <span className='w-full space-x-1 flex justify-between items-center'>
                        <span className='text-gray-800'>
                            {item?.efforthours}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                        </svg>
                    </span>
                </div>
            </div>
            {open && <div className="p-2 -top-2 left-20 bg-[#f5f5f5] transition z-[1000] ease-in duration-100 absolute mt-1 w-[130px] rounded-sm  text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div>
                    <p className='text-gray-700 text-xs mb-1'>Start Time</p>
                    <div className='text-gray-600 p-1 border shadow bg-white border-secondary rounded-md flex justify-start items-center space-x-1'>
                        <input className='outline-none w-1/2 text-xs bg-transparent' type='number' placeholder='HH' value={startTimeHours || ''} onChange={(e) => {
                            if (e.target.value >= 1 && e.target.value <= 12) {
                                setStartTimeHours(parseInt(e.target.value || ''))
                            } else if (e?.target?.value?.trim() === '') {
                                setStartTimeHours(0)
                            }
                        }} />
                        <span>:</span>
                        <input className='outline-none w-1/2 text-xs bg-transparent' type='number' placeholder='MM' value={startTimeMinutes || 0} onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 60) {
                                setStartTimeMinutes(parseInt(e.target.value || ''))
                            } else if (e?.target?.value?.trim() === '') {
                                setStartTimeMinutes(0)
                            }
                        }} />
                        <select className='outline-none text-xs bg-transparent' value={startTimePeriod || 0} onChange={(e) => setStartTimePeriod(e.target.value)}>
                            <option value='AM'>AM</option>
                            <option value='PM'>PM</option>
                        </select>
                    </div>
                </div>
                <div className='mt-1'>
                    <p className='text-gray-700 text-xs mb-1'>End Time</p>
                    <div className='text-gray-600 p-1 border shadow bg-white border-secondary rounded-md flex justify-start items-center space-x-1'>
                        <input className='outline-none w-1/2 text-xs bg-transparent' type='number' placeholder='HH' value={endTimeHours || ''} onChange={(e) => {
                            if (e.target.value >= 1 && e.target.value <= 12) {
                                setEndTimeHours(parseInt(e.target.value || ''))
                            } else if (e?.target?.value?.trim() === '') {
                                setEndTimeHours(0)
                            }
                        }} />
                        <span>:</span>
                        <input className='outline-none w-1/2 text-xs bg-transparent' type='number' placeholder='MM' value={endTimeMinutes || 0} onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 60) {
                                setEndTimeMinutes(parseInt(e.target.value || ''))
                            } else if (e?.target?.value?.trim() === '') {
                                setEndTimeMinutes(0)
                            }
                        }} />
                        <select className='outline-none text-xs bg-transparent' value={endTimePeriod} onChange={(e) => setEndTimePeriod(e.target.value)}>
                            <option value='AM'>AM</option>
                            <option value='PM'>PM</option>
                        </select>
                    </div>
                </div>
            </div>}
        </div>
    )
}


const TimeSelect = (props) => {

    return (
        <div className='w-full pr-1'>
            <SingleSelectField {...props} />
        </div>
    )
}

export default memo(TimeSelect);
