import moment from "moment";
import { memo } from "react";

const DayHeaders = ({ startdate }) => {
    const startDate = moment(startdate); // replace with your own start date
    const dates = [];

    for (let i = 0; i < 7; i++) {
        const nextDate = startDate.clone().add(i, 'days');
        // const dayis = nextDate.format('llll').split(',')[0];
        // console.log(nextDate)
        const formattedDate = `${nextDate.format('dd')} (${nextDate.format('DD/MM')})`;
        dates.push(formattedDate);
        // dates.push(`${dayis} (${formattedDate})`);
    }

    return (
        <>
            {startdate !== 'Invalid date' &&
                dates?.map((date) => {
                    return (
                        <th key={date} className="p-1 border font-[500] text-start">
                            <div className="w-auto">
                                {date}
                            </div>
                        </th>
                    )
                })
            }
        </>
    )
}

export default memo(DayHeaders)