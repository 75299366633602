import { useDispatch, useSelector } from 'react-redux'
import SelectMonth from 'src/Components/SelectMonth'
import SelectProjects from 'src/Components/SelectProjects'
import { getDefaultRevenuePayload } from 'src/Pages/app/Financials/revenue/defaultPayload'
import { GET_PROJECT_REVENUE } from 'src/Redux/actions/projects/financials'

const FinancialsFilterRevenue = ({ cols = 'grid-cols-6', project, setRevenue, filters, setFilters, setProject }) => {
    const dispatch = useDispatch()
    const { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)

    const handleSelectChange = (e) => {
        let { name, value } = e.target
        setFilters({ ...filters, [name]: value })
    }

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        const parsedMonth = parseInt(month, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        setFilters({
            ...filters,
            [name]: value,
            Monthid: validMonthid,
            Yearid: validYearid,
        })
    }

    const applyFilters = async () => {
        let res = await dispatch(
            GET_PROJECT_REVENUE({
                ...filters,
                Projectid: project?.projectid,
            }),
        )
        setRevenue(res)
    }

    const clearFilters = () => {
        if (projectsOfM?.length > 0) {
            setFilters(getDefaultRevenuePayload(0))
            setProject({ projectid: 0, projectname: 'All' })
            dispatch(GET_PROJECT_REVENUE(getDefaultRevenuePayload(0)))
        }
    }

    return (
        <div className="w-full p-2 text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <p className="font-[500] pb-1">Select Project</p>
                    <SelectProjects
                        hideLabel={true}
                        Options={projectsOfM}
                        setSelected={setProject}
                        selected={project}
                    />
                </div>
                <div>
                    <p className="font-[500]">Select Month/Year</p>
                    <SelectMonth
                        onChange={handleMonthChange}
                        name="Monthid"
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        year={filters?.Yearid}
                        month={filters?.Monthid?.length === 1 ? `0${filters?.Monthid}` : filters?.Monthid}
                    />
                </div>
                <div>
                    <p className="font-[500]">Select Profit Center Code</p>
                    <input
                        value={filters?.Profitcentercode || ''}
                        onChange={handleSelectChange}
                        name="Profitcentercode"
                        placeholder="Profit Center Code "
                        type="text"
                        className="w-full p-1.5 mt-1 text-gray-600 border rounded-md"
                    />
                </div>
                <div />
                <div className="flex justify-end mt-0">
                    <div className="w-40">
                        <h1 className="mb-1 font-[500]">Action</h1>
                        <div className="grid grid-cols-2 gap-2">
                            <button
                                onClick={clearFilters}
                                className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                            >
                                Clear
                            </button>
                            <button
                                onClick={applyFilters}
                                className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialsFilterRevenue
