import { useMemo } from "react";
import { useSelector } from "react-redux";


const ApproveAndRejectButton = ({ hooks }) => {
    const listOfStaffWithType = useSelector((state => state?.ProjectStaffing?.listOfStaffWithType))
    const { handleStatus, item, StaffingStatusId, statffingstatus } = hooks

    const getIdByNameOfStatus = (name) => {
        return listOfStaffWithType?.find(it => it?.statusname === name)?.statusid
    }

    const findStatusIdByName = useMemo(() => {
        switch (statffingstatus) {
            case 'Staffing Approval':
                return getIdByNameOfStatus('Rejected')
            case 'Release Approval':
                return getIdByNameOfStatus('Staffed')
            case 'Extension Approval':
                return getIdByNameOfStatus('Staffed')
            default:
                break;
        }
        return
        // eslint-disable-next-line
    }, [listOfStaffWithType])

    const findStatusIdForApprove = useMemo(() => {
        switch (statffingstatus) {
            case 'Staffing Approval':
                return getIdByNameOfStatus('Staffed')
            case 'Release Approval':
                return getIdByNameOfStatus('Staffed')
            case 'Extension Approval':
                return getIdByNameOfStatus('Staffed')
            default:
                break;
        }
        return
        // eslint-disable-next-line
    }, [listOfStaffWithType])

    return (
        <div className="flex items-center justify-start space-x-2">
            <button onClick={() => handleStatus(findStatusIdByName, item?.uId, statffingstatus, 'reject')} className={`border rounded-full flex justify-center items-center focus:outline-0 w-6 h-6 ${(StaffingStatusId === findStatusIdByName && item?.actionType === 'reject') ? 'bg-red-500 text-white' : 'text-red-500'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <button onClick={() => handleStatus(findStatusIdForApprove, item?.uId, statffingstatus, 'approve')} className={`border rounded-full flex justify-center items-center focus:outline-0 w-6 h-6 ${(StaffingStatusId === findStatusIdForApprove && item?.actionType === 'approve') ? 'bg-green-500 text-white' : 'text-green-500'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
            </button>
        </div>
    )
}
export default ApproveAndRejectButton;