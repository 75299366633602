export const detailItem = () => {
    return {
        datsubscriptionpackageid: 0,
        packagename: "",
        activeflag: true,
        createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
        notes: "",
        noofusers: 0,
        priceperuser: 0,
        datsubscriptionpackagemodule: [
            {
                datmoduleid: 0
            },
            {
                datmoduleid: 0
            }
        ]
    }
}

