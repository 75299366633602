import IconButton from "src/Components/Buttons/IconButton";
import { defaultContactItem } from "../defaultPayload";
import { useSelector } from "react-redux";
import { memo } from "react";
import SelectInput from "src/Components/SelectInput";
import { AlertError } from "src/Services";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const cols = ["Customer SPOC", "Name", "Designation", "Contact Type", "Email", "Contact No.", "Action"]

const CustomerInfoDynamicForm = ({ hooks }) => {
    const { selectedContacts, setSelectedContacts } = hooks
    const { CustomerContacts } = useSelector(state => state?.CustomerManagementList)

    const handleAdd = () => {
        if (selectedContacts?.length < 5) {
            setSelectedContacts([...selectedContacts, {
                ...defaultContactItem(),
                uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
            }]);
        }
    };

    const handleDelete = (uId) => {
        if (selectedContacts?.length > 1) {
            setSelectedContacts(selectedContacts.filter((item) => item.uId !== uId));
        }
    };


    const selectCustomer = (item, rowId) => {
        let duplicate = selectedContacts?.find(contact => contact.createdcomuserid === item?.customerid)
        if (duplicate) {
            AlertError('Duplicate contact ')
            return
        }
        let copyItem = selectedContacts?.map((contact) => {
            if (contact?.uId === rowId) {
                contact['contacttype'] = item?.contacttype
                contact['contactpersonname'] = item?.contactpersonname
                contact['designation'] = item?.designation
                contact['phoneno'] = item?.phoneno
                contact['email'] = item?.email
                contact['createdcomuserid'] = JSON.parse(localStorage.getItem('comuserid'))
            }
            return contact
        })
        setSelectedContacts(copyItem)
    }

    return (
        <>
            <div className="flex justify-between items-center mt-7 text-sm">
                <p className="font-[500]"> Contact Info <span className="text-red-300">*</span></p>

            </div>
            <div className='h-52'>
                <table className="w-full mt-2 text-sm">
                    <thead>
                        <tr className="text-left capitalize bg-gray-50">
                            {cols?.map((col) => {
                                return (
                                    <th key={col} title={col} className="p-2 border font-[500] text-start">{col}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className="text-gray-600">
                        {selectedContacts?.map((item, i) => {
                            const { uId, contacttype, contactpersonname, designation, phoneno, email } = item
                            return (
                                <tr key={uId} className="text-left">
                                    <td className="border">
                                        <SelectInput
                                            className="bg-white border-0 p-0 h-6"
                                            options={CustomerContacts || []}
                                            keyname='contactpersonname'
                                            selected={item}
                                            setSelected={(it) => {
                                                selectCustomer(it, uId)
                                            }}
                                        />
                                    </td>
                                    <td className="border p-1.5">
                                        {contactpersonname}
                                    </td>
                                    <td className="border p-1.5">
                                        {designation}
                                    </td>
                                    <td className="border p-1.5">
                                        {contacttype}
                                    </td>
                                    <td className="border p-1.5">
                                        {email}
                                    </td>
                                    <td className="border p-1.5">
                                        {phoneno}
                                    </td>
                                    <td className="border p-1.5">
                                        <div className='flex justify-start items-center'>
                                            {selectedContacts?.length !== 1 ? <IconButton
                                                title="Delete Contact"
                                                onClick={() =>
                                                    handleDelete(uId)
                                                }
                                                className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                <MinusIcon className="w-3" />
                                            </IconButton> : <div className="w-5" />}
                                            <IconButton
                                                title="Add Contact"
                                                disabled={i > 4}
                                                onClick={handleAdd} className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                <PlusIcon className="w-3" />
                                            </IconButton>
                                        </div>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default memo(CustomerInfoDynamicForm);