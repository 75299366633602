
export const OverheadsItem = () => {
      return {
            overheadmasterid: null,
            overhead: "",
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            isactiveflag: true,
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            notes: "",
            costtype: ""
      }
}

export const CostRatesItem = () => {
      return {
            uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
            orgcostrateid: null,
            companyid: null,
            designationid: null,
            bandid: null,
            skillareamasterid: null,
            techgroupid: null,
            countryid: null,
            cost: null,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            comcurrencyid: null,
            unit: null,
            startdate: null,
            enddate: null,
            isactiveflag: true,
      }
}