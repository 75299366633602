import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { GET_INSUDTRY_MASTER, GET_SKILLAREA_MASTER, GET_SKILL_MASTER, GET_TECHNOLOGY_MASTER, } from 'src/Redux/actions/workforce/eprofile'
import { getDeafultIndustryData, getDeafultSkillAreaData, getDeafultSkillData, getDeafultTechnologyData, } from 'src/Services/constant/defaultPayload'
import SkillArea from './skillArea'
import Technology from './technology'
import Industry from './domain'
import Importance from './importance'
import SkillInput from './skill'
import { AlertError } from 'src/Services'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'
import { GET_DEMAND_MASTER, SAVE_DEMAND_SKILL_MASTER } from 'src/Redux/actions/workforce/demands'
import { insertDemandSkillExpertise } from './defaultItems'

const cols = ['Skill Area', 'Skill', 'Expertise', 'Technology', 'Domain', 'Importance']
const importancelist = [{ name: 'Must have', importance: 1 }, { name: 'Good to have', importance: 2 },]

const SkillExperties = ({ hooks }) => {
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const { skillData, setSkillData, demandStaff } = hooks
	const { technologyList, industrylist, skillList } = EmployeeProfile
	const dispatch = useDispatch()
	const DemandId = demandStaff?.demandid
	const getACtiveRecordOnly = (data) => {
		return data?.filter(it => it?.isactiveflag === true)
	}
	useEffect(() => {
		dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
		dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()))
		dispatch(GET_TECHNOLOGY_MASTER(getDeafultTechnologyData()))
		dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()))
	}, [dispatch])

	const SelectHandleChange = (e, uId) => {
		let { name, value } = e.target
		let afterChange = skillData?.map(item => {
			if (item?.uId === uId) {
				item[name] = value
			}
			return item
		})
		setSkillData(afterChange)
	}

	const handleAdd = () => {
		if (skillData?.length < 10) {
			setSkillData([...skillData, insertDemandSkillExpertise(DemandId)])
		}
	}
	const handleDelete = uId => {
		if (skillData?.length > 1) {
			let items = skillData?.map(item => {
				if (item?.uId === uId) {
					item['isactiveflag'] = false
				}
				return item
			})
			setSkillData(items)
		}
	}

	const handleSubmit = async () => {
		for (let item of skillData) {
			let { userskillattributes, skillType } = item
			let techskill = userskillattributes?.filter(
				it => it?.technologymasterid !== undefined,
			)
			let domainskill = userskillattributes?.filter(
				it => it?.industrymasterid !== undefined,
			)
			if (skillType === 'Technical') {
				if (techskill?.length === 0) {
					AlertError(`Technology is required for ${item['skillname']}! `)
					return
				}
				let aftertest = techskill?.filter(
					el =>
						el?.technologymasterid !== undefined &&
						(el?.technologymasterid === null || el.technologymasterid === 0),
				)
				if (aftertest.length > 0) {
					AlertError(`Technology is required for ${item['skillname']}! `)
					return
				}
			} else if (skillType === 'Domain') {
				if (domainskill?.length === 0) {
					AlertError(`Domain is required for ${item['skillname']}! `)
					return
				}
				let aftertest = domainskill?.filter(
					el =>
						el?.industrymasterid !== undefined &&
						(el?.technologymasterid === null || el?.technologymasterid === 0),
				)
				if (aftertest.length > 0) {
					AlertError(`Domain is required for ${item['skillname']}! `)
					return
				}
			}
		}
		let insertKeysare = ['demandskillid', 'demandid', 'skillmasterid', 'expertise', 'technologymasterid', 'domainid', 'importance', 'comtenantid', 'isactiveflag', 'createdcomuserid', 'modifiedcomuserid',]
		let isValidated = CheckListObjectValidation(skillData, ['notes', 'demandid', 'demandskillid', 'technologymasterid','domain', 'domainid', 'expertiseid', 'skillarea', 'modifiedtimestamp', 'modifiedcomuserid'])
		if (isValidated?.isvalid) {
			let res = await dispatch(SAVE_DEMAND_SKILL_MASTER(skillData, insertKeysare, +DemandId))
			if (res?.success) {
				let res = await dispatch(GET_DEMAND_MASTER(+DemandId))
				if (res?.data?.length > 0) {
					let resData = res?.data[0]
					let skills = resData?.demandskills?.map((skill) => {
						skill['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
						return skill
					})
					setSkillData(skills || [insertDemandSkillExpertise()])
				}
			
			}
		}
	}

	return (
		<div className='mt-4 w-full h-[70vh] text-sm'>
			<div className='my-2 text-sm'>
				<div className='w-full h-[60vh] overflow-scroll'>
					<table className='w-full text-left text-gray-500'>
						<thead className='capitalize text-textSecondary bg-gray-50'>
							<tr className='text-left capitalize bg-gray-50'>
								{cols?.map(item => {
									return (
										<th
											key={item}
											className='bg-gray-100 border-b  font-[500] py-2 px-2'
										>
											{item}
										</th>
									)
								})}
								<th className='bg-gray-100 border-b font-[500] py-2 px-2'>
									Action
								</th>
							</tr>
						</thead>
						<tbody className='text-gray-600'>
							{getACtiveRecordOnly(skillData)?.map((item, i) => {
								return (
									<Fragment key={`${item?.uId}-${i}`}>
										<tr key={item?.uId} className='bg-white border-b'>
											<td className='px-2 py-2 border'>
												<div>
													<SkillArea
														rowid={item?.uId}
														value={item?.skillareamasterid || 0}
														options={skillList}
														handleChange={SelectHandleChange}
													/>
												</div>
											</td>
											<td className='px-2 py-2 border'>
												<div className=' min-w-[80px] '>
													<SkillInput
														item={item}
														skillareamasterid={item?.skillareamasterid || 0}
														rowid={item?.uId}
														value={item?.skillmasterid || ''}
														handleChange={SelectHandleChange}
													/>
												</div>
											</td>
											<td className='px-1 py-1 border'>
												<input
													onChange={e => SelectHandleChange(e, item?.uId)}
													type="text"
													value={item?.expertise || ''}
													id={item?.uId}
													name="expertise"
													placeholder='Expertise'
													maxLength={50}
													className={` placeholder:text-gray-400 placeholder:font-[200] px-0.5 w-[120px] ${true ? 'border-0 rounded-md' : 'border-0'} py-0.5 w-full disabled:text-gray-800 disabled:bg-transparent focus:outline-none `}
												/>
											</td>
											<td className='px-2 py-2 border'>
												<Technology
													value={item?.technologymasterid || ''}
													rowid={item?.uId}
													handleChange={SelectHandleChange}
													options={technologyList}
												/>
											</td>
											<td className='px-2 py-2 border'>
												<Industry
													value={item?.domainid || ''}
													rowid={item?.uId}
													handleChange={SelectHandleChange}
													options={industrylist}
												/>
											</td>
											<td className='px-2 py-2 border'>
												<Importance
													value={item?.importance || ''}
													rowid={item?.uId}
													handleChange={SelectHandleChange}
													options={importancelist}
												/>
											</td>
											{<td className='p-1 border'>
												<div className='flex justify-end space-x-1'>
													{getACtiveRecordOnly(skillData)?.length !== 1 && (
														<button
															onClick={() => handleDelete(item?.uId)}
															className='flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary'
														>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																viewBox='0 0 24 24'
																fill='currentColor'
																className='w-4 h-4'
															>
																{' '}
																<path
																	fillRule='evenodd'
																	d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
																	clipRule='evenodd'
																/>{' '}
															</svg>
														</button>
													)}
													{getACtiveRecordOnly(skillData)?.length - 1 === i && (
														<button
															disabled={skillData?.length >= 10}
															onClick={handleAdd}
															className='flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary'
														>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																viewBox='0 0 24 24'
																fill='currentColor'
																className='w-4 h-4'
															>
																{' '}
																<path
																	fillRule='evenodd'
																	d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
																	clipRule='evenodd'
																/>{' '}
															</svg>
														</button>
													)}
												</div>
											</td>}
										</tr>
									</Fragment>
								)
							})}
						</tbody>
					</table>
				</div>
				<div className='fixed bottom-0 mt-2 right-6'>
					<div className='fixed bottom-2 right-5'>
						<PrimaryButton
							className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
							onClick={handleSubmit}
						>
							Save
						</PrimaryButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SkillExperties
