import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
    GET_LEVEL_MASTER,
    GET_SKILLS_EMPLOYEE,
} from 'src/Redux/actions/workforce/eprofile'
import { getDeafultProficiencyData } from 'src/Services/constant/defaultPayload'
import Skillpopup from './skillpopup'
import ScrollToBottom from 'react-scroll-to-bottom'

const SkillPortfolio = ({ talentId }) => {
    const dispatch = useDispatch()
    const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
    const { proficiencylist, userSkillsDetail } = EmployeeProfile
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const tenid = searchParams.get('tenid')

    useEffect(() => {
        dispatch(GET_SKILLS_EMPLOYEE({ comuserid: +talentId, Tenantid: +tenid }))
        dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData()))
    }, [dispatch, talentId, tenid])

    return (
        <div className="my-2 text-sm">
            <ScrollToBottom className="h-[48vh]">
                <table className="w-full text-left text-textSecondary">
                    <thead className="capitalize bg-gray-50">
                        <tr className="bg-gray-100  font-[500] border-b">
                            <th className="border font-[500] px-1 py-1">Skill</th>
                            <th className="border font-[500] px-1 py-1">Expertise</th>
                            <th className="border font-[500] px-1 py-1">Technology</th>
                            <th className="border font-[500] px-1 py-1">Domain</th>
                            <th className="border font-[500] px-1 py-1">Experience</th>
                            <th className="border font-[500] px-1 py-1">Last Used</th>
                            <th className="border font-[500] px-1 py-1">Proficiency</th>
                            <th className="border font-[500] px-1 py-1">Primary</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userSkillsDetail?.map((item, i) => {
                            let {
                                skillarea,
                                skill,
                                expertise,
                                technology,
                                proficiencyid,
                                lastusedyear,
                                experienceinmonths,
                                industryname,
                                isprimary,
                            } = item
                            return (
                                <tr key={i} className="bg-white border-b">
                                    <td className="px-1 py-1 border">
                                        <Skillpopup skillarea={skillarea} skill={skill} />
                                    </td>
                                    <td className="px-1 py-1 border">{expertise}</td>
                                    <td className="px-1 py-1 border">{technology}</td>
                                    <td className="px-1 py-1 border">{industryname}</td>
                                    <td className="px-1 py-1 border">
                                        {experienceinmonths || ''}{' '}
                                        {experienceinmonths && 'Months'}
                                    </td>
                                    <td className="px-1 py-1 border">
                                        {lastusedyear &&
                                            moment(lastusedyear).format('DD-MM-YYYY')}
                                    </td>
                                    <td className="px-1 py-1 border">
                                        {proficiencylist !== null ? (
                                            <select
                                                name="proficiencyid"
                                                disabled={true}
                                                value={proficiencyid || 0}
                                                className={`w-full disabled:text-black/90 focus:outline-none `}
                                            >
                                                {proficiencylist?.map((option) => {
                                                    return (
                                                        <option
                                                            key={option?.levelmasterid}
                                                            value={
                                                                option?.levelmasterid || 0
                                                            }
                                                        >
                                                            {option?.levelname}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td className="px-2 py-2 border">
                                        <input
                                            type="checkbox"
                                            checked={isprimary || false}
                                            name={`isprimary`}
                                            readOnly
                                            className="block w-full px-2 text-gray-600 outline-gray-100 focus:outline-none "
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </ScrollToBottom>
        </div>
    )
}

export default SkillPortfolio
