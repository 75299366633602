import axios from 'axios'
import {
    ACTION_GET_LEAVETYPE_MASTER,
    ACTION_GET_OVERHEAD_MASTER,
    ACTION_GET_PROJECT_EXPENSES,
    ACTION_GET_PROJECT_PROFITABLE,
    ACTION_GET_PROJECT_REVENUE,
    ACTION_GET_PROJECT_USER_PAYROLL,
    ACTION_GET_TIMESHEET_BILLING,
    ACTION_GET_USER_LEAVE_DATA,
} from 'src/Redux/types'
import { AlertError, AlertSuccess } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER
const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
}

export const GET_OVERHEAD_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_overheadmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Overhead found!')
                dispatch({ type: ACTION_GET_OVERHEAD_MASTER, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_TIMESHEET_BILLING = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_timesheetdetails_config`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Timesheet data found!')
                dispatch({ type: ACTION_GET_TIMESHEET_BILLING, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_LEAVETYPE_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_LeavetypeMaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No leavetype found!')
                dispatch({ type: ACTION_GET_LEAVETYPE_MASTER, data: items || [] })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROJECT_EXPENSES = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectexpense`
            dispatch({ type: ACTION_GET_PROJECT_EXPENSES, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No expenses found for selected project!')
                dispatch({ type: ACTION_GET_PROJECT_EXPENSES, data: addnewkey || [] })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const INSERT_PROJECT_EXPENSES = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/insert_projectexpense`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const projexpenseid = item?.projexpenseid
                if (item?.Message === 'Success') {
                    AlertSuccess('projexpense saved successfully!')
                }
                return { success: true, projexpenseid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const INSERT_PROJECT_REVENUE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/insert_projectrevenue`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const projrevid = item?.projrevid
                if (item?.Message === 'Success') {
                    AlertSuccess('projrevenue saved successfully!')
                }
                return { success: true, projrevid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const GET_PROJECT_REVENUE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectrevenue`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No revenue found for selected project!')
                dispatch({ type: ACTION_GET_PROJECT_REVENUE, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const INSERT_PROJECT_USERPAYROLL = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/insert_userpayroll`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const userpayid = item?.userpayid
                if (item?.Message === 'Success') {
                    AlertSuccess('userpayroll saved successfully!')
                }
                return { success: true, userpayid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const GET_PROJECT_USER_PAYROLL = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_userpayroll`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No user found for selected payroll!')
                dispatch({ type: ACTION_GET_PROJECT_USER_PAYROLL, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_USER_LEAVE_DATA = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/get_userleavedata`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No user found for selected leave!')
                dispatch({ type: ACTION_GET_USER_LEAVE_DATA, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const INSERT_USERLEAVES = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/insert_userleavedata`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let item = res?.data?.data
                const userpayid = item?.userpayid
                if (item?.Message === 'Success') {
                    AlertSuccess('userleaves saved successfully!')
                }
                return { success: true, userpayid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const IMPORT_PROJECT_EXPENSES = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/import_projectexpense`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
                return true
            }
        } catch (error) {
            // console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const IMPORT_PROJECT_REVENUE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/import_projectrevenue`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
                return true
            }
        } catch (error) {
            // console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const IMPORT_PAYROLL_USER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/import_userpayroll`
            const res = await postMethodApiCall(
                url,
                getHeadersWithoutAuth(),
                JSON.stringify(data),
            )
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
                return true
            }
        } catch (error) {
            // console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
export const IMPORT_LEAVE_USER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/Import_userleavedata`
            const res = await postMethodApiCall(
                url,
                getHeadersWithoutAuth(),
                JSON.stringify(data),
            )
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved Successfully !')
                return true
            }
        } catch (error) {
            // console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const PROCESS_PROFITABLE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/process_projectprfitablity`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROJECT_PROFITABLE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectprofitability_list`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items?.length > 0) {
                    dispatch({ type: ACTION_GET_PROJECT_PROFITABLE, data: items[0] })
                } else {
                    dispatch({ type: ACTION_GET_PROJECT_PROFITABLE, data: {} })
                }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROJECT_EXPENSE_TEMPLATE = () => {
    return async () => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/Download_ProjectExpenseDataTemplate`
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
                responseType: 'arraybuffer',
                data: payload,
            }

            let res = await axios.request(config)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, res }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const GET_PROJECT_REVENUE_TEMPLATE = () => {
    return async () => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/Download_ProjectRevenueDataTemplate`
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
                responseType: 'arraybuffer',
                data: payload,
            }
            let res = await axios.request(config)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, res }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const GET_USER_PAYROLL_TEMPLATE = () => {
    return async () => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/Download_UserPayrollDataTemplate`
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
                responseType: 'arraybuffer',
                data: payload,
            }
            let res = await axios.request(config)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, res }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}
export const GET_USER_LEAVE_TEMPLATE = () => {
    return async () => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/Download_UserLeaveTemplate`
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
                responseType: 'arraybuffer',
                data: payload,
            }
            let res = await axios.request(config)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, res }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}
