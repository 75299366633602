import { memo, useEffect, useMemo } from "react";
import { AlertError, getTotalFromArrayOfObjects } from "src/Services";
import ActivityTable from './table'
import Owner from "./owner";
import { GET_SEARCH_ACTIVITY_TASK_BY_ID } from "src/Redux/actions/projects/planning";
import { useDispatch, useSelector } from "react-redux";

const ActivityForm = ({ hooks }) => {
    const { disabled, project, activeId,
        selectActivity, searchTerm, activity, setActivity, setSearchTerm } = hooks
    const planningState = useSelector((state => state?.ProjectPlanning))
    const { activitySearchedTasks, activitiesByProjectId } = planningState
    const dispatch = useDispatch()

    useEffect(() => {
        if (searchTerm.trim()) {
            let payload = {
                Tenantid: Number(localStorage.getItem('Tenantid') || 0),
                projectid: project?.projectid,
                activityid: activity?.activityid,
                versionno: null,
                taskname: searchTerm?.trim() || '',
            }
            const debounceTimer = setTimeout(async () => {
                let res = await dispatch(GET_SEARCH_ACTIVITY_TASK_BY_ID(payload))
                if (res?.length > 0) {
                    setActivity(res[0])
                } else {
                    AlertError('No search result found in this activity')
                }
            }, 1500)
            return () => {
                clearTimeout(debounceTimer)
            }
        } else {
            let currentOne = activitiesByProjectId.find(a => a.activityid === activity.activityid)
            if (currentOne) {
                setActivity(currentOne)
            } else if (activitiesByProjectId?.length > 0) {
                setActivity(activitiesByProjectId[0])
            }
        }
        // eslint-disable-next-line
    }, [searchTerm, activitiesByProjectId])

    const handleChangeActivity = (e) => {
        let { name, value } = e.target
        let item = { ...activity, [name]: value }
        setActivity(item)
    };

    const handleOwner = (owner) => {
        if (owner && activity?.activityid) {
            let item = { ...activity, activityownerid: (owner?.comuserid || 0) }
            setActivity(item)
        }
    }

    const projectEffortHours = useMemo(() => {
        let sum = 0
        for (let a of activitySearchedTasks) {
            sum = sum + getTotalFromArrayOfObjects(a?.taskdetails || [], 'estdeffort')
        }
        return sum
        // eslint-disable-next-line
    }, [activitySearchedTasks, activity])

    let activityiduId = activity?.activityid || activity?.uId

    return (
        <>
            <div className="px-2 w-full text-sm">
                <div className="w-full flex justify-start items-center space-x-2 py-2">
                    <div className=" w-56 ">
                        <label htmlFor="name" className="py-2 font-[500]">Phase Name  <span className="text-red-300">*</span></label>
                        <input type="text"
                            maxLength={30} disabled={!disabled} name="activityname" id={activeId} value={activity?.activityname || ''} onChange={handleChangeActivity}
                            className="bg-gray-50  outline-gray-100 mt-2 border text-gray-600 border-gray-300 rounded-md focus:ring-primary-600 focus:border-primary-600 w-full p-2"
                            placeholder="Phase Name" required="" />
                    </div>
                    <div className=" w-28 ">
                        <label htmlFor="estdEffort" className="py-2 font-[500]">Phase Effort</label>
                        <input disabled title="estdEffort" name="estdEffort"
                            value={`${getTotalFromArrayOfObjects(activity?.taskdetails || [], 'estdeffort') || 0} Hrs`}
                            className="outline-gray-100 mt-2 border text-gray-600 border-gray-300 rounded-md focus:ring-primary-600 focus:border-primary-600 w-full p-2"
                            placeholder="hours" required="" />
                    </div>
                    <div className=" w-28 ">
                        <label htmlFor="estdEffort" className="py-2 font-[500]">Project Effort</label>
                        <input disabled title="estdEffort" name="estdEffort"
                            value={`${projectEffortHours} Hrs`}
                            className="outline-gray-100 mt-2 border text-gray-600 border-gray-300 rounded-md focus:ring-primary-600 focus:border-primary-600 w-full p-2"
                            placeholder="hours" required="" />
                    </div>
                    <div className="w-56">
                        <Owner
                            disabled={!disabled}
                            setOwner={handleOwner}
                            ownerId={activity?.activityownerid} />
                    </div>
                    <div>
                        <label htmlFor="daterange" className="py-2 font-[500]">Phase Duration</label>
                        <div className="w-full flex text-gray-600 h-10 space-x-1 justify-start items-center border mt-2 p-2 rounded-md bg-white focus:outline-none hover:outline-none">
                            <span className={` w-24 text-center ${activity?.startdate ? 'border-r ' : ''}`}>
                                {activity?.startdate}
                            </span>
                            <span className=" w-24 text-center ">
                                {activity?.enddate}
                            </span>
                        </div>
                    </div>
                    <div className=" w-60 ">
                        <span htmlFor="taskname" className="font-[500]">Search For Task</span>
                        <div className="mt-2">
                            <div className="relative flex items-center justify-between w-full bg-white rounded-lg disabled:bg-white">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg
                                        className="w-6 h-6"
                                        fill="text-gray-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="48"
                                        viewBox="0 96 960 960"
                                        width="48"
                                    >
                                        <path d="M782 892 528 638q-29.605 27.077-69.051 41.038Q419.503 693 381 693q-91.812 0-155.406-63.337Q162 566.325 162 474.663 162 383 225.337 319.5 288.675 256 380.11 256t155.662 63.385Q600 382.769 600 474.288 600 515 585 554.5T544 622l254 253-16 17ZM381 671q83.083 0 140.042-56.5Q578 558 578 474.5t-56.958-140Q464.083 278 381 278t-140.042 56.5Q184 391 184 474.5t56.958 140Q297.917 671 381 671Z" />
                                    </svg>
                                </div>
                                <input
                                    autoComplete="off"
                                    value={searchTerm}
                                    type="search"
                                    className={`p-2 bg-gray-100 outlined  disabled:bg-white border placeholder:text-gray-600  border-gray-300 text-textSecondary text-sm rounded outline-none focus:ring-0 block w-full pl-10`}
                                    placeholder='Search by task name'
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                activity ? <ActivityTable
                    disabled={disabled}
                    hooks={{
                        project,
                        activityiduId,
                        selectActivity,
                        activity,
                        setActivity
                    }}
                /> : <div className='flex items-center justify-center w-full h-1/2'>
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            }

        </>

    )
}

export default memo(ActivityForm);