
import { useSelector } from "react-redux"
import SelectCustomer from "./selectCustomer";
import ServiceLine from "./serviceLine";
import BillingType from "./billingType";
import ProjectType from "./projectType";
import SelectCompany from "./selectCompany";
import SelectStatus from "./selectStatus";
import PrimaryButton from "src/Components/Buttons/PrimaryButton";
// import { AlertError } from "src/Services";
import { memo,  useEffect,  useMemo } from "react";
import CustomhtmlSelect from "src/Components/CustomhtmlSelect";
import FlatPickrDateInput from "src/Components/FlatpickerDateInput";
import SelectTimeTemplate from "./selectTimeTemplate";
import SelectTimezone from "./selectTimezone";
import TimeInput from "./timeInput";

const categorylist = [
    { category: 'Internal', id: 'I' },
    { category: 'Customer', id: 'C' },
]
const sharedSupport = [
    { label: 'SAP', value: 'SAP' },
    { label: 'Salesforce', value: 'Salesforce' },
    { label: 'Success Factors', value: 'Success Factors' },
]

const timezone = [
    { zone: 'AST (UTC + 09:00)' },
    { zone: 'EST (UTC - 05:00)' },
    { zone: 'GMT (UTC + 0:00)' },
    { zone: 'IST (UTC + 05:30)' },
    { zone: 'PST (UTC - 08:00)' },

]

const Details = ({ hooks }) => {
    const projectsReducer = useSelector(state => state.Projects)
    const timetemplates = useSelector(state => state?.ConfigurationList?.templateList)
    const { setBusinessUnit, payload, setPayload, businessUnit, customer, setCustomer, serviceLine, setServiceLine, nextToDetail, errorFound, setErrorFound } = hooks
    const { servicelineList, customerList, typesListProject, listOfCompany, listOfBillingProjects, statusListByType } = projectsReducer

    const handleChangeValue = (name, value) => {
        if (errorFound) {
            setErrorFound(null)
        }
        setPayload({ ...payload, [name]: value })
    };

    const handleSelectTab = (name, value) => {
        setPayload({ ...payload, [name]: value })
    };

    const handleSelectCustomer = (select) => {
        if (errorFound) {
            setErrorFound(null)
        }
        setBusinessUnit({businessunitid: select?.businessunitid || null, businessunitname : select?.businessunitname || null})
        setPayload({ ...payload, businessunitid: select?.businessunitid || null })
        setCustomer(select)
    }

    useMemo(() => {
        let start = payload?.startdate
        let end = payload?.enddate
        if (start >= end && payload?.enddate) {
            setErrorFound({ keyname: 'startdate', msg: 'Start date must be smaller than End date' })
            // AlertError("Start must be smaller")
            return
        } else if (end <= start && payload?.startdate) {
            setErrorFound({ keyname: 'enddate', msg: 'End date must be greater ' })
            // AlertError("End date must be greater than start date")
            return
        }
        // eslint-disable-next-line
    }, [payload])

    const handleDates = (e) => {
        let { name, value } = e.target
        setErrorFound({ keyname: '', msg: '' })
        setPayload(prevPayload => ({
            ...prevPayload,
            [name]: value
        }));
    }
    
    useEffect(()=>{
        let defaultTimetemplate = timetemplates?.find(it=> it?.isdefault === true)?.timetemplatemasterid
        setPayload(prevPayload => ({
            ...prevPayload,
            timetemplatemasterid: defaultTimetemplate
        }))
    // eslint-disable-next-line
    },[timetemplates])

    return (
        <div className="w-full mt-4 text-sm">
            <div className=" h-[64vh] w-full pb-10 overflow-y-scroll">
                <div className="grid grid-cols-4 gap-6 p-2">
                    <div>
                        <label htmlFor="projectname" className="block font-[500] mb-2">Project Name <span className="text-red-300">*</span> </label>
                        <input
                            pattern="[A-Za-z0-9\s]*"
                            type='text'
                            maxLength={50}
                            value={payload?.projectname}
                            onChange={(e) => {
                                if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                                    handleChangeValue('projectname', e.target?.value)
                                }
                            }} name="projectname" id="projectname" className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            placeholder="Project name" required="" />
                        {errorFound?.keyname === 'projectname' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <SelectCompany listOfCompany={listOfCompany}
                            value={payload?.companyid}
                            handleChangeValue={handleChangeValue} />
                        {errorFound?.keyname === 'companyid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <ProjectType typesListProject={typesListProject} handleChangeValue={handleChangeValue} value={payload?.projecttypeid} />
                        {errorFound?.keyname === 'projecttypeid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Shared Support Type  </h1>
                        <CustomhtmlSelect
                            options={sharedSupport || []}
                            // noDataLabel='No Category'
                            value={payload?.projectsubtype}
                            // disabled={true}
                            name='projectsubtype'
                            labelkey='label'
                            className='border rounded bg-gray-50 py-2'
                            onChange={handleChangeValue}
                            id='value'
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Select Category <span className="text-red-300">*</span> </h1>
                        <CustomhtmlSelect
                            options={categorylist || []}
                            noDataLabel='No Category'
                            value={payload?.category}
                            name='category'
                            labelkey='category'
                            className='border rounded bg-gray-50 py-2'
                            onChange={handleChangeValue}
                            id='id'
                        />
                    </div>
                    <div className="relative">
                        <SelectCustomer
                            customer={customer}
                            handleSelectCustomer={handleSelectCustomer}
                            customerList={customerList} />
                        {errorFound?.keyname === 'customerid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <label htmlFor="account" className="block font-[500] mb-2">Account Name</label>
                        <input disabled type="text" value={customer?.accountname || ''} title="account" id="account" className="block w-full p-2 text-gray-600 border border-gray-300 rounded outline-gray-100 bg-gray-50 sm focus:ring-primary-600 focus:border-primary-600"
                            placeholder="Account" required="" />
                    </div>
                    <div>
                        <BillingType
                            handleSelectTab={handleSelectTab}
                            value={payload?.datprojectbillingtypeid}
                            listOfBillingProjects={listOfBillingProjects} />
                        {errorFound?.keyname === 'datprojectbillingtypeid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <label htmlFor="BusinessUnit" className="block font-[500] mb-2">Business Unit </label>
                        <div className="w-full p-2 text-gray-600 border border-gray-300 h-9 rounded outline-gray-100 bg-gray-50 sm focus:ring-primary-600 focus:border-primary-600">
                            {businessUnit?.businessunitname}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="ServiceLine" className="block font-[500] mb-2">Service Line {payload?.category === 'I' && <span className="text-red-300">*</span>}</label>
                        <ServiceLine
                            serviceLine={serviceLine}
                            setServiceLine={setServiceLine}
                            servicelineList={servicelineList}
                        />
                        {errorFound?.keyname === 'servicelineid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <SelectStatus statusListByType={statusListByType}
                            value={payload?.projectstatus}
                            handleChangeValue={handleChangeValue} />
                        {errorFound?.keyname === 'projectstatus' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <SelectTimeTemplate timetemplates={timetemplates}
                            value={payload?.timetemplatemasterid}
                            handleChangeValue={handleChangeValue} />
                        {errorFound?.keyname === 'timetemplatemasterid' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <h6 htmlFor='shiftstarttime' className=' font-[500] mb-2'>
                            Shift Start Time <span className="text-red-300">*</span>
                        </h6>
                        <TimeInput
                            name='shiftstarttime'
                            value={payload?.shiftstarttime || ''}
                            onChange={handleChangeValue}
                        />
                        {errorFound?.keyname === 'shiftstarttime' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <h6 htmlFor='shiftendtime' className=' font-[500] mb-2'>
                            Shift End Time <span className="text-red-300">*</span>
                        </h6>
                        <TimeInput
                            name='shiftendtime'
                            value={payload?.shiftendtime || ''}
                            onChange={handleChangeValue}
                        />
                        {errorFound?.keyname === 'shiftendtime' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <SelectTimezone timezones={timezone}
                            value={payload?.timezone}
                            handleChangeValue={handleChangeValue} />
                        {errorFound?.keyname === 'timezone' && <small className="text-red-400">Field is required !</small>}
                    </div>
                    <div>
                        <h6 htmlFor="ptojectcode" className="block mb-2 text-sm font-[500]">Project Code</h6>
                        <input maxLength={20}
                            disabled
                            value={payload?.projectcode || ''}
                            onChange={(e) => handleChangeValue('projectcode', e.target.value)} type="text" title="ptojectcode" id="ptojectcode" className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px]"
                            placeholder="Project Code" required="" />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-6 px-2">
                    <div className="grid grid-cols-2 gap-8 mt-2">
                        <div>
                            <label htmlFor="startdate" className="block font-[500] mb-2">Start Date <span className="text-red-300">*</span></label>
                            <FlatPickrDateInput
                                name='startdate'
                                className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px]'
                                id='startdate'
                                value={payload?.startdate || ''}
                                onChangeDate={handleDates}
                            />
                            {errorFound?.keyname === 'startdate' && <small className="text-red-400">{errorFound?.msg || 'Field is required !'}</small>}
                        </div>
                        <div>
                            <label htmlFor="enddate" className="block font-[500] mb-2">End Date <span className="text-red-300">*</span></label>
                            <FlatPickrDateInput
                                name='enddate'
                                className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[8px]'
                                id='enddate'
                                value={payload?.enddate || ''}
                                onChangeDate={handleDates}
                            />
                            {errorFound?.keyname === 'enddate' && <small className="text-red-400">{errorFound?.msg || 'Field is required !'}</small>}
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="projectdescription" className="block font-[500] mb-2"> Description</label>
                        <textarea type="text" rows={1}
                            maxLength={200}
                            value={payload?.projectdescription || ''}
                            onChange={(e) => { handleChangeValue('projectdescription', e.target?.value) }} id="projectdescription" className="outline-gray-100 text-gray-600 bg-gray-50 border border-gray-300 sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            placeholder="Description" required="" />
                    </div>
                </div>
            </div>
            <div className='flex justify-end'>
                <PrimaryButton onClick={nextToDetail} className='text-white py-2 rounded'>
                    <span>Next</span>
                </PrimaryButton>
            </div>
        </div>

    )
}

export default memo(Details);