
import ReactPaginate from "react-paginate";
import { PreIcon, NextIcon } from "src/Assets/icons";

const ReactPaginationStyle = ({ total, currentPage, handlePagination, limit }) => {
    const count = Number(Math.ceil(total / limit));
    return (
        <ReactPaginate
            previousLabel={<span className="border border-secondary text-secondary rounded-md w-6 h-6 flex justify-center items-center">{PreIcon}</span>}
            nextLabel={<span className="border border-secondary text-secondary rounded-md w-6 h-6 flex justify-center items-center">{NextIcon}</span>}
            pageCount={count || 1}
            breakLabel='..'
            pageRangeDisplayed={0}
            // previousClassName='w-6 h-6 bg-gray-100 border'
            activeLinkClassName='bg-secondary text-white w-6 h-6 rounded-md   flex justify-center items-center'
            pageClassName='flex justify-center text-secondary items-center rounded-md   border border-secondary  h-6 w-6'
            marginPagesDisplayed={1}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            onPageChange={(page) => handlePagination(page)}
            // pageClassName={'page-item'}
            // nextClassName={'page-item next'}
            // pageLinkClassName={'page-link'}
            containerClassName="pagination react-paginate rounded-md text-sm  gap-2 flex justify-end my-2 rounded-md"
        />
    )
}

export default ReactPaginationStyle;