import moment from "moment"
import { useMemo } from "react"

export const getTotalFromSameObject = (data, date) => {
    try {
        let total = 0;
        data?.forEach(entry => {
            entry.timesheetdetails.forEach(detail => {
                if (moment(new Date(detail.effortdate)).format('YYYY-MM-DD') === date) {
                    total += detail?.efforthours;
                }
            });
        });

        return total.toFixed(2);
    } catch (error) {
        return 0
    }

}

const Calculation = ({ data, weeks }) => {

    const dates = useMemo(() => {
        let items = []
        const startDate = moment(weeks?.monday); // replace with your own start date
        for (let i = 0; i < 7; i++) {
            const nextDate = startDate.clone().add(i, 'days');
            const formattedDate = nextDate.format('YYYY-MM-DD');
            items.push(formattedDate);
        }
        return items
    }, [weeks])

    const getTotalEffort = useMemo(() => {
        let total = 0;

        data?.forEach(entry => {
            if (entry?.timesheetdetails) {
                entry?.timesheetdetails?.forEach(detail => {
                    total += detail?.efforthours;
                });
            }
        });

        return total.toFixed(2);
        // eslint-disable-next-line 
    }, [weeks, data]);


    return (
        <>
            <tr className='text-left'>
                <td className='border px-2 py-1.5' />
                <td className='border px-2 py-1.5' />
                <td className='border px-2 py-1.5'>
                    <span className="font-[500] text-xs"> Total Effort (Hrs)</span>
                </td>
                {
                    dates?.map((date, i) => {
                        return (<td key={i} id={`cell-effort-${date}`} className='border text-xs pl-1.5 py-1.5 text-green-500 font-medium'>
                            {getTotalFromSameObject(data, date) || 0}
                        </td>
                        )
                    })
                }
                <td className='border pl-1.5 py-1.5 text-xs text-green-500 font-medium '>
                    {getTotalEffort} Hrs
                </td>
                <td className='border px-2 py-1.5'>
                </td>
            </tr>

        </>
    )
}

export default Calculation;