import { memo, useEffect, useRef, useState } from "react";
import Taskgroup from "./taskgroup";
import TaskgroupById from "./taskgroupById";
import { useDispatch, useSelector } from "react-redux";
import { GET_TASK_BY_GROUP_ID } from "src/Redux/actions/projects/planning";
import LazyLoad from "react-lazy-load";

const TaskWrapper = ({ hooks }) => {
    const dispatch = useDispatch();
    const planningState = useSelector((state => state?.ProjectPlanning))
    const taskType = useSelector((state => state?.ProjectTimesheet?.taskType))
    const { taskGroups, tasksByGroupId, fetchActivityPayload } = planningState
    const {
        // taskid, 
        taskgroupid, uId, handleChange, project } = hooks;
    const [tasksByGroupIdlist, setTasksByGroupId] = useState(tasksByGroupId || []);
    const isMounted = useRef(true); // Create a ref to track component mount status

    const getTasks = async (taskgroupid, taskid = 0) => {
        try {
            let taskTypeProjectId = taskType?.data?.find(item => item?.tasktype === "Project")?.tasktypeid || 1
            let res = null
            let tasktypeid = taskTypeProjectId || 0
            let logedIncomuserid = 0 // JSON.parse(localStorage.getItem("comuserid"))
            let selectedGroup = taskGroups?.find(it => it?.taskgroupid === taskgroupid)
            // only pass if loged in user id if it is PM 
            let payload = {
                Tenantid: Number(localStorage.getItem('Tenantid') || 0),
                taskgroupid,
                taskid,
                searchname: "",
                status: 'ACTIVE',
                sorttype: 1,
                // 1 is for project
                tasktypeid,
                // to get all user task
                createdcomuserid: logedIncomuserid
            }

            if (selectedGroup?.defaulttask && (project?.projectmanagerid === logedIncomuserid)) {
                payload['logedIncomuserid'] = logedIncomuserid
                res = await dispatch(GET_TASK_BY_GROUP_ID(payload, fetchActivityPayload));
            } else {
                res = await dispatch(GET_TASK_BY_GROUP_ID(payload, fetchActivityPayload));
            }
            if (isMounted.current) { // Check if the component is still mounted
                setTasksByGroupId(res);
            }
        } catch (error) {
            // Handle error if necessary
        }
    };
    useEffect(() => {
        isMounted.current = true; // Component is mounted
        return () => {
            isMounted.current = false; // Component is unmounted, clean up here if needed
        };
    }, []);

    const finalApiCall = (taskgroupid) => {
        if(taskgroupid !== undefined){
            getTasks(taskgroupid, 0);
        }
    }

    const handleGroupSelect = (e) => {
        handleChange(e)
        getTasks(e?.target?.value, 0);
    }

    return (
        <>
            <td className="border w-[200px] ">
                <LazyLoad
                    placeholder={<div className="animate-pulse">
                        <div className="bg-gray-200 h-4 w-full mb-4"></div>
                    </div>}
                    width="100%"
                    threshold={0.95} onContentVisible={() => finalApiCall(taskgroupid)}>
                    <Taskgroup hooks={{ ...hooks, value: taskgroupid, uId, handleGroupSelect, taskGroups }} />
                </LazyLoad>
            </td>
            <td className="border">
                <LazyLoad
                    placeholder={<div className="animate-pulse">
                        <div className="bg-gray-200 h-4 w-full mb-4"></div>
                    </div>}
                    width={"100%"}
                    threshold={0.95}>
                    <div className=' min-w-[140px] max-w-full'>
                        <TaskgroupById hooks={{ ...hooks, tasksByGroupIdlist, tasksByGroupId }} />
                    </div>
                </LazyLoad>
            </td>
        </>
    );
};

export default memo(TaskWrapper);
