// **  Initial State
const initialState = {
    logedInUser: JSON.parse(localStorage.getItem('menuinfo')),
    activeModuleMenu: JSON.parse(localStorage.getItem('activeModuleMenu'))
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_LOGIN_HANDLE':
            return {
                ...state,
                logedInUser: action.data?.sort((a, b) => a.modulealiasdisplayorder - b.modulealiasdisplayorder)
            }
        case 'ACTIVE_MODULE_MENU':
            return {
                ...state,
                activeModuleMenu: action.data
            }
        default:
            return state
    }
}

export default authReducer