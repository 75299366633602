
import moment from "moment";
import { useState } from "react";
import { PreIcon, NextIcon } from "src/Assets/icons";
import ApproveAndRejectButton from "./approve-Reject-btn";

const cols = ["Role", "Staff", "Request Type ", "Level", "Project", "Project Manager", "Country ", "Designation", "Band", "Actions"]
const cols2 = ["Role", "Staff", "Band range", "Billable", "Plan Duration", "Actual Duration", "Release Date", "Allocation", "Actions"]

const getDataAfterFilter = (data, filter) => {
    if (filter?.projectid && filter?.userid && filter?.staffingstatusid) {
        return data?.filter((it => it?.projectid === filter.projectid && it?.staffingstatusid === filter?.staffingstatusid && it?.userid === filter?.userid))
    } else if (filter?.projectid && filter?.userid) {
        return data?.filter((it => it?.projectid === filter.projectid && it?.userid === filter?.userid))
    } else if (filter?.userid && filter?.staffingstatusid) {
        return data?.filter((it => it?.userid === filter?.userid && it?.staffingstatusid === filter?.staffingstatusid))
    } else if (filter?.projectid && filter?.staffingstatusid) {
        return data?.filter((it => it?.projectid === filter?.projectid && it?.staffingstatusid === filter?.staffingstatusid))
    } else if (filter?.projectid) {
        return data?.filter((it => it?.projectid === filter?.projectid))
    } else if (filter?.staffingstatusid) {
        return data?.filter((it => it?.staffingstatusid === filter?.staffingstatusid))
    } else if (filter?.userid) {
        return data?.filter((it => it?.userid === filter?.userid))
    } else return data
}

const StaffingTable = ({ hooksValue }) => {
    const { staffingData, setstaffingData, setEdit, edit, filters } = hooksValue
    const [showMore, setShowMore] = useState(true)

    const handleStatus = (statusId, rowId, statusName, type) => {
        if (!edit) {
            setEdit(true)
        }
        let updateStatus = staffingData?.map((item) => {
            if (item?.uId === rowId) {
                item['actionType'] = type
                // rejecting staff then update below values with previous values 
                if ('Staffing Approval' === statusName && type === 'reject') {
                    item['StaffingStatusId'] = statusId
                } else if ((statusName === 'Release Approval' && type === 'approve') || (statusName === 'Extension Approval' && type === 'approve')) {
                    item['enddate'] = item?.releasedate
                    item['StaffingStatusId'] = statusId
                } else {
                    item['StaffingStatusId'] = statusId
                }
                item['releasedate'] = null
            }
            return item
        })
        setstaffingData(updateStatus)
    }

    return (
        <>
           <div className='flex justify-start items-center space-x-2 absolute top-1 right-20  '>
				<button disabled={showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary'>
					{PreIcon}
				</button>
				<button disabled={!showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary'>
					{NextIcon}
				</button>
			</div>
            <table className="w-full text-xs">
                <thead>
                    <tr className="text-left capitalize bg-gray-50">
                        {showMore ? cols?.map((col) => {
                            return (
                                <th key={col} title={col} scope="col" className="sticky top-0 capitalize bg-gray-50 font-[500] px-2 py-2 border text-start">{col}</th>
                            )
                        }) : cols2?.map((col) => {
                            return (
                                <th key={col} title={col} scope="col" className="sticky top-0 capitalize bg-gray-50 font-[500] px-2 py-2 border text-start">{col}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {/* {console.log(staffingData)} */}
                    {
                        getDataAfterFilter(staffingData, filters)?.map((item) => {
                            let { rolename, projectmanagername, statffingstatus, projectname, plannedallocation,
                                plannedstartdate, plannedenddate, StaffingStatusId, min_band, max_band,
                                uId, countryname, billable, startdate, enddate, releasedate,
                                allocation, employeecode, username, levelname, designation, bandname } = item

                            return (
                                <tr key={uId} className="text-left">
                                    <td className='border px-2'>
                                        <div className=" w-32 ">
                                            {rolename || 'N/A'}
                                        </div>
                                    </td>
                                    <td className='border px-2'>
                                        {username}  {employeecode && `(${employeecode})`}
                                    </td>
                                    {
                                        showMore ? <>
                                            <td className='border px-2'>
                                                <div className=" w-20 ">
                                                    {statffingstatus || 'N/A'}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className=" min-w-max ">
                                                    {levelname || 'N/A'}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className="  w-40 ">
                                                    {projectname || 'N/A'}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className=" min-w-[100px] ">
                                                    {projectmanagername || 'N/A'}
                                                </div>
                                            </td>
                                            {/* <td className='border px-2'>
                                                {username}  {employeecode && `${({ employeecode })}`}
                                            </td> */}
                                            <td className='border px-2'>
                                                <div className=" min-w-max ">
                                                    {countryname || 'N/A'}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className=" w-32 ">
                                                    {designation || 'N/A'}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className=" min-w-max ">
                                                    {bandname || 'N/A'}
                                                </div>
                                            </td>

                                        </> : <>
                                            <td className='border px-2'>
                                                <div className=" min-w-max ">
                                                    {min_band || ''} - {max_band || ''}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className=" min-w-max  ">
                                                    {billable ? 'Yes' : 'No'}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className=" min-w-max  ">
                                                    {moment(plannedstartdate).format('DD-MM-YYYY') || ''}  |  {moment(plannedenddate).format('DD-MM-YYYY') || ''}
                                                </div>
                                            </td>

                                            <td className='border px-2'>
                                                <div className=" min-w-max  ">
                                                    {moment(startdate).format('DD-MM-YYYY') || ''} |   {moment(enddate).format('DD-MM-YYYY') || ''}
                                                </div>
                                            </td>

                                            <td className='border px-2'>
                                                <div className=" min-w-max  ">
                                                    {releasedate ? moment(releasedate).format('DD-MM-YYYY') : ''}
                                                </div>
                                            </td>
                                            <td className='border px-2'>
                                                <div className="flex justify-start space-x-1 w-28">
                                                    <span>{allocation}% (P) </span>
                                                    <span className="space-x-1 ">|</span>
                                                    <span>{plannedallocation}% (A)</span>
                                                </div>
                                            </td>

                                        </>
                                    }

                                    <td className='border px-2'>
                                        <div className="flex justify-start items-center space-x-2">
                                            <ApproveAndRejectButton
                                                hooks={{
                                                    handleStatus,
                                                    StaffingStatusId,
                                                    statffingstatus,
                                                    item
                                                }} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default StaffingTable;