
export const createDemandDetailsInfo = () => {
	return {
		demandid: null,
		demandcode: '',
		workertypeid: null,
		companyid: null,
		requesttype: 'O',
		businessunitid: null,
		servicelineid: null,
		startdate: '',
		opportunityid: null,
		projectid: null,
		departmentid: null,
		billrate: null,
		datcomcurrencyid: null,
		billingunit: null,
		allocation: 0,
		workmodeid: null,
		priority: '',
		locationid: null,
		assignedto: null,
		assigneddate: null,
		demandstatusid: 31,
		cityid: null,
		is_billable: true,
		comtenantid: Number(localStorage.getItem('Tenantid') || 0),
		isactiveflag: true,
		createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
		modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
		notes: '',
		peopleno: null,
		isonsite: true,
		workforcegroupid: null
	}
}

export const detailNotRequired = ['demandid', 'opportunityid', 'projectid', 'departmentid', 'demandcode', 'locationid', 'assignedto', 'assigneddate', 'is_billable', 'billrate', 'datcomcurrencyid', 'billingunit', 'notes'];

