import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_EXIT_PIPELINE_REPORT,
} from 'src/Redux/actions/reports'

const cols1 = [
    'Employee name',
    'Gender',
    'Worker Type ',
    'Workforce Group',
    'Designation',
    'Band',
    'Department',
]
const cols2 = [
    'Employee name',
    'Primary Skill	',
    'Service line',
    'Project Name',
    'Exit Date',
    'Status',
    'Total Experience (Yr)',
    'MM Experience (Yr)',
]

const rows = [10, 25, 50]
const ReportList = ({
    filters,
    reportMasterList,
    reportId,
    page,
    setPage,
    limit,
    setLimit,
    exitPipelineReport
}) => {
    const dispatch = useDispatch();
    const [currentColsIndex, setCurrentColsIndex] = useState(0);
    const allCols = [cols1, cols2];

    const handleRightSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex + 1) % allCols.length);
    };

    const handleLeftSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex - 1 + allCols.length) % allCols.length);
    };

    const currentCols = allCols[currentColsIndex];

    const handlePagination = (page) => {
        dispatch(
            GET_EXIT_PIPELINE_REPORT({
                ...filters,
                limit: limit,
                page: page?.selected + 1,
            }),
        );
        setPage(page?.selected + 1);
    };

    const handleRows = (row) => {
        setLimit(row);
        if (row > 10) {
            setPage(1);
        }
        dispatch(
            GET_EXIT_PIPELINE_REPORT({
                ...filters,
                limit: row,
                page: row > 10 ? 1 : page,
            }),
        );
    };

    const report = reportMasterList?.find((report) => report?.reportid === reportId);
    const fileName = report ? `${report.reportname}` : '';

    const totalRecords = useMemo(() => {
        return exitPipelineReport?.length > 0
            ? exitPipelineReport[0]?.totalcount
            : exitPipelineReport?.length;
    }, [exitPipelineReport]);

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={currentColsIndex === 0}
                        onClick={handleLeftSlide}
                        className="disabled:text-gray-400 disabled:border-0 z-10 flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={currentColsIndex === allCols.length - 1}
                        onClick={handleRightSlide}
                        className="disabled:text-gray-400 disabled:border-0 z-10 flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>

                <div className="flex items-center justify-between w-full space-x-6">
                    <p className="text-primary font-[500]">
                        Reports Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        fileName={fileName}
                        limit={totalRecords}
                        endpoint="Download_exitpipelinereport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        filters={filters}
                    />
                </div>
            </div>
            {exitPipelineReport === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(90%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {currentCols?.map((col) => (
                                    <th
                                        key={col}
                                        title={col}
                                        scope="col"
                                        className="p-2 font-[500] border text-start"
                                    >
                                        {col}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {exitPipelineReport?.map((item, i) => {
                                const {
                                    employeename,
                                    employeecode,
                                    gender,
                                    workforcegroup,
                                    workertype,
                                    designation,
                                    bandname,
                                    department,
                                    primaryskill,
                                    servicelinename,
                                    projectname,
                                    exitdate,
                                    emp_statusname,
                                    totalexperience,
                                    motivemindexperience,
                                    uId,
                                } = item;

                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {currentColsIndex === 0 && (
                                            <>
                                                <td className="px-2 py-1 border w-[240px]">
                                                    {employeename}{' '}
                                                    {employeecode
                                                        ? `(${employeecode})`
                                                        : ''}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {gender}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {workertype}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {workforcegroup}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {designation}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {bandname}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {department}
                                                </td>
                                            </>
                                        )}
                                        {currentColsIndex === 1 && (
                                            <>
                                                <td className="px-2 py-1 border w-[240px]">
                                                    {employeename}{' '}
                                                    {employeecode
                                                        ? `(${employeecode})`
                                                        : ''}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {primaryskill}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {servicelinename}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {projectname}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {exitdate}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {emp_statusname}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {totalexperience}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {motivemindexperience}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ReportList;