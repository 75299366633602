import { Fragment, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { CategoryItem } from '../default';
import Details from './details';
import { SAVE_CATEGORY_MASTER, GET_TIME_CATEGORY_MASTER } from 'src/Redux/actions/configuration';
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCompanyPayload, getDefaultTimeCategoryPayload } from 'src/Services/constant/masterdatapayload';


const CreateCategory = () => {
  const [detailsData, setDetailsData] = useState(CategoryItem());
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);


  const loadFormApis = () => {
    dispatch(GET_COMPANY_MASTER(getMasterCompanyPayload()));
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    loadFormApis();
  }

  async function detailSave(payload) {
    let res = await dispatch(SAVE_CATEGORY_MASTER(payload));
    if (res?.success) {
      AlertSuccess('Time Type saved successfully.');
      await dispatch(GET_TIME_CATEGORY_MASTER(getDefaultTimeCategoryPayload()));
    }
  }

  return (
    <>
      <IconButton
			  id='Create-New-Time-Typebtn'
        title='Create New Time Type'
        onClick={openModal}
        className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </IconButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <div className='border-b py-2 border-primary flex justify-between items-center'>
                    <h3 className='text-lg font-[500] leading-6 text-primary'>
                      Create Time Category
                    </h3>
                    <IconButton
			                id='Create-Time-Category-btn'
                      title='Close'
                      onClick={closeModal}
                      type='button'
                      className='rounded-full border h-10 w-10 flex justify-center items-center'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-4 h-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </svg>
                      <span className='sr-only'>Close icon</span>
                    </IconButton>
                  </div>
                  <Details
                    setDetailsData={setDetailsData}
                    detailsData={detailsData}
                    detailSave={detailSave}
                    onModalClose={closeModal}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(CreateCategory);
