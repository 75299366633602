import React, { useRef, useEffect, memo } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';

const FlatPickrDateInput = ({ id, onChangeDate, min, max, name, value, disabled = false, className = 'bg-gray-50 uppercase border rounded py-1.5 focus:ring-0 focus:border-0', placeholder = 'YYYY/MM/DD' }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        let options = {
            onChange: selectedDates => {
                let now = selectedDates[0]
                let e = { target: { name, id, value: moment(now).format('YYYY/MM/DD') } }
                onChangeDate(e)
            },
        }

        options['minDate'] = moment(new Date(min)).format('YYYY/MM/DD') || null
        options['maxDate'] = moment(new Date(max)).format('YYYY/MM/DD') || null

        const instance = flatpickr(inputRef.current, options);

        return () => {
            instance.destroy(); // destroy the Flatpickr instance on component unmount
        };
        // eslint-disable-next-line
    }, [value, onChangeDate, name, id]);


    return (
        <div style={{ background: disabled ? '#fff' : '' }} className={`flex justify-start items-center space-x-2 ${className}`}>
            <button
                disabled={disabled}
                type="text"
                id={'flatpickerInputDate' + id || new Date()}
                ref={inputRef}
                className="flex justify-start  px-0 rounded-md items-centerbg-transparent focus:outline-none"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-primary">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>
                <span className=' w-28'>
                    {value ? moment(new Date(value)).format('YYYY/MM/DD') : placeholder}
                </span>
            </button>
        </div>
    );
};


export default memo(FlatPickrDateInput)