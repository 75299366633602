import SingleSelectField from "src/Components/SingleSelectForTable";
import { useSelector } from 'react-redux'

const Domin = ({ canEdit, value, rowId, handleChange }) => {
  const EmployeeProfile = useSelector(state => state.EmployeeProfile)
  const { industrylist } = EmployeeProfile
    const getSelected = (value) => {
        let e = { target: { name: 'domainid', value: value['industryid'] } }
        handleChange(e, rowId)
    }

    return (
        <div className="relative w-full">
            <SingleSelectField
                valueKey='industryid'
                labelKey='industryname'
                options={industrylist}
                value={value}
                handleSelected={getSelected}
                disabled={!canEdit}
            />
        </div>
    )
}

export default Domin;
