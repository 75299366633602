
const Indecator = () => {
    return (
        <div className="text-xs flex justify-end items-center space-x-2">
            <span className="h-2 w-2 rounded-full  bg-gray-500" />
            <span>Pending</span>
            <span className="h-2 w-2 rounded-full  bg-blue-500" />
            <span>Draft</span>
            <span className="h-2 w-2 rounded-full  bg-yellow-500" />
            <span>Submitted</span>
            <span className="h-2 w-2 rounded-full  bg-green-500" />
            <span>Approved</span>
            <span className="h-2 w-2 rounded-full bg-[#008080]" />
            <span>Reopened</span>
            <span className=" w-6 " />
            <span className="h-4 w-[3px] rounded-md  bg-orange-500" />
            <span>Billable</span>
            <span className="h-4 w-[3px] rounded-md  bg-green-500" />
            <span>Client Approval</span>
            <span className="h-4 w-[3px] rounded-md  bg-blue-500" />
            <span>Holiday</span>
            <span className="h-4 w-[3px] rounded-md  bg-gray-500" />
            <span>Leave</span>
        </div>
    )
}

export default Indecator;