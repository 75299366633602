import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { costtypeList } from 'src/Services/constant/staticValues';

const OverheadsDetails = (props) => {
  const { detailSave, detailsData, setDetailsData } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);
    let item = {
      ...detailsData,
    };

    let isValidated = CheckObjectValidation(item, ['overheadmasterid', 'notes']);
    if (isValidated?.isvalid) {
      let payload = {
        overheadjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='overhead' className='block font-[500] mb-2'>
            Overhead
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='overhead'
            id='overhead'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Overhead'
            required=''
            onChange={(e) => handleChangeInput('overhead', e.target.value)}
            maxLength={50}
          // value={customercode || ''}
          />
          {!detailsData?.overhead && checkvalidation && (
            <small className='text-red-400'>Overhead Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='costtype' className='block font-[500] mb-2'>
            Cost Type
          </label>
          <CustomhtmlSelect
            options={costtypeList || []}
            value={detailsData?.costtype}
            noDataLabel='No costtype found'
            name='costtype'
            labelkey='name'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ' onChange={handleChangeInput}
            id='costtype'
          />
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(OverheadsDetails);