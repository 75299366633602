import Tabs from './tabs'
import AnalyticsProjectFilter from 'src/Filter/analytics/analyticsProject'
import { useEffect, useState } from 'react'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import { ToastContainer } from 'react-toastify'

const AnalyticsLayout = ({ modulename, menu }) => {
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filter, setFilter] = useState({})

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(
            (item) => item?.permissionname,
        )
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="p-2 bg-white rounded-md shadow">
                <AnalyticsProjectFilter
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            <div className="shadow-lg mt-4 w-full rounded-lg bg-white h-[calc(100%-170px)] ">
                <Tabs
                    thisPagePermissions={thisPagePermissions}
                />
            </div>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default AnalyticsLayout
