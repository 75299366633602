import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import {
    GET_EDUCATIONBACKGROUND_LIST,
    GET_EDUCATION_MASTER,
    INSERT_EDUCATION,
} from 'src/Redux/actions/workforce/eprofile'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'
import { getTenantEducationData } from 'src/Services/constant/defaultPayload'
import { educationRowDefault } from 'src/Services/constant/staticValues'
import Table from './table'

const EducationBackground = (props) => {
    const {
        canEdit,
        setCanEdit,
        // training,
        // setTraining,
        // mockObject,
        // trainingCertificateSave,
    } = props
    const dispatch = useDispatch()
    const EmployeeProfile = useSelector((state) => state.EmployeeProfile)
    const { academicQualificationList, educationData } = EmployeeProfile
    const [formData, setFormData] = useState([])
    const [notValidated, setNotValidated] = useState(null)
    //set it call the api for course
    useEffect(() => {
        dispatch(GET_EDUCATIONBACKGROUND_LIST())
        dispatch(GET_EDUCATION_MASTER(getTenantEducationData()))
    }, [dispatch])

    useEffect(() => {
        setFormData(academicQualificationList)
    }, [academicQualificationList])

    const HandleSubmit = async () => {
        let NotReq = [
            'coursemaster',
            'specialisation',
            'educationmaster',
            'remarks',
            'comusereducationid',
            'percentageofmarks',
            'periodofeducation',
            'notes',
            'cousetype',
            'gradeorclass',
            'percentageofmarks',
        ]

        let isvalidaed = CheckListObjectValidation(formData, NotReq, false)
        if (isvalidaed?.isvalid) {
            let afterFilter = formData.map((item) => {
                for (let keyname in educationRowDefault()) {
                    if (item[keyname] === undefined) {
                        item[keyname] = educationRowDefault()[keyname]
                    }
                }

                return item
            })
            let res = await dispatch(INSERT_EDUCATION(afterFilter))
            if (res !== null) {
            }
            setCanEdit(false)
            await dispatch(GET_EDUCATIONBACKGROUND_LIST())
        } else {
            setNotValidated(isvalidaed)
        }
    }

    return (
        <div className="h-[44vh] text-sm my-2">
            <div className="overflow-hidden min-h-max">
                <Table
                    notValidated={notValidated}
                    canEdit={canEdit}
                    formData={formData}
                    setFormData={setFormData}
                    educationData={educationData}
                    // document={training}
                    // setDocument={setTraining}
                    // mockObject={mockObject}
                    // trainingCertificateSave={trainingCertificateSave}
                />
            </div>
            {canEdit ? (
                <div className="fixed mb-4 mr-4 bottom-2 right-6">
                    <div className="flex items-center justify-end gap-2">
                        <button
                            onClick={() => {
                                setCanEdit(false)
                                dispatch(GET_EDUCATIONBACKGROUND_LIST())
                            }}
                            className="px-2 py-1 border rounded border-primary text-primary h-9"
                        >
                            Cancel
                        </button>
                        <PrimaryButton
                            className="py-2 text-sm text-white rounded-md bg-primary"
                            onClick={() => {
                                HandleSubmit()
                                // trainingCertificateSave()
                            }}
                        >
                            Save
                        </PrimaryButton>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default EducationBackground
