

import SingleSelectField from "src/Components/SingleSelectForTable";

const Band = ({ options, disabled, value, rowId, handleChange }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'bandid', value: value['bandmasterid'] } }
        handleChange(e, rowId)
    }

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='bandmasterid'
                labelKey='bandname'
                options={options}
                value={value}
                handleSelected={getSelected}
                disabled={disabled} />
        </div>
    )
}

export default Band;