import moment from 'moment' // Assuming Moment.js is installed

export const createDemandDetailsInfo = (demandmatchid, userid) => {
    return {
        demandid: null,
        demandcode: '',
        workertypeid: null,
        companyid: null,
        requesttype: 'O',
        businessunitid: null,
        servicelineid: null,
        startdate: '2023-07-01',
        opportunityid: null,
        projectid: null,
        departmentid: null,
        billrate: null,
        datcomcurrencyid: null,
        billingunit: null,
        allocation: 0,
        workmodeid: null,
        priority: '',
        locationid: null,
        assignedto: null,
        assigneddate: null,
        demandstatusid: null,
        cityid: null,
        is_billable: true,
        comtenantid: Number(localStorage.getItem('comuserid') || 0),
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: '',
        userid,
        peopleno: null,
        isonsite: true,
        demandmatchid,
    }
}

export const getDemandUserList = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        username: '',
        status: 'Active',
        COMUserId: 0,
        allocationstatusid: 0,
        companyid: 0,
        usertypeid: 0,
        workforcegroup: 0,
        designationmasterid: 0,
        departmentid: 0,
        mintotalexperience: 0,
        maxtotalexperience: 0,
        skillmasterid: 0,
        technologymasterid: 0,
        locationid: 0,
        limit: 10,
        pageno: 1,
        sorttype: 1,
    }
}

export const insertDemandMatchItem = (demandid, userid, matchstatus, demandmatchid) => {
    return {
        demandmatchid,
        demandid,
        userid,
        matchscore: null,
        matchstatus,
        // statusstartdate: new Date(),
        // statusenddate: null,
        approvalstatus: null,
        approverid: null,
        approveddate: moment().format('YYYY-MM-DD'),
        comtenantid: Number(localStorage.getItem('Tenantid') || 0),
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: null,
    }
}
export const getDemandMatchList = (demandid) => {
    return {
        demandid,
        userid: 0,
        matchstatusid: 0,
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    }
}
export const getProjectStaffList = (projectid, projectstaffid, staffingstatusid) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        projectid,
        projectstaffid,
        staffingstatusid,
        limit: 25,
        pageno: 1,
        sorttype: 1,
    }
}

export const staffItemSkeleton = (
    demandid,
    projectstaffid,
    userid,
    projectid,
    startdate,
    StaffingStatusId,
    projroleid,
    allocation,
    enddate,
    plannedenddate,
    plannedstartdate,
    plannedallocation,
    releasedate,
) => {
    return [
        {
            plannedenddate,
            plannedstartdate,
            plannedallocation,
            releasedate,
            StaffingStatusId,
            projectstaffid,
            demandid,
            projroleid,
            userid,
            allocation,
            startdate,
            enddate,
            projectid,
            comtenantid: Number(localStorage.getItem('Tenantid') || 0),
            isactiveflag: true,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: '',
            localItem: true,
        },
    ]
}

export const detailNotRequired = [
    'demandid',
    'locationid',
    'assignedto',
    'assigneddate',
    'is_billable',
    'billrate',
    'datcomcurrencyid',
    'billingunit',
    'notes',
]
