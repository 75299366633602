import { Fragment, useEffect, useRef, } from 'react';
import IconButton from 'src/Components/Buttons/IconButton'
import TaskWrapper from './taskWrapper';
import SelectOption from 'src/Components/SelectOption';
// import ScrollToBottom from 'react-scroll-to-bottom';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';
import { AlertError } from 'src/Services';
import { getMinMaxDateFromListOfObjects } from 'src/Pages/app/Admin/masterData/Service';
import { GET_TIMESHEET_TASK_TYPE } from 'src/Redux/actions/projects/timesheet';
import { useDispatch } from 'react-redux';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

const cols = ["Task Group *", "Task *", "Ref No.", "Start Date *", "End Date *", "ESTD. Effort (Hrs)*", "Client Billable", "Client Approval"]

const onlyActiveData = (data) => {
    return data?.filter((task) => task?.isactiveflag === true || task?.isactiveflag === undefined)
}

const ActivityTable = ({ hooks, disabled }) => {
    const dispatch = useDispatch()
    const { activityiduId, activity, project, setActivity } = hooks
    const lastRowRef = useRef(null); // Ref to track the newly added row
    const containerRef = useRef(null); // Ref to track the scrollable container

    useEffect(() => {
        dispatch(GET_TIMESHEET_TASK_TYPE())
        // eslint-disable-next-line 
    }, [])

    const handleAdd = () => {
        let data = activity['taskdetails']
        data.push({
            taskid: 0,
            uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
            taskseq: 0,
            taskcode: 0,
            startdate: "",
            enddate: "",
            billtoclient: true,
            clientapproval: true,
            estdeffort: 0,
            isactiveflag: true,
            notes: ""
        })

        let item = { ...activity, taskdetails: data }
        setActivity(item)

    };

    const handleDelete = (selectedTask) => {
        let item = { ...activity }
        if (selectedTask?.projecttaskid !== undefined) {
            item['taskdetails']?.map((task => {
                if (task?.uId === selectedTask?.uId) {
                    task['isactiveflag'] = false
                    task['projectactivityid'] = activityiduId
                }
                return task
            }))
        } else {
            let data = item['taskdetails']?.filter((task) => task?.uId !== selectedTask?.uId)
            item['taskdetails'] = data
        }
        let { minStartDate, maxEndDate } = getMinMaxDateFromListOfObjects(item['taskdetails'])
        item['startdate'] = minStartDate;
        item['enddate'] = maxEndDate;
        setActivity(item)
    };

    const handleChange = (event) => {
        let { name, value, id } = event.target
        let item = { ...activity }
        let afterTaskChange = item['taskdetails']?.map((task) => {
            if (String(task?.uId) === String(id)) {
                if (name === 'taskgroupid') {
                    delete task['taskid']
                }
                if (name === 'enddate' && task?.startdate > value) {
                    AlertError('End date must be greater than start date')
                    return task
                } else if ((name === 'startdate' && task?.enddate) && value > task?.enddate) {
                    AlertError('Start date must be smaller than end date')
                    return task
                }

                task[name] = value
            }
            return task;
        });
        item['taskdetails'] = afterTaskChange
        if (name === 'startdate' || name === 'enddate') {
            let { minStartDate, maxEndDate } = getMinMaxDateFromListOfObjects(item['taskdetails'])
            item['startdate'] = minStartDate;
            item['enddate'] = maxEndDate;
        }
        setActivity(item)
    };

    const handletaskChange = (event) => {
        let { name, value, id } = event.target
        let item = { ...activity }
        if (String(item?.activityid === String(activity?.activityid))) {
            let afterTaskChange = item['taskdetails'].map((task) => {
                if (String(task?.uId) === String(id)) {
                    task[name] = value;
                    task['projecttaskid'] = task?.projecttaskid || null
                }
                return task;
            });
            item['taskdetails'] = afterTaskChange
        }
        setActivity(item)
    }

    useEffect(() => {
        // Scroll to the last row when a new row is added
        if (lastRowRef.current) {
            lastRowRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [activity?.taskdetails?.length]); // Check length change to trigger scroll

    return (
        <div ref={containerRef} className="w-full overflow-y-scroll h-[calc(70%-80px)] text-sm">
            <table className="min-w-full table-auto">
                <thead>
                    <tr className="text-left capitalize bg-gray-50">
                        {cols?.map((col) => {
                            return (
                                <th key={col} title={col} className="p-2 border font-[500] text-start">
                                    <div className='flex justify-start items-end'>
                                        {col.replace('*', '')} {col.includes("*") ? <span className='text-red-300'> *</span> : ''}
                                    </div>
                                </th>
                            )
                        })}
                        {disabled && <th className="p-2 border font-[500] text-start">Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {onlyActiveData(activity?.taskdetails)?.map((item, index) => {
                        const { taskid, taskgroupid, uId } = item
                        return (
                            <Fragment key={uId || taskid} >
                                <tr
                                    refs={`${index === onlyActiveData(activity?.taskdetails).length - 1 ? lastRowRef : null}`} // Assign ref to the last row
                                    className="text-left">
                                    <TaskWrapper
                                        hooks={{
                                            project,
                                            disabled,
                                            uId,
                                            taskid, taskgroupid, handleChange,
                                            handletaskChange
                                        }} />

                                    <td className="py-1 pl-2 border">
                                        <div className=' w-28'>
                                            <input type='text'
                                                maxLength={30}
                                                value={item?.tasknotes || ''}
                                                disabled={!disabled}
                                                name='tasknotes'
                                                id={uId}
                                                // placeholder={uId}
                                                onChange={handleChange} className=" placeholder:text-[10px] w-full uppercase focus:outline-none hover:outline-none" />
                                        </div>
                                    </td>
                                    <td className="py-1 pl-2 border">
                                        <FlatPickrDateInput
                                            disabled={!disabled}
                                            name='startdate'
                                            min={project?.startdate}
                                            max={project?.enddate}
                                            className=''
                                            id={uId}
                                            value={item?.startdate || ''}
                                            onChangeDate={(e) => handleChange(e)}
                                        />
                                    </td>
                                    <td className="py-1 pl-2 border">
                                        <FlatPickrDateInput
                                            disabled={!item?.startdate || !disabled}
                                            name='enddate'
                                            min={project?.startdate}
                                            max={project?.enddate}
                                            className=''
                                            id={uId}
                                            value={item?.enddate || ''}
                                            onChangeDate={(e) => handleChange(e)}
                                        />
                                    </td>
                                    <td className="px-2 py-1 border w-36">
                                        <input type='number' value={item?.estdeffort || ''} disabled={!disabled} name='estdeffort' id={uId} onChange={handleChange} className="w-[100px] focus:outline-none hover:outline-none" />
                                    </td>
                                    <td className="px-2 py-1 border w-28">
                                        <SelectOption
                                            disabled={!disabled}
                                            onChange={handleChange} id={uId}
                                            name='billtoclient'
                                            value={item?.billtoclient || false}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                        />

                                    </td>
                                    <td className="px-2 py-1 border w-36">
                                        <SelectOption
                                            disabled={!disabled}
                                            onChange={handleChange} id={uId}
                                            name='clientapproval'
                                            value={item?.clientapproval || false}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                        />

                                    </td>
                                    {disabled && <td className="px-2 py-1 border w-28">
                                        <div className='flex items-center justify-start'>
                                            {activity?.taskdetails?.length !== 1 && <IconButton title='Delete Task' onClick={() =>
                                                handleDelete(item)
                                            }
                                                className='bg-primary disabled:bg-primary/20 text-white'>
                                                <MinusIcon className="w-3" />
                                            </IconButton>}
                                            <div className='hidden addIconbutton-add-btn'>
                                                <IconButton title='Add Task' onClick={handleAdd}
                                                    // disabled={onlyActiveData(activity?.taskdetails)?.length === 8} 
                                                    className='bg-primary p-0 disabled:bg-primary/20 text-white'
                                                >
                                                    <PlusIcon className="w-3" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </td>}
                                </tr>
                            </Fragment>

                        )
                    })}
                </tbody>
            </table>
        </div>

    )
}

export default ActivityTable;

