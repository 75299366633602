import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_UTILIZATION_REPORT_FOR_EMPLOYEE,
} from 'src/Redux/actions/reports'

const cols1 = [
    'Employee Name',
    'Company',
    'Service line',
    'Designation',
    'DOJ',
    'Location',
    'Band',
    'Primary Skill',
]
const cols2 = [
    'Employee Name',
    'Current Utilization',
    'Group',
    'Month 1',
    'Month 2',
    'Month 3',
    'Month 4',
    'Month 5',
    'Month 6',
    'Month 7',
    'Month 8',
    'Month 9',
    'Month 10',
    'Month 11',
    'Month 12',
]

const rows = [10, 25, 50]

const EmployeeUtilizationReportsList = ({
    filters,
    utilizationreportforEmployee,
    user,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const [showMore, setShowMore] = useState(true)

    const handlePagination = (page) => {
        dispatch(
            GET_UTILIZATION_REPORT_FOR_EMPLOYEE({
                ...filters,
                limit,
                page: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_UTILIZATION_REPORT_FOR_EMPLOYEE({
                ...filters,
                limit: row,
                page: row > 10 ? 1 : page,
            }),
        )
    }

    let payload = {
        ...filters,
        comuserid: user?.comuserid || 0,
    }

    const totalRecords = useMemo(() => {
        return utilizationreportforEmployee?.Data?.length > 0
            ? utilizationreportforEmployee?.Data[0]?.totalcount
            : utilizationreportforEmployee?.Data?.length
    }, [utilizationreportforEmployee])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={!showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>
                <div className="flex items-center justify-between w-full space-x-5">
                    <p className="text-primary font-[500]">
                        Report Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        fileName="Utilization-report.csv"
                        limit={totalRecords}
                        endpoint="Download_employeeutilizationreport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        filters={payload}
                    />
                </div>
            </div>
            {utilizationreportforEmployee === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(90%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {showMore
                                    ? cols1.map((col) => {
                                          return (
                                              <th
                                                  key={col}
                                                  title={col}
                                                  scope="col"
                                                  className="p-2 font-[500] border text-start"
                                              >
                                                  {col}
                                              </th>
                                          )
                                      })
                                    : cols2.map((col) => {
                                          return (
                                              <th
                                                  key={col}
                                                  title={col}
                                                  scope="col"
                                                  className="p-2 font-[500] border text-start"
                                              >
                                                  {col}
                                              </th>
                                          )
                                      })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {utilizationreportforEmployee?.Data?.map((row, i) => {
                                return (
                                    <tr key={i} className="text-left cursor-pointer">
                                        {showMore ? (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {row?.employeename}{' '}
                                                    {row?.employeeid
                                                        ? `(${row?.employeeid})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.companyname}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.servicelinename}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.designation}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.dateofjoining}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.locationname}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.bandname}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.primaryskill}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="w-48 px-1 py-1 capitalize border ">
                                                    {row?.employeename}{' '}
                                                    {row?.employeeid
                                                        ? `(${row?.employeeid})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.curmonthutilization} %
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {row?.utilizationgroup}
                                                </td>
                                                {row?.utilizationdetail?.map(
                                                    (item, i) => {
                                                        return (
                                                            <td
                                                                key={i}
                                                                className="px-1 py-1 border w-28 "
                                                            >
                                                                <div className="w-full">
                                                                    {item?.monthname} |{' '}
                                                                    {
                                                                        item?.monthutilization
                                                                    }
                                                                </div>
                                                            </td>
                                                        )
                                                    },
                                                )}
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default EmployeeUtilizationReportsList
