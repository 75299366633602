import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IconButton from 'src/Components/Buttons/IconButton'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { GET_DEMAND_MASTER, SAVE_DEMAND_SKILL_MASTER } from 'src/Redux/actions/workforce/demands'
import {
    GET_INSUDTRY_MASTER,
    GET_SKILLAREA_MASTER,
    GET_SKILL_MASTER,
    GET_TECHNOLOGY_MASTER,
} from 'src/Redux/actions/workforce/eprofile'
import { AlertError } from 'src/Services'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'
import {
    getDeafultIndustryData,
    getDeafultSkillAreaData,
    getDeafultSkillData,
    getDeafultTechnologyData,
} from 'src/Services/constant/defaultPayload'
import { insertDemandSkillExpertise } from './defaultItems'
import Industry from './domain'
import Importance from './importance'
import SkillInput from './skill'
import SkillArea from './skillArea'
import Technology from './technology'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

const cols = ['Skill Area', 'Skill', 'Expertise', 'Technology', 'Domain', 'Importance']
const importancelist = [
    { name: 'Must have', importance: 1 },
    { name: 'Good to have', importance: 2 },
]

const SkillExperties = ({ hooks }) => {
    const EmployeeProfile = useSelector((state) => state.EmployeeProfile)
    const { skillData, setSkillData } = hooks
    const { technologyList, industrylist, skillList } = EmployeeProfile
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const editDemandId = searchParams.get('demandid')
    const getACtiveRecordOnly = (data) => {
        return data?.filter((it) => it?.isactiveflag === true)
    }
    useEffect(() => {
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
        dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()))
        dispatch(GET_TECHNOLOGY_MASTER(getDeafultTechnologyData()))
        dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()))
    }, [dispatch])

    const SelectHandleChange = (e, uId) => {
        let { name, value, objectValue } = e.target
        let afterChange = skillData?.map((item) => {
            if (item?.uId === uId) {
                if (name === 'skillareamasterid') {
                    // console.log('.')
                } else if (name === 'skillmasterid') {
                    item['skill'] = objectValue?.skill
                    item['skilltype'] = objectValue?.skilltype
                }
                item[name] = value
            }
            return item
        })
        setSkillData(afterChange)
    }

    const handleAdd = () => {
        if (skillData?.length < 10) {
            setSkillData([...skillData, insertDemandSkillExpertise(editDemandId)])
        }
    }
    const handleDelete = (uId) => {
        if (skillData?.length > 1) {
            let items = skillData?.map((item) => {
                if (item?.uId === uId) {
                    item['isactiveflag'] = false
                }
                return item
            })
            setSkillData(items)
        }
    }

    const handleSubmit = async () => {
        for (let item of skillData) {
            let { skilltype } = item
            if (!item?.expertise?.trim()) {
                AlertError(`Expertise is required for ${item?.skill || 'All Skill'}! `)
                return
            }
            if (skilltype === 'Technical') {
                if (!item?.technologymasterid) {
                    AlertError(`Technology is required for ${item['skill']}! `)
                    return
                }
            } else if (skilltype === 'Domain') {
                if (!item?.domainid) {
                    AlertError(`Domain is required for ${item['skill']}! `)
                    return
                }
            }
        }
        let insertKeysare = [
            'demandskillid',
            'demandid',
            'skillmasterid',
            'expertise',
            'technologymasterid',
            'domainid',
            'importance',
            'comtenantid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'createdtimestamp',
        ]
        let isValidated = CheckListObjectValidation(skillData, [
            'notes',
            'demandid',
            'demandskillid',
            'technologymasterid',
            'domainid',
            'expertise',
            'domain',
            'skill',
            'skillarea',
            'skilltype',
            'technology',
            'skillareamasterid',
            'createdtimestamp',
            'modifiedtimestamp',
            'modifiedcomuserid',
        ])
        if (isValidated?.isvalid) {
            let res = await dispatch(SAVE_DEMAND_SKILL_MASTER(skillData, insertKeysare, +editDemandId))
            if (res?.success) {
                dispatch(GET_DEMAND_MASTER(+editDemandId))
            }
        }
    }

    return (
        <div className="mt-4 w-full h-[70vh] text-sm">
            <div className="my-2 text-sm">
                <div className="w-full h-[60vh] overflow-scroll">
                    <table className="w-full text-left text-gray-500">
                        <thead className="capitalize text-textSecondary bg-gray-50">
                            <tr className="text-left capitalize bg-gray-50">
                                {cols?.map((item) => {
                                    const label = item.includes('*') ? item.replace('*', '') : item
                                    const isRequired = ['Skill', 'Expertise', 'Importance'].includes(label)
                                    return (
                                        <th key={item} className="bg-gray-100 border-b  font-[500] py-2 px-2">
                                            {label}
                                            {isRequired && <span className="text-red-300"> *</span>}
                                        </th>
                                    )
                                })}
                                <th className="bg-gray-100 border-b font-[500] py-2 px-2">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {getACtiveRecordOnly(skillData)?.map((item, i) => {
                                return (
                                    <Fragment key={`${item?.uId}-${i}`}>
                                        <tr key={item?.uId} className="bg-white border-b">
                                            <td className="px-2 py-2 border">
                                                <div>
                                                    <SkillArea
                                                        rowId={item?.uId}
                                                        value={item?.skillareamasterid || 0}
                                                        options={skillList}
                                                        handleChange={SelectHandleChange}
                                                    />
                                                </div>
                                            </td>
                                            <td className="px-2 py-2 border">
                                                <div className=" min-w-[80px] ">
                                                    <SkillInput
                                                        item={item}
                                                        skillareamasterid={item?.skillareamasterid || 0}
                                                        rowId={item?.uId}
                                                        value={item?.skillmasterid || ''}
                                                        handleChange={SelectHandleChange}
                                                    />
                                                </div>
                                            </td>
                                            <td className="px-1 py-1 border">
                                                <input
                                                    // canEdit={true}
                                                    onChange={(e) => SelectHandleChange(e, item?.uId)}
                                                    type="text"
                                                    value={item?.expertise || ''}
                                                    // rowId={item?.uId}
                                                    name="expertise"
                                                    placeholder="Expertise"
                                                    maxLength={50}
                                                    className={` placeholder:text-gray-400 placeholder:font-[200] px-0.5 w-[120px] ${true ? 'border-0 rounded-md' : 'border-0'
                                                        } py-0.5 w-full disabled:text-gray-800 disabled:bg-transparent focus:outline-none `}
                                                />
                                            </td>
                                            <td className="px-2 py-2 border">
                                                <Technology
                                                    value={item?.technologymasterid || ''}
                                                    rowId={item?.uId}
                                                    handleChange={SelectHandleChange}
                                                    options={technologyList}
                                                />
                                            </td>
                                            <td className="px-2 py-2 border">
                                                <Industry
                                                    value={item?.domainid || ''}
                                                    rowId={item?.uId}
                                                    handleChange={SelectHandleChange}
                                                    options={industrylist}
                                                />
                                            </td>
                                            <td className="px-2 py-2 border">
                                                <Importance
                                                    value={item?.importance || ''}
                                                    rowId={item?.uId}
                                                    handleChange={SelectHandleChange}
                                                    options={importancelist}
                                                />
                                            </td>
                                            {
                                                <td className="p-1 border">
                                                    <div className="flex justify-end space-x-1">
                                                        {getACtiveRecordOnly(skillData)?.length !== 1 && (
                                                            <IconButton
                                                                onClick={() => handleDelete(item?.uId)}
                                                                className="w-6 h-6 p-1 text-white bg-primary"
                                                                title="Delete skills"
                                                            >
                                                                <MinusIcon className="w-3" />
                                                            </IconButton>
                                                        )}
                                                        {getACtiveRecordOnly(skillData)?.length - 1 === i && (
                                                            <IconButton
                                                                disabled={skillData?.length >= 10}
                                                                onClick={handleAdd}
                                                                title="Add skills"
                                                                className="w-6 h-6 p-1 text-white bg-primary"
                                                            >
                                                                <PlusIcon className="w-3" />
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="fixed bottom-0 mt-2 right-6">
                    <div className="fixed bottom-2 right-5">
                        <PrimaryButton
                            className="px-8 py-2 mb-4 text-white rounded-lg bg-primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkillExperties
