import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SideMenu = ({ submenuIcons }) => {
    let verticalMenu = useSelector(state => state?.auth?.logedInUser)
    let activeModuleMenu = useSelector(state => state?.auth?.activeModuleMenu)
    const location = useLocation()
    const navigate = useNavigate();
    let [allAccessibleUrl, setAllAccessibleUrl] = useState(['/app/home', '/app/access-denied', '/app/index.html'])

    // console.log(allAccessibleUrl)
    useLayoutEffect(() => {
        let urlsList = ['/app/home', '/app/access-denied', '/app/index.html']
        if (verticalMenu?.length > 0) {
            // console.log(verticalMenu)
            for (let item of verticalMenu) {
                for (let child of item?.moduledetails) {
                    if (child?.menuname[0]?.RelativePage3.includes('detail')) {
                        let urlis = child?.menuname[0]?.RelativePage3?.split(':')[0]
                        urlsList.push(urlis)
                    } else {
                        urlsList.push(child?.menuname[0]?.RelativePage3)
                    }
                    // console.log(urlsList)
                }
            }
            setAllAccessibleUrl([...urlsList])
        }
    }, [setAllAccessibleUrl, verticalMenu])

    useLayoutEffect(() => {
        if (location?.pathname === '/') {
            navigate('/app/home')
        } else if (allAccessibleUrl?.length > 0 && !allAccessibleUrl.includes(location?.pathname)) {
            navigate('/app/access-denied')
        }
        // eslint-disable-next-line 
    }, [location?.pathname, allAccessibleUrl, navigate])


    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (activeModuleMenu?.moduledetails?.length > 0) {
                let item = activeModuleMenu?.moduledetails?.find(i => !i?.menuname[0]?.menuname3?.includes('Detail'))
                navigate(`${item?.menuname[0]?.RelativePage3}?datmenuid=${item?.menuname[0]?.datmenuid}`);
            }
        }, 600)
        return () => {
            clearTimeout(debounceTimer)
        }
        // eslint-disable-next-line
    }, [activeModuleMenu?.moduledetails])

    return (
        <nav className="w-full py-4 px-2.5">
            <ul className='w-full'>
                {activeModuleMenu?.moduledetails?.map(subitem => {
                    return (!subitem?.menuname[0]?.menuname3?.includes('Detail') ?
                        <li className='pl-1.5 py-1 w-full cursor-pointer' key={Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)}>
                            <Link to={{ pathname: subitem?.menuname[0]?.RelativePage3, search: `?datmenuid=${subitem?.menuname[0]?.datmenuid}` }} 
                            className={`w-full z-10 flex justify-start items-center  ${subitem?.menuname[0]?.RelativePage3 === location?.pathname ? 'text-secondary' : 'text-gray-500'}`}
                            >
                                <span className={`${subitem?.menuname[0]?.RelativePage3 === location?.pathname ? 'bg-secondary text-white border border-secondary' : 'text-gray-500 border border-gray-500 '} hover:-translate-3 hover:shadow-lg duration-500 hover:bg-secondary hover:text-white h-9 w-9  transition ease-in-out delay-0 flex justify-start items-center px-2 rounded-md text-base font-[500]  focus:outline-none`}>
                                    {submenuIcons[subitem?.menuname[0]?.menuname3]}
                                </span>
                                <span className='text-sm pl-5'>
                                    {subitem?.menuname[0]?.menuname3}
                                </span>
                            </Link>
                        </li> : ''
                    )
                })}
            </ul>
        </nav>
    )
}

export default SideMenu;