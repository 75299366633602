import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    // ReferenceLine,
} from 'recharts'

const data = [
    { serviceLine: 'Service A', Internal: 120, External: 80 },
    { serviceLine: 'Service B', Internal: 150, External: 90 },
    { serviceLine: 'Service E', Internal: 150, External: 90 },
    { serviceLine: 'Service C', Internal: 70, External: 110 },
    { serviceLine: 'Service F', Internal: 70, External: 110 },
    { serviceLine: 'Service D', Internal: 120, External: 80 },
    // Add more services as needed
]

const StackedBarChart = () => {
    return (
        <div className="sm:h-[50dvh] md:h-[50dvh]">
            <ResponsiveContainer  debounce={300} width="100%" height="100%">
                <BarChart
                    width={400}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="serviceLine"
                        angle={-15}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Internal" stackId="a" fill="#8884d8" />
                    <Bar dataKey="External" stackId="a" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default StackedBarChart
