import SingleSelectField from 'src/Components/SingleSelectForTable'

const SkillArea = ({ rowId, value, handleChange, options }) => {
	const getSelected = value => {
		let e = { target: { name: 'skillarea', value: value['skillareamasterid'] } }
		handleChange(e, rowId)
	}
	return (
		<div className='w-full'>
			<SingleSelectField
				valueKey='skillareamasterid'
				labelKey='skillarea'
				options={options}
				value={value || 0}
				handleSelected={getSelected}
			/>
		</div>
	)
}

export default SkillArea
