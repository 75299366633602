

import { useDispatch, useSelector } from "react-redux";
import IconButton from "src/Components/Buttons/IconButton";
import { useEffect } from "react";
import DocumentType from "./documentType";
import UploadDocument from "./uploadDocument";
import PrimaryButton from "src/Components/Buttons/PrimaryButton";
import { DELETE_CUSTOMER_DOCUMENT, GET_CUSTOMER_DOCUMENT_LIST } from "src/Redux/actions/masterdata/customer";
import { getCustomerDocument } from "src/Services/constant/Customerpayload";
import moment from "moment";

let cols = ['Document type *', 'File name', 'Version', 'Created by', 'Created at']

const Documents = (props) => {
    const { mockObject, documents, setDocuments, canEdit, setCanEdit, customerid, handleSubmit, } = props
    const CustomerManagementList = useSelector(state => state?.CustomerManagementList)
    let { customerDocumentTypeList, customerDocumentList } = CustomerManagementList
    const dispatch = useDispatch()

    useEffect(() => {
        if (!customerDocumentList?.length) {
            setDocuments([{ ...mockObject, customerid }])
        }
        // eslint-disable-next-line
    }, [customerDocumentList])

    const removeDoc = async (item) => {
        let id = item?.customerdocumentattachmentsid
        if (id) {
            let res = await dispatch(DELETE_CUSTOMER_DOCUMENT(id))
            if (res?.success) {
                dispatch(GET_CUSTOMER_DOCUMENT_LIST(getCustomerDocument(customerid)))
            }
        } else {
            let items = documents?.filter(doc => doc?.uId !== item?.uId)
            setDocuments(items)
        }
    }

    const addDoc = () => {
        setDocuments([...documents, { ...mockObject, customerid }])
    }

    const handleChange = (e, uId) => {
        let { name, value } = e.target;
        let afterChange = documents?.map((item) => {
            if (item?.uId === uId) {
                item[name] = value;
            }
            return item;
        });
        setDocuments(afterChange);
    };

    return (
        <div className='h-[60vh] w-full overflow-scroll'>
            <table className='w-full mt-2 relative text-left'>
                <thead className='capitalize bg-gray-50 h-[1vh]'>
                    <tr className='text-left capitalize bg-gray-50'>
                        {cols?.map((col) => {
                            return (
                                <th
                                    key={col}
                                    title={col}
                                    className='p-1 border font-[500]'>
                                    {col.replace('*', '')} {col.includes("*") ? <span className='text-red-300'> *</span> : ''}
                                </th>
                            );
                        })}
                        <th
                            className='p-1 border font-[500]'>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 mr-4'>
                    {customerDocumentList?.map((item) => {
                        // console.log(item)
                        return (
                            <tr key={item?.uId} className='text-left'>
                                <td className='border px-2 py-1'>
                                    {item?.documenttype}
                                </td>
                                <td className='border px-2 py-1'>
                                    {item?.filename}
                                </td>
                                <td className='border w-20 px-2 py-1'>
                                    {item?.versionno}
                                </td>
                                <td className='border px-2 py-1'>
                                    {item?.createduser}
                                </td>
                                <td className='border px-2 py-1'>
                                    {item?.createdtimestamp && moment(item?.createdtimestamp).format('DD/MM/YYYY')}
                                </td>
                                <td className='border w-20 px-2 py-1'>
                                    <div className="w-full flex justify-start items-center space-x-1">
                                        <UploadDocument
                                            item={item}
                                            setDocuments={setDocuments}
                                            documents={documents}
                                        />
                                        {!canEdit && <>
                                            <IconButton title="Remove Document" onClick={() => removeDoc(item)} className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 24 24'
                                                    fill='currentColor'
                                                    className='w-6 h-6'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>

                                            </IconButton>
                                            <span className="hidden addIconbutton-add-btn">
                                                <IconButton title="Add Document" onClick={addDoc} disabled={documents?.length === 5} className='disabled:bg-primary/10 w-6 h-6 p-1 text-white bg-primary'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        viewBox='0 0 24 24'
                                                        fill='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
                                                            clipRule='evenodd'
                                                        />
                                                    </svg>
                                                </IconButton>
                                            </span>
                                        </>}
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    }
                    {documents?.map((item) => {
                        return (
                            <tr key={item?.uId} className='text-left'>
                                <td className='border px-0 py-1'>
                                    <DocumentType disabled={canEdit}
                                        value={item?.documenttypeid || item?.documenttype}
                                        rowId={item?.uId}
                                        name='documenttype'
                                        handleChange={handleChange}
                                        options={customerDocumentTypeList} />
                                </td>
                                <td className='border truncate px-2 py-1'>
                                    {item?.filename}
                                </td>
                                <td className='border w-20 px-2 py-1'>
                                    <input name="versionno" type="number"
                                        id={item?.uId}
                                        value={item?.versionno || ''}
                                        disabled={canEdit}
                                        placeholder="version"
                                        className="focus:outline-none w-full h-full"
                                        onChange={(e) => handleChange(e, item?.uId)} />
                                </td>
                                <td className='border truncate px-2 py-1'>
                                    {item?.createduser}
                                </td>
                                <td className='border px-2 py-1'>
                                    {item?.createdtimestamp && moment(item?.createdtimestamp).format('DD/MM/YYYY')}
                                </td>
                                <td className='border w-20 px-2 py-1'>
                                    <div className="flex justify-start items-center space-x-1">
                                        <UploadDocument
                                            item={item}
                                            setDocuments={setDocuments}
                                            documents={documents}
                                        />
                                        {!canEdit && <>
                                            <IconButton title="Remove Document" disabled={documents?.length === 1 && !customerDocumentList?.length} onClick={() => removeDoc(item)} className=' disabled:bg-primary/10 w-6 h-6 p-1 text-white bg-primary'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 24 24'
                                                    fill='currentColor'
                                                    className='w-6 h-6'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
                                                        clipRule='evenodd'
                                                    />
                                                </svg>

                                            </IconButton>
                                            <span className="hidden addIconbutton-add-btn">
                                                <IconButton title="Add Document" onClick={addDoc} disabled={documents?.length === 5} className='disabled:bg-primary/10 w-6 h-6 p-1 text-white bg-primary'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        viewBox='0 0 24 24'
                                                        fill='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
                                                            clipRule='evenodd'
                                                        />
                                                    </svg>
                                                </IconButton>
                                            </span>
                                        </>}
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
            <div className='fixed bottom-0 mt-2 right-6'>
                {!canEdit && (
                    <div className='fixed bottom-0 mt-2 right-6'>
                        <div className='flex items-center justify-end gap-2'>
                            <button
                                // disabled={!canEdit}
                                onClick={() => setCanEdit(false)}
                                type=''
                                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                            >
                                Cancel
                            </button>
                            <PrimaryButton
                                disabled={canEdit}
                                className='px-8 py-2 mb-4 text-white rounded-md bg-primary'
                                onClick={handleSubmit}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Documents;