import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_FINANCE_REPORT,
} from 'src/Redux/actions/reports'

const cols1 = [
    'Project Name',
    'Status',
    'Type',
    'Bill Type',
    'Category',
    'Project Manager',
    'Delivery Manager',
    'Company',
    'Customer',
]
const cols2 = [
    'Project Name',
    'Account',
    'Month',
    'Method',
    'Planned Revenue',
    'Recognized Revenue',
    'Payroll Costs',
    'Expenses',
    'GM (P)',
    'GM (P+E)',
]

const rows = [10, 25, 50]
const ProjectFinancesReport = ({
    filters,
    reportMasterList,
    reportId,
    projectIds,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const { financereport } = useSelector((state) => state?.WorkforceReport)
    const [showMore, setShowMore] = useState(true)

    const handlePagination = (page) => {
        dispatch(
            GET_FINANCE_REPORT({
                ...filters,
                limit,
                page: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        setLimit(row)
        let data = filters
        if (filters['projectid'] === '{}') {
            data['projectid'] = `{${projectIds?.join(',')}}`
        }
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_FINANCE_REPORT({
                ...data,
                limit: row,
                page: row > 10 ? 1 : page,
            }),
        )
    }

    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    const totalRecords = useMemo(() => {
        return financereport?.length > 0
            ? financereport[0]?.totalcount
            : financereport?.length
    }, [financereport])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={!showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>
                <div className="flex items-center justify-between w-full space-x-5">
                    <p className="text-primary font-[500]">
                        Report Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        fileName={fileName}
                        limit={totalRecords}
                        endpoint="Download_projectprofitabilityreport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        filters={filters}
                    />
                </div>
            </div>
            {financereport === null || reportMasterList?.length === 0 ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(88%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {showMore
                                    ? cols1.map((col) => {
                                        return (
                                            <th
                                                key={col}
                                                title={col}
                                                scope="col"
                                                className=" min-w-[100px]  p-2 font-[500] border text-start"
                                            >
                                                {' '}
                                                {col}
                                            </th>
                                        )
                                    })
                                    : cols2.map((col) => {
                                        return (
                                            <th
                                                key={col}
                                                title={col}
                                                scope="col"
                                                className=" min-w-[100px] p-2 font-[500] border text-start"
                                            >
                                                {' '}
                                                {col}
                                            </th>
                                        )
                                    })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {financereport?.map((item, i) => {
                                let {
                                    projectcode,
                                    projectname,
                                    projectstatus,
                                    projecttype,
                                    billingtype,
                                    category,
                                    projectmanager,
                                    deliverymanager,
                                    company,
                                    customer,
                                    account,
                                    calculatedmonth,
                                    method,
                                    plannedrevenue,
                                    totalrecognizedrevenue,
                                    totaldirectcost,
                                    totalindirectcost,
                                    margin1,
                                    margin2,
                                    currency,
                                    uId,
                                } = item
                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {showMore ? (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {projectname}{' '}
                                                    {projectcode
                                                        ? `(${projectcode})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {projectstatus}{' '}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {projecttype}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {billingtype}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {category}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {projectmanager}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {deliverymanager}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {company}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {customer}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {projectname}{' '}
                                                    {projectcode
                                                        ? `(${projectcode})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    <span>{account}</span>
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {calculatedmonth}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {method === 'A'
                                                        ? 'Actual'
                                                        : 'Standard'}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {plannedrevenue} {currency}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {totalrecognizedrevenue} {currency}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {totaldirectcost} {currency}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {totalindirectcost} {currency}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {margin1}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {margin2}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ProjectFinancesReport
