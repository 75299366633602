import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts'

const data = [
    {
        name: '0-25',
        'Staff Count': 240,
    },
    {
        name: '25-50',
        'Staff Count': 139,
    },
    {
        name: '50-75',
        'Staff Count': 980,
    },
    {
        name: '75-100',
        'Staff Count': 390,
    },
    {
        name: '> 100',
        'Staff Count': 391,
    },
]

const BarchartStaffing = () => {
    return (
        <div className="h-[32vh] w-full flex justify-center items-center ">
            <ResponsiveContainer width="80%" height="80%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid opacity={1} vertical={false} strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize={10} />
                    <ReferenceLine y={0} stroke="gray" />
                    <YAxis axisLine={false} fontSize={10} />
                    <Tooltip />
                    <Legend />
                    <Bar
                        dataKey="Staff Count"
                        fill="#82ca9d"
                        background={{ fill: '#eee' }}
                    />{' '}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default BarchartStaffing
