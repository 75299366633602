const initialState = {
  ProjectRevenue: [],
  ProjectExpenses: [],
  ProjectOverhead: [],
  ProjectUserPayroll: [],
  getProjectProfitable: {},
  getUserLeave: [],
  leaveTypeList: [],
  getTimesheetBilling:[]
}

const ProjectFinancial = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECT_EXPENSES':
      return { ...state, ProjectExpenses: action?.data }
    case 'GET_PROJECT_REVENUE':
      return { ...state, ProjectRevenue: action?.data }
    case 'GET_PROJECT_USER_PAYROLL':
      return { ...state, ProjectUserPayroll: action?.data }
    case 'GET_OVERHEAD_MASTER':
      return { ...state, ProjectOverhead: action?.data }
    case 'GET_PROJECT_PROFITABLE':
      return { ...state, getProjectProfitable: action?.data }
    case 'GET_USER_LEAVE_DATA':
      return { ...state, getUserLeave: action?.data }
    case 'GET_LEAVETYPE_MASTER':
      return { ...state, leaveTypeList: action?.data }
    case 'GET_TIMESHEET_BILLING':
      return { ...state, getTimesheetBilling: action?.data }
    default:
      return state
  }
}


export default ProjectFinancial