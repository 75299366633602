import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import {memo, useState} from 'react'
import {useSelector} from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import {CheckObjectValidation} from 'src/Services/constant/customvalidation'
import {detailNotRequired} from '../default'

const Details = props => {
	const planningState = useSelector(state => state?.ProjectPlanning)
	const {taskGroups} = planningState
	const ProjectTimesheet = useSelector(state => state?.ProjectTimesheet)
	let {taskType} = ProjectTimesheet
	const {
		detailSave,
		detailsData,
		setDetailsData,
		canEdit,
		setCanEdit,
		closeModal,
	} = props
	const [checkvalidation, setcheckvalidation] = useState(false)

	const handleSubmit = () => {
		setcheckvalidation(true)
		let items = {
			taskid: detailsData?.taskid,
			taskgroupid: detailsData?.taskgroupid,
			taskdesc: detailsData?.taskdesc,
			tasktypeid: detailsData?.tasktypeid,
			comtenantid: Number(localStorage.getItem('Tenantid') || 0),
			isactiveflag: detailsData?.isactiveflag,
			createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
			notes: null,
		}
		let isValidated = CheckObjectValidation(items, detailNotRequired)
		if (isValidated?.isvalid) {
			let payload = {
				taskjson: [items],
			}
			detailSave(payload)
			closeModal()
		}
	}
	const handleChangeInput = (name, value) => {
		//  setcheckvalidation(false)
		setDetailsData({...detailsData, [name]: value})
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='taskcode' className='block font-[500] mb-2'>
						Task Code
					</label>
					<input
						disabled
						maxLength={50}
						type='text'
						title='taskcode'
						id='taskcode'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
						placeholder='Task Code'
						required=''
						value={detailsData?.taskcode || ''}
						onChange={e => {
							if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
								handleChangeInput('taskcode', e.target.value)
							}
						}}
					/>
				</div>
				<div>
					<label htmlFor='taskdesc' className='block font-[500] mb-2'>
						Task Name   <span className="text-red-300">*</span>
					</label>
					<input
						disabled={canEdit}
						maxLength={50}
						type='text'
						title='taskdesc'
						id='taskid'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
						placeholder='Task Name'
						required=''
						value={detailsData?.taskdesc || ''}
						onChange={e => {
							if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
								handleChangeInput('taskdesc', e.target.value)
							}
						}}
					/>
					{!detailsData?.taskdesc && checkvalidation && (
						<small className='text-red-400'>Field is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='tasktype' className='block font-[500] mb-2'>
						Task Type
					</label>
					<CustomhtmlSelect
						options={taskType || []}
						value={detailsData?.tasktypeid || ''}
						noDataLabel='No Task Type'
						name='tasktypeid'
						labelkey='tasktype'
						className='py-2 border rounded-lg bg-gray-50'
						onChange={handleChangeInput}
						id='tasktypeid'
						disabled={true}
					/>
				</div>

				<div>
					<label htmlFor='taskgroupdesc' className='block font-[500] mb-2'>
						Task Group
					</label>
					<CustomhtmlSelect
						options={taskGroups || []}
						value={detailsData?.taskgroupid || ''}
						noDataLabel='No Group'
						name='taskgroupid'
						labelkey='taskgroupdesc'
						className='py-2 border rounded-lg bg-gray-50'
						onChange={handleChangeInput}
						id='taskgroupid'
						disabled={true}
					/>
				</div>
			</div>
			<div className='fixed bottom-0 mt-2 right-6'>
				{!canEdit && (
					<div className='fixed bottom-0 mt-2 right-6'>
						<div className='flex items-center justify-end gap-2'>
							<button
								onClick={() => setCanEdit(false)}
								type=''
								className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
							>
								Cancel
							</button>
							<PrimaryButton
								disabled={canEdit}
								className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
								onClick={handleSubmit}
							>
								Save
							</PrimaryButton>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(Details)
