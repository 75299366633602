import { Dialog, Transition } from '@headlessui/react'
import { Fragment, memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from 'src/Components/Buttons/IconButton'
import SelectInput from 'src/Components/SelectInput'
import { GET_ACTIVITY_BY_PROJECT } from 'src/Redux/actions/projects/planning'
import SelectTaskToAssign from './selectTaskToAssign'

const Projectroletasks = ({ hooksValue, alltask }) => {
    const { roles, setRoles, uId, project, disabled } = hooksValue
    const [selectedTask, setSelectedTask] = useState({})
    const [selectedActivity, setSelectedActivity] = useState({})
    const dispatch = useDispatch()
    const planningState = useSelector((state => state?.ProjectPlanning))
    const { activitiesByProjectId } = planningState
    let [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setSelectedTask({})
    }, [selectedActivity])

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        if (project?.projectid) {
            dispatch(GET_ACTIVITY_BY_PROJECT(project?.projectid, 0))
        }
        setIsOpen(true)
    }

    const selectTask = (task) => {
        setSelectedTask(task)
        const newroles = roles?.map((item) => {
            if (item?.uId === uId) {
                let alreadyThere = alltask?.find(_task => _task?.projtaskid === task?.projecttaskid)
                if (!alreadyThere) {
                    let newTask = {
                        projtaskid: task?.projecttaskid,
                        isactiveflag: true,
                        notes: task?.tasknotes,
                        taskname: task?.taskname,
                        createdcomuserid: JSON.parse(localStorage.getItem('comuserid'))
                    }
                    item['projectroletasks'] = [...alltask, newTask]
                }

            }
            return item;
        });
        setRoles([...newroles]);
    }

    const removeTask = (id) => {
        const newroles = roles?.map((item) => {
            if (item?.uId === uId) {
                // console.log(alltask, id)
                let alreadyThere = alltask?.filter(_task => _task?.projtaskid !== id)
                item['projectroletasks'] = alreadyThere || []
            }
            return item;
        });
        setRoles(newroles);
    }

    return (
        <>
            { alltask?.length ? <button
                    disabled={!disabled}
                    onClick={openModal}
                    className='underline text-secondary text-left focus:ring-0 focus:ring-transparent px-2 focus:outline-none'>
                    {alltask?.length} Tasks
                </button> : <IconButton title='Assign Tasks' disabled={!disabled} onClick={openModal} className='bg-gray-100 px-4 border my-0 p-0.5 text-xs rounded'>  Select</IconButton>}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" w-[calc(90%)] 
  md:w-[calc(80%)] h-[80vh] py-8 transform overflow-scroll  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className='border-b py-2 border-primary flex justify-between items-center'>
                                        <h3 className="mb-4 text-lg font-[500] leading-6 text-primary">
                                            Select Tasks
                                        </h3>
                                        <button onClick={closeModal} type="button" className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                            <span className="sr-only">Close icon</span>
                                        </button>
                                    </div>
                                    <div className='w-full flex justify-start gap-2 py-2'>
                                        <div className='w-1/3'>
                                            <p className='text-gray-700 pb-1'>Select Phase</p>
                                            <SelectInput
                                                className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                                                options={activitiesByProjectId}
                                                keyname='activityname'
                                                mapkey='uId'
                                                selected={selectedActivity}
                                                setSelected={setSelectedActivity} />
                                        </div>
                                        <div className='w-3/4'>
                                            <p className='text-gray-700 pb-1'>Select Tasks</p>
                                            <SelectTaskToAssign
                                                Options={selectedActivity?.taskdetails || []}
                                                selected={selectedTask}
                                                setSelected={selectTask} />
                                        </div>
                                    </div>

                                    <p className='text-primary py-4 font-[500]'> List of Tasks </p>
                                    <div className='h-[40vh] overflow-scroll'>
                                        {
                                            alltask?.map((item, i) => {
                                                return (
                                                    <div className='mt-2 rounded-md shadow hover:shadow-md px-2 flex justify-between items-center ' key={(Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)) + item?.projtaskid}>
                                                        <p className='text-gray-600 font-[300]'>{i + 1}. {item?.taskname || item?.task} - {item?.notes}</p>
                                                         <IconButton
                                                            title='Remove task'
                                                            onClick={() => { removeTask(item?.projtaskid) }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                            </svg>
                                                        </IconButton>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default memo(Projectroletasks);

