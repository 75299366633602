import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ConfigurationData from './configuration'
import MasterData from './masterData'
import UserManagment from './usermanagment'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AdminLayout = () => {
    const navigate = useNavigate()
    const [accesstabs, setAccesstabs] = useState([])
    const [active, setActive] = useState('')

    useLayoutEffect(() => {
        let menuinfo = JSON.parse(localStorage.getItem('menuinfo'))
        let findAdmin = menuinfo?.find((item) => item?.modulealiasname === 'Admin')?.moduledetails
        let tabsToShow = []
        findAdmin?.map((item) => {
            if (item?.menuname?.length > 0) {
                tabsToShow.push({
                    tabname: item?.menuname[0]?.menuname3,
                    path: item?.menuname[0]?.RelativePage3,
                    component: components[item?.menuname[0]?.menuname3],
                })
            }
            return item
        })
        setAccesstabs(tabsToShow)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setActive(window.location.pathname)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    const components = {
        'Master Data': <MasterData modulename="Admin" menu="Master Data" />,
        'User Access': <UserManagment modulename="Admin" menu="User Access" />,
        Configuration: <ConfigurationData modulename="Admin" menu="Configuration" />,
    }

    return (
        <>
            <div className="bg-white rounded-md h-[90vh] overflow-hidden">
                <div className="flex justify-start w-full p-1 space-x-1 border-b border-secondary">
                    {accesstabs?.map((item, i) => {
                        return (
                            <button
                                onClick={() => navigate(item?.path)}
                                key={i}
                                className={classNames(
                                    'py-2 text-sm font-[500] outline-white',
                                    'ring-white ring-opacity-60 ring-offset-0 ring-offset-white focus:outline-none focus:ring-0',
                                    active.includes(item?.path)
                                        ? 'bg-secondary/10 text-secondary rounded-md font-[500]'
                                        : 'text-gray-500',
                                )}
                            >
                                <span className="px-4"> {item?.tabname}</span>
                            </button>
                        )
                    })}
                </div>
                {accesstabs?.find((it) => active.includes(it?.path))?.component}
            </div>
            <ToastContainer position="bottom-center" newestOnTop limit={1} />
        </>
    )
}

export default AdminLayout
