const initialState = {
  deliveryrolemaster: [],
  roleByCustomerId: []
};

const masterDataCustomer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DELIVERY_ROLE_MASTER_BY_ID':
      return { ...state, deliveryrolemaster: action.data?.Message ? [] : action.data, };
    case 'GET_ALL_ROLES_OF_A_CUSTOMER':
      return { ...state, roleByCustomerId: action.data?.Message ? [] : action.data, };
    default:
      return state;
  }
};

export default masterDataCustomer;
