import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import RevenueFinanceAnalyticsFilter from 'src/Filter/analytics/analyticsFinance'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import { RevenueItem } from 'src/Services/constant/defaultPayload'
import Tabs from './tabs'

const AnalyticsForFinance = ({ modulename, menu }) => {
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filters, setFilters] = useState(RevenueItem())
    const [subFilter, setSubFilter] = useState({typeid: 'YTD'})

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="p-2 py-2 bg-white rounded-md shadow">
                <RevenueFinanceAnalyticsFilter filters={filters} setFilters={setFilters} />
            </div>
            <div className="shadow-lg mt-4 w-full rounded-lg bg-white h-[calc(100%-170px)] ">
                <Tabs
                    setSubFilter={setSubFilter}
                    subFilter={subFilter}
                    thisPagePermissions={thisPagePermissions}
                    filters={filters} setFilters={setFilters}
                />
            </div>
            <ToastContainer position="bottom-center" newestOnTop limit={1} />
        </>
    )
}

export default AnalyticsForFinance
