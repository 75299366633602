import { memo } from 'react'
import IconButton from 'src/Components/Buttons/IconButton'
// import MilestoneTaskActivity from './taskAndActivity'
import { defaultItemOfMileStone } from './defautVar'
import SelectCurrency from './currency'
import SelectOption from 'src/Components/SelectOption'
import { AlertError } from 'src/Services'
import ScrollToBottom from 'react-scroll-to-bottom';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

const cols = ["name *", "description *", "Planned completion date *", "Actual Completion Date", "Billing Milestone", 'Amount *', "Currency", 'Confirmed'
    //  "Dependant Phase", "Dependant Task"
]

const onlyActiveData = (data) => {
    return data?.filter((task) => task?.isactiveflag === true || task?.isactiveflag === undefined)
}

const MilestoneTable = ({ hooksValues }) => {
    const currencyList = useSelector(state=> state?.Projects?.currencyList)
    const { milestone, setMilestone, project, disabled } = hooksValues

    const handleChange = (e) => {
        let { name, value, id, checked } = e.target
        let newValue = milestone?.map((item) => {
            if (item?.uId === id) {
                if (name === 'billingmilestone') {
                    item[name] = JSON.parse(value)
                } else if (name === 'confirmed') {
                    item[name] = checked
                } else {
                    if (isNaN(value)) {
                        item[name] = value
                    } else {
                        item[name] = value
                    }
                }
            }
            return item;
        });
        setMilestone(newValue)
    }

    const handledates = (e) => {
        let { name, value, id } = e.target
        let pstartdate = moment(new Date(project?.startdate)).format('YYYY/MM/DD')
        let penddate = moment(new Date(project?.enddate)).format('YYYY/MM/DD')

        let newValue = milestone?.map((item) => {
            if (item?.uId === id) {
                if (name === 'plannedcompletiondate' && value > penddate) {
                    AlertError(`Planned Completion Date must be between the Project start date and end date `)
                    return item
                } else if (name === 'plannedcompletiondate' && value < pstartdate) {
                    AlertError(`Planned Completion Date must be between the Project start date and end date `)
                    return item
                } else if (name === 'actualcompletiondate' && value > penddate) {
                    AlertError(`Actual Completion Date must be between the Project start date and end date `)
                    return item
                } else if (name === 'actualcompletiondate' && value < pstartdate) {
                    AlertError(`Actual Completion Date must be between the Project start date and end date `)
                    return item
                }
                item[name] = value
            }
            return item;
        });
        setMilestone(newValue)
    };

    const addRow = () => {
        setMilestone([...milestone, defaultItemOfMileStone(project?.defaultcomcurrencyid)])
    }

    const handleDelete = (selectedMile) => {
        if (milestone?.length > 1) {
            let rows = []
            if (selectedMile?.projectmilestoneid !== undefined) {
                rows = milestone?.map((milestone => {
                    if (milestone?.uId === selectedMile?.uId) {
                        milestone['isactiveflag'] = false
                    }
                    return milestone
                }))
            } else {
                rows = milestone?.filter((item) => item?.uId !== selectedMile?.uId)
            }
            setMilestone(rows);
        }
    };


    return (
        <ScrollToBottom className="pl-2 h-[calc(76%)] w-full overflow-y-scroll text-sm">
            <table className="w-full relative overflow-y-scroll">
                <thead>
                    <tr className="text-left capitalize bg-gray-50">
                        {cols?.map((col) => {
                            return (
                                <th key={col} title={col} scope="col" className="p-2 font-[500] border text-start">{col.replace('*', '')} {col.includes("*") ? <span className='text-red-300'> *</span> : ''}</th>
                            )
                        })}
                        {disabled && <th className="p-2 border font-[500] text-start">Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {onlyActiveData(milestone)?.map((item, i) => {
                        const { uId, milestonename, milestonedesc, milestoneamount, datcomcurrencyid, plannedcompletiondate, actualcompletiondate,
                            confirmed } = item
                        return (
                            <tr key={uId} className="text-left">
                                <td className="border px-2"><input onChange={handleChange} disabled={!disabled} type='text' id={uId} name='milestonename' className="w-38 focus:outline-none hover:outline-none" value={milestonename || ''} /></td>
                                <td className="border px-2"><input onChange={handleChange} disabled={!disabled} type='text' id={uId} name='milestonedesc' className=" w-52 focus:outline-none hover:outline-none" value={milestonedesc || ''} /></td>
                                <td className="border px-2">
                                    <FlatPickrDateInput
                                        disabled={!disabled}
                                        name='plannedcompletiondate'
                                        max={project?.enddate}
                                        min={project?.startdate}
                                        className=''
                                        id={uId}
                                        value={plannedcompletiondate || ''}
                                        onChangeDate={(e) => handledates(e)}
                                    />
                                </td>
                                <td className="border px-2">
                                    <FlatPickrDateInput
                                        id={uId}
                                        placeholder={confirmed ? 'YYYY/MM/DD' : ''}
                                        disabled={!disabled || !confirmed}
                                        name='actualcompletiondate'
                                        max={project?.enddate}
                                        min={project?.startdate}
                                        className=''
                                        value={actualcompletiondate || ''}
                                        onChangeDate={(e) => handledates(e)}
                                    />
                                </td>
                                <td className="border px-2">
                                    <SelectOption
                                        disabled={!disabled}
                                        onChange={handleChange} id={uId}
                                        name='billingmilestone'
                                        value={item?.billingmilestone || false}
                                        options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                    />
                                </td>
                                <td className="border w-20 px-2">
                                    <input type='number' value={milestoneamount || ''} name='milestoneamount' disabled={!disabled} id={uId} onChange={handleChange} className="focus:outline-none w-full uppercase hover:outline-none" />
                                </td>
                                <td className="border px-2">
                                    <SelectCurrency
                                        disabled={true}
                                        uId={uId}
                                        value={datcomcurrencyid || 0}
                                        handleChange={handleChange}
                                        currencyList={currencyList} />
                                </td>
                                <td className="border px-2">
                                    {!disabled ? <input
                                        readOnly
                                        type='checkbox'
                                        name="confirmed"
                                        checked={confirmed || false}
                                        className='block w-full px-2 text-gray-600 outline-gray-100 focus:outline-none '
                                    /> : <input
                                        // disabled={!disabled}
                                        // readOnly
                                        type='checkbox'
                                        id={uId}
                                        name="confirmed"
                                        checked={confirmed || false}
                                        onChange={handleChange}
                                        className='block w-full px-2 text-gray-600 outline-gray-100 focus:outline-none '
                                    />}

                                </td>

                                {disabled && <td className="border px-2">
                                    <div className='flex justify-start items-center'>
                                        {milestone?.length !== 1 && <IconButton
                                            title='Delete Milestone'
                                            onClick={() => handleDelete(item)}
                                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                <MinusIcon className="w-3" />
                                        </IconButton>}
                                        <div className=' hidden addIconbutton-add-btn '>
                                            <IconButton onClick={addRow}
                                                title='Add Milestone'
                                                className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                    <PlusIcon className="w-3" />
                                            </IconButton>
                                        </div>

                                    </div>
                                </td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </ScrollToBottom>
    )
}

export default memo(MilestoneTable);