import { Dialog, Transition } from '@headlessui/react';
import { Fragment, memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Details from './details';
import { AlertError, AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { SAVE_ROLE_MASTER, GET_ROLES_MASTER, GET_MENU_MASTER, GET_ROLE_BY_ID, } from 'src/Redux/actions/configuration';
import { getMenuMasterPayload, getRolesMasterPayload } from 'src/Services/constant/masterdatapayload';
import RolePermission from './permission'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const EditRole = (props) => {
  const {
    isOpen = true,
    setIsOpen,
    roleid,
    setDetailsData,
    detailsData,
    page,
    limit,
    sortType,
    permissions
  } = props;

  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [checkvalidation, setCheckvalidation] = useState(false);
  const canEditRoles = permissions?.includes('Add/Edit Config');


  async function fetchData() {
    try {
      await Promise.all([
        dispatch(GET_MENU_MASTER(getMenuMasterPayload()))

      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);


  useEffect(() => {
    if (roleid) {
      dispatch(GET_ROLE_BY_ID({ Roleid: roleid }))
        .then((response) => {
          if (response.success) {
            const { rolemenus } = response;
            setSelectedPermissions(rolemenus?.filter((item) => item.modulename !== "Subscriptions"))
          }
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          AlertError("Error fetching role data:", error);
        });
    }
    // eslint-disable-next-line
  }, [roleid]);

  function closeModal() {
    setIsOpen(false);
    setCanEdit(false);
  }

  function openModal() {
    setIsOpen(true);
    setCanEdit(false);
  }

  async function detailSave(payload, roleid) {
    let res = await dispatch(SAVE_ROLE_MASTER(payload, roleid));
    if (res?.success) {
      AlertSuccess('Role updated successfully.');
      await dispatch(GET_ROLES_MASTER(getRolesMasterPayload()));
    }
  }

  const handleSubmit = () => {
    setCheckvalidation(true);
    delete detailsData['uId']
    delete detailsData['totalcount']
    delete detailsData['totalpages']
    delete detailsData['menucount']
    let isValidated = CheckObjectValidation(detailsData, ["code", 'roleid', 'menucount', 'permissioncount', '', 'issystemrole', 'usercount']);
    if (isValidated?.isvalid) {
      // console.log(selectedPermissions)
      let rolemenus = selectedPermissions?.map(p => {
        let item = { ...p }
        return {
          datmenuid: item?.datmenuid,
          isfullrights: item?.isfullrights || false,
          isselected: item?.rolemenupermissions?.length >0 ,
          rolemenupermissions: item?.rolemenupermissions?.filter(sub => sub?.isselected === true)?.map((role) => {
            return {
              datmenuid: role?.datmenuid,
              datmenupermissionid: role?.datmenupermissionid
            }
          })
        }

      })

      let payload = {
        rolemenupermissionsjson: [
          {
            ...detailsData,
            rolemenus: rolemenus //?.filter((p) => p?.rolemenupermissions?.length > 0 || p?.rolemenupermissions === undefined)
          }
        ]
      };
      // console.log(rolemenus)
      detailSave(payload);
    }
  };


  const handleDelete = async () => {
    const item = {
      roleid: detailsData?.roleid,
      rolename: detailsData?.rolename,
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      activeflag: !detailsData.activeflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
      issystemrole: detailsData?.issystemrole,
      code:detailsData?.code,
      rolemenus: []
    };
    const payload = {
      rolemenupermissionsjson: [item],
    };

    let res = await dispatch(SAVE_ROLE_MASTER(payload));
    if (res?.success) {
      if (detailsData.activeflag) {
        AlertSuccess('Role deleted successfully.');
      } else {
        AlertSuccess('Role reactivated successfully.');
      }
      await dispatch(
        GET_ROLES_MASTER({
          ...getRolesMasterPayload(),
          limit,
          page: page?.selected + 1,
          sorttype: sortType?.id,
        })
      );
    }
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    closeModal();
  }

  function handleDeleting() {
    if (detailsData?.activeflag) {
      setCanEdit(true);
    }
    setShowDeleteConfirmation(true);
  }

  function handleCancelDeleting() {
    setShowDeleteConfirmation(false);
    setCanEdit(false);
  }

  function handleCancel() {
    closeModal();
    setShowDeleteConfirmation(false);
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <div className='border-b py-2 border-primary flex justify-between items-center'>
                    <h3 className='text-lg font-[500] leading-6 text-primary'>
                      Edit Role
                    </h3>
                    <div className='flex items-center justify-end space-x-2'>
                      {/*  ❗️ need to update logic again if issystemrole === false and can edit is true then show edit button */}
                      {canEditRoles && detailsData?.issystemrole === false ? <Fragment>
                        {!canEdit && detailsData?.activeflag && (
                          <IconButton
			                      id='edit-role-btn'
                            title='Edit'
                            onClick={() => setCanEdit(true)}
                            type='button'
                            className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-4 h-4'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                              />
                            </svg>
                            <span className='sr-only'>Edit icon</span>
                          </IconButton>
                        )}
                        {canEditRoles && !canEdit && !detailsData?.issystemrole && (
                          <>
                            <IconButton
			                        id='delete-role-btn-Reactivate'
                              title={detailsData.activeflag ? 'Delete' : 'Reactivate'}
                              onClick={handleDeleting}
                              type='button'
                              className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.8}
                                stroke='currentColor'
                                className='w-4 h-4'
                              >
                                {detailsData.activeflag ? (
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                  />
                                ) : (
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth={3}
                                    d='M5 13l4 4L19 7'
                                  />
                                )}
                              </svg>
                              <span className='sr-only'>
                                {detailsData.activeflag ? 'Unlock' : 'Delete'}{' '}
                                icon
                              </span>
                            </IconButton>
                          </>
                        )}
                      </Fragment> : ''}
                      <IconButton
			                  id='close-role-edit-btn'
                        title='Close'
                        onClick={handleCancel}
                        type='button'
                        className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-4 h-4'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                        <span className='sr-only'>Close icon</span>
                      </IconButton>
                    </div>
                  </div>
                  <div className=' h-[calc(100%)] w-full'>
                    <Details
                      hooks={{
                        canEdit,
                        detailsData, setDetailsData,
                        checkvalidation, setCheckvalidation
                      }}
                    />
                    <RolePermission
                      hooks={{
                        canEdit,
                        handleSubmit, detailsData, selectedPermissions, setSelectedPermissions
                      }}
                    />
                  </div>
                  {showDeleteConfirmation && (
                    <div className='absolute bottom-0 left-0 w-full p-6 text-center bg-white border-t border-gray-300'>
                      <p className='mb-4'>
                        {detailsData.activeflag
                          ? 'Are you sure you want to delete this Role?'
                          : 'Are you sure you want to reactivate this Role?'}
                      </p>
                      <button
                        onClick={handleDelete}
                        className='px-8 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5 mr-4'
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleCancelDeleting}
                        className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                      >
                        No
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default memo(EditRole);
