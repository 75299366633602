import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import MultiSelectItem from 'src/Components/MultiSelect'
import { GET_STAFFING_DETAILS_REPORT } from 'src/Redux/actions/reports'
import { getStaffingReportsPayload } from 'src/Services/constant/defaultPayload'

const StaffingReportsFilter = ({
    filters,
    setFilters,
    project,
    setProject,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
}) => {
    const dispatch = useDispatch()
    const projectsReducer = useSelector((state) => state.Projects)
    const { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    const { reportMasterList } = useSelector((state) => state?.WorkforceReport)
    const { typesListProject, listOfBillingProjects, statusListByType } = projectsReducer
    const { allocationstatuslist } = useSelector((state) => state?.EmployeeDetails)
    const { serviceList } = useSelector((state) => state.UserManagementList)

    useEffect(() => {
        if (projectsOfM?.length > 0) {
            const projectIdsString = `{${project.map((p) => p.projectid).join(',')}}`
            setFilters((prevFilters) => ({
                ...prevFilters,
                projectid: projectIdsString,
            }))
        }
    }, [project, setFilters, projectsOfM?.length])

    const handleSelectTab = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }
    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleDates = (e) => {
        let { name, value } = e.target
        setFilters((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }))
    }

    const clearFilters = () => {
        setProject([])
        let payload = {
            ...getStaffingReportsPayload(),
            userid: 0,
        }
        dispatch(GET_STAFFING_DETAILS_REPORT(payload))
        setFilters(payload)
    }

    const handleProjectSelect = (item) => {
        const isNew = project?.some((p) => p?.projectid === item?.projectid)
        if (isNew) {
            const updatedAllocationStatus = project?.filter(
                (p) => p?.projectid !== item?.projectid,
            )
            setProject(updatedAllocationStatus)
        } else {
            setProject((prevList) => [
                ...prevList,
                { projectid: item?.projectid, projectname: item?.projectname },
            ])
        }
    }

    const applyFilters = () => {
        dispatch(GET_STAFFING_DETAILS_REPORT(filters))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Projects</h1>
                    {projectsOfM !== null && (
                        <MultiSelectItem
                            className="py-2 border rounded bg-gray-50"
                            itemIdKey="projectid"
                            labelKeyname="projectname"
                            limitShow={1}
                            selectedOptions={project}
                            options={projectsOfM}
                            handleSelection={handleProjectSelect}
                        />
                    )}
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Service Line</h1>
                    <CustomhtmlSelect
                        options={serviceList || []}
                        value={filters?.servicelineid}
                        noDataLabel="No Serviceline"
                        name="servicelineid"
                        labelkey="servicelinename"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="servicelineid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Type</h1>
                    <CustomhtmlSelect
                        options={typesListProject || []}
                        noDataLabel="No Type"
                        value={filters?.projecttypeid}
                        name="projecttypeid"
                        labelkey="projecttype"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="projecttypeid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Select Project Status</h1>
                    <CustomhtmlSelect
                        options={statusListByType || []}
                        value={filters?.projectstatusid}
                        noDataLabel="No Projects"
                        name="projectstatusid"
                        labelkey="statusname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="statusid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Billing Type</h1>
                    <FilterButtonTabObject
                        className="py-2 border"
                        tabs={listOfBillingProjects || []}
                        selected={filters?.billingtypeid}
                        labelkey="billingtype"
                        name="billingtypeid"
                        valuekey="datprojectbillingtypeid"
                        onClick={handleSelectTab}
                    />
                </div>
                <div>
                    <h1 htmlFor="startdate" className=" font-[500] mb-2">
                        Start Date
                    </h1>
                    <FlatPickrDateInput
                        className="px-2 py-2 border rounded bg-gray-50"
                        name="startdate"
                        value={
                            filters?.startdate ||
                            moment(new Date()).subtract(12, 'months').format('YYYY/MM/DD')
                        }
                        onChangeDate={handleDates}
                    />
                </div>
                <div>
                    <h1 htmlFor="enddate" className=" font-[500] mb-2">
                        End Date
                    </h1>
                    <FlatPickrDateInput
                        className="px-2 py-2 border rounded bg-gray-50"
                        name="enddate"
                        value={
                            filters?.enddate || moment(new Date()).format('YYYY/MM/DD')
                        }
                        onChangeDate={handleDates}
                        min={filters?.startdate}
                        disabled={!filters?.startdate}
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]"> Select Staffing Status</h1>
                    <CustomhtmlSelect
                        options={allocationstatuslist || []}
                        value={filters?.staffingstatusid}
                        noDataLabel="No staffing Status"
                        name="staffingstatusid"
                        labelkey="statusname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="statusid"
                    />
                </div>
                <div />
                <div />
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaffingReportsFilter
