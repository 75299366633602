import WeekSelect from "./weekSelect"
import ManagerFilters from "./filters";
import ApproveAll from "./approveall";

const Toolbar = ({ updatehooks, updatehooks: { setWeeks, weeks } }) => {

    return (
        <div className="relative  my-2 w-full flex justify-between items-center space-x-4">
            <div className="flex justify-start items-center">
                <WeekSelect weeks={weeks} setWeeks={setWeeks} />
                <ManagerFilters
                    updatehooks={updatehooks}
                />
            </div>
            <div className="flex justify-start items-center space-x-2">
                <ApproveAll updatehooks={updatehooks} />
            </div>
        </div>
    )
}

export default Toolbar;