import { useMsal } from '@azure/msal-react';
import { PowerIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ProfileButton = () => {
	const { instance } = useMsal();
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const logout = () => {
		dispatch({ type: 'ACTIVE_MODULE_MENU', data: null })
		localStorage.clear()
		navigate('/app/login')
	}


	const handleLogoutMS = () => {
		instance.logoutPopup().catch(e => {
			console.error(e);
		});
		dispatch({ type: 'ACTIVE_MODULE_MENU', data: null })
		localStorage.clear()
		navigate('/app/login')
	};


	return (
		<div onClick={process.env.REACT_APP_LOGIN_TYPE === 'prosrvusernamepwd' ? logout : handleLogoutMS} role='buttom' className="relative flex flex-col cursor-pointer  group">

			<PowerIcon className='w-6 h-6 mr-3 font-semibold text-white ' />
			{/* <span className='flex items-center justify-center w-12 h-12 text-center text-white rounded-md focus:ring-0 focus:outline-none'>
					<svg xmlns="http://www.w3.org/2000/svg" fill='#fff' height="25" viewBox="0 -960 960 960" width="25"><path d="M226-98q-53.825 0-90.913-37.087Q98-172.175 98-226v-508q0-53.825 37.087-90.912Q172.175-862 226-862h262v128H226v508h262v128H226Zm406-151-92-89 78-78H374v-128h244l-78-78 92-89 230 231-230 231Z" /></svg>
				</span> */}
			<div className={`z-50 mt-1 min-h-[1.8rem]  py-1 px-4 right-2 shadow-xl min-w-max  absolute top-10 scale-0 rounded bg-white border p-2 text-xs text-secondary group-hover:scale-100`}>
				<svg className="absolute right-0 h-3 mr-3 text-white bottom-3" x="0px" y="0px" viewBox="0 0 255 255" space="preserve"><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
				<span>Logout</span>
			</div>
		</div>
	)
}

export default ProfileButton
