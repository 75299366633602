export const detailItem = () => {
  return {
    comtenantid: null,
    tenantname: "",
    tenantcode: "",
    themecode: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
    loginmethod: "",
    logopath: "",
    heroimagepath: "",
    tenantaddress: "",
    contactdetails: "",
    GSTnumber: "",
    CIN: "",
    comcityid: 0,
    emailid: "",
    pincode: "",
    companyname: "",
    password: "",
    token: "",
    mobileno: "",
    employeecode: "",
    url:"",
    comcurrencyid: 1,
    tenantcontactdetails: [
      {
        contactpersonname: "",
        designation: "",
        phoneno: "",
        email: "",
        isrenewalreminderneedtosend: true,
        isinvoiceneedtosend: true,
        isactiveflag: true,
        notes: ""
      },
    ]
  }
}

export const subscriptionItem = () => {
  return {
    tenantsubscriptionid: null,
    comtenantid: 0,
    datsubscriptionpackageid: 0,
    subscriptionfromdate: "",
    subscriptiontodate: "",
    graceperiodindays: 0,
    istrialaccount: true,
    activeflag: true,
    isadvancesubscriptionrenew: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    billingcycle: 0,
    tenantsubscriptionpricing: [
      {
        comtenantid: 0,
        minusers: 0,
        maxusers: 0,
        unitprice: 0,
        datcomcurrencyid: 0,
        isactiveflag: true,
        createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
        modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
        notes: ""
      }
    ]
  }

}