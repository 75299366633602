import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = (props) => {
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let items = {
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      businessunitgroupid: detailsData?.businessunitgroupid,
      groupcontactpersonid: detailsData?.groupcontactpersonid,
      businessunitgroupname: detailsData?.businessunitgroupname,
      groupheadid: detailsData?.groupheadid,
      isactiveflag: detailsData?.isactiveflag,
    };
    let isValidated = CheckObjectValidation(detailsData, ['groupheadid', 'groupcontactpersonid', 'modifiedcomuserid', 'notes'
    ]);
    if (isValidated?.isvalid) {
      let payload = {
        bugroupjson: [items],
      };
      detailSave(payload);
    };
  }
  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='businessunitname' className='block font-[500] mb-2'>
            Business Unit Group Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            maxLength={50}
            disabled={canEdit}
            type='text'
            title='businessunitgroupname'
            id='businessunitgroupname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Business Unit Group Name'
            required=''
            value={detailsData?.businessunitgroupname || ''}
            onChange={(e) =>
              handleChangeInput('businessunitgroupname', e.target.value)
            }
          />
          {!detailsData?.businessunitgroupname && checkvalidation && (
            <small className='text-red-400'> Business Unit Group Name is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Details);
