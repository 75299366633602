import { memo, useEffect, useState } from "react";
import Actionheader from "./ActionHeader";
import ActiveTab from "./activeTab";
import BaseProjectInfo from "./baseProjectInfo";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_BUDGET_ESTIMATION, GET_PROJECTS_BY_ID } from "src/Redux/actions/projects";
import { getRoleMasterIdData } from "src/Services/constant/defaultPayload";
import { GET_ROLE_MASTER } from "src/Redux/actions/masterdata/user";
import { getPermissionsOfCurrentUrl } from "src/Services";
import { ToastContainer } from "react-toastify";

const ProjectDetails = ({ modulename, menu }) => {
    const dispatch = useDispatch()
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filterTab, setFilterTab] = useState('Execution')
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const projectid = searchParams.get('projectid')
    const projectState = useSelector(state => state?.Projects)
    const { projectDetail, projectBudgetEstimation } = projectState

    useEffect(() => {
        dispatch(GET_ROLE_MASTER(getRoleMasterIdData()));
        dispatch(GET_PROJECTS_BY_ID(+projectid))
        dispatch(GET_BUDGET_ESTIMATION(+projectid))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(item => item?.permissionname);
        setThisPagePermissions(permissionNames)
        if (permissionNames?.length > 0) {
            if (!permissionNames.includes('View Project')) {
                navigate('/app/access-denied')
            }
        }
        // eslint-disable-next-line
    }, [menu])

    return (
        <>
            <Actionheader
                projectDetail={projectDetail}
            />
            <div className="relative h-[calc(100%-80px)] gap-4 w-full flex justify-start">
                <BaseProjectInfo
                    projectDetail={projectDetail}
                />
                <ActiveTab
                    thisPagePermissions={thisPagePermissions}
                    setFilterTab={setFilterTab}
                    projectDetail={projectDetail}
                    projectBudgetEstimation={projectBudgetEstimation}
                    filterTab={filterTab} />
            </div>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default memo(ProjectDetails);