
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from './defaultValue';


const Details = (props) => {
  const { detailSave, detailsData, setDetailsData } = props
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        bandjson: [detailsData]
      }
      detailSave(payload)
    };
  }

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value })
  }

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='bandname' className='block font-[500] mb-2'>
            Band
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='bandname'
            id='bandname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Band Name'
            required=''
            onChange={(e) => {
              handleChangeInput('bandname', e.target.value)
            }}
          />
          {!detailsData?.bandname && checkvalidation && (
            <small className='text-red-400'> Band Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='slno' className='block font-[500] mb-2'>
            Serial No
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='number'
            title='slno'
            id='slno'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Enter Serial Number'
            required=''
            onChange={(e) => {
              handleChangeInput('slno', e.target.value)
            }}
          />
          {!detailsData?.slno && checkvalidation && (
            <small className='text-red-400'> Serial No is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
