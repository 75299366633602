import { Fragment, memo } from 'react'
import { Listbox, Transition } from '@headlessui/react'

const getItem = (options, valueId, valueKey) => {
	return options?.find(el => el[valueKey] === valueId) || {}
}
const CustomSelect = ({
	id,
	// noDataLabel,
	options,
	value,
	name,
	defaultValue,
	labelkey,
	onChange,
	secondKey,
	className = 'p-1.5',
	disabled,
	displayLabel = 'Select',
	defaultWidthClass = 'w-full pl-3'
}) => {
	// Use defaultValue as initial state
	const handleSelect = (selectedOption )=>{
		onChange(name, selectedOption[id], selectedOption['incorporationdate'])
	}
	return (
		<Listbox
			disabled={disabled || options?.length === 0 || options === null}
			className="w-full"
			value={getItem(options, value, id)}
			onChange={handleSelect}
		>
			<div className="relative w-full">
				<Listbox.Button
					className={`${defaultWidthClass} flex justify-between items-center space-x-2 cursor-pointer disabled:bg-white text-left focus:outline-none focus-visible:border-primary ${className}  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2`}
				>
					<span className="block text-gray-900 capitalize truncate">
						{getItem(options, value, id)[labelkey] || displayLabel}
					</span>
					<span className="flex items-center pr-1 pointer-events-none">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-4 h-4 text-gray-500"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19.5 8.25l-7.5 7.5-7.5-7.5"
							/>
						</svg>
					</span>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="bg-red-400">
						<Listbox.Options
							style={{ zIndex: 2000 }}
							className="absolute w-full py-1 mt-1 overflow-auto text-sm bg-white border rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none"
						>
							{options?.map((option, optionIdx) => (
								<Listbox.Option
									onClick={() => {
										onChange(name, value === option[id] ? null : option[id])
									}}
									key={optionIdx}
									className={({ active }) =>
										`relative cursor-pointer select-none ${active
											? 'text-secondary'
											: 'text-secondary'
										}`
									}
									title={`${option[labelkey]} ${option[secondKey] || ''}`}
									value={option}
								>
									{({ selected }) => (
										<>
											<span
												className={`py-2 pl-3 pr-4 block truncate ${selected
													? 'font-[500] text-secondary hover:text-secondary'
													: 'font-normal text-gray-600 hover:text-secondary'
													}`}
											>
												{option[labelkey]} {option[secondKey]}
											</span>
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</div>
				</Transition>
			</div>
		</Listbox>
	)
}

export default memo(CustomSelect)
