import { Dialog, Transition } from '@headlessui/react';
import { Fragment, memo, useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import Details from './details';
import { AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { SAVE_CATEGORY_MASTER, GET_TIME_CATEGORY_MASTER } from 'src/Redux/actions/configuration';
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getDefaultCompanyPayload, getDefaultTimeCategoryPayload } from 'src/Services/constant/masterdatapayload';


const EditCategory = (props) => {
  const {
    isOpen = true,
    setIsOpen,
    timecategorymasterid,
    setDetailsData,
    detailsData,
    page,
    limit,
    sortType,
    permissions
  } = props;

  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const canEditTemplates = permissions?.includes('Add/Edit Config');

  async function fetchData() {
    try {
      await Promise.all([
        dispatch(GET_COMPANY_MASTER(getDefaultCompanyPayload()))

      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  async function closeModal() {
    setIsOpen(false);
    setCanEdit(false);
  }

  async function openModal() {
    setIsOpen(true);
    setCanEdit(false);
  }

  async function detailSave(payload) {
    let res = await dispatch(SAVE_CATEGORY_MASTER(payload, timecategorymasterid));
    if (res?.success) {
      AlertSuccess('Time Type updated successfully.');
      await dispatch(GET_TIME_CATEGORY_MASTER(getDefaultTimeCategoryPayload()));
    }
  }

  const handleDelete = async () => {
    const item = {
      timecategorymasterid: detailsData?.timecategorymasterid,
      timecategory: detailsData?.timecategory,
      companyid: detailsData?.companyid,
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      isactiveflag: !detailsData.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
      isdefault: detailsData?.isdefault,

    };
    const payload = {
      timecategorymasterjson: [item],
    };

    let res = await dispatch(SAVE_CATEGORY_MASTER(payload));
    if (res?.success) {
      if (detailsData.isactiveflag) {

        AlertSuccess('Time Type deleted successfully.');
      } else {
        AlertSuccess('Time Type reactivated successfully.');
      }
      await dispatch(
        GET_TIME_CATEGORY_MASTER({
          ...getDefaultTimeCategoryPayload(),
          limit,
          page: page?.selected + 1,
          sorttype: sortType?.id,
        })
      );
    }
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    closeModal();
  }

  function handleDeleting() {
    if (detailsData?.isactiveflag) {
      setCanEdit(true);
    }
    setShowDeleteConfirmation(true);
  }

  function handleCancelDeleting() {
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    //  closeModal();
  }

  function handleCancel() {
    closeModal();
    setShowDeleteConfirmation(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <div className='border-b py-2 border-primary flex justify-between items-center'>
                    <h3 className='text-lg font-[500] leading-6 text-primary'>
                      Edit Category
                    </h3>
                    <div className='flex justify-end items-center space-x-2'>
                      {canEditTemplates && !canEdit && detailsData.isactiveflag && (
                        <IconButton
                          title='Edit'
			                    id='Edit-Category-btn'
                          onClick={() => setCanEdit(true)}
                          type='button'
                          className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-4 h-4'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                            />
                          </svg>
                          <span className='sr-only'>Edit icon</span>
                        </IconButton>
                      )}
                      {canEditTemplates && !canEdit && (
                        <>
                          <IconButton
			                      id='Edit-Category-Reactivate-btn'
                            title={detailsData?.isactiveflag ? 'Delete' : 'Reactivate'}
                            onClick={handleDeleting}
                            type='button'
                            className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.8}
                              stroke='currentColor'
                              className='w-4 h-4'
                            >
                              {detailsData?.isactiveflag ? (
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                />
                              ) : (
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth={3}
                                  d='M5 13l4 4L19 7'
                                />
                              )}
                            </svg>
                            <span className='sr-only'>
                              {detailsData?.isactiveflag ? 'Unlock' : 'Delete'}{' '}
                              icon
                            </span>
                          </IconButton>
                        </>
                      )}
                      <IconButton
			                  id='Edit-Category-Reactivate-close-btn'
                        title='Close'
                        onClick={handleCancel}
                        type='button'
                        className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-4 h-4'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                        <span className='sr-only'>Close icon</span>
                      </IconButton>
                    </div>
                  </div>
                  <Tab.Group>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Details
                          detailSave={detailSave}
                          detailsData={detailsData}
                          timecategorymasterid={timecategorymasterid}
                          setDetailsData={setDetailsData}
                          canEdit={!canEdit}
                          setCanEdit={setCanEdit}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                  {showDeleteConfirmation && (
                    <div className='absolute bottom-0 left-0 w-full bg-white p-6 border-t border-gray-300 text-center'>
                      <p className='mb-4'>
                        {detailsData?.isactiveflag
                          ? 'Are you sure you want to delete this Time Type?'
                          : 'Are you sure you want to reactivate this Time Type?'}
                      </p>
                      <button
                        onClick={handleDelete}
                        className='px-8 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5 mr-4'
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleCancelDeleting}
                        className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                      >
                        No
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(EditCategory);
