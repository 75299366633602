import { Tab } from '@headlessui/react'
import NoPermissionComponent from 'src/Components/NoPermissionTabComponent'
import AllocationDetails from './allocationDetails'
import EducationBackground from './educationBackground'
import EmployeePersonalInfo from './employeePersonalInfo'
import Experience from './employmentHistory'
import FindOpportunity from './findOpportunity'
import LearningHistory from './learningHistory'
import ProjectHistory from './projectHistory'
import Resumes from './resumes'
import SkillPortfolio from './skillPortfolio'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const baseTabs = [
    { name: 'Projects', component: ProjectHistory },
    { name: 'Skills', component: SkillPortfolio },
    { name: 'Experience', component: Experience },
    { name: 'Certifications', component: LearningHistory },
    { name: 'Education', component: EducationBackground },
    { name: 'Resume', component: Resumes },
]

const DetailTabs = ({ candidateSummary = false, allocationData, setActiveTab, Employees, thisPagePermissions }) => {
    const tabs = [
        ...baseTabs,
        ...(allocationData?.Status !== 'Deployed' ? [{ name: 'Demands', component: FindOpportunity }] : []),
    ]

    return (
        <Tab.Group>
            <div className="flex items-center justify-between w-full border-b border-secondary">
                <Tab.List className="flex py-1 space-x-2 rounded-lg ">
                    {tabs.map((tab, i) => (
                        <Tab
                            key={i}
                            className={({ selected }) =>
                                classNames(
                                    'text-sm text-left w-full rounded-md py-2 font-[500] outline-white',
                                    'ring-white ring-opacity-60 ring-offset-0 ring-offset-white focus:outline-none focus:ring-0',
                                    selected
                                        ? 'bg-secondary/10 text-secondary font-[500] p-1.5 rounded-md'
                                        : 'text-gray-500',
                                )
                            }
                        >
                            <span className="px-4">{tab.name}</span>
                        </Tab>
                    ))}
                </Tab.List>
            </div>
            <Tab.Panels className="w-full p-2">
                {candidateSummary && (
                    <Tab.Panel>
                        <div className="grid grid-cols-2 gap-4 text-sm">
                            <div>
                                <h1 className="my-2 font-semibold">Employee Summary</h1>
                                <EmployeePersonalInfo allocationData={allocationData} />
                            </div>
                            <div>
                                <h1 className="my-2 font-semibold">
                                    Allocation Status:
                                    <span className="text-green-500">Deployed</span>
                                </h1>
                                <AllocationDetails />
                            </div>
                        </div>
                    </Tab.Panel>
                )}
                {tabs.map((tab, index) => (
                    <Tab.Panel key={index}>
                        {tab.name === 'Demands' ? (
                            thisPagePermissions?.includes('View Demands') ? (
                                <tab.component
                                    setActiveTab={setActiveTab}
                                    Employees={Employees}
                                    thisPagePermissions={thisPagePermissions}
                                />
                            ) : (
                                <NoPermissionComponent />
                            )
                        ) : (
                            <tab.component />
                        )}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    )
}

export default DetailTabs
