
import { useMemo, useState } from "react";
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { GET_CUSTOMER_LIST } from "src/Redux/actions/projects";
import { getDefaultCustomerPayload } from "src/Services/constant/defaultPayload";

const SelectCustomer = ({ customerList, handleSelectCustomer, customer, disabled }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useMemo(()=>{
        if(customer?.customername){
            setSearchTerm(customer?.customername || '')
        }
    },[customer])

    return (
        <>
            <label htmlFor="customer" className="block font-[500] mb-2">Select Customer <span className="text-red-300">*</span></label>
            <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_CUSTOMER_LIST}
                searchPayload={{
                    ...getDefaultCustomerPayload(),
                    customername: searchTerm
                }}
                disabled={disabled}
                className=' bg-gray-50 text-gray-800 border rounded-lg block w-full p-2'
                setSearchTerm={setSearchTerm}
                options={customerList}
                idkey='customerid'
                optionlabelKey='customername'
                getSelectedOption={handleSelectCustomer}
                placeholder='Search Customer' />
        </>
    )
}

export default SelectCustomer;