function getUserId() {
	return JSON.parse(localStorage.getItem('comuserid'))
}
function gettenantId() {
	return JSON.parse(localStorage.getItem('Tenantid'))
}

export const insertDemandSkillExpertise = demandid => {
	return {
		skillarea: 0,
		demandskillid: null,
		demandid,
		skillmasterid: 0,
		technologymasterid: null,
		domainid: null,
		importance: 0,
		expertise: '',
		comtenantid: gettenantId(),
		isactiveflag: true,
		createdcomuserid: getUserId(),
		modifiedcomuserid: getUserId(),
		uId:
			Math.random().toString(36).substr(2, 9) +
			new Date().getTime().toString(36),
	}
}
