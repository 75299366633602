import UserManagmentFilter from 'src/Filter/admin/usermanagment';
import UserManagmentList from './list';
import { getRoleMasterIdData, getTenantCompanyMasterData, getUserAccessPayload, getUserTypeIdData, getDefaultStatusPayload } from 'src/Services/constant/defaultPayload';
import { useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { GET_ROLE_MASTER, GET_USER_TYPE_LIST, GET_USER_ACCESS_LIST } from 'src/Redux/actions/masterdata/user';
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects';
import { getPermissionsOfCurrentUrl } from 'src/Services';
// import NoPermissionComponent from 'src/Components/NoPermission';

const UserManagment = ({ modulename, menu }) => {
  const [filters, setFilters] = useState(getUserAccessPayload());
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  // const { PermissionsOfUserAccess } = useSelector((state) => state.UserManagementList);

  useEffect(() => {
    dispatch(GET_USER_ACCESS_LIST(getUserAccessPayload()));
    dispatch(GET_COMPANY_MASTER(getTenantCompanyMasterData()));
    dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()));
    dispatch(GET_ROLE_MASTER(getRoleMasterIdData()));
    dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E')));
  }, [dispatch]);


  useEffect(() => {
    const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname);
    dispatch({ type: 'SAVE_PERMISSIONS_OF_USER_ACCESS', data: permissionNames });
    // eslint-disable-next-line
  }, [dispatch, menu]);


  return (
    <>
      <div className='p-3 text-sm'>
        <UserManagmentFilter filters={filters} setFilters={setFilters} setUsers={setUsers} />
        <UserManagmentList
          filters={filters}
          users={users}
          setUsers={setUsers}
        />
      </div>
    </>
  );
}

export default UserManagment;