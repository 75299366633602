import { memo, useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'

const Details = (props) => {
    const { canEdit, setDetailsData, detailsData, detailSave, setCanEdit } = props
    const [checkvalidation, setCheckvalidation] = useState(false)

    const handleSubmit = () => {
        setCheckvalidation(true)

        let item = {
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            educationmaster: detailsData?.educationmaster,
            dateducationmasterid: detailsData?.dateducationmasterid,
            isactiveflag: detailsData?.isactiveflag,
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            notes: detailsData?.notes,
        }
        let isValidated = CheckObjectValidation(detailsData, ['dateducationmasterid', 'modifiedcomuserid', 'modifiedtimestamp', 'notes', 'totalpages'])
        if (isValidated?.isvalid) {
            let payload = {
                dateducationjson: [item],
            }
            detailSave(payload)
        }
    }
    const handleChangeInput = (name, value) => {
        setCheckvalidation(false)
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label htmlFor="educationmaster" className="block font-[500] mb-2">
                        Qualification
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        type="text"
                        title="educationmaster"
                        id="educationmaster"
                        className="block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 "
                        placeholder="Enter educationmaster Name"
                        required=""
                        onChange={(e) => {
                            handleChangeInput('educationmaster', e.target.value)
                        }}
                        value={detailsData?.educationmaster || ''}
                        disabled={canEdit}
                    />
                    {!detailsData?.educationmaster && checkvalidation && (
                        <small className="text-red-400"> educationmaster is required!</small>
                    )}
                </div>
            </div>
            <div className="fixed bottom-0 mt-2 right-6">
                {!canEdit && (
                    <div className="fixed bottom-0 mt-2 right-6">
                        <div className="flex items-center justify-end gap-2">
                            <button
                                // disabled={!canEdit}
                                onClick={() => setCanEdit(false)}
                                type=""
                                className="px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2"
                            >
                                Cancel
                            </button>
                            <PrimaryButton
                                disabled={canEdit}
                                className="bg-primary text-white rounded py-1.5 px-8 mb-4 h-9"
                                onClick={handleSubmit}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default memo(Details)
