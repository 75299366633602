import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import drilldown from 'highcharts/modules/drilldown'
import { useDispatch, useSelector } from 'react-redux'
import { GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD } from 'src/Redux/actions/analytics'

drilldown(Highcharts)

const AllocationChart = ({ filters, setDrilldownData, reportLevel, setReportLevel, setSelectedIds, selectedIds }) => {
    const dispatch = useDispatch()
    const [chartOptions, setChartOptions] = useState({})
    const [navigationPath, setNavigationPath] = useState([{ name: 'Service Line Group', level: 'SERVICELINEGROUP' }])
    const [loading, setLoading] = useState(false)
    const { allocationChart } = useSelector((state) => state?.Analytics)

    const fetchData = async (payload) => {
        try {
            setLoading(true)
            const res = await dispatch(GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD(payload))
            if (res && res.data) {
                setDrilldownData(res.data)
                setChartOptions(generateChartOptions(res.data, payload.reportlevel))
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(
        () => {
            setDrilldownData(allocationChart)
            setChartOptions(generateChartOptions(allocationChart, reportLevel))
        },
        // eslint-disable-next-line
        [allocationChart],
    )

    const buildPayload = (level, ids) => {
        const payload = {
            ...filters,
            Tenantid: 3,
            reportlevel: level,
            servicelinegroupid: ids.servicelinegroupid || 0,
            servicelineid: ids.servicelineid || 0,
            businessunitid: ids.businessunitid || 0,
            customergroupid: ids.customergroupid || 0,
            customerid: ids.customerid || 0,
        }
        // console.log('Building payload with:', payload)
        return payload
    }

    useEffect(
        () => {
            const currentPayload = buildPayload(reportLevel, selectedIds)
            fetchData(currentPayload)
        },
        // eslint-disable-next-line
        [filters],
    )

    const handleDrilldown = async (event) => {
        // console.log('Drilldown event:', event)
        const { point } = event

        if (!point || reportLevel === 'PROJECT') return

        let newLevel
        const newSelectedIds = { ...selectedIds }

        // Define the level mapping for navigation display names
        const levelDisplayNames = {
            SERVICELINE: 'Service Line',
            BUSINESSUNIT: 'Business Unit',
            CUSTOMERGROUP: 'Customer Group',
            CUSTOMER: 'Customer',
            PROJECT: 'Project',
        }

        switch (reportLevel) {
            case 'SERVICELINEGROUP':
                newLevel = 'SERVICELINE'
                newSelectedIds.servicelinegroupid = point.options.id
                break
            case 'SERVICELINE':
                newLevel = 'BUSINESSUNIT'
                newSelectedIds.servicelineid = point.options.id
                break
            case 'BUSINESSUNIT':
                newLevel = 'CUSTOMERGROUP'
                newSelectedIds.businessunitid = point.options.id
                break
            case 'CUSTOMERGROUP':
                newLevel = 'CUSTOMER'
                newSelectedIds.customergroupid = point.options.id
                break
            case 'CUSTOMER':
                newLevel = 'PROJECT'
                newSelectedIds.customerid = point.options.id
                break
            default:
                return
        }

        // Create new navigation path item
        const newNavItem = {
            name: point.name,
            displayName: levelDisplayNames[newLevel] || newLevel,
            level: newLevel,
        }

        // Update navigation path ensuring no duplicates
        const existingIndex = navigationPath.findIndex((item) => item.level === newLevel)
        let newPath
        if (existingIndex !== -1) {
            // If level exists, update everything from that point forward
            newPath = [...navigationPath.slice(0, existingIndex), newNavItem]
        } else {
            // If level doesn't exist, append to the path
            newPath = [...navigationPath, newNavItem]
        }

        setNavigationPath(newPath)
        setReportLevel(newLevel)
        setSelectedIds(newSelectedIds)

        const newPayload = buildPayload(newLevel, newSelectedIds)
        await fetchData(newPayload)
        setDrilldownData(point.options)
    }

    const generateChartData = (data, level) => {
        if (!data) return { categories: [], allocation: [], fte: [] }

        const getLevelData = (item) => {
            switch (level) {
                case 'SERVICELINEGROUP':
                    return {
                        name: item.ServiceLineGroupName,
                        id: item.ServiceLineGroupid,
                        label: item.ServiceLineGroupName,
                    }
                case 'SERVICELINE':
                    return {
                        name: item.ServiceLineName,
                        // Use ServiceLineId instead of ServiceLineid
                        id: item.ServiceLineId,
                        label: item.ServiceLineName,
                    }
                case 'BUSINESSUNIT':
                    return {
                        name: item.BusinessUnitName,
                        id: item.BusinessUnitId,
                        label: item.BusinessUnitName,
                    }
                case 'CUSTOMERGROUP':
                    return {
                        name: item.CustomerGroupName,
                        id: item.CustomerGroupId,
                        label: item.CustomerGroupName,
                    }
                case 'CUSTOMER':
                    return {
                        name: item.CustomerName,
                        id: item.CustomerId,
                        label: item.CustomerName,
                    }
                case 'PROJECT':
                    return {
                        name: item.ProjectName,
                        id: item.ProjectId,
                        label: item.ProjectName,
                    }
                default:
                    return { name: '', id: null, label: '' }
            }
        }

        const chartData = data.map((item) => {
            const levelInfo = getLevelData(item)
            return {
                categories: levelInfo.label,
                allocation: {
                    name: levelInfo.name,
                    y: item.AllocationPerc,
                    id: levelInfo.id,
                    drilldown: reportLevel !== 'PROJECT' ? levelInfo.id : null,
                },
                fte: {
                    name: levelInfo.name,
                    y: item.FTE,
                    id: levelInfo.id,
                    drilldown: reportLevel !== 'PROJECT' ? levelInfo.id : null,
                },
            }
        })

        return {
            categories: chartData.map((item) => item.categories),
            allocation: chartData.map((item) => item.allocation),
            fte: chartData.map((item) => item.fte),
        }
    }

    const generateChartOptions = (data, currentReportLevel) => {
        const chartData = generateChartData(data, currentReportLevel)

        return {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                style: {
                    fontSize: '14px',
                },
                events: {
                    drilldown: handleDrilldown,
                    drillup: handleDrillup,
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: chartData.categories,
                labels: {
                    rotation: -45,
                    style: {
                        color: '#6b7280',
                        fontSize: '14px',
                        fontWeight: '500',
                    },
                },
            },
            yAxis: [
                {
                    title: {
                        text: 'Allocation (%)',
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                    },
                    labels: {
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.value, 0, '.', ',')
                        },
                    },
                },
                {
                    title: {
                        text: 'FTE Count',
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                    },
                    labels: {
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                        formatter: function () {
                            return Highcharts.numberFormat(this.value, 0, '.', ',')
                        },
                    },
                    opposite: true,
                },
            ],
            legend: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 0, '.', ',')
                        },
                        style: {
                            color: '#6b7280',
                            fontSize: '14px',
                            fontWeight: '500',
                        },
                    },
                },
                column: {
                    grouping: true,
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: 'Allocation %',
                    data: chartData.allocation,
                    color: '#FF6633',
                    yAxis: 0,
                },
                {
                    name: 'FTE',
                    data: chartData.fte,
                    color: '#00B3E6',
                    yAxis: 1,
                },
            ],
            tooltip: {
                headerFormat: '<span style="font-size:14px;font-weight:500;color:#6b7280">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.category}</span>: <b>{point.y}</b><br/>',
                style: {
                    color: '#6b7280',
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
            credits: {
                enabled: false,
            },
            drilldown: {
                activeAxisLabelStyle: {
                    color: '#6b7280',
                    fontWeight: '500',
                    textDecoration: 'none',
                },
                activeDataLabelStyle: {
                    color: '#6b7280',
                    fontWeight: '500',
                    textDecoration: 'none',
                },
                drillUpButton: {
                    relativeTo: 'spacingBox',
                    position: {
                        y: 0,
                        x: 0,
                    },
                },
            },
        }
    }

    const handleDrillup = async () => {
        if (navigationPath.length <= 1) return

        const newPath = navigationPath.slice(0, -1)
        const newLevel = newPath[newPath.length - 1].level
        const newSelectedIds = { ...selectedIds }

        // Reset IDs based on the level we're drilling up to
        switch (newLevel) {
            case 'SERVICELINEGROUP':
                newSelectedIds.servicelinegroupid = null
                newSelectedIds.servicelineid = null
                newSelectedIds.businessunitid = null
                newSelectedIds.customergroupid = null
                newSelectedIds.customerid = null
                break
            case 'SERVICELINE':
                newSelectedIds.servicelineid = null
                newSelectedIds.businessunitid = null
                newSelectedIds.customergroupid = null
                newSelectedIds.customerid = null
                break
            case 'BUSINESSUNIT':
                newSelectedIds.businessunitid = null
                newSelectedIds.customergroupid = null
                newSelectedIds.customerid = null
                break
            case 'CUSTOMERGROUP':
                newSelectedIds.customergroupid = null
                newSelectedIds.customerid = null
                break
            case 'CUSTOMER':
                newSelectedIds.customerid = null
                break
            default:
                return
        }

        setNavigationPath(newPath)
        setReportLevel(newLevel)
        setSelectedIds(newSelectedIds)

        const newPayload = buildPayload(newLevel, newSelectedIds)
        await fetchData(newPayload)
    }

    const handleBreadcrumbClick = async (index) => {
        if (index === navigationPath.length - 1) return

        // Only keep navigation items up to the clicked index
        const newPath = navigationPath.slice(0, index + 1)
        const newLevel = newPath[index].level
        const newSelectedIds = { ...selectedIds }

        const levels = ['SERVICELINEGROUP', 'SERVICELINE', 'BUSINESSUNIT', 'CUSTOMERGROUP', 'CUSTOMER', 'PROJECT']
        const currentLevelIndex = levels.indexOf(newLevel)

        // Reset all IDs after the current level
        if (currentLevelIndex < levels.indexOf('SERVICELINE')) newSelectedIds.servicelinegroupid = null
        if (currentLevelIndex < levels.indexOf('BUSINESSUNIT')) newSelectedIds.servicelineid = null
        if (currentLevelIndex < levels.indexOf('CUSTOMERGROUP')) newSelectedIds.businessunitid = null
        if (currentLevelIndex < levels.indexOf('CUSTOMER')) newSelectedIds.customergroupid = null
        if (currentLevelIndex < levels.indexOf('PROJECT')) newSelectedIds.customerid = null

        setNavigationPath(newPath)
        setReportLevel(newLevel)
        setSelectedIds(newSelectedIds)

        const newPayload = buildPayload(newLevel, newSelectedIds)
        await fetchData(newPayload)
    }

    return (
        <div>
            <div
                className="flex flex-wrap items-center px-4 py-2 mb-4 rounded-md"
                style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#6b7280',
                }}
            >
                {navigationPath.map((item, index) => (
                    <span key={`${item.level}-${index}`} className="flex items-center">
                        <span
                            className={`cursor-pointer ${index < navigationPath.length - 1
                                ? 'text-primary-600 hover:text-primary-700'
                                : 'text-primary-800 font-semibold'
                                }`}
                            onClick={() => handleBreadcrumbClick(index)}
                        >
                            {item.displayName || item.name}
                        </span>
                        {index < navigationPath.length - 1 && (
                            <span className="mx-2 text-gray-400">/</span>
                        )}
                    </span>
                ))}
            </div>

            {loading ? (
                <div className="flex items-center justify-center w-full h-full">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    containerProps={{ className: 'chart-container' }}
                />
            )}
        </div>
    )
}

export default AllocationChart
