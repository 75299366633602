import SingleSelectField from "src/Components/SingleSelectForTable";

const RoleLevel = ({ options, canEdit, value, rowId, handleChange, index, checkvalidation, isValidatedField }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'levelid', value: value['levelmasterid'] } }
        handleChange(e, rowId)
    }

    return (
        <div>
            <SingleSelectField
                valueKey='levelmasterid'
                labelKey='levelname'
                options={options}
                value={value || 0}
                handleSelected={getSelected}
                disabled={false} />
        </div>
    )
}

export default RoleLevel;