import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import MultiSelectItem from 'src/Components/MultiSelect'
import { GET_CAPACITY_REPORT } from 'src/Redux/actions/reports'
import { getUserAccessPayload, getWorkForceReportsPayload } from 'src/Services/constant/defaultPayload'
import SelectLocation from './selectLocation'
import { GET_USER_ACCESS_LIST } from 'src/Redux/actions/masterdata/user'
import Selectuser from '../finance/selectuser'

const ReportFilter = ({
    filters,
    setFilters,
    cols = 'grid-cols-6',
    setReportId,
    reportId,
    user, setUser
}) => {
    const dispatch = useDispatch()
    const { workertypelist, workforcelist, allocationstatuslist, companylist } =
        useSelector((state) => state.EmployeeDetails)
    const { userSkillList } = useSelector((state) => state.EmployeeProfile)
    const { reportMasterList } = useSelector((state) => state.WorkforceReport)
    const { serviceList } = useSelector((state) => state.UserManagementList)
    const [location, setLocation] = useState({})
    const [allocationStatus, setAllocationStatuslist] = useState([])
    const { statusListByType } = useSelector((state) => state.Projects)
    const [resetFilters, setResetFilters] = useState(false)

    const handleStatusSelect = (status) => {
        const isNew = allocationStatus?.some((p) => p?.statusid === status?.statusid)
        if (isNew) {
            const updatedAllocationStatus = allocationStatus?.filter(
                (p) => p?.statusid !== status?.statusid,
            )
            setAllocationStatuslist(updatedAllocationStatus)
        } else {
            setAllocationStatuslist((prevList) => [
                ...prevList,
                { statusid: status?.statusid, statusname: status?.statusname },
            ])
        }
    }

    useEffect(() => {
        if (allocationStatus) {
            const statusIdsString = `{${allocationStatus
                .map((status) => status.statusid)
                .join(',')}}`
            setFilters((prevFilters) => ({
                ...prevFilters,
                Cityid: location?.comcityid || 0,
                Allocationstatusid: statusIdsString,
                Userid: user?.comuserid || 0,
            }))
        }
    }, [allocationStatus, location, user, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        setAllocationStatuslist([])
        setLocation(null)
        let payload = {
            ...getWorkForceReportsPayload(),
            Allocationstatusid: '{}',
        }
        dispatch(GET_CAPACITY_REPORT(payload))
        setFilters(payload)
        setUser({})
        setResetFilters(true)
    }

    const applyFilters = () => {
        let data = {
            ...filters,
            Cityid: location?.comcityid || 0,
            Userid: user?.comuserid || 0,
        }
        dispatch(GET_CAPACITY_REPORT(data))
    }

    const selectReport = (name, value) => {
        setFilters({ ...filters, [name]: value })
        setReportId(value)
    }

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Select Report</h1>
                    {reportMasterList?.length > 0 && (
                        <CustomhtmlSelect
                            options={reportMasterList || []}
                            noDataLabel="No Report"
                            value={reportId}
                            name="reportid"
                            labelkey="reportname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={selectReport}
                            id="reportid"
                        />
                    )}
                </div>
                <>
                    <div>
                        <label htmlFor="User" className="block capitalize mb-2  font-[500]">
                            Select User
                        </label>
                        <Selectuser
                            setUser={setUser}
                            user={user}
                            apiCallForSearch={GET_USER_ACCESS_LIST}
                            className="py-2 border rounded bg-gray-50"
                            userSelectorKey="userlistforadmin"
                            searchPayloadFunction={getUserAccessPayload}
                            placeholder="Select User"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Company</h1>
                        <CustomhtmlSelect
                            options={companylist || []}
                            value={filters?.Companyid}
                            noDataLabel="No Company"
                            name="Companyid"
                            labelkey="companyname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="companyid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Worker Type</h1>
                        <CustomhtmlSelect
                            options={workertypelist || []}
                            value={filters?.Emptypeid}
                            noDataLabel="No Type"
                            name="Emptypeid"
                            labelkey="workertype"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="workertypeid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Workforce group</h1>
                        <CustomhtmlSelect
                            options={workforcelist || []}
                            value={filters?.Workforcegroup}
                            noDataLabel="No Workforce found"
                            name="Workforcegroup"
                            labelkey="workforcegroup"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="wfgid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Emp Status</h1>
                        <CustomhtmlSelect
                            options={statusListByType || []}
                            value={filters?.Empstatus}
                            noDataLabel="No Employee Status found"
                            name="Empstatus"
                            labelkey="statusname"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="statusid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Allocation Status</h1>
                        <div className="border rounded bg-gray-50 p-0.5">
                            <MultiSelectItem
                                itemIdKey="statusid"
                                labelKeyname="statusname"
                                limitShow={1}
                                selectedOptions={allocationStatus}
                                options={allocationstatuslist}
                                handleSelection={handleStatusSelect}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Primary Skill</h1>
                        <CustomhtmlSelect
                            options={userSkillList || []}
                            value={filters?.Primaryskillid}
                            noDataLabel="No Skill"
                            name="Primaryskillid"
                            labelkey="skill"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="skillmasterid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Secondary Skill</h1>
                        <CustomhtmlSelect
                            options={userSkillList || []}
                            value={filters?.Secondaryskillid}
                            noDataLabel="No Skill"
                            name="Secondaryskillid"
                            labelkey="skill"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="skillmasterid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]">Service Line</h1>
                        <CustomhtmlSelect
                            options={serviceList || []}
                            value={filters?.servicelineid}
                            noDataLabel="No Serviceline"
                            name="servicelineid"
                            labelkey="servicelinename"
                            className="py-2 border rounded bg-gray-50"
                            onChange={handleSelectChange}
                            id="servicelineid"
                        />
                    </div>
                    <div>
                        <h1 className="mb-2 font-[500]"> Location</h1>
                        <SelectLocation
                            location={location}
                            setLocation={setLocation}
                            resetFilters={resetFilters}
                        />
                    </div>
                </>
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={clearFilters}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportFilter
