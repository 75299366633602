import { postMethodApiCall } from "src/Services/api"
import { AlertError, AlertSuccess } from "src/Services";
import {
    ACTION_GET_ACTIVITY_BY_PROJECT,
    ACTION_GET_TASK_GROUPS,
    ACTION_GET_TASK_BY_GROUP_ID,
    ACTION_GET_LIST_OF_PROJECT_ROLE,
    ACTION_GET_CUSTOMER_ROLE_BY_ID,
    ACTION_GET_MILESTONE_OF_PROJECT,
    ACTION_GET_BUDGET_OF_PROJECT,
    ACTION_GET_SEARCH_ACTIVITY_TASK_BY_ID,
    // ACTION_SAVE_PROJECT_ROLE,
    // ACTION_SAVE_MILESTONE_PROJECT_ID,
    // ACTION_SAVE_BUDGET_OF_PROJECT,
    // ACTION_SAVE_BUDGET_ESTIMATION,
    // ACTION_SAVE_ACTIVITY_PLANNING,
} from "src/Redux/types";

const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: JSON.parse(localStorage.getItem("comuserid"))
    }
}

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
}

/**
 * This function returns list of activity by project id.
 *
 * @param {Number} param1 - project id .
 * @param {Number} param2 - activity id .
 * @returns {Array} list if array
 */

export const GET_ACTIVITY_BY_PROJECT = (projectid = 0, activityid = 0, versionno = null) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid,
                projectid,
                activityid,
                versionno,
                taskname:''
            }
            const url = `${baseUrl}/get_projectactivity`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    item?.taskdetails?.map((_t) => {
                        _t['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                        return _t
                    })
                    return item
                })
                if (items === null) AlertError('No Phases found!')
                dispatch({ type: ACTION_GET_ACTIVITY_BY_PROJECT, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_SEARCH_ACTIVITY_TASK_BY_ID = (payload) => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/get_projectactivity`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                    item?.taskdetails?.map((_t) => {
                        _t['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                        return _t
                    })
                    return item
                })
                // if (items === null) AlertError('No Phases found!')
                dispatch({ type: ACTION_GET_SEARCH_ACTIVITY_TASK_BY_ID, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}


/**
 * This function returns list of task group.
 * @param {Number} taskgroupid default id 0
 */

export const GET_TASK_GROUPS = (taskgroupid = 0) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid,
                taskgroupid,
                searchname: "",
                sorttype: 1,
                status: 'ACTIVE'
            }
            const url = `${baseUrlMaster}/get_taskgroupmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No task groups found!')
                dispatch({ type: ACTION_GET_TASK_GROUPS, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

/**
 * This function returns list of task list by select task group.
 * @param {Number} param1 - taskgroup id .
 * @param {Number} param2 - taskid id  ir can be zero.
 */

export const GET_TASK_BY_GROUP_ID = (payload, cachePayload) => {
    return async dispatch => {
        try {
            if(cachePayload && (JSON.stringify(payload) === JSON.stringify(cachePayload))){
                return 
            }
            dispatch({ type: 'FETCH_ACTIVITY_PAYLOAD', data: payload })
            const url = `${baseUrlMaster}/get_taskmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                if (items === null) AlertError('No task found for selected task group!')
                dispatch({ type: ACTION_GET_TASK_BY_GROUP_ID, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

/**
 * This function save active
 * @param {Number} param1 - project id .
 * @param {Object} param2 - data as list of object.
 */

export const SAVE_ACTIVITY_PLANNING = (projectid, datalists) => {
    return async dispatch => {
        try {
            let data = []
            let lists = [...datalists]
            for (let item of lists) {
                let activity = { ...item }
                activity['createdcomuserid'] = getTanAndComId().comuserid
                activity['comtenantid'] = getTanAndComId().Tenantid
                activity['projectid'] = projectid
                activity['projectactivityid'] = isNaN(activity['activityid']) ? null : activity['activityid']
                delete activity['activityownername']
                delete activity['uId']
                delete activity['activityid']
                delete activity['projectname']

                let tasks = activity?.taskdetails?.map((taskItem) => {
                    let task = { ...taskItem }
                    // delete task['projecttaskid']
                    delete task['taskname']
                    delete task['taskgroupid']
                    delete task['uId']
                    task['clientapproval'] = JSON.parse(task['clientapproval'])
                    task['billtoclient'] = JSON.parse(task['billtoclient'])
                    task['taskcode'] = ""
                    if (task['isactiveflag'] === undefined) {
                        task['isactiveflag'] = true
                    }
                    // task['isactiveflag'] =  task['isactiveflag'] || true
                    task['projecttaskid'] = taskItem?.projecttaskid || null
                    task['notes'] = task['tasknotes'] || ''
                    delete task['tasknotes']
                    return task
                })
                activity['taskdetails'] = tasks
                data.push(activity)
            }
            const url = `${baseUrl}/insert_projectactivity`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), { projectjson: data })
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Activity saved successfully !')
                return true
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

// ========================================================================
//                          roles API Call
// =======================================================================

/**
 * This function fetch the list of roles
 * @param {Number} param1 - project id .
 * @param {Number} param2 - project roleid by default it is zero.
 */


export const GET_LIST_OF_PROJECT_ROLE = (projectid = 0, projectroleid = 0, versionno = null, isactive = true) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid,
                projectid,
                projectroleid,
                versionno,
                isactive
            }
            const url = `${baseUrl}/get_projectrole`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['allocation'] = item?.allocation * 100
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                // if (items === null) AlertError('No roles found for selected Project!')
                dispatch({ type: ACTION_GET_LIST_OF_PROJECT_ROLE, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const SAVE_PROJECT_ROLE = (data) => {
    return async dispatch => {
        try {
            let payload = { rolejson: data }
            const url = `${baseUrl}/insert_projectrole`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved successfully')
                return true
            } else {
                return false
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_CUSTOMER_ROLE_BY_ID = (customerid = 0, custroleid = 0, isactive = true) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid,
                customerid,
                custroleid,
                isactive
            }
            const url = `${baseUrl}/get_customerrates`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                // if (items === null) AlertError('No roles found for selected Project!')
                dispatch({ type: ACTION_GET_CUSTOMER_ROLE_BY_ID, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

// ========================================================================
//                          Milestone API Call
// =======================================================================

export const GET_MILESTONE_OF_PROJECT = (projectid = 0, projectmilestoneid = 0) => {
    return async dispatch => {
        try {
            let payload = {
                Tenantid: getTanAndComId().Tenantid,
                projectid,
                projectmilestoneid,
            }
            const url = `${baseUrl}/get_projectmilestones`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                // if (items === null) AlertError('No milestone found for selected project!')
                dispatch({ type: ACTION_GET_MILESTONE_OF_PROJECT, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const SAVE_MILESTONE_PROJECT_ID = (data, insertDataKeys, projectid) => {
    return async dispatch => {
        try {
            let insertData = []
            for (let item of data) {
                let row = {}
                delete item['createdtimestamp']
                delete item['modifiedtimestamp']
                row['createdcomuserid'] = JSON.parse(localStorage.getItem('comuserid'))
                row['comtenantid'] = JSON.parse(localStorage.getItem('Tenantid'))
                for (let rolekey in item) {
                    if (insertDataKeys.includes(rolekey)) {
                        row['milestoneamount'] = Number(item['milestoneamount'] || 0)
                        row['datcomcurrencyid'] = Number(item['datcomcurrencyid'] || 0)
                        row[rolekey] = item[rolekey]
                    }
                }
                row['projectid'] = projectid
                insertData.push(row)
            }

            let payload = { projectjson: insertData }
            const url = `${baseUrl}/insert_projectmilestones`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved successfully')
                return true
            } else {
                return false
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const GET_BUDGET_OF_PROJECT = (data, versionno = null) => {
    return async dispatch => {
        try {
            let payload = {
                versionno,
                Tenantid: getTanAndComId().Tenantid,
                ...data,
            }
            const url = `${baseUrl}/get_projectbudget`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map(item => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                // if (items === null) AlertError('No milestone found for selected project!')
                dispatch({ type: ACTION_GET_BUDGET_OF_PROJECT, data: addnewkey || [] })
                return addnewkey || []
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const SAVE_BUDGET_OF_PROJECT = (data, projectid) => {
    return async dispatch => {
        try {
            let insertData = []
            for (let item of data) {
                let row = { ...item }
                delete row['uId']
                row['units'] = Number(item?.units)
                row['rate'] = Number(item?.rate)
                row['plannedcost'] = Number(item?.units) * Number(item?.rate)
                row['isbillable'] = Boolean(item?.isbillable)
                row['projectid'] = projectid
                insertData.push(row)
            }

            let payload = { projectbudgetjson: insertData }
            const url = `${baseUrl}/insert_projectbudget`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Saved successfully')
                return true
            } else {
                return false
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}

export const SAVE_BUDGET_ESTIMATION = (data) => {
    return async () => {
        try {
            let payload = { estimationjson: data }
            const url = `${baseUrl}/insert_projectestimation`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                return true
            } else {
                return false
            }
        } catch (error) {
            AlertError(error.response?.data).replace(/\\/g, "")
        }
    }
}
