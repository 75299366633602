import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { eventListforDemand, eventListforEprofile, eventListforProject, eventListforTimesheet, eventListforUsermanagement, eventListforStaffing, moduleList, modesList, messageToList, ccList1, ChannelList, FrequencyList, } from 'src/Services/constant/staticValues';
import MultiSelectItem from 'src/Components/MultiSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = (props) => {
   const { canEdit, setDetailsData, detailsData, detailSave, setCanEdit } = props
   const [checkvalidation, setcheckvalidation] = useState(false);
   const [sendto, setSendTo] = useState([]);
   const [copyTo, setCopyTo] = useState([]);

   const getEventList = (moduleName) => {
      switch (moduleName) {
         case 'Project':
            return eventListforProject;
         case 'Staffing':
            return eventListforStaffing;
         case 'Timesheet':
            return eventListforTimesheet;
         case 'Demands':
            return eventListforDemand;
         case 'Eprofile':
            return eventListforEprofile;
         case 'User Management':
            return eventListforUsermanagement;
         default:
            return [];
      }
   };

   const handleSubmit = () => {
      setcheckvalidation(true);
      let item = {
         comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
         notificationid: detailsData?.notificationid,
         message: detailsData?.message,
         module: detailsData?.module,
         event: detailsData?.event,
         parameter: detailsData?.parameter,
         mode: detailsData?.mode,
         subject: detailsData?.subject,
         isactiveflag: !detailsData?.isactiveflag,
         createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
         modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
         notes: detailsData?.notes,
         copyto: detailsData?.copyto,
         sendto: detailsData?.sendto,
      }

      let reqList = []
      if (item?.mode === 'Scheduled') {
         reqList.push('frequency')
      }

      let isValidated = CheckObjectValidation(item, reqList);
      if (isValidated?.isvalid) {
         let payload = {
            notificationjson: [item],
         }
         detailSave(payload)
      }
   }

   const handleChangeInput = (name, value) => {
      setcheckvalidation(false);
      if (name === 'module') {
         const selectedModule = moduleList.find((module) => module.name === value);
         const events = selectedModule ? getEventList(value) : [];
         setDetailsData((prevData) => ({ ...prevData, module: value, events }));
      } else {
         setDetailsData((prevData) => ({ ...prevData, [name]: value }));
      }
   };

   const handleSendToSelect = (selectedParams) => {
      const isNew = sendto?.some(p => p?.name === selectedParams?.name);
      if (isNew) {
         const updatedParams = sendto?.filter(p => p?.name !== selectedParams?.name);
         setSendTo(updatedParams);
      } else {
         setSendTo(prevList => [
            ...prevList,
            { name: selectedParams?.name },
         ]);
      }
   }

   const handleCopyToSelect = (selectedParams) => {
      const isNew = copyTo?.some(p => p?.name === selectedParams?.name);
      if (isNew) {
         const updatedParams = copyTo?.filter(p => p?.name !== selectedParams?.name);
         setCopyTo(updatedParams);
      } else {
         setCopyTo(prevList => [
            ...prevList,
            { name: selectedParams?.name },
         ]);
      }
   }

   return (
      <div className='w-full h-[65vh] text-sm mb-10 mt-3'>
         <div className='grid grid-cols-4 gap-5 mt-10'>
            <div>
               <label htmlFor='module' className='block font-[500] mb-2'>
                  Module
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={moduleList || []}
                  value={detailsData?.module || ''}
                  noDataLabel='No Module'
                  name='module'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  onChange={handleChangeInput}
                  id='name'
               />
            </div>
            <div>
               <label htmlFor='event' className='block font-[500] mb-2'>
                  Event
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  disabled={canEdit}
                  options={detailsData?.events}
                  value={detailsData?.event || ''}
                  noDataLabel='No Event'
                  name='event'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  onChange={handleChangeInput}
                  id='name'
               />
            </div>
            <div>
               <label htmlFor='paramsid' className='block capitalize font-[500] mb-2'>
                  Mode
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={modesList || []}
                  value={detailsData?.mode || ''}
                  noDataLabel='No Mode'
                  name='mode'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  onChange={handleChangeInput}
                  id='name'
               />
            </div>
            {detailsData?.mode === 'Scheduled' ? <div>
               <label htmlFor='mode' className='block font-[500] mb-2'>
                  Frequency
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={FrequencyList || []}
                  value={detailsData?.frequency || ''}
                  noDataLabel='No Frequency'
                  name='Frequency'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  onChange={handleChangeInput}
                  id='name'
               />
               {!detailsData?.frequency && checkvalidation && (
                  <small className='text-red-400'>Frequency is required!</small>
               )}
            </div> : <div />}
            <div className='mt-3'>
               <label htmlFor='mode' className='block font-[500] mb-2'>
                  Channel
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={ChannelList || []}
                  value={detailsData?.channel || ''}
                  noDataLabel='No channel'
                  name='channel'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
                  onChange={handleChangeInput}
                  id='name'
               />
               {!detailsData?.channel && checkvalidation && (
                  <small className='text-red-400'>channel is required!</small>
               )}
            </div>
            <div className='mt-3'>
               <label htmlFor='sendto' className='block font-[500] mb-2'>
                  Send To
                  <span className="text-red-300"> *</span>
               </label>
               <div className=' w-full border p-0.5 bg-gray-50 rounded-md'>
                  <MultiSelectItem
                     itemIdKey='name'
                     labelKeyname='name'
                     limitShow={1}
                     selectedOptions={sendto}
                     handleSelection={handleSendToSelect}
                     options={messageToList} // Use the dynamically determined parameter list
                  />
               </div>
               {!detailsData?.sendto && checkvalidation && (
                  <small className='text-red-400'>Send to is required!</small>
               )}
            </div>

            <div className='mt-3'>
               <label htmlFor='copyto' className='block font-[500] mb-2'>
                  Copy To
                  <span className="text-red-300"> *</span>
               </label>
               <div className=' w-full border p-0.5 bg-gray-50 rounded-md'>
                  <MultiSelectItem
                     itemIdKey='name'
                     labelKeyname='name'
                     limitShow={1}
                     selectedOptions={copyTo}
                     handleSelection={handleCopyToSelect}
                     options={ccList1} // Use the dynamically determined parameter list
                  />
               </div>
               {!detailsData?.copyto && checkvalidation && (
                  <small className='text-red-400'>Copy to is required!</small>
               )}
            </div>

         </div>
         <div className='mt-3'>
            <label htmlFor='subject' className='block font-[500] mb-2'>
               Subject
               <span className="text-red-300"> *</span>
            </label>
            <input
               disabled={canEdit}
               type='text'
               title='subject'
               id='subject'
               className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-1/2 p-2'
               placeholder='Enter Subject'
               required=''
               onChange={(e) => {
                  if (/^[A-Za-z0-9._-\s]*$/.test(e.target?.value)) {
                     handleChangeInput('subject', e.target.value)
                  }
               }}
               value={detailsData?.subject || ''}
               maxLength={100}
            />
            {!detailsData?.subject && checkvalidation && (
               <small className='text-red-400'>Subject is required!</small>
            )}
         </div>
         <div className='mt-8'>
            <label htmlFor='message' className='block font-[500] mb-2'>
               Message
               <span className="text-red-300"> *</span>
            </label>
            <textarea
               disabled={canEdit}
               type='text'
               title='message'
               rows={5}
               id='message'
               className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
               placeholder='Enter Message'
               required=''
               onChange={(e) => {
                  if (/^[A-Za-z0-9._-\s]*$/.test(e.target?.value)) {
                     handleChangeInput('message', e.target.value)
                  }
               }}
               value={detailsData?.message || ''}
               maxLength={100}
            />
            {!detailsData?.message && checkvalidation && (
               <small className='text-red-400'>Message is required!</small>
            )}
         </div>
         <div className='fixed bottom-0 right-6 mt-2'>
            {!canEdit && (
               <div className='fixed bottom-0 right-6 mt-2'>
                  <div className='gap-2 flex justify-end items-center'>
                     <button
                        onClick={() => setCanEdit(false)}
                        type=''
                        className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                     >
                        Cancel
                     </button>
                     <PrimaryButton
                        disabled={canEdit}
                        className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                        onClick={handleSubmit}
                     >
                        Save
                     </PrimaryButton>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default memo(Details);