import { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { GET_LOCATION_MASTER, GET_SERVICELINE_MASTER } from "src/Redux/actions/masterdata/user"
import { GET_DEMAND_SUMMARY_REPORT, GET_REPORT_MASTER_LIST } from "src/Redux/actions/reports"
import { GET_SKILLAREA_MASTER } from "src/Redux/actions/workforce/eprofile"
import { GET_ALLOCATION_STATUS_FOR_EMPLOYEE, GET_COMPANY_MASTER } from "src/Redux/actions/workforce/talent"
import { getDefaultLocationPayload, getDemandSummaryReportsPayload, getStatusMasterIdData, getTenantCompanyIdData } from "src/Services/constant/defaultPayload"
import { getMasterServiceLinePayload, getMasterSkillAreaData } from "src/Services/constant/masterdatapayload"
import ReportsFilter from 'src/Filter/reports/workforce/demandSummary'
import ReportList from "./table"

const DemandSummaryReports = ({setReportId, reportId}) => {
	const [filters, setFilters] = useState(getDemandSummaryReportsPayload())
	const dispatch = useDispatch()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const datmenuId = searchParams.get('datmenuid')
	const {reportMasterList} = useSelector(
		state => state?.WorkforceReport,
	)
	useEffect(() => {
		dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
	}, [datmenuId, dispatch])

	useEffect(() => {
 		dispatch(GET_SKILLAREA_MASTER(getMasterSkillAreaData()))
		dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
		dispatch(GET_DEMAND_SUMMARY_REPORT(getDemandSummaryReportsPayload()))
		dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
 		dispatch(GET_LOCATION_MASTER(getDefaultLocationPayload()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('W')))
	}, [dispatch])

	return (
		<>
			<div className='p-2 py-3 bg-white rounded-md shadow'>
				<ReportsFilter
					reportId={reportId}
					setReportId={setReportId}
					setFilters={setFilters}
					filters={filters}
				/>
			</div>
			<ReportList
				filters={filters}
				reportMasterList={reportMasterList}
				reportId={reportId}
			/>
		</>
	)
}

export default memo(DemandSummaryReports)