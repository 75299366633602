import ReportsFilter from 'src/Filter/reports/workforce/pdpAging'
import { memo, useEffect, useState } from 'react'
import ReportList from './table'
import {
	getBandMasterData,
	getPdpAgingReportsPayload,
	getTenantCompanyIdData,
	getUserTypeIdData,
} from 'src/Services/constant/defaultPayload'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
	GET_COMPANY_MASTER,
	GET_BAND_MASTER,
} from 'src/Redux/actions/workforce/talent'

import {
	GET_PDP_AGING_REPORT,
	GET_REPORT_MASTER_LIST,
} from 'src/Redux/actions/reports'
import {
	GET_SERVICELINE_MASTER,
	GET_USER_TYPE_LIST,
} from 'src/Redux/actions/masterdata/user'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'

const PdpAgingDetails = ({ setReportId, reportId }) => {
	const [filters, setFilters] = useState(getPdpAgingReportsPayload())
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const dispatch = useDispatch()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const datmenuId = searchParams.get('datmenuid')
	const { pdpAgingReport, reportMasterList } = useSelector(
		state => state?.WorkforceReport,
	)
	useEffect(() => {
		dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
	}, [datmenuId, dispatch])

	useEffect(() => {
		dispatch(GET_BAND_MASTER(getBandMasterData()))
		dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
		dispatch(GET_PDP_AGING_REPORT(getPdpAgingReportsPayload()))
		dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
		dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()))
	}, [dispatch])

	return (
		<>
			<div className="p-2 py-3 bg-white rounded-md shadow">
				<ReportsFilter
					reportId={reportId}
					setReportId={setReportId}
					setFilters={setFilters}
					filters={filters}
					page={page}
					limit={limit}
				/>
			</div>
			<ReportList
				pdpAgingReport={pdpAgingReport}
				filters={filters}
				reportMasterList={reportMasterList}
				reportId={reportId}
				page={page}
				setPage={setPage}
				limit={limit}
				setLimit={setLimit}
			/>
		</>
	)
}

export default memo(PdpAgingDetails)
