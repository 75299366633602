import { LogLevel } from "@azure/msal-browser";


const authConfig = {
    auth: {
      clientId: process.env.REACT_APP_clientId,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TenantId}`,
      redirectUri: process.env.REACT_APP_redirectUri,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              return;
            case LogLevel.Info:
              return;
            case LogLevel.Verbose:
              return;
            case LogLevel.Warning:
              return;
          }
        },
      },
    },
  };
  

  export default authConfig