import { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { getMasterCategoryPayload, getTemplateCategoryPayload } from 'src/Services/constant/masterdatapayload';
import { GET_TIME_CATEGORY_MASTER, GET_TEMPLATE_MASTER } from 'src/Redux/actions/configuration';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { AlertError } from 'src/Services';
import { timeTypeUniquenes, timeEntryFormat
   // validationTime
   , holidayCalendarAttestaion, yesNoWithboolean } from 'src/Services/constant/staticValues'


const Details = (props) => {
   const dispatch = useDispatch();
   const { companylist, workertypelist } = useSelector((state) => state.EmployeeDetails);
   const { countryList } = useSelector(state => state.UserManagementList)
   const { detailSave, detailsData, setDetailsData } = props;
   const [checkvalidation, setCheckvalidation] = useState(false);
   const [selectedCompanyId, setSelectedCompanyId] = useState(null);
   const [filteredCategoryList, setFilteredCategoryList] = useState([]);
   const [filteredTemplateList, setFilteredTemplateList] = useState([]);
   const [selectedTemplateType, setSelectedTemplateType] = useState('');
   const [timeCategoryError, setTimeCategoryError] = useState(false);
   const [timeTemplateError, setTimeTemplateError] = useState(false);


   useEffect(() => {
      if (selectedCompanyId) {
         let payload = {
            ...getTemplateCategoryPayload(),
            companyid: selectedCompanyId,
         };
         dispatch(GET_TEMPLATE_MASTER(payload))
            .then(res => {
               const filteredDataTwo = res?.data
               setFilteredTemplateList(filteredDataTwo);
            });
         let payloadTwo = {
            ...getMasterCategoryPayload(),
            companyid: selectedCompanyId,
         };
         dispatch(GET_TIME_CATEGORY_MASTER(payloadTwo))
            .then(res => {
               const filteredData = res?.data
               setFilteredCategoryList(filteredData);
            })
      }
   }, [selectedCompanyId, dispatch]);


   const handleSubmit = () => {
      setCheckvalidation(true);
      if (!detailsData?.timecategorymasterid) {
         setTimeCategoryError(true);
         let isValidated = true;
         // eslint-disable-next-line
         isValidated.isvalid = false;
         // Update the overall validation flag
      }
      if (!detailsData?.timetemplatemasterid) {
         setTimeTemplateError(true);
         // eslint-disable-next-line
         isValidated.isvalid = false;
         // Update the overall validation flag
      }
      let item = {
         ...detailsData,
         companyid: detailsData?.companyid,
         timecategorymasterid: filteredCategoryList?.length > 0 ? detailsData?.timecategorymasterid : null,
         timetemplatemasterid: filteredTemplateList?.length > 0 ? detailsData?.timetemplatemasterid : null,
      };

      let isValidated = CheckObjectValidation(item, ['weekdayentryallowed', 'notes', 'timetypetemplatemappingid', 'timetemplatemasterid']);
      if (isValidated?.isvalid && parseFloat(item.mindailylimit) <= parseFloat(item.maxdailylimit)) {
         let payload = {
            timetypetemplatemappingjson: [item],
         };
         detailSave(payload);
      } else {
         AlertError('Minimum daily limit should be less than maximum daily limit');
      }
   };

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);
      if (name === 'companyid') {
         const selectedCompanyId = value;
         setSelectedCompanyId(selectedCompanyId);
         setFilteredTemplateList([]);
         setFilteredCategoryList([]);
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: value,
            timetemplatemasterid: null, // Reset the template name
            timecategorymasterid: null, // Reset the category
         }));
      }
      else if (name === 'timetemplatemasterid') {
         const selectedTemplate = filteredTemplateList.find((item) => item.timetemplatemasterid === value);
         const templateType = selectedTemplate ? selectedTemplate.templatetype : '';
         setSelectedTemplateType(templateType); // Set the selected template type
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: value,
         }));
      }
      else if (name === 'mindailylimit' || name === 'maxdailylimit') {
         let parsedValue = parseFloat(value);
         if (isNaN(parsedValue) || parsedValue < 0) {
            value = ''; // Set the value to an empty string if it's NaN or negative
         }
         else if (name === 'maxdailylimit' && parsedValue > 24) {
            value = '24'; // Set maximum value for maxdailylimit to 24
         }
         else if (name === 'mindailylimit' && parsedValue > 8) {
            parsedValue = 8; // Set maximum value for mindailylimit to 8
         }
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: value,
         }));
      }
      else {
         setDetailsData((prevDetailsData) => ({
            ...prevDetailsData,
            [name]: value,
         }));
      }
   };

   return (
      <div className='w-full h-[65vh] text-sm mb-10'>
         <div className='grid grid-cols-4 border-b pb-4 gap-7 my-8'>
            <div>
               <label htmlFor='companyname' className='block font-[500] mb-2'>
                  Company
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={companylist || []}
                  value={selectedCompanyId || ''}
                  noDataLabel='No Company'
                  name='companyid'
                  labelkey='companyname'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='companyid'
               />
               {!detailsData?.companyid && checkvalidation && (
                  <small className='text-red-400'>Company is required!</small>
               )}
            </div>
            <div>
               {/* {console.log(filteredTemplateList)} */}
               <label htmlFor='timetemplatemasterid' className='block font-[500] mb-2'>
                  Template Name
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={filteredTemplateList || []}
                  value={detailsData?.timetemplatemasterid || ''}
                  noDataLabel='No Template'
                  name='timetemplatemasterid'
                  labelkey='templatename'
                  className={`border rounded bg-gray-50 py-2 ${timeTemplateError ? 'border' : ''}`}
                  onChange={handleChangeInput}
                  id='timetemplatemasterid'
               />
               {!detailsData?.timetemplatemasterid && checkvalidation && (
                  <small className='text-red-400'>Time Template is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='templatetype' className='block font-[500] mb-2'>
                  Template Type
               </label>
               <input
                  type="text"
                  value={selectedTemplateType === 'P' ? 'Project' : selectedTemplateType === 'G' ? 'Generic' : ''} className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
                  disabled
               />
            </div>
         </div>
         <div className='grid grid-cols-4 gap-6'>
            <div>
               <label htmlFor='comcountryid' className='block font-[500] mb-2'>
                  Country
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={countryList || []}
                  value={detailsData?.comcountryid || ''}
                  noDataLabel='No country'
                  name='comcountryid'
                  labelkey='countryname'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='comcountryid'
               />
               {!detailsData?.comcountryid && checkvalidation && (
                  <small className='text-red-400'>Country is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='workertypeid' className='block font-[500] mb-2'>
                  Worker Type
                  <span className='text-red-300'> *</span>
               </label>
               <CustomhtmlSelect
                  options={workertypelist || []}
                  value={detailsData?.workertypeid}
                  noDataLabel='No Worker Type'
                  name='workertypeid'
                  labelkey='workertype'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='workertypeid'
               />
               {!detailsData?.workertypeid && checkvalidation && (
                  <small className='text-red-400'>Worker Type is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='timecategory' className='block font-[500] mb-2'>
                  Time Type
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={filteredCategoryList || []}
                  value={detailsData?.timecategorymasterid || ''}
                  noDataLabel='No Category'
                  name='timecategorymasterid'
                  labelkey='timecategory'
                  className={`border rounded bg-gray-50 py-2 ${timeCategoryError ? 'border' : ''}`} onChange={handleChangeInput}
                  id='timecategorymasterid'
               />
               {!detailsData?.timecategorymasterid && checkvalidation && (
                  <small className='text-red-400'>Time Type is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='timeentryformat' className='block font-[500] mb-2'>
                  Time Entry Format
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={timeEntryFormat || []}
                  value={detailsData?.timeentryformat}
                  noDataLabel='No Time Entery Format'
                  name='timeentryformat'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='timeentryformat'
               />
               {!detailsData?.timeentryformat && checkvalidation && (
                  <small className='text-red-400'>Time Entry Format is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='timetypeuniqueness' className='block font-[500] mb-2'>
                  Time Type Uniqueness
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={timeTypeUniquenes(selectedTemplateType)}
                  value={detailsData?.timetypeuniqueness}
                  noDataLabel='No Time Type Uniqueness'
                  name='timetypeuniqueness'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='timetypeuniqueness'
               />
               {!detailsData?.timetypeuniqueness && checkvalidation && (
                  <small className='text-red-400'>Time Type is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='mindailylimit' className='block font-[500] mb-2'>
                  Min. Daily Hours
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  type='number'
                  title='mindailylimit'
                  id='mindailylimit'
                  min={0} // Minimum value set to 0
                  max={detailsData?.maxdailylimit || 24}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
                  placeholder='Minimum Daily Hours'
                  required=''
                  // value={detailsData?.mindailylimit || ''}
                  onChange={(e) => handleChangeInput('mindailylimit', e.target.value)}
               />
               {!detailsData?.mindailylimit && checkvalidation && (
                  <small className='text-red-400'>Minimum Daily Hours is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='maxdailylimit' className='block font-[500] mb-2'>
                  Max. Daily Hours
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  type='number'
                  title='maxdailylimit'
                  id='maxdailylimit'
                  min={detailsData?.mindailylimit || 0} // Minimum value set to mindailylimit or 0
                  max={24} // Maximum value set to 24
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
                  placeholder='Maximum Daily Hours'
                  required=''
                  // value={detailsData?.maxdailylimit || ''}
                  onChange={(e) => handleChangeInput('maxdailylimit', e.target.value)}
               />
               {!detailsData?.maxdailylimit && checkvalidation && (
                  <small className='text-red-400'>Maximum Daily Hours is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='holidaycalendarintegration' className='block font-[500] mb-1'>
                  Holiday Calendar
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={holidayCalendarAttestaion || []}
                  value={detailsData?.holidaycalendarintegration}
                  noDataLabel='No Holiday Calendar Integration'
                  name='holidaycalendarintegration'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='holidaycalendarintegration'
               />
               {!detailsData?.holidaycalendarintegration && checkvalidation && (
                  <small className='text-red-400'> Holiday Calendar is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='weekdayentryallowed' className=' block font-[500] mb-2'>
                  Weekday Entry
               </label>
               <CustomhtmlSelect
                  options={yesNoWithboolean} value={detailsData?.weekdayentryallowed}
                  name='weekdayentryallowed'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />

            </div>

            <div>
               <label htmlFor='weekendentryallowed' className=' block font-[500] mb-2'>
                  Weekend Entry
               </label>
               <CustomhtmlSelect
                  options={yesNoWithboolean} value={detailsData?.weekendentryallowed}
                  name='weekendentryallowed'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />

            </div>
            <div>
               <label htmlFor='holidayallowed' className=' block font-[500] mb-2'>
                  Holiday Entry
               </label>
               <CustomhtmlSelect
                  options={yesNoWithboolean} value={detailsData?.holidayallowed}
                  name='holidayallowed'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />

            </div>

            <div>
               <label htmlFor='selfattestation' className=' block font-[500] mb-2'>
                  Self Attestation Required
               </label>
               <CustomhtmlSelect
                  options={yesNoWithboolean} value={detailsData?.selfattestation}
                  name='selfattestation'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />
            </div>
         </div>

         <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
               <PrimaryButton
                  className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
                  onClick={handleSubmit}
               >
                  Save
               </PrimaryButton>
            </div>
         </div>
      </div>
   );
};

export default memo(Details);
