import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
	GET_ACTIVITY_BY_PROJECT,
} from 'src/Redux/actions/projects/planning'
import moment from 'moment'

const Plannings = ({ projectDetail }) => {
	const dispatch = useDispatch()
	const { activitiesByProjectId } = useSelector(state => state?.ProjectPlanning)

	useEffect(() => {
		if (projectDetail?.projectid) {
			dispatch(GET_ACTIVITY_BY_PROJECT(projectDetail?.projectid, 0))
		}
	}, [projectDetail, dispatch])

	return (
		<div className='h-[calc(90%)]  mt-2 overflow-scroll'>
			<table className='text-sm w-full relative'>
				<thead className='sticky top-0'>
					<tr className='text-left capitalize bg-gray-50'>
						<th
							scope='col'
							className='font-[500] border text-start p-1.5'
						>Phase </th>
						<th
							scope='col'
							className='font-[500] border text-start p-1.5'
						>Start Date</th>
						<th
							scope='col'
							className='font-[500] border text-start p-1.5'
						>End Date</th>
						<th
							scope='col'
							className='font-[500] border text-start p-1.5'
						>No. of Tasks</th>
						<th
							scope='col'
							className='font-[500] border text-start p-1.5'
						>Estd. Effort</th>
						<th
							scope='col'
							className='font-[500] border text-start p-1.5'
						>Completion %</th>
					</tr>
				</thead>
				<tbody>
					{activitiesByProjectId?.map((item, i) => {
						let {
							enddate,
							startdate,
							taskdetails,
							activityname,
						} = item

						return (
							<tr key={i}>
								<td className='border px-1 text-sm'>{activityname}</td>
								<td className='border px-1 text-sm'>{moment(startdate).format('DD-MM-YYYY')}</td>
								<td className='border px-1 text-sm'>{moment(enddate).format('DD-MM-YYYY')}</td>
								<td className='border px-1 text-sm'>{taskdetails?.length || 0}</td>
								<td className='border px-1 text-sm'>
									{taskdetails?.reduce((sum, task) => sum + task?.estdeffort, 0)} Hrs
								</td>
								<td className='border px-1 text-sm'></td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default Plannings
