

import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ProjectDetails from 'src/Pages/app/Projects/details'
import ProjectListing from 'src/Pages/app/Projects/listing'
import NotFound from 'src/Pages/view/NotFound'
import Homedashboard from 'src/Pages/app/Home'
import ProjectPlanning from 'src/Pages/app/Projects/planning'
import ProjectStaffing from 'src/Pages/app/Projects/staffing'
import ProjectTimesheet from 'src/Pages/app/Projects/timesheetUi'
import AdminApp from 'src/Pages/app/Admin'
import LoginPage from 'src/Pages/view/Auth'
import SidebarAndProtected from './sidebarAndProjected'
import AppFinancials from 'src/Pages/app/Financials'
import WorkforceDemandDetail from 'src/Pages/app/Workforce/DemamdDetails'
import WorkforceDemandList from 'src/Pages/app/Workforce/demand'
import WorkforceTalent from 'src/Pages/app/Workforce/talent'
import WorkforceEprofile from 'src/Pages/app/Workforce/eprofile'
import WorkforceEmployeeDetails from 'src/Pages/app/Workforce/employeeDetails'
import ReportsApp from 'src/Pages/app/Reports/capacity-workforce'
import TimesheetReportsApp from 'src/Pages/app/Reports/project'
import FinanceReportsApp from 'src/Pages/app/Reports/finance'
import Analytics from 'src/Pages/app/Analytics'
import AccessDenied from 'src/Pages/app/AccessDenied'
import Createnewpassword from 'src/Pages/view/Auth/createnewpassword'
import WorkforceAnalytics from 'src/Pages/app/Analytics/workforceAnalytics'
import AnalyticsForFinance from 'src/Pages/app/Analytics/financeAnalytics'
import TenantLayout from 'src/Pages/app/Subscriptions'

const AllRoutes = () => {

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TENANT_CODE} - ProSrv360` || ''
    }, [])
    return (
        <Router>
            <Routes>
                <Route path="/app" element={<LoginPage />} />
                <Route path="/app/login" element={<LoginPage />} />
                <Route
                    path="/app/Createnewpassword/:userid/:createpasswordKey"
                    element={<Createnewpassword />}
                />
                <Route element={<SidebarAndProtected />}>
                    <Route
                        path="/app/workforce/demand/detail"
                        element={
                            <WorkforceDemandDetail
                                modulename="Workforce"
                                menu="Demand Details"
                            />
                        }
                    />
                    <Route
                        path="/app/workforce/talent/detail"
                        element={
                            <WorkforceEmployeeDetails
                                modulename="Workforce"
                                menu="Talent Details"
                            />
                        }
                    />
                    <Route
                        path="/app/workforce/demand"
                        element={
                            <WorkforceDemandList modulename="Workforce" menu="Demands" />
                        }
                    />
                    <Route
                        path="/app/workforce/talent"
                        element={<WorkforceTalent modulename="Workforce" menu="Talent" />}
                    />
                    <Route
                        path="/app/workforce/eprofile"
                        element={
                            <WorkforceEprofile modulename="Workforce" menu="E-Profile" />
                        }
                    />
                    <Route
                        path="/app/projects/planning"
                        element={
                            <ProjectPlanning modulename="Projects" menu="Planning" />
                        }
                    />
                    <Route
                        path="/app/projects/staffing"
                        element={
                            <ProjectStaffing modulename="Projects" menu="Staffing" />
                        }
                    />
                    <Route
                        path="/app/projects/timesheet"
                        element={
                            <ProjectTimesheet modulename="Projects" menu="Timesheet" />
                        }
                    />
                    <Route
                        path="/app/finance/finance"
                        element={<AppFinancials modulename="Finance" menu="Finance" />}
                    />
                    <Route
                        path="/app/projects"
                        element={<ProjectListing modulename="Projects" menu="Projects" />}
                    />
                    <Route path="/app/subscriptions" element={<TenantLayout />} />
                    {/* <Route path="/app/admin/user-managment/details/:userId" element={<UserManagmentDetails />} /> */}
                    <Route
                        path="/app/admin/masterdata"
                        element={<AdminApp modulename="Admin" menu="Master Data" />}
                    />
                    <Route
                        path="/app/admin/useraccess"
                        element={<AdminApp modulename="Admin" menu="User Access" />}
                    />
                    <Route
                        path="/app/admin/configuration"
                        element={<AdminApp modulename="Admin" menu="Configuration" />}
                    />
                    <Route path="/app/dashboard" element={<Analytics />} />
                    <Route path="/app/reports" element={<ReportsApp />} />
                    <Route
                        path="/app/dashboards/projects"
                        element={
                            <Analytics modulename="Analytics" menu="Project Dashboards" />
                        }
                    />
                    <Route
                        path="/app/dashboards/finance"
                        element={
                            <AnalyticsForFinance
                                modulename="Analytics"
                                menu="Finance Dashboards"
                            />
                        }
                    />
                    <Route
                        path="/app/dashboards/workforce"
                        element={
                            <WorkforceAnalytics
                                modulename="Analytics"
                                menu="Workforce Dashboards"
                            />
                        }
                    />
                    <Route
                        path="/app/reports/workforce"
                        element={
                            <ReportsApp modulename="Reports" menu="Workforce Reports" />
                        }
                    />
                    <Route
                        path="/app/reports/projects"
                        element={
                            <TimesheetReportsApp
                                modulename="Reports"
                                menu="Project Reports"
                            />
                        }
                    />
                    <Route
                        path="/app/reports/finance"
                        element={
                            <FinanceReportsApp
                                modulename="Reports"
                                menu="Finance Reports"
                            />
                        }
                    />
                    <Route
                        path="/app/project/detail"
                        element={
                            <ProjectDetails
                                modulename="Projects"
                                menu="Project Details"
                            />
                        }
                    />
                    <Route path="/app/home" element={<Homedashboard />} />
                    <Route path="/app/index.html" element={<Homedashboard />} />
                    <Route path="/app/access-denied" element={<AccessDenied />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default AllRoutes

