
const SkillExpertise = ({demandData}) => {


  return (
    <div className="my-2 text-sm">
      <div className="h-[76dvh] overflow-y-scroll">
        <table className="w-full text-left text-gray-500">
          <thead className="capitalize text-textSecondary bg-gray-50">
            <tr className="bg-gray-100  font-[500] border-b">
              <th className="border  font-[500] py-2 px-2">Skill Area</th>
              <th className="border  font-[500] py-2 px-2">Skill</th>
              <th className="border  font-[500] py-2 px-2">Expertise</th>
              <th className="border  font-[500] py-2 px-2">Technology</th>
              <th className="border  font-[500] py-2 px-2">Domain</th>
              <th className="border  font-[500] py-2 px-2">Importance</th>
            </tr>
          </thead>
          <tbody>
            {demandData[0]?.demandskills?.map((item, id) => {
              let {uId, skillarea, skill, importance, technology, domain, expertise} = item
              return (
                <tr key={uId} className="bg-white border-b">
                  <td className="px-2 py-2 border ">{skillarea}</td>
                  <td className="px-2 py-2 border ">{skill}</td>
                  <td className="px-2 py-2 border ">{expertise}</td>
                  <td className="px-2 py-2 border ">{technology}</td>
                  <td className="px-2 py-2 border ">{domain}</td>
                  <td className="px-2 py-2 border ">{importance === 1 ? "Must Have": "Good to Have"}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SkillExpertise
