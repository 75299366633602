import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import SingleSelectField from 'src/Components/SingleSelectForTable'
import { GET_COURSE_MASTER } from 'src/Redux/actions/workforce/eprofile'
import { getDefaultCoursePayload } from 'src/Services/constant/defaultPayload'

const Course = ({ className, canEdit, value, rowId, handleChange, qualification }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    let isMounted = true

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isMounted = false
        }
    }, [])

    useMemo(() => {
        setLoading(true)
        let payload = {
            ...getDefaultCoursePayload(),
            dateducationmasterid: Number(qualification || 0),
        }
        dispatch(GET_COURSE_MASTER(payload))
            .then((res) => {
                if (isMounted) {
                    setLoading(false)
                    setData(res?.data)
                }
            })
            .catch((err) => {
                if (isMounted) {
                    setLoading(false)
                }
            })
    }, [qualification, dispatch, isMounted])

    const getSelected = (value) => {
        let e = {
            target: { name: 'datcoursemasterid', value: value['datcoursemasterid'] },
        }
        handleChange(e, rowId)
    }

    return (
        <div className={className}>
            {loading ? (
                'loading..'
            ) : (
                <SingleSelectField
                    hover={true}
                    valueKey="datcoursemasterid"
                    labelKey="coursemaster"
                    options={data}
                    value={value || 0}
                    handleSelected={getSelected}
                    disabled={!canEdit}
                    className="w-10 break-words"
                />
            )}
        </div>
    )
}

export default Course
