import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FinancialsFilterRevenue from 'src/Filter/projects/financialsFilterRevenue'
import { GET_PROJECT_REVENUE } from 'src/Redux/actions/projects/financials'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import { getDefaultRevenuePayload } from './defaultPayload'
import Revenuetable from './table'

const Revenue = ({ addEditPermission }) => {
    const dispatch = useDispatch()
    const { ProjectRevenue, loading: serviceLoading } = useSelector((state) => state.ProjectFinancial)
    const [revenue, setRevenue] = useState([])
    const [revId, setRevId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [filters, setFilters] = useState(getDefaultRevenuePayload())
    const [loading, setLoading] = useState(true)
    const [project, setProject] = useState({ projectid: 0, projectname: 'All' })
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    let { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)

    const getDefaultRevenueData = async () => {
        let res = await dispatch(
            GET_PROJECT_REVENUE({
                ...filters,
                Projectid: 0,
                Limit: limit,
                Page: page,
                Sorttype: sortType?.id,
            }),
        )
        if (res !== null) {
            setRevenue(res)
        }
    }

    useEffect(
        () => {
            dispatch(GET_TIMESHEET_PROJECTS_M())
            setLoading(serviceLoading)
        }, // eslint-disable-next-line
        [],
    )

    useEffect(
        () => {
            if (projectsOfM?.length > 0) {
                dispatch(
                    GET_PROJECT_REVENUE(
                        getDefaultRevenuePayload(0),
                    ),
                    null,
                )
            }
        }, // eslint-disable-next-line
        [projectsOfM],
    )

    return (
        <>
            <FinancialsFilterRevenue
                cols="grid-cols-5"
                project={project}
                setRevenue={setRevenue}
                setProject={setProject}
                ProjectRevenue={ProjectRevenue}
                filters={filters}
                setFilters={setFilters}
                page={page}
                limit={limit}
                sortType={sortType}
            />
            <Revenuetable
                hooks={{
                    addEditPermission,
                    sortType,
                    setSortType,
                    page,
                    setPage,
                    setLimit,
                    limit,
                    loading,
                    filters,
                    setFilters,
                    ProjectRevenue,
                    revenue,
                    setRevenue,
                    revId,
                    setRevId,
                    project,
                    isOpen,
                    setIsOpen,
                    getDefaultRevenueData,
                    projectsOfM,
                }}
            />
        </>
    )
}

export default Revenue
