import { memo } from 'react';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';


const cols = ['Module', 'Menu', 'Full Access', 'Permissions']

const RolePermission = ({ hooks }) => {
    const { handleSubmit, detailsData, selectedPermissions, setSelectedPermissions, canEdit } = hooks;

    const handlePermissionCheckboxChange = (e, permission, module) => {
        let { checked } = e.target
        let data = selectedPermissions.map((it) => {
            if (it?.datmenuid === module?.datmenuid) {
                it?.rolemenupermissions?.map(subMenu => {
                    if (subMenu?.datmenupermissionid === permission?.datmenupermissionid && subMenu?.datmenuid === module?.datmenuid) {
                        subMenu['isselected'] = checked
                    }
                    return subMenu
                })

                let isfullselected = it?.rolemenupermissions?.filter(subm => subm?.isselected === true)
                if (isfullselected?.length === it?.rolemenupermissions?.length) {
                    it['isfullrights'] = true
                } else {
                    it['isfullrights'] = false
                }
            }
            return it
        })
        setSelectedPermissions(data)
    };

    const handleCheckboxChange = (e, module) => {
        let { checked } = e.target
        let data = selectedPermissions.map((it) => {
            if (it?.datmenuid === module?.datmenuid) {
                it['isselected'] = checked
                it['isfullrights'] = checked
                it?.rolemenupermissions?.map(per => {
                    per['isselected'] = checked
                    return per
                })

            }
            return it
        })
        setSelectedPermissions(data)
    };

    return (
        <div className=' overflow-scroll h-[calc(100%-180px)]  w-full'>
            <table className='relative w-full'>
                <thead className='sticky -top-1 z-40'>
                    <tr className='text-left capitalize bg-gray-50'>
                        {cols?.map((col) => {
                            return (
                                <th
                                    key={col}
                                    title={col}
                                    scope='col'
                                    className='font-[500] text-sm p-2 min-w-[100px] border text-start'
                                >
                                    {col}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        selectedPermissions?.map((it, i) => {
                            return (
                                <tr
                                    key={i}
                                    className='text-left cursor-pointer'>
                                    <td className='border px-1 text-sm'>
                                        <span>
                                            {it?.modulename}
                                        </span>
                                    </td>
                                    <td role='button' className='border min-w-[150px] px-1 text-sm'>
                                        <span> {it?.menuname}</span>
                                    </td>
                                    <td className='border p-1 text-sm'>
                                        {
                                            canEdit ? <input
                                                type="checkbox"
                                                id={it?.datmenuid}
                                                name="isfullrights"
                                                checked={it.isfullrights || false}
                                                onChange={(e) => handleCheckboxChange(e, it)} /> : <input readOnly checked={it.isfullrights || false} type='checkbox' />
                                        }

                                    </td>
                                    <td className='p-2 border text-sm w-full'>
                                        <div className="grid grid-cols-3 gap-2">
                                            {it?.rolemenupermissions?.map((permission) => (
                                                <div key={permission?.permissionname} className="flex items-center justify-start">
                                                    <label>
                                                        {canEdit ? <input
                                                            disabled={detailsData.isfullrights}
                                                            className="mr-2"
                                                            type="checkbox"
                                                            name={permission?.permissionname}
                                                            checked={permission?.isselected || false}
                                                            // checked={selectedPermissions[permission.permissionname]}
                                                            onChange={(e) => handlePermissionCheckboxChange(e, permission, it)} /> : <input readOnly className="mr-2" checked={permission.isselected || false} type='checkbox' />}
                                                        {permission.permissionname}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>

            {canEdit && <div className='fixed bottom-0 right-6 mt-2'>
                <div className='gap-2 flex justify-end items-center'>
                    <PrimaryButton
                        className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
                        onClick={handleSubmit}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>}
        </div>
    );
};

export default memo(RolePermission);

