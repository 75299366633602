import { useDispatch, useSelector } from 'react-redux';
import BarchartDashboard from './barChart';
import LineChartDashboard from './lineChart'
import HomeNotification from './notification'
import { useEffect, useState } from 'react';
import { GET_EFFORT_HISTROY_METRICS, GET_METRICS_FOR_HOME, GET_NOTIFICATION_FOR_HOMEPAGE } from 'src/Redux/actions/analytics';
import AppMatrics from './martics';
import { getEmployeeUtilizationReportsPayload, notificationRowDefault } from 'src/Services/constant/defaultPayload';
import { GET_UTILIZATION_REPORT_FOR_EMPLOYEE } from 'src/Redux/actions/reports';
import { ToastContainer } from 'react-toastify';

const Homeapp = () => {
	const dispatch = useDispatch()
	const { notificationList, homePagemetrics, effortHistoryMatrics } = useSelector(state => state?.Analytics)
	const { utilizationreportforEmployee } = useSelector(state => state?.WorkforceReport)
	const [utilizationHistory, setUtilizationHistory] = useState([])
	// console.log(notificationList, homePagemetrics, effortHistoryMatrics )
	useEffect(() => {
		dispatch(GET_NOTIFICATION_FOR_HOMEPAGE(notificationRowDefault()))
		dispatch(GET_METRICS_FOR_HOME())
		dispatch(GET_EFFORT_HISTROY_METRICS())
		let payload = {
			...getEmployeeUtilizationReportsPayload(),
			comuserid: JSON.parse(localStorage.getItem('comuserid'))
		}
		dispatch(GET_UTILIZATION_REPORT_FOR_EMPLOYEE(payload))
	}, [dispatch])

	useEffect(() => {
		let items = utilizationreportforEmployee?.Data
		if (items?.length > 0) {
			let data = items[0]?.utilizationdetail?.map((u) => {
				u['Utilization'] = u['monthutilization'] || 0
				return u
			})
			setUtilizationHistory(data)

		}
	}, [utilizationreportforEmployee])

	return (
		<>
			<div className="flex-grow">
				<div className="flex flex-col h-full">
					<div className="flex h-full gap-4 px-2 pb-2">
						<div className="flex-grow flex flex-col gap-4 max-w-[45%]">
							<AppMatrics homePagemetrics={homePagemetrics} />
							<div className="bg-white shadow-md rounded-md  w-full pb-5 overflow-hidden flex flex-col gap-4 h-[calc(100%/1)]">
								<HomeNotification notificationList={notificationList} />
							</div>
						</div>
						<div className="flex-grow max-w-[55%]">
							<div className="flex flex-col w-full h-full gap-5 p-5 bg-white rounded-md shadow-md">
								<div className='w-full'>
									<div className='flex justify-center text-gray-600 font-[500]'>
										<h1>
											Utilization History (%)
										</h1>
									</div>
									<LineChartDashboard data={utilizationHistory} />
									<div className='flex justify-center mt-5 text-gray-600 font-[500]'>
										<h1>Effort History (Hrs)</h1>
									</div>
									<BarchartDashboard
										effortdetails={effortHistoryMatrics?.effortdetails}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer
				position="bottom-center"
				newestOnTop limit={1} />
		</>
	)
}

export default Homeapp
