import { Dialog, Transition } from '@headlessui/react'
import { Fragment, memo, useEffect, useState } from 'react'
import Comercials from './commercials';
import Contacts from './contacts';
import Details from './details'
import { useDispatch } from 'react-redux';
import { GET_SERVICE_LINE_MASTER_LIST, GET_CURRENCY_MASTER_LIST, GET_PRODUCT_LIST, CREATE_NEW_PROJECT, GET_LIST_OF_STATUS, GET_PROJECT_DOCUMENT_LIST, SAVE_PROJECT_DOCUMENT, GET_PROJECT_DOCUMENT_TYPE_LIST } from 'src/Redux/actions/projects';
import { getDefaultCurrencyPayload, getDefaultServiceLinePayload, getDefaultProductPayload, getDefaultStatusPayload, getRoleMasterIdData } from 'src/Services/constant/defaultPayload';
import { createProjectbaseInfo, defaultContactItem, projectDocumentRow } from './defaultPayload';
import { GET_ALL_CONTACTS_OF_A_CUSTOMER } from 'src/Redux/actions/masterdata/customer';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { AlertError, AlertSuccess, findDuplicatesByKeyName } from 'src/Services';
import {
    GET_ROLE_MASTER,
    // GET_ROLE_MASTER,
    GET_TECHNOLOGY_GROUP
} from 'src/Redux/actions/masterdata/user';
import IconButton from 'src/Components/Buttons/IconButton';
import { getTechGroupMasterData, getTemplateCategoryPayload } from 'src/Services/constant/masterdatapayload';
import Documents from './documents';
import { GET_TEMPLATE_MASTER } from 'src/Redux/actions/configuration';

const tabslist = ["Details", "Commercials", "Contacts", "Documents"]

const CreateProject = ({ getProjectDefaultdata }) => {
    const dispatch = useDispatch()
    const [businessUnit, setBusinessUnit] = useState({})
    const [opportunity, setOpportunity] = useState({})
    const [serviceLine, setServiceLine] = useState({})
    const [customer, setCustomer] = useState({})
    const [errorFound, setErrorFound] = useState({})
    const [documents, setDocuments] = useState([projectDocumentRow()])
    const [projectid, setProjectId] = useState(null)
    const [payload, setPayload] = useState(createProjectbaseInfo())
    let [isOpen, setIsOpen] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedContacts, setSelectedContacts] = useState([defaultContactItem()])
    const [pm, setPm] = useState({})
    const [dm, setDm] = useState({})
    const [pmo, setPmo] = useState({})
    const [activeTab, setActiveTab] = useState('Details')
    const [tabCanAccess, setTabCanAccess] = useState(['Details'])

    useEffect(() => {
        dispatch(GET_PROJECT_DOCUMENT_TYPE_LIST())
        dispatch(GET_ROLE_MASTER(getRoleMasterIdData()));
        dispatch(GET_SERVICE_LINE_MASTER_LIST(getDefaultServiceLinePayload()))
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_TECHNOLOGY_GROUP(getTechGroupMasterData()))
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('P')))
        dispatch(GET_PRODUCT_LIST(getDefaultProductPayload(0)))
    }, [dispatch])

    useEffect(() => {
        if (customer?.customerid) {
            setPayload({ ...payload, defaultcomcurrencyid: customer?.defaultcomcurrencyid })
            dispatch(GET_ALL_CONTACTS_OF_A_CUSTOMER(customer?.customerid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    useEffect(() => {
        if (payload?.companyid) {
            let data = getTemplateCategoryPayload(1, 1000, 3, 'Active');
            data['companyid'] = payload?.companyid
            data['typefilter'] = 'Project'
            dispatch(GET_TEMPLATE_MASTER(data))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload?.companyid])

    function closeModal() {
        setIsOpen(false)
        setTabCanAccess(['Details'])
    }

    function openModal() {
        setProjectId(null)
        setBusinessUnit({})
        setOpportunity({})
        setServiceLine({})
        setCustomer({})
        setErrorFound({})
        setPayload(createProjectbaseInfo())
        setIsOpen(true)
    }

    const nextToDetail = () => {
        let validationdata = {}
        let requiredInDetail = ['projectname', 'projecttypeid', 'companyid', 'customerid', 'projectstatus',
            'servicelineid', 'timetemplatemasterid', 'shiftstarttime', 'shiftendtime',
            'startdate', 'enddate', 'datprojectbillingtypeid', 'timezone'
        ]

        for (let value of requiredInDetail) {
            validationdata[value] = payload[value]
        }
        validationdata['servicelineid'] = serviceLine?.servicelineid
        validationdata['businessunitid'] = businessUnit?.businessunitid
        validationdata['customerid'] = customer?.customerid

        let optionalkey = []

        if (payload?.category === 'C') {
            optionalkey = ['servicelineid']
        }

        let validation = CheckObjectValidation(validationdata, optionalkey, false)
        if (!validation?.isvalid) {
            setErrorFound(validation)
            setTabCanAccess(['Details'])
            return
        } else {
            setTabCanAccess(['Details', 'Commercials'])
        }

        if (payload?.startdate > payload?.enddate) {
            AlertError('End date must be greater than start date ')
            return
        }


        setActiveTab('Commercials')
    }

    const nextToCommercials = () => {
        let validationdata = {
            opportunityid: opportunity?.opportunityid || null,
            billingcycle: payload?.billingcycle,
            totalestimateamt: payload?.totalestimateamt
        }

        let validation = CheckObjectValidation(validationdata, ['totalestimateamt', 'opportunityid'], false)
        if (!validation?.isvalid) {
            setErrorFound(validation)
            setTabCanAccess(['Details', 'Commercials'])
            return
        } else {
            setTabCanAccess(['Details', 'Commercials', 'Contacts'])
        }
        setActiveTab('Contacts')
    }

    const saveProjectDocument = async () => {
        let afterModify = []
        for (let it of documents) {
            let document = { ...it }
            delete document['comtenantid']
            delete document['createduser']
            delete document['uId']
            document['projectid'] = projectid
            document['Tenantid'] = it?.comtenantid || it?.Tenantid
            document['documenttype'] = it?.documenttypeid || it['documenttype']
            if (!document['documenttype']) {
                AlertError('Document type is required !')
                return
            }
            afterModify.push(document)
        }

        let res = await dispatch(SAVE_PROJECT_DOCUMENT(afterModify));
        if (res?.success) {
            AlertSuccess('Project document saved successfully.');
            dispatch(GET_PROJECT_DOCUMENT_LIST(projectid))
            resetHooks()
        }
    };

    const resetHooks = () => {
        setBusinessUnit({});
        setOpportunity({});
        setServiceLine({});
        setCustomer({});
        setErrorFound({});
        setPayload(createProjectbaseInfo());
        setSelectedProducts([]);
        setSelectedContacts([defaultContactItem()]);
        setPm({});
        setDm({});
        setDocuments([])
        setProjectId(null)
        setActiveTab('Details');
        setTabCanAccess(['Details']);
    };

    const addProject = async () => {
        let validationdata = {
            projectmanagerid: pm?.comuserid,
            deliverymanagerid: dm?.comuserid,
            customerid: customer?.customerid
        }
        let validation = CheckObjectValidation(validationdata, ['opportunityid'], true)
        if (!validation?.isvalid) {
            setErrorFound(validation)
            return
        }

        function checkUniqueUser(str1, str2, str3) {
            if (str1 === str2 || str1 === str3 || str2 === str3) {
                return false
            } else {
                return true
            }
        }
        let result = checkUniqueUser(pm?.comuserid, dm?.comuserid, pmo?.comuserid);

        if (!result) {
            AlertError('PM, DM should be different !')
            return
        }
        if (!Number(payload?.totalestimateamt)) {
            setErrorFound({ keyname: 'totalestimateamt' })
            AlertError('Planned Revenue is required ')
            return
        }
        let contacts = selectedContacts?.filter(item => item?.contactpersonname === '')

        let [hoursSS, minutesSS] = payload?.shiftstarttime.split(":");
        let [hoursSE, minutesSE] = payload?.shiftendtime.split(":");

        let apiPayload = {
            ...payload,
            projectdocuments: [],
            opportunityid: opportunity?.opportunityid,
            servicelineid: serviceLine?.servicelineid,
            businessunitid: businessUnit?.businessunitid,
            totalestimateamt: Number(payload?.totalestimateamt),
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            projectmanagerid: pm?.comuserid,
            deliverymanagerid: dm?.comuserid,
            PMOcontactpersonid: pmo?.comuserid || null,
            projectcustomercontacts: contacts?.length > 0 ? [] : selectedContacts,
            projectproductmapping: selectedProducts,
            customerid: customer?.customerid,
            shiftstarttime: parseFloat(hoursSS + "." + minutesSS),
            shiftendtime: parseFloat(hoursSE + "." + minutesSE),
        }

        let finalPayload = {
            projectjson: [apiPayload]
        }
        const duplicateEmails = findDuplicatesByKeyName(selectedContacts, 'email');
        if (duplicateEmails?.length > 0) {
            AlertError('Duplicate Contacts are not allowed !')
            return
        }
        let res = await dispatch(CREATE_NEW_PROJECT(finalPayload))
        if (res?.success) {
            setProjectId(res.data?.projectid)
            setTabCanAccess(["Details", "Commercials", "Contacts", 'Documents'])
            setActiveTab('Documents')
            getProjectDefaultdata()
        } else {
            setProjectId(res.data?.projectid)
        }
    }

    return (
        <>
            <IconButton
                title='Create New Project'
                onClick={openModal}
                className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </IconButton>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => console.log('')}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" w-[calc(90%)] 
  md:w-[calc(80%)]  transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">

                                    <div className="flex justify-between w-full items-center border-b border-secondary ">
                                        <h3 className=" text-lg font-[500] leading-6 text-primary">
                                            Create Project
                                        </h3>
                                        <div className="flex w-auto p-1 space-x-1 rounded-lg">
                                            {tabslist?.map((item, i) => {
                                                return (
                                                    <button
                                                        key={i}
                                                        disabled={!tabCanAccess.includes(item)}
                                                        onClick={() => setActiveTab(item)}
                                                        className={`w-full py-1 text-lg outline-white ${item === activeTab
                                                            ? 'bg-secondary/10 text-secondary rounded-md font-[500]'
                                                            : 'text-gray-500'}`}
                                                    >
                                                        <span className='px-4'> {item}</span>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                        <IconButton onClick={closeModal} title='Close' className="text-textSecondary w-9 h-9 flex justify-center items-center border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center ">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </IconButton>
                                    </div>
                                    {activeTab === 'Details' && <>
                                        <Details
                                            hooks={{
                                                errorFound, setErrorFound,
                                                nextToDetail,
                                                businessUnit, setBusinessUnit,
                                                payload, setPayload,
                                                customer, setCustomer,
                                                serviceLine, setServiceLine
                                            }}
                                        />
                                    </>}
                                    {activeTab === 'Commercials' && <>
                                        <Comercials
                                            hooks={{
                                                errorFound, setErrorFound,
                                                nextToCommercials,
                                                payload, setPayload,
                                                opportunity, setOpportunity,
                                                selectedProducts, setSelectedProducts,
                                            }}
                                        />
                                    </>}
                                    {activeTab === 'Contacts' && <>
                                        <Contacts
                                            hooks={{
                                                addProject,
                                                errorFound, setErrorFound,
                                                customer, setCustomer,
                                                payload, setPayload,
                                                serviceLine,
                                                businessUnit,
                                                pm, setPm,
                                                dm, setDm,
                                                pmo, setPmo,
                                                selectedContacts, setSelectedContacts
                                            }}
                                        />
                                    </>}

                                    {activeTab === 'Documents' && <>
                                        <Documents
                                            hooks={{
                                                documents, setDocuments,
                                                saveProjectDocument
                                            }}
                                        />
                                    </>}

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>

                </Dialog>
            </Transition>
        </>
    )
}

export default memo(CreateProject);

