import ActivityForm from "./activityForm";
import SubActivity from "./subActivity";
import { memo, useEffect, useState } from "react";
import { activityItem } from './defaultDataSkeleton'
import { useDispatch, useSelector } from "react-redux";
import { GET_ACTIVITY_BY_PROJECT, GET_TASK_GROUPS, SAVE_ACTIVITY_PLANNING } from "src/Redux/actions/projects/planning";
import PrimaryButton from "src/Components/Buttons/PrimaryButton";
import { CheckObjectValidation, getTitleOfKeyname } from "src/Services/constant/customvalidation";
import { AlertError } from "src/Services";
import { useLocation } from "react-router-dom";

const PlanningActivity = ({ project, disabled, setEditTab, editTab }) => {
    const planningState = useSelector((state => state?.ProjectPlanning))
    const { activitySearchedTasks } = planningState
    const dispatch = useDispatch()
    const [activeId, setActiveId] = useState(0)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const staffingProjectId = searchParams.get('projectid')
    const [searchTerm, setSearchTerm] = useState('')
    const [activity, setActivity] = useState({})

    useEffect(() => {
        dispatch(GET_TASK_GROUPS())
    }, [dispatch])

    const getDatabyProjectId = async () => {
        if (staffingProjectId) {
            setEditTab(null)
            let res = await dispatch(GET_ACTIVITY_BY_PROJECT(+staffingProjectId || project?.projectid, 0))
            if (res?.length > 0) {
                setActiveId(res[0]?.activityid)
                setActivity(res[0])
            } else {
                dispatch({ type: 'GET_ACTIVITY_BY_PROJECT', data: [activityItem()] })
                setActivity(activityItem())
                setActiveId(activityItem()?.activityid)
            }
        }
    }

    useEffect(() => {
        getDatabyProjectId()
        // eslint-disable-next-line
    }, [dispatch, staffingProjectId, project])

    const selectActivity = (item) => {
        setActiveId(item?.activityid)
        setActivity(item)
        setSearchTerm('')
    }

    const saveActivity = async () => {
        let isvalidaed = CheckObjectValidation(activity, ['isactiveflag', 'notes', 'projectname', 'activitycode', 'activitydesc'])
        if (!isvalidaed?.isvalid) {
            AlertError(`Please fill required field in ${getTitleOfKeyname(isvalidaed?.keyname)}, ${getTitleOfKeyname(activity?.activityname)}`)
            return
        }
        for (let task of activity?.taskdetails) {
            let isvalidaed = CheckObjectValidation(task, ['projecttaskid', 'taskname', 'tasknotes', 'taskcode', 'taskseq', 'notes'])
            if (!isvalidaed?.isvalid) {
                return
            }
        }
        let res = await dispatch(SAVE_ACTIVITY_PLANNING(project?.projectid, [activity]))
        if (res) {
            setEditTab(null)
            let res = await dispatch(GET_ACTIVITY_BY_PROJECT(+staffingProjectId || project?.projectid, 0))
            let findActiveOne = res.find(a => a?.activityid === activity?.activityid)
            if (findActiveOne) {
                setActivity(findActiveOne)
            } else {
                setActivity(res[0])
            }
        }
    }

    return (
        <div className="h-full relative">
            <SubActivity hooks={{ editTab, activeId, selectActivity, activitySearchedTasks, project, setActiveId }} />
            <ActivityForm
                hooks={{
                    disabled,
                    project,
                    selectActivity,
                    searchTerm, setSearchTerm,
                    activeId,
                    activity, setActivity
                }}
            />
            {disabled ? <div className="flex justify-between items-center">
                <p className="px-2 text-[12px] text-gray-700 font-[300]">Please use Tasks tab to create custom tasks for your project</p>
                <div className="flex justify-end items-center space-x-2 px-2">
                    <button onClick={getDatabyProjectId}
                        className='text-primary border border-primary w-[80px] rounded-md py-1.5'
                    >
                        Cancel
                    </button>
                    <PrimaryButton onClick={saveActivity} className='bg-primary text-white rounded-md py-2'>
                        Save
                    </PrimaryButton>
                </div>
            </div> : ''}
        </div>
    )
}

export default memo(PlanningActivity);