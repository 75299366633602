
import { useDispatch } from 'react-redux'
import Budgettable from './table'
import { GET_BUDGET_OF_PROJECT, SAVE_BUDGET_ESTIMATION, SAVE_BUDGET_OF_PROJECT } from 'src/Redux/actions/projects/planning'
import { useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'
import BudgetCalculation from '../budgetcalculation'
import { getBudgetofProjectPayload } from 'src/Services/constant/defaultPayload'
import { getGross1, getGross2 } from '../budgetcalculation'
import { getTotalPeopleCostProjectPlanning } from 'src/Services'

const BudgetLayout = ({ hooksValues, disabled, setEditTab }) => {
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const { budget, project, roles } = hooksValues

    const insertEestimationPayload = () => {
        return {
            projectid: project?.projectid,
            plannedeffort: 4000,  // sum of best. Effort from roles table 
            planneddirectcost: getTotalPeopleCostProjectPlanning(roles, 'estPeopleCost'), // people cost 
            plannedindirectcost: getTotalPeopleCostProjectPlanning(budget, 'plannedcost'), // Planned Expenses 
            plannedrevenue: project?.totalestimateamt || 0, // planned revenue 
            datcomcurrencyid: project?.defaultcomcurrencyid,
            grossmargin1: Number(getGross1(project?.totalestimateamt || 0, getTotalPeopleCostProjectPlanning(roles, 'estPeopleCost')) || 0),
            grossmargin2: Number(getGross2(
                project?.totalestimateamt || 0,
                getTotalPeopleCostProjectPlanning(roles, 'estPeopleCost'),
                getTotalPeopleCostProjectPlanning(budget, 'plannedcost')
            ) || 0),
            approved: false,  //  default null for now 
            approverid: null, // logged in user id try to make it null 
            approverremarks: "",
            approvedtimestamp: null,
            rejectionreason: null,
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            isactiveflag: true,
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            notes: ""
        }
    }

    const saveBudget = async () => {
        setLoad(true)
        let notReq = ['plannedcost', 'projectbudgetid', 'plannedeffort', 'notes', 'projectid']
        let isValidated = CheckListObjectValidation(budget, notReq);
        if (isValidated?.isvalid) {
            await dispatch(SAVE_BUDGET_OF_PROJECT(budget, project?.projectid))
            dispatch(SAVE_BUDGET_ESTIMATION([insertEestimationPayload()]))
        }

        setLoad(false)
    }


    const handleCancel = () => {
        setEditTab(null)
        dispatch(GET_BUDGET_OF_PROJECT(getBudgetofProjectPayload(10, 1, project?.projectid)), null)
    }

    return (
        <div className='w-full'>
            <div className=" h-[54vh] overflow-y-scroll px-1">
                <Budgettable disabled={disabled} hooksValues={hooksValues} />
            </div>
            <div className='flex justify-between p-2 items-center'>
                <BudgetCalculation
                    hooksValue={hooksValues}
                />
                {disabled ? <div className='absolute bottom-0 right-0 flex justify-end space-x-2 items-center px-2'>
                    <button onClick={handleCancel} className='text-primary border border-primary w-[80px] rounded-md py-1.5'>
                        Cancel
                    </button>
                    <PrimaryButton disabled={load} onClick={saveBudget} className='bg-primary text-white rounded-md py-2'>
                        {load ? 'Saving...' : 'Save'}
                    </PrimaryButton>
                </div> : <div className='h-12' />}
            </div>
        </div>
    )
}

export default BudgetLayout;