
import { useEffect, useMemo, useState } from "react";
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { GET_CUSTOMER_LIST } from "src/Redux/actions/projects";
import { getDefaultCustomerPayload } from "src/Services/constant/defaultPayload";

const SelectCustomer = ({ customerList, handleSelectCustomer, customer, reset }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useMemo(()=>{
        if(customer?.customername){
            setSearchTerm(customer?.customername || '')
        }
    },[customer])

    	useEffect(() => {
		if (reset) {
			setSearchTerm('')
			handleSelectCustomer({})
		}
	}, [reset, handleSelectCustomer])

    return (
        <>
            <label htmlFor="customer" className="block font-[500] mb-2">Select Customer <span className="text-red-300">*</span></label>
            <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_CUSTOMER_LIST}
                searchPayload={{
                    ...getDefaultCustomerPayload(),
                    customername: searchTerm
                }}
                // disabled={disabled}
                className='p-2'
                setSearchTerm={setSearchTerm}
                options={customerList}
                idkey='customerid'
                optionlabelKey='customername'
                getSelectedOption={handleSelectCustomer}
                placeholder='Search Customer' />
        </>
    )
}

export default SelectCustomer;