
import { useEffect, useState } from "react";
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { GET_OPPORTUNITY_LIST } from "src/Redux/actions/projects";
import { getDefaultOpportunityPayload } from "src/Services/constant/defaultPayload";

const SelectOpportunity = ({ options, setSelected, selected }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(()=>{
        if(selected?.opportunityname){
            setSearchTerm(selected?.opportunityname || '')
        }
    },[selected])

    return (
        <>
            <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_OPPORTUNITY_LIST}
                searchPayload={{
                    ...getDefaultOpportunityPayload(selected?.opportunityid || 0 ),
                    searchname: searchTerm.trim() || selected?.opportunityname?.trim(),
                    Status:'ACTIVE'
                }}
                className='p-2 bg-gray-50'
                setSearchTerm={setSearchTerm}
                options={options}
                idkey='uId'
                optionlabelKey='opportunityname'
                getSelectedOption={setSelected}
                placeholder='Search Opportunity' />
        </>
    )
}

export default SelectOpportunity;