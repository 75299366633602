import { useState } from 'react'
import { useDispatch } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import {
	GET_DEMAND_MASTER,
	SAVE_DEMAND_ROLE_MASTER,
} from 'src/Redux/actions/workforce/demands'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'

const RoleDetails = ({ hooks }) => {
	const { rolebaseDetail, setRoleBaseDetail, roleData, setRoleData, demandStaff } = hooks
	const [checkValidation, setCheckValidation] = useState(null)
	const dispatch = useDispatch()
	const DemandId = demandStaff?.demandid

	const handleAdd = () => {
		if (roleData?.length < 10) {
			setRoleData([
				...roleData,
				{
					uId:
						Math.random().toString(36).substr(2, 9) +
						new Date().getTime().toString(36),
					responsibilities: '',
				},
			])
		}
	}

	const handleDelete = uId => {
		if (roleData?.length > 1) {
			setRoleData(roleData.filter(item => item.uId !== uId))
		}
	}

	const handleChange = e => {
		let { name, value, id } = e.target
		const newroleData = roleData?.map(item => {
			if (item.uId === id) {
				item[name] = value
			}
			return item
		})
		setRoleData([...newroleData])
	}

	const handleChangeInput = (name, value) => {
		setRoleBaseDetail({ ...rolebaseDetail, [name]: value })
	}

	const handleSubmit = async () => {
		let apiPayload = {
			...rolebaseDetail,
			demandid: demandStaff?.demandid,
			demandroledetailid: rolebaseDetail?.demandroledetailid,
			jobtitle: rolebaseDetail?.jobtitle || demandStaff?.rolename,
			experienceinyear: Number(rolebaseDetail?.experienceinyear),
			rolesummary: rolebaseDetail?.rolesummary,
			comtenantid: Number(localStorage.getItem('Tenantid') || 0),
			isactiveflag: rolebaseDetail?.isactiveflag || true,
			createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
			modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
			roleresponsibilities: roleData,
		}
		let data = [apiPayload]
		let isValidated = CheckListObjectValidation(
			data,
			['notes', 'demandroledetailid', 'demandid'],
			false,
		)
		if (isValidated?.isvalid) {
			let res = await dispatch(SAVE_DEMAND_ROLE_MASTER(data, +DemandId))
			if (res !== null) {
				dispatch(GET_DEMAND_MASTER(+DemandId))
			}
		} else {
			setCheckValidation(isValidated)
		}
	}

	return (
		<form className='mt-4 w-full h-[70vh]  text-sm'>
			<div className='grid grid-cols-4 gap-8'>
				<div>
					<label htmlFor='jobtitle' className='block font-[500] mb-2'>
						Job Title <span className='text-red-300'>*</span>
					</label>
					<input
						type='text'
						title='jobtitle'
						id='jobtitle'
						className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
						placeholder='Enter Job Title'
						required=''
						value={rolebaseDetail?.jobtitle || ''}
						onChange={e => handleChangeInput('jobtitle', e.target.value)}
					/>
					{!rolebaseDetail?.jobtitle && checkValidation && (
						<small className='text-red-400'>Job is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='band' className='block font-[500] mb-2'>
						Min Band
					</label>
					<input
						disabled
						type='text' name='Min Band' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]' placeholder='Min Band' required=''
						value={rolebaseDetail?.min_band || ''}
					/>
				</div>
				<div>
					<label htmlFor='band' className='block font-[500] mb-2'>
						Max Band
					</label>
					<input
						disabled
						type='text' name='Max Band' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]' placeholder='Max Band' required=''
						value={rolebaseDetail?.max_band || ''}
					/>
				</div>
				<div>
					<label className='mb-2 text-sm font-[500]'>
						Min Experience (Years) <span className='text-red-300'>*</span>
					</label>
					<input
						placeholder='Min'
						type='text'
						pattern='[0-9\s]*'
						className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
						value={rolebaseDetail?.experienceinyear || ''}
						onChange={e => {
							if (/^[0-9\s]*$/.test(e.target?.value)) {
								handleChangeInput('experienceinyear', e.target?.value)
							}
						}}
					/>
					{!rolebaseDetail?.experienceinyear && checkValidation && (
						<small className='text-red-400'>Min Experience is required!</small>
					)}
				</div>
			</div>
			<div>
				<div className='my-3'>
					<h1 className='mb-2 text-sm font-[500]'>
						Role Summary <span className='text-red-300'>*</span>
					</h1>
					<textarea
						placeholder='Role Summary...'
						rows={3}
						type='text'
						className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
						value={rolebaseDetail?.rolesummary || ''}
						onChange={e => handleChangeInput('rolesummary', e.target.value)}
					/>
					{!rolebaseDetail?.rolesummary && checkValidation && (
						<small className='text-red-400'>Role Summary is required!</small>
					)}
				</div>
				<div className='flex items-center justify-between'>
					<label className='text-sm font-[500]'>
						Role Responsibilities <span className='text-red-300'>*</span>
					</label>
				</div>
				<div className=' overflow-scroll h-[260px] '>
					{roleData?.length > 0 && roleData?.map((item, i) => {
						let { uId, responsibilities } = item
						return (
							<div key={uId} className='flex my-2 space-x-2'>
								<input
									placeholder='responsibility'
									onChange={handleChange}
									name='responsibilities'
									value={responsibilities || ''}
									id={uId}
									className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
								/>
								{!responsibilities && checkValidation && (
									<small className='text-red-400'>Roles is required!</small>
								)}
								<div className='flex justify-center space-x-2'>
									{roleData?.length >= 1 && (
										<button
											onClick={() => handleDelete(item?.uId)}
											className='flex items-center justify-center p-1 text-sm text-white rounded-full w-7 h-7 bg-primary focus:ring-0 focus:ring-primary focus:outline-none'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 24 24'
												fill='none'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-4 h-4'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													fillRule='evenodd'
													d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
													clipRule='evenodd'
												/>{' '}
											</svg>
										</button>
									)}
									{roleData?.length - 1 === i && (
										<button
											disabled={roleData?.length >= 10}
											onClick={handleAdd}
											className='text-white flex justify-center items-center rounded-full w-7 h-7 bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-4 h-4'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M12 4.5v15m7.5-7.5h-15'
												/>
											</svg>
										</button>
									)}
								</div>
							</div>
						)
					})}
				</div>
			</div>
			<div className='fixed bottom-0 mt-2 right-6'>
				<div className='fixed bottom-2 right-5'>
					<PrimaryButton
						className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
						onClick={handleSubmit}
					>
						<span>Save</span>
					</PrimaryButton>
				</div>
			</div>
		</form>
	)
}

export default RoleDetails
