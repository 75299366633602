import { memo, useMemo, useState } from 'react'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { GET_COURSE_LIST_MASTER } from 'src/Redux/actions/masterdata/user'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { getDefaultCourseListPayload } from 'src/Services/constant/defaultPayload'

const Details = (props) => {
    const { detailSave, detailsData, setDetailsData, educationData, courseList } = props
    const [checkvalidation, setCheckvalidation] = useState(false)
    const [course, setCourse] = useState({})
    const [searchTerm, setSearchTerm] = useState('')

    useMemo(() => {
        if (course?.coursemaster) {
            setSearchTerm(course?.coursemaster)
        }
    }, [course])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setCheckvalidation(true)
        let item = {
            ...detailsData,
            datcoursemasterid: course?.datcoursemasterid
        };
        let isValidated = CheckObjectValidation(detailsData, ['notes', 'datspecialisationid', 'datcoursemasterid',])
        if (isValidated?.isvalid) {
            let payload = {
                datspecialisationjson: [item],
            }
            detailSave(payload)
        }
    }

    const handleChangeInput = (name, value) => {
        setCheckvalidation(false)
        setDetailsData({ ...detailsData, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label htmlFor="specialisation" className="block font-[500] mb-2">
                        Specialization
                        <span className="text-red-300"> *</span>
                    </label>
                    <input
                        type="text"
                        title="specialisation"
                        id="specialisation"
                        className="block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 "
                        placeholder="Enter specialisation Name"
                        required=""
                        onChange={(e) => {
                            handleChangeInput('specialisation', e.target.value)
                        }}
                    />
                    {!detailsData?.specialisation && checkvalidation && (
                        <small className="text-red-400"> specialisation is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="dateducationmasterid" className="block font-[500] mb-2">
                        Education
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomhtmlSelect
                        options={educationData || []}
                        value={detailsData?.dateducationmasterid || ''}
                        noDataLabel="No education master"
                        name="dateducationmasterid"
                        labelkey="educationmaster"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleChangeInput}
                        id="dateducationmasterid"
                    />
                    {!detailsData?.dateducationmasterid && checkvalidation && (
                        <small className="text-red-400">Education is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="datcoursemasterid" className="block font-[500] mb-2">
                        Course
                        <span className="text-red-300"> *</span>
                    </label>
                    <CustomAutoSearch
                        searchTerm={searchTerm}
                        API_CALL_FOR_SEARCH={GET_COURSE_LIST_MASTER}
                        searchPayload={{ ...getDefaultCourseListPayload(), searchname: searchTerm }}
                        setSearchTerm={setSearchTerm}
                        options={courseList}
                        idkey="datcoursemasterid"
                        optionlabelKey="coursemaster"
                        getSelectedOption={setCourse}
                        placeholder="Enter Course Name"
                        className="block w-full p-2 text-gray-600 border border-gray-200 rounded-lg bg-gray-50 focus:ring-primary-600 focus:border-primary-600"
                    />
                    {!course?.datcoursemasterid && checkvalidation && (
                        <small className="text-red-400">Course is required!</small>
                    )}
                </div>
            </div>
            <div className="fixed bottom-0 mt-2 right-6">
                <div className="flex items-center justify-end gap-2">
                    <PrimaryButton className="px-8 py-2 mb-4 text-white rounded-lg bg-primary" onClick={handleSubmit}>
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default memo(Details)
