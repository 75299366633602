import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TimesheetExceptionReportsFilter from 'src/Filter/reports/timsheet/exceptionReports'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import {
    GET_REPORT_MASTER_LIST,
    GET_TIMESHEET_REPORT_FOR_EXCEPTION,
} from 'src/Redux/actions/reports'
import { getTimesheetExceptionReportPayload } from 'src/Services/constant/defaultPayload'
import ExceptionTimesheetReport from './table'

const ExceptionReports = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getTimesheetExceptionReportPayload())
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    let { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    let { reportMasterList } = useSelector((state) => state?.WorkforceReport)
    const [project, setProject] = useState([])
    const projectIds = projectsOfM?.map((project) => project.projectid)
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    const projectList = async () => {
        let items = await dispatch(GET_TIMESHEET_PROJECTS_M())
        const pId = items?.map((project) => project?.projectid)
        if (pId) {
            let payload = {
                ...filters,
                comuserid: 0,
                projectid: `{${projectIds?.join(',')}}`,
                weekstartdate: '',
                weekenddate: '',
            }
            await dispatch(GET_TIMESHEET_REPORT_FOR_EXCEPTION(payload))
            setLoader(false)
            setFilters(payload)
        }
    }

    useEffect(() => {
        projectList()
        // eslint-disable-next-line
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 text-sm bg-white rounded-md shadow">
                <TimesheetExceptionReportsFilter
                    projectIds={projectIds}
                    filters={filters}
                    setFilters={setFilters}
                    project={project}
                    setLoader={setLoader}
                    setProject={setProject}
                    reportId={reportId}
                    setReportId={setReportId}
                    page={page}
                    limit={limit}
                />
            </div>
            <ExceptionTimesheetReport
                loader={loader}
                setLoader={setLoader}
                projectIds={projectIds}
                filters={filters}
                project={project}
                reportId={reportId}
                reportMasterList={reportMasterList}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default ExceptionReports
