import TenantManagementList from './createTenant/list';

const TenantManagment = () => {
  return (
    <div className='p-3 text-sm'>
      <TenantManagementList />
    </div>
  );
};

export default TenantManagment;
