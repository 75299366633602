import { useDispatch, useSelector } from 'react-redux'
import { GET_ALLOCATION_STATUS } from 'src/Redux/actions/workforce/eprofile'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const AllocationDetails = () => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector(state => state?.EmployeeProfile)
	const { allocationData } = EmployeeProfile
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const userId = searchParams.get('userid')
	const tenid = searchParams.get('tenid')

	useEffect(() => {
		dispatch(GET_ALLOCATION_STATUS({ comuserid: +userId, Tenantid: +tenid }))
	}, [dispatch, tenid, userId])

	return (
		<div className='text-sm min-h-[24vh]'>
			<div className='flex items-center justify-start space-x-2'>
				<p className='py-[3px] flex justify-start items-center'>
					<p>Project Name: </p>
					<p className='ml-1 text-gray-500'>
						{allocationData?.allocatedprojects?.[0].Projectname || 'N/A'}
					</p>
				</p>
			</div>
			<div className='flex items-center justify-start space-x-2'>
				<p className='py-[3px] flex justify-start items-center'>
					<p>Role ( Duration ) : </p>
					{allocationData?.allocatedprojects?.[0].projectrole && (
						<p className='ml-1 text-gray-500'>
							{allocationData?.allocatedprojects?.[0].projectrole} (
							{moment(allocationData?.allocatedprojects?.[0].StartDate).format(
								'DD-MM-YYYY',
							)}
							{' - '}
							{moment(allocationData?.allocatedprojects?.[0].EndDate).format(
								'DD-MM-YYYY',
							)}
							)
						</p>
					)}
				</p>
			</div>
			<div className='flex items-center justify-start space-x-2'>
				<p className='py-[3px] flex justify-start items-center'>
					<p>Project Manager</p>
					<p className='ml-1 text-gray-500'>
						{allocationData?.allocatedprojects?.[0].ProjectManagerName}{' '}
						{allocationData?.allocatedprojects?.[0].ProjectManagerEmpCode}
					</p>
				</p>
			</div>
		</div>
	)
}

export default AllocationDetails
