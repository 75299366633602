import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import GetUserFromAdmin from 'src/Components/GetUserFromAdmin'
import SelectMonth from 'src/Components/SelectMonth'
import {
    getDefaultLeavePayload,
    getLeaveTypePayload,
} from 'src/Pages/app/Financials/leaves/defaultPayload'
import {
    GET_LEAVETYPE_MASTER,
    GET_USER_LEAVE_DATA,
} from 'src/Redux/actions/projects/financials'

const FinancialsFilterLeave = ({
    cols = 'grid-cols-6',
    filters,
    setFilters,
    user,
    setUser,
}) => {
    const {leaveTypeList} = useSelector((state) => state?.ProjectFinancial)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GET_LEAVETYPE_MASTER(getLeaveTypePayload()))
    }, [dispatch])

    useEffect(() => {
        setFilters({ ...filters, comuserid: user?.comuserid || 0 })
        // eslint-disable-next-line
    }, [user, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = () => {
        setFilters(getDefaultLeavePayload())
        setUser({})
        let payload = {
            ...getDefaultLeavePayload(),
            comuserid: 0,
            leavetypeid: 0,
            /*  limit: limit,
            page: page,
            sorttype: sortType?.id, */
        }
        dispatch(GET_USER_LEAVE_DATA(payload))
    }

    const applyFilters = () => {
        dispatch(
            GET_USER_LEAVE_DATA({
                ...filters,
            }),
        )
    }

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        let m = month?.length === 1 ? `0${month}` : month
        const parsedMonth = parseInt(m, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        setFilters({
            ...filters,
            [name]: Number(`${parsedYear}${m}`),
            Monthid: validMonthid,
            Yearid: validYearid,
        })
    }

    return (
        <div className={`grid ${cols} gap-4 text-sm p-2`}>
            <div className="mt-1 bg-gray-50">
                <p className="font-[500]">Select User</p>
                <GetUserFromAdmin selected={user} setSelected={setUser} />
            </div>
            <div>
                <p className=" font-[500]">Select Month/year</p>
                <div className=" bg-gray-50">
                    <SelectMonth
                        className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                        onChange={handleMonthChange}
                        name="yearmonth"
                        year={filters?.Yearid}
                        month={
                            filters?.Monthid?.length === 1
                                ? `0${filters?.Monthid}`
                                : filters?.Monthid
                        }
                    />
                </div>
            </div>
            <div>
                <p className="font-[500]">Select Leave Type</p>
                <CustomhtmlSelect
                    options={leaveTypeList || []}
                    value={filters?.leavetypeid || ''}
                    noDataLabel="No leave type"
                    name="leavetypeid"
                    labelkey="leavetypename"
                    className="w-full p-2 mt-1 border rounded-md bg-gray-50"
                    onChange={handleSelectChange}
                    id="leavetypeid"
                />
            </div>
            <div></div>
            <div></div>
            <div className="flex justify-end mt-0">
                <div className="w-40">
                    <h1 className="mb-1 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialsFilterLeave
