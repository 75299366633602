import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { GET_EMPLOYEE_DETAILS } from 'src/Redux/actions/workforce/talent'
import { getDeafultTalentPayload } from 'src/Services/constant/defaultPayload'
import SelectLocation from './selectLocation'

const TalentFilter = ({ cols = 'grid-cols-6', filters, setFilters, empStatusList, userTypeId }) => {
    const dispatch = useDispatch()
    const { departmentlist, designationlist, workertypelist, workforcelist, allocationstatuslist, companylist } =
        useSelector((state) => state.EmployeeDetails)
    const { userSkillList } = useSelector((state) => state?.EmployeeProfile)
    const { statusListByType } = useSelector((state) => state.Projects)
    const navigate = useNavigate()
    const [location, setLocation] = useState({})
    const [reset, setReset] = useState(false)
    const params = new URLSearchParams(window.location.search)
    const datmenuid = params.get('datmenuid')
    const AppliedFilters = params.get('AppliedFilters')

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            locationid: location?.comcityid || 0,
        }))
    }, [location, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }

    const handleExperience = (e) => {
        let { name, value } = e.target
        setFilters({ ...filters, [name]: +value })
    }

    const clearFilters = () => {
        navigate('/app/workforce/talent')
        let defautlPayload = { ...getDeafultTalentPayload(), empstatus: `{${empStatusList.join(',')}}`, usertypeid: userTypeId }
        dispatch(GET_EMPLOYEE_DETAILS(defautlPayload))
        setFilters(defautlPayload)
        setReset(true)
    }
    const applyFilters = () => {
        let data = {
            ...filters,
            cityname: location?.cityname,
        }
        let search = `/app/workforce/talent?datmenuid=${datmenuid}&AppliedFilters=${JSON.stringify(data)}`
        navigate(search)
        dispatch(GET_EMPLOYEE_DETAILS(data))
    }

    useEffect(() => {
        let applied = JSON.parse(AppliedFilters)
        if (applied) {
            setLocation({ cityname: applied?.cityname, comcityid: applied?.cityid })
            setFilters(applied)
        }
        // eslint-disable-next-line
    }, [AppliedFilters])

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols} gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Company</h1>
                    <CustomhtmlSelect
                        options={companylist || []}
                        value={filters?.companyid}
                        noDataLabel="No Company"
                        name="companyid"
                        labelkey="companyname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="companyid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Emp Type</h1>
                    <CustomhtmlSelect
                        options={workertypelist || []}
                        value={filters?.workertypeid}
                        noDataLabel="No Projects"
                        name="workertypeid"
                        labelkey="workertype"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="workertypeid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Workforce Group</h1>
                    <CustomhtmlSelect
                        options={workforcelist || []}
                        value={filters?.workforcegroup}
                        noDataLabel="No Workforce found"
                        name="workforcegroup"
                        labelkey="workforcegroup"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="wfgid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Emp Status</h1>
                    <CustomhtmlSelect
                        options={statusListByType || []}
                        value={filters?.empstatus}
                        noDataLabel="No Employee Status found"
                        name="empstatus"
                        labelkey="statusname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="statusid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Allocation Status</h1>
                    <CustomhtmlSelect
                        options={allocationstatuslist || []}
                        value={filters?.allocationstatusid}
                        noDataLabel="No Allocation Status"
                        name="allocationstatusid"
                        labelkey="statusname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="statusid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Designation</h1>
                    <CustomhtmlSelect
                        options={designationlist || []}
                        value={filters?.designationmasterid}
                        noDataLabel="No Designation"
                        name="designationmasterid"
                        labelkey="designation"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="designationmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Department</h1>
                    <CustomhtmlSelect
                        options={departmentlist || []}
                        value={filters?.departmentid}
                        noDataLabel="No Department"
                        name="departmentid"
                        labelkey="department"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="departmentid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Total Experience (Years)</h1>
                    <div className="flex items-center justify-between w-full p-2 mt-1 bg-white border rounded-md ">
                        <div className="flex items-center justify-start">
                            <input
                                value={filters?.mintotalexperience || ''}
                                onChange={handleExperience}
                                name="mintotalexperience"
                                placeholder="Min "
                                type="number"
                                className="w-10 capitalize focus:outline-none hover:outline-none"
                            />
                        </div>
                        <span>To</span>
                        <div className="flex items-center justify-start">
                            <input
                                value={filters?.maxtotalexperience || ''}
                                onChange={handleExperience}
                                name="maxtotalexperience"
                                placeholder="Max"
                                type="number"
                                className="w-10 capitalize focus:outline-none hover:outline-none"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Primary Skill</h1>
                    <CustomhtmlSelect
                        options={userSkillList || []}
                        value={filters?.skillmasterid}
                        noDataLabel="No Projects"
                        name="skillmasterid"
                        labelkey="skill"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="skillmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Secondary Skill</h1>
                    <CustomhtmlSelect
                        options={userSkillList || []}
                        value={filters?.secondaryskillid}
                        noDataLabel="No Skill"
                        name="secondaryskillid"
                        labelkey="skill"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="skillmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]"> Location</h1>
                    <SelectLocation location={location} setLocation={setLocation} reset={reset} />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                                setReset(false)
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TalentFilter
