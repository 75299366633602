import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { useSelector, useDispatch } from 'react-redux';
import SkillGroup from './skillGroup';
import Technology from './technology';
import Location from './location';
import Band from './band';
import Currency from './currency';
import { CostRatesItem } from '../../default';
import { useEffect, useState } from 'react';
import { GET_COST_RATES } from 'src/Redux/actions/masterdata/user';
import { getDefaultCostRates } from 'src/Services/constant/masterdatapayload';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';
import SelectOption from 'src/Components/SelectOption';
import IconButton from 'src/Components/Buttons/IconButton';
import ScrollToBottom from 'react-scroll-to-bottom';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

const isValidatedField = (checkvalidation, index, keyname) => {
  return (
    checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
  );
};

const getActiveRecordOnly = (data) => {
  if (data?.length > 0) {
    return data?.filter((it) => it?.isactiveflag === true);
  }
  return []
};

const CostrateDetails = ({
  addOrEdit,
  canEdit,
  handleSubmit,
  formData,
  setFormData,
  orgcostrateid,
  setCanEdit,
  companyid,
  startEndDate
}) => {
  const dispatch = useDispatch();
  const { bandList } = useSelector((state) => state?.EmployeeDetails);
  const { technologyGroup, countryList } = useSelector((state) => state?.UserManagementList);
  const { skillList } = useSelector((state) => state?.EmployeeProfile);
  const { currencyList } = useSelector((state) => state?.Projects);
  const { costratesList } = useSelector((state) => state?.UserManagementList);
  const [checkvalidation, setCheckvalidation] = useState(null);

  useEffect(() => {
    if (orgcostrateid) {
      let data = {
        ...getDefaultCostRates(),
        Searchname: '',
        Companyid: companyid,
        Startdate: startEndDate?.startdate,
        Enddate: startEndDate?.enddate
      }
      dispatch(GET_COST_RATES(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        if (costratesList?.length > 0 && addOrEdit === 'Edit') {
          const filteredCostrateData = costratesList?.filter(
            (item) => item.orgcostrateid === orgcostrateid
          );
          let rmUnwanted = filteredCostrateData?.map((it) => {
            const {
              cityname,
              currency,
              companyname,
              currencycode,
              designation,
              skill,
              technology,
              username,
              unitname,
              bandname,
              totalcount,
              uId,
              modifiedtimestamp,
              createdtimestamp,
              ...updatedItem
            } = it;
            updatedItem.modifiedcomuserid = Number(
              localStorage.getItem('comuserid') || 0
            );
            return updatedItem;
          });

          if (isMounted) {
            setFormData(rmUnwanted);
          }
        } else {
          if (isMounted) {
            setFormData([{ ...CostRatesItem(), companyid, ...startEndDate }]);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costratesList]);

  const handleAdd = () => {
    setFormData([...formData, { ...CostRatesItem(), companyid, ...startEndDate }]);
  };

  const handleDelete = (selected) => {
    if (formData?.length > 1) {
      if (selected?.createdtimestamp === undefined) {
        let rm = formData?.filter(item => item?.uId !== selected?.uId)
        setFormData(rm)
      } else {
        let items = formData?.map((item) => {
          if (item?.uId === selected?.uId) {
            item['isactiveflag'] = false
          }
          return item
        })
        setFormData(items)
      }
    }
  };



  const SelectHandleChange = (e, uId) => {
    let { name, value } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        item[name] = value;
      }
      return item;
    });
    setFormData(afterChange);
  };


  const saveCostrateInfo = () => {
    let isValidated = CheckListObjectValidation(formData, [
      // 'startdate',
      'unit',
      // 'enddate',
      'orgcostrateid',
      'designationid'
    ]);
    if (isValidated?.isvalid) {
      const formDataArray = Array.from(formData);
      handleSubmit(orgcostrateid, formDataArray);
    } else {
      setCheckvalidation(isValidated);
    }
  };

  return (
    <div className='my-2 text-sm'>
      <div className='w-full h-screen'>
        <ScrollToBottom className="h-4/5">
          <table className="w-full text-sm relative overflow-scroll">
            <thead className='capitalize bg-gray-50'>
              <tr className='text-left capitalize bg-gray-50'>
                <th className='border font-[500] p-1'>Band<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Skill Area<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Tech Group<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Country<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Rate<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Currency<span className="text-red-300">*</span></th>
                <th className='border font-[500] p-1'>Unit<span className="text-red-300">*</span></th>
                {(!canEdit && addOrEdit === 'Add') && (
                  <th className='border font-[500] py-2 px-2'>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {getActiveRecordOnly(formData)?.map((item, i) => {
                return (
                  <tr key={item?.uId + item?.orgcostrateid} className='text-left px-0.5'>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'bandid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Band
                        disabled={canEdit}
                        value={item?.bandmasterid || item?.bandid}
                        rowId={item?.uId}
                        id={item?.uId}
                        handleChange={SelectHandleChange}
                        options={bandList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'skillareamasterid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <SkillGroup
                        disabled={canEdit}
                        value={item?.skillareamasterid}
                        rowId={item?.uId}
                        id={item?.uId}
                        handleChange={SelectHandleChange}
                        options={skillList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'techgroupid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Technology
                        disabled={canEdit}
                        value={item?.techgroupid}
                        rowId={item?.uId}
                        id={item?.uId}
                        handleChange={SelectHandleChange}
                        options={technologyGroup}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'countryid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Location
                        value={item?.countryid}
                        disabled={canEdit}
                        rowId={item?.uId}
                        handleChange={SelectHandleChange}
                        options={countryList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'cost')
                        ? 'border-2 border-red-400 '
                        : 'border w-8'
                        } px-0.5`}
                    >
                      <input
                        disabled={canEdit}
                        className='text-gray-600 w-14 border-none rounded-md border focus:outline-none hover:outline-none'
                        name='cost'
                        type='number'
                        id={item?.uId}
                        value={item?.cost || ''}
                        onChange={(e) => SelectHandleChange(e, item?.uId)} />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'comcurrencyid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Currency
                        disabled={canEdit}
                        value={item?.comcurrencyid}
                        rowId={item?.uId}
                        id={item?.uId}
                        handleChange={SelectHandleChange}
                        options={currencyList}
                      />
                    </td>
                    <td className='border w-20 p-1'>
                      <SelectOption
                        disabled={canEdit}
                        name='unit'
                        id={item?.uId}
                        value={item?.unit || ''}
                        onChange={SelectHandleChange}
                        options={[
                          { value: 1, label: 'Monthly' },
                          { value: 2, label: 'Weekly' },
                          { value: 3, label: 'Daily' },
                          { value: 4, label: 'Hourly' }
                        ]}
                      />
                    </td>

                    {(!canEdit && addOrEdit === 'Add') && (
                      <td className='border'>
                        <div className='flex items-center justify-start w-full px-2'>
                          {getActiveRecordOnly(formData)?.length !== 1 && (
                            <IconButton
                              title='Delete'
                              onClick={() => handleDelete(item)}
                              className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                            >
                              <MinusIcon className="w-3" />
                            </IconButton>
                          )}
                          <IconButton
                            title='Add'
                            disabled={
                              getActiveRecordOnly(formData)?.length >= 10
                            }
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScrollToBottom>
        <div className='fixed bottom-0 right-6 mt-2'>
          {!canEdit && (
            <div className='fixed bottom-0 right-6 mt-2'>
              <div className='gap-1.5 flex justify-end items-center'>
                {addOrEdit === 'Edit' && <button
                  // disabled={!canEdit}
                  onClick={() => setCanEdit(false)}
                  type=''
                  className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                >
                  Cancel
                </button>}
                <PrimaryButton
                  disabled={canEdit}
                  className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                  onClick={saveCostrateInfo}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CostrateDetails;
