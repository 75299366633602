const initialState = {
    workmodelist: [],
    demandList: null,
    demandData: [],
    skillListDemand: [],
    skillAreaListDemand: [],
    domainListDemand: [],
    techListDemand: [],
    demandSkills: [],
    demandRole: [],
    demandMatch: [],
    getStaffStatusTypeM: [],
    demandStaff: [],
    getStaffStatusTypeS: [],
    demandMatchProfile: [],
    demandListTalent: [],
}

const DemandDetailsList = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STAFF_STATUS_TYPE_M':
            return { ...state, getStaffStatusTypeM: action.data }
        case 'GET_STAFF_STATUS_TYPE_S':
            return { ...state, getStaffStatusTypeS: action.data }
        case 'GET_WORK_MODE_LIST':
            return { ...state, workmodelist: action.data }
        case 'GET_DEMAND_MASTER':
            return { ...state, demandData: action.data }
        case 'GET_DEMAND_LIST_MASTER':
            return { ...state, demandList: action.data }
        case 'GET_SKILL_LIST_MASTER':
            return { ...state, skillListDemand: action.data }
        case 'GET_DEMAND_SKILLAREA_MASTER':
            return { ...state, skillAreaListDemand: action.data }
        case 'GET_DEMAND_INSUDTRY_MASTER':
            return { ...state, domainListDemand: action.data }
        case 'GET_DEMAND_TECHNOLOGY_MASTER':
            return { ...state, techListDemand: action.data }
        case 'GET_DEMAND_ROLE_MASTER':
            return { ...state, demandRole: action.data }
        case 'GET_DEMAND_MATCH':
            return { ...state, demandMatch: action.data }
        case 'GET_PROJECT_STAFFING_LIST':
            return { ...state, demandStaff: action.data }
        case 'GET_DEMAND_MATCH_DETAILS':
            return { ...state, demandMatchProfile: action.data }
        case 'GET_DEMAND_LIST_FOR_TALENT':
            return { ...state, demandListTalent: action.data }
        default:
            return state
    }
}

export default DemandDetailsList
