import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo,useState } from 'react'
import { useSelector } from 'react-redux'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'


const StateDetails = props => {
	const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props
	const { countryList } = useSelector(state => state.UserManagementList)
	const [checkvalidation, setCheckvalidation] = useState(false);

	const handleSubmit = () => {
		setCheckvalidation(true);

		let item = {
			comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
			statename: detailsData?.statename,
			statecode: detailsData?.statecode,
			comstateid: detailsData?.comstateid,
			comcountryid: detailsData?.comcountryid,
			isactiveflag: detailsData?.isactiveflag,
			createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
			notes: detailsData?.notes,
		}

		let isValidated = CheckObjectValidation(detailsData, [
			'notes',
			'comstateid',
			'modifiedtimestamp',
			'modifiedcomuserid'
		])

		if (isValidated?.isvalid) {
			let payload = {
				statejson: [item],
			}
			detailSave(payload)
		}
	}

	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='statename' className='block font-[500] mb-2'>
						State Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						disabled={canEdit}
						type='text'
						onChange={(e) => {
							if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
								handleChangeInput('statename', e.target.value)
							}
						}}
						title='statename'
						id='statename'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='statename'
						required=''
						value={detailsData?.statename || ''}
					/>
					{!detailsData?.statename && checkvalidation && (
						<small className='text-red-400'>State Name is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='statecode' className='block font-[500] mb-2'>
						State Code
					</label>
					<input
						disabled
						type='text'
						title='statecode'
						id='statecode'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='statecode'
						required=''
						onChange={e => handleChangeInput('statecode', e.target.value)}
						value={detailsData?.statecode || ''}
					/>
				</div>
				<div>
					<label htmlFor='comcountryid' className='block font-[500] mb-2'>
						Country
						<span className="text-red-300"> *</span>
					</label>
					<CustomhtmlSelect
						disabled={canEdit}
						options={countryList || []}
						value={detailsData?.comcountryid || ''}
						noDataLabel='No country'
						name='comcountryid'
						labelkey='countryname'
						className='border rounded bg-gray-50 py-2'
						onChange={handleChangeInput}
						id='comcountryid'
					/>
					{!detailsData?.comcountryid && checkvalidation && (
						<small className='text-red-400'>Country is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				{!canEdit && (
					<div className='fixed bottom-0 right-6 mt-2'>
						<div className='gap-2 flex justify-end items-center'>
							<button
								onClick={() => setCanEdit(false)}
								type=''
								className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
							>
								Cancel
							</button>
							<PrimaryButton
								disabled={canEdit}
								className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
								onClick={handleSubmit}
							>
								Save
							</PrimaryButton>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(StateDetails)
