export const insertUserDocumentPayload = (documenttypeid = 0)=>{
  return  {
    userdocumentattachmentsid: null,
    Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    documenttype: 0,
    documenttypeid,
    comuserid: Number(localStorage.getItem('comuserid') || 0),
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    deletedcomuserid: null,
    createdtimestamp: "",
    modifiedtimestamp: "",
    deletedtimestamp: "",
    versionno: "",
    notes: "",
    filename: "",
    filepath: "",
    fileformat: "",
    isdeletedflag: false,
    file: ""
  }
}


export const insertUserCertificatePayload = (documenttypeid = 0)=>{
  return  {
    uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    userdocumentattachmentsid: null,
    Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    documenttype: 0,
    documenttypeid,
    comuserid: Number(localStorage.getItem('comuserid') || 0),
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    deletedcomuserid: null,
    createdtimestamp: "",
    modifiedtimestamp: "",
    deletedtimestamp: "",
    versionno: "",
    notes: "",
    filename: "",
    filepath: "",
    fileformat: "",
    isdeletedflag: false,
    file: ""
  }
}