export const detailItem = () => {
  return {
    companyid: null,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    companyname: '',
    parentcompanyname: null,
    shortname: null,
    companycode: "",
    address_street: null,
    address_pobox: null,
    address3: null,
    pincode: null,
    phoneno: null,
    comcityid: 0,
    comstateid: null,
    comcountryid: null,
    region: null,
    mobile1: null,
    fax: null,
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    isdeleteflag: null,
    deletedtimestamp: null,
    deletedcomuserid: null,
    defaultcomcurrencyid: 1,
    notes: null,
    incorporationdate: ""

  };
};
export const detailNotRequired = [
  'companyid',
  'deletedcomuserid',
  'deletedtimestamp',
  'isdeleteflag',
  'notes',
  'parentcompanyname',
  'addressline1',
  'shortname',
  'companycode',
  'modifieduser',
  'address_pobox',
  'region',
  'mobile1',
  'fax',
  'pincode',
  'comstateid',
  'comcountryid',
];

export const weekdaysItem = () => {
  return {
    uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    companyweekdaysid: null,
    companyid: 0,
    sunholiday: true,
    monholiday: true,
    tueholiday: true,
    wedholiday: true,
    thuholiday: true,
    friholiday: true,
    satholiday: true,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    modifiedcomuserid: JSON.parse(localStorage.getItem('modifiedcomuserid')),
    notes: "",
    noofhoursperday: 0

  };
};

