import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { TemplateItem } from './default';
import CreateTemplate from './createTemplate';
import EditTemplate from './editTemplate';
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { GET_TEMPLATE_MASTER } from 'src/Redux/actions/configuration';
import { getTemplateCategoryPayload } from 'src/Services/constant/masterdatapayload';
import { getMasterCompanyIdData } from 'src/Services/constant/defaultPayload';
import { basicShortList } from 'src/Services/constant/staticValues';
// CODE FOR FUTURE USE import CustomAutoSearch from 'src/Components/CustomAutoSearch';


const cols = ['Company', 'Time Template', 'Template Type', 'Default', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const templateType = [
  { label: 'Project', templatetype: "P" },
  { label: 'Generic', templatetype: 'G' },
];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const TemplateMasterList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [company, setCompany] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [templateId, setTemplateId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(TemplateItem());
  const [templatetypefilter, setTemplatetypefilter] = useState({});
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });

  const dispatch = useDispatch();
  const { companylist } = useSelector((state) => state.EmployeeDetails);
  const { templateList, PermissionsOfConfiguration } = useSelector((state) => state.ConfigurationList);

  //  CODE FOR FUTURE USE const [searchTerm, setSearchTerm] = useState('');
  //  CODE FOR FUTURE USE const [template, setTemplate] = useState({}); 
  //  CODE FOR FUTURE USE 
  // useMemo(() => {
  //   if (template?.templatename) {
  //     setSearchTerm(template?.templatename);
  //   }
  // }, [template]);

  useEffect(() => {
    dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
    // eslint-disable-next-line
  }, [])

  async function fetchData() {
    setLoading(true); // set loading state to true when data is being fetched
    let res = await dispatch(GET_TEMPLATE_MASTER(getTemplateCategoryPayload(1, 10, 3, 'Active')));
    if (res !== null) {
      setDetailsData(res.data);
    }
    setLoading(false); // set loading state to false when data has been fetched
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, [dispatch]);

  const handleTemplateClick = (bu) => {
    setDetailsData(bu);
    setTemplateId(bu?.timetemplatemasterid);
    setIsOpen(true);
  };

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_TEMPLATE_MASTER({ ...getTemplateCategoryPayload(), typefilter: templatetypefilter?.label, sorttype: item.id, status: status?.label, limit: limit, companyid: company?.companyid }));
    if (res !== null) {
      setDetailsData(res);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_TEMPLATE_MASTER({ ...getTemplateCategoryPayload(), status: item.label, companyid: company?.companyid, typefilter: templatetypefilter?.label }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handleTemplateType = async (item) => {
    setTemplatetypefilter(item);
    let res = await dispatch(GET_TEMPLATE_MASTER({ ...getTemplateCategoryPayload(), limit: limit, page: page, sorttype: sortType?.id, typefilter: item?.label, status: status.label, companyid: company?.companyid }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };
  const handleCompany = async (item) => {
    setCompany(item);
    let res = await dispatch(GET_TEMPLATE_MASTER({
      ...getTemplateCategoryPayload(), limit: limit, page: page, sorttype: sortType?.id, status: status?.label, companyid: item?.companyid, typefilter: templatetypefilter?.label
    }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };


  const handlePagination = async (page) => {
    dispatch(
      GET_TEMPLATE_MASTER({ ...getTemplateCategoryPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, companyid: company?.companyi, typefilter: templatetypefilter?.label }));
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(GET_TEMPLATE_MASTER({ ...getTemplateCategoryPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, companyid: company?.companyid, typefilter: templatetypefilter?.label }));
    if (res !== null) {
      setDetailsData(res?.data);
    }
  };



  return (
    <div className='mt-4 p-1 py-1 w-full h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Time Templates ({templateList?.length > 0 ? templateList[0]?.totalcount : templateList?.length}){' '}
          </h1>
          {/*
           // CODE FOR FUTURE USE 
           <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_TEMPLATE_MASTER}
              searchPayload={{ ...getTemplateCategoryPayload(), searchname: searchTerm, }}
              setSearchTerm={setSearchTerm}
              options={templateList}
              idkey='templatename'
              optionlabelKey='templatename'
              getSelectedOption={setTemplate}
              placeholder='Enter Time Template Name'
              className='p-2'
            />
          </div> */}
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              total={templateList?.length > 0 ? templateList[0]?.totalcount : templateList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-40 '>
            <SelectInput
              options={companylist || []}
              keyname='companyname'
              selected={company}
              setSelected={handleCompany}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-32'>
            <SelectInput
              options={templateType || []}
              keyname='label'
              selected={templatetypefilter}
              setSelected={handleTemplateType}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
        </div>
        {PermissionsOfConfiguration?.includes('Add/Edit Config') ? <CreateTemplate /> : ""}
      </div>
      {loading ? (<div className='flex items-center justify-center w-full h-1/2'>
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>) : (
        <div className=' h-[calc(60%)]  overflow-scroll'>
          <table className='text-sm w-full relative'>
            <thead>
              <tr className='text-left capitalize bg-gray-50'>
                {cols?.map((col) => {
                  return (
                    <th
                      key={col}
                      title={col}
                      scope='col'
                      className='font-[500] p-2 border text-start'
                    >
                      {col}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {templateList?.map((item, i) => {
                let {
                  companyname,
                  templatetype,
                  templatename,
                  isdefault,
                  isactiveflag,
                  createduser,
                  createdtimestamp,
                  timetemplatemasterid,
                } = item;
                return (
                  <tr
                    onClick={() => handleTemplateClick(item)}
                    key={timetemplatemasterid}
                    className='cursor-pointer text-left'>
                    <td className='border px-2 py-1.5'><div>{companyname}</div></td>
                    <td className='border px-2 py-1.5'><div>{templatename}</div></td>
                    <td className='border px-2'><div>{templatetype === 'P' ? 'Project' : 'Generic'}</div></td>
                    <td className='border px-2'><div>{isdefault === true ? 'True' : 'False'}</div></td>
                    <td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                    <td className='border px-2'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                    <td className='border px-2'><div>{createduser}</div></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>)}
      <EditTemplate
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        timetemplatemasterid={templateId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfConfiguration}
      />
    </div>
  );
};

export default memo(TemplateMasterList);
