export const CourseItem = () => {
    return {
        datcoursemasterid: null,
        dateducationmasterid: null,
        coursemaster: "",
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        isactiveflag: true,
        createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
        notes: "",

    }
}

export const SpecialisationItem = () => {
    return {
        datspecialisationid: null,
        datcoursemasterid: null,
        dateducationmasterid: null,
        specialisation: "",
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        activeflag: true,
        createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
        notes: "",
    }
}

export const QualificationItem = () => {
    return {

        dateducationmasterid: null,
        educationmaster: "",
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        isactiveflag: true,
        createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
        notes: "",
    }
}

