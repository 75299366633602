import Financials from './TabsComponents/financials'
import Staffing from './TabsComponents/staffing'
import Timesheet from './TabsComponents/timesheet'
import Phases from './TabsComponents/phases'
import Milestone from './TabsComponents/milestones'
import EditProject from '../editProject'
import { useMemo } from 'react'
import SummaryCom from './TabsComponents/summary'
import { useLocation } from 'react-router-dom'
import TitleAndViewAll from './TabsComponents/titleAndViewAll'

const tabs = ['Execution', 'Financials', 'Planning', 'Milestones', 'Staffing', 'Effort']

const ActiveTab = ({ filterTab, setFilterTab, projectDetail, projectBudgetEstimation, thisPagePermissions }) => {
    const location = useLocation()

    const activeCom = tab => {
        switch (tab) {
            case 'Execution':
                return <SummaryCom projectDetail={projectDetail} />
            case 'Financials':
                return <Financials projectBudgetEstimation={projectBudgetEstimation} projectDetail={projectDetail} />
            case 'Staffing':
                return <Staffing projectDetail={projectDetail} />
            case 'Effort':
                return <Timesheet pId={projectDetail?.projectid} />
            case 'Planning':
                return <Phases projectDetail={projectDetail} />
            case 'Milestones':
                return <Milestone projectDetail={projectDetail} />
            default:
                break
        }
    }

    const getArrowButton = (tab) => {
        switch (tab) {
            case 'Planning':
                return <TitleAndViewAll
                    viewPath={`/app/projects/planning${location.search}&activetab=Phases`}
                    title='View Details'
                />

            case 'Milestones':
                return <TitleAndViewAll
                    viewPath={`/app/projects/planning${location.search}&activetab=Milestones`}
                    title='View Details'
                />
            case 'Staffing':
                return <TitleAndViewAll
                    viewPath={`/app/projects/staffing?projectid=${projectDetail?.projectid}`}
                    title='View Details'
                />
            case 'Effort':
                return <TitleAndViewAll viewPath='/app/projects/timesheet' title='View Details' />
            default:
                break
        }
    }

    const EditConditon = useMemo(() => {
        if (projectDetail?.statusname !== 'Closed' && projectDetail?.statusname !== "Completed") {
            return thisPagePermissions?.includes('Edit Project') ? <EditProject projectDetail={projectDetail} /> : ''
        }
        return <div className='h-8' />
        // eslint-disable-next-line 
    }, [projectDetail, thisPagePermissions])

    return (
        <div className='w-9/12 px-4 h-[calc(100%-20px)] overflow-y-scroll bg-white rounded-lg shadow-md'>
            <div className=' border-secondary border-b flex justify-between items-center'>
                <div className='py-2 space-x-3 text-sm flex justify-start items-center'>
                    {tabs?.map(item => {
                        return (
                            <span key={item} className='flex space-x-1 justify-start items-center'>
                                <button
                                    className={
                                        item === filterTab
                                            ? 'bg-secondary/10 text-secondary font-[500] p-1.5 rounded-md'
                                            : 'text-gray-500'
                                    }
                                    onClick={() => setFilterTab(item)}

                                >
                                    {item}
                                </button>
                                {item === filterTab ? getArrowButton(item) : ''}
                            </span>
                        )
                    })}
                </div>
                {EditConditon}
            </div>
            {activeCom(filterTab)}
        </div>
    )
}

export default ActiveTab
