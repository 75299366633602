import IconButton from 'src/Components/Buttons/IconButton';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import RoleLevel from './level';
import PrimarySkill from './primarySkill';
import Technology from './technology';
import Location from './Location';
import Currency from './currency';
import { RoleItem } from '../defaultRowAndData';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';
import { useEffect, useState } from 'react';
import { GET_ALL_ROLES_OF_A_CUSTOMER } from 'src/Redux/actions/masterdata/customer';
import { getRoleByCustomerId } from 'src/Services/constant/Customerpayload';
import Band from './band';
import { AlertError } from 'src/Services';
import ScrollToBottom from 'react-scroll-to-bottom';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

const isValidatedField = (checkvalidation, index, keyname) => {
  return (
    checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
  );
};
const getActiveRecordOnly = (data) => {
  if (data?.length > 0) {
    return data?.filter((it) => it?.isactiveflag === true);
  }
  return []
};

const RoleDetails = ({
  handleSubmit,
  formData,
  setFormData,
  customerid,
  canEdit,
  setCanEdit,
  detailsData,
}) => {
  const dispatch = useDispatch();
  const { bandList } = useSelector((state) => state?.EmployeeDetails);
  const { technologyGroup, countryList } = useSelector((state) => state?.UserManagementList);
  const { proficiencylist, skillList } = useSelector((state) => state?.EmployeeProfile);
  const { roleByCustomerId } = useSelector((state) => state?.masterDataCustomer);
  const { currencyList } = useSelector((state) => state?.Projects);
  const [checkvalidation, setcheckvalidation] = useState(null);

  useEffect(() => {
    dispatch(GET_ALL_ROLES_OF_A_CUSTOMER(getRoleByCustomerId(customerid)));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (roleByCustomerId?.length > 0) {
      let rmUnwanted = roleByCustomerId?.map((it) => {
        //  below fields are not allowed to add in update payload
        it['modifiedcomuserid'] = Number(Number(localStorage.getItem('comuserid') || 0));
        it['comcurrencyid'] = detailsData?.defaultcomcurrencyid;
        delete it['cityname'];
        delete it['currency'];
        delete it['customercontactpersonid'];
        delete it['createdtimestamp'];
        delete it['modifiedtimestamp'];
        delete it['locationid'];
        delete it['locationname'];
        delete it['technology'];
        delete it['levelname'];
        delete it['skill'];
        delete it['leveltype'];
        // delete it['rateunit'];
        return it;
      });
      // console.log(rmUnwanted)
      setFormData(rmUnwanted);
    } else {
      setFormData([RoleItem(detailsData?.defaultcomcurrencyid)]);
    }
    // eslint-disable-next-line
  }, [roleByCustomerId]);

  const selectBandMax = (e, uId) => {
    let { name, value, itemInfo } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        let minitem = bandList?.find(b => b?.bandmasterid === item['min_bandid'])
        if (itemInfo?.slno >= minitem?.slno || !item['min_bandid']) {
          item[name] = value;
        } else {
          AlertError('Max band must be greater than min band')
        }
      }
      return item;
    });
    setFormData(afterChange);
  };

  const selectBandMin = (e, uId) => {
    let { name, value, itemInfo } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        let maxitem = bandList?.find(b => b?.bandmasterid === item['max_bandid'])
        if (itemInfo?.slno <= maxitem?.slno || !item['max_bandid']) {
          item[name] = value;
        } else {
          AlertError('Min band must be smaller than max band')
        }
      }
      return item;
    });
    setFormData(afterChange);
  };


  const SelectHandleChange = (e, uId) => {
    let { name, value } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        item[name] = value;
      }
      return item;
    });
    setFormData(afterChange);
  };

  const handleAdd = () => {
    setFormData([...formData, RoleItem(detailsData?.defaultcomcurrencyid)]);
  };

  const handleDelete = (selected) => {
    if (formData?.length > 1) {
      if (!selected?.croleid) {
        let rm = formData?.filter((item) => item?.uId !== selected?.uId);
        setFormData(rm);
      } else {
        let items = formData.map((item) => {
          if (item?.uId === selected?.uId) {
            item['isactiveflag'] = false;
          }
          return item;
        });
        setFormData(items);
      }
    }
  };

  const saveRoleInfo = () => {
    let isValidated = CheckListObjectValidation(formData, [
      'notes',
      'locationid',
      'croleid',
      'isonsite',
      'RateUnit',
      'customerid',
      'techarea',
      'skillarea',
      'countryname',
      'max_band', 'min_band', 'deliveryroleid'
    ]);
    if (isValidated?.isvalid) {
      handleSubmit();
    } else {
      setcheckvalidation(isValidated);
    }
  };

  const handleCancel = () => {
    // customerid
    setCanEdit(false)
    let items = formData?.filter(item => item?.customerid)
    setFormData(items)
  }

  return (
    <div className='my-2 text-sm'>
      <ScrollToBottom className='h-[48vh]'>
        <table className='w-full relative text-left'>
          <thead className='capitalize bg-gray-50 h-[1vh]'>
            <tr className='p-2 text-left capitalize bg-gray-50'>
              <th className='border font-[500] p-1 '>
                Customer Role  <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Level <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Min band <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Max band <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Skill Area <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Tech Group <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Country <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Bill Rate <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Currency <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Unit <span className='text-red-300'>*</span>
              </th>
              {!canEdit && (
                <th className='border font-[500] py-2 px-2'>Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {getActiveRecordOnly(formData)?.map((item, i) => {
              return (
                <tr key={`${item?.uId}-${i}`} className='px-1 text-left'>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'rolename')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <input value={item?.rolename || ''}
                      name='rolename'
                      disabled={canEdit}
                      onChange={(e) => SelectHandleChange(e, item?.uId)}
                      className='w-full focus:outline-none ' placeholder='Customer' />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'levelid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <RoleLevel
                      disabled={canEdit}
                      value={item?.levelid}
                      rowId={item?.uId}
                      // className={` ${isValidatedField(checkvalidation, i, 'contactpersonname') ? 'border-2 border-red-400 ' : 'border'} px-1`}
                      handleChange={SelectHandleChange}
                      options={proficiencylist}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'min_bandid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <Band
                      name='min_bandid'
                      disabled={canEdit}
                      value={item?.min_bandid || item?.min_bandid}
                      rowId={item?.uId}
                      handleChange={selectBandMin}
                      options={bandList}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'max_bandid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <Band
                      name='max_bandid'
                      disabled={canEdit}
                      value={item?.max_bandid || item?.max_bandid}
                      rowId={item?.uId}
                      handleChange={selectBandMax}
                      options={bandList}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'skillareamasterid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <PrimarySkill
                      disabled={canEdit}
                      value={item?.skillareamasterid}
                      rowId={item?.uId}
                      handleChange={SelectHandleChange}
                      options={skillList}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'techgroupid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <Technology
                      disabled={canEdit}
                      value={item?.techgroupid}
                      rowId={item?.uId}
                      handleChange={SelectHandleChange}
                      options={technologyGroup}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'countryid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <Location
                      value={item?.countryid}
                      disabled={canEdit}
                      rowId={item?.uId}
                      handleChange={SelectHandleChange}
                      options={countryList}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'bill_hr')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <input
                      className='text-gray-600 border border-none rounded-md w-14 focus:outline-none hover:outline-none'
                      name='bill_hr'
                      type='number'
                      disabled={canEdit}
                      value={item?.bill_hr || ''}
                      onChange={(e) => SelectHandleChange(e, item?.uId)}
                    />
                  </td>
                  <td
                    className={` ${isValidatedField(checkvalidation, i, 'comcurrencyid')
                      ? 'border-2 border-red-400 '
                      : 'border'
                      } px-1`}
                  >
                    <Currency
                      disabled={true}
                      value={item?.comcurrencyid}
                      rowId={item?.uId}
                      handleChange={SelectHandleChange}
                      options={currencyList}
                    />
                  </td>
                  <td className='w-32 p-1 border'>
                    <select
                      name='rateunit'
                      disabled={canEdit}
                      value={item?.rateunit}
                      onChange={(e) => SelectHandleChange(e, item?.uId)}
                      className='block w-full p-1 text-sm outline-none disabled:text-black focus:ring-0 focus:outline-none focus:border-0'
                    >
                      <option value='Select'>Select</option>
                      <option value='1'>Monthly</option>
                      <option value='2'>Weekly</option>
                      <option value='3'>Daily</option>
                      <option value='4'>Hourly</option>
                    </select>
                  </td>
                  {!canEdit && (
                    <td className='border w-20 px-2 py-1'>
                      <div className="flex justify-start items-center space-x-1">
                        {getActiveRecordOnly(formData)?.length !== 1 && (
                          <IconButton
                            title='Delete'
                            onClick={() => handleDelete(item)}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <MinusIcon className="w-3" />
                          </IconButton>
                        )}
                        <span className='hidden addIconbutton-add-btn'>
                          <IconButton
                            title='Add'
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='fixed bottom-0 mt-2 right-6'>
          {!canEdit && (
            <div className='fixed bottom-0 mt-2 right-6'>
              <div className='flex items-center justify-end gap-2'>
                <button
                  // disabled={!canEdit}
                  onClick={handleCancel}
                  className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
                >
                  Cancel
                </button>
                <PrimaryButton
                  disabled={canEdit}
                  className='px-8 py-2 mb-4 text-white rounded-md bg-primary'
                  onClick={saveRoleInfo}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </ScrollToBottom>
    </div>
  );
};

export default RoleDetails;