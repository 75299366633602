import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Toolbar from "./toolbar";
import Table from './table'
import { GET_MANAGER_TIMESHEET_RECORDS, GET_TIMESHEET_PROJECTS_M } from "src/Redux/actions/projects/timesheet";
import ReactPaginationStyle from "src/Components/ReactPagination";
import FilterButtonTab from "src/Components/FilterButtonTab";
import Indecator from "./indecator";

const rows = [10, 25, 50];

const getCurrentWeekDates = () => {
    const now = new Date()
    const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1))
    const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7))
    return {
        monday: moment(monday).format('YYYY-MM-DD'),
        sunday: moment(sunday).format('YYYY-MM-DD'),
    }
}

const TimesheetManager = ({ timesheetView, setTimesheetView }) => {
    const dispatch = useDispatch()
    const [project, setProject] = useState({})
    const [selectStatus, setSelectStatus] = useState({ value: '', label: "All" })
    const [selectUser, setSelectUser] = useState({})
    const datalist = useSelector(state => state?.ProjectTimesheet?.getManagerTimesheetRecord)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10);
    const [weeks, setWeeks] = useState({
        monday: getCurrentWeekDates()?.monday,
        sunday: getCurrentWeekDates()?.sunday,
    })

    const getData = (page, limit) => {
        let payload = {
            "loginuserid": localStorage.getItem("comuserid"),
            "comuserid": selectUser?.comuserid || 0,
            "Tenantid": localStorage.getItem("Tenantid"),
            "projectid": project?.projectid || 0,
            "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
            "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
            "timesheetstatus": selectStatus?.value,
            "limit": limit,
            "page": page
        }
        dispatch(GET_MANAGER_TIMESHEET_RECORDS(payload))
    }

    const handlePage = (p) => {
        if(p?.selected === 1){
            setPage(p?.selected)
        }else{
            setPage(p?.selected+1)
        }
        getData(p?.selected+1, limit)
    }

    const handleRows = async (row) => {
        setLimit(row);
        getData(page, row)
    };

    useEffect(() => {
        dispatch(GET_TIMESHEET_PROJECTS_M())
        // eslint-disable-next-line 
    }, [])

    return (
        <div className="bg-white shadow rounded-lg h-[calc(100%-100px)] text-sm  mt-2 p-2">
            <div className="flex justify-between items-center">
                <p className="text-primary font-[500]">Total Records ({datalist?.length > 0 ? datalist[0]?.totalcount : 0})</p>
                <Indecator />
                <div className="flex justify-start space-x-2 items-center">
                    <ReactPaginationStyle
                        currentPage={page}
                        limit={limit}
                        total={datalist?.length > 0 ? datalist[0]?.totalcount : 0}
                        handlePagination={handlePage}
                    />
                    <FilterButtonTab
                        setSelectedtab={handleRows}
                        selectedTab={limit}
                        tabs={rows}
                    />
                    <div>
                        <FilterButtonTab
                            className="py-1.5 px-2 border"
                            tabs={['Employee', 'Manager']}
                            setSelectedtab={setTimesheetView}
                            selectedTab={timesheetView}
                        />
                    </div>
                </div>
            </div>
            <Toolbar
                updatehooks={{
                    selectStatus, setSelectStatus, selectUser, setSelectUser,
                    setPage, setLimit, setWeeks, weeks,
                    page, limit, project, setProject
                }}
            />

            <Table updatehooks={{
                selectStatus, setSelectStatus, selectUser, setSelectUser,
                setPage, setLimit, setWeeks, weeks,
                page, limit, project, setProject, getData
            }} />
        </div>
    )
}

export default TimesheetManager;