import Tabs from './tabs'
import AnalyticsWorkforceFilter from 'src/Filter/analytics/analyticsWorkforce'
import { useEffect, useState } from 'react'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import { ToastContainer } from 'react-toastify'
import { WorkforceAllocationItem } from 'src/Services/constant/defaultPayload'

const WorkforceAnalytics = ({ modulename, menu }) => {
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filters, setFilters] = useState(WorkforceAllocationItem())

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [menu])

    return (
        <>
            <div className="p-2 py-2 bg-white rounded-md shadow">
                <AnalyticsWorkforceFilter filters={filters} setFilters={setFilters} />
            </div>
            <div className="shadow-lg mt-4 w-full rounded-lg bg-white h-[calc(100%-180px)] ">
                <Tabs thisPagePermissions={thisPagePermissions} filters={filters} setFilters={setFilters} />
            </div>
            <ToastContainer position="bottom-center" newestOnTop limit={1} />
        </>
    )
}

export default WorkforceAnalytics
