import { useState, useRef, useEffect, memo, useMemo } from 'react';

function SingleSelectField({ options, handleSelected, disabled, value, labelKey, valueKey, hover = false }) {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('')
    const dropdownRef = useRef(null);

    const getByTitle = () => {
        try {
            return options?.find(el => el[valueKey] === value)[labelKey];
        } catch (error) {
            return '';
        }
    }

    useMemo(()=>{
        if(getByTitle()){
            setQuery(getByTitle())
        }
    // eslint-disable-next-line
    },[valueKey, options])

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredList =
        query === ''
            ? options
            : options.filter(el =>
                el?.taskdesc
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, '')),
            )
    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative ">
                <span role='button' onClick={() => setOpen(!open)} className='flex justify-start items-center px-1'>
                    <input value={query || ''}
                        onChange={(e) => setQuery(e.target.value)}
                        className='w-full focus:outline-none placeholder:font-[400] placeholder:text-gray-800 ' />
                    {!disabled && <div>
                        {open ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-secondary">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>}
                    </div>}
                </span>
            </div>
            {open && <div className="transition z-[1000] min-h-44 max-h-44 ease-in duration-100 absolute mt-1 w-auto min-w-[90px] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div>
                    {filteredList?.map((item, itemIdx) => {
                        return (
                            <div
                                key={item?.uId || itemIdx}
                                className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-2 cursor-pointer ${item[valueKey] === value ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                                onClick={() => {
                                    handleSelected(item)
                                    setOpen(!open)
                                    setQuery(item[labelKey])
                                }}
                            >
                                <span>
                                    {item[labelKey]}
                                </span>
                                {item[valueKey] === value &&
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}


const TaskgroupById = ({ hooks }) => {
    const { taskid, uId, handletaskChange, tasksByGroupIdlist, tasksByGroupId, disabled } = hooks

    const getSelected = (valueis) => {
        let e = { target: { name: 'taskid', value: valueis['taskid'], id: uId } }
        handletaskChange(e, uId)
    }

    return (
        <SingleSelectField
            valueKey='taskid'
            labelKey='taskdesc'
            options={tasksByGroupIdlist || tasksByGroupId}
            value={taskid}
            handleSelected={getSelected}
            disabled={!disabled}
        />
    )
}

export default memo(TaskgroupById);
