export const RoleItemSkeleton = () => {
	return (
		{
		demandroledetailid: null,
		demandid: 0,
		jobtitle: '',
		bandmasterid: 0,
		experienceinyear: null,
		rolesummary: '',
		comtenantid: Number(localStorage.getItem('Tenantid') || 0),
		isactiveflag: true,
		createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
		modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
		roleresponsibilities: [
			{
				responsibilities: '',
				uId:Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
			},
		],
	}
	)
}

export const detailNotRequired = [ 'demandroledetailid','demandid', 'projectid', 'departmentid','demandcode','locationid', 'assignedto', 'assigneddate', 'is_billable', 'billrate', 'datcomcurrencyid', 'billingunit', 'notes' ];
