import moment from 'moment'
import { useSelector } from 'react-redux'
import TalentNote from './talentNote'

const matchSummaryTitles = {
    Proposed: 'MPROPOSD',
    Blocked: 'MBLKED',
    Selected: 'MSELECTD',
}
const MatchSummaryCard = ({ count, title }) => {
    return (
        <div className="flex flex-col items-center justify-center p-2 text-center border border-gray-100 rounded-md shadow-md">
            <h1 className="text-2xl font-bold text-primary ">{count}</h1>
            <p className="text-gray-500">{title}</p>
        </div>
    )
}

const EmployeePersonalInfo = () => {
    const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
    const { employeeSummary, allocationData } = EmployeeProfile
    const { demandListTalent } = useSelector((state) => state?.DemandDetailsList)

    const statusCountMap = (employeeSummary[0]?.statuscount || [])?.reduce((map, item) => {
        map[item.statuscode] = item.countno
        return map
    }, {})

    const matchSummary = Object.keys(matchSummaryTitles)?.map((title) => ({
        count: statusCountMap[matchSummaryTitles[title]] || 0,
        title,
    }))

    return (
        <div className="w-full h-full">
            <div className="gap-4 p-2 text-sm bg-white rounded-md shadow-md">
                <div className="border-b flex justify-between items-center w-full border-primary py-1.5 ">
                    <p className="mb-1 text-sm font-medium">Employee Summary</p>
                    <TalentNote notes={employeeSummary[0]?.notes} />
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Gender: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.gender}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Email: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.emailid}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Phone: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.mobileno}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Status: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.emp_statusname}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Worker Type : </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.workertype}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Time Type : </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.timetype}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Workforce group: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.workforcegroup}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Designation: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.designation}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Band: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.bandname}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Department: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.department}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Joining Date: </p>
                    <p className="text-gray-500 ">
                        {moment(employeeSummary[0]?.dateofjoining).format('DD-MM-YYYY') || ''}
                    </p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Manager: </p>
                    <p className="text-gray-500 ">
                        {employeeSummary[0]?.managername}({employeeSummary[0]?.manageremployeecode})
                    </p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Service Line: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.servicelinename}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Primary Skill Area: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.skillarea}</p>
                </div>
                <div className="py-[3px] flex justify-start items-center space-x-1 ">
                    <p>Primary Skill: </p>
                    <p className="text-gray-500 ">{employeeSummary[0]?.primaryskill}</p>
                </div>
            </div>
            {allocationData?.Status !== 'Deployed' ? (
                <div className="p-2.5 mb-2.5 mt-4  text-sm bg-white border rounded-lg shadow-md">
                    <div className="border-b border-primary mb-1.5 ">
                        <p className="pb-1 text-sm font-[500]">Demand Matches</p>
                    </div>
                    <div className="grid grid-cols-4 py-1.5 gap-1 bg-white ">
                        <MatchSummaryCard
                            key="Matches"
                            count={demandListTalent?.[0]?.totalcount || 0}
                            title="Matches"
                        />
                        {matchSummary?.map((item) => {
                            return <MatchSummaryCard key={item?.title} count={item?.count} title={item?.title} />
                        })}
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    )
}

export default EmployeePersonalInfo
