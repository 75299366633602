import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { weekdaysItem } from './CreateCompany/default';
import IconButton from 'src/Components/Buttons/IconButton';
import { AlertError, AlertSuccess } from 'src/Services';
import SelectOption from 'src/Components/SelectOption';
import Company from './company'
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { GET_COMPANY_WEEKDAYS, SAVE_COMPANY_WEEKDAYS } from 'src/Redux/actions/masterdata/user';
import { getTenantCompanyWeedayData, getMasterCompanyIdData } from 'src/Services/constant/defaultPayload';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';


const CompanyWeekdaysMasterList = () => {
  const dispatch = useDispatch();
  const [weekdaysData, setWeekdaysData] = useState([]);
  const companyweekdaysid = null;
  const { companylist } = useSelector((state) => state.EmployeeDetails);
  const { companyWeekdaysList } = useSelector((state) => state.UserManagementList);


  useEffect(() => {
    dispatch(GET_COMPANY_WEEKDAYS(getTenantCompanyWeedayData()));
    dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
  }, [dispatch]);


  useEffect(() => {
    let rmUnwanted = companyWeekdaysList?.map((it) => {
      delete it['createdtimestamp'];
      delete it['modifiedtimestamp'];
      return it;
    });

    if (rmUnwanted?.length > 0) {
      const sortedData = [...rmUnwanted].sort((a, b) => a.companyweekdaysid - b.companyweekdaysid);
      setWeekdaysData(sortedData);
    } else {
      const defaultRow = weekdaysItem();
      setWeekdaysData([defaultRow]);
    }
  }, [companyWeekdaysList]);

  const handleAdd = () => {
    const maxUId = weekdaysData.reduce((acc, curr) => Math.max(acc, curr.uId), 0);
    const newRow = weekdaysItem(maxUId + 1);
    setWeekdaysData((prevData) => [...prevData, newRow]);
  };


  const SelectHandleChange = (e, uId) => {
    const { name, value } = e.target;
    let isCompanyAlreadySelected = false;

    // Check if the selected company is already selected in another row
    weekdaysData.forEach((item) => {
      if (item.uId !== uId && item.companyid === value) {
        isCompanyAlreadySelected = true;
      }
    });
    if (isCompanyAlreadySelected) {
      AlertError('Company already selected!');
      return;
    }
    let afterChange = weekdaysData?.map((item) => {
      if (item?.uId === uId) {
        item[name] = value;
      }
      return item;
    });
    setWeekdaysData(afterChange);
  };



  const handleDelete = async (uId) => {
    const updatedData = weekdaysData.map((item) => {
      if (item?.uId === uId) {
        return {
          ...item,
          isactiveflag: false
        };
      }
      return item;
    });

    const filteredData = updatedData.filter((item) => {
      return (
        item?.companyweekdaysid !== null &&
        item?.companyweekdaysid !== 0);
    });
    const sortedData = [...filteredData].sort((a, b) => a.companyweekdaysid - b.companyweekdaysid);
    setWeekdaysData(sortedData);
    dispatch(SAVE_COMPANY_WEEKDAYS(sortedData, companyweekdaysid))
      .then((res) => {
        if (res?.success) {
          AlertSuccess('Customer Weekdays saved successfully!');
        }
        // After successful dispatch, fetch updated data
        dispatch(GET_COMPANY_WEEKDAYS(getTenantCompanyWeedayData()));
      })
      .catch((error) => {
        // Handle error if needed
        console.error('Error saving customer weekdays:', error);
      });
  };


  const handleDailyHoursChange = async (e, uId) => {
    const { value } = e.target;
    const updatedWeekdaysData = weekdaysData.map((item) => {
      if (item?.uId === uId) {
        item['noofhoursperday'] = parseFloat(value);
      }
      return item;
    });
    setWeekdaysData(updatedWeekdaysData);
    let res = await dispatch(SAVE_COMPANY_WEEKDAYS(updatedWeekdaysData, companyweekdaysid));
    if (res?.success) {
      dispatch(GET_COMPANY_WEEKDAYS(getTenantCompanyWeedayData()));
    }
  };

  const handleHolidaySelect = async (e, uId, name) => {
    const { checked } = e.target;
    let afterChange = weekdaysData?.map((item) => {
      if (item?.uId === uId) {
        return {
          ...item,
          [name]: !checked,
        };
      }
      return item;
    });
    setWeekdaysData(afterChange);
    const debounceTimer = setTimeout(async () => {
      let res = await dispatch(SAVE_COMPANY_WEEKDAYS(afterChange, companyweekdaysid));
      if (res?.success) {
        AlertSuccess('Company Weekdays saved successfully!');
      }
      dispatch(GET_COMPANY_WEEKDAYS(getTenantCompanyWeedayData()));

    }, 20)
    return () => {
      clearTimeout(debounceTimer)
    }
  };

  return (
    <div className='mt-4'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Weekdays
          </h1>
        </div>
      </div>
      <div className='h-[60vh] overflow-scroll pb-80'>
        <table className='w-full relative text-left'>
          <thead className='capitalize bg-gray-50 h-[1vh]'>
            <tr className='p-2 text-left capitalize bg-gray-50'>
              <th className='border font-[500] p-1 '>
                Company  <span className='text-red-300'>*</span>
              </th>
              <th className='border font-[500] p-1'>
                Monday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Tuesday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Wednesday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Thursday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Friday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Saturday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Sunday <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] p-1'>
                Daily Hours <span className='text-red-300'></span>
              </th>
              <th className='border font-[500] py-2 px-2'>Action</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 mr-4'>
            {weekdaysData?.map((item, i) => {
              const {
                uId,
                monholiday,
                tueholiday,
                wedholiday,
                thuholiday,
                friholiday,
                satholiday,
                sunholiday,
              } = item;
              return (
                <tr key={uId} className='text-left'>
                  <td className='border px-2 py-1'>
                    <Company
                      value={item?.companyid}
                      rowId={item?.uId}
                      id={uId}
                      handleChange={SelectHandleChange}
                      options={companylist}
                      labelKey='companyname'
                    />
                  </td>

                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.companyid}
                      checked={!monholiday}
                      value={monholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'monholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.companyid}
                      checked={!tueholiday}
                      value={tueholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'tueholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      checked={!wedholiday}
                      disabled={!item?.companyid}
                      value={wedholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'wedholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.companyid}
                      checked={!thuholiday}
                      value={thuholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'thuholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.companyid}
                      checked={!friholiday}
                      value={friholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'friholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.companyid}
                      checked={!satholiday}
                      value={satholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'satholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border px-2 py-1'>
                    <input
                      id={uId}
                      type='checkbox'
                      disabled={!item?.companyid}
                      checked={!sunholiday}
                      value={sunholiday}
                      onChange={(e) => handleHolidaySelect(e, uId, 'sunholiday')}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2'
                    />
                  </td>
                  <td className='border w-18 p-1'>
                    <SelectOption
                      name='noofhoursperday'
                      disabled={!item?.companyid}
                      value={item?.noofhoursperday}
                      onChange={(e) => handleDailyHoursChange(e, item?.uId)}
                      options={[
                        { value: 8.0, label: '8.0' },
                        { value: 8.5, label: '8.5' },
                        { value: 9.0, label: '9.0' },
                        { value: 9.5, label: '9.5' },
                        { value: 10.0, label: '10.0' },
                      ]}
                    />
                  </td>
                  {(
                    <td className='border'>
                      <div className='flex items-center justify-start w-full px-2'>
                        {weekdaysData?.length > 2 && (
                          <IconButton
                            title='Delete'
                            onClick={() => handleDelete(item?.uId)}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                             <MinusIcon className="w-3" />
                          </IconButton>
                        )}
                        {weekdaysData?.length - 1 === i && (
                          <IconButton
                            title='Add'
                            disabled={
                              weekdaysData?.length >= 10
                            }
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>)}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default memo(CompanyWeekdaysMasterList);