    import StackedBarChart from './bar'
    import LineChartAnalytic from './lineChart'
    import RadialPieChart from './pieChart'

    const AnalyticFullFillment = () => {
        return (
            <div className="relative w-full ">
                <div className="grid grid-cols-3 py-2">
                    <>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                By Service Line / Fulfilment Type
                            </h3>
                            <StackedBarChart />
                        </div>
                        <div className="relative w-full h-80">
                            <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                                Fulfilment Timeline
                            </h3>
                            <LineChartAnalytic />
                        </div>
                        <div className="relative w-full ">
                            <div className="h-60 ">
                                <RadialPieChart
                                    title="Avg TAT Days (Internal)"
                                    value={45}
                                    color="#8884d8"
                                />
                            </div>
                            <div className="mt-1 h-60 ">
                                <RadialPieChart
                                    title="Avg TAT Days (External)"
                                    value={75}
                                    color="#82ca9d"
                                />
                            </div>
                        </div>
                    </>
                </div>
            </div>
        )
    }

    export default AnalyticFullFillment
