
import SingleSelectField from "src/Components/SingleSelectForTable";

const SkillArea = ({ canEdit, value, rowId, handleChange, options, isprimary }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'skillareamasterid', value: value['skillareamasterid'],  } }
        handleChange(e, rowId)
    }

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='skillareamasterid'
                labelKey='skillarea'
                options={options}
                value={value || 0}
                handleSelected={getSelected}
                disabled={isprimary || !canEdit}
            />
        </div>
    )
}

export default SkillArea;
