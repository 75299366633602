import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { GET_PREVIOUS_EXPERIENCE } from 'src/Redux/actions/workforce/eprofile'
import { useLocation } from 'react-router-dom'
import ScrollToBottom from 'react-scroll-to-bottom'
import ExperienceNote from './experienceNote'

const EmploymentHistory = ({ talentId }) => {
    const dispatch = useDispatch()
    const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
    const { previousExperience } = EmployeeProfile
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const tenid = searchParams.get('tenid')

    useEffect(() => {
        dispatch(GET_PREVIOUS_EXPERIENCE({ comuserid: +talentId, Tenantid: +tenid }))
    }, [dispatch, tenid, talentId])

    // Helper function to determine if the employment history data is valid
    const isValidExperience = (item) => {
        return (
            item.designation && item.companyname && item.joiningdate && item.releivingdate
        )
    }

    return (
        <div className=" h-[44vh] my-2 text-sm">
            <ScrollToBottom className="h-[48vh]">
                <table className="w-full text-left text-textSecondary">
                    <thead className="capitalize bg-gray-50">
                        <tr className="bg-gray-100 border-b">
                            <th className="border  font-[500] px-1 py-1">Position</th>
                            <th className="border  font-[500] px-1 py-1">Company</th>
                            <th className="border  font-[500] px-1 py-1">Start Date</th>
                            <th className="border  font-[500] px-1 py-1">Last Date</th>
                            <th className="border  font-[500] px-1 py-1">Role Summary</th>
                        </tr>
                    </thead>
                    <tbody>
                        {previousExperience?.length > 0 ? (
                            previousExperience
                                .filter(isValidExperience)
                                .map((item, i) => (
                                    <tr key={i} className="bg-white border-b">
                                        <td className="px-1 py-1 border">
                                            {item.designation}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            {item.companyname}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            {item.joiningdate}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            {item.releivingdate}
                                        </td>
                                        <td className="px-1 py-1 border">
                                            <ExperienceNote notes={item.notes} />
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="py-3 text-center">
                                    No previous employment history available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </ScrollToBottom>
        </div>
    )
}

export default EmploymentHistory
