import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import MilestoneTable from './table'
import { insertDataKeys } from './defautVar'
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { GET_ACTIVITY_BY_PROJECT, GET_MILESTONE_OF_PROJECT, SAVE_MILESTONE_PROJECT_ID } from 'src/Redux/actions/projects/planning';
import { AlertError, getTotalFromArrayOfObjects } from 'src/Services';

const Milestone = ({ hooksValues, disabled, setEditTab }) => {
    const { milestone, project } = hooksValues
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const searchParams = new URLSearchParams(window?.location.search.split('?')[1])
    const staffingProjectId = searchParams.get('projectid')

    const getDataByrojectid = (pId) => {
        dispatch(GET_ACTIVITY_BY_PROJECT(+staffingProjectId || pId, 0))
        dispatch(GET_MILESTONE_OF_PROJECT(+staffingProjectId || pId, 0))
    }

    const saveMileStone = async () => {
        setLoad(true)
        let data = milestone?.map(r => {
            r['milestoneamount'] = parseInt(r['milestoneamount'])
            return r
        })
        if (getTotalFromArrayOfObjects(data, 'milestoneamount') !== project?.totalestimateamt) {
            AlertError(`Total milestone amount should be equal to planned contract value of ${project?.totalestimateamt} ${project?.currency}`)
            setLoad(false)
            return
        }
        let res = await dispatch(SAVE_MILESTONE_PROJECT_ID(milestone, insertDataKeys, project?.projectid))
        if (res) {
            setLoad(false)
            setEditTab(null)
        }
        setLoad(false)
    }

    useEffect(() => {
        if (project?.projectid) {
            getDataByrojectid(project?.projectid)
        }
        // eslint-disable-next-line
    }, [project])

    const cancelBtn = () => {
        setEditTab(null)
        getDataByrojectid(project?.projectid)
    }

    return (
        <div className="h-full text-sm w-full rounded-lg">
            <MilestoneTable
                hooksValues={{ ...hooksValues, disabled }} />
            {disabled ? <div className=' absolute bottom-0 right-0 flex justify-end space-x-2 items-center px-2'>
                <button onClick={cancelBtn} className='text-primary border border-primary w-[80px] rounded-md py-1.5'>
                    Cancel
                </button>
                <PrimaryButton disabled={load} onClick={saveMileStone} className='bg-primary text-white rounded-md py-2'>
                    {load ? 'Saving...' : 'Save'}
                </PrimaryButton>
            </div> : <div className='h-12' />}
        </div>
    )
}

export default Milestone;


