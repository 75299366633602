export const detailItem = () => {
       return {
              companyholidaysid: null,
              companyid: 0,
              holiday: "",
              reason: "",
              comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
              isactiveflag: true,
              createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
              modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
              notes: null
       };
};
