export const detailItem = () => {
  return {
    exchangerateid: null,
    currencyfrom: 0,
    currencyto: 0,
    conversionrate: 0,
    validfrom: '',
    validto: '',
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: null,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
  };
}


