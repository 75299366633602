import { useState } from 'react';
import { PreIcon, NextIcon } from "src/Assets/icons";
import ScrollToBottom from 'react-scroll-to-bottom';
import RoleRow from './row'
const cols1 = ["Customer Role *", "Level", "Skill Area", "Tech Group", "Bill Rate *", "Currency", "Unit", "Location Flag", "Country", "# People *"]
const cols2 = ["Customer Role *", "Allocation *", "Start Date *", "End Date *", "Estd. Effort", "Estd. Cost ", "Task assignment", "Client Billable"]

const getActiveRecordOnly = (data) => {
    return data?.filter(it => it?.isactiveflag === true)
}

const RolesTable = ({ hooksValue, disabled }) => {
    const { roles } = hooksValue
    const [showMore, setShowMore] = useState(true)

    return (
        <div className="bg-white text-sm w-full">
            <div className="overflow-y-scroll px-1">
                <div className='mr-12 flex justify-start items-center space-x-2 absolute top-2 right-2 '>
                    <button disabled={showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary'>
                        {PreIcon}
                    </button>
                    <button disabled={!showMore} onClick={() => setShowMore(!showMore)} className='disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary'>
                        {NextIcon}
                    </button>
                </div>
                <ScrollToBottom className="bg-white h-[54vh] w-full overflow-y-scroll text-sm">
                    <table className="w-full overflow-scroll">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {
                                    showMore ? cols1?.map((col) => {
                                        return (
                                            <th key={col} title={col} className="font-[500] px-2 py-2 border text-start">{col.replace('*', '')} {col.includes("*") ? <span className='text-red-300'> *</span> : ''}</th>
                                        )
                                    }) : <>
                                        {
                                            cols2?.map((col) => {
                                                return (
                                                    <th key={col} title={col} className="font-[500] px-2 py-2 border text-start">{col.replace('*', '')} {col.includes("*") ? <span className='text-red-300'> *</span> : ''}</th>
                                                )
                                            })
                                        }
                                    </>
                                }
                                {disabled && <th className="font-[500] px-2 py-2 border text-start">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {getActiveRecordOnly(roles)?.map((item) => {
                                return (
                                    <RoleRow hooksValue={{ ...hooksValue, showMore, disabled }} roleInfo={item} key={item?.uId} />
                                )
                            })}
                        </tbody>
                    </table>
                </ScrollToBottom>
            </div>
        </div>
    )
}

export default RolesTable;

