import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TimesheetNonComplianceReportsFilter from 'src/Filter/reports/timsheet/nonComplianceReports'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import {
    GET_REPORT_MASTER_LIST,
    GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE,
} from 'src/Redux/actions/reports'
import { getTimesheetNonComplianeReportPayload } from 'src/Services/constant/defaultPayload'
import NonComplianceTimesheetReport from './table'

const NonComplianceReports = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getTimesheetNonComplianeReportPayload())
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    let { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    let { reportMasterList } = useSelector((state) => state?.WorkforceReport)
    const [project, setProject] = useState([])
    const projectIds = projectsOfM?.map((project) => project.projectid)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    const projectList = async () => {
        let items = await dispatch(GET_TIMESHEET_PROJECTS_M())
        const pId = items?.map((project) => project?.projectid)
        if (pId) {
            let payload = {
                ...filters,
                comuserid: 0,
                projectid: `{${pId?.join(',')}}`,
                weekstartdate: '',
                weekenddate: '',
            }
            dispatch(GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE(payload))
            setFilters(payload)
        }
    }

    useEffect(() => {
        projectList()
        // eslint-disable-next-line
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 text-sm bg-white rounded-md shadow">
                {projectsOfM !== null ? (
                    <TimesheetNonComplianceReportsFilter
                        projectIds={projectIds}
                        filters={filters}
                        setFilters={setFilters}
                        project={project}
                        setProject={setProject}
                        reportId={reportId}
                        setReportId={setReportId}
                        page={page}
                        limit={limit}
                    />
                ) : (
                    <div className="w-full h-32 " />
                )}
            </div>
            <NonComplianceTimesheetReport
                projectIds={projectIds}
                filters={filters}
                reportId={reportId}
                reportMasterList={reportMasterList}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default NonComplianceReports
