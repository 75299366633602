import { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { AlertError, AlertSuccess } from 'src/Services';
import IconButton from 'src/Components/Buttons/IconButton';
import { RoleItem, contactItem, detailItem } from './defaultRowAndData';
import Details from './details';
import RoleDetails from './roleDetails';
import ContactInfo from './contactInfo';
import {
  getDeafultIndustryData,
  getMasterCompanyIdData,
  getDefaultBusinessUnitPayload,
  getDeafultProficiencyData,
  getDefaultCurrencyPayload,
  getBandMasterIdData,
  getDeafultSkillAreaData,
  getTechGroupMasterIdData,
} from 'src/Services/constant/defaultPayload';
import { getMasterCountryPayload, getMasterCityPayload } from 'src/Services/constant/masterdatapayload'
import {
  getCustomerDocument,
  getCustomerListPayload,
  getCustomerRoleListMasterPayload,
} from 'src/Services/constant/Customerpayload';
import { GET_CUSTOMER_GROUP_MASTER, GET_COUNTRY_MASTER, GET_TECHNOLOGY_GROUP } from 'src/Redux/actions/masterdata/user';
import {
  GET_BAND_MASTER,
  GET_CITY_MASTER,
  GET_COMPANY_MASTER,
} from 'src/Redux/actions/workforce/talent';
import {
  GET_BUSINESS_UNIT_MASTER_LIST,
  GET_CURRENCY_MASTER_LIST,
} from 'src/Redux/actions/projects';
import {
  ADD_RATE_OF_CUSTOMER,
  GET_DELIVERY_ROLE_MASTER_BY_ID,
} from 'src/Redux/actions/masterdata/customer/role';
import {
  GET_LEVEL_MASTER,
  GET_INSUDTRY_MASTER,
  GET_SKILLAREA_MASTER,
} from 'src/Redux/actions/workforce/eprofile';
import {
  GET_CUSTOMER_MASTERLIST,
  SAVE_CUSTOMER_CONTACTS,
  GET_SOURCE_MASTER_FILTER,
  SAVE_CUSTOMER_AS_DRAFT,
  SAVE_CUSTOMER_DOCUMENT,
  GET_CUSTOMER_DOCUMENT_LIST,
  GET_CUSTOMER_DOCUMENT_TYPE_LIST,
} from 'src/Redux/actions/masterdata/customer';
import { customerDocumentRow } from '../editCustomer/defaultRowAndData';
import Documents from './documents';

const tabslist = ['Details', 'Contacts', 'Roles', 'Documents'];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateCustomer = ({ page, limit }) => {
  const [roleData, setRoleData] = useState([RoleItem()]);
  const [detailsData, setDetailsData] = useState(detailItem());
  const [contactsData, setContactsData] = useState([contactItem()]);
  const [documents, setDocuments] = useState([customerDocumentRow()])
  const [account, setAccount] = useState({});
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  let [customerid, setCustomerid] = useState(0);
  const [activeTabs, setActiveTabs] = useState(['Details']);

  useEffect(() => {
    if (isOpen) {
      dispatch(GET_CUSTOMER_DOCUMENT_TYPE_LIST())
      dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData()));
      dispatch(GET_CUSTOMER_GROUP_MASTER(getCustomerRoleListMasterPayload()));
      dispatch(GET_INSUDTRY_MASTER(getDeafultIndustryData()));
      dispatch(GET_BUSINESS_UNIT_MASTER_LIST(getDefaultBusinessUnitPayload()));
      dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()));
      dispatch(GET_CITY_MASTER(getMasterCityPayload()));
      dispatch(GET_SOURCE_MASTER_FILTER());
      dispatch(GET_COUNTRY_MASTER(getMasterCountryPayload()))
      dispatch(GET_DELIVERY_ROLE_MASTER_BY_ID());
      dispatch(GET_LEVEL_MASTER(getDeafultProficiencyData('C')));
      dispatch(GET_BAND_MASTER(getBandMasterIdData()))
      dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData()));
      dispatch(GET_TECHNOLOGY_GROUP(getTechGroupMasterIdData()));
    }
  }, [dispatch, isOpen]);

  function closeModal() {
    setIsOpen(false);
    setRoleData([RoleItem()]);
    setDetailsData(detailItem());
    setContactsData([contactItem()]);
    setActiveTabs(['Details']);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function detailSave(payload) {
    let res = await dispatch(SAVE_CUSTOMER_AS_DRAFT(payload));
    if (res?.success) {
      setCustomerid(res?.customerid);
      setActiveTabs([...activeTabs, 'Contacts']);
      dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload(page, limit)));
      // setContactsData([...contactsData]);
      AlertSuccess('Customer saved successfully.');
    }
  }

  const documentSave = async () => {
    let afterModify = []
    for (let it of documents) {
      let document = { ...it }
      delete document['comtenantid']
      delete document['createduser']
      document['customerid'] = customerid
      document['Tenantid'] = it?.comtenantid || it?.Tenantid
      document['documenttype'] = it?.documenttypeid || it['documenttype']
      if (!document['documenttype']) {
        AlertError('Document type is required !')
        return
      }
      afterModify.push(document)
    }

    let res = await dispatch(SAVE_CUSTOMER_DOCUMENT(afterModify));
    if (res?.success) {
      setDocuments([customerDocumentRow()])
      AlertSuccess('Customer Document Saved successfully.');
      dispatch(GET_CUSTOMER_DOCUMENT_LIST(getCustomerDocument(customerid)))
    }
  };

  const contactSave = async () => {
    let res = await dispatch(SAVE_CUSTOMER_CONTACTS(contactsData, customerid));
    if (res?.success) {
      AlertSuccess('Contact saved successfully!');
      setCustomerid(res?.customerid);
      setActiveTabs([...activeTabs, 'Roles', 'Documents']);
    }
  };

  const roleSave = async () => {
    let afterModify = [];
    for (let d of roleData) {
      let item = d
      delete item['uId'];
      delete item['isonsite'];
      item['RateUnit'] = Number(item?.rateunit);
      item['bill_hr'] = Number(item['bill_hr']);
      item['locationid'] = null;
      item['customerid'] = customerid;
      afterModify.push(item);
    }
    let payload = { customerjson: afterModify };
    let res = await dispatch(ADD_RATE_OF_CUSTOMER(payload));
    if (res?.success) {
      dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload(page, limit)));
    }
  };

  return (
    <>
      <IconButton
        title='Create New Customer'
        onClick={openModal}
        className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-5 h-5'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 4.5v15m7.5-7.5h-15'
          />
        </svg>
      </IconButton>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>

                  <Tab.Group>
                    <div className='flex justify-between items-center w-full border-b border-secondary '>
                      <h3 className='text-lg font-[500] leading-6 text-primary'>
                        Create Customer
                      </h3>
                      <Tab.List className='justify-start flex space-x-2 rounded-lg p-1'>
                        {tabslist?.map((item, i) => {
                          return (
                            <Tab
                              key={i}
                              disabled={!activeTabs.includes(item)}
                              className={({ selected }) =>
                                classNames(
                                  'w-full text-lg py-1 outline-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                  selected
                                  ? 'bg-secondary/10 text-secondary rounded-md font-[500]'
                                  : 'text-gray-500'
                                )
                              }
                            >
                              <span className='px-2'> {item}</span>
                            </Tab>
                          );
                        })}
                      </Tab.List>

                      <IconButton
                        title='Close'
                        onClick={closeModal}
                        type='button'
                        className='text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-4 h-4'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                        <span className='sr-only'>Close icon</span>
                      </IconButton>
                    </div>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Details
                          customerid={customerid}
                          detailSave={detailSave}
                          account={account}
                          setAccount={setAccount}
                          detailsData={detailsData}
                          setDetailsData={setDetailsData}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ContactInfo
                          customerid={customerid}
                          handleSubmit={contactSave}
                          mockObject={contactItem()}
                          contactsData={contactsData}
                          setContactsData={setContactsData}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <RoleDetails
                          detailsData={detailsData}
                          formData={roleData}
                          setFormData={setRoleData}
                          handleSubmit={roleSave}
                          customerid={customerid}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <Documents
                          customerid={customerid}
                          handleSubmit={documentSave}
                          mockObject={customerDocumentRow()}
                          documents={documents}
                          setDocuments={setDocuments}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default memo(CreateCustomer);