import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { GET_PROJECTS_EPROFILE } from 'src/Redux/actions/workforce/eprofile'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import IconButton from 'src/Components/Buttons/IconButton'
import ProjectNote from './projectNote'
import ScrollToBottom from 'react-scroll-to-bottom'
import UniqueOptionSearch from 'src/Components/UniqueOptionSearch'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { projectStatusByDate } from 'src/Services/constant/staticValues'

const getStatus = (projectData) => {
	const currentDate = moment().format('YYYY-MM-DD');
	const startDate = moment(new Date(projectData.startdate)).format('YYYY-MM-DD');
	const endDate = moment(new Date(projectData.enddate)).format('YYYY-MM-DD');

	if (startDate > currentDate) {
		return {
			icon: <IconButton title='Upcoming'>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-primary">
					<path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
				</svg>
			</IconButton>, title: 'Upcoming'
		};
	} else if (startDate <= currentDate && currentDate <= endDate) {
		return {
			icon: <IconButton title='Active'>
				<span className='w-3 h-3 bg-green-500 rounded-full ' />
			</IconButton>, title: 'Active'
		};
	} else if (endDate < currentDate) {
		return {
			icon: <IconButton title='Previous'>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-gray-500 rotate-180 ">
					<path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
				</svg>
			</IconButton>, title: 'Previous'
		};
	} else {
		return { title: 'All' }
	}
};


const ProjectHistory = () => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector(state => state?.EmployeeProfile)
	const { projectsEprofile } = EmployeeProfile
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const userId = searchParams.get('userid')
	const tenid = searchParams.get('tenid')
	const [dateStatus, setDateStatus] = useState('All Allocations')
	const [project, setProject] = useState({})

	useEffect(() => {
		dispatch(GET_PROJECTS_EPROFILE({ comuserid: +userId, Tenantid: +tenid }))
	}, [dispatch, tenid, userId])

	const getDataAfterFilter = useMemo(() => {
		if (project?.projectid) {
			return projectsEprofile?.filter(it => it?.projectid === project?.projectid)
		}else if(dateStatus === 'All Allocations'){
			return projectsEprofile
		} else {
			return projectsEprofile?.filter(it => dateStatus === getStatus(it)?.title)
		}
	}, [dateStatus, projectsEprofile, project])

	return (
		<div className='my-2 text-sm'>
			<div className='flex items-center justify-start mb-2 space-x-2'>
				<div className=' w-72'>
					<UniqueOptionSearch
						options={projectsEprofile || []}
						idkey='uId'
						uniqueby='projectid'
						className='p-1.5  w-full'
						optionlabelKey='projectname'
						getSelectedOption={setProject}
						placeholder='Search project '
					/>
				</div>
				<div className=' w-44'>
					<CustomhtmlSelect
						options={projectStatusByDate || []}
						value={dateStatus}
						noDataLabel='No Data'
						name='label'
						labelkey='label'
						className='py-2 border rounded bg-gray-50'
						onChange={(name, value) => (setDateStatus(value))}
						id='label'
					/>
				</div>
			</div>
			<ScrollToBottom className='h-[60vh]'>
				<table className='w-full text-left text-textSecondary '>
					<thead className='capitalize bg-gray-50'>
						<tr className='bg-gray-100 border-b'>
							<th className='border  font-[500] py-1.5 px-2'>Project Name</th>
							<th className='border  font-[500] py-1.5 px-2'>Customer</th>
							<th className='border  font-[500] py-1.5 px-2'>Role</th>
							<th className='border  font-[500] py-1.5 px-2'>Start Date</th>
							<th className='border  font-[500] py-1.5 px-2'>End Date</th>
							<th className='border  font-[500] py-1.5 px-2'>Allocation</th>
							<th className='border  font-[500] py-1.5 px-2'>
								Project Manager
							</th>
							<th className='border  font-[500] py-1.5 px-2'>
								Role Summary
							</th>
							<th className='border  font-[500] py-1.5 px-2'>
								Status
							</th>
						</tr>
					</thead>
					<tbody>
						{getDataAfterFilter?.map((item) => {
							let {
								customername,
								projectname,
								rolename,
								startdate,
								enddate,
								projectmanager,
								projectmanageremployeecode,
								allocation,
								notes,
								uId,
								projectid
							} = item

							return (
								<tr key={`${uId}${projectid}`} className='bg-white border-b'>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>{projectname}</p>
									</td>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>{customername}</p>
									</td>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>{rolename}</p>
									</td>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>
											{moment(startdate).format('DD-MM-YYYY')}
										</p>
									</td>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>
											{moment(enddate).format('DD-MM-YYYY')}
										</p>
									</td>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>
											{allocation * 100}{" "}%
										</p>
									</td>
									<td className='border py-1.5 px-2'>
										<p className='ml-1'>
											{`${projectmanager} ${projectmanageremployeecode}`}
										</p>
									</td>
									<td className='border py-1.5 px-2'>
										<ProjectNote notes={notes} />
									</td>
									<td className='border py-1.5 px-2'>
										{getStatus(item).icon}
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</ScrollToBottom>
		</div>
	)
}

export default ProjectHistory
