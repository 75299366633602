import { useEffect, useState } from 'react'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import { GET_DEPARTMENT_MASTER } from 'src/Redux/actions/workforce/talent'
import { getDepartmentMasterIdData } from 'src/Services/constant/defaultPayload'

const SelectDepartment = ({
    department,
    handleSelectDepartment,
    departmentlist,
    disabled,
}) => {
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        if (department?.department) {
            setSearchTerm(department?.department || '')
        }
    }, [department])

    return (
        <>
            <label htmlFor="Department" className="block font-[500] mb-2">
                {' '}
                Select Department <span className="text-red-300">*</span>
            </label>
            <CustomAutoSearch
                disabled={disabled}
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_DEPARTMENT_MASTER}
                searchPayload={{
                    ...getDepartmentMasterIdData(),
                    searchname: searchTerm,
                }}
                className="p-2"
                setSearchTerm={setSearchTerm}
                options={departmentlist}
                idkey="departmentid"
                optionlabelKey="department"
                getSelectedOption={handleSelectDepartment}
                placeholder="Search Department"
            />
        </>
    )
}

export default SelectDepartment
