    import { memo, useEffect, useMemo, useState } from 'react'
    import { useDispatch, useSelector } from 'react-redux'
    import { Link, useLocation } from 'react-router-dom'
    import { ToastContainer } from 'react-toastify'
    import IconButton from 'src/Components/Buttons/IconButton'
    import {
        GET_ALLOCATION_STATUS,
        GET_CERTIFICATIONS,
        GET_EDUCATIONBACKGROUND_LIST,
        GET_EMPLOYEE_SUMMARY,
        GET_PREVIOUS_EXPERIENCE,
        GET_PROJECTS_EPROFILE,
        GET_SKILLS_EMPLOYEE,
    } from 'src/Redux/actions/workforce/eprofile'
    import { getPermissionsOfCurrentUrl } from 'src/Services'
    import DetailTabs from './detailsTabs'
    import EmployeePersonalInfo from './employeePersonalInfo'
    import ResumeBuilder from './resumeBuilder'

    const EmployeeDetailLayout = ({ modulename, menu }) => {
        const [activetab, setActiveTab] = useState('')
        const dispatch = useDispatch()
        const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
        const {
            employeeSummary,
            allocationData,
            certifications,
            userSkillsDetail,
            projectsEprofile,
            academicQualificationList,
            previousExperience,
        } = EmployeeProfile
        const [showPdf, setShowPdf] = useState(false)
        const [thisPagePermissions, setThisPagePermissions] = useState([])
        const location = useLocation()
        const searchParams = new URLSearchParams(location.search.split('?')[1])
        const userId = searchParams.get('userid')
        const tenid = searchParams.get('tenid')
        const AppliedFilters = searchParams.get('AppliedFilters')

        useEffect(() => {
            const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
            setThisPagePermissions(permissionNames)
            // eslint-disable-next-line
        }, [menu])

        useEffect(() => {
            dispatch(GET_EMPLOYEE_SUMMARY({ COMUserId: +userId, Tenantid: +tenid }))
            dispatch(GET_ALLOCATION_STATUS({ comuserid: +userId, Tenantid: +tenid }))
            dispatch(GET_CERTIFICATIONS({ comuserid: +userId, Tenantid: +tenid }))
            dispatch(GET_SKILLS_EMPLOYEE({ comuserid: +userId, Tenantid: +tenid }))
            dispatch(GET_PROJECTS_EPROFILE({ comuserid: +userId, Tenantid: +tenid }))
            dispatch(GET_EDUCATIONBACKGROUND_LIST({ comuserid: +userId, Tenantid: +tenid }))
            dispatch(GET_PREVIOUS_EXPERIENCE({ comuserid: +userId, Tenantid: +tenid }))
            // eslint-disable-next-line
        }, [dispatch])

        const convertMonthsToYearsAndMonths = (totalMonths) => {
            const years = Math.floor(totalMonths / 12)
            const months = totalMonths % 12
            return { years, months }
        }

        const experience = useMemo(() => {
            if (employeeSummary?.length > 0) {
                const totalExperienceInMonths = employeeSummary[0]?.totalexperienceinmonths || 0
                const currentcompanyexperience = employeeSummary[0]?.currentcompanyexperience || 0
                return convertMonthsToYearsAndMonths(totalExperienceInMonths + currentcompanyexperience)
            }
    
        }, [employeeSummary])


        const generatePDF = () => {
            setShowPdf(true)
        }

        const onClose = () => {
            setShowPdf(false)
        }

        return (
            <>
                <div className="h-[calc(100%-75px)]  w-full ">
                    <div className="flex justify-between mr-4">
                        <div className="flex justify-start items-center space-x-2 pb-2 text-sm sm:text-[10px] lg:text-sm">
                            <Link to={`/app/workforce/talent?AppliedFilters=${AppliedFilters}`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                    />
                                </svg>
                            </Link>
                            <h1 className="text-gray-500 ">
                                {employeeSummary[0]?.firstname} {employeeSummary[0]?.lastname}{' '}
                                {employeeSummary[0]?.employeecode ? `(${employeeSummary[0]?.employeecode})` : ''}
                            </h1>
                            <span className="px-1">|</span>
                            <h1 className="text-gray-500 ">
                                Total Experience : {experience?.years} Years {experience?.months} Months
                            </h1>
                            <span className="px-1">|</span>
                            <h1 className="text-gray-500 ">{employeeSummary[0]?.companyname}</h1>
                            <span className="px-1">|</span>
                            <div className="flex items-center justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-3 h-3 text-red-500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <p className="ml-1 text-gray-500">{employeeSummary[0]?.cityname} </p>
                            </div>
                            <span className="px-1">|</span>
                            <div className="flex items-center justify-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                                    />
                                </svg>
                                <p className="ml-1 text-gray-500">{employeeSummary[0]?.locationname}</p>
                            </div>
                            <div className="flex items-center justify-start ml-1">
                                <span className="px-2 text-black "> | </span>
                                <p className="text-green-600">
                                    <b>{allocationData?.Status}</b>
                                </p>
                            </div>
                            <div className="flex items-center justify-start pl-2 space-x-2">
                                <span className="text-black "> | </span>
                                <p className="ml-1 text-gray-500">Total Allocation</p>
                                <p className="text-green-600">
                                    <b>{((allocationData?.totalallocation || 0) * 100).toFixed(0)} %</b>
                                </p>
                            </div>
                            <div className="flex items-center justify-start ml-1">
                                <span className="px-2 text-black "> | </span>
                                <IconButton
                                    title="Build Resume"
                                    className="flex items-center justify-center w-6 h-6 p-0 text-white bg-primary"
                                    onClick={generatePDF}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-4 h-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
                                        />
                                    </svg>
                                </IconButton>
                                {showPdf && (
                                    <div className=" flex p-5 justify-center fixed top-0 left-0 w-full h-[100vh] bg-white z-[1000] ">
                                        <ResumeBuilder
                                            hooks={{
                                                employeeSummary,
                                                allocationData,
                                                certifications,
                                                userSkillsDetail,
                                                projectsEprofile,
                                                academicQualificationList,
                                                previousExperience,
                                                onClose,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start justify-start h-full space-x-2">
                        <div className="w-3/12 h-[calc(100%-30px)]">
                            <EmployeePersonalInfo />
                        </div>
                        <div className="w-9/12 px-2 h-[calc(100%-30px)] relative rounded-md shadow-md bg-white">
                            <DetailTabs
                                thisPagePermissions={thisPagePermissions}
                                allocationData={allocationData}
                                setActiveTab={setActiveTab}
                                Employees={employeeSummary}
                                activetab={activetab}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer position="bottom-center" newestOnTop limit={1} />
            </>
        )
    }

    export default memo(EmployeeDetailLayout)
