import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const PieChartTech = ({ technologyGroup }) => {
    const COLORS = ['#da9104', '#8884d8', '#82ca9d', '#57779b'];
    const data = technologyGroup?.slice(0, 6)?.map(techGroup => ({
        name: techGroup.techarea,
        value: Math.floor(Math.random() * 500)
    }));

    return (
        <ResponsiveContainer width='100%' height='100%'>
        <PieChart width={500} height={500}>
            <Tooltip contentStyle={{ fontSize: '12px' }} /> {/* Adjust font size here */}
            <Pie
                data={data}
                cx='50%'
                cy='50%'
                labelLine={false}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
                label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    value,
                    index,
                }) => {
                    const RADIAN = Math.PI / 180
                    const radius = 10 + innerRadius + (outerRadius - innerRadius)
                    const x = cx + radius * Math.cos(-midAngle * RADIAN)
                    const y = cy + radius * Math.sin(-midAngle * RADIAN)
                    return (
                        <text
                            style={{ fontSize: '10px' }}
                            x={x}
                            y={y}
                            fill={COLORS[index % COLORS.length]}
                            textAnchor={x > cx ? 'start' : 'end'}
                            dominantBaseline='central'
                        >
                            {data[index].name}
                        </text>
                    )
                }}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
        </PieChart>
    </ResponsiveContainer>
    );
};

export default PieChartTech;
