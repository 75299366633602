import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { AlertSuccess } from 'src/Services'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import {
    getDeafultTalentPayload,
    getDefaultCurrencyPayload,
} from 'src/Services/constant/defaultPayload'
import { GET_CURRENCY_MASTER_LIST } from 'src/Redux/actions/projects'
import { getPayRollOverheadPayload } from '../defaultPayload'
import { GET_OVERHEAD_MASTER } from 'src/Redux/actions/projects/financials'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
import { GET_EMPLOYEE_DETAILS } from 'src/Redux/actions/workforce/talent'
import SelectUser from 'src/Components/SelectUser'

const EditDetails = ({ hooks }) => {
    const dispatch = useDispatch()
    const { currencyList } = useSelector((state) => state?.Projects)
    const {
        detailSave,
        closeModal,
        canDelete,
        setCanDelete,
        canEdit,
        setCanEdit,
        setUserPayroll,
        userPayroll,
    } = hooks
    const [user, setUser] = useState({})
    const { ProjectOverhead } = useSelector((state) => state?.ProjectFinancial)
    const [checkValidation, setCheckValidation] = useState(false)

    useEffect(() => {
        dispatch(GET_OVERHEAD_MASTER(getPayRollOverheadPayload()))
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        // eslint-disable-next-line
    }, [])

    useMemo(() => {
        setUser({
            comuserid: userPayroll?.userid,
            firstname: userPayroll?.username || '',
        })
        // eslint-disable-next-line
    }, [])

    const handleDates = (e) => {
        let { name, value } = e.target
        setUserPayroll((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setCheckValidation(true)
        const transactionDate = new Date(
            userPayroll?.transactiondate?.replace(/\/|\./g, '-'),
        )
        const monthid = transactionDate?.getMonth() + 1 // Adjust for JavaScript's 0-based months
        const yearid = transactionDate?.getFullYear()
        let validationdata = {}
        let requiredInDetail = [
            'userpayid',
            'userid',
            'monthid',
            'yearid',
            'amount',
            'datcomcurrencyid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'notes',
            'transactionid',
            'externalsystemcode',
            'transactiondate',
            'overheadid',
        ]
        for (let value of requiredInDetail) {
            validationdata[value] = userPayroll[value]
        }
        validationdata['userid'] = user?.comuserid
        validationdata['yearid'] = yearid
        validationdata['monthid'] = monthid
        let optionalkey = [
            'userpayid',
            'isactiveflag',
            'createdcomuserid',
            'modifiedcomuserid',
            'notes',
            'externalsystemcode',
        ]
        let isValidated = CheckObjectValidation(validationdata, optionalkey, true)
        if (!isValidated?.isvalid) {
            return
        } else {
            let item = {
                userpayid: userPayroll?.userpayid,
                userid: user?.comuserid,
                monthid: monthid,
                yearid: yearid,
                amount: userPayroll?.amount,
                overheadid: userPayroll?.overheadid,
                datcomcurrencyid: userPayroll?.datcomcurrencyid,
                isactiveflag: userPayroll?.isactiveflag,
                transactionid: userPayroll?.transactionid,
                transactiondate: userPayroll?.transactiondate,
                externalsystemcode: userPayroll?.externalsystemcode,
                createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
                modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
                notes: userPayroll?.notes,
            }
            setCheckValidation(false)
            let apiData = { ...item, amount: Number(userPayroll?.amount) }
            let finalPayload = {
                userpayrolljson: [apiData],
            }
            detailSave(finalPayload)
            setCanEdit(!canEdit)
        }
    }

    const handleDelete = async () => {
        let item = {
            userpayid: userPayroll?.userpayid,
            userid: userPayroll?.userid,
            monthid: userPayroll?.monthid,
            yearid: userPayroll?.yearid,
            amount: userPayroll?.amount,
            overheadid: userPayroll?.overheadid,
            datcomcurrencyid: userPayroll?.datcomcurrencyid,
            isactiveflag: false,
            transactionid: userPayroll?.transactionid,
            transactiondate: userPayroll?.transactiondate,
            externalsystemcode: userPayroll?.externalsystemcode,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            notes: userPayroll?.notes,
        }

        let payload = {
            userpayrolljson: [item],
        }
        detailSave(payload)
        AlertSuccess('Record deleted successfully')
        closeModal()
    }

    const handleChangeInput = (name, value) => {
        setUserPayroll({ ...userPayroll, [name]: value })
    }

    return (
        <div className="w-full h-[65vh] text-sm mb-10">
            <div className="grid grid-cols-4 gap-10 mt-4">
                <div>
                    <label
                        htmlFor="Overhead"
                        className="block capitalize mb-2  font-[500]"
                    >
                        Linked User
                        <span className="text-red-300">*</span>
                    </label>
                    <SelectUser
                        setUser={setUser}
                        className="py-2 border rounded bg-gray-50"
                        user={user}
                        apiCallForSearch={GET_EMPLOYEE_DETAILS}
                        userSelectorKey="Employees"
                        searchPayloadFunction={getDeafultTalentPayload}
                        placeholder="Select User"
                        disabled={!canEdit}
                    />
                </div>
                <div>
                    <label
                        htmlFor="employeecode"
                        className="block capitalize mb-2  font-[500]"
                    >
                        {' '}
                        employeecode{' '}
                    </label>
                    <input
                        disabled={true}
                        type="employeecode"
                        title="employeecode"
                        id="employeecode"
                        className="block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50"
                        placeholder="employeecode"
                        required=""
                        value={userPayroll?.employeecode || ''}
                    />
                </div>
                <div>
                    <label
                        htmlFor="Overhead"
                        className="block capitalize mb-2  font-[500]"
                    >
                        Overhead <span className="text-red-300">*</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={!canEdit}
                        options={ProjectOverhead || []}
                        noDataLabel="No Overhead Type"
                        value={userPayroll?.overheadid || null}
                        name="overheadid"
                        labelkey="overhead"
                        className="py-2 border rounded-lg bg-gray-50"
                        onChange={handleChangeInput}
                        id="overheadmasterid"
                    />
                    {!userPayroll?.overheadid && checkValidation && (
                        <small className="text-red-400">Overhead is required!</small>
                    )}
                </div>
                <div>
                    <label
                        htmlFor="datcomcurrencyid"
                        className="block capitalize mb-2  font-[500]"
                    >
                        Currency <span className="text-red-300">*</span>
                    </label>
                    <CustomhtmlSelect
                        disabled={!canEdit}
                        options={currencyList || []}
                        noDataLabel="No Currency Type"
                        value={userPayroll?.datcomcurrencyid || null}
                        name="datcomcurrencyid"
                        labelkey="currency"
                        className="py-2 border rounded-lg bg-gray-50"
                        onChange={handleChangeInput}
                        id="datcomcurrencyid"
                    />
                    {!userPayroll?.datcomcurrencyid && checkValidation && (
                        <small className="text-red-400">currency is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="amount" className="block font-[500] mb-2">
                        Amount
                        <span className="text-red-300">*</span>
                    </label>
                    <input
                        disabled={!canEdit}
                        type="number"
                        title="amount"
                        id="amount"
                        className="block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50"
                        placeholder="Amount"
                        required=""
                        onChange={(e) => handleChangeInput('amount', e.target.value)}
                        value={userPayroll?.amount || ''}
                    />
                    {!userPayroll?.amount && checkValidation && (
                        <small className="text-red-400">amount is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="transactionid" className="block font-[500] mb-2">
                        Transaction ID
                        <span className="text-red-300">*</span>
                    </label>
                    <input
                        disabled={!canEdit}
                        type="text"
                        title="transactionid"
                        id="transactionid"
                        className="block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50"
                        placeholder="transactionid"
                        required=""
                        value={userPayroll?.transactionid || ''}
                        onChange={(e) =>
                            handleChangeInput('transactionid', e.target.value)
                        }
                    />
                    {!userPayroll?.transactionid && checkValidation && (
                        <small className="text-red-400">transactionid is required!</small>
                    )}
                </div>
                <div>
                    <label htmlFor="externalsystemcode" className="block font-[500] mb-2">
                        Cost Center Code
                    </label>
                    <input
                        disabled={!canEdit}
                        type="text"
                        title="externalsystemcode"
                        id="externalsystemcode"
                        className="block w-full p-2 text-gray-600 border border-gray-200 rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 "
                        placeholder="externalsystemcode"
                        required=""
                        value={userPayroll?.externalsystemcode || ''}
                        onChange={(e) =>
                            handleChangeInput('externalsystemcode', e.target.value)
                        }
                    />
                </div>
                <div>
                    <label htmlFor="transactiondate" className="block font-[500] mb-2">
                        Transaction Date <span className="text-red-300">*</span>
                    </label>
                    <FlatPickrDateInput
                        name="transactiondate"
                        value={userPayroll?.transactiondate || ''}
                        id="transactiondate"
                        disabled={!canEdit}
                        className="outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]"
                        onChangeDate={handleDates}
                    />
                    {!userPayroll?.transactiondate && checkValidation && (
                        <small className="text-red-400">
                            transactiondate is required!
                        </small>
                    )}
                </div>
            </div>
            {canEdit ? (
                <div className="fixed bottom-0 mt-2 right-6">
                    <button
                        onClick={() => setCanEdit(null)}
                        className="text-primary bg-gray-100 w-[80px] rounded-md py-2"
                    >
                        Cancel
                    </button>
                    <PrimaryButton
                        onClick={handleSubmit}
                        className="py-2 mx-2 rounded-md bg-primary"
                    >
                        Save
                    </PrimaryButton>
                </div>
            ) : (
                <div className="h-12" />
            )}
            {canDelete ? (
                <div className='fixed bottom-0 mt-2 right-6'>
                    <div className='flex items-center justify-end gap-2'>
                        <span className=""> Do you really want to delete this record? </span>
                        <PrimaryButton className='px-6 border bg-white  border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
                            onClick={handleDelete}
                        >
                            Yes
                        </PrimaryButton>
                        <PrimaryButton className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                            onClick={() => setCanDelete(null)}
                        >
                            No
                        </PrimaryButton>
                    </div>
                </div>
            ) : (
                <div className="h-12" />
            )}
        </div>
    )
}

export default memo(EditDetails)
