import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const PieChartCustomer = ({ listOfCustomers }) => {
    const data = listOfCustomers?.slice(0, 6)?.map(customer => ({
        name: customer.customername,
        y: Math.floor(Math.random() * 500),
    }));

    const options = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'By Account',
        },
        series: [
            {
                name: 'Customers',
                data: data,
            },
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChartCustomer;
