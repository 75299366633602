import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../defaultDetails';

const StateDetails = props => {
	const { countryList } = useSelector(state => state.UserManagementList)
	const { detailSave, detailsData, setDetailsData } = props
	const [checkvalidation, setCheckvalidation] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCheckvalidation(true);

		let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
		if (isValidated?.isvalid) {
			let payload = {
				statejson: [detailsData],
			}
			detailSave(payload)
		}
	}
	
	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='statename' className='block font-[500] mb-2'>
						State Name
						<span className="text-red-300"> *</span>
					</label>
					<input
						type='text'
						onChange={(e) => {
							if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
								handleChangeInput('statename', e.target.value)
							}
						}}
						title='statename'
						id='statename'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='statename'
						required=''
						maxLength={50}
					/>
					{!detailsData?.statename && checkvalidation && (
						<small className='text-red-400'>State Name is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='statecode' className='block font-[500] mb-2'>
						State Code
					</label>
					<input
						disabled
						type='text'
						title='statecode'
						id='statecode'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='statecode'
						required=''
						onChange={e => handleChangeInput('statecode', e.target.value)}
					/>
				</div>
				<div>
					<label htmlFor='comcountryid' className='block font-[500] mb-2'>
						Country
						<span className="text-red-300"> *</span>
					</label>
					<CustomhtmlSelect
						options={countryList || []}
						value={detailsData?.comcountryid || ''}
						noDataLabel='No country'
						name='comcountryid'
						labelkey='countryname'
						className='border rounded bg-gray-50 py-2'

						onChange={handleChangeInput}
						id='comcountryid'
					/>
					{!detailsData?.comcountryid && checkvalidation && (
						<small className='text-red-400'>Country is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				<div className='gap-2 flex justify-end items-center'>
					<PrimaryButton
						className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
						onClick={handleSubmit}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default memo(StateDetails)
