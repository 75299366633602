
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useState, useRef, useEffect, memo } from 'react';

function SingleSelectField({ props }) {
    let { id,
        value,
        handleOnchange,
        disabled = false,
        name } = props
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="group relative ">
                <button className='my-0.5 text-primary' onClick={() => setOpen(!open)}>
                    <ChatBubbleBottomCenterTextIcon className=' text-primary w-[19px] ' />
                </button>
            </div>
            {open && <div className="p-2 transition right-0 z-[1000] ease-in duration-100 absolute mt-1 w-72 min-w-[90px] rounded-sm bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className='border rounded-md bg-gray-50'>
                    <textarea rows={3}
                        onChange={handleOnchange}
                        placeholder='Maximum 200 characters allowed'
                        maxLength={200}
                        id={id}
                        disabled={disabled}
                        className='text-xs placeholder:text-xs placeholder:italic w-full bg-gray-50 outline-none rounded-md p-1 '
                        value={value || ''}
                        name={name} />
                </div>
            </div>}
        </div>
    )
}


const EmployeeNotePopup = (props) => {
    // console.log(props)
    return (
        <div className=' w-full'>
            <SingleSelectField props={props} />
        </div>
    )
}

export default memo(EmployeeNotePopup);
