import { ACTION_GET_TASK_TIMESHEET_CONFIG, ACTION_GET_TASK_BY_PROJECTID_TIMESHEET, ACTION_GET_TIMESHEET_ORG_TASKS_BY_PRO_ID, ACTION_GET_MANAGER_TIMESHEET_RECORDS } from "src/Redux/types"


const initialState = {
    projectsOfE: [],
    projectsOfM: null,
    timesheetUserUnderManager: [],
    taskOrgType: null,
    taskType: [{
        "tasktypeid": 0,
        "tasktype": "All"
    }],
    getTimesheetReport: [],
    getProjectEffort: null,
    getTaskTimesheetConfig: null,
    getTaskByProjectTimesheet: null,
    getTasksByTaskType: null,
    getManagerTimesheetRecord: [],
    employeeTimesheetData: []
}

const ProjectTimesheet = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TIMESHEET_PROJECTS_E':
            return { ...state, projectsOfE: action.data?.Message ? [] : action.data }
        case 'GET_TIMESHEET_PROJECTS_M':
            return { ...state, projectsOfM: action.data?.Message ? [] : action.data }
        case 'GET_TIMESHEET_USERLIST':
            return { ...state, timesheetUserUnderManager: action.data?.Message ? [] : action.data }
        case 'GET_TIMESHEET_TASK_TYPE':
            return {
                ...state, taskType: action.data?.Message ? [] : [{
                    "tasktypeid": 0,
                    "tasktype": "All"
                }, ...action.data]
            }
        case 'GET_TIMESHEET_REPORT_STARTDATE_ENDDATE':
            return { ...state, getTimesheetReport: action.data?.Message ? [] : action.data }
        case 'GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT':
            return { ...state, getProjectEffort: action.data?.Message ? [] : action.data }
        case 'SET_EMPLOYEE_TIMESHEET_DATA':
            return { ...state, employeeTimesheetData: action.data }
        case ACTION_GET_TIMESHEET_ORG_TASKS_BY_PRO_ID:
            // console.log(action)
            return { ...state, getTasksByTaskType: action.data?.Message ? [] : action.data }
        case ACTION_GET_TASK_TIMESHEET_CONFIG:
            return { ...state, getTaskTimesheetConfig: action.data?.Message ? [] : action.data }
        case ACTION_GET_TASK_BY_PROJECTID_TIMESHEET:
            return { ...state, getTaskByProjectTimesheet: action.data?.Message ? [] : action.data }
        case ACTION_GET_MANAGER_TIMESHEET_RECORDS:
            return { ...state, getManagerTimesheetRecord: action.data?.Message ? [] : action.data }
        default:
            return state
    }
}

export default ProjectTimesheet