const initialState = {
    reportMasterList: [],
    timesheetreportForEmployee: [],
    statusListByType: [],
    capacityreport: [],
    financereport: [],
    staffingDetailsReport: [],
    utilizationreportforEmployee: [],
    timesheetreportForNonCompliance: [],
    timesheetreportForException: [],
    releasePlanReport: [],
    pdpAgingReport: [],
    exitPipelineReport: [],
    demandSummaryReport: [],
    projectwiseReport: [],
    timesheetTrackingReport: []
}

const WorkforceReport = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REPORT_MASTER_LIST':
            return { ...state, reportMasterList: action.data }
        case 'GET_LIST_OF_STATUS':
            return { ...state, statusListByType: action.data }
        case 'GET_TIMESHEET_REPORT_FOR_EMPLOYEE':
            return { ...state, timesheetreportForEmployee: action.data }
        case 'GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE':
            return { ...state, timesheetreportForNonCompliance: action.data }
        case 'GET_TIMESHEET_REPORT_FOR_EXCEPTION':
            return { ...state, timesheetreportForException: action.data }
        case 'GET_CAPACITY_REPORT':
            return { ...state, capacityreport: action.data }
        case 'GET_RELEASE_PLAN_REPORT':
            return { ...state, releasePlanReport: action.data }
        case 'GET_PDP_AGING_REPORT':
            return { ...state, pdpAgingReport: action.data }
        case 'GET_FINANCE_REPORT':
            return { ...state, financereport: action.data }
        case 'GET_STAFFING_DETAILS_REPORT':
            return { ...state, staffingDetailsReport: action.data }
        case 'GET_UTILIZATION_REPORT_FOR_EMPLOYEE':
            return { ...state, utilizationreportforEmployee: action.data }
        case 'GET_EXIT_PIPELINE_REPORT':
            return { ...state, exitPipelineReport: action.data }
        case 'GET_DEMAND_SUMMARY_REPORT':
            return { ...state, demandSummaryReport: action.data }
        case 'GET_PROJECT_WISE_UTILIZATION_REPORT':
            return { ...state, projectwiseReport: action.data }
        case 'GET_TIMESHEET_TRACKING_REPORT':
            return { ...state, timesheetTrackingReport: action.data }

        default:
            return state
    }
}

export default WorkforceReport
