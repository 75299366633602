import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "src/Components/Buttons/IconButton";
import { DOWNLOAD_CUSTOMER_DOCUMENT } from "src/Redux/actions/masterdata/customer";
import { AlertError, getFileExtension } from "src/Services";

const allowedFileTypes = ["pdf", "docx", "xlsx", "pptx", "jpg", "png", "zip", "rar", "jpeg"];

const UploadDocument = (props) => {
    const dispatch = useDispatch()
    const [binaryData, setBinaryData] = useState('')
    const Projects = useSelector(state => state?.Projects)
    let { getProjectDocument } = Projects
    const { item: { uId, fileformat, filepath, filename, projectdocumentattachmentsid }, setDocuments, documents } = props

    async function getBase64() {
        let res = await dispatch(DOWNLOAD_CUSTOMER_DOCUMENT(filepath))
        let afterChange = getProjectDocument?.map((item) => {
            if (item?.uId === uId) {
                setBinaryData(res?.res?.data)
                const response = res?.res?.data; // (your complete response string)
                const base64Encoded = btoa(unescape(encodeURIComponent(response)));
                item['file'] = base64Encoded
            }
            return item;
        });
        dispatch({ type: 'SAVE_PROJECT_DOCUMENT', data: afterChange })
    }

    useEffect(() => {
        if (filepath) {
            getBase64()
        }
        // eslint-disable-next-line
    }, [filepath])

    const handleFileChange = (e) => {
        const fileInput = e.target;
        const { files } = e.target;
        if (files?.length) {
            let doc = files[0];
            const fileExtension = getFileExtension(doc?.name);

            if (!allowedFileTypes.includes(fileExtension)) {
                fileInput.value = null;
                AlertError(`Invalid file type. Allowed formats are : ${allowedFileTypes}`);
                return
            }

            const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB
            // Check if the file size exceeds the limit
            if (doc.size > maxSizeInBytes) {
                AlertError("Please choose a file smaller than 5 MB.");
                // Optionally, you can clear the file input
                fileInput.value = null;
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                // The result property contains the base64 string
                const base64 = reader.result.split(',')[1];
                let afterChange = documents?.map((item) => {
                    if (item?.uId === uId) {
                        item['file'] = base64;
                        item['filename'] = doc?.name
                        item['fileformat'] = doc?.type
                    }
                    return item;
                });
                setDocuments(afterChange);
            };

            // Read the file as a data URL
            reader.readAsDataURL(doc);
        }

    }

    const downloadFile = async () => {
        if (binaryData) {
            const blob = new Blob([binaryData], { type: fileformat });
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            // Specify the file name (you can extract it from the response headers)
            downloadLink.download = filename;
            // Append the link to the document and trigger the click event
            document.body.appendChild(downloadLink);
            downloadLink.click();
            // Clean up by removing the link
            document.body.removeChild(downloadLink);
        }
    }

   
    return (
        <div>
            {
                filename ?
                    <>
                        {projectdocumentattachmentsid ? <IconButton title='Download Document' className='w-6 h-6 p-0 bg-primary flex justify-center text-white items-center' onClick={downloadFile}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                            </svg>
                        </IconButton> : ''}
                    </> : <label>
                        <span className="flex justify-center items-center w-6 h-6 bg-primary text-white rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                            </svg>
                        </span>
                        <input
                            accept=".pdf, .docx, .xlsx, .pptx, .jpg, .png, .zip, .rar, .jpeg"
                            onChange={handleFileChange}
                            className="hidden" id={uId} type="file" />
                    </label>
            }
        </div>
    )
}

export default UploadDocument
