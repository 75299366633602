import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { GET_EDUCATIONBACKGROUND_LIST } from 'src/Redux/actions/workforce/eprofile'

const EducationBackground = () => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector((state) => state?.EmployeeProfile)
	const { academicQualificationList } = EmployeeProfile
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const userId = searchParams.get('userid')
	const tenid = searchParams.get('tenid')

	useEffect(() => {
		dispatch(
			GET_EDUCATIONBACKGROUND_LIST({
				comuserid: +userId,
				Tenantid: +tenid,
			}),
		)
	}, [dispatch, userId, tenid])

	const isValidEducation = (item) => {
		return item.educationmaster && item.university_institute && item.yearofpassing
	}

	return (
		<div className="text-sm h-[74vh] my-2">
			<table className="w-full text-left text-textSecondary">
				<thead className="capitalize bg-gray-50">
					<tr className="bg-gray-100 border-b">
						<th className="border font-[500] px-1 py-1"> Qualification</th>
						<th className="border font-[500] px-1 py-1"> Stream</th>
						<th className="border font-[500] py-1"> Specialization</th>
						<th className="border font-[500] py-1"> Institution</th>
						<th className="border font-[500] py-1"> Completed</th>
						<th className="border font-[500] px-1 py-1"> Marks</th>
						<th className="border font-[500] px-1 py-1"> Grade</th>
					</tr>
				</thead>
				<tbody>
					{academicQualificationList?.length > 0 ? (
						academicQualificationList.map(
							(item, i) =>
								isValidEducation(item) && (
									<tr key={i} className="bg-white border-b">
										<td className="px-2 py-1 border">
											{item.educationmaster}
										</td>
										<td className="px-2 py-1 border">
											{item.coursemaster}
										</td>
										<td className="px-2 py-1 border">
											{item.specialisation}
										</td>
										<td className="px-2 py-1 border">
											{item.university_institute}
										</td>
										<td className="px-2 py-1 border w-28">
											{item.yearofpassing}
										</td>
										<td className="px-2 py-1 border">
											{item.percentageofmarks}
										</td>
										<td className="px-2 py-1 border">
											{item.gradeorclass}
										</td>
									</tr>
								),
						)
					) : (
						<tr>
							<td colSpan="7" className="py-3 text-center">
								No educational qualifications available.
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default EducationBackground
