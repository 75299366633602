import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { CostRatesItem } from './default';
import AddEditCostRates from './AddeditCostRates';
import IconButton from 'src/Components/Buttons/IconButton';
import Company from './AddeditCostRates/costDetails/company';
import SingleSelectField from 'src/Components/SingleSelectForTable';
import { GET_COST_RATES } from 'src/Redux/actions/masterdata/user';
import { GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getTenantCompanyIdData } from 'src/Services/constant/defaultPayload';
import { getDefaultCostRates } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = ['Band', 'Skill Area', 'Tech Group', 'Country', 'Rate', 'Currency', 'Unit', 'Start Date', 'End Date', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const quarters = [
  { label: 'Q1', value: '-04-01,-06-30' },
  { label: 'Q2', value: '-07-01,-09-30' },
  { label: 'Q3', value: '-10-01,-12-31' },
  { label: 'Q4', value: '-01-01,-03-31' }
]
const yearsData = Array.from({ length: 21 }, (_, index) => ({
  value: 2020 + index,
  label: 2020 + index,
}));

const getStartAndEndDate = () => {
  let startdate = `${new Date().getFullYear()}${quarters[0]?.value?.split(',')[0]}`
  let enddate = `${new Date().getFullYear()}${quarters[0]?.value?.split(',')[1]}`
  return { startdate, enddate }
}

const CostRatesMasterlist = () => {
  const [dm, setDm] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [addOrEdit, setAddOrEdit] = useState(null)
  const [orgcostRateId, setOrgCostRateId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [costrateData, setCostrateData] = useState(CostRatesItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
  const [company, setCompany] = useState({ companyid: 0 })
  const [selectQ, setSelectQ] = useState(quarters[0])
  const [year, setYear] = useState({ value: new Date().getFullYear(), label: new Date().getFullYear() })
  const [startEndDate, setStartEndDate] = useState(getStartAndEndDate())
  const dispatch = useDispatch();
  const {companylist} = useSelector((state) => state?.EmployeeDetails);
  const {costratesList, loading: costRatesLoading} = useSelector((state) => state?.UserManagementList);

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(costRatesLoading); // Hide the loader after a delay
    }, 100);
  }, [costRatesLoading]);


  useEffect(() => {
    dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()));
    // eslint-disable-next-line
  }, [])

  useMemo(() => {
    if (dm?.costrate) {
      setSearchTerm(dm?.costrate);
    }
  }, [dm]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_COST_RATES({
      ...getDefaultCostRates(),
      Sorttype: item?.id, Status: status?.label, Limit: limit,
      Companyid: company?.companyid,
      Startdate: startEndDate?.startdate,
      Enddate: startEndDate?.enddate,
      Searchname: searchTerm.trim().split(/\s+/).join(' '), // Splitting and joining the search terms
    }));
    if (res !== null) {
      setCostrateData(res?.data);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_COST_RATES({
      ...getDefaultCostRates(),
      Status: item.label, Limit: limit, Page: page,
      Companyid: company?.companyid,
      Startdate: `${year?.value}${selectQ?.value?.split(',')[0]}`,
      Enddate: `${year?.value}${selectQ?.value?.split(',')[1]}`,
    }));
    if (res !== null) {
      setCostrateData(res.data);
    }
  };

  const handleSkillClick = (costrate) => {
    // console.log(costrate)
    let findCom = companylist.find(c=> c?.companyid === costrate.companyid)
    setCompany(findCom)
    setCostrateData(costrate);
    setOrgCostRateId(costrate?.orgcostrateid);
    setIsOpen(true);
    setAddOrEdit('Edit')
  };

  const handlePagination = async (page) => {
    // console.log(page)
    let res = await dispatch(
      GET_COST_RATES({
        ...getDefaultCostRates(), limit,
        Page: page?.selected + 1,
        Sorttype: sortType?.id,
        Status: status?.label,
        Companyid: company?.companyid,
        Startdate: startEndDate?.startdate,
        Enddate: startEndDate?.enddate,
      }));
    if (res?.success) {
      setCostrateData(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    setPage(1)
    let res = await dispatch(
      GET_COST_RATES({
        ...getDefaultCostRates(), Limit: row, Page: 1,
        Sorttype: sortType?.id, Searchname: searchTerm,
        Status: status?.label,
        Companyid: company?.companyid,
        Startdate: startEndDate?.startdate,
        Enddate: startEndDate?.enddate,
      }));
    if (res?.success) {
      setCostrateData(res?.data);
    }
  };

  const selectCompany = (e, uId) => {
    let { item } = e.target;
    setCompany(item)
    let payload = {
      ...getDefaultCostRates(), Limit: limit, Page: page,
      Sorttype: sortType?.id,
      Status: status?.label,
      Companyid: item?.companyid,
      Startdate: startEndDate?.startdate,
      Enddate: startEndDate?.enddate,
      // Startdate: `${year?.value}${selectQ?.value?.split(',')[0]}`,
      // Enddate: `${year?.value}${selectQ?.value?.split(',')[1]}`,
    }
    // console.log(payload)
    dispatch(GET_COST_RATES(payload))
  }

  const selectYear = (year) => {
    let value = selectQ?.label === 'Q4' ? Number(year?.value) + 1 : year?.value
    setYear(year)
    setStartEndDate({
      startdate: `${value}${selectQ?.value?.split(',')[0]}`,
      enddate: `${value}${selectQ?.value?.split(',')[1]}`,
    })
    let payload = {
      ...getDefaultCostRates(), Limit: limit, Page: page,
      Sorttype: sortType?.id,
      Status: status?.label,
      Startdate: `${value}${selectQ?.value?.split(',')[0]}`,
      Enddate: `${value}${selectQ?.value?.split(',')[1]}`,
      Companyid: company?.companyid,
    }
    dispatch(GET_COST_RATES(payload))
  };

  const selectQuarters = (q) => {
    setSelectQ(q)
    let yearNum = q?.label === 'Q4' ? Number(year?.value) + 1 : year?.value
    setStartEndDate({
      startdate: `${yearNum}${q?.value?.split(',')[0]}`,
      enddate: `${yearNum}${q?.value?.split(',')[1]}`,
    })
    let payload = {
      ...getDefaultCostRates(), Limit: limit, Page: page,
      Sorttype: sortType?.id,
      Status: status?.label,
      Startdate: `${yearNum}${q?.value?.split(',')[0]}`,
      Enddate: `${yearNum}${q?.value?.split(',')[1]}`,
      Companyid: company?.companyid,
    }
    dispatch(GET_COST_RATES(payload))
  };

  // console.log(companylist)
  const YearCompanyQ = () => {
    return (
      <>
        <div>
          <div className=' w-44 border rounded px-2 bg-gray-50 py-1'>
            <Company
              disabled={addOrEdit === 'Edit'}
              value={company?.companyid}
              rowId={company?.uId}
              id={company?.uId}
              handleChange={selectCompany}
              options={[{ companyid: 0, companyname: 'All' }, ...companylist]}
            />
          </div>
        </div>
        <div>
          <div className='bg-gray-50 w-20 border rounded px-2  py-1'>
            <SingleSelectField
              disabled={addOrEdit === 'Edit'}
              valueKey='value'
              labelKey='label'
              options={yearsData}
              value={year?.label}
              handleSelected={selectYear}
            />
          </div>
        </div>
        <div>
          <div className='bg-gray-50 w-20 border rounded px-2  py-1'>
            <SingleSelectField
              disabled={addOrEdit === 'Edit'}
              valueKey='value'
              labelKey='label'
              options={quarters}
              value={selectQ?.value}
              handleSelected={selectQuarters}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='mt-4 h-screen'>
      <div className='mb-2 flex justify-between items-center'>
        <div className='flex justify-start items-center space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Cost Rates ({costratesList?.length > 0 ? costratesList[0]?.totalcount : costratesList?.length}){' '}
          </h1>
          <div className=' w-56 '>
            {companylist?.length > 0 && <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_COST_RATES}
              searchPayload={{
                ...getDefaultCostRates(),
                Searchname: searchTerm,
                Companyid: company?.companyid,
                Startdate: startEndDate?.startdate,
                Enddate: startEndDate?.enddate,
                Limit: limit,
                Page: 1,
                Sorttype: sortType?.id,
                Status: status?.label,

              }}
              setSearchTerm={setSearchTerm}
              idkey='orgcostrateid'
              optionlabelKey='skillarea'
              optionLabelkey2='techarea'
              getSelectedOption={setDm}
              placeholder='Enter Skill Area / Tech '
              className='p-2'
            />}
          </div>
          <div className='flex justify-start items-center space-x-6'>
            <ReactPaginationStyle
              total={costratesList?.length > 0 ? costratesList[0]?.totalcount : costratesList?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className=' w-24 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <div className=' w-28 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='border rounded bg-gray-50 py-2'
            />
          </div>
          <YearCompanyQ />
        </div>
        <IconButton
          title='Create New Cost Rate'
          onClick={() => {
            setIsOpen(true)
            setAddOrEdit('Add')
          }}
          className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </IconButton>
      </div>
      <div className="h-[46vh] overflow-scroll">
        {loading || costratesList === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          costratesList && (<table className='text-sm w-full relative overflow-y-scroll'>
            <thead>
              <tr className='text-left capitalize bg-gray-50'>
                {cols?.map((col) => {
                  return (
                    <th
                      key={col}
                      title={col}
                      scope='col'
                      className='font-[500] px-2 py-1 border text-start'
                    >
                      {col}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {costratesList?.map((item, i) => {
                let {
                  bandname,
                  skillarea,
                  techarea,
                  countryname,
                  cost,
                  currency,
                  unitname,
                  startdate,
                  enddate,
                  isactiveflag,
                  username,
                  createdtimestamp,
                  orgcostrateid,
                } = item;
                return (
                  <tr
                    onClick={() => handleSkillClick(item)}
                    key={orgcostrateid}
                    className='cursor-pointer text-left'>
                    <td className='border px-2 py-1.5'><div>{bandname}</div></td>
                    <td className='border px-2 py-1.5'><div>{skillarea}</div></td>
                    <td className='border px-2 py-1.5'><div>{techarea}</div></td>
                    <td className='border px-2 py-1.5'> <div>{countryname}</div></td>
                    <td className='border px-2 py-1.5'><div>{cost}</div></td>
                    <td className='border px-2 py-1.5'><div>{currency}</div></td>
                    <td className='border px-2 py-1.5'><div>{unitname}</div></td>
                    <td className='border px-2 py-1.5'><div>{moment(startdate).format('DD-MM-YYYY')}</div></td>
                    <td className='border px-2 py-1.5'><div>{moment(enddate).format('DD-MM-YYYY')}</div></td>
                    <td className='border px-2'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                    <td className='border px-2'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                    <td className='border px-2'><div>{username}</div></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          ))}

      </div>
      {isOpen && <AddEditCostRates
        startEndDate={startEndDate}
        company={company}
        setAddOrEdit={setAddOrEdit}
        YearCompanyQ={YearCompanyQ}
        setIsOpen={setIsOpen}
        addOrEdit={addOrEdit}
        isOpen={isOpen}
        orgcostrateid={orgcostRateId}
        setCostrateData={setCostrateData}
        costrateData={costrateData}
        page={page}
        limit={limit}
        status={status}
        sortType={sortType}
      // permissions={PermissionsOfMasterdata}

      />}
    </div>
  );
};

export default CostRatesMasterlist;
