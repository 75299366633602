import { useEffect, useState } from 'react'
import LoginForm from 'src/Components/Auth/login'
import { useNavigate } from 'react-router-dom'
import { GET_TENANT_CODE, RESET_PASSWORD, USER_LOGIN_HANDLE } from 'src/Redux/actions/auth'
import { useDispatch } from 'react-redux'
import ResetPassword from 'src/Components/Auth/resetpassword'
import { AlertSuccess } from 'src/Services'
import { ToastContainer } from 'react-toastify'
import { useMsal } from "@azure/msal-react";
import authConfig from 'src/authConfig'
// import { loginRequest } from "./authConfig";

const Loginlayout = () => {
    const { instance, accounts } = useMsal();
    const [code, setCode] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginInfoData, setLoginInfoData] = useState(null)
    const [resetPassword, setResetPassword] = useState(false)

    const handleLoginMS = () => {
        instance.loginPopup(authConfig).catch(e => {
            console.error(e);
        });
    };

    const saveLoginInfo = (data, token) => {
        localStorage.setItem('user', JSON.stringify(data[0]))
        localStorage.setItem('companyid', JSON.stringify(data[0]?.companyid))
        localStorage.setItem(
            'comuserid',
            JSON.stringify(data[0]?.comuserid)
        )
        localStorage.setItem('menuinfo', JSON.stringify(data))
        if (token) {
            localStorage.setItem('token', JSON.stringify(token))
        }
        // localStorage.setItem('primaryColor', JSON.stringify(userExist?.primaryColor))
        // localStorage.setItem('secondaryColor', JSON.stringify(userExist?.secondaryColor))
        navigate('/app/home')
    }

    const handleLogin = async payload => {
        let res = await dispatch(
            USER_LOGIN_HANDLE({ ...payload, password, Tenantid: code })
        )
        if (res?.success) {
            let data = res?.data
            if (data[0]?.passwordresetrequired && process.env.REACT_APP_LOGIN_TYPE === 'prosrvusernamepwd') {
                AlertSuccess('Login successful. Please reset your password to proceed!', '', 3000)
                setLoginInfoData(data)
                setResetPassword(true)
            } else {
                saveLoginInfo(data, res?.token)
            }
        }
    }

    useEffect(() => {
        if (accounts.length > 0) {
            let payload = {
                loginmethod: process.env.REACT_APP_LOGIN_TYPE,
                username: accounts[0]?.username
            }
            handleLogin(payload)
        }
     // eslint-disable-next-line
    }, [accounts])

    useEffect(() => {
        async function fetchCode() {
            let brand = {
                tenantcode: process.env.REACT_APP_TENANT_CODE,
            }
            let res = await dispatch(GET_TENANT_CODE(brand))
            localStorage.setItem('Tenantid', res?.data?.comtenantid)
            localStorage.setItem('tenantname', res?.data?.tenantname)
            document.title = `${res?.data?.tenantname} - ProSrv360` || '';
            setCode(res?.data?.comtenantid)
        }
        fetchCode()
    }, [dispatch])

    const handleResetPassword = async (new_, re_) => {
        let payload = {
            Tenantid: code,
            comuserid: loginInfoData[0]?.comuserid,
            old_password: password,
            new_password: new_,
            re_password: re_
        }
        let res = await dispatch(RESET_PASSWORD(payload))
        if (res?.success) {
            saveLoginInfo(loginInfoData)
        }
    }

    return (
        <>
            <section
                style={{ backgroundImage: `url(${process.env.REACT_APP_LOGIN_BG})` }}
                className="h-screen bg-center bg-no-repeat bg-cover"
            >
                <div className="flex items-center justify-center h-full">
                    <div className="bg-white rounded-lg shadow-lg ">
                        {resetPassword ? <ResetPassword
                            handleResetPassword={handleResetPassword}
                        /> : <LoginForm
                            handleLogin={process.env.REACT_APP_LOGIN_TYPE === 'prosrvusernamepwd' ? handleLogin : handleLoginMS}
                            hooks={{
                                username, setUsername,
                                password, setPassword
                            }} />}

                    </div>
                </div>
            </section>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default Loginlayout
