import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import DemandlistFilter from 'src/Filter/workforce/demand'
import {
    GET_DEMAND_LIST_MASTER,
    GET_DEMAND_ROLE_MASTER,
} from 'src/Redux/actions/workforce/demands'
import {
    GET_SKILL_MASTER,
    GET_TECHNOLOGY_MASTER,
} from 'src/Redux/actions/workforce/eprofile'
import { GET_CITY_MASTER, GET_COMPANY_MASTER } from 'src/Redux/actions/workforce/talent'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import { getDefaultDemandPayload } from 'src/Services/constant/Demandpayload'
import {
    getCityPayload,
    getDeafultTechnologyData,
    getTenantCompanyIdData,
} from 'src/Services/constant/defaultPayload'
import {
    getDeafultRoleData,
    getDeafultSkillData,
} from 'src/Services/constant/masterdatapayload'
import { basicShortList } from 'src/Services/constant/staticValues'
import Cards from './card'
import CreateDemand from './createDemand'

const rows = [10, 25, 50]

const WorkforceDemand = ({ modulename, menu }) => {
    const dispatch = useDispatch()
    const { demandList } = useSelector((state) => state?.DemandDetailsList)
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filters, setFilters] = useState(getDefaultDemandPayload())
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const AppliedFilters = searchParams.get('AppliedFilters')
    const [Sorttype, setSortType] = useState({ label: 'Latest First', id: 3 })

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(
            (item) => item?.permissionname,
        )
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [menu])

    const getDefaultDemandData = () => {
        dispatch(
            GET_DEMAND_LIST_MASTER({
                ...filters,
                Limit: limit,
                Page: page,
                Sorttype: Sorttype?.id,
            }),
        )
    }

    useEffect(() => {
        dispatch(GET_CITY_MASTER({ ...getCityPayload(), sorttype: 1 }))
        dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
        dispatch(GET_SKILL_MASTER({ ...getDeafultSkillData(), sorttype: 1 }))
        dispatch(GET_TECHNOLOGY_MASTER({ ...getDeafultTechnologyData(), sorttype: 1 }))
        dispatch(GET_DEMAND_ROLE_MASTER({ ...getDeafultRoleData(), sorttype: 1 }))
        // eslint-disable-next-line
    }, [dispatch])

    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_DEMAND_LIST_MASTER({
                ...filters,
                Limit: limit,
                Page: page,
                Sorttype: item?.id,
            }),
        )
    }

    const handleSearch = (search) => {
        // console.log(search)
    }

    const handlePagination = async (page) => {
        dispatch(
            GET_DEMAND_LIST_MASTER({
                ...filters,
                Limit: limit,
                Page: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_DEMAND_LIST_MASTER({
                ...filters,
                Limit: row,
                Page: row > 10 ? 1 : page,
            }),
        )
    }

    const payloadWithAppliedFilter = () => {
        let applied = JSON.parse(AppliedFilters)
        if (applied) {
            return applied
        } else {
            return getDefaultDemandPayload()
        }
    }

    return (
        <>
            <div className="w-full h-screen p-2 overflow-y-scroll">
                <div className="p-2 py-3 bg-white rounded-md shadow">
                    <DemandlistFilter
                        filters={filters}
                        setFilters={setFilters}
                        page={page}
                        limit={limit}
                        sortType={Sorttype}
                    />
                </div>
                <div className="flex items-center justify-between py-4">
                    <div className="flex items-center justify-start space-x-6">
                        <p className="text-primary font-[500]">
                            Demands (
                            {demandList?.length > 0
                                ? demandList[0]?.totalcount || 0
                                : demandList?.length || 0}
                            )
                        </p>
                        <div className=" w-96">
                            <CustomAutoSearch
                                hideOptions={true}
                                searchTerm={searchTerm}
                                API_CALL_FOR_SEARCH={GET_DEMAND_LIST_MASTER}
                                searchPayload={{
                                    ...payloadWithAppliedFilter(),
                                    Searchname: searchTerm,
                                }}
                                setSearchTerm={setSearchTerm}
                                options={demandList}
                                idkey="role"
                                optionlabelKey="jobtitle"
                                className="p-2"
                                bgcolor="bg-white"
                                getSelectedOption={handleSearch}
                                placeholder="Enter Job Title"
                            />
                        </div>
                        <ReactPaginationStyle
                            total={
                                demandList?.length > 0
                                    ? demandList[0]?.totalcount
                                    : demandList?.length
                            }
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <FilterButtonTab
                            setSelectedtab={handleRows}
                            selectedTab={limit}
                            tabs={rows}
                        />
                        <div className="w-32 ">
                            <SelectInput
                                options={basicShortList || []}
                                keyname="label"
                                selected={Sorttype}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                    {thisPagePermissions.includes('Add Demand') ? (
                        <CreateDemand getDefaultDemandData={getDefaultDemandData} />
                    ) : (
                        ''
                    )}
                </div>
                {demandList === null ? (
                    <div className="flex items-center justify-center w-full h-1/2">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                Loading...
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4 mt-4 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
                        {demandList?.map((item) => {
                            return (
                                <Cards id={item?.demandid} key={item?.demandid} item={item} />
                            )
                        })}
                    </div>
                )}
            </div>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default WorkforceDemand
