

import SingleSelectField from "src/Components/SingleSelectForTable";

const DocumentType = ({ options, disabled, value, rowId, handleChange, name }) => {

    const getSelected = (value) => {
        let e = { target: { name, value: value['documenttypeid'], itemInfo: value } }
        handleChange(e, rowId)
    }

    return (
        <div>
            <SingleSelectField
                valueKey='documenttypeid'
                labelKey='documenttype'
                options={options}
                value={value}
                handleSelected={getSelected}
                disabled={disabled} />
        </div>
    )
}

export default DocumentType;