import { postMethodApiCall } from 'src/Services/api';
import { AlertError, AlertSuccess } from 'src/Services';
import {
  getSourceId,
  getCountryId,
  getTenantCustomerId,
} from 'src/Services/constant/defaultPayload';
import {
  getTenRegionId, getTenCompanyId, getTenIndustryId
} from 'src/Services/constant/Customerpayload';
import axios from 'axios';
import {
  ACTION_GET_CUSTOMER_MASTERLIST,
  ACTION_GET_CUSTOMER_BY_ID,
  ACTION_GET_COMPANY_MASTER_FILTER,
  ACTION_GET_REGION_MASTER_FILTER,
  ACTION_GET_INDUSTRY_MASTER_FILTER,
  ACTION_GET_ACCOUNT_MASTER_FILTER,
  ACTION_GET_COUNTRY_MASTER_FILTER,
  ACTION_GET_SOURCE_MASTER_FILTER,
  ACTION_GET_ALL_CONTACTS_OF_A_CUSTOMER,
  ACTION_GET_ALL_ROLES_OF_A_CUSTOMER,
  ACTION_GET_CUSTOMER_DOCUMENT_LIST,
  ACTION_GET_CUSTOMER_DOCUMENT_TYPE_LIST
} from 'src/Redux/types';

const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER;

const getHeadersWithoutAuth = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const GET_CUSTOMER_MASTERLIST = (payload) => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/get_customermasterList`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_CUSTOMER_MASTERLIST,
          data: res?.data?.data
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_CUSTOMER_BY_ID = (customerid) => {
  return async (dispatch) => {
    try {
      let payload = {
        ...getTenantCustomerId(), customerid,
        searchname: ''
      }
      const url = `${baseUrl}/get_customermaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_CUSTOMER_BY_ID,
          data: res?.data?.data,
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_COMPANY_MASTER_FILTER = (data) => {
  return async (dispatch) => {
    try {
      let payload = {
        ...getTenCompanyId(),
        ...data,
        searchname: data?.searchname || '',
        status: 'ACTIVE'
      }
      const url = `${baseUrlMaster}/get_companymaster`;
      const res = await postMethodApiCall(
        url,
        getHeadersWithoutAuth(),
        payload
      );
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_COMPANY_MASTER_FILTER,
          data: res?.data?.data,
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_REGION_MASTER_FILTER = () => {
  return async (dispatch) => {
    try {
      let payload = {
        ...getTenRegionId(),
        searchname: '',
        status: 'ACTIVE'
      }
      const url = `${baseUrlMaster}/get_regionmaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_REGION_MASTER_FILTER,
          data: res?.data?.data,
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_INDUSTRY_MASTER_FILTER = (data) => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/get_industrymaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), { ...getTenIndustryId(), ...data });
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_INDUSTRY_MASTER_FILTER,
          data: res?.data?.data,
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_ACCOUNT_MASTER_FILTER = data => {
  return async dispatch => {
    try {
      const url = `${baseUrl}/get_customergroupmaster`
      const res = await postMethodApiCall(
        url,
        getHeadersWithoutAuth(),
        data,
      )
      let items = res?.data?.data
      if (items === null) AlertError('No roles found')
      let addnewkey = items?.map(item => {
        item['uId'] =
          Math.random().toString(36).substr(2, 9) +
          new Date().getTime().toString(36)
        return item
      })
      dispatch({ type: ACTION_GET_ACCOUNT_MASTER_FILTER, data: addnewkey || [] })
      return { success: true, data: addnewkey || [] }
    } catch (error) {
      AlertError(error?.response?.data).replace(/\\/g, '')
    }
  }
}

export const GET_COUNTRY_MASTER_FILTER = () => {
  return async (dispatch) => {
    try {
      let payload = {
        ...getCountryId(),
        searchname: ''
      }
      const url = `${baseUrlMaster}/get_countrymaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_COUNTRY_MASTER_FILTER,
          data: res?.data?.data,
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_SOURCE_MASTER_FILTER = () => {
  return async (dispatch) => {
    try {
      const url = `${baseUrlMaster}/get_sourcemaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), getSourceId());
      if (res?.status === 200 || res?.status === 201) {
        dispatch({
          type: ACTION_GET_SOURCE_MASTER_FILTER,
          data: res?.data?.data,
        });
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const SAVE_CUSTOMER_AS_DRAFT = (payload) => {
  return async () => {
    try {
      const url = `${baseUrl}/insert_customermaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), JSON.stringify(payload));
      if (res?.status === 200 || res?.status === 201) {
        let item = res?.data?.data
        const customerid = item?.customerid;
        return { success: true, customerid };
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
      return { success: false };
    }
  };
}
export const UPDATE_CUSTOMER_AS_DRAFT = (payload) => {
  return async () => {
    try {
      const url = `${baseUrl}/update_customermaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), JSON.stringify(payload));
      if (res?.status === 200 || res?.status === 201) {
        const customerid = res?.data?.data
        return { success: true, customerid };
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
      return { success: false };
    }
  };
};


export const GET_ALL_CONTACTS_OF_A_CUSTOMER = (customerId) => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/get_customercontacts`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), { customerid: customerId, Tenantid: Number(localStorage.getItem('Tenantid') || 0) });
      if (res?.status === 200 || res?.status === 201) {
        let items = res?.data?.data
        let addnewkey = items?.map((item) => {
          item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
          return item
        })
        dispatch({
          type: ACTION_GET_ALL_CONTACTS_OF_A_CUSTOMER,
          data: addnewkey || []
        },
        );
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_ALL_ROLES_OF_A_CUSTOMER = (payload) => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/get_customerrates`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);

      if (res?.status === 200 || res?.status === 201) {
        let items = res?.data?.data
        let addnewkey = items?.map((item) => {
          item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
          return item
        })
        dispatch({
          type: ACTION_GET_ALL_ROLES_OF_A_CUSTOMER,
          data: addnewkey || []
        },
        );
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const SAVE_CUSTOMER_CONTACTS = (data, id) => {
  return async () => {
    try {
      let newdataFrame = []
      for (let item of data) {
        let contact = {}
        contact['customerid'] = id
        contact['modifiedcomuserid'] = item?.modifiedcomuserid
        for (let key in item) {
          if (key !== 'uId') {
            contact[key] = item[key]
          }
        }
        newdataFrame.push(contact)
      }
      let payload = {
        customerjson: newdataFrame
      }
      const url = `${baseUrl}/insert_customercontacts`
      let res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
      let item = res?.data?.data
      if (res?.status === 200 || res?.status === 201) {
        const customerid = item?.customerid;
        if (item?.Message === 'Success') {
          return { success: true, customerid };
        } else {
          AlertError(item?.Message || 'Something went wrong. Please try later.')
        }
        return { success: true, customerid };
      }
    } catch (error) {
      AlertError(error?.response?.data).replace(/\\/g, '');
      return { success: false };
    }
  };
}

export const GET_CUSTOMER_DOCUMENT_LIST = (payload) => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/get_customerdocuments`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);

      if (res?.status === 200 || res?.status === 201) {
        let items = res?.data?.data
        let addnewkey = items?.map((item) => {
          item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
          return item
        })
        dispatch({
          type: ACTION_GET_CUSTOMER_DOCUMENT_LIST,
          data: addnewkey || []
        },
        );
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const GET_CUSTOMER_DOCUMENT_TYPE_LIST = () => {
  return async (dispatch) => {
    try {
      let payload = {
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
        documenttypeid: 0,
        Type: "C"
      }
      const url = `${baseUrlMaster}/get_documenttypemaster`;
      const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload);

      if (res?.status === 200 || res?.status === 201) {
        let items = res?.data?.data
        let addnewkey = items?.map((item) => {
          item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
          return item
        })
        dispatch({
          type: ACTION_GET_CUSTOMER_DOCUMENT_TYPE_LIST,
          data: addnewkey || []
        },
        );
      }
    } catch (error) {
      AlertError(error.response.data).replace(/\\/g, '');
    }
  };
};

export const SAVE_CUSTOMER_DOCUMENT = (data) => {
  return async () => {
    try {
      let newdataFrame = []
      for (let item of data) {
        let contact = { ...item }
        delete contact['uId']
        newdataFrame.push(contact)
      }

      const url = `${baseUrl}/insert_customerdocumentsNew`
      let res = await postMethodApiCall(url, getHeadersWithoutAuth(), newdataFrame)
      let item = res?.data?.data
      if (res?.status === 200 || res?.status === 201) {
        const customerid = item?.customerid;
        return { success: true, customerid };
      }
    } catch (error) {
      AlertError(error?.response?.data).replace(/\\/g, '');
      return { success: false };
    }
  };
}

export const DELETE_CUSTOMER_DOCUMENT = (id) => {
  return async () => {
    try {
      let payload = {
        customerdocumentattachmentsid: id,
        deletedcomuserid: Number(localStorage.getItem('comuserid') || 0),
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
      }
      const url = `${baseUrl}/delete_customerdocuments`
      let res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
      let item = res?.data?.data
      if (res?.status === 200 || res?.status === 201) {
        const customerid = item?.customerid;
        AlertSuccess('Document Deleted successfully!');
        return { success: true, customerid };
      }
    } catch (error) {
      AlertError(error?.response?.data).replace(/\\/g, '');
      return { success: false };
    }
  };
}

export const DOWNLOAD_CUSTOMER_DOCUMENT = (file_path) => {
  return async () => {
    try {
      let payload = {
        file_path,
        Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
      }
      const url = `${baseUrl}/Download_s3documentsDirect`
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: url,
        headers: {
          'Content-Type': 'text/plain',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },
        responseType: 'arraybuffer',
        data: payload
      };

      let res = await axios.request(config)
      if (res?.status === 200 || res?.status === 201) {
        return { success: true, res };
      }
    } catch (error) {
      AlertError(error?.response?.data).replace(/\\/g, '');
      return { success: false };
    }
  };
}