import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IconButton from 'src/Components/Buttons/IconButton'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import {
    GET_DEMAND_MASTER,
    SAVE_DEMAND_ROLE_MASTER,
} from 'src/Redux/actions/workforce/demands'
import { GET_BAND_MASTER } from 'src/Redux/actions/workforce/talent'
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation'
import { getBandMasterIdData } from 'src/Services/constant/defaultPayload'

const RoleDetails = ({ hooks }) => {
    const { band, setBand, roleData, setRoleData, demandData } = hooks
    const [checkValidation, setCheckValidation] = useState(null)
    const [maxBandError, setMaxBandError] = useState('')
    const { bandList } = useSelector((state) => state?.EmployeeDetails)
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const editDemandId = searchParams.get('demandid')

    useEffect(() => {
        if (demandData[0]?.demandroledetails?.length > 0) {
            const roles = demandData[0].demandroledetails?.[0]?.roleresponsibilities?.map(
                (r) => ({
                    ...r,
                    uId:
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36),
                }),
            )
            setRoleData(roles)
            setBand(demandData[0].demandroledetails[0])
        } else {
            // Initialize with default values if no data exists
            setRoleData([
                {
                    responsibilities: '',
                    uId:
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36),
                },
            ])
            setBand({
                demandroledetailid: null,
                demandid: 0,
                jobtitle: '',
                min_bandid: demandData[0]?.requesttype === 'P' ? 0 : null,
                max_bandid: demandData[0]?.requesttype === 'P' ? 0 : null,
                experienceinyear: null,
                rolesummary: '',
                comtenantid: Number(localStorage.getItem('Tenantid') || 0),
                isactiveflag: true,
                createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
                modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
                roleresponsibilities: [],
            })
        }
    }, [demandData, setBand, setRoleData])

    useEffect(() => {
        dispatch(GET_BAND_MASTER(getBandMasterIdData()))
    }, [dispatch])

    const handleAdd = () => {
        if (roleData?.length < 10) {
            setRoleData((prevRoleData) => [
                ...prevRoleData,
                {
                    uId:
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36),
                    responsibilities: '',
                },
            ])
        }
    }

    const handleChange = (e) => {
        let { name, value, id } = e.target
        const newRoleData = roleData.map((item) => {
            if (item.uId === id) {
                return { ...item, [name]: value }
            }
            return item
        })
        setRoleData(newRoleData)
    }

    const handleDelete = (uId) => {
        if (roleData?.length > 1) {
            setRoleData(roleData.filter((item) => item.uId !== uId))
        }
    }

    const handleChangeInput = (name, value) => {
        setBand({ ...band, [name]: value })
    }

    const handleSubmit = async () => {
        const hasEmptyResponsibility = roleData.some(
            (item) => !item.responsibilities || item.responsibilities.trim() === '',
        )

        if (roleData.length === 0 || hasEmptyResponsibility) {
            setCheckValidation({ isvalid: false })
            return
        }

        const minBandId = band?.min_bandid
        const maxBandId = band?.max_bandid
        const minBand = bandList?.find((b) => b?.bandmasterid === minBandId)
        const maxBand = bandList?.find((b) => b?.bandmasterid === maxBandId)

        if (!minBand || !maxBand || minBand?.slno >= maxBand?.slno) {
            setMaxBandError('Max band should be greater than Min band!')
            setCheckValidation({ isvalid: false })
            return
        } else {
            setMaxBandError('')
        }

        let apiPayload = {
            demandroledetailid: band?.demandroledetailid || null,
            jobtitle: band?.jobtitle,
            min_bandid: minBandId || 0,
            max_bandid: maxBandId || 0,
            experienceinyear: band?.experienceinyear,
            rolesummary: band?.rolesummary,
            comtenantid: Number(localStorage.getItem('Tenantid') || 0),
            isactiveflag: true,
            createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
            modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
            roleresponsibilities: roleData.map((rd) => ({
                responsibilities: rd.responsibilities || '',
            })),
        }

        let isValidated = CheckListObjectValidation(
            apiPayload,
            ['notes', 'demandroledetailid', 'demandid'],
            false,
        )
        if (!isValidated?.isvalid) {
            return
        } else {
            setCheckValidation(false)
            let res = await dispatch(SAVE_DEMAND_ROLE_MASTER([apiPayload], +editDemandId))
            if (res !== null) {
                dispatch(GET_DEMAND_MASTER(+editDemandId))
                setMaxBandError('')
            }
        }
    }

    return (
        <div className="mt-4 w-full h-[70vh]  text-sm">
            <div className="grid grid-cols-2 gap-8 mt-2">
                <div>
                    <label htmlFor="jobtitle" className="block font-[500] mb-2">
                        Job Title <span className="text-red-300">*</span>
                    </label>
                    <input
                        type="text"
                        title="jobtitle"
                        id="jobtitle"
                        className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                        placeholder="Enter Job Title"
                        required=""
                        value={band?.jobtitle || ''}
                        onChange={(e) => handleChangeInput('jobtitle', e.target.value)}
                    />
                    {!band?.jobtitle && checkValidation && (
                        <small className="text-red-400">Job is required!</small>
                    )}
                </div>
                <div className="grid grid-cols-3 gap-8 px-2">
                    <div>
                        <label htmlFor="band" className="block font-[500] mb-2">
                            Min Band <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            disabled={demandData[0]?.requesttype === 'P'}
                            options={bandList || []}
                            value={band?.min_bandid || ''}
                            noDataLabel="No band"
                            name="min_bandid"
                            labelkey="bandname"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="bandmasterid"
                        />
                        {!band?.min_bandid && checkValidation && (
                            <small className="text-red-400">band is required!</small>
                        )}
                    </div>
                    <div>
                        <label htmlFor="band" className="block font-[500] mb-2">
                            Max Band <span className="text-red-300">*</span>
                        </label>
                        <CustomhtmlSelect
                            disabled={demandData[0]?.requesttype === 'P'}
                            options={bandList || []}
                            value={band?.max_bandid || ''}
                            noDataLabel="No band"
                            name="max_bandid"
                            labelkey="bandname"
                            className="p-2 border rounded-lg bg-gray-50"
                            onChange={handleChangeInput}
                            id="bandmasterid"
                        />
                        {!band?.max_bandid && checkValidation && (
                            <small className="text-red-400">band is required!</small>
                        )}
                        {maxBandError && (
                            <small className="text-red-400">{maxBandError}</small>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="experienceinyear"
                            className="block font-[500] mb-2"
                        >
                            Min Exp (Years) <span className="text-red-300">*</span>
                        </label>
                        <input
                            placeholder="Min"
                            type="text"
                            pattern="[0-9\s]*"
                            className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            value={band?.experienceinyear || ''}
                            onChange={(e) => {
                                if (/^[0-9\s]*$/.test(e.target?.value)) {
                                    handleChangeInput('experienceinyear', e.target?.value)
                                }
                            }}
                        />
                        {!band?.experienceinyear && checkValidation && (
                            <small className="text-red-400">
                                Min Experience is required!
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="rolesummary" className="block font-[500] mb-2">
                    Role Summary <span className="text-red-300">*</span>
                </label>{' '}
                <textarea
                    placeholder="Role Summary..."
                    rows={3}
                    type="text"
                    className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                    value={band?.rolesummary || ''}
                    onChange={(e) => handleChangeInput('rolesummary', e.target.value)}
                />
                {!band?.rolesummary && checkValidation && (
                    <small className="text-red-400">Role Summary is required!</small>
                )}
            </div>
            <div className="flex items-center justify-between mt-8">
                <h1 className="text-sm font-[500]">
                    Role Responsibilities <span className="text-red-300">*</span>
                </h1>
            </div>
            <div className=" overflow-scroll h-[260px] ">
                {roleData?.map((item, i) => {
                    let { uId, responsibilities } = item
                    return (
                        <div
                            key={`${uId}-${i}`}
                            className="flex items-center justify-start my-2 space-x-2"
                        >
                            <input
                                placeholder="responsibilities"
                                onChange={handleChange}
                                name="responsibilities"
                                value={responsibilities}
                                id={uId}
                                className="outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]"
                            />
                            {!responsibilities && checkValidation && (
                                <small className="text-red-400">
                                    Please enter at least one responsibility!
                                </small>
                            )}
                            <div className="flex justify-center space-x-2">
                                {roleData?.length >= 1 && (
                                    <IconButton
                                        onClick={() => handleDelete(item?.uId)}
                                        className="flex items-center justify-center p-1 text-sm text-white rounded-full w-7 h-7 bg-primary focus:ring-0 focus:ring-primary focus:outline-none"
                                        title="Delete responsibility"
                                    >
                                        <MinusIcon className="w-3" />
                                    </IconButton>
                                )}
                                {roleData?.length - 1 === i && (
                                    <IconButton
                                        disabled={roleData?.length >= 10}
                                        onClick={handleAdd}
                                        className="text-white flex justify-center items-center rounded-full w-7 h-7 bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none"
                                        title="Add responsibility"
                                    >
                                        <PlusIcon className="w-3" />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="fixed bottom-0 mt-2 right-6">
                <div className="fixed bottom-2 right-5">
                    <PrimaryButton
                        className="px-8 py-2 mb-4 text-white rounded-lg bg-primary"
                        onClick={handleSubmit}
                    >
                        <span>Save</span>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default RoleDetails
