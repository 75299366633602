// NoPermissionComponent.js
const NoPermissionComponent = ({ title = 'Access denied.', des = "You do not have the permission to view this page." }) => {
    return <div className="flex flex-col items-center justify-center w-full mt-16 pt-52 h-52">
        <h1 className="text-xl font-medium text-red-400">{title}</h1>
        <h1 className="font-medium text-red-400 text-m">{des}</h1>
    </div>
};


export default NoPermissionComponent;
