import { postMethodApiCall } from 'src/Services/api'
import { AlertError, AlertSuccess } from 'src/Services'
import moment from 'moment'
import {
	ACTION_GET_TIMESHEET_PROJECTS_E,
	ACTION_GET_TIMESHEET_PROJECTS_M,
	ACTION_GET_TIMESHEET_USERLIST,
	ACTION_GET_TIMESHEET_TASK_TYPE,
	ACTION_STAFFING_SUMMARY_BY_PROJECTID,
	ACTION_GET_TIMESHEET_ORG_TASKS_BY_PRO_ID,
	ACTION_GET_TIMESHEET_TASKS_BY_PRO_ID,
	ACTION_GET_TIMESHEET_REPORT_STARTDATE_ENDDATE,
	ACTION_GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT,
	ACTION_GET_TASK_TIMESHEET_CONFIG,
	ACTION_GET_TASK_BY_PROJECTID_TIMESHEET,
	ACTION_GET_MANAGER_TIMESHEET_RECORDS
	// ACTION_GET_TIMESHEET_TASKS,
	// ACTION_SAVE_TIMESHEET_AS_DRAFT,
	// ACTION_SUBMIT_TIMESHEET_OF_EMPLOYEE,
	// ACTION_CHANGE_STATUS_OF_TIMESHEET,
} from 'src/Redux/types'


const baseUrl = process.env.REACT_APP_BASE_URL

const getTanAndComId = () => {
	return {
		Tenantid: Number(localStorage.getItem('Tenantid') || 0),
		comuserid: Number(localStorage.getItem('comuserid') || 0),
	}
}
const getHeadersWithoutAuth = () => {
	return {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
	}
}

export const GET_TIMESHEET_PROJECTS_E = (emptype = 'E', Searchname = '') => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/getprojectByEmployeeType`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				{
					Tenantid: getTanAndComId()?.Tenantid,
					emptype,
					COMUserId: getTanAndComId()?.comuserid,
					Searchname,
					projectstatus: 0,
					sorttype: 1,
				},
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (items === null) AlertError('No Projects found as Employee')
				dispatch({ type: ACTION_GET_TIMESHEET_PROJECTS_E, data: items || [] })
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}
const ProjectPayload = () => {
	return {
		Tenantid: getTanAndComId()?.Tenantid,
		emptype: 'M',
		COMUserId: getTanAndComId()?.comuserid,
		Searchname: '',
		projectstatus: 0,
		sorttype: 1,
	}
}
export const GET_TIMESHEET_PROJECTS_M = (data = ProjectPayload()) => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/getprojectByEmployeeType`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				dispatch({ type: ACTION_GET_TIMESHEET_PROJECTS_M, data: items || [] })
				return items
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TIMESHEET_USERLIST = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_userdetailsfortimesheetapproval`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				{ Tenantid: getTanAndComId()?.Tenantid, ...data },
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				let addnewkey = items?.map((item) => {
					item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
					return item
				})
				dispatch({
					type: ACTION_GET_TIMESHEET_USERLIST,
					data: addnewkey || [],
				})
				return addnewkey
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TIMESHEET_TASKS = (data) => {
	return async () => {
		try {
			const url = `${baseUrl}/get_TasksforTimesheet`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), { Tenantid: getTanAndComId()?.Tenantid, comuserid: getTanAndComId()?.comuserid, ...data })
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if ((items?.length === 0 || items === null) && data?.emptype === 'M') {
					AlertError('No Timesheet data found !')
					return { success: true, data: items || [] }
				} else if (data?.emptype === 'M') {
					let addnewkey = items?.map((item) => {
						item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
						return item
					})
					return { success: true, data: addnewkey }
				} else if (items === null) {
					let startDate = moment(data?.startdate); // replace with your own start date
					let newObject = { tasktype: 'Project', timesheetstatus: null, uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36) }
					for (let i = 0; i < 7; i++) {
						let nextDate = startDate.clone().add(i, 'days');
						let dateis = nextDate.format('YYYY-MM-DD').split(',')[0];
						newObject[dateis] = 0
					}
					return { success: true, data: [newObject] }
				} else {
					let addnewkey = items?.map((item) => {
						item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
						return item
					})
					return { success: true, data: addnewkey }
				}
			}
			return { success: false }
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, "")
		}
	}
}

export const GET_TIMESHEET_TASK_TYPE = () => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_tasktype`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				{ Tenantid: getTanAndComId()?.Tenantid },
			)
			if (res?.status === 200 || res?.status === 201) {
				// AlertError res.data?.value
				dispatch({
					type: ACTION_GET_TIMESHEET_TASK_TYPE,
					data: res?.data?.data,
				})
				return {
					success: true,
					data: res?.data?.data,
				}
			}
			return { success: false }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const STAFFING_SUMMARY_BY_PROJECTID = (
	projectid,
	userid = getTanAndComId()?.comuserid,
) => {
	return async dispatch => {
		try {
			let payload = {
				Tenantid: getTanAndComId()?.Tenantid, projectid, userid,
				sorttype: 1,
				// searchname:''
			}
			const url = `${baseUrl}/get_projectstaffingsummaryforauser`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)

			if (res?.status === 200 || res?.status === 201) {
				let items = res.data?.data
				dispatch({
					type: ACTION_STAFFING_SUMMARY_BY_PROJECTID,
					data: items,
				})
				return {
					success: true,
					data: items,
				}
			}
			return { success: false }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TIMESHEET_ORG_TASKS_BY_PRO_ID = tasktypeid => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_taskmaster_forataskType`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				{ Tenantid: getTanAndComId()?.Tenantid, tasktypeid },
			)
			if (res?.status === 200 || res?.status === 201) {
				dispatch({
					type: ACTION_GET_TIMESHEET_ORG_TASKS_BY_PRO_ID,
					data: res?.data?.data,
				})
				return {
					success: true,
					data: res?.data?.data,
				}
			}
			return { success: false }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_TIMESHEET_TASKS_BY_PRO_ID = projectroleid => {
	return async dispatch => {
		try {
			let payload = {
				Tenantid: getTanAndComId()?.Tenantid, projectroleid
			}
			const url = `${baseUrl}/get_taskmaster_foraprojectroleid`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res.data?.data
				// console.log(items)
				dispatch({
					type: ACTION_GET_TIMESHEET_TASKS_BY_PRO_ID,
					data: items,
				})
				return {
					success: true,
					data: items,
				}
			}
			return { success: false }
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const SAVE_TIMESHEET_AS_DRAFT = payload => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/inserttimesheet`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				AlertSuccess('Timesheet saved successfully')
				return true
			}
			return false
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const SUBMIT_TIMESHEET_OF_EMPLOYEE = payload => {
	return async () => {
		try {
			const url = `${baseUrl}/timesheetsubmission`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				AlertSuccess('Timesheet submitted successfully')
				return true
			}
			return false
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}


export const CHANGE_STATUS_OF_TIMESHEET = (data, reopened) => {
	if (data?.effortid === null) {
		AlertError('Effort Id not provided')
	}
	let payload = {
		timesheetjson: [
			{
				...data,
				comtenantid: getTanAndComId()?.Tenantid,
				approverid: getTanAndComId()?.comuserid,
			}
		]
	}
	return async () => {
		try {
			const url = `${baseUrl}/timesheetapproval`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
			if (res?.status === 200 || res?.status === 201) {
				AlertSuccess(reopened ? 'Reopened successfully' : data?.approved ? 'Timesheet approved successfully' : 'Timesheet rejected')
				return true
			}
			return false
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, "")
		}
	}
}

export const APPROVE_ALL_TIMESHEET = (data) => {
	return async () => {
		try {
			const url = `${baseUrl}/timesheetapproval`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
			if (res?.status === 200 || res?.status === 201) {
				AlertSuccess('Timesheets approved successfully')
				return true
			}
			return false
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, "")
		}
	}
}

export const GET_TIMESHEET_REPORT_STARTDATE_ENDDATE = (projectid = 0, weekstartdate, weekenddate) => {
	return async dispatch => {
		try {
			let payload = {
				projectid,
				Tenantid: getTanAndComId().Tenantid,
				Userid: getTanAndComId().comuserid,
				weekstartdate,
				weekenddate
			}

			const url = `${baseUrl}/get_timesheetreportforuserdatewise`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				let addnewkey = items?.map((item) => {
					item['otherprojecthoursLocal'] = item?.otherprojecthours + item?.currprojecthours
					item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
					return item
				})
				dispatch({
					type: ACTION_GET_TIMESHEET_REPORT_STARTDATE_ENDDATE,
					data: addnewkey || [],
				})
			} else {
				AlertError('No report data found !')
			}
			return false
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT = (projectid) => {
	return async dispatch => {
		try {
			let payload = {
				Tenantid: Number(localStorage.getItem('Tenantid') || 0),
				projectid,
			}
			const url = `${baseUrl}/get_projecteffortsummaryemployeewise`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				payload,
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				dispatch({ type: ACTION_GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT, data: items || [] })
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}


export const GET_TASK_TIMESHEET_CONFIG = (data) => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_tasksfortimesheet_config`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				if (items === null) AlertError('No Timesheet config found')
				if (items?.length > 0) {
					items[0]?.timesheetdata.map(item => {
						item['isactiveflag'] = true
						item['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
						return item
					})
				}

				dispatch({ type: ACTION_GET_TASK_TIMESHEET_CONFIG, data: items[0] || [] })
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}


export const GET_TASK_BY_PROJECTID_TIMESHEET = (Projectid, weeks) => {
	return async dispatch => {
		try {
			let data = {
				Tenantid: Number(localStorage.getItem('Tenantid') || 0),
				Comuserid: Number(localStorage.getItem('comuserid') || 0),
				Projectid,
				startdate: moment(weeks?.monday).format('YYYY-MM-DD'),
				enddate: moment(weeks?.sunday).format('YYYY-MM-DD'),

			}
			const url = `${baseUrl}/get_projecttask_fortimesheetui`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data
			)
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data
				// if (items === null) AlertError('No Timesheet config found')
				dispatch({ type: ACTION_GET_TASK_BY_PROJECTID_TIMESHEET, data: items || [] })
				return items
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}


export const INSERT_TIMESHEET_EFFORT_TASK = (data) => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_time_sheet_config`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data
			)
			if (res?.status === 200 || res?.status === 201) {
				AlertSuccess('Timesheet Saved Successfully')
				return true
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '')
		}
	}
}

export const GET_MANAGER_TIMESHEET_RECORDS = (data) => {
	return async (dispatch) => {
		try {
			const url = `${baseUrl}/get_TasksforTimesheetApproval_config`
			const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
			if (res?.status === 200 || res?.status === 201) {
				let dataWithUId =  res?.data?.data?.map(it=>{
					it['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
					return it
				})
				dispatch({ type: ACTION_GET_MANAGER_TIMESHEET_RECORDS, data: dataWithUId || [] })
			}
			return { success: false }
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, "")
		}
	}
}
