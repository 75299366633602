import React, { useEffect, useState } from 'react'

const ResumePreview = ({ binaryData, fileformat, title }) => {
    const [previewUrl, setPreviewUrl] = useState('')
    const [showDownload, setShowDownload] = useState(false)

    useEffect(() => {
        if (binaryData) {
            // Create a Blob from the base64 data
            const blob = new Blob([binaryData], { type: fileformat })
            // Create a data URL representing the Blob
            const dataUrl = URL.createObjectURL(blob)
            // Set the data URL to be displayed
            setPreviewUrl(dataUrl)
        }
    }, [binaryData, fileformat])

    const handleLoad = () => {
        setShowDownload(true)
    }

    return (
        <div className="relative w-full h-full">
            {showDownload ? (
                <button className="text-white outline-none w-[220px] h-10 inline-flex justify-center items-center bg-[#323639] absolute right-0  top-1.5 ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                    >
                        <path
                            fill="#fff"
                            d="M160-80v-80h640v80H160Zm320-160L200-600h160v-280h240v280h160L480-240Zm0-130 116-150h-76v-280h-80v280h-76l116 150Zm0-150Z"
                        />
                    </svg>
                </button>
            ) : (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_2s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            )}
            {previewUrl && (
                <iframe
                    frameBorder="0"
                    allowtransparency="yes"
                    scrolling="no"
                    id="talent-pdf-iframe"
                    title={title}
                    type="application/pdf"
                    src={`${previewUrl}#view=FitH&zoom=100`}
                    width="100%"
                    height="400px"
                    loading="lazyLoading"
                    onLoad={handleLoad}
                    style={{
                        border: '0px',
                        overflow: 'hidden',
                    }}
                />
            )}
        </div>
    )
}

export default ResumePreview
