import moment from 'moment'

const Cards = ({ item, setIsOpen, setDemandId, setSelectedDemand }) => {
    const handleCardClick = (com) => {
        setDemandId(com?.demandid)
        setIsOpen(true)
        setSelectedDemand(com)
    }
    const getRequestTypeLabel = (requesttype) => {
        switch (requesttype) {
            case 'O':
                return 'Opportunity'
            case 'P':
                return 'Project'
            case 'I':
                return 'Internal'
            default:
                return 'Unknown'
        }
    }

    const requestTypeLabel = getRequestTypeLabel(item?.requesttype)
    let requestName =
        requestTypeLabel === 'Opportunity'
            ? item?.opportunityname
            : requestTypeLabel === 'Project'
                ? item?.projectname
                : requestTypeLabel === 'Internal'
                    ? item?.department
                    : ''

    const getMatchStatus = (matchdetails) => {
        if (matchdetails && matchdetails.length > 0) {
            return matchdetails[0].matchstatus
        }
        return ''
    }
    const matchStatus = getMatchStatus(item?.matchdetails)

    return (
        <div
            className="w-full px-2 pb-1 bg-white rounded-lg shadow "
            onClick={() => handleCardClick(item)}
            style={{ margin: '1px', boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1)' }}
        >
            <div className="py-2">
                <div className="flex justify-between space-x-2">
                    <button className="inline-flex items-center px-2 py-1 text-xs font-normal text-green-600 bg-green-200 rounded">
                        {item?.statusname}
                    </button>
                    {matchStatus && <button className=" capitalize inline-flex items-center px-2 py-1 text-xs font-normal text-primary bg-primary/20 rounded">
                        {matchStatus}
                    </button>}
                </div>

                <div className="flex items-center mt-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-4 h-4 mr-1 text-red-500"
                    >
                        <path
                            fillRule="evenodd"
                            d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <p className="text-xs font-normal text-gray-500">
                        {item?.cityname} {' - '} {item?.companyname}
                    </p>
                </div>

            </div>

            <div className="px-1">
                <p className="text-sm font-normal text-gray-900">
                    {item?.jobtitle} ({item?.demandcode})
                </p>
                <p className="text-sm text-gray-500 capitalize">
                    {requestTypeLabel} - {requestName}
                </p>
                <div className="grid grid-cols-1 gap-y-0.5 py-1.5 text-sm font-normal text-gray-500">
                    <p>
                        <span className="text-gray-700">BU:</span>
                        {item?.businessunitname}
                    </p>
                    <p>
                        <span className="text-gray-700">SL:</span>
                        {item?.servicelinename}
                    </p>
                    <p>
                        <span className="text-gray-700">Requested Date:</span>
                        {moment(item?.requesteddate).format('DD-MM-YYYY')}
                    </p>
                    <p>
                        <span className="text-gray-700">Start Date:</span>
                        {moment(item?.startdate).format('DD-MM-YYYY')}
                    </p>
                    <p>
                        <span className="text-gray-700">Requested By:</span>
                        {item?.requestorname}
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Cards
