// import ArrowGoBack from "src/Components/ArrowGoback";
import { memo } from "react";
import { Link, useLocation } from "react-router-dom";

const getCategory = (category) => {
    if (category === 'I') {
        return 'Internal'
    } else if (category === 'C') {
        return 'Customer'
    }
}


const Actionheader = ({ projectDetail }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const AppliedFilters = searchParams.get('AppliedFilters')

    return (
        <div id='layout-header-detail' className="flex justify-between items-center">
            <div className="flex justify-start items-center space-x-2 pb-2 text-sm sm:text-[10px] lg:text-sm">
                <Link to={`/app/projects?AppliedFilters=${AppliedFilters}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                </Link>
                <h1 className="font-[500] text-primary">{projectDetail?.projectname} ({projectDetail?.projectcode}) |</h1>
                <p className=" text-gray-600">{projectDetail?.startdate} - {projectDetail?.enddate} |</p>
                <p className=" text-gray-600">{getCategory(projectDetail?.category)}<span className="px-1">|</span></p>
                <p className="text-green-600 font-[500] "> {projectDetail?.statusname}</p>
            </div>

        </div>
    )
}

export default memo(Actionheader);