import { useSelector } from "react-redux"
import { memo, useEffect, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { GET_USER_MASTER_LIST } from "src/Redux/actions/projects";
import { getMasterUserPayload } from "src/Services/constant/defaultPayload";

const SelectAccountManager = ({ setAccountSelect, accountSelect }) => {
  const projectsReducer = useSelector(state => state.Projects)
  const { userMasterList } = projectsReducer
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (accountSelect?.length <= 1) {
      setAccountSelect(userMasterList);
    }
  }, [setAccountSelect, userMasterList, accountSelect?.length]);

  useEffect(() => {
    if (accountSelect?.firstname) {
      setSearchTerm(`${accountSelect?.firstname} ${accountSelect?.lastname || ''} ${accountSelect?.employeecode ? `(${accountSelect?.employeecode || 0})` : ''}`)
    }
  }, [accountSelect])

  return (
    <>
      <CustomAutoSearch
        searchTerm={searchTerm}
        API_CALL_FOR_SEARCH={GET_USER_MASTER_LIST}
        searchPayload={{
          ...getMasterUserPayload(),
          username: searchTerm,
        }}
        setSearchTerm={setSearchTerm}
        options={userMasterList}
        idkey='comuserid'
        optionlabelKey='firstname'
        optionLabelkey2='lastname'
        bracketLabel='employeecode'
        getSelectedOption={setAccountSelect}
        placeholder='Enter Account Manager Name'
        className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
      />
    </>
  )
}


export default memo(SelectAccountManager)