import { AlertError } from "src/Services";
import { useState, useRef, useEffect, memo, useMemo } from 'react';
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";

function SingleSelectField({ options, handleSelected, disabled, value, labelKey, valueKey }) {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('')
    const dropdownRef = useRef(null);

    const getByTitle = useMemo(() => {
        try {
            return options?.find(el => el[valueKey] === value)[labelKey];
        } catch (error) {
            return '';
        }
        // eslint-disable-next-line
    }, [valueKey, options, value])

    const handleOpen = () => {
        setOpen(true)
        setQuery('')
    }

    useMemo(() => {
        setQuery(getByTitle)
        // eslint-disable-next-line
    }, [valueKey, options, value])

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // setQuery(getByTitle)
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    const filteredList =
        query === ''
            ? options
            : options.filter(el =>
                el?.timetypename
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, '')),
            )

    return (
        <div ref={dropdownRef} className="relative w-28">
            <div className="relative group ">
                <button disabled={disabled} onClick={handleOpen} className='flex items-center justify-start px-1 py-1 rounded'>
                    <input value={query || getByTitle}
                        placeholder='Select timetype'
                        disabled={disabled}
                        onChange={(e) => setQuery(e.target.value || ' ')}
                        className='w-full text-xs focus:outline-none placeholder:text-primary/40 ' />

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>

                </button>
            </div>
            {open && <div className="transition z-[1000] max-h-44 ease-in duration-100 absolute mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {filteredList?.map((item, itemIdx) => {
                    return (
                        <div
                            key={item?.uId || itemIdx}
                            className={`hover:bg-secondary/10 hover:text-secondary flex justify-between items-center relative select-none p-2 cursor-pointer ${item[valueKey] === value ? 'bg-secondary/10 text-secondary/90' : 'text-gray-900'}`}
                            onClick={() => {
                                handleSelected(item)
                                setOpen(!open)
                                setQuery(item[labelKey])
                            }}
                        >
                            <span>
                                {item[labelKey]}
                            </span>
                            {item[valueKey] === value &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                            }
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}

const SelectTimeType = ({ hooks: { it, rowId } }) => {
    const dispatch = useDispatch()
    const employeeTimesheetData = useSelector(state => state?.ProjectTimesheet?.employeeTimesheetData)
    const getTaskTimesheetConfig = useSelector(state => state?.ProjectTimesheet?.getTaskTimesheetConfig)

    const timeTypelist = useMemo(() => {
        if (it?.entrytype === 'Generic') {
            let timeType = getTaskTimesheetConfig?.timesheetdata?.find(row => row?.entrytype === 'Generic')
            if (timeType?.timesheetconfiguration?.length > 0) {
                return timeType?.timesheetconfiguration[0]?.timetypetemplatemapping
            } else if (timeType?.timesheetconfiguration) {
                return timeType?.timesheetconfiguration?.timetypetemplatemapping
            }
            return []
        } else if (it?.projectid) {
            let timeType = getTaskTimesheetConfig?.timesheetdata?.find(row => row?.projectid === it?.projectid)
            if (timeType?.timesheetconfiguration?.length > 0) {
                return timeType?.timesheetconfiguration[0]?.timetypetemplatemapping
            } else if (timeType?.timesheetconfiguration) {
                return timeType?.timesheetconfiguration?.timetypetemplatemapping
            }
            return []
        }
        // eslint-disable-next-line
    }, [it?.entrytype, it?.projectid, it?.timetypeid])

    const getSelected = async (selectedTimetype) => {
        let value = selectedTimetype?.timetypeid

        let isDuplicate = employeeTimesheetData?.find(r => (r?.timetypeid === value && r?.projecttaskid === it?.projecttaskid) && r?.projstaffid === it?.projstaffid)
        let items = null

        let updatevaluesFun = (timetypeid) => {
            items = employeeTimesheetData?.map(row => {
                if (row?.uId === rowId) {
                    row['newChange'] = true
                    row['timetypeid'] = timetypeid
                    row['selectedTimetype'] = selectedTimetype
                    row['timetypeUniqueness'] = selectedTimetype?.timetype

                    if (row?.selectedAllocation?.allocation || row?.allocation || row?.allocationperc) {
                        row['minEffort'] = selectedTimetype?.mindailylimit
                        row['maxEffort'] = selectedTimetype?.maxdailylimit
                    } else {
                        row['maxEffort'] = row?.entrytype === 'Project' ? 0 : selectedTimetype?.maxdailylimit
                        row['minEffort'] = row?.entrytype === 'Project' ? 0 : selectedTimetype?.mindailylimit
                    }
                    row?.timesheetdetails?.map(eff => {
                        eff['efforthours'] = 0
                        eff['endtime'] = 0
                        eff['starttime'] = 0
                        return eff
                    })

                }
                return row
            })
        }
        if (isDuplicate && selectedTimetype?.timetype !== null) {
            AlertError(`${it?.entrytype} ${it?.projectname ? '|' : ''} ${it?.projectname || ''} | ${it?.taskdesc} | ${selectedTimetype?.timetypename} already exists`)
            return
        } else if (selectedTimetype?.timetype === 'Date') {
            let findDuplicate = employeeTimesheetData?.find(r => r?.timetypeid === value && r.entrytype === 'Generic')
            if (findDuplicate) {
                AlertError(`${selectedTimetype?.timetypename}: can only be selected once for Generic entry`)
                return
            }
        } else if (selectedTimetype?.timetype === 'Project') {
            let findDuplicate = employeeTimesheetData?.find(r => r?.projectid === it?.projectid && r?.timetypeid === value)
            if (findDuplicate) {
                AlertError(`${selectedTimetype?.timetypename}: can only be selected once for ${it?.projectname} project`)
                return
            }
        } if (it?.entrytype === 'Project') {
            if (selectedTimetype?.timetype === 'None' || selectedTimetype?.timetype === null) {
                await updatevaluesFun(value)
            } else {
                let findDuplicate = employeeTimesheetData?.filter(f => f?.isactiveflag !== false)?.find(row => row?.projectid === it?.projectid && row?.timetypeid === value)
                if (findDuplicate) {
                    items = employeeTimesheetData?.map(row => {
                        if (row?.uId === rowId) {
                            row['newChange'] = true
                            row['timetypeid'] = null
                            delete row['selectedTimetype']
                            delete row['timetypeUniqueness']
                            delete row['minEffort']
                            delete row['maxEffort']
                        }
                        return row
                    })
                    AlertError(`${selectedTimetype?.timetypename} time type can only be selected once for a given date.`)
                    return
                } else {
                    await updatevaluesFun(value)
                }
            }
        } else if (it?.entrytype === 'Generic') {
            if (selectedTimetype?.timetype === 'None' || selectedTimetype?.timetype === null) {
                await updatevaluesFun(value)
            } else {
                let findDuplicate = employeeTimesheetData?.filter(f => f?.isactiveflag !== false)?.find(row => row?.timetypeUniqueness === 'Date' && (row?.timetypeid === value && row?.projecttaskid === it?.projecttaskid))
                if (findDuplicate) {
                    items = employeeTimesheetData?.map(row => {
                        if (row?.uId === rowId) {
                            row['timesheetdetails'] = row?.timesheetdetails?.map(eff => {
                                eff['efforthours'] = 0
                                eff['endtime'] = 0
                                eff['starttime'] = 0
                                return eff
                            })
                            row['newChange'] = true
                            row['timetypeid'] = null
                            delete row['selectedTimetype']
                            delete row['timetypeUniqueness']
                            delete row['minEffort']
                            delete row['maxEffort']
                        }
                        return row
                    })
                    AlertError(`${selectedTimetype?.timetypename} time type can only be selected once for a given date.`)
                    return
                } else {
                    await updatevaluesFun(value)
                }
            }
        }
        if (items) {
            dispatch({ type: 'SET_EMPLOYEE_TIMESHEET_DATA', data: items })
        }
    }

    const getdisable = () => {
        if (it?.curstatus === 'Submitted' || it?.curstatus === 'Approved') {
            return true
        } else if (!it?.projtaskid) {
            return true
        }
        return false
    }

    return (
        <SingleSelectField
            disabled={getdisable()}
            valueKey='timetypeid'
            labelKey='timetypename'
            options={timeTypelist || []}
            value={it?.timetypeid || null}
            handleSelected={getSelected}
        />
    )
}

export default memo(SelectTimeType);
