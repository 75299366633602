import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { GET_PROJECTS_BY_ID } from 'src/Redux/actions/projects'
import { GET_PROJECT_STAFFING_LIST } from 'src/Redux/actions/projects/staffing'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

const Staffing = ({ projectDetail }) => {
	const dispatch = useDispatch()
	const { listOfProjectStaffing } = useSelector(state => state?.ProjectStaffing)
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search.split('?')[1])
	const projectid = searchParams.get('projectid')

	useEffect(() => {
		dispatch(GET_PROJECTS_BY_ID(+projectid))
	}, [dispatch, projectid])

	useMemo(() => {
		if (projectDetail?.projectid) {
			dispatch(GET_PROJECT_STAFFING_LIST(projectDetail?.projectid, null))
		}
	}, [dispatch, projectDetail])

	return (
		<div className='h-[calc(80%)]  mt-2 overflow-scroll'>
			<table className='w-full relative'>
				<thead className='sticky -top-1'>
					<tr className='text-left capitalize bg-gray-50 text-sm '>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Staff</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Role</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Billable</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Start date</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>End date</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Allocation (%)</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Status</th>
					</tr>
				</thead>
				<tbody>
					{listOfProjectStaffing?.map((item, id) => {
						const {
							allocation,
							billable,
							startdate,
							enddate,
							rolename,
							username,
							uId,
							statffingstatus
						} = item
						return (
							<tr key={uId}>
								<td className='border px-1 text-sm'>{username}</td>
								<td className='border px-1 text-sm'>{rolename}</td>
								<td className='border px-1 text-sm'>{billable === false ? 'No' : 'Yes'}</td>
								<td className='border px-1 text-sm'>{moment(startdate).format('DD-MM-YYYY')}</td>
								<td className='border px-1 text-sm'>{moment(enddate).format('DD-MM-YYYY')}</td>
								<td className='border px-1 text-sm'>{allocation}</td>
								<td className='border px-1 text-sm'>{statffingstatus}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default Staffing

