import SingleSelectField from 'src/Components/SingleSelectForTable'

const Industry = ({ options, value, rowId, handleChange }) => {
	const getSelected = value => {
		let e = { target: { name: 'domainid', value: value['industryid'] } }
		handleChange(e, rowId)
	}
	return (
		<div className='w-full'>
			<SingleSelectField
				valueKey='industryid'
				labelKey='industryname'
				options={options}
				value={value || 0}
				handleSelected={getSelected}
			/>
		</div>
	)
}

export default Industry
