import CustomhtmlSelect from "src/Components/CustomhtmlSelect";

const SelectCompany = ({ listOfCompany, handleChangeValue, value }) => {
    return (

        <>
            <label htmlFor="projectEntity" className="block font-[500] mb-2">Select Company <span className="text-red-300">*</span></label>
            <CustomhtmlSelect
                options={listOfCompany || []}
                value={value}
                noDataLabel='No Company'
                name='companyid'
                labelkey='companyname'
                className='border rounded bg-gray-50 py-2'
                onChange={handleChangeValue}
                id='companyid'
            />
        </>
    )
}

export default SelectCompany;