import moment from 'moment'
import React, { memo, useEffect, useRef, useState } from 'react'

const MultiSelectMonth = ({
    onChange,
    year,
    month,
    name,
    min,
    max,
    setSelectedMonths,
    selectedMonths,
    className = 'flex items-center justify-start w-full p-2 space-x-1 border rounded-md',
    disabled = false,
    multiselect = false,
}) => {
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedYear, setSelectedYear] = useState(year || new Date().getFullYear())
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const months = [
        { name: 'Jan', value: '01' },
        { name: 'Feb', value: '02' },
        { name: 'Mar', value: '03' },
        { name: 'Apr', value: '04' },
        { name: 'May', value: '05' },
        { name: 'Jun', value: '06' },
        { name: 'Jul', value: '07' },
        { name: 'Aug', value: '08' },
        { name: 'Sep', value: '09' },
        { name: 'Oct', value: '10' },
        { name: 'Nov', value: '11' },
        { name: 'Dec', value: '12' },
    ]

    const toggleMonth = (monthValue) => {
        if (multiselect) {
            const isSelected = selectedMonths?.includes(monthValue)
            const newSelectedMonths = isSelected
                ? selectedMonths?.filter((m) => m !== monthValue)
                : [...selectedMonths, monthValue]
            setSelectedMonths(newSelectedMonths)
            onChange(newSelectedMonths) // Assuming you want to call onChange even for multi-select
        } else {
            setSelectedMonths([monthValue])
            onChange(monthValue)
        }
    }

    const IncrementYear = () => {
        setSelectedYear((pre) => pre + 1)
    }

    const DecrementYear = () => {
        setSelectedYear((pre) => pre - 1)
    }

    const displayButtonContent = () => {
        if (selectedMonths?.length > 0) {
            if (multiselect) {
                return selectedMonths
                    .map((month) => moment(month, 'YYYY-MM').format('MMM YYYY'))
                    .join(', ')
            } else {
                return moment(selectedMonths[0], 'YYYY-MM').format('MMM YYYY')
            }
        } else {
            return ''
        }
    }

    return (
        <div ref={dropdownRef} className="relative w-full mt-1 ">
            <button
                disabled={disabled}
                onClick={() => setIsOpen(!isOpen)}
                className={className}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                </svg>
                <span className="text-gray-600 truncate ">{displayButtonContent()}</span>
            </button>

            {isOpen && (
                <div
                    style={{ zIndex: 2000 }}
                    className="p-2 min-w-[190px] transition ease-in duration-100 absolute mt-1 w-full max-h-72  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <div>
                        <div className="flex items-center justify-between my-1">
                            <button
                                onClick={DecrementYear}
                                className="flex items-center justify-center rounded-full text-primary"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15.75 19.5 8.25 12l7.5-7.5"
                                    />
                                </svg>
                            </button>
                            <span className="font-[500]">
                                {months?.find((it) => +it?.value === month)?.name}{' '}
                                {selectedYear}
                            </span>
                            <button
                                onClick={IncrementYear}
                                className="flex items-center justify-center rounded-full text-primary"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="grid w-full grid-cols-3 gap-2">
                            {months?.map((item) => {
                                const monthValue = `${selectedYear}-${item?.value}`
                                const disabled =
                                    (min && monthValue < min) || (max && monthValue > max)
                                return (
                                    <button
                                        key={item?.name}
                                        disabled={disabled}
                                        className={`text-sm hover:bg-secondary/10 disabled:bg-gray
                                    		-50 disabled:text-gray-200 rounded-md hover:text-secondary flex justify-center items-center relative select-none p-1.5 cursor-pointer ${
                                                selectedMonths?.includes(monthValue)
                                                    ? 'bg-secondary/10 text-secondary/90'
                                                    : 'text-gray-900'
                                            }`}
                                        onClick={() => {
                                            if (multiselect) {
                                                toggleMonth(monthValue)
                                            } else {
                                                let e = {
                                                    target: {
                                                        name: name,
                                                        value: monthValue,
                                                    },
                                                }
                                                onChange(e)
                                                setIsOpen(false)
                                            }
                                        }}
                                    >
                                        <span>{item?.name}</span>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default memo(MultiSelectMonth)
