var initialState = {
    listOfProjectStaffing: null,
    listOfStaffWithType: [],
    staffingFte: null
}


const ProjectStaffing = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROJECT_STAFFING_LIST':
            return { ...state, listOfProjectStaffing: action.data?.Message ? [] : action.data, staffingFte: action?.fte }
        case 'GET_STAFF_STATUS_TYPE_S':
            return { ...state, listOfStaffWithType: action.data?.Message ? [] : action.data }
        default:
            return state
    }
}

export default ProjectStaffing