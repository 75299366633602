import PieChartByStatus from './byStatus'
import BarChartEffortTimeline from './effortTimeline'
import RadialPieChart from './metrics'
import PieChartTaskType from './taskType'

const metrics = [
    { title: 'Overtime Hours (%)', value: 300, color: '#83a6ed', innerLabel: '' },
    { title: 'Deficit Hours (%)', value: 200, color: '#8dd1e1', innerLabel: '' },
    { title: 'Timesheet Compliance (%)', value: 65, color: '#82ca9d', innerLabel: '' },
    { title: 'Avg Daily Hours (Emp)', value: 5.5, color: '#a4de6c', innerLabel: '' },
    { title: 'Avg Weekly Hours (Emp)', value: 38, color: '#d0ed57', innerLabel: '' },
    { title: 'Avg Monthly Hours (Emp)', value: 155, color: '#ffc658', innerLabel: '' },
]

const AnalyticEffort = () => {
    return (
        <div className="relative w-full">
            <div className="grid grid-cols-3 gap-4 py-2 ml-8">
                <div className="relative flex justify-center w-full h-full">
                    <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                        Effort Timeline (Hrs)
                    </h3>
                    <BarChartEffortTimeline />
                </div>
                <div className="relative w-full h-80 ">
                    <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                        By Status
                    </h3>
                    <PieChartByStatus />
                </div>
                <div className="relative w-full h-80 ">
                    <h3 className="absolute top-0 z-10 px-1 text-xs font-semibold transform -translate-x-1/2 bg-white left-1/2">
                        By Task Type
                    </h3>
                    <PieChartTaskType />
                </div>
            </div>
            <div className="grid grid-cols-6 gap-1 ">
                {metrics.map((metric, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-center h-12 col-span-1"
                    >
                        <RadialPieChart
                            title={metric.title}
                            value={metric.value}
                            color={metric.color}
                            innerLabel={metric?.innerLabel}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AnalyticEffort
