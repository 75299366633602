import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from './defaultRowAndData';
import { AlertError } from 'src/Services';
import MultiSelectItem from 'src/Components/MultiSelect';


const Details = (props) => {
  const { detailSave, detailsData, setDetailsData, selectedRoles, setSelectedRoles, setProfileData } = props;
  const [checkvalidation, setcheckvalidation] = useState(false);
  const {companylist} = useSelector((state) => state?.EmployeeDetails);
  const { usertypelist, rolelist } = useSelector((state) => state.UserManagementList);
  const projectsReducer = useSelector((state) => state.Projects);
  const { statusListByType } = projectsReducer;

  const getEmpStatusId = (code) => {
    return statusListByType?.find(status => status.statuscode === code)?.statusid
  }

  useEffect(()=>{
    setDetailsData((preData) => ({
      ...preData,
      emp_status: getEmpStatusId("EACTIVE"),
   }));
  // eslint-disable-next-line 
  },[statusListByType])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setcheckvalidation(true);

    let item = {
      ...detailsData,
      username: detailsData?.emailid,
      Roledetails: selectedRoles.map((role) => ({
        Roleid: role.roleid,
      })),
    };
    let isValidated = CheckObjectValidation(item, [...detailNotRequired, 'emp_status']);
    if (isValidated?.isvalid) {
      // Email validation
      if (!detailsData.emailid || !/\S+@\S+\.\S+/.test(detailsData.emailid)) {
        AlertError('Invalid email format. Please enter a valid email address.');
        return;
      }
      let payload = {
        userjson: [item],
        password: '123456',
        url: `${window.location?.origin}/app/login`
        //CODE FOR FUTURE USE url: getBaseURL() // Get the base URL based on the environment
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value, extraValue) => {
    if (name === 'companyid') {
      setProfileData(prevPayload => ({
        ...prevPayload,
        incorporationdate: extraValue
      }));
      setDetailsData({ ...detailsData, payrollcompanyid: value });
    } else {
      setDetailsData({ ...detailsData, [name]: value });
    }
  }

  const handleRoleSelect = item => {
    const isNew = selectedRoles?.some(p => p?.roleid === item?.roleid)
    if (isNew) {
      const updatedAllocationStatus = selectedRoles?.filter(
        p => p?.roleid !== item?.roleid,
      )
      setSelectedRoles(updatedAllocationStatus)
    } else {
      if (selectedRoles?.length === 3) {
        AlertError('Maximum of 3 roles allowed per user');
        return
      }
      setSelectedRoles(prevList => [...prevList, {
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        roleid: item?.roleid,
        activeflag: true,
        rolename: item?.rolename,
      }])
    }
  }

  return (
    <div className='w-full h-[65vh] text-sm mb-10 overflow-y-auto'>
      <div className='grid grid-cols-4 gap-5 mt-4'>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={companylist || []}
            value={detailsData?.payrollcompanyid || ''}
            noDataLabel='No Company'
            name='companyid'
            labelkey='companyname'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='companyid'
          />
          {!detailsData?.payrollcompanyid && checkvalidation && (
            <small className='text-red-400'>Company is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='usertype' className='block font-[500] mb-2'>
            User Type
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={usertypelist || []}
            value={detailsData?.datusertypeid || ''}
            noDataLabel='No Users'
            name='datusertypeid'
            labelkey='usertype'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='datusertypeid'
          />
          {!detailsData?.datusertypeid && checkvalidation && (
            <small className='text-red-400'>User Type is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='Role' className='block capitalize mb-2 font-[500]'>
            Role
            <span className="text-red-300"> *</span>
          </label>
          <div className='border p-1 bg-gray-50 rounded'>
            <MultiSelectItem
              itemIdKey='roleid'
              labelKeyname='rolename'
              limitShow={1}
              selectedOptions={selectedRoles}
              options={rolelist}
              handleSelection={handleRoleSelect}
            />
          </div>
          {!selectedRoles && checkvalidation && (
            <small className='text-red-400'>Role is required!</small>
          )}
        </div>
        <div>
          <h1 className='block font-[500] pb-2'>
            Status<span className='text-red-300'> *</span>
          </h1>
          <CustomhtmlSelect
            disabled={true}
            options={statusListByType || []}
            value={detailsData?.emp_status}
            noDataLabel='No Status'
            name='emp_status'
            labelkey='statusname'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='statusid'
          />
          {!detailsData?.emp_status && checkvalidation && (
            <small className='text-red-400'>
              Employee Status is required!
            </small>
          )}
        </div>

        <div>
          <label htmlFor='firstname' className='block font-[500] mb-2'>
            First Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='firstname'
            id='firstname'
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('firstname', e.target.value);
              }
            }}
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='First Name'
            required=''
            value={detailsData?.firstname || ''}
            maxLength={50}
          />
          {!detailsData?.firstname && checkvalidation && (
            <small className='text-red-400'>Firstname is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='lastname' className='block font-[500] mb-2'>
            Last Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='lastname'
            id='lastname'
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('lastname', e.target.value);
              }
            }}
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='Last Name'
            required=''
            value={detailsData?.lastname || ''}
            maxLength={50}
          />
          {!detailsData?.lastname && checkvalidation && (
            <small className='text-red-400'>Lastname is required!</small>
          )}        </div>
        <div>
          <label htmlFor='Email' className='block font-[500] mb-2'>
            Email
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='email'
            title='email'
            id='emailid'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='Email'
            required=''
            value={detailsData?.emailid || ''}
            onChange={(e) => handleChangeInput('emailid', e.target.value)}
          />
          {!detailsData?.emailid && checkvalidation && (
            <small className='text-red-400'></small>
          )}
        </div>
        <div>
          <label htmlFor='username' className='block font-[500] mb-2'>
            Username
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={!detailsData?.emailid}
            type='username'
            title='username'
            id='username'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='Username'
            required=''
            value={detailsData?.emailid || ''}
            onChange={(e) =>
              handleChangeInput('username', detailsData?.emailid)
            }
          />
          {!detailsData?.emailid && checkvalidation && <small></small>}
        </div>
        <div>
          <label htmlFor='Mobile' className='block font-[500] mb-2'>
            Mobile
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='mobileno'
            id='mobileno'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='Mobile No'
            required=''
            maxLength={15}
            value={detailsData?.mobileno || ''}
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target?.value)) {
                handleChangeInput('mobileno', e.target.value);
              }
            }}
          />
          {!detailsData?.mobileno && checkvalidation && (
            <small className='text-red-400'>Mobile no is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='usercode' className='block font-[500] mb-2'>
            User Code
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='employeecode'
            id='employeecode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='User Code'
            required=''
            value={detailsData?.employeecode || ''}
            onChange={(e) =>
              handleChangeInput('employeecode', e.target.value)
            }
          />
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded py-2 px-8 mb-2'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);