import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_DEMAND_MASTER } from "src/Redux/actions/workforce/demands";
const getRequestTypeLabel = requesttype => {
    switch (requesttype) {
        case 'O':
            return 'Opportunity'
        case 'I':
            return 'Internal'
        case 'P':
            return 'Project'
        default:
            return 'Unknown'
    }
}

const getPriorityTypeLabel = priority => {
    switch (priority) {
        case 'H':
            return 'High'
        case 'L':
            return 'Low'
        case 'M':
            return 'Medium'
        default:
            return 'Unknown'
    }
}

const getCycleLabel = id => {
    switch (id) {
        case 1:
            return 'Monthly'
        case 2:
            return 'Weekly'
        case 3:
            return 'Daily'
        case 4:
            return 'Hourly'
        default:
            return ''
    }
}

const BaseInfo = ({ demandId }) => {
    const dispatch = useDispatch()
    const DemandDetailsList = useSelector(state => state.DemandDetailsList)
    const { demandData } = DemandDetailsList

    useEffect(() => {
        dispatch(GET_DEMAND_MASTER(+demandId))
    }, [dispatch, demandId])

    const billingunit = getCycleLabel(demandData[0]?.billingunit)
    const priorityTypeLabel = getPriorityTypeLabel(demandData[0]?.priority)
    const requestTypeLabel = getRequestTypeLabel(demandData[0]?.requesttype)
    let requestName =
        requestTypeLabel === 'Opportunity'
            ? demandData[0]?.opportunityname
            : requestTypeLabel === 'Project'
                ? demandData[0]?.projectname
                : requestTypeLabel === 'Internal'
                    ? demandData[0]?.department
                    : ''

    return (
        <div className="grid grid-cols-1 gap-4 p-4 text-sm bg-white md:grid-cols-3">
            <div className="space-y-2">
                <p className="font-semibold">
                    Request Type :{' '}
                    <span className="font-normal">
                        {requestTypeLabel}{' '}
                    </span>
                </p>
                <p className="font-semibold">
                    {requestTypeLabel === 'Internal' ? 'Department' : requestTypeLabel} :<span className="font-normal"> {requestName}</span>
                </p>
                <p className="font-semibold">
                    Employee Type:{' '}
                    <span className="font-normal">
                        {demandData[0]?.workertype}
                    </span>
                </p>
                <p className="font-semibold">
                    Service Line :{' '}
                    <span className="font-normal">
                        {demandData[0]?.servicelinename}{' '}
                    </span>
                </p>
                <p className="font-semibold">
                    Business Unit :{' '}
                    <span className="font-normal">
                        {demandData[0]?.businessunitname}{' '}
                    </span>
                </p>
                <p className="font-semibold">
                    Start Date :{' '}
                    <span className="font-normal">
                        {demandData[0]?.startdate}
                    </span>
                </p>
            </div>
            <div className="space-y-2">
                <p className="font-semibold">
                    Match Status :{' '}
                    <span className="font-normal">
                        {demandData[0]?.matchstatus_name}
                    </span>
                </p>
                <p className="font-semibold">
                    Billable :{' '}
                    <span className="font-normal">
                        {demandData[0]?.is_billable === true ? 'Yes' : 'No'}{' '}
                    </span>
                </p>
                <p className="font-semibold">
                    Billing Unit :{' '}
                    <span className="font-normal">{billingunit} </span>
                </p>
                <p className="font-semibold">
                    Workforce Group:{' '}
                    <span className="font-normal">{demandData[0]?.workforcegroup} </span>
                </p>
                <p className="font-semibold">
                    Billing Rate  :{' '}
                    <span className="font-normal">
                        {demandData[0]?.billrate}
                    </span>
                </p>
                <p className="font-semibold">
                    Currency :{' '}
                    <span className="font-normal">
                        {demandData[0]?.currency}
                    </span>
                </p>

            </div>
            <div className="space-y-2">
                <p className="font-semibold">
                    Priority:{' '}
                    <span className="font-normal">
                        {priorityTypeLabel}
                    </span>
                </p>
                <p className="font-semibold">
                    Allocation :{' '}
                    <span className="font-normal">
                        {(Number(demandData[0]?.allocation)) || demandData[0]?.allocation}%
                    </span>
                </p>
                <p className="font-semibold">
                    Work Mode :{' '}
                    <span className="font-normal">
                        {demandData[0]?.workmode}
                    </span>
                </p>
                <p className="font-semibold">
                    Requested By:{' '}
                    <span className="font-normal">
                        {demandData[0]?.requestorname}
                    </span>
                </p>
                <p className="font-semibold">
                    Requested Date :{' '}
                    <span className="font-normal">
                        {moment(demandData[0]?.createdtimestamp).format('DD-MM-YYYY ')}
                    </span>
                </p>
                <p className="font-semibold">
                    Selected Staff :{' '}
                    <span className="font-normal">
                        {demandData[0]?.demandusername}
                    </span>
                </p>

                <p className="font-semibold">
                    Selected Staff :{' '}
                    <span className="font-normal">
                        {demandData[0]?.demandusername}
                    </span>
                </p>

            </div>
        </div>

    )
}

export default BaseInfo;