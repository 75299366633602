import moment from 'moment';
import React, { memo } from 'react';
import ReactApexChart from 'react-apexcharts';


const ApexChart = ({ seriesData }) => {

    const options = {
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                const seriesIndex = opts.seriesIndex;
                const dataPointIndex = opts.dataPointIndex;
                const data = seriesData[seriesIndex].data[dataPointIndex];
                return `${data?.x || ''} - ${data?.effort || 0} Hrs `;
            },
            style: {
                // colors: ['#6b7281'], // Data label text color
                fontWeight: 'normal', // Font weight
                fontSize: '8px',
                colors: ['#000000'], // Data label text color,
            }
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const data = seriesData[seriesIndex].data[dataPointIndex];
                return `<div style="padding: 10px; font-size:8px; background-color: #ffffff; border: 1px solid #cccccc; border-radius: 5px;">
                            <div>${data?.effort || 0} Hrs</div>
                            <div>Start: ${data?.y[0] ? moment(new Date(data?.y[0])).format('MMM DD, YYYY') : 'N/A'}</div>
                            <div>End: ${data?.y[1] ? moment(new Date(data?.y[1])).format('MMM DD, YYYY') : 'N/A'}</div>
                        </div>`;
            }
        },
        chart: {
            height: '85%',
            type: 'rangeBar'
        },
        plotOptions: {
            bar: {
                horizontal: true,
                // barHeight: '20%',
                minBarHeight: 10, // Adjust the value as per your requirement
                rangeBarOverlap: false,
                max: 10
                // rangeBarGroupRows: false,
            }
        },

        xaxis: {
            labels: {
                formatter: function (value) {
                    return value ?  moment(new Date(value)).format('DD/MMM/YY') : value
                },
            },
        },
        yaxis: {
            show: false ,
            labels: {
                formatter: function (value) {
                    return value
                },
                // maxWidth: 80, // Limiting the width of y-axis labels
                wrapText: true, // Enable text wrapping
            }
        },
        stroke: {
            width: 1
        },
        fill: {
            type: 'solid',
            opacity: 0.6
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },

        colors: ['#008ffb', '#00e396', '#ffb01a'], // Default colors for Plan and Actual
    };

    return (
        <ReactApexChart options={options} series={seriesData} type="rangeBar" height={'85%'} />
    );
};

export default memo(ApexChart);
