import React, { useState } from 'react'
import AllocationChart from './allocationChart'
import BandWiseAllocationChart from './bandWiseChart'
import SkillWiseAllocationChart from './skillWiseChart'
import DynamicAllocationSplineChart from './monthWiseChart'

const Allocation = ({ filters }) => {
    const [drilldownData, setDrilldownData] = useState({})
    const [reportLevel, setReportLevel] = useState('SERVICELINEGROUP')
    const [selectedIds, setSelectedIds] = useState({})

    return (
        <div className="relative w-full">
            <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="relative w-full px-2 mt-8 h-80">
                    <AllocationChart
                        filters={filters}
                        drilldownData={drilldownData}
                        setDrilldownData={setDrilldownData}
                        reportLevel={reportLevel}
                        selectedIds={selectedIds}
                        setReportLevel={setReportLevel}
                        setSelectedIds={setSelectedIds}
                    />
                </div>
                <div className="relative flex flex-col w-full mt-8 h-80">
                    <div className="w-full mb-4 h-1/2">
                        <SkillWiseAllocationChart
                            reportLevel={reportLevel}
                            selectedIds={selectedIds}
                            filters={filters}
                            drilldownData={drilldownData}
                        />
                    </div>
                    <div className="w-full mb-4 h-1/2">
                        <BandWiseAllocationChart
                            reportLevel={reportLevel}
                            selectedIds={selectedIds}
                            filters={filters}
                            drilldownData={drilldownData}
                        />
                    </div>
                </div>
                <div className="relative w-full px-2 mt-8 h-80">
                    <DynamicAllocationSplineChart
                        reportLevel={reportLevel}
                        selectedIds={selectedIds}
                        filters={filters}
                        drilldownData={drilldownData}
                    />
                </div>
            </div>
        </div>
    )
}

export default Allocation
