import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_PDP_AGING_REPORT,
} from 'src/Redux/actions/reports'

const colss = [
    'Employee Name',
    'Employee Type',
    'Date of Joining',
    'Designation',
    'Band',
    'Department',
    // 'WorkForce Group',
    // 'Emp Type',
    // 'Technology',
    'Primary Skill',
]
const cols1 = [
    // 'Employee Name',
    // 'Business Unit',
    // 'Company',
    // 'Location',
    'Employee Name',
    'Service Line',
    'Reporting Manager',
    'PDP Allocation %',
    'Bench Age days',
    'Last Staffed Date',
]

const rows = [10, 25, 50]

const ReportList = ({
    filters,
    pdpAgingReport,
    reportMasterList,
    reportId,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const [currentColsIndex, setCurrentColsIndex] = useState(0)
    const allCols = [colss, cols1]
    const handleRightSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex + 1) % allCols.length)
    }
    const handleLeftSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex - 1) % allCols.length)
    }
    const currentCols = allCols[currentColsIndex]

    const handlePagination = (page) => {
        dispatch(
            GET_PDP_AGING_REPORT({
                ...filters,
                limit: limit,
                page: page?.selected + 1,
            }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_PDP_AGING_REPORT({ ...filters, limit: row, page: row > 10 ? 1 : page }),
        )
    }

    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    const totalRecords = useMemo(() => {
        return pdpAgingReport?.length > 0
            ? pdpAgingReport[0]?.totalcount
            : pdpAgingReport?.length
    }, [pdpAgingReport])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={currentColsIndex === 0}
                        onClick={handleLeftSlide}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={currentColsIndex === 1}
                        onClick={handleRightSlide}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>

                <div className="flex items-center justify-between w-full space-x-6">
                    <p className="text-primary font-[500]">
                        Reports Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32 ">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        fileName={fileName}
                        limit={totalRecords}
                        endpoint="Download_pdpagingreport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        filters={filters}
                    />
                </div>
            </div>
            {pdpAgingReport === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(90%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {currentCols?.map((col, index) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="p-2 font-[500] border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {pdpAgingReport?.map((item, i) => {
                                let {
                                    employeecode,
                                    employeename,
                                    servicelinename,
                                    uId,
                                    lastprojectenddate,
                                    baldays,
                                    skill,
                                    managername,
                                    managercode,
                                    designation,
                                    department,
                                    emptype,
                                    benchperc1,
                                    dateofjoining,
                                    band,
                                } = item
                                return (
                            <tr key={uId} className="text-left cursor-pointer">
                                {currentColsIndex === 0 && (
                                    <>
                                        <td className="px-2 py-1 border w-[240px]">
                                            {employeename}{' '}
                                            {employeecode
                                                ? `(${employeecode})`
                                                : ''}{' '}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {emptype}
                                        </td>
                                        <td className="w-40 p-1 px-2 border">
                                            {dateofjoining}
                                        </td>
                                        <td className="w-40 p-1 px-2 border">
                                            {designation}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {band}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {department}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {skill}
                                        </td>
                                    </>
                                )}
                                {currentColsIndex === 1 && (
                                    <>
                                        <td className="px-2 py-1 border w-[240px]">
                                            {employeename}{' '}
                                            {employeecode
                                                ? `(${employeecode})`
                                                : ''}{' '}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {servicelinename}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {managername}{' '}
                                            {managercode
                                                ? `(${managercode})`
                                                : ''}{' '}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            { }
                                            {benchperc1 !== null
                                                ? `${benchperc1}%`
                                                : 'N/A'}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {baldays}
                                        </td>
                                        <td className="p-1 px-2 border ">
                                            {lastprojectenddate}
                                        </td>
                                    </>
                                )}
                            </tr>
                            )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ReportList
