
const Indecator = () => {
    return (
        <div className="text-xs flex justify-end items-center space-x-2">
            <span className="h-4 w-[3px] rounded-md  bg-orange-500" />
            <span>Billable</span>
            <span className="h-4 w-[3px] rounded-md  bg-green-500" />
            <span>Client Approval</span>
            <span className="h-4 w-[3px] rounded-md  bg-blue-500" />
            <span>Holiday</span>
            <span className="h-4 w-[3px] rounded-md  bg-gray-500" />
            <span>Leave</span>
        </div>
    )
}

export default Indecator;