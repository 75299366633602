export const ServiceItem = () => {
	return {
		servicelineid: null,
		servicelinename: '',
		servicelinegroupid: null,
		servicelineheadid: 0,
		servicelinecontactpersonid: null,
		comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
		isactiveflag: true,
		createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
		notes: null,
		servicelineskillareamapping: [
			{
				skillareamasterid: 0
			},
			{
				skillareamasterid: 0
			}
		]
	}
}

export const ServiceGroupItem = () => {
	return {
		servicelinegroupid: null,
		servicelinegroupname: '',
		groupheadid: null,
		groupcontactpersonid: null,
		comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
		isactiveflag: true,
		createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
		notes: null,
		Servicelineheadid: 0
	}
}

export const detailNotRequired = [
	'servicelineid',
	'servicelinecontactpersonid',
	'notes', 'servicelinegroupid',
	'slcode',
	'groupheadid',
	'groupcontactpersonid',
	'Servicelineheadid',
	'skillareamasterid'
];

