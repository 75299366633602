import { Link, useLocation } from "react-router-dom";

// const colors = ['red', 'green', '#FFBF00']
const progress = ['90%', '10%', '30%', "43%", "60%"]


const getCategory = (category)=>{
    if(category === 'I'){
        return 'Internal'
    }else if(category === 'C'){
        return 'Customer'
    }
}

const Cards = ({ item, id }) => {
    const {
        progressInPercentage = progress[Math.floor(Math.random() * progress.length)],
        // color = colors[Math.floor(Math.random() * colors.length)]
    } = item
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    // Get a specific parameter value
    const AppliedFilters = params.get('AppliedFilters');


    return (
        <Link className="shadow bg-white rounded-lg" id={`project-${id}`} to={`/app/project/detail?projectid=${id}&AppliedFilters=${AppliedFilters}`}>
            <div className="relative flex justify-between">
                <div className="text-sm font-semibold flex justify-center items-center ml-4 my-2">
                    <p className="ml-1 text-primary">{item?.projectcode} ({getCategory(item?.category)})</p>
                </div>
                <button className='bg-green-200 h-8 font-semibold inline-flex items-center text-green-600 text-sm mx-2 px-2 rounded-b-lg'>
                    {item?.statusname}
                </button>
            </div>
            <div className="w-full px-4 pb-4">
                <div className="mr-2 mb-2 flex justify-start items-center py-1">
                    <div className="w-full">
                        <p className=" text-lg text-gray-900 font-medium title-font truncate" title={item?.projectname}>{item?.projectname}</p>
                        <p className="capitalize text-gray-500 text-xs truncate">Company: {item?.companyname}</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 text-sm text-gray-500 capitalize pb-2">
                    <div className="mr-2 flex justify-between items-center">
                        <span className="text-gray-700">Customer:</span>
                        <p className="truncate">{item?.customername}</p>
                    </div>
                    <div className="mr-2 flex justify-between items-center">
                        <span className="text-gray-700">Project Type:</span>
                        <p className="truncate">{item?.projecttype}</p>
                    </div>
                    <div className="mr-2 flex justify-between items-center">
                        <span className="text-gray-700">Billing Type:</span>
                        <p> {item?.billingtype}</p>
                    </div>
                    <div className="mr-2 flex justify-between items-center">
                        <span className="text-gray-700">PM:</span>
                        <p> {item?.projectmanager}</p>
                    </div>
                    <div className="mr-2 flex justify-between items-center">
                        <span className="text-gray-700">DM:</span>
                        <p>{item?.deliverymanager}</p>
                    </div>
                </div>
                <div className='w-full mt-2 rounded-lg bg-gray-200'>
                    <div style={{ width: progressInPercentage }} className='h-1 bg-primary rounded-lg flex justify-end items-center' />
                </div>
            </div>
        </Link>
    )
}
export default Cards;