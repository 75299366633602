

import SingleSelectField from "src/Components/SingleSelectForTable";

const Company = ({ options, disabled, value, rowId, handleChange }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'companyid', value: value['companyid'], item: value } }
        handleChange(e, rowId)
    }

    return (
        <div>
            <SingleSelectField
                valueKey='companyid'
                labelKey='companyname'
                options={options}
                value={value}
                handleSelected={getSelected}
                disabled={disabled}
            />
        </div>
    )
}

export default Company;