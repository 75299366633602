import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TimesheetReportsFilter from 'src/Filter/reports/timsheet/timesheet'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import {
    GET_TIMESHEET_REPORT_FOR_EMPLOYEE,
} from 'src/Redux/actions/reports'
import { getTimesheetReportPayload } from 'src/Services/constant/defaultPayload'
import StaffTimesheetReport from './table'

const TimesheetReports = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getTimesheetReportPayload())
    const dispatch = useDispatch()
    let { projectsOfM } = useSelector((state) => state?.ProjectTimesheet)
    let { reportMasterList } = useSelector((state) => state?.WorkforceReport)
    const [project, setProject] = useState([])
    // const [user, setUser] = useState({})
    const projectIds = projectsOfM?.map((project) => project.projectid)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const projectList = async () => {
        let items = await dispatch(GET_TIMESHEET_PROJECTS_M())
        const pId = items?.map((project) => project?.projectid)
        if (pId) {
            let payload = {
                ...filters,
                comuserid: 0,
                projectid: `{${pId?.join(',')}}`,
                weekstartdate: '',
                weekenddate: '',
            }
            dispatch(GET_TIMESHEET_REPORT_FOR_EMPLOYEE(payload))
            setFilters(payload)
        }
    }

    useEffect(() => {
        projectList()
        // eslint-disable-next-line
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 text-sm bg-white rounded-md shadow">
                <TimesheetReportsFilter
                    projectIds={projectIds}
                    filters={filters}
                    setFilters={setFilters}
                    project={project}
                    setProject={setProject}
                    reportId={reportId}
                    setReportId={setReportId}
                    page={page}
                    limit={limit}
                />
            </div>
            <StaffTimesheetReport
                projectIds={projectIds}
                filters={filters}
                // project={project}
                reportId={reportId}
                reportMasterList={reportMasterList}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(TimesheetReports)
