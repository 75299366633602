import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import { GET_OPPORTUNITY_LIST, GET_PROJECTS } from 'src/Redux/actions/projects'
import { GET_DEMAND_LIST_MASTER } from 'src/Redux/actions/workforce/demands'
import {
    GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    GET_DEPARTMENT_MASTER,
} from 'src/Redux/actions/workforce/talent'
import { getDefaultDemandPayload } from 'src/Services/constant/Demandpayload'
import {
    getDefaultOpportunityPayload,
    getDefaultProjectPayload,
    getDepartmentMasterIdData,
    getStatusMasterIdData,
} from 'src/Services/constant/defaultPayload'
import SelectDepartment from './selectDepartment'
import SelectLocation from './selectLocation'
import SelectOpportunity from './selectOpportunity'
import SelectProject from './selectProject'

const requestedDatesList = [
    { requesteddate: '<1 week' },
    { requesteddate: '1-3 weeks' },
    { requesteddate: '3-6 weeks' },
    { requesteddate: '>6 weeks' },
]
const startDatesList = [
    { startdate: '<1 week' },
    { startdate: '1-3 weeks' },
    { startdate: '3-6 weeks' },
    { startdate: '>6 weeks' },
]
const requestTypeslist = [
    { requesttype: 'Internal', id: 'I' },
    { requesttype: 'Opportunity', id: 'O' },
    { requesttype: 'Project', id: 'P' },
]
const prioritiesList = [
    { priority: 'Low', id: 'L' },
    { priority: 'Medium', id: 'M' },
    { priority: 'High', id: 'H' },
]

const DemandlistFilter = ({ cols = 'grid-cols-6', filters, setFilters }) => {
    const dispatch = useDispatch()
    const { technologylist, allocationstatuslist, companylist, departmentlist } =
        useSelector((state) => state?.EmployeeDetails)
    const { listOfOpportunity, projectList } = useSelector((state) => state?.Projects)
    const { userSkillList } = useSelector((state) => state?.EmployeeProfile)
    const [opportunity, setOpportunity] = useState({})
    const [department, setDepartment] = useState({})
    const [project, setProject] = useState({})
    const [location, setLocation] = useState({})
    const [reset, setReset] = useState(false)
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    // Get a specific parameter value
    const datmenuid = params.get('datmenuid')
    const AppliedFilters = params.get('AppliedFilters')

    useEffect(() => {
        dispatch(GET_OPPORTUNITY_LIST(getDefaultOpportunityPayload()))
        dispatch(GET_PROJECTS(getDefaultProjectPayload()))
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('W')))
    }, [dispatch])

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            cityid: location.comcityid || 0,
        }))
    }, [location, setFilters])

    const handleSelectChange = (name, value) => {
        setFilters({ ...filters, [name]: value })
    }
    const setBillingType = (option) => {
        setFilters({ ...filters, billabletype: option === 'Billable' ? true : false })
    }

    const applyFilters = () => {
        let data = {
            ...filters,
            cityname: location?.cityname,
        }
        let search = `/app/workforce/demand?datmenuid=${datmenuid}&AppliedFilters=${JSON.stringify(
            data,
        )}`
        navigate(search)
        dispatch(GET_DEMAND_LIST_MASTER({ ...data }))
    }

    const clearFilters = () => {
        let search = `/app/workforce/demand?datmenuid=${datmenuid}`
        navigate(search)

        dispatch(
            GET_DEMAND_LIST_MASTER({
                ...getDefaultDemandPayload(),
            }),
        )
        setFilters(getDefaultDemandPayload())
    }

    useEffect(() => {
        const { requesttype } = filters
        let updatedFilters = { ...filters }
        switch (requesttype) {
            case 'I':
                updatedFilters = {
                    ...updatedFilters,
                    departmentid: department?.departmentid || 0,
                }
                break
            case 'P':
                updatedFilters = { ...updatedFilters, projectid: project?.projectid || 0 }
                break
            case 'O':
                updatedFilters = {
                    ...updatedFilters,
                    opportunityid: opportunity?.opportunityid || 0,
                }
                break
            default:
                break
        }
        if (
            updatedFilters.departmentid !== filters.departmentid ||
            updatedFilters.projectid !== filters.projectid ||
            updatedFilters.opportunityid !== filters.opportunityid
        ) {
            setFilters(updatedFilters)
        }
        // eslint-disable-next-line
    }, [department, opportunity, project, filters])

    useEffect(() => {
        let applied = JSON.parse(AppliedFilters)
        if (applied) {
            setLocation({ cityname: applied?.cityname, comcityid: applied?.cityid })
            setFilters(applied)
        }
        // eslint-disable-next-line
    }, [AppliedFilters])

    return (
        <div className="w-full text-sm">
            <div className={`grid ${cols}  gap-4`}>
                <div>
                    <h1 className="mb-2 font-[500]">Company</h1>
                    <CustomhtmlSelect
                        options={companylist || []}
                        value={filters?.companyid}
                        noDataLabel="No Company"
                        name="companyid"
                        labelkey="companyname"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="companyid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Request Type</h1>
                    <CustomhtmlSelect
                        options={requestTypeslist || []}
                        value={filters?.requesttype}
                        noDataLabel="No request type"
                        name="requesttype"
                        labelkey="requesttype"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="id"
                    />
                </div>
                {filters?.requesttype === 'I' && (
                    <div>
                        <SelectDepartment
                            department={department}
                            handleSelectDepartment={setDepartment}
                            departmentlist={departmentlist}
                            reset={reset}
                        />
                    </div>
                )}
                {filters?.requesttype === 'P' && (
                    <div>
                        <SelectProject
                            project={project}
                            handleSelectProject={setProject}
                            projectList={projectList}
                            reset={reset}
                        />
                    </div>
                )}
                {filters?.requesttype === 'O' && (
                    <div>
                        <SelectOpportunity
                            opportunity={opportunity}
                            handleSelectOpportunity={setOpportunity}
                            listOfOpportunity={listOfOpportunity}
                            reset={reset}
                        />
                    </div>
                )}
                <div>
                    <h1 className="mb-2 font-[500]">Demand Status</h1>
                    <CustomhtmlSelect
                        options={allocationstatuslist || []}
                        value={filters?.statusid}
                        noDataLabel="No Allocation Status"
                        name="statusid"
                        labelkey="statusname"
                        className="p-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="statusid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Requested Date</h1>
                    <CustomhtmlSelect
                        options={requestedDatesList || []}
                        value={filters?.requesteddate}
                        noDataLabel="No Requested Date"
                        name="requesteddate"
                        labelkey="requesteddate"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="requesteddate"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Staffing Start Date</h1>
                    <CustomhtmlSelect
                        options={startDatesList || []}
                        value={filters?.startdate}
                        noDataLabel="No Staffing Start Date"
                        name="startdate"
                        labelkey="startdate"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="startdate"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Billable Type</h1>
                    <FilterButtonTab
                        // buttonWidth='w-52'
                        className="w-full py-2 border gray-50"
                        tabs={['Billable', 'Non-billable']}
                        setSelectedtab={setBillingType}
                        selectedTab={
                            filters?.billabletype ? 'Non-billable' : 'Billable' || null
                        }
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Skill</h1>
                    <CustomhtmlSelect
                        options={userSkillList || []}
                        value={filters?.skillid}
                        noDataLabel="No skill"
                        name="skillid"
                        labelkey="skill"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="skillmasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Technology</h1>
                    <CustomhtmlSelect
                        options={technologylist || []}
                        value={filters?.technologyid}
                        noDataLabel="No tech"
                        name="technologyid"
                        labelkey="technology"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="technologymasterid"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Priority</h1>
                    <CustomhtmlSelect
                        options={prioritiesList || []}
                        value={filters?.priority}
                        noDataLabel="No Priorities"
                        name="priority"
                        labelkey="priority"
                        className="py-2 border rounded bg-gray-50"
                        onChange={handleSelectChange}
                        id="id"
                    />
                </div>
                <div>
                    <h1 className="mb-2 font-[500]">Location</h1>
                    <SelectLocation location={location} setLocation={setLocation} />
                </div>
                {!filters?.requesttype ? <div></div> : ''}
                <div>
                    <h1 className="mb-2 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                                setReset(false)
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DemandlistFilter
