import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import IconButton from 'src/Components/Buttons/IconButton';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';

const cols = [
  'Customer SPOC',
  'Contact Type',
  'Designation',
  'Email',
  'Phone No.',
  'Is Primary',
  'Action',
];

const isValidatedField = (checkvalidation, index, keyname) => {
  return (
    checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
  );
};

const ContactInfo = (props) => {
  const {
    setContactsData,
    contactsData,
    mockObject,
    handleSubmit,
    //  customerid
  } = props;
  const [checkvalidation, setcheckvalidation] = useState(null);

  const handleChange = (e) => {
    let { id, name, value } = e.target;
    setcheckvalidation(null);
    let afterAdd = contactsData?.map((item) => {
      if (item?.uId === id) {
        item[name] = value;
      }
      return item;
    });
    setContactsData(afterAdd);
  };

  const handlePrimarySelect = (e) => {
    let { id, name, checked } = e.target;
    let afterChange = contactsData?.map((item) => {
      if (item?.uId === id) {
        item[name] = checked;
      } else {
        item[name] = false;
      }
      return item;
    });
    setContactsData(afterChange);
  };

  const handleAdd = () => {
    setContactsData([...contactsData, mockObject]);
  };

  const handleDelete = (uId) => {
    if (contactsData?.length > 1) {
      setContactsData(contactsData.filter((item) => item?.uId !== uId));
    }
  };

  const saveContactInfo = () => {
    let isValidated = CheckListObjectValidation(contactsData, ['notes']);
    if (isValidated?.isvalid) {
      handleSubmit();
    } else {
      setcheckvalidation(isValidated);
    }
  };

  return (
    <div className='mt-2 w-full h-[70vh]'>
      <div className='flex space-between items-center mt-2 text-sm align-items'>
        <table className='w-full mt-1 text-sm '>
          <thead>
            <tr className='text-left capitalize bg-gray-50'>
              {cols?.map((col) => {
                return (
                  <th
                    key={col}
                    title={col}
                    className='p-2 border font-[500] text-start'
                  >
                    {col}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className='text-gray-600 mr-4'>
            {contactsData?.map((item, i) => {
              const {
                uId,
                contactpersonname,
                designation,
                phoneno,
                email,
                contacttype,
                isprimary,
              } = item;
              return (
                <tr key={uId} className='text-left'>
                  <td
                    className={`${isValidatedField(checkvalidation, i, 'contactpersonname')
                      ? 'border-2 border-red-400'
                      : 'border'
                      } `}
                  >
                    <input
                      id={uId}
                      type='text'
                      value={contactpersonname || ''}
                      onChange={(e) => {
                        if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                          handleChange(e);
                        }
                      }}
                      name='contactpersonname'
                      placeholder='Contact Person'
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                    />
                  </td>
                  <td
                    className={`${isValidatedField(checkvalidation, i, 'contacttype')
                      ? 'border-2 border-red-400'
                      : 'border'
                      } `}
                  >
                    <input
                      id={uId}
                      type='text'
                      onChange={(e) => {
                        if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                          handleChange(e);
                        }
                      }}
                      value={contacttype || ''}
                      name='contacttype'
                      placeholder=' Contact Type'
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                    />
                  </td>
                  <td
                    className={`${isValidatedField(checkvalidation, i, 'designation')
                      ? 'border-2 border-red-400'
                      : 'border'
                      } `}
                  >
                    <input
                      id={uId}
                      onChange={handleChange}
                      type='text'
                      value={designation || ''}
                      name='designation'
                      placeholder='Designation'
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                    />
                  </td>
                  <td
                    className={`${isValidatedField(checkvalidation, i, 'email')
                      ? 'border-2 border-red-400'
                      : 'border'
                      } `}
                  >
                    <input
                      id={uId}
                      onChange={handleChange}
                      type='email'
                      value={email || ''}
                      name='email'
                      placeholder='Email'
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                    />
                  </td>
                  <td
                    className={`${isValidatedField(checkvalidation, i, 'phoneno')
                      ? 'border-2 border-red-400'
                      : 'border'
                      } `}
                  >
                    <input
                      id={uId}
                      onChange={handleChange}
                      type='number'
                      value={phoneno || ''}
                      name='phoneno'
                      placeholder='Phone No'
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                    />
                  </td>
                  <td className='border px-4 py-1'>
                    <input
                      id={uId}
                      onChange={handlePrimarySelect}
                      type='checkbox'
                      checked={isprimary || false}
                      name={`isprimary`}
                      className='outline-gray-100 text-gray-600 focus:outline-none block w-full px-2  '
                    // checked={item.isprimary}
                    />
                  </td>
                  <td className='border px-4 py-1'>
                    <div className='flex justify-start items-cpx-2 w-full'>
                      <IconButton
                        title='Delete'
                        onClick={() => handleDelete(item?.uId)}
                        className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                      >
                        <MinusIcon className="w-3" />
                      </IconButton>
                      <span className="hidden addIconbutton-add-btn">
                        <IconButton
                          title='Add'
                          onClick={handleAdd}
                          className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                        >
                          <PlusIcon className="w-3" />
                        </IconButton>
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='fixed bottom-2 right-5'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-2'
            onClick={saveContactInfo}
          >
            <span> Save</span>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;