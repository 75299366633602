import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import SelectInput from 'src/Components/SelectInput'
import { GET_EMPLOYEE_DETAILS } from 'src/Redux/actions/workforce/talent'
import { getDeafultTalentPayload } from 'src/Services/constant/defaultPayload'
import { basicShortList } from 'src/Services/constant/staticValues'
import AddApprovals from './staffApprovals'

const cols = [
    'Name',
    'Band',
    'Designation',
    'Department',
    'Emp Type',
    'Workforce',
    'Service Line',
    'Primary Skill',
    'Location',
    'Total Exp',
    'Allocation Status',
]

const rows = [10, 25, 50]

const Talenttable = ({ hooks }) => {
    const { page, setPage, setLimit, limit, filters, sortType, setSortType, empStatusList, userTypeId } = hooks
    const dispatch = useDispatch()
    const [searchTerm, setSearchTerm] = useState('')
    const [showSearch, setShowSearch] = useState(false)
    const EmployeeDetail = useSelector((state) => state?.EmployeeDetails)
    const { Employees, tanentPermission } = EmployeeDetail
    let navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const AppliedFilters = searchParams.get('AppliedFilters')

    const redirectTo = (tenid, userid) => {
        navigate(
            `/app/workforce/talent/detail?userid=${userid}&tenid=${tenid}&AppliedFilters=${AppliedFilters}`,
        )
    }

    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_EMPLOYEE_DETAILS({
                ...filters,
                limit: limit,
                pageno: page,
                sorttype: item?.id,
            }),
        )
    }

    const handleRows = (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_EMPLOYEE_DETAILS({
                ...filters,
                limit: row,
                pageno: row > 10 ? 1 : page,
                sorttype: sortType?.id,
            }),
        )
    }

    const handlePagination = (page) => {
        dispatch(
            GET_EMPLOYEE_DETAILS({
                ...filters,
                limit,
                pageno: page?.selected + 1,
                sorttype: sortType?.id,
            }),
        )
        setPage(page?.selected + 1)
    }

    const payloadWithAppliedFilter = () => {
        let applied = JSON.parse(AppliedFilters)
        if (applied) {
            return applied
        } else {
            return { ...getDeafultTalentPayload(), empstatus: `{${empStatusList.join(',')}}`, usertypeid: userTypeId }
        }
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setShowSearch(true)
        }, 1000)
        return () => {
            clearTimeout(debounceTimer)
        }
    }, [])

    return (
        <div className="mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mt-2 mb-2">
                <div className="flex items-center justify-start w-full space-x-6">
                    <div className="inline-flex items-center justify-end min-w-max ">
                        <h1 className="font-[500]">
                            Employee Details(
                            {Employees?.length > 0
                                ? Employees[0]?.totalcount
                                : Employees?.length}
                            )
                        </h1>
                    </div>
                    <div className="flex items-center justify-between w-full space-x-6">
                        <div className="flex items-center justify-start space-x-6">
                            <div className=" w-96">
                                {showSearch ? (<CustomAutoSearch
                                    hideOptions={true}
                                    searchTerm={searchTerm}
                                    API_CALL_FOR_SEARCH={GET_EMPLOYEE_DETAILS}
                                    searchPayload={{
                                        ...payloadWithAppliedFilter(),
                                        username: searchTerm,
                                    }}
                                    setSearchTerm={setSearchTerm}
                                    options={Employees}
                                    idkey="uId"
                                    optionlabelKey="firstname"
                                    optionLabelkey2="lastname"
                                    bracketLabel="employeecode"
                                    className="p-2"
                                    getSelectedOption={() => console.log()}
                                    placeholder="Enter Employee Name"
                                />) : (
                                    <div className="w-full h-8 bg-gray-200 rounded-md" />
                                )}
                            </div>
                            <ReactPaginationStyle
                                total={
                                    Employees?.length > 0
                                        ? Employees[0]?.totalcount
                                        : Employees?.length
                                }
                                limit={limit}
                                currentPage={page}
                                handlePagination={handlePagination}
                            />
                            <div className=" w-44">
                                <FilterButtonTab
                                    selectedTab={limit}
                                    tabs={rows}
                                    setSelectedtab={handleRows}
                                />
                            </div>
                            <div className="w-32 mb-1">
                                <SelectInput
                                    options={basicShortList || []}
                                    keyname="label"
                                    selected={sortType}
                                    setSelected={handleSorting}
                                />
                            </div>
                        </div>
                        {tanentPermission.includes('Approve Staffing') && (
                            <AddApprovals />
                        )}
                    </div>
                </div>
            </div>
            {Employees === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[50vh] overflow-scroll">
                    <table className="relative w-full mb-5 text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {cols.map((col) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="p-2 font-[500] border text-start"
                                        >
                                            {' '}
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {Employees?.map((item, i) => {
                                let {
                                    department,
                                    // gender,
                                    firstname,
                                    lastname,
                                    servicelinename,
                                    // skillarea,
                                    cityname,
                                    allocationstatus,
                                    workertype,
                                    workforcegroup,
                                    designation,
                                    comuserid,
                                    comtenantid,
                                    uId,
                                    employeecode,
                                    allocation_percentage,
                                    bandname,
                                    totalexperienceinmonths,
                                    primaryskill,
                                } = item
                                // console.log(item)
                                const totalexperienceinyears = (
                                    totalexperienceinmonths / 12
                                ).toFixed(1)
                                return (
                                    <tr
                                        role="button"
                                        onClick={() => redirectTo(comtenantid, comuserid)}
                                        key={uId + employeecode + i}
                                        className="text-left cursor-pointer "
                                    >
                                        <td className="p-1 px-2 border">
                                            {firstname} {lastname}{' '}
                                            {employeecode ? `(${employeecode})` : ''}
                                        </td>
                                        <td className="p-1 px-2 border">{bandname}</td>
                                        <td className="p-1 px-2 border">{designation}</td>
                                        <td className="p-1 px-2 border">{department}</td>
                                        <td className="p-1 px-3 border">{workertype}</td>
                                        <td className="p-1 px-2 border">
                                            {workforcegroup}
                                        </td>
                                        <td className="p-1 px-2 border">
                                            {servicelinename}
                                        </td>
                                        <td className="p-1 px-2 border">
                                            {primaryskill}
                                        </td>
                                        <td className="p-1 px-2 border">{cityname}</td>
                                        <td className="p-1 px-2 border">
                                            {totalexperienceinyears}{' '}
                                            {totalexperienceinmonths ? 'Years' : ''}{' '}
                                        </td>
                                        <td className="p-1 px-2 border">
                                            {allocationstatus} •{' '}
                                            {allocation_percentage || 0}%
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default Talenttable