import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import GetUserFromAdmin from 'src/Components/GetUserFromAdmin'
import SelectMonth from 'src/Components/SelectMonth'
import { getDefaultPayrollPayload } from 'src/Pages/app/Financials/payroll/defaultPayload'
import { GET_PROJECT_USER_PAYROLL } from 'src/Redux/actions/projects/financials'

const FinancialsFilterPayroll = ({
    cols = 'grid-cols-6',
    filters,
    setFilters,
    setUserPayroll,
    user,
    setUser,
    limit,
    page,
    sortType,
}) => {
    const dispatch = useDispatch()

    const handleSelectChange = (e) => {
        let { name, value } = e.target
        setFilters({ ...filters, [name]: value })
    }

    const clearFilters = async () => {
        setFilters(getDefaultPayrollPayload())
        setUser({})
        dispatch(GET_PROJECT_USER_PAYROLL(getDefaultPayrollPayload(limit, page, sortType?.id)))
    }

    const applyFilters = async () => {
        let res = await dispatch(
            GET_PROJECT_USER_PAYROLL(filters),
        )
        setUserPayroll(res)
    }

    useEffect(() => {
        setFilters({ ...filters, COMUserId: user?.comuserid || 0 })
        // eslint-disable-next-line
    }, [user, setFilters])

    const handleMonthChange = (e) => {
        let { name, value } = e.target
        const [year, month] = value.split('-')
        const parsedMonth = parseInt(month, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        setFilters({
            ...filters,
            [name]: value,
            Monthid: validMonthid,
            Yearid: validYearid,
        })
    }

    return (
        <div className={`grid ${cols} gap-4 text-sm p-2`}>
            <div className="mt-1 ">
                <p className="font-[500]">Select User</p>
                <GetUserFromAdmin selected={user} setSelected={setUser} />
            </div>
            <div>
                <p className="font-[500]">Select Month/Year</p>
                <SelectMonth
                    onChange={handleMonthChange}
                    name="Monthid"
                    className="flex items-center justify-start w-full p-2 space-x-1 border rounded-md"
                    year={filters?.Yearid}
                    month={
                        filters?.Monthid?.length === 1
                            ? `0${filters?.Monthid}`
                            : filters?.Monthid
                    }
                />
            </div>
            <div>
                <p className="font-[500]">Select Cost Center Code</p>
                <input
                    value={filters?.Costcentercode || ''}
                    onChange={handleSelectChange}
                    name="Costcentercode"
                    placeholder="Cost Center Code "
                    type="text"
                    className="w-full p-2 mt-1 text-gray-600 border rounded-md"
                />
            </div>
            <div></div>
            <div></div>
            <div className="flex justify-end mt-0">
                <div className="w-40">
                    <h1 className="mb-1 font-[500]">Action</h1>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => {
                                clearFilters()
                            }}
                            className="px-2 border border-primary text-primary rounded mt-0 h-9 py-1.5"
                        >
                            Clear
                        </button>
                        <button
                            onClick={applyFilters}
                            className="px-2 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5"
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialsFilterPayroll
