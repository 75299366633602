
import ToastContent from "src/Components/ToastContent";
import { toast, Zoom } from 'react-toastify';


const successicon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

let erroricon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
</svg>


const AlertSuccess = (text, title = 'Success !', time = 2000) => {
    return toast.success(
        <ToastContent type='success'
            icon={successicon}
            text={<span className="fw-lighter">{text}</span>}
            title={<span className="fw-lighter text-success" >{title}</span>} />,
        { transition: Zoom, hideProgressBar: true, autoClose: time, className: 'text-sm rounded-lg shadow w-[500px] ' }
    )
}


const AlertError = (title = 'Fail :(', text) => {
    return toast.error(
        <ToastContent type='light-danger'
            icon={erroricon}
            title={title}
            text={<span className="fw-light">{typeof text === 'object' ? 'Something is wrong Please  try on more time ' : text} </span>} />,
        { transition: Zoom, hideProgressBar: true, autoClose: 2000, className: 'text-sm rounded-lg shadow w-[500px] ' }
    )
}

function findUniqueById(data, uniqueby) {
    const uniqueData = {};
    data.forEach(item => {
        if (!uniqueData.hasOwnProperty(item[uniqueby])) {
            uniqueData[item[uniqueby]] = item;
        }
    });
    return Object.values(uniqueData);
}

const getStaffingIdByNameOfStatus = (data, name) => {
    return data?.find(it => it?.statusname === name)?.statusid
}

const getTotalEffortOfweek = (item) => {
    let sum = 0
    for (const date in item) {
        if (Object.prototype.hasOwnProperty.call(item, date) && typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
            sum = sum + Number(item[date])
        }
    }
    return sum
}

const getTotalFromSameObject = (data, keynameIs) => {
    let initialValue = 0
    try {
        let sum = data?.reduce(function (accumulator, curValue) {
            return Number(accumulator) + Number(curValue[curValue[keynameIs]['date']])
        }, initialValue)
        return sum
    } catch (error) {
        return 0
    }

}

const getTotalFromArrayOfObjects = (data, keynameIs) => {
    let initialValue = 0
    let sum = data?.reduce(function (accumulator, curValue) {
        return Number(accumulator) + Number(curValue[keynameIs])
    }, initialValue)

    return sum || 0
}

const getTotalPeopleCostProjectPlanning = (data, billRate) => {
    let initialValue = 0
    let sum = data?.reduce(function (accumulator, curValue) {
        return Number(accumulator) + Number(curValue[billRate] || 0)
    }, initialValue)

    return sum
}

const replaceDatebyDayName = (data) => {
    let daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    let item = data
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key) && typeof key === 'string' && key.match(/^\d{4}-\d{2}-\d{2}$/)) {
            const date = new Date(key);
            const dayOfWeek = daysOfWeek[date.getDay()];
            item[dayOfWeek] = { day: dayOfWeek, date: key }
        } else {
            item[key] = data[key]
        }
    }
    return item

}

const getDateFromItemByDayName = (data, daynameis) => {
    // console.log(data)
    let daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    let item = data
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key) && typeof key === 'string' && key.match(/^\d{4}-\d{2}-\d{2}$/)) {
            const date = new Date(key);
            if (daynameis === daysOfWeek[date.getDay()]) {
                return key
            }
        } else {
            item[key] = data[key]
        }
    }

}

const isfuturedate = (date) => {
    let givenDate = new Date(date);
    let currentDate = new Date();

    if (givenDate > currentDate) {
        return true
    } else if (givenDate < currentDate) {
        return false
    } else {
        return false
    }
}

function isPastDate(dateString) {
    // Parse the input date string
    const parts = dateString.split("-");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is zero-based
    const year = parseInt(parts[2], 10);

    // Create Date objects for the input date and current date
    const inputDate = new Date(year, month, day);
    const currentDate = new Date();

    // Compare the input date with the current date
    return inputDate < currentDate;
}

const getWorkingDaysStartAndEndDate = (startDate, endDate) => {

    // Convert start and end dates to JavaScript Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    let workingDays = 0;
    let currentDate = start;

    // Iterate through each day in the range
    while (currentDate <= end) {
        // Check if the current day is not Saturday (6) or Sunday (0)
        if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
            workingDays++;
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return workingDays;
};

const calculatEstdEffortHrs = (noPeople, alloc, hourPerDay, numberOfDays) => {
    return Math.round((noPeople * alloc * hourPerDay * numberOfDays) / 100)
}

const getDifferenceFromCurrentDay = (startingDate) => {
    // Calculate the difference in milliseconds
    var differenceMs = Math.abs(startingDate - new Date());

    // Convert the difference to days
    var differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    return differenceDays
}

function findDuplicatesByKeyName(array, property) {
    const seen = {};
    const duplicates = [];

    array.forEach((item) => {
        const value = item[property];

        if (seen[value]) {
            duplicates.push(item);
        } else {
            seen[value] = true;
        }
    });

    return duplicates;
}

function getLastMonthAndYear() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let lMonth, lastYear;

    // Check if the current date is 3rd January
    if (today.getDate() === 3 && currentMonth === 0) {
        lMonth = 12; // December (12th month)
        lastYear = currentYear - 1; // Last year
    } else {
        lMonth = currentMonth === 0 ? 12 : currentMonth; // Previous month
        lastYear = currentMonth === 0 ? currentYear - 1 : currentYear; // Current year or last year
    }
    let lastMonth = lMonth < 10 ? `0${lMonth}` : lMonth
    return { lastMonth, lastYear };
}


function getQuarterDates() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    let startDate, endDate;

    if (currentMonth >= 1 && currentMonth <= 3) {
        // Quarter 1: Jan 1st - Mar 31st
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 2, 31);
    } else if (currentMonth >= 4 && currentMonth <= 6) {
        // Quarter 2: Apr 1st - Jun 30th
        startDate = new Date(currentDate.getFullYear(), 3, 1);
        endDate = new Date(currentDate.getFullYear(), 5, 30);
    } else if (currentMonth >= 7 && currentMonth <= 9) {
        // Quarter 3: Jul 1st - Sep 30th
        startDate = new Date(currentDate.getFullYear(), 6, 1);
        endDate = new Date(currentDate.getFullYear(), 8, 30);
    } else {
        // Quarter 4: Oct 1st - Dec 31st
        startDate = new Date(currentDate.getFullYear(), 9, 1);
        endDate = new Date(currentDate.getFullYear(), 11, 31);
    }

    // Format the dates as "YYYY-MM-DD"
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    return { startDate: formattedStartDate, endDate: formattedEndDate }

}

// Helper function to format date as "YYYY-MM-DD"
function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}
function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

function getPermissionsOfCurrentUrl(modulename, menuname) {
    let location = window.location
    const menuinfo = JSON.parse(localStorage.getItem('menuinfo'))
    let activeModule = menuinfo?.find(menu => menu?.modulealiasname === modulename)
    let getPermissions = activeModule?.moduledetails?.find(per=> per?.menuname[0]?.menuname3 === menuname && per?.menuname[0]?.RelativePage3 === location?.pathname)
    if(getPermissions?.menuname){
        return getPermissions?.menuname[0]?.permissionnames
    }
    return []
   
}



export {
    getPermissionsOfCurrentUrl,
    getQuarterDates,
    getTotalEffortOfweek,
    getTotalFromArrayOfObjects,
    getTotalPeopleCostProjectPlanning,
    replaceDatebyDayName,
    getTotalFromSameObject,
    getDateFromItemByDayName,
    AlertError,
    AlertSuccess,
    isfuturedate,
    getDifferenceFromCurrentDay,
    findDuplicatesByKeyName,
    getWorkingDaysStartAndEndDate,
    calculatEstdEffortHrs,
    getLastMonthAndYear,
    isPastDate,
    getFileExtension,
    getStaffingIdByNameOfStatus,
    findUniqueById
}