import Actionheader from "./ActionHeader"
import BaseInfo from "./baseInfo"
import { useEffect, useMemo, useState } from "react"
import StaffingTable from "./table"
import { useDispatch, useSelector } from "react-redux"
import { GET_PROJECT_STAFFING_LIST, GET_PROJECT_STAFFING_LIST_SEARCH, INSERT_PROJECT_STAFFING } from "src/Redux/actions/projects/staffing"
import { GET_TIMESHEET_PROJECTS_M } from "src/Redux/actions/projects/timesheet"
import { GET_LIST_OF_PROJECT_ROLE } from "src/Redux/actions/projects/planning"
import PrimaryButton from "src/Components/Buttons/PrimaryButton"
import { defaultItem } from './defaultdata'
import { GET_LIST_OF_STATUS } from "src/Redux/actions/projects"
import { CheckObjectValidation } from "src/Services/constant/customvalidation"
import { insertKeysForProjectStaffing, sortTypeStaffing } from "src/Services/constant/staticValues"
import { useLocation, useNavigate } from "react-router-dom"
import moment from "moment"
import { GET_LIST_OF_STATUS_BY_E, GET_USER_TYPE_LIST } from "src/Redux/actions/masterdata/user"
import { findUniqueById, getPermissionsOfCurrentUrl, getStaffingIdByNameOfStatus } from "src/Services"
import { getUserTypeIdData } from "src/Services/constant/defaultPayload"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import ReactPaginationStyle from "src/Components/ReactPagination"
import FilterButtonTab from "src/Components/FilterButtonTab"
import IconButton from "src/Components/Buttons/IconButton"
import CustomhtmlSelect from "src/Components/CustomhtmlSelect"
import { ToastContainer } from "react-toastify"

let optionalFields = ['roleallocation','loginusername', 'requesttype', 'opportunityname', 'demandstartdate',
    'demandstatus', 'demandstatusid', 'demandstatuscode', 'demandcode', 'projectmanagername',
    'projectstaffid', 'demanddepartment', 'plannedstartdate', 'plannedenddate',
    'releasedate', 'plannedallocation', 'skillareamasterid', 'skillarea', 'techarea', 'techgroupid',
    'userid', 'allocation', 'isactiveflag', 'notes', 'projectid', 'comtenantid',
    'designation', 'bandname',
    'demandprojectname', 'currency', 'projectname', 'max_band', 'max_bandid', 'min_band',
    'min_bandid', 'cityname', 'workmode', 'username', 'rolename', 'cityid', 'citytext', 'billratehr', 'cost_hr', 'peopleno', 'levelid', 'skillid', 'techid',
    'levelname', 'skill', 'technology', 'billable', 'employeecode', 'workertypeid',
    'workertype', 'staffingstatusid', 'statffingstatus', 'uId', 'demandid', 'countryname', 'countryid']
const rows = [10, 25, 50];

const Staffing = ({ modulename, menu }) => {
    const {projectsOfM} = useSelector(state => state?.ProjectTimesheet)
    const {listOfProjectStaffing, staffingFte} = useSelector(state => state?.ProjectStaffing)
    const {getListOfProjectRole} = useSelector(state => state?.ProjectPlanning)
    const {statusListByType} = useSelector(state => state?.Projects)
    const navigate = useNavigate()

    const [staffingData, setstaffingData] = useState([defaultItem()])
    const [canEdit, setCanEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [validation, setValidtion] = useState({})
    const [demandid, setDemandid] = useState(null)
    const dispatch = useDispatch()
    const location = useLocation()
    const [warning, setWarning] = useState(false)
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const staffingProjectId = searchParams.get('projectid')
    const [project, setProject] = useState({})
    const [allProjects, setAllProjects] = useState(null)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchTerm, setSearchTerm] = useState('')
    const [rolesOption, setRolesOption] = useState([])
    const [statusoption, setStatusOption] = useState([])
    const [thisPagePermissions, setThisPagePermissions] = useState([])
    const [filters, setFilters] = useState({ croleid: 0, staffingstatusid: null, sorttype: 4 })
    // Staffing Status get id by status name
    let ReleaseA = getStaffingIdByNameOfStatus(statusListByType, "Release Approval")
    let extensionA = getStaffingIdByNameOfStatus(statusListByType, "Extension Approval")

    const fetchAllProjectList = async () => {
        let res = await dispatch(GET_TIMESHEET_PROJECTS_M())
        if (res?.length > 0) {
            if (staffingProjectId) {
                let p = res?.find(p => p?.projectid === +staffingProjectId)
                setProject(p)
            } else {
                navigate(`/app/projects/staffing?projectid=${res[0]?.projectid}`)
                setProject(res[0])
            }

            setAllProjects(res)
        }
    }

    useEffect(() => {
        let statusP = {
            Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            statustype: "S"
        }
        fetchAllProjectList()
        dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()))
        dispatch(GET_LIST_OF_STATUS_BY_E())
        dispatch(GET_LIST_OF_STATUS(statusP))
        // eslint-disable-next-line
    }, [dispatch])

    const saveStaffing = async () => {
        let index = 0
        for (let item of staffingData) {
            let res = await CheckObjectValidation(item, optionalFields)
            // console.log(item, optionalFields)
            if (!res?.isvalid) {
                setValidtion({ ...res, rowId: index })
                return
            }
            index = index + 1
        }
        setValidtion(null)
        let itemsCopy = []
        for (let item of staffingData) {
            let info = {}
            let endDate = moment(item?.enddate).format('YYYY-MM-DD')
            let startDate = moment(item?.startdate).format('YYYY-MM-DD')
            let releaseDate = moment(item?.releasedate).format('YYYY-MM-DD')

            if (item?.StaffingStatusId !== undefined) {
                info['StaffingStatusId'] = item?.StaffingStatusId
            } else {
                info['StaffingStatusId'] = item?.staffingstatusid
            }
            // if extending project then pass "Extension Approval" ( id )
            if (endDate < releaseDate && item?.releasedate) {
                info['StaffingStatusId'] = extensionA // 29
            }
            // if end date is greater than release date pass "Release Approval" ( id )
            else if (endDate > releaseDate && item?.releasedate) {
                info['StaffingStatusId'] = ReleaseA // 28
            }

            for (let rowkey of insertKeysForProjectStaffing) {
                info[rowkey] = item[rowkey]
            }
            info['userid'] = item?.userid
            info['startdate'] = startDate
            info['enddate'] = endDate
            if (item['plannedallocation'] > 0) {
                info['plannedallocation'] = (Number(item?.plannedallocation || 0) / 100).toFixed(2)
            }
            if (info['plannedstartdate'] === null) {
                info['plannedstartdate'] = startDate
            }
            if (info['plannedenddate'] === null) {
                info['plannedenddate'] = endDate
            }
            if (info['plannedallocation'] === null) {
                info['plannedallocation'] = (Number(item?.allocation || 0) / 100).toFixed(2)
            }
            info['allocation'] = (Number(item?.allocation || 0) / 100).toFixed(2)
            info['createdcomuserid'] = JSON.parse(localStorage.getItem('comuserid'))
            info['projectid'] = project?.projectid
            itemsCopy.push(info)
        }
        let res = await dispatch(INSERT_PROJECT_STAFFING(itemsCopy))
        if (res) {
            setPage(1)
            dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId || project?.projectid, null, 1, limit))
            setLoading(false)
            setCanEdit(false)
        } else {
            dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId || project?.projectid, null, 1, limit))
        }
    }

    const handleCancel = () => {
        setCanEdit(null)
        dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId || project?.projectid, null, 1))
    }

    const handlePagination = async (page) => {
        dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId || project?.projectid, null, page?.selected + 1, limit))
        setPage(page?.selected + 1);
    };

    const handleRows = async (row) => {
        setLimit(row);
        let payload = {
            ...filters,
            searchname: searchTerm?.trim(),
            projectid: +staffingProjectId || project?.projectid,
            pageno: 1,
            limit: row,
            staffingstatusid: filters?.staffingstatusid ? [filters?.staffingstatusid] : null
        }
        dispatch(GET_PROJECT_STAFFING_LIST_SEARCH(payload))
    };

    const handleFilter = (name, value) => {
        let data = {
            ...filters,
            [name]: value
        }
        setFilters(data)
        let payload = {
            ...data,
            searchname: searchTerm?.trim(),
            projectid: +staffingProjectId || project?.projectid,
            pageno: page,
            limit,
            staffingstatusid: data?.staffingstatusid ? [data?.staffingstatusid] : null
        }
        dispatch(GET_PROJECT_STAFFING_LIST_SEARCH(payload))
    }

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map(item => item?.permissionname);
        setThisPagePermissions(permissionNames)
        // eslint-disable-next-line
    }, [menu])

    useEffect(() => {
        if (staffingProjectId) {
            dispatch(GET_LIST_OF_PROJECT_ROLE(+staffingProjectId))
            dispatch(GET_PROJECT_STAFFING_LIST(+staffingProjectId, null, page, limit))
        }
        // eslint-disable-next-line
    }, [staffingProjectId])

    useEffect(() => {
        if (listOfProjectStaffing) {
            setstaffingData(listOfProjectStaffing)
        } else {
            let staffingId = statusListByType?.find(i=> i?.statuscode === "SPENDFUL")?.statusid
            setstaffingData([defaultItem(staffingId)])
        }
    // eslint-disable-next-line
    }, [listOfProjectStaffing])

    useMemo(() => {
        if (getListOfProjectRole) {
            setRolesOption([{ projectroleid: 0, rolename: 'All Roles' }, ...getListOfProjectRole])
        } if (statusListByType) {
            setStatusOption([{ statusid: 0, statusname: 'All Statuses' }, ...statusListByType])
        }
    }, [getListOfProjectRole, statusListByType])

    const selectProject = (pro) => {
        setProject(pro)
        navigate(`/app/projects/staffing?projectid=${pro?.projectid}`)
    }

    const editAddButtonPermission = () => {
        if (project?.statusname === 'Execution' && project?.projecttype !== 'Bench') {
            if (thisPagePermissions.includes('Add/Edit Staffing')) {
                return true
            }
        }
        return false
    }


    const getSelectedValue = (e) => {
        if (e?.uId) {
            setstaffingData([e])
        }
    }

    return (
        <>
            <Actionheader hooksValue={{ projectsOfM, project, selectProject }} />
            {allProjects?.length > 0 && <>
                <BaseInfo project={project} />
                <div className="relative w-full h-[calc(100%-200px)] px-2 pt-3 mt-4 text-sm bg-white rounded-md">
                    <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center justify-start space-x-2 ">
                            <div className="flex items-start justify-start mr-2 space-x-2">
                                <p className="font-[500] inline-flex justify-start ">Total Records: {listOfProjectStaffing?.length > 0 ? listOfProjectStaffing[0]?.totalcount : listOfProjectStaffing?.length}</p>
                            </div>
                            <div className=" w-72">
                                <CustomAutoSearch
                                    defaultApiCall={searchTerm !== ''}
                                    hideOptions={false}
                                    searchTerm={searchTerm}
                                    API_CALL_FOR_SEARCH={GET_PROJECT_STAFFING_LIST_SEARCH}
                                    searchPayload={{
                                        sorttype: filters.sorttype,
                                        projectroleid: 0,
                                        searchname: searchTerm?.trim(),
                                        projectid: +staffingProjectId,
                                        pageno: page,
                                        limit,
                                        staffingstatusid: null
                                    }}
                                    setSearchTerm={setSearchTerm}
                                    options={listOfProjectStaffing || []}
                                    idkey='uId'
                                    optionlabelKey='username'
                                    bracketLabel='employeecode'
                                    className='p-1.5'
                                    getSelectedOption={(getSelectedValue)}
                                    placeholder='Enter Employee Name'
                                />
                            </div>
                            <div className="w-48 ">
                                <CustomhtmlSelect
                                    options={findUniqueById(rolesOption, 'croleid') || []}
                                    displayLabel='All Roles'
                                    value={filters?.croleid || null}
                                    noDataLabel='No Data'
                                    name='croleid'
                                    labelkey='rolename'
                                    className='py-2 text-xs border rounded bg-gray-50'
                                    onChange={handleFilter}
                                    id='croleid'
                                />
                            </div>
                            <div className="w-48 ">
                                <CustomhtmlSelect
                                    options={statusoption || []}
                                    displayLabel='All Statuses'
                                    value={filters?.staffingstatusid}
                                    noDataLabel='No Data'
                                    name='staffingstatusid'
                                    labelkey='statusname'
                                    className='py-2 text-xs border rounded bg-gray-50'
                                    onChange={handleFilter}
                                    id='statusid'
                                />
                            </div>
                            <div className=" w-38">
                                <CustomhtmlSelect
                                    options={sortTypeStaffing || []}
                                    value={filters?.sorttype}
                                    noDataLabel='No Data'
                                    name='sorttype'
                                    labelkey='label'
                                    className='py-2 text-xs border rounded bg-gray-50'
                                    onChange={handleFilter}
                                    id='id'
                                />
                            </div>
                            <ReactPaginationStyle
                                total={staffingData?.length > 0 ? staffingData[0]?.totalcount : staffingData?.length}
                                currentPage={page}
                                handlePagination={handlePagination}
                                limit={limit}
                            />
                            <div>
                                <FilterButtonTab
                                    setSelectedtab={handleRows}
                                    selectedTab={limit}
                                    tabs={rows}
                                />
                            </div>
                            <p className="font-[500] px-2 inline-flex justify-start ">
                                Project FTE: {staffingFte?.fte}
                            </p>
                        </div>

                        {editAddButtonPermission() && <>
                            {!canEdit && <IconButton title="Edit Staffing" className='w-8 h-8 text-white bg-primary' onClick={() => setCanEdit(true)} >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='w-4 h-4'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                                    />
                                </svg>
                            </IconButton>}
                        </>
                        }
                    </div>
                    <div className="w-full">
                        {getListOfProjectRole ? <StaffingTable
                            hooksValue={{
                                thisPagePermissions,
                                validation,
                                setstaffingData,
                                staffingData, project,
                                getListOfProjectRole,
                                canEdit, statusListByType,
                                saveStaffing, loading,
                                setWarning,
                                demandid, setDemandid,
                                limit, page
                            }} /> : <div className='w-full flex justify-center  items-center h-[56vh]'>
                            <div
                                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status"
                            >
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Loading...
                                </span>
                            </div>
                        </div>
                        }

                    </div>
                    {canEdit && <div className="absolute flex items-center justify-between w-full bottom-2 right-2">
                        {warning ? <span className='pl-5 text-xs text-red-400'>Staffing end date has expired. Please modify the role duration in planning before staffing a resource.</span> : <div className="h-12" />}
                        <div className='flex items-center justify-end space-x-2 '>
                            <button onClick={handleCancel} className='text-primary border border-primary w-[80px] rounded-md py-1.5'>
                                Cancel
                            </button>
                            <PrimaryButton id='saveStaffing' disabled={loading} onClick={saveStaffing} className='text-white py-2 mx-2 rounded-md bg-primary'>
                                {loading ? 'Processing...' : 'Save'}
                            </PrimaryButton>
                        </div>
                    </div>}
                </div>
            </>}
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default Staffing