import ProcessProfibilty from './processProfitablity'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExpenseDetail from './expenseDetails'
import RevenueDetail from './revenueDetail'
import { GET_PROJECT_PROFITABLE } from 'src/Redux/actions/projects/financials'
import { getLastMonthAndYear } from 'src/Services'
import SelectMonth from 'src/Components/SelectMonth'

const Financials = ({ projectDetail, projectBudgetEstimation }) => {
    const dispatch = useDispatch()
    const getProjectProfitable = useSelector(state => state?.ProjectFinancial?.getProjectProfitable)
    const [month, setMonth] = useState(getLastMonthAndYear()?.lastMonth)
    const [year, setYear] = useState(getLastMonthAndYear()?.lastYear)

    const getProjectProfitableData = () => {
        // const { lastMonth, lastYear } = getLastMonthAndYear();
        let payload = {
            Tenantid: Number(localStorage.getItem('Tenantid') || 0),
            Projectid: projectDetail?.projectid, //313 example project,
            Searchname: "",
            Sorttype: 1,
            Yearid: year,
            Monthid: month,
            Method: 'S',//method?.value,
            Detail: 1,
            Limit: 1,
            Page: 1,
        }
        dispatch(GET_PROJECT_PROFITABLE(payload))
    }

    useEffect(() => {
        getProjectProfitableData()
        // eslint-disable-next-line
    }, [month, year])

    const handleMonthChange = (e) => {
        let { value } = e.target
        const [year, month] = value.split('-')
        const parsedMonth = parseInt(month, 10)
        const parsedYear = parseInt(year, 10)
        const validMonthid = isNaN(parsedMonth) ? '' : parsedMonth
        const validYearid = isNaN(parsedYear) ? '' : parsedYear
        setYear(validYearid)
        setMonth(validMonthid)
    }

    return (
        <div className='w-full'>
            <div className='flex justify-between items-center my-2'>
                <div className='text-sm flex items-center justify-start space-x-2'>
                    <p>Method:</p>
                    <span className='font-normal text-gray-500'>
                        {getProjectProfitable?.notes || 'N/A'}
                    </span>
                </div>
                <div className=' w-52  flex justify-start items-center space-x-2' >
                    <SelectMonth
                        className='text-sm flex items-center justify-start w-full p-1.5 space-x-1 border rounded-md'
                        onChange={handleMonthChange}
                        name='Monthid'
                        year={Number(year)}
                        month={Number(month)}
                        min={projectDetail?.startdate?.substring(0, 7)} // 2024-02 
                        max={projectDetail?.enddate?.substring(0, 7)} // 2024-04 
                    />
                    <ProcessProfibilty 
                    projectDetail={projectDetail}
                    month={month}
                    year={year}
                    getProjectProfitableData={getProjectProfitableData} />
                </div>
            </div>
            <div className='grid grid-cols-5 gap-5 my-6'>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-primary font-[500]'>
                        {projectDetail?.totalestimateamt ? projectDetail?.currency : ''}  {projectDetail?.totalestimateamt || 'N/A'}
                    </h1>
                    <p className='text-sm text-gray-500'>Planned Revenue</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-primary font-[500]'>
                        {projectDetail?.currency}  {projectBudgetEstimation?.planneddirectcost || 'N/A'}
                    </h1>
                    <p className='text-sm text-gray-500'>Direct Cost (Plan)</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-primary font-[500]'>
                        {projectDetail?.currency} {projectBudgetEstimation?.plannedindirectcost || 'N/A'}
                    </h1>
                    <p className='text-sm text-gray-500'>Indirect Cost (Plan)</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-primary font-[500]'>
                        {projectBudgetEstimation?.grossmargin1 || 'N/A'} %
                    </h1>
                    <p className='text-sm text-gray-500'>GM 1 (Plan)</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-primary font-[500]'>
                        {projectBudgetEstimation?.grossmargin2 || 'N/A'} %
                    </h1>
                    <p className='text-sm text-gray-500'>GM 2 (Plan)</p>
                </div>

                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-green-600 font-[500]'>
                        {getProjectProfitable?.currencycode} {getProjectProfitable?.totalrecognizedrevenue || 'N/A'}
                    </h1>
                    <p className='text-sm text-gray-500'>Recognized Revenue</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-green-600 font-[500]'>
                        {getProjectProfitable?.currencycode} {getProjectProfitable?.totaldirectcost || 'N/A'}
                    </h1>
                    <p className='text-sm text-gray-500'>Direct Cost (Actual)</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-green-600 font-[500]'>
                        {getProjectProfitable?.currencycode} {getProjectProfitable?.totalindirectcost || 'N/A'}
                    </h1>
                    <p className='text-sm text-gray-500'>Indirect Cost (Actual)</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-green-600 font-[500]'>
                        {getProjectProfitable?.margin1 || 'N/A'} %
                    </h1>
                    <p className='text-sm text-gray-500'>GM 1 (Actual)</p>
                </div>
                <div className='p-2 text-center bg-white rounded-md shadow'>
                    <h1 className='text-green-600 font-[500]'>
                        {getProjectProfitable?.margin2 || 'N/A'} %
                    </h1>
                    <p className='text-sm text-gray-500'>GM 2 (Actual)</p>
                </div>
            </div>
            <div className='w-full grid grid-cols-2 gap-2'>
                <RevenueDetail currency={getProjectProfitable?.currencycode} data={getProjectProfitable?.profitabilitydetails?.revenuedetails} />
                <ExpenseDetail currency={getProjectProfitable?.currencycode} data={getProjectProfitable?.profitabilitydetails?.expensedetail} />
            </div>
        </div>
    )
}

export default Financials
