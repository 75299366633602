export const NotificationItem = () => {
  return {
    notificationid: null,
    event: "",
    module: "",
    message: "",
    parameter: "",
    mode: "",
    subject: "",
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    companyid: 0,
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    modifiedcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
    copyto: "",
    sendto: "",
  }
};

