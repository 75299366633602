import { memo, useEffect } from "react";
import SingleSelectField from "src/Components/SingleSelectForTable";

const CustomerRole = ({ canEdit, value, rowId, handleChange, options, roles, setRoles }) => {

    const getSelected = (v) => {
        // let e = { target: { name: 'crateid', value: v['croleid'] } } 
        handleChange(v, rowId)
    }

    useEffect(()=>{
        let customer = options?.find(it => it?.custrateid === value)
        let afterChange = roles?.map((item) => {
            if (item?.uId === rowId) {
                item['rolename'] = customer?.rolename
                item['levelid'] = customer?.levelid
                item['skillareamasterid'] = customer?.skillareamasterid
                item['customerid'] = customer?.customerid
                item['techgroupid'] = customer?.techgroupid
                item['datcomcurrencyid'] = customer?.comcurrencyid
                item['currency'] = customer?.currency
                item['isonsite'] = customer?.isonsite ? true : false
                item['cityid'] = customer?.cityid
                item['billratehr'] = item?.billratehr || customer?.bill_hr
                item['levelname'] = customer?.levelname
                item['croleid'] = customer?.croleid
            }
            return item
        })
        setRoles(afterChange)
    // eslint-disable-next-line
    },[value])

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='custrateid'
                labelKey='rolename'
                options={options}
                value={value}
                handleSelected={getSelected}
                disabled={!canEdit}
        />
        </div>
    )
}

export default memo(CustomerRole);


