

import { memo, useMemo, useState, useEffect } from 'react';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { detailNotRequired } from './defaultRowAndData';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { useSelector } from 'react-redux';
import Selectmanager from './selectmanager'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { AlertError } from 'src/Services';
import { useDispatch } from 'react-redux';
import { GET_SKILLAREA_MASTER } from 'src/Redux/actions/workforce/eprofile';
import { getMasterSkillAreaData } from 'src/Services/constant/masterdatapayload';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';
import moment from 'moment';


const currentDate = new Date();
currentDate.setFullYear(currentDate.getFullYear() - 18); // Subtract 18 years
const minDate = currentDate.toISOString().split('T')[0];

const Profile = (props) => {
  const { profileSave, profileData, setProfileData, rm, setRm, UserId, detailsData } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [filteredLocationList, setFilteredLocationList] = useState([]);
  const [selectedServiceLineId, setSelectedServiceLineId] = useState(null);
  const [filteredSkillAreaList, setFilteredSkillAreaList] = useState([]);
  const [dm, setDm] = useState({})
  const [searchTerm, setSearchTerm] = useState('')

  const { genderList, locationList, timeType, cityList, nationality } = useSelector(state => state.UserManagementList);
  const { workertypelist, workforcelist, designationlist, departmentlist, bandList, } = useSelector(state => state.EmployeeDetails);
  const { serviceList } = useSelector((state) => state.UserManagementList);

  const dispatch = useDispatch();

  useMemo(() => {
    if (dm?.cityname) {
      setSearchTerm(dm?.cityname)
      setSelectedCityId(dm?.comcityid);
    }
  }, [dm])

  useEffect(() => {
    setRm(null); // Reset the selected manager to null when the component mounts
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      manager_id: null, // Reset the manager_id field to null when the component mounts
    }));
  }, [setProfileData, setRm]);

  useEffect(() => {
    if (selectedServiceLineId) {
      let payload = {
        ...getMasterSkillAreaData(),
        servicelineid: selectedServiceLineId,
      };
      dispatch(GET_SKILLAREA_MASTER(payload))
        .then(res => {
          const filteredData = res?.data
          setFilteredSkillAreaList(filteredData);
        });
    }
  }, [selectedServiceLineId, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    if (rm?.comuserid === UserId) {
      AlertError('Reporting Manager cannot be the same as the Employee.');
      return;
    }

    let item = {
      ...profileData,
      comuserid: UserId,
      manager_id: rm?.comuserid,
      city_id: selectedCityId,
      exitdate: null,
      location_id: profileData?.location_id,
    };

    let req = [...detailNotRequired, 'servicelineid', 'skillareamasterid']
    let isValidated = CheckObjectValidation(item, req);
    if (isValidated?.isvalid) {
      let payload = {
        userjson: [
          {
            ...item,
            totalexperienceinmonths:
              parseInt(item.years || 0) * 12 + parseInt(item.months || 0),
          },
        ],
      };
      delete payload.userjson[0].years;
      delete payload.userjson[0].months;
      profileSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    if (name === 'years' || name === 'months') {
      const years = parseInt(name === 'years' ? value : profileData?.years) || 0;
      const months = parseInt(name === 'months' ? value : profileData?.months) || 0;
      const totalMonths = years * 12 + months;
      setProfileData({
        ...profileData,
        years: years.toString(),
        months: months.toString(),
        totalexperienceinmonths: totalMonths,
      });
    }
    else if (name === 'city_id') {
      const selectedCityId = value?.comcityid;
      setSelectedCityId(selectedCityId);
      const filteredLocations = locationList.filter(
        (location) => location.comcityid === selectedCityId
      );
      setFilteredLocationList(filteredLocations);
      if (filteredLocations.length > 0) {
        setProfileData((prevProfileData) => ({
          ...prevProfileData,
          [name]: selectedCityId, // Update the city_id in profileData
          location_id: filteredLocations[0]?.location_id || null, // Set location_id to the first item in the filtered list or null if list is empty
        }));
      } else {
        setProfileData((prevProfileData) => ({
          ...prevProfileData,
          [name]: selectedCityId, // Update the city_id in profileData
          location_id: null, // Set location_id to null when there are no filtered locations
        }));
      }
    } else if (name === 'emp_status') {
      if (value === 'Active' || value === 'Long Leave/Sabbatical') {
        setProfileData((prevProfileData) => ({
          ...prevProfileData,
          [name]: value,
        }));
      }
      else {
        setProfileData((prevProfileData) => ({
          ...prevProfileData,
          [name]: value,
        }));
      }
    } else if (name === 'servicelineid') {
      const selectedServiceLineId = value;
      setSelectedServiceLineId(selectedServiceLineId);
      setFilteredSkillAreaList([]); // Reset skill area list
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        [name]: value,
      }));
    } else {
      setProfileData((prevProfileData) => ({
        ...prevProfileData,
        [name]: value,
      }));
    }
  };

  const handleRm = (select) => {
    setRm(select);
    setProfileData({ ...profileData, manager_id: select.comuserid });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10 overflow-y-auto'>
      <div className='grid grid-cols-4 gap-5 mt-4'>
        <div>
          <label htmlFor='gender' className='block font-[500] pb-2'>
            Gender
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={genderList || []}
            value={profileData?.datcomgenderid || ''}
            noDataLabel='No Gender'
            name='datcomgenderid'
            labelkey='gender'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='datcomgenderid'
          />
          {!profileData?.datcomgenderid && checkvalidation && (
            <small className='text-red-400'>Gender is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='workforcegroup' className='block font-[500] pb-2'>
            Work Force Group
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={workforcelist || []}
            value={profileData?.workforcegroupid || ''}
            noDataLabel='No Workforce found'
            name='workforcegroupid'
            labelkey='workforcegroup'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='wfgid'
          />
          {!profileData?.workforcegroupid && checkvalidation && (
            <small className='text-red-400'>
              Work Force Group is required!
            </small>
          )}
        </div>
        <div>
          <label htmlFor='dob' className='block font-[500] pb-2'>
            Date of Birth
            <span className='text-red-300'> *</span>
          </label>
          <FlatPickrDateInput
            value={profileData?.dob || ''}
            max={minDate}
            name='dob'
            id='dob'
            className='outline-none border bg-gray-50 border-gray-300 text-textSecondary sm:text-sm rounded-md focus:ring-primary focus:border-primary block w-full p-[7px]'
            onChangeDate={e => handleChangeInput('dob', e.target.value)}
          />
          {!profileData?.dob && checkvalidation && (
            <small className='text-red-400'>
              Date Of Birth is required!
            </small>
          )}
        </div>
        <div>
          <label htmlFor='dateofjoining' className='block font-[500] pb-2'>
            Date of Joining
            <span className='text-red-300'> *</span>
          </label>
          <FlatPickrDateInput
            // disabled={!profileData?.dob}
            min={profileData?.incorporationdate || ''}
            max={moment(new Date()).format('YYYY/MM/DD')}
            value={profileData?.dateofjoining || ''}
            name='dateofjoining'
            id='dateofjoining'
            className='outline-none border bg-gray-50 border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]'
            onChangeDate={e => handleChangeInput('dateofjoining', e.target.value)}
          />
          {!profileData?.dateofjoining && checkvalidation && (
            <small className='text-red-400'>
              Date Of Joining is required!
            </small>
          )}
        </div>
        <div>
          <label htmlFor='designation' className='block font-[500] pb-2'>
            Designation <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={designationlist || []}
            value={profileData?.designationmasterid || ''}
            noDataLabel='No Designation'
            name='designationmasterid'
            labelkey='designation'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='designationmasterid'
          />
          {!profileData?.designationmasterid && checkvalidation && (
            <small className='text-red-400'>Designation is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='department' className='block font-[500] pb-2'>
            Department <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={departmentlist || []}
            value={profileData?.departmentid || ''}
            noDataLabel='No Department'
            name='departmentid'
            labelkey='department'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='departmentid'
          />
          {!profileData?.departmentid && checkvalidation && (
            <small className='text-red-400'>Department is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='serviceline' className='block font-[500] mb-2'>
            Service Line  <span className='text-red-300'> *</span>
          </label>{' '}
          <CustomhtmlSelect
            options={serviceList || []}
            value={profileData?.servicelineid}
            noDataLabel='No Serviceline'
            name='servicelineid'
            labelkey='servicelinename'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='servicelineid'
          />
          {(!profileData?.servicelineid && checkvalidation) && <small className='text-red-400'>Service Line is required!</small>}
        </div>
        <div>
          <label htmlFor='skillareamasterid' className='block font-[500] mb-2'>
            Skill Area <span className='text-red-300'> *</span>
          </label>{' '}
          <CustomhtmlSelect
            options={filteredSkillAreaList || []}
            value={profileData?.skillareamasterid || ''}
            noDataLabel='No Skill Area'
            name='skillareamasterid'
            labelkey='skillarea'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='skillareamasterid'
          />
          {(!profileData?.skillareamasterid && checkvalidation) && <small className='text-red-400'>Skill Area is required!</small>}
        </div>
        <div>
          <label htmlFor='bandname' className='block font-[500] pb-2'>
            Band
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={bandList || []}
            value={profileData?.band || ''}
            noDataLabel='No Band'
            name='band'
            labelkey='bandname'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='bandmasterid'
          />
          {!profileData?.band && checkvalidation && (
            <small className='text-red-400'>Band is required!</small>
          )}
        </div>
        <div>
          <Selectmanager
            rm={rm}
            UserId={UserId}
            setRm={handleRm}
            companyid={detailsData?.payrollcompanyid}
            searchStr={profileData?.managername}
          />

          {!profileData?.manager_id && checkvalidation && (
            <small className='text-red-400'>Reporting Manager is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='nationality' className='block font-[500] pb-2'>
            Nationality
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={nationality || []}
            value={profileData?.nationalityid || ''}
            noDataLabel='No Nationality'
            name='nationalityid'
            labelkey='nationality'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='nationalityid'
          />
          {!profileData?.nationalityid && checkvalidation && (
            <small className='text-red-400'>Nationality is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='city_id' className='block font-[500] mb-2'>
            City
            <span className="text-red-300"> *</span>
          </label>
          <CustomAutoSearch
            searchTerm={searchTerm}
            API_CALL_FOR_SEARCH={GET_CITY_MASTER}
            searchPayload={{ ...getMasterCityPayload(), searchname: searchTerm, }}
            setSearchTerm={setSearchTerm}
            options={cityList}
            idkey='comcityid'
            placeholder='Search for city'
            optionlabelKey='cityname'
            getSelectedOption={(option) => {
              setDm(option);
              handleChangeInput('city_id', option); // Invoke handleChangeInput with the 'city_id' and selected option
            }} className='bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
          />
          {!profileData?.city_id && checkvalidation && (
            <small className='text-red-400'>City is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='locationname' className='block font-[500] pb-2'>
            Location
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={filteredLocationList || []}
            value={profileData?.location_id || ''}
            noDataLabel='No Location'
            name='location_id'
            labelkey='locationname'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='comlocationid'
          />
          {!profileData?.location_id && checkvalidation && (
            <small className='text-red-400'>Location is required!</small>
          )}
        </div>
        {/* <div>
          <h1 className='block font-[500] pb-2'>
            Status<span className='text-red-300'> *</span>
          </h1>
          <CustomhtmlSelect
            options={statusListByType || []}
            value={profileData?.emp_status}
            noDataLabel='No Status'
            name='emp_status'
            labelkey='statusname'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='statusid'
          />
          {!profileData?.emp_status && checkvalidation && (
            <small className='text-red-400'>
              Employee Status is required!
            </small>
          )}
        </div> */}
        <div>
          <label htmlFor='workertype' className='block font-[500] pb-2'>
            Worker Type
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={workertypelist || []}
            value={profileData?.worker_type || ''}
            noDataLabel='No Worker Type'
            name='worker_type'
            labelkey='workertype'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='workertypeid'
          />
          {!profileData?.worker_type && checkvalidation && (
            <small className='text-red-400'>Worker Type is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='timetype' className='block font-[500] pb-2'>
            Time Type
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={timeType || []}
            value={profileData?.time_type || ''}
            noDataLabel='No Time Type'
            name='time_type'
            labelkey='timetype'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='timetypeid'
          />
          {!profileData?.time_type && checkvalidation && (
            <small className='text-red-400'>Time Type is required!</small>
          )}
        </div>
        <div>
          <label
            htmlFor='totalexperienceinmonths'
            className='block font-[500] pb-2'
          >
            Previous Experience (Months)
          </label>
          <div className='flex items-center gap-4'>
            <input
              value={profileData?.years || ''}
              onChange={(e) => handleChangeInput('years', e.target.value)}
              name='years'
              type='number'
              id='years'
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-2'
              placeholder='Years'
            />
            <input
              onChange={(e) => handleChangeInput('months', e.target.value)}
              value={profileData?.months || ''}
              name='months'
              type='number'
              id='months'
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-2'
              placeholder='Months'
            />
            <input
              value={profileData?.totalexperienceinmonths || ''}
              name='totalexperienceinmonths'
              type='number'
              id='totalexperienceinmonths'
              className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-2'
              placeholder='Total Months'
            />
          </div>
        </div>
       
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            // type=''
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Profile);