export const BusinessItem = () => {
  return {
    businessunitid: null,
    businessunitname: '',
    businessunitgroupid: 0,
    businessunitheadid: null,
    businessunitcontactpersonid: null,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: '',
  };
};

export const BusinessGroupItem = () => {
  return {
    businessunitgroupid: null,
    businessunitgroupname: '',
    groupheadid: null,
    groupcontactpersonid: null,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: null,
  };
};

export const detailNotRequired = [
  'businessunitgroupid',
  'businessunitid',
  'businessunitcontactpersonid',
  'notes',
  'groupheadid',
  'groupcontactpersonid'
];

