import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import FilterButtonTabObject from 'src/Components/FilterButtonTabObject'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { useEffect, useState } from 'react'
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent'
import { getDefaultCityPayload } from 'src/Services/constant/defaultPayload'
import { GET_STATE_MASTER } from 'src/Redux/actions/masterdata/user'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'

const cycleList = [{ id: 1, cycle: 'Monthly' }, { id: 2, cycle: 'Weekly' }, { id: 3, cycle: 'Daily' }, { id: 4, cycle: 'Hourly' },]
const requestTypeslist = [{ requesttype: 'Opportunity', id: 'O' }, { requesttype: 'Project', id: 'P' }, { requesttype: 'Internal', id: 'I' },]
const prioritiesList = [{ priority: 'High', id: 'H' }, { priority: 'Low', id: 'L' }, { priority: 'Medium', id: 'M' },]

const Details = ({ hooks }) => {
	const dispatch = useDispatch()
	const { detailSave, project, department, payload, setPayload, demandStaff } = hooks
	const { currencyList } = useSelector(state => state?.Projects)
	const { businessList, serviceList, stateList, cityList } = useSelector(state => state?.UserManagementList)
	const { workertypelist } = useSelector(state => state?.EmployeeDetails)
	const { workmodelist } = useSelector(state => state?.DemandDetailsList)
	const [checkValidation, setCheckValidation] = useState(false)
	const [selectState, setSelectState] = useState(null)
	const currentDate = new Date()
	const futureDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000) // Adding 14 days in milliseconds to the current date
	const next14DaysDisable = futureDate.toISOString().split('T')[0]

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCheckValidation(true);
		let validationdata = {}
		let requiredInDetail = ['cityid', 'workertypeid', 'companyid', 'requesttype', 'businessunitid', 'servicelineid', 'startdate', 'datcomcurrencyid', 'allocation', 'workmodeid', 'priority', 'demandstatusid', 'is_billable', 'comtenantid', 'isactiveflag', 'createdcomuserid', 'modifiedcomuserid', 'peopleno', 'isonsite']

		if (payload?.requesttype === 'P') {
			requiredInDetail.push('projectid');
		} else if (payload?.requesttype === 'I') {
			requiredInDetail.push('departmentid');
		} else if (payload?.requesttype === 'O') {
			requiredInDetail.push('opportunityid');
		}

		for (let value of requiredInDetail) {
			validationdata[value] = payload[value]
		}


		let validation = CheckObjectValidation(validationdata, ['demandid', 'demandcode', 'departmentid',
			'opportunityid', "projectid", 'billrate',
			'datcomcurrencyid', 'billingunit', 'locationid',
			'assignedto', 'assigneddate', 'demandstatusid',
			'cityid', 'notes', 'countryname'], false)

		if (!validation?.isvalid) {
			return
		}
		else {
			let insertKeysare = ['demandid', 'demandcode', 'workertypeid',
				'companyid','comstateid',
				'requesttype', 'businessunitid', 'servicelineid', 'startdate',
				'opportunityid', 'projectid', 'departmentid', 'billrate',
				'datcomcurrencyid', 'billingunit', 'allocation', 'workmodeid',
				'priority', 'locationid', 'assignedto', 'assigneddate',
				'demandstatusid', 'cityid', 'is_billable', 'comtenantid',
				'isactiveflag', 'createdcomuserid', 'modifiedcomuserid',
				'notes', 'peopleno', 'isonsite']
			if(!selectState || !payload?.cityid){
				return
			}
			let apiPayload = {
				...payload,
				comstateid: selectState,
				projectid: project?.projectid,
				departmentid: department?.departmentid,
				allocation: (Number(payload?.allocation || 0) / 100).toFixed(2),
				peopleno: 1,
				billrate: Number(payload?.billrate),
				demandid: demandStaff?.demandid || null
			}
			if (!payload?.is_billable) {
				apiPayload['billrate'] = null
				apiPayload['billingunit'] = null
				apiPayload['datcomcurrencyid'] = null
			}
			setPayload(apiPayload)
			let finalPayload = {
				demandmasterjson: [apiPayload]
			}
			setCheckValidation(false);
			detailSave(finalPayload, insertKeysare)
			setPayload({ ...apiPayload, allocation: Number(apiPayload?.allocation) * 100 })
		}
	}

	const handleSelectTab = (name, value) => {
		setPayload({ ...payload, [name]: value })
	}

	const handleChangeInput = (name, value) => {
		setPayload({ ...payload, [name]: value })
	}

	const setBillingType = option => {
		setPayload({ ...payload, is_billable: option === 'Billable' ? true : false })
	}

	useEffect(() => {
		if (demandStaff?.countryid) {
			let payload = {
				Tenantid: Number(localStorage.getItem('Tenantid')),
				comcountryid: demandStaff?.countryid,
				comstateid: 0,
				sorttype: 3,
				searchname: '',
				status: 'ACTIVE'
			}
			dispatch(GET_STATE_MASTER(payload))

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [demandStaff?.countryid])

	useEffect(() => {
		if (payload?.comstateid) {
			setSelectState(payload?.comstateid)
			let data = {
				...getDefaultCityPayload(),
				comstateid: payload?.comstateid
			}
			dispatch(GET_CITY_MASTER(data))
		}
		// eslint-disable-next-line
	}, [payload?.comstateid])

	const handleState = (name, value) => {
		let payload = {
			...getDefaultCityPayload(),
			[name]: value
		}
		setSelectState(value)
		dispatch(GET_CITY_MASTER(payload))
	}

	return (
		<div className='w-full mt-4 text-sm'>
			<div className=' h-[70vh] w-full overflow-scroll  text-sm'>
				<div className='grid grid-cols-1 gap-6 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1'>
					<div>
						<label htmlFor='demandcode' className='block font-[500] mb-2'>  Demand Code </label>
						<input
							disabled type='text' title='demandcode' id='demandcode' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]' placeholder='Demand Code' required=''
							value={payload?.demandcode || ''}
						/>
					</div>
					<div>
						<label htmlFor='Companyname' className='block font-[500] mb-2'> Company </label>
						<input
							disabled={true} required='' placeholder='Company'
							value={payload?.companyname || ''}
							type='text' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
						/>
						{!payload?.companyid && checkValidation && (
							<small className='text-red-400'>Company is required!</small>
						)}
					</div>
					<div>
						<label htmlFor='requestType' className='block font-[500] mb-2'>  Request Type </label>
						<FilterButtonTabObject
							disabled={true}
							className='p-2 border'
							tabs={requestTypeslist || []}
							selected={payload?.requesttype}
							labelkey='requesttype'
							name='requesttype'
							valuekey='id'
							onClick={handleSelectTab}
						/>
						{(!payload?.requesttype && checkValidation) && <small className='text-red-400'>request type is required!</small>}
					</div>
					{payload?.requesttype === 'P' && (
						<div>
							<label htmlFor='Project' className='block font-[500] mb-2'> Project </label>
							<input
								disabled={true} required='' placeholder='Project'
								value={payload?.projectname || ''}
								type='text' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
							/>
							{(!payload?.projectid && checkValidation) && <small className='text-red-400'>projectid is required!</small>}
						</div>
					)}
					<div>
						<label htmlFor='workertype' className='block font-[500] mb-2'> Employee Type  <span className="text-red-300"> *</span> </label>
						<CustomhtmlSelect
							options={workertypelist || []}
							value={payload?.workertypeid || ''}
							noDataLabel='No workertype'
							name='workertypeid'
							labelkey='workertype'
							className='border rounded-lg bg-gray-50 p-2'
							onChange={handleChangeInput}
							id='workertypeid'
						/>
						{!payload?.workertypeid && checkValidation && (
							<small className='text-red-400'>Employee is required!</small>
						)}
					</div>
					<div>
						<label htmlFor='noofpeople' className='block font-[500] mb-2'> Number Of People </label>
						<div className='relative'>
							<input
								disabled
								type='number' name='peopleno' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]' placeholder='peopleno' required=''
								value={payload?.peopleno || ''}
								onChange={e => handleChangeInput('peopleno', e.target.value)}
							/>
							{(!payload?.peopleno && checkValidation) && <small className='text-red-400'>noofpeople is required!</small>}
						</div>
					</div>
					<div>
						<label htmlFor='BusinessUnit' className='block font-[500] mb-2'>
							Business Unit
						</label>
						<CustomhtmlSelect
							disabled={true}
							options={businessList || []}
							value={payload?.businessunitid || ''}
							noDataLabel='No business unit'
							name='businessunitid'
							labelkey='businessunitname'
							className='border rounded-lg bg-gray-50 p-2'
							onChange={handleChangeInput}
							id='businessunitid'
						/>
						{(!payload?.businessunitid && checkValidation) && <small className='text-red-400'>businessunitid is required!</small>}
					</div>
					<div>
						<label htmlFor='ServiceLine' className='block font-[500] mb-2'>
							Service Line
						</label>
						<CustomhtmlSelect
							disabled={demandStaff?.demandid !== null}
							options={serviceList || []}
							value={payload?.servicelineid || ''}
							noDataLabel='No service'
							name='servicelineid'
							labelkey='servicelinename'
							className='border rounded bg-gray-50 py-2'

							onChange={handleChangeInput}
							id='servicelineid'
						/>
						{(!payload?.servicelineid && checkValidation) && <small className='text-red-400'>servicelineid is required!</small>}
					</div>
					<div>
						<label htmlFor='demandstatus' className='block font-[500] mb-2'> Demand Status </label>
						<input
							disabled={true} required='' placeholder='demandstatus'
							value={payload?.demandstatusname || 'Open'}
							type='text' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
						/>
					</div>
					<div>
						<label htmlFor='startdate' className='block font-[500] mb-2'>  Staffing Start Date <span className="text-red-300"> *</span> </label>
						<FlatPickrDateInput
							disabled={payload?.startdate === ''}
							min={next14DaysDisable}
							max={demandStaff?.plannedenddate}
							name='startdate'
							value={payload?.startdate || ''}
							id='startdate'
							className='block w-full p-2 uppercase border border-gray-300 rounded bg-gray-50 focus:ring-0 focus:border-0'
							onChangeDate={e => handleChangeInput('startdate', e.target.value)}
						/>
						{(!payload?.startdate && checkValidation) && <small className='text-red-400'>startdate is required!</small>}
					</div>
					<div>
						<label htmlFor='billingType' className='block font-[500] mb-2'>  Billing Type </label>
						<FilterButtonTab
							disabled={true}
							className='p-2 border w-full'
							tabs={['Billable', 'Non-billable']}
							setSelectedtab={setBillingType}
							selectedTab={payload?.is_billable ? 'Billable' : 'Non-billable' || null}
						/>
					</div>
					{payload?.is_billable && <>
						<div>
							<label htmlFor='billrate' className='block font-[500] mb-2'> Billing Rate </label>
							<div className='relative '>
								<input
									disabled required='' placeholder='billrate' type='number' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
									value={payload?.billrate || ''}
									onChange={e => handleChangeInput('billrate', e.target.value)}
								/>
							</div>
						</div>
						<div>
							<label htmlFor='billingunit' className='block capitalize mb-2  font-[500]' >  Billing Unit </label>
							<CustomhtmlSelect
								disabled={true}
								options={cycleList || []}
								noDataLabel='No cycle'
								value={payload?.billingunit || ''}
								name='billingunit'
								labelkey='cycle'
								className='py-2 border rounded-lg bg-gray-50'
								onChange={handleChangeInput}
								id='id'
							/>
						</div>
						<div>
							<label htmlFor='billingCurrency' className='block capitalize mb-2  font-[500]' >  Billing Currency </label>
							<CustomhtmlSelect
								disabled={true}
								options={currencyList || []}
								noDataLabel='No Currency Type'
								value={payload?.datcomcurrencyid || null}
								name='datcomcurrencyid'
								labelkey='currency'
								className='py-2 border rounded-lg bg-gray-50'
								onChange={handleChangeInput}
								id='datcomcurrencyid'
							/>
						</div>
					</>}
					<div>
						<label htmlFor='allocation' className='block font-[500] mb-2'>  Allocation (%) </label>
						<div className='relative'>
							<input
								type='number'
								name='allocation'
								disabled
								className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
								placeholder='Allocation'
								required=''
								value={payload?.allocation || 0}
								onChange={(e) => {
									if (/^[0-9]*$/.test(e.target?.value) && (Number(e.target.value) >= 0 && Number(e.target.value) <= 100)) {
										handleChangeInput('allocation', e.target.value)
									}
								}}
							/>
							{(!payload?.allocation && checkValidation) && <small className='text-red-400'>allocation is required!</small>}
						</div>
					</div>
					<div>
						<label htmlFor='Priority' className='block font-[500] mb-2'> Priority  <span className="text-red-300"> *</span> </label>
						<CustomhtmlSelect
							options={prioritiesList || []}
							value={payload?.priority || ''}
							noDataLabel='No priority'
							name='priority'
							labelkey='priority'
							className='border rounded-lg bg-gray-50 p-2'
							onChange={handleChangeInput}
							id='id'
						/>
						{(!payload?.priority && checkValidation) && <small className='text-red-400'>Priority is required!</small>}
					</div>
					<div>
						<label htmlFor='workmode' className='block font-[500] mb-2'>  Work Mode  <span className="text-red-300"> *</span> </label>
						<CustomhtmlSelect
							options={workmodelist || []}
							value={payload?.workmodeid || ''}
							noDataLabel='No workmode'
							name='workmodeid'
							labelkey='workmode'
							className='border rounded-lg bg-gray-50 p-2'
							onChange={handleChangeInput}
							id='workmodeid'
						/>
						{(!payload?.workmodeid && checkValidation) && <small className='text-red-400'>Workmode is required!</small>}
					</div>
					<div>
						<label htmlFor='countryname' className='block font-[500] mb-2'> Country</label>
						<div className='relative '>
							<input
								required='' placeholder='Country name' type='text' className='outline-gray-100 bg-gray-50 text-gray-600 border border-gray-300 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-[8px]'
								value={payload?.countryname}
								disabled
							/>
						</div>
					</div>
					<div>
						<label htmlFor='location' className='block font-[500] mb-2'> State   <span className="text-red-300"> *</span></label>
						<CustomhtmlSelect
							options={stateList || []}
							value={selectState || ''}
							noDataLabel='No State '
							name='comstateid'
							labelkey='statename'
							className='border rounded-lg bg-gray-50 p-2'
							onChange={handleState}
							id='comstateid'
						/>
						{(!selectState && checkValidation) && <small className='text-red-400'>State is required!</small>}
					</div>
					<div>
						<label htmlFor='location' className='block font-[500] mb-2'> Location   <span className="text-red-300"> *</span></label>
						<CustomhtmlSelect
							options={cityList || []}
							value={payload?.cityid || ''}
							noDataLabel='No City '
							name='cityid'
							labelkey='cityname'
							className='border rounded-lg bg-gray-50 p-2'
							onChange={handleChangeInput}
							id='comcityid'
						/>
						{(!payload?.cityid && checkValidation) && <small className='text-red-400'>City is required!</small>}
					</div>
				</div>
				{payload?.startdate === '' ? <div className='absolute flex justify-center w-full p-2 my-3 text-red-500 rounded-lg bottom-2'>
					<p>
						Demand can't be created as staffing start date exceeds the role end date.
					</p>
				</div> : <div className=' float-right '>
					<PrimaryButton className='py-2  rounded-lg text-white' onClick={handleSubmit} >
						<span>Save</span>
					</PrimaryButton>
				</div>}
			</div>
		</div>
	)
}

export default Details
