import { memo } from "react";
import SingleSelectField from "src/Components/SingleSelectForTable";

const SelectStatus = ({ hooksValue, value, canEdit }) => {
    const { statusListByType } = hooksValue

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='statusid'
                labelKey='statusname'
                options={statusListByType}
                value={value}
                handleSelected={(e)=>{}}
                disabled={!canEdit}
        />
        </div>
    )
}

export default memo(SelectStatus);
