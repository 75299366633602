export const detailItem = () => {
  return {
    userid: 0,
    username: '',
    emailid: '',
    personalemailid: '',
    mobileno: '',
    phoneno: '',
    datusertypeid: 0,
    firstname: '',
    lastname: '',
    payrollcompanyid: 0,
    employeecode: '',
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: 'Emp Modify',
    activeflag: true,
    Roledetails: [
      {
        Roleid: 0,
      },
      {
        Roleid: 0,
      },
    ],
    emp_status: 0
  };
};

export const detailNotRequired = [
  // 'location_id',
  // 'city_id',
  'employeecode',
  'skillareamasterid',
  'personalemailid',
  'phoneno',
  'username',
  'userid',
  'photo',
  // 'dob',
  'anniversarydate',
  'totalexperienceinmonths',
  'bloodgroup',
  'workforcegroupid',
  // 'manager_id',
  // 'emp_status',
  // 'exitdate',
  // 'createdcomuserid',
  // 'ModifiedCOMUserID'
  
];

export const profileItem = () => {
  return {
    comuserid: 0,
    bloodgroup: '',
    dateofjoining: '',
    photo: null,
    datcomgenderid: 0,
    dob: null,
    workforcegroupid: 0,
    anniversarydate: null,
    comtenantid: Number(localStorage.getItem('Tenantid')),
    departmentid: 0,
    servicelineid: 0,
    designationmasterid: 0,
    nationalityid: 0,
    band: 0,
    time_type: 0,
    worker_type: 0,
    emp_status: 0,
    location_id: 0,
    manager_id: 0,
    ModifiedCOMUserID: Number(localStorage.getItem('comuserid')),
    city_id: 0,
    totalexperienceinmonths: 0,
    exitdate: "",
    skillareamasterid: 0

  };
};