export const DeliveryRoleItem = () => {
  return {
    "deliveryroleid": null,
    "rolename": "",
    "comtenantid": Number(localStorage.getItem('Tenantid') || 0),
    "activeflag": true,
    "createdcomuserid": Number(localStorage.getItem('comuserid') || 0),
    "notes": null
  }
}

export const IndustryItem = () => {
  return {
    "industryid": null,
    "industryname": "",
    "comtenantid": Number(localStorage.getItem('Tenantid') || 0),
    "isactiveflag": true,
    "createdcomuserid": Number(localStorage.getItem('comuserid') || 0),
    "notes": null
  }
}

export const CustomerGroupItem = () => {
  return {
    "customergroupid": 0,
    "comtenantid": Number(localStorage.getItem('Tenantid') || 0),
    "customergroupname": "",
    "accountmanagerid": 0,
    "contactpersonid": null,
    "modifiedcomuserid": Number(localStorage.getItem('comuserid') || 0),
    "isactiveflag": true,
    "createdcomuserid": Number(localStorage.getItem('comuserid') || 0),
    "notes": " "
  }
}

export const detailNotRequired = [
  "notes",
  "contactpersonid",
  "customergroupid",
  "accountmanagerid", 'opportunityid', 'opportunitycode',
  "deliveryroleid"
];



export const OpportunityItem = () => {
  return {
    opportunityid: null,
    opportunityname: "",
    opportunitycode: "",
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    totalopportunityvalue: 0,
    opportunitystatus: "",
    winprobabilitypercentage: 0,
    salesmanagerid: 0,
    sourceofopportunity: "",
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: null,
    customerid: 0,
    currencyid: 0
  }
}


export const Item = () => {
  return {
    opportunityid: null,
    opportunityname: "",
    opportunitycode: "",
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    totalopportunityvalue: 0,
    opportunitystatus: "",
    winprobabilitypercentage: 0,
    salesmanagerid: 0,
    sourceofopportunity: "",
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: null
  }
}

export const detailItem = () => {
  return {
    customerholidaysid: null,
    customerid: 0,
    holiday: "",
    reason: "",
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    "modifiedcomuserid": Number(localStorage.getItem('comuserid') || 0),
    notes: ""
  };
};

export const weekdaysItem = () => {
  return {
    uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
    customerweekdaysid: null,
    customerid: 0,
    sunholiday: true,
    monholiday: true,
    tueholiday: true,
    wedholiday: true,
    thuholiday: true,
    friholiday: true,
    satholiday: true,
    comtenantid: Number(localStorage.getItem('Tenantid') || 0),
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: "",
    noofhoursperday: null

  };
};
