import { Dialog, Transition } from '@headlessui/react'
import { Fragment, memo, useEffect, useState } from 'react'
import Comercials from './commercials';
import Contacts from './contacts';
import Details from './details'
import { useDispatch } from 'react-redux';
import { GET_SERVICE_LINE_MASTER_LIST, GET_CURRENCY_MASTER_LIST, GET_PRODUCT_LIST, CREATE_NEW_PROJECT, GET_LIST_OF_STATUS, GET_LIST_TYPE_OF_PROJECT, GET_LIST_COMPANY, GET_PROJECT_LIST_BILLING_TYPE, GET_PROJECTS_BY_ID, SAVE_PROJECT_DOCUMENT, GET_PROJECT_DOCUMENT_LIST, GET_PROJECT_DOCUMENT_TYPE_LIST } from 'src/Redux/actions/projects';
import { getDefaultCurrencyPayload, getDefaultServiceLinePayload, getDefaultProductPayload, getDefaultStatusPayload, getDefaultProjectTypePayload, getDefaultCompanyPayload, getDefaultBillingIdPayload } from 'src/Services/constant/defaultPayload';
import { projectbaseInfo, defaultContactItem, businessunitItem, serviceLineItem } from './defaultPayload'
import { GET_ALL_CONTACTS_OF_A_CUSTOMER } from 'src/Redux/actions/masterdata/customer';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { AlertError, AlertSuccess, findDuplicatesByKeyName } from 'src/Services';
import { GET_LIST_OF_STATUS_BY_E, GET_TECHNOLOGY_GROUP } from 'src/Redux/actions/masterdata/user';
import IconButton from 'src/Components/Buttons/IconButton';
import { getTechGroupMasterData, getTemplateCategoryPayload } from 'src/Services/constant/masterdatapayload';
import Documents from './documents';
import { GET_TEMPLATE_MASTER } from 'src/Redux/actions/configuration';

const tabslist = ["Details", "Commercials", "Contacts", 'Documents']

const UpdateProject = ({ projectDetail, disabled }) => {
    const dispatch = useDispatch()
    const [businessUnit, setBusinessUnit] = useState({})
    const [opportunity, setOpportunity] = useState({})
    const [serviceLine, setServiceLine] = useState({})
    const [customer, setCustomer] = useState({})
    const [errorFound, setErrorFound] = useState({})
    const [payload, setPayload] = useState({})
    let [isOpen, setIsOpen] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [documents, setDocuments] = useState([])
    const [selectedContacts, setSelectedContacts] = useState([defaultContactItem()])
    const [pm, setPm] = useState({})
    const [dm, setDm] = useState({})
    const [pmo, setPmo] = useState({})
    const [activeTab, setActiveTab] = useState('Details')
    const [tabCanAccess, setTabCanAccess] = useState(["Details", "Commercials", "Contacts", 'Documents'])

    useEffect(() => {
        dispatch(GET_PROJECT_DOCUMENT_TYPE_LIST())
        dispatch(GET_SERVICE_LINE_MASTER_LIST(getDefaultServiceLinePayload()))
        dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
        dispatch(GET_TECHNOLOGY_GROUP(getTechGroupMasterData()))
        dispatch(GET_LIST_OF_STATUS_BY_E())
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('P')))
        dispatch(GET_LIST_TYPE_OF_PROJECT(getDefaultProjectTypePayload(0)))
        dispatch(GET_PROJECT_LIST_BILLING_TYPE(getDefaultBillingIdPayload()))
        dispatch(GET_LIST_COMPANY(getDefaultCompanyPayload()))
        dispatch(GET_PRODUCT_LIST(getDefaultProductPayload(0)))
    }, [dispatch])

    useEffect(() => {
        let item = {}
        if (projectDetail) {
            dispatch(GET_PROJECT_DOCUMENT_LIST(projectDetail?.projectid))
            setSelectedContacts(projectDetail?.projectcustomercontacts || [])
            setPm({
                comuserid: projectDetail?.projectmanagerid,
                firstname: projectDetail?.projectmanager
            })
            setDm({
                comuserid: projectDetail?.deliverymanagerid,
                firstname: projectDetail?.deliverymanager
            })
            setPmo({
                comuserid: projectDetail?.pmocontactpersonid,
                firstname: projectDetail?.pmocontactperson?.trim() || ''
            })
            setCustomer({
                accountname: projectDetail?.accountname,
                accountmanagername: projectDetail?.accountmanager,
                customerid: projectDetail?.customerid,
                customername: projectDetail?.customername,
                defaultcomcurrencyid: projectDetail?.defaultcomcurrencyid,
            })
            setBusinessUnit({
                ...businessunitItem,
                businessunitname: projectDetail?.businessunitname,
                businessunitid: projectDetail?.businessunitid,
                businessunitheadid: projectDetail?.businessunitheadid,
                buhead: projectDetail?.businessunithead,
            })
            setServiceLine({
                ...serviceLineItem,
                servicelineid: projectDetail?.servicelineid,
                servicelinename: projectDetail?.servicelinename,
                servicelineheadid: projectDetail?.servicelineheadid,
                servicelinehead: projectDetail?.servicelinehead,
            })
            setOpportunity({
                opportunitystatus: projectDetail?.opportunitystatus,
                opportunitycode: projectDetail?.opportunitycode,
                opportunityid: projectDetail?.opportunityid,
                opportunityname: projectDetail?.opportunityname,
                totalopportunityvalue: projectDetail?.totalopportunityvalue,
                Salesmanagername: projectDetail?.salesmanager,
                winprobabilitypercentage: projectDetail?.winprobabilitypercentage
            })

            let contactsItem = projectDetail?.projectcustomercontacts?.map((contact) => {
                contact['uId'] = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)
                contact['isactiveflag'] = true
                delete contact['projectid']
                contact['createdcomuserid'] = JSON.parse(localStorage.getItem('comuserid'))
                return contact
            })

            if (contactsItem) {
                setSelectedContacts(contactsItem || [defaultContactItem()])
            } else {
                setSelectedContacts(selectedContacts || [defaultContactItem()])
            }

            projectDetail?.projectproductmapping?.map((pro) => {
                pro['productmasterid'] = pro?.productid
                pro['isactiveflag'] = true
                pro['comtenantid'] = JSON.parse(localStorage.getItem('Tenantid'))
                return pro
            })

            item["shiftstarttime"] = projectDetail["shiftstarttime"]
            item["shiftendtime"] = projectDetail["shiftendtime"]
            item["timezone"] = projectDetail["timezone"]
            item["timetemplatemasterid"] = projectDetail["timetemplatemasterid"]

            item['projectsubtype'] = projectDetail?.projectsubtype
            item['techgroupid'] = projectDetail?.techgroupid
            setSelectedProducts(projectDetail?.projectproductmapping || [])
            for (let keyname in projectbaseInfo()) {
                if (keyname !== 'projectcustomercontacts' || keyname !== 'projectproductmapping' || keyname !== 'projectdocuments') {
                    item[keyname] = projectDetail[keyname]

                }
            }
            setPayload(item)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectDetail])

    useEffect(() => {
        if (customer?.customerid) {
            setPayload({ ...payload, defaultcomcurrencyid: customer?.defaultcomcurrencyid })
            dispatch(GET_ALL_CONTACTS_OF_A_CUSTOMER(customer?.customerid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    useEffect(() => {
        if (payload?.companyid) {
            let data = getTemplateCategoryPayload(1, 1000, 3, 'Active');
            data['companyid'] = payload?.companyid
            data['typefilter'] = 'Project'
            dispatch(GET_TEMPLATE_MASTER(data))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload?.companyid])

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const nextToDetail = () => {
        let validationdata = {}
        let requiredInDetail = ['projectname', 'projecttypeid', 'companyid', 'customerid', 'shiftstarttime', 'shiftendtime',
            'servicelineid',  'startdate', 'enddate', 'datprojectbillingtypeid',
        ]

        for (let value of requiredInDetail) {
            validationdata[value] = payload[value]
        }

        validationdata['servicelineid'] = serviceLine?.servicelineid
        validationdata['businessunitid'] = businessUnit?.businessunitid
        validationdata['customerid'] = customer?.customerid

        let validation = CheckObjectValidation(validationdata, [], false)
        if (!validation?.isvalid) {
            setErrorFound(validation)
            setTabCanAccess(['Details'])
            return
        } else {
            setTabCanAccess(['Details', 'Commercials'])
        }

        if (payload?.startdate > payload?.enddate) {
            AlertError('End date must be greater than start date ')
            return
        }

        setActiveTab('Commercials')
    }

    const nextToCommercials = () => {
        let validationdata = {
            opportunityid: opportunity?.opportunityid,
            billingcycle: payload?.billingcycle,
            totalestimateamt: payload?.totalestimateamt
        }

        let validation = CheckObjectValidation(validationdata, ['totalestimateamt', 'opportunityid', 'techgroupid'], false)
        if (!validation?.isvalid) {
            setErrorFound(validation)
            setTabCanAccess(['Details', 'Commercials'])
            return
        } else {
            setTabCanAccess(['Details', 'Commercials', 'Contacts'])
        }
        setActiveTab('Contacts')
    }

    const addProject = async () => {
        let validationdata = {
            projectmanagerid: pm?.comuserid,
            deliverymanagerid: dm?.comuserid,
        }

        let validation = CheckObjectValidation(validationdata, ['opportunityid'], false)
        if (!validation?.isvalid) {
            setErrorFound(validation)
            return
        }

        function checkUniqueUser(value1, value2, value3) {
            if (value1 === value2 || value1 === value3 || value2 === value3) {
                return false
            } else {
                return true
            }
        }
        let result = checkUniqueUser(pm?.comuserid, dm?.comuserid, pmo?.comuserid);

        if (!result) {
            AlertError('PM, DM, PMO should be different !')
            return
        }

        selectedProducts.map((pro) => {
            delete pro['projectid']
            delete pro['productid']
            delete pro['productname']
            return pro
        })

        let contacts = selectedContacts?.filter(item => item?.contactpersonname === '')

        selectedContacts.map((con) => {
            con['comtenantid'] = JSON.parse(localStorage.getItem('Tenantid'))
            return con
        })
        if (!Number(payload?.totalestimateamt)) {
            setErrorFound({ keyname: 'totalestimateamt' })
            AlertError('Planned Revenue is required ')
            return
        }

        let [hoursSS, minutesSS] = String(payload?.shiftstarttime).split(":");
        let [hoursSE, minutesSE] = String(payload?.shiftendtime).split(":");

        let apiPayload = {
            ...payload,
            customerid: customer?.customerid,
            projectdocuments: [],
            opportunityid: opportunity?.opportunityid,
            servicelineid: serviceLine?.servicelineid,
            businessunitid: businessUnit?.businessunitid,
            totalestimateamt: Number(payload?.totalestimateamt),
            comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
            createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
            projectmanagerid: pm?.comuserid,
            deliverymanagerid: dm?.comuserid,
            PMOcontactpersonid: pmo?.comuserid || null,
            projectcustomercontacts: contacts?.length > 0 ? [] : selectedContacts,
            projectproductmapping: selectedProducts,
            shiftstarttime: parseFloat(hoursSS + "." + minutesSS),
            shiftendtime: parseFloat(hoursSE + "." + minutesSE),
        }
        let finalPayload = {
            projectjson: [apiPayload]
        }

        const duplicateEmails = findDuplicatesByKeyName(selectedContacts, 'email');
        if (duplicateEmails?.length > 0) {
            AlertError('Duplicate Contacts are not allowed !')
            return
        }
        let res = await dispatch(CREATE_NEW_PROJECT(finalPayload))
        if (res) {
            setTabCanAccess(["Details", "Commercials", "Contacts", 'Documents'])
            setActiveTab('Documents')
            dispatch(GET_PROJECTS_BY_ID(projectDetail?.projectid))
        }
    }

    const saveProjectDocument = async () => {
        let afterModify = []
        for (let it of documents) {
            let document = { ...it }
            delete document['comtenantid']
            delete document['createduser']
            delete document['uId']
            document['projectid'] = projectDetail?.projectid
            document['Tenantid'] = it?.comtenantid || it?.Tenantid
            document['documenttype'] = it?.documenttypeid || it['documenttype']
            if (!document['documenttype']) {
                AlertError('Document type is required !')
                return
            }
            afterModify.push(document)
        }

        let res = await dispatch(SAVE_PROJECT_DOCUMENT(afterModify));
        if (res?.success) {
            setDocuments([])
            AlertSuccess('Project document saved successfully.');
            dispatch(GET_PROJECT_DOCUMENT_LIST(projectDetail?.projectid))
        }
    };

    return (
        <>
            <IconButton
                disabled={disabled}
                title='Edit Project'
                onClick={openModal}
                className='border disabled:bg-primary/40 rounded-full bg-primary text-white focus:ring-0 focus:ring-primary font-[500] text-sm focus:outline-none p-1.5'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                </svg>
            </IconButton>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" w-[calc(90%)] 
  md:w-[calc(80%)] transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between w-full items-center border-b border-secondary ">
                                        <h3 className=" text-lg font-[500] leading-6 text-primary">
                                            Update Project
                                        </h3>
                                        <div className="flex w-auto p-1 space-x-1 rounded-lg">
                                            {tabslist?.map((item, i) => {
                                                return (
                                                    <button
                                                        key={i}
                                                        disabled={!tabCanAccess.includes(item)}
                                                        onClick={() => setActiveTab(item)}
                                                        className={`w-full py-1 text-lg outline-white ${item === activeTab
                                                            ? 'bg-secondary/10 text-secondary rounded-md font-[500]'
                                                            : 'text-gray-500'}`}
                                                    >
                                                        <span className='px-4'> {item}</span>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                        <button onClick={closeModal} type="button" className="text-textSecondary w-9 h-9 flex justify-center items-center border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center ">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                            <span className="sr-only">Close icon</span>
                                        </button>
                                    </div>


                                    {activeTab === 'Details' && <>
                                        <Details
                                            hooks={{
                                                errorFound, setErrorFound,
                                                nextToDetail,
                                                businessUnit, setBusinessUnit,
                                                payload, setPayload,
                                                customer, setCustomer,
                                                serviceLine, setServiceLine
                                            }}
                                        />
                                    </>}
                                    {activeTab === 'Commercials' && <>
                                        <Comercials
                                            hooks={{
                                                errorFound, setErrorFound,
                                                nextToCommercials,
                                                payload, setPayload,
                                                opportunity, setOpportunity,
                                                selectedProducts, setSelectedProducts
                                            }}
                                        />
                                    </>}
                                    {activeTab === 'Contacts' && <>
                                        <Contacts
                                            hooks={{
                                                addProject,
                                                errorFound, setErrorFound,
                                                customer, setCustomer,
                                                payload, setPayload,
                                                serviceLine,
                                                businessUnit,
                                                pm, setPm,
                                                dm, setDm,
                                                pmo, setPmo,
                                                selectedContacts, setSelectedContacts
                                            }}
                                        />
                                    </>}

                                    {activeTab === 'Documents' && <>
                                        <Documents
                                            hooks={{
                                                documents, setDocuments,
                                                projectDetail,
                                                saveProjectDocument

                                            }}
                                        />
                                    </>}

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>

                </Dialog>
            </Transition>
        </>
    )
}

export default memo(UpdateProject);

