import { memo, useEffect, useRef, useState } from 'react'

const NotePopup = ({ props }) => {
    const { usernames } = props
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <span ref={dropdownRef} className="relative inline-block">
            <span role="button" onClick={() => setOpen(!open)} className="inline-block text-blue-500 cursor-pointer">
                +{usernames.length}
            </span>
            {open && (
                <div
                    className="p-2 transition right-0 z-[1000] ease-in duration-100 absolute mt-1 w-96 min-w-[200px] rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    style={{ left: '100%' }}
                >
                    <div className="border rounded-md bg-gray-50">
                        <textarea
                            rows={6}
                            className="w-full p-2 rounded-md outline-none bg-gray-50"
                            readOnly
                            value={usernames.join('\n')}
                        />
                    </div>
                </div>
            )}
        </span>
    )
}

export default memo(NotePopup)
