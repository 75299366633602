import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import Details from './details'
import IconButton from 'src/Components/Buttons/IconButton'
import { AlertSuccess } from 'src/Services'
import { OpportunityItem } from '../default'
import { GET_ROLE_MASTER, SAVE_OPPORTUNITY_MASTER } from 'src/Redux/actions/masterdata/user'
import { GET_OPPORTUNITY_LIST, GET_LIST_OF_STATUS, GET_CURRENCY_MASTER_LIST } from 'src/Redux/actions/projects'
import {
	GET_CUSTOMER_MASTERLIST, GET_SOURCE_MASTER_FILTER,
} from 'src/Redux/actions/masterdata/customer';
import { getDefaultCurrencyPayload, getDefaultOpportunityPayload, getDefaultStatusPayload, getRoleMasterIdData } from 'src/Services/constant/defaultPayload';
import { getCustomerListPayload } from 'src/Services/constant/Customerpayload';



const CreateDeliveryRole = () => {
	const [detailsData, setDetailsData] = useState(OpportunityItem())
	const [accountSelect, setAccountSelect] = useState({})
	const [customer, setCustomer] = useState({})

	const dispatch = useDispatch()
	let [isOpen, setIsOpen] = useState(false)


	const loadFormApis = () => {
		dispatch(GET_ROLE_MASTER(getRoleMasterIdData()))
		dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('O')))
		dispatch(GET_CUSTOMER_MASTERLIST(getCustomerListPayload()))
		dispatch(GET_SOURCE_MASTER_FILTER())
		dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()));
	};

	function closeModal() {
		setIsOpen(false)
		setAccountSelect({})
	}

	function openModal() {
		setIsOpen(true)
		loadFormApis();
	}

	async function detailSave(payload) {
		await dispatch(SAVE_OPPORTUNITY_MASTER(payload))
		AlertSuccess("Opportunity saved successfully.");
		await dispatch(GET_OPPORTUNITY_LIST(getDefaultOpportunityPayload()))
	}

	return (
		<>
			<IconButton
				title='Create New Opportunity'
				onClick={openModal}
				className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
					<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
				</svg>
			</IconButton>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-20' onClose={openModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
					</Transition.Child>
					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-2xl bg-white px-6 text-left align-middle shadow-xl transition-all'>
									<div className='border-b py-2 border-primary flex justify-between items-center'>
										<h3 className='text-lg font-[500] leading-6 text-primary'>
											Create Opportunity
										</h3>
										<IconButton
											title='Close'
											onClick={closeModal}
											type='button'
											className='rounded-full border h-10 w-10 flex justify-center items-center'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												strokeWidth={1.5}
												stroke='currentColor'
												className='w-4 h-4'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
											<span className='sr-only'>Close icon</span>
										</IconButton>
									</div>
									<Details
										setDetailsData={setDetailsData}
										detailsData={detailsData}
										detailSave={detailSave}
										onModalClose={closeModal}
										accountSelect={accountSelect}
										setAccountSelect={setAccountSelect}
										customer={customer}
										setCustomer={setCustomer}
									/>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default CreateDeliveryRole
