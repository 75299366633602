import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';
import { subscriptionItem } from '../defaultRowAndData'; // Import subscriptionItem function
import SubscriptionPricing from './subscriptionpricing';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { AlertError } from 'src/Services';
import { cycleList } from 'src/Services/constant/staticValues';


const SubscriptionDetails = (props) => {
  const { tenantid, subscriptionData, setSubscriptionData, subscriptionSave } = props;
  const [pricingData, setPricingData] = useState(subscriptionData.tenantsubscriptionpricing);
  const [checkvalidation, setCheckvalidation] = useState(false);
  const { subscriptionList } = useSelector((state) => state.SubscriptionList);


  const handleSubmit = () => {
    setCheckvalidation(true);

    if (subscriptionData.subscriptionfromdate && subscriptionData.subscriptiontodate) {
      const startDate = new Date(subscriptionData.subscriptionfromdate);
      const endDate = new Date(subscriptionData.subscriptiontodate);

      if (startDate >= endDate) {
        AlertError('End Date must be greater than Start Date');
        return;
      }
    }
    const isPricingDataValid = pricingData.every(pricing => {
      // Convert the values to strings before checking
      const minusers = String(pricing.minusers).trim();
      const maxusers = String(pricing.maxusers).trim();
      const unitprice = String(pricing.unitprice).trim();

      return minusers !== '' && maxusers !== '' && unitprice !== '' && pricing.datcomcurrencyid !== 0;
    });

    if (!isPricingDataValid) {
      AlertError('Please fill in all pricing data fields.');
      return;
    }

    // Check if Max Users is less than Min Users
    const isMaxUsersLessThanMinUsers = pricingData?.some((item, index, array) => {
      if (Number(item?.maxusers) < Number(item?.minusers)) {
        return true
      } else if (Number(item?.maxusers) < Number(item?.minusers)) {
        return true
      } else if (index > 0 && (Number(array[index - 1]?.maxusers) < Number(item?.minusers))) {
        return false
      } else if (index > 0 && (Number(array[index - 1]?.maxusers) > Number(item?.minusers))) {
        return true
      } else {
        return false
      }
    });

    if (isMaxUsersLessThanMinUsers) {
      AlertError('Maximum Users cannot be less than Minimum Users');
      return;
    }
    
    let item = {
      ...subscriptionItem(),
      ...subscriptionData,
      comtenantid: parseInt(tenantid),
      tenantsubscriptionpricing: pricingData.map((pricing) => ({
        ...pricing,
        comtenantid: parseInt(tenantid), // Include the tenantid field in each pricing item
      })),
    };

    let isValidated = CheckObjectValidation(subscriptionData, [
      'tenantsubscriptionid',
      'comtenantid',
      'graceperiodindays'
    ]);

    if (isValidated?.isvalid) {
      let payload = {
        tenantsubscriptionjson: [item],
      };

      subscriptionSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setSubscriptionData({ ...subscriptionData, [name]: value });
  };

  return (
    <div className="w-full text-sm my-4">
      <div className="grid grid-cols-4 gap-10 mt-4">
        <div>
          <label htmlFor="datsubscriptionpackageid" className="block font-[500] mb-2">
            Subscription <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={subscriptionList || []}
            value={subscriptionData?.datsubscriptionpackageid || ''}
            noDataLabel='No Subscription Package'
            name='datsubscriptionpackageid'
            labelkey='packagename'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='datsubscriptionpackageid'
          />
          {!subscriptionData?.datsubscriptionpackageid && checkvalidation && (
            <small className="text-red-400">Subscription Package is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='subscriptionfromdate' className='block font-[500] mb-2'>
            Start Date
            <span className="text-red-300"> *</span>
          </label>
          <FlatPickrDateInput
            value={subscriptionData?.subscriptionfromdate || ''}
            name='subscriptionfromdate'
            id='subscriptionfromdate'
            className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
            onChangeDate={e => handleChangeInput('subscriptionfromdate', e.target.value)}
          />
          {!subscriptionData?.subscriptionfromdate && checkvalidation && (
            <small className='text-red-400'>Start Date is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='subscriptiontodate' className='block font-[500] mb-2'>
            End Date
            <span className="text-red-300"> *</span>
          </label>
          <FlatPickrDateInput
            value={subscriptionData?.subscriptiontodate || ''}
            name='subscriptiontodate'
            id='subscriptiontodate'
            className='block w-full p-2 border border-gray-300 rounded outline-none bg-gray-50 text-textSecondary sm:text-sm focus:ring-primary focus:border-primary'
            onChangeDate={e => handleChangeInput('subscriptiontodate', e.target.value)}
          />
          {!subscriptionData?.subscriptiontodate && checkvalidation && (
            <small className='text-red-400'>End Date is required!</small>
          )}
        </div>
        <div>
          <label htmlFor="billingcycle" className="block font-[500] mb-2">
            Billing Cycle <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            options={cycleList || []}
            noDataLabel='No cycle'
            value={subscriptionData?.billingcycle}
            name='billingcycle'
            labelkey='cycle'
            className='border rounded bg-gray-50 py-2'
            onChange={handleChangeInput}
            id='billingcycle'
          />
          {!subscriptionData?.billingcycle && checkvalidation && (
            <small className="text-red-400">Billing Cycle is required!</small>
          )}
        </div>
      </div>
      <SubscriptionPricing
        pricingData={pricingData}
        setPricingData={setPricingData}
        handleSubmit={handleSubmit}
        tenantid={tenantid}
      />
    </div>
  );
};

export default SubscriptionDetails;