const initialState = {
  category: [],
  categoryList: [],
  template: [],
  templateList: [],
  rules: [],
  rulesList: [],
  match: [],
  matchList: [],
  notificationList: [],
  notifications: [],
  roleList: [],
  role: [],
  menuList: [],
  roleById: [],
  PermissionsOfConfiguration: []

};

const ConfigurationList = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_CATEGORY_MASTER':
      return { ...state, category: action.data?.Message ? [] : action.data, };
    case "GET_TIME_CATEGORY_MASTER":
      return { ...state, categoryList: action.data }
    case 'SAVE_TEMPLATE_MASTER':
      return { ...state, template: action.data?.Message ? [] : action.data, };
    case "GET_TEMPLATE_MASTER":
      return { ...state, templateList: action.data }
    case 'SAVE_RULES_MASTER':
      return { ...state, rules: action.data?.Message ? [] : action.data, };
    case "GET_RULES_MASTER":
      return { ...state, rulesList: action.data }
    case 'SAVE_DEMAND_MATCH_CONFIG':
      return { ...state, match: action.data?.Message ? [] : action.data, };
    case 'GET_DEMAND_MATCH':
      return { ...state, matchList: action.data }
    case 'SAVE_NOTIFICATION_MASTER':
      return { ...state, notifications: action.data?.Message ? [] : action.data, };
    case 'GET_NOTIFICATION_MASTER':
      return { ...state, notificationList: action.data };
    case 'SAVE_ROLE_MASTER':
      return { ...state, role: action.data?.Message ? [] : action.data, };
    case 'GET_ROLES_MASTER':
      return { ...state, roleList: action.data };
    case 'GET_MENU_MASTER':
      return { ...state, menuList: action.data };
    case 'GET_ROLE_BY_ID':
      return { ...state, roleById: action.data };
    case 'SAVE_PERMISSIONS_OF_CONFIGRATION':
      return { ...state, PermissionsOfConfiguration: action.data };
    default:
      return state;
  }
};

export default ConfigurationList;




