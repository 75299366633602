import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useState } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../defaulTech';

const Details = props => {
	const { detailSave, detailsData, setDetailsData } = props
	const [checkvalidation, setCheckvalidation] = useState(false);


	const handleSubmit = async (e) => {
		e.preventDefault();
		setCheckvalidation(true);

		let isValidated = CheckObjectValidation(detailsData, detailNotRequired);
		if (isValidated?.isvalid) {
			let payload = {
				techgroupjson: [detailsData],
			}
			detailSave(payload)
		}
	}
	const handleChangeInput = (name, value) => {
		setCheckvalidation(false);
		setDetailsData({ ...detailsData, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='techarea' className='block font-[500] mb-2'>
						Technology Group
						<span className="text-red-300"> *</span>
					</label>
					<input
						type='text'
						title='techarea'
						id='techarea'
						className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
						placeholder='Enter Technology Group'
						required=''
						onChange={e => handleChangeInput('techarea', e.target.value)}
						maxLength={50}
					/>
					{!detailsData?.techarea && checkvalidation && (
						<small className='text-red-400'>Technology Group Name is required!</small>
					)}
				</div>
			</div>
			<div className='fixed bottom-0 right-6 mt-2'>
				<div className='gap-2 flex justify-end items-center'>
					<PrimaryButton
						type=''
						className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
						onClick={handleSubmit}
					>
						Save
					</PrimaryButton>
				</div>
			</div>
		</div>
	)
}

export default memo(Details)
