import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT } from 'src/Redux/actions/projects/timesheet'

const TimesheetEffort = ({ pId }) => {
	const dispatch = useDispatch()
	const getProjectEffort = useSelector(state => state?.ProjectTimesheet?.getProjectEffort)

	useEffect(() => {
		if (pId) {
			dispatch(GET_PROJECT_EFFORT_SUMMARY_BY_PROJECT(pId))
		}
	}, [dispatch, pId])

	return (
		<div className='h-[calc(90%)]  mt-2 overflow-scroll'>
			<table className='text-sm w-full relative'>
				<thead className='sticky top-0'>
					<tr className='text-left capitalize bg-gray-50'>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Staff</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Role</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Billable</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Planned Effort</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Actual Effort</th>
						<th scope='col'
							className='font-[500] border text-start p-1.5'>Variance (%)</th>
					</tr>
				</thead>
				<tbody>
					{getProjectEffort?.map((item, i) => (
						<tr key={item?.username + i}>
							<td className='border px-1 text-sm'>{item?.username}</td>
							<td className='border px-1 text-sm'>{item?.rolename}</td>
							<td className='border px-1 text-sm'>{item?.billable ? 'Yes' : 'No'}</td>
							<td className='border px-1 text-sm'>{item?.plannedeffort || 0} Hrs</td>
							<td className='border px-1 text-sm'>{item?.actualeffort.toFixed(0) || 0} Hrs</td>
							<td className='border px-1 text-sm'>{item?.variance}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div >
	)
}

export default TimesheetEffort
