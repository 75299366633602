import { useState, useRef, useEffect, memo } from 'react'

function SingleSelectField({ props }) {
    const { skillarea, skill } = props
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef(null)

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside)

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="relative group ">
                <button
                    className="text-start inline-flex items-center min-w-[140px]"
                    onClick={() => setOpen(!open)}
                >
                    {skill || ''}
                </button>
            </div>
            {open && (
                <div className="p-2 transition z-[1000] ease-in duration-100 absolute mt-1 w-96 min-w-[90px] rounded-sm bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <h6 className="mb-1">Skill Area</h6>
                    <div className="p-1 border rounded-md">{skillarea}</div>
                    <h6 className="my-1">Skill</h6>
                    <div className="p-1 border rounded-md">{skill}</div>
                </div>
            )}
        </div>
    )
}

const SkillPopup = (props) => {
    return (
        <div className="w-full ">
            <SingleSelectField props={props} />
        </div>
    )
}

export default memo(SkillPopup)
