
import { useMemo, useState } from "react";
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { GET_CUSTOMER_LIST } from "src/Redux/actions/projects";
import { getDefaultCustomerPayload } from "src/Services/constant/defaultPayload";

const SelectCustomer = ({ customerList, handleSelectCustomer, customer, disabled, canEdit }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useMemo(() => {
        if (customer?.customername) {
            setSearchTerm(customer?.customername || '')
        }
    }, [customer])

    return (
        <>
            <CustomAutoSearch
                disabled={canEdit}
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_CUSTOMER_LIST}
                searchPayload={{
                    ...getDefaultCustomerPayload(),
                    customername: searchTerm
                }}
                canedit={canEdit}
                className=' bg-gray-50 text-gray-800 border rounded-lg block w-full p-2'
                setSearchTerm={setSearchTerm}
                options={customerList}
                idkey='customerid'
                optionlabelKey='customername'
                getSelectedOption={handleSelectCustomer}
                placeholder='Search Customer' />
        </>
    )
}

export default SelectCustomer;