

import React, { useRef, useEffect } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';


const WeekSelect = ({weeks, setWeeks}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const instance = flatpickr(inputRef.current, {
            // defaultDate: moment(new Date(state?.monday)).format('DD/MM/YYYY'), // Add your desired default date here
            disable: [
                function (date) {
                    // return true to disable
                    return date.getDay() !== 1;
                }
            ],
            onChange: selectedDates => {
                let now = selectedDates[0]
                const monday = new Date(now.setDate(now.getDate() - now.getDay() + 1));
                const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7));
                // const nextMonday = new Date(monday.getTime() + 7 * 24 * 60 * 60 * 1000);
                // const nextSunday = new Date(sunday.getTime() + 7 * 24 * 60 * 60 * 1000);
                let range = { monday: monday, sunday: sunday }
                setWeeks(range)
            },
        });

        return () => {
            instance.destroy(); // destroy the Flatpickr instance on component unmount
        };
        // eslint-disable-next-line 
    }, []);

    return (
        <div className='flex justify-start items-center relative text-primary font-[500]'>
            <span className='w-6 h-6 flex justify-center items-center absolute left-1 bg-primary rounded-full'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>
            </span>
            <input
                type="text"
                ref={inputRef}
                className="pl-8 px-0 py-2 z-10 w-[20px] bg-transparent text-primary focus:outline-none rounded-md"
            />
            <div className='flex justify-start items-center space-x-1'>
                <span className=' inline-flex'>{moment(weeks?.monday).format('DD/MM/YYYY')}</span>
                <span>-</span>
                <span className=' inline-flex'>{moment(weeks?.sunday).format('DD/MM/YYYY')}</span>
            </div>
        </div>
    );
};

export default WeekSelect