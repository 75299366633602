import CustomhtmlSelect from "src/Components/CustomhtmlSelect";

const SelectTimezone = ({ timezones, handleChangeValue, value }) => {
    return (

        <>
            <label htmlFor="timezone" className="block font-[500] mb-2">Shift Timezone <span className="text-red-300">*</span></label>
            <CustomhtmlSelect
                options={timezones || []}
                value={value}
                noDataLabel='Timezone'
                name='timezone'
                labelkey='zone'
                className='border rounded bg-gray-50 py-2'
                onChange={handleChangeValue}
                id='zone'
            />
        </>
    )
}

export default SelectTimezone;