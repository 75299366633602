import { memo } from "react";
import CustomerRole from './customerRole';
import SelectOption from 'src/Components/SelectOption';
import IconButton from 'src/Components/Buttons/IconButton'
import Assigment from './assigment';
import { AlertError, calculatEstdEffortHrs, getQuarterDates, getWorkingDaysStartAndEndDate } from 'src/Services';
import { defaultItem } from './defaultData';
import { getDefaultCostRates } from "src/Services/constant/masterdatapayload";
import { GET_COST_RATES, GET_EXCHANGE_RATE_MASTER } from "src/Redux/actions/masterdata/user";
import { getExchangeRatePayload } from "src/Services/constant/defaultPayload";
import { useDispatch, useSelector } from "react-redux";
import FlatPickrDateInput from "src/Components/FlatpickerDateInput";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const RoleRow = ({ hooksValue, roleInfo }) => {
    const { setRoles, roles, project, showMore, disabled } = hooksValue
    const getCustomerRoleById = useSelector(state=> state?.ProjectPlanning?.getCustomerRoleById)
    const companyWeekdaysList = useSelector(state=> state?.UserManagementList?.companyWeekdaysList)

    const dispatch = useDispatch()
    const handleAdd = () => {
        setRoles([...roles, defaultItem()]);
    };

    const handleDelete = (selected) => {
        if (roles?.length > 1) {
            if (selected?.createdtimestamp === undefined) {
                let rm = roles?.filter(item => item?.uId !== selected?.uId)
                setRoles(rm)
            } else {
                let items = roles?.map((item) => {
                    if (item?.uId === selected?.uId) {
                        item['isactiveflag'] = false
                    }
                    return item
                })
                setRoles(items)
            }

        }
    };

    const handleInputChange = (event) => {
        const { name, value, id } = event.target
        let newValue = roles?.map((item) => {
            if (item?.uId === id) {
                if (name === 'allocation' || name === 'peopleno') {
                    if (item?.startdate && item?.enddate) {
                        let po = Number(item?.peopleno) || 0
                        let al = Number(item?.allocation) || 0
                        let hr = 0
                        let wday = getWorkingDaysStartAndEndDate(item?.startdate, item?.enddate)
                        if (companyWeekdaysList?.length > 0) {
                            hr = companyWeekdaysList[0]?.noofhoursperday
                        }
                        item['plannedeffort'] = calculatEstdEffortHrs(po, al, hr, wday)
                    }
                }
                if (name === 'billable') {
                    item[name] = JSON.parse(value)
                } else {
                    if (isNaN(value)) {
                        item[name] = value
                    } else {
                        item[name] = Number(value)
                    }
                }
            }
            return item;
        });
        setRoles([...newValue])
    };

    const handledates = (event) => {
        const { name, value, id } = event.target
        let newValue = [...roles]
        let AfterChange = newValue?.map((item) => {
            // let item = { ...r }
            if (item?.uId === id) {
                if (name === 'enddate' && item?.startdate > value) {
                    AlertError('End date must be greater than start date')
                    return item
                } else if ((name === 'startdate' && item?.enddate) && value > item?.enddate) {
                    AlertError('Start date must be smaller than end date')
                    return item
                }
                item[name] = value
                if (item?.startdate && item?.enddate) {
                    let po = Number(item?.peopleno) || 0
                    let al = Number(item?.allocation) || 0
                    let hr = 0
                    let wday = getWorkingDaysStartAndEndDate(item?.startdate, item?.enddate)
                    if (companyWeekdaysList?.length > 0) {
                        hr = companyWeekdaysList[0]?.noofhoursperday
                    }
                    // console.log(calculatEstdEffortHrs(po, al, hr, wday))
                    item['plannedeffort'] = calculatEstdEffortHrs(po, al, hr, wday)

                    async function startAsyncCalculation() {
                        if (project?.companyid) {
                            let noofhoursperday = companyWeekdaysList[0]?.noofhoursperday
                            let currencyto = project?.defaultcomcurrencyid
                            let conversionValue = 0 // cost rate multiply by no of hours per day ( converting into hours  )
                            let payload = {
                                ...getDefaultCostRates(),
                                Companyid: project?.companyid,
                                Startdate: getQuarterDates()?.startDate,
                                Enddate: getQuarterDates()?.endDate,
                                Bandid: item?.max_bandid,
                                Skillareamasterid: item?.skillareamasterid,
                                techgroupid: item?.techgroupid,
                                countryid: item?.countryid

                            }
                            let costrate = 0
                            let unitname = null
                            let conversionrate = 0

                            let costratesList = await dispatch(GET_COST_RATES(payload))

                            if (costratesList) {
                                costrate = costratesList[0]?.cost
                                unitname = costratesList[0]?.unitname
                                let currencyfrom = costratesList[0]?.comcurrencyid
                                // get exchange rate if project and role costrate currency is diff ...
                                if (currencyto !== currencyfrom) {
                                    let data = {
                                        ...getExchangeRatePayload(),
                                        currencyfrom,
                                        currencyto,
                                        Startdate: getQuarterDates()?.startDate,
                                        Enddate: getQuarterDates()?.endDate,
                                    }
                                    let exchangeratesList = await dispatch(GET_EXCHANGE_RATE_MASTER(data));
                                    if (exchangeratesList?.length > 0) {
                                        conversionrate = exchangeratesList[0]?.conversionrate || 0
                                    }
                                }

                            }

                            /*  Convert cost rate into hours  */
                            if (unitname === 'Monthly') {
                                conversionValue = (costrate / (22 * noofhoursperday))
                            } else if (unitname === 'Weekly') {
                                conversionValue = (costrate / (5 * noofhoursperday))
                            } else if (unitname === 'Daily') {
                                conversionValue = costrate / noofhoursperday
                            }

                            let x = conversionValue
                            // if we have conversion rate then multiply conversionValue 
                            if (conversionrate !== 0) {
                                x = (conversionValue * conversionrate).toFixed(2)
                            }
                            item['estPeopleCost'] = Number(x * item?.plannedeffort).toFixed(0)
                            if (costrate <= 0 || conversionrate <= 0) {
                                item['estPeopleCost'] = 0
                            }
                        }
                    }
                    startAsyncCalculation()
                }
            }

            return item
        })

        setRoles(AfterChange)
    };
    const selectCustomer = (customer, uId) => {
        let afterChange = roles?.map((item) => {
            if (item?.uId === uId) {
                item['levelid'] = customer?.levelid
                item['skillareamasterid'] = customer?.skillareamasterid
                item['customerid'] = customer?.customerid
                item['skillarea'] = customer?.skillarea
                item['techarea'] = customer?.techarea
                item['techgroupid'] = customer?.techgroupid
                item['datcomcurrencyid'] = customer?.comcurrencyid
                item['currency'] = customer?.currency
                item['isonsite'] = customer?.isonsite ? true : false
                item['cityid'] = customer?.cityid
                item['billratehr'] = customer?.billratehr || 0
                item['levelname'] = customer?.levelname
                item['crateid'] = customer?.custrateid
                item['rateunit'] = customer?.rateunit
                item['countryid'] = customer?.countryid
                item['rateunitname'] = customer?.rateunitname
                item['countryname'] = customer?.countryname
            }
            return item
        })
        setRoles(afterChange)
    }

    const { uId, crateid, projectroletasks, taskdetails, billratehr, peopleno,
        allocation, startdate, enddate, isonsite, currency,
        levelname, skillarea, techarea, countryname, plannedeffort, estPeopleCost } = roleInfo

    return (
        <tr className="text-left">
            {showMore ? <>
                <td className="border w-36 py-1">
                    <CustomerRole
                        roles={roles}
                        setRoles={setRoles}
                        rowId={uId}
                        value={crateid}
                        options={getCustomerRoleById}
                        handleChange={selectCustomer}
                        canEdit={disabled} />
                </td>
                <td className="border w-[50px]  px-2">
                    {levelname}
                </td>
                <td className="border w-40 px-2">
                    {skillarea}
                </td>
                <td className="border w-[130px] px-2">
                    {techarea}
                </td>
                <td className="border w-20 pl-2">
                    <input type='text' disabled={!disabled} name='billratehr' id={uId} onChange={(e) => {
                        if (e.target.value < 0) {
                            e.target.value = 1
                        } else {
                            handleInputChange(e)
                        }
                    }} className="w-full focus:outline-none hover:outline-none" value={billratehr || 0} />
                </td>
                <td className="border w-14 px-2">
                    {currency || 'N/A'}
                </td>
                <td className="border w-16 px-2">
                    {roleInfo?.rateunitname || 'N/A'}
                </td>
                <td className="border w-[110px] px-2">
                    {isonsite ? 'Onsite' : 'Offshore'}
                </td>
                <td className="border w-[110px] py-0 px-1">
                    {countryname || 'N/A'}
                </td>
                <td className="border w-[80px] px-1 py-0">
                    <input type='number' disabled={!disabled} name='peopleno'
                        id={uId} onChange={(e) => {
                            if (e.target.value < 0) {
                                e.target.value = 1
                            } else {
                                handleInputChange(e)
                            }
                        }} className=" w-full focus:outline-none hover:outline-none" value={peopleno || ''} />
                </td>
            </> :
                <>
                    <td className="border w-32 text-sm px-2 py-1">
                        <CustomerRole
                            roles={roles}
                            setRoles={setRoles}
                            rowId={uId}
                            value={crateid}
                            options={getCustomerRoleById}
                            handleChange={selectCustomer}
                            canEdit={disabled} />
                    </td>
                    <td className="border w-20 px-1 py-0">
                        <div className="flex justify-start ">
                            <input disabled={!disabled} name='allocation' id={uId}
                                onChange={(e) => {
                                    if (/^[0-9]*$/.test(e.target?.value) && (Number(e.target.value) >= 0 && Number(e.target.value) <= 100)) {
                                        handleInputChange(e)
                                    }
                                }}
                                className="w-full focus:outline-none hover:outline-none" value={(allocation || 0).toFixed(0) || ''} />
                            <span>%</span>
                        </div>
                    </td>
                    <td className="border w-20 px-1 py-0">
                        <FlatPickrDateInput
                            max={project?.enddate}
                            min={project?.startdate}
                            disabled={!disabled}
                            name='startdate'
                            id={uId}
                            className=""
                            value={startdate || ''}
                            onChangeDate={e => handledates(e)}
                        />
                    </td>
                    <td className="border w-20 px-1 py-0">
                        <FlatPickrDateInput
                            max={project?.enddate}
                            min={project?.startdate}
                            disabled={!startdate || !disabled}
                            name='enddate'
                            className=""
                            id={uId}
                            value={enddate || ''}
                            onChangeDate={e => handledates(e)}
                        />
                    </td>
                    <td className="border w-20 px-1 py-0">
                        {plannedeffort || 0} Hrs
                    </td>
                    <td className="border w-20 px-1 py-0">
                        {`${estPeopleCost || 0} ${project?.currency || ''}`}
                    </td>
                    <td className="border w-[70px] p-1 py-0">
                        <div className='w-full flex justify-start items-center'>
                            <Assigment
                                alltask={projectroletasks || taskdetails || []}
                                hooksValue={{ setRoles, roles, uId, project, disabled }}
                            />
                        </div>
                    </td>
                    <td className="border w-28 px-1 py-0">
                        <SelectOption
                            disabled={!disabled}
                            onChange={handleInputChange} id={uId}
                            name='billable'
                            value={roleInfo?.billable || false}
                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                        />
                    </td>
                </>}
            {disabled && <td className="border w-20 px-1 py-0">
                <div className='flex justify-end items-center'>
                    <IconButton title='Delete Role' onClick={() =>
                        handleDelete(roleInfo)
                    }
                        className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                            <MinusIcon className="w-3" />
                    </IconButton>
                    <span className="hidden addIconbutton-add-btn">
                        <IconButton title='Add Role' onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                            <PlusIcon className="w-3" />
                        </IconButton>
                    </span>
                </div>
            </td>}
        </tr>
    )
}

export default memo(RoleRow);