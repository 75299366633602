export const getDefaultPayrollPayload = (Limit = 10, Page = 1, Sorttype = 3) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        COMUserId: 0,
        Limit,
        Page,
        Sorttype,
        Searchname: '',
        Costcentercode: '',
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
    }
}

export const getPayRollOverheadPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        overheadmasterid: 0,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
        costtype: 'D',
    }
}
