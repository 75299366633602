import { memo, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';

const TenantDetails = (props) => {
  const [city, setCity] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const { cityList } = useSelector((state) => state.UserManagementList);
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit, comtenantid } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);
  const [addressLine1, addressLine2] = useMemo(
    () => {
      if (detailsData?.tenantaddress) {
        const addressArray = detailsData?.tenantaddress.split(' ');
        const addressLine1 = addressArray.shift() || '';
        const addressLine2 = addressArray.join(' ') || '';
        return [addressLine1, addressLine2];
      } else {
        return ['', ''];
      }
    }, // eslint-disable-next-line
    [detailsData?.addressLine1, detailsData?.addressLine2]
  );

  useMemo(() => {
    if (city?.cityname) {
      setSearchTerm(city?.cityname);
    }
  }, [city]);

  useEffect(
    () => {
      setDetailsData((prevState) => ({
        ...prevState,
        addressLine1: addressLine1 || '',
        addressLine2: addressLine2 || '',
      }));
    },
    // eslint-disable-next-line
    [detailsData?.tenantaddress, addressLine1, addressLine2]
  );

  useEffect(() => {
    if (detailsData?.cityname && detailsData?.comcityid) {
      setCity({
        cityname: detailsData.cityname,
        comcityid: detailsData.comcityid,
      });
    }
  }, [detailsData?.cityname, detailsData?.comcityid]);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let tenantAddress = `${detailsData?.addressLine1 || ''} ${detailsData?.addressLine2 || ''}`;

    const isContactDetailsValid = detailsData?.tenantcontactdetails.every((contact) =>
      contact.contactpersonname && contact.designation && contact.phoneno && contact.email
    );
    let items = {
      comtenantid: comtenantid,
      tenantname: detailsData?.tenantname,
      tenantcode: detailsData?.tenantcode,
      themecode: detailsData?.themecode,
      isactiveflag: true,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
      loginmethod: detailsData?.loginmethod,
      logopath: detailsData?.logopath,
      heroimagepath: detailsData?.heroimagepath,
      tenantaddress: tenantAddress.trim(),
      contactdetails: detailsData?.contactdetails,
      GSTnumber: detailsData?.GSTnumber,
      CIN: detailsData?.CIN,
      comcityid: city?.comcityid,
      emailid: detailsData?.emailid,
      pincode: detailsData?.pincode,
      companyname: detailsData?.tenantname,
      // password: detailsData?.password,
      token: detailsData?.token,
      mobileno: detailsData?.mobileno,
      employeecode: detailsData?.employeecode,
      comcurrencyid: 1,
      tenantcontactdetails: detailsData?.tenantcontactdetails.map(
        (contact) => ({
          ...contact,
          isrenewalreminderneedtosend: true,
          isinvoiceneedtosend: true,
          isactiveflag: true,
          notes: '',
        })
      ),
    };

    let isValidated = CheckObjectValidation(items, [
      'employeecode',
      'mobileno',
      'token',
      'notes',
      'CIN',
      'comtenantid',
      'themecode',
      'createdcomuserid',
      'loginmethod',
      'logopath',
      'heroimagepath',
      'GSTnumber',
      'contactdetails',
      'emailid',
      'totalpages',
      'packagename',
      'subscriptionfromdate',
      'subscriptiontodate',
      'tenantaddress'
    ]);

    if (isValidated?.isvalid && isContactDetailsValid) {
      let payload = {
        tenantjson: [items],
        password: detailsData?.password || '',
        url: detailsData?.url || '',
      };
      delete payload.tenantjson[0].addressLine1;
      delete payload.tenantjson[0].addressLine2;
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    const updatedDetailsData = { ...detailsData };

    if (name === 'cityname') {
      setCity({ cityname: value, comcityid: updatedDetailsData.comcityid });
    } else if (name in updatedDetailsData.tenantcontactdetails[0]) {
      const updatedTenantContactDetails =
        updatedDetailsData.tenantcontactdetails.map((contact) => ({
          ...contact,
          [name]: value,
        }));
      updatedDetailsData.tenantcontactdetails = updatedTenantContactDetails;
    } else {
      updatedDetailsData[name] = value;
    }
    setDetailsData(updatedDetailsData);
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-4 mt-4'>
        <div>
          <label htmlFor='tenantname' className='block font-[500] mb-2'>
            Tenant Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('tenantname', e.target.value);
              }
            }}
            title='tenantname'
            id='tenantname'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Tenant Name'
            required=''
            value={detailsData?.tenantname || ''}
            maxLength={50}
          />
          {!detailsData?.tenantname && checkvalidation && (
            <small className='text-red-400'>Tenant Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled
            type='text'
            title='companyname'
            id='companyname'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Company Name'
            required=''
            value={detailsData?.tenantname || ''}
            maxLength={50}
            onChange={(e) =>
              handleChangeInput('companyname', detailsData?.tenantname)
            }
          />
          {!detailsData?.tenantname && checkvalidation && (
            <small className='text-red-400'>Tenant Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='tenantcode' className='block font-[500] mb-2'>
            Sub Domain
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='tenantcode'
            id='tenantcode'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Tenant Code'
            value={detailsData?.tenantcode || ''}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^\w]/g, '');
              handleChangeInput('tenantcode', inputValue);
            }}
          />
          {!detailsData?.tenantcode && checkvalidation && (
            <small className='text-red-400'>Sub Domain/Tenant Code is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='addressLine1' className='block font-[500] mb-2'>
            Address Line 1 <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='addressLine1'
            id='addressLine1'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Address Line 1'
            required=''
            value={detailsData?.addressLine1 || ''}
            onChange={(e) => handleChangeInput('addressLine1', e.target.value)}
            maxLength={50}
          />
          {!detailsData?.addressLine1 && checkvalidation && (
            <small className='text-red-400'>Address Line 1 is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='addressLine2' className='block font-[500] mb-2'>
            Address Line 2<span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='addressLine2'
            id='addressLine2'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Address Line 2'
            value={detailsData?.addressLine2 || ''}
            onChange={(e) => handleChangeInput('addressLine2', e.target.value)}
            maxLength={50}
          />
        </div>
        <div>
          <label htmlFor='comcityid' className='block font-[500] mb-2'>
            City
            <span className='text-red-300'> *</span>
          </label>
          <CustomAutoSearch
            disabled={canEdit}
            searchTerm={searchTerm}
            API_CALL_FOR_SEARCH={GET_CITY_MASTER}
            searchPayload={{
              ...getMasterCityPayload(),
              searchname: searchTerm,
            }}
            setSearchTerm={setSearchTerm}
            options={cityList}
            idkey='cityname'
            optionlabelKey='cityname'
            getSelectedOption={setCity}
            placeholder='Enter City Name'
            className=' bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
          />
          {!city?.comcityid && checkvalidation && (
            <small className='text-red-400'>City is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='pincode' className='block font-[500] mb-2'>
            Pincode
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='pincode'
            id='pincode'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Pincode'
            required=''
            value={detailsData?.pincode || ''}
            onChange={(e) => handleChangeInput('pincode', e.target.value)}
          />
        </div>
        {!detailsData?.pincode && checkvalidation && (
          <small className='text-red-400'></small>
        )}
        <div>
          <label htmlFor='emailid' className='block font-[500] mb-2'>
            Email
          </label>
          <input
            disabled={canEdit}
            type='emailid'
            title='emailid'
            id='emailid'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Email'
            required=''
            value={detailsData?.emailid || ''}
            onChange={(e) => handleChangeInput('emailid', e.target.value)}
          />
          {!detailsData?.emailid && checkvalidation && (
            <small className='text-red-400'></small>
          )}
        </div>
        <div>
          <label htmlFor='GSTnumber' className='block font-[500] mb-2'>
            GST
          </label>
          <input
            disabled={canEdit}
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('GSTnumber', e.target.value);
              }
            }}
            title='GSTnumber'
            id='GSTnumber'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='GST No.'
            required=''
            value={detailsData?.GSTnumber || ''}
            maxLength={50}
          />
          {/* {!detailsData?.GSTnumber && checkvalidation && (
            <small className='text-red-400'>GST No. is required!</small>
          )} */}
        </div>
        <div>
          <label htmlFor='CIN' className='block font-[500] mb-2'>
            CIN
          </label>
          <input
            disabled={canEdit}
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('CIN', e.target.value);
              }
            }}
            title='CIN'
            id='CIN'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='CIN'
            required=''
            value={detailsData?.CIN || ''}
            maxLength={50}
          />
        </div>
      </div>

      <div className='grid grid-cols-4 gap-4 mt-8'>
        <div>
          <label htmlFor='contactpersonname' className='block font-[500] mb-2'>
            Contact Person
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            id='contactpersonname'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Name'
            required=''
            value={
              detailsData?.tenantcontactdetails[0]?.contactpersonname || ''
            }
            onChange={(e) =>
              handleChangeInput('contactpersonname', e.target.value)
            }
            maxLength={50}
          />
          {(!detailsData?.tenantcontactdetails[0]?.contactpersonname || '') &&
            checkvalidation && (
              <small className='text-red-400'>
                Contact Person Name is required!
              </small>
            )}
        </div>
        <div>
          <label htmlFor='designation' className='block font-[500] mb-2'>
            Designation
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            id='designation'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Designation'
            required=''
            value={detailsData?.tenantcontactdetails[0]?.designation || ''}
            onChange={(e) => handleChangeInput('designation', e.target.value)}
            maxLength={50}
          />
          {!detailsData?.tenantcontactdetails[0]?.designation &&
            checkvalidation && (
              <small className='text-red-400'>Designation is required!</small>
            )}
        </div>
        <div>
          <label htmlFor='phoneno' className='block font-[500] mb-2'>
            Phone
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='tel'
            id='phoneno'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Phone'
            required=''
            value={detailsData?.tenantcontactdetails[0]?.phoneno || ''}
            onChange={(e) => handleChangeInput('phoneno', e.target.value)}
          />
          {!detailsData?.tenantcontactdetails[0]?.phoneno &&
            checkvalidation && (
              <small className='text-red-400'>Phone is required!</small>
            )}
        </div>
        <div>
          <label htmlFor='email' className='block font-[500] mb-2'>
            Email
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='email'
            id='email'
            className='outline-gray-100 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Contact Person Email'
            required=''
            value={detailsData?.tenantcontactdetails[0]?.email || ''}
            onChange={(e) => handleChangeInput('email', e.target.value)}
          />
          {!detailsData?.tenantcontactdetails[0]?.email && checkvalidation && (
            <small className='text-red-400'>Email is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-2 right-6'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TenantDetails);