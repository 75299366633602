
import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment'
import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APPROVE_ALL_TIMESHEET, GET_MANAGER_TIMESHEET_RECORDS } from 'src/Redux/actions/projects/timesheet'
import { AlertError } from 'src/Services'

export default function ApproveAll({updatehooks: {weeks, page, limit, project, selectUser, selectStatus}}) {
    let [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()
    const loggedinuser = JSON.parse(localStorage.getItem('comuserid'))
    const datalist = useSelector(state => state?.ProjectTimesheet?.getManagerTimesheetRecord)

    function open() {
        setIsOpen(true)
    }

    function close() {
        setIsOpen(false)
    }


    const listToDisplay = (id) => {
        if (id) {
            return datalist?.filter(i => (i?.comuserid === selectUser?.comuserid))
        } else {
            return datalist?.filter(i => (i?.comuserid !== +loggedinuser))
        }
    }

    const handleApproveAll = async () => {
        //  repalce data with real data 
        let timesheetjson = []
        for (let item of listToDisplay(selectUser?.comuserid)) {
            if (item?.effortid === null) {
                AlertError('Effort Id not provided')
                return
            }
            timesheetjson.push({
                statusname: "Approved",
                effortid: item?.timesheetdata[0]?.effortid,
                approverremarks: 'Approved',
                approved: true,
                comtenantid: Number(localStorage.getItem('Tenantid') || 0),
                approverid: Number(localStorage.getItem('comuserid') || 0),
            })
        }


        let payload = {
            timesheetjson
        }
        let res = await dispatch(APPROVE_ALL_TIMESHEET(payload))
        if(res){
            let payload = {
                "loginuserid": localStorage.getItem('comuserid'),
                "comuserid": selectUser?.comuserid || 0,
                "Tenantid": localStorage.getItem("Tenantid"),
                "projectid": project?.projectid || 0,
                "startdate": moment(new Date(weeks?.monday)).format('YYYY-MM-DD'),
                "enddate": moment(new Date(weeks?.sunday)).format('YYYY-MM-DD'),
                "timesheetstatus": selectStatus?.value,
                "limit": limit,
                "page": page
            }
            dispatch(GET_MANAGER_TIMESHEET_RECORDS(payload))
        }
        setIsOpen(false)
    }

    return (
        <>
            <button
                onClick={open}
                className='focus:outline-0'>
                <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#228B22"><path d="M120-327.33V-394h310.67v66.67H120Zm0-163.34v-66.66h475.33v66.66H120ZM120-654v-66.67h475.33V-654H120Zm532.67 453.33-138-138L561.33-386l91.34 90 180-180L880-428 652.67-200.67Z" /></svg>
            </button>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={open}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <p className="mb-6 text-sm/6 text-primary">
                                        Are you sure you want to approve all timesheets?
                                    </p>
                                    <div className="mt-4 flex justify-end space-x-2">

                                        <button
                                            className="inline-flex items-center gap-2 rounded-md text-primary py-1.5 px-3 text-sm/6 border border-primary shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                            onClick={close}
                                        >
                                            No
                                        </button>

                                        <button
                                            className="inline-flex items-center gap-2 rounded-md bg-primary py-1.5 px-3 text-sm/6 text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                            onClick={handleApproveAll}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
