export const getDefaultExpensePayload = (
    Projectid,
    Limit = 10,
    Page = 1,
    Sorttype = 3,
) => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        Projectid,
        Companyid: 0,
        Overheadid: 0,
        Monthid: new Date().getMonth() + 1,
        Yearid: new Date().getFullYear(),
        Limit,
        Page,
        Sorttype,
        Searchname: '',
        Costcentercode: '',
    }
}

export const getExpenseOverheadPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        overheadmasterid: 0,
        sorttype: 3,
        searchname: '',
        status: 'ACTIVE',
        costtype: 'I',
    }
}

export const getTenantPayload = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
    }
}
