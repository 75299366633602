
import { postMethodApiCall } from '../../../Services/api'
import { ACTION_RESET_PASSWORD } from 'src/Redux/types'

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
}


// get tenant code by subdomain name
export const GET_TENANT_CODE = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/gettenantdetailsforaTenantcode`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, data: res?.data?.data[0] }
            }
            return { success: false }
        } catch (error) {
            console.log((error.response.data).replace(/\\/g, ""))
        }
    }
}

// login user by email and password
export const USER_LOGIN_HANDLE = payload => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/login`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.data && res?.status === 200) {
                dispatch({ type: 'USER_LOGIN_HANDLE', data: res?.data?.data })
                return { success: true, data: res.data?.data, token:res?.data?.token }
            }
            return { success: false }
        } catch (error) {
            console.log(error)
            // console.log((error.response.data).replace(/\\/g, ""))
        }
    }
}
// 
export const USER_LOGOUT_HANDLE = () => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/User/logout`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), {})
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, data: null }
            }
            return { success: false }
        } catch (error) {
            console.log((error.response.data).replace(/\\/g, ""))
        }
    }
}

// RESET PASSWORD
export const RESET_PASSWORD = payload => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/change_password`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.data && res?.status === 200) {
                dispatch({ type: ACTION_RESET_PASSWORD, data: res?.data?.data })
                return { success: true, data: res.data?.data }
            }
            return { success: false }
        } catch (error) {
            console.log(error)
            // console.log((error.response.data).replace(/\\/g, ""))
        }
    }
}

export const SEND_FORGOT_PASSWORD_LINK = payload => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/Forgot_password_token_generation`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.data && res?.status === 200) {
                return { success: true, data: res.data }
            }
            return { success: false }
        } catch (error) {
            console.log(error)
            // console.log((error.response.data).replace(/\\/g, ""))
        }
    }
}

// login user by email and password
export const CREATE_NEW_PASSWORD = payload => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/Forget_password_Newpassword_generation`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.data && res?.status === 200) {
                return { success: true, data: res.data }
            }
            return { success: false }
        } catch (error) {
            console.log(error)
            // console.log((error.response.data).replace(/\\/g, ""))
        }
    }
}