import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

const Cards = ({ item, id }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const AppliedFilters = params.get('AppliedFilters');

    const getRequestTypeLabel = (requesttype) => {
        switch (requesttype) {
            case 'O':
                return 'Opportunity';
            case 'P':
                return 'Project';
            case 'I':
                return 'Internal';
            default:
                return 'Unknown';
        }
    };

    const requestTypeLabel = getRequestTypeLabel(item?.requesttype);
    let requestName =
        requestTypeLabel === 'Opportunity'
            ? item?.opportunityname
            : requestTypeLabel === 'Project'
                ? item?.projectname
                : requestTypeLabel === 'Internal'
                    ? item?.department
                    : '';

    return (
        <Link
            className="bg-white rounded-lg shadow"
            id={`demand-${id}`}
            to={`/app/workforce/demand/detail?demandid=${id}&AppliedFilters=${AppliedFilters}`}
        >
            <div className="relative flex justify-between">
                <div className="flex items-center justify-center my-2 ml-4 text-xs">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-4 h-4 text-red-500"
                    >
                        <path
                            fillRule="evenodd"
                            d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <p className="ml-1 text-gray-500">{item?.cityname}</p>
                </div>
                {item?.statusname && (
                    <button className="inline-flex px-2 py-1 mx-2 text-sm font-semibold text-green-600 bg-green-200 rounded-b-lg">
                        {item?.statusname}
                    </button>
                )}
            </div>
            <div className="w-full px-4 pb-4">
                <div className="flex items-center justify-start py-1 mb-2 mr-2">
                    <div>
                        <p className="text-xl font-medium text-gray-900 break-words title-font">
                            {item?.jobtitle} ({item?.demandcode})
                        </p>
                        <p className="text-xs text-gray-500 capitalize">
                            Company: {item?.companyname}
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-2 pb-2 text-sm text-gray-500 capitalize">
                    <div className="mr-2">
                        <p className="break-words">
                            <span className="text-gray-700">Request Type:</span>{' '}
                            {requestTypeLabel}
                        </p>
                    </div>
                    <div className="mr-2">
                        <p className="break-words">
                            {' '}
                            <span className="text-gray-700">
                                {requestTypeLabel === 'Internal' ? 'Department' : requestTypeLabel}:{' '}
                            </span>{' '}
                            {requestName}{' '}
                        </p>
                    </div>
                    <div className="mr-2">
                        <p className="break-words">
                            <span className="text-gray-700">Business Unit:</span>{' '}
                            {item?.businessunitname}{' '}
                        </p>
                    </div>
                    <div className="mr-2">
                        <p className="break-words">
                            {' '}
                            <span className="text-gray-700">Service Line:</span>{' '}
                            {item?.servicelinename}
                        </p>
                    </div>

                    <div className="mr-2">
                        <p className="break-words">
                            <span className="text-gray-700">Requested Date:</span>{' '}
                            {moment(item?.requesteddate).format('DD-MM-YYYY ')}
                        </p>
                    </div>
                    <div className="mr-2">
                        <p className="break-words">
                            <span className="text-gray-700">Start Date:</span>{' '}
                            {item?.startdate}
                        </p>
                    </div>
                    <div className="mr-2">
                        <p className="break-words">
                            <span className="text-gray-700">Assigned To:</span>
                        </p>
                    </div>
                    <div className="mr-2">
                        <p className="break-words">
                            <span className="text-gray-700">Requested By: </span>
                            {item?.requestorname}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Cards;
