import { memo, useState, useEffect, useMemo } from 'react';
import { useSelector, } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { validationTime, yesNoWithboolean } from 'src/Services/constant/staticValues';
import { AlertError } from 'src/Services';

const templateType = [
   { name: 'Project', templatetype: "P" },
   { name: 'Generic', templatetype: 'G' },
];

const Details = (props) => {
   const { companylist } = useSelector((state) => state.EmployeeDetails);
   const { templateList } = useSelector((state) => state.ConfigurationList);
   const { detailSave, detailsData, setDetailsData } = props;
   const [checkvalidation, setCheckvalidation] = useState(false);
   const [isDefault, setIsDefault] = useState(false);
   const [filteredTemplates, setFilteredTemplates] = useState([]);
   const [load, setLoad] = useState(false)

   // Filter the templateList whenever detailsData, companyid, or templatetype changes
   useEffect(() => {
      if (templateList?.length > 0) {
         if (detailsData.companyid && detailsData.templatetype) {
            const filteredData = templateList?.filter(template => (
               template.companyid === detailsData.companyid &&
               template.templatetype === detailsData.templatetype &&
               template.isdefault === true
            ));
            setFilteredTemplates(filteredData);
            // CODE FOR  FUTURE USE
            // const templateIds = filteredData.map(template => template?.timetemplatemasterid);
            //  console.log("Template IDs:", templateIds);
         }
      }
   }, [detailsData, templateList, isDefault]);

   const handleSubmit = async () => {
      setLoad(true)
      setCheckvalidation(true);

      let item = { ...detailsData };
      item['minhours'] = detailsData['minhours'] || null
      item['maxhours'] = detailsData['maxhours'] || null


      let notReqField = ['notes', 'timetemplatemasterid']
      if (item?.templatetype === 'G') {
         notReqField.push('allocationrestriction')
      } if (item?.validationunit === -1) {
         notReqField.push('maxhours')
         notReqField.push('minhours')
      } if (detailsData?.allocationrestriction !== true) {
         notReqField.push('validationunit')
      }

      // console.log(item?.minhours , item?.maxhours)
      if (parseFloat(item?.minhours) > parseFloat(item?.maxhours)) {
         setLoad(false)
         AlertError('Can not add min hours more than max hours')
         return
      }

      let isValidated = await CheckObjectValidation(item, notReqField);
      if (isValidated?.isvalid) {
         let updatedFilteredTemplates = filteredTemplates; // Initialize with current filteredTemplates
         if (isDefault) {
            item.isdefault = true;
            updatedFilteredTemplates = filteredTemplates.map(template => ({
               ...template,
               isdefault: template.timetemplatemasterid === item.timetemplatemasterid,
            }));
         }
         let payload = {
            timetemplatemasterjson: [item, ...updatedFilteredTemplates],
         };
         await detailSave(payload);
      }
      setLoad(false)
   };

   const handleChangeInput = (name, value) => {
      setCheckvalidation(false);
      if (name === 'isdefault') {
         setIsDefault(value);
         if (filteredTemplates.length > 0) {
            const updatedFilteredTemplates = filteredTemplates?.map(template => ({
               ...template,
               isdefault: false,
            }));
            setFilteredTemplates(updatedFilteredTemplates);
         }
      } else if (name === 'allocationrestriction') {
         setDetailsData(prevDetailsData => ({
            ...prevDetailsData,
            [name]: value,
            validationunit: value ? 1 : -1,
            maxhours: 0,
            minhours: 0,
         }));
      } else if (name === 'templatetype') {
         setDetailsData(prevDetailsData => ({
            ...prevDetailsData,
            [name]: value,
            allocationrestriction: value === 'G' ? false : null
         }));
      } else if (name === 'validationunit') {
         setDetailsData(prevDetailsData => ({
            ...prevDetailsData,
            [name]: value,
            maxhours: null,
            minhours: null,
         }));
      } else {
         setDetailsData(prevDetailsData => ({
            ...prevDetailsData,
            [name]: value,
         }));
      }
   };



   const validationTimeOption = useMemo(() => {
      if (detailsData?.allocationrestriction) {
         return validationTime?.filter(r => r?.name !== 'None')
      } return validationTime
   }, [detailsData?.allocationrestriction])

   return (
      <div className='w-full h-[65vh] text-sm mb-10'>
         <div className='grid grid-cols-4 gap-10 mt-4'>
            <div>
               <label htmlFor='companyname' className='block font-[500] mb-2'>
                  Company
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={companylist || []}
                  value={detailsData?.companyid || ''}
                  noDataLabel='No Company'
                  name='companyid'
                  labelkey='companyname'
                  className='py-2 border rounded bg-gray-50'
                  onChange={handleChangeInput}
                  id='companyid'
               />
               {/* {console.log(detailsData?.companyid)} */}
               {!detailsData?.companyid && checkvalidation && (
                  <small className='text-red-400'>Company is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='templatename' className='block font-[500] mb-2'>
                  Time Template
                  <span className="text-red-300"> *</span>
               </label>
               <input
                  type='text'
                  title='templatename'
                  id='timetemplatemasterid'
                  className='block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50'
                  placeholder=' Enter Time Template'
                  maxLength={50}
                  onChange={(e) => {
                     if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                        handleChangeInput('templatename', e.target.value);
                     }
                  }}
               />
               {!detailsData?.templatename && checkvalidation && (
                  <small className='text-red-400'>Time Template is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='templatetype' className='block font-[500] mb-2'>
                  Template Type
                  <span className="text-red-300"> *</span>
               </label>
               <CustomhtmlSelect
                  options={templateType || []}
                  value={detailsData?.templatetype}
                  noDataLabel='No costtype found'
                  name='templatetype'
                  labelkey='name'
                  className='block w-full p-2 text-gray-600 border rounded-md outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
                  onChange={handleChangeInput}
                  id='templatetype'
               />
               {!detailsData?.templatetype && checkvalidation && (
                  <small className='text-red-400'>Template Type is required!</small>
               )}
            </div>
            <div className='flex items-center mt-2'>
               <label htmlFor='isdefault' className='py-4 block font-[500] mr-4 mt-2'>
                  Is Default
               </label>
               <input
                  type='checkbox'
                  id="isdefault"
                  name="isdefault"
                  checked={isDefault}
                  onChange={() => handleChangeInput('isdefault', !isDefault)}
                  className="mt-2 mr-120"
               />
            </div>
            <div>
               <label htmlFor='allocationrestriction' className='mb-2 block font-[500]'>
                  Restrict By Allocation
                  {detailsData?.templatetype === 'P' && <span className="text-red-300"> *</span>}
               </label>
               <CustomhtmlSelect
                  disabled={detailsData?.templatetype === 'G'}
                  options={yesNoWithboolean} value={detailsData?.allocationrestriction}
                  name='allocationrestriction'
                  labelkey='name'
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded focus:ring-primary-600 focus:border-primary-600 block w-full py-2'
                  onChange={handleChangeInput}
                  id='value'
               />
               {!detailsData?.templatetype && checkvalidation && (
                  <small className='text-red-400'>Restrict By Allocation is required!</small>
               )}

            </div>
            <div>
               <label htmlFor='validationunit' className='block font-[500] mb-2'>
                  Validate Time
                  {detailsData?.allocationrestriction && <span className="text-red-300"> *</span>}
               </label>
               <CustomhtmlSelect
                  options={validationTimeOption || []}
                  value={detailsData?.validationunit}
                  noDataLabel='No Validation Time'
                  name='validationunit'
                  labelkey='name'
                  className='border rounded bg-gray-50 py-2'
                  onChange={handleChangeInput}
                  id='validationunit'
               />
               {!detailsData?.validationunit && checkvalidation && (
                  <small className='text-red-400'>Time Type is required!</small>
               )}
            </div>
            <div>
               <label htmlFor='validationunit' className='block font-[500] mb-2'>
                  Min Hours
                  {detailsData?.validationunit !== -1 && <span className="text-red-300"> *</span>}
               </label>
               <input
                  name='minhours'
                  disabled={detailsData?.validationunit === -1}
                  value={detailsData?.minhours || ''}
                  onChange={(e) => {
                     if (/^[0-9]*$/.test(e.target?.value)) {
                        handleChangeInput('minhours', e.target.value)
                     }
                  }}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
               />
               {(detailsData?.validationunit !== -1 && !detailsData?.minhours) && checkvalidation && (
                  <small className='text-red-400'>Min hours is required!</small>
               )}
            </div>

            <div>
               <label htmlFor='validationunit' className='block font-[500] mb-2'>
                  Max Hours
                  {detailsData?.validationunit !== -1 && <span className="text-red-300"> *</span>}
               </label>
               <input
                  name='maxhours'
                  disabled={detailsData?.validationunit === -1}
                  value={detailsData?.maxhours || ''}
                  onChange={(e) => {
                     if (/^[0-9]*$/.test(e.target?.value)) {
                        handleChangeInput('maxhours', e.target.value)
                     }
                  }}
                  className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
               />
               {(detailsData?.validationunit !== -1 && !detailsData?.maxhours) && checkvalidation && (
                  <small className='text-red-400'>Max hours is required!</small>
               )}
            </div>

            <div className='fixed bottom-0 mt-2 right-6'>
               <div className='flex items-center justify-end gap-2'>
                  <PrimaryButton
                     disabled={load}
                     className='px-8 py-2 mb-4 text-white rounded-lg bg-primary'
                     onClick={handleSubmit}
                  >
                     {load ? 'Processing...' : 'Save'}
                  </PrimaryButton>
               </div>
            </div>
         </div>
      </div>
   );
};

export default memo(Details);
