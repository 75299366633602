import { Link, useLocation } from "react-router-dom"

const Actionheader = ({ filterTab, demandData }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search.split('?')[1])
  const AppliedFilters = searchParams.get('AppliedFilters')

  return (
    <div className="flex items-center justify-between">
      <div className="flex justify-start items-center space-x-2 pb-2 text-sm sm:text-[10px] lg:text-sm">
        <Link to={`/app/workforce/demand?AppliedFilters=${AppliedFilters}`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
          </svg>
        </Link>
        <h1 className="font-[500] text-primary">{demandData[0]?.jobtitle} {demandData[0]?.demandcode}</h1>
        <h1 className="pl-1 text-primary-600">| {demandData[0]?.companyname} |</h1>
        <div className='flex items-center justify-start ml-1'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className='w-3 h-3 text-red-500'
          >
            {' '}
            <path
              fillRule='evenodd'
              d='M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z'
              clipRule='evenodd'
            />{' '}
          </svg>
          <h1 className="pl-1 text-primary-600"> {demandData[0]?.cityname}</h1>
        </div>
        <p className="text-green-600 font-[500] pl-1"> | {demandData[0]?.demandstatusname}</p>
      </div>
      {filterTab === '' ? (
        <div className="flex items-center justify-end space-x-2 rounded-lg">
          <div className="flex items-center justify-end">
          </div>
        </div>
      ) : (
        <div className="h-12" />
      )}
    </div>
  )
}

export default Actionheader
