import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE_NEW_PASSWORD } from "src/Redux/actions/auth";
import { AlertSuccess } from "src/Services";

const CreateNewPassword = () => {
    const dispatch = useDispatch()
    const { userid, createpasswordKey } = useParams()
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [error, setError] = useState(false)

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            setError(true)
            return
        }
        setError(false)
        let payload = {
            Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            comuserid: userid,
            forgot_password_token: createpasswordKey,
            new_password: newPassword,
            re_password: confirmPassword
        }
        let res = await dispatch(CREATE_NEW_PASSWORD(payload))
        if (res?.success) {
            AlertSuccess('Password updated successfully!', '')
            const debounceTimer = setTimeout(() => {
                navigate('/app/login')
            }, 1500)
            return () => {
                clearTimeout(debounceTimer)
            }

        }
    }

    const handleChange = (e) => {
        let { value } = e.target;
        const debounceTimer = setTimeout(() => {
            setNewPassword(value)
            if (newPassword === confirmPassword) {
                setError(false)
            }
        }, 1500)

        return () => {
            clearTimeout(debounceTimer)
        }
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <div className="w-[330px] h-52 relative flex flex-col items-center justify-center">
                    <h1 className="text-primary font-[500] absolute top-0 ">Welcome to ProSrv360</h1>
                    <img
                        width={200}
                        height={200}
                        src={process.env.REACT_APP_LOGIN_LOGO}
                        alt="login"
                    />
                </div>
                <div className="w-[430px] p-6 space-y-2 sm:p-8">
                    <div
                        className="space-y-4 md:space-y-6">
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-[500] text-textprimary">Enter New Password</label>
                            <input onChange={handleChange}
                                type={"password"}
                                name="password" id="password"
                                autoComplete="true"
                                placeholder="••••••••"
                                className='shadow-md backdrop-blur-[5px] focus:outline-none bg-white/70 border border-gray-300 sm:text-sm rounded-md focus:ring-0 focus:border-0 block w-full p-2' />
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-[500] text-textprimary">Confirm New Password</label>
                            <input onChange={(e) => { setconfirmPassword(e.target.value) }}
                                type={"password"}
                                name="password" id="password"
                                autoComplete="true"
                                placeholder="••••••••"
                                className='shadow-md backdrop-blur-[5px] focus:outline-none bg-white/70 border border-gray-300 sm:text-sm rounded-md focus:ring-0 focus:border-0 block w-full p-2' />
                            {error && <small className="text-red-500">Password mismatch !</small>}
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={handleSubmit}
                                // disabled={confirmPassword?.length > 0}
                                type="submit"
                                className="w-[70%] text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-[500] rounded-lg text-sm px-5 py-2 text-center">
                                Create New Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateNewPassword;