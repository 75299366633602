import { memo, useEffect, useState } from "react";

const FilterButtonTabObject = ({ selected, tabs, labelkey, name, onClick, valuekey, className = 'py-1.5 border px-2', disabled=false  }) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        let copyvalue = tabs
        const newData = copyvalue?.map((item, i) => {
            let words = item[labelkey]?.split(' ');
            if (words?.length !== 1) {
                let stringis = ''
                for (let char of words) {
                    stringis = stringis + `${char[0]}`
                }
                item[labelkey] = stringis
            }
            return item

        })
        setOptions(newData)
        // eslint-disable-next-line
    }, [tabs])

    return (
        <div className="rounded-md flex justify-start">
            {
                options?.map((item, i) => {
                    return (
                        <button disabled={disabled} title={tabs[i][labelkey]} key={item[valuekey]} onClick={() => { onClick(name, item[valuekey]) }}
                            className={`${i === tabs?.length - 1 ? 'rounded-r-md' : ''} ${i === 0 ? 'rounded-l-md' : ''} ${item[valuekey] === selected ? 'bg-secondary/10 text-secondary font-semibold' : 'text-gray-600 bg-white'} px-2 text-sm w-full ${className}`}>
                            {item[labelkey]}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default memo(FilterButtonTabObject);