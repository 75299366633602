import { memo, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';


const CompanyDetails = (props) => {
  const [city, setCity] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const { cityList } = useSelector((state) => state.UserManagementList);
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  // console.log(city)
  const getTodayDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()
    // Ensure month and day have two digits
    month = month < 10 ? `0${month}` : month
    day = day < 10 ? `0${day}` : day
    return `${year}-${month}-${day}`
  }

  useMemo(() => {
    if (city?.cityname) {
      setSearchTerm(city?.cityname)
    }
  }, [city])

  useEffect(() => {
    if (detailsData?.cityname && detailsData?.comcityid) {
      setCity({ cityname: detailsData.cityname, comcityid: detailsData.comcityid });
    }
  }, [detailsData?.cityname, detailsData?.comcityid]);

  const handleSubmit = () => {
    setCheckvalidation(true);
    let items = {
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      address_street: detailsData?.address_street,
      address3: detailsData?.address3,
      address_pobox: detailsData?.address_pobox,
      pincode: detailsData?.pincode,
      phoneno: detailsData?.phoneno,
      comcityid: city?.comcityid,
      comstateid: detailsData?.comstateid,
      region: detailsData?.region,
      comcountryid: detailsData?.comcountryid,
      mobile1: detailsData?.mobile1,
      fax: detailsData?.fax,
      companyname: detailsData?.companyname,
      parentcompanyname: detailsData?.parentcompanyname,
      shortname: detailsData?.shortname,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: detailsData?.createdcomuserid,
      isdeleteflag: detailsData?.isdeleteflag,
      deletedtimestamp: detailsData?.deletedtimestamp,
      deletedcomuserid: detailsData?.deletedcomuserid,
      defaultcomcurrencyid: detailsData?.defaultcomcurrencyid,
      notes: detailsData?.notes,
      companyid: detailsData?.companyid,
      companycode: detailsData?.companycode,
      incorporationdate: detailsData?.incorporationdate
    };

    let isValidated = CheckObjectValidation(items, [
      'notes',
      'parentcompanyname',
      'shortname',
      'pincode',
      'companycode',
      // 'createdcomuserid',
      'address_pobox',
      'mobile1',
      'isdeleteflag',
      'modifiedtimestamp',
      'modifiedcomuserid',
      'deletedtimestamp',
      'deletedcomuserid',
      'createdtimestamp',
      'region',
      // 'fax',
      // 'pincode',
      'comcountryid',
      'countryname',
      'comstateid',
      'statename',
      'cityname',
      'fax',
    ]);
    if (isValidated?.isvalid) {
      let payload = {
        companyjson: [items],
      };
      delete ['comstateid'];
      delete ['statename'];
      // delete ['cityname']
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='companycode' className='block font-[500] mb-2'>
            Company Code
          </label>
          <input
            disabled
            type='text'
            title='companycode'
            id='companycode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Company Code'
            required=''
            value={detailsData?.companycode}
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('companycode', e.target.value);
              }
            }}
          />
        </div>
        <div>
          <label htmlFor='companyname' className='block font-[500] mb-2'>
            Company Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            maxLength={50}
            disabled={canEdit}
            type='text'
            title='companyname'
            id='companyid'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Company Name'
            required=''
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('companyname', e.target.value);
              }
            }}
            value={detailsData?.companyname || ''}
          />
          {!detailsData?.companyname && checkvalidation && (
            <small className='text-red-400'>Company Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='incorporationdate' className='block font-[500] mb-2'>
            Incorporation Date
            <span className="text-red-300"> *</span>
          </label>
          <FlatPickrDateInput
            disabled={(canEdit || detailsData?.incorporationdate > 'max')}
            max={getTodayDate()}
            name='incorporationdate'
            value={detailsData?.incorporationdate || ''}
            id='incorporationdate'
            className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-2'
            onChangeDate={e => handleChangeInput('incorporationdate', e.target.value)}
          />
          {!detailsData?.incorporationdate && checkvalidation && (
            <small className='text-red-400'>Incorporation Date is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='address3' className='block font-[500] mb-2'>
            Address Line 1<span className='text-red-300'> *</span>
          </label>
          <input
            maxLength={50}
            disabled={canEdit}
            type='text'
            title='address3'
            id='address3'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Address Line 1'
            required=''
            value={detailsData?.address3 || ''}
            onChange={(e) => handleChangeInput('address3', e.target.value)}
          />
          {!detailsData?.address3 && checkvalidation && (
            <small className='text-red-400'>Address is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='address_street' className='block font-[500] mb-2'>
            Address Line 2<span className='text-red-300'> *</span>
          </label>
          <input
            maxLength={50}
            disabled={canEdit}
            type='text'
            title='address_street'
            id='address_street'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Address Street'
            required=''
            value={detailsData?.address_street || ''}
            onChange={(e) =>
            handleChangeInput('address_street', e.target.value)
            }
          />
        </div>
        <div>
          <label htmlFor='comcityid' className='block font-[500] mb-2'>
            City
            <span className="text-red-300"> *</span>
          </label>
          <div>
            <CustomAutoSearch
              disabled={canEdit}
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_CITY_MASTER}
              searchPayload={{ ...getMasterCityPayload(), searchname: searchTerm, }}
              setSearchTerm={setSearchTerm}
              options={cityList}
              idkey='cityname'
              optionlabelKey='cityname'
              getSelectedOption={setCity}
              placeholder='Enter City Name'
              className='bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
            />
          </div>
          {!city?.comcityid && checkvalidation && (
            <small className='text-red-400'>City is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='pincode' className='block font-[500] mb-2'>
            Pincode
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='pincode'
            id='pincode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Pincode'
            required=''
            value={detailsData?.pincode || ''}
            onChange={(e) => handleChangeInput('pincode', e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='phoneno' className='block font-[500] mb-2'>
            Phone
            <span className='text-red-300'> *</span>
          </label>
          <input
            disabled={canEdit}
            type='tel'
            title='phoneno'
            id='phoneno'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Phone No'
            required=''
            value={detailsData?.phoneno || ''}
            onChange={(e) => handleChangeInput('phoneno', e.target.value)}
          />
          {!detailsData?.phoneno && checkvalidation && (
            <small className='text-red-400'>Phone is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='fax' className='block font-[500] mb-2'>
            Fax
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='fax'
            id='fax'
            className='outline-gray-50 bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='Fax'
            required=''
            value={detailsData?.fax || ''}
            onChange={(e) => handleChangeInput('fax', e.target.value)}
          />
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CompanyDetails);
