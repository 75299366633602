import { memo, useState } from 'react';

const Details = ({ hooks }) => {
  const { detailsData, setDetailsData, checkvalidation, setCheckvalidation, canEdit } = hooks;
  const [isSystemrole, setIssystemrole] = useState(detailsData?.issystemrole);
  // console.log("dd", detailsData)
  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    if (name === 'issystemrole') {
      setIssystemrole(value);
    }
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className="w-full text-sm my-4">
      <div className="grid grid-cols-4 gap-10 mt-4">
        <div>
          <label htmlFor="rolename" className="block font-[500] mb-2">
            Role Name <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={!canEdit}
            maxLength={50}
            type="text"
            title="rolename"
            id="rolename"
            className="outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2"
            placeholder="Enter Rolename"
            required=""
            value={detailsData?.rolename || ""}
            onChange={(e) => handleChangeInput("rolename", e.target.value)}
          />
          {!detailsData?.rolename && checkvalidation && (
            <small className="text-red-400">Role Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor="notes" className="block font-[500] mb-2">
            Role Description <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={!canEdit}
            maxLength={50}
            type="text"
            title="notes"
            id="notes"
            className="outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2"
            placeholder="Role Description"
            required=""
            value={detailsData?.notes || ""}
            onChange={(e) => handleChangeInput("notes", e.target.value)}
          />
          {!detailsData?.notes && checkvalidation && (
            <small className="text-red-400">Role Description is required!</small>
          )}
        </div>
        <div>
          <label htmlFor="code" className="block font-[500] mb-2">
            Code <span className="text-red-300"> *</span>
          </label>
          <input
            maxLength={50}
            type="text"
            title="code"
            disabled={isSystemrole}
            id="code"
            className="outline-gray-50 capitalize bg-gray-50 text-gray-600 border rounded-md block w-full p-2"
            placeholder="Code"
            required=""
            value={detailsData?.code || ""}
            onChange={(e) => handleChangeInput("code", e.target.value)}
            // onChange={(e) => handleChangeInput("code", e.target.value)}
          />
          {/* {!detailsData?.code && checkvalidation && (
            <small className="text-red-400">Code is required!</small>
          )} */}
        </div>
        <div className='flex items-center mt-2'>
          <label htmlFor='issystemrole' className='py-4 block font-[500] mr-4 mt-2'>
            System Role
          </label>
          <input
            readOnly
            type='checkbox'
            id="issystemrole"
            name="issystemrole"
            checked={isSystemrole ? 'checked' : !'checked'} // Checked if isSystemrole is true, unchecked otherwise
            className="mt-2 mr-120"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Details);

