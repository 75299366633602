export const TechnologyItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    technologymasterid: null,
    technology: "",
    techgroup: 0,
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}


export const TechGroupItem = () => {
  return {
    techgroupid: null,
    techarea: "",
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}
export const ProductItem = () => {
  return {
    productmasterid: null,
    productname: "",
    technology: 0,
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}


export const detailNotRequired = [
  "notes",
  "productmasterid",
  "technologymasterid",
  "techgroup",
  "techgroupid"
];
