
import FilterButtonTabObject from "src/Components/FilterButtonTabObject"

const BillingType = ({listOfBillingProjects, value, handleSelectTab}) => {
    return (
        <>
            <h1 className="block font-[500] mb-2">Billing Type</h1>
            <FilterButtonTabObject
            disabled={true}
                className='border p-2'
                tabs={listOfBillingProjects || []}
                selected={value}
                labelkey='billingtype'
                name='datprojectbillingtypeid'
                valuekey='datprojectbillingtypeid'
                onClick={handleSelectTab}
            />
        </>
    )
}

export default BillingType;