import IconButton from 'src/Components/Buttons/IconButton';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import ScrollToBottom from 'react-scroll-to-bottom';
import { useSelector } from 'react-redux';
import RoleLevel from './level';
import SkillGroup from './skillgroup';
import Technology from './technology';
import Location from './Location';
import Currency from './currency';
import { RoleItem } from '../defaultRowAndData';
import { CheckListObjectValidation } from 'src/Services/constant/customvalidation';
import { useState, useEffect } from 'react';
import Band from './band';
import { AlertError } from 'src/Services';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

const isValidatedField = (checkvalidation, index, keyname) => {
  return (
    checkvalidation?.keyname === keyname && checkvalidation?.rowNo === index + 1
  );
};

const RoleDetails = ({ handleSubmit, formData, setFormData, detailsData }) => {
  const { bandList } = useSelector((state) => state?.EmployeeDetails);
  const { technologyGroup, countryList } = useSelector((state) => state?.UserManagementList);
  const { proficiencylist, skillList } = useSelector((state) => state?.EmployeeProfile);
  const currencyList = useSelector((state) => state?.Projects);
  const [checkvalidation, setcheckvalidation] = useState(null);

  const SelectHandleChange = (e, uId) => {
    let { name, value } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        item[name] = value;
      }
      return item;
    });
    setFormData(afterChange);
  };



  const selectBandMax = (e, uId) => {
    let { name, value, itemInfo } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        let minitem = bandList?.find(b => b?.bandmasterid === item['min_bandid'])
        if (itemInfo?.slno >= minitem?.slno || item['min_bandid'] === null) {
          item[name] = value;
        } else {
          AlertError('Max band must be greater than min band')
        }
      }
      return item;
    });
    setFormData(afterChange);
  };

  const selectBandMin = (e, uId) => {
    let { name, value, itemInfo } = e.target;
    let afterChange = formData?.map((item) => {
      if (item?.uId === uId) {
        let maxitem = bandList?.find(b => b?.bandmasterid === item['max_bandid'])
        if (itemInfo?.slno <= maxitem?.slno || item['max_bandid'] === null) {
          item[name] = value;
        } else {
          AlertError('Min band must be smaller than max band')
        }
      }
      return item;
    });
    setFormData(afterChange);
  };


  // Update the currency ID of the first row with the default currency of detailsData
  useEffect(() => {
    if (detailsData) {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        if (updatedFormData.length > 0) {
          updatedFormData[0].comcurrencyid = detailsData.defaultcomcurrencyid;
        }
        return updatedFormData;
      });
    }
  }, [detailsData, setFormData]);

  const handleAdd = () => {
    setFormData((prevFormData) => [
      ...prevFormData,
      { ...RoleItem(), comcurrencyid: detailsData?.defaultcomcurrencyid || 0 },
    ]);
  };

  const handleDelete = (uId) => {
    setFormData((prevFormData) =>
      prevFormData.filter((item) => item?.uId !== uId)
    );
  };

  const saveRoleInfo = () => {
    let isValidated = CheckListObjectValidation(formData, [
      'notes',
      'locationid',
      'croleid',
      'isonsite',
      'RateUnit',
      'customerid',
      'techarea',
      'skillarea',
      'countryname',
      'max_band', 'min_band',
      'defaultcomcurrencyid',
      'deliveryroleid'
    ]);
    if (isValidated?.isvalid) {
      handleSubmit();
    } else {
      setcheckvalidation(isValidated);
    }
  };
  return (
    <div className='my-2 text-sm'>
      <div className='w-full'>
        <ScrollToBottom className='h-[60vh]'>
          <table className='w-full relative text-left'>
            <thead className='capitalize bg-gray-50 h-[1vh]'>
              <tr className='text-left capitalize bg-gray-50'>
                <th className='border font-[500] p-1 '>
                  Customer Role <span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Level<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Min band<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Max band<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Skill Area<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Tech Group<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Country<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Bill Rate<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Currency<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] p-1'>
                  Unit<span className='text-red-300'>*</span>
                </th>
                <th className='border font-[500] py-2 px-2'>Action</th>
              </tr>
            </thead>
            <tbody>
              {formData?.map((item, i) => {
                return (
                  <tr key={item.uId} className='text-left px-1'>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'rolename')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <input value={item?.rolename || ''}
                        name='rolename'
                        onChange={(e) => SelectHandleChange(e, item?.uId)}
                        className='w-full focus:outline-none ' placeholder='Customer' />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'levelid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <RoleLevel
                        value={item?.levelid}
                        rowId={item?.uId}
                        handleChange={SelectHandleChange}
                        options={proficiencylist}
                      />
                    </td>

                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'min_bandid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Band
                        name='min_bandid'
                        value={item?.min_bandid}
                        rowId={item?.uId}
                        handleChange={selectBandMin}
                        options={bandList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'max_bandid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Band
                        name='max_bandid'
                        value={item?.max_bandid}
                        rowId={item?.uId}
                        handleChange={selectBandMax}
                        options={bandList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'skillid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <SkillGroup
                        // disabled={canEdit}
                        value={item?.skillareamasterid}
                        rowId={item?.uId}
                        handleChange={SelectHandleChange}
                        options={skillList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'techid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Technology
                        // disabled={canEdit}
                        value={item?.techgroupid}
                        rowId={item?.uId}
                        handleChange={SelectHandleChange}
                        options={technologyGroup}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'countryid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <Location
                        value={item?.countryid}
                        // disabled={canEdit}
                        rowId={item?.uId}
                        handleChange={SelectHandleChange}
                        options={countryList}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'bill_hr')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      <input
                        className='text-gray-600  w-14 border-none rounded-md border focus:outline-none hover:outline-none'
                        name='bill_hr'
                        type='number'
                        value={item?.bill_hr || ''}
                        onChange={(e) => SelectHandleChange(e, item?.uId)}
                      // notvalidated={notvalidated}
                      />
                    </td>
                    <td
                      className={` ${isValidatedField(checkvalidation, i, 'comcurrencyid')
                        ? 'border-2 border-red-400 '
                        : 'border'
                        } px-1`}
                    >
                      {detailsData && currencyList?.length > 0 && (
                        <Currency
                          disabled={true}
                          value={
                            i === 0 &&
                              (item.comcurrencyid === null ||
                                item.comcurrencyid === undefined)
                              ? detailsData.defaultcomcurrencyid
                              : item.comcurrencyid
                          }
                          rowId={item?.uId}
                          handleChange={SelectHandleChange}
                          options={currencyList}
                        />
                      )}
                    </td>
                    <td className='w-32 p-1 border'>
                      <select
                        name='rateunit'
                        value={item?.rateunit}
                        onChange={(e) => SelectHandleChange(e, item?.uId)}
                        className='block w-full p-1 text-sm outline-none disabled:text-black focus:ring-0 focus:outline-none focus:border-0'
                      >
                        <option value='Select'>Select</option>
                        <option value='1'>Monthly</option>
                        <option value='2'>Weekly</option>
                        <option value='3'>Daily</option>
                        <option value='4'>Hourly</option>
                      </select>
                    </td>
                    <td className='border'>
                      <div className='flex justify-start items-center px-2 w-full'>
                        {formData?.length > 1 && (
                          <IconButton
                            title='Delete'
                            onClick={() => handleDelete(item?.uId)}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <MinusIcon className="w-3" />
                          </IconButton>
                        )}
                        <span className="hidden addIconbutton-add-btn">
                          <IconButton
                            title='Add'
                            onClick={handleAdd}
                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
                          >
                            <PlusIcon className="w-3" />
                          </IconButton>
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScrollToBottom>
        <div className='fixed bottom-0 right-6 mt-2'>
          <div className='gap-2 flex justify-end items-center'>
            <PrimaryButton
              type=''
              className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
              onClick={saveRoleInfo}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleDetails;