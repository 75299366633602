import { postMethodApiCall } from 'src/Services/api'
import { AlertError } from 'src/Services'
import { ACTION_GET_TENANTS_MASTER, ACTION_GET_SUBSCRIPTIONS_MASTER, ACTION_GET_TENANT_SUBSCRIPTION_PRICING_BY_ID } from 'src/Redux/types'
const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
	return {
	'Content-Type': 'application/json',
	'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
}
}

export const SAVE_TENANTS_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_tenantmaster`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const tenantid = item?.Tenantid;
				return { success: true, tenantid };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_TENANTS_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/get_tenantmasterlist`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Tenants found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_TENANTS_MASTER,
				data: addnewkey || [],
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const GET_SUBSCRIPTIONS_MASTER = payload => async dispatch => {
	try {
		const url = `${baseUrl}/get_datsubscriptionpackage`
		const res = await postMethodApiCall(
			url,
			getHeadersWithoutAuth(),
			payload,
		)
		if (res?.status === 200 || res?.status === 201) {
			let items = res?.data?.data
			if (items === null) AlertError('No Subscription Found !')
			let addnewkey = items?.map(item => {
				item['uId'] =
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36)
				return item
			})
			dispatch({
				type: ACTION_GET_SUBSCRIPTIONS_MASTER,
				data: addnewkey || [],
			})
			return { success: true, data: addnewkey || [] }
		}
	} catch (error) {
		AlertError(error?.response?.data).replace(/\\/g, '')
	}
}

export const SAVE_SUBSCRIPTION_MASTER = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/insert_datsubscriptionpackage`
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				data,
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const subscriptionId = item?.datsubscriptionpackageid;
				return { success: true, subscriptionId };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const SAVE_SUBSCRIPTION_DETAILS = (payload) => {
	return async () => {
		try {
			const url = `${baseUrl}/insert_tenantsubscription`;
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				JSON.stringify(payload)
			)
			if (res?.status === 200 || res?.status === 201) {
				let item = res?.data?.data;
				const tenantsubscriptionid = item?.tenantsubscriptionid
				if (item?.Message === 'Success') {
				} else {
					AlertError(item?.Message || 'Something went wrong. Please try later.');
				}
				return { success: true, tenantsubscriptionid };
			}
		} catch (error) {
			AlertError(error.response.data).replace(/\\/g, '');
			return { success: false };
		}
	}
}

export const GET_TENANT_SUBSCRIPTION_PRICING_BY_ID = data => {
	return async dispatch => {
		try {
			const url = `${baseUrl}/get_tenantsubscriptionpricing`;
			const res = await postMethodApiCall(
				url,
				getHeadersWithoutAuth(),
				{
					Tenantid: data?.Tenantid,
					tenantsubscriptionid: 0
				},
			);
			if (res?.status === 200 || res?.status === 201) {
				let items = res?.data?.data;
				if (items === null) {
					AlertError('No Subscription Pricing found!');
				}
				let addnewkey = items?.map(item => {
					item['uId'] =
						Math.random().toString(36).substr(2, 9) +
						new Date().getTime().toString(36);
					return item;
				});
				dispatch({ type: ACTION_GET_TENANT_SUBSCRIPTION_PRICING_BY_ID, data: addnewkey || [] });
				return { success: true, data: addnewkey || [] };
			}
		} catch (error) {
			AlertError(error?.response?.data).replace(/\\/g, '');
		}
	};
};