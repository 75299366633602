import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ResumeBuilder = ({ hooks }) => {
	const {
		employeeSummary,
		academicQualificationList,
		certifications,
		userSkillsDetail,
		onClose,
		projectsEprofile,
		previousExperience,
	} = hooks

	const htmlElement = useRef();

	const generatePDF = (htmlElement) => {
		const pdf = new jsPDF('p', 'pt', 'a4');
		const htmlContainer = htmlElement.current;
		const options = {
			scale: 1.2, // Adjust as needed
			useCORS: true, // Enable if you're working with cross-origin images
		};

		html2canvas(htmlContainer, options).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdfWidth = pdf.internal.pageSize.getWidth();
			// const pdfHeight = pdf.internal.pageSize.getHeight();
			const imgWidth = pdfWidth;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;

			let position = 0;

			pdf.addImage(imgData, 'PNG', 30, 0, imgWidth - 60, imgHeight - 160);
			position -= imgHeight - 160; // Adjust position based on first page height
			if (Number(canvas.height) > 1400) {
				// Add a second page
				pdf.addPage();
				pdf.addImage(imgData, 'PNG', 30, position + 30, imgWidth - 60, imgHeight - 60);
			}
			let filename = `${employeeSummary[0]?.firstname}_${employeeSummary[0]?.lastname}_resume.pdf`
			pdf.save(filename);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			generatePDF(htmlElement)
			onClose()
		}, 1000)
		// eslint-disable-next-line
	}, [])

	const projectsToDisplay = () => {
		let items = projectsEprofile?.sort((a, b) => new Date(a?.startdate) - new Date(b?.startdate))
		return items?.filter((p) => p?.projecttype !== 'Bench') || []
	}

	return (
		<div className='h-full overflow-y-scroll align-middle'>
			<div className='w-[950px] px-20  bg-white' ref={htmlElement}>
				<div className='flex items-start justify-between mb-8 border-b'>
					<div className='pt-10 bg-white'>
						<p className='mb-2 text-2xl font-bold'>
							{employeeSummary[0]?.firstname} {employeeSummary[0]?.lastname}
						</p>
						<p className='mb-2 font-medium'>
							{employeeSummary[0]?.cityname} | {employeeSummary[0]?.designation} |{' '}
							{(employeeSummary[0]?.totalexperienceinmonths / 12).toFixed(1)}{' '}
							{'Years'}
						</p>
						<p className='mb-2 font-medium'>
							{employeeSummary[0]?.emailid} |
							<span className='ml-2 font-medium'>
								{employeeSummary[0]?.phoneno || employeeSummary[0]?.mobileno}
							</span>
						</p>
					</div>
					<div className='py-16'>
						<img width={200} height={200} src='/assets/login-icon.png' alt='login' />
					</div>
				</div>
				<div>
					<div className='py-1.5'>
						<p className='mb-1 text-lg font-medium'>Summary: </p>
					</div>
					<div className='flex flex-wrap py-1.5 mb-4'>
						<p>
							{employeeSummary[0]?.notes || ''}
						</p>
					</div>
				</div>
				<div>
					<div className='py-1.5'>
						<p className='mb-1 text-lg font-medium'>Skills: </p>
					</div>
					<div className='py-1.5'>
						<ul className='flex items-center justify-start mb-1 space-x-2'>
							{userSkillsDetail?.map((item, i) => {
								return (
									<li key={i}>
										<div className='flex flex-wrap items-center mb-2 space-x-2 '>
											<span>{item?.skill}</span>
											{i < userSkillsDetail?.length - 1 && <span>|</span>}
										</div>
									</li>
								)
							})}
						</ul>
					</div>

				</div>
				<div>
					<div className='py-1.5'>
						<p className='mb-1 text-lg font-medium'>Project History: </p>
					</div>
					<div className='flex flex-wrap py-1.5 mb-4'>
						<ul>
							{projectsToDisplay()?.map((item, i) => {
								let { projectname, rolename, startdate, enddate, notes } = item
								const formattedStartDate = moment(
									startdate,
									'YYYY-MM-DD',
								)?.format('MMMM YYYY')
								const formattedEndDate = moment(enddate, 'YYYY-MM-DD')?.format(
									'MMMM YYYY',
								)
								return (
									<li key={i}>
										<div className='flex items-center justify-start mb-1 space-x-2'>
											<p>
												{projectname}
											</p>
											<span>|</span>
											<p>
												{rolename}
												<span>|</span>
												{formattedStartDate} {'-'} {formattedEndDate}
											</p>
										</div>
										<p className='mt-2 mb-8 text-gray-700'> <span className='font-normal '>{notes || ''}</span></p>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
				<div>
					<div className='py-1.5'>
						<p className='mb-1 text-lg font-medium'>Work Experience: </p>
					</div>
					<div className='py-1.5 mb-4'>
						<ul className='mb-1 mr-1'>
							{previousExperience?.sort((a, b) => new Date(a?.joiningdate) - new Date(b?.releivingdate))?.map((item, i) => {
								let {
									designation,
									companyname,
									jobprofile,
									releivingdate,
									joiningdate,
									notes
								} = item

								return (
									<li key={i}>
										<div className='flex flex-wrap items-center space-x-2 '>
											<p >{designation}</p>
											<p>
												{jobprofile} <span className='px-1'>|</span> {companyname} <span className='px-1'>|</span> {moment(joiningdate).format('MMMM YYYY')}{' '}
												{'-'} {moment(releivingdate).format('MMMM YYYY')}
											</p>
										</div>
										<p className='mt-2 mb-8 font-medium text-gray-700'><span className='font-normal '>{notes || ''}</span></p>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
				<div>
					<div className='py-1.5'>
						<p className='mb-1 text-lg font-medium'>Education: </p>
					</div>
					<div className='py-1.5'>
						<ul className='mb-1'>
							{academicQualificationList?.map((item, i) => {
								let {
									university_institute,
									yearofpassing,
									coursemaster,
									specialisation,
								} = item
								return (
									<li key={i}>
										<div className='flex flex-wrap items-center mb-2'>
											<span className='px-1'>
												{university_institute}
											</span>
											<span className='px-1'>|</span>
											<span className='px-1'>{coursemaster}</span>
											<span className='px-1'>|</span>
											<span className='px-1'>{specialisation}</span>
											<span className='px-1'>|</span>
											<span className='px-1'>{yearofpassing}</span>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
				<div>
					<div className='py-1.5'>
						<p className='mb-1 text-lg font-medium'>Certifications: </p>
					</div>
					<div className='py-1.5'>
						<ul className='mb-1 mr-1'>
							{certifications?.map((item, i) => {
								let { certificationname, certificationagency } = item
								return (
									<li key={i}>
										<div className='flex flex-wrap items-center mb-2 space-x-2 '>
											<span>{certificationname} </span><span>{certificationname && '|'}</span> <span>{certificationagency}</span>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ResumeBuilder
