import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import SelectServiceLineHead from './selecttServiceLineHead';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from '../default';
import MultiSelectItem from 'src/Components/MultiSelect';

const Details = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    accountSelect,
    setAccountSelect,
    selectedSkillAreas,
    setSelectedSkillAreas,
  } = props;
  const { serviceGroup } = useSelector((state) => state.UserManagementList);
  const { skillList } = useSelector((state) => state.EmployeeProfile);
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let item = {
      ...detailsData,
      servicelineheadid: parseFloat(accountSelect?.comuserid),
      servicelineskillareamapping: selectedSkillAreas.map((skill) => ({
        skillareamasterid: skill.skillareamasterid,
      })),

    };
    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid && item.servicelineheadid) {
      let payload = {
        servicelinejson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  const handleSkillAreaSelect = (skill) => {
    let copySkillAreas = selectedSkillAreas;
    let isNew = copySkillAreas?.find((p) => p?.skillareamasterid === skill?.skillareamasterid);
    if (isNew) {
      let rm = copySkillAreas?.filter((p) => p?.skillareamasterid !== skill?.skillareamasterid);
      setSelectedSkillAreas(rm);
    } else {
      copySkillAreas.push({
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        skillareamasterid: skill?.skillareamasterid,
        activeflag: true,
      });
      setSelectedSkillAreas([...copySkillAreas]);
    }
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, servicelineheadid: select.comuserid });
    setCheckvalidation(false);
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='slcode' className='block font-[500] mb-2'>
            Service Line Code
          </label>
          <input
            type='text'
            onChange={(e) => handleChangeInput('slcode', e.target.value)}
            title='slcode'
            id='slcode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Service Line Code'
            required=''
            maxLength={50}
            disabled
          // value={detailsData?.slcode || ''}
          />
        </div>
        <div>
          <label htmlFor='servicelinename' className='block font-[500] mb-2'>
            Service Line Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='servicelinename'
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('servicelinename', e.target.value);
              }
            }}
            id='servicelinename'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Service Line Name'
            required=''
            maxLength={50}
          />{' '}
          {!detailsData?.servicelinename && checkvalidation && (
            <small className='text-red-400'>
              Service Line Name is required!
            </small>
          )}
        </div>
        <div>
          <label
            htmlFor='servicelinegroupname'
            className='block font-[500] mb-2'
          >
            Service Line Group
            <span className='text-red-300'> *</span>
          </label>
          <CustomhtmlSelect
            options={serviceGroup || []}
            value={detailsData?.servicelinegroupid || ''}
            noDataLabel='No Group'
            name='servicelinegroupid'
            labelkey='servicelinegroupname'
            className='outline-gray-50 bg-gray-50 text-gray-400 border rounded-lg block w-full p-2'
            onChange={handleChangeInput}
            id='servicelinegroupid'
          />
          {!detailsData?.servicelinegroupid && checkvalidation && (
            <small className='text-red-400'>
              Service Line Group is required!
            </small>
          )}
        </div>
        <div>
          <label
            htmlFor='servicelineheadname'
            className='block font-[500] mb-2'
          >
            Service Line Head
            <span className='text-red-300'> *</span>
          </label>
          <div className='relative'>
            <SelectServiceLineHead
              accountSelect={accountSelect}
              setAccountSelect={setAccountSelect}
              handleSelectManager={handleSelectManager}
            />
            {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>
                Service Line Head is required!
              </small>
            )}
          </div>
        </div>
        <div>
          <label htmlFor='skillarea' className='block capitalize mb-2  font-[500]'>
            Skill Area
            <span className='text-red-300'> *</span>
          </label>
          <div className='border p-1 bg-gray-50 rounded'>
            <MultiSelectItem
              itemIdKey='skillareamasterid'
              labelKeyname='skillarea'
              limitShow={1}
              selectedOptions={selectedSkillAreas}
              options={skillList}
              handleSelection={handleSkillAreaSelect}
            />
          </div>
          {!selectedSkillAreas && checkvalidation && (
            <small className='text-red-400'>Skill Area is required</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
