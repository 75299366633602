import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TalentFilter from 'src/Filter/workforce/telant'
import { GET_LIST_OF_STATUS_BY_E, GET_USER_TYPE_LIST } from 'src/Redux/actions/masterdata/user'
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects'
import { GET_STAFF_STATUS_TYPE_S } from 'src/Redux/actions/projects/staffing'
import { GET_SKILL_MASTER, GET_TECHNOLOGY_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
    GET_ALLOCATION_STATUS_FOR_EMPLOYEE,
    GET_CITY_MASTER,
    GET_COMPANY_MASTER,
    GET_DEPARTMENT_MASTER,
    GET_DESIGNATION_MASTER,
    GET_EMPLOYEE_DETAILS,
    GET_WORKERTYPE_MASTER,
    GET_WORKFORCEGROUP_MASTER,
} from 'src/Redux/actions/workforce/talent'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import {
    getCityPayload,
    getDeafultSkillData,
    getDeafultTalentPayload,
    getDeafultTechnologyData,
    getDefaultStatusPayload,
    getDepartmentMasterIdData,
    getDesignationMasterIdData,
    getStatusMasterIdData,
    getTenantCompanyIdData,
    getTenantWorkForceyIdData,
    getTenantWorkerTypeyIdData,
    getUserTypeIdData
} from 'src/Services/constant/defaultPayload'
import Talenttable from './components/table'

const TalentLayout = ({ modulename, menu }) => {
    const [filters, setFilters] = useState(getDeafultTalentPayload())
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [empStatusList, setEmpStatusList] = useState([])
    const [userTypeId, setUserTypeId] = useState(null)
    const [limit, setLimit] = useState(10)
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 })
    const UserManagementList = useSelector((state) => state?.UserManagementList)
    const { getStatusByTypeE, usertypelist } = UserManagementList

    useEffect(() => {
        dispatch(GET_CITY_MASTER(getCityPayload()))
        dispatch(GET_COMPANY_MASTER(getTenantCompanyIdData()))
        dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData()))
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData()))
        dispatch(GET_DESIGNATION_MASTER(getDesignationMasterIdData()))
        dispatch(GET_ALLOCATION_STATUS_FOR_EMPLOYEE(getStatusMasterIdData('D')))
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData()))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
        dispatch(GET_TECHNOLOGY_MASTER(getDeafultTechnologyData()))
        dispatch(GET_STAFF_STATUS_TYPE_S())
        dispatch(GET_LIST_OF_STATUS_BY_E())
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E')))
        dispatch(GET_USER_TYPE_LIST(getUserTypeIdData()));
    }, [dispatch])

    useEffect(() => {
        if (getStatusByTypeE?.length > 0) {
            const statusCodes = ['EACTIVE', 'ELNGLEVE', 'ENOTCEPRD', 'ERELEIVD']
            const statusIds = getStatusByTypeE
                ?.filter((status) => statusCodes?.includes(status.statuscode))
                ?.map((status) => status.statusid)
            setEmpStatusList(statusIds)
            setFilters((prevFilters) => ({
                ...prevFilters,
                empstatus: `{${statusIds.join(',')}}`,
            }))
        }
    }, [getStatusByTypeE])

    useEffect(() => {
        if (usertypelist?.length > 0) {
            const employeeType = usertypelist?.find(user => user.usertype === 'Employee')
            if (employeeType) {
                setUserTypeId(employeeType.datusertypeid)
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    usertypeid: employeeType.datusertypeid,
                }))
            }
        }
    }, [usertypelist])


    useEffect(() => {
        if (filters.empstatus && filters.usertypeid) {
            dispatch(GET_EMPLOYEE_DETAILS({
                ...filters,
                limit,
                pageno: page,
                sorttype: sortType.id,
            }))
        }
    // eslint-disable-next-line 
    }, [dispatch])

    useLayoutEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        dispatch({ type: 'TANENT_PERMISSIONS', data: permissionNames })
        // eslint-disable-next-line
    }, [menu])

    return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <TalentFilter
                    filters={filters}
                    setFilters={setFilters}
                    page={page}
                    limit={limit}
                    sortType={sortType}
                    empStatusList={empStatusList}
                    userTypeId={userTypeId}
                />
            </div>
            <Talenttable hooks={{ page, setPage, setLimit, limit, filters, sortType, setSortType, empStatusList, userTypeId }} />
        </>
    )
}

export default TalentLayout
