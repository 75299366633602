import { useDispatch } from 'react-redux'
import { GET_PROJECT_USER_PAYROLL } from 'src/Redux/actions/projects/financials'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import SelectInput from 'src/Components/SelectInput'
import EditUserPayroll from './editPayroll/index'
import UploadPayRoll from './uploadPayroll'
import DownloadAsExcel from './downloadPayRoll'
import { basicShortList } from 'src/Services/constant/staticValues'

const rows = [10, 25, 50]
const cols = [
    'Employee Name',
    'Employee Code',
    'Mon & Year',
    'Amount',
    'Currency',
    'Transaction Date',
    'Transaction Id',
    'Cost Center Code',
]
const Payrolltable = ({ hooks }) => {
    const {
        ProjectUserPayroll,
        isOpen,
        setIsOpen,
        userPayroll,
        setUserPayroll,
        setPayrollId,
        filters,
        loading,
        sortType,
        setSortType,
        page,
        setPage,
        setLimit,
        limit,
        addEditPermission,
        getDefaultPayrollData,
        ProjectOverhead,
    } = hooks
    const dispatch = useDispatch()
    const handleSorting = (item) => {
        setSortType(item)
        dispatch(
            GET_PROJECT_USER_PAYROLL({
                ...filters,
                Sorttype: item?.id,
                Limit: limit,
                Page: page,
            }),
        )
    }
    const handlePagination = async (page) => {
        dispatch(
            GET_PROJECT_USER_PAYROLL({
                ...filters,
                Limit: limit,
                Page: page?.selected + 1,
                Sorttype: sortType?.id,
            }),
        )

        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_PROJECT_USER_PAYROLL({
                ...filters,
                Limit: row,
                Page: row > 10 ? 1 : page,
                Sorttype: sortType?.id,
            }),
        )
    }

    const handleComClick = (com) => {
        setUserPayroll(com)
        setPayrollId(com?.userpayid)
        setIsOpen(true)
    }

    return (
        <div className="p-2 h-[66vh]">
            <div className="flex items-center justify-between py-4">
                <div className="flex items-center justify-start space-x-4">
                    <p className="text-primary font-[500] inline-flex justify-start items-center ">
                        {' '}
                        Payroll
                        <span>
                            (
                            {ProjectUserPayroll?.length > 0
                                ? ProjectUserPayroll[0]?.totalcount
                                : ProjectUserPayroll?.length}
                            )
                        </span>
                    </p>
                    <ReactPaginationStyle
                        total={
                            ProjectUserPayroll?.length > 0
                                ? ProjectUserPayroll[0]?.totalcount
                                : ProjectUserPayroll?.length
                        }
                        currentPage={page}
                        handlePagination={handlePagination}
                        limit={limit}
                    />
                    <div className="w-32 ">
                        <FilterButtonTab
                            setSelectedtab={handleRows}
                            selectedTab={limit}
                            tabs={rows}
                        />
                    </div>
                    <div>
                        <div className="w-32 ">
                            <SelectInput
                                options={basicShortList || []}
                                keyname="label"
                                selected={sortType}
                                setSelected={handleSorting}
                            />
                        </div>
                    </div>
                </div>
                {addEditPermission ? (
                    <div className="flex justify-end w-full space-x-2">
                        <UploadPayRoll
                            getDefaultPayrollData={getDefaultPayrollData}
                            ProjectOverhead={ProjectOverhead}
                        />
                        <DownloadAsExcel fileName="Payroll" apiData={[]} />
                        {/* DONT REMOVE THIS */}
                        {/* <AddPayroll getDefaultPayrollData={getDefaultPayrollData} userPayroll={userPayroll} setUserPayroll={setUserPayroll} /> */}
                    </div>
                ) : (
                    ''
                )}
            </div>
            {loading ? (
                <div className="flex items-center justify-center w-full h-[56vh]">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[56vh] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {cols?.map((col) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="font-[500] px-2 py-2 border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {ProjectUserPayroll?.map((item, i) => {
                                const {
                                    username,
                                    monthname,
                                    employeecode,
                                    yearid,
                                    amount,
                                    currency,
                                    transactionid,
                                    transactiondate,
                                    externalsystemcode,
                                    uId,
                                } = item
                                return (
                                    <tr
                                        key={uId}
                                        onClick={() => handleComClick(item)}
                                        className="text-left cursor-pointer"
                                    >
                                        <td className="px-2 py-1 border">{username}</td>
                                        <td className="px-2 py-1 border">
                                            {employeecode}
                                        </td>
                                        <td className="px-2 py-1 border">
                                            <div className="w-44">
                                                {monthname.substring(0, 3)} {yearid}
                                            </div>
                                        </td>
                                        <td className="px-2 py-1 border">
                                            <div className="w-36">{amount}</div>
                                        </td>
                                        <td className="px-2 py-1 border">{currency}</td>
                                        <td className="px-2 py-1 border">
                                            {transactiondate}
                                        </td>

                                        <td className="px-2 py-2 border">
                                            {transactionid}
                                        </td>
                                        <td className="px-2 py-2 border">
                                            {externalsystemcode}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div>
                        <EditUserPayroll
                            hooks={{
                                addEditPermission,
                                ProjectUserPayroll,
                                setUserPayroll,
                                userPayroll,
                                setPayrollId,
                                isOpen,
                                setIsOpen,
                                getDefaultPayrollData,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Payrolltable
