export const WorkerTypeItem = () => {
    return {
        workertypeid: null,
        workertype: "",
        comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
        isactiveflag: true,
        createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
        notes: "",

    }
}

export const detailNotRequired = [
    'workertypeid',
    'notes'
];
