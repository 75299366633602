import {
    ACTION_GET_ALLOCATION_STATUS,
    ACTION_GET_CERTIFICATIONS,
    ACTION_GET_COURSE_MASTER,
    ACTION_GET_EDUCATION_MASTER,
    ACTION_GET_EDUCATIONBACKGROUND_LIST,
    ACTION_GET_EMPLOYEE_DOCUMENT_LIST,
    ACTION_GET_EMPLOYEE_DOCUMENT_TYPE_LIST,
    ACTION_GET_EMPLOYEE_SUMMARY,
    ACTION_GET_INSUDTRY_MASTER,
    ACTION_GET_LEVEL_MASTER,
    ACTION_GET_PREVIOUS_EXPERIENCE,
    ACTION_GET_PROJECTS_EPROFILE,
    ACTION_GET_SKILL_MASTER,
    ACTION_GET_SKILLAREA_MASTER,
    ACTION_GET_SKILLS_EMPLOYEE,
    ACTION_GET_SPECIALISATION_MASTER,
    ACTION_GET_TECHNOLOGY_MASTER,
    ACTION_SAVE_USEREDUCATION,
} from 'src/Redux/types'
import { AlertError, AlertSuccess } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'
import {
    certificationRowDefault,
    educationRowDefault,
    experienceRowDefault,
} from 'src/Services/constant/staticValues'
// import moment from 'moment'
const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrlMaster = process.env.REACT_APP_BASE_URL_MASTER

const getTanAndComId = () => {
    return {
        Tenantid: Number(localStorage.getItem('Tenantid') || 0),
        comuserid: Number(localStorage.getItem('comuserid') || 0),
    }
}

const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
}

export const INSERT_EDUCATION = (data) => {
    return async () => {
        try {
            let items = data?.map((item) => {
                delete item['educationmaster']
                delete item['coursemaster']
                delete item['specialisation']
                delete item['uId']
                return item
            })
            let payload = {
                usereducationdtlsjson: items,
            }
            const url = `${baseUrl}/insert_usereducation`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Education saved successfully!')
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const INSERT_SKILL = (data) => {
    return async () => {
        try {
            let items = data?.map((item) => {
                // item['isactiveflag'] = true
                delete item['technology']
                delete item['industryname']
                delete item['modifiedcomuserid']
                delete item['modifiedtimestamp']
                delete item['createdtimestamp']
                // delete item['createdcomuserid']
                item['experienceinmonths'] = Number(item['experienceinmonths'])
                delete item['skillname']
                delete item['skillareamasterid']
                delete item['levelname']
                delete item['skillType']
                delete item['userskillid']
                delete item['skill']
                delete item['skillarea']
                // delete item['technology']
                delete item['uId']
                delete item['username']
                return item
            })
            let payload = {
                userskilltechnologyjson: items,
            }
            const url = `${baseUrl}/insert_userskilltechnology`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Skill saved successfully!')
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const INSERT_EXPERIENCE = (data) => {
    return async () => {
        try {
            let items = data?.map((item) => {
                item['comuserprevexperienceid'] = null
                item['comuserid'] = getTanAndComId().comuserid
                item['createdcomuserid'] = getTanAndComId().comuserid
                delete item['username']
                delete item['createdtimestamp']
                delete item['uId']
                return item
            })
            let payload = {
                prevexpjson: items,
            }
            const url = `${baseUrl}/insert_userprevexperience`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Experience saved successfully!')
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const INSERT_CERTIFICATIONS = (data) => {
    return async () => {
        try {
            let items = data?.map((item) => {
                item['comusercertificationid'] = null
                item['comuserid'] = getTanAndComId().comuserid
                item['createdcomuserid'] = getTanAndComId().comuserid
                delete item['username']
                delete item['certificatefilepath']
                delete item['createdtimestamp']
                delete item['uId']
                return item
            })
            let payload = {
                usercertjson: items,
            }
            const url = `${baseUrl}/insert_usercertification`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Cerification saved successfully!')
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_EDUCATIONBACKGROUND_LIST = (data) => async (dispatch) => {
    try {
        const url = `${baseUrl}/get_usereducation`
        const res = await postMethodApiCall(
            url,

            getHeadersWithoutAuth(),
            {
                Tenantid: getTanAndComId()?.Tenantid,
                comuserid: data?.comuserid || getTanAndComId()?.comuserid,
            },
            // console.log()
        )
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            if (!items) AlertError('No Education found for Employee')
            let addnewkey = items?.map((item) => {
                item['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                return item
            })
            dispatch({
                type: ACTION_GET_EDUCATIONBACKGROUND_LIST,
                data: addnewkey || [educationRowDefault()],
            })
            return { success: true, data: addnewkey || [educationRowDefault()] }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_EDUCATION_MASTER = (data) => async (dispatch) => {
    try {
        const url = `${baseUrlMaster}/get_dateducation`
        const res = await postMethodApiCall(
            url,

            getHeadersWithoutAuth(),
            data,
        )
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data

            let addnewkey = items?.map((item) => {
                item['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                return item
            })
            dispatch({ type: ACTION_GET_EDUCATION_MASTER, data: addnewkey })
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const GET_COURSE_MASTER = (payload) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/get_datcourse`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_COURSE_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_SKILLAREA_MASTER = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || '',
            }
            const url = `${baseUrlMaster}/get_skillareamaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Skill Area found for Employee!')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_SKILLAREA_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_SPECIALISATION_MASTER = (data) => async (dispatch) => {
    try {
        const url = `${baseUrlMaster}/get_datspecialisation`
        const res = await postMethodApiCall(
            url,
            getHeadersWithoutAuth(),
            data,
        )
        if (res?.status === 200 || res?.status === 201) {
            let items = res?.data?.data
            let addnewkey = items?.map((item) => {
                item['uId'] =
                    Math.random().toString(36).substr(2, 9) +
                    new Date().getTime().toString(36)
                return item
            })
            dispatch({ type: ACTION_GET_SPECIALISATION_MASTER, data: addnewkey })
            return { success: true, data: addnewkey || [] }
        }
    } catch (error) {
        AlertError(error?.response?.data).replace(/\\/g, '')
    }
}

export const SAVE_USEREDUCATION = (payload) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/insert_usereducation`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            dispatch({
                type: ACTION_SAVE_USEREDUCATION,
                data: res.data?.data,
            })
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Usereducation saved successfully!')
                return true
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROJECTS_EPROFILE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectstaffing_forauser`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), {
                Tenantid: getTanAndComId()?.Tenantid,
                comuserid: data?.comuserid || getTanAndComId()?.comuserid,
            })
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_PROJECTS_EPROFILE, data: addnewkey })
                if (items === null) AlertError('No Projects found for Employee!')
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const SAVE_NOTES_OF_PROJECT_STAFFING = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/update_projectstaffing_notes`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Summary Updated Successfully !')
                return { success: true }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
export const SAVE_NOTES_OF_E_PROFILE = (notes) => {
    return async () => {
        try {
            let payload = {
                notes,
                Tenantid: getTanAndComId()?.Tenantid,
                userid: getTanAndComId()?.comuserid,
            }
            const url = `${baseUrl}/update_userprofile_notes`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess('Summary Updated Successfully !')
                return { success: true }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PREVIOUS_EXPERIENCE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_userprevexperience`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), {
                Tenantid: getTanAndComId()?.Tenantid,
                comuserid: data?.comuserid || getTanAndComId()?.comuserid,
            })
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Experience found for Employee !')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_PREVIOUS_EXPERIENCE,
                    data: items || [experienceRowDefault()],
                })
                return {
                    success: true,
                    data: addnewkey || [experienceRowDefault()],
                }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_EMPLOYEE_SUMMARY = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/getuserdetailsbyUserid`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), {
                Tenantid: getTanAndComId()?.Tenantid,
                COMUserId: data?.COMUserId || getTanAndComId()?.comuserid,
                Minbandid: 0,
                Maxbandid: 0,
            })
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Employee found!')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_EMPLOYEE_SUMMARY, data: addnewkey || [] })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_ALLOCATION_STATUS = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectAllocationStatusforauser`
            const res = await postMethodApiCall(
                url,
                getHeadersWithoutAuth(),
                data || getTanAndComId(),
            )
            if (res?.status === 200 || res?.status === 201) {
                if (res?.data?.data === null) AlertError('No Allocation found !')
                dispatch({
                    type: ACTION_GET_ALLOCATION_STATUS,
                    data: res?.data?.data || [],
                })
                return { success: true, data: res?.data?.data }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_CERTIFICATIONS = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_usercertification`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), {
                Tenantid: getTanAndComId()?.Tenantid,
                comuserid: data?.comuserid || getTanAndComId()?.comuserid,
            })
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Cerification found for Employee!')

                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_CERTIFICATIONS,
                    data: addnewkey || [certificationRowDefault()],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_SKILLS_EMPLOYEE =
    (data = getTanAndComId()) =>
        async (dispatch) => {
            try {
                const url = `${baseUrl}/get_userskilltechnology`
                const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
                if (res?.status === 200 || res?.status === 201) {
                    let items = res?.data?.data
                    if (!items) AlertError('No Skill found for Employee')
                    let addnewkey = items?.map((item) => {
                        item['uId'] =
                            Math.random().toString(36).substr(2, 9) +
                            new Date().getTime().toString(36)
                        return item
                    })
                    dispatch({
                        type: ACTION_GET_SKILLS_EMPLOYEE,
                        data: addnewkey,
                    })
                    return { success: true, data: addnewkey }
                }
            } catch (error) {
                AlertError(error?.response?.data).replace(/\\/g, '')
            }
        }

export const GET_SKILL_MASTER = (data) => {
    return async (dispatch) => {
        try {
            let payload = {
                ...data,
                searchname: data?.searchname || '',
            }
            const url = `${baseUrlMaster}/get_skillmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Skill found for Employee!')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_SKILL_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_LEVEL_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/get_levelmaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Proficiency found for Employee!')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_LEVEL_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_TECHNOLOGY_MASTER = (payload) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrlMaster}/get_technologymaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No Technology found for Employee!')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_TECHNOLOGY_MASTER, data: items || [] })
                return { success: true, data: addnewkey || [] }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_INSUDTRY_MASTER = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_industrymaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No  Industry/Domain found for Employee!')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({ type: ACTION_GET_INSUDTRY_MASTER, data: addnewkey || [] })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_EMPLOYEE_DOCUMENT_LIST = (payload) => {
    return async (dispatch) => {
        try {
            let data = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
                ...payload,
            }
            const url = `${baseUrl}/get_userdocuments`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)

            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_EMPLOYEE_DOCUMENT_LIST,
                    data: addnewkey || [],
                })
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_EMPLOYEE_DOCUMENT_TYPE_LIST = () => {
    return async (dispatch) => {
        try {
            let payload = {
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
                documenttypeid: 0,
                Type: 'E',
            }
            const url = `${baseUrlMaster}/get_documenttypemaster`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)

            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_EMPLOYEE_DOCUMENT_TYPE_LIST,
                    data: addnewkey || [],
                })
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const SAVE_USER_DOCUMENT = (data) => {
    return async () => {
        try {
            let newdataFrame = []
            for (let item of data) {
                let contact = { ...item }
                delete contact['uId']
                newdataFrame.push(contact)
            }

            const url = `${baseUrl}/insert_UserdocumentsNew`
            let res = await postMethodApiCall(url, getHeadersWithoutAuth(), newdataFrame)
            let item = res?.data?.data
            if (res?.status === 200 || res?.status === 201) {
                const comuserid = item?.comuserid
                return { success: true, comuserid }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}

export const DELETE_USER_DOCUMENT = (id) => {
    return async () => {
        try {
            let payload = {
                userdocumentattachmentsid: id,
                deletedcomuserid: Number(localStorage.getItem('comuserid') || 0),
                Tenantid: JSON.parse(localStorage.getItem('Tenantid')),
            }
            const url = `${baseUrl}/delete_userdocuments`
            let res = await postMethodApiCall(url, getHeadersWithoutAuth(), payload)
            let item = res?.data?.data
            if (res?.status === 200 || res?.status === 201) {
                // const customerid = item?.customerid;
                AlertSuccess('Document Deleted successfully!')
                return { success: true, item }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}
