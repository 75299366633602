import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectInput from 'src/Components/SelectInput';
import { GET_INDUSTRY_LIST_MASTER } from 'src/Redux/actions/masterdata/user';
import { getIndustryListPayload } from 'src/Services/constant/Customerpayload';
import { basicShortList } from 'src/Services/constant/staticValues';
import CreateIndustry from './createIndustry/index';
import { IndustryItem } from "./default";
import EditIndustry from './editIndustry';

const cols = ['Industry', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
    { label: 'Active' },
    { label: 'Inactive' },
    { label: 'All' },
];

const IndustryList = () => {
    const [dm, setDm] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [status, setStatus] = useState({ label: 'Active' });
    const [industryId, setIndustryId] = useState(null);
    const [detailsData, setDetailsData] = useState(IndustryItem());
    const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });
    const { PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);
    const dispatch = useDispatch();
    const { industryList } = useSelector((state) => state.UserManagementList);

    useMemo(() => {
        if (dm?.industryname) {
            setSearchTerm(dm?.industryname);
        }
    }, [dm]);

    const handleSorting = async (item) => {
        setSortType(item);
        let res = await dispatch(GET_INDUSTRY_LIST_MASTER({ ...getIndustryListPayload(), sorttype: item.id, status: status?.label, limit: limit }));
        if (res !== null) {
            setDetailsData(res.data);
        }
    };

    const handleStatus = async (item) => {
        setStatus(item);
        let res = await dispatch(GET_INDUSTRY_LIST_MASTER({ ...getIndustryListPayload(), status: item.label }));
        if (res === null) {
            setDetailsData(res.data);
        }
    };

    const handleDeliveryClick = (industry) => {
        setDetailsData(industry);
        setIndustryId(industry?.industryid);
        setIsOpen(true);
    };

    const handlePagination = async (page) => {
        let res = await dispatch(GET_INDUSTRY_LIST_MASTER({ ...getIndustryListPayload(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }));
        if (res?.success) {
            setDetailsData(res?.data);
        }
        setPage(page?.selected + 1);
    };

    const handleRows = async (row) => {
        setLimit(row);
        let res = await dispatch(GET_INDUSTRY_LIST_MASTER({ ...getIndustryListPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label }));
        if (res !== null) {
            setDetailsData(res?.data);
        }
    };

    return (
        <div className='w-full h-screen p-2 py-3 mt-4 bg-white rounded-lg shadow'>
            <div className='flex items-center justify-between mb-2'>
                <div className='flex items-center justify-start space-x-5'>
                    <h1 className='pb-2 font-[500]'>
                        Industries ({industryList?.length > 0 ? industryList[0]?.totalcount : industryList?.length})
                    </h1>
                    <div className='w-72'>
                        <CustomAutoSearch
                            searchTerm={searchTerm}
                            API_CALL_FOR_SEARCH={GET_INDUSTRY_LIST_MASTER}
                            searchPayload={{ ...getIndustryListPayload(), searchname: searchTerm }}
                            setSearchTerm={setSearchTerm}
                            options={industryList}
                            idkey='industryid'
                            className='p-2'
                            optionlabelKey='industryname'
                            getSelectedOption={setDm}
                            placeholder='Enter Industry'
                        />
                    </div>
                    <div className='flex items-center justify-start space-x-6'>
                        <ReactPaginationStyle
                            total={industryList?.length > 0 ? industryList[0]?.totalcount : industryList?.length}
                            currentPage={page}
                            handlePagination={handlePagination}
                            limit={limit}
                        />
                        <FilterButtonTab
                            setSelectedtab={handleRows}
                            selectedTab={limit}
                            tabs={rows}
                        />
                    </div>
                    <div className='w-32 '>
                        <SelectInput
                            options={statusList || []}
                            keyname='label'
                            selected={status}
                            setSelected={handleStatus}
                            className='py-2 border rounded bg-gray-50'
                        />
                    </div>
                    <div className='w-32 '>
                        <SelectInput
                            options={basicShortList || []}
                            keyname='label'
                            selected={sortType}
                            setSelected={handleSorting}
                            className='py-2 border rounded bg-gray-50'
                        />
                    </div>
                </div>
                {(PermissionsOfMasterdata.includes('Add/Edit Customer')) ?
                    <CreateIndustry />
                    : ""}
            </div>
            <div className='overflow-scroll h-[50vh] pb-20'>
                <table className='relative w-full text-sm'>
                    <thead>
                        <tr className='text-left capitalize bg-gray-50 sticky top-0 ...'>
                            {cols?.map((col) => {
                                return (
                                    <th
                                        key={col}
                                        title={col}
                                        scope='col'
                                        className='font-[500] px-1 py-2 border text-start'
                                    >
                                        {col}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {industryList?.map((item, i) => {
                            const {
                                industryname,
                                industryid,
                                isactiveflag,
                                createdtimestamp,
                                createduser,
                            } = item;
                            return (
                                <tr
                                    onClick={() => handleDeliveryClick(item)}
                                    key={industryid}
                                    className='text-left cursor-pointer'>
                                    <td className='border px-1 py-1.5'>{industryname}</td>
                                    <td className='px-2 border'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                                    <td className='px-2 border'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                                    <td className='border px-1 py-1.5'><div>{createduser}</div></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <EditIndustry
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                industryId={industryId}
                setDetailsData={setDetailsData}
                detailsData={detailsData}
                page={page}
                limit={limit}
                sortType={sortType}
                permissions={PermissionsOfMasterdata}
            />
        </div>
    );
};

export default IndustryList;
