import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
	GET_CERTIFICATIONS,
	GET_EMPLOYEE_DOCUMENT_TYPE_LIST,
	INSERT_CERTIFICATIONS,
} from 'src/Redux/actions/workforce/eprofile'
import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import Table from './table'
import {CheckListObjectValidation} from 'src/Services/constant/customvalidation'
import {certificationRowDefault} from 'src/Services/constant/staticValues'
// import { userDocumentRow } from '../defaultRowData'

const Certifications = props => {
	const dispatch = useDispatch()
	const EmployeeProfile = useSelector(state => state.EmployeeProfile)
	const {certifications, /* empDocumentList */} = EmployeeProfile
	const [formData, setFormData] = useState([])
	const [notValidated, setNotValidated] = useState(null)
	const {
		// certificate,
		// setCertificate,
		// certificateSave,
		canEdit,
		setCanEdit,
		// mockObject,
	} = props
	// let {comusercertificationid} = certifications[0]

/* 	useEffect(() => {
		if (empDocumentList?.length > 0 && certifications.length > 0) {
			setCertificate([{...mockObject}])
		} else {

		}
		// eslint-disable-next-line
	}, [empDocumentList]) */
	// console.log(certificate, "cerrtrttertrettreterttttttttttttttttttt")
	useEffect(() => {
		dispatch(GET_CERTIFICATIONS())
		dispatch(GET_EMPLOYEE_DOCUMENT_TYPE_LIST())
	}, [dispatch])

	useEffect(() => {
		setFormData(certifications)
	}, [certifications])

/* 	const removeDoc = async () => {
		let id = certificate?.userdocumentattachmentsid
		if (canEdit && id) {
			let res = await dispatch(DELETE_USER_DOCUMENT(id))
			if (res !== null) {
				// setPdfUrl(null)
				dispatch(GET_EMPLOYEE_DOCUMENT_LIST(getuserDocumentPayload()))
				// setCanEdit(false)
			} else {
				setCertificate(mockObject)
			}
		}
	} */
	// console.log(certificationRowDefault,certifications );
	const HandleSubmit = async () => {
		let NotReq = [
			'username',
			'createdtimestamp',
			'remarks',
			'notes',
			'comusercertificationid',
			'certificatefilepath',
			'modulename',
		]

		let isvalidaed = CheckListObjectValidation(formData, NotReq, false)
		if (isvalidaed?.isvalid) {
			let afterFilter = formData.map(item => {
				for (let keyname in certificationRowDefault()) {
					if (item[keyname] === undefined) {
						item[keyname] = certificationRowDefault()[keyname]
					}
				}
				return item
			})
			let res = await dispatch(INSERT_CERTIFICATIONS(afterFilter))
			if (res !== null) {
				setCanEdit(false)
				await dispatch(GET_CERTIFICATIONS())
			}
		} else {
			setNotValidated(isvalidaed)
		}
	}

	return (
		<div className='h-[44vh] text-sm my-2'>
			<div className='overflow-hidden min-h-max'>
				<Table
					notValidated={notValidated}
					canEdit={canEdit}
					formData={formData}
					setFormData={setFormData}
					// setDocuments={setCertificate}
					// documents={certificate}
				/>
			</div>
			{canEdit ? (
				<div className='fixed mb-4 mr-4 bottom-2 right-6'>
					<div className='flex items-center justify-end gap-2'>
						<button
							onClick={() => {
								setCanEdit(false)
								dispatch(GET_CERTIFICATIONS())
							}}
							className='px-2 py-1 border rounded border-primary text-primary h-9'
						>
							Cancel
						</button>
						<PrimaryButton
							className='py-2 text-sm text-white rounded-md bg-primary'
							onClick={() => {
								HandleSubmit()
								// certificateSave()
							}}
						>
							Save
						</PrimaryButton>
					</div>
				</div>
			) : (
				''
			)}
		</div>
	)
}

export default Certifications
