import { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Transition, Tab } from '@headlessui/react';
import IconButton from 'src/Components/Buttons/IconButton';
import { AlertSuccess } from 'src/Services';
import Details from './details';
import Profile from './profile';
import {
  GET_COMPANY_MASTER,
  GET_DESIGNATION_MASTER,
  GET_WORKERTYPE_MASTER,
  GET_CITY_MASTER,
  GET_DEPARTMENT_MASTER,
  GET_WORKFORCEGROUP_MASTER,
  GET_BAND_MASTER,
} from 'src/Redux/actions/workforce/talent';
import {
  GET_USER_TYPE_LIST,
  GET_USER_ACCESS_LIST,
  SAVE_USER_AS_DRAFT,
  GET_ROLE_MASTER,
  GET_LOCATION_MASTER,
  GET_GENDER_MASTER,
  GET_TIME_TYPE,
  SAVE_USER_PROFILE_AS_DRAFT,
  GET_NATIONALITY_MASTER,
  GET_SERVICELINE_MASTER
} from 'src/Redux/actions/masterdata/user';
import { GET_LIST_OF_STATUS } from 'src/Redux/actions/projects';
import {
  getDefaultStatusPayload,
  getMasterCompanyIdData,
  getUserAccessPayload,
  getUserTypeIdData,
  getRoleMasterIdData,
  getDefaultNationalityPayload,
  getTenantWorkForceyIdData,
  getDepartmentMasterIdData,
  getDesignationMasterIdData,
  getBandMasterIdData,
  getDefaultLocationPayload,
  getTenantWorkerTypeyIdData,
  getGenderPayload,
  getDefaultTimeTypePayload,
  getCityPayload,
  getDeafultSkillAreaData,

} from 'src/Services/constant/defaultPayload';
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload';
import UserPrimarySkill from './skills';
import { GET_SKILLAREA_MASTER, GET_SKILLS_EMPLOYEE } from 'src/Redux/actions/workforce/eprofile';


const tabslist = ['Details', 'Profile', "Skills"];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditUser = (props) => {
  const dispatch = useDispatch();
  const {
    isOpen = false,
    setIsOpen,
    UserId,
    setDetailsData,
    detailsData,
    username,
    page,
    limit,
    sortType,
    empstatus,
    empstatusName,
    setEmpStatus,
    permissions,
    filters
    // Add empstatus to the destructured props
  } = props;

  const [canEdit, setCanEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [rm, setRm] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const canEditUser = permissions.includes('Add/Edit Users');


  async function fetchData() {
    try {
      await Promise.all([
        dispatch(GET_COMPANY_MASTER(getMasterCompanyIdData())),
        dispatch(GET_USER_TYPE_LIST(getUserTypeIdData())),
        dispatch(GET_ROLE_MASTER(getRoleMasterIdData())),
        dispatch(GET_WORKFORCEGROUP_MASTER(getTenantWorkForceyIdData())),
        dispatch(GET_DEPARTMENT_MASTER(getDepartmentMasterIdData())),
        dispatch(GET_DESIGNATION_MASTER(getDesignationMasterIdData())),
        dispatch(GET_BAND_MASTER(getBandMasterIdData())),
        dispatch(GET_LOCATION_MASTER(getDefaultLocationPayload())),
        dispatch(GET_WORKERTYPE_MASTER(getTenantWorkerTypeyIdData())),
        dispatch(GET_GENDER_MASTER(getGenderPayload())),
        dispatch(GET_TIME_TYPE(getDefaultTimeTypePayload())),
        dispatch(GET_CITY_MASTER(getCityPayload())),
        dispatch(GET_NATIONALITY_MASTER(getDefaultNationalityPayload())),
        dispatch(GET_LIST_OF_STATUS(getDefaultStatusPayload('E'))),
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload())),
        dispatch(GET_SKILLAREA_MASTER(getDeafultSkillAreaData())),
        dispatch(GET_SKILLS_EMPLOYEE({ comuserid: UserId, Tenantid: +localStorage.getItem('Tenantid') }))
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  async function closeModal() {
    setIsOpen(false);
    await dispatch(GET_USER_ACCESS_LIST(filters));
    const debounceTimer = setTimeout(() => {
      setCanEdit(false);
      setSelectedRoles(selectedRoles);
      setShowDeleteConfirmation(false);
    }, 1000)
    return () => {
      clearTimeout(debounceTimer)
    }


  }
  // GET_CITY_MASTER

  async function detailSave(payload) {
    let res = await dispatch(SAVE_USER_AS_DRAFT(payload, UserId));
    if (res.success) {
      AlertSuccess('User updated successfully.');
      await dispatch(GET_USER_ACCESS_LIST(getUserAccessPayload()));
    }
  }
  const profileSave = async (payload) => {
    let res = await dispatch(SAVE_USER_PROFILE_AS_DRAFT(payload, UserId));
    if (res?.success) {
      AlertSuccess('Profile Details Updated successfully!');
      // await dispatch(GET_USER_ACCESS_LIST(getUserAccessMasterPayload()));
    }
  };

  const handleDelete = async () => {
    let item = {
      userid: detailsData?.userid,
      username: detailsData?.username,
      emailid: detailsData?.emailid,
      personalemailid: '',
      mobileno: detailsData?.mobileno,
      phoneno: '',
      datusertypeid: detailsData?.datusertypeid,
      firstname: detailsData?.firstname,
      lastname: detailsData?.lastname,
      payrollcompanyid: detailsData?.payrollcompanyid,
      employeecode: detailsData?.employeecode,
      comtenantid: Number(localStorage.getItem('Tenantid') || 0),
      createdcomuserid: Number(localStorage.getItem('comuserid')),
      notes: 'Emp insert',
      emp_status: detailsData?.emp_statusid,
      activeflag: !detailsData.activeflag,
      Roledetails: detailsData?.Roleid ? [{ Roleid: detailsData.Roleid }] : [],
    };
    let payload = {
      userjson: [item],
      password: '123456',
    };

    const res = await dispatch(SAVE_USER_AS_DRAFT(payload));
    if (detailsData.activeflag && res?.success === true) {
      AlertSuccess('User deactivated successfully.');
      await dispatch(
        GET_USER_ACCESS_LIST({
          ...getUserAccessPayload(),
          limit,
          page: page?.selected + 1,
          sorttype: sortType?.id,
        })
      );
    } else if (!detailsData.activeflag && res?.success === true) {
      AlertSuccess('User activated successfully.');
    }
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    closeModal();
  };

  function handleDeleting() {
    if (detailsData?.activeflag) {
      setCanEdit(true);
    }
    setShowDeleteConfirmation(true);
  }

  function handleCancelDeleting() {
    setShowDeleteConfirmation(false);
    setCanEdit(false);
    //  closeModal();
  }


  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
          </Transition.Child>
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                  <Tab.Group>
                    <div className='border-b border-secondary py-1  flex justify-between items-center'>
                      <h3 className='text-lg font-[500] leading-6 text-primary'>
                        Edit User
                      </h3>

                      <Tab.List className='justify-start flex space-x-2 rounded-lg p-1'>
                        {tabslist?.map((item, i) => {
                          return (
                            <Tab
                              key={i}
                              // disabled={!activeTabs.includes(item)}
                              className={({ selected }) =>
                                classNames(
                                  'w-full rounded-lg py-1 text-lg outline-white',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-0',
                                  selected
                                    ? 'bg-secondary/10 text-secondary rounded-md font-[500]'
                                    : 'text-gray-500'
                                )
                              }
                            >
                              <span className='px-2'> {item}</span>
                            </Tab>
                          );
                        })}
                      </Tab.List>

                      <div className='flex justify-end items-center space-x-2'>
                        {canEditUser && !canEdit && detailsData.activeflag && (
                          <IconButton
                            title='Edit'
                            onClick={() => setCanEdit(true)}
                            type='button'
                            className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='w-4 h-4'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                              />
                            </svg>
                            <span className='sr-only'>Edit icon</span>
                          </IconButton>
                        )}
                        {canEditUser && !canEdit && (
                          <>
                            <IconButton
                              title={detailsData.activeflag ? 'Delete' : 'Reactivate'}
                              onClick={handleDeleting}
                              type='button'
                              className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.8}
                                stroke='currentColor'
                                className='w-4 h-4'
                              >
                                {detailsData.activeflag ? (
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                  />
                                ) : (
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth={3}
                                    d='M5 13l4 4L19 7' />
                                )}
                              </svg>
                              <span className='sr-only'>
                                {detailsData.activeflag ? 'Unlock' : 'Delete'}{' '}
                                icon
                              </span>
                            </IconButton>
                          </>
                        )}
                        <IconButton
                          title='Close'
                          onClick={closeModal}
                          type='button'
                          className='text-white bg-primary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-4 h-4'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M6 18L18 6M6 6l12 12'
                            />
                          </svg>
                          <span className='sr-only'>Close icon</span>
                        </IconButton>
                      </div>

                    </div>
                    <Tab.Panels>
                      <Tab.Panel>
                        <Details
                          canEdit={!canEdit}
                          UserId={UserId}
                          detailSave={detailSave}
                          detailsData={detailsData}
                          setDetailsData={setDetailsData}
                          username={username}
                          setCanEdit={setCanEdit}
                          empstatus={empstatus}
                          selectedRoles={selectedRoles}
                          setSelectedRoles={setSelectedRoles}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <Profile
                          rm={rm}
                          setRm={setRm}
                          canEdit={!canEdit}
                          UserId={UserId}
                          detailsData={detailsData}
                          profileSave={profileSave}
                          setCanEdit={setCanEdit}
                          empstatus={empstatus}
                          empstatusName={empstatusName}
                          setEmpStatus={setEmpStatus}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <UserPrimarySkill
                          canEdit={!canEdit}
                          UserId={UserId}
                          setCanEdit={setCanEdit}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                  {showDeleteConfirmation && (
                    <div className='absolute bottom-0 left-0 w-full bg-white p-6 border-t border-gray-300 text-center'>
                      <p className='mb-4'>
                        {detailsData.activeflag
                          ? 'Are you sure you want to delete this user?'
                          : 'Are you sure you want to reactivate this user?'}
                      </p>
                      <button
                        onClick={handleDelete}
                        className='px-8 border border-primary bg-primary text-white rounded mt-0 h-9 py-1.5 mr-4'
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleCancelDeleting}
                        className='px-8 border border-primary text-primary rounded mt-0 h-9 py-1.5'
                      >
                        No
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default memo(EditUser);