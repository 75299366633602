export const RegionItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    comregionid: null,
    regionname: "",
    regioncode: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}

export const StateItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    comstateid: null,
    comcountryid: "",
    statename: "",
    statecode: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}

export const CountryItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    comcountryid: null,
    comregionid: 0,
    countryname: "",
    countrycode: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}

export const CityItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    comcityid: null,
    comstateid: 0,
    cityname: "",
    citycode: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}

export const LocItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    comlocationid: null,
    comcityid: 0,
    locationname: "",
    locationcode: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}

export const detailNotRequired = [
  'comcityid',
  'comstateid',
  'comcountryid',
  'comregionid',
  'comlocationid',
  'regioncode',
  'notes',
  'countrycode',
  'statecode',
  'citycode',
  'locationcode',
  'comstateid'
];