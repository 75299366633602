import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_TIMESHEET_REPORT_FOR_EXCEPTION,
} from 'src/Redux/actions/reports'

const cols1 = [
    'Employee Name',
    'Project name',
    'Role name',
    'Week Start',
    'Week End',
    'Planned Effort',
    'Actual Effort',
]

const cols2 = [
    'Employee Name',
    'Exception',
    'Variation %',
    'Status',
    'Approver Name',
    'Approver Remarks',
]

const rows = [10, 25, 50]
const ExceptionTimesheetReport = ({
    filters,
    reportMasterList,
    reportId,
    projectIds,
    loader,
    setLoader,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const { timesheetreportForException } = useSelector((state) => state?.WorkforceReport)
    const [showMore, setShowMore] = useState(true)

    const handlePagination = async (page) => {
        setLoader(true)
        await dispatch(
            GET_TIMESHEET_REPORT_FOR_EXCEPTION({
                ...filters,
                limit,
                pageno: page?.selected + 1,
            }),
        )
        setLoader(false)
        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        setLoader(true)
        let data = filters
        if (filters['projectid'] === '{}') {
            data['projectid'] = `{${projectIds?.join(',')}}`
        }
        if (row > 10) {
            setPage(1)
        }
        await dispatch(
            GET_TIMESHEET_REPORT_FOR_EXCEPTION({
                ...data,
                limit: row,
                pageno: row > 10 ? 1 : page,
            }),
        )
        setLoader(false)
    }
    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    const totalRecords = useMemo(() => {
        return timesheetreportForException?.length > 0
            ? timesheetreportForException[0]?.totalcount
            : timesheetreportForException?.length
    }, [timesheetreportForException])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={!showMore}
                        onClick={() => setShowMore(!showMore)}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>
                <div className="flex items-center justify-between w-full space-x-5">
                    <p className="text-primary font-[500]">
                        Reports Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        limit={totalRecords}
                        endpoint="download_timesheetexceptionreport"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        fileName={fileName}
                        filters={filters}
                    />
                </div>
            </div>
            {loader ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(88%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {showMore
                                    ? cols1.map((col) => {
                                          return (
                                              <th
                                                  key={col}
                                                  title={col}
                                                  scope="col"
                                                  className="p-2 font-[500] border text-start"
                                              >
                                                  {' '}
                                                  {col}
                                              </th>
                                          )
                                      })
                                    : cols2.map((col) => {
                                          return (
                                              <th
                                                  key={col}
                                                  title={col}
                                                  scope="col"
                                                  className="p-2 font-[500] border text-start"
                                              >
                                                  {' '}
                                                  {col}
                                              </th>
                                          )
                                      })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {timesheetreportForException?.map((item, i) => {
                                let {
                                    employeeid,
                                    employeename,
                                    projectname,
                                    projectcode,
                                    rolename,
                                    timesheetstatus,
                                    approverremarks,
                                    approvedemployeename,
                                    approveremployeeid,
                                    weekstartdate,
                                    weekenddate,
                                    timsheethours,
                                    exception,
                                    variation,
                                    uId,
                                    plannedhours,
                                } = item
                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {showMore ? (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}{' '}
                                                    {employeeid ? `(${employeeid})` : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {projectname}{' '}
                                                    {projectcode
                                                        ? `(${projectcode})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {rolename}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {weekstartdate}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {weekenddate}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {plannedhours} Hrs
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {timsheethours} Hrs
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="px-1 py-1 capitalize border ">
                                                    {employeename}{' '}
                                                    {employeeid ? `(${employeeid})` : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {exception}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {variation !== null
                                                        ? `${(variation * 100).toFixed(
                                                              0,
                                                          )}`
                                                        : 'N/A'}{' '}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {timesheetstatus}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {approvedemployeename}{' '}
                                                    {approveremployeeid
                                                        ? `(${approveremployeeid})`
                                                        : ''}
                                                </td>
                                                <td className="px-1 py-1 border ">
                                                    {approverremarks}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ExceptionTimesheetReport
