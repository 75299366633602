import Tooltip from "../Tooltip"

const IconButton = ({ children, onClick, className, disabled = false, title = '', id }) => {

    return (
        <Tooltip
            id={id || Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)}
            title={title}
            place="bottom"
            variant="info"
            content='Create new Project'
        >
            <button disabled={disabled} id={id || Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)}
                onClick={onClick} type="button"
                className={`mx-1 focus:ring-0 focus:outline-none font-[500] rounded-full text-sm p-2 text-center inline-flex items-center ${className}`}>
                {children}
            </button>
        </Tooltip>

    )
}

export default IconButton