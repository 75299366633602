import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NextIcon, PreIcon } from 'src/Assets/icons'
import DownloadAsCsv from 'src/Components/DownloadAsCsv'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import {
    DOWNLOAD_REPORT_BY_ENDPOINT,
    GET_CAPACITY_REPORT,
} from 'src/Redux/actions/reports'

const colss = [
    'Employee Name',
    'Company',
    'City',
    'Worker Type',
    'Gender',
    'Email',
    'Phone',
    'DOJ',
    'User Status',
    'Emp Status',
    'Workforce Group',
    'Service Line',
    'Band',
]
const cols1 = [
    'Employee Name',
    'Designation',
    'Department',
    'Total Exp',
    'Primary Skill',
    'Expertise',
    'Technology',
    'Domain',
    'Manager Name',
]
const cols2 = [
    'Employee Name',
    'Allocation Status',
    'Project Allocation',
    'Bench Allocation',
    'Bench Age',
    'Project 1',
    'Role 1',
    'Release Date 1',
    'Allocation 1',
]
const cols3 = [
    'Employee Name',
    'Project 2',
    'Role 2',
    'Release Date 2',
    'Allocation 2',
    'Project 3',
    'Role3',
    'Release Date 3',
    'Allocation 3',
]

const rows = [10, 25, 50]

const ReportList = ({
    filters,
    reportMasterList,
    reportId,
    page,
    setPage,
    limit,
    setLimit,
}) => {
    const dispatch = useDispatch()
    const [currentColsIndex, setCurrentColsIndex] = useState(0)
    const allCols = [colss, cols1, cols2, cols3]
    const { capacityreport } = useSelector((state) => state?.WorkforceReport)

    const handleRightSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex + 1) % allCols.length)
    }
    const handleLeftSlide = () => {
        setCurrentColsIndex((prevIndex) => (prevIndex - 1) % allCols.length)
    }
    const currentCols = allCols[currentColsIndex]

    const handlePagination = (page) => {
        dispatch(
            GET_CAPACITY_REPORT({ ...filters, Limit: limit, Page: page?.selected + 1 }),
        )
        setPage(page?.selected + 1)
    }

    const handleRows = (row) => {
        setLimit(row)
        if (row > 10) {
            setPage(1)
        }
        dispatch(
            GET_CAPACITY_REPORT({ ...filters, Limit: row, Page: row > 10 ? 1 : page }),
        )
    }

    const report = reportMasterList?.find((report) => report?.reportid === reportId)
    const fileName = report ? `${report.reportname}` : ''

    const totalRecords = useMemo(() => {
        return capacityreport?.length > 0
            ? capacityreport[0]?.totalcount
            : capacityreport?.length
    }, [capacityreport])

    return (
        <div className="relative mt-4 p-2 rounded-lg shadow bg-white h-[calc(100%-250px)]">
            <div className="flex items-center justify-between mb-2 ">
                <div className="absolute flex items-center justify-start space-x-2 top-3 right-20 ">
                    <button
                        disabled={currentColsIndex === 0}
                        onClick={handleLeftSlide}
                        className="disabled:text-gray-400 disabled:border-0  z-10  flex justify-center items-center font-[500]  h-8 w-8 text-lg rounded-md  text-secondary"
                    >
                        {PreIcon}
                    </button>
                    <button
                        disabled={currentColsIndex === 3}
                        onClick={handleRightSlide}
                        className="disabled:text-gray-400 disabled:border-0 z-10  flex justify-center items-center font-[500] h-8 w-8 text-lg rounded-md text-secondary"
                    >
                        {NextIcon}
                    </button>
                </div>

                <div className="flex items-center justify-between w-full space-x-6">
                    <p className="text-primary font-[500]">
                        Reports Details ({totalRecords})
                    </p>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={totalRecords}
                            limit={limit}
                            currentPage={page}
                            handlePagination={handlePagination}
                        />
                        <div className="w-32 ">
                            <FilterButtonTab
                                setSelectedtab={handleRows}
                                selectedTab={limit}
                                tabs={rows}
                            />
                        </div>
                    </div>
                    <DownloadAsCsv
                        fileName={fileName}
                        limit={totalRecords}
                        endpoint="Download_capacity_report"
                        APICALL={DOWNLOAD_REPORT_BY_ENDPOINT}
                        filters={filters}
                    />
                </div>
            </div>
            {capacityreport === null ? (
                <div className="flex items-center justify-center w-full h-1/2">
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div>
            ) : (
                <div className="h-[calc(90%)] overflow-scroll">
                    <table className="relative w-full text-sm">
                        <thead>
                            <tr className="text-left capitalize bg-gray-50">
                                {currentCols?.map((col, index) => {
                                    return (
                                        <th
                                            key={col}
                                            title={col}
                                            scope="col"
                                            className="p-1 font-[500] border text-start"
                                        >
                                            {col}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="text-gray-600">
                            {capacityreport?.map((item) => {
                                let {
                                    company,
                                    EmpID,
                                    EmpName,
                                    City,
                                    WorkerType,
                                    Gender,
                                    Email,
                                    Phone,
                                    DOJ,
                                    Status,
                                    WorkforceGroup,
                                    Designation,
                                    Band,
                                    Department,
                                    PrimarySkill,
                                    Expertise,
                                    Technology,
                                    domain,
                                    AllocationStatus,
                                    TotalAllocation,
                                    Project1,
                                    Role1,
                                    StaffingEndDate1,
                                    Allocation1,
                                    Project2,
                                    Role2,
                                    StaffingEndDate2,
                                    Allocation2,
                                    Project3,
                                    Role3,
                                    StaffingEndDate3,
                                    Allocation3,
                                    servicelinename,
                                    uId,
                                    ManagerName,
                                    ManagerCode,
                                    BenchAllocation,
                                    emp_statusname,
                                } = item
                                return (
                                    <tr key={uId} className="text-left cursor-pointer">
                                        {currentColsIndex === 0 && (
                                            <>
                                                <td className="px-2 py-1 border w-[240px]">
                                                    {EmpName} {EmpID ? `(${EmpID})` : ''}{' '}
                                                </td>
                                                <td className="py-1 px-2.5 border ">
                                                    {company}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {City}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {WorkerType}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Gender}
                                                </td>
                                                <td className="p-1 px-2 border">
                                                    {Email}
                                                </td>
                                                <td className="w-[70px] p-1 px-2 border ">
                                                    {Phone}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {DOJ}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Status}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {emp_statusname}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {WorkforceGroup}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {servicelinename}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Band}
                                                </td>
                                            </>
                                        )}
                                        {currentColsIndex === 1 && (
                                            <>
                                                <td className="p-1 px-2 border w-[240px]">
                                                    {' '}
                                                    {EmpName} {EmpID ? `(${EmpID})` : ''}{' '}
                                                </td>
                                                <td className="w-40 p-1 px-2 border">
                                                    {Designation}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Department}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {item['TotalExp(yrs)']}{' '}
                                                    {item['TotalExp(yrs)'] ? 'Yrs' : ''}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {PrimarySkill}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Expertise}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Technology}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {domain}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {ManagerName}{' '}
                                                    {ManagerCode
                                                        ? `(${ManagerCode})`
                                                        : ''}
                                                </td>
                                            </>
                                        )}
                                        {currentColsIndex === 2 && (
                                            <>
                                                <td className="p-1 px-2 border w-[240px]">
                                                    {' '}
                                                    {EmpName} {EmpID ? `(${EmpID})` : ''}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {AllocationStatus}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {TotalAllocation !== null
                                                        ? `${(
                                                              TotalAllocation * 100
                                                          ).toFixed(0)}%`
                                                        : 'N/A'}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {BenchAllocation !== null
                                                        ? `${(
                                                              BenchAllocation * 100
                                                          ).toFixed(0)}%`
                                                        : 'N/A'}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {item['BenchAge(days)']} Days
                                                </td>
                                                <td className="w-40 p-1 px-2 border">
                                                    {Project1}
                                                </td>
                                                <td className="p-1 px-2 border w-22">
                                                    {Role1}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {StaffingEndDate1}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {Allocation1 !== null
                                                        ? `${(Allocation1 * 100).toFixed(
                                                              0,
                                                          )}%`
                                                        : 'N/A'}{' '}
                                                </td>
                                            </>
                                        )}
                                        {currentColsIndex === 3 && (
                                            <>
                                                <td className="px-1 py-1 border w-[240px]">
                                                    {' '}
                                                    {EmpName} {EmpID ? `(${EmpID})` : ''}{' '}
                                                </td>
                                                <td className="w-40 p-1 px-2 border">
                                                    {Project2}
                                                </td>
                                                <td className="p-1 px-2 border w-[150px]">
                                                    {Role2}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {StaffingEndDate2}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {Allocation2 !== null
                                                        ? `${(Allocation2 * 100).toFixed(
                                                              0,
                                                          )}%`
                                                        : 'N/A'}{' '}
                                                </td>
                                                <td className="w-40 p-1 px-2 border">
                                                    {Project3}
                                                </td>
                                                <td className="w-32 p-1 px-2 border">
                                                    {Role3}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {StaffingEndDate3}{' '}
                                                </td>
                                                <td className="p-1 px-2 border ">
                                                    {' '}
                                                    {Allocation3 !== null
                                                        ? `${(Allocation3 * 100).toFixed(
                                                              0,
                                                          )}%`
                                                        : 'N/A'}{' '}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ReportList
