import { AlertError } from "..";

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

const getTitleOfKeyname = (value) => {
    if (value) {
        let titleis = value
            ?.replace(/([A-Z])/g, " $1")
            ?.replace(/^./, function (str) {
                return str.toUpperCase();
            });
        return titleis.replace(/_/g, " ");
    }
    return ""
};
const CheckObjectValidation = (data, notRequired, showError = true) => {
    for (const keyname in data) {
        if (keyname === 'email') {
            if (!validateEmail(data[keyname])) {
                if(showError){
                    AlertError('Email format is not valid !')
                }
                return { isvalid: false, keyname }
            }
        }else if (!notRequired.includes(keyname) && (data[keyname] === undefined || data[keyname] === null || data[keyname].length === 0 || data[keyname] === '\r\n' || data[keyname] === 0 || data[keyname] === '')) {
            if(showError){
                AlertError(getTitleOfKeyname(keyname), `${getTitleOfKeyname(keyname)} is required !`)
            }
            return { isvalid: false, keyname }
        }
    }
    return { isvalid: true, keyname: '' }
}

const CheckListObjectValidation = (data, notRequired) => {
    for (let index = 0; index < data.length; index++) {
        const obj = data[index];
        let res = CheckObjectValidation(obj, notRequired)
        if (res?.isvalid === false) {
            return { ...res, rowNo: index + 1 }
        }
        
    }
    return  { isvalid: true }
}

const  formatIdForPayload = (id) => {
			if (Array.isArray(id) && id.length > 0) {
				return `{${id.join(',')}}`
			} else if (id) {
				return `{${id}}`
			}
			return '{}' // Return empty object string if no ID or empty
		}

export {
    CheckObjectValidation,
    CheckListObjectValidation,
    getTitleOfKeyname,
    formatIdForPayload
}
