// import { Navigate, Outlet } from "react-router-dom";

// const useAuth = () => {
//     const user = { loggedIn: localStorage.getItem("token") !== null ? true : false }
//     return user && user.loggedIn
// }
// const ProtectedRoute = () => {
//     const isAuth = useAuth();
//     return isAuth ? <Outlet /> : <Navigate to='/app/login' />
// }

// export default ProtectedRoute;


import { Navigate, Outlet } from "react-router-dom";
import React from "react";

const useAuth = () => {
    const user = { loggedIn: localStorage.getItem("token") !== null };
    return user && user.loggedIn;
};

const ProtectedRoute = React.forwardRef((props, ref) => {
    const isAuth = useAuth();

    return isAuth ? (
        <div className="w-full h-full" ref={ref}>
            <Outlet />
        </div>
    ) : (
        <Navigate to="/app/login" />
    );
});

export default ProtectedRoute;
