import { ACTION_GET_NOTIFICATION_FOR_HOMEPAGE } from "src/Redux/types"

const initialState = {
    notificationList: [],
    homePagemetrics: null,
    effortHistoryMatrics: [],
    revenueDashboard: [],
    profitabilityDashboard: [],
    allocationWorkforceDashboard: [],
    bandWiseallocationWorkforceDashboard: [],
    monthWiseallocationWorkforceDashboard: [],
    skillWiseallocationWorkforceDashboard: []
}

const Analytics = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_NOTIFICATION_FOR_HOMEPAGE:
            return { ...state, notificationList: action.data }
        case 'GET_METRICS_FOR_HOME':
            return { ...state, homePagemetrics: action.data }
        case 'GET_EFFORT_HISTROY_METRICS':
            return { ...state, effortHistoryMatrics: action.data }
        case 'GET_REVENUE_FOR_DASHBOARD':
            return { ...state, revenueDashboard: action.data }
        case 'GET_PROFITABILITY_FOR_DASHBOARD':
            return { ...state, profitabilityDashboard: action.data }
        case 'GET_ALLOCATION_FOR_WORKFORCE_DASHBOARD':
            return { ...state, allocationChart: action.data }
        case 'GET_BANDWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD':
            return { ...state, bandWiseallocationChart: action.data }
        case 'GET_MONTHWISE_ALLOCATION_FOR_WORKFORCE_DASHBOARD':
            return { ...state, monthWiseallocationChart: action.data }
        case 'GET_PRIMARYSKILL_ALLOCATION_FOR_WORKFORCE_DASHBOARD':
            return { ...state, skillWiseallocationChart: action.data }
        default:
            return state
    }
}


export default Analytics
