

const AppLogo = () => {
    return <div className="flex justify-start items-center space-x-2 p-1">
        <img
            className='p-1 h-9 w-9 object-fill '
            src={process.env.REACT_APP_APPBAR_LOGO}
            alt='logo'
        /> <div className="pl-7 font-oxanium font-[500]  text-lg text-white">
            ProSrv<span className="text-white font-semibold">360</span>
        </div>
    </div>
}

export default AppLogo;