import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState, useMemo, useEffect } from 'react';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { useSelector } from 'react-redux';
import { GET_CITY_MASTER } from 'src/Redux/actions/workforce/talent';
import { getMasterCityPayload } from 'src/Services/constant/masterdatapayload';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';


const LocDetails = (props) => {
  const [city, setCity] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const { cityList } = useSelector((state) => state.UserManagementList);
  const [checkvalidation, setCheckvalidation] = useState(false);


  useMemo(() => {
    if (city?.cityname) {
      setSearchTerm(city?.cityname)
    }
  }, [city])

  useEffect(() => {
    if (detailsData?.cityname && detailsData?.comcityid) {
      setCity({ cityname: detailsData.cityname, comcityid: detailsData.comcityid });
    }
  }, [detailsData?.cityname, detailsData?.comcityid]);

	const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);
    let item = {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      locationname: detailsData?.locationname,
      locationcode: detailsData?.locationcode,
      comlocationid: detailsData?.comlocationid,
      comcityid: city?.comcityid,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
    };

    let isValidated = CheckObjectValidation(detailsData, [
      'notes',
      'comlocationid',
      'modifiedtimestamp',
      'modifiedcomuserid',
      'activeflag',
    ]);

    if (isValidated?.isvalid) {
      let payload = {
        locationjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='Locname' className='block font-[500] mb-2'>
            Location Name
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('locationname', e.target.value);
              }
            }}
            title='locationname'
            id='locationname'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='locationname'
            required=''
            value={detailsData?.locationname || ''}
            maxLength={50}
          />
          {!detailsData?.locationname && checkvalidation && (
            <small className='text-red-400'>Location Name is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='locationcode' className='block font-[500] mb-2'>
            Location Code
          </label>
          <input
            disabled
            type='text'
            title='locationcode'
            id='locationcode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='locationcode'
            required=''
            onChange={(e) => handleChangeInput('locationcode', e.target.value)}
            value={detailsData?.locationcode || ''}
          />
        </div>
        <div>
          <label htmlFor='comcityid' className='block font-[500] mb-2'>
            City
            <span className="text-red-300"> *</span>
          </label>
          <div>
            <CustomAutoSearch
              disabled={canEdit}
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_CITY_MASTER}
              searchPayload={{ ...getMasterCityPayload(), searchname: searchTerm, }}
              setSearchTerm={setSearchTerm}
              options={cityList}
              idkey='cityname'
              optionlabelKey='cityname'
              getSelectedOption={setCity}
              placeholder='Enter City Name'
              className='bg-gray-50 text-gray-600 border border-gray-200 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
            />
            {!city?.comcityid && checkvalidation && (
              <small className='text-red-400'>City is required!</small>
            )}
          </div>
          {!city?.comcityid}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <PrimaryButton
                // disabled={!canEdit}
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(LocDetails);
