import {
    BriefcaseIcon,
    BanknotesIcon,
    UserGroupIcon,
    BuildingOfficeIcon,
    ClockIcon,
    UserCircleIcon,
    AdjustmentsVerticalIcon,
    ClipboardIcon,
    CircleStackIcon,
    UsersIcon,
    CalendarIcon
} from '@heroicons/react/24/outline';

import SideMenu from "./sidemenu";
import { useCallback, useState } from 'react';

const submenuIcons = {
    "Configuration": <AdjustmentsVerticalIcon className="h-5 w-5" />,
    "Master Data": <CircleStackIcon className="h-5 w-5" />,
    "User Access": <UsersIcon className="h-5 w-5" />,
    "Finance Dashboards": <BanknotesIcon className="h-5 w-5" />,
    "Project Dashboards": <BriefcaseIcon className="h-5 w-5" />,
    "Workforce Dashboards": <UserGroupIcon className="h-5 w-5" />,
    "Finance Reports": <BanknotesIcon className="h-5 w-5" />,
    "Project Reports": <BriefcaseIcon className="h-5 w-5" />,
    "Workforce Reports": <UserGroupIcon className="h-5 w-5" />,
    "Finance": <BanknotesIcon className="h-5 w-5" />,
    "Demands": <BriefcaseIcon className="h-5 w-5" />,
    "Talent": <UserGroupIcon className="h-5 w-5" />,
    "E-Profile": <ClipboardIcon className="h-5 w-5" />,
    "Projects": <BriefcaseIcon className="h-5 w-5" />,
    "Planning": <CalendarIcon className="h-5 w-5" />,
    "Staffing": <UserCircleIcon className="h-5 w-5" />,
    "Timesheet": <ClockIcon className="h-5 w-5" />,
    "Subscriptions": <BuildingOfficeIcon className='h-5 w-5' />,

};


const SidebarMenu = () => {
    const [onHover, setOnHover] = useState(false)

    const handleHover = useCallback(() => {
        const debounceTimer = setTimeout(() => {
            setOnHover(true)
        }, 200)
        return () => {
            clearTimeout(debounceTimer)
        }
        // eslint-disable-next-line
    }, [onHover])

    const handleHoverRemove = useCallback(() => {
        const debounceTimer = setTimeout(() => {
            setOnHover(false)
        }, 200)
        return () => {
            clearTimeout(debounceTimer)
        }
        // eslint-disable-next-line
    }, [onHover])

    return (
        <div onMouseOver={handleHover} onMouseLeave={handleHoverRemove} className="z-10 min-h-screen bg-white text-white left-0 shadow-lg fixed top-0">
            <div className="sidebar min-h-screen w-16 overflow-y-auto border-r hover:w-52 hover:shadow-lg">
                <div className="w-full pt-10 flex h-screen flex-col items-center justify-start py-4 pb-6">
                    <SideMenu submenuIcons={submenuIcons} />
                </div>
            </div>
        </div>


    )
}

export default SidebarMenu;