import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import IconButton from 'src/Components/Buttons/IconButton'
import { IMPORT_PROJECT_EXPENSES } from 'src/Redux/actions/projects/financials'
import * as XLSX from 'xlsx'
const getTanAndComId = () => {
	return {
		Tenantid: Number(localStorage.getItem('Tenantid') || 0),
		comuserid: JSON.parse(localStorage.getItem('comuserid')),
	}
}
const UploadExpenses = ({ getDefaultExpensesData, projectsOfM }) => {
	const dispatch = useDispatch()
	let [isOpen, setIsOpen] = useState(false)
	const [columns, setColumns] = useState([])
	const [feedbackMessage, setFeedbackMessage] = useState('')

	useEffect(() => {
		if (isOpen) {
			// Clear feedback message when the modal is opened
			setFeedbackMessage('')
		}
	}, [isOpen])

	function closeModal() {
		setIsOpen(false)
		// dispatch(GET_PROJECT_EXPENSES(getDefaultExpensePayload()))
	}

	function openModal() {
		setIsOpen(true)
	}

	const handleFile = async (e) => {
		const file = e.target.files[0]
		if (!file) {
			return
		}

		setFeedbackMessage('')
		const data = await file.arrayBuffer()
		const workbook = XLSX.readFile(data, { sheetRows: 5000 })
		const worksheet = workbook.Sheets[workbook.SheetNames[0]]
		const jsonData = XLSX.utils.sheet_to_json(worksheet, {
			raw: false,
			header: worksheet ? 0 : 1,
			cellDates: true, // Parse date strings into Date objects
			dateNF: 'YYYY-MM-DD',
			blankrows: false,
			rawDates: false, // Format date objects as strings in "YYYY-MM-DD" format
			defval: '', // Default value for empty cells
		})
		const columnMapping = {
			'Project Code': 'project_code',
			'Project Name': 'project_name',
			Month: 'month',
			Year: 'year',
			Overhead: 'overhead',
			Description: 'description',
			'Transaction Date': 'transaction_date',
			'Transaction Id': 'transaction_id',
			Amount: 'amount',
			Currency: 'currency',
			'Linked User': 'linked_user',
			'Cost Center Code': 'cost_center_code',
		}
		const transformedData = jsonData?.map((item) => {
			const transformedItem = {}
			for (const [excelColumn, apiKey] of Object.entries(columnMapping)) {
				if (excelColumn === 'Transaction Date') {
					const [month, day, year] = item[excelColumn].split('/')
					const fullYear = year.length === 2 ? `20${year}` : year
					const formattedDate = `${fullYear}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
					transformedItem[apiKey] = formattedDate
				} else if (excelColumn === 'Amount' || excelColumn === 'Year') {
					transformedItem[apiKey] = Number(item[excelColumn])
				} else {
					transformedItem[apiKey] = item[excelColumn]
				}
			}
			transformedItem['createdcomuserid'] = getTanAndComId()?.comuserid
			transformedItem['comtenantid'] = getTanAndComId()?.Tenantid
			return transformedItem
		})
		setColumns(transformedData)
	}

	const handleSubmit = async () => {
		if (columns.length === 0) {
			setFeedbackMessage('No data uploaded.')
			return // Return early if no data to upload
		}
		let finalPayload = { importprojectexpensejson: columns }
		let res = await dispatch(IMPORT_PROJECT_EXPENSES(finalPayload))
		// dispatch(GET_PROJECT_EXPENSES(getDefaultExpensePayload(projectsOfM[0]?.projectid)))
		setFeedbackMessage('')
		if (res !== null) {
			getDefaultExpensesData()
		} else {
			setFeedbackMessage('Failed to upload data.')
		}
	}

	return (
		<Fragment>
			<IconButton
				title="Upload your Expense File"
				type="button"
				onClick={openModal}
				className=" text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-5 h-5"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
					/>
				</svg>
			</IconButton>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex items-center justify-center min-h-full p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
									<Dialog.Title
										as="h3"
										className="flex justify-between items-center text-lg font-[500] leading-6 text-gray-900"
									>
										<span>Upload your Expense File</span>
										<button
											onClick={closeModal}
											type="button"
											className="text-textSecondary border border-gray-300 focus:ring-1 focus:outline-none focus:ring-gray-300 font-[500] rounded-full text-sm p-2 text-center inline-flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-4 h-4"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
											<span className="sr-only">Close icon</span>
										</button>
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm text-gray-500">Upload your expense details by file</p>
										<input
											className="mt-2 file:mr-2 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-[500] hover:file:cursor-pointer file:bg-secondary/10  file:text-secondary"
											type="file"
											id=""
											onChange={(e) => handleFile(e)}
										/>
										{feedbackMessage && <p className="mt-2 text-red-400">{feedbackMessage}</p>}{' '}
									</div>
									<div className="flex justify-end mt-4">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-[500] text-white focus:outline-none focus-visible:ring-2 "
											onClick={handleSubmit}
										>
											Upload
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	)
}
export default UploadExpenses
