export const SkillItem = () => {
  return {
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    skillmasterid: 0,
    skill: "",
    skillarea: 0,
    skilltype: "",
    isactiveflag: true,
    createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
    notes: "",
  }
}

export const SkillAreaItem = () => {
  return {
    skillareamasterid: null,
    skillarea: "",
    comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
    isactiveflag: true,
    createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
    notes: null,
  }
}

export const detailNotRequired = [
  'skillareamasterid',
  'skillmasterid',
  'notes'
];

