import IconButton from 'src/Components/Buttons/IconButton'
import SelectOverHead from './overHead'
import { budgetRow } from './defaultItems'
import SelectCurrency from './currency'
import SelectOption from 'src/Components/SelectOption'
import { useSelector } from 'react-redux'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

const cols = ["Overhead", "Description", "Units", "Rate", "Estd Budget",
    "Currency", "Client Billable"]

const BudgetTable = ({ hooksValues, disabled }) => {
    const currencyList = useSelector(state => state?.Projects?.currencyList)

    const { budget, setBudget, ProjectOverhead, project } = hooksValues

    const handleAdd = () => {
        setBudget([...budget, { ...budgetRow(), datcomcurrencyid: project?.defaultcomcurrencyid }]);
    };

    const handleDelete = (id) => {
        if (budget?.length > 1) {
            setBudget(budget?.filter((item) => item?.uId !== id));
        }
    };

    const handleInputChange = (event) => {
        const { name, value, id } = event.target
        const newbudget = budget.map((item) => {
            if (item?.uId === id) {
                item[name] = value;
                if (name === 'units' || name === 'rate') {
                    item['plannedcost'] = Number(item?.units || 0) * Number(item?.rate || 0)
                }
            }
            return item;
        });
        setBudget(newbudget);
    };


    return (
        <div className="bg-white text-sm w-full relative">
            <table className="w-full relative overflow-y-scroll ">
                <thead>
                    <tr className="text-left capitalize bg-gray-50">
                        {cols?.map((col) => {
                            return (
                                <th key={col} title={col} scope="col" className="p-2 font-[500] border text-start">{col} <span className='text-red-300'>* </span></th>
                            )
                        })}
                        {disabled && <th className="p-2 border font-[500] text-start">Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {budget?.map((item) => {
                        const { uId, description, units, rate,
                            // isbillable, 
                            overheadid,
                            //  plannedcost,
                            datcomcurrencyid } = item
                        return (
                            <tr key={uId} className="text-left">
                                <td className="border px-2">
                                    <SelectOverHead
                                        canEdit={!disabled}
                                        value={overheadid}
                                        rowId={uId}
                                        handleChange={handleInputChange}
                                        options={ProjectOverhead}
                                    />
                                </td>
                                <td className="border px-2"><input id={uId} disabled={!disabled} type='text' name='description' onChange={handleInputChange} maxLength={50} className="min-w-[400px] w-full focus:outline-none hover:outline-none" value={description} /></td>
                                <td className="border px-2"><input id={uId} disabled={!disabled} type='number' name='units' onChange={handleInputChange} maxLength={3} className="w-8 focus:outline-none hover:outline-none" value={units} /></td>
                                <td className="border px-2"><input id={uId} disabled={!disabled} type='number' name='rate' onChange={handleInputChange} maxLength={7} className="w-12 focus:outline-none hover:outline-none" value={rate} /></td>
                                <td className="border px-2"> {Number(units) * Number(rate)} </td>
                                <td className="border px-2">
                                    <SelectCurrency
                                        canEdit={true}
                                        value={datcomcurrencyid}
                                        rowId={uId}
                                        handleChange={handleInputChange}
                                        options={currencyList}
                                    />
                                </td>
                                <td className="border px-2">
                                    <SelectOption
                                        disabled={!disabled}
                                        onChange={handleInputChange}
                                        id={uId}
                                        name='isbillable'
                                        value={item?.isbillable || false}
                                        options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                    />
                                </td>
                                {disabled && <td className="border px-2">
                                    <div className='flex justify-start items-center'>
                                        {budget?.length !== 1 && <IconButton title='Add Budget' onClick={() =>
                                            handleDelete(uId)
                                        }
                                            className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                            <MinusIcon className="w-3" />
                                        </IconButton>}
                                        <span className="hidden addIconbutton-add-btn">
                                            <IconButton title='Delete Budget' onClick={handleAdd} className='bg-primary p-0.5 disabled:bg-primary/20 text-white'>
                                                <PlusIcon className="w-3" />
                                            </IconButton>
                                        </span>
                                    </div>
                                </td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default BudgetTable;

