import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';


const Details = (props) => {
  const { technologyGroup } = useSelector((state) => state.UserManagementList);
  const { detailSave, detailsData, setDetailsData, canEdit, setCanEdit } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);

  const handleSubmit = () => {
    setCheckvalidation(true);

    let item = {
      comtenantid: JSON.parse(localStorage.getItem('Tenantid')),
      technology: detailsData?.technology,
      techgroup: detailsData?.techgroup,
      technologymasterid: detailsData?.technologymasterid,
      isactiveflag: detailsData?.isactiveflag,
      createdcomuserid: JSON.parse(localStorage.getItem('comuserid')),
      notes: detailsData?.notes,
    };
    let isValidated = CheckObjectValidation(detailsData, ['notes'])
    if (isValidated?.isvalid) {

      let payload = {
        technologyjson: [item],
      };
      detailSave(payload);
    };
  };
  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='technologycode' className='block font-[500] mb-2'>
            Technology Code
          </label>
          <input
            disabled
            maxLength={50}
            type='text'
            title='technologycode'
            id='technologycode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Technology Code'
            required=''
            value={detailsData?.technologycode || ''}
            onChange={(e) => {
              if (/^[A-Za-z0-9\s]*$/.test(e.target?.value)) {
                handleChangeInput('technologycode', e.target.value);
              }
            }}
          />
        </div>
        <div>
          <label htmlFor='technology' className='block font-[500] mb-2'>
            Technology
            <span className="text-red-300"> *</span>
          </label>
          <input
            disabled={canEdit}
            type='text'
            title='technology'
            id='technology'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2 '
            placeholder='technology'
            required=''
            onChange={(e) => handleChangeInput('technology', e.target.value)}
            value={detailsData?.technology || ''}
            maxLength={50}
          />
          {!detailsData?.technology && checkvalidation && (
            <small className='text-red-400'>Technology is required!</small>
          )}{' '}
        </div>
        <div>
          <label htmlFor='techgroup' className='block font-[500] mb-2'>
            Technology Group
            <span className="text-red-300"> *</span>
          </label>
          <CustomhtmlSelect
            disabled={canEdit}
            options={technologyGroup || []}
            value={detailsData?.techgroup || ''}
            noDataLabel='No techarea'
            name='techgroup'
            labelkey='techarea'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
            onChange={handleChangeInput}
            id='techgroupid'
          />
          {!detailsData?.techgroup && checkvalidation && (
            <small className='text-red-400'>Technology Group is required!</small>
          )}
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        {!canEdit && (
          <div className='fixed bottom-0 right-6 mt-2'>
            <div className='gap-2 flex justify-end items-center'>
              <button
                onClick={() => setCanEdit(false)}
                type=''
                className='px-6 border bg-white  border-primary text-primary rounded mt-0 h-9 py-1.5 mb-2'
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={canEdit}
                className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
                onClick={handleSubmit}
              >
                Save
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Details);
