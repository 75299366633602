import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { memo, useState } from 'react';
import SelectDepartmentHead from './selectDepartmentHead'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { detailNotRequired } from './defaultValue';


const DepartmentDetails = (props) => {
  const {
    detailSave,
    detailsData,
    setDetailsData,
    accountSelect,
    setAccountSelect,
  } = props;
  const [checkvalidation, setCheckvalidation] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckvalidation(true);

    let item = {
      ...detailsData,
      departmentheadid: parseFloat(accountSelect?.comuserid),
    }
    let isValidated = CheckObjectValidation(item, detailNotRequired);
    if (isValidated?.isvalid) {
      let payload = {
        departmentjson: [item],
      };
      detailSave(payload);
    };
  }

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  const handleSelectManager = (select) => {
    setAccountSelect(select);
    setDetailsData({ ...detailsData, departmentheadid: select.comuserid });
    setCheckvalidation(false);
  };
  
  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='departmentcode' className='block font-[500] mb-2'>
            Department Code
          </label>
          <input
            disabled
            maxLength={50}
            type='text'
            title='departmentcode'
            id='departmentcode'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Deaprtment Code'
            required=''
            // value={detailsData?.departmentcode || ''}
            onChange={(e) => handleChangeInput('departmentcode', e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='department' className='block font-[500] mb-2'>
            Department
            <span className="text-red-300"> *</span>
          </label>
          <input
            type='text'
            title='department'
            id='department'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded-md block w-full p-2'
            placeholder='Department'
            required=''
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('department', e.target.value)
              }
            }}
            value={detailsData?.department || ''}
            maxLength={50}
          />
          {!detailsData?.department && checkvalidation && (
            <small className='text-red-400'>Department is required!</small>
          )}
        </div>
        <div>
          <label htmlFor='departmentheadname' className='block font-[500] mb-2'>
            Department Head
            <span className="text-red-300"> *</span>
          </label>
          <div className='relative'>
            <SelectDepartmentHead
              accountSelect={accountSelect}
              handleSelectManager={handleSelectManager}
              setAccountSelect={setAccountSelect}
            />
            {!accountSelect?.comuserid && checkvalidation && (
              <small className='text-red-400'>Department Head is required!</small>
            )}
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 right-6 mt-2'>
        <div className='gap-2 flex justify-end items-center'>
          <PrimaryButton
            className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default memo(DepartmentDetails);
