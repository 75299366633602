import { ToastContainer } from 'react-toastify'
import CreateNewPassword from 'src/Components/Auth/createNewPassword'

const CreateNewPasswordLayout = () => {

    return (
        <>
            <section
                style={{ backgroundImage: `url(${process.env.REACT_APP_LOGIN_BG})` }}
                className="h-screen bg-no-repeat bg-cover bg-center"
            >
                <div className="h-full flex justify-center items-center">
                    <div className=" bg-white rounded-lg shadow-lg">
                        <CreateNewPassword />
                    </div>
                </div>
            </section>
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </>
    )
}

export default CreateNewPasswordLayout
