import { useState } from "react"
import { useDispatch } from "react-redux"
import IconButton from "src/Components/Buttons/IconButton"
import { PROCESS_PROFITABLE } from "src/Redux/actions/projects/financials"
import FinancialBilling from './billing'

const ProcessProfibilty = ({ projectDetail, getProjectProfitableData, year, month }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleApiCall = async () => {
        setLoading(true)
        let payload = {
            Tenantid: Number(localStorage.getItem('Tenantid') || 0),
            Projectid:projectDetail?.projectid,
            yearmonth: `${year}${month <= 9 ? `0${month}` : month}`,
            createduserid: Number(localStorage.getItem('comuserid') || 0),
        }
        await dispatch(PROCESS_PROFITABLE(payload))
        getProjectProfitableData()
        setLoading(false)
    }

    return (
        <div className="flex justify-start items-center ">
            <IconButton onClick={handleApiCall} className='p-0 w-9 h-9 bg-primary text-white' title="Calculate Profitability">
                {loading ? <div className='w-7 h-7 flex justify-center items-center'>
                    <div
                        className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                </div> : <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#fff"><path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z"/></svg>
                }
            </IconButton>
            {/* <FinancialBilling projectDetail={projectDetail} y={year} m={month} /> */}
        </div>
    )
}

export default ProcessProfibilty