import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ProjectWiseReportsFilter from 'src/Filter/reports/workforce/projectWise'
import { GET_SERVICELINE_MASTER } from 'src/Redux/actions/masterdata/user'
import { GET_BUSINESS_UNIT_MASTER_LIST } from 'src/Redux/actions/projects'
import { GET_TIMESHEET_PROJECTS_M } from 'src/Redux/actions/projects/timesheet'
import {
	GET_PROJECT_WISE_UTILIZATION_REPORT,
	GET_REPORT_MASTER_LIST,
} from 'src/Redux/actions/reports'
import { GET_SKILL_MASTER } from 'src/Redux/actions/workforce/eprofile'
import {
	getDeafultSkillData,
	getDefaultBusinessUnitPayload,
	getProjectUtilizationReportsPayload,
} from 'src/Services/constant/defaultPayload'
import { getMasterServiceLinePayload } from 'src/Services/constant/masterdatapayload'
import ProjectUtilizationReports from './table'

const ProjectWiseUtilizationReports = ({ setReportId, reportId }) => {
    const [filters, setFilters] = useState(getProjectUtilizationReportsPayload())
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const dispatch = useDispatch()
    const location = useLocation()
    const [project, setProject] = useState([])
    const searchParams = new URLSearchParams(location.search.split('?')[1])
    const datmenuId = searchParams.get('datmenuid')
    const { projectwiseReport, reportMasterList } = useSelector(
        (state) => state?.WorkforceReport,
    )

    useEffect(() => {
        dispatch(GET_REPORT_MASTER_LIST(+datmenuId))
    }, [datmenuId, dispatch])

    useEffect(() => {
        dispatch(
            GET_PROJECT_WISE_UTILIZATION_REPORT(getProjectUtilizationReportsPayload()),
        )
        dispatch(GET_TIMESHEET_PROJECTS_M())
        dispatch(GET_SERVICELINE_MASTER(getMasterServiceLinePayload()))
        dispatch(GET_BUSINESS_UNIT_MASTER_LIST(getDefaultBusinessUnitPayload()))
        dispatch(GET_SKILL_MASTER(getDeafultSkillData()))
    }, [dispatch])

    return (
        <>
            <div className="p-2 py-3 bg-white rounded-md shadow">
                <ProjectWiseReportsFilter
                    project={project}
                    setProject={setProject}
                    setReportId={setReportId}
                    reportId={reportId}
                    setFilters={setFilters}
                    filters={filters}
                    page={page}
                    limit={limit}
                />
            </div>
            <ProjectUtilizationReports
                projectwiseReport={projectwiseReport}
                filters={filters}
                reportId={reportId}
                reportMasterList={reportMasterList}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />
        </>
    )
}

export default memo(ProjectWiseUtilizationReports)
