const getTanAndComId = () => {
    return {
        tenantid: JSON.parse(localStorage.getItem('Tenantid')),
    }
}

export const createProjectbaseInfo = () => {
    return {
        projectid: null,
        customerid: null,
        comtenantid: null,
        companyid: null,
        businessunitid: 1,
        servicelineid: null,
        departmentid: null,
        projecttypeid: null,
        datprojectbillingtypeid: 1,
        projectname: "",
        projectcode: null,
        projectdescription: null,
        locationid: null,
        startdate: "",
        enddate: "",
        projectstatus: 5,
        defaultcomcurrencyid: 1,
        totalestimateamt: null,
        totalactualamt: 0,
        reasonforrejection: null,
        projectmanagerid: null,
        deliverymanagerid: null,
        isactiveflag: true,
        createdcomuserid: null,
        notes: '',
        cust_project: true,
        category: "C",
        billingcycle: null,
        deliverymodemasterid: null,
        licensingrevenue: null,
        technologymasterid: null,
        opportunityid: null,
        PMOcontactpersonid: null,
        projectsubtype:null
    }
}

export const defaultContactItem = () => {
    return {
        uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
        contacttype: "",
        contactpersonname: "",
        designation: "",
        phoneno: "",
        email: "",
        isactiveflag: true,
        createdcomuserid: null,
        notes: null,
        comtenantid: getTanAndComId().tenantid
    }
}

export const projectDocumentRow = (projectid = null)=>{
    return  {
      uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
      projectdocumentattachmentsid: null,
      Tenantid: Number(localStorage.getItem('Tenantid') || 0),
      documenttype: 0,
      projectid,
      createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
      modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
      deletedcomuserid: null,
      versionno: "",
      notes: "",
      filename: "",
      filepath: "",
      isactiveflag: false,
      file: ""
    }
  }