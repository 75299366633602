import {useEffect, useMemo, useState} from 'react'
import {getDefaultSpecialisationPayload} from 'src/Services/constant/defaultPayload'
import {GET_SPECIALISATION_MASTER} from 'src/Redux/actions/workforce/eprofile'
import {useDispatch} from 'react-redux'
import SingleSelectField from 'src/Components/SingleSelectForTable'

const Specialisation = ({
	className,
	canEdit,
	value,
	rowId,
	handleChange,
	course,
}) => {
	const [data, setData] = useState(null)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	let isMounted = true

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			isMounted = false
		}
	}, [])

	useMemo(() => {
		setLoading(true)
		let payload = {
			...getDefaultSpecialisationPayload(),
			datcoursemasterid: Number(course || 0),
		}
		dispatch(GET_SPECIALISATION_MASTER(payload))
			.then(res => {
				if (isMounted) {
					setLoading(false)
					setData(res?.data)
				}
			})
			.catch(err => {
				if (isMounted) {
					setLoading(false)
				}
			})
	}, [course, dispatch, isMounted])

	const getSelected = value => {
		let e = {
			target: {
				name: 'datspecialisationid',
				value: value['datspecialisationid'],
			},
		}
		handleChange(e, rowId)
	}

	return (
		<div className={className}>
			{loading ? (
				'loading..'
			) : (
				<>
					{data !== null ? (
						<SingleSelectField
							hover={true}
							valueKey='datspecialisationid'
							labelKey='specialisation'
							options={data}
							value={value || 0}
							handleSelected={getSelected}
							disabled={!canEdit}
							className='w-10 break-words'
						/>
					) : (
						'No Data'
					)}
				</>
			)}
		</div>
	)
}

export default Specialisation
