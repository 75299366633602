

import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BanknotesIcon, BriefcaseIcon, BuildingOfficeIcon, ChartPieIcon, HomeIcon, PresentationChartLineIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/24/outline';

let icons = {
    Projects: <BriefcaseIcon className='h-5 w-5' />,
    Home: <HomeIcon className='h-5 w-5' />,
    Workforce: <UserGroupIcon className='h-5 w-5' />,
    Reports: <PresentationChartLineIcon className='h-5 w-5' />,
    Analytics: <ChartPieIcon className='h-5 w-5' />,
    Admin: <ShieldCheckIcon className='h-5 w-5' />,
    Finance: <BanknotesIcon className='h-5 w-5' />,
    Subscriptions: <BuildingOfficeIcon className='h-5 w-5' />, 
}

const HorizontalNavbar = () => {
    let verticalMenu = useSelector(state => state?.auth?.logedInUser)
    let activeModuleMenu = useSelector(state => state?.auth?.activeModuleMenu)
    const location = useLocation()
    const [openModule, setOpenModule] = useState('home')
    const navigate = useNavigate();
    let [allAccessibleUrl, setAllAccessibleUrl] = useState(['/app/home', '/app/access-denied', '/app/index.html'])
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        let urlsList = ['/app/home', '/app/access-denied', '/app/index.html']
        if (verticalMenu?.length > 0) {
            for (let item of verticalMenu) {
                for (let child of item?.moduledetails) {
                    if (child?.menuname[0]?.RelativePage3.includes('detail')) {
                        let urlis = child?.menuname[0]?.RelativePage3?.split(':')[0]
                        urlsList.push(urlis)
                    } else {
                        urlsList.push(child?.menuname[0]?.RelativePage3)
                    }
                }
            }
            setAllAccessibleUrl([...urlsList])
        }
    }, [setAllAccessibleUrl, verticalMenu])

    useLayoutEffect(() => {
        if (location?.pathname === '/') {
            navigate('/app/home')
        } else if (allAccessibleUrl?.length > 0 && !allAccessibleUrl.includes(location?.pathname)) {
            navigate('/app/access-denied')
        }
        // eslint-disable-next-line 
    }, [location?.pathname, allAccessibleUrl, navigate])

    const handleOnClick = (modulename, module) => {
        if (modulename === openModule) {
            setOpenModule('')
        } else {
            dispatch({ type: 'ACTIVE_MODULE_MENU', data: module })
            localStorage.setItem('activeModuleMenu', JSON.stringify(module))
            setOpenModule(modulename)
        }
    }


    const handleHome = () => {
        dispatch({ type: 'ACTIVE_MODULE_MENU', data: null })
        navigate('/app/home')
    }

    return (
        <nav className="flex justify-start items-center mb-2 space-x-3 flex-shrink-0 ">
            <button onClick={handleHome} className={`${activeModuleMenu === null ? '  border-b-2 border-white font-[600] ' : ''} 
                         transition ease-in-out delay-0 hover:-translate-3 duration-150
                        flex justify-start space-x-2 items-center px-2 py-1 text-base hover:font-[600] text-white  hover:text-white focus:outline-none`}>
                <span>{icons?.Home}</span>
                <span>Home</span>
            </button>
            {allAccessibleUrl?.length > 0 &&
                verticalMenu?.map((item, i) => {
                    return (
                        item?.moduledetails?.length ? <div role='button' key={i}>
                            <div
                                onClick={() => handleOnClick(item?.modulealiasname, item)}
                                role='button'
                                className={`font-[300] cursor-pointer text-white flex justify-between items-center px-2 py-1 text-start hover:font-[600]
                                transition ease-in-out delay-0 hover:-translate-3 duration-150
                                hover:text-white  focus:outline-none ${item?.modulealiasname === activeModuleMenu?.modulealiasname ? '   border-b-2 border-white font-[600]  ' : ''}`}>
                                <div className='flex justify-start space-x-2 items-center'>
                                    {icons[item?.modulealiasname]}
                                    <span>{item?.modulealiasname}</span>
                                </div>
                            </div>
                        </div> : <p key={i} className={`transition ease-in-out delay-0 hover:-translate-3 hover:shadow-lg duration-500
                        flex justify-start items-center px-2 py-1 rounded-md text-base font-[500] text-white hover:bg-secondary hover:text-white focus:outline-none`}>
                            <span>{icons[item?.modulealiasname]}</span>
                            <span>{item?.modulealiasname}</span>
                        </p>
                    )
                })
            }

        </nav>
    )
}

export default HorizontalNavbar;