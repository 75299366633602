import { useSelector } from "react-redux"
import { memo, useMemo, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { getUserAccessPayload } from "src/Services/constant/defaultPayload";
import { GET_USER_ACCESS_LIST } from "src/Redux/actions/masterdata/user";

const GetUserFromAdmin = ({ setSelected, selected }) => {
    const UserManagementList = useSelector(state => state.UserManagementList)
    const { userlistforadmin, getStatusByTypeE } = UserManagementList
    const [searchTerm, setSearchTerm] = useState('');
    const [empstatus, setEmpStatus] = useState(0)

    useMemo(() => {
        if (getStatusByTypeE?.length > 0) {
            let id = getStatusByTypeE?.find(it => it?.statusname === 'Active')?.statusid
            setEmpStatus(id)
        }
    }, [getStatusByTypeE])

    useMemo(() => {
        if (selected?.firstname) {
            setSearchTerm(`${selected?.firstname} ${selected?.lastname || ''} ${selected?.employeecode ? `(${selected?.employeecode || 0})` : ''}`)
        }else{
            setSearchTerm('') 
        }
    }, [selected])

    return (
        <>
           {empstatus && <CustomAutoSearch
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_USER_ACCESS_LIST}
                searchPayload={{
                    ...getUserAccessPayload(),
                    username: searchTerm.trim(),
                    Roleid:`{}`,
                    empstatus
                }}
                setSearchTerm={setSearchTerm}
                options={userlistforadmin}
                // mapping key  (idkey)
                idkey='uId'
                optionLabelkey2='lastname'
                optionlabelKey='firstname'
                bracketLabel='username'
                getSelectedOption={setSelected}
                className='p-2 bg-gray-50'
                placeholder='Select user' />}
        </>
    )
}

export default memo(GetUserFromAdmin)