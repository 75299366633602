import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { GET_MILESTONE_OF_PROJECT } from 'src/Redux/actions/projects/planning'
import moment from 'moment'

const Milestones = ({ projectDetail }) => {
    const dispatch = useDispatch()
    const { getMilestoneByproject } = useSelector(state => state?.ProjectPlanning)

    useEffect(() => {
        if (projectDetail?.projectid) {
            dispatch(GET_MILESTONE_OF_PROJECT(projectDetail?.projectid, 0))
        }
    }, [dispatch, projectDetail])

    return (
        <div className='h-[calc(90%)]  mt-2 overflow-scroll'>
            <table className='text-sm w-full relative'>
                <thead>
                    <tr className='text-left capitalize bg-gray-50'>
                        <th scope='col'
                            className='font-[500] border text-start p-1.5'>Milestone Name</th>
                        <th scope='col'
                            className='font-[500] border text-start p-1.5'>Billing Milestone</th>
                        <th scope='col'
                            className='font-[500] border text-start p-1.5'>Planned Completion</th>
                        <th scope='col'
                            className='font-[500] border text-start p-1.5'>Actual Completion</th>
                        <th scope='col'
                            className='font-[500] border text-start p-1.5'>Amount</th>

                    </tr>
                </thead>
                <tbody>
                    {getMilestoneByproject?.map((item, i) => {
                        let {
                            milestonename,
                            billingmilestone,
                            plannedcompletiondate,
                            actualcompletiondate,
                            currency,
                            milestoneamount,
                        } = item
                        return (
                            <tr key={i}>
                                <td className='border px-1 text-sm'>{milestonename}</td>
                                <td className='border px-1 text-sm'>{billingmilestone ? 'true' : 'false'}</td>
                                <td className='border px-1 text-sm'>{moment(plannedcompletiondate).format('DD-MM-YYYY')}</td>
                                <td className='border px-1 text-sm'>{moment(actualcompletiondate).format('DD-MM-YYYY')}</td>
                                <td className='border px-1 text-sm'>{milestoneamount} {currency}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Milestones
