import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'

const SelectCompany = ({ listOfCompany, handleChangeValue, value }) => {
	return (
		<>
			<CustomhtmlSelect
				options={listOfCompany || []}
				value={value}
				noDataLabel="No Company"
				name="companyid"
				labelkey="companyname"
				className="py-2 border rounded bg-gray-50"
				onChange={handleChangeValue}
				id="companyid"
			/>
		</>
	)
}

export default SelectCompany
