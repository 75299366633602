import { useEffect, useState } from 'react'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import { GET_OPPORTUNITY_LIST } from 'src/Redux/actions/projects'
import { getDefaultOpportunityPayload } from 'src/Services/constant/defaultPayload'

const SelectOpportunity = ({
    opportunity,
    handleSelectOpportunity,
    listOfOpportunity,
    disabled,
}) => {
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        if (opportunity?.opportunityname) {
            setSearchTerm(opportunity?.opportunityname || '')
        }
    }, [opportunity])

    return (
        <>
            <label htmlFor="Opportunity" className="block font-[500] mb-2">
                Select Opportunity <span className="text-red-300">*</span>
            </label>{' '}
            <CustomAutoSearch
                disabled={disabled}
                searchTerm={searchTerm}
                API_CALL_FOR_SEARCH={GET_OPPORTUNITY_LIST}
                searchPayload={{
                    ...getDefaultOpportunityPayload(),
                    searchname: searchTerm,
                }}
                className="p-2"
                setSearchTerm={setSearchTerm}
                options={listOfOpportunity}
                idkey="opportunityid"
                optionlabelKey="opportunityname"
                getSelectedOption={handleSelectOpportunity}
                placeholder="Search Opportunity"
            />
        </>
    )
}

export default SelectOpportunity
