

export const activityItem = ()=>{
    return {
        activityid: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
        projectid: 1,
        activityname: "Phase 1",
        activitycode: "",
        activitydesc: "",
        startdate: "",
        enddate: "",
        activityownerid: 0,
        isactiveflag: true,
        notes: "",
        projectname: "",
        taskdetails: [
            {
                projecttaskid: null,
                taskid:0,
                uId: Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36),
                taskname: "",
                taskseq: 1,
                taskcode: "",
                startdate: "",
                enddate: "",
                billtoclient: false,
                clientapproval: false,
                estdeffort: 0,
                tasknotes: ""
            }
        ]
    }
}