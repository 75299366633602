
import { memo, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from 'src/Components/Buttons/PrimaryButton';
import { CheckObjectValidation } from 'src/Services/constant/customvalidation';
import { GET_CUSTOMER_MASTERLIST } from 'src/Redux/actions/masterdata/customer';
import { getCustomerMasterListPayload } from 'src/Services/constant/Customerpayload';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput';

const Details = (props) => {
  const { detailSave, detailsData, setDetailsData } = props;
  const [dm, setDm] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const { listOfCustomers } = useSelector((state) => state.CustomerManagementList);
  const [checkvalidation, setCheckvalidation] = useState(false);

  useMemo(() => {
    if (dm?.customername) {
      setSearchTerm(dm?.customername)
    }
  }, [dm])

  const handleSubmit = () => {
    setCheckvalidation(true);
    let item = {
      ...detailsData,
      customerid: dm?.customerid

    };

    let isValidated = CheckObjectValidation(item, [
      'customerholidaysid',
      'modifiedcomuserid',
      'notes',
    ]);
    if (isValidated?.isvalid) {
      let payload = {
        customerholidaysjson: [item],
      };
      detailSave(payload);
    }
  };

  const handleChangeInput = (name, value) => {
    setCheckvalidation(false);
    setDetailsData({ ...detailsData, [name]: value });
  };

  return (
    <div className='w-full h-[65vh] text-sm mb-10'>
      <div className='grid grid-cols-4 gap-10 mt-4'>
        <div>
          <label htmlFor='customerid' className='block font-[500] mb-2'>
            Customer
            <span className="text-red-300"> *</span>
          </label>
          <CustomAutoSearch
            searchTerm={searchTerm}
            API_CALL_FOR_SEARCH={GET_CUSTOMER_MASTERLIST}
            searchPayload={{ ...getCustomerMasterListPayload(), customername: searchTerm, }}
            setSearchTerm={setSearchTerm}
            options={listOfCustomers}
            idkey='customername'
            optionlabelKey='customername'
            getSelectedOption={setDm}
            placeholder='Enter Customer Name'
            className='bg-gray-50 text-gray-600 border border-gray-200 rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2'
          />
            {!dm?.customerid && checkvalidation && (
          <small className='text-red-400'>Customer Name is required!</small>
        )}
        </div>
      
        <div>
          <label htmlFor='holiday' className='block font-[500] mb-2'>
            Holiday Date
            <span className='text-red-300'> *</span>
          </label>
          <FlatPickrDateInput
            name='holiday'
            value={detailsData?.holiday || ''}
            id='holiday'
            className='outline-none border border-gray-300 text-textSecondary sm:text-sm rounded focus:ring-primary focus:border-primary block w-full p-[7px]'
            onChangeDate={e => handleChangeInput('holiday', e.target.value)}
          />    
          {!detailsData?.holiday && checkvalidation && (
              <small className='text-red-400'>Holiday Date is required!</small>
            )}
        </div>
        <div>
          <label htmlFor='reason' className='block font-[500] mb-2'>
            Holiday Name
            <span className='text-red-300'> *</span>
          </label>
          <input
            type='text'
            title='reason'
            id='reason'
            className='outline-gray-50 bg-gray-50 text-gray-600 border rounded block w-full p-2'
            placeholder='Holiday Name'
            maxLength={50}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target?.value)) {
                handleChangeInput('reason', e.target.value);
              }
            }}
          />
          {!detailsData?.reason && checkvalidation && (
            <small className='text-red-400'>Holiday Name is required!</small>
          )}
        </div>
        <div className='fixed bottom-0 right-6 mt-2'>
          <div className='gap-2 flex justify-end items-center'>
            <PrimaryButton
              className='bg-primary text-white rounded-lg py-2 px-8 mb-4'
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
