import { Dialog, Tab, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'src/Components/Buttons/IconButton';
import { GET_COURSE_LIST_MASTER, SAVE_COURSE_MASTER } from 'src/Redux/actions/masterdata/user';
import { AlertSuccess } from 'src/Services';
import { getDefaultCourseListPayload } from 'src/Services/constant/defaultPayload';
import { CourseItem } from '../defaultPayload';
import Details from './details';


const CreateCourse = () => {
    const [detailsData, setDetailsData] = useState(CourseItem());
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const { educationData } = useSelector((state) => state?.EmployeeProfile);

    function closeModal() {
        setIsOpen(false);
        setDetailsData(CourseItem());
    }

    function openModal() {
        setIsOpen(true);
    }

    async function detailSave(payload) {
        let res = await dispatch(SAVE_COURSE_MASTER(payload));
        if (res?.success) {
            AlertSuccess('Course saved successfully.');
            await dispatch(GET_COURSE_LIST_MASTER(getDefaultCourseListPayload()));
        }
    }

    return (
        <>
            <IconButton
                title='Create Course'
                onClick={openModal}
                className='text-white rounded-full bg-primary focus:ring-0 focus:ring-primary font-[500] text-sm p-2 focus:outline-none'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </IconButton>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-20' onClose={openModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-[0.50] ' />
                    </Transition.Child>
                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex items-center justify-center min-h-full p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className=' w-[calc(90%)] 
  md:w-[calc(80%)]  h-[90vh] transform overflow-hidden rounded-lg bg-white px-6 text-left align-middle shadow-xl transition-all'>
                                    <div className='flex items-center justify-between py-2 border-b border-primary'>
                                        <h3 className='text-lg font-[500] leading-6 text-primary'>
                                            Create Course
                                        </h3>
                                        <IconButton
                                            title='Close'
                                            onClick={closeModal}
                                            type='button'
                                            className='flex items-center justify-center w-10 h-10 border rounded-full'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-4 h-4'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M6 18L18 6M6 6l12 12'
                                                />
                                            </svg>
                                            <span className='sr-only'>Close icon</span>
                                        </IconButton>
                                    </div>
                                    <Tab.Group>
                                        <Tab.Panels>
                                            <Tab.Panel>
                                                <Details
                                                    detailSave={detailSave}
                                                    detailsData={detailsData}
                                                    setDetailsData={setDetailsData}
                                                    onModalClose={closeModal}
                                                    educationData={educationData}
                                                />
                                            </Tab.Panel>
                                        </Tab.Panels>
                                    </Tab.Group>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default CreateCourse;
