import moment from "moment";
import EmployeeNotePopup from "./EmployeeNotePopup";
import DayHeaders from "./dayHeaders";
import EffortInput from "./effortInput";
import { memo } from "react";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import ConfigInfo from "../configInfo";
import { AlertError } from "src/Services";


const cols = ['Task Description', 'Ref No.', 'Time Type']

const TaskDetails = ({ weeks, active, setActive, editItem }) => {
    const handleeffort = (name, value, projtaskid) => {
        let Activedata = { ...active }
        if (isNaN(value)) {
            return
        } else if (parseFloat(value) > 24) {
            AlertError('Daily Effort should not be more than 24 hours')
        }
        let updatedEffort = Activedata?.timesheetdata?.map(row => {
            if (row?.projtaskid === projtaskid) {
                row?.timesheetdetails?.map(effortrow => {
                    if (effortrow?.effortdate === name) {
                        effortrow['efforthours'] = value
                    }
                    return effortrow
                })
            }
            return row
        })

        let total = 0;
        let data = updatedEffort?.filter(row => row?.isactiveflag === true || row?.isactiveflag === undefined)
        data?.forEach(entry => {
            entry?.timesheetdetails?.filter(r => r?.effortdate === name)?.forEach(detail => {
                total += detail?.efforthours;
            });
        });
        let dailyEffort = total
        if (dailyEffort > 24) {
            AlertError('Daily Effort should not be more than 24 hours')
            return
        } else {
            setActive({ ...active, timesheetdata: updatedEffort })
        }

    }

    const selectedTimetype = (item) => {
        if (item?.timesheetconfiguration?.length > 0) {
            return item?.timesheetconfiguration[0]?.timetypetemplatemapping?.find(value => value?.timetypeid === item?.timetypeid)
        }
        return {}
        // eslint-disable-next-line
    }

    const holidaycalendar = (effortdate, time_type, item) => {
        let listOfLeaves = []
        // this row will contain holiday and leave list
        if (selectedTimetype(item)?.holidaycalendarintegration === 'Both') {
            // Company holidays
            if (item?.Companyholidays) {
                for (let l of item?.Companyholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }
            // Customer holidays
            if (item?.customerholidays) {
                for (let l of item?.customerholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }
        } else if (selectedTimetype(item)?.holidaycalendarintegration === 'Customer') {
            // Customer holidays
            if (item?.customerholidays) {
                for (let l of item?.customerholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }
        } else if (selectedTimetype(item)?.holidaycalendarintegration === 'Company') {
            // Company holidays
            if (item?.Companyholidays) {
                for (let l of item?.Companyholidays) {
                    listOfLeaves.push(l?.holiday)
                }
            }

        }
        return listOfLeaves?.includes(effortdate)
    }

    const leaveFound = (celldate, row) => {
        let leaveData = row?.leavedetails_var || []
        let list = leaveData?.map(l => {
            return l?.leavedate
        })
        return list?.includes(celldate)
    }


    const allocationDateValidation = (effortdate, rowdata) => {
        if (rowdata?.Allocationenddate && rowdata.Allocationstartdate) {
            if (rowdata?.entrytype === 'Project') {
                let start = moment(new Date(rowdata?.Allocationstartdate)).format('YYYY-MM-DD')
                let end = moment(new Date(rowdata?.Allocationenddate)).format('YYYY-MM-DD')
                let effortDateis = moment(new Date(effortdate)).format('YYYY-MM-DD')

                if (effortDateis >= start && effortDateis <= end) {
                    return false
                } else {
                    return true
                }
            }
        }

    }

    return (
        <div className="w-full py-2 pb-36 max-h-full mb-2 overflow-scroll">
            <table className='relative w-full text-sm'>
                <thead>
                    <tr className='text-left capitalize bg-gray-100'>
                        {
                            cols?.map(col => {
                                return <th
                                    key={col}
                                    title={col}
                                    scope='col'
                                    className='font-[500] px-2 py-2 border text-start'
                                >
                                    {col}
                                </th>
                            })
                        }
                        <DayHeaders startdate={weeks?.monday} />
                        <th
                            scope='col'
                            className='font-[500] px-2 py-2 border text-start'
                        >
                            Notes
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        active?.timesheetdata?.map((row, i) => {
                            return <tr key={row?.effortid + i} className='text-left text-[14px]'>
                                <td className='border pl-1.5 py-1.5'>
                                    <div className="flex items-center justify-start w-full">
                                        {row?.billtoclient && <span className="h-4 w-[3px] rounded-md bg-orange-500 mx-1"></span>}
                                        {row?.clientapprovel && <span className="h-4 w-[3px] rounded-md  bg-green-500 mx-1"></span>}
                                        <span className="truncate " data-tooltip-id={row?.effortid + i + row?.taskdesc} data-tooltip-content={row?.taskdesc}>
                                            {row?.taskdesc}
                                        </span>
                                        <Tooltip
                                            style={{
                                                zIndex: 1000,
                                                color: process.env.REACT_APP_color_secondary,
                                                background: '#fff',
                                                border: `1px solid ${process.env.REACT_APP_color_secondary}`
                                            }} id={row?.effortid + i + row?.taskdesc} className='shadow' />
                                    </div>
                                </td>
                                <td className='border pl-1.5 py-1.5'>
                                    {row?.referencenumber}
                                </td>
                                <td className='border pl-1.5 py-1.5'>
                                    <div className="flex justify-between space-x-1 items-center">
                                        <span>{row?.timetypename}</span>
                                        {row?.timetypeid && <ConfigInfo data={row} />}
                                    </div>
                                </td>

                                {
                                    row?.timesheetdetails?.map((effort, effortIndex) => {
                                        return (
                                            <td key={effortIndex} className='border pl-1.5 py-1.5'>
                                                {row?.curstatus === 'Approved' ? <EffortInput
                                                    effortIndex={effortIndex}
                                                    selectedTimetype={selectedTimetype(row)}
                                                    isOnLeave={leaveFound(effort?.effortdate, row)}
                                                    effortid={row?.effortid}
                                                    handleeffort={handleeffort}
                                                    item={effort}
                                                    row={row}
                                                    active={active}
                                                    effortdate={effort?.effortdate}
                                                    setActive={setActive}
                                                    holidayActive={holidaycalendar(effort?.effortdate, row?.timetypeid, row)}
                                                    disabled={(
                                                        editItem?.uId !== active?.uId ||
                                                        row?.curstatus === 'Submitted' ||
                                                        // disable if date falls in leave list
                                                        // leaveFound(effort?.effortdate, row) ||
                                                        // // disable if current status is approved
                                                        allocationDateValidation(effort?.effortdate, row) ||
                                                        // // disable if future date
                                                        (effort?.effortdate > moment().format('YYYY-MM-DD')) ||
                                                        // // disable if time type not selected
                                                        !row?.timetypeid ||
                                                        (holidaycalendar(effort?.effortdate, row?.timetypeid, row) && !selectedTimetype?.holidayallowed)
                                                    )}
                                                /> : <span className="flex items-center justify-start w-full space-x-2">
                                                    {holidaycalendar(effort?.effortdate, row?.timetypeid, row) && <span className="h-4 w-[3px] rounded-md  bg-blue-500" />}
                                                    {leaveFound(effort?.effortdate, row) && <span className="h-4 w-[3px] rounded-md  bg-gray-500" />}
                                                    <span>{effort?.efforthours}</span>
                                                </span>}

                                            </td>
                                        )
                                    })
                                }

                                <td className='border pl-1.5'>
                                    <EmployeeNotePopup note={row?.effortnotes || ''} />
                                </td>
                            </tr>
                        })
                    }
                </tbody>

            </table>

        </div>
    )
}

export default memo(TaskDetails);