
import { useState, useRef, useEffect, memo, useMemo } from 'react';
import ProjectSearch from './projectSearch';
import TaskgroupById from './taskSearch'
import AlloocationById from './allocationSearch'
import { useDispatch } from "react-redux";
import { GET_TASK_BY_PROJECTID_TIMESHEET } from 'src/Redux/actions/projects/timesheet';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function SingleSelectField({ ProjectCom, TaskCom, hooks, hooks: { it } }) {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState("bottom");

    useEffect(() => {
        // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    useEffect(() => {
        if (open) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const dropdownHeight = 300; // Estimated height of the dropdown

            // Check if dropdown is too close to the viewport bottom
            if (dropdownRect.bottom + dropdownHeight > viewportHeight) {
                setDropdownPosition("top");
            } else {
                setDropdownPosition("bottom");
            }
        }
    }, [open]);

    let tooltipid = Math.random().toString(36).substr(2, 9) + new Date().getTime().toString(36)

    const taskTitleValue = useMemo(() => {
        if (it?.entrytype === 'Generic') {
            return it?.taskdesc || <span className='text-gray-500'>Select Task</span>
        } else {
            return (
                <div className='flex items-center'>
                    <span className='max-w-[50%] truncate text-xs text-ellipsis overflow-hidden whitespace-nowrap'>
                        {it?.projectname}
                    </span>
                    {it?.projectname && <span className='px-2'> - </span>}
                    <span className='max-w-[50%] truncate text-xs text-ellipsis overflow-hidden whitespace-nowrap'>
                        {it?.taskdesc || 'Select Task'}
                    </span>
                </div>
            )
        }
        // eslint-disable-next-line
    }, [it?.taskdesc, it?.projectname, it?.entrytype])

    return (
        <div ref={dropdownRef} className="relative w-full">
            <div className="w-full group relative flex justify-between items-center text-xs">
                <div data-tooltip-id={tooltipid} className=' w-full'>
                    {it?.curstatus === 'Submitted' ? taskTitleValue : <div role='button' onClick={() => setOpen(!open)} className='truncate px-1'>
                        {taskTitleValue}
                    </div>}
                </div>
                {it?.projectname && <Tooltip
                    // place='bottom'
                    opacity={1}
                    id={tooltipid}
                    className="!bg-[#f9fafb] !text-gray-800 !border !border-gray-200 !shadow-sm !rounded-md !p-0 relative" // Added relative positioning
                    render={() => (
                        <div className="w-64 text-secondary font-medium p-3 m-0 rounded-md relative">
                            Project: {it?.projectname}
                            <br />
                            Task: {it?.taskdesc}
                            <br />
                            Ref: {it?.referencenumber ? `${it?.referencenumber}` : ''}
                        </div>
                    )}
                    border={'1px solid #e5e7eb'}
                    style={{
                        zIndex: 2000,
                        backgroundColor: '#f9fafb', padding: '0', margin: '0', color: 'black',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                />}

            </div>
            {open && <div
                className={`p-2 transition bg-[#f5f5f5] z-[1000] ease-in duration-100 absolute ${dropdownPosition === "top" ? "bottom-8" : "top-8"} mt-1 w-96 min-w-[100px] rounded-sm text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
            >
                {it?.entrytype === 'Project' && <>
                    <h6 className='text-xs mb-1 font-[500]'>Select Project</h6>
                    <ProjectCom hooks={hooks} />
                    <h6 className='text-xs my-1 font-[500]'>Select Allocation</h6>
                    <AlloocationById hooks={hooks} />
                </>}
                <h6 className='text-xs my-1 font-[500]'>Select Task</h6>
                <TaskCom setOpen={setOpen} hooks={hooks} />
            </div>}
        </div>
    )
}


const SelectTask = ({ hooks, hooks: { it, weeks } }) => {
    const dispatch = useDispatch()
    const [allocations, setAllocations] = useState([])

    useEffect(() => {
        let isMounted = true;

        async function fetchTaskForEntryProject(projectid) {
            let res = await dispatch(GET_TASK_BY_PROJECTID_TIMESHEET(projectid, weeks));
            if (isMounted && res?.length > 0) {
                setAllocations(res);
            } else if (res === null && allocations?.length !== 0) {
                setAllocations([]);
            }
        }

        if (it?.entrytype === 'Project' && it?.projectid) {
            fetchTaskForEntryProject(it?.projectid);
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, [it?.projectid, dispatch]);

    return (
        <SingleSelectField
            hooks={{ ...hooks, allocations }}
            ProjectCom={ProjectSearch}
            TaskCom={TaskgroupById}
        />
    )
}

export default memo(SelectTask);
