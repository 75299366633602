import axios from 'axios'
import {
    ACTION_GET_CAPACITY_REPORT,
    ACTION_GET_DEMAND_SUMMARY_REPORT,
    ACTION_GET_EXIT_PIPELINE_REPORT,
    ACTION_GET_FINANCE_REPORT,
    ACTION_GET_PDP_AGING_REPORT,
    ACTION_GET_PROJECT_WISE_UTILIZATION_REPORT,
    ACTION_GET_RELEASE_PLAN_REPORT,
    ACTION_GET_REPORT_MASTER_LIST,
    ACTION_GET_STAFFING_DETAILS_REPORT,
    ACTION_GET_TIMESHEET_REPORT_FOR_EMPLOYEE,
    ACTION_GET_TIMESHEET_REPORT_FOR_EXCEPTION,
    ACTION_GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE,
    ACTION_GET_TIMESHEET_TRACKING_REPORT,
    ACTION_GET_UTILIZATION_REPORT_FOR_EMPLOYEE,
} from 'src/Redux/types'
import { AlertError } from 'src/Services'
import { postMethodApiCall } from 'src/Services/api'
import { reportMasterRowDefault } from 'src/Services/constant/staticValues'

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
}

export const GET_REPORT_MASTER_LIST = (menuId) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_reportmasterlist`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), {
                Tenantid: Number(localStorage.getItem('Tenantid') || 0),
                datmenuid: menuId,
            })
            let items = res?.data?.data
            if (items === null) AlertError('No reports found')
            dispatch({
                type: ACTION_GET_REPORT_MASTER_LIST,
                data: items || [reportMasterRowDefault()],
            })
            return { success: true, data: items }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_TIMESHEET_REPORT_FOR_EMPLOYEE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_timesheetreportforemployeewise`
            let paylaod = { ...data }
            delete paylaod['reportid']
            delete paylaod['Monthid']
            delete paylaod['Yearid']
            dispatch({ type: ACTION_GET_TIMESHEET_REPORT_FOR_EMPLOYEE, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_TIMESHEET_REPORT_FOR_EMPLOYEE,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
export const GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_timesheetnoncompliancereport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            delete paylaod['Monthid']
            delete paylaod['Yearid']
            dispatch({ type: ACTION_GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_TIMESHEET_REPORT_FOR_NONCOMPLIANCE,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_TIMESHEET_REPORT_FOR_EXCEPTION = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_timesheetexceptionreport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            delete paylaod['Monthid']
            delete paylaod['Yearid']
            dispatch({ type: ACTION_GET_TIMESHEET_REPORT_FOR_EXCEPTION, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_TIMESHEET_REPORT_FOR_EXCEPTION,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            console.log(error)
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_FINANCE_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectprofitabilityreport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            delete paylaod['Monthid']
            delete paylaod['Yearid']
            dispatch({ type: ACTION_GET_FINANCE_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_FINANCE_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_CAPACITY_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_capacity_report`
            let paylaod = { ...data }
            delete paylaod['reportid']
            dispatch({ type: ACTION_GET_CAPACITY_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_CAPACITY_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_TIMESHEET_TRACKING_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_timesheettrackingreport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            dispatch({ type: ACTION_GET_TIMESHEET_TRACKING_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_TIMESHEET_TRACKING_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_RELEASE_PLAN_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_releaseplanreport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            dispatch({ type: ACTION_GET_RELEASE_PLAN_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_RELEASE_PLAN_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}
export const GET_PDP_AGING_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_pdpagingreport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            dispatch({ type: ACTION_GET_PDP_AGING_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_PDP_AGING_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_STAFFING_DETAILS_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_projectstaffingdetailreport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            dispatch({ type: ACTION_GET_STAFFING_DETAILS_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_STAFFING_DETAILS_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

// WORK____IN____PROGRESS__DO___NOT_____TOUCH____//
export const GET_UTILIZATION_REPORT_FOR_EMPLOYEE = (payload) => {
    return async (dispatch) => {
        try {
            let data = { ...payload }
            if (data?.endMonthid) delete data['endMonthid']
            if (data?.endYearid) delete data['endYearid']
            if (data?.startMonthid) delete data['startMonthid']
            if (data?.startYearid) delete data['startYearid']
            const url = `${baseUrl}/get_employeeutilizationreport`
            dispatch({ type: ACTION_GET_UTILIZATION_REPORT_FOR_EMPLOYEE, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                dispatch({
                    type: ACTION_GET_UTILIZATION_REPORT_FOR_EMPLOYEE,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_PROJECT_WISE_UTILIZATION_REPORT = (payload) => {
    return async (dispatch) => {
        try {
            let data = { ...payload }
            if (data?.endMonthid) delete data['endMonthid']
            if (data?.endYearid) delete data['endYearid']
            if (data?.startMonthid) delete data['startMonthid']
            if (data?.startYearid) delete data['startYearid']
            const url = `${baseUrl}/get_projectutilizationreport`
            dispatch({ type: ACTION_GET_PROJECT_WISE_UTILIZATION_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                dispatch({
                    type: ACTION_GET_PROJECT_WISE_UTILIZATION_REPORT,
                    data: items || [],
                })
                return items
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_EXIT_PIPELINE_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_exitpipelinereport`
            let paylaod = { ...data }
            delete paylaod['reportid']
            dispatch({ type: ACTION_GET_EXIT_PIPELINE_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_EXIT_PIPELINE_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const GET_DEMAND_SUMMARY_REPORT = (data) => {
    return async (dispatch) => {
        try {
            const url = `${baseUrl}/get_demandsummary`
            let paylaod = { ...data }
            delete paylaod['reportid']
            delete paylaod['Monthid']
            delete paylaod['Yearid']
            dispatch({ type: ACTION_GET_DEMAND_SUMMARY_REPORT, data: null })
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), paylaod)
            if (res?.status === 200 || res?.status === 201) {
                let items = res?.data?.data
                if (items === null) AlertError('No data found')
                let addnewkey = items?.map((item) => {
                    item['uId'] =
                        Math.random().toString(36).substr(2, 9) +
                        new Date().getTime().toString(36)
                    return item
                })
                dispatch({
                    type: ACTION_GET_DEMAND_SUMMARY_REPORT,
                    data: addnewkey || [],
                })
                return { success: true, data: addnewkey }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
        }
    }
}

export const DOWNLOAD_REPORT_BY_ENDPOINT = (data, endpoint) => {
    return async () => {
        try {
            const url = `${baseUrl}/${endpoint}`
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
                responseType: 'arraybuffer',
                data,
            }

            let res = await axios.request(config)
            if (res?.status === 200 || res?.status === 201) {
                return { success: true, res }
            }
        } catch (error) {
            AlertError(error?.response?.data).replace(/\\/g, '')
            return { success: false }
        }
    }
}
