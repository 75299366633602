
import { useSelector } from "react-redux"
import { memo, useMemo, useState } from "react"
import CustomAutoSearch from "src/Components/CustomAutoSearch"
import { getUserAccessPayload } from "src/Services/constant/defaultPayload";
import { GET_USER_ACCESS_LIST } from "src/Redux/actions/masterdata/user";

const SelectManager = ({ setRm, rm, canEdit, searchStr, companyid, UserId }) => {
  const UserManagementList = useSelector(state => state.UserManagementList)
  const { userlistforadmin } = UserManagementList
  const [searchTerm, setSearchTerm] = useState('');

  useMemo(() => {
    if (rm?.firstname) {
      setSearchTerm(`${rm?.firstname || ''} ${rm?.lastname || ''} ${rm?.employeecode ? `(${rm?.employeecode || 0})` : ''}`)
    }
  }, [rm])

  const optionsAre = useMemo(()=>{
    return userlistforadmin?.filter(i=> i?.comuserid !== UserId)
  },[UserId, userlistforadmin])

  return (
    <>
      <label
        htmlFor='ReportingManager'
        className='block mb-2 text-sm font-[500]'
      >
        Reporting Manager
        <span className="text-red-300"> *</span>
      </label>
      <CustomAutoSearch
        disabled={canEdit}
        searchTerm={searchTerm}
        API_CALL_FOR_SEARCH={GET_USER_ACCESS_LIST}
        searchPayload={{
          ...getUserAccessPayload(),
          username: searchTerm || searchStr?.trim(),
          companyid
        }}
        setSearchTerm={setSearchTerm}
        options={optionsAre}
        // mapping key  (idkey)
        idkey='uId'
        optionLabelkey2='lastname'
        optionlabelKey='firstname'
        bracketLabel='username'
        getSelectedOption={setRm}
        className='p-2'
        placeholder='Select Reporting Manager' />
    </>
  )
}

export default memo(SelectManager)
