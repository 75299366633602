import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from 'src/Components/SelectInput';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import moment from 'moment';
import { detailItem } from '../createCustomer/defaultRowAndData';
import CreateCustomer from '../createCustomer';
import EditCustomer from '../editCustomer';
import { GET_ALL_CONTACTS_OF_A_CUSTOMER, GET_CUSTOMER_MASTERLIST } from 'src/Redux/actions/masterdata/customer';
import { getCustomerListPayload } from 'src/Services/constant/Customerpayload';
import { basicShortList } from 'src/Services/constant/staticValues';

const cols = ['Customer', 'Code', 'Customer Group', 'Industry', 'City', 'Contact Person', 'Phone', 'Email', 'Status', 'Created At'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const CustomerManagmentList = ({ setCustomers, customers }) => {
  const [customer, setCustomer] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [customerId, setCustomerId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(detailItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });

  const dispatch = useDispatch();
  const { listOfCustomers, CustomerContacts, loading: customerLoading } = useSelector((state) => state.CustomerManagementList);
  const primaryContact = CustomerContacts?.find((contact) => contact.isprimary === true);
  const { PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(customerLoading); // Hide the loader after a delay
    }, 100);
  }, [customerLoading]);

  useEffect(() => {
    if (customer?.customername) {
      setSearchTerm(`${customer?.customername}`);
    }
  }, [customer]);

  const handleCustomerClick = (customer) => {
    setDetailsData(customer);
    setCustomerId(customer?.customerid);
    setIsOpen(true);
  };

  useEffect(() => {
    if (customerId) {
      dispatch(GET_ALL_CONTACTS_OF_A_CUSTOMER(customerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_CUSTOMER_MASTERLIST({ ...getCustomerListPayload(), sorttype: item.id, statusname: status?.label, limit: limit, page }));
    if (res !== null) {
      setCustomers(res?.data);
    }
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_CUSTOMER_MASTERLIST({ ...getCustomerListPayload(), statusname: item.label }));
    if (res === null) {
      setDetailsData(res.data);
    }
  };
  const handlePagination = async (page) => {
    let res = await dispatch(GET_CUSTOMER_MASTERLIST({ ...getCustomerListPayload(), limit, pageno: page?.selected + 1, sorttype: sortType?.id, statusname: status?.label }));
    if (res?.success) {
      setCustomers(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(GET_CUSTOMER_MASTERLIST({ ...getCustomerListPayload(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, statusname: status?.label }));
    if (res?.success) {
      setDetailsData(res?.data);
    }
  };

  return (
    <div className='w-full h-screen p-2 py-3 mt-4 bg-white'>
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center justify-start space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Customer Details ({listOfCustomers?.length > 0 ? listOfCustomers[0]?.totalcount : 0}){' '}
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_CUSTOMER_MASTERLIST}
              searchPayload={{ ...getCustomerListPayload(), customername: searchTerm }}
              setSearchTerm={setSearchTerm}
              options={listOfCustomers}
              idkey='customerid'
              optionlabelKey='customername'
              // optionLabelkey2='lastname'
              bracketLabel='customerid'
              getSelectedOption={setCustomer}
              className='p-2'
              placeholder='Enter Customer Name'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={listOfCustomers?.length > 0 ? listOfCustomers[0]?.totalcount : listOfCustomers?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
            <div className='w-32 '>
              <SelectInput
                options={statusList || []}
                keyname='label'
                selected={status}
                setSelected={handleStatus}
                className='py-2 border rounded bg-gray-50'
              />
            </div>
            <div className='w-32 '>
              <SelectInput
                options={basicShortList || []}
                keyname='label'
                selected={sortType}
                setSelected={handleSorting}
                className='py-2 border rounded bg-gray-50'
              />
            </div>
          </div>
        </div>
        {(PermissionsOfMasterdata.includes("Add/Edit Customer")) ? <CreateCustomer /> : ""}
      </div>
      <div className='overflow-scroll h-[50vh] '>
        {loading || listOfCustomers === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin-slow rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          listOfCustomers && (
            <table className='relative w-full text-sm '>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {listOfCustomers?.map((item, i) => {
                  const {
                    customerid,
                    customername,
                    customercode,
                    // eslint-disable-next-line
                    companyname,
                    accountname,
                    industryname,
                    cityname,
                    contactpersonname,
                    phoneno,
                    email,
                    createdtimestamp,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleCustomerClick(item)}
                      key={customerid}
                      className='text-left cursor-pointer'>
                      <td className='border px-2 py-1.5'><div>{customername}</div></td>
                      <td className='border px-2 py-1.5'><div>{customercode}</div></td>
                      <td className='border px-2 py-1.5'><div>{accountname}</div></td>
                      <td className='border px-2 py-1.5'><div>{industryname}</div></td>
                      <td className='border px-2 py-1.5'><div>{cityname}</div></td>
                      <td className='border px-2 py-1.5'><div>{primaryContact && primaryContact.customerid === customerid ? `${primaryContact.contactpersonname}` : contactpersonname}</div></td>
                      <td className='px-2 border'><div>{phoneno}</div></td>
                      <td className='border px-2 py-1.5'><div>{email}</div></td>
                      <td className='px-2 border'><div>{item.isactiveflag ? 'Active' : 'Inactive'}</div></td>
                      <td className='px-2 border'><div>{moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ))}
      </div>
      <EditCustomer
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        isOpen={isOpen}
        customerid={customerId}
        setIsOpen={setIsOpen}
        permissions={PermissionsOfMasterdata}
      />
    </div>
  );
};

export default CustomerManagmentList;
