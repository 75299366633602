import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import ReactPaginationStyle from 'src/Components/ReactPagination'
import moment from 'moment'
import { AnnouncementItem } from './createAnnouncement/defaultValue'
import CreateAnnouncement from './createAnnouncement'
import EditAnnouncement from './editAnnouncement/index'
import { GET_ANNOUNCEMENT_MASTER } from 'src/Redux/actions/masterdata/user'
import { getAnnouncementMasterIdData } from 'src/Services/constant/masterdatapayload'

const cols = ['Announcement Text', 'Announcement Link', 'Sequence', 'Expiry Date', 'Status', 'Created At', 'Created By']

const rows = [10, 25, 50]

// Sort and staus not required for this landing page as data is already sorted and and expiry dtae is already there so status filter also mot required
const AnnouncementMasterList = () => {
    const [announcement, setAnnouncement] = useState({})
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [announcementId, setAnnouncementId] = useState(null)
    const [detailsData, setDetailsData] = useState(AnnouncementItem())

    const dispatch = useDispatch()
    const {
        announcementList,
        loading: announcementLoading,
        PermissionsOfMasterdata,
    } = useSelector((state) => state.UserManagementList)

    useEffect(() => {
        setLoading(true) // Show the loader
        setTimeout(() => {
            setLoading(announcementLoading) // Hide the loader after a delay
        }, 100)
    }, [announcementLoading])

    useMemo(() => {
        if (announcement?.announcement) {
            setSearchTerm(announcement?.announcement)
        }
    }, [announcement])

    const handlePagination = async (page) => {
        let res = await dispatch(
            GET_ANNOUNCEMENT_MASTER({ ...getAnnouncementMasterIdData(), limit, page: page?.selected + 1 }),
        )
        if (res?.success) {
            setDetailsData(res?.data)
        }
        setPage(page?.selected + 1)
    }

    const handleRows = async (row) => {
        setLimit(row)
        let res = await dispatch(
            GET_ANNOUNCEMENT_MASTER({ ...getAnnouncementMasterIdData(), limit: row, page: page?.selected + 1 }),
        )
        if (res?.success) {
            setDetailsData(res?.data)
        }
    }

    const handleAnnouncementClick = (announcement) => {
        setDetailsData(announcement)
        setAnnouncementId(announcement?.announcementid)
        setIsOpen(true)
    }

    return (
        <div className="w-full h-screen p-2 py-3 mt-4">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center justify-start space-x-5">
                    <h1 className="pb-2 font-[500]">
                        Announcements (
                        {announcementList?.length > 0 ? announcementList[0]?.totalcount : announcementList?.length})
                    </h1>
                    <div className="w-72">
                        <CustomAutoSearch
                            searchTerm={searchTerm}
                            API_CALL_FOR_SEARCH={GET_ANNOUNCEMENT_MASTER}
                            searchPayload={{ ...getAnnouncementMasterIdData(), searchname: searchTerm }}
                            setSearchTerm={setSearchTerm}
                            options={announcementList}
                            idkey="announcement"
                            optionlabelKey="announcement"
                            getSelectedOption={setAnnouncement}
                            placeholder="Enter Announcement Name"
                            className="p-2"
                        />
                    </div>
                    <div className="flex items-center justify-start space-x-6">
                        <ReactPaginationStyle
                            total={
                                announcementList?.length > 0
                                    ? announcementList[0]?.totalcount
                                    : announcementList?.length
                            }
                            currentPage={page}
                            handlePagination={handlePagination}
                            limit={limit}
                        />
                        <FilterButtonTab setSelectedtab={handleRows} selectedTab={limit} tabs={rows} />
                    </div>
                </div>
                {PermissionsOfMasterdata.includes('Add/Edit Organization') ? <CreateAnnouncement /> : ''}
            </div>
            <div className="h-[46vh] overflow-scroll">
                {loading || announcementList === null ? (
                    <div className="w-full flex justify-center items-center h-[56vh]">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                Loading...
                            </span>
                        </div>
                    </div>
                ) : (
                    announcementList && (
                        <table className="relative w-full text-sm">
                            <thead>
                                <tr className="text-left capitalize bg-gray-50">
                                    {cols?.map((col) => {
                                        return (
                                            <th
                                                key={col}
                                                title={col}
                                                scope="col"
                                                className="font-[500] p-2 border text-start"
                                            >
                                                {col}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {announcementList?.map((item, i) => {
                                    const {
                                        isactiveflag,
                                        announcementtext,
                                        announcementlink,
                                        sequence,
                                        expirydate,
                                        createdtimestamp,
                                        createduser,
                                        announcementid,
                                    } = item
                                    return (
                                        <tr
                                            onClick={() => handleAnnouncementClick(item)}
                                            key={announcementid}
                                            className="text-left cursor-pointer"
                                        >
                                            <td className="border px-2 py-1.5">
                                                <div>{announcementtext}</div>
                                            </td>
                                            <td className="border px-2 py-1.5">
                                                <div>{announcementlink}</div>{' '}
                                            </td>
                                            <td className="border px-2 py-1.5">
                                                <div>{sequence}</div>{' '}
                                            </td>
                                            <td className="border px-2 py-1.5">
                                                <div>{expirydate}</div>{' '}
                                            </td>
                                            <td className="px-2 border">
                                                <div>{isactiveflag === true ? 'Active' : 'Inactive'}</div>
                                            </td>
                                            <td className="px-2 border">
                                                <div> {moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div>
                                            </td>
                                            <td className="px-2 border">
                                                <div>{createduser}</div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                )}
            </div>
            <EditAnnouncement
                detailsData={detailsData}
                setDetailsData={setDetailsData}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                announcementid={announcementId}
                page={page}
                limit={limit}
                permissions={PermissionsOfMasterdata}
            />
        </div>
    )
}

export default AnnouncementMasterList
