import {useEffect, useState} from 'react'
import CustomAutoSearch from 'src/Components/CustomAutoSearch'
import {GET_PROJECTS} from 'src/Redux/actions/projects'
import {getDefaultProjectPayload} from 'src/Services/constant/defaultPayload'

const SelectProject = ({
	project,
	handleSelectProject,
	projectList,
	disabled,
}) => {
	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		if (project?.projectname) {
			setSearchTerm(project?.projectname || '')
		}
	}, [project])

	return (
		<>
			<label htmlFor='Project' className='block font-[500] mb-2'>
				Select Project <span className='text-red-300'>*</span>
			</label>
			<CustomAutoSearch
				disabled={disabled}
				searchTerm={searchTerm}
				API_CALL_FOR_SEARCH={GET_PROJECTS}
				searchPayload={{
					...getDefaultProjectPayload(),
					projectname_code: searchTerm,
				}}
				className='p-2'
				setSearchTerm={setSearchTerm}
				options={projectList}
				idkey='projectid'
				optionlabelKey='projectname'
				getSelectedOption={handleSelectProject}
				placeholder='Search Project'
			/>
		</>
	)
}

export default SelectProject
