import { ACTION_GET_SEARCH_ACTIVITY_TASK_BY_ID, ACTION_GET_TASK_BY_GROUP_ID } from "src/Redux/types"

var initialState = {
    activitiesByProjectId: [],
    taskGroups: [{ taskgroupid: 0, taskgroupdesc: 'All',defaulttask:false }],
    tasksByGroupId: [],
    activitySearchedTasks:[],
    getListOfProjectRole: null,
    getCustomerRoleById: [],
    getMilestoneByproject: [],
    getBudgetByproject: [],
    fetchActivityPayload: {}
}

const ProjectPlanning = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ACTIVITY_BY_PROJECT':
            return { ...state, activitiesByProjectId: action.data?.Message ? [] : action.data }
        case 'GET_TASK_GROUPS':
            return { ...state, taskGroups: action.data?.Message ? [] : [{ taskgroupid: 0, taskgroupdesc: 'All',defaulttask:false }, ...action.data] }
        case ACTION_GET_SEARCH_ACTIVITY_TASK_BY_ID:
            return { ...state, activitySearchedTasks: action.data?.Message ? [] : action.data }
        case ACTION_GET_TASK_BY_GROUP_ID:
            return { ...state, tasksByGroupId: action.data?.Message ? [] : action.data }
        case 'GET_LIST_OF_PROJECT_ROLE':
            return { ...state, getListOfProjectRole: action.data?.Message ? [] : action.data }
        case 'GET_CUSTOMER_ROLE_BY_ID':
            return { ...state, getCustomerRoleById: action.data?.Message ? [] : action.data }
        case 'GET_MILESTONE_OF_PROJECT':
            return { ...state, getMilestoneByproject: action.data?.Message ? [] : action.data }
        case 'GET_BUDGET_OF_PROJECT':
            return { ...state, getBudgetByproject: action.data?.Message ? [] : action.data }
        case 'FETCH_ACTIVITY_PAYLOAD':
            return { ...state, fetchActivityPayload: action.data }
        default:
            return state
    }
}

export default ProjectPlanning