import SingleSelectField from 'src/Components/SingleSelectForTable'

const Importance = ({canEdit, rowId, value, handleChange, options}) => {
	const getSelected = value => {
		let e = {target: {name: 'importance', value: value['importance']}}
		handleChange(e, rowId)
	}
	return (
		<div className='w-full'>
			<SingleSelectField
				valueKey='importance'
				labelKey='name'
				options={options}
				value={value || 0}
				handleSelected={getSelected}
			/>
		</div>
	)
}

export default Importance
