
import SingleSelectField from "src/Components/SingleSelectForTable";

const Industry = ({ options, value, rowid, handleChange }) => {

    const getSelected = (value) => {
        let e = { target: { name: 'domainid', value: value['industryid'] } }
        handleChange(e, rowid)
    }

    return (
        <div className="w-full">
            <SingleSelectField
                valueKey='industryid'
                labelKey='industryname'
                options={options}
                value={value || 0}
                handleSelected={getSelected}
            />
        </div>
    )
}

export default Industry;
