import PrimaryButton from 'src/Components/Buttons/PrimaryButton'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomhtmlSelect from 'src/Components/CustomhtmlSelect'
import { AlertSuccess } from 'src/Services'
import { CheckObjectValidation } from 'src/Services/constant/customvalidation'
import { GET_CURRENCY_MASTER_LIST } from 'src/Redux/actions/projects'
import { getDefaultCurrencyPayload } from 'src/Services/constant/defaultPayload'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'

const EditDetails = ({ hooks }) => {
	const { currencyList } = useSelector(state => state?.Projects)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(GET_CURRENCY_MASTER_LIST(getDefaultCurrencyPayload()))
		// eslint-disable-next-line
	}, [])
	const {
		detailSave,
		closeModal,
		canDelete,
		setCanDelete,
		canEdit,
		setCanEdit,
		setRevenue,
		revenue,
	} = hooks
	const handleDates = e => {
		let { name, value } = e.target
		setRevenue(prevPayload => ({
			...prevPayload,
			[name]: value,
		}))
	}
	const [checkValidation, setCheckValidation] = useState(false)
	const handleSubmit = e => {
		e.preventDefault()
		setCheckValidation(true)
		const transactionDate = new Date(
			revenue?.transactiondate?.replace(/\/|\./g, '-'),
		)
		const monthid = transactionDate?.getMonth() + 1 // Adjust for JavaScript's 0-based months
		const yearid = transactionDate?.getFullYear()

		let validationdata = {}
		let requiredInDetail = [
			'projrevid',
			'projectid',
			'monthid',
			'yearid',
			'plannedrevenue',
			'recognizedrevenue',
			'actualrevenue',
			'datcomcurrencyid',
			'invoiceno',
			'transactionid',
			'transactiondate',
			'profitcentercode',
			'isactiveflag',
			'createdcomuserid',
			'modifiedcomuserid',
			'notes',
		]

		for (let value of requiredInDetail) {
			validationdata[value] = revenue[value]
		}
		validationdata['yearid'] = yearid
		validationdata['monthid'] = monthid
		validationdata['projectid'] = revenue?.projectid
		let optionalkey = [
			'projrevid',
			'profitcentercode',
			'notes',
			'recognizedrevenue',
			'plannedrevenue',
			'modifiedcomuserid'
		]
		let isValidated = CheckObjectValidation(validationdata, optionalkey, true)
		if (!isValidated?.isvalid) {
			return
		} else {
			let item = {
				projrevid: revenue?.projrevid,
				projectid: revenue?.projectid,
				yearid: yearid,
				monthid: monthid,
				recognizedrevenue: revenue?.recognizedrevenue,
				actualrevenue: revenue?.actualrevenue,
				plannedrevenue: revenue?.plannedrevenue,
				datcomcurrencyid: revenue?.datcomcurrencyid,
				invoiceno: revenue?.invoiceno,
				transactionid: revenue?.transactionid,
				transactiondate: revenue?.transactiondate,
				profitcentercode: revenue?.profitcentercode,
				isactiveflag: revenue?.isactiveflag,
				createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
				modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
				notes: revenue?.notes,
				description: revenue?.description,
				invoicedate: revenue?.invoicedate
			}
			let apiData = {
				...item,
				actualrevenue: Number(revenue?.actualrevenue),
				recognizedrevenue: Number(revenue?.recognizedrevenue),
				plannedrevenue: Number(revenue?.plannedrevenue),
			}
			let finalPayload = {
				projectrevenuejson: [apiData],
			}
			setCheckValidation(false)
			detailSave(finalPayload)
			setCanEdit(!canEdit)
		}
	}

	const handleDelete = async () => {
		let item = {
			projrevid: revenue?.projrevid,
			projectid: revenue?.projectid,
			monthid: revenue?.monthid,
			yearid: revenue?.projectid,
			recognizedrevenue: revenue?.recognizedrevenue,
			actualrevenue: revenue?.actualrevenue,
			plannedrevenue: revenue?.plannedrevenue,
			datcomcurrencyid: revenue?.datcomcurrencyid,
			invoiceno: revenue?.invoiceno,
			transactionid: revenue?.transactionid,
			transactiondate: revenue?.transactiondate,
			profitcentercode: revenue?.profitcentercode,
			isactiveflag: false,
			createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
			modifiedcomuserid: Number(localStorage.getItem('comuserid') || 0),
			notes: revenue?.notes,
			description: revenue?.description,
			invoicedate: revenue?.invoicedate
		}

		let payload = {
			projectrevenuejson: [item],
		}
		detailSave(payload)
		AlertSuccess('Record deleted successfully')
		closeModal()
	}

	const handleChangeInput = (name, value) => {
		setRevenue({ ...revenue, [name]: value })
	}

	return (
		<div className='w-full h-[65vh] text-sm mb-10'>
			<div className='grid grid-cols-4 gap-10 mt-4'>
				<div>
					<label htmlFor='actualrevenue' className='block font-[500] mb-2'>
						Actual Revenue
						<span className='text-red-300'>*</span>
					</label>
					<input
						disabled={!canEdit}
						type='number'
						title='actualrevenue'
						id='actualrevenue'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
						placeholder='actualrevenue'
						required=''
						onChange={e => handleChangeInput('actualrevenue', e.target.value)}
						value={revenue?.actualrevenue || ''}
					/>
					{!revenue?.actualrevenue && checkValidation && (
						<small className='text-red-400'>actualrevenue is required!</small>
					)}
				</div>
				<div>
					<label
						htmlFor='datcomcurrencyid'
						className='block capitalize mb-2  font-[500]'
					>

						Currency
						<span className='text-red-300'>*</span>
					</label>
					<CustomhtmlSelect
						disabled={!canEdit}
						options={currencyList || []}
						noDataLabel='No Currency Type'
						value={revenue?.datcomcurrencyid || null}
						name='datcomcurrencyid'
						labelkey='currency'
						className='py-2 border rounded-lg bg-gray-50'
						onChange={handleChangeInput}
						id='datcomcurrencyid'
					/>
					{!revenue?.datcomcurrencyid && checkValidation && (
						<small className='text-red-400'>currency is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='profitcentercode' className='block font-[500] mb-2'>
						Profit Center Code
					</label>
					<input
						disabled={!canEdit}
						type='text'
						title='profitcentercode'
						id='profitcentercode'
						className='block w-full p-2 text-gray-600 border border-gray-200 rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
						placeholder='profitcentercode'
						required=''
						value={revenue?.profitcentercode || ''}
						onChange={e =>
							handleChangeInput('profitcentercode', e.target.value)
						}
					/>
				</div>
				<div>
					<label htmlFor='invoiceno' className='block font-[500] mb-2'>
						Invoice Number
						<span className='text-red-300'>*</span>
					</label>
					<input
						type='text'
						disabled={!canEdit}
						title='invoiceno'
						id='invoiceno'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
						placeholder='invoiceno'
						required=''
						value={revenue?.invoiceno || ''}
						onChange={e => handleChangeInput('invoiceno', e.target.value)}
					/>
					{!revenue?.invoiceno && checkValidation && (
						<small className='text-red-400'>invoiceno is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='transactiondate' className='block font-[500] mb-2'>
						Transaction Date
						<span className='text-red-300'>*</span>
					</label>
					<FlatPickrDateInput
						disabled={!canEdit}
						name='transactiondate'
						value={revenue?.transactiondate || ''}
						id='transactiondate'
						className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]'
						onChangeDate={handleDates}
					/>
					{!revenue?.transactiondate && checkValidation && (
						<small className='text-red-400'>
							transactiondate is required!
						</small>
					)}
				</div>
				<div>
					<label htmlFor='transactionid' className='block font-[500] mb-2'>
						Transaction ID
						<span className='text-red-300'>*</span>
					</label>
					<input
						type='text'
						disabled={!canEdit}
						title='transactionid'
						id='transactionid'
						className='block w-full p-2 text-gray-600 border rounded-lg outline-gray-50 bg-gray-50'
						placeholder='transactionid'
						required=''
						value={revenue?.transactionid || ''}
						onChange={e => handleChangeInput('transactionid', e.target.value)}
					/>
					{!revenue?.transactionid && checkValidation && (
						<small className='text-red-400'>transactionid is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='description' className='block font-[500] mb-2'>
						Description
						<span className='text-red-300'>*</span>
					</label>
					<input
						type='text'
						title='description'
						id='description'
						className='block w-full p-2 text-gray-600 border border-gray-200 rounded-lg outline-gray-50 bg-gray-50 focus:ring-primary-600 focus:border-primary-600 '
						placeholder='description'
						required=''
						value={revenue?.description || ''}
						onChange={e =>
							handleChangeInput('description', e.target.value)
						}
					/>
					{!revenue?.description && checkValidation && (
						<small className='text-red-400'>transactionid is required!</small>
					)}
				</div>
				<div>
					<label htmlFor='invoicedate' className='block font-[500] mb-2'>
						Invoice Date
						<span className='text-red-300'>*</span>
					</label>
					<FlatPickrDateInput
						disabled={!canEdit}
						name='invoicedate'
						value={revenue?.invoicedate?.split("T")[0] || ''}
						id='invoicedate'
						className='outline-none bg-gray-50 border border-gray-300 text-textSecondary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-[7px]'
						onChangeDate={handleDates}
					/>
					{!revenue?.invoicedate && checkValidation && (
						<small className='text-red-400'>
							invoicedate is required!
						</small>
					)}
				</div>
			</div>
			{canEdit ? (
				<div className='fixed bottom-0 mt-2 right-6'>
					<button
						onClick={() => setCanEdit(null)}
						className='text-primary bg-gray-100 w-[80px] rounded-md py-2'
					>
						Cancel
					</button>
					<PrimaryButton
						onClick={handleSubmit}
						className='py-2 mx-2 text-white rounded-md bg-primary'
					>
						Save
					</PrimaryButton>
				</div>
			) : (
				<div className='h-12' />
			)}
			{canDelete ? (
				<div className='fixed bottom-0 mt-2 right-6'>
					<div className='flex items-center justify-end gap-2'>
						<span className=""> Do you really want to delete this record? </span>
						<PrimaryButton className='px-6 border bg-white  border-primary text-black rounded mt-0 h-9 py-1.5 mb-2'
							onClick={handleDelete}
						>
							Yes
						</PrimaryButton>
						<PrimaryButton className='bg-primary text-white rounded py-1.5 px-8 mb-4 h-9'
							onClick={() => setCanDelete(null)}
						>
							No
						</PrimaryButton>
					</div>
				</div>
			) : (
				<div className='h-12' />
			)}
		</div>
	)
}

export default memo(EditDetails)
