// import {jsPDF} from 'jspdf'
import ScrollToBottom from 'react-scroll-to-bottom'
import IconButton from 'src/Components/Buttons/IconButton'
import FlatPickrDateInput from 'src/Components/FlatpickerDateInput'
// import UploadDocument from './uploadCertificate'

const cols = ['Certification', 'Certified By', 'Completed Date', 'Valid Till']

const Table = props => {
	const { formData, setFormData, canEdit /* documents, setDocuments */ } = props
	const handleAdd = () => {
		setFormData([
			...formData,
			{
				comusercertificationid: null,
				certificatefilepath: '',
				comuserid: Number(localStorage.getItem('comuserid') || 0),
				certificationname: '',
				certificationagency: '',
				modulename: '',
				certificationdate: '',
				certificationexpirydate: '',
				remarks: '',
				comtenantid: Number(localStorage.getItem('Tenantid') || 0),
				isactiveflag: true,
				createdcomuserid: Number(localStorage.getItem('comuserid') || 0),
				notes: '',
				uId:
					Math.random().toString(36).substr(2, 9) +
					new Date().getTime().toString(36),
				username: '',
			},
		])
	}

	const handleDelete = uId => {
		if (formData?.length > 1) {
			setFormData(formData?.filter(item => item.uId !== uId))
		}
	}

	const handleChange = (e, uId) => {
		let afterChange = formData?.map(item => {
			if (item.uId === uId) {
				item[e.target.name] = e.target.value
			}
			return item
		})
		setFormData(afterChange)
	}

	/* 	const createHeaders = keys => {
			const result = []
			for (let key in keys) {
				result.push({
					id: key,
					name: keys[key],
					prompt: key,
				})
			}
			return result
		}

		console.log(formData)

		const exportPdf = async () => {
			const headers = createHeaders([
				// 'Id',
				'Certification',
				'Completed_From',
				'Completed_Date',
				'Valid_Till',
			])
			const doc = new jsPDF({orientation: 'landscape'})
			// doc.autoTable({
			// 	html: '#my-table',
			// })
			const tableData = formData?.map(row => ({
				// ...row,
				comusercertificationid: row?.comusercertificationid?.toString(),
				certificationname: row?.certificationname?.toString(),
				certificationdate: row?.certificationdate?.toString(),
				certificationagency: row?.certificationagency?.toString(),
			}))
			console.log({tableData})

			doc.table( 1,1, tableData,headers, {autoSize: true})
			doc.save('file.pdf')
		} */

	return (
		<ScrollToBottom className='bg-white w-full overflow-y-scroll h-[40vh] text-sm'>
			<table className='w-full text-left text-gray-600' id='my-table'>
				<thead className='capitalize bg-gray-50'>
					<tr className='bg-gray-100 border-b'>
						{cols?.map(item => {
							const label = item.includes('*') ? item.replace('*', '') : item
							const isRequired = [
								'Certification',
								'Completed From',
								'Completed Date',
							].includes(label)
							return (
								<th
									key={item}
									className='bg-gray-100 border-b  font-[500] py-2 px-2'
								>
									{label}
									{isRequired && <span className='text-red-300'> *</span>}
								</th>
							)
						})}
						{canEdit && (
							<>
								{/* <th className='border  font-[500] py-2 px-2'>Certificate</th> */}
								<th className='border  font-[500] py-2 px-2'>Action</th>
							</>
						)}
					</tr>
				</thead>
				<tbody>
					{formData?.map((item, i) => {
						let {
							certificationname,
							certificationdate,
							certificationagency,
							uId,
							certificationexpirydate,
							// comusercertificationid,
						} = item
						return (
							<tr key={`${uId}-${i}`} className='bg-white border-b'>
								<td className='px-1 py-1 border'>
									<div className='w-56 '>
									<input
										disabled={!canEdit}
										onChange={e => handleChange(e, item?.uId)}
										type='text'
										value={certificationname || ''}
										name='certificationname'
										placeholder='course'
										className={`py-0.5 w-full disabled:text-gray-600  disabled:bg-transparent focus:outline-none `}
										maxLength={50}
									/>
									</div>
								</td>
								<td className='px-1 py-1 border'>
									<input
										disabled={!canEdit}
										onChange={e => handleChange(e, item?.uId)}
										type='text'
										value={certificationagency || ''}
										name='certificationagency'
										placeholder='agencyname'
										className={`py-0.5 w-full disabled:text-gray-600  disabled:bg-transparent focus:outline-none `}
										maxLength={50}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<FlatPickrDateInput
										disabled={!canEdit}
										name='certificationdate'
										value={certificationdate || ''}
										id='certificationdate'
										className=''
										onChangeDate={e => handleChange(e, item?.uId)}
									/>
								</td>
								<td className='px-1 py-1 border'>
									<FlatPickrDateInput
										disabled={!canEdit}
										name='certificationexpirydate'
										value={certificationexpirydate || ''}
										id='certificationexpirydate'
										className=''
										onChangeDate={e => handleChange(e, item?.uId)}
									/>
								</td>
								{canEdit && (
									<td className='px-1 py-1 border'>
										<div className='flex justify-end'>
											{formData?.length > 1 && (
												<IconButton
													onClick={() => handleDelete(item?.uId)}
													title='Remove Certification'
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'
													>
														<path
															fillRule='evenodd'
															d='M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z'
															clipRule='evenodd'
														/>
													</svg>
												</IconButton>
											)}
											<span className="hidden addIconbutton-add-btn">
												<IconButton
													disabled={formData?.length >= 10}
													onClick={handleAdd}
													title='Add Certification'
													className='bg-primary p-0.5 disabled:bg-primary/20 text-white'
												>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 24 24'
														fill='currentColor'
														className='w-6 h-6'
													>
														<path
															fillRule='evenodd'
															d='M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z'
															clipRule='evenodd'
														/>
													</svg>
												</IconButton>
											</span>
										</div>
									</td>
								)}
							</tr>
						)
					})}
				</tbody>
			</table>
			{/* <div className='fixed flex items-center justify-end gap-2 mb-4 mr-4 bottom-4'>
				<button
					hidden={!canEdit}
					onClick={() => {
						// setCanEdit(false)
						// dispatch(GET_CERTIFICATIONS())
						// generatePdf() 						exportPdf()

					}}
					className='px-2 py-1 border rounded border-primary text-primary h-9'
				>
					Export
				</button>
			</div> */}
		</ScrollToBottom>
	)
}

export default Table
