import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomAutoSearch from 'src/Components/CustomAutoSearch';
import FilterButtonTab from 'src/Components/FilterButtonTab';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectInput from 'src/Components/SelectInput';
import { GET_DESIGNATION_MASTER } from 'src/Redux/actions/workforce/talent';
import { getDesignationMasterIdData } from 'src/Services/constant/masterdatapayload';
import { basicShortList } from 'src/Services/constant/staticValues';
import { DesignationItem } from './createOrg/default';
import CreateDesignation from './createOrg/index';
import EditDetails from './editOrg/index';

const cols = ['Designation', 'Status', 'Created At', 'Created By'];

const rows = [10, 25, 50];

const statusList = [
  { label: 'Active' },
  { label: 'Inactive' },
  { label: 'All' },
];

const DesignationMasterList = () => {
  const [designation, setDesignation] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [designationId, setDesignationId] = useState(null);
  const [status, setStatus] = useState({ label: 'Active' });
  const [detailsData, setDetailsData] = useState(DesignationItem());
  const [sortType, setSortType] = useState({ label: 'Latest First', id: 3 });

  const dispatch = useDispatch();
  const { designationlist, loading: designationLoading } = useSelector((state) => state.EmployeeDetails);
  const { PermissionsOfMasterdata } = useSelector((state) => state.UserManagementList);

  useEffect(() => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      setLoading(designationLoading); // Hide the loader after a delay
    }, 100);
  }, [designationLoading]);

  useMemo(() => {
    if (designation?.designation) {
      setSearchTerm(designation?.designation);
    }
  }, [designation]);

  const handleSorting = async (item) => {
    setSortType(item);
    let res = await dispatch(GET_DESIGNATION_MASTER({ ...getDesignationMasterIdData(), sorttype: item.id, status: status?.label, limit: limit, }));
    if (res !== null) {
      setDetailsData(res.data);
    }
  };

  const handleDesignationClick = (designation) => {
    setDetailsData(designation);
    setDesignationId(designation?.designationmasterid);
    setIsOpen(true);
  };

  const handleStatus = async (item) => {
    setStatus(item);
    let res = await dispatch(GET_DESIGNATION_MASTER({ ...getDesignationMasterIdData(), status: item.label }));
    if (res === null) {
      setDetailsData(res?.data);
    }
  };

  const handlePagination = async (page) => {
    let res = await dispatch(GET_DESIGNATION_MASTER({ ...getDesignationMasterIdData(), limit, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res?.success) {
      setDetailsData(res?.data);
    }
    setPage(page?.selected + 1);
  };

  const handleRows = async (row) => {
    setLimit(row);
    let res = await dispatch(
      GET_DESIGNATION_MASTER({ ...getDesignationMasterIdData(), limit: row, page: page?.selected + 1, sorttype: sortType?.id, status: status?.label, }));
    if (res?.success) {
      setDetailsData(res?.data);
    }
  };

  return (
    <div className='w-full h-screen p-2 py-3 mt-4 bg-white rounded-lg shadow'>
      <div className='flex items-center justify-between mb-2'>
        <div className='flex items-center justify-start space-x-5'>
          <h1 className='pb-2 font-[500]'>
            Designations ({designationlist?.length > 0 ? designationlist[0]?.totalcount : designationlist?.length}){' '}
          </h1>
          <div className='w-72'>
            <CustomAutoSearch
              searchTerm={searchTerm}
              API_CALL_FOR_SEARCH={GET_DESIGNATION_MASTER}
              searchPayload={{ ...getDesignationMasterIdData(), searchname: searchTerm }}
              setSearchTerm={setSearchTerm}
              options={designationlist}
              idkey='designation'
              optionlabelKey='designation'
              getSelectedOption={setDesignation}
              placeholder='Enter Designation Name'
              className='p-2'
            />
          </div>
          <div className='flex items-center justify-start space-x-6'>
            <ReactPaginationStyle
              total={designationlist?.length > 0 ? designationlist[0]?.totalcount : designationlist?.length}
              currentPage={page}
              handlePagination={handlePagination}
              limit={limit}
            />
            <FilterButtonTab
              setSelectedtab={handleRows}
              selectedTab={limit}
              tabs={rows}
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={statusList || []}
              keyname='label'
              selected={status}
              setSelected={handleStatus}
              className='py-2 border rounded bg-gray-50'
            />
          </div>
          <div className='w-32 '>
            <SelectInput
              options={basicShortList || []}
              keyname='label'
              selected={sortType}
              setSelected={handleSorting}
              className='py-2 border rounded bg-gray-50'
            />
          </div>
        </div>
        {(PermissionsOfMasterdata.includes("Add/Edit Organization")) ? <CreateDesignation /> : ""}
      </div>
      <div className='h-[46vh] overflow-scroll'>
        {loading || designationlist === null ? (
          <div className='w-full flex justify-center items-center h-[56vh]'>
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          designationlist && (
            <table className='relative w-full text-sm'>
              <thead>
                <tr className='text-left capitalize bg-gray-50'>
                  {cols?.map((col) => {
                    return (
                      <th
                        key={col}
                        title={col}
                        scope='col'
                        className='font-[500] p-2 border text-start'
                      >
                        {col}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {designationlist?.map((item, i) => {
                  const {
                    isactiveflag,
                    designationmasterid,
                    designation,
                    createdtimestamp,
                    createduser,
                  } = item;
                  return (
                    <tr
                      onClick={() => handleDesignationClick(item)}
                      key={designationmasterid}
                      className='text-left cursor-pointer'>
                      <td className='border px-2 py-1.5'><div>{designation}</div></td>
                      <td className='px-2 border'><div>{isactiveflag === true ? 'Active' : 'Inactive'}</div></td>
                      <td className='px-2 border'><div> {moment(createdtimestamp).format('DD-MM-YYYY kk:mm:ss')}</div></td>
                      <td className='px-2 border'><div>{createduser}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>))}
      </div>
      <EditDetails
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        isOpen={isOpen}
        designationmasterid={designationId}
        setIsOpen={setIsOpen}
        page={page}
        limit={limit}
        sortType={sortType}
        permissions={PermissionsOfMasterdata}
      />
    </div>
  );
};

export default DesignationMasterList;