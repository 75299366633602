import CustomhtmlSelect from "src/Components/CustomhtmlSelect";

const SelectTimeTemplate = ({ timetemplates, handleChangeValue, value }) => {
    return (

        <>
            <label htmlFor="projectEntity" className="block font-[500] mb-2">Select Time Template <span className="text-red-300">*</span></label>
            <CustomhtmlSelect
                options={timetemplates || []}
                value={value}
                noDataLabel='No Time templates'
                name='timetemplatemasterid'
                labelkey='templatename'
                className='border rounded bg-gray-50 py-2'
                onChange={handleChangeValue}
                id='timetemplatemasterid'
            />
        </>
    )
}

export default SelectTimeTemplate;