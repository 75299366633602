import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterButtonTab from 'src/Components/FilterButtonTab'
import NoPermissionComponent from 'src/Components/NoPermission'
import { getPermissionsOfCurrentUrl } from 'src/Services'
import NotificationMasterList from '../configuration/Notifications'
import RolesMasterList from '../configuration/Roles'
import Matchmaking from './Demands/createDemand/index.js'
import CategoryMasterList from './Timesheet'
import RulesMasterList from './Timesheet/rules'
import TemplateMasterList from './Timesheet/templates'

const typeList = ['Project', 'Planning', 'Staffing', 'Timesheet', 'Demands', 'Roles', 'Notifications']
const tabslistforTS = ['Time Types', 'Templates', 'Rules']
const tabslistforPR = ['Rules', 'Approvals']
const tabslistforPL = ['Rules', 'Approvals']
const tabslistforST = ['Rules', 'Approvals']
const tabslistforDM = ['Matchmaking', 'Approvals']
const tabslistforRO = ['Roles']
const tabslistforNO = ['Notifications']

const ConfigurationData = ({ modulename, menu }) => {
    const dispatch = useDispatch()
    const [typeis, setTypeis] = useState(typeList[0])
    const [tabsTS, setTabsTS] = useState(tabslistforTS[0])
    const [tabsPR, setTabsPR] = useState(tabslistforPR[0])
    const [tabsPL, setTabsPL] = useState(tabslistforPL[0])
    const [tabsST, setTabsST] = useState(tabslistforST[0])
    const [tabsDM, setTabsDM] = useState(tabslistforDM[0])
    const [tabsRO, setTabsRO] = useState(tabslistforRO[0])
    const [tabsNO, setTabsNO] = useState(tabslistforNO[0])
    const { PermissionsOfConfiguration } = useSelector((state) => state.ConfigurationList)

    useEffect(() => {
        const permissionNames = getPermissionsOfCurrentUrl(modulename, menu)?.map((item) => item?.permissionname)
        dispatch({ type: 'SAVE_PERMISSIONS_OF_CONFIGRATION', data: permissionNames })
        // eslint-disable-next-line
    }, [dispatch, menu])

    return (
        <div>
            <div className="flex items-start px-1 py-2">
                <FilterButtonTab
                    className="px-5 py-2 shadow"
                    tabs={typeList}
                    setSelectedtab={setTypeis}
                    selectedTab={typeis}
                />
            </div>
            <div className="px-1 text-sm">
                {typeis === 'Timesheet' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforTS}
                                    setSelectedtab={setTabsTS}
                                    selectedTab={tabsTS}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsTS === 'Time Types' && (
                                    <>
                                        <CategoryMasterList />
                                    </>
                                )}
                                {tabsTS === 'Templates' && (
                                    <>
                                        <TemplateMasterList />
                                    </>
                                )}
                                {tabsTS === 'Rules' && (
                                    <>
                                        <RulesMasterList />
                                    </>
                                )}
                                {tabsTS === 'Approvals' && <>{/* CODE FOR  FUTURE USE  <RulesMasterList /> */}</>}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Project' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    // buttonWidth='w-full'
                                    className="px-4 py-2 shadow"
                                    tabs={tabslistforPR}
                                    setSelectedtab={setTabsPR}
                                    selectedTab={tabsPR}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div className="flex justify-start">
                                {tabsPR === 'Rules' && <>{/* CODE FOR FUTURE USE <CategoryMasterList /> */}</>}
                                {tabsPR === 'Approvals' && <>{/* CODE FOR FUTURE USE  <TemplateMasterList /> */}</>}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Planning' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                            <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforPL}
                                    setSelectedtab={setTabsPL}
                                    selectedTab={tabsPL}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsPL === 'Rules' && <>{/* CODE FOR  FUTURE USE <CategoryMasterList /> */}</>}
                                {tabsPL === 'Approvals' && <>{/* CODE FOR  FUTURE USE <TemplateMasterList /> */}</>}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Demands' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                        <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforDM}
                                    setSelectedtab={setTabsDM}
                                    selectedTab={tabsDM}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsDM === 'Matchmaking' && (
                                    <>
                                        <Matchmaking />
                                    </>
                                )}
                                {tabsDM === 'Approvals' && <>{/* CODE FOR  FUTURE USE <TemplateMasterList /> */}</>}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Staffing' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                        <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforST}
                                    setSelectedtab={setTabsST}
                                    selectedTab={tabsST}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsST === 'Rules' && <>{/*CODE FOR FUTURE USE  <CategoryMasterList /> */}</>}
                                {tabsST === 'Approvals' && <>{/*CODE FOR FUTURE USE  <TemplateMasterList /> */}</>}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Roles' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                        <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforRO}
                                    setSelectedtab={setTabsRO}
                                    selectedTab={tabsRO}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsRO === 'Roles' && (
                                    <>
                                        <RolesMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
                {typeis === 'Notifications' &&
                    (PermissionsOfConfiguration?.includes('View Config') ? (
                        <>
                        <div className="flex justify-start">
                                <FilterButtonTab
                                    className="px-5 py-2 shadow"
                                    tabs={tabslistforNO}
                                    setSelectedtab={setTabsNO}
                                    selectedTab={tabsNO}
                                    // buttonWidth='w-56'
                                />
                            </div>
                            <div>
                                {tabsNO === 'Notifications' && (
                                    <>
                                        <NotificationMasterList />
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoPermissionComponent />
                    ))}
            </div>
        </div>
    )
}
export default ConfigurationData
