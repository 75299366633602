


const ExpenseDetail = ({ data, currency }) => {
    return (
        <div className="shadow rounded-lg text-sm p-2 ">
            <h1 className="my-1 font-[500]">Expense Details</h1>
            <div className="overflow-scroll h-[210px] ">
                <table className="w-full">
                    <thead>
                        <tr className='text-left  bg-gray-50'>
                            <th className="font-[500] border p-1.5">Expense Head</th>
                            <th className="font-[500] border p-1.5">As of Prev. Month </th>
                            <th className="font-[500] border p-1.5">Current Month </th>
                            <th className="font-[500] border p-1.5">Total Expenses </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((ite, i) => {
                                return <tr key={i}>
                                    <td className="border p-1.5">{ite?.type}</td>
                                    <td className="border p-1.5">{currency} {ite?.amount_prevmonth}</td>
                                    <td className="border p-1.5">{currency} {ite?.amount_curmonth}</td>
                                    <td className="border p-1.5">{currency} {ite?.total_amount}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ExpenseDetail;